import Button from '@admin/components/button'
import Email from '@admin/components/email'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class ThreadViewer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    thread: PropTypes.object
  }

  iframe = null
  pasteur = null

  state = {
    expanded: [],
    seen: []
  }

  _handleExpandAll = this._handleExpandAll.bind(this)

  render() {
    const { thread } = this.props
    return (
      <div className="email-viewer-canvas">
        <div className="email-viewer-frame">
          <div className="email-viewer">
            <div className="email-viewer-header">
              <strong>Imported By:</strong> { this._getUser() }<br />
              <strong>Program:</strong> { this._getProgram() }<br />
              <strong>Contacts:</strong> <div className="email-viewer-header-contacts">
                { thread.contacts.map((contact, index) => (
                  <Button key={`contact_${index}`} { ...this._getContact(contact) } />
                )) }
              </div><br />
              <strong>Subject:</strong> { thread.subject }<br />
              <strong>Sent:</strong> { moment(thread.sent_at).format('MMM D, YYYY @ h:mm A') } via { thread.via }
              { thread.emails.length > 1 &&
                <div className="email-viewer-header-actions">
                  <Button { ...this._getExpandAll() } />
                </div>
              }
            </div>
            { thread.emails.map((email, index) => (
              <div className={ this._getEmailClass(email) } key={`email_${index}`}>
                { thread.emails.length > 1 &&
                  <div className="email-viewer-email-header" onClick={ this._handleExpand.bind(this, email) }>
                    <div className="email-viewer-email-header-icon">
                      <Icon icon="chevron-right" />
                    </div>
                    <div className="email-viewer-email-header-details">
                      <div className="email-viewer-email-header-label">
                        { email.from.rfc822 } { this._getIsNew(email) &&
                          <span className="alert">NEW!</span>
                        }
                      </div>
                      <div className="email-viewer-email-header-timestamp">
                        { email.sent_at ?
                          <>{ this._getTimestamp(email.sent_at) }</> :
                          <>SENDING...</>
                        }
                      </div>
                    </div>
                  </div>
                }
                { this._getIsExpanded(email) &&
                  <div className="email-viewer-email-body">
                    <Email { ...this._getEmail(email) } />
                  </div>
                }
              </div>
            )) }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { emails } = this.props.thread
    const last_email = emails.slice(-1)[0]
    this.setState({
      seen: emails.map(email => email.id),
      expanded: [last_email.id]
    })
  }

  _getAreAllExpanded() {
    const { expanded } = this.state
    const { thread } = this.props
    return expanded.length === thread.emails.length
  }

  _getContact(contact) {
    return {
      label: contact.display_name,
      className: 'link',
      route: `/admin/crm/contacts/${contact.id}`
    }
  }

  _getEmail(email) {
    return {
      email,
      expandable: true
    }
  }

  _getEmailClass(email) {
    const classes = ['email-viewer-email']
    if(this._getIsExpanded(email)) classes.push('expanded')
    return classes.join(' ')
  }

  _getExpandAll() {
    const expanded = this._getAreAllExpanded()
    return {
      icon: expanded ? 'minus-square-o' : 'plus-square-o',
      className: 'email-viewer-header-action',
      handler: this._handleExpandAll,
      tooltip: `${expanded ? 'Contract' : 'Expand'} all messages`
    }
  }

  _getIcon(email) {
    return this._getIsExpanded(email) ? 'down' : 'right'
  }

  _getIsExpanded(email) {
    const { expanded } = this.state
    return _.includes(expanded, email.id)
  }

  _getIsNew(email) {
    const { seen } = this.state
    return !_.includes(seen, email.id)
  }

  _getProgram() {
    const { thread } = this.props
    const button = {
      label: thread.program.title,
      className: 'link',
      route: `/admin/team/programs/${thread.program.id}`
    }
    return <Button { ...button } />
  }

  _getTimestamp(sent_at) {
    const now = moment()
    const sent = moment(sent_at)
    const diff = now.diff(sent, 'hours')
    const time = diff >= 12 ? sent.format('ddd, MMM D, YYYY, h:mm A') : sent.format('h:mm A')
    const ago = sent.fromNow()
    return `${time} (${ago})`
  }

  _getUser() {
    const { thread } = this.props
    const button = {
      label: thread.user.full_name,
      className: 'link',
      route: `/admin/team/users/${thread.user.id}`
    }
    return <Button { ...button } />
  }

  _handleExpand(email) {
    const { composing, expanded, seen } = this.state
    this.setState({
      seen: _.union(seen, [email.id])
    })
    this.setState({
      composing: _.omit(composing, email.id),
      expanded: _.xor(expanded, [email.id])
    })
  }

  _handleExpandAll() {
    const { thread } = this.props
    const expanded = this._getAreAllExpanded()
    this.setState({
      seen: expanded ? this.state.seen : thread.emails.map(email => email.id),
      composing: expanded ? {} : this.state.composing,
      expanded: expanded ? [] : thread.emails.map(email => email.id)
    })
  }

}

export default ThreadViewer
