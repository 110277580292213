import Player from '@apps/phone/admin/components/player'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Recordings)',
  access: {},
  collection: {
    endpoint: '/api/admin/phone/voicemails',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { key: 'asset.file_name', primary: true, padded: true, collapsing: true, format: (voicemail) => {
        return <Player label={ null } voicemail={ voicemail } />
      } },
      { label: 't(Program)', key: 'program.title', sort: 'program', primary: true },
      { label: 't(Duration)', key: 'duration', collapsing: true, format: 'duration' },
      { label: 't(Created At)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    empty: {
      icon: 'voicemail',
      title: 't(No Voicemails)',
      text: 't(There are no voicemails for this number)'
    },
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: '-created_at', order: 'asc' },
    entity: 'voicemail'
  }
})

export default Panel(null, mapPropsToPanel)
