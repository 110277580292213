import ImageToken from '@admin/tokens/image'
import PropTypes from 'prop-types'
import React from 'react'

class Layout extends React.Component {

  static propTypes = {
    cid: PropTypes.string,
    excludeIds: PropTypes.array,
    files: PropTypes.array,
    format: PropTypes.any,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    selected: PropTypes.array,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onToggle: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="truevail-images">
        { records.map((image, index) => (
          <div className={ this._getClass(image) } key={`photo_${image.id}`} onClick={ this._handleChoose.bind(this, image) }>
            <ImageToken { ...image } />
          </div>
        )) }
      </div>
    )
  }

  _getClass(photo) {
    const { files } = this.props
    const classes = ['truevail-image']
    if(_.find(files, { id: photo.id })) classes.push('selected')
    return classes.join(' ')
  }

  _handleChoose(image) {
    const { multiple } = this.props
    this.props.onToggle(multiple, image)
  }

}

export default Layout
