import MultiForm from '@admin/components/multiform'
import Configure from './configure'
import PropTypes from 'prop-types'
import Number from './number'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Provision Phone Number)',
      action: '/api/admin/phone/numbers',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getPanel() {
    return {
      title: 't(Provision Phone Number)',
      rightItems: [
        { label: 'Done', handler: this._handleCancel }
      ]
    }
  }

  _getData(formdata) {
    const { program_id } = this.props
    return {
      program_id,
      hold_strategy: formdata.hold_strategy,
      stock_hold_music: formdata.stock_hold_music,
      custom_hold_music_id: formdata.custom_hold_music_id,
      number: formdata.number
    }
  }

  _getSteps() {
    return [
      { label: 't(Number)', component: Number },
      { label: 'Configure', component: Configure }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(number) {
    this.context.router.push(`/phone/numbers/${number.id}`)
    this.context.modal.close()
  }

}

export default New
