import PropTypes from 'prop-types'
import React from 'react'

const UsagesToken = ({ usages }) => (
  <div className="subscriptions-usages-token">
    <table className="ui celled compact unstackable table">
      <thead>
        <tr>
          <th>Item</th>
          <th>Used</th>
        </tr>
      </thead>
      <tbody>
        { usages.map((usage, index) => (
          <tr key={`allowance_${index}`}>
            <td>{ usage.resource.title.value }</td>
            <td className="right aligned">
              { usage.units !== null ?
                <span className={ usage.overage ? 'alert' : ''}>
                  { usage.units } / { usage.included }
                </span> :
                <span className="disabled">NO VALUE</span>
              }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

UsagesToken.propTypes = {
  usages: PropTypes.array
}

export default UsagesToken
