import Tokens from '@admin/components/tokens'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Sidebar from './sidebar'
import React from 'react'
import New from './new'

class SidebarContainer extends React.PureComponent {

  static propTypes = {
    active: PropTypes.string,
    blocks: PropTypes.array,
    campaign: PropTypes.object,
    changes: PropTypes.number,
    fields: PropTypes.array,
    program: PropTypes.object,
    properties: PropTypes.array,
    status: PropTypes.string,
    steps: PropTypes.array,
    step: PropTypes.object,
    tokens: PropTypes.array,
    version: PropTypes.object,
    versions: PropTypes.array,
    workflow: PropTypes.object,
    onAddStep: PropTypes.func,
    onEditStep: PropTypes.func,
    onNewStep: PropTypes.func,
    onUpdateStep: PropTypes.func
  }

  stackRef = React.createRef()

  _handleAdd = this._handleAdd.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleEdit = this._handleEdit.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleTokens = this._handleTokens.bind(this)

  render() {
    return (
      <div className="flowchart-designer-sidebar">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  componentDidMount() {
    this._handlePush(Sidebar, this._getSidebar.bind(this))
  }

  componentDidUpdate(prevProps) {
    const { active, step } = this.props
    if(active !== prevProps.active) {
      if(active === null) {
        this._handlePop()
      } else if(prevProps.active) {
        this._handlePop()
        setTimeout(this._handleEdit, 300)
      } else if(active) {
        this._handleEdit()
      }
    }
    if(!_.isEqual(step, prevProps.step)) {
      if(step) {
        this._handleNew()
      } else {
        this._handlePop()
      }
    }
  }

  _getSidebar() {
    const { blocks, version, versions } = this.props
    return {
      blocks,
      version,
      versions
    }
  }

  _getEdit(step) {
    const { campaign, fields, program, properties, steps, workflow } = this.props
    const { code, config } = step
    return {
      campaign,
      config,
      fields,
      properties,
      program,
      steps,
      workflow,
      onCancel: this._handleCancel.bind(this, 'edit'),
      onTokens: this._handleTokens,
      onDone: this._handleUpdate.bind(this, code)
    }
  }

  _getNew() {
    const { campaign, blocks, fields, program, properties, step, steps, workflow } = this.props
    const { type, action } = step
    const block = _.find(blocks, { type, action })
    return {
      block,
      campaign,
      fields,
      program,
      properties,
      step,
      steps,
      workflow,
      onAddStep: this._handleAdd,
      onCancel: this._handleCancel.bind(this, 'new'),
      onTokens: this._handleTokens,
      onDone: this._handleAdd
    }
  }

  _getStack() {
    return {
      display_name: 'flowchart_designer',
      ref: this.stackRef
    }
  }

  _getTokens() {
    const { tokens } = this.props
    return {
      tokens,
      onPop: this._handlePop
    }
  }

  _handleAdd(type, action, parent, answer, delta, config) {
    this.props.onAddStep(type, action, parent, answer, delta, config)
  }

  _handleCancel(type) {
    if(type === 'new') this.props.onNewStep(null)
    if(type === 'edit') this.props.onEditStep(null)
  }

  _handleEdit() {
    const { active, blocks, steps } = this.props
    const step = _.find(steps, { code: active })
    const { type, action } = step
    const search = action ? { type, action } : { type }
    const block = _.find(blocks, search)
    if(!block.form) return
    this._handlePush(block.form, this._getEdit(step))
  }

  _handleNew() {
    this._handlePush(New, this._getNew())
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleTokens() {
    this._handlePush(Tokens, this._getTokens())
  }

  _handleUpdate(code, config) {
    this.props.onUpdateStep(code, config)
  }

}

export default SidebarContainer
