import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ account }) => {

  const details = {
    sections: [
      {
        title: 't(Account Details)',
        items: [
          { label: 't(Name)', content: account.full_name },
          { label: 't(Email)', content: account.email },
          { label: 't(Mobile Phone)', content: account.mobile_phone }
        ]
      }
    ]
  }

  if(account.is_blocked) {
    details.alert = { color: 'red', message: 't(This account has been blocked)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  account: PropTypes.object
}

export default Overview
