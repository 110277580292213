import React, { useEffect, useState } from 'react'
import Loader from '@admin/components/loader'
import PropTypes from 'prop-types'

const IFrame = (props) => {

  const { src, onLoading, onError, onLoad, ...additionalProps } = props

  const [status, setStatus] = useState('pending')

  const handleError = () => {
    setStatus('error')
    if(onError) onError()
  }

  const handleLoad = () => {
    setStatus('loaded')
    if(onLoad) onLoad()
  }

  useEffect(() => {
    setStatus('loading')
    if(onLoading) onLoading()
  }, [src])

  const mergedProps = {
    ...additionalProps,
    src,
    ...status !== 'loaded' ? {
      style: {
        display: 'none'
      }
    } : {},
    onError: handleError,
    onLoad: handleLoad
  }

  return (
    <>
      { status === 'loading' &&
        <Loader />
      }
      <iframe { ...mergedProps } />
    </>
  )

}

IFrame.propTypes = {
  src: PropTypes.string,
  onLoad: PropTypes.func,
  onLoading: PropTypes.func,
  onError: PropTypes.func
}

export default IFrame