import { DragSource, DropTarget } from 'react-dnd'
import PropTypes from 'prop-types'
import Children from './children'
import Item from './item'
import React from 'react'

class Child extends React.PureComponent {

  static propTypes = {
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDropTarget: PropTypes.func,
    can_drop: PropTypes.bool,
    collapsed: PropTypes.array,
    hover: PropTypes.object,
    index: PropTypes.string,
    is_dragging: PropTypes.bool,
    is_over: PropTypes.bool,
    item: PropTypes.object,
    padding: PropTypes.number,
    parent: PropTypes.object,
    selected: PropTypes.string,
    onCollapse: PropTypes.func,
    onHover: PropTypes.func,
    onMove: PropTypes.func,
    onSelect: PropTypes.func
  }

  state = {
    ref: null
  }

  _handleRef = this._handleRef.bind(this)

  render() {
    const { connectDropTarget, connectDragSource } = this.props
    const { hover, item } = this.props
    const { children } = item
    const show = this._getShow()
    const is_expanded = this._getIsExpanded()
    return connectDragSource(connectDropTarget(
      <div { ...this._getDraggable() }>
        { (show && hover.position === 'before') &&
          <div className="mjson-designer-outline-droptarget" />
        }
        <Item { ...this._getItem() } />
        { children && is_expanded &&
          <Children { ...this._getChildren() } />
        }
        { (show && hover.position === 'after') &&
          <div className="mjson-designer-outline-droptarget" />
        }
      </div>
    ))
  }

  _getChildren() {
    const { collapsed, hover, index, item, padding, selected, onCollapse, onHover, onMove, onSelect } = this.props
    const { children } = item
    return {
      children,
      collapsed,
      hover,
      index,
      item,
      padding,
      parent: item,
      selected,
      onCollapse,
      onHover,
      onMove,
      onSelect
    }
  }

  _getClass() {
    const { is_dragging } = this.props
    const classes = ['mjson-designer-outline-item-draggable']
    if(is_dragging) classes.push('dragging')
    return classes.join(' ')
  }

  _getDraggable() {
    return {
      className: this._getClass(),
      ref: this._handleRef
    }
  }

  _getIsExpanded() {
    const { item, collapsed } = this.props
    return !_.includes(collapsed, item.id)
  }

  _getItem() {
    const { item, collapsed, index, padding, selected, onCollapse, onSelect } = this.props
    return {
      ...item,
      collapsed,
      index,
      padding,
      selected,
      onCollapse,
      onSelect
    }
  }

  _getShow() {
    const { can_drop, hover, index, is_over } = this.props
    return hover && hover.target === index && hover.source !== index && is_over && can_drop
  }

  _handleRef(ref) {
    this.setState({ ref })
  }

}

const source = {
  beginDrag: (props) => ({
    id: props.item.id,
    entity: props.item.entity,
    index: props.index,
    parent: props.parent
  }),
  canDrag: (props, monitor) => {
    const source = props.item
    return source.editable !== false
  },
  endDrag: (props, monitor) => {
    if(!monitor.didDrop()) return
  }

}

const target = {
  canDrop: (props, monitor) => {
    const source = monitor.getItem()
    if(!source?.entity) return false
    const target = props.item
    if(!target?.dropTargets) return false
    return target.editable !== false && _.includes(target.dropTargets, source.entity)
  },
  drop: (props, monitor, component) => {
    const source = monitor.getItem()
    const parent = props.parent
    const index = parseInt(props.index.split('.').pop())
    const toIndex = index + (props.hover.position === 'after' ? 1 : 0)
    props.onMove(source.id, parent.id, toIndex)
  },
  hover: (props, monitor, component) => {
    if(!monitor.canDrop()) return
    const source = monitor.getItem().index
    const target = props.index
    const { ref } = component.state
    if (!ref) return    
    const hoverBoundingRect = ref.getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    if (Math.abs(hoverClientY - hoverMiddleY) < 5) return    
    const position = hoverClientY > hoverMiddleY ? 'after' : 'before'
    props.onHover({ source, target, position })
  }
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  is_dragging: monitor.isDragging(),
  can_drag: monitor.canDrag()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  is_over: monitor.isOver(),
  can_drop: monitor.canDrop()
})

Child = DragSource('ITEM', source, sourceCollector)(Child)
Child = DropTarget('ITEM', target, targetCollector)(Child)

export default Child
