import TextField from '@admin/components/form/textfield'
import PropTypes from 'prop-types'
import React from 'react'

class RoutingNumberField extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    number: '',
    bank: null
  }

  _handleLookup = _.debounce(this._handleLookup.bind(this), 250, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { bank } = this.state
    return (
      <div className="routing-number-field">
        <TextField { ...this._getTextField() } />
        { bank &&
          <div className="routing-number-field-bank">
            <div className="routing-number-field-bank-details">
              <strong>{ bank.customer_name }</strong><br />
              { bank.address }
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { bank, number } = this.state
    if(number !== prevState.number) {
      this._handleLookup()
    }
    if(!_.isEqual(bank, prevState.bank) && bank) {
      this._handleChange()
    }
  }

  _getTextField() {
    const { tabIndex } = this.props
    return {
      entity: 't(Routing Number)',
      tabIndex,
      onChange: this._handleUpdate
    }
  }

  _handleChange() {
    const { number, bank } = this.state
    this.props.onChange({
      number,
      bank_name: bank.customer_name
    })
  }

  _handleLookup() {
    const { number } = this.state
    if(number.length !== 9) return this._handleClear()
    this.context.network.request({
      endpoint: '/api/admin/finance/banks/lookup',
      method: 'GET',
      query: { number },
      onSuccess: ({ data }) => {
        this.setState({
          bank: data.customer_name ? data : null
        })
      }
    })
  }

  _handleClear() {
    this.setState({
      number: '',
      bank: null
    })
  }

  _handleUpdate(number) {
    this.setState({
      number: number || ''
    })
  }

}

export default RoutingNumberField
