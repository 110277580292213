import PropTypes from 'prop-types'
import React from 'react'

const LocationToken = (location) => {
  return (
    <div className="token">
      { location.address ?
        <>{location.address.city}, {location.address.region}</> :
        <>Unknown</>
      }
    </div>
  )
}

LocationToken.propTypes = {
  value: PropTypes.object
}

export default LocationToken
