import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import Assignment from '@admin/components/assignment'
import PropTypes from 'prop-types'
import React from 'react'

class Projects extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    user_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Assignment { ...this._getAssignment() } />
  }

  _getAssignment() {
    const { user_id } = this.props
    return {
      title: 't(Manage Projects)',
      action: `/api/admin/finance/users/${user_id}/memberships`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        assignments: assigned.map((assignee) => ({
          project_id: assignee.id,
          type: assignee.type
        })) 
      }),
      assigneeFormat: (project) => <CompactProjectToken project={ project } />,
      assignedEndpoint: `/api/admin/finance/users/${user_id}/memberships`,
      assignedFilter: (assigned) => assigned.map(assignee => ({
        ...assignee.project,
        type: assignee.type
      })),
      empty: {
        icon: 'folder',
        title: 't(Add projects)',
        text: 't(Please assign projects to this user)'
      },
      types: [
        { value: 'member' , text: 't(member)' },
        { value: 'approver', text: 't(approver)' },
        { value: 'owner', text: 't(owner)' }
      ],
      unassignedEndpoint: '/api/admin/finance/projects',
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.title.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          return assignment.id === assignee.id
        }) === undefined
      },
      onCancel: this._handleCancel,
      onDone: this._handleDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Projects
