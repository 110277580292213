import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

const SocialPublishingToken = ({ publishing }) => (
  <div className="team-token">
    <div className="team-token-logo">
      <Logo team={ publishing.subscription.advisor.team } />
    </div>
    <div className="team-token-label">
      { publishing.subscription.advisor.team.title }
      <div className="token-stats">
        <span>{ pluralize('Social Campaign', publishing.social_campaigns_count, true) } </span>
      </div>
    </div>
  </div>
)

SocialPublishingToken.propTypes = {
  publishing: PropTypes.object
}

export default SocialPublishingToken
