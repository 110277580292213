import ContactField from '@apps/domains/admin/components/contactfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Contacts extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    domain: {}
  }  

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { domain } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Registrant Contact)', name: 'registrant_contact', type: ContactField, required: true  },
            { label: 't(Admin Contact)', type: 'segment', fields: [
              { name: 'admin_strategy', type: 'radiogroup', options: [
                { value: 'registrant', text: 't(Use registrant contact)' },
                { value: 'custom', text: 't(Custom contact)' }
              ], defaultValue: 'registrant' },
              ...domain.admin_strategy === 'custom' ? [
                { name: 'admin_contact', type: ContactField  }
              ] : []
            ] },
            { label: 't(Technical Contact)', type: 'segment', fields: [
              { name: 'tech_strategy', type: 'radiogroup', options: [
                { value: 'registrant', text: 't(Use registrant contact)' },
                { value: 'custom', text: 't(Custom contact)' }
              ], defaultValue: 'registrant' },
              ...domain.tech_strategy === 'custom' ? [
                { name: 'tech_contact', type: ContactField  }
              ] : []
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(domain) {
    this.setState({ domain })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(domain) {
    this.props.onNext(domain)
  }

}

export default Contacts
