import Page from '@admin/components/page'
import Payments from './payments'
import Overview from './overview'
import React from 'react'

const getTabs = ({ audits, credit, payments }) => ({
  items: [
    { label: 't(Overview)', component: <Overview credit={ credit }/> },
    { label: 't(Applications)', component: <Payments credit={ credit } payments={ payments }/> }
  ]
})

const getTasks = ({ customer }) => ({
  items: []
})

const mapResourcesToPage = (props, context) => ({
  credit: `/api/admin/finance/credits/${props.params.id}`,
  payments: `/api/admin/finance/credits/${props.params.id}/payments`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Credit)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
