import Page from '@admin/components/page'
import Overview from './overview'
import Usage from './usage'
import Apps from './apps'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.team.title,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: `/admin/platform/teams/${resources.team.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview team={ resources.team } />
      },
      {
        label: 't(Apps)',
        path: '/apps',
        panel: <Apps team={ resources.team } />
      },
      {
        label: 't(Usage)',
        path: '/usage',
        panel: <Usage team={ resources.team } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  team: `/api/admin/platform/teams/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
