import ReimbursementShow from './reimbursements/show'
import ExpenseTypeShow from './expense_types/show'
import RevenueTypeShow from './revenue_types/show'
import ScholarshipShow from './scholarships/show'
import ChargebackShow from './chargebacks/show'
import AdvanceShow from './advances/show'
import DepositShow from './deposits/show'
import ExpenseShow from './expenses/show'
import InvoiceShow from './invoices/show'
import PaymentShow from './payments/show'
import ProjectShow from './projects/show'
import CreditShow from './credits/show'
import RefundShow from './refunds/show'
import VendorShow from './vendors/show'
import CheckShow from './checks/show'
import BankShow from './banks/show'
import TripShow from './trips/show'
import Finance from './show'

const routes = [
  { path: '/', component: Finance },
  { path: '/:view', component: Finance },
  { path: '/advances/:id', component: AdvanceShow },
  { path: '/banks/:id', component: BankShow },
  { path: '/chargebacks/:id', component: ChargebackShow },
  { path: '/checks/:id', component: CheckShow },
  { path: '/credits/:id', component: CreditShow },
  { path: '/deposits/:id', component: DepositShow },
  { path: '/expenses/:id', component: ExpenseShow },
  { path: '/expense_types/:id', component: ExpenseTypeShow },
  { path: '/invoices/:id', component: InvoiceShow },
  { path: '/payments/:id', component: PaymentShow },
  { path: '/projects/:id', component: ProjectShow },
  { path: '/refunds/:id', component: RefundShow },
  { path: '/reimbursements/:id', component: ReimbursementShow },
  { path: '/revenue_types/:id', component: RevenueTypeShow },
  { path: '/scholarships/:id', component: ScholarshipShow },
  { path: '/trips/:id', component: TripShow },
  { path: '/vendors/:id', component: VendorShow }
]

export default routes
