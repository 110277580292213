import { toFilter } from '@admin/components/criteria_builder/utils'
import InputTokens from '@admin/components/input_tokens'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Designer from './designer'
import pluralize from 'pluralize'
import React from 'react'

class CriteriaField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    cid: PropTypes.string,
    comment: PropTypes.any,
    contacts: PropTypes.array,
    criteria: PropTypes.array,
    defaultValue: PropTypes.object,
    entity: PropTypes.string,
    endpoint: PropTypes.string,
    format: PropTypes.any,
    fields: PropTypes.array,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    total: PropTypes.number,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onSetCriteria: PropTypes.func,
    onSetTotal: PropTypes.func
  }

  static defaultProps = {
    placeholder: 'Design criteria',
    entity: 'item',
    onChange: () => {},
    onReady: () => {}
  }

  _handleBegin = this._handleBegin.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleEnd = this._handleEnd.bind(this)
  _handleUpate = this._handleUpate.bind(this)

  render() {
    const { criteria, placeholder, tabIndex } = this.props
    return (
      <div className="maha-input" tabIndex={ tabIndex } onClick={ this._handleBegin }>
        <div className="maha-input-field">
          { criteria && criteria.length > 0 ?
            <InputTokens { ...this._getInputTokens() } /> :
            <div className="maha-input-placeholder">
              { placeholder }
            </div>
          }
        </div>
        { criteria && criteria.length > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { criteria, value } = this.props
    if(!_.isEqual(criteria, prevProps.criteria) && criteria) {
      this._handleFetch()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this.props.onSetCriteria(value)
    }
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDesigner() {
    const { comment, criteria, entity, endpoint, format, fields, title } = this.props
    return {
      comment,
      criteria,
      entity,
      endpoint,
      format,
      fields,
      title,
      onDone: this._handleUpate
    }
  }

  _getInputTokens() {
    const { entity, total } = this.props
    return {
      tokens: [entity],
      format: (entity) => pluralize(entity, total, true)
    }
  }

  _handleBegin() {
    this.context.form.push(Designer, this._getDesigner.bind(this))
  }

  _handleChange() {
    const { criteria } = this.props
    this.props.onChange(criteria)
  }

  _handleClear() {
    this.props.onSetCriteria(null)
  }

  _handleFetch() {
    const { criteria, endpoint } = this.props
    this.context.network.request({
      endpoint,
      method: 'GET',
      query: { 
        $filter: toFilter(criteria, null), 
        $page: { limit: 1 } 
      },
      onSuccess: ({ pagination }) => {
        this.props.onSetTotal(pagination.total)
        this._handleChange()
      },
      onFailure: () => {}
    })
  }

  _handleEnd() {
    this.context.form.pop()
  }

  _handleSet(value) {
    this.props.onSetCriteria(value)
  }

  _handleUpate(value) {
    this.props.onSetCriteria(value)
  }

}

export default CriteriaField
