import { CSSTransition } from 'react-transition-group'
import Container from '@admin/components/container'
import AppToken from '@admin/tokens/app'
import T from '@admin/components/t'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const actions = ['none','view','manage']

class AccessField extends React.Component {

  static contextTypes = {
    maha: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    apps: PropTypes.array,
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    rights: []
  }

  render() {
    const access = this._getAccess()
    if(!access) return null
    return (
      <div className="maha-accessfield">
        { access.map((app, appindex) => (
          <Fragment  key={`app_${appindex}`}>
            <div className={ this._getAppClass(app.code) } onClick={ this._handleApp.bind(this, app.code) }>
              <div className="maha-accessfield-app-label">
                <AppToken { ...app } />
              </div>
              <div className="maha-accessfield-app-input">
                <i className={ this._getAppIcon(app.code) } />
              </div>
            </div>
            { (app.entities.length + app.rights.length) > 0 &&
              <CSSTransition in={ this._getCan(`${app.code}:access_app`) } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
                <div className="maha-accessfield-body">
                  { app.entities.map((entity, entityindex) => (
                    <div className="maha-accessfield-right" key={`entity_${entityindex}`}>
                      <div className="maha-accessfield-right-label">
                        <T text={ entity.label } />
                      </div>
                      <div className="maha-accessfield-right-input">
                        <div className="maha-accessfield-right-actions">
                          { actions.map((action, actionindex) => (
                            <div { ...this._getAction(app.code, entity.code, action) } key={`action_${actionindex}`}>
                              { action }
                            </div>
                          )) }
                        </div>
                      </div>
                    </div>
                  ))}
                  { app.rights.map((right, rightindex) => (
                    <div className="maha-accessfield-right" key={`right_${rightindex}`}>
                      <div className="maha-accessfield-right-label">
                        <T text={ right.label } />
                      </div>
                      <div className="maha-accessfield-right-input">
                        <div className="maha-accessfield-right-actions">
                          <div { ...this._getRight(app.code, right.code, false) }>
                            disabled
                          </div>
                          <div { ...this._getRight(app.code, right.code, true) }>
                            enabled
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </CSSTransition>
            }
          </Fragment>
        )) }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { rights } = this.state
    const { value } = this.props
    if(rights !== prevState.rights) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getAccess() {
    const { maha } = this.context
    if(!this.props.apps) return null
    const apps = this.props.apps.reduce((apps,app) => ({
      ...apps,
      [app.code]: app
    }), {})
    return maha.access.filter(app => {
      return _.includes(Object.keys(apps), app.code) 
    }).map(app => ({
      ...app,
      icon: apps[app.code].icon
    }))
  }

  _getAction(app, entity, action) {
    return {
      className: this._getActionClass(app, entity, action),
      onClick: this._handleAction.bind(this, app, entity, action)
    }
  }

  _getActionClass(app, entity, action) {
    const view = this._getCan(`${app}:view_${entity}`)
    const manage = this._getCan(`${app}:manage_${entity}`)
    const classes = ['maha-accessfield-right-action']
    if(action === 'none' && !view && !manage) classes.push('active')
    if(action === 'view' && view) classes.push('active')
    if(action === 'manage' && manage) classes.push('active')
    return classes.join(' ')
  }

  _getAppClass(app) {
    const classes = ['maha-accessfield-app']
    if(this._getCan(`${app}:access_app`)) classes.push('active')
    return classes.join(' ')
  }

  _getAppIcon(app) {
    return this._getCan(`${app}:access_app`) ? 'fa fa-toggle-on' : 'fa fa-toggle-off'
  }

  _getCan(right) {
    const { rights } = this.state
    return _.includes(rights, right)
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getRight(app, right, enabled) {
    return {
      className: this._getRightClass(app, right, enabled),
      onClick: this._handleRight.bind(this, app, right)
    }
  }

  _getRightClass(app, right, enabled) {
    const classes = ['maha-accessfield-right-action']
    if(this._getCan(`${app}:${right}`) === enabled) classes.push('active')
    return classes.join(' ')
  }

  _handleAction(app, entity, action) {
    const { rights } = this.state
    const view = `${app}:view_${entity}`
    const manage = `${app}:manage_${entity}`
    this.setState({
      rights: [
        ...rights.filter(right => !_.includes([view, manage], right)),
        ...action === 'manage' ? [manage] : action === 'view' ? [view] : []
      ]
    })
  }

  _handleApp(app) {
    const { rights } = this.state
    const right = `${app}:access_app`
    this.setState({
      rights: _.xor(rights, [right])
    })
    if(!_.includes(rights, right)) return
    setTimeout(() => this.setState({
      rights: rights.filter(right => !right.startsWith(`${app}:`))
    }), 250)
  }

  _handleChange() {
    const { rights } = this.state
    this.props.onChange(rights.sort((a,b) => {
      return a > b ? 1 : -1
    }))
  }

  _handleClearEntity(app, entity) {
    const { rights } = this.state
    const all = actions.map(a => `${app}:${a}_${entity}`)
    this.setState({
      rights: rights.filter(right => !_.includes(all, right))
    })
  }

  _handleClearRight(app, right) {
    const { rights } = this.state
    this.setState({
      rights: rights.filter(r => r !== `${app}:${right}`)
    })
  }

  _handleRight(app, right) {
    const { rights } = this.state
    this.setState({
      rights: _.xor(rights, [`${app}:${right}`])
    })
  }

  _handleSet(rights) {
    this.setState({ rights })
  }

}

const mapResources = (props, context) => ({
  apps: '/api/admin/team/apps'
})

export default Container(mapResources)(AccessField)

