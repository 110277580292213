import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class ContactField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    contact: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.contact) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { contact } = this.state
    const { value } = this.props
    if(!_.isEqual(contact, prevState.contact)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { contact } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 'Address', name: 'address', type: 'addressfield', value: contact.address },
            { label: 'Email', name: 'email', type: 'emailfield', value: contact.email },
            { label: 'Phone', name: 'phone', type: 'phonefield', value: contact.phone },
            { label: 'Website', name: 'website', type: 'urlfield', value: contact.website }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { contact } = this.state
    this.props.onChange({ contact })
  }

  _handleSet(contact) {
    this.setState({
      contact: {
        address: '',
        phone: '',
        email: '',
        ...contact || {}
      }
    })
  }

  _handleUpdate(contact) {
    this.setState({ contact })
  }

}

export default ContactField
