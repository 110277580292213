import Page from '@admin/components/page'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const getTabs = ({ app, audits, revenue_type }) => ({
  items: [
    { label: 't(Overview)', component: <Overview revenue_type={ revenue_type } integration={ app.settings.integration } /> }
  ]
})

const getTasks = ({ app, revenue_type }) => ({
  items: [
    { label: 'Edit Revenue Type', show: revenue_type.type === 'custom', modal: <Edit revenue_type={ revenue_type } integration={ app.settings.integration  } /> }
  ]
})

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  revenue_type: `/api/admin/finance/revenue_types/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Revenue Type)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
