export const onSetBusy = (key, value) => ({
  type: 'SET_BUSY' ,
  key,
  value
})

export const onSetData = (data) => ({
  type: 'SET_DATA',
  data
})

export const onSetReady = (key) => ({
  type: 'SET_READY',
  key
})

export const onSetStatus = (status) => ({
  type: 'SET_STATUS',
  status
})

export const onSetValid = (key, value, errors) => ({
  type: 'SET_VALID',
  key,
  value,
  errors
})

export const onRemoveField = (name) => ({
  type: 'REMOVE_FIELD',
  name
})

export const onUpdateData = (key, value) => ({
  type: 'UPDATE_DATA',
  key,
  value
})

export const onValidate = () => ({
  type: 'VALIDATE'
})
