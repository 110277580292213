import RefundStrategyToken from '@apps/finance/admin/tokens/refund_strategy'
import AmountField from '@apps/finance/admin/components/amountfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Refund extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object,
    registration: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event, registration } = this.props
    const strategies = this._getStrategies()
    const { config } = this.state
    return {
      title: 't(Cancel Registration)',
      action: `/api/admin/events/events/${event.id}/registrations/${registration.id}/cancel`,
      method: 'PATCH',
      saveText: 'Proceed',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Reason)', name: 'cancellation_reason', type: 'textfield', placeholder: 't(Enter a reason for canceling registration)', required: true },
            { label: 'Refund', name: 'refund_strategy', type: 'radiogroup', options: [
              { value: 'nothing', text: 't(Do not issue refund)' },
              { value: 'refund', text: 't(Issue refund)' }
            ], defaultValue: 'nothing', required: true },
            ...config.refund_strategy === 'refund' ? [
              { label: 't(Refund Type)', name: 'refund_type', type: 'radiogroup', deselectable: false, options: strategies, format: RefundStrategyToken, required: true, defaultValue: strategies[0] },
              { label: 't(Amount)', name: 'amount', type: AmountField, action: 'Refund', required: true, balance: registration.payment.amount }
            ] : []
          ]
        }
      ]
    }
  }

  _getStrategies() {
    const { registration } = this.props
    const { method } = registration.payment
    const strategies = []
    if(method === 'ach') {
      strategies.push('ach')
    } else if(method === 'paypal') {
      strategies.push('paypal')
    } else if(_.includes(['card','googlepay','applepay'], method)) {
      strategies.push('card')
    }
    strategies.push('credit')
    return strategies
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(event) {
    this.context.modal.close()
  }

}

export default Refund
