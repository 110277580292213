import PropertiesShow from './properties/show'
import TemplatesShow from './templates/show'
import ProfilesShow from './profiles/show'
import ProgramsShow from './programs/show'
import RenewalShow from './renewals/show'
import TeamShow from './team/show/index'
import SendersShow from './senders/show'
import ContentShow from './content/show'
import ThemesShow from './themes/show'
import RolesShow from './roles/show'
import UsageShow from './usage/show'
import UserShow from './users/show'
import TagShow from './tags/show'

const routes = [
  { path: '/:view', component: TeamShow },
  { path: '/content/:id/:view', component: ContentShow },
  { path: '/profiles/:id/:view', component: ProfilesShow },
  { path: '/programs/:id/:view', component: ProgramsShow },
  { path: '/properties/:id/:view', component: PropertiesShow },
  { path: '/renewals/:id', component: RenewalShow },
  { path: '/roles/:id/:view', component: RolesShow },
  { path: '/senders/:id/:view', component: SendersShow },
  { path: '/tags/:id/:view', component: TagShow },
  { path: '/themes/:id/:view', component: ThemesShow },
  { path: '/templates/:id/:view', component: TemplatesShow },
  { path: '/users/:id/:view', component: UserShow },
  { path: '/usage/:id/:view', component: UsageShow }
]

export default routes
