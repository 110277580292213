import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'
import Step from './step'

class CallActivities extends React.PureComponent {

  static propTypes = {
    call: PropTypes.object
  }

  state = {
    counter: 0
  }

  _handleTimer = this._handleTimer.bind(this)

  render() {
    const { call } = this.props
    const started_at = moment(call.started_at)
    return (
      <div className="phone-call-activities">
        <div className="phone-call-activities-activity">
          <Icon icon="circle" />
          <span>
            <Format value={ moment(call.started_at).diff(started_at, 'second') } format="duration" />
          </span>
          Call was initiated
        </div>
        { call.activities.map((activity, index) => (
          <div className="phone-call-activities-activity" key={`activity_${index}`}>
            <Icon icon="circle" />
            <span>
              <Format value={ moment(activity.created_at).diff(started_at, 'second') } format="duration" />
            </span>
            { activity.type === 'step' ?
              <Step step={ activity.data} /> :
              <>{ this._getDescription(activity) }</>
            }

          </div>
        )) }
        { call.status === 'in-progress' &&
          <div className="phone-call-activities-activity active">
            <Icon icon="circle" />
            <span>
              <Format value={ moment().diff(started_at, 'second') } format="duration" />
            </span>
            Call is active
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this._handleTimer()
  }

  _getDescription(activity) {
    const parts = []
    if(activity.contact) parts.push(activity.contact.display_name)
    if(activity.user) parts.push(activity.user.full_name)
    parts.push(this._getType(activity))
    return parts.join(' ')
  }

  _getType(activity) {
    const { data, to_user, type } = activity
    const device = activity.device === 'mobile' ? 'mobile' : 'Maha'
    if(type === 'no-answer') return `did not answer the call on ${device} phone`
    if(type === 'transfer-back') return `Call was transferred back to ${to_user.full_name}`
    if(type === 'forward-back') return `Call was forwarded back to ${to_user.full_name}`
    if(type === 'answered') return `answered the call on ${device} phone`
    if(type === 'hold') return 'placed the call on hold'
    if(type === 'unhold') return 'took the call off hold'
    if(type === 'hangup') return 'hung up the call'
    if(type === 'transfer') return `transferred the call to ${to_user.full_name}`
    if(type === 'forward') return `forwarded the call to ${device} phone`
    if(type === 'step') return this._getStep(data)
  }

  _getStep(data) {
    const { key, text, verb } = data
    if(verb === 'say') return `said '${text}'`
    if(verb === 'play') return `played '${key}'`
  }

  _handleTimer() {
    const { counter } = this.state
    const { call } = this.props
    if(call.status !== 'in-progress') return
    this.setState({
      counter: counter + 1
    })
    setTimeout(this._handleTimer, 1000)
  }

}

export default CallActivities
