import ImportEmail from '@apps/email/admin/views/emails/import'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Import extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <>
        { email.import_thread_id ?
          <div className="maha-email-status imported">
            This thread has been imported into Maha. <Button { ...this._getThread() } />
          </div> :
          <div className="maha-email-status unimported">
            This thread has not yet been imported into Maha. <Button { ...this._getImport() } />
          </div>
        }
      </>
    )
  }

  _getThread() {
    const { email } = this.props
    return {
      label: 'View thread',
      className: 'link',
      route: `/admin/email/threads/${email.import_thread_id}`
    }
  }

  _getImport() {
    const { email } = this.props
    return {
      label: 'Import thread',
      className: 'link',
      modal: <ImportEmail profile_id={ email.profile_id } thread_id={ email.thread_id } />
    }
  }

}

export default Import
