import EmailTemplateToken from '@apps/truevail/admin/tokens/email_template'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Templates)',
  collection: {
    endpoint: '/api/admin/truevail/admin/email_templates',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Template)', key: 'title', primary: true, format: (email_template) => (
        <EmailTemplateToken email_template={ email_template } />
      ) },
      { label: 't(Type)', key: 'type', collapsing: true, format: (email_template) => email_template.type.toUpperCase() },
      { label: 't(Library)', key: 'status', collapsing: true, format: LibraryToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] },
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: LibraryToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Templates)',
      text: 't(You have not yet have any email templates)',
      buttons: [
        { label: 't(Create Email Template)', modal: New }
      ]
    },
    entity: 'email template',
    recordTasks: (record) => [
      {
        label: 't(Clone Email Template)',
        modal: <Clone email_template={ record } />
      },
      {
        label: 't(Publish to Library)',
        show: record.status === 'draft',
        confirm: 't(Are you sure you want to publish this template?)',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish email template)')
        }
      },
      {
        label: 't(Unpublish from Library)',
        show: record.status === 'published',
        confirm: 't(Are you sure you want to unpublish this template?)',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish email template)')
        }
      },
      {
        label: 't(Delete Email Template)',
        confirm: 't(Are you sure you want to delete this email template?',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete email template)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/email_templates/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
