import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Communication extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { program } = this.props.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true, defaultValue: program.default_sender ? program.default_sender.id : null },
            { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/team/programs/${program.id}/templates`, valueKey: 'id', textKey: 'title', required: true, defaultValue: program.default_template ? program.default_template.id : null }
          ]
        },
        {
          label: 't(Subscription Created)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'subscription_created.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Thank you for your order' },
            { label: 't(Body)', name: 'subscription_created.body', type: 'htmlfield', emojis: true, required: true, defaultValue:`
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>Thank you for your subscription!  Your card ending in <%- subscription.payment_method.last_four %>
              will be charged <%- subscription.frequency %>.</p>
            ` }
          ]
        },
        {
          label: 't(Invoice Created)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'invoice_created.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Your subscription invoice' },
            { label: 't(Body)', name: 'invoice_created.body', type: 'htmlfield', emojis: true, required: true, defaultValue:`
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>Thank you for your subscription!  Your invoice is ready.</p>
            ` }
          ]
        },
        {
          label: 't(Renewal Success)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'renewal_success.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Your subscription has been renewed' },
            { label: 't(Body)', name: 'renewal_success.body', type: 'htmlfield', emojis: true, required: true, defaultValue: `
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>Your subscription has been successfully renewed.</p>
              <p>&nbsp;</p>
              <p><%- renewal.payment_summary %></p>
            ` }
          ]
        },
        {
          label: 't(Renewal Failure)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'renewal_failure.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'We were unable to renew your subscription' },
            { label: 't(Body)', name: 'renewal_failure.body', type: 'htmlfield', emojis: true, required: true, defaultValue: `
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>We were unable to charge your card ending in <%- subscription.payment_method.last_four %>
              and renew your subscription. We will try again tomorrow.</p>
            ` }
          ]
        },
        {
          label: 't(Payment Method Expiring)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'payment_method_expiring.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Your card on file is expiring' },
            { label: 't(Body)', name: 'payment_method_expiring.body', type: 'htmlfield', emojis: true, required: true, defaultValue: `
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>The card we have on file ending in <%- subscription.payment_method.last_four %>
              will expire on <%- subscription.payment_method.expiration_date %>. Please update
              your payment method</p>
            ` }
          ]
        },
        {
          label: 't(Payment Method Expired)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'payment_method_expired.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Your card on file has expired' },
            { label: 't(Body)', name: 'payment_method_expired.body', type: 'htmlfield', emojis: true, required: true, defaultValue: `
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>The card we have on file ending in <%- subscription.payment_method.last_four %>
              has expired. Please update your payment method</p>
            ` }
          ]
        },
        {
          label: 't(Subscription Canceled)',
          collapsing: true,
          collapsed: true,
          fields: [
            { label: 't(Subject)', name: 'subscription_canceled.subject', type: 'textfield', emojis: true, required: true, defaultValue: 'Your subscription has been cancelled' },
            { label: 't(Body)', name: 'subscription_canceled.body', type: 'htmlfield', emojis: true, required: true, defaultValue: `
              <p><%- contact.first_name %>,</p>
              <p>&nbsp;</p>
              <p>Sorry to see you go!</p>
            ` }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(service) {
    this.props.onSave(service)
  }

}

export default Communication
