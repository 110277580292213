import AssetThumbnail from '@admin/components/asset_thumbnail'
import Message from '@admin/components/message'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Multiple extends React.Component {

  static propTypes = {
    files: PropTypes.array
  }

  render() {
    const { files } = this.props
    return (
      <div className="maha-attachments-multiple">
        { files.length > 0 ?
          <div className="maha-attachments-list-items">
            { files.map((file, index) => (
              <div className="maha-attachments-list-item" key={`plain_${index}`}>
                <div className="maha-attachments-list-item-icon">
                  <AssetThumbnail { ...file } />
                  <div className="maha-attachments-list-item-service">
                    <Img src={ `/images/services/${file.service}.png` } />
                  </div>
                </div>
                <div className="maha-attachments-list-item-name">
                  { file.name }<br />
                  <span className={ file.status }>
                    <Icon icon={`${this._getIcon(file.status)}`} /> { _.capitalize(file.status) } { file.status === 'uploading' &&
                      <span>({ numeral(file.progress).format('0%') })</span>
                    }
                  </span>
                </div>
              </div>
            )) }
          </div> :
          <Message { ...this._getEmpty() } />
        }
      </div>
    )
  }

  _getThumbail() {
    
  }

  _getEmpty() {
    return {
      icon: 'file-o',
      title: 't(No Files)',
      text: 't(You have not selected any files)'
    }
  }

  _getIcon(status) {
    if(status === 'pending') return 'clock-o'
    if(_.includes(['importing','uploading','processing'], status)) return 'circle-o-notch fa-spin'
    if(status === 'complete') return 'check'
    if(status === 'failed') return 'exclamation-triangle'
  }

}

export default Multiple
