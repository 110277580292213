import MultiForm from '@admin/components/multiform'
import Campaigns from './campaigns'
import PropTypes from 'prop-types'
import Details from './details'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Import Social Campaigns)',
      action: '/api/admin/truevail/agency/social_campaigns',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    return {
      social_campaign_ids: formdata.social_campaigns.map(social_campaign => {
        return social_campaign.id
      }),
      voice: formdata.voice,
      language: formdata.language,
      perspective: formdata.perspective,
      status: formdata.status
    }
  }

  _getSteps() {
    return [
      { label: 't(Campaigns)', component: Campaigns },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

export default Import
