import ProductShow from './stores/products/show'
import OrderShow from './stores/orders/show'
import CartShow from './stores/carts/show'
import StoreList from './stores/list'
import StoreShow from './stores/show'

const routes = [
  { path: '/', component: StoreList },
  { path: '/stores', component: StoreList },
  { path: '/stores/:id/:view', component: StoreShow },
  { path: '/stores/:store_id/products/:id', component: ProductShow },
  { path: '/stores/:store_id/carts/:id', component: CartShow },
  { path: '/stores/:store_id/orders/:id', component: OrderShow }
]

export default routes
