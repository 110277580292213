import Form from '@admin/components/form'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class FlexChildField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    omitFeatures: PropTypes.array,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.config) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            {
              label: 't(Sizing)',
              type: 'segment',
              fields: [
                {
                  name: 'sizing', 
                  type: 'dropdown', 
                  options: [
                    { value: 'default', text: 't(Default)' },
                    { value: 'shrink', text: 't(Shrink if needed)' },
                    { value: 'grow', text: 't(Grow if possible)' },
                    { value: 'none', text: 't(Dont shrink or grow)' },
                    { value: 'custom', text: 't(Custom grow and shrink behavior)' }
                  ],
                  defaultValue: 'default', 
                  value: config.sizing 
                },
                { 
                  label: 't(Grow)',
                  name: 'grow', 
                  type: WebRange,
                  units: [
                    { name: '', min: 0, max: 1000, step: 1 }
                  ],
                  nullValues: ['0'],
                  defaultValue: null, 
                  value: config.grow , 
                  show: config.sizing === 'custom'
                },
                { 
                  label: 't(Shrink)',
                  name: 'shrink', 
                  type: WebRange,
                  units: [
                    { name: '', min: 0, max: 1000, step: 1 }
                  ],
                  nullValues: ['0'],
                  defaultValue: null, 
                  value: config.shrink,
                  show: config.sizing === 'custom'
                },
                { 
                  label: 't(Basis)',
                  name: 'basis', 
                  type: WebRange,
                  units: [
                    { name: 'px', min: 0, max: 1000, step: 1 }
                  ],
                  nullValues: ['auto'],
                  defaultValue: null, 
                  value: config.basis,
                  show: config.sizing === 'custom'
                }
              ]
            },
            {
              label: 't(Order)',
              type: 'segment',
              fields: [
                { 
                  name: 'order', 
                  type: 'dropdown', 
                  options: [
                    { value: 'default', text: 't(Default)' },
                    { value: 'first', text: 't(First)' },
                    { value: 'last', text: 't(Last)' },
                    { value: 'custom', text: 't(Custom Order)' }
                  ], 
                  defaultValue: 'default', 
                  value: config.order 
                },
                { 
                  label: 't(Order)',
                  name: 'order_value',
                  type: 'numeric',
                  defaultValue: 1, 
                  value: config.order_value , 
                  show: config.order === 'custom'
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { config } = this.state
    const sizing = config.sizing || 'default'
    const order = config.order || 'default'
    this.props.onChange((sizing !== 'default' || order !== 'default') ? {
      ...config,
      ...config.sizing === 'custom' ? {
        grow: config.grow || '0',
        shrink: config.shrink || '0',
        basis: config.basis || 'auto'
      } : {}
    } : null)
  }

  _handleSet(config) {
    this.setState({ 
      config: config || {} 
    })
  }

}

export default FlexChildField
