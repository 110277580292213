import Message from '@admin/components/message'
import { canAccess } from '@core/utils/access'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Item from './item'
import React from 'react'

class Section extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    component: PropTypes.any,
    content: PropTypes.any,
    empty: PropTypes.any,
    instructions: PropTypes.string,
    items: PropTypes.array,
    padded: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.any
  }

  render() {
    const { component, content, empty, instructions, title, subtitle } = this.props
    const items = this._getItems()
    return (
      <div className={ this._getClass() }>
        { title &&
          <div className="maha-details-title">
            <T text={ title } />
          </div>
        }
        { subtitle &&
          <div className="maha-details-subtitle">
            <T text={ subtitle } />
          </div>
        }
        { instructions &&
          <div className="maha-details-instructions">
            <T text={ instructions } />
          </div>
        }
        { component &&
          _.isFunction(component) ? React.createElement(component, content) : component
        }
        { content &&
          <div className="maha-details-item">
            <div className="maha-details-item-content">
              <div className="maha-details-item-content-value">
                { content }
              </div>
            </div>
          </div>
        }
        { items && items.length > 0 &&
          items.map((item, itemIndex) => (
            <Item key={`list_item_${itemIndex}`} { ...item } />
          ))
        }
        { empty && items && items.length === 0 &&
          <div className="maha-details-item">
            { _.isPlainObject(empty) ?
              <Message { ...empty } /> :
              <div className="maha-details-item-content">
                <div className="maha-details-item-content-value">
                  { this._getComponent(empty) }
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }

  _getClass() {
    const { padded } = this.props
    const classes = ['maha-details-section']
    if(padded !== false) classes.push('padded')
    return classes.join(' ')
  }

  _getComponent(component) {
    if(_.isString(component)) return <T text={ component } />
    return _.isFunction(component) || !!component.WrappedComponent ? React.createElement(component, this.props) : component
  }

  _getItems() {
    const { admin } = this.context
    const { items } = this.props
    return items ? items.filter(item => {
      const show = item.show !== false
      const access = item.access ? canAccess(item.access, admin) : true
      return show && access
    }) : []
  }

}

export default Section
