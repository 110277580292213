import { useLocalStorageContext } from '@admin/components/local_storage'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useTasksContext } from '@admin/components/tasks'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'
import Grid from './grid'

const GridContainer = (props) => {

  const { local_storage } = useLocalStorageContext()
  const { tasks } = useTasksContext()

  const [state, handlers] = useActionReducer({
    display_name: 'grid',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      local_storage,
      tasks
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Grid { ...mergedProps } />

}

GridContainer.propTypes = {}

export default GridContainer