import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ deal }) => (
  <>{ deal ? deal.pipline.title : '' }</>
)

Token.propTypes = {
  deal: PropTypes.object
}

export default Token
