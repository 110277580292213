import Avatar from '@admin/components/avatar'
import Img from '@admin/components/html/img'
import Logo from '@admin/components/logo'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Item extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.string,
    item: PropTypes.object,
    user: PropTypes.object,
    onClick: PropTypes.func
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    const { item, context } = this.props
    const color = item.app.color || 'blue'
    const icon = item.app.icon || 'user'  
    return (
      <a className={ this._getClass() } onClick={ this._handleClick }>
        <div className="maha-feed-item-avatar">
          { this._getImage(item) }
        </div>
        <div className="maha-feed-item-details">
          <div className="maha-feed-item-story">
            <span dangerouslySetInnerHTML={ this._getDescription() } />
            { item.team &&
              <div className="maha-feed-item-team">
                <Logo team={ item.team } />
                { item.team.title }
              </div>
            }
          </div>
          <div className="maha-feed-item-timestamp">
            <div className={`maha-feed-item-app-icon ${color}`}>
              <Icon icon={`${icon}`} />
            </div>
            { this._getRelativeTime(item.created_at) }
          </div>
        </div>
        { context === 'feed' && item.url && item.is_visited === false &&
          <div className="maha-feed-item-icon">
            <Icon icon="circle" />
          </div>
        }
      </a>
    )
  }

  _getClass() {
    const { item } = this.props
    let classes = ['maha-feed-item', item.app.color]
    return classes.join(' ')
  }

  _getDescription() {
    const { story } = this.props.item
    const { subject_full_name, object_article, object_type, object_text} = story.bindings
    let __html = story.text || ''
    if(subject_full_name) __html = __html.replace('{subject_full_name}', `<span class="maha-feed-item-object">${subject_full_name}</span>`)
    if(object_article) __html = __html.replace('{object_article}', object_article)
    if(object_type) __html = __html.replace('{object_type}', object_type)
    if(object_text) __html = __html.replace('{object_text}', `<span class="maha-feed-item-object">${object_text}</span>`)
    __html = __html.replace('{object}', '')
    return { __html }
  }

  _getImage() {
    const { image, team, subject } = this.props.item
    const { provider } = this.context
    if(team) return <Logo team={ team } />
    if(subject) return <Avatar user={ subject } presence={ false } />
    if(image) return <Img src={ image } />
    return <Logo team={ provider } />
  }

  _getRelativeTime(datetime) {
    const now  = moment()
    const timestamp = moment(datetime)
    const minutes_ago = now.diff(timestamp, 'minutes')
    if(minutes_ago === 0) return 'a few seconds ago'
    if(minutes_ago === 1) return '1 minute ago'
    if(minutes_ago < 60) return `${minutes_ago} minutes ago`
    return timestamp.format('hh:mm A')
  }

  _handleClick() {
    this.props.onClick()
  }

}

export default Item
