import Page from '@admin/components/page'
import Overview from './overview'
import Records from './records'
import Fields from './fields'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.type.title.value,
  manager: {
    path: `/admin/datasets/${resources.dataset.id}/types/${resources.type.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview dataset={ resources.dataset } type={ resources.type } />
      },
      {
        label: 't(Fields)',
        path: '/fields',
        show: resources.dataset.access_type === 'manage',
        panel: <Fields dataset={ resources.dataset } type={ resources.type } />
      },
      {
        label: 't(Records)',
        path: '/records',
        panel: <Records dataset={ resources.dataset } type={ resources.type } />
      // },
      // {
      //   label: 't(Access)',
      //   path: '/access',
      //   panel: <TypeAccess dataset={ resources.dataset } type={ type } />
      // },
      // {
      //   label: 'Forms',
      //   path: '/forms',
      //   panel: <Forms dataset={ resources.dataset } type={ type } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  dataset: `/api/admin/datasets/datasets/${props.params.dataset_id}`,
  type: `/api/admin/datasets/datasets/${props.params.dataset_id}/types/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
