import { useEffect, useMemo, useReducer, useRef } from 'react'
import { createLogger } from '@core/utils/console'

const logAction = createLogger('yellow') 

const useActionReducer = (params) => {

  const { props } = params

  const display_name = params.display_name || 'reducer'
  const actions = params.actions || {}
  const reducer = params.reducer ? params.reducer : (state) => state
  const selectors = params.selectors || {}

  const isMountedRef = useRef(true)
  useEffect(() => {
    return () => isMountedRef.current = false
  }, [])

  const wrappedReducer = useMemo(() => (prevState, action) => {
    const nextState = reducer(prevState, action)
    const display_type = params.display_name ? `${display_name}/${action.type}` : action.type
    logAction(display_type, { prevState, action, nextState })
    return nextState
  }, [reducer])

  const initialState = useMemo(() => ({
    ...reducer(undefined, {}) || {},
    ...params.initialState || {}
  }), [])

  const [state, dispatch] = useReducer(wrappedReducer, initialState)
 
  const merged = Object.keys(selectors).reduce((state2, key) => ({
    ...state2,  
    [key]: selectors[key](state2, props)
  }), state)

  const handlers = useMemo(() => Object.keys(actions).reduce((handlers, handle) => ({
    ...handlers,
    [handle]: (...args) => {
      if(!isMountedRef.current) return
      return dispatch(actions[handle](...args))
    }
  }), {}), [actions])

  return [merged, handlers]

}

export default useActionReducer