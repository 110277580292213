import Input from '@admin/components/html/input'
import { DropTarget } from 'react-dnd'
import Recipient from './recipient'
import PropTypes from 'prop-types'
import React from 'react'

class Field extends React.PureComponent {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    id: PropTypes.string,
    isFull: PropTypes.bool,
    q: PropTypes.string,
    recipients: PropTypes.array,
    tabIndex: PropTypes.number,
    onAdd: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onType: PropTypes.func
  }

  inputRef = React.createRef()

  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { connectDropTarget, isFull, recipients } = this.props
    return connectDropTarget(
      <div className="maha-recipientfield-recipients">
        { recipients.map((recipient, index) => (
          <Recipient { ...this._getRecipient(recipient, index) } key={`recipient_${index}`} />
        ))}
        { !isFull &&
          <Input { ...this._getInput() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const { q } = this.props
    if(q.length === 0) return
    this._handleFocus()
  }

  componentDidUpdate(prevProps) {
    const { recipients } = this.props
    if(recipients.length > prevProps.recipients.length) {
      this._handleFocus()
    }
  }

  _getInput() {
    const { q, recipients, tabIndex } = this.props
    return {
      className: 'maha-recipientfield-field',
      ref: this.inputRef,
      tabIndex,
      type: 'email',
      autoCapitalize: 'off',
      value: q,
      onChange: this._handleType,
      onKeyDown: this._handleKeyDown
    }
  }

  _getRecipient(recipient, index) {
    const { id, isFull, onAdd, onMove, onRemove } = this.props
    return {
      id,
      index,
      isFull,
      recipient,
      onAdd,
      onMove,
      onRemove
    }
  }

  _handleBackspace() {
    const { q, recipients } = this.props
    if(q.length > 0 || recipients.lenth === 0) return
    this.props.onRemove(recipients.length - 1)
  }

  _handleFocus() {
    if(this.props.isFull) return
    this.inputRef.current.focus()
  }

  _handleKeyDown(e) {
    if(e.key === 'Backspace') this._handleBackspace()
    if(e.key === 'Enter') this._handleEnter()
  }

  _handleEnter() {
    const { q } = this.props
    this.props.onAdd({
      name: null,
      address: q
    })
  }

  _handleType(value) {
    this.props.onType(value)
  }

}

const target = {
  drop: (props, monitor, component) => {
    if(monitor.didDrop() && monitor.getDropResult().action) return
    const item = monitor.getItem()
    if(props.isFull) return { action: false }
    if(props.id === item.id) {
      const index = props.recipients.length - 1
      if(item.index === index) return { action: false }
      props.onMove(item.index, index)
      return { action: 'moved' }
    }
    props.onAdd(item.recipient)
    return { action: 'added' }
  }
}

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop()
})

Field = DropTarget('RECIPIENT', target, targetCollector)(Field)

export default Field
