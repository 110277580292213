import useActionReducer from '@admin/hooks/use_action_reducer'
import CriteriaBuilder from './criteria_builder'
import * as selectors from './selectors'
import * as actions from './actions'
import reducer from './reducer'
import React from 'react'

const CriteriaBuilderContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'criteria_builder',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <CriteriaBuilder { ...mergedProps } />

}

CriteriaBuilderContainer.propTypes = {}

export default CriteriaBuilderContainer