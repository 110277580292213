import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const reasons = {
  done: 't(I no longer want to receive these emails)',
  never: 't(I never signed up for this mailing list)',
  inappropriate: 't(The emails are inappropriate)',
  spam: 't(The emails are spam and should be reported)',
  other: 't(Other)'
}

const OptOutToken = ({ value }) => {
  const reason = reasons[value]
  return (
    <div className="token">
      <T text={ reason } />
    </div>
  )
}

OptOutToken.propTypes = {
  value: PropTypes.string
}

export default OptOutToken
