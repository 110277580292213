import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Complete extends React.Component {

  static propTypes = {
    account: PropTypes.object,
    onSignin: PropTypes.func
  }

  _handleSignin = this._handleSignin.bind(this)

  render() {
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon="check-circle" /></h1>
            <h2><T text="t(Congratulations)" /></h2>
            <p><T text="t(Your password was successfully reset!)" /></p>
            <div className="field button-field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getContinue() {
    return {
      label: 't(Continue)',
      color: 'blue',
      size: 'large',
      handler: this._handleSignin
    }
  }

  _handleSignin() {
    const { account } = this.props
    this.props.onSignin(account)
  }

}

export default Complete
