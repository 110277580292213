export const onClear = () => ({
  type: 'CLEAR'
})

export const onPush = (code, notification) => ({
  type: 'PUSH',
  code,
  notification
})

export const onRemove = (code) => ({
  type: 'REMOVE',
  code
})
