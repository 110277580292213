import useActionReducer from '@admin/hooks/use_action_reducer'
import { useAdminContext } from '@admin/components/admin'
import * as selectors from './selectors'
import PropTypes from 'prop-types'
import Comment from './comment'
import React from 'react'

const CommentContainer = (props) => {

  const { admin } = useAdminContext()

  const [state] = useActionReducer({
    display_name: 'comment',
    props,
    selectors   
  })

  const mergedProps = {
    context: {
      admin
    },
    ...props,
    ...state.sorted
  }

  return <Comment { ...mergedProps } />

}

CommentContainer.propTypes = {}

export default CommentContainer