import MJSONPreview from '@admin/components/mjson_preview'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Reschedule from '../reschedule'
import PropTypes from 'prop-types'
import Clone from '../clone'
import moment from 'moment'
import Send from '../send'
import Edit from '../edit'
import React from 'react'

const Overview = ({ email_campaign }, { admin }) => {

  const timestamp = moment(email_campaign.screenshoted_at).format('x')
  const public_link = `${admin.team.asset_host}/campaigns/email/${timestamp}/${email_campaign.code}`

  const program = {
    label: email_campaign.program.title,
    className: 'link',
    route: `/team/programs/${email_campaign.program.id}`
  }

  const share = {
    label: 'Share',
    className: 'link',
    showIcon: false,
    link: public_link
  }

  const html = {
    label: 'Download HTML',
    className: 'link',
    url: `${public_link}.html`
  }

  const pdf = {
    label: 'Download PDF',
    className: 'link',
    url: `${public_link}.pdf`
  }

  const details = {
    audits: `campaigns_email_campaigns/${email_campaign.id}`,
    comments: `campaigns_email_campaigns/${email_campaign.id}`,
    header:  <MJSONPreview entity={ email_campaign } table="campaigns_email_campaigns" />,
    sections: [
      {
        title: 't(Email Campaign Details)',
        items: [
          { label: 't(Title)', content: email_campaign.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
          { label: 't(Links)', content: <><Button { ...share } /> | <Button { ...html } /></> }
        ]
      },
      {
        title: 't(Email Settings)',
        items: [
          ...email_campaign.template ? [
            { label: 't(Template)', content: email_campaign.template.title }
          ] : [],
          { label: 't(From)', content: email_campaign.sender.rfc822 },
          ...email_campaign.meta.cc ? [
            { label: 't(CC)', content: email_campaign.meta.cc }
          ] : [],
          ...email_campaign.meta.bcc ? [
            { label: 't(BCC)', content: email_campaign.meta.bcc }
          ] : [],
          { label: 't(Subject)', content: email_campaign.subject },
          { label: 't(Preview Text)', content: email_campaign.preview_text }
        ]
      }
    ]
  }

  if(email_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This email campaign was deleted)' }
  } else if(email_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This email campaign is in draft mode)' }
  } else if(email_campaign.status === 'scheduled') {
    details.alert = { color: 'teal', message: 't(This email campaign is scheduled)' }
  } else if(email_campaign.status === 'queuing') {
    details.alert = { color: 'yellow', message: 't(This email campaign is being queued)' }
  } else if(email_campaign.status === 'sending') {
    details.alert = { color: 'yellow', message: 't(This email campaign is being sent)' }
  } else if(email_campaign.status === 'warming') {
    details.alert = { color: 'yellow', message: 't(This email campaign is being sent with a warming strategy)' }
  } else if(email_campaign.status === 'sent') {
    details.alert = { color: 'green', message: 't(This email campaign was sent)' }
  }

  if(email_campaign.event) {
    const event = {
      label: email_campaign.event.title,
      className: 'link',
      route: `/events/events/${email_campaign.event.id}`
    }
    details.sections[0].items.push({ label: 't(Event)', content: <Button { ...event } /> })
  }

  if(email_campaign.form) {
    const form = {
      label: email_campaign.form.title,
      className: 'link',
      route: `/forms/forms/${email_campaign.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(email_campaign.pipeline) {
    const pipeline = {
      label: email_campaign.pipeline.title,
      className: 'link',
      route: `/sales/pipelines/${email_campaign.pipeline.id}`
    }
    details.sections[0].items.push({ label: 't(Pipeline)', content: <Button { ...pipeline } /> })
  }

  if(email_campaign.service) {
    const service = {
      label: email_campaign.service.title,
      className: 'link',
      route: `/subscriptions/services/${email_campaign.service.id}`
    }
    details.sections[0].items.push({ label: 't(Service)', content: <Button { ...service } /> })
  }

  if(email_campaign.store) {
    const store = {
      label: email_campaign.store.title,
      className: 'link',
      route: `/stores/stores/${email_campaign.store.id}`
    }
    details.sections[0].items.push({ label: 't(Store)', content: <Button { ...store } /> })
  }

  details.sections[0].items.push({ label: 't(Purpose)', content: email_campaign.purpose })

  if(email_campaign.status === 'scheduled') {
    details.sections[0].items.push({ label: 't(Send At)', content: email_campaign.send_at, format: 'datetime' })
  }

  if(email_campaign.status === 'sent') {
    details.sections[0].items.push({ label: 't(Sent At)', content: email_campaign.sent_at, format: 'datetime' })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  email_campaign: PropTypes.object
}

const getTasks = ({ email_campaign }, context) => ({
  access: { rights: { $oneOf: ['campaigns:manage_email_campaigns'] } },
  items: !email_campaign.deleted_at ? [
    {
      label: 't(Edit Campaign)',
      show:_.includes(['draft','scheduled'], email_campaign.status),
      modal: <Edit email_campaign={ email_campaign } />
    },
    {
      label: 't(Send/Schedule Campaign)',
      show: email_campaign.status === 'draft' && email_campaign.is_spam === false,
      modal: <Send email_campaign={ email_campaign } />
    },
    {
      label: 't(Reschedule Campaign)',
      show: email_campaign.status === 'scheduled',
      modal: <Reschedule email_campaign={ email_campaign } />
    },
    {
      label: 't(Unschedule Campaign)',
      show: email_campaign.status === 'scheduled',
      confirm: 't(Are you sure you want to unschedule this campaign?)',
      request: {
        endpoint: `/api/admin/campaigns/email/${email_campaign.id}/unschedule`,
        method: 'PATCH',
        onFailure: () => context.flash.set('error', 't(Unable to unschedule campaign)')
      }
    },
    {
      label: 't(Clone Campaign)',
      show: email_campaign.is_spam === false,
      modal: <Clone email_campaign={ email_campaign } />
    },
    {
      label: 't(Delete Campaign)',
      show: email_campaign.status !== 'sent',
      confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
      request: {
        endpoint: `/api/admin/campaigns/email/${email_campaign.id}`,
        method: 'DELETE',
        onSuccess: () => {
          context.flash.set('success', 't(Successfully deleted campaign)')
          context.router.goBack()
        },
        onFailure: () => context.flash.set('error', 't(Unable to delete campaign)')
      }
    }
  ] : []
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email_campaign={ props.email_campaign } />,
  tasks: getTasks(props, context)
})

export default Panel(null, mapPropsToPanel)
