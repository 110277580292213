import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.template.title,
  access: { 
    programs: { $canView: resources.template.program.id },
    rights: { $oneOf: ['team:view_templates','team:manage_templates'] } 
  },
  manager: {
    path: `/admin/team/templates/${resources.template.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview program={ resources.template.program } template={ resources.template } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design program={ resources.template.program } template={ resources.template } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  template: `/api/admin/team/templates/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
