import ButtonStyleField from '@admin/components/mjson_designer/components/buttonstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import TextStyleField from '@admin/components/mjson_designer/components/textstylefield'
import ButtonField from '@admin/components/mjson_designer/components/buttonfield'
import AlignmentField from '@admin/components/form/alignmentfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

const Alignment = (props) => <ResponsiveField { ...props } component={ AlignmentField } />

class EditCardBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, parent, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Title)', name: 'content.title', type: 'textfield', required: true, value: config.content.title },
                { label: 't(Description)', name: 'content.description', type: 'textfield', value: config.content.description },
                { label: 't(Button)', name: 'content.button', type: ButtonField, theme, website, value: config.content.button }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 't(Show)', name: 'content.show', type: 'checkboxgroup', options: [
                  { value: 'title', text: 'Title' },
                  { value: 'description', text: 'Description' },
                  { value: 'button', text: 'Button' }
                ], defaultValue: ['name','description','button'], value: config.content.show },
                { label: 't(Show on Hover)', name: 'content.showHover', type: 'checkboxgroup', options: [
                  { value: 'title', text: 'Title' },
                  { value: 'description', text: 'Description' },
                  { value: 'button', text: 'Button' }
                ], defaultValue: ['name','description','button'], value: config.content.showHover }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 'Card',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Card)', type: 'segment', collapsing: true, collapsed: true, fields: [
                  { label: 't(Padding)', name: 'styles.cardPadding', device, collapsing: true, collapsed: true, type: styles.Spacing, value: config.styles.cardPadding },
                  { label: 't(Height)', name: 'styles.cardHeight', device, collapsing: true, collapsed: true, type: styles.Height, value: config.styles.cardHeight },
                  { label: 't(Alignment)', name: 'styles.horizontalAlign', device, collapsing: true, collapsed: true, type: Alignment, defaultValue: 'left', value: config.styles.horizontalAlign }
                ] },
                { label: 't(Title Text style)', name: 'styles.titleText', type: TextStyleField, collapsing: true, collapsed: true, theme, defaultValue: {
                  preset: 'heading',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.titleText },
                { label: 't(Description Text style)', name: 'styles.descriptionText', type: TextStyleField, collapsing: true, collapsed: true, theme, defaultValue: {
                  preset: 'text',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.descriptionText },
                { label: 't(Button)',  name: 'styles.button', device, theme, collapsing: true, collapsed: true, type: ButtonStyleField, defaultValue: {
                  preset: 'primary_button'
                }, value: config.styles.button }
              ]
            },
            ...styles.getIsFlexChild(parent) ? [
              styles.FlexChildSection(config, device, theme, website, type)
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditCardBlock
