const INITIAL_STATE = {
  assets: []
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_ASSETS':
    return {
      ...state,
      assets: action.assets
    }

  case 'REMOVE_ASSET':
    return {
      ...state,
      assets: state.assets.filter((asset, index) => {
        return index !== action.index
      })
    }

  default:
    return state
  }

}

export default reducer
