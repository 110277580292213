import AlignmentField from '@admin/components/form/alignmentfield'
import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import RadiusField from '../radiusfield'
import BorderField from '../borderfield'
import LinkedField from '../linkedfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Linked = (props) => <ResponsiveField { ...props } component={ LinkedField } />
const Border = (props) => <ResponsiveField { ...props } component={ BorderField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const Alignment = (props) => <ResponsiveField { ...props } component={ AlignmentField } />

class ItemStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    item: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.item) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { item } = this.state
    if(!_.isEqual(item, prevState.item)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { item } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Alignment)', name: 'align', type: Alignment, defaultValue: 'left', value: item.align },
            { label: 't(Background Color)', name: 'backgroundColor', device, theme, type: Color, value: item.backgroundColor },
            { label: 't(Border)', name: 'border', device, theme, type: Border, value: item.border },
            { label: 't(Rounded Corners)', name: 'radius', device, theme, type: Radius, value: item.radius },
            { label: 't(Margin)', name: 'margin', device, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: item.margin },
            { label: 't(Padding)', name: 'padding', device, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: item.padding }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { item } = this.state
    this.props.onChange(item)
  }

  _handleSet(item) {
    this.setState({
      item: {
        ...item
      }
    })
  }

}

export default ItemStyleField
