import ProgressPie from '@admin/components/progress/pie'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Validate extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    progress: null
  }

  _handleDone = this._handleDone.bind(this)
  _handleFailure = this._handleFailure.bind(this)
  _handleProgress = _.throttle(this._handleProgress.bind(this), 150)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="import-parsing">
          <ProgressPie { ...this._getProgress() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleValidate()
  }

  _getPanel() {
    return {
      title: 't(Validating Data)'
    }
  }

  _getProgress() {
    const { progress } = this.state
    return {
      title: 't(Validating Data)',
      ...progress || {}
    }
  }

  _handleDone() {
    const { _import } = this.props
    this.props.onDone(_import)
  }

  _handleFailure(err) {
  }

  _handleJoin() {
    const { admin, network } = this.context
    const { _import } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/${_import.object_type}/imports/${_import.id}/validate`
    network.subscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.subscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.subscribe({ channel, action: 'failed', handler: this._handleFailure })
  }

  _handleLeave() {
    const { admin, network } = this.context
    const { _import } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/${_import.object_type}/imports/${_import.id}/validate`
    network.unsubscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.unsubscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.unsubscribe({ channel, action: 'failed', handler: this._handleFailure })
  }

  _handleProgress({ completed, total }){
    const { progress } = this.state
    this.setState({ 
      progress: {
        completed: progress && completed <= progress.completed ? progress.completed : completed,
        total
      } 
    })
  }

  _handleSuccess() {
    const { _import } = this.props
    setTimeout(() => {
      this.props.onDone(_import)      
    }, 1000)
  }

  _handleValidate() {
    const { _import } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${_import.object_type}/imports/${_import.id}/validate`,
      method: 'PATCH',
      onSuccess: () => this._handleJoin(),
      onFailure: () => {}
    })
  }

}

export default Validate
