import Page from '@admin/components/page'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const getTabs = ({ app, audits, expense_type }) => ({
  items: [
    { label: 't(Overview)', component: <Overview expense_type={ expense_type } integration={ app.settings.integration } /> }
  ]
})

const getTasks = ({ app, expense_type }) => ({
  items: [
    { label: 'Edit Expense Type', show: expense_type.type === 'custom', modal: <Edit expense_type={ expense_type } integration={ app.settings.integration  } /> }
  ]
})

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  expense_type: `/api/admin/finance/expense_types/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Expense Type)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
