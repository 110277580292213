import Reactions from './reactions'
import Profiles from './profiles'
import Stars from './stars'
import Help from './help'

const roots = [
  Help,
  Profiles,
  Reactions,
  Stars
]

export default roots
