import PropTypes from 'prop-types'
import Empty from './empty'
import Child from './child'
import React  from 'react'

class Children extends React.PureComponent {

  static propTypes = {
    children: PropTypes.array,
    collapsed: PropTypes.array,
    hover: PropTypes.object,
    index: PropTypes.string,
    padding: PropTypes.number,
    parent: PropTypes.object,
    selected: PropTypes.string,
    onCollapse: PropTypes.func,
    onHover: PropTypes.func,
    onMove: PropTypes.func,
    onSelect: PropTypes.func
  }

  render() {
    const { children } = this.props
    return children.length > 0 ? children.map((child, cindex) => (
      <Child { ...this._getChild(child, cindex)} key={`child_${cindex}`} />
    )) : <Empty { ...this._getEmpty() } />
  }

  _getChild(item, cindex) {
    const { collapsed, hover, index, padding, parent, selected, onCollapse, onHover, onMove, onSelect } = this.props
    return {
      collapsed,
      hover,
      index: `${index ? `${index}.` : ''}${cindex}`,
      item,
      padding: padding + 1,
      parent,
      selected,
      onCollapse,
      onHover,
      onMove,
      onSelect
    }
  }

  _getEmpty() {
    const { parent, onMove } = this.props
    return {
      parent,
      onMove
    }
  }

}

export default Children
