import FulfillmentField from '@apps/stores/admin/components/fulfillmentfield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Fulfill extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    order: PropTypes.object,
    store: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { order, store } = this.props
    const items = this._getItems()
    return {
      title: 't(Fulfill Order)',
      action: `/api/admin/stores/stores/${store.id}/orders/${order.id}/fulfill`,
      method: 'PATCH',
      saveText: 'Fulfill',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Items', type: 'segment', fields: [
              { type: 'radiogroup', name: 'strategy', deselectable: false, options: [
                { value: 'full', text: `Fulfill all ${pluralize('unfulfilled item', items.length, true)}` },
                { value: 'partial', text: 't(Fulfill selected items)' }
              ], defaultValue: 'full' },
              ...this._getItemIds()
            ] }
          ]
        }
      ]
    }
  }

  _getItems() {
    const { order } = this.props
    return order.items.filter(item => {
      return item.status === 'unfulfilled'
    })
  }

  _getItemIds() {
    const { config } = this.state
    if(config.strategy === 'partial') {
      return [
        { name: 'item_ids', type: FulfillmentField, items: this._getItems() }
      ]
    }
    return []
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(store) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  store: `/api/admin/stores/stores/${props.store_id}`,
  order: `/api/admin/stores/stores/${props.store_id}/orders/${props.order_id}`
})

export default Container(mapResources)(Fulfill)
