import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const PaymentMethodToken = ({ payment_method }) => (
  <div className="card-token">
    { payment_method ?
      <>
        <div className="card-token-icon">
          <Img src={`/images/payments/${payment_method.card_type}.png`} />
        </div>
        <div className="card-token-label">
          { payment_method.description }
          { payment_method.status === 'active' &&
            <> (expires { payment_method.expiration })</>
          }
          { payment_method.status === 'expiring' &&
            <> (<span className="alert">expires { payment_method.expiration }!</span>)</>
          }
          { payment_method.status === 'expired' &&
            <> (<span className="alert">expired { payment_method.expiration }</span>)</>
          }
        </div>
      </> :
      <div className="card-token-label">
        <span className="alert">No card on file</span>
      </div>
    }
  </div>
)

PaymentMethodToken.propTypes = {
  payment_method: PropTypes.object
}

export default PaymentMethodToken
