import Page from '@admin/components/page'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  bank: `/api/admin/platform/banks/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Bank Account)',
  access: { rights: { $oneOf: ['platform:access_app'] } },
  tabs: {
    items: [
      { label: 't(Overview)', component: <Overview bank={ resources.bank } /> }
    ]
  },
  tasks: {
    items: [
      {
        label: 't(Edit Bank)',
        modal: <Edit bank={ resources.bank } />
      }
    ]
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
