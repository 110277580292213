import Attachments from '@admin/components/attachments'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Image from './image'
import React from 'react'

class MediaField extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    endpoint: PropTypes.string,
    images: PropTypes.array,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onMove: PropTypes.func,
    onReady: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
    onSet: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)

  render() {
    const { images } = this.props
    if(!images) return null
    return (
      <div className="mediafield">
        { images.map((image, index) => (
          <Image { ...this._getImage(image, index) } key={`image_${index}`} />
        )) }
        <Button { ...this._getAdd() } />
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) return this._handleFetch(defaultValue)
    this.props.onSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { images } = this.props
    if(!_.isEqual(images, prevProps.images)) {
      this._handleChange()
    }
  }

  _getAdd() {
    return {
      label: '+ t(add photos)',
      className: 'mediafield-add',
      modal: <Attachments { ...this._getAttachments() } />
    }
  }

  _getAttachments() {
    return {
      multiple: true,
      type: 'photos',
      onChooseAssets: this._handleAdd,
      onCancel: this._handleCancel
    }
  }

  _getImage(image, index) {
    const { onMove, onRemove, onUpdate } = this.props
    return {
      image,
      index,
      onMove,
      onRemove,
      onUpdate
    }
  }

  _handleAdd(assets) {
    this.props.onAdd(assets.map(asset => ({
      id: null,
      asset,
      caption: null
    })))
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleChange() {
    const { images } = this.props
    this.props.onChange(images.map(image => ({
      id: image.id,
      asset_id: image.asset.id,
      caption: image.caption
    })))
  }

  _handleFetch(ids) {
    const { endpoint } = this.props
    this.context.network.request({
      method: 'GET',
      endpoint,
      query: {
        $filter: {
          id: {
            $in: ids
          }
        }
      },
      onSuccess: ({ data }) => {
        this.props.onSet(data)
        this.props.onReady()
      },
      onFailure: () => {}
    })
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

}

export default MediaField
