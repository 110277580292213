import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const Overview = ({ scholarship }) => {

  const customer = {
    className: 'link',
    label: scholarship.customer.display_name,
    route: `/admin/crm/contacts/${scholarship.customer.id}`
  }

  const details = {
    audits: `finance_scholarships/${scholarship.id}`,
    comments: `finance_scholarships/${scholarship.id}`,
    sections: [
      {
        title: 't(Scholarship Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...customer } /> },
          { label: 't(Program)', content: scholarship.program.title },
          { label: 't(Amount)', content: numeral(scholarship.amount).format('0.00') },
          { label: 't(Balance)', content: numeral(scholarship.balance).format('0.00') }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  scholarship: PropTypes.object,
  payments: PropTypes.array
}

export default Overview
