import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Program from './program'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    flash: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    profile_id: PropTypes.number,
    thread_id: PropTypes.string
  }

  stackRef = React.createRef()

  state = {
    program_id: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleProgram = this._handleProgram.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleImport = this._handleImport.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Program, this._getProgram.bind(this))
  }

  _getDetails() {
    return {
      onBack: this._handlePop,
      onSend: this._handleImport
    }
  }

  _getProgram() {
    return {
      onCancel: this._handleCancel,
      onChoose: this._handleProgram
    }
  }

  _getStack() {
    return {
      display_name: 'import',
      ref: this.stackRef
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleProgram(program_id) {
    this.setState({ program_id }, () => {
      this._handleImport()
      // this._handlePush(Details, this._getDetails.bind(this))
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleImport(details) {
    const { profile_id, thread_id } = this.props
    const { program_id } = this.state
    this.context.network.request({
      endpoint: `/api/admin/email/${profile_id}/import`,
      method: 'POST',
      body: {
        thread_id,
        program_id
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to import conversation)'),
      onSuccess: () => {
        this.context.flash.set('success', 't(Importing conversation)')
        this.context.modal.close()
      }
    })
  }

}

export default Import
