import Page from '@admin/components/page'
import Voicemails from './voicemails'
import Overview from './overview'
import Blocked from './blocked'
import Design from './design'
import Calls from './calls'
import React from 'react'
import SMS from './smses'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.number.formatted,
  manager: {
    path: `/admin/phone/numbers/${resources.number.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview number={ resources.number } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design number={ resources.number } />
      },
      {
        label: 't(Blocked)',
        path: '/blocked',
        panel: <Blocked number={ resources.number } />
      },
      {
        label: 't(Calls)',
        path: '/calls',
        panel: <Calls number={ resources.number } />
      },
      {
        label: 't(SMS)',
        path: '/smses',
        panel: <SMS number={ resources.number } />
      },
      {
        label: 't(Voicemails)',
        path: '/voicemails',
        panel: <Voicemails number={ resources.number } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  number: `/api/admin/phone/numbers/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
