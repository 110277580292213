import MultiForm from '@admin/components/multiform'
import Communication from './communication'
import PropTypes from 'prop-types'
import Renewals from './renewals'
import Billing from './billing'
import Details from './details'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.number,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program } = this.props
    return {
      title: 't(New Subscription Service)',
      action: '/api/admin/subscriptions/services',
      method: 'POST',
      props: { program },
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { program_id } = this.props
    return { program_id }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Billing)', component: Billing },
      { label: 't(Renewals)', component: Renewals },
      { label: 't(Communication)', component: Communication }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(service) {
    this.context.router.push(`/subscriptions/services/${service.id}`)
    this.context.modal.close()
  }

}

export default New
