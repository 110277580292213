import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { first_name, last_name, email } = formdata
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Users)', name: 'users', type: 'collectionfield', fields: [
              { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
              { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
              { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
              { type: 'checkbox', name: 'send_activation', prompt: 't(Send an activation email to the user)', defaultValue: true }
            ], entity: 'user', orderable: false, cloneable: false, token: ({ value }) => (
              <div className="token">{value.first_name} {value.last_name} &lt;{ value.email }&gt;</div>
            ), required: true, defaultValue: [
              { first_name, last_name, email, role: 'advisor', send_activation: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(advisor) {
    this.props.onSave(advisor)
  }

}

export default Details
