import Dropdown from '@admin/components/form/dropdown'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import React from 'react'

class FrequencyField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    frequency: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 50)

  render() {
    if(!this.state.frequency) return null
    return (
      <div className="subscriptions-frequencyfield">
        <div className={ this._getClass('calendar') } onClick={ this._handleUpdate.bind(this, 'mode', 'calendar') }>
          <div className="subscriptions-frequencyfield-option-label">
            <Icon icon={ this._getIcon('calendar') } />
          </div>
          <div className="subscriptions-frequencyfield-option-dropdown">
            <Dropdown { ...this._getCalendarQuantity() } />
          </div>
          <div className="subscriptions-frequencyfield-option-dropdown">
            <Dropdown { ...this._getCalendarUnits() } />
          </div>
        </div>
        <div className={ this._getClass('periodic') } onClick={ this._handleUpdate.bind(this, 'mode', 'periodic') }>
          <div className="subscriptions-frequencyfield-option-label">
            <Icon icon={ this._getIcon('periodic') } />
          </div>
          <div className="subscriptions-frequencyfield-option-dropdown">
            <Dropdown { ...this._getPeriodicQuantity() } />
          </div>
          <div className="subscriptions-frequencyfield-option-dropdown">
            <Dropdown { ...this._getPeriodicUnits() } />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { frequency } = this.state
    const { value } = this.props
    if(!_.isEqual(frequency, prevState.frequency)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getClass(mode) {
    const { frequency } = this.state
    const classes = ['subscriptions-frequencyfield-option']
    if(mode !== frequency.mode) classes.push('disabled')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getIcon(mode) {
    const { frequency } = this.state
    return mode === frequency.mode ? 'check-circle' : 'circle-o'
  }

  _getCalendarLength(units) {
    if(units === 'week') return 7
    if(units === 'month') return 31
    if(units === 'quarter') return 92
    if(units === 'year') return 365
  }

  _getCalendarQuantity() {
    const { frequency } = this.state
    const length = this._getCalendarLength(frequency.calendar_units)
    return {
      options: new Array(length).fill(0).map((i,j) => ({
        value: j + 1,
        text: `${numeral(j + 1).format('0o')} day of every`
      })),
      disabled: frequency.mode === 'periodic',
      value: frequency.calendar_quantity,
      onChange: this._handleUpdate.bind(this, 'calendar_quantity')
    }
  }

  _getCalendarUnits() {
    const { frequency } = this.state
    return {
      options: [
        { value: 'week', text: 't(week)' },
        { value: 'month', text: 't(month)' },
        { value: 'quarter', text: 't(quarter)' },
        { value: 'year', text: 't(year)' }
      ],
      disabled: frequency.mode === 'periodic',
      value: frequency.calendar_units,
      onChange: this._handleUpdate.bind(this, 'calendar_units')
    }
  }

  _getPeriodicQuantity() {
    const { frequency } = this.state
    return {
      options: new Array(31).fill(0).map((i,j) => ({
        value: j + 1,
        text: `every ${j + 1}`
      })),
      disabled: frequency.mode === 'calendar',
      value: frequency.periodic_quantity,
      onChange: this._handleUpdate.bind(this, 'periodic_quantity')
    }
  }

  _getPeriodicUnits() {
    const { frequency } = this.state
    const { periodic_quantity } = frequency
    return {
      options: [
        { value: 'day', text: pluralize('day', periodic_quantity) },
        { value: 'week', text: pluralize('week', periodic_quantity) },
        { value: 'month', text: pluralize('month', periodic_quantity) },
        { value: 'year', text: pluralize('year', periodic_quantity) }
      ],
      disabled: frequency.mode === 'calendar',
      value: frequency.periodic_units,
      onChange: this._handleUpdate.bind(this, 'periodic_units')
    }
  }

  _handleChange() {
    const { frequency } = this.state
    this.props.onChange({
      mode: frequency.mode,
      ...frequency.mode === 'calendar' ? {
        quantity: frequency.calendar_quantity,
        units: frequency.calendar_units
      } : {
        quantity: frequency.periodic_quantity,
        units: frequency.periodic_units
      }
    })
  }

  _handleSet(frequency) {
    this.setState({
      frequency: {
        mode: 'calendar',
        calendar_quantity: 1,
        calendar_units: 'month',
        periodic_quantity: 1,
        periodic_units: 'month',
        ...frequency ? {
          mode: frequency.mode,
          ...frequency.mode === 'calendar' ? {
            calendar_quantity: frequency.quantity,
            calendar_units: frequency.units
          } : {
            periodic_quantity: frequency.quantity,
            periodic_units: frequency.units
          }
        } : {}
      }
    })
  }

  _handleUpdate(key, value) {
    const { frequency } = this.state
    this.setState({
      frequency: {
        ...frequency,
        [key]: value
      }
    })
  }

}

export default FrequencyField
