import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const list_types = {
  private: {
    title: 't(Private Channel)',
    description: 't(This is a private channel for administrative purposes. Advisors cannot manually subscribe/unsubscribe themselves)'
  },
  public: {
    title: 't(Public Channel)',
    description: 't(This is a public channel. Advisors will be able to manually subscribe/ unsubscribe themselves)'
  }
}

const ChannelTypeToken = ({ value }) => (
  <div className="maha-token">
    <div className="maha-token-details">
      <T text={ list_types[value].title } /><br />
      <span><T text={ list_types[value].description } /></span>
    </div>
  </div>
)

ChannelTypeToken.propTypes = {
  value: PropTypes.string
}

export default ChannelTypeToken
