import Page from '@admin/components/page'
import Notifications from './notifications'
import Activities from './activities'
import Overview from './overview'
import Emails from './emails'
import Access from './access'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.user.full_name,
  manager: {
    path: `/admin/team/users/${resources.user.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview user={ resources.user }  />
      },
      {
        label: 't(Access)',
        path: '/access',
        panel: <Access user={ resources.user } />
      },
      {
        label: 'Activities',
        path: '/activities',
        panel: <Activities user={ resources.user } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails user={ resources.user } />
      },
      {
        label: 't(Notifications)',
        path: '/notifications',
        panel: <Notifications user={ resources.user } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  user: `/api/admin/team/users/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
