import LineItems from '@apps/finance/admin/components/line_items'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const line_items = {}

class Details extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    setup: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata} = this.props
    const line_items = this._getLineItems(formdata.type) || []
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(Date)', name: 'date', type: 'datefield', placeholder: 'Date', required: true, defaultValue: moment() },
              { label: 't(Due)', name: 'due', type: 'datefield', placeholder: 'Date', required: true, defaultValue: moment().add(1, 'week') }
            ] },
            { label: 't(Line Items)', name: 'line_items', type: LineItems, required: true, defaultValue: line_items },
            { label: 't(Notes)', name: 'notes', type: 'textfield', placeholder: 'Add notes' }
          ]
        }
      ]
    }
  }

  _getLineItems(key) {
    const line_item = line_items[key]
    return [
      ...line_item ? [
        { 
          project_id: 6380,
          revenue_type_id: line_item.revenue_type_id,
          description: line_item.description,
          quantity: 1,
          price: line_item.price,
          tax_rate: 0.00,
          is_tax_deductible: false 
        }
      ] : []
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onSave(subscription)
  }

}

export default Details