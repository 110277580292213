import ModalPanel from '@admin/components/modal_panel'
import { canAccess } from '@core/utils/access'
import Finder from '@admin/components/finder'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

export const ManagerContext = React.createContext()
ManagerContext.displayName = 'ManagerContext'

export const useManagerContext = () => React.useContext(ManagerContext)

class Manager extends React.PureComponent {

  static childContextTypes = {
    manager: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    page: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    alert: PropTypes.any,
    before: PropTypes.any,
    back: PropTypes.bool,
    defaultView: PropTypes.any,
    items: PropTypes.array,
    page: PropTypes.object,
    path: PropTypes.string,
    title: PropTypes.string,
    view: PropTypes.string
  }

  state = {
    show: false,
    selected: '0',
    rightItems: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSetRightItems = this._handleSetRightItems.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { selected } = this.state
    const { alert } = this.props
    const { before } = this.props
    const item = this._getResolved(selected)
    return (
      <ManagerContext.Provider value={ this.getChildContext() }>
        <ModalPanel { ...this._getPanel() }>
          <div className={ this._getClass() }>
            <div className="maha-manager-canvas" onClick={ this._handleToggle } />
            <div className="maha-manager-sidebar">
              <div className="maha-manager-sidebar-body">
                <Finder {...this._getFinder() } />
              </div>
            </div>
            <div className="maha-manager-main">
              { before }
              { alert &&
                <div className="maha-manager-alert-container">
                  <div className={`maha-manager-alert ${alert.style}`}>
                    <div className="maha-manager-alert-inner">
                      { _.isString(alert.message) ?
                        <T text={ alert.message } /> :
                        <>{ alert.message }</>
                      }
                    </div>
                  </div>
                </div>
              }
              { (item && item.panel) &&
                <Fragment key={ selected }>
                  { React.cloneElement(item.panel, { pathname: this._getPathname() }) }
                </Fragment>
              }
            </div>
          </div>
        </ModalPanel>
      </ManagerContext.Provider>
    )
  }

  componentDidMount() {
    const { view } = this.props
    const items = this._getAllowed(this.props.items)
    const selected = this._getIndex(items, view)
    if(selected) this.setState({ selected })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    if(selected !== prevState.selected) {
      this._handleUpdateTitle()
    }
  }

  getChildContext() {
    return {
      manager: {
        setRightItems: this._handleSetRightItems
      }
    }
  }

  _getAllowed(items) {
    const { admin } = this.context
    return items.filter(item => {
      return item.show !== false && canAccess(item.access, admin)
    }).map(item => ({
      ...item,
      ...item.children ? { children: this._getAllowed(item.children) } : {}
    }))
  }

  _getClass() {
    const { show } = this.state
    const classes = ['maha-manager']
    if(show) classes.push('show')
    return classes.join(' ')
  }

  _getIndex(items, view, prefix = null) {
    return items.reduce((found, item, index) => {
      if(found !== null) return found
      const itemindex = prefix ? [prefix,index].join('.') : `${index}`
      if(item.path === `/${view}` || (!item.path && !item.children && view === '')) return itemindex
      return item.children ? this._getIndex(item.children, view, itemindex) : null
    }, null)
  }

  _getFinder() {
    const { selected } = this.state
    const { items } = this.props 
    return {
      items: this._getAllowed(items),
      selected,
      onChange: this._handleChange
    }
  }

  _getFullPath(segment) {
    const { path } = this.props
    return `${path}${segment || ''}`
  }

  _getPanel() {
    const { back } = this.props
    const { rightItems } = this.state
    return {
      title: (
        <div className="maha-manager-title" onClick={ this._handleToggle }>
          <T text={ this._getTitle() } /> <Icon icon="caret-down" />
        </div>
      ),
      onClick: this._handleToggle,
      leftItems: [
        ...back !== false ? [{ icon: 'chevron-left', handler: this._handleBack }] : []
      ],
      rightItems
    }
  }

  _getPathname() {
    const { selected } = this.state
    const { path } = this.props
    const item = this._getResolved(selected)
    return `${path || ''}${item.path || ''}`
  }

  _getResolved(index) {
    const items = this._getAllowed(this.props.items)
    const resolved = index.replace(/\./g,'.children.')
    return _.get(items, resolved)
  }

  _getTitle() {
    const { selected } = this.state
    const { title } = this.props
    const item = this._getResolved(selected)
    return [
      title,
      ...item ? [item.label] : []
    ].join(' - ')
  }

  _handleBack() {
    this.context.router.goBack()
  }

  _handleChange(selected, item) {
    const viewitem = !item.panel && item.children && item.children.length > 0 ? item.children[0] : item
    if(!viewitem.component && !viewitem.panel) return
    this.context.router.replace(this._getFullPath(item.path))
    this.setState({
      show: false,
      selected
    })
  }

  _handleSetRightItems(rightItems) {
    this.setState({
      rightItems: rightItems || []
    })
  }

  _handleToggle() {
    const { show } = this.state
    this.setState({
      show: !show
    })
  }

  _handleUpdateTitle() {
    const title = this._getTitle()
    if(!this.context.page) return
    this.context.page.setTitle(title)
  }

}

export default Manager
