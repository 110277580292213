import ElementToken from '@admin/components/mjson_designer/tokens/element'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Items extends React.PureComponent {

  static propTypes = {
    device: PropTypes.object,
    website: PropTypes.object,
    onBack: PropTypes.func,
    onNavigate: PropTypes.func,
    onSettings: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    const { website } = this.props
    return {
      endpoint: `/api/admin/websites/websites/${website.id}/items`,
      format: (item) => <ElementToken icon="copy" type="item" element={ item } />,
      label: 'Item',
      valueKey: null,
      textKey: 'title',
      onChange: this._handleChoose
    }
  }

  _handleChoose(item) {
    this.props.onSettings(null)
    this.props.onNavigate(`/websites_items/${item.id}`)
  }

}

export default Items
