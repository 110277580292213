import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class NoTeams extends React.Component {

  static propTypes = {
    onSignout: PropTypes.func
  }

  render() {
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon="building-o" /></h1>
            <h2><T text="No Active Teams" /></h2>
            <p><T text="Your account is not connected to any active teams" /></p>
            <div className="field button-field">
              <Button { ...this._getSignout() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getSignout() {
    const { onSignout } = this.props
    return {
      label: 't(Sign Out)',
      color: 'blue',
      handler: onSignout
    }
  }

}

export default NoTeams
