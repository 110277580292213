import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import CompactTypeToken from '@apps/finance/admin/tokens/type/compact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Tax)',
  access: { rights: { $oneOf: ['finance:access_expense_reports'] } },
  collection: {
    endpoint: '/api/admin/finance/items/tax',
    table: [
      { label: 't(ID)', key: 'item_id', collapsing: true, visible: false },
      { label: 't(Type)', key: 'type', collapsing: true, format: CompactTypeToken },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(User)', key: 'user.full_name', sort: 'maha_users.last_name', primary: true },
      { label: 'Project', key: 'project.title', sort: 'finance_projects.title', format: CompactProjectToken },
      { label: 'Tax Account', key: 'tax_project.title', sort: 'finance_projects.title', format: (item) => <CompactProjectToken project={item.tax_project} /> },
      { label: 't(Description)', key: 'description' },
      { label: 'Tax', key: 'tax', collapsing: true, primary: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/${record.type}s/${record.item_id}`)

  }
})

export default Panel(null, mapPropsToPanel)
