import TextField from '@admin/components/form/textfield'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Security extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    account: PropTypes.object,
    security_question: PropTypes.string,
    token: PropTypes.string,
    onNext: PropTypes.func
  }

  state = {
    answer: '',
    error: false,
    message: null,
    status: 'pending'
  }

  _handleNext = this._handleNext.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    const { account, security_question } = this.props
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            { account && <Avatar user={ account } width="150" presence={ false } /> }
            { account && <h2>{ account.full_name }</h2> }
            <p>Hi { account.first_name }. Before we can reset your password, Please
            answer the following security question to prove your identity.</p>
            <p>{ security_question }</p>
          </div>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            <input type="hidden" value="something" />
            <div className="field text-field">
              <TextField { ...this._getAnswer() } />
            </div>
            <div className="field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getAnswer() {
    return {
      placeholder: 't(Answer)',
      onChange: this._handleUpdate.bind(this, 'answer')
    }
  }

  _getContinue() {
    const { status } = this.state
    return {
      label: 't(Continue)',
      loading: status === 'submitting',
      color: 'blue',
      size: 'large',
      handler: this._handleSubmit
    }
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _handleNext() {
    this.props.onNext('password')
  }

  _handleShake({ message }) {
    this.setState({ 
      error: true,
      message
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleSubmit() {
    const { token } = this.props
    const { answer } = this.state
    this.setState({ status: 'submitting' })
    this.context.network.request({
      endpoint: '/api/admin/reset/security',
      method: 'POST',
      body: { 
        token,
        answer
      },
      onSuccess: this._handleNext,
      onFailure: this._handleShake
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Security
