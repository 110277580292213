import SocialCampaignVariantToken from '@apps/campaigns/admin/tokens/social_campaign_variant'
import MJSONPreview from '@admin/components/mjson_preview'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Schedule from '../schedule'
import Edit from '../edit'
import React from 'react'

const Overview = ({ social_campaign, variants }, { admin }) => {

  const program = {
    label: social_campaign.program.title,
    className: 'link',
    route: `/team/programs/${social_campaign.program.id}`
  }

  const slides = variants.map((variant, index) => (
    <MJSONPreview entity={ variant } table="campaigns_social_campaign_variants" key={`variant_${index}`} />
  ))

  const details = {
    audits: `campaigns_social_campaigns/${social_campaign.id}`,
    comments: `campaigns_social_campaigns/${social_campaign.id}`,
    header: <Carousel slides={ slides } />,
    sections: [
      {
        title: 't(Campaign Details)',
        items: [
          { label: 't(Title)', content: social_campaign.title },
          ...admin.team.has_programs ? [
            { label: 't(Program)', content: <Button { ...program } /> }
          ] : [],
          { label: 't(Type)', content: social_campaign.type.toUpperCase() },
          ...social_campaign.status === 'scheduled' ? [
            { label: 't(Post At)', content: social_campaign.post_at, format: 'datetime' }
          ] : [],
          { label: 't(Posts)', content: variants.map((social_campaign_variant, index) => (
            <SocialCampaignVariantToken social_campaign_variant={ social_campaign_variant } key={`variant_${index}`} />
          )) },
          ...social_campaign.status === 'posted' ? [
            { label: 't(Posted At)', content: social_campaign.posted_at, format: 'datetime' }
          ] : []
        ]
      }
    ]
  }

  if(social_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social campaign was deleted)' }
  } else if(social_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social campaign is in draft mode)' }
  } else if(social_campaign.status === 'scheduled') {
    details.alert = { color: 'teal', message: 't(This social campaign is scheduled)' }
  } else if(social_campaign.status === 'posting') {
    details.alert = { color: 'yellow', message: 't(This social campaign is in the process of being posted)' }
  } else if(social_campaign.status === 'posted') {
    details.alert = { color: 'green', message: 't(This social campaign was posted)' }
  } else if(social_campaign.status === 'failed') {
    details.alert = { color: 'red', message: 't(Unable to post campaign)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  social_campaign: PropTypes.object,
  variants: PropTypes.array
}

const getTasks = (props, context) => {
  const { social_campaign } = props
  const { status } = social_campaign
  const items = [
    { label: 't(Edit Campaign)', show: status === 'draft', modal: <Edit social_campaign={ social_campaign } /> },
    { label: status === 'scheduled' ? 't(Reschedule Campaign)' : 't(Schedule Campaign)', modal: <Schedule social_campaign={ social_campaign } /> }
  ]

  if(status === 'scheduled') {
    items.push({
      label: 't(Unschedule Campaign)',
      confirm: 't(Are you sure you want to unschedule this campaign?)',
      request: {
        endpoint: `/api/admin/campaigns/social/${social_campaign.id}/schedule`,
        method: 'PATCH',
        body: {
          strategy: 'unschedule'
        },
        onFailure: () => context.flash.set('error', 't(Unable to unschedule campaign)')
      }
    })
  }

  items.push({
    label: 't(Delete Campaign)',
    confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
    request: {
      endpoint: `/api/admin/campaigns/social/${social_campaign.id}`,
      method: 'DELETE',
      onSuccess: () => {
        context.flash.set('success', 't(Successfully deleted campaign)')
        context.router.goBack()
      },
      onFailure: () => context.flash.set('error', 't(Unable to delete campaign)')
    }
  })

  return { items }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_campaign={ props.social_campaign } variants={ props.variants } />,
  tasks: getTasks(props, context)
})

export default Panel(null, mapPropsToPanel)
