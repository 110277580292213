import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.notice.notice.title,
  manager: {
    path: `/admin/platform/providers/${resources.provider.id}/notices/${resources.notice.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview provider={ resources.provider } notice={ resources.notice } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design provider={ resources.provider } notice={ resources.notice } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  provider: `/api/admin/platform/providers/${props.params.provider_id}`,
  notice: `/api/admin/platform/providers/${props.params.provider_id}/notices/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
