import NotificationMethodToken from '@apps/maha/admin/tokens/notification_method'
import RadioGroup from '@admin/components/form/radiogroup'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Notifications extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    token: PropTypes.string,
    onNext: PropTypes.func
  }

  _handleNotification = this._handleNotification.bind(this)

  render() {
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h2><T text="t(Notification Preferences)" /></h2>
            <p><T text="t(If you are not signed in when a notification arrives for you, we can send you an email. Please indicate how you would like to be contacted when you miss a notification)" /></p>
            <RadioGroup { ...this._getRadioGroup()} />
          </div>
        </div>
      </div>
    )
  }

  _getRadioGroup() {
    return {
      deselectable: false,
      options: ['none','ondemand','digest'],
      format: NotificationMethodToken,
      selectFirst: false,
      onChange: this._handleNotification
    }
  }

  _handleNotification(email_notifications_method) {
    const { token } = this.props
    this.context.network.request({
      method: 'POST',
      endpoint: '/api/admin/activate/notifications',
      body: { token, email_notifications_method },
      onSuccess: ({ data }) => {
        const { account } = data
        this.props.onNext({ account })

      }
    })
  }

}

export default Notifications
