import New from '@admin/components/fields/new'
import Fields from '@admin/components/fields'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Properties)',
  component: <Fields label="property" parent_type="crm_programs" parent_id={ context.admin.programs[0].id } access={ { rights: { $oneOf: ['team:manage_properties'] } } } />,
  task: {
    icon: 'plus',
    tooltip: 't(Create Property)',
    access: { rights: { $oneOf: ['team:manage_properties'] } },
    modal: <New action={`/api/admin/crm_programs/${context.admin.programs[0].id}/fields`} />
  }
})

export default Panel(null, mapPropsToPanel)
