import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

class Month extends React.Component {

  static contextTypes = {
    maha: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    current: PropTypes.object,
    items: PropTypes.array
  }

  render() {
    const weeks = this._getWeeks()
    return (
      <div className="maha-calendar-month">
        { Array(weeks).fill(0).map((x, i) => (
          <div className="maha-calendar-month-week" key={`week_${i}`}>
            { Array(7).fill(0).map((x, j) => (
              <div className="maha-calendar-month-day" key={`day_${i}_${j}`}>
                { i === 0 && 
                  <div className="maha-calendar-month-weekday">
                    { days[j] }
                  </div>
                }
                <div className="maha-calendar-month-number">
                  <span className={ this._getNumberClass(i * 7 + j) }>
                    { this._getNumber(i * 7 + j) }
                  </span>
                </div>
                <div className="maha-calendar-month-content">
                  { this._getItems(i * 7 + j).map((item, index) => (
                    <div { ...this._getItem(item) } key={`item_${index}`}>
                      <div className="maha-calendar-month-item-label">
                        <div className="maha-calendar-month-item-text">
                          { this._getLabel(item) }
                        </div>
                      </div>
                    </div>
                  )) }
                </div>
              </div>
            )) }
          </div>
        )) }
      </div>
    )
  }

  _getCalendarType(code) {
    const { calendarTypes } = this.context.maha
    return calendarTypes.find(type => {
      return `${type.app}:${type.code}` === code
    })
  }

  _getDate(index) {
    const { current } = this.props
    const offset = index - current.weekday()
    return current.clone().add(offset, 'days')
  }

  _getItem(item) {
    const classes = ['maha-calendar-month-item']
    return {
      className: classes.join(' '),
      onClick: this._handleChoose.bind(this, item)
    }
  }

  _getLabel(item) {
    const event = this._getCalendarType(item.code)
    return `${pluralize(event.entity, item.quantity, true)} ${ event.action }`
  }

  _getNumber(index) {
    const date = this._getDate(index)
    return date.format('D') === '1' ? date.format('MMM D') : date.format('D')
  }

  _getNumberClass(index) {
    const date = this._getDate(index)
    const classes = []
    if(date.isSame(moment(), 'day')) classes.push('today')
    return classes.join(' ')
  }

  _getItems(index) {
    const date = this._getDate(index)
    const { items } = this.props
    return items.filter(item => {
      return date.isSame(moment(item.date), 'day')
    })
  }

  _getWeeks() {
    const { current } = this.props
    const start_of_month = current.startOf('month')
    const days = start_of_month.weekday() + start_of_month.daysInMonth()
    return Math.ceil(days/7)
  }

  _handleChoose(item) {
    const type = this._getCalendarType(item.code)
    const date = moment(item.date).format('YYYY-MM-DD')
    const query = qs.stringify({ date }, { encode: false })
    this.context.router.push(`/admin/calendar/${type.app}/${type.code}?${query}`)
  }

}

export default Month
