import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

class EmailField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onUnique: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    label: 't(Enter Email)',
    onChange: () => {},
    onEnter: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    email: '',
    focused: false
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyPressed = this._handleKeyPressed.bind(this)
  _handleUpdated = this._handleUpdated.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { email } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-field">
          <Input { ...this._getInput() }/>
        </div>
        { email && email.length > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { email } = this.state
    const { value } = this.props
    if(email !== prevState.email) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-emailfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { id, tabIndex } = this.props
    const { email, focused } = this.state
    return {
      id,
      className: 'ui input',
      type: 'email',
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      tabIndex,
      value: email,
      onBlur: this._handleBlured,
      onChange: this._handleUpdated,
      onKeyPress: this._handleKeyPressed,
      onFocus: this._handleFocused
    }
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { email } = this.state
    this.props.onChange(email)
  }

  _handleClear() {
    this.setState({
      email: ''
    })
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyPressed(e) {
    if(e.code === 'Space') return e.preventDefault()
    if(e.key !== 'Enter') return
    this.props.onEnter()
  }

  _handleSet(email) {
    this.setState({ email })
  }

  _handleUpdated(email) {
    this.setState({ email })
  }

  _handleValidate() {
    const { network } = this.context
    const { email } = this.state
    const { required, onUnique } = this.props
    if(required && !email) {
      return this.props.onValid(email, ['This field is required'])
    }
    if(!email) return this.props.onValid(null)
    if(required && email.length === 0) {
      return this.props.onValid(email, ['This field is required'])
    }
    if(email.length > 0 && !EMAIL_REGEX.test(email)) {
      return this.props.onValid(email, ['Enter a valid email format'])
    }
    if(!onUnique) return this.props.onValid(email)
    onUnique({
      value: email,
      network,
      onValid: this.props.onValid
    })
  }

}

export default EmailField
