import Page from '@admin/components/page'
import itemButtons from '../item_buttons'
import itemTasks from '../item_tasks'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  check: `/api/admin/finance/checks/${props.params.id}`
})

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Check)',
  access: { rights: { $oneOf: ['finance:view_expenses','finance:manage_expenses'] } },
  panel: {
    component: <Overview check={ resources.check } />
  },
  tasks: itemTasks('check', resources.check, context, Edit),
  buttons: itemButtons(resources.app.settings, 'check', resources.check, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
