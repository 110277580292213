import SourceToken from '@admin/tokens/source'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

class Networks extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const networks = this._getSources()
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Networks)', name: 'sources', type: 'checkboxgroup', entity: 'network', options: networks, valueKey: 'source_name', textKey: 'name', format: (source) => (
              <SourceToken source={ source } />
            ), defaultValue: networks.map(n => n.source_name), required: true }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { formdata } = this.props
    return [
      ..._.includes(['photo','video','link','story','reel'], formdata.type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ..._.includes(['photo','video','story','reel'], formdata.type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      // ..._.includes(['photo','video','link'], formdata.type) ? [
      //   { name: 'LinkedIn', source_name: 'linkedin' }
      // ] : []
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(social_campaign) {
    this.props.onNext(social_campaign)
  }

}

export default Networks
