import PropTypes from 'prop-types'
import React from 'react'

const StatusToken = ({ mode, value }) => {
  const val = (mode || value)
  if(!val) return null
  return (
    <div className={`maha-mode-token ${val.replace('-','')}`}>
      { _.startCase(val.replace('-','')) }
    </div>
  )
}

StatusToken.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.string
}

export default StatusToken
