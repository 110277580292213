import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const getLabel = ({ address, contact, name }) => {
  if(contact && contact.display_name !== 'Unknown') {
    return contact.display_name
  } else {
    return name || address
  }
}

const EmailAddressToken = (email) => {
  if(!email) return null
  const { address, contact, name } = email
  const props = {
    ...!address ? {
      icon: 'exclamation-circle'
    } : {},
    label: getLabel(email),
    className: 'text',
    ...(contact || name) ? {
      tooltip: {
        title: address || 'Invalid email address',
        position: 'top center'
      }
    } : {}
  }
  return (
    <div className="maha-email-header-token">
      <Button { ...props } />
    </div>
  )
}

EmailAddressToken.propTypes = {
  address: PropTypes.string,
  contact_id: PropTypes.number,
  name: PropTypes.string
}

export default EmailAddressToken
