import EmailToken from '@admin/tokens/email'
import Panel from '@admin/components/panel'
import React from 'react'

const getEmail = (email) => ({
  type: 'incoming',
  from: {
    id: email.id,
    icon: 'envelope',
    name: email.from.name || email.from.address
  },
  subject: email.subject,
  sent_at: email.date
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Incoming Email)',
  collection: {
    endpoint: '/api/admin/platform/incoming_emails',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Subject)', key: 'subject', primary: true, format: (email) => (
        <EmailToken email={ getEmail(email) } perspective="incoming" show_interactions={ false } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Incoming Email)',
      text: 't(You have not yet received any incoming email)'
    },
    entity: 'email'
  }
})

export default Panel(null, mapPropsToPanel)
