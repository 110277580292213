import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Complete extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    team: PropTypes.object,
    onDone: PropTypes.func
  }

  render() {
    const { provider } = this.context
    const { team } = this.props
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon="check-circle" /></h1>
            <h2><T text="t(Congratulations)" /></h2>
            <p>Your account for <strong>{ team.title }</strong> has been successfully activated! You may now begin using { provider.title }</p>
            <div className="field button-field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getContinue() {
    const { onDone } = this.props
    return {
      label: 't(Continue)',
      color: 'blue',
      size: 'large',
      handler: onDone
    }
  }

}

export default Complete
