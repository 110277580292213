import { TransitionGroup, CSSTransition } from 'react-transition-group'
import TwoFactor from './twofactor'
import PropTypes from 'prop-types'
import Password from './password'
import Lockout from './lockout'
import Blocked from './blocked'
import Email from './email'
import React from 'react'

class Signin extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      flash: PropTypes.object,
      host: PropTypes.object,
      network: PropTypes.object,
      provider: PropTypes.object,
      router: PropTypes.object
    })
  }

  state = {
    direction: 'forward',
    mode: 'email',
    previous: null,
    token: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleRedirect = this._handleRedirect.bind(this)
  _handleSignin = this._handleSignin.bind(this)

  render() {
    const { direction, mode } = this.state
    if(!mode) return null
    const Component = this._getComponent()
    return (
      <div className={`maha-signin ${direction}`}>
        <TransitionGroup component={ null }>
          <CSSTransition key={ mode } timeout={ 500 } classNames="slide" appear={ false }>
            <Component  { ...this._getProps() } />
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }

  _getComponent() {
    const { mode } = this.state
    if(mode === 'email') return Email
    if(mode === 'password') return Password
    if(mode === 'twofactor') return TwoFactor
    if(mode === 'lockout') return Lockout
    if(mode === 'blocked') return Blocked
  }

  _getProps() {
    const { context } = this.props
    return {
      context,
      ...this.state,
      onBack: this._handleBack,
      onNext: this._handleNext,
      onRedirect: this._handleRedirect,
      onSignin: this._handleSignin
    }
  }
 
  _handleBack() {
    const { previous } = this.state
    this.setState({
      direction: 'backward',
      mode: previous
    })
  }
    
  _handleNext(mode, values) {
    this.setState({
      ...values || {},
      direction: 'forward',
      mode,
      previous: this.state.mode
    })  
  }

  _handleRedirect(account) {
    const url = `/admin/auth/${account.authentication_strategy}`
    this.props.context.host.window.signin(url)
  }

  _handleSignin() {
    const { account } = this.state
    this.props.context.admin.signin(account)
    this.props.context.router.replace('/')
  } 

}

export default Signin
