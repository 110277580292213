import UnscheduledToken from './token'
import PropTypes from 'prop-types'
import React from 'react'

class SocialSchedule extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    onEdit: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="campaigns-social-schedule-tiles">
        { records.map((social_campaign, index) => (
          <div className="campaigns-social-schedule-tile" key={`social_campaign_${social_campaign.id}`}>
            <UnscheduledToken { ...this._getToken(social_campaign) } />
          </div>
        ))}
      </div>
    )
  }

  _getToken(social_campaign) {
    const { onEdit } = this.props
    return {
      social_campaign,
      onEdit
    }
  }

}

export default SocialSchedule
