const calendar = [
  { 
    code: 'assigned_to_me',
    entity: 'task',
    action: 'assigned to you is due',
    query: `
    select tasks_tasks.id, 
    tasks_tasks.team_id, 
    'tasks:assigned_to_me' as code,
    date(tasks_tasks.due_at) as date
    from tasks_tasks
    inner join tasks_assignees on tasks_assignees.task_id=tasks_tasks.id
    where tasks_tasks.due_at is not null
    and tasks_tasks.completed_at is null
    and tasks_assignees.user_id=?
    `,
    bindings: (req) => [
      req.user.get('id')
    ]
  },

  { 
    code: 'assigned_by_me',
    entity: 'task',
    action: 'assigned by you is due',
    query: `
    select tasks_tasks.id, 
    tasks_tasks.team_id, 
    'tasks:assigned_by_me' as code,
    date(tasks_tasks.due_at) as date
    from tasks_tasks
    inner join tasks_assignees on tasks_assignees.task_id=tasks_tasks.id
    where tasks_tasks.due_at is not null
    and tasks_tasks.completed_at is null
    and tasks_tasks.owner_id=?
    and tasks_assignees.user_id!=?
    `,
    bindings: (req) => [
      req.user.get('id'),
      req.user.get('id')
    ]
  }
]

export default calendar
