import CriteriaDesigner from '@admin/components/criteria_designer'
import Criteria from '@apps/crm/admin/views/contacts/criteria'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import RecipientToken from '../../tokens/recipient'
import PropTypes from 'prop-types'
import React from 'react'

class Picker extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    fields: PropTypes.array,
    instructions: PropTypes.any,
    program: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    criteria: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <CriteriaDesigner { ...this._getCriteriaDesigner() } />
      </ModalPanel>
    )
  }

  _getCriteriaDesigner() {
    const { channel, defaultValue, endpoint, fields, program, program_id, purpose } = this.props
    return {
      defaultValue: defaultValue ? defaultValue.criteria : null,
      defaultQuery: {
        channel,
        program_id,
        purpose,
        strategy: 'criteria'
      },
      endpoint,
      entity: 'contact',
      fields: Criteria([{
        ...program,
        fields
      }], this.context),
      format: (recipient) => <RecipientToken recipient={ recipient } />,
      onChange: this._handleChange
    }
  }

  _getPanel() {
    const { instructions } = this.props
    const { criteria } = this.state
    return {
      title: 't(Select Criteria)',
      instructions,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: criteria.length > 1 ? [
        { label: 't(Done)', handler: this._handleDone }
      ] : null
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange(criteria) {
    this.setState({ criteria })
  }

  _handleDone() {
    const { criteria } = this.state
    this.props.onDone({ criteria })
    this.context.form.pop()
  }

}

const mapResources = (props, context) => ({
  fields: `/api/admin/team/programs/${props.program_id}/fields`,
  program: `/api/admin/team/programs/${props.program_id}`
})

export default Container(mapResources)(Picker)
