import Form from '@admin/components/form'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class FiltersField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    filters: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.filters) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { filters } = this.state
    if(!_.isEqual(filters, prevState.filters)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { filters } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Hue)', name: 'hue', type: WebRange, units: [
              { name: 'deg', min: 0, max: 359, step: 1 }
            ], nullValues: ['0deg'], defaultValue: '0deg', value: filters.hue },
            { label: 't(Saturation)', name: 'saturation', type: WebRange, units: [
              { name: '%', min: 0, max: 200, step: 1 }
            ], min: 0, nullValues: ['100%'], defaultValue: '100%', value: filters.saturation },
            { label: 't(Brightness)', name: 'brightness', type: WebRange, units: [
              { name: '%', min: 0, max: 200, step: 1 }
            ], nullValues: ['100%'], defaultValue: '100%', value: filters.brightness },
            { label: 't(Contrast)', name: 'contrast', type: WebRange, units: [
              { name: '%', min: 0, max: 200, step: 1 }
            ], nullValues: ['100%'], defaultValue: '100%', value: filters.contrast },
            { label: 't(Invert)', name: 'invert', type: WebRange, units: [
              { name: '%', min: 0, max: 100, step: 1 }
            ], nullValues: ['0%'], defaultValue: '0%', value: filters.invert },
            { label: 't(Sepia)', name: 'sepia', type: WebRange, units: [
              { name: '%', min: 0, max: 100, step: 1 }
            ], nullValues: ['0%'], defaultValue: '0%', value: filters.sepia },
            { label: 't(Opacity)', name: 'opacity', type: WebRange, units: [
              { name: '%', min: 0, max: 100, step: 1 }
            ], nullValues: ['100%'], defaultValue: '100%', value: filters.opacity },
            { label: 't(Blur)', name: 'blur', type: WebRange, units: [
              { name: 'px', min: 0, max: 50, step: 1 }
            ], nullValues: ['0px'], defaultValue: '0px', value: filters.blur },
            { label: 't(Blend Mode)', name: 'blendMode', type: 'dropdown', options: [
              { value: 'normal', text: 't(Normal)' },
              { value: 'multiply', text: 't(Multiply)' },
              { value: 'screen', text: 't(Screen)' },
              { value: 'overlay', text: 't(Overlay)' },
              { value: 'darken', text: 't(Darken)' },
              { value: 'lighten', text: 't(Lighten)' },
              { value: 'color-dodge', text: 't(Color Dodge)' },
              { value: 'color-burn', text: 't(Color Burn)' },
              { value: 'hard-light', text: 't(Hard Light)' },
              { value: 'soft-light', text: 't(Soft Light)' },
              { value: 'difference', text: 't(Difference)' },
              { value: 'exclusion', text: 't(Exclusion)' },
              { value: 'hue', text: 't(Hue)' },
              { value: 'saturation', text: 't(Saturation)' },
              { value: 'color', text: 't(Color)' },
              { value: 'luminosity', text: 't(Luminosity)' }
            ], defaultValue: 'normal', value: filters.blendMode }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { filters } = this.state
    const { hue, blur, sepia, invert, opacity, contrast, brightness, saturation, blendMode } = filters
    const value = {
      ...(hue && hue !== '0deg' ) ? { hue } : {},
      ...(blur && blur !== '0px' ) ? { blur } : {},
      ...(sepia && sepia !== '0%' ) ? { sepia } : {},
      ...(invert && invert !== '0%' ) ? { invert } : {},
      ...(opacity && opacity !== '100%' ) ? { opacity } : {},
      ...(contrast && contrast !== '100%' ) ? { contrast } : {},
      ...(brightness && brightness !== '100%' ) ? { brightness } : {},
      ...(saturation && saturation !== '100%' ) ? { saturation } : {},
      blendMode
    }
    this.props.onChange(Object.keys(value).length > 1 ? value : null)
  }

  _handleSet(filters) {
    const value = filters || {}
    this.setState({
      filters: {
        hue: '0deg',
        blur: '0px',
        sepia: '0%',
        invert: '0%',
        opacity: '100%',
        contrast: '100%',
        brightness: '100%',
        saturation: '100%',
        blendMode: 'normal',
        ...value
      }
    })
  }

  _handleUpdate(filters) {
    this.setState({ filters })
  }

}

export default FiltersField
