import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.agreement.unsigned ? resources.agreement.unsigned.file_name : resources.agreement.signed.file_name,
  manager: {
    path: `/admin/crm/agreements/${resources.agreement.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview agreement={ resources.agreement } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  agreement: `/api/admin/crm/agreements/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
