import EmailAddressToken from '@apps/crm/admin/tokens/email_address'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.PureComponent {

  static propTypes = {
    q: PropTypes.string,
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="maha-recipientfield-results">
        { records.map((email_address, index) => (
          <div className="maha-recipientfield-result" key={`result_${email_address.id}`} onClick={ this._handleChoose.bind(this, email_address) }>
            <EmailAddressToken { ...email_address } />
          </div>
        ))}
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { q } = this.props
    if(q !== prevProps.q) {
      this._handleFind()
    }
  }

  _handleChoose(email_address) {
    const { address, contact } = email_address
    this.props.onChoose({
      name: contact.display_name !== 'Unknown' ? contact.display_name : null,
      address
    })
  }

  _handleFind() {
    const { q, records } = this.props
    const found = records.find(record => {
      return record.address === q
    })
    if(!found) return
    this._handleChoose(found)
  }

}

export default Results
