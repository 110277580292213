import AdminIconToken from '@admin/tokens/icon'
import PropTypes from 'prop-types'
import React from 'react'

const getIcon = (icon) => {
  return {
    fg: '2185d0',
    w: 24,
    icon: {
      noun_project_id: icon.noun_project_id
    }
  }
}

const IconToken = (icon) => (
  <div className="maha-icon-token">
    <div className="maha-icon-token-image">
      <AdminIconToken { ...getIcon(icon) } />
    </div>
    <div className="maha-icon-token-label">
      { icon.noun_project_id }<br />
      <span>{ icon.tags }</span>
    </div>
  </div>
)

IconToken.propTypes = {
  icon: PropTypes.object
}

export default IconToken
