import Dependencies from '@admin/components/dependencies'
import ReactQuill, { Quill } from 'react-quill'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

const map = {
  b: 'bold',
  em: 'italic',
  u: 'underline',
  ul: { list: 'bullet' },
  ol: { list: 'ordered' },
  a: 'link'
}

const getIcon = (icon, tooltip) => {
  return `<div data-tooltip="${tooltip}" data-inverted="true"><i class="fa fa-${icon}"></i></div>`
}

const icons = Quill.import('ui/icons')
icons.bold = getIcon('bold', 'Bold')
icons.italic = getIcon('italic', 'Italic')
icons.underline = getIcon('underline', 'Underline')
icons.link = getIcon('link', 'Insert Link')

class TextField extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    defaultValue: '',
    onChange: (value) => {},
    onReady: () => {}
  }

  quillRef = React.createRef()

  _handleChange = _.throttle(this._handleChange.bind(this), 500)
  _handlePaste = this._handlePaste.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { value, defaultValue } = props
    const def = !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : ''
    this.state = {
      defaultValue: def,
      value: def
    }
  }

  render() {
    return (
      <div className="maha-htmlfield">
        <ReactQuill { ...this._getEditor() } />
      </div>
    )
  }

  componentDidMount() {
    this._handleInit()
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : ''
  }

  _getEditor() {
    const { tabIndex, placeholder } = this.props
    const { defaultValue } = this.state
    return {
      defaultValue,
      ref: this.quillRef,
      onChange: this._handleUpdate,
      placeholder,
      tabIndex,
      modules: {
        toolbar: {
          container: [Object.values(map)]
        },
        clipboard: {
          matchVisual: false
        }
      },
      formats: [
        'bold', 'italic', 'underline',
        'list', 'bullet', 'ordered',
        'link'
      ]
    }
  }

  _handleChange() {
    const { value } = this.state
    const sanitized = value.replace(/&lt;%-/g,'<%-').replace(/%&gt;/g,'%>')
    this.props.onChange(sanitized)
  }

  _handleInit() {
    const editor = this.quillRef.current.getEditor()
    editor.clipboard.addMatcher(Node.ELEMENT_NODE, this._handlePaste)
  }

  _handlePaste(node, delta) {
    let ops = []
    delta.ops.forEach(op => {
      delete op.attributes
      if(op.insert && typeof op.insert === 'string') ops.push(op)
    })
    delta.ops = ops
    return delta
  }

  _handleUpdate(value) {
    const cleanedValue = this._cleanupLineBreaks(value)
    this.setState({ value: cleanedValue })
  }

  _cleanupLineBreaks(html) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const processNode = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName === 'P' && node.innerHTML.trim() === '<br>') {
          const prev = node.previousElementSibling
          const next = node.nextElementSibling
          if ((!prev || !this._isBlockElement(prev)) && (!next || !this._isBlockElement(next))) {
            if (node.parentNode === doc.body && !node.nextSibling) {
              return
            }
            node.parentNode.removeChild(node)
          }
        } else {
          Array.from(node.childNodes).forEach(processNode)
        }
      }
    }
    processNode(doc.body)
    html = doc.body.innerHTML
    if (html.endsWith('<p><br></p>')) {
      html = html.slice(0, -11) + '<p><br></p>'
    }
    return html
  }

  _isBlockElement(element) {
    const blockElements = ['P', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'UL', 'OL', 'LI', 'BLOCKQUOTE']
    return blockElements.includes(element.tagName)
  }
}

const dependencies = {
  styles: [
    { url: '/css/quill.snow.css' }
  ]
}

TextField = Dependencies(dependencies)(TextField)

export default TextField