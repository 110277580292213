export const onAdd = (network) => ({
  type: 'ADD',
  network
})

export const onRemove = (index) => ({
  type: 'REMOVE',
  index
})

export const onSet = (networks) => ({
  type: 'SET',
  networks
})

export const onUpdate = (index, network) => ({
  type: 'UPDATE',
  index,
  network
})
