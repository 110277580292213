import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  label: 't(Record)',
  manager: {
    path: `/admin/datasets/datasets/${resources.dataset.id}/types/${resources.type.id}/records/${resources.record.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview dataset={ resources.dataset } fields={ resources.fields } type={ resources.type } record={ resources.record } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  dataset: `/api/admin/datasets/datasets/${props.params.dataset_id}`,
  fields: `/api/admin/datasets_types/${props.params.type_id}/fields`,
  record: `/api/admin/datasets/datasets/${props.params.dataset_id}/types/${props.params.type_id}/records/${props.params.id}`,
  type: `/api/admin/datasets/datasets/${props.params.dataset_id}/types/${props.params.type_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
