import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    contact: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_addresses, positions } = this.props.contact
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...positions.length > 0 ? [
              { label: 'Organization', name: 'position_id', type: 'radiogroup', options: positions.map(position => (
                { value: position.id, text: position.organization }
              )), defaultValue: positions.length > 0 ? positions[0].id : null }
            ] : [],
            { label: 'Communication', type: 'segment', fields: [
              { label: 'To', name: 'to', type: 'recipientfield', multiple: false, defaultValue: [{ name: email_addresses[0].name, address: email_addresses[0].address}] },
              { label: 'Cc', name: 'cc', type: 'recipientfield' },
              { label: 'Bcc', name: 'bcc', type: 'recipientfield' }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    const { contact } = this.props
    this.props.onDone({
      contact_id: contact.id,
      ...subscription
    })
  }

}

const mapResources = (props, context) => ({
  contact: `/api/admin/crm/contacts/${props.contact_id}`
})

export default Container(mapResources)(Details)
