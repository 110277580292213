import Form from '@admin/components/form'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class BoxShadowField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    boxShadow: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.boxShadow) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { boxShadow } = this.state
    if(!_.isEqual(boxShadow, prevState.boxShadow)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { boxShadow } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'none', text: 't(No Shadow)' },
              { value: 'inner', text: 't(Inner Shadow)' },
              { value: 'outer', text: 't(Outer Shadow)' }
            ], value: boxShadow.type },
            ...this._getPreset()
          ]
        }
      ]
    }
  }

  _getPreset() {
    const { boxShadow } = this.state
    const { theme } = this.props
    if(boxShadow.type !== 'none') {
      return [
        { label: 'Horizontal Position', name: 'offsetX', type: WebRange, units: [
          { name: 'px', min: -50, max: 50, step: 1 }
        ], nullValues: ['0px'], defaultValue: '0px', value: boxShadow.offsetX },
        { label: 'Vertical Position', name: 'offsetY', type: WebRange, units: [
          { name: 'px', min: -50, max: 50, step: 1 }
        ], nullValues: ['0px'], defaultValue: '0px', value: boxShadow.offsetY },
        { label: 'Blur', name: 'blur', type: WebRange, units: [
          { name: 'px', min: 0, max: 50, step: 1 }
        ], nullValues: ['0px'], defaultValue: '0px', value: boxShadow.blur },
        { label: 'Spread', name: 'spread', type: WebRange, units: [
          { name: 'px', min: -50, max: 50, step: 1 }
        ], nullValues: ['0px'], defaultValue: '0px', value: boxShadow.spread },
        { label: 't(Color)', name: 'color', type: ColorField, theme, value: boxShadow.color }
      ]
    }
    return []
  }

  _handleChange() {
    const { boxShadow } = this.state
    this.props.onChange(boxShadow.type !== 'none' ? {
      type: boxShadow.type,
      offsetX: boxShadow.offsetX,
      offsetY: boxShadow.offsetY,
      blur: boxShadow.blur,
      spread: boxShadow.spread,
      color: boxShadow.color
    } : null)
  }

  _handleSet(boxShadow) {
    this.setState({
      boxShadow: {
        type: 'none',
        offsetX: '-3px',
        offsetY: '3px',
        blur: '3px',
        spread: '3px',
        color: {
          value: '#DFDFDF',
          brightness: '100%',
          opacity: '100%'
        },
        ...boxShadow || {}
      }
    })
  }

}

export default BoxShadowField
