import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  photo: {
    title: 't(Photo Post)',
    text: 't(Post with one or more photos and a caption)',
    // services: ['Facebook','Instagram','LinkedIn']
    services: ['Facebook','Instagram']
  },
  video: {
    title: 't(Video Post)',
    text: 't(Post with a video and a caption)',
    // services: ['Facebook','Instagram','LinkedIn']
    services: ['Facebook','Instagram']
  },
  link: {
    title: 't(Link Post)',
    text: 't(Post with a link and a caption)',
    // services: ['Facebook','LinkedIn']
    services: ['Facebook']
  },
  reel: {
    title: 't(Reel)',
    text: 't(Reel with a video)',
    services: ['Facebook','Instagram']
  },
  story: {
    title: 't(Story)',
    text: 't(Story with a photo or video)',
    services: ['Facebook','Instagram']
  }
}

const SocialTypeToken = ({ value }) => (
  <div className="truevail-social-type-token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <span>{ service_types[value].services.join(', ') }</span><br />
    <T text={ service_types[value].text } />
  </div>
)

SocialTypeToken.propTypes = {
  value: PropTypes.string
}

export default SocialTypeToken
