import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ project, integration }) => {

  const details = {
    audits: `finance_projects/${project.id}`,
    comments: `finance_projects/${project.id}`,
    sections: [
      {
        title: 't(Project Details)',
        items: [
          { label: 't(Title)', content: project.title },
          { label: 't(Type)', content: project.type.toUpperCase() },
          { label: 'Tax Account', content: { project: project.tax_project }, format: CompactProjectToken }
        ]
      }
    ]
  }

  if(!project.is_active) {
    details.alert = { color: 'red', message: 't(This project is disabled)' }
  }

  if(integration) {
    details.sections.push({
      title: <IntegrationToken integration={ integration } />,
      padded: false,
      items: [
        { label: 'County Project Code', content: project.integration.project_code },
        { label: 'Main Project Code', content: project.integration.main_project_code },
        { label: 'Program Code', content: project.integration.program_code },
        { label: 'Source Code', content: project.integration.source_code },
        { label: 'Match', content: project.integration.match }
      ]
    })
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  integration: PropTypes.string,
  project: PropTypes.object
}

export default Overview
