import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Panel from './panel'
import React from 'react'

class Emojis extends React.Component {

  static contextTypes = {
    local_storage: PropTypes.object
  }

  static propTypes = {
    changing: PropTypes.bool,
    skinTone: PropTypes.string,
    skinToneIndex: PropTypes.number,
    status: PropTypes.string,
    onChangeSkinTone: PropTypes.func,
    onChoose: PropTypes.func,
    onSetSkinToneIndex: PropTypes.func
  }

  static defaultProps = {
    onChoose: () => {}
  }

  emojisRef = React.createRef()

  state = {
    position: 'above',
    show: false
  }

  _handleClickOutside = this._handleClickOutside.bind(this)
  _handleSaveSkinTone = this._handleSaveSkinTone.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { position, show } = this.state
    return (
      <div className="maha-emojis" ref={ this.emojisRef }>
        <div className="maha-emojis-icon" onClick={ this._handleToggle }>
          <Icon icon="smile-o" />
        </div>
        { show &&
          <div className={`maha-emojis-chooser ${position}`}>
            <div className="maha-emojis-chooser-inner">
              <Panel { ...this._getPanel() } />
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('mousedown', this._handleClickOutside)
    this.context.local_storage.get({
      key: 'skintone',
      onSuccess: (value) => {
        this.props.onSetSkinToneIndex(value)
      },
      onFailure: () => {}
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this._handleClickOutside)
  }

  _getPanel() {
    const { changing, skinTone, onChangeSkinTone, onChoose } = this.props
    return {
      changing,
      skinTone,
      onChangeSkinTone,
      onChoose,
      onSaveSkinTone: this._handleSaveSkinTone
    }
  }

  _handleClickOutside(e) {
    if(e.target.className === 'fa fa-smile-o') return
    if(this.emojisRef.current && this.emojisRef.current.contains(e.target)) return
    this.setState({
      show: false
    })
  }

  _handleSaveSkinTone(index) {
    this.context.local_storage.set({
      key: 'skintone',
      value: index,
      onSuccess: () => {
        this.props.onSetSkinToneIndex(index)
      }
    })
  }

  _handleToggle(e) {
    const { show } = this.state
    const percent = (e.clientY / window.innerHeight) * 100
    this.setState({
      position: percent < 75 ? 'below' : 'above',
      show: !show
    })
  }

}

export default Emojis
