export const actions = [
  { value: 'hide', text: 'hide' },
  { value: 'show', text: 'show' }
]

export const comparisons = [
  { value: '$eq', text: 't(equals)', types: ['textfield','textarea','emailfield','phonefield','hiddenfield'] },
  { value: '$neq', text: 't(does not equal)', types: ['textfield','textarea','emailfield','phonefield','hiddenfield'] },
  { value: '$ct', text: 't(contains)', types: ['textfield','textarea','emailfield','phonefield','hiddenfield'] },
  { value: '$nct', text: 't(does not contain)', types: ['textfield','textarea','emailfield','phonefield','hiddenfield'] },
  { value: '$eq', text: 't(is)', types: ['optionsfield','radiogroup','dropdown'] },
  { value: '$neq', text: 't(is not)', types: ['optionsfield','radiogroup','dropdown'] },
  { value: '$in', text: 't(is one of)', types: ['optionsfield','radiogroup','dropdown'] },
  { value: '$nin', text: 't(is not one of)', types: ['optionsfield','radiogroup','dropdown'] },
  { value: '$ck', text: 't(is checked)', types: ['checkbox'] },
  { value: '$nck', text: 't(is not checked)', types: ['checkbox'] },
  { value: '$nl', text: 't(has been uploaded)', types: ['filefield'] },
  { value: '$nnl', text: 't(has not been uploaded)', types: ['filefield'] },
  { value: '$int', text: 't(is one of)', types: ['checkboxes'] },
  { value: '$nint', text: 't(is not one of)', types: ['checkboxes'] },
  { value: '$eq', text: 't(is equal to)', types: ['numberfield','moneyfield'] },
  { value: '$lt', text: 't(is less than)', types: ['numberfield','moneyfield'] },
  { value: '$lte', text: 't(is less than or equal to)', types: ['numberfield','moneyfield'] },
  { value: '$gt', text: 't(is greater than)', types: ['numberfield','moneyfield'] },
  { value: '$gte', text: 't(is greater than or equal to)', types: ['numberfield','moneyfield'] }
]
