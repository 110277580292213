import RegistrationToken from '@apps/events/admin/tokens/registration'
import Page from '@admin/components/page'

const mapPropsToPage = (props, context) => ({
  title: 't(Registrations)',
  collection: {
    endpoint: '/api/admin/dashboards/events/registrations',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: RegistrationToken },
      { label: 't(Event)', key: 'event.title', sort: 'event', primary: true},
      { label: 't(Registered)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'user-circle-o',
      title: 't(No Registrations)',
      text: 't(No one has registered for an event)'
    },
    entity: 'registration',
    onClick: (record) => context.router.push(`/events/events/${record.event.id}/registrations/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
