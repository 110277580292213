import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: `/api/admin/crm/lists/${props.list.id}/subscriptions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => (
        <ContactToken { ...contact } />
      ) },
      ...props.unsubscribed_at ? [
        { label: 't(Unsubscribed)', key: 'unsubscribed_at', visible: true, collapsing: true, format: 'datetime' }
      ] : [
        { label: 't(Subscribed)', key: 'created_at', visible: true, collapsing: true, format: 'datetime' }
      ]
    ],
    defaultQuery: {
      unsubscribed_at: props.unsubscribed_at
    },
    defaultSort: { key: props.unsubscribed_at ? 'unsubscribed_at' : 'created_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Subscriptions)',
      text: 't(No contacts is subscribed to this list)'
    },
    entity: 'subscription',
    recordTasks: (record) => [
      {
        label: 't(Unsubscribe Contact)',
        request: {
          endpoint: `/api/admin/crm/lists/${props.list.id}/subscriptions/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Contact was successfully unsubscribed)'),
          onFailure: () => context.flash.set('error', 't(Unable to subscribe contact)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/crm/contacts/${record.contact.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
