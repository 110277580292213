import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sender Domains)',
  collection: {
    endpoint: '/api/admin/truevail/admin/sender_domains',
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', sort: 'name', primary: true },
      { label: 't(Domain)', key: 'domain_status', collapsing: true, format: StatusToken },
      { label: 't(DKIM)', key: 'dkim_status', collapsing: true, format: StatusToken },
      { label: 't(Mail From)', key: 'mail_from_domain_status', collapsing: true, format: StatusToken },
      { label: 't(DMARC)', key: 'dmarc_status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Domain)', key: 'domain_status', type: 'select', multiple: true, options: ['pending','verified','expired'], format: StatusToken },
            { name: 't(DKIM)', key: 'dkim_status', type: 'select', multiple: true, options: ['pending','verified','expired'], format: StatusToken },
            { name: 't(Mail From)', key: 'mail_from_domain_status', type: 'select', multiple: true, options: ['pending','verified','expired'], format: StatusToken },
            { name: 't(DMARC)', key: 'dmarc_status', type: 'select', multiple: true, options: ['pending','verified','expired'], format: StatusToken },
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Verified)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'domain_status', operator: '$eq', parent: null, value: 'verified' }
          ]
        } },
        { id: 1, title: 't(Unverified)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'domain_status', operator: '$neq', parent: null, value: 'verified' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      icon: 'paper-plane-o',
      title: 't(No Sender Domains)',
      text: 't(You have not yet created any sender domains)'
    },
    entity: 'sender domain',
    onClick: (record) => context.router.push(`/truevail/admin/sender_domains/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
