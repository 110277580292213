import React, { useState, useRef, useImperativeHandle, useEffect } from 'react'
import { createLogger } from '@core/utils/console'
import PropTypes from 'prop-types'

const logAction = createLogger('olive')

const Stack = React.forwardRef((props, ref) => {

  const { cards: propCards, display_name, initial, slideFirst = false } = props

  const label = display_name ? `stack.${display_name}` : 'stack'
  
  const [cards, setCards] = useState(initial || propCards || [])
  const [initialized, setInitialized] = useState(slideFirst)
  const [mounted, setMounted] = useState(initial?.length || propCards?.length || 0)
  const timeouts = useRef([])

  useEffect(() => {
    return () => {
      timeouts.current.forEach(clearTimeout)
    }
  }, [])

  useEffect(() => {
    if(!propCards) return
    if(propCards.length > cards.length) {
      propCards.slice(cards.length - propCards.length).map(card => {
        push(card)
      })
    }
    if(propCards.length < cards.length) {
      pop(propCards.length - cards.length)
    }
  }, [propCards?.length])

  const push = function(card) {
    setCards(prevCards => {
      const nextCards = [...prevCards, card]
      logAction(`${label}/PUSH`, { prevCards, nextCards })
      return nextCards
    })
    const timeoutId = setTimeout(() => {
      setMounted(prevMounted => prevMounted + 1)
      setInitialized(true)
    }, 250)
    timeouts.current.push(timeoutId)
  }

  const pop = function() {
    const index = typeof arguments[0] === 'number' ? arguments[0] : -1
    if (cards.length === 0) return
    setMounted(prevMounted => prevMounted + index)
    const timeoutId = setTimeout(() => {
      setCards(prevCards => {
        const nextCards = prevCards.slice(0, index)
        logAction(`${label}/POP`, { prevCards, nextCards })
        return nextCards
      })
    }, 250)
    timeouts.current.push(timeoutId)
  }

  useImperativeHandle(ref, () => ({
    push,
    pop 
  }))

  const getCard = (card, index) => {
    const create = _.isFunction(card.component) || !!card.component.WrappedComponent
    const method = create ? React.createElement : React.cloneElement
    return method(card.component, getProps(index))
  }

  const getProps = (index) => {
    const card = cards[index]
    const { props } = card
    return {
      active: index === cards.length - 1,
      ..._.isFunction(props) ? props() : {},
      ..._.isPlainObject(props) ? props : {}
    }
  }

  const getCardStatus = (index) => {
    const mountedIndexes = mounted - 1
    const cardIndexes = cards.length - 1
    if (!initialized && mountedIndexes === -1) return 'active'
    if (index > mountedIndexes && index === cardIndexes) return 'mounting'
    if (index === mountedIndexes && index === cardIndexes) return 'active'
    if (index === mountedIndexes && index < cardIndexes) return 'covering'
    if (index < cardIndexes) return 'covered'
  }

  const getCardStyle = (index) => {
    const status = getCardStatus(index)
    if(status === 'active') return { transform: 'translateX(0)' }
    if(status === 'mounting') return { transform: 'translateX(100%)' }
    if(status === 'covered') return { transform: 'translateX(-25%)' }
    return {}
  }

  const getCoverStyle = (index) => {
    const status = getCardStatus(index)
    if(status === 'covered') return { opacity: '0.2' }
    return {}
  }

  if(cards.length === 0) return null

  return (
    <div className="maha-stack">
      { cards.map((card, index) => (
        <div key={`card_${index}`} className="maha-stack-card" style={ getCardStyle(index) }>
          { getCard(card, index) }
          { index < cards.length - 1 &&
            <div className="maha-stack-card-cover" style={ getCoverStyle(index) } />        
          }
        </div>
      )) }
    </div>
  )

})

Stack.propTypes = {
  cards: PropTypes.array,
  display_name: PropTypes.string,
  initial: PropTypes.array,
  slideBeneath: PropTypes.bool,
  slideFirst: PropTypes.bool
}

export default Stack
