import EmailToken from '@apps/automation/admin/tokens/email'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: '/api/admin/automation/emails',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email) => (
        <EmailToken email={ email } compact={ true } />
      ) }
    ],
    empty: {
      icon: 'envelope-o',
      title: 't(No Emails)',
      text: 't(There are no emails attached to any workflows)'
    },
    defaultQuery: { form_id: props.form.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'email',
    onClick: (record) => context.router.push(`/automation/emails/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
