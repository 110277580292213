import Assignment from '@admin/components/assignment'
import AssigneeToken from '@admin/tokens/assignee'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    dataset: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Assignment {...this._getAssignment()} />
  }

  _getAssignment() {
    const { dataset } = this.props
    return {
      title: 't(Manage Access)',
      action: `/api/admin/datasets/datasets/${dataset.id}/access`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        access: assigned.map(assignee => ({
          grouping_id: assignee.grouping_id,
          user_id: assignee.user_id,
          type: assignee.type
        }))
      }),
      assigneeFormat: (assignee) => <AssigneeToken { ...assignee } />,
      assignedEndpoint: `/api/admin/datasets/datasets/${dataset.id}/access`,
      unassignedEndpoint: {
        endpoint: '/api/admin/assignees',
        query: {
          $filter: {
            is_active: {
              $eq: true
            }
          }
        }
      },
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.full_name.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          if(assignment.grouping_id && assignment.grouping_id === assignee.grouping_id) return true
          if(assignment.user_id && assignment.user_id === assignee.user_id) return true
          return false
        }) === undefined
      },
      types: ['view','contribute','manage'],
      typeKey: 'type',
      onCancel: this._handleCancel,
      onDone: this._handleSuccess
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Manage
