import Report from '@admin/components/report'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const data = (data, key) => data.map(row => ({
  x: row.collected_at,
  y: row[key]
}))

class Performance extends React.Component {

  static propTypes = {
    profile: PropTypes.object,
    performance: PropTypes.object
  }

  render() {
    return <Report { ...this._getReport() } />
  }

  _getReport() {
    const { performance, profile } = this.props
    const { age, gender, city, country } = performance.demographics
    return {
      title: profile.name || profile.username,
      link: {
        label: `View on ${profile.service.name.replace(' for Business', '')}`,
        className: 'link',
        link: profile.url
      },
      charts: { 
        label: 't(Page Insights)',
        options: {
          x_min: moment().subtract(30, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          x_max: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          x_ticks: 8
        },
        charts: [
          ...profile.service.source === 'facebook' ? [
            { 
              label: 't(Page Likes)',
              description: 't(Number of people who have liked or followed your page)',
              data: data(performance.data, 'fans'),
              metric: performance.metrics.fans
            },
            { 
              label: 't(Impressions)',
              description: 't(Number of times any content from or about your page entered a person\'s screen)',
              data: data(performance.data, 'impressions'),
              metric: performance.metrics.impressions
            },
            { 
              label: 't(Reach)',
              description: 't(Number of people who had any content from or about your page enter their screen)',
              data: data(performance.data, 'reach'),
              metric: performance.metrics.reach
            },
            { 
              label: 't(Page Views)',
              description: 't(Total times your Page has been viewed by people logged in or logged out of Facebook)',
              data: data(performance.data, 'views'),
              metric: performance.metrics.views
            },
            { 
              label: 't(Reactions)',
              description: 't(Total reactions to posts on your page, including like, love, care, wow, haha, sad, and anger)',
              data: data(performance.data, 'reactions'),
              metric: performance.metrics.reactions
            },
            { 
              label: 't(Engagement)',
              description: 't(Total reactions, comments, shares, and post clicks, divided by reach)',
              data: data(performance.data, 'engagement'),
              metric: performance.metrics.engagement
            }
          ] : profile.service.source === 'instagram_business' ? [
            { 
              label: 't(Followers)',
              description: 't(Followers gained or lost over time)',
              data: data(performance.data, 'fans'),
              metric: performance.metrics.fans
            },
            { 
              label: 't(Impressions)',
              description: 't(Total times all your posts and stories have been seen)',
              data: data(performance.data, 'impressions'),
              metric: performance.metrics.impressions
            },
            { 
              label: 't(Reach)',
              description: 't(Unique accounts that viewed your posts)',
              data: data(performance.data, 'reach'),
              metric: performance.metrics.reach
            },
            { 
              label: 't(Profile Views)',
              description: 't(Times your profile has been viewed)',
              data: data(performance.data, 'views'),
              metric: performance.metrics.views
            },
            { 
              label: 't(Website Clicks)',
              description: 't(Accounts that clicked your Instagram bio link)',
              data: data(performance.data, 'website_clicks'),
              metric: performance.metrics.website_clicks
            }  
          ] : []
        ]
      },
      ...Object.keys(performance.demographics).length > 0 ? {
        demographics: {
          label: 't(Audience Demographics)',
          breakdowns: [
            ...age ? [
              {
                label: 't(Age)',
                title: 't(Audience by Age)',
                description: 't(Your page\'s audience broken down by age)',
                total: Object.values(age).reduce((total, value) => total + value, 0),
                data: [
                  { label: '13-17', value: age['13-17'] },
                  { label: '18-24', value: age['18-24'] },
                  { label: '25-34', value: age['25-34'] },
                  { label: '35-44', value: age['35-44'] },
                  { label: '45-54', value: age['45-54'] },
                  { label: '55-64', value: age['55-64'] },
                  { label: '65+', value: age['65+'] }
                ]
              }
            ] : [],
            ...gender ? [
              {
                label: 't(Gender)',
                title: 't(Audience by Gender)',
                description: 't(Your page\'s audience broken down by gender)',
                total: Object.values(gender).reduce((total, value) => total + value, 0),
                data: [
                  { label: 't(Female)', value: gender['F'] },
                  { label: 't(Male)', value: gender['M'] },
                  { label: 't(Prefer Not to Say)', value: gender['U'] }
                ]
              }
            ] : [],
            {
              label: 't(Top 5 Cities)',
              title: 't(Audience by City)',
              description: 't(Your page\'s audience broken down by city)',
              total: Object.values(city).reduce((total, value) => total + value, 0),
              data: Object.keys(city).sort((a,b) => {
                return city[a] < city[b] ? 1 : -1
              }).filter((i,j) => {
                return j < 5
              }).map(key => ({
                label: key,
                value: city[key]
              }))
            },
            {
              label: 't(Top 5 Countries)',
              title: 't(Audience by Country)',
              description: 't(Your page\'s audience broken down by country)',
              total: Object.values(country).reduce((total, value) => total + value, 0),
              data: Object.keys(country).sort((a,b) => {
                return country[a] < country[b] ? 1 : -1
              }).filter((i,j) => {
                return j < 5
              }).map(key => ({
                label: key,
                value: country[key]
              }))
            }
          ]
        }
      } : {},
      metrics: {
        metrics: [
          ...profile.service.source === 'facebook' ? [
            { label: 't(Engagement)', value: performance.metrics.engagement, format: 'integer' },
            { label: 't(Impressions)', value: performance.metrics.impressions, format: 'integer' },
            { label: 't(Reach)', value: performance.metrics.reach, format: 'integer' },
            { label: 't(Page Likes)', value: performance.metrics.fans, format: 'integer' },
            { label: 't(Page Views)', value: performance.metrics.views, format: 'integer' },
            { label: 't(Reactions)', value: performance.metrics.reactions, format: 'integer' }  
          ] : profile.service.source === 'instagram_business' ? [
            { label: 't(Total Posts)', value: performance.metrics.posts, format: 'integer' },
            { label: 't(Followers)', value: performance.metrics.fans, format: 'integer' },
            { label: 't(Following)', value: performance.metrics.following, format: 'integer' }
          ] : []
        ]  
      }
    }
  }

}

const mapResourcesToPanel = (props, context) => ({
  performance: `/api/admin/team/profiles/${props.profile.id}/performance`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance performance={ props.performance } profile={ props.profile } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
