import Search from '@admin/components/search'
import Logo from '@admin/components/logo'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Teams extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    teams: PropTypes.array,
    onDone: PropTypes.func,
    onReset: PropTypes.func,
    onSetMode: PropTypes.func
  }

  _handleChangeTeam = this._handleChangeTeam.bind(this)
  _handleToggleMode = this._handleToggleMode.bind(this)

  render() {
    const { team } = this.context.admin
    return (
      <div className="maha-navigation-panel">
        <div className="maha-navigation-header" onClick={ this._handleToggleMode }>
          <div className="maha-navigation-header-back">
            <Logo team={ team } width="50" />
          </div>
          <div className="maha-navigation-header-team">
            <T text="Teams" />
          </div>
          <div className="maha-navigation-header-next">
            <Icon icon="chevron-down" />
          </div>
        </div>
        <div className="maha-navigation-body">
          <Search { ...this._getSearch() } />
        </div>
      </div>
    )
  }

  _getSearch() {
    return {
      endpoint: '/api/admin/teams',
      label: 'team',
      onChange: this._handleChangeTeam,
      valueKey: null,
      format: (team) => (
        <div className="maha-navigation-account">
          <div className="maha-navigation-account-logo">
            <Logo team={ team } width="50" />
          </div>
          <div className="maha-navigation-account-title">
            { team.title }
          </div>
        </div>
      )
    }
  }

  _getTeam(team) {
    return {
      event: `clicked '${team.title}' in teams navigation`,
      className: 'maha-navigation-account',
      handler: this._handleChangeTeam.bind(this, team)
    }
  }

  _handleToggleMode() {
    this.props.onSetMode('apps')
  }

  _handleChangeTeam(team) {
    const { active, chooseTeam } = this.context.admin
    if(team.id !== active) return chooseTeam(team.id)
    this.props.onDone()
    setTimeout(() => {
      this.props.onSetMode('apps')
    }, 250)
  }

}

export default Teams
