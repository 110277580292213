import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'
import moment from 'moment'
import React from 'react'

const ranges = [
  { value: '30_days', text: 't(Last 30 days)' },
  { value: '60_days', text: 't(Last 60 days)' },
  { value: 'ytd', text: 't(Year to Date)' },
  { value: 'ltd', text: 't(Life to Date)' }
]

class ChartWrapper extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    started_at: PropTypes.any,
    pointRadius: PropTypes.number,
    borderWidth: PropTypes.number,
    displayXAxis: PropTypes.bool
  }

  static defaultProps = {
    pointRadius: 4,
    borderWidth: 3,
    displayXAxis: true
  }

  canvasRef = React.createRef()
  chart = null

  state = {
    data: null,
    range: '30_days'
  }

  _handleFetch = this._handleFetch.bind(this)
  _handlePlot = this._handlePlot.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { data } = this.state
    if(!data) return null
    return (
      <div className="maha-performance">
        <div className="maha-performance-header">
          { ranges.map((range, index) => (
            <div className="maha-performance-range" key={`range_${index}`}>
              <Button { ...this._getRange(range) } />
            </div>
          ))}
        </div>
        <div className="maha-performance-body">
          <div className="maha-performance-canvas">
            <canvas ref={ this.canvasRef } width="900" height="300" />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, range } = this.state
    if(range !== prevState.range) {
      this._handleFetch()
    }
    if(!_.isEqual(data, prevState.data)) {
      this._handlePlot()
    }
  }

  _getRange(range) {
    return {
      label: range.text,
      className: this.state.range !== range.value ? 'link' : 'text',
      handler: this._handleRange.bind(this, range.value)
    }
  }

  _getStep(start, end) {
    const diff = end.diff(start, 'days')
    if(diff <= 60) return 'day'
    return 'month'
  }

  _getQuery() {
    const { started_at } = this.props
    const { range } = this.state
    const tz = moment().tz(moment.tz.guess()).format('z')
    if(range === 'ltd') {
      const start = moment(started_at)
      const end = moment()
      const step = this._getStep(start, end)
      return {
        start: start.startOf(step).format('YYYY-MM-DD'),
        end: end.add(1, step).startOf(step).format('YYYY-MM-DD'),
        step,
        tz
      }
    } else if(range === 'ytd') {
      const start = moment().startOf('year')
      const end = moment()
      const step = this._getStep(start, end)
      return {
        start: start.startOf(step).format('YYYY-MM-DD'),
        end: end.add(1, step).startOf(step).format('YYYY-MM-DD'),
        step,
        tz
      }
    } else if(range === '60_days') {
      return {
        start: moment().subtract(60, 'days').format('YYYY-MM-DD'),
        end: moment().add(1, 'day').format('YYYY-MM-DD'),
        step: 'day',
        tz
      }
    } else if(range === '30_days') {
      return {
        start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end: moment().add(1, 'day').format('YYYY-MM-DD'),
        step: 'day',
        tz
      }
    }
  }

  _handleFetch() {
    const { endpoint } = this.props
    this.context.network.request({
      method: 'GET',
      endpoint,
      query: this._getQuery(),
      onSuccess: this._handleSuccess
    })
  }

  _handleInit() {
    const { displayXAxis } = this.props
    this.chart = new Chart(this.canvasRef.current.getContext('2d'), {
      type: 'line',
      responsive: false,
      options: {
        animation: {
          duration: 0
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: displayXAxis,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            ticks: {
              min: 0,
              stepSize: 1,
              maxTicksLimit: 5
            },
            grid: {
              display: false
            }
          }
        }
      }
    })
  }

  _handlePlot() {
    const { data } = this.state
    const { pointRadius, borderWidth } = this.props
    if(!this.chart) this._handleInit()
    // this.chart.options.scales.x.time.unit = step
    this.chart.data.datasets = [{
      label: 't(Responses)',
      data,
      borderColor: '#DB2828',
      backgroundColor: '#DB282822',
      fill: true,
      pointBackgroundColor: '#FFFFFF',
      pointRadius,
      pointHoverBackgroundColor: '#DB2828',
      pointHoverRadius: pointRadius,
      borderWidth
    }]
    this.chart.update()
  }

  _handleRange(range) {
    this.setState({ range })
  }

  _handleSuccess(result) {
    this.setState({
      data: result.data
    })
  }

}

export default ChartWrapper
