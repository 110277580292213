import ProgramToken from '@apps/crm/admin/tokens/program'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Refunds)',
  collection: {
    endpoint: '/api/admin/finance/refunds',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (refund) => <ProgramToken { ...refund.payment.invoice.program } /> },
      { label: 't(Date)', key: 'created_at', collapsing: true, format: 'date' },
      { label: 't(Amount)', key: 'amount', collapsing: true, align: 'right' }
    ],
    empty: {
      title: 't(No Refunds)',
      text: 't(There are no refunds for this contact)',
      icon: 'dollar'
    },
    entity: 'refunds',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/refunds/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
