import Form from '@admin/components/form'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class BorderField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    border: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.border) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { border } = this.state
    const { value } = this.props
    if(!_.isEqual(border, prevState.border)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { theme } = this.props
    const { border } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'none', text: 't(No Border)' },
              { value: 'full', text: 't(Full Border)' },
              { value: 'top', text: 't(Top Border)' },
              { value: 'right', text: 't(Right Border)' },
              { value: 'bottom', text: 't(Bottom Border)' },
              { value: 'left', text: 't(Left Border)' }
            ], value: border.type },
            ...border.type !== 'none' ? [
              { label: 't(Width)', name: 'width', type: WebRange, units: [
                { name: 'px', min: 0, max: 50, step: 1 }
              ], nullValues: ['0px'], defaultValue: '0px', value: border.width },
              { label: 't(Style)', name: 'style', type:  'dropdown', options: [
                { value: 'solid', text: 't(Solid)' },
                { value: 'dashed', text: 't(Dashed)' },
                { value: 'dotted', text: 't(Dotted)' },
                { value: 'double', text: 't(Double)' },
                { value: 'groove', text: 't(Groove)' },
                { value: 'ridge', text: 't(Ridge)' },
                { value: 'inset', text: 't(Inset)' },
                { value: 'outset', text: 't(Outset)' },
                { value: 'none', text: 't(None)' }
              ], value: border.style },
              { label: 't(Color)', name: 'color', type: ColorField, theme, defaultColor: true, value: border.color }
            ] : []
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { border } = this.state
    this.props.onChange(border.type !== 'none' ? {
      type: border.type,
      width: border.width,
      color: border.color,
      style: border.style
    } : null)
  }

  _handleSet(border) {
    this.setState({
      border: {
        type: 'none',
        width: '1px',
        color: {
          value: '#000000',
          brightness: '100%',
          opacity: '100%'
        },
        style: 'solid',
        ...border || {}
      }
    })
  }

}

export default BorderField
