import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import ItemStyleField from '@admin/components/mjson_designer/components/itemstylefield'
import TextStyleField from '@admin/components/mjson_designer/components/textstylefield'
import IconField from '@admin/components/mjson_designer/components/iconfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

const ListItemToken = ({ value }) => (
  <div className="token">
    { value.title }
  </div>
)

ListItemToken.propTypes = {
  value: PropTypes.object
}

class EditListBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, parent, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Items)', name: 'content.items', type: 'collectionfield', fields: [
                  { label: 't(Title)', name: 'title', type: 'textfield', required: true },
                  { label: 't(Description)', name: 'description', type: 'textarea' },
                  { label: 't(Icon)', name: 'icon', type: IconField, theme, website },
                  { label: 't(Price)', name: 'price', type: 'moneyfield'}
                ], token: ListItemToken, defaultValue: [
                  { title: 'Item 1', description: 'Item 1 description', icon: { noun_project_id: 309676 }, price: '8.50' },
                  { title: 'Item 2', description: 'Item 2 description', icon: { noun_project_id: 309676 }, price: '10.00' },
                  { title: 'Item 3', description: 'Item 3 description', icon: { noun_project_id: 309676 }, price: '5.00' }
                ], value: config.content.items }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 't(Show)', name: 'content.show', type: 'checkboxgroup', options: [
                  { value: 'title', text: 't(Title)' },
                  { value: 'description', text: 't(Description)' },
                  { value: 'icon', text: 't(Icon)' },
                  { value: 'price', text: 't(Price)' }
                ], defaultValue: ['title','description','icon','price'], value: config.content.show }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(List)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Item)', name: 'styles.item', collapsing: true, collapsed: true, type: ItemStyleField, device, theme, defaultValue: {
                  margin: '0 0 15px 0'
                }, value: config.styles.item },
                { label: 't(Icon)', name: 'styles.icon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, value: config.styles.icon },
                { label: 't(Title Text)', name: 'styles.titleText', collapsing: true, collapsed: true, type: TextStyleField, device, theme, defaultValue: {
                  preset: 'heading',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.titleText },
                { label: 't(Description Text)', name: 'styles.descriptionText', collapsing: true, collapsed: true, type: TextStyleField, device, theme, defaultValue: {
                  preset: 'text',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.descriptionText },
                { label: 't(Price Text)', name: 'styles.priceText', collapsing: true, collapsed: true, type: TextStyleField, device, theme, defaultValue: {
                  preset: 'heading',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.priceText }
              ]
            },
            ...styles.getIsFlexChild(parent) ? [
              styles.FlexChildSection(config, device, theme, website, type)
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditListBlock
