import SocialStrategyToken from './social_strategy'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          disabled: true,
          handler: this._handleBack 
        },
        { 
          label: config.social_strategy === 'optin' ? 't(Next)' : 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Social Media)',
              instructions: 't(Opt-in or Opt-out of the done-for-you social media campaign service)',
              type: 'segment',
              fields: [
                { 
                  name: 'social_strategy',
                  type: 'radiogroup',
                  options: ['optin','optout'],
                  defaultValue: config.social_strategy || 'optin',
                  format: SocialStrategyToken,
                  required: true 
                }
              ]
            }
          ] 
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext, onSave } = this.props
    const action = config.social_strategy === 'optin' ? onNext : onSave
    action({
      ...formdata,
      ...config
    })
  }

}

export default Details
