import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class CheckboxField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object,
    verify: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    prompt: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.bool,
    verify: PropTypes.object,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    tabIndex: 0,
    value: false,
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    checked: null
  }

  _handleClick = this._handleClick.bind(this)
  _handleKeyPress = this._handleKeyPress.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { prompt } = this.props
    const { t } = this.context.locale
    const { checked } = this.state
    if(checked == null) return null
    return (
      <div className={ this._getClass() } onClick={ this._handleClick }>
        <div className="maha-checkbox-icon">
          <Icon { ...this._getInput() } />
        </div>
        { prompt &&
          <div className="maha-checkbox-prompt">
            <div dangerouslySetInnerHTML={{ __html: t(prompt) }} />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { checked } = this.state
    const { value } = this.props
    if(checked !== prevState.checked) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const classes = ['maha-checkbox']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getIcon() {
    return this.state.checked ? 'check-square' : 'square-o'
  }

  _getInput() {
    const { tabIndex } = this.props
    return {
      icon: this._getIcon(),
      tabIndex,
      onKeyPress: this._handleKeyPress
    }
  }

  _handleClick() {
    this._handleUpdate()
  }

  _handleChange() {
    const { checked } = this.state
    this.props.onChange(checked)
  }

  _handleKeyPress(e) {
    if (e.key !== ' ' && e.keyCode !== 32) return
    this._handleUpdate()
  }

  _handleSet(checked) {
    this.setState({ 
      checked: checked ?? false 
    })
  }

  _handleUpdate(checked = null) {
    const { disabled, verify } = this.props
    if(disabled) return
    if(!verify) return this._handleSet(checked ?? !this.state.checked)
    if(this.state.checked) {
      this.context.verify.open({
        ...verify,
        handler: this._handleSet.bind(this, false)
      })
    } else {
      this._handleSet(true)
    }
  }

  _handleValidate() {
    const { checked } = this.state
    const { required, onValid } = this.props
    if(required && !checked) return onValid(null, ['This field is required'])
    onValid(checked)
  }

}

export default CheckboxField
