import PropTypes from 'prop-types'
import React from 'react'

class ColorSetPreview extends React.Component {

  static propTypes = {
    palette: PropTypes.object,
    onReady: PropTypes.func
  }

  render() {
    const { palette }  = this.props
    const colorset = palette.colorset ? palette.colorset : palette
    return (
      <div className="colorset-swatches-container">
        <div className="colorset-swatches">
          <div className="colorset-swatch" style={{ backgroundColor: colorset.light }} />
          <div className="colorset-swatch" style={{ backgroundColor: colorset.accent1 }} />
          <div className="colorset-swatch" style={{ backgroundColor: colorset.base }} />
          <div className="colorset-swatch" style={{ backgroundColor: colorset.accent2 }} />
          <div className="colorset-swatch" style={{ backgroundColor: colorset.dark }} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

}

export default ColorSetPreview
