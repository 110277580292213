import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Calls from './calls'
import React from 'react'

class CallsContainer extends React.Component {

  static propTypes = {
    calls: PropTypes.array
  }

  stackRef = React.createRef()

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Calls, this._getCall.bind(this))
  }

  _getCall() {
    const { calls } = this.props
    return {
      calls,
      onPop: this._handlePop,
      onPush: this._handlePush
    }
  }

  _getStack() {
    return {
      display_name: 'connections',
      ref: this.stackRef
    }
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default CallsContainer
