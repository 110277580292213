import PropTypes from 'prop-types'
import Header from './header'
import React from 'react'
import Body from './body'

class Preview extends React.PureComponent {

  static propTypes = {
    children: PropTypes.any,
    device: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    device: 'desktop',
    canvas: <div />,
    onChange: () => {}
  }

  _handleChangeViewport = this._handleChangeViewport.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      device: props.device,
      orientation: 'portrait',
      scale: 1
    }
  }

  render() {
    const { children } = this.props
    return (
      <div className="preview">
        <Header { ...this._getHeader() } />
        <Body { ...this._getBody() }>
          { children }
        </Body>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.device !== prevProps.device) {
      this.setState({
        device: this.props.device
      })
    }
    if(this.state.device !== prevState.device) {
      this._handleChange()
    }
  }

  _getHeader() {
    const { device, orientation, scale } = this.state
    return {
      device,
      orientation,
      scale,
      onChange: this._handleChangeViewport
    }
  }

  _getBody() {
    const { device, orientation, scale } = this.state
    return {
      device,
      orientation,
      scale
    }
  }

  _handleChange() {
    const { device } = this.state
    this.props.onChange(device)
  }

  _handleChangeViewport(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Preview
