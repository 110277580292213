import PropTypes from 'prop-types'
import React from 'react'

const TimingToken = ({ timing }) => {
  if(timing === 'beginning') {
    return <span>Subscription renews at the beginning of the cycle</span>
  } else {
    return <span>Subscription renews at the end of the cycle</span>
  }
}

TimingToken.propTypes = {
  timing: PropTypes.string
}

export default TimingToken
