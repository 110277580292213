import { useMemo } from 'react'

export const records = (state) => {
  return useMemo(() => {
    if(!state.records) return null
    return state.records.sort((a, b) => {
      const aValue = a[state.sort.key]
      const bValue = b[state.sort.key]
      if(state.sort.order === 'asc' && aValue < bValue) return -1
      if(state.sort.order === 'asc' && aValue > bValue) return 1
      if(state.sort.order === 'desc' && aValue > bValue) return -1
      if(state.sort.order === 'desc' && aValue < bValue) return 1
      return 0
    })
  }, [state.records,state.sort])
}
