import Icon from '@admin/components/icon'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class LinkedField extends React.Component {

  static propTypes = {
    types: PropTypes.array,
    defaultValue: PropTypes.any,
    nullValues: PropTypes.array,
    units: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    linked: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleLinked = this._handleLinked.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { linked } = this.state
    if(!linked) return null
    const types = this._getTypes()
    return (
      <div className="linkedfield">
        <div className="linkedfield-fields">
          { types.map((type, index) => (
            <div className="linkedfield-field" key={`range_${index}`} >
              { !linked.is_linked &&
                <div className="linkedfield-field-label">
                  { this._getLabel(type) }:
                </div>
              }
              <div className="linkedfield-field-input">
                <WebRange { ...this._getRange(type) }/>
              </div>
            </div>
          ))}
        </div>
        <div { ...this._getLinked() }>
          <Icon icon="link" />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { linked } = this.state
    if(!_.isEqual(linked, prevState.linked)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getLabel(type) {
    return type.split('_').map(word =>{
      return word.slice(0,1)
    }).join('').toUpperCase()
  }

  _getLinked() {
    return {
      className: this._getLinkedClass(),
      onClick: this._handleLinked
    }
  }

  _getLinkedClass() {
    const { linked } = this.state
    const classes = ['linkedfield-link']
    if(linked.is_linked) classes.push('linked')
    return classes.join(' ')
  }

  _getMax() {
    const { types } = this.props
    const { linked } = this.state
    return types.reduce((max, type) => {
      const value = linked[type]
      if(!value) return max
      const matches = value.match(/[.\d]*/)
      return Math.max(max, matches ? Number(matches[0]) : 0)
    }, 0)
  }

  _getParsed(text) {
    const { types } = this.props
    if(!text || text.length === 0) return null
    const parts = text.split(' ')
    return parts.length > 1 ? {
      is_linked: false,
      ...types.reduce((values, type, index) => ({
        ...values,
        [type]: parts[index]
      }), {})
    } : {
      is_linked: true,
      combined: parts[0]
    }
  }

  _getRange(type) {
    const { nullValues, units } = this.props
    const { linked } = this.state
    return {
      nullValues,
      units,
      value: linked[type],
      onChange: this._handleUpdate.bind(this, type)
    }
  }

  _getTypes() {
    const { types } = this.props
    const { linked } = this.state
    return linked.is_linked ? ['combined'] : types
  }

  _handleChange() {
    const { units, types } = this.props
    const { is_linked } = this.state.linked
    const defaultUnits = units[0].name
    const combined = this.state.linked.combined || `0${defaultUnits}`
    const matches = combined.match(/[-.\d]*/)
    const total = matches ? Number(matches[0]) : 0
    const value = !is_linked ? types.map(type => {
      return this.state.linked[type] || `0${defaultUnits}`
    }).join(' ') : total !== 0 ? combined : null
    this.props.onChange(value)
  }

  _handleLinked() {
    const { units, types } = this.props
    const { linked } = this.state
    const defaultUnits = units[0].name
    const max = this._getMax()
    this.setState({
      linked: {
        is_linked: !linked.is_linked ,
        combined: linked.is_linked ? `0${defaultUnits}` : `${max}${defaultUnits}`,
        ...types.reduce((values, type) => ({
          ...values,
          [type]: linked.is_linked ? linked.combined : `0${defaultUnits}`
        }), {})
      }
    })
  }

  _handleSet(text) {
    const { units, types } = this.props
    const defaultUnits = units[0].name
    const parsed = this._getParsed(text)
    this.setState({
      linked: {
        is_linked: true,
        combined: `0${defaultUnits}`,
        ...types.reduce((values, type) => ({
          ...values,
          [type]: `0${defaultUnits}`
        }), {}),
        ...parsed || {}
      }
    })
  }

  _handleUpdate(key, value) {
    const { linked } = this.state
    this.setState({
      linked: {
        ...linked,
        [key]: value
      }
    })
  }

}

export default LinkedField
