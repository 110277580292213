import SMSClient from '@apps/phone/admin/components/sms_client'
import PropTypes from 'prop-types'
import React from 'react'
import List from './list'

class SMSReader extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    phone_number: PropTypes.object,
    program: PropTypes.object
  }

  state = {
    selected: null
  }

  _handleSelect = this._handleSelect.bind(this)

  render() {
    const { selected } = this.state
    return (
      <div className="sms-reader">
        <List { ...this._getList() } />
        <div className="sms-reader-show">
          { document.body.clientWidth > 1024 && selected &&
            <SMSClient { ...this._getSMSClient() } />
          }
        </div>
      </div>
    )
  }

  _getList() {
    const { contact, phone_number, program } = this.props
    const { selected } = this.state
    return {
      contact,
      phone_number,
      program,
      selected,
      onChoose: this._handleSelect
    }
  }

  _getSMSClient() {
    const { selected } = this.state
    const { contact, contact_phone_number, program, program_phone_number } = selected
    return {
      key: `${contact_phone_number.id}:${program_phone_number.id}`,
      contact,
      contact_phone_number,
      program,
      program_phone_number
    }
  }

  _handleSelect(selected) {
    const { contact_phone_number, program_phone_number } = selected
    this.setState({ selected })
    if(document.body.clientWidth > 1024) return
    this.context.router.push(`/admin/phone/conversations/${program_phone_number.id}/${contact_phone_number.id}`)
  }

}

export default SMSReader
