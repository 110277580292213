import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class ConversationToken extends React.Component {

  static propTypes = {
    conversation: PropTypes.object,
    perspective: PropTypes.string
  }

  render() {
    const { conversation, perspective } = this.props
    return (
      <div className="phone-conversation-token">
        <div className="phone-conversation-token-avatar">
          { perspective === 'contact' ?
            <Logo team={ conversation.program } width="32" /> :
            <ContactAvatar { ...conversation.contact } width="32" />
          }
        </div>
        <div className="phone-conversation-token-details">
          <div className="phone-conversation-token-row">
            <div className="phone-conversation-token-recipient">
              { this._getRecipient() }
            </div>
            <div className="phone-conversation-token-timestamp">
              {this._getTimestamp() }
            </div>
          </div>
          <div className="phone-conversation-token-row">
            <div className="phone-conversation-token-body">
              { conversation.last_message.body }
            </div>
            <div className="phone-conversation-token-extra">
              { conversation.unread > 0 &&
                <>{ conversation.unread }</>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getRecipient() {
    const { conversation, perspective } = this.props
    const { contact, contact_phone_number, direction, program, program_phone_number } = conversation
    if(perspective === 'contact') return program.title
    if(contact.display_name !== 'Unknown') return contact.display_name
    return direction === 'inbound' ? contact_phone_number.formatted : program_phone_number.formatted
  }

  _getTimestamp() {
    const { conversation } = this.props
    const timestamp = moment(conversation.last_message_at)
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    if(timestamp.isSame(today, 'day')) return timestamp.format('h:mm A')
    if(timestamp.isSame(yesterday, 'day')) return 'Yesterday'
    return timestamp.format('MM/DD/YY')
  }

}

export default ConversationToken
