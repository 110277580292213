import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import PropTypes from 'prop-types'
import React from 'react'

class RadiusField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    radius: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.radius) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { radius } = this.state
    const { value } = this.props
    if(!_.isEqual(radius, prevState.radius)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device } = this.props
    const { radius } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'none', text: 't(No Border Radius)' },
              { value: 'circular', text: 't(Circular)' },
              { value: 'rounded', text: 't(Rounded)' }
            ], value: radius.type },
            ...radius.type === 'rounded' ? [
              { name: 'radius', device, type: LinkedField, units: [
                { name: 'px', min: 0, max: 500, step: 1 }
              ], types: ['top_left','top_right','bottom_right','bottom_left'], nullValues: ['0px'], defaultValue: '0px', value: radius.radius }
            ] : []
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { radius } = this.state
    this.props.onChange(radius.type !== 'none' ? {
      type: radius.type,
      ...radius.type === 'rounded' ? {
        radius: radius.radius
      } : {}
    } : null)
  }

  _handleSet(radius) {
    this.setState({
      radius: {
        type: 'none',
        radius: '0px',
        ...radius || {}
      }
    })
  }

}

export default RadiusField
