import Dependencies from '@admin/components/dependencies'
import TextField from '@admin/components/form/textfield'
import InputTokens from '@admin/components/input_tokens'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Manual from './manual'
import React from 'react'

class Addressfield extends React.Component {

  static contextTypes = {
    form: PropTypes.object,
    locale: PropTypes.object
  }

  static propTypes = {
    code: PropTypes.string,
    compact: PropTypes.bool,
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    htmlFor: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    show_address_2: PropTypes.bool,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.object,
    onBlur: PropTypes.func,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValidate: PropTypes.func
  }

  static defaultProps = {
    compact: false,
    disabled: false,
    placeholder: 'Search for a place or address',
    show_address_2: true,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    address: null,
    direction: null,
    focused: false,
    options: [],
    q: '',
    selected: null
  }

  inputRef = React.createRef()
  autocomplete = null
  options = {}
  geocoder = null

  _handleAutocomplete = this._handleAutocomplete.bind(this)
  _handleBlured = this._handleBlured.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleGeocode = this._handleGeocode.bind(this)
  _handleInit = this._handleInit.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleLookup = this._handleLookup.bind(this)
  _handleManual = this._handleManual.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleSetStreet2 = this._handleSetStreet2.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { address, options, q } = this.state
    const { show_address_2 } = this.props
    return (
      <div className={ this._getClass() }>
        <div className={ this._getInputClass() }>
          <div className="maha-input-field" onClick={ this._handleBegin }>
            { address ?
              <InputTokens { ...this._getInputTokens() } /> :
              <Input { ...this._getInput() } />
            }
            { q.length > 0 &&
              <div className={ this._getResultClass() }>
                { options.map((option, index) => (
                  <div { ...this._getOption(option, index) } key={`option_${index}`}>
                    <div className="maha-addressfield-result-icon">
                      <Icon icon="map-marker" />
                    </div>
                    <div className="maha-addressfield-result-details">
                      <strong>{ option.match }</strong>{ option.remaining }
                    </div>
                  </div>
                )) }
                <div className="maha-addressfield-result-add" onClick={ this._handleManual }>
                  Cant find your address? Enter it manually
                </div>
              </div>
            }
          </div>
          { address &&
            <Button { ...this._getClear() } />
          }
        </div>
        { address && show_address_2 === true && 
          <div className="maha-input">
            <TextField { ...this._getStreet2() } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this._handleInit()
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { address, q } = this.state
    const { value } = this.props
    if(q !== prevState.q) {
      if(q.length === 0) this._handleSetOptions([])
      this._handleLookup()
    }
    if(!_.isEqual(address, prevState.address)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { compact, disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-addressfield']
    if(compact) classes.push('compact')
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getCounty(result) {
    const component = result.address_components.find(component => {
      return component.long_name.toLowerCase().match(/county/)
    })
    return component ? component.short_name : null
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDescription(include_street_2) {
    const { street_1, street_2, city, state_province, postal_code, country } = this.state.address
    return [
      ...street_1 ? [`${street_1},`] : [],
      ...street_2 && include_street_2 ? [`${street_2},`] : [],
      ...city ? [`${city},`] : [],
      ...state_province ? [state_province] : [],
      ...postal_code ? [`${postal_code},`] : [],
      ...country ? [country] : []
    ].join(' ')
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused } = this.state
    return {
      disabled,
      id,
      type: 'text',
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      tabIndex,
      onBlur: this._handleBlured,
      onChange: this._handleType,
      onFocus: this._handleFocused,
      onKeyDown: this._handleKeyDown
    }
  }

  _getInputClass() {
    const { disabled } = this.props
    const classes = ['maha-input']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getInputTokens() {
    const { address } = this.state
    return {
      tokens: [address],
      format: (address) => (
        <>
          { address.name &&
            <><strong>{ address.name }<br /></strong></>
          }
          { this._getDescription(false) }
        </>
      )
    }
  }

  _getManual() {
    const { q } = this.state
    return {
      q,
      onDone: this._handleSet.bind(this)
    }
  }

  _getOption(option, index) {
    return {
      className: this._getOptionClass(index),
      onClick: this._handleChoose.bind(this, option),
      ref: node => this.options[index] = node
    }
  }

  _getOptionClass(index) {
    const { selected } = this.state
    const classes = ['maha-addressfield-result']
    if(index === selected) classes.push('selected')
    return classes.join(' ')
  }

  _getPlaceholder() {
    const { label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    return t(label ? `t(Enter) ${label}` : '')
  }

  _getResultClass() {
    const { direction } = this.state
    return ['maha-addressfield-results', direction].join(' ')
  }

  _getStreet2() {
    const { tabIndex } = this.props
    const { address } = this.state
    const street_2 = address.street_2 || ''
    return {
      placeholder: street_2.length === 0 ? 'Enter Suite, Apartment, or PO Box' : null,
      tabIndex,
      value: street_2,
      onChange: this._handleSetStreet2
    }
  }

  _getType(result, type) {
    const component = result.address_components.find(component => {
      return _.includes(component.types, type)
    })
    return component ? component.short_name : null
  }

  _handleAutocomplete(result) {
    const { q } = this.state
    const options = result || []
    this._handleSetOptions(options.map(option => {
      const { main_text, secondary_text } = option.structured_formatting
      const full = `${main_text}, ${secondary_text}`
      const match = full.match(new RegExp(q, 'i'), '')
      return {
        ...option,
        match: match ? q : main_text,
        remaining: match ? full.replace(new RegExp(q, 'i'), '') : `, ${secondary_text}`
      }
    }))
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { address } = this.state
    this.props.onChange(address ? {
      ...address,
      description: this._getDescription(true)
    } : null)
  }

  _handleChoose(address) {
    const name = /^\d*$/.test(address.terms[0].value) !== true ? address.terms[0].value : null
    this.geocoder.geocode({
      placeId: address.place_id
    }, this._handleGeocode.bind(this, name))
  }

  _handleClear() {
    this.setState({
      options: [],
      address: null
    })
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleGeocode(name, results) {
    const result = results[0]
    this._handleSet({
      name,
      description: result.formatted_address,
      street_1: `${this._getType(result, 'street_number')} ${this._getType(result, 'route')}`,
      street_2: null,
      city: this._getType(result, 'locality'),
      state_province: this._getType(result, 'administrative_area_level_1'),
      postal_code: this._getType(result, 'postal_code'),
      county: this._getCounty(result),
      country: this._getType(result, 'country'),
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng()
    })
  }

  _handleInit() {
    this.autocomplete = new window.google.maps.places.AutocompleteService()
    this.geocoder = new window.google.maps.Geocoder()
  }

  _handleKeyDown(e) {
    const { direction, selected } = this.state
    const { options } = this.state
    if(e.which === 38 && direction === 'up') {
      this.setState({
        selected: selected !== null ? (selected === 0 ? options.length - 1 : selected - 1) : options.length -1
      })
    } else if(e.which === 38 && direction === 'down') {
      this.setState({
        selected: selected !== null  ? (selected === 0 ? options.length - 1 : selected - 1) : options.length - 1
      })
    } else if(e.which === 40 && direction === 'down') {
      this.setState({
        selected: selected !== null  ? (selected === options.length - 1 ? 0 : selected + 1) : 0
      })
    } else if(e.which === 40 && direction === 'up') {
      this.setState({
        selected: selected !== null  ? (selected === options.length - 1 ? 0 : selected + 1) : 0
      })
    } else if(selected !== null && e.which === 13) {
      this._handleChoose(options[selected])
    }
  }

  _handleLookup() {
    const { q } = this.state
    this.setState({ selected: null })
    if(!q || q.length == 0) return
    this.autocomplete.getPlacePredictions({
      input: q
    }, this._handleAutocomplete)
  }

  _handleManual() {
    this.context.form.push(Manual, this._getManual.bind(this))
  }

  _handleSet(address) {
    this.setState({
      q: '',
      options: [],
      address
    })
  }

  _handleSetOptions(options) {
    this.setState({ options })
  }

  _handleSetStreet2(street_2) {
    const { address } = this.state
    this.setState({
      address: {
        ...address,
        street_2
      }
    })
  }

  _handleType(q, e) {
    e.stopPropagation()
    var { top } = this.inputRef.current.getBoundingClientRect()
    const percent = (top / window.innerHeight) * 100
    const direction = percent > 75 ? 'up' : 'down'
    this.setState({
      direction,
      q
    })
  }

}

const dependencies = {
  scripts: [
    { url: `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`, check: 'google.maps.places' }
  ]
}

Addressfield = Dependencies(dependencies)(Addressfield)

export default Addressfield
