import PipelinesList from './pipelines/list'
import PipelinesShow from './pipelines/show'
import DealsShow from './deals/show'

const routes = [
  { path: '/deals/:id/:view', component: DealsShow },
  { path: '/pipelines', component: PipelinesList },
  { path: '/pipelines/:id/:view', component: PipelinesShow}
]

export default routes
