import Attachments from '@admin/components/attachments'
import Camera from '@admin/components/camera'
import Icon from '@admin/components/icon'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Editor from './editor'

class Composer extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    attachments: PropTypes.array,
    link: PropTypes.object,
    placeholder: PropTypes.string,
    quoted: PropTypes.object,
    text: PropTypes.string,
    textareaRef: PropTypes.object,
    onAddAttachments: PropTypes.func,
    onAddLink: PropTypes.func,
    onRemoveAttachment: PropTypes.func,
    onRemoveLink: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdateAttachment: PropTypes.func,
    onUpdateText: PropTypes.func
  }

  static defaultProps = {
    attachments: [],
    link: null,
    placeholder: 'Enter text',
    quoted: null,
    text: '',
    onSubmit: () => {},
    onUpdateText: () => {}
  }

  _handleAddAttachments = this._handleAddAttachments.bind(this)
  _handleAttachments = this._handleAttachments.bind(this)
  _handleCancel = this._handleCancel.bind(this)

  render() {
    const { onAddAttachments } = this.props
    return (
      <div className="maha-composer">
        { onAddAttachments &&
          <div className="maha-composer-attachments-icon" onClick={ this._handleAttachments }>
            <Icon icon="plus-circle" />
          </div>      
        }
        <div className="maha-composer-input">
          <Editor { ...this._getEditor() } />
        </div>
        { onAddAttachments &&
          <div className="maha-composer-camera">
            <Camera { ...this._getCamera() } />
          </div>  
        }
      </div>
    )
  }

  _getAttachments() {
    return {
      multiple: true,
      prompt: 't(Upload Files)',
      onCancel: this._handleCancel,
      onChooseAssets: this._handleAddAttachments
    }
  }

  _getCamera() {
    const { onAddAttachments, onUpdateAttachment } = this.props
    return {
      icon: 'camera',
      onAddAsset: onAddAttachments,
      onUpdateAsset: onUpdateAttachment
    }
  }

  _getEditor() {
    return this.props
  }

  _handleAddAttachments(attachments) {
    this.props.onAddAttachments(attachments)
    this.context.modal.close()
  }

  _handleAttachments() {
    this.context.modal.open(<Attachments { ...this._getAttachments() } />)
  }

  _handleCancel() {
    this.context.modal.close()
  }

}

const WrappedComposer = forwardRef((props, ref) => (
  <Composer textareaRef={ ref } { ...props } />
))

export default WrappedComposer
