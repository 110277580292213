import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const getTabs = ({ audits, allocations, chargeback }) => ({
  items: [
    { label: 't(Overview)', component: <Overview chargeback={ chargeback } /> }
  ]
})

const getTasks = ({ refund }) => {
  return null
}

const mapResourcesToPage = (props, context) => ({
  chargeback: `/api/admin/finance/chargebacks/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Chargeback)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
