import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Token extends React.Component {

  static propTypes = {
    children: PropTypes.any,
    details: PropTypes.any,
    text: PropTypes.string,
    title: PropTypes.string
  }

  render() {
    const { children, text, title } = this.props
    return (
      <div className="maha-token">
        { children &&
          <div className="maha-token-details">
            { children }
          </div>        
        }
        { (!title && text) &&
          <div className="maha-token-details">
            <T text={ text } />
          </div>        
        }
        { (title && text) &&
          <div className="maha-token-details">
            <T text={ title } /><br />
            <span><T text={ text } /></span>
          </div>        
        }
      </div>
    )
  }

}

export default Token
