
import PropTypes from 'prop-types'
import svgs from './svgs'
import React from 'react'

const Icon = (props) => {

  const { icon, svg, ...additionalProps } = props

  const mergedProps = {
    ...additionalProps,
    ...icon ? {
      className: `fa fa-${icon}`
    } : {}
  }

  const Svg = svgs?.[svg]

  return (
    <div className="maha-icon">
      { icon &&
        <i { ...mergedProps } />
      }
      { Svg &&
        <Svg />
      }
    </div>
  )

}

Icon.propTypes = {
  icon: PropTypes.string,
  svg: PropTypes.string
}

export default Icon