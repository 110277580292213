import ValueField from '@apps/domains/admin/components/valuefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    domain: PropTypes.object
  }

  state = {
    record: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { record } = this.state
    const { domain } = this.props
    return {
      title: 't(New Record)',
      method: 'POST',
      action: `/api/admin/domains/domains/${domain.id}/records`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Host)', name: 'name', type: 'textfield', suffix: `.${domain.name}` },
            { label: 't(Type)', name: 'type', type: 'dropdown', options: [
              { value: 'A', text: 'A - Route traffic to an IPv4 address' },
              { value: 'AAAA', text: 'AAAA - Route traffic to an IPv6 address' },
              { value: 'CNAME', text: 'CNAME - Route traffic to another domain name' },
              { value: 'MX', text: 'MX - Specify one or more mail servers' },
              { value: 'SRV', text: 'SRV - Application-specific values that identify servers' },
              { value: 'TXT', text: 'TXT - Used to verify email senders and for application specific values' }
            ], defaultValue: 'A' },
            { label: 't(TTL)', name: 'ttl', type: 'dropdown', options: [
              { value: 5 * 60, text: '5 minutes' },
              { value: 15 * 60, text: '15 minutes' },
              { value: 30 * 60, text: '30 minutes' },
              { value: 60 * 60, text: '1 hour' },
              { value: 24 * 60 * 60, text: '1 day' }
            ], defaultValue: 300 },
            { label: 't(Values)', name: 'values', type: ValueField, recordType: record.type, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(record) {
    this.setState({ record })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default New
