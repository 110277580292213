import Img from '@admin/components/html/img'
import ImageToken from '@admin/tokens/image'
import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    type: PropTypes.string
  }

  render() {
    const { records, type } = this.props
    return (
      <div className="mjson-designer-media-results">
        { records.map((record, index) => (
          <div className={`mjson-designer-media-result ${type}`} key={ index }>
            { type === 'icon' &&
              <div className="mjson-designer-media-icon">
                <Img src={`/icons/noun/fg=000000&s=32/${record.noun_project_id}.png`} />
              </div>
            }
            { type === 'image' &&
              <ImageToken { ...record } />
            }
            { type === 'video' &&
              <div className="mjson-designer-media-video">
                <div className="mjson-designer-media-video-preview">
                  <Image { ...this._getVideo(record.asset) } />
                </div>
                <div className="mjson-designer-media-video-details">
                  <strong>{ record.title }</strong><br />
                  { record.network }.com
                </div>
              </div>
            }
          </div>
        ))}
      </div>
    )
  }

  _getVideo(image) {
    return {
      src: image.path,
      transforms: {
        w: 300,
        h: 169,
        fit: 'cover'
      }
    }
  }

}

export default Results
