const INITIAL_STATE = {
  active: null,
  details: null,
  expanded: [],
  hovering: null,
  step: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'DETAILS':
    return {
      ...state,
      details: action.code !== state.details ? action.code : null
    }

  case 'EXPAND_STEP':
    return {
      ...state,
      expanded: [
        ..._.xor(state.expanded, [action.code])
      ]
    }

  case 'HOVER':
    return {
      ...state,
      hovering: action.hovering
    }

  case 'SET_ACTIVE':
    return {
      ...state,
      active: action.active
    }

  case 'SET_STEP':
    return {
      ...state,
      step: action.step
    }

  default:
    return state
  }

}

export default reducer
