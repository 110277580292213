import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Organizer)',
      action: '/api/admin/events/organizers',
      method: 'POST',
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield' },
            { label: 't(Phone)', name: 'phone', type: 'phonefield' },
            { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield' }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop(-2)
  }

  _handleSuccess(organizer) {
    this.props.onDone(organizer)
  }

}

export default New
