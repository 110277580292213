import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class RefundField extends React.Component {

  static propTypes = {
    tickets: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    ticket_ids: null
  }

  render() {
    if(!this.state.ticket_ids) return null
    const { tickets } = this.props
    return (
      <div className="finance-allocationfield">
        <table className="ui celled compact unstackable table">
          <thead>
            <tr>
              <th className="collapsing">&nbsp;</th>
              <th>Ticket</th>
              <th className="collapsing">Total</th>
            </tr>
          </thead>
          <tbody>
            { tickets.map((ticket, index) => (
              <tr key={`ticket_${index}`} onClick={ this._handleToggle.bind(this, ticket) }>
                <td>
                  <Icon icon={ this._getIcon(ticket) } />
                </td>
                <td>
                  <strong>{ ticket.name }</strong><br />
                  { ticket.ticket_type.name }
                </td>
                <td className="right aligned">{ numeral(ticket.price).format('0.00') }</td>
              </tr>
            )) }
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="2">Total</th>
              <td className="right aligned">{ numeral(this._getTotal()).format('0.00') }</td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  componentDidMount() {
    const { tickets } = this.props
    this.setState({
      ticket_ids: tickets.map(ticket => ticket.id)
    }, this.props.onReady)
  }

  componentDidUpdate(prevProps, prevState) {
    const { ticket_ids } = this.state
    if(!_.isEqual(ticket_ids, prevState.ticket_ids)) {
      this._handleChange()
    }
  }

  _getClass(ticket) {
    const { ticket_ids } = this.state
    const classes = ['paymentsfield-payment']
    if(_.includes(ticket_ids, ticket.id)) classes.push('selected')
    return classes.join(' ')
  }

  _getIcon(ticket) {
    const { ticket_ids } = this.state
    return _.includes(ticket_ids, ticket.id) ? 'check-circle' : 'circle-o'
  }

  _getTotal() {
    const { ticket_ids } = this.state
    return this.props.tickets.filter(ticket => {
      return _.includes(ticket_ids, ticket.id)
    }).reduce((total, ticket) => {
      return total += ticket.price
    }, 0.00)
  }

  _handleChange() {
    const { ticket_ids } = this.state
    this.props.onChange(ticket_ids)
  }

  _handleToggle(ticket) {
    const { ticket_ids } = this.state
    this.setState({
      ticket_ids: _.xor(ticket_ids, [ticket.id])
    })
  }

}

export default RefundField
