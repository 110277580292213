import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Analytics)',
  manager: {
    path: `/admin/analytics/apps/${resources.app.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview app={ resources.app } />
      },
      {
        label: 'Sessions'
      },
      {
        label: 'Audience',
        children: [
          {
            label: 'Devices'
          },
          {
            label: 'Referers'
          },
          {
            label: 'Geography'
          }
        ]
      },
      {
        label: 'Behavior',
        children: [
          {
            label: 'Pageviews'
          },
          {
            label: 'Events'
          }
        ]
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  app: `/api/admin/analytics/apps/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
