import PChart from '@admin/components/chart/pchart.js'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Performance extends React.Component {

  static propTypes = {
    workflow: PropTypes.object
  }

  render() {
    const { workflow } = this.props
    return (
      <div className="crm-report">
        <div className="crm-report-title">
          Enrollments
        </div>
        <div className="crm-report-header">
          <PChart { ...this._getChart() } />
        </div>
        <div className="crm-report-metrics">
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Enrollments
            </div>
            <div className="crm-report-metric-value">
              { workflow.enrolled_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Active
            </div>
            <div className="crm-report-metric-value">
              { workflow.active_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Converted
            </div>
            <div className="crm-report-metric-value">
              { workflow.converted_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Conversion Rate
            </div>
            <div className="crm-report-metric-value">
              { numeral(workflow.converted_count / workflow.enrolled_count).format('0.0%') }
            </div>
          </div>
        </div>
        <div className="crm-report-table">
          <table className="ui unstackable table">
            <tbody>
              <tr>
                <td>
                  Lost
                </td>
                <td className="right aligned">
                  { workflow.lost_count }
                </td>
              </tr>
              <tr>
                <td>
                  Completed
                </td>
                <td className="right aligned">
                  { workflow.completed_count }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  _getChart() {
    const { workflow } = this.props
    return {
      endpoint: `/api/admin/automation/workflows/${workflow.id}/performance`,
      started_at: workflow.created_at
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance workflow={ props.workflow } />
})

export default Panel(null, mapPropsToPanel)
