import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const statuses = {
  draft: {
    title: 't(Don\'t Publish to Library)',
    text: 't(Do not publish this content to my advisors\' libraries.)'
  },
  published: {
    title: 't(Publish to Library)',
    text: 't(Publish this content to my advisors\' libraries. This will override the imported version from Truevail.)'
  }
}

const LibraryStatusToken = ({ value }) => (
  <div className="token">
    <strong><T text={ statuses[value].title } /></strong><br />
    <T text={ statuses[value].text } />
  </div>
)

LibraryStatusToken.propTypes = {
  value: PropTypes.string
}

export default LibraryStatusToken
