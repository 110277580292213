import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Confirmation extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    data: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { props } = this.props
    const { program } = props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          instructions: 't(When a contact registers for this event, they will receive a confirmation email as part of a workflow. You can customize the email will the following values. You will be able to change these details after the workflow is created.)',
          fields: [
            { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/team/programs/${program.id}/templates`, valueKey: 'id', textKey: 'title', defaultValue: program.default_template ? program.default_template.id : null },
            { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: '(tChoose Sender)', endpoint: `/api/admin/team/programs/${program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', defaultValue: program.default_sender ? program.default_sender.id : null, required: true },
            { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, required: true },
            { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' },
            { label: 't(Body)', name: 'body', type: 'htmlfield', toolbar: ['b','em','u','ol','ul','a'], required: true, defaultValue: `
            <p><%- contact.first_name %>,</p>
            <p>&nbsp;</p>
            <p>Thank you for your registration. Here is a summary:</p>
            <p>&nbsp;</p>
            <p><%- registration.payment_summary %></p>
            <p>&nbsp;</p>
            <p>Below you will find links to your ticket(s). You may either print
            these documents or present them on your device.</p>
            <p>&nbsp;</p>
            <p><%- registration.etickets %></p>
              ` }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(confirmation) {
    this.props.onSave({ confirmation })
  }

}

export default Confirmation
