import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import SourceToken from '@admin/tokens/source'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Service extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    integrations: PropTypes.array,
    sources: PropTypes.array,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    const { onBack, onCancel } = this.props
    return {
      title: 't(Choose Service)',
      leftItems: onBack ? [
        { icon: 'chevron-left', handler: onBack }
      ] : onCancel ? [
        { label: 't(Cancel)', handler: onCancel }
      ] : []
    }
  }

  _getOptions() {
    const { integrations } = this.props
    return integrations.map(({ service }) => service)
  }

  _getSearch() {
    const { types } = this.props
    return {
      search: false,
      group: !types || types.length > 1,
      options: this._getOptions(),
      valueKey: null,
      textKey: 'text',
      format: ({ option }) => (
        <SourceToken source={ option } />
      ),
      onChange: this._handleChoose
    }
  }

  _handleChoose(integration) {
    this.props.onChoose(integration)
  }

}

const mapResources = (props, context) => ({
  integrations:  {
    endpoint: '/api/admin/integrations',
    ...props.types || props.sources ? {
      filter: {
        $and: [
          { source: { $neq: 'linkedin' } },
          ...props.sources ? [
            { source: { $in: props.sources } }
          ] : [],
          ...props.types ? [
            { type: { $in: props.types } }
          ] : []
        ]
      }
    } : {}
  }
})

export default Container(mapResources)(Service)