import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Templates extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    templates: PropTypes.array,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleNew = this._handleNew.bind(this)

  render() {
    const { templates } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        { templates.length > 0 ?
          <Search  { ...this._getSearch() } /> :
          <Message  { ...this._getMessage() } />
        }
      </ModalPanel>
    )
  }

  _getPanel() {
    const { templates } = this.props
    return {
      title: 't(Choose Template)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack.bind(this, -1) }
      ],
      ...templates.length > 0 ? {
        buttons: [
          { label: 'Create New Template', color: 'blue', handler: this._handleNew }
        ]
      } : {}
    }
  }

  _getMessage() {
    return {
      icon: 'copy',
      title: 't(Email Templates)',
      text: 't(You can create templates of emails that you frequently send to customers so you dont have to retype or copy/paste them every time.)',
      buttons: [
        { label: 'Create Template', handler: this._handleNew }
      ]
    }
  }

  _getSearch() {
    const { templates } = this.props
    return {
      prompt: 'Find a Template',
      options: templates,
      valueKey: null,
      textKey: 'title',
      onChange: this._handleChoose
    }
  }

  _handleNew() {
    const props = {
      onBack: this._handleBack.bind(this, -2),
      onDone: this._handleCreate
    }
    this.context.modal.push(New, props)
  }

  _handleBack(index = -1) {
    this.context.modal.pop(index)
  }

  _handleChoose(template) {
    this.context.modal.pop()
    this.props.onDone(template)
  }

  _handleCreate(template) {
    this.context.modal.pop(-2)
    this.props.onDone(template)
  }

}

const mapResources = (props, context) => ({
  templates: '/api/admin/email/templates'
})

export default Container(mapResources)(Templates)
