import ExpenseTypeToken from '@apps/finance/admin/tokens/expense_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    return {
      title: 't(New Cash Advance)',
      method: 'POST',
      action: '/api/admin/finance/advances',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Date Needed)', name: 'date_needed', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Description)', name: 'description', type: 'textfield', required: true },
            { label: 't(Amount)', name: 'amount', type: 'moneyfield', required: true },
            { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/memberships', valueKey: 'id', textKey: 'display', format: ProjectToken },
            { label: 't(Expense Type)', name: 'expense_type_id', type: 'lookup', endpoint: '/api/admin/finance/expense_types', valueKey: 'id', textKey: 'display', format: ExpenseTypeToken }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(expense) {
    const { modal } = this.context
    modal.close()
  }

}

export default New
