import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const list_types = {
  private: {
    title: 't(Private List)',
    description: 't(This is a private list for administrative purposes. Contacts cannot manually unsubscribe themselves)'
  },
  public: {
    title: 't(Public List)',
    description: 't(This is a public list. Contacts will be able to manually subscribe and unsubscribe themselves via the public "Adjust My Preferences" interface)'
  }
}

const ListTypeToken = ({ value }) => (
  <div className="token purpose-token">
    <strong><T text={ list_types[value].title } /></strong><br />
    <T text={ list_types[value].description } />
  </div>
)

ListTypeToken.propTypes = {
  value: PropTypes.string
}

export default ListTypeToken
