import Page from '@admin/components/page'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

const Overview = ({ title, origins }) => {

  const details = {
    sections: [
      {
        items: [
          { label: 't(Title)', content: title },
          { label: 't(Origins)', content: <span>{ origins.split('\n').map((origin, index) => (
            <span key={`origin_${index}`}>{ origin }<br /></span>
          )) }</span> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  origins: PropTypes.string,
  title: PropTypes.string
}

const mapResourcesToPage = (props, context) => ({
  site: `/api/admin/sites/sites/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Site)',
  panel: <Overview { ...resources.site } />,
  tasks: {
    items: [
      {
        label: 't(Edit Site)',
        modal: () => <Edit site_id={ props.params.id } />
      }, 
      {
        label: 't(Reindex Site)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/sites/sites/${ props.params.id }/reindex`,
          onFailure: (result) => context.flash.set('error', 't(Unable to reindex the site)'),
          onSuccess: (result) => context.flash.set('success', 't(Successfully reindexed the site)')
        }
      }, 
      {
        label: 't(Backup Data)',
        handler: () => {
          window.location.href = context.admin.signUrl(`/api/admin/sites/sites/${ props.params.id }/backup`)
        }
      }
    ]
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
