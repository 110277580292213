import qs from 'qs'

const colors = {
  blue: '#2185D0',
  green: '#21BA45',
  grey: '#888888',
  olive: '#B5CC18',
  pink: '#E03997',
  purple: '#8E004B',
  red: '#DB2828',
  violet: '#6435C9',
  yellow: '#FBBD08'
}

export const createLogger = (color) => (action, data) => {
  const logFlag = typeof window !== 'undefined' ? qs.parse(window.location.search.substr(1)).log : false
  const isDevelopment = process.env.MAHA_ENV === 'development'
  if(!logFlag && !isDevelopment) return
  console.groupCollapsed(' %c█ %c%s', `color:${colors[color]}`, 'color:inherit;font-weight:bold;', action)
  if(data) {
    Object.keys(data).map(key => {
      console.log('  %c%s:%c', `color:${colors.purple};font-weight:bold`, key, 'color: inherit;font-weight:normal', data[key])
    })
  }
  console.groupEnd()
}