import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    flash: PropTypes.object
  }

  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { id, type } = this.props
    const { admin } = this.context
    return {
      title: 't(Send Preview)',
      method: 'POST',
      action: `/api/admin/mjson/${type}/${id}/preview`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      saveText: 'Send',
      sections: [
        {
          fields: [
            { type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'me', text: `Send to ${admin.user.email}` },
                { value: 'user', text: 't(Choose a specific user)' },
                { value: 'email', text: 't(Enter an email address)' }
              ], defaultValue: 'me' },
              ...this._getStrategy()
            ] }
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { config } = this.state
    if(config.strategy === 'email') {
      return [
        { label: 't(First Name)', type: 'textfield', name: 'first_name', required: true },
        { label: 't(Last Name)', type: 'textfield', name: 'last_name', required: true },
        { label: 't(Email)', type: 'emailfield', name: 'email', required: true }
      ]
    } else if(config.strategy === 'user') {
      return [{ name: 'user_id', type: 'lookup', required: true, prompt: 't(Choose a User)', endpoint: '/api/admin/users', filter: { is_active: { $eq: true } }, valueKey: 'id', textKey: 'full_name', format: UserToken }]
    }
    return []
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.flash.set('success', 't(Email queued for delivery)')
    this.context.modal.close()
  }

}

export default Preview
