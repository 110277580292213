import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import WebRange from '@admin/components/mjson_designer/components/webrange'
import React from 'react'

const width  = {
  units: [
    { name: 'px', min: 0, max: 1000, step: 1 },
    { name: '%', min: 0, max: 100, step: 1 },
    { name: 'vw', min: 0, max: 100, step: 1 }
  ],
  nullValues: ['none','100%','100vw']
}

const height  = {
  units: [
    { name: 'px', min: 0, max: 1000, step: 1 },
    { name: '%', min: 0, max: 100, step: 1 },
    { name: 'vh', min: 0, max: 100, step: 1 }
  ],
  nullValues: ['none','100%','100vh']
}

export const Width = (props) => <ResponsiveField { ...props } { ...width } component={ WebRange } />
export const Height = (props) => <ResponsiveField { ...props } { ...height } component={ WebRange } />

export const SizingSection = (config, device, theme, website, type, width = false, height = false) => ({
  label: 't(Sizing)',
  instructions: 't(Specify the width and height constraints that define the responsive sizing of this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    ...width ? [
      { label: 't(Min Width)', name: 'styles.minWidth', device, type: Width, value: config.styles.minWidth },
      { label: 't(Width)', name: 'styles.width', device, type: Width, value: config.styles.width },
      { label: 't(Max Width)', name: 'styles.maxWidth', device, type: Width, value: config.styles.maxWidth }
    ] : [],
    ...height ? [
      { label: 't(Min Height)', name: 'styles.minHeight', device, type: Height, value: config.styles.minHeight },
      { label: 't(Height)', name: 'styles.height', device, type: Height, value: config.styles.height },
      { label: 't(Max Height)', name: 'styles.maxHeight', device, type: Height, value: config.styles.maxHeight }
    ] : []
  ]
})
