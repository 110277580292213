import TextField from '@admin/components/form/textfield'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class SecretsField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    secrets: null
  }

  _handleAdd = this._handleAdd.bind(this)

  render() {
    const { secrets } = this.state
    if(!secrets) return null
    return (
      <div className="platform-secretsfield">
        { secrets.length > 0 &&
          <div className="platform-secretsfield-secrets">
            { secrets.map((secret, index) => (
              <div className="platform-secretsfield-secret" key={`secret_${index}`}>
                <div className="platform-secretsfield-secret-field">
                  <TextField { ...this._getTextField(secret, index, 'key') } />
                </div>
                <div className="platform-secretsfield-secret-field">
                  <TextField { ...this._getTextField(secret, index, 'value') } />
                </div>
                <Button { ...this._getRemove(index) } />
              </div>
            )) }
          </div>
        }
        <Button { ...this._getAdd() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { secrets } = this.state
    if(!_.isEqual(secrets, prevState.secrets)) {
      this._handleChange()
    }
  }

  _getAdd() {
    return {
      label: '+ add secret',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getRemove(index) {
    return {
      icon: 'trash-o',
      className: 'platform-secretsfield-secret-action',
      handler: this._handleRemove.bind(this, index)
    }    
  }

  _getTextField(secret, index, key) {
    return {
      value: secret[key],
      onChange: this._handleUpdate.bind(this, index, key),
      placeholder: key
    }
  }

  _handleAdd() {
    this.setState({ 
      secrets: [
        ...this.state.secrets,
        { key: '', value: '' }
      ]
    })
  }

  _handleChange() {
    this.props.onChange(this.state.secrets.filter(secret => {
      return secret.key.length > 0 && secret.value.length > 0
    }).reduce((secrets, secret) => ({
      ...secrets,
      [secret.key]: secret.value
    }), {}))
  }

  _handleRemove(index) {
    this.setState({ 
      secrets: this.state.secrets.filter((secret, i) => {
        return i !== index
      })
    })
  }

  _handleSet(secrets) {
    this.setState({ 
      secrets: secrets && Object.keys(secrets).length > 0 ? Object.keys(secrets).map(key => ({
        key,
        value: `${secrets[key]}`
      })) : [
        { key: '', value: '' }
      ]
    })
  }

  _handleUpdate(index, key, value) {
    this.setState({ 
      secrets: this.state.secrets.map((secret, i) => {
        return i === index ? {
          ...secret,
          [key]: value
        } : secret
      })
    })
  }

}

export default SecretsField
