import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

const ImageToken = ({ asset }) => (
  <div className="maha-image-token">
    <div className="maha-image-token-photo">
      <Image src={ asset.path } width={150} height={100} transforms={{ w: 150, h: 100, fit: 'cover' }} />
    </div>
    <div className="maha-image-token-details">
      { asset.file_name }<br />
      <span>{ asset.metadata.width }x{ asset.metadata.height }</span>
    </div>
  </div>
)

ImageToken.propTypes = {
  asset: PropTypes.object
}

export default ImageToken
