import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const format = (rate = 0) => {
  return rate > 0 ? numeral(rate).format('0.00%') : '0%'
}

const getScreenshot = (entity) => ({
  engine: entity.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `automation_emails/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const EmailToken = ({ email, compact }) => (
  <div className="automation-email-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email) } />
      </div>
    </div>
    <div className="automation-email-token-label">
      { email.title }<br />
      { compact !== true &&
        <div className="workflow-token-link">
          { email.workflow.email &&
            <>Automation Email <span className="workflow-token-target">{ email.workflow.email.title }</span></>
          }
          { email.workflow.email_campaign &&
            <>Email Campaign <span className="workflow-token-target">{ email.workflow.email_campaign.title }</span></>
          }
          { email.workflow.event &&
            <>Event <span className="workflow-token-target">{ email.workflow.event.title }</span></>
          }
          { email.workflow.form &&
            <>Form <span className="workflow-token-target">{ email.workflow.form.title }</span></>
          }
          { email.workflow.list &&
            <>List <span className="workflow-token-target">{ email.workflow.list.title }</span></>
          }
          { email.workflow.store &&
            <>Store  <span className="workflow-token-target">{ email.workflow.store.title }</span></>
          }
          { email.workflow.service &&
            <>Service <span className="workflow-token-target">{ email.workflow.service.title }</span></>
          }
          { email.workflow.field &&
            <>Contact property <span className="workflow-token-target">{ email.workflow.field.name.value }</span> was updated</>
          }
        </div>
      }
      <div className="token-stats">
        <span>{ email.sent || 0 } sent</span>
        <span>{ format(email.open_rate) } open rate</span>
        <span>{ format(email.click_rate) } click rate</span>
        <span>{ format(email.bounce_rate) } bounce rate</span>
      </div>
    </div>
  </div>
)

EmailToken.propTypes = {
  compact: PropTypes.bool,
  email: PropTypes.object
}

export default EmailToken
