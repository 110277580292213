import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    addon: PropTypes.object,
    plan: PropTypes.object,
    service: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { addon, plan, service } = this.props
    return {
      title: 't(Edit Add On)',
      method: 'PATCH',
      endpoint: `/api/admin/subscriptions/services/${service.id}/plans/${plan.id}/addons/${addon.id}/edit`,
      action: `/api/admin/subscriptions/services/${service.id}/plans/${plan.id}/addons/${addon.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...plan.type === 'paid' ? [
              { label: 't(Pricing)', type: 'segment', fields: [
                { type: 'fields', fields: [
                  { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
                  { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken }
                ] },
                { label: 't(Price)', name: 'price', type: 'moneyfield', required: true }
              ] }
            ] : []
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(service) {
    this.context.modal.close()
  }

}

export default New
