import Token from './token'
import Form from './form'

export default {
  icon: 'handshake-o',
  label: 'Create a Deal',
  type: 'contact',
  action: 'deal',
  form: Form,
  token: Token
}
