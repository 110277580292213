import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Campaign extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { email_campaigns } = this.state
    const { admin } = this.context
    return {
      defaultValue: email_campaigns,
      endpoint: '/api/admin/truevail/email_campaigns',
      entity: 'email campaign',
      format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ true } show_tags={ false } show_voice={ admin.agency !== null } />
      ),
      multiple: false,
      screenshot: (entity) => `truevail_email_campaigns/${entity.code}`,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(email_campaign) {
    this.props.onNext({ email_campaign })
  }

}

export default Campaign
