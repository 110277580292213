import Dropdown from '@admin/components/form/dropdown'
import PropTypes from 'prop-types'
import React from 'react'

class PageLayoutField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    layout: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.layout) return null
    return <Dropdown { ...this._getDropdown() } />
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { layout } = this.state
    if(!_.isEqual(layout, prevState.layout)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDropdown() {
    const { layout } = this.state
    return {
      options: [
        { text: 't(Scroll)', value: 'scroll' },
        { text: 't(Flex)', value: 'flex' }
      ],
      value: layout,
      onChange: this._handleUpdate
    }
  }

  _handleChange() {
    const { layout } = this.state
    this.props.onChange(layout !== 'scroll' ? layout : null)
  }

  _handleSet(layout) {
    this.setState({
      layout: layout || 'scroll'
    })
  }

  _handleUpdate(layout) {
    this.setState({ layout })
  }

}

export default PageLayoutField
