import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import ItemStyleField from '@admin/components/mjson_designer/components/itemstylefield'
import TextStyleField from '@admin/components/mjson_designer/components/textstylefield'
import IconField from '@admin/components/mjson_designer/components/iconfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import settings from '../settings'
import React from 'react'

const check = '<svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg"><path d="m594 1074c-265.2 0-480-214.8-480-480s214.8-480 480-480 480 214.8 480 480-214.8 480-480 480zm0-888c-225.6 0-408 182.4-408 408s182.4 408 408 408 408-182.4 408-408-182.4-408-408-408zm-38.398 565.2c-14.398 14.398-37.199 14.398-51.602 0l-128.4-132c-14.398-14.398-14.398-38.398 0-52.801 14.398-14.398 37.199-14.398 51.602 0l102 105.6 230.4-235.2c14.398-14.398 37.199-14.398 51.602 0 14.398 14.398 14.398 38.398 0 52.801z" /></svg>'

const IconListItemToken = ({ value }) => (
  <div className="token">
    { value.text }
  </div>
)

IconListItemToken.propTypes = {
  value: PropTypes.object
}

class EditIconListBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, parent, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Items)', name: 'content.items', type: 'collectionfield', fields: [
                  { label: 't(Icon)', name: 'icon', type: IconField, required: true, theme, website, defaultValue: { noun_project_id: '309855', svg: check } },
                  { label: 't(Text)', name: 'text', type: 'textfield', required: true }
                ], token: IconListItemToken, defaultValue: [
                  { text: 'Item 1', icon: { noun_project_id: '309855', svg: check } },
                  { text: 'Item 2', icon: { noun_project_id: '309855', svg: check } },
                  { text: 'Item 3', icon: { noun_project_id: '309855', svg: check } }
                ], value: config.content.items }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Icon List)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Items)', name: 'styles.items', collapsing: true, collapsed: true, type: ItemStyleField, device, theme, value: config.styles.items },
                { label: 't(Item)', name: 'styles.item', collapsing: true, collapsed: true, type: ItemStyleField, device, theme, defaultValue: {
                  margin: '0 0 15px 0'
                }, value: config.styles.item },
                { label: 't(Icon)', name: 'styles.icon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, defaultValue: {
                  size: '20px',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.icon },
                { label: 't(Text)', name: 'styles.text', collapsing: true, collapsed: true, type: TextStyleField, device, theme, defaultValue: {
                  preset: 'text',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.text }
              ]
            },
            ...styles.getIsFlexChild(parent) ? [
              styles.FlexChildSection(config, device, theme, website, type)
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditIconListBlock
