import AddressField from '@admin/components/form/addressfield'
import ChannelField from './channelfield'

const validate = ({ value, network, onValid }) => {
  return onValid(value)
}

const compare = (item1, item2) => {
  return item1.description === item2.description
}

const unpack = (item) => item ? item.address : null

const pack = (address) => ({ address })

export default ChannelField({
  compare,
  emptyValue: null,
  entity: 'mailing address',
  format: (item) => item.address.description,
  pack,
  unpack,
  validate,
  verify: () => true
}, AddressField)
