import Dropdown from '@admin/components/form/dropdown'
import FontFamilyToken from './token'
import PropTypes from 'prop-types'
import React from 'react'

class FontFamilyField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    fonts: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    fonts: [
      { value: 'Arial, Helvetica, sans-serif', text: 'Arial' },
      { value: '"Comic Sans MS", cursive, sans-serif', text: 'Comic Sans' },
      { value: '"Courier New", Courier, monospace', text: 'Courier New' },
      { value: 'Georgia, serif', text: 'Georgia' },
      { value: '"Helvetica Neue", Helvetica, Arial, Verdana, sans-serif', text: 'Helvetica' },
      { value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif', text: 'Lucida' },
      { value: 'Tahoma, Geneva, sans-serif', text: 'Tahoma' },
      { value: '"Times New Roman", Times, serif', text: 'Times New Roman' },
      { value: '"Trebuchet MS", Helvetica, sans-serif', text: 'Trebuchet MS' },
      { value: 'Verdana, Geneva, sans-serif', text: 'Verdana' }
    ],
    onChange: () => {},
    onReady: () => {}
  }

  _handleReady = this._handleReady.bind(this)

  render() {
    return <Dropdown { ...this._getDropdown() } />
  }

  _getDropdown() {
    const { defaultValue, disabled, fonts, value, onChange } = this.props
    return {
      disabled,
      defaultValue,
      format: FontFamilyToken,
      options: fonts,
      value,
      onChange,
      onReady: this._handleReady
    }
  }

  _handleReady() {
    this.props.onReady()
  }

}

export default FontFamilyField
