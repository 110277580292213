import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Payment from './payment'
import Details from './details'
import React from 'react'

class Cancel extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    plans: PropTypes.array,
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { service, subscription } = this.props
    return {
      title: 't(Cancel Subscription)',
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/cancel`,
      method: 'PATCH',
      formatData: this._getData,
      getSteps: this._getSteps.bind(this),
      props: this._getProps(),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(subscription) {
    return subscription
  }

  _getProps() {
    const { service, subscription } = this.props
    return {
      service,
      subscription
    }
  }

  _getSteps(formdata) {
    const { subscription } = this.props
    return [
      { label: 't(Details)', component: Details },
      ...subscription.current_period.plan.type === 'paid' ? [
        { label: 't(Payment)', component: Payment }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(subscription) {
    this.context.modal.close()
  }

}

export default Cancel
