import AppsField from '@apps/platform/admin/components/appsfield'
import ServiceToken from '@admin/tokens/service'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class GroupsEdit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    team_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team_id } = this.props
    return {
      title: 't(Edit Team)',
      method: 'PATCH',
      endpoint: `/api/admin/platform/teams/${team_id}/edit`,
      action: `/api/admin/platform/teams/${team_id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Subdomain)', name: 'subdomain', type: 'textfield', required: true },
            { label: 't(Provider)', name: 'provider_id', type: 'dropdown', entity: 'user', endpoint: '/api/admin/providers', valueKey: 'id', textKey: 'title', required: true },
            { label: 't(Domain)', name: 'domain_id', type: 'dropdown', entity: 'user', endpoint: '/api/admin/domains', valueKey: 'id', textKey: 'name', required: true },
            { label: 't(Payment Processor)', name: 'payment_processor', type: 'dropdown', options: ['stripe','braintree'], required: true, format: ServiceToken },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Apps)', name: 'app_ids', type: AppsField }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default GroupsEdit
