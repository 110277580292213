import Search from '@admin/components/search'
import TeamToken from '@admin/tokens/team'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React from 'react'

class Unassigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    entity: PropTypes.string,
    format: PropTypes.any,
    unassigned: PropTypes.array,
    onAdd: PropTypes.func
  }

  render() {
    return (
      <div className="maha-support-assignment-unassigned">
        <Search { ...this._getSearch() } />
      </div>
    )    
  }

  _getSearch() {
    const { assigned, entity, format, unassigned, onAdd } = this.props
    return {
      empty: {
        icon: 'users',
        title: `No ${_.startCase(pluralize(entity))}`,
        text: `There are no unassigned ${pluralize(entity)} that match your query`
      },
      options: unassigned.filter(team => {
        return !assigned.find(item => {
          return item.id === team.id
        })
      }),
      valueKey: null,
      textKey: 'title',
      entity: _.startCase(pluralize(entity)),
      onChoose: onAdd,
      format
    }
  }

}

export default Unassigned
