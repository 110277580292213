import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneBadge extends React.Component {

  static contextTypes = {
    twilio: PropTypes.object
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    return (
      <div className="maha-badge" onClick={ this._handleClick }>
        <Icon icon="phone" />
      </div>
    )
  }

  _handleClick() {
    this.context.twilio.toggle()
  }

}

export default PhoneBadge
