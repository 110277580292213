import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useModalContext } from '@admin/components/modal'
import Container from '@admin/components/container'
import * as selectors from './selectors'
import * as actions from './actions'
import Assignment from './assignment'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const AssignmentContainer = (props) => {

  const { modal } = useModalContext()
  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'assignment',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      modal,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Assignment { ...mergedProps } />

}

AssignmentContainer.propTypes = {
  assigned: PropTypes.array,
  unassigned: PropTypes.array
}

const mapResources = (props) => ({
  ...props.assignedEndpoint ? {
    defaultAssigned: props.assignedEndpoint
  } : {},
  ...props.unassignedEndpoint ? {
    unassigned: props.unassignedEndpoint
  } : {}
})

export default Container(mapResources)(AssignmentContainer)