import MultiField from '@admin/components/form/multifield'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'
import New from './new'

class ButtonsField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.array,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.array,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    return <MultiField { ...this._getMultiField() } />
  }

  _getMultiField() {
    return {
      ...this.props,
      format: ({ value }) => <div className="token">{ value.content.button.text }</div>,
      addText: '+ t(add button)',
      moveable: false,
      props: this._getProps.bind(this),
      newForm: New,
      editForm: Edit
    }
  }

  _getProps() {
    const { device, theme, website } = this.props
    return {
      device,
      theme,
      website
    }
  }

}

export default ButtonsField
