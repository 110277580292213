import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class EmailActivities extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className="email-activities">
        { email.activities.map((activity, index) => (
          <div className="email-activities-item" key={`feed_item_${index}`}>
            <div className="email-activities-item-icon">
              <div className="email-activities-item-icon-badge">
                <Icon icon={`${activity.icon}`} />
              </div>
            </div>
            <div className="email-activities-item-content">
              <div className="email-activities-item-timestamp">
                <Date datetime={ activity.created_at } /><br />
              </div>
              { activity.description }
            </div>
          </div>
        ))}
      </div>
    )
  }

}

export default EmailActivities
