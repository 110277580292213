import LayoutField from '@admin/components/mjson_designer/components/layoutfield'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class NewCollection extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Section)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { label: 't(Label)', name: 'label', type: 'textfield', placeholder: 'Enter Label', required: true, defaultValue: 'Section' },
            { label: 'Section', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'new', text: 't(Design from scratch)' },
                { value: 'library', text: 't(Import item from library)' }
              ], defaultValue: 'new' },
              ...this._getStrategy()
            ] }
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { website } = this.props
    const { config } = this.state
    if(config.strategy === 'library') {
      return [
        { label: 'Library Item', name: 'item', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/items`, filter: { type: { $eq: 'section' }}, valueKey: null, textKey: 'title' }
      ]
    }
    return [
      { label: 't(Rows)', name: 'rows', type: 'optionsfield', options: [1,2,3,4,5,6], defaultValue: 1, value: config.rows },
      { label: 't(Columns per Row)', name: 'columns', type: 'optionsfield', options: [1,2,3,4,5,6], defaultValue: 1, value: config.columns },
      { label: 't(Column Layout)', name: 'layout', type: LayoutField, value: config.layout }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _getColumn() {
    return {
      id: getCode(5),
      entity: 'column',
      meta: {
        label: 'Column'
      },
      settings: {},
      styles: {
        padding: '15px'
      },
      content: {
        children: []
      }
    }
  }

  _getRow(config) {
    return {
      id: getCode(5),
      entity: 'row',
      meta: {
        label: 'Row'
      },
      settings: {},
      styles: {
        padding: '15px',
        rowWidth: '1200px',
        rowAlign: 'center',
        columnHorizontalAlign: 'center',
        columnVerticalAlign: 'middle'
      },
      content: {
        layout: config.layout,
        children: Array(config.columns).fill(0).map(column => {
          return this._getColumn()
        })
      }
    }
  }

  _handleSubmit(config) {
    this.props.onDone({
      id: getCode(5),
      ...config.strategy === 'library' ? {
        item_id: config.item.config.id,
        ..._.omit(config.item.config, ['id'])
      } : {
        entity: 'section',
        meta: {
          label: config.label,
          region: null
        },
        styles: {
          padding: '25px 0 25px 0'
        },
        content: {
          children: Array(config.rows).fill(0).map(row => {
            return this._getRow(config)
          })
        }
      }
    })
    this.context.modal.close()
  }

}

export default NewCollection
