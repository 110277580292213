import PropTypes from 'prop-types'
import React from 'react'
import Deal from './deal'

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    fields: PropTypes.array,
    isOver: PropTypes.bool,
    pipeline: PropTypes.object,
    stage: PropTypes.object,
    onTransition: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="sales-dealboard-stage-results">
        { records.map((deal, index) => (
          <Deal { ...this._getDeal(deal) } key={`deal_${index}`} />
        )) }
      </div>
    )
  }

  _getDeal(deal, index) {
    const { fields, pipeline } = this.props
    return {
      deal,
      fields,
      pipeline
    }
  }

}

export default Results
