import useActionReducer from '@admin/hooks/use_action_reducer'
import * as selectors from './selectors'
import * as actions from './actions'
import FileField from './filefield'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const FileFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'filefield',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <FileField { ...mergedProps } />

}

FileFieldContainer.propTypes = {
  assigned: PropTypes.array,
  unassigned: PropTypes.array
}

export default FileFieldContainer