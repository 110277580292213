import ToField from '@apps/campaigns/admin/components/tofield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    workflow: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { workflow } = this.props
    return {
      title: 't(Enroll Contacts)',
      action: `/api/admin/automation/workflows/${workflow.id}/enrollments`,
      method: 'POST',
      saveText: 'Enroll',
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Contacts)', name: 'to', type: ToField, program_id: workflow.program.id, channel: 'all', purpose: workflow.purpose }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default New
