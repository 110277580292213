export const INITIAL_STATE = {
  app_ids: []
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_APPS':
    return {
      ...state,
      app_ids: action.app_ids
    }

  case 'TOGGLE_APP':
    return {
      ...state,
      app_ids: _.xor(state.app_ids, [action.app_id])
    }

  default:
    return state

  }

}
