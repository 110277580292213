import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Contact from './contact'
import Social from './social'
import React from 'react'

class Identity extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(Business Setup)',
      cancelIcon: 'chevron-left',
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      props: {
        advisor
      },
      showSteps: false,
      onCancel: this._handleCancel,
      onNext: this._handleNext,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { configuration } = this.props
    return configuration
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Contact)', component: Contact },
      { label: 't(Social Media)', component: Social }
    ]
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleNext(data) {
    this.props.onNext(data)
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Identity
