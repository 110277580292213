import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

const getDate = ({ start_date, end_date }) => {
  if(start_date === end_date) return moment(end_date).format('MMM DD, YYYY')
  return [
    moment(start_date).format('MMM DD'),
    moment(end_date).format('MMM DD, YYYY')
  ].join(' - ')
}

const EventToken = (event) => (
  <div className="event-token">
    { event.program &&
      <div className="event-token-logo">
        <Logo team={ event.program } width="24" />
      </div>
    }
    <div className="event-token-label">
      <div className="token-link">
        { event.title }
      </div>
      <div className="token-stats">
        { event.canceled_at !== null &&
          <span className="canceled">CANCELED</span>
        }
        <span className="active"> { getDate(event) }</span>
        <span>{ pluralize('registration', event.registrations_count, true)}</span>
        <span>{ pluralize('ticket', event.tickets_count, true)}</span>
        <span>{ event.waitings_count } waiting</span>
        <span>{ numeral(event.revenue).format('$0.00') } revenue</span>
      </div>
    </div>
  </div>
)

EventToken.propTypes = {
  end_date: PropTypes.string,
  program: PropTypes.object,
  start_date: PropTypes.string,
  title: PropTypes.string
}

export default EventToken
