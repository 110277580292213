import CoversationsShow from './conversations/show'
import NumbersList from './numbers/list'
import NumbersShow from './numbers/show'
import CallsShow from './calls/show'

const routes = [
  { path: '/calls/:id/:view', component: CallsShow },
  { path: '/conversations/:program_phone_number_id/:contact_phone_number_id', component: CoversationsShow },
  { path: '/numbers/:id/:view', component: NumbersShow },
  { path: '/numbers/:view', component: NumbersList }
]

export default routes
