import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Signups)',
  collection: {
    endpoint: '/api/admin/truevail/admin/signups',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'team', primary: true, format: (advisor) => <TeamToken { ...advisor.team } /> },
      { label: 't(Signup Page)', key: 'signup.signup_page.title', sort: 'signup_page', primary: true },
      { label: 't(Signed Up)', key: 'signup.created_at', sort: 'signed_up_at', collapsing: true,  format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check-square',
      title: 't(No Signups)',
      text: 't(No one has signed up yet)',
      buttons: [
        {
          label: 't(Create Signup)',
          modal: New
        }
      ]
    },
    entity: 'signup',
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${record.id}/signup`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Signup)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
