import Report from './report'
import Card from './card'
import Edit from './edit'
import New from './new'

const card = {
  code: 'email_campaign_unsubscribe_rate',
  card: Card,
  edit: Edit,
  new: New,
  report:  Report
}

export default card
