import Preferences from './preferences'
import Divider from './divider'
import Follow from './follow'
import Button from './button'
import Video from './video'
import Share from './share'
import Image from './image'
import Text from './text'
import Web from './web'


const blocks = [
  { label: 't(Text)', type: 'text', icon: 'align-justify', component: Text },
  { label: 't(Divider)', type: 'divider', icon: 'minus', component: Divider },
  { label: 't(Image)', type: 'image', icon: 'picture-o', component: Image },
  { label: 't(Button)', type: 'button', icon: 'mouse-pointer', component: Button },
  { label: 't(Preferences)', type: 'preferences', icon: 'check', component: Preferences },
  { label: 't(Social Share)', type: 'share', icon: 'share', component: Share },
  { label: 't(Social Follow)', type: 'follow', icon: 'plus', component: Follow },
  { label: 't(Video)', type: 'video', icon: 'play', component: Video },
  { label: 't(Web Version)', type: 'web', icon: 'globe', component: Web }
]

export default blocks