import ProgressPie from '@admin/components/progress/pie'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Process extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    entity: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    progress: null,
    status: 'processing'
  }

  _handleFailure = this._handleFailure.bind(this)
  _handleProgress = _.throttle(this._handleProgress.bind(this), 150)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { status } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        { status === 'processing' && 
          <ProgressPie { ...this._getProgress() } />
        }
        { status === 'success' &&
          <Message { ...this._getSuccess() } />
        }
        { status === 'failure' &&
          <Message { ...this._getFailure() } />
        }
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleJoin()
    this._handleProcess()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  _getFailure() {
    return {
      title: 't(Import Failed)',
      text: 't(Oh no! Your data was not imported successfully. Please double-check your spreadsheet and try again.)',
      icon: 'warning',
      color: 'red',
      animation: 'shake'
    }
  }

  _getPanel() {
    const { entity } = this.props
    return {
      title: `Importing ${_.startCase(pluralize(entity))}`
    }
  }

  _getProgress() {
    const { progress } = this.state
    const { entity } = this.props
    return {
      title: `Importing ${_.startCase(pluralize(entity))}`,
      ...progress || {}
    }
  }

  _handleFailure(err) {
    this.setState({
      status: 'failure'
    })
  }

  _handleJoin() {
    const { admin, network } = this.context
    const { _import } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/${_import.object_type}/imports/${_import.id}/process`
    network.subscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.subscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.subscribe({ channel, action: 'failed', handler: this._handleFailure })
  }

  _handleLeave() {
    const { admin, network } = this.context
    const { _import } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/${_import.object_type}/imports/${_import.id}/process`
    network.unsubscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.unsubscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.unsubscribe({ channel, action: 'failed', handler: this._handleFailure })
  }

  _handleProcess() {
    const { _import } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${_import.object_type}/imports/${_import.id}/process`,
      method: 'PATCH'
    })
  }

  _handleProgress({ completed, total }){
    const { progress } = this.state
    this.setState({ 
      progress: {
        completed: progress && completed <= progress.completed ? progress.completed : completed,
        total
      } 
    })
  }

  _handleSuccess() {
    setTimeout(this.props.onDone, 1000)
  }

}

export default Process
