import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const alignments = [
  { label: 'Left', icon: 'align-left', code: 'left' },
  { label: 'Center', icon: 'align-center', code: 'center' },
  { label: 'Right', icon: 'align-right', code: 'right' }
]

class AlignmentField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    selected: null
  }

  render() {
    return (
      <div className="alignmentfield">
        { alignments.map((style, index) => (
          <div key={`style_${index}`} {...this._getStyle(style)}>
            <Icon icon={`${style.icon}`} />
          </div>
        ))}
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    const { value } = this.props
    if(selected !== prevState.selected) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getStyle(style) {
    return {
      className: this._getClass(style),
      onClick: this._handleChoose.bind(this, style.code)
    }
  }

  _getClass(style) {
    const { selected } = this.state
    const classes = ['alignmentfield-option']
    if(_.includes(selected, style.code)) classes.push('selected')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { selected } = this.state
    this.props.onChange(selected !== 'left' ? selected : null)
  }

  _handleChoose(selected) {
    this.setState({ selected })
  }

  _handleSet(value) {
    const selected = value === null ? 'left' : value
    this.setState({ selected })
  }

}

export default AlignmentField
