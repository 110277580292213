import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Content from './content'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    type: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { type } = this.props
    return {
      title: type === 'email_article' ? 't(Import Email Articles)' : 't(Import Email Modules)',
      action: '/api/admin/truevail/agency/content',
      method: 'POST',
      props: {
        type
      },
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    const { type } = this.props
    return {
      content_ids: formdata.contents.map(content => {
        return content.id
      }),
      type,
      voice: formdata.voice,
      language: formdata.language,
      perspective: formdata.perspective,
      status: formdata.status
    }
  }

  _getSteps() {
    const { type } = this.props
    return [
      { 
        label: type === 'email_article' ? 't(Articles)' : 't(Modules)', 
        component: Content
      },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

export default Import
