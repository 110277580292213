import Page from '@admin/components/page'
import itemButtons from '../item_buttons'
import itemTasks from '../item_tasks'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  expense: `/api/admin/finance/expenses/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Expense)',
  access: { rights: { $oneOf: ['finance:view_expenses','finance:manage_expenses'] } },
  panel: {
    component: <Overview expense={ resources.expense } />
  },
  tasks: itemTasks('expense', resources.expense, context, Edit),
  buttons: itemButtons(resources.app.settings, 'expense', resources.expense, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
