import MahaPanel from '@admin/components/panel/index.js'
import { CSSTransition } from 'react-transition-group'
import NewPanel from '../panels/new'
import PropTypes from 'prop-types'
import Card from '../cards/show'
import React from 'react'
import Grid from './grid'

class Panel extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    panel: PropTypes.object
  }

  state = {
    daterange: {
      type: '90_days',
      step: 'week'
    },
    expanded: null,
    isExpanded: false,
    cards: null
  }

  _handleDateRange = this._handleDateRange.bind(this)
  _handleLayout = this._handleLayout.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { cards, isExpanded, expanded } = this.state
    if(!cards) return null
    const ecard = expanded ? _.find(cards, { id: expanded }) : null
    return (
      <div className="maha-dashboard-panel">
        <Grid { ...this._getGrid() } />
        <CSSTransition in={ isExpanded } timeout={ 250 } classNames="appear" mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-dashboard-panel-overlay" />
        </CSSTransition>
        <CSSTransition in={ isExpanded } timeout={ 250 } classNames="appear" mountOnEnter={ true } unmountOnExit={ true }>
          { () => (
            <div className="maha-dashboard-panel-expanded">
              <Card { ...this._getCard(ecard, 0, true) } />
            </div>
          ) }
        </CSSTransition>
      </div>
    )
  }

  componentDidMount() {
    const { panel } = this.props
    this._handleSet(panel.cards)
  }

  componentDidUpdate(prevProps) {
    const { panel } = this.props
    if(!_.isEqual(panel.cards, prevProps.panel.cards)) {
      this._handleSet(panel.cards)
    }
  }

  _getAdd() {
    return {
      icon: 'plus',
      basic: true
    }
  }

  _getCard(card, index, isExpanded) {
    const { daterange } = this.state
    const { panel } = this.props
    return {
      card,
      daterange,
      index,
      panel,
      isExpanded,
      showChart: _.includes(card.config.elements, 'chart') || isExpanded,
      showTable: _.includes(card.config.elements, 'table') || isExpanded,
      onToggle: this._handleToggle.bind(this, card.id)
    }
  }

  _getGrid() {
    const { card_types, panel } = this.props
    const { cards, daterange } = this.state
    return {
      card_types,
      cards,
      daterange,
      panel,
      onDateRange: this._handleDateRange,
      onLayout: this._handleLayout,
      onToggle: this._handleToggle
    }
  }

  _handleDateRange(daterange) {
    this.setState({ daterange })
  }

  _handleLayout(layout) {
    const { panel } = this.props
    this.context.network.request({
      endpoint: `/api/admin/dashboards/panels/${panel.id}/layout`,
      method: 'patch',
      body: { layout }
    })
  }

  _handleSet(cards) {
    this.setState({ cards })
  }

  _handleToggle(id) {
    const { isExpanded } = this.state
    this.setState({
      isExpanded: !isExpanded,
      expanded: id
    })
  }

}

const mapPropsToPanel = (props, context) => ({
  title: props.panel.title,
  component: <Panel { ...props } />,
  task: {
    access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
    modal: <NewPanel />
  }
})

const mapResourcesToPanel = (props, context) => ({
  panel: {
    endpoint: `/api/admin/dashboards/panels/${props.panel.id}`,
    refresh: `/users/${context.admin.user.id}/admin/dashboards/panels/${props.panel.id}`
  }
})

export default MahaPanel(mapResourcesToPanel, mapPropsToPanel)
