import ProgramToken from '@apps/crm/admin/tokens/program'
import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Program extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel()}>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 'Choose Program',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ]
    }
  }

  _getSearch() {
    return {
      autofocus: true,
      endpoint: '/api/admin/programs',
      search: false,
      format: ProgramToken,
      label: 'program',
      onChange: this._handleChoose,
      valueKey: null
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChoose(program) {
    this.props.onChoose(program)
  }

}

export default Program
