import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import ImageStyleField from '@admin/components/mjson_designer/components/imagestylefield'
import FlexField from '@admin/components/mjson_designer/components/flexfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

const Flex = (props) => <ResponsiveField { ...props } component={ FlexField } />

class EditVideoBlock extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, theme, type } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Video)', name: 'content.video', type: 'videofield', defaultValue: config.content.video },
              ]
            }, {
              label: 't(Options)',
              fields: [
                { label: 't(Video Overlay)', name: 'styles.videoOverlay', device, type: 'dropdown', options: [
                  { value: 'play-circle-black-bordered', text: 't(Circle Black Bordered)' },
                  { value: 'play-circle-black', text: 't(Circle Black)' },
                  { value: 'play-circle-white-bordered', text: 't(Circle White Bordered)' },
                  { value: 'play-circle-white', text: 't(Circle White)' },
                  { value: 'play-rounded-black', text: 't(Rounded Black)' },
                  { value: 'play-rounded-blue', text: 't(Rounded Blue)' },
                  { value: 'play-rounded-red', text: 't(Rounded Red)' },
                  { value: 'play-rounded-white', text: 't(Rounded White)' }
                ], defaultValue: 'play-rounded-red', value: config.styles.videoOverlay }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Video)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Display)', name: 'styles.display', device, canHover: false, type: Flex, omitFeatures: ['height'], theme, defaultValue: {}, value: config.styles.display },
                { label: 't(Video)', name: 'styles.video', device, type: ImageStyleField, omitFeatures: ['display','overlayColor','filters'], theme, value: config.styles.video }
              ]
            },
            styles.BackgroundSection(config, device, theme, type),
            styles.BorderSection(config, device, theme, type),
            styles.SpacingSection(config, device, theme, type),
            styles.VisibilitySection(config, device, theme)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditVideoBlock
