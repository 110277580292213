export const INITIAL_STATE = {
  chosen: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CHOOSE':
    return {
      ...state,
      chosen: action.chosen
    }

  case 'REMOVE':
    return {
      ...state,
      chosen: state.chosen.filter((i, j) => {
        return j !== action.index
      })
    }

  default:
    return state

  }

}

export default reducer
