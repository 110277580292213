import Conversations from './conversations'
import Page from '@admin/components/page'
import Performance from './performance'
import T from '@admin/components/t'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_campaign.title,
  manager: {
    path: `/admin/campaigns/social/${resources.social_campaign.id}`,
    ...resources.social_campaign.variants.find(variant => variant.collection_status === 'failed') ? {
      alert: {
        style: 'error',
        message: (
          <>
            <T text={ 't(Unable to collect post performance insights from the following profiles:)' } />
            { resources.social_campaign.variants.filter(variant => variant.collection_status === 'failed').map((variant, index) => (
              <div key={`variant_${index}`}>
                <strong>{ variant.profile.name} ({variant.profile.service.name })</strong>
              </div>
            )) }
          </>
        )
      }
    } : {},
    items: [
      {
        label: 't(Overview)',
        panel: <Overview social_campaign={ resources.social_campaign } variants={ resources.variants } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design social_campaign={ resources.social_campaign } />
      },
      {
        label: 't(Conversations)',
        path: '/conversations',
        show: resources.social_campaign.status === 'posted',
        panel: <Conversations social_campaign={ resources.social_campaign } variants={ resources.variants } />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        show: resources.social_campaign.status === 'posted',
        panel: <Performance social_campaign={ resources.social_campaign } variants={ resources.variants } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  social_campaign: `/api/admin/campaigns/social/${props.params.id}`,
  variants: `/api/admin/campaigns/social/${props.params.id}/variants`
})

export default Page(mapResourcesToPage, mapPropsToPage)
