import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ pipeline }, { admin }) => {

  const program = {
    label: pipeline.program.title,
    className: 'link',
    route: `/admin/team/programs/${pipeline.program.id}`
  }

  const details = {
    audits: `sales_pipelines/${pipeline.id}`,
    comments: `sales_pipelines/${pipeline.id}`,
    sections: [
      {
        title: 't(Sales Pipeline Details)',
        items: [
          { label: 't(Title)', content: pipeline.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  pipeline: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview pipeline={ props.pipeline } />,
  tasks: {
    items: [
      { label: 'Edit Pipeline', modal: <Edit pipeline={ props.pipeline } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
