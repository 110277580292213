import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class PostsField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    items: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.border) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { border } = this.state
    const { value } = this.props
    if(!_.isEqual(border, prevState.border)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: []
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { items } = this.state
    // this.props.onChange({
    //   datasource: 'posts',
    //   records: items.records,
    //   limit: 3
    // })
  }

  _handleSet(items) {
    this.setState({
      items: {
        datasource: 'posts',
        records: items.records,
        limit: 3
      }
    })
  }

}

export default PostsField
