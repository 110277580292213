import TextField from '@admin/components/form/textfield'
import PropTypes from 'prop-types'
import React from 'react'

class PositionField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onEnter: () => {},
    onReady: () => {}
  }

  state = {
    position: {
      organization: null,
      job_title: null
    }
  }

  _handleUpdate = this._handleUpdate.bind(this)
  _handleEnter = this._handleEnter.bind(this)

  render() {
    return (
      <div className="crm-positionfield">
        <div className="crm-positionfield-field">
          <TextField { ...this._getOrganization() } />
        </div>
        <div className="crm-positionfield-field">
          <TextField { ...this._getTitle() } />
        </div>
      </div>

    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { position } = this.state
    const { value } = this.props
    if(!_.isEqual(position, prevState.position)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getOrganization() {
    const { tabIndex, onBlur, onFocus } = this.props
    const { position } = this.state
    return {
      placeholder: 't(Enter Organization)',
      type: 'text',
      tabIndex,
      value: position.organization,
      onBlur,
      onChange: this._handleUpdate.bind(this, 'organization'),
      onEnter: this._handleEnter,
      onFocus
    }
  }

  _getTitle() {
    const { tabIndex, onBlur, onFocus } = this.props
    const { position } = this.state
    return {
      placeholder: 't(Enter Job Title)',
      type: 'text',
      tabIndex,
      value: position.job_title,
      onBlur,
      onChange: this._handleUpdate.bind(this, 'job_title'),
      onEnter: this._handleEnter,
      onFocus
    }
  }

  _handleChange() {
    const { position } = this.state
    this.props.onChange(position.job_title && position.organization ? position : null)
  }

  _handleEnter() {
    const { position } = this.state
    if(!position.organization || position.organization.length === 0) return
    this.props.onEnter()
  }

  _handleSet(value) {
    this.setState({
      position: {
        organization: null,
        job_title: null,
        ...value || {}
      }
    })
  }

  _handleUpdate(key, position) {
    this.setState({
      position: {
        ...this.state.position,
        [key]: position
      }
    })
  }

}

export default PositionField
