import Message from '@admin/components/message'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Authorize extends React.Component {

  static contextTypes = {
    provider: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    onCancel: PropTypes.func,
    onNext: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(Mobile Phone Verification)',
      action: '/api/admin/account/mobile/authorize',
      method: 'POST',
      saveText: 'Next',
      cancelIcon: 'chevron-left',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      before: <Message { ...this._getMessage() } />,
      sections: [
        {
          fields: [
            { name: 'mobile_phone', type: 'phonefield', placeholder: 't(Enter Mobile Phone)', required: true }
          ]
        }
      ]
    }
  }

  _getMessage() {
    const { provider } = this.context
    return {
      icon: 'phone',
      backgroundColor: 'darkblue',
      title: 't(Authorize Your Mobile Phone)',
      text: `t(If you authorize your mobile phone, ${provider.title} can send you notifications via text message.)`
    }
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleSuccess() {
    this.props.onNext()
  }

}

export default Authorize
