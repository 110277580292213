const calendar = [
  {
    code: 'email_campaigns_scheduled',
    entity: 'advisor email campaign',
    action: 'scheduled',
    query: `
    select campaigns_email_campaigns.id, 
    41 as team_id, 
    'truevail:email_campaigns_scheduled' as code,
    date(campaigns_email_campaigns.send_at) as date
    from campaigns_email_campaigns
    inner join truevail_publishings on truevail_publishings.id=campaigns_email_campaigns.truevail_publishing_id
    where campaigns_email_campaigns.sent_at is null
    and campaigns_email_campaigns.send_at is not null
    and campaigns_email_campaigns.deleted_at is null
    `
  },
  { 
    code: 'email_campaigns_sent',
    entity: 'advisor email campaign',
    action: 'sent',
    query: `
    select campaigns_email_campaigns.id, 
    41 as team_id, 
    'truevail:email_campaigns_sent' as code,
    date(campaigns_email_campaigns.sent_at) as date
    from campaigns_email_campaigns
    inner join truevail_publishings on truevail_publishings.id=campaigns_email_campaigns.truevail_publishing_id
    where campaigns_email_campaigns.sent_at is not null
    and campaigns_email_campaigns.deleted_at is null
    `
  }
]

export default calendar
