import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Verify extends React.Component {

  static contextTypes = {
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    onNext: PropTypes.func
  }

  state = {
    message: null
  }
  
  _handleVerify = this._handleVerify.bind(this)

  render() {
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon={ this._getIcon() } /></h1>
            <h2><T text="t(Verifying Reset Link)" /></h2>
            { message &&
              <p>{ message }</p>
            }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(this._handleVerify, 500)
  }

  _getIcon() {
    const { message } = this.state
    return message ? 'warning' : 'circle-o-notch fa-spin'
  }

  _handleVerify() {
    const { pathname } = this.context.router.location
    const [,token] = pathname.match(/^\/reset\/(.*)/)
    this.context.network.request({
      endpoint: '/api/admin/reset/verify',
      method: 'POST',
      body: {
        token
      },
      onSuccess: ({ data }) => {
        const { account, security_question, strategy } = data
        this.props.onNext(strategy, {
          account,
          security_question,
          token
        })
      },
      onFailure: ({ message }) => {
        this.setState({ message })
      }
    })
  }

}

export default Verify
