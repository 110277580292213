import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const methods = {
  ach: 't(Bank Account)',
  cash: 't(Cash)',
  card: 't(Credit Card)',
  check: 't(check)',
  credit: 't(Customer Credit)',
  ebt: 't(Electronic Benefit Transfer (EBT))',
  scholarship: 't(Scholarship)'  
}

const PaymentMethodToken = ({ value }) => (
  <div className="token">
    <T text={ methods[value] } />
  </div>
)

PaymentMethodToken.propTypes = {
  value: PropTypes.string
}

export default PaymentMethodToken
