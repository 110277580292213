import ReminderField from '@apps/tasks/admin/components/reminderfield'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    cancel: PropTypes.object,
    contact: PropTypes.object,
    deal: PropTypes.object,
    program_id: PropTypes.number,
    team: PropTypes.object,
    onBack: PropTypes.func
  }

  state = {
    task: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { contact, deal, program_id } = this.props
    const { user } = this.context.admin
    const { task } = this.state
    return {
      title: 't(New Task)',
      method: 'POST',
      action: '/api/admin/tasks/tasks',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program_id },
            { label: 't(Task)', type: 'segment', fields: [
              { name: 'title', type: 'textfield', required: true, placeholder: 't(Enter Title)' },
              { name: 'description', type: 'textarea', placeholder: 't(Enter Description)' }
            ] },
            { label: 't(Assigned To)', name: 'assignee_ids', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', multiple: true, required: true, defaultValue: user.id, format: UserToken },
            { label: 't(Due)', type: 'segment', fields: [
              { name: 'is_due', type: 'radiogroup', options: [
                { value: false, text: 't(No due date)' },
                { value: true, text: 't(This task has a due date)' }
              ], defaultValue: false },
              { type: 'fields', show: task.is_due, fields: [
                { name: 'due_date', type: 'datefield', defaultValue: moment() },
                { name: 'due_time', type: 'timefield', defaultValue: '09:00' }
              ] }
            ] },
            { label: 't(Reminders)', name: 'reminders', type: ReminderField, relative: task.is_due },
            ...contact ? [
              { name: 'contact_id', type: 'hidden', value: contact.id }
            ] : [],
            ...deal ? [
              { name: 'deal_id', type: 'hidden', value: deal.id }
            ] : []
          ]
        }
      ]
    }
  }

  _getDate(duration) {
    const format = 'dddd, MMMM D'
    const today = moment()
    if(duration === 'today') return today.format(format)
    if(duration === 'tomorrow') return today.add(1, 'days').format(format)
    if(duration === '2_days') return this._getBusinessDay(today.add(2, 'days')).format(format)
    if(duration === '3_days') return this._getBusinessDay(today.add(3, 'days')).format(format)
    if(duration === '1_week') return today.add(1, 'weeks').format(format)
    if(duration === '2_weeks') return today.add(2, 'weeks').format(format)
    if(duration === '1_month') return today.add(1, 'months').format(format)
    if(duration === '2_months') return today.add(2, 'months').format(format)
    if(duration === '3_months') return today.add(3, 'months').format(format)
  }

  _getBusinessDay(candidate) {
    const dayOfWeek = parseInt(candidate.format('E'))
    return dayOfWeek > 5 ? candidate.add(2, 'days') : candidate
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(task) {
    this.setState({ task })
  }

  _handleSuccess() {
    const { contact, deal } = this.props
    if(contact || deal) this.context.flash.set('success', 't(Task was successfully created)')
    this.context.modal.close()
  }

}

export default New
