import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Usage)',
  collection: {
    endpoint: `/api/admin/platform/teams/${props.team.id}/usage`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Period)', key: 'period', sort: 'start', primary: true },
      { label: 't(Emails)', key: 'emails_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(Minutes)', key: 'minutes_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(SMS)', key: 'smses_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(Numbers)', key: 'phone_numbers_count', collapsing: true, align: 'right', format: 'number' }
    ],
    defaultSort: { key: 'start', order: 'desc' },
    empty: {
      icon: 'usage',
      title: 't(No Usage)',
      text: 't(This team has not yet used any resources)'
    },
    entity: 'usage'
  }
})

export default Panel(null, mapPropsToPanel)
