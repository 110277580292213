import Panel from '@admin/components/panel'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const categories = [
  { label: 'Race', options: [
    { label: 'African', key: 'african' },
    { label: 'American Indian', key: 'american_indian' },
    { label: 'Asian', key: 'asian' },
    { label: 'Caucasian', key: 'caucasian' },
    { label: 'Multiracial', key: 'multiracial' },
    { label: 'Pacific Islander', key: 'pacific_islander' },
    { label: 'Other', key: 'other_race' },
    { label: 'Undeclared', key: 'undeclared_race' }
  ] },
  { label: 'Ethnicity', options: [
    { label: 'Hispanic', key: 'hispanic' },
    { label: 'Non-Hispanic', key: 'nonhispanic' },
    { label: 'Undeclared', key: 'undeclared_ethnicity' }
  ] },
  { label: 'Gender', options: [
    { label: 'Female', key: 'female' },
    { label: 'Male', key: 'male' },
    { label: 'Other', key: 'other_gender' },
    { label: 'Undeclared', key: 'undeclared_gender' }
  ] },
  { label: 'Disability', options: [
    { label: 'Disabled', key: 'disabled' },
    { label: 'Not Disabled', key: 'notdisabled' },
    { label: 'Undeclared', key: 'undeclared_disability' }
  ] }
]

class Demographics extends React.Component {

  static propTypes = {
    demographics: PropTypes.object,
    event: PropTypes.object
  }

  render() {
    const { demographics } = this.props
    return (
      <div className="crm-report">
        <div className="crm-report-header">
        </div>
        <div className="crm-report-table">
          <table className="ui unstackable table">
            <tbody>
              { categories.map((category, cindex) => (
                <Fragment key={`category_${cindex}`}>
                  <tr className="heading">
                    <td colSpan="2">{ category.label }</td>
                  </tr>
                  { category.options.map((option, index) => (
                    <tr key={`category_${cindex}_${index}`}>
                      <td>{ option.label }</td>
                      <td className="right aligned collapased">
                        { demographics[option.key] }
                      </td>
                    </tr>
                  )) }
                </Fragment>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

const mapResourcesToPanel = (props, context) => ({
  demographics: `/api/admin/events/events/${props.event.id}/demographics`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Demographics)',
  panel: <Demographics event={ props.event } demographics={ props.demographics } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
