import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  remove: {
    title: 't(Remove Membership)',
    description: 't(Remove this user from all projects)'
  },
  nothing: {
    title: 't(Do Nothing)',
    description: 't(Leave this users memberships intact)'
  }
}

const ExpenseStrategyToken = ({ value }) => {
  const strategy = strategies[value]
  return (
    <div className="type-token">
      <strong><T text={ strategy.title } /></strong><br />
      <T text={ strategy.description } />
    </div>
  )
}

ExpenseStrategyToken.propTypes = {
  value: PropTypes.string
}

export default ExpenseStrategyToken
