import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class MJSONScreenshot extends React.PureComponent {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.string,
    height: PropTypes.number,
    engine: PropTypes.string,
    width: PropTypes.number,
    placeholder: PropTypes.bool,
    screenshoted_at: PropTypes.string
  }

  static defaultProps = {
    engine: 'mjson',
    placeholder: false
  }

  state = {
    status: 'pending'
  }

  screenshotRef = React.createRef()

  _handleError = this._handleError.bind(this)
  _handleLoaded = this._handleLoaded.bind(this)

  render() {
    const { status } = this.state
    const showImg = this.props.placeholder === false
    const sowLoader = this.props.placeholder || status === 'loading'
    const showFailed = this.props.placeholder === false && status === 'failed'
    return (
      <div { ...this._getScreenshot() }>
        { showImg && 
          <Img { ...this._getImg() } /> 
        }
        { sowLoader &&
          <div className="maha-mjson-screenshot-inner">
            <Icon icon="spin fa-circle-o-notch" />
          </div>
        }
        { showFailed &&
          <div className="maha-mjson-screenshot-inner">
            <Icon icon="exclamation-triangle" />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if(!entry.isIntersecting) return
        observer.unobserve(entry.target)
        this._handleLoad()
      })
    })
    observer.observe(this.screenshotRef.current)
  }

  _getClass() {
    const { status } = this.state
    const classes = ['maha-mjson-screenshot']
    classes.push(status)
    return classes.join(' ')
  }

  _getImg() {
    return {
      src: this._getSrc(),
      onLoad: this._handleLoaded,
      onError: this._handleError
    }
  }

  _getScreenshot() {
    return {
      ref: this.screenshotRef,
      className: this._getClass(),
      style: this._getStyle()
    }
  }

  _getSrc() {
    const { engine, entity } = this.props
    const { provider } = this.context
    const timestamp = this._getTimestamp()
    return `${provider.cdn_host}/${engine}/dynamic/${timestamp}/${entity}/screenshot.jpg`
  }

  _getStyle() {
    const { width, height } = this.props
    return {
      paddingBottom: `calc(100% * (${height} / ${width}))`
    }
  }

  _getTimestamp() {
    const { screenshoted_at } = this.props
    return moment(screenshoted_at).format('x')
  }

  _handleError(e) {
    this._handleStatus('failed')
  }

  _handleLoad() {
    if(this.state.status !== 'pending') return
    this._handleStatus('loading')
  }

  _handleLoaded() {
    this._handleStatus('loaded')
  }

  _handleStatus(status) {
    this.setState({ status })
  }

}

export default MJSONScreenshot
