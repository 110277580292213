import RulesField from '../../components/rulesfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Rules extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    rules: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    if(!this.state.rules) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { config } = this.props
    this.setState({
      rules: _.get(config, 'rules.rules') || []
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { rules } = this.state
    if(!_.isEqual(rules, prevState.rules)) {
      this.props.onUpdate('rules', rules)
    }
  }

  _getForm() {
    const { rules } = this.state
    return {
      title: 't(Rules)',
      onCancel: this._handleDone,
      onChange: this._handleChange,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleDone }
      ],
      sections: [
        {
          fields: [
            { name: 'rules', type: RulesField, fields: this._getFields(), defaultValue: rules }
          ]
        }
      ]
    }
  }

  _getFields() {
    const { fields } = this.props.config
    return fields.map(field => ({
      code: field.code,
      name: field.name.value,
      type: _.get(field, 'contactfield.type') || field.type,
      options: _.get(field, 'contactfield.options') || field.options
    }))
  }

  _handleChange(rules) {
    this.setState({ rules })
  }

  _handleDone() {
    this.props.onPop()
  }

}

export default Rules
