import InputTokens from '@admin/components/input_tokens'
import Button from '@admin/components/button'
import Token from '@admin/tokens/token'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Chooser from './chooser'
import React from 'react'

class Lookup extends React.Component {

  static contextTypes = {
    form: PropTypes.object, 
    locale: PropTypes.object, 
    network: PropTypes.object
  }

  static propTypes = {
    chosen: PropTypes.array,
    compareKey: PropTypes.string,
    defaultQuery: PropTypes.object,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    filter: PropTypes.object,
    form: PropTypes.object,
    format: PropTypes.any,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    oneAtATime: PropTypes.bool,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    prompt: PropTypes.string,
    search: PropTypes.bool,
    showToggles: PropTypes.bool,
    sort: PropTypes.object,
    tabIndex: PropTypes.number,
    textKey: PropTypes.string,
    token: PropTypes.any,
    valueKey: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onRemove: PropTypes.func,
    onSetChosen: PropTypes.func
  }

  static defaultProps = {
    format: Token,
    multiple: false,
    oneAtATime: false,
    placeholder: null,
    search: true,
    valueKey: 'value',
    textKey: 'text',
    onReady: () => {}
  }

  state = {
    focused: false
  }

  _handleBegin = this._handleBegin.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleKeyUp = this._handleKeyUp.bind(this)
  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { chosen } = this.props
    if(!chosen) return null
    return (
      <div { ...this._getInput() } onClick={ this._handleBegin }>
        { chosen.length > 0 ?
          <InputTokens { ...this._getInputTokens() } /> :
          <div className="maha-input-placeholder">
            <T text={ this._getPlaceholder() } />
          </div>
        }
        { chosen.length > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const { endpoint, multiple } = this.props
    const defaultValue = this._getDefaultValue()
    if(endpoint && defaultValue && (!multiple || defaultValue.length > 0)) return this._handleFetch(defaultValue)
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { chosen } = this.props
    if(!_.isEqual(chosen, prevProps.chosen)) {
      this._handleChange()
    }
  }

  _getChooser() {
    const { chosen, defaultQuery, endpoint, entity, filter, form, format, label, multiple, oneAtATime, options, sort, search, showToggles, token, textKey, valueKey, onSetChosen } = this.props
    return {
      chosen,
      defaultQuery,
      endpoint,
      entity,
      filter,
      form,
      format,
      label,
      multiple,
      oneAtATime,
      options,
      search,
      showToggles,
      sort,
      textKey,
      token,
      valueKey,
      onSetChosen
    }
  }

  _getClass() {
    const { disabled, multiple } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-lookup-field']
    if(multiple) classes.push('multiple')
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? _.castArray(value) : !_.isNil(defaultValue) ? _.castArray(defaultValue) : null
  }

  _getInput() {
    const { tabIndex } = this.props
    return {
      className: this._getClass(),
      tabIndex,
      onKeyUp: this._handleKeyUp
    }
  }

  _getInputTokens() {
    const { chosen, textKey } = this.props
    return {
      tokens: chosen,
      textKey,
      onRemove: this._handleRemove
    }
  }

  _getPlaceholder() {
    const { entity, label, placeholder, prompt } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(prompt && prompt.length > 0) return t(prompt)
    if(entity && entity.length > 0) return t(`t(Choose) ${entity}`)
    if(label && label.length > 0) return t(`t(Choose) ${label}`)
    return ''
  }

  _getValueKey() {
    const { multiple, chosen, valueKey } = this.props
    const valueKeys = chosen.map(item => valueKey ? _.get(item, valueKey) : item)
    return (multiple ? valueKeys : valueKeys[0]) || null
  }

  _handleBegin() {
    const { disabled } = this.props
    if(disabled) return this.input.blur()
    this.context.form.push(Chooser, this._getChooser.bind(this))
  }

  _handleChange() {
    this.props.onChange(this._getValueKey())
  }

  _handleClear() {
    this.props.onSetChosen([])
  }

  _handleFetch(defaultValue) {
    const { compareKey, valueKey, endpoint } = this.props
    const key = compareKey || valueKey
    this.context.network.request({
      endpoint,
      method: 'GET',
      query: { 
        $filter: { 
          [key]: { $in: defaultValue.map(value => {
            return value?.[key] || value
          }) } 
        } 
      },
      onSuccess: ({ data }) => {
        this.props.onSetChosen(data)
        this.props.onReady()
      },
      onFailure: () => {}
    })
  }

  _handleKeyUp(e) {
    if(e.key !== 'Enter') return
    this._handleBegin()
  }

  _handleRemove(item, index) {
    this.props.onRemove(index)
  }

  _handleSet(value) {
    const defaultValue = value || []
    const { valueKey } = this.props
    const options = this.props.options || []
    const selected = options.filter(option => {
      return defaultValue.find(v => {
        return _.get(option, valueKey) === v
      }) !== undefined
    })
    this.props.onSetChosen(selected)
  }

}

export default Lookup
