import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import Infinite from './infinite'
import reducer from './reducer'
import React from 'react'

const InfiniteContainer = (props) => {

  const { network } = useNetworkContext()

  const { admin } = useAdminContext()

  const [state, handlers] = useActionReducer({
    display_name: 'infinite',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      admin,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Infinite { ...mergedProps } />

}

InfiniteContainer.propTypes = {}

export default InfiniteContainer