import PropTypes from 'prop-types'
import Card from './card'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    card:  PropTypes.object,
    panel:  PropTypes.object
  }

  render() {
    const Component = this._getComponent()
    return <Component { ...this.props } />
  }

  _getComponent() {
    const { maha } = this.context
    const { card } = this.props
    const card_type = _.find(maha.dashboardCards, {
      code: card.type.code
    })
    return card_type.edit || Card
  }

}

export default Edit
