import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object,
    ticket_type: PropTypes.object
  }

  state = {
    ticket_type: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event, ticket_type } = this.props
    return {
      title: 't(Edit Ticket Type)',
      endpoint: `/api/admin/events/events/${event.id}/ticket_types/${ticket_type.id}/edit`,
      action: `/api/admin/events/events/${event.id}/ticket_types/${ticket_type.id}`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'is_active', type: 'hidden' },
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' },
            { label: 't(Pricing)', type: 'segment', required: true, fields: [
              { name: 'price_type', type: 'radiogroup',options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'sliding_scale', text: 't(Sliding Scale)' },
                { value: 'free', text: 't(Free)'}
              ], required: true },
              ...this._getPriceType()
            ] },
            { label: 't(Availability)', type: 'segment', fields: [
              { type: 'fields', fields: [
                { label: 't(Total Tickets)', name: 'total_tickets', type: 'numberfield' },
                { label: 't(Maximum Per Order)', name: 'max_per_order', type: 'numberfield' }
              ] },
              { type: 'fields', fields: [
                { label: 't(Sales Open)', name: 'sales_open_at', type: 'datetimefield' },
                { label: 't(Sales Close)', name: 'sales_close_at', type: 'datetimefield' }
              ] }
            ] }
          ]
        }
      ]
    }
  }

  _getPriceType() {
    const { ticket_type } = this.state
    if(ticket_type.price_type === 'fixed') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup',endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', filter: { id: { $in: [25,26,49] } }, valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken }
        ] },
        { label: 't(Fixed Price)', name: 'fixed_price', type: 'moneyfield', required: true },
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true  },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)'  }
      ]
    }
    if(ticket_type.price_type === 'sliding_scale') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', filter: { id: { $in: [25,26,49] } }, valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken }
        ] },
        { type: 'fields', fields: [
          { label: 't(Low Price)', name: 'low_price', type: 'moneyfield', required: true },
          { label: 't(High Price)', name: 'high_price', type: 'moneyfield', required: true }
        ] },
        { label: 't(Overage Strategy)', name: 'overage_strategy', type: 'dropdown', options: [
          { value: 'income', text: 't(Treat any amount over the low price as additional income)' },
          { value: 'donation', text: 't(Treat any amount over the low price as a donation)' }
        ], required: true },
        ...this._getOverageStrategy(),
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', placeholder: 'Tax Rate', required: true  },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)' }
      ]
    }
    return []
  }

  _getOverageStrategy() {
    const { ticket_type } = this.state
    if(ticket_type.overage_strategy === 'donation') {
      return [
        { label: 't(Donation Revenue Type)', name: 'donation_revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', filter: { id: { $in: [30, 37] } }, valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken }
      ]
    }
    return []
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(ticket_type) {
    this.setState({ ticket_type })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
