import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

class Payment extends React.Component {

  static propTypes = {
    invoice: PropTypes.object,
    order: PropTypes.object
  }

  render() {
    const { invoice, order } = this.props
    return (
      <div className="store-order-panel payment">
        <div className="store-order-panel-header">
          Payment
        </div>
        <div className="maha-table">
          <table>
            <tbody>
              { (invoice.discount > 0 || invoice.tax > 0) &&
                <>
                  <tr>
                    <td><strong>Subtotal</strong></td>
                    <td className="right">{ numeral(invoice.subtotal).format('0.00') }</td>
                  </tr>
                  { invoice.discount > 0 &&
                    <tr>
                      <td>Discount</td>
                      <td className="right">{ numeral(invoice.discount).format('0.00') }</td>
                    </tr>
                  }
                  { invoice.tax > 0 &&
                    <tr>
                      <td>Tax</td>
                      <td className="right">{ numeral(invoice.tax).format('0.00') }</td>
                    </tr>
                  }
                </>
              }
              { invoice.payments.length > 0 &&
                <tr>
                  <td><strong>Total</strong></td>
                  <td className="right">{ numeral(invoice.total).format('0.00') }</td>
                </tr>
              }
              { invoice.payments.map((payment, index) => (
                <tr key={`payment_${index}`} className={ this._getPaymentClass(payment) }>
                  <td>
                    <span>
                      { payment.activity } on { moment(payment.date).format('MM/DD/YYYY') }
                    </span> { payment.voided_date &&
                      `(voided on ${ moment(payment.voided_date).format('MM/DD/YYYY') })`
                    }
                  </td>
                  <td className="right">-{ numeral(payment.amount).format('0.00') }</td>
                </tr>
              )) }
              <tr>
                <td><strong>Balance Due</strong></td>
                <td className="right">{ numeral(invoice.balance).format('0.00') }</td>
              </tr>
              { order.payment.refunded > 0 &&
                <tr>
                  <td>Refunded</td>
                  <td className="right">{ numeral(order.payment.refunded).format('0.00') }</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  _getPaymentClass(payment) {
    const classes = []
    if(payment.voided_date) classes.push('voided')
    return classes.join(' ')
  }

}

export default Payment
