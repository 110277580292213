import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import TextField from '@admin/components/mjson_designer/components/textstylefield'
import LinkedField from '@admin/components/mjson_designer/components/linkedfield'
import ColorField from '@admin/components/mjson_designer/components/colorfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Linked = (props) => <ResponsiveField { ...props } component={ LinkedField } />

class InputStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    input: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.input) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { input } = this.state
    if(!_.isEqual(input, prevState.input)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { input } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Text)', device, name: 'text', collapsing: true, collapsed: true, type: TextField, theme, defaultColor: true, omitFeatures: ['linkColor','linkFormat'], defaultValue: {
              preset: 'text',
              color: {
                value: 'default'
              }
            }, value: input.text },
            { label: 't(Background Color)', name: 'backgroundColor', collapsing: true, collapsed: true, device, theme, type: Color, value: input.backgroundColor },
            { label: 't(Color)', name: 'color', collapsing: true, collapsed: true, type: Color, device, theme, defaultColor: true, value: input.color },
            { label: 't(Margin)', name: 'margin', collapsing: true, collapsed: true, device, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: input.margin },
            { label: 't(Padding)', name: 'padding', collapsing: true, collapsed: true, device, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: input.padding }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { input } = this.state
    this.props.onChange(input)
  }

  _handleSet(input) {
    this.setState({
      input: {
        ...input
      }
    })
  }

}

export default InputStyleField
