import dialbyextension from './dialbyextension'
import internal_email from './internal_email'
import internal_sms from './internal_sms'
import notification from './notification'
import dialbyname from './dialbyname'
import timeofday from './timeofday'
import voicemail from './voicemail'
import dialmenu from './dialmenu'
import redirect from './redirect'
import workflow from './workflow'
import property from './property'
import question from './question'
import message from './message'
import consent from './consent'
import ifthen from './ifthen'
import email from './email'
import list from './list'
import wait from './wait'
import goal from './goal'
import deal from './deal'
import task from './task'
import play from './play'
import dial from './dial'
import sms from './sms'
import set from './set'
import say from './say'

const blocks = {
  consent,
  dial,
  dialbyextension,
  dialbyname,
  dialmenu,
  deal,
  email,
  goal,
  internal_email,
  internal_sms,
  ifthen,
  list,
  message,
  notification,
  play,
  property,
  question,
  redirect,
  say,
  set,
  sms,
  task,
  timeofday,
  voicemail,
  wait,
  workflow
}

export default blocks
