import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import Poster from './poster'
import React from 'react'

class Profile extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const aesthetic_profiles = this._getAestheticProfiles()
    const { config } = this.state
    return {
      compareKey: 'id',
      defaultValue: config.aesthetic_profile_id,
      options: aesthetic_profiles,
      entity: 'aesthetic profile',
      format: (aesthetic_profile) => (
        <div className="campaign-token">
          <div className="campaign-token-preview">
            <div className="campaign-token-preview-inner">
              <img src={ aesthetic_profile.thumbnail } />
            </div>
          </div>
          <div className="campaign-token-label">
            <div className="token-link">
              { aesthetic_profile.title }
            </div>
            <div className="token-stats">
              { aesthetic_profile.tags.map(tag => tag.text).join(' • ') }
            </div>
          </div>
        </div>
      ),
      multiple: false,
      search: false,
      preview: (aesthetic_profile) => (
        <Poster aesthetic_profile={ aesthetic_profile } key={`aesthetic_profile_${aesthetic_profile.id}`} />
      ),
      showHeader: false,
      instructions: 'Select from five luxury-inspired Aesthetic Profiles that capture your brand’s unique feel. The selected aesthetic profile will be applied to your newsletter template, landing page, opt-in forms, and optional website design. Each profile can be used as is or fully customized with your brand\'s colors and fonts on the next page.',
      onDone: this._handleSuccess
    }
  }

  _getAestheticProfiles() {
    const { team } = this.context.admin
    return [
      { 
        id: 1, 
        title: 'Escape',
        description: 'ESCAPE is a modern template featuring vibrant color blocks and dynamic scrolling images. Its fresh design engages users and enhances their journey.',
        thumbnail: `${team.provider.admin_host}/images/aesthetic_profiles/escape.thumbnail.png`, 
        poster: `${team.provider.admin_host}/images/aesthetic_profiles/escape.poster.jpg`,
        tags: [
          { id: 1, text: 'modern' },
          { id: 2, text: 'dynamic' },
          { id: 3, text: 'visual' }
        ]
      },
      { 
        id: 2, 
        title: 'Nomad',
        description: 'NOMAD is an adventurous and bold template that evokes a sense of epic exploration. With its striking visuals and dynamic design, it invites users to embark on their next great journey.',
        thumbnail: `${team.provider.admin_host}/images/aesthetic_profiles/nomad.thumbnail.png`, 
        poster: `${team.provider.admin_host}/images/aesthetic_profiles/nomad.poster.jpg`,
        tags: [
          { id: 1, text: 'adventurous' },
          { id: 2, text: 'bold' },
          { id: 3, text: 'dramatic' }
        ]
      },
      { 
        id: 3, 
        title: 'Quest',
        description: 'QUEST is an editorial template that captures the timeless essence of a classic journal. Its elegant design invites users to share their stories.',
        thumbnail: `${team.provider.admin_host}/images/aesthetic_profiles/quest.thumbnail.png`, 
        poster: `${team.provider.admin_host}/images/aesthetic_profiles/quest.poster.jpg`,
        tags: [
          { id: 1, text: 'editorial' },
          { id: 2, text: 'timeless' },
          { id: 3, text: 'journal' }
        ]
      },
      { 
        id: 4, 
        title: 'Riviera', 
        description: 'RIVIERA is a classic template that captures the nostalgia of handwritten postcards. Its timeless design transports users to sun-soaked destinations.',
        thumbnail: `${team.provider.admin_host}/images/aesthetic_profiles/riviera.thumbnail.png`, 
        poster: `${team.provider.admin_host}/images/aesthetic_profiles/riviera.poster.jpg`,
        tags: [
          { id: 1, text: 'classic' },
          { id: 2, text: 'postcard' },
          { id: 3, text: 'nostalgic' }
        ]
      },
      { 
        id: 5, 
        title: 'Wander', 
        description: 'WANDER is a modern and trendy template that embodies an urban vibe. With its sleek design, bold aesthetics, and overlapping elements, it captures the essence of contemporary city life.',
        thumbnail: `${team.provider.admin_host}/images/aesthetic_profiles/wander.thumbnail.png` , 
        poster: `${team.provider.admin_host}/images/aesthetic_profiles/wander.poster.png`,
        tags: [
          { id: 1, text: 'modern' },
          { id: 2, text: 'trendy' },
          { id: 3, text: 'overlapping' }
        ]
      }
    ]
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(aesthetic_profile) {
    this.props.onNext({
      aesthetic_profile_id: aesthetic_profile.id
    })
  }

}

export default Profile
