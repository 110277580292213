export const onMove = (from, to) => ({
  type: 'MOVE',
  from,
  to
})

export const onSet = (fields) => ({
  type: 'SET',
  fields
})
