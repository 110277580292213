import { useMemo } from 'react'

export const channels = (state) => {
  return useMemo(() => {
    return state.subscriptions.reduce((subscribers, subscription) => ({
      ...subscribers,
      ...subscription.channel ? {
        [subscription.channel]: (subscribers[subscription.channel] || 0) + 1
      } : {}    
    }), {})
  }, [state.subscriptions])
}
