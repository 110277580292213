import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  upload: {
    title: 't(I Already Have a Logo)',
    text: 't(Upload a high resolution version of your existing logo)',
    charges: null
  },
  design: {
    title: 't(Design a New Logo for Me)',
    text: 't(Hire Truevail to design a new logo for you. Our support team will reach out to you with additional details and purchase options)',
    charges: '$799 one time fee'
  },
  text: {
    title: 't(Use a Text Based Logo)',
    text: 't(Use my business name in a stylized font as my logo)',
    charges: 'complimentary'
  }
}

const LogoToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong> { service_types[value].charges &&
      <>
        (<span className="alert"><T text={ service_types[value].charges } /></span>)
      </>
    }<br />
    <T text={ service_types[value].text } />
    
  </div>
)

LogoToken.propTypes = {
  value: PropTypes.string
}

export default LogoToken
