import SubscriptionProfileToken from '@apps/truevail/admin/tokens/subscription_profile'
import TimeFrameField from '@apps/truevail/admin/components/timeframefield'
import SchedulingToken from '@apps/truevail/admin/tokens/scheduling'
import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    setup: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata, profiles, props, setup } = this.props
    const { configuration } = setup
    const { config } = this.state
    const { advisor } = props
    const { type } = formdata
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: type === 'email' ? [
            { name: 'type', type: 'hidden', value: 'email' },
            { label: 't(Sender)', name: 'sender_id', type: 'dropdown', entity: 'sender', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/senders`, valueKey: 'id', textKey: 'rfc822', selectFirst: true, required: true },
            { label: 't(List)', name: 'list_id', type: 'dropdown', entity: 'list', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/lists`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true },
            { label: 't(Email Template)', name: 'email_template_id', type: 'dropdown', entity: 'template', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/templates`, valueKey: 'id', textKey: 'title', selectFirst: true, required: true },
            { label: 't(Scheduling)', name: 'scheduling', type: 'radiogroup', options: ['optout','optin'], format: SchedulingToken, required: true, defaultValue: configuration.email_scheduling || 'optout' },
            { label: 't(Channels)', name: 'channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/channels`, valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'email' } }, format: (channel) => (
              <ChannelToken channel={ channel } />
            ), defaultValue: configuration.email_channel_ids || [] }
          ] : [
            { name: 'type', type: 'hidden', value: 'social' },
            { label: 't(Profiles)', name: 'subscription_profiles', type: 'collectionfield', fields: [
              { label: 't(Profile)', name: 'profile_id', type: 'dropdown', options: profiles, valueKey: 'id', textKey: 'username', format: (profile) => (
                <ProfileToken profile={ profile } />
              ), placeholder: 't(Choose a profile)', required: true },
              { label: 't(Hashtags)', name: 'hashtags', type: 'textfield', required: true }
            ], entity: 'profile', orderable: false, cloneable: false, token: ({ value }) => (
              <SubscriptionProfileToken { ...this._getSubscriptionProfile(value) } />
            ), defaultValue: configuration.social_profiles ? configuration.social_profiles.map(social_profile => ({
              profile_id: social_profile.profile.id,
              hashtags: social_profile.hashtags
            })) : null, required: true },
            { label: 't(Scheduling)', type: 'segment', fields: [
              { name: 'scheduling', type: 'radiogroup', options: ['optout','optin'], format: SchedulingToken, defaultValue: 'optout', required: true },
              { name: 'scheduling_timeframe', type: TimeFrameField, show: config.scheduling === 'optout', required: true }
            ], required: true },
            { label: 't(Channels)', name: 'channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/channels`, valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'social' } }, format: (channel) => (
              <ChannelToken channel={ channel } />
            ), defaultValue: configuration.social_channel_ids || [] }
          ]
        }
      ]
    }
  }

  _getSubscriptionProfile(value) {
    const { profiles } = this.props
    const profile = profiles.find(profile => {
      return profile.id === value.profile_id
    })
    return {
      profile,
      hashtags: value.hashtags
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onSave(subscription)
  }

}

const mapResources = (props, context) => ({
  ...props.formdata.type === 'social' ? {
    profiles: `/api/admin/truevail/admin/advisors/${props.props.advisor.id}/profiles`
  } : {},
  setup: `/api/admin/truevail/admin/advisors/${props.props.advisor.id}/configuration`
})

export default Container(mapResources)(Details)
