import PositionField from '../positionfield'
import ChannelField from './channelfield'

const validate = ({ value, network, onValid }) => {
  const { organization } = value
  if(!organization || organization.length === 0) return onValid(null, 'Enter an organization')
  return onValid(value)
}

const unpack = (item) => item ? {
  organization: item.organization,
  job_title: item.job_title
} : null

const pack = (item) => item

const format = (item) => {
  return [
    item.organization,
    ...item.job_title ? [item.job_title] : []
  ].join(', ')
}

const compare = (item1, item2) => {
  return item1.organization === item2.organization
}

const verify = (value) => {
  const { organization } = value
  return organization && organization.length > 0
}

export default ChannelField({
  compare,
  emptyValue: {
    organization: null,
    job_title: null
  },
  entity: 'position',
  format,
  pack,
  unpack,
  validate,
  verify
}, PositionField)
