export const onAdd = (images) => ({
  type: 'ADD',
  images
})

export const onSet = (images) => ({
  type: 'SET',
  images
})

export const onRemove = (index) => ({
  type: 'REMOVE',
  index
})

export const onUpdate = (index, image) => ({
  type: 'UPDATE',
  index,
  image
})

export const oMove = (from, to) => ({
  type: 'MOVE',
  from,
  to
})
