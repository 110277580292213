import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Star extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      network: PropTypes.object,
      stars: PropTypes.object
    }),
    id: PropTypes.number,
    label: PropTypes.string,
    is_starred: PropTypes.bool,
    starText: PropTypes.string,
    table: PropTypes.string,
    unstarText: PropTypes.string
  }

  static defaultProps = {
    label: 'item'
  }

  _handleClick = this._handleClick.bind(this)
  _handleToggle = _.debounce(this._handleToggle.bind(this), 250, { leading: true, trailing: false })

  render() {
    const { starText, unstarText } = this.props
    const is_starred = this._getIsStarred()
    return (
      <div className={ this._getClass() } onClick={ this._handleClick } title={ this._getTitle() }>
        <div className="maha-star-icon">
          <Icon icon={`${this._getIcon()}`} />
        </div>
        { !is_starred && starText &&
          <span className="maha-star-text">{ starText }</span>
        }
        { is_starred && unstarText &&
          <span className="maha-star-text">{ unstarText }</span>
        }
      </div>
    )
  }

  _getClass() {
    const { starText } = this.props
    const classes = ['maha-star']
    if(starText) classes.push('labeled')
    return classes.join(' ')
  }

  _getTitle() {
    const { label } = this.props
    const is_starred = this._getIsStarred()
    const verb = is_starred ? 'Unstar' : 'Star'
    return `${verb} this ${label}`
  }

  _getIcon() {
    const is_starred = this._getIsStarred()
    return is_starred ? 'star' : 'star-o'
  }

  _handleClick(e) {
    this._handleToggle()
    e.stopPropagation()
  }

  _getIsStarred() {
    const { table, id , is_starred } = this.props
    const { stars } = this.props.context.stars
    return stars[`${table}/${id}`] ?? is_starred
  }

  _handleToggle(e) {
    const { table, id } = this.props
    const is_starred = this._getIsStarred()
    this.props.context.stars.update({
      table, 
      id, 
      is_starred: !is_starred
    })
    this.props.context.network.request({
      method: 'PATCH',
      endpoint: `/api/admin/${table}/${id}/star`,
      onFailure: (result) => {},
      onSuccess: (result) => {}
    })
  }

}

export default Star
