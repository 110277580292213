import useActionReducer from '@admin/hooks/use_action_reducer'
import CriteriaField from './criteriafield'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const CriteriaFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'criteriafield',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <CriteriaField { ...mergedProps } />

}

CriteriaFieldContainer.propTypes = {}

export default CriteriaFieldContainer