import CardToken from '@apps/finance/admin/tokens/card'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Cards)',
  collection: {
    endpoint: `/api/admin/subscriptions/services/${props.service.id}/payment_methods`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Description)', key: 'payment_method.cart_type', primary: true, format: ({ payment_method }) => (
        <CardToken payment_method={ payment_method } /> 
      ) },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name' },
      { label: 't(Status)', key: 'payment_method.status', collapsing: true, format: ({ payment_method }) => (
        <StatusToken status={ payment_method.status } />
      ) }
    ],
    empty: {
      title: 't(No Payment Methods)',
      text: 't(There are no payment methods for this service)',
      icon: 'dollar'
    },
    entity: 'payment method',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/subscriptions/services/${props.service.id}/subscriptions/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
