import PropTypes from 'prop-types'
import blocks from './index'
import React from 'react'

class Edit extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    onBack: PropTypes.func,
    onChange: PropTypes.func
  }

  render() {
    const Component = this._getComponent()
    return <Component { ...this.props } />
  }

  _getComponent() {
    const { type } = this.props.entity
    const block = _.find(blocks, { type })
    return block.form
  }

}

export default Edit
