import { DragSource, DropTarget } from 'react-dnd'
import Handle from '@admin/components/handle'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Item extends React.PureComponent {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    Component: PropTypes.any,
    entity: PropTypes.string,
    format: PropTypes.func,
    tabIndex: PropTypes.number,
    index: PropTypes.number,
    isDragging: PropTypes.bool,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onMove: PropTypes.func,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleEnter = this._handleEnter.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, Component } = this.props
    return connectDropTarget(connectDragPreview(
      <div className="channelfield-item">
        { connectDragSource(
          <div className="channelfield-item-handle">
            <Handle />
          </div>
        ) }
        <div className="channelfield-item-field">
          <Component { ...this._getElement() } />
        </div>
        <Button { ...this._getRemove() } />
      </div>
    ))
  }

  _getElement() {
    const { tabIndex, value, onBlur, onFocus } = this.props
    return {
      tabIndex,
      value,
      onBlur,
      onChange: this._handleUpdate,
      onEnter: this._handleEnter,
      onFocus
    }
  }

  _getRemove() {
    const { entity } = this.props
    return {
      className: 'channelfield-item-action',
      icon: 'trash-o',
      confirm: `Are you sure you want to remove this ${entity}?`,
      handler: this._handleRemove
    }
  }

  _handleEnter() {
    this.props.onEnter()
  }

  _handleRemove() {
    const { index } = this.props
    this.props.onRemove(index)
  }

  _handleUpdate(value) {
    const { index } = this.props
    this.props.onUpdate(index, value)
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    delta: props.delta,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index,
    delta: props.delta
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Item = DragSource('ITEM', source, sourceCollector)(Item)
Item = DropTarget('ITEM', target, targetCollector)(Item)

export default Item
