import { DropTarget } from 'react-dnd'
import PropTypes from 'prop-types'
import React from 'react'

const allowed = {
  landing_page: ['container','block'],
  embed: ['container','block'],
  container: ['container','block'],
  section: ['row'],
  row: ['column'],
  column: ['block']
}

class Empty extends React.PureComponent {

  static propTypes = {
    can_drop: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    is_over: PropTypes.bool,
    parent: PropTypes.object,
    onMove: PropTypes.func
  }

  render() {
    const { can_drop, connectDropTarget, is_over } = this.props
    return connectDropTarget(
      <div>
        { (can_drop && is_over) ?
          <div className="mjson-designer-outline-droptarget" /> :
          <div className="mjson-designer-outline-empty" />
        }
      </div>
    )
  }

}

const target = {
  canDrop: (props, monitor) => {
    const source = monitor.getItem()
    return allowed[props.parent.entity].includes(source.entity)
  },
  drop: (props, monitor, component) => {
    const source = monitor.getItem()
    const parent = props.parent
    props.onMove(source.id, parent.id, 0)
  }
}

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  is_over: monitor.isOver(),
  can_drop: monitor.canDrop()
})

Empty = DropTarget('ITEM', target, targetCollector)(Empty)

export default Empty
