import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class SignersField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    defaultValue: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  input = null

  state = {
    signers: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) {
      this.setState({
        value: defaultValue
      })
    }
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { signers } = this.state
    const { value } = this.props
    if(!_.isEqual(signers, prevState.signers)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleUpdate(value)
    }
  }

  _getAdd() {
    return {
      label: '+ t(add signer)',
      className: 'link',
      handler: this._handleNew
    }
  }

  _getList() {
    const { signers } = this.state
    return {
      actions: (item, index) => [
        {
          icon: 'times',
          confirm: 't(Are you sure you want to remove this signer?)',
          handler: this._handleRemove.bind(this, index)  
        }
      ],
      items: signers,
      empty: <Token text="t(You have not yet added any signers)" />,
      format: (signer) => <Token text={ signer.email } />,
      onMove: this._handleMove
    }
  }

  _handleAdd(signer) {
    const { signers } = this.state
    this.setState({
      signers: [
        ...signers,
        signer
      ]
    })
  }

  _handleChange(e) {
    const { signers } = this.state
    this.props.onChange(signers)
  }

  _handleMove(from, to) {
    const { signers } = this.state
    this.setState({
      signers: (from < to) ? [
        ...signers.slice(0, from),
        ...signers.slice(from + 1, to + 1),
        signers[from],
        ...signers.slice(to + 1)
      ] : [
        ...signers.slice(0, to),
        signers[from],
        ...signers.slice(to, from),
        ...signers.slice(from + 1)
      ]
    })
  }

  _handleNew() {
    const { contact } = this.props
    const props = {
      contact,
      onDone: this._handleAdd
    }
    this.context.form.push(<New { ...props } />)
  }

  _handleRemove(index) {
    const { signers } = this.state
    this.setState({
      signers: signers.filter((signer, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(signers) {
    this.setState({ signers })
  }

}

export default SignersField
