const INITIAL_STATE = {
  selected: []
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET':
    return {
      ...state,
      selected: action.selected
    }

  case 'TOGGLE':
    return {
      ...state,
      selected: [
        ...action.multiple ?
          _.xorWith(state.selected, [action.item], _.isEqual) :
          _.includes(state.selected, action.item) ? [] : [action.item]
      ]
    }

  default:
    return state
  }

}

export default reducer
