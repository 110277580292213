import Page from '@admin/components/page'
import Overview from './overview'
import Records from './records'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.dmarc_report.organization.name,
  manager: {
    path: `/admin/truevail/admin/dmarc_reports/${resources.dmarc_report.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview dmarc_report={ resources.dmarc_report } />
      },
      {
        label: 't(Records)',
        panel: <Records dmarc_report={ resources.dmarc_report } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  dmarc_report: `/api/admin/truevail/admin/dmarc_reports/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
