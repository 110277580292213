import StatusToken from '@admin/tokens/status'
import Page from '@admin/components/page'

const mapPropsToPage = (props, context) => ({
  title: `Verifications - ${props.team.title}`,
  collection: {
    endpoint: `/api/admin/truevail/admin/verifications/${props.team.id}/addresses`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Address)', key: 'address', primary: true },
      { label: 't(Error)', key: 'verification_error', wrap: false, collapsing: true},
      { label: 't(Attempts)', key: 'verification_attempts', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'verification_status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'address', order: 'asc' },
    empty: {
      icon: 'check',
      title: 't(No Email Verifications)',
      text: 't(There are no email verifications for this advisor)'
    },
    entity: 'email'
  }
})

const mapResourcesToPage = (props, context) => ({
  team: `/api/admin/truevail/admin/verifications/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
