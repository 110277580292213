import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Sound from './sound'
import React from 'react'

const sounds = [
  { value: 'ding', text: 't(Ding)' },
  { value: 'boing', text: 't(Boing)' },
  { value: 'drop', text: 't(Drop)' },
  { value: 'tada', text: 't(Tada)' },
  { value: 'plink', text: 't(Plink)' },
  { value: 'wow', text: 't(Wow)' },
  { value: 'here_you_go', text: 't(Here you go)' },
  { value: 'hi', text: 't(Hi)' },
  { value: 'knock_brush', text: 't(Knock Brush)' },
  { value: 'whoah', text: 't(Whoah!)' },
  { value: 'yoink', text: 't(Yoink)' }
]

class Sounds extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    notification_sound_enabled: true,
    notification_sound: 'ding'
  }

  _handleChange = this._handleChange.bind(this)
  _handleDemo = this._handleDemo.bind(this)
  _handleLookup = this._handleLookup.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { notification_sound_enabled } = this.state
    return (
      <div className="maha-preferences">
        <div className="maha-preference" onClick={ this._handleToggle }>
          <div className="maha-preference-icon">
            <Icon icon={ notification_sound_enabled ? 'check-square' : 'square-o' } />
          </div>
          <div className="maha-preference-label">
            Play the <Button { ...this._getLookup() } /> sound when I get a notification (<Button { ...this._getDemo() } />)
          </div>
        </div>
      </div>
    )
  }

  componentDidMount(){
    const { defaultValue } = this.props
    if(defaultValue) this.setState(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { notification_sound_enabled, notification_sound } = this.state
    if(notification_sound_enabled !== prevState.notification_sound_enabled) {
      this.props.onChange(this.state)
    }
    if(notification_sound !== prevState.notification_sound) {
      this.props.onChange(this.state)
    }
  }

  _getDemo() {
    return {
      label: 'demo',
      className: 'link',
      handler: this._handleDemo
    }
  }

  _getLookup() {
    const selected = this._getSelected()
    return {
      label: selected.text,
      className: 'link',
      handler: this._handleLookup
    }
  }

  _getSelected() {
    const { notification_sound } = this.state
    return sounds.find(sound => {
      return sound.value === notification_sound
    })
  }

  _getSound() {
    return {
      defaultValue: this.state.notification_sound,
      onDone: this._handleChange
    }
  }

  _handleLookup(e) {
    e.stopPropagation()
    this.context.form.push(Sound, this._getSound.bind(this))
  }

  _handleChange(notification_sound) {
    this.setState({
      notification_sound
    })
  }

  _handleDemo(e) {
    const { notification_sound } = this.state
    e.stopPropagation()
    const audio = new Audio(`/audio/${notification_sound}.mp3`)
    audio.play()
  }

  _handleToggle() {
    this.setState({
      notification_sound_enabled: !this.state.notification_sound_enabled
    })
  }

}

export default Sounds
