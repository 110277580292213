import Button from '@admin/components/button'
import Form from '@admin/components/form'
import RecipientToken from './recipient'
import PropTypes from 'prop-types'
import NewRecipient from './new'
import React from 'react'

class InternalSMS extends React.PureComponent {

  static propTypes = {
    config: PropTypes.object,
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config || {}
      }
    })
  }

  _getDefault() {
    return {
      strategy: 'user'
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Send Internal SMS)',
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Recipients)', name: 'recipients', type: 'collectionfield', orderable: false, cloneable: false, editable: false, entity: 'recipient', value: config.recipients, newForm: NewRecipient, token: RecipientToken, required: true },
            { label: 't(Message)', type: 'segment', fields: [
              { name: 'message', type: 'smsfield', placeholder: 'Enter a message', defaultValue: config.message, rows: 4, required: true },
              { name: 'asset_ids', type: 'attachmentfield', multiple: true, defaultValue: config.asset_ids }
            ], after: <Button { ...this._getTokens() } /> }
          ]
        }
      ]
    }
  }

  _getTokens() {
    const { onTokens } = this.props
    return {
      label: 't(you can use these tokens)',
      className: 'link',
      handler: onTokens
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default InternalSMS
