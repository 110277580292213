import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Menu extends React.Component {

  static contextTypes = {
    stack: PropTypes.object
  }

  static propTypes = {
    color: PropTypes.string,
    items: PropTypes.array,
    type: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    items: [],
    type: 'item'
  }

  state = {
    chosen: 0,
    visited: [],
    transitioning: false
  }

  render() {
    const { items, onChange } = this.props
    return (
      <div className={ this._getMenuClass() }>
        <div className="maha-menus-header">
          <div className="maha-menus-header-menu">
            <div className={ this._getClass() }>
              { items.map((item, index) => (
                <a key={`menu_${index}`} className={ this._getItemClass(item, index) } onClick={ this._handleChoose.bind(this, index) }>
                  <span><T text={ item.label } /></span>
                </a>
              )) }
            </div>
          </div>
        </div>
        { !onChange &&
          <div className="maha-menus-body">
            { items.map((item, index) => (
              <div key={`menu_body_${index}`} className={ this._getTabClass(index) }>
                <div className="maha-menus-menu-content">
                  { _.isFunction(item.component) ? React.createElement(item.component) : item.component }
                </div>
              </div>
            )) }
          </div>
        }
      </div>
    )
  }

  _getMenuClass() {
    const { color, type } = this.props
    const classes = ['maha-menus',`maha-menus-${type}`]
    if(color) classes.push(color)
    return classes.join(' ')
  }

  _getClass() {
    const { items, type } = this.props
    const numbers = ['zero','one','two','three','four','five','six','seven','eight']
    const classes = ['ui',numbers[items.length],'item']
    if(type === 'pointing') classes.push('secondary pointing')
    classes.push('menu')
    return classes.join(' ')
  }

  _getItemClass(item, index) {
    const { chosen } = this.state
    const classes = ['item']
    if(item.className) classes.push(item.className)
    if(index === chosen) classes.push('active')
    return classes.join(' ')
  }

  _getTabClass(index) {
    const { chosen, transitioning } = this.state
    const classes = ['maha-menus-menu']
    if(transitioning) classes.push('transitioning')
    if(index > chosen) classes.push('right')
    if(index < chosen) classes.push('left')
    if(index === chosen) classes.push('active')
    return classes.join(' ')
  }

  _getComponent(index) {
    const { chosen } = this.state
    return {
      active: (index === chosen)
    }
  }

  _handleChoose(index) {
    const { items, onChange } = this.props
    const item = items[index]
    if(onChange) {
      this.setState({ chosen: index })
      return onChange(item)
    }
    const visited = _.uniq([ ...this.state.visited, index ])
    this.setState({ visited, transitioning: true })
    setTimeout(() => this.setState({ chosen: index }), 20)
    setTimeout(() => this.setState({ transitioning: false }), 500)
  }

}

export default Menu
