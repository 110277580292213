import EditEmailTemplate from './email_template/edit'
import NewEmailArticle from './email_articles/new'
import EditSection from './sections/edit'
import EditColumn from './columns/edit'
import NewSection from './sections/new'
import EditBlock from './blocks/edit'
import NewColumn from './columns/new'
import NewModule from './modules/new'
import EditEmail from './email/edit'
import NewBlock from './blocks/new'
import EditRow from './rows/edit'
import NewRow from './rows/new'
import Library from './library'

const entities = {
  library: Library,
  new: {
    block: NewBlock,
    column: NewColumn,
    email_article: NewEmailArticle,
    module: NewModule,
    row: NewRow,
    section: NewSection
  },
  edit: {
    block: EditBlock,
    column: EditColumn,
    email: EditEmail,
    email_template: EditEmailTemplate,
    row: EditRow,
    section: EditSection
  }
}

export default entities
