import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'

const ImportToken = (_import) => (
  <div className="import-token">
    <div className="import-token-icon">
      <Img src={ `/images/services/${_import.service}.png` } />
    </div>
    <div className="import-token-label">
      <strong>{ _import.asset ? _import.asset.original_file_name : _import.service }</strong><br />
      { pluralize('record', _import.completed_count || _import.item_count, true) } by {_import.user.full_name}<br />
      on { moment(_import.created_at).format('MMM DD, YYYY [@] h:mma') }
    </div>
  </div>
)

ImportToken.propTypes = {
  _import: PropTypes.object
}

export default ImportToken
