import AccessField from '@apps/team/admin/components/accessfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class RolesNew extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Role)',
      method: 'POST',
      action: '/api/admin/team/roles',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Access)', name: 'rights', type: AccessField }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.router.push(`/team/roles/${result.id}`)
    this.context.modal.close()
  }

}

export default RolesNew
