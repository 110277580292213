import Dropdown from '@admin/components/form/dropdown'
import PropTypes from 'prop-types'
import LayoutToken from './token'
import React from 'react'

class LayoutField extends React.Component {

  static propTypes = {
    columns: PropTypes.number,
    defaultValue: PropTypes.array,
    entity: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    layout: null
  }

  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.layout) return null
    return (
      <div className="mjson-designer-layoutfield">
        <Dropdown { ...this._getDropdown() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { layout } = this.state
    if(!_.isEqual(layout, prevState.layout)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDropdown() {
    const layouts = this._getLayouts()
    const { layout } = this.state
    return {
      options: layouts.map(value => ({ value, text: value })),
      onChange: this._handleSet,
      format: LayoutToken,
      value: layout
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : [12]
  }

  _getLayouts() {
    const { columns } = this.props
    const layouts = [[12],[6,6],[4,8],[8,4],[3,9],[9,3],[10,2],[2,10],[4,4,4],[3,6,3],[2,8,2],[3,3,6],[6,3,3],[3,3,3,3],[2,2,2,6],[6,2,2,2],[4,2,2,4],[2,2,4,2,2],[4,2,2,2,2],[2,2,2,2,4],[2,2,2,2,2,2]]
    return columns ? layouts.filter(layout => {
      return layout.length === columns
    }) : layouts
  }

  _handleChange() {
    const { layout } = this.state
    this.props.onChange(layout)
  }

  _handleSet(layout) {
    this.setState({
      layout: layout || [12]
    })
  }

}

export default LayoutField
