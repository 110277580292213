import PurposeField from '@apps/campaigns/admin/components/purposefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_campaign } = this.props
    return {
      title: 't(Clone Email)',
      method: 'POST',
      action: `/api/admin/campaigns/email/${email_campaign.id}/clone`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: `Copy of ${email_campaign.title}` },
            { label: 't(Purpose)', name: 'purpose', type: PurposeField, channel: 'email', required: true, defaultValue: 'marketing' },
            { label: 't(Email Details)', type: 'segment', fields: [
              { label: 't(From)', name: 'sender_id', type: 'lookup', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${email_campaign.program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true, defaultValue: email_campaign.sender.id },
              { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, required: true, defaultValue: email_campaign.subject },
              { label: 't(Preview Text)', name: 'preview_text', type: 'textfield',  required: true, defaultValue: email_campaign.preview_text }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(email_campaign) {
    this.context.router.push(`/campaigns/email/${email_campaign.id}`)
    this.context.modal.close()
  }

}

export default Clone
