import Textarea from '@admin/components/html/textarea'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class TextArea extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    autogrow: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    autogrow: true,
    disabled: false,
    maxLength: null,
    placeholder: '',
    rows: 2,
    tabIndex: 0,
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    value: ''
  }

  _handleBlur = this._handleBlur.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 150)
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { disabled, maxLength } = this.props
    const { value } = this.state
    return (
      <div className={ this._getClass() }>
        { maxLength &&
          <div className={ this._getMaxClass() }>
            { value.length } / { maxLength }
          </div>
        }
        <div className="maha-input-field">
          <Textarea { ...this._getTextarea() } />
        </div>
        { value && value.length > 0 && !disabled &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled, label, maxLength } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-textarea']
    if(!label && maxLength !== null) classes.push('unlabeled')
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getMaxClass() {
    const { value } = this.state
    const { maxLength } = this.props
    const classes = ['maha-input-extra']
    if(value.length >= maxLength) classes.push('max')
    return classes.join(' ')
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _getTextarea() {
    const { autogrow, id, label, disabled, rows, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      autogrow,
      disabled,
      id,
      'aria-label': label,
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      tabIndex,
      value,
      rows,
      onBlur: this._handleBlur,
      onChange: this._handleUpdate,
      onFocus: this._handleFocus
    }
  }

  _handleBlur() {
    this.setState({
      focused: false
    })
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value.length > 0 ? value : null)
  }

  _handleClear() {
    this._handleSet('')
  }

  _handleFocus() {
    this.setState({
      focused: true
    })
  }

  _handleSet(val) {
    const value = `${val}`
    if(val === null || (this.props.maxLength && value.length > this.props.maxLength)) return
    this.setState({ value }, this._handleResize)
  }

  _handleUpdate(value) {
    this._handleSet(value)
  }

}

export default TextArea
