import Message from '@admin/components/message'
import Image from '@admin/components/image'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import specials from '../specials'
import Folder from './folder'
import Tasks from '../tasks'
import React from 'react'

class Overview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object,
    onChangeFolder: PropTypes.func,
    onCreateFile: PropTypes.func,
    onPreview: PropTypes.func,
    onShowDetails: PropTypes.func
  }

  _handleHideDetails = this._handleHideDetails.bind(this)
  _handleView = this._handleView.bind(this)

  render() {
    return (
      <div className="drive-details">
        <Details { ...this._getDetails() } />
        <Tasks { ...this._getTasks() } />
      </div>
    )
  }

  _getDetails() {
    const { item } = this.props
    const special = _.includes(['root','drive','starred','trash'], item.code)
    if(special) return this._getSpecialList()
    if(item.type === 'folder') return this._getFolderList()
    if(item.type === 'file') return this._getFileList()
  }

  _getTasks() {
    const { item, onCreateFile, onPreview } = this.props
    return {
      items: [item],
      title: false,
      onCreateFile,
      onPreview
    }
  }

  _getSpecialList() {
    const { item } = this.props
    const items = []
    items.push({ component: <Message icon={ item.icon }/> })
    if(item.description) items.push({ component: <div className="drive-details-description">{ item.description }</div> })
    return { items }
  }

  _getFolderList() {
    const { item } = this.props
    const items = []
    const folder = item.folder || specials.drive
    items.push({ component: <Message icon="folder-o" /> })
    items.push({ label: 'Created At', content: item.created_at, format: 'datetime' })
    if(item.deleted_at) {
      items.push({ label: 'Folder', content: <Folder { ...this._getFolder(specials.trash) } /> })
      if(item.folder) items.push({ label: 'Removed From', content: <Folder { ...this._getFolder(folder) } /> })
    } else {
      items.push({ label: 'Folder', content: <Folder { ...this._getFolder(folder) } /> })
    }
    return { items }
  }

  _getFileList() {
    const { item } = this.props
    const { asset } = item
    const folder = item.folder || specials.drive
    const items = []
    if(asset) {
      const is_image = asset.content_type.match(/(jpeg|jpg|gif|png)/)
      const previewSrc = is_image ? asset.path : `/assets/${asset.id}/preview.jpg`
      items.push({ component: <Image src={ previewSrc } onClick={ this._handleView } title={ asset.original_file_name } transforms={{ fit: 'cover', h: 200, w: 320 }} />})
    } else {
      items.push({ component: <Message icon="folder" />})
    }
    items.push({ label: 'Created At', content: item.created_at, format: 'datetime' })
    items.push({ label: 'Virus Scan', content: item.asset.is_infected ? 'infected' : 'uninfected' })
    if(item.deleted_at) {
      items.push({ label: 'Folder', content: <Folder { ...this._getFolder(specials.trash) } /> })
      if(item.folder) items.push({ label: 'Removed From', content: <Folder { ...this._getFolder(folder) } /> })
    } else {
      items.push({ label: 'Folder', content: <Folder { ...this._getFolder(folder) } /> })
    }
    return { items }
  }

  _getFolder(folder) {
    const { onChangeFolder } = this.props
    return {
      folder,
      onChangeFolder
    }
  }

  _handleHideDetails() {
    this.props.onShowDetails(false)
  }

  _handleView() {
    const { item } = this.props
    this.context.router.push(`/drive/files/${item.code}`)
  }

}

export default Overview
