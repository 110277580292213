import EmailTemplateToken from '@apps/truevail/admin/tokens/email_template'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Templates)',
  collection: {
    endpoint: '/api/admin/truevail/agency/email_templates',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Email Template)', key: 'title', primary: true, format: (email_template) => (
        <EmailTemplateToken email_template={ email_template } />
      ) }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Email Templates)',
      text: 't(You have not yet have any email templates)',
      buttons: [
        { label: 't(Create Email Template)', modal: New }
      ]
    },
    entity: 'email template',
    recordTasks: (record) => [
      {
        label: 't(Clone Email Template)',
        modal: <Clone email_template={ record } />
      },
      {
        label: 't(Delete Email Template)',
        confirm: 't(Are you sure you want to delete this email template?',
        request: {
          endpoint: `/api/admin/truevail/agency/email_templates/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete email template)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/email_templates/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
