import { CSSTransition } from 'react-transition-group'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const messages = {
  denied: {
    title: 't(Notifications Blocked)',
    text: 't(You\'ve disallowed push notifications. If you want to enable them in the future, you&apos;ll need to open your device settings to change that.)'
  },
  ignored: {
    title: 't(Notifications Ignored)',
    text: 't(We won\'t bother you about push notifications any more. If you want to enable them in the future, you can do so in your notification preferences.)'
  },
  unknown: {
    title: 't(Don\'t miss a message or notification!)',
    text: 't(We can send push notifications to alert you when there are actions that require your attention! We need your permission to enable push notifications.)'
  }
}

class Push extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    device: PropTypes.object,
    host: PropTypes.object,
    local_storage: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  state = {
    asking: false,
    permission: null,
    push: null,
    ready: false
  }

  _handleEnable = this._handleEnable.bind(this)
  _handleNever = this._handleNever.bind(this)

  render() {
    const { asking, permission, push, ready } = this.state
    const { user } = this.context.admin
    if(!ready) return null
    return (
      <>
        { this.props.children }
        <CSSTransition component={ null } in={ asking && user !== null } timeout={ 500 } classNames="fade-in-half" mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-push-overlay" />
        </CSSTransition>
        <CSSTransition component={ null } in={ asking && user !== null } timeout={ 500 } classNames="fade-in-full" mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-push-modal">
            <div className="maha-push-modal-image">
              <Img src="/images/notifications.jpg" />
            </div>
            <div className="maha-push-modal-body">
              { permission === 'denied' &&
                <div className="maha-push-modal-message">
                  <h3><T text={ messages.denied.title } /></h3>
                  <p><T text={ messages.denied.text } /></p>
                  <Button { ...this._getDone() } />
                </div>
              }
              { push === 'never' &&
                <div className="maha-push-modal-message">
                  <h3><T text={ messages.ignored.title } /></h3>
                  <p><T text={ messages.ignored.text } /></p>
                  <Button { ...this._getDone() } />
                </div>
              }
              { push !== 'never' && permission === 'default' &&
                <div className="maha-push-modal-message">
                  <h3><T text={ messages.unknown.title } /></h3>
                  <p><T text={ messages.unknown.text } /></p>
                  <Button { ...this._getEnable() } /><br />
                  <Button { ...this._getNextTime() } /><br />
                  <Button { ...this._getNever() } />
                </div>
              }
            </div>
          </div>
        </CSSTransition>
      </>
    )
  }

  componentDidMount() {
    this._handleInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { permission } = this.state
    if(permission !== prevState.permission) {
      if(permission === 'granted') this._handleAsk(false)
      if(permission === 'default') setTimeout(this._handleAsk.bind(this, true), 1000)
    }
  }

  _getDone() {
    return {
      label: 't(Done)',
      color: 'green',
      handler: this._handleAsk.bind(this, false)
    }
  }

  _getEnable() {
    return {
      label: 't(Enable Notifications)',
      color: 'green',
      handler: this._handleEnable
    }
  }

  _getNextTime() {
    return {
      label: 't(Ask me next time)',
      className: 'link',
      handler: this._handleAsk.bind(this, false)
    }
  }

  _getNever() {
    return {
      label: 't(Never ask again on this device)',
      className: 'link',
      handler: this._handleNever
    }
  }

  _handleAsk(asking) {
    this.setState({ asking })
  }

  async _handleGetToken() {
    const { device, host } = this.context
    const token = await host.notifications.getToken()
    if(!token) return
    device.saveToken(token)
  }

  async _handleEnable() {
    const { host } = this.context
    const permission = await host.notifications.requestPermission()
    this.setState({ permission })
    if(permission === 'denied') return
    await this._handleGetToken()
  }

  _handleInit() {
    this.context.local_storage.get({
      key: 'push',
      onSuccess: (push) => {
        this.context.host.notifications.getPermission().then(permission => {
          this.setState({
            permission: push || permission,
            ready: true
          })
          if(permission !== 'granted') return
          this._handleGetToken()
        })
      }
    })
  }

  _handleNever() {
    this.context.local_storage.set({
      key: 'push',
      value: 'never',
      onSuccess: (push) => {
        this.setState({
          push: 'never'
        })
      }
    })
  }

}

export default Push
