import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  sophisticated: 'https://truevail.com/wp-content/uploads/2021/08/sophisticated.jpg',
  artistic: 'https://truevail.com/wp-content/uploads/2021/08/artistic.jpg',
  corporate: 'https://truevail.com/wp-content/uploads/2021/08/corporate.jpg',
  feminine: 'https://truevail.com/wp-content/uploads/2021/08/feminine.jpg',
  minimal: 'https://truevail.com/wp-content/uploads/2021/08/minimal.jpg',
  web20: 'https://truevail.com/wp-content/uploads/2021/08/web20.jpg',
  childish: 'https://truevail.com/wp-content/uploads/2021/08/childish.jpg',
  fun: 'https://truevail.com/wp-content/uploads/2021/08/fun.jpg',
  hitech: 'https://truevail.com/wp-content/uploads/2021/08/hitech.jpg',
  retro: 'https://truevail.com/wp-content/uploads/2021/08/retro-1.jpg'

}

const LookAndFeelStyleToken = ({ value }) => (
  <div className="token look-and-feel-style-token">
    <img src={ service_types[value] } />
  </div>
)

LookAndFeelStyleToken.propTypes = {
  value: PropTypes.string
}

export default LookAndFeelStyleToken
