import MultiForm from '@admin/components/multiform'
import Templates from './templates'
import PropTypes from 'prop-types'
import Details from './details'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(Import Email Templates)',
      action: `/api/admin/truevail/agency/advisors/${advisor.id}/email_campaigns`,
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        advisor
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    return {
      email_template_ids: formdata.email_templates.map(email_template => {
        return email_template.id
      }),
      template_id: formdata.template_id,
      sender_id: formdata.sender_id
    }
  }

  _getSteps() {
    return [
      { label: 't(Templates)', component: Templates },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(template) {
    this.context.modal.close()
  }

}

export default Import
