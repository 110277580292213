import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const purposes = {
  transactional: {
    title: 't(Transactional)',
    text: 't(This communication is required as part of a service the contact has already purchased or registered for)'
  },
  marketing: {
    title: 't(Marketing)',
    text: 't(This communication is being sent for marketing purposes)'
  }
}

const PurposeToken = ({ value }) => (
  <div className="token purpose-token">
    <strong><T text={ purposes[value].title } /></strong><br />
    <T text={ purposes[value].text } />
  </div>
)

PurposeToken.propTypes = {
  value: PropTypes.string
}

export default PurposeToken
