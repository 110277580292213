export const onPrevious = (total) => ({
  type: 'PREVIOUS',
  total
})

export const onNext = (total) => ({
  type: 'NEXT',
  total
})

export const onGoto = (index) => ({
  type: 'GOTO',
  index
})
