import Navigation from './navigation'
import Settings from './settings'
import Assets from './assets'

const panels = {
  assets: Assets,
  navigation: Navigation,
  settings: Settings
}

export default panels
