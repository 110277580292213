import PropTypes from 'prop-types'
import React from 'react'

class SEOPreview extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    const { config } = this.props
    return (
      <div className="seo-preview">
        <div className="seo-preview-link">
          http://ccetompkins.org
        </div>
        <div className="seo-preview-title">
          { config.meta.seo_title || config.meta.title }
        </div>
        <div className="seo-preview-description">
          { config.meta.seo_description || config.meta.description }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

}

export default SEOPreview
