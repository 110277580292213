import PositionsField from '@apps/crm/admin/components/channelfield/position'
import AddressesField from '@apps/crm/admin/components/channelfield/address'
import EmailsField from '@apps/crm/admin/components/channelfield/email'
import PhonesField from '@apps/crm/admin/components/channelfield/phone'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Properties extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(First Name)', name: 'first_name', type: 'textfield' },
              { label: 't(Last Name)', name: 'last_name', type: 'textfield' }
            ] },
            { label: 't(Positions)', name: 'positions', type: PositionsField },
            { label: 't(Email Addresses)', name: 'email_addresses', type: EmailsField },
            { label: 't(Phone Numbers)', name: 'phone_numbers', type: PhonesField },
            { label: 't(Mailing Addresses)', name: 'mailing_addresses', type: AddressesField },
            { label: 't(Social)', name: 'website', type: 'urlfield', service: 'website', placeholder: 't(Enter Website URL)' },
            { name: 'linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)' },
            { name: 'instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)' },
            { name: 'facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)' },
            { name: 'threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)' },
            { name: 'youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)' },
            { name: 'x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)' },
            { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Birthday)', name: 'birthday', type: 'datefield' },
            { label: 't(Spouse)', name: 'spouse', type: 'textfield' },
            { label: 't(Prefered Language)', name: 'language_id', type: 'dropdown', endpoint: '/api/admin/crm/languages', valueKey: 'id', textKey: 'text', defaultValue: this._getPreferedLanguageId() }
          ]
        },
        ...this._getProperties()
      ]
    }
  }

  _getPreferedLanguageId() {
    const { admin } = this.context
    const crm = _.find(admin.apps, { code: 'crm' })
    return crm ? crm.settings.language_id : 12
  }

  _getProperties() {
    const { admin } = this.context
    const programs = this.props.props.fields.filter(program => {
      return program.access_type !== 'view'
    })
    if(programs.length === 0) return []
    if(admin.team.has_programs) {
      return programs.map(program => ({
        label: <ProgramToken { ...program } />,
        padded: false,
        collapsing: true,
        collapsed: true,
        fields: program.fields.map(field => ({
          type: this._getType(field),
          name: `values.${field.code}`,
          ...field.config
        }))
      }))
    }
    return [{
      label: 't(Custom Properties)',
      fields: programs[0].fields.map(field => ({
        type: this._getType(field),
        name: `values.${field.code}`,
        ...field.config
      }))
    }]
  }

  _getType(field) {
    if(field.type === 'filefield') return 'attachmentfield'
    if(field.type === 'imagefield') return 'attachmentfield'
    return field.type
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(values) {
    this.props.onNext(values)
  }

}

export default Properties
