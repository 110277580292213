import TicketFields from '@apps/events/admin/components/ticketfields'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Ticket Fields)',
  component: <TicketFields event={ props.event } />
})

export default Panel(null, mapPropsToPanel)
