import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Schedule from './schedule'
import Profiles from './profiles'
import Campaign from './campaign'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program_id } = this.props
    return {
      title: 't(Import Social Campaign)',
      action: '/api/admin/campaigns/social',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        program_id
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    const { program_id } = this.props
    return {
      program_id,
      profile_ids: formdata.profile_ids,
      truevail_social_campaign_id: formdata.social_campaign.id,
      strategy: formdata.strategy,
      date: formdata.date,
      time: formdata.time
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Campaign)', component: Campaign },
      { label: 't(Profiles)', component: Profiles },
      { label: 't(Schedule)', component: Schedule }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

export default Import
