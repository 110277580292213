import Button from '@apps/phone/admin/components/button'
import PropTypes from 'prop-types'
import Header from './header'
import React from 'react'

class Outgoing extends React.Component {

  static contextTypes = {
    twilio: PropTypes.object
  }

  static propTypes = {
    call: PropTypes.object,
    program: PropTypes.object
  }

  ringtone = null

  _handleHangup = this._handleHangup.bind(this)

  render() {
    const { call } = this.props
    return (
      <div className="maha-handset-call">
        <Header call={ call } />
        { call.program_device === 'mobile' && call.program_status !== 'in-progress' &&
          <div className="maha-handset-call-extra">
            Calling your mobile phone<br />
            { call.program_status }
          </div>
        }
        { call.contact_status &&
          <div className="maha-handset-call-extra">
            Calling contact<br />
            { call.contact_status }
          </div>
        }
        <div className="maha-handset-call-body" />
        { call.program_device !== 'mobile' &&
          <div className="maha-handset-actions">
            <div className="maha-handset-action">
              <Button { ...this._getHangup() } />
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this.ringtone = new Audio('/audio/ring.mp3')
    this.ringtone.play()
  }

  componentWillUnmount() {
    this.ringtone.pause()
    this.ringtone = null
  }

  _getHangup() {
    return { icon: 'phone', type: 'hangup', handler: this._handleHangup }
  }

  _handleHangup() {
    const { call } = this.props
    this.context.twilio.hangup(call.call.id)
  }

}

export default Outgoing
