import Avatar from '@admin/components/avatar'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Disable from '../disable'
import Enable from '../enable'
import Edit from '../edit'
import React from 'react'

const Overview = ({ user, appUserValues }, context) => {

  const details = {
    audits: `maha_users/${user.id}`,
    comments: `maha_users/${user.id}`,
    sections: [
      {
        title: 't(User Details)',
        items: [
          { label: 't(Name)', content: user.full_name },
          { label: 't(Email)', content: user.email },
          { label: 't(Notifications)', content: user.email_notifications_method },
          { label: 't(Roles)', content: user.roles.map(role => role.title).join(', ') }
        ]
      },
      ...appUserValues.filter(values => {
        return context.admin.apps.find(app => {
          return app.code !== values.app
        }) !== undefined
      }).reduce((values, app) => [
        ...values,
        ...app.sections(user, context)
      ], [])
    ]
  }

  if(!user.is_active) {
    details.alert = { color: 'red', message: 't(This user has been disabled)' }
  } else if(!user.account.activated_at) {
    details.alert = { color: 'teal', message: 't(This user hasn\'t yet activated their account)' }    
  }

  return (
    <>
      <div className="crm-contact-properties">
        <div className="crm-contact-properties-contact">
          <Avatar user={ user } width="120" presence={ false } />
          <h2>{ user.full_name }</h2>
        </div>
      </div>
      <Details { ...details } />
    </>
  )

}

Overview.contextTypes = {
  admin: PropTypes.object,
  maha: PropTypes.object
}

Overview.propTypes = {
  appUserValues: PropTypes.array,
  user: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  access: { rights: { $oneOf: ['team:manage_users'] } },
  panel: <Overview audits={ props.audits } user={ props.user } appUserValues={ context.maha.appUserValues } />,
  tasks: {
    items: [
      {
        label: 't(Edit User)',
        modal: <Edit user={ props.user } />,
        access: { rights: { $oneOf: ['team:manage_users'] } },
      }, {
        label: 't(Disable User)',
        access: { rights: { $oneOf: ['team:manage_users'] } },
        show: props.user.is_active,
        modal: <Disable user={ props.user } />
      }, {
        label: 't(Enable User)',
        access: { rights: { $oneOf: ['team:manage_users'] } },
        show: !props.user.is_active,
        modal: <Enable user={ props.user } />
      },{
        label: 't(Resend Activation Email)',
        access: { rights: { $oneOf: ['team:manage_users'] } },
        show: props.user.account.activated_at === null,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/team/users/${props.user.id}/activate`,
          onFailure: (result) => context.flash.set('error', 't(Unable to send activation email)'),
          onSuccess: (result) => context.flash.set('success', 't(Email queued for delivery)')
        }
      },
      ...context.maha.appUserTasks.reduce((tasks, appUserTasks) => [
        ...tasks,
        ...appUserTasks(props.user, context)
      ], [])
    ]
  }
})

export default Panel(null, mapPropsToPanel)
