import PropTypes from 'prop-types'
import React from 'react'

const perspectives = {
  all: 'All Perspectives',
  'first-person singular': 'First-Person Singluar',
  'first-person plural': 'First-Person Plural'
}

const languages = {
  all: 'All Languages',
  'american english': 'American English',
  'canadian english': 'Canadian English',
  'french': 'French',
  'german': 'German'
}

const VoiceToken = ({ perspective, language }) => (
  <div className="voice-token">
    { perspectives[perspective] }, { languages[language] }
  </div>
)

VoiceToken.propTypes = {
  perspective: PropTypes.string,
  language: PropTypes.string
}

export default VoiceToken
