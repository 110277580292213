export const onAddComment = (comment) => ({
  type: 'ADD_COMMENT',
  comment
})

export const onAddTyping = (typing) => ({
  type: 'ADD_TYPING',
  typing
})

export const onQuoteComment = (id) => ({
  type: 'QUOTE_COMMENT',
  id
})

export const onRemoveComment = (uid) => ({
  type: 'REMOVE_COMMENT',
  uid
})

export const onRemoveTyping = (typing) => ({
  type: 'REMOVE_TYPING'
})

export const onSetComments = (comments) => ({
  type: 'SET_COMMENTS',
  comments
})

export const onSetMode = (mode) => ({
  type: 'SET_MODE',
  mode
})

export const onUpdateDraft = (draft) => ({
  type: 'UPDATE_DRAFT',
  draft
})
