import ConversationToken from '@apps/phone/admin/tokens/conversation'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    perspective: PropTypes.string,
    records: PropTypes.array,
    selected: PropTypes.object,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose

  render() {
    const { records } = this.props
    return (
      <div className="sms-reader-list-results">
        { records.map((conversation, index) => (
          <div className={ this._getClass(conversation) } key={`record_${index}`} onClick={ this._handleChoose.bind(this, conversation) }>
            <ConversationToken { ...this._getConversation(conversation) } />
          </div>
        )) }
      </div>
    )
  }

  componentDidMount() {
    const { records } = this.props
    if(records.length === 0) return
    if(document.body.clientWidth <= 1024) return
    this._handleChoose(records[0])
  }

  _getClass(conversation) {
    const { selected } = this.props
    const classes = ['sms-reader-list-result']
    if(selected && conversation.contact_phone_number.id === selected.contact_phone_number.id && conversation.program_phone_number.id === selected.program_phone_number.id) classes.push('selected')
    return classes.join(' ')
  }

  _getConversation(conversation) {
    const { perspective } = this.props
    return {
      conversation,
      perspective
    }
  }

  _handleChoose(conversation) {
    this.props.onChoose(conversation)
  }

}

export default Results
