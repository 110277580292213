import PropTypes from 'prop-types'
import LineChart from './line'
import BarChart from './bar'
import PieChart from './pie'
import React from 'react'

class Chart extends React.Component {

  static propTypes = {
    type: PropTypes.string,
    datasets: PropTypes.array,
    options: PropTypes.object
  }

  render() {
    const Component = this._getComponent()
    return <Component { ...this.getChart() } />
  }

  getChart() {
    const { datasets, options, type } = this.props
    return {
      datasets,
      options,
      type
    }
  }

  _getComponent() {
    const { type } = this.props
    if(type === 'area') return LineChart
    if(type === 'line') return LineChart
    if(type === 'bar') return BarChart
    if(type === 'doughnut') return PieChart
    if(type === 'pie') return PieChart
  }

}

export default Chart
