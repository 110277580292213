import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import New from '@apps/campaigns/admin/views/email/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } },
  collection: {
    endpoint: '/api/admin/campaigns/email',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } compact={ true } />
      ) }
    ],
    empty: {
      icon: 'envelope-o',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any email campaigns for this form)',
      buttons: props.form.deleted_at === null ? [
        { 
          label: 't(Create Email Campaign)', 
          access: { rights: { $oneOf: ['campaigns:manage_email_campaigns'] } },
          modal: <New form={ props.form } /> 
        }
      ] : null
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Sent)', key: 'sent' },
      { label: 't(Open Rate)', key: 'open_rate' },
      { label: 't(Click Rate)', key: 'click_rate' },
      { label: 't(Bounce Rate)', key: 'bounce_rate' },
      { label: 't(Status)', key: 'status' }
    ],
    entity: 'email campaign',
    defaultQuery: { form_id: props.form.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/campaigns/email/${record.id}`)
  },
  task: props.form.deleted_at === null ? {
    icon: 'plus',
    tooltip: 't(Create Email Campaign)', 
    access: { rights: { $oneOf: ['campaigns:manage_email_campaigns'] } },
    modal: <New form={ props.form } />
  } : null
})

export default Panel(null, mapPropsToPanel)
