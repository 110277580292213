import PropTypes from 'prop-types'
import Posts from './posts'
import Post from './post'
import React from 'react'

class SocialConversations extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string
  }

  static defaultProps = {}

  state = {
    selected: null
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { selected } = this.state
    return (
      <div className="campaigns-social-conversations">
        <Posts { ...this._getPosts() } />
        <div className="campaigns-social-conversations-main">
          { selected &&
            <Post { ...this._getPost() } key={ selected.code } />          
          }
        </div>
      </div>
    )
  }

  _getPosts() {
    const { endpoint } = this.props
    const { selected } = this.state
    return {
      endpoint,
      selected,
      onChoose: this._handleChoose
    }
  }

  _getPost() {
    const { selected } = this.state
    return {
      variant: selected      
    }
  }

  _handleChoose(selected) {
    this.setState({ selected })
  }

}

export default SocialConversations
