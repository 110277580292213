import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Usage)',
  collection: {
    endpoint: '/api/admin/team/usage',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Period)', key: 'period', sort: 'start', primary: true },
      { label: 't(Emails)', key: 'emails_count', collapsing: true, align: 'right', format: 'number' },
      ...context.admin.hasRights({ allOf: ['finance:access_app'] }) ? [
        { label: 't(Minutes)', key: 'minutes_count', collapsing: true, align: 'right', format: 'number' },
        { label: 't(SMS)', key: 'smses_count', collapsing: true, align: 'right', format: 'number' },
        { label: 't(Numbers)', key: 'phone_numbers_count', collapsing: true, align: 'right', format: 'number' }
      ] : []
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Start Date)', key: 'start' },
      { label: 't(End Date)', key: 'end' },
      { label: 't(Emails)', key: 'emails_count' },
      { label: 't(Minutes)', key: 'minutes_count' },
      { label: 't(SMS)', key: 'smses_count' },
      { label: 't(Numbers)', key: 'phone_numbers_count' }

    ],
    defaultSort: { key: 'start', order: 'desc' },
    empty: {
      icon: 'line-chart',
      title: 't(No Usage)',
      text: 't(You have not yet used any resources)'
    },
    entity: 'usage',
    onClick: (record) => context.router.push(`/team/usage/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
