import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Payment from './payment'
import React from 'react'
import Plan from './plan'

class Change extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    plans: PropTypes.array,
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { service, subscription } = this.props
    return {
      title: 't(Change Subscription)',
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/change`,
      method: 'PATCH',
      formatData: this._getData,
      getSteps: this._getSteps,
      props: this._getProps(),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(subscription) {
    return subscription
  }

  _getProps() {
    const { plans, service, subscription } = this.props
    return {
      plans,
      service,
      subscription
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Plan)', component: Plan },
      { label: 'Payment', component: Payment }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(subscription) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  plans: `/api/admin/subscriptions/services/${props.service.id}/plans`
})

export default Container(mapResources)(Change)
