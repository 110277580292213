import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  administrator: 't(Administrator)',
  approver: 't(Approver)',
  contribute: 't(Contribute)',
  contribute: 't(Contribute)',
  contributor: 't(Contributor)',
  edit: 't(Edit)',
  manage: 't(Manage)',
  member: 't(Member)',
  owner: 't(Owner)',
  view: 't(View)',
  revoked: 't(Revoked)'
}

const AccessTypeToken = ({ type }) => (
  <div className={`access-type-token ${type}`}>
    <T text={ types[type] } />
  </div>
)

AccessTypeToken.propTypes = {
  type: PropTypes.string
}

export default AccessTypeToken
