export const INITIAL_STATE = {
  stars: {}
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'UPDATE':
    return {
      ...state,
      stars: {
        ...state.stars,
        [`${action.table}/${action.id}`]: action.starred
      }
    }

  default:
    return state

  }

}

export default reducer
