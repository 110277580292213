import OptionsField from '@admin/components/form/optionsfield'
import DateField from '@admin/components/form/datefield'
import Dropdown from '@admin/components/form/dropdown'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class DateRangeField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    daterange: null
  }

  render() {
    const { daterange } = this.state
    if(!daterange) return null
    return (
      <div className="maha-daterangefield">
        <div className="maha-daterangefield-type">
          <Dropdown { ...this._getType() } />
        </div>
        { daterange.type === 'custom' &&
        <div className="maha-daterangefield-dates">
          <div className="maha-daterangefield-date">
            <DateField { ...this._getStart() } />
          </div>
          <div className="maha-daterangefield-date">
            <DateField { ...this._getEnd() } />
          </div>
        </div>
        }
        <div className="maha-daterangefield-label">
          Grouped By
        </div>
        <div className="maha-daterangefield-step">
          <OptionsField { ...this._getStep() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { daterange } = this.state
    const { value } = this.props
    if(!_.isEqual(daterange, prevState.daterange)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getType() {
    const { daterange } = this.state
    return {
      options: [
        { value: '30_days', text: 't(Last 30 days)' },
        { value: '60_days', text: 't(Last 60 days)' },
        { value: '90_days', text: 't(Last 90 days)' },
        { value: 'this_week', text: 't(This Week)' },
        { value: 'last_week', text: 't(Last Week)' },
        { value: 'this_month', text: 't(This Month)' },
        { value: 'last_month', text: 't(Last Month)' },
        { value: 'this_quarter', text: 't(This Quarter)' },
        { value: 'last_quarter', text: 't(Last Quarter)' },
        { value: 'this_year', text: 't(This Year)' },
        { value: 'last_year', text: 't(Last Year)' },
        { value: 'custom', text: 't(Custom)' }
      ],
      value: daterange.type,
      onChange: this._handleUpdate.bind(this, 'type')
    }
  }

  _getStart() {
    const { daterange } = this.state
    return {
      value: daterange.start,
      onChange: this._handleUpdate.bind(this, 'start')
    }
  }

  _getStep() {
    const { daterange } = this.state
    return {
      options: ['day','week','quarter','month','year'],
      value: daterange.step,
      onChange: this._handleUpdate.bind(this, 'step')
    }
  }

  _getEnd() {
    const { daterange } = this.state
    return {
      value: daterange.end,
      onChange: this._handleUpdate.bind(this, 'end')
    }
  }

  _handleChange() {
    const { end, start, step, type } = this.state.daterange
    this.props.onChange({
      type,
      step,
      ...type === 'custom' ? { start, end } : {}
    })
  }

  _handleSet(daterange) {
    this.setState({
      daterange: {
        type: '30_days',
        step: 'day',
        start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        ...daterange ? {
          type: daterange.type,
          step: daterange.step,
          ...daterange.type === 'custom' ? {
            start: daterange.start,
            end: daterange.end
          } : {}
        } : {}
      }
    })
  }

  _handleUpdate(key, value) {
    const { daterange } = this.state
    this.setState({
      daterange: {
        ...daterange,
        [key]: value
      }
    })
  }

}

export default DateRangeField
