import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ bank }) => {

  const items = [
    { label: 't(Title)', content: bank.title },
    { label: 't(Bank Name)', content: bank.bank_name },
    { label: 't(Routing Number)', content: bank.routing_number },
    { label: 't(Account Number)', content: bank.account_number },
    { label: 'Applied On', content: bank.applied_on, format: 'date' }
  ]

  if(bank.braintree_id) {

    const braintree = {
      label: bank.braintree_id,
      className: 'link',
      link: bank.braintree_link
    }

    items.push({ label: 't(Braintree ID)', content: <Button { ...braintree} /> })
    items.push({ label: 't(Credit Card Rate)', content: bank.rate, format: 'percent' })
    items.push({ label: 't(Amex Rate)', content: bank.amex_rate , format: 'percent'})
    items.push({ label: 't(ACH Rate)', content: bank.ach_rate, format: 'percent' })
    items.push({ label: 't(ACH)', content: bank.has_ach, format: 'yes_no' })
    items.push({ label: 't(PayPal)', content: bank.has_paypal, format: 'yes_no' })

  }

  if(bank.stripe_id) {
    const stripe = {
      label: bank.stripe_id,
      className: 'link',
      link: bank.stripe_link
    }
    items.push({ label: 't(Stripe ID)', content: <Button { ...stripe} /> })
  }

  const details = {
    sections: [
      {
        title: 't(Bank Details)',
        items
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  bank: PropTypes.object
}

export default Overview
