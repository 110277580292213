import ServiceToken from '@apps/subscriptions/admin/tokens/service'
import ProgramForm from '@apps/crm/admin/components/programform'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Subscription Services)',
  access: { rights: { $oneOf: ['subscriptions:view_services','subscriptions:manage_services'] } },
  collection: {
    endpoint: '/api/admin/subscriptions/services',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (service) => (
        <ServiceToken service={ service } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Event)', fields: [
            { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/subscriptions/services/${record.id}`),
    empty: {
      icon: 'refresh',
      title: 't(No Subscription Services)',
      text: 't(You have not yet created any subscription services)',
      buttons: [
        { 
          label: 't(Create Subscription Service)',
          access: { rights: { $oneOf: ['subscriptions:manage_services'] } },
          modal: <ProgramForm form={ New } /> 
        }
      ]
    },
    entity: 'invoice'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Subscription Service)',
    access: { rights: { $oneOf: ['subscriptions:manage_services'] } },
    modal:  <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
