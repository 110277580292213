import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import FlexChildField from '@admin/components/mjson_designer/components/flexchildfield'
import React from 'react'

export const FlexChild = (props) => <ResponsiveField { ...props } component={ FlexChildField } />

export const getIsFlexChild = (parent) => {
  const display = parent?.styles?.display
  if(display === null) return
  return display?.display === 'flex' || display?.all?.display === 'flex' || display?.desktop?.display === 'flex' || display?.tablet?.display === 'flex' || display?.mobile?.display === 'flex'
}

export const FlexChildSection = (config, device, theme, website, type) => (
  {
    label: 't(Flex Child)',
    instructions: 't(Specify how this flex child element should behave within its parent)',
    collapsing: true,
    collapsed: true,
    fields: [
      {
        name: 'styles.flex_child', 
        type: FlexChild,
        defaultValue: { display: 'flex_child' },
        value: config.styles.flex_child
      }
    ]
  })
