import AccessTypeToken from '@admin/tokens/access_type'
import AssigneeToken from '@admin/tokens/assignee'
import PropTypes from 'prop-types'
import React from 'react'

const AccessToken = ({ access }) => (
  <div className="access-token">
    <div className="access-token-details">
      <AssigneeToken { ...access } />
    </div>
    <div className="access-token-type">
      <AccessTypeToken type={ access.type } />
    </div>
  </div>
)

AccessToken.propTypes = {
  access: PropTypes.object,
  type: PropTypes.string
}

export default AccessToken
