import PropTypes from 'prop-types'
import Configure from './index'
import React from 'react'

class Edit extends React.PureComponent {

  static propTypes = {
    advisor: PropTypes.object
  }

  render() {
    return <Configure { ...this._getConfigure() } />
  }

  _getConfigure() {
    const { advisor } = this.props
    return {
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/configuration`,
      title: 't(Edit Advisor)',
      advisor,
      mode: 'edit',
      requirements: ['identity','logo','brand','personalization','email','social','website','additional']
    }
  }

}

export default Edit
