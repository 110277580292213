const badges = [
  { 
    title: 't(Truevail Admin)', 
    weight: 10, 
    access: {
      team: { subdomain: { $eq: 'truevail' } }
    },
    icon: 'plane', 
    route: '/admin/truevail/admin', 
    visible: ['desktop','tablet','mobile'], 
    tooltip: 't(Truevail Admin)'
  }
]

export default badges
