import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static propTypes = {
    entity: PropTypes.string,
    fields: PropTypes.array,
    item: PropTypes.object,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      item: props.item
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity, fields } = this.props
    const { item } = this.state
    return {
      ref: this.formRef,
      title: `Edit ${_.capitalize(entity)}`,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Update)', color: 'blue', handler: this._handleSave }
      ],
      onCancel: this._handleCancel,
      onChange: this._handleUpdate,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: fields.map(field => ({
            ...field,
            value: item[field.name]
          }))
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSave() {
    this.formRef.current.submit()
  }

  _handleSubmit(item) {
    this.props.onDone(item)
  }

  _handleUpdate(item) {
    this.setState({ item })
  }

}

export default Edit
