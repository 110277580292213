import { toFilter } from '@admin/components/criteria_builder/utils'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Overview from './overview'
import React from 'react'

class Filter extends React.PureComponent {

  static propTypes = {
    code: PropTypes.string,
    defaultValue: PropTypes.object,
    entity: PropTypes.string,
    fields: PropTypes.array,
    system: PropTypes.array,
    onChange: PropTypes.func,
    onChoose: PropTypes.func,
    onClose: PropTypes.func
  }

  static defaultProps = {
    criteria: [],
    system: []
  }

  stackRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return (
      <div className="maha-filter">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  componentDidMount() {
    this._handlePush(Overview, this._getOverview())
  }

  _getOverview() {
    const { code, entity, fields, system, onClose } = this.props
    return {
      code,
      entity,
      fields,
      system,
      onChoose: this._handleChoose,
      onClose,
      onChange: this._handleChange,
      onPop: this._handlePop,
      onPush: this._handlePush
    }
  }

  _getStack() {
    return {
      display_name: 'filter',
      ref: this.stackRef
    }
  }

  _handleChange(criteria) {
    const filter = criteria ? toFilter(criteria) : {}
    this.props.onChange(filter)
  }

  _handleChoose(criteria) {
    const filter = criteria ? toFilter(criteria) : {}
    this.props.onChoose(filter)
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Filter
