import OrganizersField from '@apps/events/admin/components/organizersfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: formdata.title },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/stores', placeholder: '/path/to/event', defaultValue: formdata.permalink },
            { label: 't(Description)', name: 'description', type: 'textarea', defaultValue: formdata.description },
            { label: 't(Image)', name: 'image_id', type: 'attachmentfield', defaultValue: formdata.image_id },
            { label: 't(Organizers)', name: 'organizer_ids', type: OrganizersField, defaultValue: formdata.organizer_ids }
          ]
        }, {
          label: 't(Options)',
          fields: [
            { label: 't(Pay by Check)', type: 'segment', fields: [
              { prompt: 't(Enable Pay by Check)', name: 'payment_config.check_enabled', type: 'checkbox', defaultValue: formdata.payment_config.check_enabled },
              ...this._getCheck()
            ] },
            { label: 't(Pay Later)', type: 'segment', fields: [
              { prompt: 't(Enable Pay Later)', name: 'payment_config.async_enabled', type: 'checkbox', defaultValue: formdata.payment_config.async_enabled },
              ...this._getAsync()
            ] },
            { label: 't(Confirmation Message)', name: 'payment_config.confirmation', type: 'textarea', defaultValue: 'You will receive your tickets shortly via email' }
          ]
        }
      ]
    }
  }

  _getAsync() {
    const { formdata } = this.props
    const { config } = this.state
    return [
      { name: 'payment_config.async_text', type: 'textfield', show: _.get(config, 'payment_config.async_enabled') === true,  placeholder: 't(Enter payment text (e.g. Pay at Pickup / Pay at Register))', required: true, defaultValue: formdata.payment_config.async_text }
    ]
  }

  _getCheck() {
    const { formdata } = this.props
    const { config } = this.state
    if(_.get(config, 'payment_config.check_enabled') !== true) return []
    return [
      { name: 'payment_config.check_text', type: 'textarea', show: _.get(config, 'payment_config.check_enabled') === true, placeholder: 't(Enter instructions for sending check)', required: true, defaultValue: formdata.payment_config.check_text }
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(event) {
    this.props.onNext(event)
  }

}

export default Details
