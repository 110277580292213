import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Properties extends React.PureComponent {

  static propTypes = {
    contact1: PropTypes.object,
    contact2: PropTypes.object,
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const names = this._getNames()
    const email_addresses = this._getMerged('email_addresses')
    const phone_numbers = this._getMerged('phone_numbers')
    const mailing_addresses = this._getMerged('mailing_addresses')
    const positions = this._getMerged('positions')
    const lists = this._getItems('lists')
    const birthdays = this._getValues('birthday')
    const spouses = this._getValues('spouse')
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...names.length > 0 ? [
              { label: 't(Name)', name: 'values.name', type: 'radiogroup', options: names, defaultValue: names[0].value }
            ] : [],
            ...positions.length > 0 ? [
              { label: 'Organization / Job Title', name: 'values.position_ids', type: 'checkboxgroup', options: positions.map(position => (
                { text: [
                  ...position.value.organization ? [position.value.organization] : [],
                  ...position.value.job_title ? [position.value.job_title] : []
                ].join(', '), value: position.value.id }
              )), defaultValue: positions.map(e => e.value.id) }
            ] : [
              { name: 'values.position_ids', type: 'hidden', value: [] }
            ],
            ...email_addresses.length > 0 ? [
              { label: 't(Emails)', name: 'values.email_address_ids', type: 'checkboxgroup', options: email_addresses.map(email => (
                { text: email.value.address, value: email.value.id, extra: email.extra }
              )), defaultValue: email_addresses.map(e => e.value.id) }
            ] : [
              { name: 'values.email_address_ids', type: 'hidden', value: [] }
            ],
            ...phone_numbers.length > 0 ? [
              { label: 't(Phone Numbers)', name: 'values.phone_number_ids', type: 'checkboxgroup', options: phone_numbers.map(phone => (
                { text: phone.value.formatted, value: phone.value.id, extra: phone.extra }
              )), defaultValue: phone_numbers.map(p => p.value.id) }
            ] : [
              { name: 'values.phone_number_ids', type: 'hidden', value: [] }
            ],
            ...mailing_addresses.length > 0 ? [
              { label: 't(Mailing Addresses)', name: 'values.mailing_address_ids', type: 'checkboxgroup', options: mailing_addresses.map(address => (
                { text: address.value.address.description, value: address.value.id, extra: address.extra }
              )), defaultValue: mailing_addresses.map(a => a.value.id) }
            ] : [
              { name: 'values.mailing_address_ids', type: 'hidden', value: [] }
            ],
            ...lists.length > 0 ? [
              { label: 't(Lists)', name: 'values.list_ids', type: 'checkboxgroup', options: lists, defaultValue: lists.map(e => e.value) }
            ] : [
              { name: 'values.list_ids', type: 'hidden', value: [] }
            ],
            ...birthdays.length > 0 ? [
              { label: 't(Birthday)', name: 'values.birthday', type: 'radiogroup', options: [
                ...birthdays.map(birthday => (
                  { text: moment(birthday.text).format('YYYY-MM-DD'), value: birthday.value }
                )),
                { text: 't(No birthday)', value: 'none' }
              ], defaultValue: birthdays[0].value }
            ] : [
              { name: 'values.birthday', type: 'hidden', value: 'none' }
            ],
            ...spouses.length > 0 ? [
              { label: 't(Spouse)', name: 'values.spouse', type: 'radiogroup', options: [
                ...spouses,
                { text: 't(No spouse)', value: 'none' }
              ], defaultValue: spouses[0].value }
            ] : [
              { name: 'values.spouse', type: 'hidden', value: 'none' }
            ]
          ]
        },
        ...this._getProperties()
      ]
    }
  }

  _getProgram(id) {
    const { programs } = this.props.formdata
    return programs.find(program => {
      return program.id === id
    })
  }

  _getProperties() {
    const { contact1, contact2, formdata } = this.props
    const { fields } = formdata
    const isSet = (value) => {
      return !_.isNil(value) && (!_.isArray(value) || value.length > 0) && (!_.isString(value) || value.length > 0)
    }
    return fields.map(program => ({
      label: `${ program.title } Properties`,
      fields: program.fields.filter(field => {
        return isSet(contact1.values[field.code]) || isSet(contact2.values[field.code])
      }).map(field => {
        if(field.type === 'checkboxgroup') {
          const options = field.config.options.map(option => ({
            ...option,
            extra: [
              ..._.includes(contact1.values[field.code], option.value) ? [
                contact1.display_name
              ] : [],
              ..._.includes(contact2.values[field.code], option.value) ? [
                contact2.display_name
              ] : []
            ].join(', ')
          })).filter(option => {
            return option.extra.length > 0
          })
          return {
            label: field.config.label,
            type: 'checkboxgroup',
            options,
            defaultValue: [
              ...contact1.values[field.code],
              ...contact2.values[field.code]
            ]
          }
        }
        const options = [
          ...contact1.values[field.code] ? [
            { text: contact1.values[field.code], value: 'contact1', extra: contact1.display_name }
          ] : [],
          ...contact2.values[field.code] ? [
            { text: contact2.values[field.code], value: 'contact2', extra: contact2.display_name }
          ] : [],
          { text: 't(No Value)', value: 'none' }
        ]
        return {
          label: field.config.label,
          name: `values.${field.code}`,
          type: 'radiogroup',
          options: options,
          defaultValue: options[0].value
        }
      })
    })).filter(program => {
      return program.fields.length > 0
    })
  }

  _getItems(property) {
    const { contact1, contact2 } = this.props
    return Object.values([
      ...contact1[property],
      ...contact2[property]
    ].reduce((items, item) => ({
      ...items,
      [item.id]: {
        value: item.id,
        text: `${this._getProgram(item.program_id).title}: ${item.title}`,
        extra: [
          ...contact1[property].find(i => {
            return i.id === item.id
          }) !== undefined ? [contact1.display_name] : [],
          ...contact2[property].find(i => {
            return i.id === item.id
          }) !== undefined ? [contact2.display_name] : []
        ].join(', ')
      }
    }), {}))
  }

  _getMerged(property) {
    const { contact1, contact2 } = this.props
    return [
      ...contact1[property].map(value => ({
        value,
        extra: contact1.display_name
      })),
      ...contact2[property].map(value => ({
        value,
        extra: contact2.display_name
      }))
    ]
  }

  _getNames() {
    const { contact1, contact2 } = this.props
    return [
      { text: contact1.display_name, value: 'contact1', extra: contact1.display_name },
      { text: contact2.display_name, value: 'contact2', extra: contact2.display_name }
    ]
  }

  _getOrganizations() {
    const { contact1, contact2 } = this.props
    return [
      ...contact1.organization ? [
        { text: [contact1.organization, contact1.job_title].join(', '), value: 'contact1', extra: contact1.display_name }
      ] : [],
      ...contact2.organization ? [
        { text: [contact2.organization, contact2.job_title].join(', '), value: 'contact2', extra: contact2.display_name }
      ] : []
    ]
  }

  _getValues(property) {
    const { contact1, contact2 } = this.props
    return [
      ...contact1[property] ? [
        { text: contact1[property], value: 'contact1', extra: contact1.display_name }
      ] : [],
      ...contact2[property] ? [
        { text: contact2[property], value: 'contact2', extra: contact2.display_name }
      ] : []
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(values) {
    const { formdata } = this.props
    this.props.onSave({
      ...formdata.values,
      ...values
    })
  }

}

const mapResources = (props, context) => ({
  contact1: `/api/admin/crm/contacts/${props.formdata.contact1_id}`,
  contact2: `/api/admin/crm/contacts/${props.formdata.contact2_id}`
})

export default Container(mapResources)(Properties)
