import NumericField from '@admin/components/form/numericfield'
import VariantToken from '@apps/stores/admin/tokens/variant'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class RefundField extends React.Component {

  static propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    items: null
  }

  render() {
    if(!this.state.items) return null
    return (
      <div className="store-fulfillmentfield">
        <div className="maha-table">
          <table>
            <tbody>
              { this._getItems().map((item, index) => (
                <tr className={ this._getClass(item) } key={`item_${index}`} onClick={ this._handleToggle.bind(this, item) }>
                  <td className="collapsing">
                    <Icon icon={ this._getIcon(item.variant.id) } />
                  </td>
                  <td className="unpadded">
                    <VariantToken product={ item.variant.product } variant={ item.variant }/>
                  </td>
                  <td>
                    <NumericField { ...this._getQuantity(item) } />
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      items: this._getItems().reduce((items, item) => ({
        ...items,
        [item.variant.id]: {
          active: false,
          quantity: item.quantity
        }
      }), {})
    }, this.props.onReady)
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.state
    if(!_.isEqual(items, prevState.items)) {
      this._handleChange()
    }
  }

  _getClass(item) {
    const { items } = this.state
    const classes = []
    if(!items[item.variant.id].active) classes.push('disabled')
    return classes.join(' ')
  }

  _getIcon(variant_id) {
    const { items } = this.state
    return items[variant_id].active ? 'check-circle' : 'circle-o'
  }

  _getItems() {
    const { items } = this.props
    return Object.values(items.reduce((collected, item) => {
      const existing = collected[item.variant.id]
      return {
        ...collected,
        [item.variant.id]: {
          variant: item.variant,
          item_ids: [
            ...existing ? existing.item_ids : [],
            item.id
          ],
          quantity: existing ? existing.quantity + 1 : 1
        }
      }
    }, {})).sort((a,b) => {
      return a.variant.product.title > b.variant.product.title ? 1 : -1
    })
  }

  _getItemIds() {
    const { items } = this.state
    return this._getItems().filter(item => {
      const { active, quantity } = items[item.variant.id]
      return active && quantity > 0
    }).reduce((item_ids, item) => [
      ...item_ids,
      ...item.item_ids.slice(0, items[item.variant.id].quantity)
    ], [])
  }

  _getQuantity(item) {
    const { items } = this.state
    const { active, quantity } = items[item.variant.id]
    return {
      disabled: active,
      min: 0,
      max: item.quantity,
      of: item.quantity,
      defaultValue: quantity,
      onChange: this._handleQuantity.bind(this, item)
    }
  }

  _handleChange() {
    this.props.onChange(this._getItemIds())
  }

  _handleQuantity(item, quantity) {
    this._handleUpdate(item.variant.id, 'quantity', quantity)
  }

  _handleToggle(item) {
    const { items } = this.state
    this._handleUpdate(item.variant.id, 'active', !items[item.variant.id].active)
  }

  _handleUpdate(variant_id, key, value) {
    const { items } = this.state
    this.setState({
      items: {
        ...items,
        [variant_id]: {
          ...items[variant_id],
          [key]: value
        }
      }
    })
  }

}

export default RefundField
