import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

class SocialPreview extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    const { config } = this.props
    const image = config.meta.social_image || config.meta.image
    return (
      <div className="social-preview">
        { image &&
          <div className="social-preview-image">
            <Image { ...this._getImage(image) } />
          </div>
        }
        <div className="social-preview-body">
          <div className="social-preview-title">
            { config.meta.social_title || config.meta.title}
          </div>
          <div className="social-preview-description">
            { config.meta.social_description || config.meta.description }
          </div>
          <div className="social-preview-link">
            http://ccetompkins.org
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  _getImage(image) {
    return {
      src: image.asset.path,
      transforms: this._getTransforms(image)
    }
  }

  _getTransforms(image) {
    const { crop } = image.transforms
    const ratio = crop.w / crop.h
    return {
      ...image.transforms,
      w: 300,
      h: Math.floor(300 / ratio)
    }
  }

}

export default SocialPreview
