import PropTypes from 'prop-types'
import Chooser from './chooser'
import File from './file'
import React from 'react'

class Sources extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    _import: PropTypes.object,
    object_type: PropTypes.string,
    sources: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onFetch: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    const { sources } = this.props
    if(sources.length > 1) return <Chooser { ...this._getChooser() } />
    return <File { ...this._getSource() } />
  }

  _getSource() {
    const { _import, object_type, onBack, onCancel, onPop, onPush } = this.props
    return {
      _import,
      object_type,
      onBack,
      onCancel,
      onDone: this._handleCreate,
      onPop,
      onPush
    }
  }

  _getChooser(source) {
    const { _import, object_type, onBack, onCancel, onPop, onPush } = this.props
    return {
      _import,
      object_type,
      source,
      onBack,
      onCancel,
      onDone: this._handleCreate,
      onPop,
      onPush
    }
  }
  
  _handleBack() {
    this.props.onPop()
  }

  _handleCreate(_import) {
    const { object_type } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${object_type}/imports`,
      method: 'POST',
      body: _import,
      onFailure: () => {},
      onSuccess: ({ data }) => {
        this._handleDone(data)
      }
    })
  }

  _handleDone(_import) {
    this.props.onDone(_import)
  }

}

export default Sources
