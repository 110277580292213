import TextField from '@admin/components/mjson_designer/components/textstylefield'

export const TextSection = (config, device, theme, website, type, preset) => ({
  label: 't(Typography)',
  instructions: 't(Specify the style and properites of text in this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    {
      label: 't(Text)',
      device,
      name: 'styles.text',
      type: TextField,
      theme,
      defaultColor: true,
      defaultValue: {
        preset
      },
      value: config.styles.text
    }
  ]
})
