import AssetThumbnail from '@admin/components/asset_thumbnail'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Review extends React.Component {

  static propTypes = {
    files: PropTypes.array,
    onClose: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleClose = this._handleClose.bind(this)

  render() {
    const { files } = this.props
    return (
      <div className="maha-attachments-review">
        <div className="maha-attachments-review-header" onClick={ this._handleClose }>
          Close
        </div>
        <div className="maha-attachments-review-body">
          { files.length > 0 ?
            <div className="maha-attachments-list-items">
              { files.map((file, index) => (
                <div className="maha-attachments-list-item" key={`file_${index}`}>
                  <div className="maha-attachments-list-item-icon">
                    <AssetThumbnail { ...file } />
                    <div className="maha-attachments-list-item-service">
                      <Img src={ `/images/services/${file.service}.png` } />
                    </div>
                  </div>
                  <div className="maha-attachments-list-item-name">
                    { file.name }
                  </div>
                  <Button { ...this._getRemove(index) } />
                </div>
              )) }
            </div> :
            <Message { ...this._getEmpty() } />
          }
        </div>
      </div>
    )
  }

  _getEmpty() {
    return {
      icon: 'file-o',
      title: 't(No Files)',
      text: 't(You have not selected any files)'
    }
  }

  _getRemove(index) {
    return {
      icon: 'times',
      className: 'maha-attachments-list-item-remove',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

  _handleClose() {
    this.props.onClose()
  }

}

export default Review
