import PropTypes from 'prop-types'
import React from 'react'

const PlaceToken = (props) => {
  const { place } = props
  return (
    <>
      <strong>{ place.name }</strong><br />
      { place.address.street }, { place.address.city }, { place.address.state } { place.address.zip } 
    </>
  )
}

PlaceToken.propTypes = {
  place: PropTypes.object
}

export default PlaceToken