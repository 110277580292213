import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Contact extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  state = {
    formdata: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { service, subscription } = this.props
    return {
      title: 't(Change Communication Preferences)',
      method: 'PATCH',
      endpoint: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/edit`,
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/communication`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'To', name: 'to', type: 'recipientfield', multiple: false },
            { label: 'Cc', name: 'cc', type: 'recipientfield' },
            { label: 'Bcc', name: 'bcc', type: 'recipientfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(formdata) {
    this.setState({ formdata })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Contact
