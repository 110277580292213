import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Program from './program'
import Contact from './contact'
import React from 'react'

class Header extends React.Component {

  static propTypes = {
    call: PropTypes.object
  }

  render() {
    const { call } = this.props
    return (
      <div className="maha-handset-call-header">
        <div className="maha-handset-call-header-title">
          { call.call.direction } CALL
        </div>
        <div className="maha-handset-call-header-call">
          { this._getFrom() }
          <div className="maha-handset-call-header-link">
            <Icon icon="arrow-right" />
          </div>
          { this._getTo() }
        </div>
      </div>
    )
  }

  _getFrom() {
    const { contact, direction, from_number, program } = this.props.call.call
    if(direction === 'inbound') {
      return <Contact contact={ contact } number={ from_number } />
    }
    if(direction === 'outbound') {
      return <Program program={ program } number={ from_number } />
    }
  }

  _getTo() {
    const { contact, direction, to_number, program } = this.props.call.call
    if(direction === 'inbound') {
      return <Program program={ program } number={ to_number } />
    }
    if(direction === 'outbound') {
      return <Contact contact={ contact } number={ to_number } />
    }
  }

}

export default Header
