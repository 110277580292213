import RefundStrategyToken from '@apps/finance/admin/tokens/refund_strategy'
import AmountField from '@apps/finance/admin/components/amountfield'
import RefundField from '@apps/events/admin/components/refundfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Refund extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object,
    registration: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event, registration } = this.props
    const strategies = this._getStrategies()
    return {
      title: 't(Refund Registration)',
      action: `/api/admin/events/events/${event.id}/registrations/${registration.id}/refund`,
      method: 'PATCH',
      saveText: 'Refund',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Reason)', name: 'reason', type: 'textfield', placeholder: 't(Enter a reason for refund)' },
            { label: 't(Tickets)', name: 'ticket_ids', type: RefundField, tickets: registration.tickets, required: true },
            { label: 't(Refund Type)', name: 'type', type: 'radiogroup', deselectable: false, options: strategies, format: RefundStrategyToken, required: true, defaultValue: strategies[0] },
            { label: 't(Amount)', name: 'amount', type: AmountField, action: 'Refund', required: true, balance: this._getTotal() }
          ]
        }
      ]
    }
  }

  _getStrategies() {
    const { registration } = this.props
    const { method } = registration.payment
    const strategies = []
    if(method === 'ach') {
      strategies.push('ach')
    } else if(method === 'paypal') {
      strategies.push('paypal')
    } else if(_.includes(['card','googlepay','applepay'], method)) {
      strategies.push('card')
    }
    strategies.push('credit')
    return strategies
  }

  _getTicketsTotal() {
    const { config } = this.state
    const { registration } = this.props
    return Object.values(registration.tickets).filter(ticket => {
      return _.includes(config.ticket_ids, ticket.id)
    }).reduce((total, ticket) => {
      return total + Number(ticket.price)
    }, 0.00)
  }

  _getStatusItems(statuses) {
    const { registration } = this.props
    return Object.values(registration.tickets.filter(ticket => {
      return _.includes(statuses, ticket.status)
    }).reduce((collected, ticket) => ({
      ...collected,
      [ticket.ticket_type.id]: {
        price: ticket.price,
        quantity: collected[ticket.ticket_type.id] ? collected[ticket.ticket_type.id].quantity + 1 : 1
      }
    }), {}))
  }

  _getStatusItemsCount(statuses) {
    return this._getStatusItems(statuses).reduce((total, ticket) => {
      return total + ticket.quantity
    }, 0)
  }

  _getStatusItemsTotal(statuses) {
    return this._getStatusItems(statuses).reduce((total, ticket) => {
      return total + (ticket.quantity * ticket.price)
    }, 0)
  }

  _getTotal() {
    const { ticket_ids } = this.state.config
    return this.props.registration.tickets.filter(ticket => {
      return _.includes(ticket_ids, ticket.id)
    }).reduce((total, ticket) => {
      return total += ticket.price
    }, 0.00)
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(event) {
    this.context.modal.close()
  }

}

export default Refund
