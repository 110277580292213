import PropTypes from 'prop-types'
import React from 'react'

class Platform extends React.Component {

  static propTypes = {
    children: PropTypes.any
  }

  platformRef = React.createRef()

  state = {
    viewportHeight: null
  }

  _handleResize = this._handleResize.bind(this)

  render() {
    return (
      <div { ...this._getPlatform() }>
        { this.props.children }
      </div>
    )
  }

  componentDidMount() {
    if(!window.visualViewport) return
    window.visualViewport.addEventListener('resize', this._handleResize)
    this.platformRef.current.addEventListener('resize', this._handleResize)
    setTimeout(this._handleResize, 250)
  }

  _getClass() {
    const { viewportHeight } = this.state
    const classes = ['maha-platform']
    if(this.platformRef.current && this.platformRef.current.clientHeight - viewportHeight > 100) classes.push('software-keyboard')
    return classes.join(' ')
  }

  _getPlatform() {
    return {
      ref: this.platformRef,
      className: this._getClass()
    }
  }

  _handleResize() {
    this.setState({
      viewportHeight: window.visualViewport.height
    })
  }

}

export default Platform
