import Container from '@admin/components/container'
import Icon from '@admin/components/icon'
import AppToken from '@admin/tokens/app'
import PropTypes from 'prop-types'
import React from 'react'

class AppsField extends React.Component {

  static propTypes = {
    apps: PropTypes.array,
    app_ids: PropTypes.array,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onSetApps: PropTypes.func,
    onToggleApp: PropTypes.func
  }

  render() {
    const { apps } = this.props
    return (
      <div className="platform-appsfield">
        { apps.map((app) =>  (
          <div key={`app_${app.id}`} className="platform-appsfield-app">
            <div className="platform-appsfield-app-label">
              <AppToken { ...app } />
            </div>
            <div className="platform-appsfield-app-input">
              <Icon icon={`toggle-${ this._getIcon(app) }`} onClick={ this._handleToggleApp.bind(this, app.id) } />
            </div>
          </div>
        )) }
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { app_ids } = this.props
    if(prevProps.app_ids.length !== app_ids.length) {
      this._handleChange()
    }
  }

  _getIcon(app) {
    const { app_ids } = this.props
    return _.includes(app_ids, app.id) ? 'on' : 'off'
  }

  _handleChange() {
    const { app_ids } = this.props
    this.props.onChange(app_ids)
  }

  _handleSet(app_ids) {
    this.props.onSetApps(app_ids)
  }

  _handleToggleApp(app_id) {
    this.props.onToggleApp(app_id)
  }

}

const mapResources = (props, context) => ({
  apps: {
    endpoint: '/api/admin/platform/apps',
    query: {
      $filter: {
        id: {
          $neq: 1
        }
      }
    }
  }
})

export default Container(mapResources)(AppsField)
