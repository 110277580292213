import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const IntegrationToken = ({ integration }) => ( 
  <div className="integration-token">
    <div className="integration-token-logo">
      <Img src="/images/accumatica.png" />
    </div>
    <div className="integration-token-label">
      Accumatica
    </div>
  </div>
)

IntegrationToken.propTypes = {
  integration: PropTypes.string
}

export default IntegrationToken
