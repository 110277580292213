import VoiceToken from '@apps/truevail/admin/tokens/voice'
import PropTypes from 'prop-types'
import React from 'react'

const SocialBatch = ({ social_batch }) => (
  <div className="campaign-token">
    <div className="campaign-token-label">
      <div className="token-link">
        { social_batch.title }  
      </div>
      <div className="token-stats">
        <VoiceToken perspective={ social_batch.perspective } language={ social_batch.language } />
      </div>
    </div>
  </div>
)

SocialBatch.propTypes = {
  social_batch: PropTypes.object
}

export default SocialBatch
