import PasswordField from '@admin/components/form/password'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Password extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    account: PropTypes.object,
    token: PropTypes.string,
    onNext: PropTypes.func
  }

  state = {
    confirmation: '',
    error: false,
    message: null,
    password: '',
    status: 'pending'
  }

  _handleNext = this._handleNext.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h2><T text="t(Choose a Password)" /></h2>
            <p><T text="t(Please enter and confirm your new password)" /></p>
          </div>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            <div className="field password-field">
              <PasswordField { ...this._getPassword() } />
            </div>
            <div className="field password-field">
              <PasswordField { ...this._getConfirm() } />
            </div>
            <div className="field button-field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getContinue() {
    const { status } = this.state
    return {
      label: 'Continue',
      loading: status === 'submitting',
      color: 'blue',
      size: 'large',
      handler: this._handleSubmit
    }
  }

  _getConfirm() {
    return {
      onChange: this._handleUpdate.bind(this, 'confirmation'),
      onEnter: this._handleSubmit
    }
  }

  _getPassword() {
    return {
      onChange: this._handleUpdate.bind(this, 'password'),
      onEnter: this._handleSubmit
    }
  }

  _getToggle() {
    const { show } = this.state
    return {
      icon: show ? 'eye-slash' : 'eye',
      className: '',
      handler: this._handleToggle
    }
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _handleNext({ data }) {
    const { account } = this.props
    const { token } = data
    account.token = token
    this.props.onNext('complete', account)
  }

  _handleShake({ message }) {
    this.setState({ 
      error: true,
      message
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleSubmit() {
    const { password, confirmation } = this.state
    const { token } = this.props
    this.setState({ status: 'submitting' })
    this.context.network.request({
      endpoint: '/api/admin/reset/password',
      method: 'POST',
      body: { token, password, confirmation },
      onSuccess: this._handleNext,
      onFailure: this._handleShake
    })
  }

  _handleToggle() {
    const { show } = this.state
    this.setState({
      show: !show
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Password
