import MahaButton from '@admin/components/button/index.js'
import Button from '@apps/phone/admin/components/button'
import Keypad from '@apps/phone/admin/components/keypad'
import Timer from '@admin/components/timer'
import PropTypes from 'prop-types'
import Transfer from './transfer'
import Header from './header'
import SMS from '../sms/sms'
import React from 'react'

class Active extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object,
    twilio: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    call: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  state = {
    mode: 'functions'
  }

  _handleDigits = this._handleDigits.bind(this)
  _handleForward = this._handleForward.bind(this)
  _handleForwardCall = this._handleForwardCall.bind(this)
  _handleHangup = this._handleHangup.bind(this)
  _handleHold = this._handleHold.bind(this)
  _handleInfo = this._handleInfo.bind(this)
  _handleMute = this._handleMute.bind(this)
  _handleSMS = this._handleSMS.bind(this)
  _handleTransferCall = this._handleTransferCall.bind(this)
  _handleTransfer = this._handleTransfer.bind(this)

  render() {
    const buttons = this._getButtons()
    const rows = _.chunk(buttons, 3)
    const { call } = this.props
    const { mode } = this.state
    return (
      <div className="maha-handset-call">
        <Header call={ call } />
        <div className="maha-handset-call-timer">
          <Timer from={ call.started_at } />
        </div>
        { call.program_device === 'mobile' &&
          <div className="maha-handset-call-extra">
            This call is active on your mobile phone<br />
            { call.program_status }
          </div>
        }
        { call.extra.transferred_to ?
          <div className="maha-handset-call-extra">
            Call transferring to {call.extra.transferred_to}
          </div> :
          <>
            <div className="maha-handset-call-body">
              { mode === 'functions' &&
                <div className="maha-handset-call-functions">
                  { rows.map((row, i) => (
                    <div className="maha-handset-actions" key={`actions_${i}`}>
                      { row.map((button, j) => (
                        <div className="maha-handset-action" key={`action_${i}_${j}`}>
                          <Button { ...button } />
                        </div>
                      ))}
                    </div>
                  )) }
                  <div className="maha-handset-call-info">
                    <MahaButton { ...this._getInfo() } />
                  </div>
                </div>
              }
              { mode === 'keypad' &&
                <Keypad { ...this._getKeyPad() } />
              }
            </div>
            { mode === 'functions' &&
              <div className="maha-handset-actions">
                <div className="maha-handset-action">
                  <Button { ...this._getHangup() } />
                </div>
              </div>
            }
            { mode === 'keypad' &&
              <div className="maha-handset-actions">
                <div className="maha-handset-action">
                  <Button { ...this._getFunctions() } />
                </div>
              </div>
            }
          </>
        }
      </div>
    )
  }

  _getButtons() {
    const { account } = this.context.admin
    const { call } = this.props
    if(call.program_device === 'mobile') {
      return [
        { icon: 'random', label: 'transfer', handler: this._handleTransfer },
        { icon: 'mobile', label: 'device', handler: this._handleForward, disabled: account.mobile_phone === null },
        { icon: 'comments', label: 'sms', handler: this._handleSMS }
      ]
    }
    return [
      { icon: call.muted ? 'microphone-slash' : 'microphone', label: 'mute', handler: this._handleMute, depressed: call.muted },
      { icon: 'th', label: 'keypad', handler: this._handleMode.bind(this, 'keypad') },
      { icon: 'pause', label: 'hold', handler: this._handleHold, depressed: call.held },
      { icon: 'random', label: 'transfer', handler: this._handleTransfer },
      { icon: 'mobile', label: 'device', handler: this._handleForward, disabled: account.mobile_phone === null },
      { icon: 'comments', label: 'sms', handler: this._handleSMS }
    ]
  }

  _getFunctions() {
    return { icon: 'arrow-left', handler: this._handleMode.bind(this, 'functions') }
  }

  _getHangup() {
    return { icon: 'phone', type: 'hangup', handler: this._handleHangup }
  }

  _getInfo() {
    return {
      label: 'Lookup Contact',
      className: 'link',
      handler: this._handleInfo
    }
  }

  _getKeyPad() {
    return {
      onChoose: this._handleDigits
    }
  }

  _getSMS() {
    const { call, onPop } = this.props
    return {
      contact: call.call.contact,
      contact_phone_number: call.call.contact_phone_number,
      program: call.call.program,
      program_phone_number: call.call.program_phone_number,
      onBack: onPop
    }
  }

  _getTransfer() {
    const { onPop } = this.props
    return {
      onPop,
      onChoose: this._handleTransferCall
    }
  }

  _handleDigits(number) {
    const { call } = this.props
    call.connection.sendDigits(number)
  }

  _handleForward() {
    const { call } = this.props
    const items = []
    if(call.program_device === 'mobile') items.push({ label: 'Forward to my Maha phone', handler: this._handleForwardCall.bind(this, 'maha') })
    if(call.program_device === 'maha') items.push({ label: 'Forward to my mobile phone', handler: this._handleForwardCall.bind(this, 'mobile') })
    this.context.tasks.open({ items })
  }

  _handleForwardCall(device) {
    const { call } = this.props
    this.context.twilio.forward(call.call.id, device)
  }

  _handleHangup() {
    const { call } = this.props
    this.context.twilio.hangup(call.call.id)
  }

  _handleHold() {
    const { twilio } = this.context
    const { call } = this.props
    if(call.held) twilio.unhold(call.call.id)
    if(!call.held) twilio.hold(call.call.id)
  }

  _handleInfo() {
    const { call } = this.props
    this.context.router.push(`/crm/contacts/${call.call.contact.id}`)
  }

  _handleMode(mode) {
    this.setState({ mode })
  }

  _handleMute() {
    const { call } = this.props
    this.context.twilio.mute(call.call.id)
  }

  _handleSMS() {
    return this.props.onPush(SMS, this._getSMS.bind(this))
  }

  _handleTransfer() {
    this.props.onPush(Transfer, this._getTransfer.bind(this))
  }

  _handleTransferCall(user) {
    const { call } = this.props
    this.context.twilio.transfer(call.call.id, user)
  }

}

export default Active
