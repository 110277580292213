import PhoneField from '@admin/components/form/phonefield'
import TextField from '@admin/components/form/textfield'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Mobile extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    account: PropTypes.object,
    token: PropTypes.string,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  state = {
    code: null,
    error: false,
    method: 'sms',
    message: null,
    mobile_phone: null,
    sent: false,
    status: 'pending'
  }

  _handleMethod = this._handleMethod.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSend = this._handleSend.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleVerify = this._handleVerify.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { message, sent } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <Icon icon="mobile" />
            <h2><T text="t(Mobile Phone)" /></h2>
            <p><T text="We can protect your account with two factor authentication. Please enter your number so we can verify your phone" /></p>
          </div>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            { sent ?
              <div className="field">
                <TextField { ...this._getTextField() } />
              </div> :
              <div className="field">
                <PhoneField { ...this._getPhoneField() } />
              </div>
            }
            <div className="field button-field">
              { sent ?
                <Button { ...this._getVerify() } /> :
                <Button { ...this._getSend() } />
              }
            </div>
          </div>
          <div className="maha-signin-footer">
            { sent ?
              <><Button { ...this._getResend() } /> | <Button { ...this._getMethod() } /></> :
              <><Button { ...this._getSkip() } /> | <Button { ...this._getMethod() } /></>
            }
          </div>
        </div>
      </div>
    )
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _getMethod() {
    const { method } = this.state
    return {
      label: method === 'sms' ? 't(Call me instead)' : 't(Text me instead)',
      className: 'link',
      handler: this._handleMethod

    }
  }

  _getPhoneField() {
    const { mobile_phone } = this.state
    return {
      value: mobile_phone,
      onChange: this._handleUpdate.bind(this, 'mobile_phone')
    }
  }

  _getResend() {
    const { method } = this.state
    return {
      label: method === 'sms' ? 't(Resend code)' : 't(Call me again)',
      className: 'link',
      handler: this._handleSend
    }
  }

  _getSend() {
    const { method, status } = this.state
    return {
      label: method === 'sms' ? 't(Send SMS)' : 't(Call Me)',
      color: 'blue',
      loasding: status === 'submitting',
      handler: this._handleSend
    }
  }

  _getSkip() {
    return {
      label: 't(Skip for now)',
      className: 'link',
      handler: this._handleNext
    }
  }

  _getTextField() {
    return {
      placeholder: 't(Enter Verification Code)',
      onChange: this._handleUpdate.bind(this, 'code')
    }
  }

  _getVerify() {
    const { status } = this.state
    return {
      label: 't(Verify Code)',
      color: 'blue',
      size: 'large',
      loasding: status === 'submitting',
      handler: this._handleVerify
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleMethod() {
    const { method } = this.state
    this.setState({
      method: method === 'sms' ? 'voice' : 'sms',
      sent: false
    })
  }

  _handleNext() {
    this.props.onNext()
  }

  _handleSend() {
    const { method, mobile_phone } = this.state
    const { token } = this.props
    this.setState({ status: 'submitting' })
    this.context.network.request({
      endpoint: '/api/admin/activate/mobile/authorize',
      method: 'POST',
      body: { token, mobile_phone, method },
      onSuccess: () => {
        this.setState({
          method,
          status: 'pending',
          sent: true 
        })
      },
      onFailure: this._handleShake
    })
  }

  _handleShake({ errors }) {
    const message = errors.mobile_phone[0]
    this.setState({ 
      error: true,
      message
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleVerify() {
    const { code } = this.state
    const { token } = this.props
    this.setState({ sent: true, status: 'submitting' })
    this.context.network.request({
      endpoint: '/api/admin/activate/mobile/verify',
      method: 'POST',
      body: { token, code },
      onSuccess: this._handleNext,
      onFailure: this._handleShake
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Mobile
