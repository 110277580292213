import { extractTokens } from './utils'
import PropTypes from 'prop-types'
import hash from 'object-hash'
import React from 'react'

export const LocaleContext = React.createContext(null)
LocaleContext.displayName = 'LocaleContext'

export const useLocaleContext = () => React.useContext(LocaleContext)

class Locale extends React.Component {

  static childContextTypes = {
    locale: PropTypes.object
  }

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    lang: PropTypes.string
  }

  state = {
    locale: null
  }

  _handleSet = this._handleSet.bind(this)
  _handleTranslate = this._handleTranslate.bind(this)

  render() {
    return (
      <LocaleContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </LocaleContext.Provider>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { lang } = this.state
    if(lang !== prevState.lang) {
      this._handleReload()
    }
  }

  getChildContext() {
    const { lang } = this.state
    return {
      locale: {
        lang,
        set: this._handleSet,
        t: this._handleTranslate
      }
    }
  }

  _handleReload() {
    const { lang } = this.state
    this.context.network.request({
      endpoint: `/api/admin/lang/${lang}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          locale: data
        })
      }
    })
  }

  _handleSet(lang) {
    this.setState({ lang })
  }

  _handleTranslate(text) {
    if(!text) return ''
    if(_.isString(text)) return this._handleTranslateText(text)
  }

  _handleTranslateText(text) {
    const { locale } = this.state
    const tokens = extractTokens(text)
    if(tokens.length === 0) return text
    return tokens.reduce((output, token) => {
      const [,key] = token.match(/^t\((.*)\)$/)
      if(!locale) return output.replace(token, key)
      const hashkey = hash(key.replace(/[^\w]*/g, ''))
      const translated = _.get(locale, hashkey) || key
      return output.replace(token, translated)
    }, text)
  }

}

export default Locale