import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class PasswordField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    placeholder: 't(Enter password)',
    tabIndex: 0,
    onChange: () => {},
    onEnter: () => {},
    onKeyPress: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    show: false,
    strong: false,
    value: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyPressed = this._handleKeyPressed.bind(this)
  _handleToggle = this._handleToggle.bind(this)
  _handleUpdated = this._handleUpdated.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
        </div>
        <div className="maha-input-icon">
          <Button { ...this._getToggle()} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-password']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, id, label, tabIndex } = this.props
    const { focused, show, value } = this.state
    return {
      'aria-label': label,
      autoComplete: 'off',
      autoCapitalize: 'off',
      autoCorrect: 'off',
      disabled,
      id,
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      spellCheck: 'false',
      tabIndex,
      type: show ? 'textfield' : 'password',
      value,
      onBlur: this._handleBlured,
      onChange: this._handleUpdated,
      onFocus: this._handleFocused,
      onKeyPress: this._handleKeyPressed
    }
  }

  _getPlaceholder() {
    const { entity, label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    if(entity && entity.length > 0) return t(`t(Enter) ${entity}`)
    if(label && label.length > 0) return t(`t(Enter) ${label}`)
    return ''
  }

  _getToggle() {
    const { show } = this.state
    return {
      icon: show ? 'eye-slash' : 'eye',
      className: '',
      handler: this._handleToggle
    }
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      value: ''
    })
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyPressed(e) {
    this.props.onKeyPress(e)
    if(e.key !== 'Enter') return
    this.props.onEnter()
  }

  _handleSet(value) {
    this.setState({ value })
  }

  _handleToggle() {
    const { show } = this.state
    this.setState({
      show: !show
    })
  }

  _handleUpdated(value) {
    this.setState({ value })
  }

  _handleValidate() {
    const { required } = this.props
    const { value } = this.state
    if(required === true && value === '') {
      return this.props.onValid(value, ['This field is required'])
    }
    this.props.onValid(value)
  }

}

export default PasswordField
