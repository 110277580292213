import Stack from '@admin/components/stack'
import * as flat from '@core/utils/flat'
import PropTypes from 'prop-types'
import React from 'react'
import Main from './main'

export const FormContext = React.createContext()
FormContext.displayName = 'FormContext'

export const useFormContext = () => React.useContext(FormContext)

class Form extends React.Component {

  static childContextTypes = {
    form: PropTypes.object
  }

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      network: PropTypes.object
    }),
    action: PropTypes.string,
    after: PropTypes.string,
    before: PropTypes.any,
    busy: PropTypes.array,
    buttons: PropTypes.array,
    defaults: PropTypes.object,
    cancel: PropTypes.object,
    cancelIcon: PropTypes.string,
    cancelText: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.object,
    errors: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.any,
    fields: PropTypes.any,
    fieldNames: PropTypes.any,
    filtered: PropTypes.object,
    format: PropTypes.func,
    highlights: PropTypes.array,
    inline: PropTypes.bool,
    instructions: PropTypes.any,
    isConfiguring: PropTypes.bool,
    isReady: PropTypes.bool,
    isBusy: PropTypes.bool,
    isValid: PropTypes.bool,
    message: PropTypes.string,
    method: PropTypes.string,
    ready: PropTypes.array,
    reference: PropTypes.func,
    saveIcon: PropTypes.string,
    saveText: PropTypes.string,
    showHeader: PropTypes.bool,
    sections: PropTypes.array,
    status: PropTypes.string,
    tabs: PropTypes.array,
    title: PropTypes.string,
    validated: PropTypes.array,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onChangeField: PropTypes.func,
    onFailure: PropTypes.func,
    onRemoveField: PropTypes.func,
    onSetBusy: PropTypes.func,
    onSetData: PropTypes.func,
    onSetReady: PropTypes.func,
    onSetStatus: PropTypes.func,
    onSetValid: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    onUpdateData: PropTypes.func,
    onValidate: PropTypes.func
  }

  static defaultProps = {
    inline: false,
    method: 'GET',
    cancelText: 't(Close)',
    color: 'darkblue',
    reference: () => {},
    saveText: 't(Save)',
    showHeader: true,
    onCancel: () => {},
    onChange: () => {},
    onChangeField: () => {},
    onSubmit: () => {},
    onFailure: () => {},
    onSuccess: () => {}
  }

  stackRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleValidate = _.debounce(this._handleValidate.bind(this), 2500, { leading: true })

  render() {
    const { inline } = this.props
    return (
      <FormContext.Provider value={ this.getChildContext() }>
        <div className={ this._getClass() }>
          { inline ?
            <Main { ...this._getMain() } /> :
            <Stack { ...this._getStack() } />
          }
        </div>
      </FormContext.Provider>
    )
  }

  componentDidMount() {
    const { reference } = this.props
    this._handleLoadData()
    if(reference) reference({
      submit: this._handleValidate
    })
  }

  componentDidUpdate(prevProps) {
    const { filtered, fields, fieldNames, isValid } = this.props
    if(isValid !== prevProps.isValid && isValid) {
      this._handleSubmit()
    }
    if(!_.isEqual(filtered, prevProps.filtered)) {
      this._handleChange(prevProps.filtered, filtered)
    }
    if(!_.isEqual(fieldNames, prevProps.fieldNames)) {
      this._handleNewFields(prevProps.fields, fields)
    }
  }

  getChildContext() {
    return {
      form: {
        push: this._handlePush,
        pop: this._handlePop
      }
    }
  }

  _getClass() {
    const { inline  } = this.props
    const classes = ['maha-form']
    if(inline) classes.push('inline')
    return classes.join(' ')
  }

  _getMain() {
    return {
      ...this.props,
      onValidate: this._handleValidate
    }
  }

  _getStack() {
    return {
      display_name: 'form',
      initial: [
        { component: Main, props: this._getMain.bind(this) }
      ],
      ref: this.stackRef
    }
  }

  _handleChange(previous, current) {
    const { onChangeField } = this.props
    this.props.onChange(current, previous)
    Object.keys(current).map(code => {
      if(!_.isEqual(previous[code], current[code])) onChangeField(code, current[code], previous[code])
    })
  }

  _handleFailure(errors, message) {
    this.props.onSetStatus('failure')
    this.props.onFailure()
  }

  _handleFetch() {
    const { defaults, endpoint } = this.props
    this.props.onSetStatus('loading')
    this.props.context.network.request({
      endpoint,
      method: 'GET',
      onSuccess: ({ data }) => {
        this._handleSetData(flat.unflatten(flat.flatten({
          ...defaults,
          ...data
        })))
      },
      onFailure: ({ errors, message }) => {
        this._handleFailure(errors, message)
      }
    })
  }

  _handleLoadData() {
    const { defaults, endpoint } = this.props
    if(endpoint) return this._handleFetch()
    this.props.onSetData(defaults)
  }

  _handleNewFields(previous, current) {
    const { defaults, filtered, onUpdateData } = this.props
    current.map(field => {
      const found = _.find(previous, { name: field.name })
      if(found || !field.name) return
      const value = _.get(filtered, field.name)
      if(value === undefined) {
        return onUpdateData(field.name, defaults[field.name])
      }
    })
    previous.map(field => {
      const found = _.find(current, { name: field.name })
      if(!found) this.props.onRemoveField(field.name)
    })
  }

  _handlePop(index = -1) {
    const { form } = this.context
    if(form) return form.pop(index)
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    const { form } = this.context
    if(form) return form.push(component, props)
    this.stackRef.current.push({ component, props })
  }

  _handleSave() {
    const { action, filtered, format, method } = this.props
    this.props.onSetStatus('submitting')
    const data = format ? format(filtered) : filtered
    this.props.context.network.request({
      endpoint: action,      
      method,
      ...method === 'GET' ? {
        query: data
      } : {
        body: data
      },
      onSuccess: ({ data }) => {
        this._handleSuccess(data)
      },
      onFailure: ({ erros, message }) => {
        this._handleFailure(erros, message)
      }
    })
  }

  _handleSetData(data) {
    this.props.onSetData(data)
  }

  _handleSubmit() {
    const { action, filtered, onSubmit } = this.props
    if(action) return this._handleSave()
    if(onSubmit) {
      const result = this.props.onSubmit(filtered)
      if(result !== false) return this._handleSuccess(result || filtered)
      return this._handleFailure()
    }
    return this._handleSuccess(filtered)
  }

  _handleSuccess(result) {
    this.props.onSuccess(result)
  }

  _handleValidate() {
    const { isBusy } = this.props
    if(isBusy) return
    this.props.onValidate()
  }

}

export default Form
