import PropTypes from 'prop-types'
import React from 'react'

export const ReactionsContext = React.createContext(null)
ReactionsContext.displayName = 'ReactionsContext'

export const useReactionsContext = () => React.useContext(ReactionsContext)

class Reactions extends React.Component {

  static childContextTypes = {
    reactions: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    reactions: PropTypes.object,
    onUpdate: PropTypes.func
  }

  _handleReact = this._handleReact.bind(this)
  _handleUnreact = this._handleUnreact.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <ReactionsContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </ReactionsContext.Provider>
    )
  }

  componentDidMount() {
    this._handleJoin()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  getChildContext() {
    const { reactions } = this.props
    return {
      reactions: {
        reactions,
        react: this._handleReact,
        unreact: this._handleUnreact
      }
    }
  }

  _handleJoin() {
    const { admin } = this.context
    const { team } = admin
    this.context.network.subscribe({ 
      channel: `/teams/${team.id}`, 
      action: 'update_reactions', 
      handler: this._handleUpdate 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}`, 
      action: 'update_reactions', 
      handler: this._handleUpdate 
    })
  }

  _handleReact({ table, id, reactions }) {
    this.props.onUpdate(table, id, reactions)
  }
  
  _handleUnreact({ table, id, reactions }) {
    this.props.onUpdate(table, id, reactions)
  }

  _handleUpdate({ table, id, reactions }) {
    this.props.onUpdate(table, id, reactions)
  }

}

export default Reactions
