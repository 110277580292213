import { CSSTransition } from 'react-transition-group'
import ModalPanel from '@admin/components/modal_panel'
import Collection from '@admin/components/collection'
import Message from '@admin/components/message'
import Manager from '@admin/components/manager'
import Buttons from '@admin/components/buttons'
import { canAccess } from '@core/utils/access'
import Error from '@admin/components/error'
import Tabs from '@admin/components/tabs'
import PropTypes from 'prop-types'
import Panel from './panel'
import React from 'react'

export const PageContext = React.createContext()
PageContext.displayName = 'PageContext'

export const usePageContext = () => React.useContext(PageContext)

class Page extends React.Component {

  static childContextTypes = {
    page: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object,
    host: PropTypes.object,
    locale: PropTypes.object,
    logger: PropTypes.object,
    maha: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.object,
    active: PropTypes.bool,
    buttons: PropTypes.array,
    cacheKey: PropTypes.string,
    children: PropTypes.any,
    component: PropTypes.any,
    collection: PropTypes.object,
    color: PropTypes.string,
    data: PropTypes.object,
    manager: PropTypes.object,
    message: PropTypes.object,
    panel: PropTypes.object,
    params: PropTypes.object,
    pathname: PropTypes.string,
    tabs: PropTypes.object,
    task: PropTypes.object,
    tasks: PropTypes.object,
    title: PropTypes.string
  }

  static defaultProps = {
    color: 'darkblue'
  }

  state = {
    access: null,
    title: ''
  }

  _handleBack = this._handleBack.bind(this)
  _handleSetTitle = this._handleSetTitle.bind(this)
  _handleTask = this._handleTask.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    const { access } = this.state
    const { buttons, collection, manager, message, panel } = this.props
    const Component = this.props.component
    if(access === null) return null
    const tabs = this._getTabs()
    return (
      <PageContext.Provider value={ this.getChildContext() }>
        { access ?
          <ModalPanel { ...this._getPanel() }>
            <Error>
              <div className="maha-page">
                <div className="maha-page-body">
                  { Component &&
                    <>
                      { _.isFunction(Component) ? <Component { ...this._getComponent() } /> : Component }
                    </>
                  }
                  { collection && <Collection { ...this._getCollection() } /> }
                  { manager && <Manager { ...this._getManager() } /> }
                  { message && <Message { ...message } /> }
                  { tabs && <Tabs { ...tabs } /> }
                  { panel && <Panel { ...panel } /> }
                  { this.props.children }
                </div>
                <CSSTransition in={ !_.isNil(buttons) } classNames="expanded" timeout={ 150 } mountOnEnter={ true } unmountOnExit={ true }>
                  <div className="maha-page-footer">
                    <Buttons buttons={ buttons } />
                  </div>
                </CSSTransition>
              </div>
            </Error>
          </ModalPanel> :
          <ModalPanel { ...this._getForbiddenPanel() }>
            <Message { ...this._getForbidden() } />
          </ModalPanel>
        }
      </PageContext.Provider>
    )
  }

  componentDidMount() {
    const { title } = this.props
    this._handleCheckAccess()
    this._handleSetTitle(title)
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.props
    const { title } = this.state
    if(active !== prevProps.active && active) {
      this._handleSetTitle(title)
    }
    if(title !== prevState.title) {
      this._handleUpdateTitle()
    }
  }

  componentWillUnmount() {
    this._handleSetTitle(null)
  }

  getChildContext() {
    return {
      page: {
        setTitle: this._handleSetTitle
      }
    }
  }

  _getAccess() {
    const { admin } = this.context
    const { access } = this.props
    if(access) return canAccess(access, admin)
    return true
  }

  _getComponent() {
    const { data } = this.props
    return {
      ...this.props,
      page: this._getPage(),
      ...data
    }
  }

  _getCollection() {
    const { token } = this.context.admin
    const { cacheKey, collection } = this.props
    return {
      cacheKey,
      token,
      ...collection
    }
  }

  _getForbiddenPanel() {
    return {
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      title: 't(Forbidden)'
    }
  }

  _getForbidden() {
    return {
      icon: 'exclamation-triangle',
      title: 't(Forbidden)',
      text: 't(You do not have permission to access this content)'
    }
  }

  _getManager() {
    const { cacheKey, manager, title } = this.props
    const page = this._getPage()
    return {
      ...manager,
      key: cacheKey,
      title,
      page,
      view: page.params.view
    }
  }

  _getPage() {
    const { params, pathname } = this.props
    const getFormatted = (value) => {
      if(!value) return null
      if(value.match(/^\d+$/)) return parseInt(value)
      return value
    }
    return {
      pathname,
      params: Object.keys(params).reduce((formatted, key) => ({
        ...formatted,
        [key]: getFormatted(params[key])
      }), {})
    }
  }

  _getPanel() {
    const { color, manager, title } = this.props
    if(!title || manager) return { showHeader: false }
    const tasks = this._getTasks()
    const task = this._getTask()
    const panel = {
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      color,
      title
    }
    if(tasks && tasks.items) {
      panel.rightItems = [
        { icon: tasks.icon || 'ellipsis-v', handler: this._handleTasks }
      ]
    }
    if(task) {
      panel.rightItems = [
        { icon: task.icon || 'plus', handler: this._handleTask }
      ]
    }
    return panel
  }

  _getTabs() {
    const { tabs } = this.props
    return tabs && tabs.items ? {
      ...tabs,
      items: tabs.items.filter(tab => {
        return tab.show !== false
      })
    } : null
  }

  _getTask() {
    const { admin } = this.context
    const { task } = this.props
    if(!task) return null
    return canAccess(task.access, admin) ? task : null
  }

  _getTasks() {
    const { admin } = this.context
    const { tasks } = this.props
    if(!tasks) return null
    const items = tasks.items.filter(item => {
      return canAccess(item.access, admin)
    })
    return items.length > 0 ? tasks : null
  }

  _getTitle() {
    const { title } = this.state
    if(!title) return null
    return this.context.locale.t(title)
  }

  _handleBack() {
    this.context.router.goBack()
  }

  _handleCheckAccess() {
    const access = this._getAccess()
    this.setState({ access })
  }

  _handleSetTitle(title) {
    this.setState({ title })
  }

  _handleTasks() {
    const { tasks } = this.props
    this.context.tasks.open({
      items: tasks.items
    })
  }

  _handleTask() {
    const { task } = this.props
    const { router, modal, tasks } = this.context
    if(task.route) {
      router.push(task.route)
    } else if(task.modal) {
      modal.open(task.modal)
    } else if(task.handler) {
      task.handler()
    }
    tasks.close()
  }

  _handleUpdateTitle() {
    const title = this._getTitle()
    this.context.host.device.setTitle(title)
  }

}

export default Page
