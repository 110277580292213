import ModalPanel from '@admin/components/modal_panel'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

class ControlPanel extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    color: PropTypes.string,
    sections: PropTypes.array,
    title: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)

  render() {
    const { sections } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className={ this._getClass() }>
          <div className="maha-control-panel-header">
            <Icon icon="id-card" />
          </div>
          <div className="maha-control-panel-body">
            { sections.map((section, i) => (
              <Fragment key={`section_${i}`}>
                { section.title &&
                  <div className="maha-control-panel-section">
                    <T text={ section.title } />
                  </div>
                }
                { section.items.map((item, j) => (
                  <div className="maha-control-panel-item" key={`item_${i}_${j}`} onClick={ this._handleChoose.bind(this, item) }>
                    <div className="maha-control-panel-item-label">
                      <strong><T text={ item.title } /></strong><br />
                      <T text={ item.text } />
                    </div>
                    <div className="maha-control-panel-item-proceed">
                      <Icon icon="chevron-right" />
                    </div>
                  </div>
                )) }
              </Fragment>
            )) }
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getClass() {
    const { color } = this.props
    const classes = ['maha-control-panel']
    classes.push(color)
    return classes.join(' ')
  }

  _getPanel() {
    const { title } = this.props
    return {
      title,
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChoose(item) {
    this.context.modal.push(<item.component />)
  }

}

export default ControlPanel
