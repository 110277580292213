import Stack from '@admin/components/stack'
import Attachment from './attachment'
import PropTypes from 'prop-types'
import Mappings from './mappings'
import Preview from './preview'
import React from 'react'

class File extends React.PureComponent {

  static propTypes = {
    _import: PropTypes.object,
    object_type: PropTypes.string,
    source: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  stackRef = React.createRef()

  _handleDone = this._handleDone.bind(this)
  _handleMappings = this._handleMappings.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePreview = this._handlePreview.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return (
      <div className="import">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  componentDidMount() {
    const { onBack, onCancel } = this.props
    this._handlePush(Attachment, {
      onBack,
      onCancel,
      onDone: this._handlePreview
    })
  }

  _getStack() {
    return {
      display_name: 'import_source_file',
      ref: this.stackRef
    }
  }

  _handleDone(_import) {
    this.props.onDone(_import)
  }

  _handleMappings(_import, parsed) {
    const { object_type } = this.props
    this._handlePush(Mappings, {
      _import,
      object_type,
      parsed,
      onPop: this._handlePop,
      onPush: this._handlePush,
      onBack: this._handlePop,
      onDone: this._handleDone
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePreview(asset) {
    const { _import, object_type } = this.props
    this._handlePush(Preview, {
      _import,
      asset,
      object_type,
      onBack: this._handlePop,
      onDone: this._handleMappings
    })
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default File
