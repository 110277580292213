import MJSONPreview from '@admin/components/mjson_preview'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ notice }) => {

  const details = {
    audits: `maha_provider_notices/${notice.id}`,
    comments: `maha_provider_notices/${notice.id}`,
    header:  <MJSONPreview entity={ notice } table="maha_provider_notices" />,
    sections: [
      {
        title: 't(Notice Details)',
        items: [
          { label: 't(Title)', content: notice.notice.title },
          { label: 't(Description)', content: notice.notice.description },
          { label: 't(Subject)', content: notice.subject },
          ...notice.meta.cc ? [
            { label: 't(CC)', content: notice.meta.cc.split(',').map((address, index) => (
              <div key={`address_${index}`}>{ address }</div>
            )) }
          ] : [],
          ...notice.meta.bcc ? [
            { label: 't(BCC)', content: notice.meta.bcc.split(',').map((address, index) => (
              <div key={`address_${index}`}>{ address }</div>
            )) }
          ] : []
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  notice: PropTypes.notice
}

const mapResourcesToPanel = (props, context) => ({
  notice: `/api/admin/truevail/admin/notices/${props.notice_id}`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview notice={ props.notice } />,
  tasks: {
    items: [
      { label: 't(Edit Notice)', modal: <Edit notice={ props.notice } /> }
    ]
  }
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
