import PropTypes from 'prop-types'
import Children from './children'
import React  from 'react'

class Finder extends React.PureComponent {

  static propTypes = {
    items: PropTypes.array,
    onMove: PropTypes.func
  }

  state = {
    collapsed: [],
    hover: null,
    selected: null
  }

  _handleCollapse = this._handleCollapse.bind(this)
  _handleHover = _.throttle(this._handleHover.bind(this), 50)
  _handleSelect = this._handleSelect.bind(this)

  render() {
    return <Children { ...this._getChildren() } />
  }

  componentWillUnmount() {
    if(!this._handleHover.cancel) return
    this._handleHover.cancel()
  }

  _getChildren() {
    const { collapsed, hover, selected} = this.state
    const { items, onMove  } = this.props
    return {
      children: items,
      collapsed,
      hover,
      padding: -1,
      selected,
      onCollapse: this._handleCollapse,
      onHover: this._handleHover,
      onMove,
      onSelect: this._handleSelect
    }
  }

  _getResolved(index) {
    const { items } = this.props
    const resolved = index.replace(/\./g,'.children.')
    return _.get(items, resolved)
  }

  _handleCollapse(id) {
    const { collapsed } = this.state
    this.setState({
      collapsed: _.xor(collapsed, [id])
    })
  }

  _handleHover(hover) {
    if(_.isEqual(hover, this.state.hover)) return
    this.setState({ hover })
  }

  _handleSelect(selected) {
    const item = this._getResolved(selected)
    if(item.onClick) return item.onClick()
    if(selected === this.state.selected) return
    this.setState({ selected })
  }
}

export default Finder
