const INITIAL_STATE = {
  active: 0,
  direction: 'left'
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'PREVIOUS':
    return {
      ...state,
      direction: 'right',
      active: state.active === 0 ? action.total - 1 :  state.active - 1
    }
    
  case 'NEXT':
    return {
      ...state,
      direction: 'left',
      active: state.active === action.total - 1 ? 0 : state.active + 1
    }

  case 'GOTO':
    return {
      ...state,
      direction: (action.index > state.index || (state.index === action.total && action.index === 0)) ? 'left' : 'right',
      active: action.index
    }

  default:
    return state
  }

}

export default reducer
