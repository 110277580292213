import ColorField from '@admin/components/form/colorfield'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'
import Color from 'color'

class WebColorField extends React.Component {

  static propTypes = {
    defaultColor: PropTypes.bool,
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    theme: PropTypes.object,
    label: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.object,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    defaultColor: false,
    defaultValue: null,
    disabled: false,
    tabIndex: 0,
    onBusy: () => {},
    onChange: (value) => {},
    onReady: () => {}
  }

  state = {
    expanded: false,
    color: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleToggle = this._handleToggle.bind(this)

  render() {
    if(!this.state.color) return null
    const { expanded } = this.state
    const { brightness, opacity, value } = this.state.color
    const color = this._getColor()
    const { id } = this.props
    return (
      <>
        { color &&
          <style nonce={ window.nonce } dangerouslySetInnerHTML={{
            __html: `
            [data-id="${id}"] {
              --colorfield-dark: ${Color(color).darken(1).hex()};
              --colorfield-normal: ${color};
              --colorfield-light: ${color};
              --colorfield-transparent: ${Color(color).alpha(0).hexa()};
              --colorfield-opaque: ${Color(color).alpha(1).hexa()};
            }`
          }} />
        }
        <div className={ this._getClass() } data-id={id}>
          <div className="mjson-designer-colorfield-input">
            <div className="mjson-designer-colorfield-input-field">
              <ColorField { ...this._getColorField() } />
            </div>
            { value &&
              <Button { ...this._getExpand() } />
            }
          </div>
          { expanded ?
            <div className="mjson-designer-colorfield-controls">
              <div className="mjson-designer-colorfield-control brightness">
                <div className="mjson-designer-colorfield-label">
                  <T text="t(Brightness)" />
                </div>
                <div className="mjson-designer-colorfield-range">
                  <WebRange { ...this._getBrightness() } />
                </div>
              </div>
              <div className="mjson-designer-colorfield-control opacity">
                <div className="mjson-designer-colorfield-label">
                  <T text="t(Opacity)" />
                </div>
                <div className="mjson-designer-colorfield-range">
                  <WebRange { ...this._getOpacity() } />
                </div>
              </div>
            </div> : value &&
            <div className="mjson-designer-colorfield-summary" onClick={ this._handleToggle }>
              <span><T text="t(Brightness)" />:</span> { brightness },  <span><T text="t(Opacity)" />:</span> { opacity }
            </div>
          }
        </div>
      </>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { color } = this.state
    const { value } = this.props
    if(!_.isEqual(color, prevState.color)) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getBrightness() {
    const { brightness } = this.state.color
    return {
      value: brightness,
      units: [
        { name: '%', min: 0, max: 200, step: 1 }
      ],
      defaultValue: '100%',
      onChange: this._handleUpdateValue.bind(this, 'brightness')
    }
  }

  _getClass() {
    const { expanded } = this.state
    const classes = ['mjson-designer-colorfield']
    if(expanded) classes.push('expanded')
    return classes.join(' ')
  }

  _getColor() {
    const { theme } = this.props
    const { value } = this.state.color
    if(!value) return null
    if(value[0] === '#') return value
    if(value[0] === '@') return theme.palette[value.substr(1)]
  }

  _getColorField() {
    const { color } = this.state
    return {
      customLabel: 'Palette',
      customColors: this._getCustomColors(),
      value: color.value,
      onChange: this._handleUpdateValue.bind(this, 'value')
    }
  }

  _getCustomColors() {
    const { defaultColor } = this.props
    const { palette } = this.props.theme
    return [
      ...defaultColor ? [
        { value: 'default', hex: '#FFFFFF', text: 't(Best Fit)' }
      ] : [],
      { value: '@light', hex: palette.light, text: 't(Light Color)' },
      { value: '@dark', hex: palette.dark, text: 't(Dark Color)' },
      { value: '@base', hex: palette.base, text: 't(Base Color)' },
      { value: '@accent1', hex: palette.accent1, text: 't(Accent 1)' },
      { value: '@accent2', hex: palette.accent2, text: 't(Accent 2)' }
    ]
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getExpand() {
    return {
      icon: 'chevron-up',
      className: 'mjson-designer-colorfield-input-action',
      handler: this._handleToggle
    }
  }

  _getOpacity() {
    const { color } = this.state
    return {
      value: color.opacity,
      units: [
        { name: '%', min: 0, max: 100, step: 1 }
      ],
      defaultValue: '100%',
      onChange: this._handleUpdateValue.bind(this, 'opacity')
    }
  }

  _handleChange() {
    const { brightness, value, opacity } = this.state.color
    this.props.onChange(value ? {
      value,
      ...brightness !== '100%' ? { brightness } : {},
      ...opacity !== '100%' ? { opacity } : {}
    } : null)
  }

  _handleSet(color) {
    this.setState({
      color: {
        brightness: '100%',
        opacity: '100%',
        value: null,
        ...color || {}
      }
    })
  }

  _handleToggle() {
    const { color, expanded } = this.state
    if(!color.value) return
    this.setState({
      expanded:  !expanded
    })
  }

  _handleUpdateValue(key, value) {
    this.setState({
      color: {
        ...this.state.color,
        [key]: value
      }
    })
  }

}

export default WebColorField
