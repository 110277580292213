import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import Classification from './classification'
import Properties from './properties'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    fields: PropTypes.array
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Contact)',
      action: '/api/admin/crm/contacts',
      method: 'POST',
      props: this._getProps(),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getProps() {
    const { fields } = this.props
    return {
      fields
    }
  }

  _getSteps() {
    return [
      { label: 't(Properties)', component: Properties },
      { label: 't(Classification)', component: Classification }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(contact) {
    this.context.router.push(`/admin/crm/contacts/${contact.id}`)
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  fields: '/api/admin/programs/fields'
})

export default Container(mapResources)(New)
