import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import RadiusField from '../radiusfield'
import BorderField from '../borderfield'
import LinkedField from '../linkedfield'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Range = (props) => <ResponsiveField { ...props } component={ WebRange } />
const Linked = (props) => <ResponsiveField { ...props } component={ LinkedField } />
const Border = (props) => <ResponsiveField { ...props } component={ BorderField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />

class IconStyleField extends React.Component {

  static propTypes = {
    canHover: PropTypes.bool,
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    canHover: true,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    icon: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.icon) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { icon } = this.state
    if(!_.isEqual(icon, prevState.icon)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { canHover, device, theme } = this.props
    const { icon } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Size)', name: 'size', device, canHover, type: Range, units: [
              { name: 'px', min: 15, max: 100, step: 1 }
            ], defaultValue: '32px', value: icon.size },
            { label: 't(Background Color)', name: 'backgroundColor', device, theme, canHover, type: Color, defaultColor: true, value: icon.backgroundColor },
            { label: 't(Color)', name: 'color', type: Color, device, theme, canHover, defaultColor: true, defaultValue: {
              value: 'default'
            },value: icon.color },
            { label: 't(Padding)', name: 'padding', device, canHover, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: icon.padding },
            { label: 't(Margin)', name: 'margin', device, canHover, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: icon.margin },
            { label: 't(Border)', name: 'border', device, theme, canHover, type: Border, value: icon.border },
            { label: 't(Rounded Corners)', name: 'radius', device, theme, canHover, type: Radius, value: icon.radius }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { icon } = this.state
    this.props.onChange(icon)
  }

  _handleSet(icon) {
    this.setState({
      icon: {
        ...icon
      }
    })
  }

}

export default IconStyleField
