import EventToken from '@apps/events/admin/tokens/event'
import Panel from '@admin/components/panel'
import Clone from '../clone'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Canceled Events)',
  collection: {
    endpoint: '/api/admin/events/events',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Event)', key: 'end_date', primary: true, format: EventToken }
    ],
    criteria: {
      fields: [
        { label: 't(Event)', fields: [
          { name: 'Program', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Start Date)', key: 'start_date' },
      { label: 't(End Date)', key: 'end_date' },
      { label: 't(Program)', key: 'program.title'},
      { label: 'Registrations', key: 'registrations_count' },
      { label: 't(Tickets)', key: 'tickets_count' },
      { label: 'Waiting', key: 'waitings_count' },
      { label: 't(Revenue)', key: 'revenue' }
    ],
    defaultQuery: { type: 'canceled' },
    defaultSort: { key: 'end_date', order: 'desc' },
    empty: {
      icon: 'calendar-o',
      title: 't(No Events)',
      text: 't(You do not have any canceled events)'
    },
    entity: 'event',
    onClick: (record) => context.router.push(`/events/events/${record.id}`),
    recordTasks: (record) => [
      {
        label: 'Clone Event',
        modal: <Clone event={ record } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
