import MJSONPreview from '@admin/components/mjson_preview'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ template }, { admin }) => {

  const program = {
    label: template.program.title,
    className: 'link',
    route: `/admin/team/programs/${template.program.id}`
  }

  const details = {
    audits: `crm_templates/${template.id}`,
    comments: `crm_templates/${template.id}`,
    header: <MJSONPreview entity={ template } table="crm_templates" />,
    sections: [
      {
        title: 't(Template Details)',
        items: [
          { label: 't(Title)', content: template.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
        ]
      }
    ]
  }

  if(template.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This template was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  template: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview template={ props.template } />,
  tasks: !props.template.deleted_at ? {
    items: [
      { 
        label: 't(Edit Template)', 
        access: { 
          programs: { $canEdit: props.template.program.id },
          rights: { $oneOf: ['team:manage_templates'] } 
        },
        modal: <Edit template={ props.template } /> 
      },
      {
        label: 't(Delete Template)',
        access: { 
          programs: { $canEdit: props.template.program.id },
          rights: { $oneOf: ['team:manage_templates'] } 
        },
        confirm: 't(Are you sure you want to delete this template?)',
        request: {
          endpoint: `/api/admin/team/templates/${props.template.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this template)'),
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted this template)')
            context.router.goBack()
          }
        }
      }
    ]
  } : null
})

export default Panel(null, mapPropsToPanel)
