import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strings = {
  blocked: {
    title: 't(Desktop Notifications Blocked)',
    text: 't(You\'ve disallowed mobile push notifications on this device. If you want to enable them in the future, you\'ll need to open the app\'s preferences to change that.)'
  },
  send: 't(Send push notifications)'
}

class Mobile extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    host: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.bool,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    permission: null,
    ready: false,
    value: true
  }

  _handleDemo = this._handleDemo.bind(this)
  _handleRequestPermission = this._handleRequestPermission.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { permission, ready, value } = this.state
    if(!ready) return null
    return (
      <div className="maha-desktop">
        { permission === 'default' &&
          <Button { ...this._getEnable() } />
        }
        { permission === 'denied' &&
          <div className="maha-desktop-panel denied">
            <div className="maha-desktop-panel-label">
              <strong><T text={ strings.blocked.title } /></strong><br />
              <T text={ strings.blocked.text } />
            </div>
          </div>
        }
        { permission === 'granted' &&
          <div className="maha-preferences">
            <div className="maha-preference" onClick={ this._handleToggle }>
              <div className="maha-preference-icon">
                <Icon icon={ value ? 'check-circle' : 'circle-o' } />
              </div>
              <div className="maha-preference-label">
                <T text={ strings.send } /> ( <Button { ...this._getDemo() } /> )
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { host } = this.context
    const { defaultValue } = this.props
    const value = defaultValue === true
    host.notifications.getPermission().then(permission => {
      this.setState({
        permission,
        ready: true,
        value
      })
      this.props.onReady()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    if(value !== prevState.value) {
      this.props.onChange(value)
    }
  }

  _getDemo() {
    return {
      label: 't(demo)',
      className: 'link',
      handler: this._handleDemo
    }
  }

  _getEnable() {
    return {
      className: 'ui blue button',
      icon: 'bell',
      label: 't(Enable push notifications)',
      handler: this._handleRequestPermission
    }
  }

  _handleDemo(e) {
    const { flash, network } = this.context
    e.stopPropagation()
    network.request({
      endpoint: '/api/admin/notifications/push',
      method: 'POST',
      body: {
        strategy: 'firebase',
        notification: {
          title: 'Test Push Notification',
          body: 'this is a test push notification',
          route: '/'
        }
      },
      onFailure: (result) => flash.set('error', 't(Unable to send a push notification)')
    })
  }

  async _handleGetToken() {
    const { device, host } = this.context
    const token = await host.notifications.getToken()
    device.saveToken(token)
  }

  async  _handleRequestPermission() {
    const { host } = this.context
    const permission = await host.notifications.requestPermission()
    this.setState({ permission })
    if(permission === 'denied') return
    await this._handleGetToken()
  }

  _handleToggle() {
    const { value } = this.state
    this.setState({
      value: !value
    })
  }

}

export default Mobile
