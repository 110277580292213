  import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Renewals from './renewals'
import Contact from './contact'
import Payment from './payment'
import React from 'react'
import Plan from './plan'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    plans: PropTypes.array,
    service: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { service } = this.props
    return {
      title: 't(New Subscription)',
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions`,
      method: 'POST',
      formatData: this._getData,
      getSteps: this._getSteps,
      props: this._getProps(),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(subscription) {
    return subscription
  }

  _getProps() {
    const { plans, service } = this.props
    return {
      plans,
      service
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Contact)', component: Contact },
      { label: 't(Plan)', component: Plan },
      { label: 't(Renewals)', component: Renewals },
      { label: 't(Payment)', component: Payment }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(subscription) {
    const { service } = this.props
    this.context.router.push(`/subscriptions/services/${service.id}/subscriptions/${subscription.id}`)
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  plans: `/api/admin/subscriptions/services/${props.service.id}/plans`
})

export default Container(mapResources)(New)
