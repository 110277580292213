const INITIAL_STATE = {
  comments: [],
  draft: {
    attachments: [],
    link: null,
    text: ''
  },
  typing: [],
  mode: 'collapsed'
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'ADD_COMMENT':
    return {
      ...state,
      comments: [
        ...state.comments.filter(added => {
          return added.uid !== action.uid
        }),
        action.comment
      ]
    }

  case 'ADD_TYPING':
    return {
      ...state,
      typing: [
        ...state.typing.filter(typing => {
          return typing.user_id !== action.user_id
        }),
        action.typing
      ]
    }

  case 'QUOTE_COMMENT':
    return {
      ...state,
      quoted_comment_id: action.id
    }

  case 'REMOVE_COMMENT':
    return {
      ...state,
      comments: state.comments.filter(added => {
        return added.uid !== action.uid
      })
    }

  case 'REMOVE_TYPING':
    return {
      ...state,
      typing: state.typing.filter(typing => {
        return typing.user_id !== action.user_id
      })
    }

  case 'SET_COMMENTS':
    return {
      ...state,
      comments: action.comments
    }

  case 'SET_MODE':
    return {
      ...state,
      mode: action.mode
    }

  case 'UPDATE_DRAFT':
    return {
      ...state,
      draft: action.draft
    }

  default:
    return state
  }

}

export default reducer
