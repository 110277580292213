import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'

const mapPropsToPanel = (props, context) => ({
  title: 't(Teams)',
  collection: {
    endpoint: `/api/admin/platform/providers/${props.provider.id}/teams`,
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', sort: 'title', primary: true, format: TeamToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'building-o',
      title: 't(No Teams)',
      text: 't(You have not yet assigned this provider to any teams)'
    },
    entity: 'team'
  }
})

export default Panel(null, mapPropsToPanel)
