import VoiceToken from '@apps/truevail/admin/tokens/voice'
import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const services = {
  instagram_business: 'Instagram',
  facebook: 'Facebook',
  linkedin: 'LinkedIn'
}

const Overview = ({ social_campaign }) => {

  const slides = social_campaign.variants.map((variant, index) => (
    <MJSONPreview entity={ variant } table="truevail_social_campaign_variants" key={`variant_${index}`} />
  ))

  const details = {
    audits: `truevail_social_campaigns/${social_campaign.id}`,
    comments: `truevail_social_campaigns/${social_campaign.id}`,
    header: <Carousel slides={ slides } />,
    sections: [
      {
        title: 't(Social Campaign Details)',
        items: [
          { label: 't(Title)', content: social_campaign.title },
          { label: 't(Type)', content: social_campaign.type.toUpperCase() },
          { label: 't(Networks)', content: social_campaign.variants.map(variant => services[variant.service]).join(', ') },
          { label: 't(Voice)', content: <VoiceToken perspective={ social_campaign.perspective } language={ social_campaign.language } /> },
          { label: 't(Tags)', content: social_campaign.tags.length > 0 ? <TagsToken tags={ social_campaign.tags } /> : null }
        ]
      }
    ]
  }

  if(social_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social campaign was deleted)' }
  } else if(social_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social campaign is not published to the library)' }
  } else if(social_campaign.status === 'translating') {
    details.alert = { color: 'teal', message: 't(This social campaign is being translated)' }
  } else if(social_campaign.status === 'published') {
    details.alert = { color: 'green', message: 't(This social campaign is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  social_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_campaign={ props.social_campaign } />,
  tasks: {
    items: [
      {
        label: 't(Edit Campaign)',
        modal: <Edit social_campaign={ props.social_campaign } />
      },
      {
        label: 't(Clone Campaign)',
        modal: <Clone social_campaign={ props.social_campaign } />
      },
      {
        label: 't(Publish to Library)',
        show: props.social_campaign.status === 'draft',
        confirm: 't(Are you sure you want to publish this campaign?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_campaigns/${props.social_campaign.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish social campaign)')
        }
      },
      {
        label: 't(Unpublish from Library)',
        show: props.social_campaign.status === 'published',
        confirm: 't(Are you sure you want to unpublish this campaign?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_campaigns/${props.social_campaign.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaign)')
        }
      },
      {
        label: 't(Delete Campaign)',
        show: props.social_campaign.status === 'draft',
        confirm: 't(Are you sure you want to delete this campaign?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_campaigns/${props.social_campaign.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted socialcampaign)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
