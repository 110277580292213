import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Variants from './variants'
import React from 'react'

class File extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    data: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...this._getStrategy(),
            ...this._getFile()
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { formdata } = this.props
    if(!formdata.has_variants) return [
    ]
    return [
      { name: 'file_strategy', type: 'radiogroup', required: true, options: [
        { value: 'shared', text: 't(Use the same shipping strategy for each variant)' },
        { value: 'unique', text: 't(Use different shipping strategy for each variant)' }
      ], defaultValue: 'shared' }
    ]
  }

  _getFile() {
    const { formdata } = this.props
    const { data } = this.state
    if(!formdata.has_variants || data.file_strategy === 'shared') {
      return [
        { label: 't(File)', name: 'file', type: 'attachmentfield', multiple: false, formatter: asset => asset, required: true }
      ]
    }
    return [
      { label: 'Files', name: 'variants', type: Variants, product: formdata, required: true }
    ]
  }

  _getVariants() {
    const { formdata } = this.props
    const { data } = this.state
    const { file_strategy, file, variants } = data
    return formdata.variants.map(variant => ({
      ...variant,
      ...file_strategy === 'unique' ? _.find(variants, { code: variant.code }) : {
        file
      }
    }))
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(data) {
    this.props.onSave({
      variants: this._getVariants()
    })
  }

}

export default File
