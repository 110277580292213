export const onDetails = (code) => ({
  type: 'DETAILS',
  code
})

export const onExpandStep = (code) => ({
  type: 'EXPAND_STEP',
  code
})

export const onHover = (hovering) => ({
  type: 'HOVER',
  hovering
})

export const onSetActive = (active) => ({
  type: 'SET_ACTIVE',
  active
})

export const onSetStep = (step) => ({
  type: 'SET_STEP',
  step
})