import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import CompactVendorToken from '@apps/finance/admin/tokens/vendor/compact'
import AllocationsToken from '@apps/finance/admin/tokens/allocations'
import Receipt from '@apps/finance/admin/components/receipt'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const requiredField = (label, item, path, extra) => {
  if(!_.isNil(_.get(item, path))) {
    return { label, content: _.get(item, path), ...extra }
  } else  {
    return { label, content: 'REQUIRED', className: 'error' }
  }
}

const Overview = ({ reimbursement }) => {

  const details = {
    audits: `finance_reimbursements/${reimbursement.id}`,
    comments: `finance_reimbursements/${reimbursement.id}`
  }

  details.items = [
    requiredField('User', reimbursement, 'user.full_name'),
    requiredField('Date', reimbursement, 'date', { content: reimbursement.date, format: 'date' }),
    requiredField('Vendor', reimbursement, 'vendor.name', { content: reimbursement, format: CompactVendorToken }),
    requiredField('Total', reimbursement, 'total', { content: reimbursement.total, format: 'currency' })
  ]

  if(reimbursement.allocations.length > 1 ) {
    details.items.push({ component: <AllocationsToken allocations={ reimbursement.allocations } item={ reimbursement } /> })
  } else {
    details.items.push(requiredField('Project', reimbursement, 'project.title', { content: reimbursement, format: CompactProjectToken }))
    details.items.push(requiredField('Expense Type', reimbursement, 'expense_type.title', { content: reimbursement, format: CompactExpenseTypeToken }))
    details.items.push(requiredField('Description', reimbursement, 'description', { content: reimbursement.description }))
    details.items.push(requiredField('Amount', reimbursement, 'amount', { content: reimbursement.amount, format: 'currency' }))
  }

  if(reimbursement.receipts.length > 0) {
    const previews = reimbursement.receipts.filter(receipt => receipt.status === 'processed' && (receipt.has_preview || receipt.is_image))
    const slides = previews.map((receipt, index) => <Receipt key={`receipt_preview_${index}`} preview={ true } value={ receipt } />)
    details.header = <Carousel slides={ slides } />
    details.items.unshift({ content: reimbursement.receipts.map((receipt, index) => <Receipt key={`receipt_${index}`} preview={ false } value={ receipt } />), className: 'receipts' })
  } else {
    details.items.unshift(requiredField('Receipt', reimbursement, 'receipts_ids'))
  }
  
  if(reimbursement.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This reimbursement was deleted)' }
  } else if(reimbursement.status === 'incomplete') {
    details.alert = { color: 'grey', message: 't(This reimbursement is missing required information)' }
  } else if(reimbursement.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This reimbursement has not yet been submitted)' }
  } else if(reimbursement.status === 'submitted') {
    details.alert = { color: 'blue', message: 't(This reimbursement has been submitted for review)' }
  } else if(reimbursement.status === 'approved') {
    details.alert = { color: 'green', message: 't(This reimbursement has been approved)' }
  } else if(reimbursement.status === 'rejected') {
    details.alert = { color: 'red', message: 't(This reimbursement has been rejected)' }
  } else if(reimbursement.status === 'reviewed') {
    details.alert = { color: 'pink', message: 't(This reimbursement has been reviewed)' }
  } else if(reimbursement.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This reimbursement was exported)' }
  }

  return <Details { ...details } />
  
}

Overview.propTypes = {
  reimbursement: PropTypes.object
}

export default Overview
