import Container from '@admin/components/container'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'
import New from './new'

class ContactFieldsField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    fields: PropTypes.array,
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    fields: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleNew = this._handleNew.bind(this)

  render() {
    const { fields } = this.state
    if(!fields) return null
    const core = [
      { label: 't(First Name)', type: 't(textfield)' },
      { label: 't(Last Name)', type: 't(textfield)' },
      { label: 't(Email)', type: 't(emailfield)' }
    ]
    return (
      <div className="contactfieldsfield">
        { core.map((field, index) => (
          <div className="contactfieldsfield-field" key={`field_${index}`}>
            <div className="contactfieldsfield-field-label">
              <T text={ field.label } /> <span>(<T text={ field.type } />)</span>
            </div>
          </div>
        )) }
        { fields.map((field, index) => (
          <div className="contactfieldsfield-field" key={`field_${index}`}>
            <div className="contactfieldsfield-field-label">
              { field.name.value } <span>(<T text={ this._getType(field) } />)</span>
            </div>
            <Button { ...this._getEditButton(field, index) } />
            <Button { ...this._getRemoveButton(index) } />
          </div>
        ))}
        <div className="contactfieldsfield-add">
          <Button { ...this._getAddButton() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    this.setState(defaultValue || { fields: [] })
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { fields } = this.state
    if(!_.isEqual(fields, prevState.fields)) {
      this._handleChange()
    }
  }

  _getAddButton() {
    return {
      label: 'Add field',
      className: 'link',
      handler: this._handleNew
    }
  }

  _getAvailable() {
    const { program } = this.props
    const { fields } = this.state
    const contactfields = fields.filter(field => {
      return field.type === 'contactfield'
    }).map(field => {
      return field.contactfield.name
    })
    const available = [
      { label: 't(Contact)', fields: [
        { label: 't(Phone)', name: 'phone', type: 'phonefield' },
        { label: 't(Address)', name: 'address', type: 'addressfield' },
        { label: 't(Birthday)', name: 'birthday', type: 'textfield' },
        { label: 't(Spouse)', name: 'spouse', type: 'textfield' }
      ] },
      {
        label: program.title,
        fields: this.props.fields.map(field => ({
          code: field.code,
          label: field.label,
          name: `values.${field.code}`,
          type: field.type,
          instructions: field.instructions,
          config: field.config
        }))
      },
      { label: 'Consent', fields: [
        { label: 'Email Consent', name: 'consent.email', type: 'checkbox', prompt: '<p>Please send me emails</p>' },
        ..._.includes(contactfields, 'phone') ? [
          { label: 'SMS Consent', name: 'consent.sms', type: 'checkbox', prompt: '<p>Please send me text messages</p>' },
          { label: 'Voice Consent', name: 'consent.voice', type: 'checkbox', prompt: '<p>Please call me</p>' }
        ] : []
      ] }
    ]
    return available.map(group => ({
      ...group,
      fields: group.fields.filter(field => {
        return _.find(fields, {
          contactfield: {
            name: field.name
          }
        }) === undefined
      })
    })).filter(group => {
      return group.fields.length > 0
    })
  }

  _getEdit(field, index) {
    return {
      field,
      fields: this._getAvailable(),
      onBack: this._handleBack,
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getEditButton(field, index) {
    return {
      icon: 'pencil',
      className: 'contactfieldsfield-field-action',
      handler: this._handleEdit.bind(this, field, index)
    }
  }

  _getNew() {
    return {
      fields: this._getAvailable(),
      onBack: this._handleBack,
      onDone: this._handleAdd
    }
  }

  _getRemoveButton(index) {
    return {
      icon: 'times',
      className: 'contactfieldsfield-field-action',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _getType(field) {
    return field.contactfield ? field.contactfield.type: field.type
  }

  _handleAdd(field) {
    this.setState({
      fields: [
        ...this.state.fields,
        field
      ]
    })
    this.context.form.pop()
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange() {
    const { fields } = this.state
    this.props.onChange({ fields })
  }

  _handleEdit(field, index) {
    this.context.form.push(Edit, this._getEdit(field, index))
  }

  _handleNew() {
    this.context.form.push(New, this._getNew())
  }

  _handleRemove(index) {
    this.setState({
      fields: this.state.fields.filter((field, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(index, newfield) {
    this.setState({
      fields: this.state.fields.map((field, i) => {
        return i === index ? newfield : field
      })
    })
    this.context.form.pop()
  }

}

const mapResources = (props, context) => ({
  program: `/api/admin/team/programs/${props.program_id}`,
  fields: `/api/admin/team/programs/${props.program_id}/fields`
})

export default Container(mapResources)(ContactFieldsField)
