import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.notice.notice.title,
  manager: {
    path: `/admin/truevail/admin/notices/${resources.notice.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview notice_id={ resources.notice.id } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design notice={ resources.notice } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  notice: `/api/admin/truevail/admin/notices/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
