import SubscriptionsShow from './services/show/subscriptions/show'
import RenewalsShow from './services/show/renewals/show'
import PlansShow from './services/show/plans/show'
import ServicesList from './services/list'
import ServicesShow from './services/show'

const routes = [
  { path: '/services', component: ServicesList },
  { path: '/services/:id/:view', component: ServicesShow },
  { path: '/services/:service_id/plans/:id/:view', component: PlansShow },
  { path: '/services/:service_id/subscriptions/:id/:view', component: SubscriptionsShow },
  { path: '/services/:service_id/renewals/:id/:view', component: RenewalsShow }
]

export default routes
