import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Type extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    sender: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Invoice Type)', 
              name: 'type', 
              type: 'radiogroup', 
              options: [
                { value: 'custom', text: 't(Custom Invoice)' }
              ],
              defaultValue: 'logo' 
            }
          ]
        }
      ]
    }
  }

  _handleChange(sender) {
    this.setState({ sender })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(sender) {
    this.props.onNext(sender)
  }

}

export default Type
