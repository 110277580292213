import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(DMARC Reports)',
  collection: {
    endpoint: '/api/admin/truevail/admin/dmarc_reports',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Organization)', key: 'organization.name', sort: 'organization' },
      { label: 't(Dates)', key: 'start_date', wrap: false, collapsing: true, format: 'date' }
    ],
    defaultSort: { key: 'start_date', order: 'desc' },
    empty: {
      icon: 'file',
      title: 't(No DMARC Reports)',
      text: 't(There are no DMARC reports)'
    },
    entity: 'DMARC report',
    onClick: (record) => context.router.push(`/admin/truevail/admin/dmarc_reports/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
