import ProviderNoticeToken from '@apps/maha/admin/tokens/provider_notice'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Notices)',
  collection: {
    endpoint: `/api/admin/platform/providers/${props.provider.id}/notices`,
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'notice.title', sort: 'code', primary: true, format: (provider_notice) => (
        <ProviderNoticeToken provider_notice={ provider_notice } />
      ) }
    ],
    defaultSort: { key: 'code', order: 'asc' },
    empty: {
      icon: 'file-o',
      title: 't(No Notices)',
      text: 't(You have not yet created any notices for this provider)'
    },
    entity: 'notice',
    onClick: (record) => context.router.push(`/platform/providers/${props.provider.id}/notices/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
