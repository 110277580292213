import ProgramToken from '@apps/crm/admin/tokens/program'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Programs = ({ programs }) => {

  const details = {
    items: programs.map((program, index) => ({
      component: <ProgramToken { ...program } />
    }))
  }

  return <Details { ...details } />

}

Programs.propTypes = {
  programs: PropTypes.array
}

export default Programs
