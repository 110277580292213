import Page from '@admin/components/page'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Analytics)',
  collection: {
    endpoint: '/api/admin/analytics/apps',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Domain)', key: 'domain', primary: true }
    ],
    defaultSort: { key: 'domain', order: 'asc' },
    entity: 'app',
    onClick: (record) => context.router.push(`/analytics/apps/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
