import NumberField from './numberfield'
import PropTypes from 'prop-types'
import React from 'react'

const NumberFieldContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    blur: () => componentRef.current._handleBlur(),
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus(),
    set: (value) => componentRef.current._handleSet(value)
  }))

  return <NumberField ref={ componentRef } {...props} />

})

export default NumberFieldContainer
