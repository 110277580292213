import PropTypes from 'prop-types'
import React from 'react'

class OptionsField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: (value) => {},
    onReady: () => {}
  }

  state = {
    value: ''
  }

  _handleChange = _.throttle(this._handleChange.bind(this), 150)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { options } = this.props
    return (
      <div className="maha-options">
        { options.map((option, index) => (
          <div className={ this._getClass(option) } key={`option_${index}`} onClick={ this._handleUpdate.bind(this, option)}>
            { option }
          </div>
        ))}
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== this.state.value) {
      this._handleSet(this.props.value)
    }
  }

  _getClass(tag) {
    const { value } = this.state
    const classes = ['maha-options-option']
    if(tag === value) classes.push('active')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value)
  }

  _handleSet(value) {
    this.setState({ value })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

}

export default OptionsField
