import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (type, entity) => ({
  entity: `websites_${type}s/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const ElementToken = ({ element, icon, type }) => (
  <div className="element-token">
    <div className="element-token-image">
      <MJSONScreenshot { ...getScreenshot(type, element) }/>
    </div>
    <div className="element-token-label">
      <div className="element-token-content">
        { element.title }<br />
        <span className={`element-token-status ${element.status}`}>{ element.status }</span>
      </div>
    </div>
  </div>
)

ElementToken.propTypes = {
  type: PropTypes.string,
  element: PropTypes.object,
  icon: PropTypes.string
}

export default ElementToken
