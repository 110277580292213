import Content from '@apps/forms/admin/tokens/content'
import StatusToken from '@admin/tokens/status'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Payment from './payment'
import Items from './items'
import moment from 'moment'
import React from 'react'

class Order extends React.Component {

  static propTypes = {
    invoice: PropTypes.object,
    order: PropTypes.object,
    store: PropTypes.object
  }

  render() {
    const { invoice, order } = this.props
    return (
      <div className="store-order">
        <div className="store-order-inner">
          <div className="store-order-header">
            <div className="store-order-header-title">
              Order #{ order.id }
            </div>
            <div className="store-order-timestamp">
              { moment(order.created_at).format('MM/DD/YYYY @ hh:mmA')}
            </div>
            <div className="store-order-contact">
              <Button { ...this._getContact() } />
            </div>
            <div className="store-order-status">
              <StatusToken value={ invoice.status } />
            </div>
          </div>
          <div className="store-order-body">
            <div className="store-order-sidebar">
              <div className="store-order-panel contact">
                <div className="store-order-panel-header">
                  Customer Info
                </div>
                <Details { ...this._getCustomer() } />
              </div>
            </div>
            <div className="store-order-main">
              { this._getTypes().map((type, index) => (
                <div className="store-order-panel" key={`type_${index}`}>
                  <Items { ...type } />
                </div>
              ))}
              <Payment order={ order } invoice={ invoice } />
              <div className="store-order-panel">
                <div className="store-order-panel-header">
                  Activity
                </div>
                <Details { ...this._getOrder() } />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getContact() {
    const { order } = this.props
    return {
      label: order.contact.display_name,
      className: 'link',
      route: `/admin/crm/contacts/${order.contact.id}`
    }
  }

  _getCustomer() {
    const { order, store } = this.props
    return {
      sections: [
        {
          items: [
            { label: 't(First Name)', content: order.data.first_name },
            { label: 't(Last Name)', content: order.data.last_name },
            { label: 't(Email)', content: order.data.email },
            ...store.contact_config.fields.filter(field => {
              return field.type !== 'text'
            }).map(field => ({
              label: field.name.value,
              content: <Content data={ order.data } field={ field } />
            })),
            ...order.location ? [
              { label: 't(IP Address)', content: (
                <>
                  {order.location.ipaddress.ipv4 } {order.location.ipaddress.address && order.location.ipaddress.address.city &&
                    <>({order.location.ipaddress.address.city}, {order.location.ipaddress.address.region})</>
                  }
                </>
              ) }  
            ] : [],
            { label: 't(Invoice)', content: <Button { ...this._getInvoice() } /> },
            { label: 't(Workflow)', content: <Button { ...this._getEnrollment() } /> }
          ]
        }
      ]
    }
  }

  _getEnrollment() {
    const { order } = this.props
    return {
      label: 'View Enrollment',
      className: 'link',
      route: `/admin/automation/workflows/${order.enrollment.workflow_id}/enrollments/${order.enrollment.id}`
    }
  }

  _getInvoice() {
    const { order } = this.props
    return {
      label: 'View Invoice',
      className: 'link',
      route: `/admin/finance/invoices/${order.invoice_id}`
    }
  }

  _getItems(statuses) {
    const { order } = this.props
    return Object.values(order.items.filter(item => {
      return _.includes(statuses, item.status)
    }).reduce((collected, item) => {
      const existing = collected[item.variant.id]
      return {
        ...collected,
        [item.variant.id]: {
          variant: item.variant,
          price: item.price,
          quantity: existing ? existing.quantity + 1 : 1
        }
      }
    }, {})).sort((a,b) => {
      return a.variant.product.title > b.variant.product.title ? 1 : -1
    })
  }

  _getOrder() {
    const { order } = this.props
    return {
      audits: `stores_orders/${order.id}`,
      comments: `stores_orders/${order.id}`
    }
  }

  _getPaymentClass(payment) {
    const classes = []
    if(payment.voided_date) classes.push('voided')
    return classes.join(' ')
  }

  _getTypes() {
    const types = [
      { title: 't(Unfulfilled Items)', type: 'unfulfilled', icon: 'shopping-bag', statuses: ['unfulfilled'] },
      { title: 't(Fulfilled Items)', type: 'fulfilled', icon: 'check', statuses: ['fulfilled'] },
      { title: 't(Refunded Items)', type: 'refunded', icon: 'arrow-left', statuses: ['restocked','refunded'] }
    ]
    return types.map(type => ({
      title: type.title,
      type: type.type,
      icon: type.icon,
      items: this._getItems(type.statuses)
    })).filter(type => {
      return type.items.length > 0
    })
  }

}

export default Order
