import Form from '@admin/components/form'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class AnimationField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    animation: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.animation) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { animation } = this.state
    if(!_.isEqual(animation, prevState.animation)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { animation } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'none', text: 't(No Animation)' },
              { value: 'fade', text: 't(Fade)' },
              { value: 'slide', text: 't(Slide)' },
              { value: 'bounce', text: 't(Bounce)' },
              { value: 'zoom', text: 't(Zoom)' },
              { value: 'flip', text: 't(Flip)' },
              { value: 'fold', text: 't(Fold)' },
              { value: 'roll', text: 't(Roll)' }
            ], defaultValue: 'none', value: animation.type },
            ...this._getAnimation()
          ]
        }
      ]
    }
  }

  _getAnimation() {
    const { animation } = this.state
    if(animation.type === 'none') return []
    return [
      ...animation.type !== 'fade' ? [
        { label: 't(Direction)', name: 'direction', type: 'dropdown', options: [
          { value: 'center', text: 't(Center)' },
          { value: 'right', text: 't(Right)' },
          { value: 'left', text: 't(Left)' },
          { value: 'up', text: 't(Up)' },
          { value: 'down', text: 't(Down)' }
        ], defaultValue: 'center', value: animation.direction }
      ] : [],
      { label: 't(Duration)', name: 'duration', type: WebRange, units: [
        { name: 'ms', min: 0, max: 2000, step: 50 }
      ], defaultValue: '1000ms', value: animation.duration },
      { label: 't(Delay)', name: 'delay', type: WebRange, units: [
        { name: 'ms', min: 0, max: 3000, step: 50 }
      ], defaultValue: '0ms', value: animation.delay  },
      ..._.includes(['slide','zoom','flip','fold','roll'], animation.type) ? [
        { label: 't(Intensity)', name: 'intensity', type: WebRange, units: [
          { name: '%', min: 0, max: 100, step: 1 }
        ], defaultValue: '50%', value: animation.intensity  }
      ] : [],
      { label: 't(Starting Opacity)', name: 'opacity', type: WebRange, units: [
        { name: '%', min: 0, max: 100, step: 1 }
      ], defaultValue: '0%', value: animation.opacity },
      { label: 't(Speed Curve)', name: 'curve', type: 'dropdown', options: [
        { value: 'ease-in-out', text: 't(Ease-In-Out)' },
        { value: 'ease', text: 't(Ease)' },
        { value: 'ease-in', text: 't(Ease-In)' },
        { value: 'ease-out', text: 't(Ease-Out)' },
        { value: 'linear', text: 't(Linear)' }
      ], defaultValue: 'ease-in-out', value: animation.curve },
      { label: 'Repeat', name: 'repeat', type: 'dropdown', options: [
        { value: 'once', text: 't(Once)' },
        { value: 'loop', text: 't(Loop)' }
      ], defaultValue: 'once', value: animation.repeat  }
    ]
  }

  _handleChange() {
    const { animation } = this.state
    this.props.onChange(animation.type !== 'none' ? {
      type: animation.type,
      direction: animation.direction,
      duration: animation.duration,
      delay: animation.delay,
      intensity: animation.intensity,
      opacity: animation.opacity,
      curve: animation.curve,
      repeat: animation.repeat
    } : null)
  }

  _handleSet(animation) {
    this.setState({
      animation: {
        type: animation ? animation.type : 'none',
        direction: animation ? animation.direction : null,
        duration: animation ? animation.duration : null,
        delay: animation ? animation.delay : null,
        intensity: animation ? animation.intensity : null,
        opacity: animation ? animation.opacity : null,
        curve: animation ? animation.curve : null,
        repeat: animation ? animation.repeat : null
      }
    })
  }

  _handleUpdate(animation) {
    this.setState({
      animation: {
        type: animation.type,
        direction: animation.direction,
        duration: animation.duration,
        delay: animation.delay,
        intensity: animation.intensity,
        opacity: animation.opacity,
        curve: animation.curve,
        repeat: animation.repeat
      }
    })
  }

}

export default AnimationField
