import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class CaptionField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    autogrow: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    maxRows: PropTypes.number,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    autogrow: true,
    disabled: false,
    maxLength: null,
    maxRows: Infinity,
    placeholder: '',
    rows: 2,
    tabIndex: 0,
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()
  containerRef = React.createRef()

  state = {
    focused: false,
    offset: 0,
    value: ''
  }

  _handleBlur = this._handleBlur.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 150)
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleKeyUp = this._handleKeyUp.bind(this)
  _handleResize = this._handleResize.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { disabled, maxLength } = this.props
    const { value } = this.state
    return (
      <div className={ this._getClass() } ref={this.containerRef}>
        { maxLength &&
          <div className={ this._getMaxClass() }>
            { value.length } / { maxLength }
          </div>
        }
        <div className="maha-input-field">
          <textarea { ...this._getTextArea() } />
        </div>
        { value && value.length > 0 && !disabled &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.setState({
      offset: this.inputRef.current.offsetHeight - this.inputRef.current.clientHeight
    })
    this.props.onReady(this._handleValidate)
    this._handleResize()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled, label, maxLength } = this.props
    const classes = ['maha-input','maha-textarea']
    if(!label && maxLength !== null) classes.push('unlabeled')
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getMaxClass() {
    const { value } = this.state
    const { maxLength } = this.props
    const classes = ['maha-input-extra']
    if(value.length >= maxLength) classes.push('max')
    return classes.join(' ')
  }

  _getPlaceholder() {
    const { label, placeholder } = this.props
    const { t } = this.context.locale
    return t(placeholder || (label ? `Enter ${label}` : ''))
  }

  _getTextArea() {
    const { id, label, disabled, tabIndex, rows } = this.props
    const { focused, value } = this.state
    return {
      disabled,
      id,
      'aria-label': label,
      placeholder: !focused ? this._getPlaceholder() : null,
      ref: this.inputRef,
      tabIndex,
      value,
      rows,
      onBlur: this._handleBlur,
      onChange: this._handleUpdate,
      onFocus: this._handleFocus,
      onKeyUp: this._handleKeyUp
    }
  }

  _handleBlur() {
    this.setState({
      focused: false
    })
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value.length > 0 ? value : null)
  }

  _handleClear() {
    this._handleSet('')
  }

  _handleFocus() {
    this.setState({
      focused: true
    })
  }

  _handleKeyUp(e) {
    if(!this.props.autogrow) return
    this._handleResize()
  }

  _handleResize() {
    if (!this.props.autogrow) return
    const { offset } = this.state
    const { maxRows, rows } = this.props
    const textarea = this.inputRef.current
    const { selectionStart, selectionEnd } = textarea
    const previousHeight = textarea.style.height
    textarea.style.height = 'auto'
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10)
    const paddingTop = parseInt(window.getComputedStyle(textarea).paddingTop, 10)
    const paddingBottom = parseInt(window.getComputedStyle(textarea).paddingBottom, 10)
    const minHeight = lineHeight * rows + paddingTop + paddingBottom
    const maxHeight = maxRows !== Infinity ? lineHeight * maxRows + paddingTop + paddingBottom : Infinity
    const newHeight = Math.max(minHeight, Math.min(textarea.scrollHeight + offset, maxHeight))
    textarea.style.height = `${newHeight}px`
    textarea.style.overflowY = textarea.scrollHeight > maxHeight ? 'auto' : 'hidden'
    if(previousHeight !== textarea.style.height) {
      const container = this.containerRef.current
      const containerScrollTop = container.scrollTop
      const containerHeight = container.clientHeight
      const textareaBottom = textarea.offsetTop + newHeight
      if(textareaBottom > containerScrollTop + containerHeight) {
        container.scrollTop = textareaBottom - containerHeight
      }
    }
    textarea.setSelectionRange(selectionStart, selectionEnd)
  }

  _handleSet(val) {
    const value = `${val}`
    if(val === null || (this.props.maxLength && value.length > this.props.maxLength)) return
    this.setState({ value }, this._handleResize)
  }

  _handleUpdate(e) {
    this._handleSet(e.target.value)
  }

  _handleValidate() {
    const { value } = this.state
    if(value.length > 2200) {
      return this.props.onValid(null, ['Posts cannot be any longer than 2200 characters'])      
    }
    const hashtags = value.match(/#[\w]+/g)
    if(hashtags && hashtags.length > 30) {
      return this.props.onValid(null, ['Posts cannot have more than 30 hashtags'])      
    }
    this.props.onValid(value)
  }

}

export default CaptionField
