export const INITIAL_STATE = {
  adding: false,
  assigned: [],
  query: ''
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'ADD_ASSIGNED':
    return {
      ...state,
      assigned: [
        ...state.assigned,
        action.assignee
      ]
    }

  case 'REMOVE_ASSIGNED':
    return {
      ...state,
      assigned: state.assigned.filter((assignee, index) => {
        return index !== action.index
      })
    }

  case 'REVOKE_ASSIGNED':
    return {
      ...state,
      assigned: state.assigned.map((assignee, index) => ({
        ..._.omit(assignee, 'is_revoked'),
        ...index === action.index && assignee.is_revoked !== true ? {
          is_revoked: true
        } : {}
      }))
    }

  case 'SET_ADDING':
    return {
      ...state,
      adding: action.adding
    }

  case 'SET_ASSIGNED':
    return {
      ...state,
      assigned: action.assigned
    }

  case 'SET_QUERY':
    return {
      ...state,
      query: action.query
    }

  case 'SET_UNASSIGNED':
    return {
      ...state,
      unassigned: action.unassigned
    }

  case 'UPDATE_ASSIGNED':
    return {
      ...state,
      assigned: state.assigned.map((assignee, index) => ({
        ...index === action.index ? action.assignee : assignee
      }))
    }

  default:
    return state

  }

}

export default reducer
