import Panel from '@admin/components/panel'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Addons)',
  collection: {
    endpoint: `/api/admin/subscriptions/services/${props.service.id}/plans/${props.plan.id}/addons`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      { label: 'Price', key: 'price', primary: true, collapsing: true, format: 'currency' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'plus',
      title: 't(No Add Ons)',
      text: 't(You have not created any add ons for this plan)',
      buttons: [
        { label: 'Create Addon', modal: <New service={ props.service } plan={ props.plan } /> }
      ]
    },
    entity: 'plan',
    recordTasks: (addon) => [
      { label: 'Edit Addon', modal: <Edit service={ props.service } plan={ props.plan } addon={ addon } />}
    ]
  },
  task: {
    icon: 'plus',
    modal: <New service={ props.service } plan={ props.plan } />
  }
})

export default Panel(null, mapPropsToPanel)
