import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class PermalinkField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    destination: PropTypes.string,
    disabled: PropTypes.bool,
    host: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    required: PropTypes.bool,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    placeholder: '/path/to/page',
    prefix: '',
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    focused: false,
    value: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { value } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-permalinkfield-field">
          <div className="maha-input">
            <div className="maha-input-field">
              <Input { ...this._getInput() } />
            </div>
            { value &&
              <Button { ...this._getClear() } />
            }
          </div>
        </div>
        <div className="maha-permalinkfield-permalink">
          <span className="maha-permalinkfield-permalink-text">
            { this._getPermalink() }
          </span>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-permalinkfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, id, placeholder, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      disabled,
      id,
      type: 'text',
      value,
      placeholder: !focused ? placeholder : null,
      tabIndex,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getPermalink() {
    const { team } = this.context.admin
    const { prefix } = this.props
    const { value } = this.state
    const host = this.props.host || team.public_host
    return `${host}${prefix}/${ value.length > 0 ? value : '' }`
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      value: ''
    })
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleSet(value) {
    this.setState({ 
      value: value || ''
    })
  }

  _handleSuccess({ data }) {
    const { onValid } = this.props
    const { value } = this.state
    if(data.length > 0) {
      return onValid(value, ['This url is already taken'])
    }
    onValid(value)
  }

  _handleUpdate(value) {
    const regex = /^[a-z0-9-_]*$/
    if(!regex.test(value)) return
    this.setState({ value })
  }

  _handleValidate() {
    const { destination, required, onValid } = this.props
    const { value } = this.state
    if(!value && required) return onValid(value, ['This field is required'])
    if(!value) return onValid(null)
    if(!destination) return onValid(value)
    this.context.network.request({
      endpoint: '/api/admin/aliases',
      query: {
        $filter: {
          ...destination ? {
            destination: {
              $neq: destination
            }
          } : {},
          src: {
            $eq: this._getPermalink()
          }
        }
      },
      onSuccess: this._handleSuccess
    })
  }

}

export default PermalinkField
