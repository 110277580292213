import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  nothing: {
    title: 't(Do Nothing)',
    description: 't(Leave all files and folders as they are)'
  },
  transfer: {
    title: 't(Transfer Items)',
    description: 't(Reassign all files and folders to another user)'
  },
  delete: {
    title: 't(Delete Items)',
    description: 't(Delete all files and folders)'
  }
}

const DriveStrategyToken = ({ value }) => {
  const strategy = strategies[value]
  return (
    <div className="type-token">
      <strong><T text={ strategy.title } /></strong><br />
      <T text={ strategy.description } />
    </div>
  )
}

DriveStrategyToken.propTypes = {
  value: PropTypes.string
}

export default DriveStrategyToken
