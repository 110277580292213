import getReducer from '@admin/utils/get_reducer'

export default getReducer([
  { name: 'items', type: 'array', default: [] },
  { name: 'test_item', type: 'var', default: null },
  { handle: 'ADD_ITEMS', handler: (state, action) => ({
    ...state,
    items: [
      ...state.items,
      action.item
    ],
    test_item: null
  }) }
])