import Edit from '@apps/datasets/admin/views/types/edit'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

class Overview extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    dataset: PropTypes.object,
    type: PropTypes.object
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { type } = this.props
    return {
      audits: `datasets_types/${type.id}`,
      comments: `datasets_types/${type.id}`,
      sections: [
        {
          items: [
            { label: 't(Title)', content: type.title.value },
            { label: 't(API Endpoint)', content: <Button { ...this._getEndpoint() }/> }
          ]
        }
      ]
    }
  }

  _getEndpoint() {
    const { dataset, type } = this.props
    return {
      label: 'View Endpoint',
      link: `${process.env.ADMIN_HOST}/api/datasets/${dataset.title.token}/${type.title.token}`,
      className: 'link'
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview dataset={ props.dataset } type={ props.type } />,
  ...props.dataset.access_type === 'manage' ? {
    tasks: {
      items: [
        { label: 'Edit Type', modal: <Edit dataset={ props.dataset } type={ props.type } /> },
        {
          label: 'Delete Type',
          confirm: `
            Are you sure you want to delete this type? It will also delete
            all related data.
          `,
          request: {
            endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}`,
            method: 'DELETE',
            onFailure: () => {},
            onSuccess: () => {}
          }
        }
      ]
    }
  } : {}
})

export default Panel(null, mapPropsToPanel)
