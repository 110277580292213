import Container from '@admin/components/container'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import { client } from 'braintree-web'
import creditcard from 'credit-card'
import { card } from 'creditcards'
import PropTypes from 'prop-types'
import React from 'react'

class BraintreeCardField extends React.PureComponent {

  static propTypes = {
    errors: PropTypes.object,
    payment: PropTypes.object,
    summary: PropTypes.object,
    token: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onUpdate: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  cvvRef = React.createRef()
  expirationDateRef = React.createRef()
  numberRef = React.createRef()

  state = {
    cvv: '',
    expirationDate: '',
    error: null,
    number: '',
    payment: null
  }

  _handleAuthorize = this._handleAuthorize.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleCVV = this._handleCVV.bind(this)
  _handleExpiration = this._handleExpiration.bind(this)
  _handleNumber = this._handleNumber.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { number, expirationDate, cvv } = this.state
    const icon = this._getIcon()
    return (
      <div className="finance-cardfield">
        <div className="finance-cardfield-icon">
          { icon ?
            <Img src={`/images/payments/${icon}.png`} /> :
            <Icon icon="credit-card-alt" />
          }
        </div>
        <div className="finance-cardfield-number">
          <Input { ...this._getNumber() } />
        </div>
        <div className="finance-cardfield-expiration">
          <Input { ...this._getExpiration() } />
        </div>
        <div className="finance-cardfield-cvv">
          <Input { ...this._getCVV() } />
        </div>
        { (number.length + expirationDate.length + cvv.length) > 0 ?
          <Button { ...this._getClear() } /> :
          <div className="finance-cardfield-clear" />
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { cvv, errors, expirationDate, number, payment } = this.state
    if(!_.isEqual(errors, prevState.errors) && errors) {
      this.props.onValid(null, errors)
    }
    if(!_.isEqual(payment, prevState.payment)) {
      this.props.onValid(payment)
    }
    if(cvv !== prevState.cvv) {
      this.props.onChange(null)
    }
    if(expirationDate !== prevState.expirationDate) {
      this.props.onChange(null)
    }
    if(number !== prevState.number) {
      this.props.onChange(null)
    }
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'finance-cardfield-clear',
      handler: this._handleClear
    }
  }

  _getCVV() {
    const { cvv } = this.state
    return {
      autoComplete: 'cc-cvv',
      ref: this.cvvRef,
      placeholder: 'CVV',
      onChange: this._handleCVV,
      onKeyDown: this._handleKeyDown.bind(this, 'cvv'),
      value: cvv
    }
  }

  _getExpiration() {
    const { expirationDate } = this.state
    return {
      autoComplete: 'cc-exp',
      ref: this.expirationDateRef,
      placeholder: 'MM/YY',
      onChange: this._handleExpiration,
      onKeyDown: this._handleKeyDown.bind(this, 'expirationDate'),
      value: this._getFormattedExpiration(expirationDate)
    }
  }

  _getFormattedExpiration(expirationDate) {
    const numbers = expirationDate.replace(/[^\d]/g, '')
    const month = numbers.substr(0,2)
    const year = numbers.substr(2,2)
    if(year.length > 0) return `${month}/${year}`
    return month
  }

  _getIcon() {
    const { number } = this.state
    const type = creditcard.determineCardType(number, { allowPartial: true })
    if(type === 'VISA') return 'visa'
    if(type === 'MASTERCARD') return 'mastercard'
    if(type === 'AMERICANEXPRESS') return 'amex'
    if(type === 'DISCOVER') return 'discover'
    if(type === 'JCB') return 'jcb'
    return null
  }

  _getNumber() {
    const { number } = this.state
    return {
      autoComplete: 'cc-number',
      ref: this.numberRef,
      placeholder: 'Card Number',
      onChange: this._handleNumber,
      value: card.format(number)
    }
  }

  _handleAuthorize() {
    const { number, expirationDate, cvv } = this.state
    const { token } = this.props
    client.create({
      authorization: token
    }).then(clientInstance => {
      return clientInstance.request({
        endpoint: 'payment_methods/credit_cards',
        method: 'POST',
        data: {
          credit_card: { 
            number, 
            expirationDate, 
            cvv 
          }
        },
        options: {
          validate: true
        }
      })
    }).then(response => {
      const { details, nonce } = response.creditCards[0]
      this.setState({
        payment: {
          card_type: details.cardType === 'American Express' ? 'amex' : details.cardType.toLowerCase(),
          last_four: details.lastFour,
          expiration_month: details.expirationMonth,
          expiration_year: details.expirationYear,
          nonce
        }
      })
    }).catch(err => {
      this.setState({
        error: err.error
      })
    })
  }

  _handleKeyDown(field, e) {
    if(e.which !== 8) return
    if(e.target.value.length > 1) return
    this.setState({ [field]: '' })
    const prev = field === 'cvv' ? 'expirationDate' : 'number'
    this[prev].focus()
  }

  _handleNumber(value) {
    const number = card.parse(value)
    this.setState({ number })
    if(number.length > 13 && card.isValid(number)) {
      this.expirationDateRef.current.focus()
    }
  }

  _handleExpiration(expirationDate) {
    this.setState({ expirationDate })
    if(expirationDate.match(/^\d{2}\/\d{2}$/)) {
      this.cvvRef.current.focus()
    }
  }

  _handleCVV(cvv) {
    this.setState({ cvv })
  }

  _handleClear() {
    this.setState({
      cvv: '',
      expirationDate: '',
      number: '',
      payment: null
    })
  }

  _handleValidate() {
    const { onValid } = this.props
    const { number, expirationDate, cvv } = this.state
    if(!number) return onValid(null, ['Card number is required'])
    if(!expirationDate) return onValid(null, ['Expiration  is required'])
    if(!cvv) return onValid(null, ['CVV is required'])
    if(!creditcard.luhn(number)) {
      return onValid(null, ['Invalid credit card number'])
    }
    const type = creditcard.determineCardType(number)
    if(!creditcard.doesCvvMatchType(cvv, type)) {
      return onValid(null, ['Invalid CVV number for this card type'])
    }
    const parts = expirationDate.match(/(\d{2})\/(\d{2})/)
    if(parts === null) {
      return onValid(null, ['Invalid date. Must be in the format MM/YY'])
    } else if(creditcard.isExpired(parts[1],`20${parts[2]}`)) {
      return onValid(null, ['This date is in the past'])
    }
    this._handleAuthorize()
  }

}

const mapResources = (props, context) => ({
  token: '/api/admin/finance/payments/token'
})

export default Container(mapResources)(BraintreeCardField)
