import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import New from '../banks/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Bank Accounts)',
  access: { rights: { $oneOf: ['finance:view_banks','finance:manage_banks'] } },
  collection: {
    endpoint: '/api/admin/finance/banks',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken  }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/banks/${record.id}`),
    empty: {
      icon: 'university',
      title: 't(No Bank Accounts)',
      text: 't(You have not yet added any bank accounts)',
      buttons: [
        { 
          label: 't(Add Bank Account)', 
          access: { rights: { $oneOf: ['finance:manage_banks'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'invoice'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Add Bank Account)',
    access: { rights: { $oneOf: ['finance:manage_banks'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
