import Tasks from '@admin/components/tasks'
import Stack from '@admin/components/stack'
import Error from '@admin/components/error'
import Connections from './connections'
import PropTypes from 'prop-types'
import SMS from './sms/sms'
import Phone from './phone'
import React from 'react'

class Handset extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    twilio: PropTypes.object
  }

  static propTypes = {
    phone_numbers: PropTypes.array,
    phone_number: PropTypes.object,
    calls: PropTypes.array,
    sms: PropTypes.object,
    error: PropTypes.string,
    onClose: PropTypes.func,
    onPhoneNumber: PropTypes.func
  }

  stackRef = React.createRef()

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    const { calls } = this.props
    return (
      <Error { ...this._getError() }>
        <Tasks>
          <div className="maha-handset-container">
            <div className="maha-handset-container-panel">
              <Stack { ...this._getStack() } />
            </div>
            { calls.length > 0 &&
              <div className="maha-handset-container-panel">
                <Connections { ...this._getConnections() } />
              </div>
            }
          </div>
        </Tasks>
      </Error>
    )
  }

  componentDidMount() {
    const { sms } = this.props
    this._handlePush(Phone, this._getPhone.bind(this), () => {
      if(sms) this._handleSMS()
    })
  }

  componentDidUpdate(prevProps) {
    const { sms } = this.props
    if(!_.isEqual(sms, prevProps.sms) && sms) {
      this._handleSMS()
    }
  }

  _getConnections() {
    const { calls } = this.props
    return {
      calls
    }
  }

  _getError() {
    return {
      isModal: false
    }
  }

  _getPhone() {
    const { phone_number, phone_numbers, onClose, onPhoneNumber } = this.props
    return {
      phone_number,
      phone_numbers,
      onClose,
      onPhoneNumber,
      onPop: this._handlePop,
      onPush: this._handlePush
    }
  }

  _getStack() {
    return {
      display_name: 'handset',
      ref: this.stackRef
    }
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _getSMS() {
    return {
      ...this.props.sms,
      onBack: this._handleSMSBack.bind(this)
    }
  }

  _handleSMSBack() {
    this._handlePop(() => {
      setTimeout(() => {
        this.context.twilio.sms(null)
      }, 250)
    })
  }

  _handleSMS() {
    this._handlePush(SMS, this._getSMS.bind(this))
  }

}

export default Handset
