import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Devices)',
  collection: {
    endpoint: `/api/admin/crm/contacts/${props.contact.id}/devices`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Operating System)', key: 'os', primary: true},
      { label: 't(Browser/Email Client)', key: 'browser' },
      { label: 't(Type)', key: 'device' },
      { label: 't(Last Accessed)', key: 'updated_at', collapsing: true, format: 'datetime' }
    ],
    empty: {
      title: 't(No Devices)',
      text: 't(There are no devices for this contact)',
      icon: 'mobile'
    },
    entity: 'device',
    defaultSort: { key: 'created_at', order: 'desc' }
  }
})

export default Panel(null, mapPropsToPanel)
