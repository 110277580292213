import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    sender: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { sender } = this.props
    const { config } = this.state
    return {
      title: 't(Edit Sender)',
      method: 'PATCH',
      endpoint: `/api/admin/team/senders/${sender.id}/edit`,
      action: `/api/admin/team/senders/${sender.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(From Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(From Email)', type: 'textfield', disabled: true, value: sender.email },
            ...sender.type === 'hosted' ? [
              { label: 't(On Behalf Of)', type: 'segment', fields: [
                { name: 'on_behalf_of', type: 'emailfield' },
                { name: 'verify_on_behalf_of',  type: 'checkbox', prompt: 't(Send a verification email to verify this email address)', disabled: !(config.on_behalf_of && config.on_behalf_of !== sender.on_behalf_of && config.on_behalf_of.length > 0), defaultValue: true }
              ] },
              { label: 't(Reply To)', name: 'reply_to', type: 'emailfield'}
            ] : []
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
