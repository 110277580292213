import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  upload: 't(Upload my own signature)',
  text: 't(Create a signature for me with a cursive font)',
  none: 't(Dont sign my email newsletters)'
}

const EmailSignature = ({ value }) => (
  <div className="token">
    <T text={ service_types[value] } />
  </div>
)

EmailSignature.propTypes = {
  value: PropTypes.string
}

export default EmailSignature
