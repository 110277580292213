import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import Manage from './manage'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  access: { rights: { $oneOf: ['team:manage_roles'] } },
  collection: {
    endpoint: `/api/admin/team/roles/${props.role.id}/users`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: ({ user }) => <UserToken { ...user } /> }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Users)',
      text: 't(You have not assigned any users to this role)',
      button: [
        {
          label: 't(Manage Users)',
          access: { rights: { $oneOf: ['team:manage_roles'] } },
          modal: <Manage role_id={ props.role.id } />
        }
      ]
    },
    entity: 'user'
  },
  tasks: {
    items: [
      {
        label: 't(Manage Users)',
        access: { rights: { $oneOf: ['team:manage_roles'] } },
        modal: <Manage role_id={ props.role.id } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
