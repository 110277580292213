import Form from '@admin/components/form'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class TriggerField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    trigger: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.trigger) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { trigger } = this.state
    if(!_.isEqual(trigger, prevState.trigger)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { trigger } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'delay', text: 't(Show after a delay)' },
              { value: 'scroll', text: 't(Show after page is scrolled)' },
              { value: 'intersection', text: 't(Show after element becomes visible)' }
            ], defualtValue: 'delay', value: trigger.type },
            { name: 'delay', show: trigger.type === 'delay', type: WebRange, units: [
              { name: 'ms', min: 0, max: 5000, step: 50 }
            ], defaultValue: '250ms', value: trigger.delay },
            { name: 'threshold', show: trigger.type === 'scroll', type: WebRange, units: [
              { name: '%', min: 0, max: 100, step: 1 }
            ], defaultValue: '50%', value: trigger.threshold },
            { name: 'target', show: trigger.type === 'intersection', type: 'textfield', value: trigger.target }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _handleChange() {
    const { trigger } = this.state
    this.props.onChange(trigger)
  }

  _handleSet(trigger) {
    this.setState({ trigger })
  }

}

export default TriggerField
