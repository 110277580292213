import Attachments from '@admin/components/attachments'
import PropTypes from 'prop-types'
import React from 'react'

class File extends React.PureComponent {

  static propTypes = {
    source: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return <Attachments { ...this._getAttachments() } />
  }

  _getAttachments() {
    const { onBack, onCancel } = this.props
    return {
      allow: {
        content_types: ['application/vnd.google-apps.spreadsheet'],
        extensions: ['csv','tsv','xls','xlsx'],
        types: ['files']
      },
      multiple: false,
      onBack,
      onCancel,
      doneText: 't(Next)',
      onDone: this._handleDone
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone(assets) {
    this.props.onDone(assets[0])
  }

}

export default File
