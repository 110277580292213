import ProgramForm from '@apps/crm/admin/components/programform'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

class Lists extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    lists: PropTypes.array
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { admin } = this.context
    const { lists } = this.props
    const programs = this._getPrograms()
    return {
      sections: [
        { title: 't(Lists)' },
        ...admin.team.has_programs ? programs.sort((a,b) => a.title < b.title ? -1 : 1).map((program) => ({
          subtitle: <ProgramToken { ...program } />,
          padded: false,
          items: program.lists.map(this._getList.bind(this))
        })) : [{
          items: lists.map(this._getList.bind(this))
        }]
      ]
    }
  }

  _getList(list) {
    return (
      { component: (
        <div className="crm-contact-properties-item" key={`list_${list.id}`}>
          <div className="crm-contact-properties-item-icon">
            <Icon icon={ list.is_subscribed ? 'check' : 'times' } />
          </div>
          <div className="crm-contact-properties-item-details">
            { list.title }
          </div>
          <div className="crm-contact-properties-item-extra">
            { list.type.toUpperCase() }
          </div>
        </div>
      )}
    )
  }

  _getPrograms() {
    const { lists } = this.props
    const sections = lists.reduce((sections, list, index) => ({
      ...sections,
      [list.program.id]: {
        ...list.program,
        lists: [
          ...sections[list.program.id] ? sections[list.program.id].lists : [],
          list
        ]
      }
    }), {})
    return Object.values(sections).sort((a,b) => {
      return a.title > b.title ? 1 : -1
    })
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Lists)',
  panel: <Lists contact={ props.contact } lists={ props.lists } />,
  tasks: {
    items: [
      { 
        label: 't(Manage Lists)', 
        access: { rights: { $oneOf: ['crm:manage_contacts'] } },
        modal: <ProgramForm form={ Edit } props={{ contact: props.contact }} />
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  lists: `/api/admin/crm/contacts/${props.contact.id}/lists`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
