import Token from './token'
import Form from './form'

export default {
  icon: 'bell',
  label: 'Send Notification',
  type: 'administrative',
  action: 'notification',
  form: Form,
  token: Token
}
