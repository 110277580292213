import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class SEO extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(SEO - Search Engine Optimization)',
              placeholder: 't(Leave values blank for Truevail to create for you)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Title)',
                  instructions: 't(Should be a combination of Name/Company/Job Title and/or Business Location and/or Main Expertise/Service. The page title is the first line that shows up in a search result and contributes greatly to SEO)',
                  name: 'website_seo_title',
                  type: 'textarea',
                  rows: 1,
                  maxLength: 60,
                  placeholder: 't(Example: John Smith | Travel Advisor in San Diego | Safari Planner)',
                  defaultValue: config.website_seo_title 
                },
                { 
                  label: 't(Meta Description)',
                  instructions: 't(This sections shows up in search results as the description of your site. Keywords placed here do not directly correlate to SEO rankings, but a good page/company description may create more clickthroughs to your site from search engines)',
                  name: 'website_seo_meta_description',
                  type: 'textarea',
                  rows: 4,
                  maxLength: 160,
                  placeholder: 't(Example: Enjoy the trip of a lifetime without an ounce of traveler\'s anxiety. John Smith Travel creates unforgettable travel experiences in every corner of the globe)',
                  defaultValue: config.website_seo_meta_description 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default SEO
