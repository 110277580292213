import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Renewals)',
  collection: {
    endpoint: `/api/admin/domains/domains/${props.domain.id}/renewals`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Type)', key: 'type', primary: true, format: (renewal) => (
        <div className="token">{ renewal.type.toUpperCase() }</div>
      ) },
      { label: 't(Renewed On)', key: 'renewed_on', format: 'date', collapsing: true },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken  }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'refresh',
      title: 't(No Renewals)',
      text: 't(There are no renewals for this domain)'
    },
    entity: 'renewal'
  }
})

export default Panel(null, mapPropsToPanel)
