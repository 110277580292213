import { useMemo } from 'react'

export const spin = (state, props) => {
  const { rotation } = state
  return useMemo(() => {
    return  ((rotation.z % 360) + 360) % 360
  }, [rotation])
}

export const zoomScale = (state, props) => {
  return useMemo(() => 10, [])
}

export const rotated = (state, props) => {
  const { original, spin } = state
  return useMemo(() => {
    return original ? ({
      w: spin % 180 === 90 ? original.h : original.w,
      h: spin % 180 === 90 ? original.w : original.h
    }) : null
  }, [original, spin])
}

export const padded = (state, props) => {
  const { frame } = state
  return useMemo(() => {
    return frame ? ({
      w: frame.w - 30,
      h: frame.h - 30
    }) : null
  }, [frame])
}


export const viewport = (state, props) => {
  const { customRatio, frame,  original, spin } = state
  const { ratio } = props
  return useMemo(() => {
    if(!original || !frame) return null
    const vratio = ratio || customRatio || original.w / original.h
    const viewport = {
      w: vratio > 1 ? frame.w : vratio * frame.h,
      h: vratio > 1 ? frame.w / vratio : frame.h
    }
    if(viewport.h > frame.h) {
      viewport.w = (viewport.w / viewport.h) * frame.h
      viewport.h = frame.h
    }
    return {
      ...viewport,
      rw: spin % 180 === 0 ? viewport.w : viewport.h,
      rh: spin % 180 === 0 ? viewport.h : viewport.w
    }
  }, [customRatio, frame,  original, ratio, spin])
}

export const scaled = (state, props) => {
  const { original, viewport, spin } = state
  return useMemo(() => {
    if(!original || !viewport) return null
    const scaled = {
      w: viewport.w > viewport.h ? viewport.w : (original.w / original.h) * viewport.h,
      h: viewport.w > viewport.h ? (original.h / original.w) * viewport.w : viewport.h
    }
    if(scaled.h < viewport.h) {
      scaled.w = (scaled.w * viewport.h) / scaled.h
      scaled.h = viewport.h
    }
    return {
      ...scaled,
      rw: scaled.w,
      rh: scaled.h,
      ...spin % 180 === 90 ? {
        rw: viewport.w > viewport.h ? (original.w / original.h) * viewport.w : viewport.h,
        rh: viewport.w > viewport.h ? viewport.w : (original.h / original.w) * viewport.h
      } : {}
    }
  }, [original, viewport, spin])
}

export const sprite = (state, props) => {
  const { scaled, viewport } = state
  return useMemo(() => {
    if(!scaled) return null
    return {
      l: 0 - ((scaled.rw - viewport.w) / 2),
      t: 0 - ((scaled.rh - viewport.h) / 2)
    }
  }, [scaled, viewport])
}

export const scalingFactor = (state, props) => {
  const { zoom, zoomScale } = state
  return useMemo(() => {
    return 1 + (zoom / zoomScale)
  }, [zoom, zoomScale])
}

export const zoomed = (state, props) => {
  const { scaled, scalingFactor, spin } = state
  return useMemo(() => {
    if(!scaled) return null
    const zoomed = {
      w: scaled.rw * scalingFactor,
      h: scaled.rh * scalingFactor
    }
    return {
      ...zoomed,
      uw: spin % 180 === 90 ? zoomed.h : zoomed.w,
      uh: spin % 180 === 90 ? zoomed.w : zoomed.h
    }
  }, [scaled, scalingFactor, spin])
}

export const crop = (state, props) => {
  const { rotated, offset, viewport, zoomed } = state
  return useMemo(() => {
    return zoomed ? ({
      l: Math.floor(((zoomed.uw - viewport.w - (2 * offset.x)) * rotated.w) / (2 * zoomed.uw)),
      t: Math.floor(((zoomed.uh - viewport.h - (2 * offset.y)) * rotated.h) / (2 * zoomed.uh)),
      w: Math.floor((viewport.w * rotated.w) / zoomed.uw),
      h: Math.floor((viewport.h * rotated.h) / zoomed.uh)
    }) : null
  }, [rotated, offset, viewport, zoomed])
}
