import NoticesShow from './providers/show/notices/show'
import AccountsShow from './accounts/show'
import PlatformShow from './platform/show'
import ProvidersShow from './providers/show'
import DomainsShow from './domains/show'
import ModulesShow from './modules/show'
import AssetsShow from './assets/show'
import TeamsShow from './teams/show'
import BankShow from './banks/show'
import AppsShow from './apps/show'

const routes = [
  { path: '/:view', component: PlatformShow },
  { path: '/accounts/:id', component: AccountsShow },
  { path: '/apps/:code/:view', component: AppsShow },
  { path: '/assets/:id', component: AssetsShow },
  { path: '/banks/:id', component: BankShow },
  { path: '/domains/:id', component: DomainsShow },
  { path: '/providers/:provider_id/notices/:id/:view', component: NoticesShow },
  { path: '/providers/:id/:view', component: ProvidersShow },
  { path: '/modules/:id/:view', component: ModulesShow },
  { path: '/teams/:id/:view', component: TeamsShow }
]

export default routes
