import TwilioNumberToken from '@apps/phone/admin/tokens/twilio_number'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Number extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    return {
      autofocus: true,
      endpoint: '/api/admin/phone/numbers/lookup',
      format: TwilioNumberToken,
      prompt: 'Enter area code',
      label: 'number',
      onChange: this._handleChoose,
      valueKey: null
    }
  }

  _handleChoose(number) {
    this.props.onNext({
      number: number.phoneNumber
    })
  }

}

export default Number
