import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import RadiusField from '../radiusfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import WebRange from '../webrange'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const Range = (props) => <ResponsiveField { ...props } component={ WebRange } />
const Spacing = (props) => <ResponsiveField { ...props } component={ LinkedField } />

class SliderField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    slider: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.slider) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { slider } = this.state
    if(!_.isEqual(slider, prevState.slider)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { slider } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'loop', type: 'checkbox', prompt: 't(Advance slides in an infinite loop)', defaultValue: true, value: slider.loop },
            { name: 'autoplay', type: 'checkbox', prompt: 't(Automatically advance the slider)', defaultValue: true, value: slider.autoplay },
            { label: 't(Slide Duration)', name: 'duration', type: Range, units: [
              { name: 'ms', min: 1000, max: 5000, step: 50 }
            ], nullValues: ['0ms'], defaultValue: '2000ms', include: slider.autoplay === true, value: slider.duration }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { slider } = this.state
    this.props.onChange(slider)
  }

  _handleSet(slider) {
    this.setState({
      slider: {
        loop: true,
        autoplay: true,
        duration: '2000ms',
        ...slider || {}
      }
    })
  }

  _handleUpdate(slider) {
    this.setState({ slider })
  }

}

export default SliderField
