import PhoneNumbers from './phone_numbers'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Tabs extends React.Component {

  static propTypes = {
    phone_numbers: PropTypes.array,
    phone_number: PropTypes.object,
    tabs: PropTypes.array,
    onPhoneNumber: PropTypes.func
  }

  state = {
    selected: 0
  }

  render() {
    const { selected } = this.state
    const { tabs } = this.props
    const tab = tabs[selected]
    return (
      <div className="maha-handset-client">
        <div className="maha-handset-client-header">
          <PhoneNumbers { ...this._getPhoneNumbers() } />
        </div>
        <div className="maha-handset-client-body">
          <tab.component { ...this._getProps() } />
        </div>
        <div className="maha-handset-client-footer">
          { tabs.map((tab, index) =>(
            <div { ...this._getTab(index) } key={`tab_${index}`}>
              { tab.icon &&
                <Icon icon={  tab.icon } />
              }
              { (tab.count !== undefined && tab.count > 0) &&
                <div className="maha-handset-client-footer-item-count">
                  { tab.count }
                </div>
              }
              <span><T text={ tab.label } /></span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  _getTab(index) {
    const { selected } = this.state
    const classes = ['maha-handset-client-footer-item']
    if(index === selected) classes.push('selected')
    return {
      className: classes.join(' '),
      onClick: this._handleSelect.bind(this, index)
    }
  }

  _getProps(index) {
    const { selected } = this.state
    const { props } = this.props.tabs[selected]
    if(_.isFunction(props)) return props()
    if(_.isPlainObject(props)) return props
    return {}
  }

  _getPhoneNumbers() {
    const { phone_numbers, phone_number, onPhoneNumber } = this.props
    return {
      phone_numbers,
      phone_number,
      onChange: onPhoneNumber
    }
  }

  _handleSelect(selected) {
    return this.setState({ selected })
  }

}

export default Tabs
