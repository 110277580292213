import PropTypes from 'prop-types'
import React from 'react'

const ChannelToken = ({ channel }) => (
  <div className="maha-token">
    <div className="maha-token-details">
      <strong>{ channel.title }</strong><br />
      <span>{ channel.description }</span>
    </div>
  </div>
)

ChannelToken.propTypes = {
  channel: PropTypes.object
}

export default ChannelToken
