import CallToken from '@apps/phone/admin/tokens/call'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Calls)',
  collection: {
    endpoint: '/api/admin/phone/calls',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Call)', key: 'from_number.formatted', sort: 'from', primary: true, format: (call) => (
        <CallToken call={ call } perspective="contact" />
      ) }
    ],
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: props.callable ? {
      icon: 'phone',
      title: 't(No Calls)',
      text: 't(There have not yet been any calls with this contact)',
      buttons: [{
        label: 't(Call Contact)',
        modal: {
          component: <New contact={ props.contact } />,
          options: {
            width: 375,
            height: 667
          }
        }
      }]
    } : {
      icon: 'phone',
      title: 't(No Phone Numbers)',
      text: 't(There are no phone numbers for this contact)'
    },
    entity: 'call',
    onClick: (record) => context.router.push(`/phone/calls/${record.id}`)
  },
  task: props.callable ? {
    icon: 'plus',
    modal: <New contact={ props.contact } />
  } : null
})

export default Panel(null, mapPropsToPanel)
