import Avatar from '@admin/components/avatar'
import PropTypes from 'prop-types'
import React from 'react'

const AdvisorUserToken = (user) => (
  <div className="user-token">
    <div className="user-token-avatar">
      <Avatar user={ user } width="40" presence={ user.presence } />
    </div>
    <div className="user-token-details">
      <div className="user-token-details-inner">
        { user.full_name } { user.is_blocked && 
          <span className="alert">Blocked</span>
        }<br />
        <span>{ user.team.title }</span> 
      </div>
    </div>
  </div>
)

AdvisorUserToken.propTypes = {
  full_name: PropTypes.string,
  id: PropTypes.number,
  is_active: PropTypes.bool,
  presence: PropTypes.bool,
  user_id: PropTypes.number
}

export default AdvisorUserToken
