import ValueField from '@apps/domains/admin/components/valuefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    domain: PropTypes.object,
    record: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { domain, record } = this.props
    const { config } = this.state
    return {
      title: 't(Edit Record)',
      method: 'PATCH',
      endpoint: `/api/admin/domains/domains/${domain.id}/records/${record.id}/edit`,
      action: `/api/admin/domains/domains/${domain.id}/records/${record.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Host)', type: 'textfield', suffix: `.${domain.name}`, value: record.name, disabled: true },
            { label: 't(Type)', name: 'type', type: 'dropdown', options: [
              { value: 'A', text: 'A - Route traffic to an IPv4 address' },
              { value: 'AAAA', text: 'AAAA - Route traffic to an IPv6 address' },
              { value: 'CNAME', text: 'CNAME - Route traffic to another domain name' },
              { value: 'MX', text: 'MX - Specify one or more mail servers' },
              { value: 'TXT', text: 'TXT - Used to verify email senders and for application specific values' }
            ], value: record.type, disabled: true },
            { label: 't(TTL)', name: 'ttl', type: 'dropdown', options: [
              { value: 5 * 60, text: '5 minutes' },
              { value: 15 * 60, text: '15 minutes' },
              { value: 30 * 60, text: '30 minutes' },
              { value: 60 * 60, text: '1 hour' },
              { value: 24 * 60 * 60, text: '1 day' }
            ], defaultValue: 300 },
            { label: 't(Values)', name: 'values', type: ValueField, recordType: config.type, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
