import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Transition extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    deal: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { deal } = this.props
    return {
      title: 't(Update Stage)',
      action: `/api/admin/sales/deals/${deal.id}/transition`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'from_stage_id', type: 'hidden', value: deal.stage.id },
            { label: 't(Stage)', name: 'to_stage_id', type: 'dropdown',  endpoint: `/api/admin/sales/pipelines/${deal.pipeline.id}/stages`, valueKey: 'id', textKey: 'title', defaultValue: deal.stage.id, required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Transition
