import DNSTable from '@admin/components/dnstable'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ domain, website }) => {

  const site = {
    label: website.title,
    className: 'link',
    route: `/admin/websites/${website.id}`
  }

  const dnstable = {
    instructions: 't(In order for the website to function properly under this domain, you must add and maintain the following DNS records):',
    is_hosted: domain.is_hosted,
    records: domain.dns,
    last_verified_at: domain.last_verified_at,
    check: {
      request: {
        endpoint: `/api/admin/websites/websites/${website.id}/domains/${domain.id}/verify`,
        method: 'PATCH',
        onFailure: () => context.flash.set('error', 't(Unable to verify dns records)')
      }
    },
    publish: {
      request: {
        endpoint: `/api/admin/websites/websites/${website.id}/domains/${domain.id}/records`,
        method: 'PATCH',
        onFailure: () => context.flash.set('error', 't(Unable to publish dns records)')
      }
    }
  }

  const details = {
    audits: `websites_domains/${website.id}`,
    comments: `websites_domains/${website.id}`,
    sections: [
      {
        title: 't(Domain Details)',
        items: [
          { label: 't(Hostname)', content: domain.hostname },
          { label: 't(Website)', content: <Button { ...site } /> }
        ]
      },
      ...dnstable ? [
        {
          title: 't(DNS)',
          items: [
            { padded: true, component: <DNSTable { ...dnstable } /> }
          ]
        }
      ] : []
    ]
  }

  if(domain.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This domain has not yet been verified! You will not be able to access your website with this domain until you have completed the verification process. Although it is usually much faster, it can take up to 72 hours for the process to complete.)' }
  } else if(domain.status === 'unverified') {
    details.alert = { color: 'red', message: 't(Something has changed with your DNS settings and this domain is no longer verified. Please check that the DNS record below still exist)' }
  } else if(domain.status === 'verified') {
    details.alert = { color: 'green', message: 't(This domain has been verified and will route to your website)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  domain: PropTypes.object,
  website: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Domain)',
  panel: <Overview website={ props.website } domain={ props.domain } />
})

export default Panel(null, mapPropsToPanel)
