import { useRouterContext } from '@admin/components/router'
import ModalPanel from '@admin/components/modal_panel'
import Container from '@admin/components/container'
import Loader from '@admin/components/loader'
import Page from './page'
import React from 'react'

export { PageContext, usePageContext } from './page'

const LoaderComponent = (props) => {

  const { router } = useRouterContext()

  const panel = {
    leftItems: [
      { icon: 'chevron-left', handler: router.goBack }
    ],
    title: ''
  }
  return (
    <ModalPanel { ...panel }>
      <Loader />
    </ModalPanel>
  )
}

const PageCreator = (mapResourcesToPanel, mapPropsToPanel) => {

  return Container(mapResourcesToPanel, mapPropsToPanel)(Page, LoaderComponent)

}

export default PageCreator
