import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class RenameFolder extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { item } = this.props
    return {
      title: 't(Rename Folder)',
      method: 'PATCH',
      endpoint: `/api/admin/drive/folders/${item.code}`,
      action: `/api/admin/drive/folders/${item.code}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'hidden', name: 'action', value: 'rename' },
            { label: 't(Label)', name: 'label', type: 'textfield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default RenameFolder
