import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ vendor, integration }) => {

  const details = {
    audits: `finance_vendors/${vendor.id}`,
    comments: `finance_vendors/${vendor.id}`,
    sections: [
      {
        title: 't(Vendor Overview)',
        items: [
          { label: 't(Name)', content: vendor.name }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  integration: PropTypes.string,
  vendor: PropTypes.object
}

export default Overview
