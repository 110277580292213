const badges = [
  { 
    title: 't(Calendar)', 
    weight: 7.3, 
    access: {
      rights: { $allOf: ['calendar:access_app'] }
    },
    icon: 'calendar', 
    route: '/admin/calendar', 
    visible: ['desktop','tablet'], 
    tooltip: 'Calendar' 
  }
]

export default badges
