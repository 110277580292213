import FrequnecyField from '@apps/subscriptions/admin/components/frequencyfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    service: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { service } = this.props
    return {
      title: 't(Edit Service)',
      method: 'PATCH',
      endpoint: `/api/admin/subscriptions/services/${service.id}/edit`,
      action: `/api/admin/subscriptions/services/${service.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Renewal Frequency)', name: 'frequency', type: FrequnecyField, required: true },
            ...service.model === 'flat' ? [
              { label: 't(Renewal Timing)', name: 'timing', type: 'radiogroup', options: [
                { value: 'end', text: 't(Charge at the end of the billing cycle)' },
                { value: 'beginning', text: 't(Charge at the beginning of the billing cycle)' }
              ], required: true  }
            ] : []
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(service) {
    this.context.modal.close()
  }

}

export default Edit
