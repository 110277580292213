import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Layout from './layout'
import React from 'react'

class Truevail extends React.Component {

  static propTypes = {
    doneText: PropTypes.any,
    files: PropTypes.array,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    const { doneText, files } = this.props
    return {
      title: 'Truevail Images',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: files.length > 0 ? [
        { label: doneText, handler: this._handleNext }
      ] : []
    }
  }

  _getSearch() {
    const { files } = this.props
    return {
      endpoint: '/api/admin/truevail/images',
      layout: Layout,
      label: 'photo',
      onChange: this._handleChoose,
      valueKey: null,
      props: {
        files
      }
    }
  }

  _handleChoose(image) {
    const { asset } = image
    this.props.onAdd({
      id: asset.id,
      source_id: 'truevail',
      name: asset.original_file_name,
      service: 'truevail',
      content_type: asset.content_type,
      asset,
      thumbnail: asset.content_type.match(/(jpeg|jpg|gif|png)/) ? asset.signed_url : null,
      status: 'imported'
    })
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Truevail
