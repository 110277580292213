import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Active Carts)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/carts`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 'Cart', key: 'code' },
      { label: 'Expires', key: 'expires_at', format: 'from' },
      { label: 'Items', key: 'items_count', collapsing: true }
    ],
    empty: {
      icon: 'shopping-cart',
      title: 't(No Carts)',
      text: 't(There are no carts)'
    },
    entity: 'cart',
    defaultFilter: {
      status: {
        $eq: 'active'
      }
    },
    defaultSort: { key: 'updated_at', order: 'desc' },
    recordTasks: (record) => [
      {
        label: 't(Delete Cart)',
        show: record.status !== 'ordered',
        confirm: 't(Are you sure you want to delete this cart?)',
        request: {
          endpoint: `/api/admin/stores/stores/${props.store.id}/carts/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete cart)'),
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted cart)')
          }
        }
      }
    ],
    onClick: (record) => context.router.push(`/stores/stores/${props.store.id}/carts/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
