import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object,
    tags: PropTypes.array
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact, program } = this.props
    const tags = this._getTags()
    return {
      title: 't(Manage Tags)',
      method: 'PATCH',
      action: `/api/admin/crm/contacts/${contact.id}/tags`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program.id },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags', filter: { program_id: { $eq: program.id } }, defaultValue: tags }
          ]
        }
      ]
    }
  }

  _getTags() {
    const { program, tags } = this.props
    return tags.filter(tag => {
      return tag.program.id === program.id
    }).map(tag => {
      return tag.text
    })
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  tags: `/api/admin/crm/contacts/${props.contact.id}/tags`
})

export default Container(mapResources)(Edit)
