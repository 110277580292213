import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Email Template)',
      action: '/api/admin/truevail/admin/email_templates',
      method: 'POST',
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getSteps() {
    return [
      { label: 't(Type)', component: Type },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(email_template) {
    this.context.router.push(`/admin/truevail/admin/email_templates/${email_template.id}`)
    this.context.modal.close()
  }

}

export default New
