import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor } = this.props.props
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)', 
          color: 'blue', 
          basic: true, 
          disabled: true, 
          handler: this._handleBack 
        },
        { 
          label: 't(Next)', 
          color: 'blue', 
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Business Name)',
              instructions: 't(Enter the official name of your business as recognized by your clients)',
              name: 'identity_business_name',
              type: 'textfield',
              required: true,
              defaultValue: config.identity_business_name || advisor.organization 
            },
            { 
              label: 't(Agency / Host Agency)',
              instructions: 't(Enter the name of your host agency if applicable)',
              name: 'identity_host_agency',
              type: 'textfield',
              defaultValue: config.identity_host_agency 
            },
            { 
              label: 't(Consortia)',
              instructions: 't(Indicate any travel consortia your business is affiliated with, if any)',
              name: 'identity_consortia',
              type: 'textfield',
              defaultValue: config.identity_consortia 
            }
          ]
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext } = this.props
    onNext({
      ...formdata,
      ...config
    })
  }

}

export default Details
