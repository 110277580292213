import PhoneForm from './phone_form'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneFormContainer extends React.PureComponent {

  static propTypes = {
    contact_ids: PropTypes.array,
    event: PropTypes.object,
    filter: PropTypes.object,
    form: PropTypes.object,
    list: PropTypes.object,
    phone_form: PropTypes.any,
    phone_number_id: PropTypes.number,
    phone_numbers: PropTypes.array,
    program: PropTypes.object,
    program_id: PropTypes.number,
    pipeline: PropTypes.number,
    props: PropTypes.object,
    service: PropTypes.object,
    store: PropTypes.object,
    user: PropTypes.object
  }

  render() {
    return <PhoneForm { ...this._getPhoneForm()} />
  }

  _getPhoneForm() {
    return {
      ...this.props,
      program: this._getProgram()
    }
  }

  _getProgram() {
    const { event, form, list, pipeline, program, service, store } = this.props.props
    if(event) return event.program
    if(form) return form.program
    if(list) return list.program
    if(pipeline) return pipeline.program
    if(program) return program
    if(service) return service.program
    if(store) return store.program
    return null
  }

}

export default PhoneFormContainer
