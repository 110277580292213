export const INITIAL_STATE = {
  profile: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'AUTHORIZED':
    return {
      ...state,
      profile: action.profile
    }

  default:
    return state

  }

}

export default reducer
