import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const options = {
  none: { title: 't(Do Nothing)', description: 't(Please do not send me any notifications via email)' },
  ondemand: { title: 't(On Demand)', description: 't(Send me an email whenever I miss a notification)' },
  digest: { title: 't(Daily Digest)', description: 't(Send me a daily email with all of my missed notifcations from the previous day)' }
}

const NotificationMethodToken = ({ value }) => (
  <div className="token">
    <strong><T text={ options[value].title } /><br /></strong>
    <T text={ options[value].description } />
  </div>
)

NotificationMethodToken.propTypes = {
  value: PropTypes.string
}

export default NotificationMethodToken
