import InputTokens from '@admin/components/input_tokens'
import Assignment from '@admin/components/assignment'
import Container from '@admin/components/container'
import AssigneeToken from '@admin/tokens/assignee'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class AssignmentField extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    assignees: PropTypes.array,
    defaultValue: PropTypes.array,
    placeholder: PropTypes.string,
    prompt: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    prompt: 't(Assign Access)'
  }

  inputRef = React.createRef()

  state = {
    assigned: null,
    focused: false
  }

  _handleAssign = this._handleAssign.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleBlured = this._handleBlured.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyPressed = this._handleKeyPressed.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { placeholder, prompt } = this.props
    const { assigned } = this.state
    if(assigned === null) return null
    const assignees = this._getAssignees()
    return (
      <div { ...this._getAssignmentField() }>
        <div className="maha-input-field">
          { assignees.length > 0 ?
            <InputTokens { ...this._getInputTokens(assignees) } /> :
            <div className="maha-input-placeholder">
              <T text={ placeholder || prompt } />
            </div>          
          }
        </div>
        { assigned.length > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { assigned } = this.state
    const { value } = this.props
    if(!_.isEqual(assigned, prevState.assigned)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAssignees() {
    const { assignees } = this.props
    return this.state.assigned.map(({ grouping_id, user_id }) => ({
      ...grouping_id ? assignees.find(assignee => assignee.grouping_id === grouping_id) : {},
      ...user_id ? assignees.find(assignee => assignee.user_id === user_id) : {}
    }))
  }

  _getAssignment() {
    const assignees = this._getAssignees()
    return {
      title: 't(Assign Users)',
      bodyFormat: (assigned) => ({
        access: assigned.map(assignee => ({
          grouping_id: assignee.grouping_id,
          user_id: assignee.user_id,
          type: assignee.type
        }))
      }),
      assigneeFormat: (assignee) => <AssigneeToken { ...assignee } />,
      defaultAssigned: assignees,
      unassignedEndpoint: {
        endpoint: '/api/admin/assignees',
        query: {
          $filter: {
            is_active: {
              $eq: true
            }
          }
        }
      },
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.full_name.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          if(assignment.grouping_id && assignment.grouping_id === assignee.grouping_id) return true
          if(assignment.user_id && assignment.user_id === assignee.user_id) return true
          return false
        }) === undefined
      },
      onBack: this._handleBack,
      onDone: this._handleDone

    }
  }

  _getAssignmentField() {
    const { tabIndex } = this.props
    return {
      ref: this.inputRef,
      className: this._getClass(),
      tabIndex,
      onBlur: this._handleBlured,
      onClick: this._handleAssign,
      onFocus: this._handleFocused,
      onKeyPress: this._handleKeyPressed
    }
  }

  _getClass() {
    const { focused } = this.state
    const classes = ['maha-input','assignmentfield']
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInputTokens(assignees) {
    return {
      tokens: assignees,
      textKey: 'full_name',
      onRemove: this._handleRemove
    }
  }

  _handleAssign() {
    this.context.form.push(Assignment, this._getAssignment.bind(this))
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { assigned } = this.state
    this.props.onChange(assigned)
  }

  _handleClear() {
    this._handleUpdate([])
  }

  _handleDone(assigned) {
    this.setState({ assigned })
    this.context.form.pop()
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyPressed(e) {
    const { onKeyPress } = this.props
    if(onKeyPress) onKeyPress(e)
    if(e.key !== 'Enter') return
    this._handleAssign()
  }

  _handleRemove(assignee, index) {
    const { assigned } = this.state
    this.setState({ 
      assigned: assigned.filter((assignee, i) => {
        return i !== index
      }) 
    })
  }

  _handleSet(assigned) {
    this.setState({ 
      assigned: assigned || [] 
    })
  }

  _handleUpdate(assigned) {
    this.setState({ assigned })
  }

}

const mapResources = (props, context) => ({
  assignees: {
    endpoint: '/api/admin/assignees',
    query: {
      $filter: {
        is_active: {
          $eq: true
        }
      }
    }
  }
})

export default Container(mapResources)(AssignmentField)
