import AppsField from '@apps/platform/admin/components/appsfield'
import ServiceToken from '@admin/tokens/service'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  state = {
    data: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { admin } = this.context
    return {
      title: 't(New Team)',
      method: 'POST',
      action: '/api/admin/platform/teams',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Subdomain)', name: 'subdomain', type: 'textfield', required: true },
            { label: 't(Provider)', name: 'provider_id', type: 'dropdown', entity: 'user', endpoint: '/api/admin/providers', valueKey: 'id', textKey: 'title', required: true },
            { label: 't(Domain)', name: 'domain_id', type: 'dropdown', entity: 'user', endpoint: '/api/admin/domains', valueKey: 'id', textKey: 'name', required: true },
            { label: 't(Payment Processor)', name: 'payment_processor', type: 'dropdown', options: ['stripe','braintree'], required: true, defaultValue: 'stripe', format: ServiceToken },
            { label: 't(Administrative User)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', required: true, options: [
                { value: 'self', text: `Assign to ${admin.user.full_name}` },
                { value: 'account', text: 't(Assign to existing account)' },
                { value: 'new', text: 't(Create and assign to a new account)' }
              ], defaultValue: 'self' },
              ...this._getStrategy()
            ] },
            { label: 'Apps', name: 'app_ids', type: AppsField }
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { data } = this.state
    if(data.strategy === 'account') {
      return [
        { label: 't(Account)', name: 'account_id', type: 'lookup', required: true, endpoint: '/api/admin/platform/accounts', valueKey: 'id', textKey: 'rfc822' }
      ]
    }
    if(data.strategy === 'new') {
      return [
        { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
        { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
        { label: 't(Email)', name: 'email', type: 'textfield', required: true }
      ]
    }
    return []
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSuccess(result) {
    this.context.router.push(`/platform/teams/${result.id}`)
    this.context.modal.close()
  }

}

export default New
