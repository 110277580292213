import Panel from '@admin/components/panel'
import Logo from '@admin/components/logo'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Registrations)',
  collection: {
    endpoint: '/api/admin/events/registrations',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Event)', key: 'form.title', primary: true, format: (registration) => (
        <div className="event-token">
          <div className="event-token-logo">
            <Logo team={ registration.event.program } width="24" />
          </div>
          <div className="event-token-label">
            <div className="token-link">
              { registration.event.title }
            </div>
          </div>
        </div>
      ) },
      { label: 't(Date)', key: 'created_at', collapsing: true, format: 'date' },
      { label: 't(Tickets)', key: 'tickets_count', collapsing: true, align: 'right' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' }
    ],
    empty: {
      title: 't(No Registrations)',
      text: 't(There are no registrations for this contact)',
      icon: 'check'
    },
    entity: 'registrations',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/events/events/${record.event.id}/registrations/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
