import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Segments)',
  collection: {
    endpoint: `/api/admin/truevail/admin/domains/${props.domain.id}/domain_segments`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment' }
    ],
    defaultSort: { key: 'segment', order: 'asc' },
    empty: {
      icon: 'sitemap',
      title: 't(No Domain Segments)',
      text: 't(There are no domain segments)'
    },
    entity: 'domain segment',
    onClick: (record) => context.router.push(`/admin/truevail/admin/domain_segments/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
