import PropTypes from 'prop-types'
import React from 'react'

class Options extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    endpoint: PropTypes.string,
    options: PropTypes.array,
    filter: PropTypes.object,
    query: PropTypes.object,
    sort: PropTypes.string
  }

  state = {
    options: null
  }

  render() {
    const { children } = this.props
    if(!this.state.options) return null
    return React.Children.map(children, child => {
      return React.cloneElement(child, this._getProps())
    })
  }

  componentDidMount() {
    const { options, endpoint } = this.props
    if(endpoint) return this._handleFetch()
    return this._handleSet(options || [])
  }

  componentDidUpdate(prevProps) {
    const { endpoint, options } = this.props
    if(endpoint !== prevProps.endpoint) {
      this._handleFetch()
    }
    if(!_.isEqual(options, prevProps.options)) {
      this._handleSet(options)
    }
  }

  _getOptions() {
    return this.state.options.map(option => {
      return _.isString(option) ? { value: option, text: option } : option
    })
  }

  _getProps() {
    const options = this._getOptions()
    return { 
      ..._.omit(this.props, ['children','endpoint','filter','options','query','sort']),
      options
    }
  }

  _getQuery() {
    const { filter, query, sort } = this.props
    return (filter || query || sort) ? {
      ...query ? query : {},
      ...filter ? { $filter: filter} : {},
      ...sort ?  { $sort: sort } : {}
    } : null
  }

  _handleFetch() {
    const { endpoint } = this.props
    const query = this._getQuery()
    this.context.network.request({
      endpoint,
      method: 'GET',
      ...query ? { query } : {},
      onSuccess: ({ data }) => {
        this._handleSet(data)
      },
      onFailure: () => {}
    })
  }

  _handleSet(options) {
    this.setState({ options })
  }

}

export default Options
