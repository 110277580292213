const triggers = [
  {
    icon: 'handshake-o',
    code: 'deal_created',
    title: 't(Deal Created)',
    text: 't(Deal is created in pipeline)'
  },
  {
    icon: 'handshake-o',
    code: 'deal_updated',
    title: 't(Deal Updated)',
    text: 't(Deal is updated in pipeline)'
  },
  {
    icon: 'handshake-o',
    code: 'deal_transitioned',
    title: 't(Deal Transition)',
    text: 't(Deal is transitioned from one stage to another)'
  }
]

export default triggers
