import Date from '@admin/components/date'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const days = [
  { value: 'monday', text: 't(Monday)' },
  { value: 'tuesday', text: 't(Tuesday)' },
  { value: 'wednesday', text: 't(Wednesday)' },
  { value: 'thursday', text: 't(Thursday)' },
  { value: 'friday', text: 't(Friday)' },
  { value: 'saturday', text: 't(Saturday)' },
  { value: 'sunday', text: 't(Sunday)' }
]

const SchedulingTimeframeToken = ({ value }) => (
  <div className="truevail-timeframe-token">
    <table>
      <thead>
        <tr>
          <td />
          <td><T text="t(No Earlier Than)" /></td>
          <td><T text="t(No Later Than)" /></td>
        </tr>
      </thead>
      <tbody>
        { days.map((day, index) => (
          <tr key={`day_${index}`}>
            <td><T text={ day.text } /></td>
            { value[day.value].is_disabled ?
              <td colSpan="2">DO NOT POST</td> :
              <>
                <td><Date datetime={ moment(value[day.value].start_time, 'HH:mm') } date={ false } /></td>
                <td><Date datetime={ moment(value[day.value].end_time, 'HH:mm') } date={ false } /></td>
              </>
            }
          </tr>
        )) }
      </tbody>
    </table>
    
  </div>
)

SchedulingTimeframeToken.propTypes = {
  value: PropTypes.object
}

export default SchedulingTimeframeToken
