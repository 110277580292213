import { createLogger } from '@core/utils/console'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const logAction = createLogger('violet')

export const AnalyticsContext = React.createContext()
AnalyticsContext.displayName = 'AnalyticsContext'

export const useAnalyticsContext = () => React.useContext(AnalyticsContext)

class Analytics extends React.Component {

  static childContextTypes = {
    analytics: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  timer = null

  state = {
    account_id: null,
    attempts: 0,
    email: null,
    events: [],
    name: null,
    source_ip: null,
    team: null
  }

  _handleLogin = this._handleLogin.bind(this)
  _handleQueue = this._handleQueue.bind(this)
  _handleSend = this._handleSend.bind(this)
  _handleTrack = this._handleTrack.bind(this)

  render() {
    const { children } = this.props
    return (
      <AnalyticsContext.Provider value={ this.getChildContext() }>
        { children }
      </AnalyticsContext.Provider>
    )
  }

  getChildContext() {
    return {
      analytics: {
        login: this._handleLogin,
        track: this._handleTrack
      }
    }
  }

  _handleLogin(account, source_ip, team) {
    logAction('analytics/SIGNIN', { account, source_ip, team })
    this.setState({
      account_id: account.id,
      email: account.email,
      source_ip,
      name: account.full_name,
      team: team.title
    })
  }

  _handleQueue() {
    if(this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this._handleSend, 5000)
  }

  _handleTrack(event, properties) {
    logAction('analytics/TRACK', { event, properties })
    if(process.env.MAHA_ENV !== 'production') return
    const timestamp = moment().format('x') / 1000
    this.setState({
      events: [
        ...this.state.events,
        { event, timestamp, properties }
      ]
    }, this._handleQueue)
  }

  _handleSend() {
    const { account_id, attempts, email, events, name, source_ip, team } = this.state
    const { userAgent } = window.navigator
    const { hostname } = window.location
    const tld = hostname.split('.').slice(-2).join('.')
    fetch(`https://telemetry.${tld}/gelf`, {
      method: 'POST',
      body: events.map(({ event, timestamp, properties }) => JSON.stringify({
        version: '1.1',
        host: 'admin',
        short_message: event,
        timestamp,
        level: 1,
        _hostname: hostname,
        _source_ip: source_ip,
        _team: team,
        _user_agent: userAgent,
        _user_id: account_id, 
        _user_email: email, 
        _user_name: name,
        ...properties ? { _properties: JSON.stringify(properties) } : {}        
      })).join('\r\n')+'\r\n'
    }).then((result) => {
      if(result.ok) {
        return this.setState({
          attempts: 0,
          events: []
        })
      }
      if(attempts < 2) {
        return this.setState({
          attempts: attempts + 1
        }, this._handleQueue)
      }
    })
  }

}

export default Analytics
