import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Chooser extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    website: PropTypes.object,
    onChoose: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getIcon(icon) {
    return {
      title: icon.code,
      className: 'mjson-designer-iconfield-chooser-icon',
      dangerouslySetInnerHTML: { __html: icon.svg },
      onClick: this._handleChoose.bind(this, icon)
    }
  }

  _getPanel() {
    return {
      title: 't(Choose Icon)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getSearch() {
    return {
      endpoint: '/api/admin/icons',
      prompt: 'Find icon',
      valueKey: null,
      textKey: 'svg',
      layout: Results,
      props: {
        onChoose: this._handleChoose
      }
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChoose(icon) {
    this.props.onChoose({
      noun_project_id: icon.noun_project_id
    })
    this.context.form.pop()
  }

}

export default Chooser
