import Assignment from '@admin/components/assignment'
import TeamToken from '@admin/tokens/team'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    app: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Assignment { ...this._getAssignment() } />
  }

  _getAssignment() {
    const { app } = this.props
    return {
      title: 't(Manage Teams)',
      action: `/api/admin/platform/apps/${app.code}/teams`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        assignments: assigned.map(assignee => ({
          team_id: assignee.id
        }))
      }),
      assigneeFormat: (team) => team ? <TeamToken { ...team } /> : null,
      assignedEndpoint: `/api/admin/platform/apps/${app.code}/teams`,
      assignedFilter: (assigned) => assigned.map(assignee => assignee.team),
      empty: {
        icon: 'building-o',
        title: 't(No Teams)',
        text: 't(Please assign teams to this app)'
      },
      unassignedEndpoint: '/api/admin/platform/teams',
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.title.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          return assignment.id === assignee.id
        }) === undefined
      },
      onCancel: this._handleCancel, 
      onDone: this._handleDone

      // action: `/api/admin/platform/apps/${app.code}/teams`,
      // assignedEndpoint: `/api/admin/platform/apps/${app.code}/teams`,
      // assignedFormat: ({ team }) => <TeamToken { ...team } />,
      // empty: {
      //   icon: 'building-o',
      //   title: 't(No Teams)',
      //   text: 't(Please assign teams to this app)'
      // },
      // label: 'Team',
      // name: 'team',
      // text: 't(title)',
      // title: 't(Manage Teams)',
      // unassignedEndpoint: '/api/admin/platform/teams',
      // unassignedFormat: TeamToken,
      // value: 'id',
      // onCancel: this._handleCancel,
      // onDone: this._handleDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Manage
