import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  automation: 't(Automation)',
  campaign: 't(Campaign)',
  system: 't(System)',
  thread: 't(Thread)'
}

const EmailTypeToken = ({ type }) => (
  <div className={`email-type-token ${type}`}>
    <T text={ types[type] } />
  </div>
)

EmailTypeToken.propTypes = {
  type: PropTypes.string
}

export default EmailTypeToken
