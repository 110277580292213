const triggers = [
  {
    icon: 'plus',
    code: 'enrollment_created',
    title: 't(Enrollment Created)',
    text: 't(Contact is manually enrolled in workflow)'
  },
  {
    icon: 'envelope-o',
    code: 'email_received',
    title: 't(Email Received)',
    text: 't(Contact receives automation email)'
  },
  {
    icon: 'envelope-open-o',
    code: 'email_opened',
    title: 't(Email Opened)',
    text: 't(Contact opens automation email)'
  },
  {
    icon: 'mouse-pointer',
    code: 'email_clicked',
    title: 't(Email Clicked)',
    text: 't(Contact clicks link in automation email)'
  }
]

export default triggers
