import ModalPanel from '@admin/components/modal_panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Field from './field'
import React from 'react'

class Types extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    parent: PropTypes.string,
    types: PropTypes.array,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onPush: PropTypes.func,
    onPop: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-criterion-items">
          <div className="maha-criterion-type">
            <T text="t(Conditional)" />
          </div>
          <div className="maha-criterion-item" onClick={ this._handleConditional.bind(this, '$and') }>
            <T text="t(AND)" />
          </div>
          <div className="maha-criterion-item" onClick={ this._handleConditional.bind(this, '$or') }>
            <T text="t(OR)" />
          </div>
          { this._getTypes().reduce((items, type, typeIndex) => [
            ...items,
            <div className="maha-criterion-type" key={`type_${typeIndex}`}>
              <T text={ type.label } />
            </div>,
            ...type.fields.map((field, fieldIndex) => (
              <div className="maha-criterion-item" key={`type_${typeIndex}_field_${fieldIndex}`} onClick={ this._handleField.bind(this, field)}>
                <T text={ field.name } />
              </div>
            ))
          ], []) }
        </div>
      </ModalPanel>
    )
  }

  _getField(field) {
    const { parent, onCancel, onPop, onPush } = this.props
    return {
      field,
      onCancel,
      onChange: this._handleChange.bind(this, field, parent),
      onDone: this._handleDone.bind(this, field, parent),
      onPop,
      onPush
    }
  }

  _getPanel() {
    return {
      title: 't(Add Criteria)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ]
    }
  }

  _getTypes() {
    const { types } = this.props
    return types.map(group => ({
      label: group.label,
      fields: [
        ...group.fields || [],
        ...group.virtuals ? group.virtuals.filter(virtual => {
          return virtual.type !== 'text'
        }).map(virtual => ({
          key: virtual.code,
          name: virtual.name.value,
          ...this._getVirtual(virtual)
        })) : []
      ]
    }))
  }

  _getVirtual(field) {
    const type = field.type === 'contactfield' ? field.contactfield.type : field.type
    if(type === 'datefield') {
      return { type: 'daterange' }
    }
    if(type === 'checkbox') {
      return { type: 'checkbox' }
    }
    if(type === 'filefield') {
      return { type: 'file' }
    }
    if(_.includes(['checkboxes','dropdown','radiogroup'], type)) {
      return { type: 'select', options: field.options || field.config.options }
    }
    return { type: 'text' }
  }

  _handleCancel() {
    this.props.onPop()
  }

  _getIsSet(newvalue) {
    const { operator, value } = newvalue
    if(_.includes(['$nl','$nnl','$kn','$nkn','$tr','$ntr'], operator)) return true
    if(_.isString(value) && value.length > 0) return true
    if(_.isNumber(value) && value) return true
    if(_.isArray(value) && value.length > 0) return true
    return false
  }

  _handleChange(field, parent, newvalue) {
    this.props.onChange({
      field: field.key,
      parent,
      ...this._getIsSet(newvalue) ? newvalue : null
    })
  }

  _handleConditional(operator) {
    const { parent } = this.props
    this.props.onDone({
      code: _.random(100000, 999999).toString(36),
      parent,
      field: null,
      operator,
      value: null,
      data: null
    }, -1)
  }

  _handleDone(field, parent, value) {
    this.props.onDone({
      field: field.key,
      parent,
      ...value
    })
  }

  _handleField(field) {
    this.props.onPush(Field, this._getField(field))
  }

}

export default Types
