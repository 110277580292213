import Icon from '@admin/components/icon'
import PhoneNumber from './phone_number'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneNumbers extends React.Component {

  static propTypes = {
    phone_numbers: PropTypes.array,
    phone_number: PropTypes.object,
    onChange: PropTypes.func
  }

  state = {
    phone_number_id: 0,
    open: false
  }

  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { phone_numbers } = this.props
    const phone_number = this._getPhoneNumber()
    const { open } = this.state
    return (
      <div className="maha-handset-phone-numbers">
        <div className="maha-handset-phone-numbers-header" onClick={ this._handleToggle }>
          <div className="maha-handset-phone-numbers-header-token">
            { phone_number &&
              <PhoneNumber phone_number={ phone_number } />
            }
          </div>
          <div className="maha-handset-phone-numbers-header-icon">
            <Icon icon="caret-down" />
          </div>
        </div>
        { open &&
          <div className="maha-handset-phone-numbers-overlay" />
        }
        { open &&
          <div className="maha-handset-phone-numbers-chooser">
            { phone_numbers.map((phone_number, index) => (
              <div className="maha-handset-phone-numbers-chooser-item" key={`phone_number_${index}`} onClick={ this._handleChoose.bind(this, phone_number.id) }>
                <PhoneNumber phone_number={ phone_number } />
              </div>
            ))}
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { phone_number } = this.props
    this.setState({
      phone_number_id: phone_number.id
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { phone_number } = this.props
    const { phone_number_id } = this.state
    if(phone_number_id !== prevState.phone_number_id) {
      this._handleChange()
    }
    if(!_.isEqual(phone_number, prevProps.phone_number)) {
      this._handleChoose(phone_number.id)
    }
  }

  _getPhoneNumber() {
    const { phone_numbers } = this.props
    const { phone_number_id } = this.state
    return phone_numbers.find(phone_number => {
      return phone_number.id === phone_number_id
    })
  }

  _handleChange() {
    const { phone_number_id } = this.state
    this.props.onChange(phone_number_id)
  }

  _handleChoose(phone_number_id) {
    this.setState({
      open: false,
      phone_number_id
    })
  }

  _handleToggle() {
    const { open } = this.state
    this.setState({
      open: !open
    })
  }

}

export default PhoneNumbers
