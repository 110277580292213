import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ advisor }) => {

  const details = {
    sections: [
      {
        title: 't(Advisor Details)',
        items: [
          { label: 't(Name)', content: advisor.name },
          { label: 't(Organization)', content: advisor.organization }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } />
})

const mapResourcesToPanel = (props, context) => ({
  advisor: '/api/admin/truevail/advisor'
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
