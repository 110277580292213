import { DragSource, DropTarget } from 'react-dnd'
import Handle from '@admin/components/handle'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'
import Edit from './edit'

class Field extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    endpoint: PropTypes.string,
    field: PropTypes.object,
    index: PropTypes.number,
    isDragging: PropTypes.bool,
    label: PropTypes.string,
    onMove: PropTypes.func,
    onReorder: PropTypes.func
  }

  _handleClick = this._handleClick.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview, field } = this.props
    return connectDropTarget(
      connectDragPreview(
        <div className={ this._getClass() } onClick={ this._handleClick }>
          { connectDragSource(
            <div className="maha-field-handle">
              <Handle />
            </div>
          ) }
          <div className="maha-field-icon">
            <div className="maha-field-badge">
              <Icon icon={ this._getIcon(field.type) } />
            </div>
          </div>
          <div className="maha-field-label">
            <strong>{ field.name.value }</strong> <span>({ field.type })</span>
          </div>
          { field.is_mutable &&
            <div className="maha-field-extra">
              <div className="maha-field-action" onClick={ this._handleTasks }>
                <Icon icon="ellipsis-h" />
              </div>
            </div>
          }
          <div className="maha-field-proceed">
            <Icon icon="chevron-right" />
          </div>
        </div>
      )
    )
  }

  _getClass() {
    const { isDragging, field } = this.props
    const classes = ['maha-field']
    if(field.type === 'section') classes.push('section')
    if(!field.is_active) classes.push('disabled')
    if(isDragging) classes.push('dragging')
    return classes.join(' ')
  }

  _getIcon(type) {
    if(type === 'textfield') return 'font'
    if(type === 'textarea') return 'bars'
    if(type === 'checkboxgroup') return 'check-square-o'
    if(type === 'checkboxes') return 'check-square-o'
    if(type === 'checkbox') return 'check-square'
    if(type === 'imagefield') return 'camera'
    if(type === 'emailfield') return 'envelope'
    if(type === 'filefield') return 'upload'
    if(type === 'addressfield') return 'map-marker'
    if(type === 'phonefield') return 'phone'
    if(type === 'datefield') return 'calendar'
    if(type === 'dropdown') return 'caret-down'
    if(type === 'timefield') return 'clock-o'
    if(type === 'moneyfield') return 'dollar'
    if(type === 'radiogroup') return 'circle-o'
    if(type === 'numberfield') return 'hashtag'
    if(type === 'lookup') return 'search'
    if(type === 'htmlfield') return 'code'
    if(type === 'urlfield') return 'globe'
  }

  _handleClick(e) {
    e.stopPropagation()
    const { field } = this.props
    if(!field.is_active) return
    this.context.router.push(`/admin/team/properties/${field.id}`)
  }

  _handleTasks(e) {
    e.stopPropagation()
    const { endpoint, field, label } = this.props
    this.context.tasks.open({
      items: [
        {
          label: `Edit ${ _.capitalize(label) }`,
          show: field.is_active,
          modal: () => <Edit endpoint={ endpoint } id={ field.id } />
        },
        {
          label: `${field.is_active ? 'Disable' : 'Enable'} ${ _.capitalize(label) }`,
          confirm: field.is_active ? `Are you sure you want to ${field.is_active ? 'disable' : 'enable'} this ${label}?` : null,
          request: {
            endpoint: `${endpoint}/${field.id}/activate`,
            method: 'PATCH',
            body: {
              is_active: !field.is_active
            },
            onFailure: (result) => this.context.flash.set('error', `Unable to ${field.is_active ? 'disable' : 'enable'} this ${label}`)
          }
        },
        {
          label: `Delete ${ _.capitalize(label) }`,
          show: field.is_active,
          confirm: `
            Are you sure you want to delete this ${label}? All associated data
            will be deleted as well. (You can disable this ${label} instead to
            maintain access to the existing data)
          `,
          request: {
            method: 'DELETE',
            endpoint: `${endpoint}/${field.id}`,
            onFailure: (result) => this.context.flash.set('error', `Unable to delete this ${label}`)
          }
        }
      ]
    })
  }

}

const source = {
  beginDrag: (props) => ({
    parent_type: props.parent_type,
    parent_id: props.parent_id,
    index: props.index,
    delta: props.field.delta,
    onMove: props.onMove,
    onReorder: props.onReorder
  }),
  endDrag: (props, monitor, component) => {
    const source = monitor.getItem()
    const target = monitor.getDropResult()
    if(!target) return
    source.onReorder(source.delta, target.index)
  }
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index,
    delta: props.field.delta
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Field = DragSource('ITEM', source, sourceCollector)(Field)
Field = DropTarget('ITEM', target, targetCollector)(Field)

export default Field
