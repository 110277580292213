import ProfileConnect from '@admin/components/profile_connect'
import TextField from '@admin/components/form/textfield'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class ProfileSetupField extends React.Component {

  static contextTypes = {
    form: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    profiles: PropTypes.array,
    program: PropTypes.object,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    profiles: []
  }

  _handleAdd = this._handleAdd.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  _getList() {
    const { profiles } = this.props
    return {
      actions: (item, index) => [
        {
          icon: 'trash-o', 
          confirm: 't(Are you sure you want to remove this profile?)',
          handler: this._handleRemove.bind(this, item)
        }
      ],
      items: profiles,
      empty: <Token text='t(You have not yet added any profiles)' />,
      format: (profile) => (
        <div className="truevail-profilesetupfield-profile-details">
          <div className="truevail-profilesetupfield-profile-token">
            <ProfileToken profile={ profile } />
          </div>
          <div className="truevail-profilesetupfield-profile-hashtags">
            <TextField { ...this._getTags(profile) } />
          </div>
        </div>
      )
    }
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { profiles } = this.state
    const { value } = this.props
    if(!_.isEqual(this.props.profiles, prevProps.profiles)) {
      this._handleSet(value)
    }
    if(!_.isEqual(profiles, prevState.profiles)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    return {
      label: '+ t(add profiles)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : []
  }

  _getRemove(profile) {
    return {
      icon: 'trash-o',
      className: 'truevail-profilesetupfield-profile-action',
      confirm: 't(Are you sure you want to remove this profile?)',
      handler: this._handleRemove.bind(this, profile.id)
    }
  }

  _getTags(profile) {
    const { profiles } = this.state
    const found = profiles.find(item => {
      return item.profile_id === profile.id
    })
    return {
      value: found ? found.hashtags : '',
      placeholder: 't(Enter brand hashtags (#luxurytravel))',
      onChange: this._handleUpdate.bind(this, profile.id)
    }
  }

  _handleAdd() {
    const { program } = this.props
    const props = {
      program_id: program.id,
      // sources: ['facebook','instagram_business','linkedin'],
      sources: ['facebook','instagram_business'],
      types: ['posts'],
      onBack: this.context.form.pop,
      onDone: this.context.form.pop
    }
    this.context.form.push(ProfileConnect, props)
  }

  _handleChange() {
    const { profiles } = this.state
    this.props.onChange(this.props.profiles.reduce((data, profile) => {
      const found = profiles.find(item => {
        return item.profile_id === profile.id
      })
      return [
        ...data,
        ...found ? [{
          profile_id: profile.id,
          hashtags: found.hashtags
        }] : []
      ]
    }, []))
  }

  _handleUpdate(profile_id, hashtags) {
    const { profiles } = this.state
    this.setState({
      profiles: profiles.map(item => ({
        ...item,
        ...item.profile_id === profile_id ? { hashtags } : {}
      }))
    })
  }

  _handleRemove(profile) {
    this.context.network.request({
      endpoint: `/api/admin/profiles/${profile.id}`,
      method: 'DELETE',
      onFailure: () => this.context.flash.set('error', 't(Unable to remove profile)')
    })
  }

  _handleSet(profiles) {
    this.setState({
      profiles: this.props.profiles.map(profile => {
        const found = profiles ? profiles.find(item => {
          return item.profile_id === profile.id
        }) : null
        return {
          profile_id: profile.id,
          hashtags: found ? found.hashtags : ''
        }
      })
    })
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/team/profiles',
    refresh: `/teams/${context.admin.team.id}/admin/team/profiles`,
    ...props.types ? {
      filter: {
        type: { $in: ['posts'] }
      }
    } : {}
  }
})

export default Container(mapResources)(ProfileSetupField)
