export const onPop = () => ({
  type: 'POP'
})

export const onPush = (route) => ({
  type: 'PUSH',
  route
})

export const onForward = (route) => ({
  type: 'FORWARD',
  route
})

export const onReplace = (route) => ({
  type: 'REPLACE',
  route
})

export const onReset = (route) => ({
  type: 'RESET',
  route
})
