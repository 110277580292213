import TimeField from '@admin/components/form/timefield'
import Checkbox from '@admin/components/form/checkbox'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const days = [
  { value: 'monday', text: 't(Monday)' },
  { value: 'tuesday', text: 't(Tuesday)' },
  { value: 'wednesday', text: 't(Wednesday)' },
  { value: 'thursday', text: 't(Thursday)' },
  { value: 'friday', text: 't(Friday)' },
  { value: 'saturday', text: 't(Saturday)' },
  { value: 'sunday', text: 't(Sunday)' }
]

class TimeFrameField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    config: null
  }

  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { config } = this.state
    if(!config) return null
    return (
      <div className="truevail-timeframefield">
        <table>
          <thead>
            <tr>
              <td />
              <td />
              <td><T text="t(No Earlier Than)" /></td>
              <td><T text="t(No Later Than)" /></td>
            </tr>
          </thead>
          <tbody>
            { days.map((day, index) => (
              <tr key={`day_${index}`}>
                <td><Checkbox { ...this._getDisabled(day) } /></td>
                <td><T text={ day.text } /></td>
                { config[day.value].is_disabled ?
                  <td colSpan="2">DO NOT POST</td> :
                  <>
                    <td><TimeField { ...this._getTimeField(day, 'start_time') } /></td>
                    <td><TimeField { ...this._getTimeField(day, 'end_time') } /></td>
                  </>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDisabled(day) {
    const { config } = this.state
    return {
      value: !config[day.value].is_disabled,
      onChange: this._handleDisabled.bind(this, day.value)
    }
  }

  _getTimeField(day, time) {
    const { config } = this.state
    return {
      value: config[day.value][time],
      disabled: config[day.value].is_disabled,
      onChange: this._handleUpdate.bind(this, day.value, time)
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleUpdate(day, time, value) {
    const { config } = this.state
    this.setState({
      config: days.reduce((ds, d) => ({
        ...ds,
        [d.value]: {
          ...config[d.value],
          ...d.value === day ? {
            [time]: value
          } : {}              
        }
      }), {})
    })
  }

  _handleDisabled(day, value) {
    const { config } = this.state
    this.setState({
      config: days.reduce((ds, d) => ({
        ...ds,
        [d.value]: {
          ...config[d.value],
          ...d.value === day ? {
            is_disabled: !value
          } : {}              
        }
      }), {})
    })
  }

  _handleSet(config) {
    this.setState({ 
      config: days.reduce((ds, d) => ({
        ...ds,
        [d.value]: {
          start_time: '06:00',
          end_time: '20:00',
          ...config && config[d.value] ? config[d.value] : {}
        }
      }), {})
    })
  }

  _handleValidate() {
    const { config } = this.state
    const valid = days.reduce((valid, day) => {
      if(!valid) return false
      if(!config[day.value].start_time) return false
      if(!config[day.value].end_time) return false
      if(config[day.value].start_time >= config[day.value].end_time) return false
      return true
    }, true)
    if(!valid) return this.props.onValid(null, ['Please enter valid data'])
    this.props.onValid(config)
  }

}

export default TimeFrameField
