import MediaField from '@apps/stores/admin/components/mediafield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Photos extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Photos', name: 'photos', type: MediaField, endpoint: `/api/admin/stores/stores/${formdata.store_id}/products/${formdata.product_id}/variants/${formdata.id}/photos`, defaultValue: formdata.photo_ids }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(variant) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      photos: variant.photos.map(photo => ({
        id: photo.id,
        asset_id: photo.asset_id,
        caption: photo.caption
      }))
    })
  }

}

export default Photos
