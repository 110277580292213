import IntegrationToken from '@apps/finance/admin/tokens/integration'
import React from 'react'

const userFields = (user, context) => {
  const finance = context.admin.apps.find(app => app.code === 'finance')
  return finance && finance.settings.integration === 'accumatica' ? [
    {
      label: <IntegrationToken />,
      padded: false,
      fields: [
        { label: 'Employee ID', name: 'values.employee_id', type: 'textfield' },
        { label: 'Vendor ID', name: 'values.vendor_id', type: 'textfield' }
      ]
    }
  ] : []
}

export default userFields
