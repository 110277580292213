import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    compareKey: PropTypes.string,
    chooseFirst: PropTypes.bool,
    chosen: PropTypes.array,
    defaultValue: PropTypes.array,
    format: PropTypes.any,
    multiple: PropTypes.any,
    records: PropTypes.array,
    previewing: PropTypes.object,
    onChooseRecord: PropTypes.func,
    onPreview: PropTypes.func
  }

  render() {
    const { format, records } = this.props
    return (
      <div className="maha-entity-chooser-results">
        { records.map((record, index) => (
          <div className={ this._getClass(record) } onClick={ this._handleChoose.bind(this, record) } key={`record_${index}`}>
            <div className="maha-entity-chooser-result-toggle">
              <Icon icon={ this._getIcon(record) }  />
            </div>          
            <div className="maha-entity-chooser-result-details">
              <Format { ...record } format={ format } value={ record } />
            </div>
          </div>
        ))}
      </div>
    )    
  }

  componentDidMount() {
    const { compareKey, defaultValue, chooseFirst, records } = this.props
    if(defaultValue) {
      const chosen = records.find(record => {
        return _.get(record, compareKey) === defaultValue
      })
      if(!chosen) return 
      this._handleSelect(chosen)
    } else if(chooseFirst) {
      this._handleSelect(records[0])
    }
  }

  _getClass(record) {
    const classes = ['maha-entity-chooser-result']
    const { previewing } = this.props
    if(record.id === previewing?.id) classes.push('selected')
    return classes.join(' ')
  }

  _getIcon(record) {
    const { chosen, multiple } = this.props
    const is_selected = chosen.find(item => item.id === record.id) !== undefined
    const shape = multiple ? 'square' : 'circle'
    return is_selected ? `check-${shape}` : `${shape}-o`
  }

  _handleChoose(record, e) {
    this.props.onChooseRecord(record)
    this.props.onPreview(record)
  }

  _handleSelect(record, e) {
    const { multiple } = this.props
    if(!multiple) this.props.onChooseRecord(record)
    this.props.onPreview(record)
  }

}

export default Results
