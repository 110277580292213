import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import TeamToken from '@admin/tokens/team'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Advisors)',
  collection: {
    endpoint: '/api/admin/truevail/admin/advisors',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team.title', sort: 'title',  primary: true, format: (advisor) => (
        <TeamToken { ..._.omit(advisor.team, 'is_active') } />
      ) },
      { label: 't(Agency)', key: 'agency.team.title', sort: 'agency' },
      { label: 't(Status)', key: 'team.status', sort: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    criteria: {
      system: [
        { id: 0, title: 't(Active Advisors)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'active' }
          ]
        } },
        { id: 0, title: 't(Pending Advisors)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'pending' }
          ]
        } },
        { id: 1, title: 't(Configured Advisors)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'configured' }
          ]
        } }
      ],
      fields: [
        { label: 't(Advisor)', fields: [
          { name: 'Agency', key: 'agency_id', type: 'select', endpoint: '/api/admin/truevail/admin/agencies', valueKey: 'id', textKey: 'organization', format: (advisor) => (
            <TeamToken { ..._.omit(advisor.team, 'is_active') } />
          ) },
          { name: 'Status', key: 'status', type: 'select', options: ['pending','configured','active','inactive','canceled'], format: StatusToken  }
        ] }
      ]
    },
    empty: {
      icon: 'user',
      title: 't(No Advisors)',
      text: 't(You have not yet created any advisors)',
      buttons: [
        { 
          label: 't(Create Advisor)', 
          modal: New 
        }
      ]
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/admin/advisors/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Advisor)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
