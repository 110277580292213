import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * @typedef {Object} RenderedTextConfig
 * @property {'system' | 'google'} fontType source of the font file
 * @property {string} fontName source of the font file
 * @property {('left' | 'right' | 'center')?} align alignment mode for the text
 * @property {string?} color text color as hex values (e.g. "ff7500")
 * @property {string?} backgroundColor (alias for bg) background color as hex values (e.g. "ff7500")
 * @property {number} fontSize font size in pixels
 * @property {number?} lineHeight line height in pixels
 * @property {number?} width fixed canvas width in pixels (optional; defaults to automatic width)
 * @property {number?} maxWidth maximum line length in pixels (optional; defaults to Infinity)
 * @property {number?} mx horizontal margin in pixels
 * @property {number?} my vertical margin in pixels
 */

/**
 *
 */
export default class RenderedText extends React.PureComponent {

  static propTypes = {
    children: PropTypes.element,
    text: PropTypes.string,
    fontType: PropTypes.oneOf(['system', 'google']).isRequired,
    fontName: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    fg: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    bg: PropTypes.string,
    fontSize: PropTypes.number.isRequired,
    lineHeight: PropTypes.number,
    width: PropTypes.number,
    maxWidth: PropTypes.number,
    mx: PropTypes.number,
    my: PropTypes.number
  }
  
  static contextTypes = {
    admin: PropTypes.object
  }

  render () {
    return <Img
      className={'rendered-text'}
      style={{mixBlendMode: this.blendMode}}
      alt={this.textContent}
      src={this.buildSrc()}
    />
  }

  get textContent() {
    return this.config.text || React.Children.toArray(this.props.children).join('')
  }

  get config() {
    return this.props.config || this.props
  }

  get blendMode() {
    // Detect the color settings and determine what default blend mode to use.
    // If provided explicitly, always use the explicit value from props.

    // With custom colors, default to the "normal" blend mode.
    if (this.config.fg || this.config.color || this.config.bg || this.config.backgroundColor) {
      return this.config.blendMode || 'normal'
    }
    
    // If there are no color overrides, use "darken" by default.
    return this.config.blendMode || 'darken'
  }

  buildSrc() {
    const argParams = new URLSearchParams()
    for (let key of Object.keys(this.config)) {
      let value = this.config[key]
      switch (key) {
      case 'fs':
      case 'fontSize':
        argParams.set('fs', value)
        break

      case 'lh':
      case 'lineHeight':
        argParams.set('lh', value)
        break

      case 'fg':
      case 'color':
        argParams.set('fg', value)
        break

      case 'bg':
      case 'backgroundColor':
        argParams.set('bg', value)
        break

      case 'ftype':
      case 'fontType':
        argParams.set('ftype', value)
        break

      case 'fname':
      case 'fontName':
        argParams.set('fname', value)
        break

      case 'width':
        argParams.set('width', value)
        break

      case 'maxWidth':
        argParams.set('maxWidth', value)
        break

      case 'align':
        argParams.set('align', value)
        break

      case 'mx':
      case 'marginX':
        argParams.set('mx', value)
        break

      case 'my':
      case 'marginY':
        argParams.set('my', value)
        break
      }
    }

    const text = this.textContent

    return new URL(
      `/text/${argParams.toString()}/${encodeURIComponent(text.replace(/\s+/g, '+'))}.png`,
      this.context.admin.team.provider.asset_host
    ).toString()
  }
}