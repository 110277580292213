export const INITIAL_STATE = {
  query: '',
  chosen: []
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_CHOSEN':
    return {
      ...state,
      chosen: action.chosen
    }

  case 'SET_QUERY':
    return {
      ...state,
      query: action.query
    }

  case 'TOGGLE_RECORD':
    return {
      ...state,
      chosen: [
        ...action.multiple ?
          _.xorWith(state.chosen, [action.record], _.isEqual) :
          !_.includes(state.chosen, action.record) ? [action.record] : []
      ]
    }

  default:
    return state
  }

}

export default reducer
