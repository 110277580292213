import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const requiredField = (label, item, path, extra) => {
  if(!_.isNil(_.get(item, path))) {
    return { label, content: _.get(item, path), ...extra }
  } else  {
    return { label, content: 'REQUIRED', className: 'error' }
  }
}

const Overview = ({ trip }) => {

  const details = {
    audits: `finance_trips/${trip.id}`,
    comments: `finance_trips/${trip.id}`
  }
  
  details.items = [
    requiredField('User', trip, 'user.full_name'),
    requiredField('Date', trip, 'date', { content: trip.date, format: 'date' }),
    { label: 'Description ', content: trip.description },
    requiredField('Project', trip, 'project.title', { content: trip, format: CompactProjectToken }),
    requiredField('Expense Type', trip, 'expense_type.title', { content: trip, format: CompactExpenseTypeToken }),
    { label: 'Time Leaving', content: trip.time_leaving, format: 'time|hh:mm A', empty: 'NONE' },
    { label: 'Time Arriving ', content: trip.time_arriving, format: 'time|hh:mm A', empty: 'NONE' },
    requiredField('Odometer Start', trip, 'odometer_start'),
    requiredField('Odometer End', trip, 'odometer_end'),
    requiredField('Total Miles', trip, 'total_miles', { units: 'mi' }),
    requiredField('Mileage Rate', trip, 'mileage_rate', { format: 'number|$0.000' }),
    requiredField('Amount', trip, 'amount', { format: 'currency' })
  ]

  if(trip.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This trip was deleted)' }
  } else if(trip.status === 'incomplete') {
    details.alert = { color: 'grey', message: 't(This trip is missing required information)' }
  } else if(trip.status === 'pending') {
    details.alert = { color: 'teal', message: 't(this trip has not yet been submitted)' }
  } else if(trip.status === 'submitted') {
    details.alert = { color: 'blue', message: 't(This trip has been submitted for review)' }
  } else if(trip.status === 'approved') {
    details.alert = { color: 'green', message: 't(This trip has been approved)' }
  } else if(trip.status === 'rejected') {
    details.alert = { color: 'red', message: 't(This trip has been rejected)' }
  } else if(trip.status === 'reviewed') {
    details.alert = { color: 'pink', message: 't(This trip has been reviewed)' }
  } else if(trip.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This trip was exported)' }
  }

  return <Details { ...details } />
  
}

Overview.propTypes = {
  trip: PropTypes.object
}

export default Overview
