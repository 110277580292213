import ProfileReconnect from '@admin/components/profile_reconnect'
import PropTypes from 'prop-types'
import React from 'react'

class ProfilesRoot extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.object
  }

  render() {
    return this.props.children
  }

  componentDidMount() {
    this.context.network.request({
      endpoint: '/api/admin/team/profiles',
      query: {
        $filter: {
          status: {
            $neq: 'connected'
          }
        }
      },
      method: 'GET',
      onSuccess: ({ data }) => {
        const profiles = data
        if(profiles.length === 0) return
        this.context.modal.open(<ProfileReconnect />)
      }
    })
  }

}

export default ProfilesRoot
