import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import VisibilityField from '@admin/components/mjson_designer/components/visibilityfield'
import React from 'react'

const Visibility = (props) => <ResponsiveField { ...props } component={ VisibilityField } />

export const VisibilitySection = (config, device, theme, website, type) => ({
  label: 't(Visibility)',
  instructions: 't(Specify the visibility for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.visibility', type: Visibility, device, value: config.styles.visibility }
  ]
})
