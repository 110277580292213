const getArraySegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = _.upperFirst(_.camelCase(segment.name))

  return  {
    [`onAdd${key}`]: (item) => ({
      type: `ADD_${typeKey}`,
      item
    }),
    [`onClear${key}`]: () => ({
      type: `CLEAR_${typeKey}`
    }),
    [`onMove${key}`]: (from, to) => ({
      type: `MOVE_${typeKey}`,
      from,
      to
    }),
    [`onRemove${key}`]: (index) => ({
      type: `REMOVE_${typeKey}`,
      index
    }),
    [`onSet${key}`]: (items) => ({
      type: `SET_${typeKey}`,
      items
    }),
    [`onUpdate${key}`]: (item, index) => ({
      type: `UPDATE_${typeKey}`,
      item,
      index
    })
  }

}

const getBooleanSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = _.upperFirst(_.camelCase(segment.name))
  
  return  {
    [`onSet${key}`]: (value) => ({
      type: `SET_${typeKey}`,
      value
    }),
    [`onToggle${key}`]: () => ({
      type: `TOGGLE_${typeKey}`
    })
  }
  
}

const getStringSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = _.upperFirst(_.camelCase(segment.name))
  
  return  {
    [`onClear${key}`]: () => ({
      type: `CLEAR_${typeKey}`
    }),
    [`onSet${key}`]: (value) => ({
      type: `SET_${typeKey}`,
      value
    })
  }
  
}

const getVarSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = _.upperFirst(_.camelCase(segment.name))
  
  return  {
    [`onClear${key}`]: () => ({
      type: `CLEAR_${typeKey}`
    }),
    [`onSet${key}`]: (value) => ({
      type: `SET_${typeKey}`,
      value
    })
  }
  
}

const getSegment = (segment) => {
  if(segment.type === 'array') return getArraySegment(segment)
  if(segment.type === 'boolean') return getBooleanSegment(segment)
  if(segment.type === 'string') return getStringSegment(segment)
  if(segment.type === 'var') return getVarSegment(segment)
  return { [segment.handle]: segment.handler}
}

const getActions = (segments) => {
  return segments.reduce((actions, segment) => ({
    ...actions,
    ...getSegment(segment)
  }), {})
}

export default getActions