import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { account, team } = this.context.admin
    return {
      title: 't(New Advisor)',
      action: '/api/admin/truevail/admin/advisors',
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Agency)', name: 'agency_id', type: 'dropdown', endpoint: '/api/admin/truevail/admin/agencies', valueKey: 'id', textKey: 'team.title', required: true, selectFirst: true },
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
            { label: 't(Organization)', name: 'organization', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { content_types: ['images'] }, multiple: false },
            { label: 't(Timezone)', name: 'timezone_id', instructions: 't(Content will be scheduled in the advisor\'s timezone)', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true, defaultValue: account.timezone_id }

          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(advisor) {
    this.context.router.push(`/admin/truevail/admin/advisors/${advisor.id}`)
    this.context.modal.close()
  }

}

export default New
