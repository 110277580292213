import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const ServiceToken = ({ service }) => (
  <div className="service-token">
    <div className="service-token-icon">
      <Img src={`/images/services/${service.source_name}.png`} />
    </div>
    <div className="service-token-details">
      { service.name }<br />
      <span>{ service.source } - { service.type }</span>
    </div>
  </div>
)

ServiceToken.propTypes = {
  service: PropTypes.string
}

export default ServiceToken
