import { DragSource, DropTarget } from 'react-dnd'
import Input from '@admin/components/html/input'
import Handle from '@admin/components/handle'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Row extends React.Component {

  static propTypes = {
    columns: PropTypes.array,
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    index: PropTypes.number,
    isDragging: PropTypes.bool,
    reorderable: PropTypes.bool,
    row: PropTypes.object,
    tabIndex: PropTypes.number,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func
  }

  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, columns, index, reorderable } = this.props
    const row = (
      <div className={ this._getClass() }>
        { reorderable &&
          connectDragSource(
            <div className="maha-tablefield-handle">
              <Handle />
            </div>
          )
        }
        { columns.map((column, index) => (
          <div className="maha-tablefield-column" key={`column_${index}`}>
            <Input { ...this._getInput(column, index) } />
          </div>
        ))}
        <div className="maha-tablefield-actions" onClick={ this._handleRemove.bind(this, index) }>
          <Icon icon="remove" />
        </div>
      </div>
    )
    return reorderable ? connectDropTarget(connectDragPreview(row)) : row
  }

  _getInput(column, cindex) {
    const { index, row, tabIndex } = this.props
    return {
      type: 'text',
      tabIndex,
      value: row[column.key],
      onChange: this._handleUpdate.bind(this, index, column.key)
    }
  }

  _getClass() {
    const { isDragging } = this.props
    const classes = ['maha-tablefield-row']
    if(isDragging) classes.push('dragging')
    return classes.join(' ')
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

  _handleUpdate(index, key, value) {
    this.props.onUpdate(index, key, value)
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  }),
  endDrag: (props, monitor, component) => {
    const source = monitor.getItem()
    const target = monitor.getDropResult()
    if(!target) return
    source.onMove(source.index, target.index)
    
  }
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Row = DragSource('TABLEFIELD_ITEM', source, sourceCollector)(Row)
Row = DropTarget('TABLEFIELD_ITEM', target, targetCollector)(Row)

export default Row
