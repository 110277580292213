import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    datasources: PropTypes.array,
    endpoint: PropTypes.string,
    id: PropTypes.number
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { endpoint, id } = this.props
    return {
      title: 't(Edit Field)',
      method: 'PATCH',
      endpoint: `${endpoint}/${id}`,
      action: `${endpoint}/${id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'type', type: 'hidden' },
            { label: 't(Name)', name: 'name', type: 'tokenfield', placeholder: 'Enter a name', required: true },
            { label: 't(Label)', name: 'config.label', type: 'textfield', placeholder: 'Enter a label', required: true },
            { label: 't(Instructions)', name: 'config.instructions', type: 'htmlfield', placeholder: 'Enter instructions' },
            { label: 't(Required)', name: 'config.required', type: 'checkbox', prompt: 'This field is required' },
            ...this._getTypeFields()
          ]
        }
      ]
    }
  }

  _getTypeFields() {
    const { config } = this.state
    const { datasources } = this.props
    const { type } = config
    if(!type) return []
    const fields = []
    if(_.includes(['textfield','textarea'], type)) {
      fields.push({ label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield', placeholder: 'Enter placeholder text' })
      fields.push({ label: 't(Min Length)', name: 'config.minLength', type: 'numberfield' })
      fields.push({ label: 't(Max Length)', name: 'config.maxLength', type: 'numberfield' })
    }
    if(type === 'numberfield') {
      fields.push({ label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield', placeholder: 'Enter placeholder text' })
      fields.push({ label: 't(Min)', name: 'config.min', type: 'numberfield' })
      fields.push({ label: 't(Max)', name: 'config.max', type: 'numberfield' })
    }
    if(type === 'lookup') {
      fields.push({ label: 't(Multiple Values)', name: 'config.multiple', type: 'radiogroup', deselectable: false, options: [
        { value: false, text: 't(User can pick one value)' },
        { value: true, text: 't(User can pick multiple values)'}
      ], defaultValue: false  })
    }
    if(type === 'checkbox') {
      fields.push({ label: 't(Prompt)', name: 'config.prompt', type: 'htmlfield' })
    }
    if(type === 'imagefield') {
      fields.push({ label: 't(Crop Ratio)', type: 'dropdown', name: 'config.ratio', options: [
        { text: 't(None)', value: null },
        { text: '1:1', value: 1 },
        { text: '16:9', value: 16/9 },
        { text: '10:8', value: 10/8 },
        { text: '7:5', value: 7/5 },
        { text: '4:3', value: 4/3 },
        { text: '5:3', value: 5/3 },
        { text: '3:2', value: 3/2 }
      ], defaultValue: 1 })
    }
    if(_.includes(['checkboxgroup','checkboxes','radiogroup','lookup','dropdown'], type)) {
      if(datasources && datasources.length > 0) {
        fields.push({ label: 't(Data Type)', name: 'config.data_type', type: 'radiogroup', deselectable: false, options: [
          { value: 'static', text: 't(Static)' },
          { value: 'dynamic', text: 't(Dynamic)' }
        ], defaultValue: 'static' })
      } else {
        fields.push({ name: 'config.data_type', type: 'hidden', defaultValue: 'static' })
      }
      if(_.get(config, 'config.data_type') === 'static') {
        fields.push({ label: 't(Options)', name: 'config.options', type: 'tablefield', columns: [
          { label: 't(Value)', key: 'value' },
          { label: 't(Text)', key: 'text' }
        ] })
      }
      if(_.get(config, 'config.data_type') === 'dynamic') {
        fields.push({ label: 't(Datasource)', name: 'config.datasource', type: 'dropdown', options: datasources.map(datasource => {
          return {
            value: {
              endpoint: datasource.endpoint,
              text: datasource.text,
              value: datasource.value,
              type_id: datasource.type_id
            },
            text: datasource.label
          }
        })})
      }
    }
    return fields
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
