import PropTypes from 'prop-types'
import React from 'react'

const PlanTypeToken = ({ value }) => (
  <div className="token">
    { value === 'paid' &&
      <>
        <strong>Paid</strong><br />
        Subscriptions will generate an invoice with each renewal
      </>
    }
    { value === 'free' &&
      <>
        <strong>Free</strong><br />
        Subscriptions will generate renewals without invoices
      </>
    }
  </div>
)

PlanTypeToken.propTypes = {
  value: PropTypes.object
}

export default PlanTypeToken
