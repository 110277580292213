import Page from '@admin/components/page'
import Transactions from './transactions'
import Overview from './overview'
import React from 'react'

const getTabs = (props, context, { deposit, transactions }) => ({
  items: [
    { label: 't(Overview)', component: <Overview deposit={ deposit } /> },
    { label: 't(Transactions)', component: <Transactions deposit={ deposit } transactions={ transactions } /> }
  ]
})

const getTasks = (props, { admin }, { deposit }) => ({
  items: [
    {
      label: 't(Download Export)',
      show: deposit.status !== 'pending',
      access: { 
        rights: { $oneOf: ['finance:manage_deposits'] } 
      },
      url: admin.signUrl(`/api/admin/finance/deposits/${deposit.id}/export.xlsx?$page[limit]=0&download=true`)
    }
  ]
})

const getButtons = (props, { admin }, { deposit }) => [
  {
    color: 'violet',
    text: 't(Export)',
    show: deposit.status === 'pending',
    access: { 
      rights: { $oneOf: ['finance:manage_deposits'] } 
    },
    request: {
      endpoint: `/api/admin/finance/deposits/${deposit.id}/export`,
      method: 'POST',
      onSuccess: () => {
        window.location.href = admin.signUrl(`/api/admin/finance/deposits/${deposit.id}/export.xlsx?$page[limit]=0&download=true`)
      }
    }
  }
]

const mapResourcesToPage = (props, context) => ({
  deposit: `/api/admin/finance/deposits/${props.params.id}`,
  transactions: `/api/admin/finance/deposits/${props.params.id}/transactions`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Deposit)',
  tabs: getTabs(props, context, resources),
  tasks: getTasks(props, context, resources),
  buttons: getButtons(props, context, resources)
})

export default Page(mapResourcesToPage, mapPropsToPage)
