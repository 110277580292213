import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.module.title,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: `/admin/platform/modules/${resources.module.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview module={ resources.module } />
      },
      {
        label: 't(Design)',
        path: '/design',
        show: resources.module.deleted_at === null,
        panel: <Design module={ resources.module } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  module: `/api/admin/platform/modules/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
