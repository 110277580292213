import RecipientField from '@admin/components/form/recipientfield'
import Composer from '@apps/email/admin/components/composer'
import TextField from '@admin/components/form/textfield'
import ModalPanel from '@admin/components/modal_panel'
import Dropdown from '@admin/components/form/dropdown'
import Checkbox from '@admin/components/form/checkbox'
import Container from '@admin/components/container'
import Lookup from '@admin/components/form/lookup'
import ProfileToken from '@admin/tokens/profile'
import Button from '@admin/components/button'
import Templates from './templates'
import PropTypes from 'prop-types'
import Program from './program'
import React from 'react'

class Email extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    contacts: PropTypes.object,
    contact: PropTypes.object,
    forward: PropTypes.object,
    profiles: PropTypes.array,
    programs: PropTypes.array,
    reply: PropTypes.object,
    subject: PropTypes.string,
    onCancel: PropTypes.func,
    onSend: PropTypes.func
  }

  composer = null

  state = {
    bcc: [],
    body: {},
    cc: [],
    import_email: false,
    import_program_id: null,
    import_thread_id: null,
    priority: 'low',
    profile_id: null,
    reply_to_thread: null,
    showcc: false,
    subject: '',
    to: []
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleImport = this._handleImport.bind(this)
  _handleSend = this._handleSend.bind(this)
  _handleTemplate = this._handleTemplate.bind(this)
  _handleTemplates = this._handleTemplates.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { showcc, import_thread_id } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-form">
          <div className="maha-email-new">
            <div className="maha-email-new-header">
              <div className="maha-email-new-header-row">
                <div className="maha-email-new-header-row-tool">
                  <Button { ...this._getTemplates() } />
                </div>
                { !import_thread_id &&
                  <div className="maha-email-new-header-row-tool">
                    <Checkbox { ...this._getImport() } />
                  </div>
                }
              </div>
              <div className="maha-email-new-header-row">
                <div className="maha-email-new-header-row-label">
                  From:
                </div>
                <div className="maha-email-new-header-row-input">
                  <Lookup { ...this._getFrom() } />
                </div>
              </div>
              <div className="maha-email-new-header-row">
                <div className="maha-email-new-header-row-label">
                  To:
                </div>
                <div className="maha-email-new-header-row-input">
                  <RecipientField { ...this._getTo() } />
                </div>
                <Button { ...this._getShow() } />
              </div>
              { showcc &&
                <>
                  <div className="maha-email-new-header-row">
                    <div className="maha-email-new-header-row-label">
                      Cc:
                    </div>
                    <div className="maha-email-new-header-row-input">
                      <RecipientField { ...this._getCC() } />
                    </div>
                  </div>
                  <div className="maha-email-new-header-row">
                    <div className="maha-email-new-header-row-label">
                      Bcc:
                    </div>
                    <div className="maha-email-new-header-row-input">
                      <RecipientField { ...this._getBCC() } />
                    </div>
                  </div>
                </>
              }
              <div className="maha-email-new-header-row">
                <div className="maha-email-new-header-row-label">
                  Subject:
                </div>
                <div className="maha-email-new-header-row-input">
                  <TextField { ...this._getSubject() } />
                </div>
                <div className="maha-email-new-header-row-extra">
                  <Dropdown { ...this._getPriority() } />
                </div>
              </div>
            </div>
            <div className="maha-email-new-body">
              <Composer { ...this._getComposer() } />
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { contact, contacts, forward, reply } = this.props
    if(contacts) return this._handleUpdate('to', contacts.map(contact => contact.email))
    if(contact) this._handleUpdate('to', [contact.email])
    if(forward) this._handleForward()
    if(reply) this._handleReply()
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile_id } = this.state
    if(profile_id !== prevState.profile_id && profile_id) {
      this._handleSignature()
    }
  }

  _getComposer() {
    return {
      inline: true,
      reference: (composer) => this.composer = composer,
      tabIndex: 6,
      onChange: this._handleUpdate.bind(this, 'body')
    }
  }

  _getBCC() {
    const { bcc } = this.state
    return {
      id: 'bcc',
      label: 'Bcc',
      tabIndex: 4,
      value: bcc,
      onChange: this._handleUpdate.bind(this, 'bcc')
    }
  }

  _getCC() {
    const { cc } = this.state
    return {
      id: 'cc',
      label: 'Cc',
      tabIndex: 3,
      value: cc,
      onChange: this._handleUpdate.bind(this, 'cc')
    }
  }

  _getFrom() {
    const { profiles } = this.props
    return {
      prompt: '',
      label: 'Account',
      search: false,
      tabIndex: 1,
      defaultValue: profiles[0].id,
      endpoint: '/api/admin/profiles',
      filter: {
        type: {
          $eq: 'emails'
        }
      },
      valueKey: 'id',
      textKey: 'username',
      format: (profile) => <ProfileToken profile={ profile } />,
      onChange: this._handleUpdate.bind(this, 'profile_id')
    }

  }

  _getImport() {
    const { import_email } = this.state
    return {
      prompt: 't(Import into CRM)',
      value: import_email,
      onChange: this._handleImport
    }
  }

  _getIsValid() {
    const { to, subject } = this.state
    return to.length > 0 && (subject && subject.length > 0)
  }

  _getPanel() {
    const { forward, reply } = this.props
    return {
      ...forward ? {
        title: 't(Forward Email)'
      } : reply ? {
        title: 't(Reply to Email)'
      } : {
        title: 't(Compose Email)'
      },
      leftItems: [
        { label: 'Cancel', handler: this._handleCancel }
      ],
      rightItems: [
        { label: 'Send', disabled: !this._getIsValid(), handler: this._handleSend }
      ]
    }
  }

  _getPriority() {
    return {
      placeholder: '',
      options: [
        { value: 'low', text: '!' },
        { value: 'normal', text: '!!' },
        { value: 'high', text: '!!!' }
      ],
      defaultValue: 'normal',
      onChange: this._handleUpdate.bind(this, 'priority')
    }
  }

  _getProfile() {
    const { profile_id } = this.state
    const { profiles } = this.props
    return profiles.find(profile => profile.id === profile_id)
  }

  _getShow() {
    const { showcc } = this.state
    return {
      icon: showcc ? 'chevron-up' : 'chevron-down',
      className: 'maha-email-new-header-row-button',
      handler: this._handleUpdate.bind(this, 'showcc', !showcc)
    }
  }

  _getSubject() {
    const { subject } = this.state
    return {
      placeholder: '',
      tabIndex: 5,
      value: subject,
      onChange: this._handleUpdate.bind(this, 'subject')
    }
  }

  _getTemplates() {
    return {
      icon: 'copy',
      label: 't(Templates)',
      className: 'link',
      handler: this._handleTemplates
    }
  }

  _getTo() {
    const { to } = this.state
    return {
      id: 'to',
      label: 'To',
      tabIndex: 2,
      value: to,
      onChange: this._handleUpdate.bind(this, 'to')
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleForward() {
    const { forward } = this.props
    this.setState({
      subject: `FW: ${forward.email.subject.replace(/re:/ig, '').trim()}`
    })
  }

  _handleImport(value) {
    const { programs } = this.props
    this._handleUpdate('import_email', value)
    if(!value) return this._handleUpdate('import_program_id', null)
    if(programs.length === 1) {
      return this._handleUpdate('import_program_id', programs[0].id)
    }
    const props = {
      onCancel: () => {
        this._handleUpdate('import_email', false)
        this._handleUpdate('import_program_id', null)
        this.context.modal.pop()
      },
      onChoose: (program) => {
        this._handleUpdate('import_program_id', program.id)
        this.context.modal.pop()
      }
    }
    this.context.modal.push(<Program { ...props }/>)
  }

  _handleReply() {
    const { reply, profiles } = this.props
    const cc = reply.all ? reply.email.cc.map(cc => ({
      address: cc.address,
      name: cc.name
    })) : []
    this.setState({
      import_thread_id: reply.email.import_thread_id,
      reply_to_thread: reply.email.thread_id,
      to: [
        ...reply.email.reply_to.length > 0 ? reply.email.reply_to.map(to => ({
          address: to.address,
          name: to.name
        })) : [
          { address: reply.email.from.address, name: reply.email.from.name }
        ],
        ...reply.all ? [
          ...reply.email.to.map(to => ({
            address: to.address,
            name: to.name
          })).filter(address => {
            return profiles.find(profile => {
              return profile.username === address.address
            }) === undefined
          })
        ] : []
      ],
      cc,
      showcc: cc.length > 0,
      subject: `RE: ${reply.email.subject.replace(/re:/ig, '').trim()}`
    })
  }

  _handleSend() {
    const { bcc, body, cc, import_program_id, priority, profile_id, reply_to_thread, subject, to } = this.state
    this.props.onSend({ bcc, body, cc, import_program_id, priority, profile_id, reply_to_thread, subject, to })
  }

  _handleSignature() {
    const profile = this._getProfile()
    const { signature } = profile.preferences
    if(!signature) return
    this.composer.set(`<p>&nbsp;</p><p>&nbsp;</p>${signature}`)
  }

  _handleTemplate(template) {
    const profile = this._getProfile()
    const { signature } = profile.preferences
    this.setState({
      subject: template.subject
    })
    this.composer.set(`${template.body}<p>&nbsp;</p>${signature || ''}`)
  }

  _handleTemplates() {
    const props = {
      onDone: this._handleTemplate
    }
    this.context.modal.push(Templates, props)
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

const mapResources = (props, context) => ({
  ...props.filter ? {
    contacts: {
      endpoint: '/api/admin/crm/contacts',
      filter: props.filter
    }
  } : {}
})

export default Container(mapResources)(Email)
