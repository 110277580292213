import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ value }) => (
  <div className="token">
    <T text={ value } />
  </div>
)

Token.propTypes = {
  value: PropTypes.string
}

export default Token
