const access = {
  entities: [
    { label: 't(Accounts)', code: 'accounts' },
    { label: 't(Banks)', code: 'banks' },
    { label: 't(Deposits)', code: 'deposits' },
    { label: 't(Expenses)', code: 'expenses' },
    { label: 't(Revenue)', code: 'revenue' },
    { label: 't(Projects)', code: 'projects' },
    { label: 't(Vendors)', code: 'vendors' }
  ], 
  rights: [
    { label: 't(Can access all projects)', code: 'access_all_projects' },
    { label: 't(Can manage configuration)', code: 'manage_configuration' },
    { label: 't(Can approve expenses)', code: 'approve_expenses' },
    { label: 't(Can process expenses)', code: 'process_expenses' },
    { label: 't(Can access expense reports)', code: 'access_expense_reports' },
    { label: 't(Can access revenue reports)', code: 'access_revenue_reports' }
  ] 
}

export default access
