import SupportAssignment from '@admin/components/support_assignment'
import AccountToken from '@admin/tokens/account'
import UserToken from '@admin/tokens/user'
import Panel from '@admin/components/panel'
import React from 'react'

const getSupportAssignment = (advisor) => ({
  title: 't(Assign Support Users)',
  entity: 'user',
  format: AccountToken,
  valueKey: 'account_ids',
  action: `/api/admin/truevail/agency/advisors/${advisor.id}/support_users/accounts`,
  assignedEndpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/support_users/accounts`,
  unassignedEndpoint: `/api/admin/truevail/agency/advisors/${advisor.id}/support_users/accounts/all`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Support Users)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/support_users`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: UserToken }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Support Users)',
      text: 't(There are no support users assigned to this advisor)'
    },
    entity: 'support user'
  },
  tasks: {
    items: [
      {
        label: 't(Manage Support Users)',
        modal: <SupportAssignment { ...getSupportAssignment(props.advisor) } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
