import Container from '@admin/components/container'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class CheckboxesField extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    multiple: PropTypes.bool,
    items: PropTypes.array,
    selected: PropTypes.array,
    status: PropTypes.string,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onChange: PropTypes.func,
    onFetch: PropTypes.func,
    onReady: PropTypes.func,
    onSet: PropTypes.func,
    onToggle: PropTypes.func
  }

  static defaultProps = {
    multiple: true,
    textKey: 'title',
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    const { textKey } = this.props
    const sections = this._getSections()
    return (
      <div className="crm-checkboxesfield">
        { sections.map((section, index) => (
          <div className="crm-checkboxesfield-section" key={`section_${index}`}>
            { section.title &&
              <div className="crm-checkboxesfield-header">
                { section.title }
              </div>
            }
            <div className="crm-checkboxesfield-items">
              { section.items.map((item, index) => (
                <div className="crm-checkboxesfield-item" key={`item_${index}`} onClick={ this._handleToggle.bind(this, item)}>
                  <div className="crm-checkboxesfield-item-icon">
                    <Icon icon={ this._getIcon(item) } />
                  </div>
                  <div className="crm-checkboxesfield-item-label">
                    { item[textKey] }
                  </div>
                </div>
              )) }
            </div>
          </div>
        )) }
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this.props.onSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props
    if(!_.isEqual(selected, prevProps.selected)) {
      this._handleChange()
    }
  }

  _getChecked(option) {
    const { selected, valueKey } = this.props
    if(!selected) return false
    return selected.find(item => {
      return valueKey ? item === _.get(option, valueKey) : _.isEqual(item, option)
    }) !== undefined
  }

  _getIcon(item) {
    const shape = this.props.multiple ? 'square' : 'circle'
    return this._getChecked(item) ? `check-${shape}` : `${shape}-o`
  }

  _getSections() {
    const { team } = this.context.admin
    const { items } = this.props
    return team.has_programs ? Object.values(items.reduce((sections, item, index) => ({
      ...sections,
      [item.program.id]: {
        ...item.program,
        items: [
          ...sections[item.program.id] ? sections[item.program.id].items : [],
          item
        ]
      }
    }), {})).sort((a,b) => {
      return a.title > b.title ? 1 : -1
    }) : [
      { items }
    ]
  }

  _handleToggle(item) {
    const { multiple, valueKey } = this.props
    const chosen = valueKey ? _.get(item, valueKey) : item
    this.props.onToggle(multiple, chosen)
  }

  _handleChange() {
    const { selected } = this.props
    this.props.onChange(selected)
  }

}

const mapResources = (props, context) => ({
  items: {
    endpoint: props.endpoint,
    filter: props.filter
  }
})

export default Container(mapResources)(CheckboxesField)