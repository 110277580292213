const badges = [
  { 
    title: 't(Platform)', 
    weight: 10, 
    access: {
      rights: { $allOf: ['platform:access_app'] }
    },
    icon: 'cogs', 
    route: '/admin/platform', 
    visible: ['desktop','tablet','mobile'],
    tooltip: 't(Platform)' 
  }
]

export default badges
