import TaskToken from '@apps/tasks/admin/tokens/task'
import DueToken from '@apps/tasks/admin/tokens/due'
import New from '@apps/tasks/admin/views/tasks/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Tasks)',
  collection: {
    endpoint: '/api/admin/tasks/tasks',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (task) => <TaskToken task={ task } /> },
      { label: 't(Assigned To)', key: 'assignee.full_name' },
      { label: 't(Due)', key: 'due_at', collapsing: true, format: (task) => <DueToken task={ task } /> }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Tasks)',
      text: 't(You have not yet created any tasks for this contact)',
      buttons: [{
        label: 'Create Task',
        modal: <New contact={ props.contact} />
      }]
    },
    entity: 'task',
    defaultQuery: { contact_id: props.contact.id },
    onClick: (record) => context.router.push(`/tasks/tasks/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <New contact={ props.contact} />
  }
})

export default Panel(null, mapPropsToPanel)
