import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Verify extends React.Component {

  static contextTypes = {
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    onNext: PropTypes.func
  }

  state = {
    message: null
  }

  render() {
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon={ this._getIcon() } /></h1>
            <h2><T text="t(Verifying Activation Link)" /></h2>
            { message &&
              <p>{ message }</p>
            }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleVerify()
  }

  _getIcon() {
    const { message } = this.state
    return message ? 'warning' : 'circle-o-notch fa-spin'
  }

  _handleVerify() {
    const { pathname } = this.context.router.location
    const [,token] = pathname.match(/^\/activate\/(.*)/)
    this.context.network.request({
      endpoint: '/api/admin/activate/verify',
      method: 'POST',
      body: {
        token
      },
      onSuccess: ({ data }) => {
        this.props.onNext({
          account: data.account,
          security_questions: data.questions,
          notification_methods: data.notification_methods,
          team: data.team,
          token,
          user: data.user
        })
      },
      onFailure: ({ message }) => {
        this.setState({ message })
      }
    })
  }

}

export default Verify
