import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const BillingCycleToken = ({ cycle_start_date, cycle_end_date }) => (
  <div className="token">
    <Date date={ cycle_start_date } /> - <Date date={ cycle_end_date } />
  </div>
)

BillingCycleToken.propTypes = {
  cycle_start_date: PropTypes.string,
  cycle_end_date: PropTypes.string
}

export default BillingCycleToken
