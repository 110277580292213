import PropTypes from 'prop-types'
import React from 'react'
import RenderedText from '@admin/components/rendered_text'

class FontToken extends React.Component {

  static propTypes = {
    value: PropTypes.object
  }

  render() {
    const { value } = this.props
    return (
      <div className="token" style={{ fontFamily: value.fontFamily.family }}>
        {
          value.type === 'system'
            ? value.fontFamily.family
            : <RenderedText fontType={'google'} fontName={value.fontFamily.family} fontSize={13}>{ value.fontFamily.family }</RenderedText>
        }
      </div>
    )
  }

}

export default FontToken
