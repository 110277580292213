import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Portal from './portal'
import React from 'react'

const PortalContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'portal',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <Portal { ...mergedProps } />

}

PortalContainer.propTypes = {}

export default PortalContainer