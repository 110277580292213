import Img from '@admin/components/html/img'
import React from 'react'

const Image = (attachment) => (
  <div className="maha-attachment-image">
    <Img src={ attachment.image_url} />
  </div>
)

export default Image
