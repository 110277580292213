import SecretsField from '@apps/platform/admin/components/secretsfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Secrets extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    integration: PropTypes.object,
    provider: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { integration, provider } = this.props
    return {
      title: 't(Edit Integration)',
      method: 'PATCH',
      endpoint: `/api/admin/platform/providers/${provider.id}/integrations/${integration.id}/edit`,
      action: `/api/admin/platform/providers/${provider.id}/integrations/${integration.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Mode)', name: 'mode', type: 'dropdown', options: [
              { value: 'development', text: 't(Development)' },
              { value: 'production', text: 't(Production)' }
            ],required: true },
            { label: 't(Secrets)', name: 'secrets', type: SecretsField, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Secrets
