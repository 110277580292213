import Menu from '@admin/components/menu'
import PropTypes from 'prop-types'
import Contact from './contact'
import Payment from './payment'
import Custom from './custom'
import React from 'react'

class Fields extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    fields: PropTypes.array,
    form: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    return (
      <div className="designer-fields">
        <Menu { ...this._getMenu() } />
      </div>
    )
  }

  _getMenu() {
    const { form } = this.props
    const bank_status = _.get(form.program, 'bank.status')
    return {
      items: [
        { label: 't(Contact)', component:  <Contact { ...this._getFields() } /> },
        { label: 't(Custom)', component: <Custom { ...this._getFields() } /> },
        ...bank_status === 'active' ? [
          { label: 't(Payment)', component: <Payment { ...this._getFields() } />  }
        ] : []
      ]
    }
  }

  _getFields() {
    return this.props
  }

}

export default Fields
