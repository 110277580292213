import Edit from '@apps/datasets/admin/views/records/edit'
import New from '@apps/datasets/admin/views/records/new'
import Content from '@apps/forms/admin/tokens/content'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import pluralize from 'pluralize'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Records)',
  collection: {
    endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      ...props.fields.filter(field => {
        return field.is_active
      }).map((field, index) => ({
        label: field.name.value,
        key: `values.${field.name.token}`,
        sort: field.code,
        primary: false,
        visible: false,
        format: ({ values }) => {
          if(field.config.multiple === true) {
            return values[field.code].map((value, index) => (
              <Content key={`data_${index}`} data={{ [field.code]: value }} field={ field } />
            ))
          }
          const value = values[field.code]
          if(!value) return ''
          return <Content data={{
            [field.code]: _.includes(['checkboxes','checkboxgroup'], field.type) ? value : value[0]
          }} field={ field } />
        }
      })),
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    empty: {
      icon: 'file-text',
      title: 't(No Records)',
      text: 't(You have not yet created any records)',
      buttons: [
        { label: 't(Create Record)', modal: <New type={ props.type } fields={ props.fields } dataset={ props.dataset } /> }
      ]
    },
    criteria: {
      fields: [
        {
          label: props.type.title.value,
          fields: [
            ...props.fields.filter(field => {
              return field.is_active
            }).map(field => ({
              name: field.name.value,
              key: field.code,
              type: 'text'
            })),
            { name: 'Status', key: 'status', type: 'select', multiple: true, options: ['draft','published','changed','archived'], format: StatusToken }
          ]
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      ...props.fields.map((field, index) => ({
        label: field.name.value,
        key: `values.${field.code}`
      })),
      { label: 't(Status)', key: 'status' }
    ],
    ...props.dataset.access_type !== 'view' ? {
      recordTasks: (record) => [
        { label: 't(Edit Record)', modal: <Edit type={ props.type } fields={ props.fields } dataset={ props.dataset } record={ record } />},
        {
          label: 't(Publish Record)',
          show: record.status !== 'published',
          request: {
            endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records/${record.id}/publish`,
            method: 'PATCH',
            onFailure: (result) => context.flash.set('error', 't(Unable to publish this record)')
          }
        },
        {
          label: 't(Archive Record)',
          show: record.status !== 'archived',
          request: {
            endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records/${record.id}/archive`,
            method: 'PATCH',
            onFailure: (result) => context.flash.set('error', 't(Unable to archive this record)')
          }
        },
        {
          label: 't(Delete Record)',
          confirm: 't(Are you sure you want to delete this record)',
          request: {
            endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records/${record.id}`,
            method: 'DELETE',
            onFailure: (result) => context.flash.set('error', 't(Unable to delete this record)')
          }
        }
      ],
      selectable: true,
      buttons: (selected, onSuccess) => [{
        color: 'red',
        text: 't(Delete Selected)',
        confirm: `Are you sure you want to delete these ${pluralize('records', selected.total, true)}?`,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records`,
          body: {
            operation: 'delete',
            filter: selected.filter
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to delete records)'),
          onSuccess: (result) => context.flash.set('success', 't(Successfully deleted records)')
        }
      },{
        color: 'red',
        text: 't(Publish Selected)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records`,
          body: {
            operation: 'publish',
            filter: selected.filter
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to publish records)'),
          onSuccess: (result) => context.flash.set('success', 't(You successfully published records)')
        }
      },{
        color: 'red',
        text: 't(Archive Selected)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records`,
          body: {
            operation: 'archive',
            filter: selected.filter
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to archive records)'),
          onSuccess: (result) => context.flash.set('success', 't(You successfully archive records)')
        }
      }]
    } : {},
    entity: 'record',
    defaultSort: { key: props.fields[0].code, order: 'asc' },
    onClick: (record) => context.router.push(`/datasets/datasets/${props.dataset.id}/types/${props.type.id}/records/${record.id}`)
  },
  ...props.dataset.access_type !== 'view' ? {
    tasks: {
      icon: 'plus',
      items: [
        { label: 't(Create Record)', modal: <New type={ props.type } fields={ props.fields } dataset={ props.dataset } />  }
      ]
    }
  } : {}
})

const mapResourcesToPanel = (props, context) => ({
  fields: `/api/admin/datasets_types/${props.type.id}/fields`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
