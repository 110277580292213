import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    platform: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    task: PropTypes.object
  }

  state = {
    task: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { task } = this.state
    return {
      title: 't(Edit Task)',
      method: 'PATCH',
      endpoint: `/api/admin/tasks/tasks/${this.props.task.id}/edit`,
      action: `/api/admin/tasks/tasks/${this.props.task.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Task)', type: 'segment', fields: [
              { name: 'title', type: 'textfield', required: true, placeholder: 't(Enter Title)' },
              { name: 'description', type: 'textarea', placeholder: 't(Enter Description)' }
            ] },
            { label: 't(Assigned To)', name: 'assignee_ids', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', multiple: true, required: true, format: UserToken },
            { label: 't(Due)', type: 'segment', fields: [
              { name: 'is_due', type: 'radiogroup', options: [
                { value: false, text: 'No due date' },
                { value: true, text: 'This task has a due date' }
              ], defaultValue: false },
              ...task.is_due ? [
                { type: 'fields', fields: [
                  { name: 'due_date', type: 'datefield' },
                  { name: 'due_time', type: 'timefield' }
                ] }
              ] : []
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(task) {
    this.setState({ task })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
