import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class User extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    team_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team_id } = this.props
    return {
      title: 't(New User)',
      method: 'POST',
      action: `/api/admin/platform/teams/${team_id}/users`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emaifield', required: true },
            { label: 't(Roles)', name: 'role_ids', type: 'lookup', placeholder: 't(Assign Roles)', multiple: true, endpoint: `/api/admin/platform/teams/${team_id}/roles`, valueKey: 'id', textKey: 'title' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default User
