import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Task extends React.Component {

  static contextTypes = {
    flash: PropTypes.object
  }

  static propTypes = {
    task: PropTypes.object
  }

  render() {
    const { task } = this.props
    return (
      <div className="task-token">
        <Button { ...this._getComplete() } />
        <div className="task-token-label">
          { task.title }
        </div>
      </div>
    )
  }

  _getComplete() {
    const { task } = this.props
    const completed = task.completed_at === null
    return {
      icon: `check-circle-o ${task.completed_at ? 'complete' : 'incomplete'}`,
      className: 'task-token-icon',
      request: {
        endpoint: `/api/admin/tasks/tasks/${task.id}/complete`,
        method: 'PATCH',
        body: { completed },
        onSuccess: () => {},
        onFailure: () => this.context.flash.set('error', 't(Unable to complete task)')
      }
    }
  }

}

export default Task
