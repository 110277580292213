import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    parent: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    return {
      title: 't(New Mileage)',
      method: 'POST',
      action: '/api/admin/finance/trips',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Date of Trip)', name: 'date', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Description)', name: 'description', type: 'textfield', placeholder: 'Describe the trip', required: true },
            { label: 't(Project)', name: 'project_id', type: 'lookup', prompt: 'Choose a project', endpoint: '/api/admin/finance/memberships', valueKey: 'id', textKey: 'display', format: ProjectToken },
            { label: 't(Time Leaving)', name: 'time_leaving', type: 'timefield' },
            { label: 't(Time Arriving)', name: 'time_arriving', type: 'timefield' },
            { label: 't(Odometer Start)', name: 'odometer_start', type: 'textfield' },
            { label: 't(Odometer End)', name: 'odometer_end', type: 'textfield' },
            { label: 't(Distance)', name: 'total_miles', type: 'textfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(trip) {
    const { modal } = this.context
    modal.close()
  }

}

export default New
