import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

class Card extends React.Component {  

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    confirm: PropTypes.object,
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    card: PropTypes.object,
    daterange: PropTypes.object,
    filter: PropTypes.object,
    index: PropTypes.number,
    panel: PropTypes.object,
    isExpanded: PropTypes.bool,
    showChart: PropTypes.bool,
    showTable: PropTypes.bool,
    onReorder: PropTypes.func,
    onToggle: PropTypes.func
  }

  _handleExpand = this._handleExpand.bind(this)

  render() {
    const { panel, isExpanded } = this.props
    const { admin } = this.context
    const Component = this._getComponent()
    return (
      <div className={ this._getClass() } onClick={ this._handleExpand }>
        <div className="maha-dashboard-card-actions">
          { isExpanded &&
            <Button { ...this._getExpand() } />
          }
          { panel.owner.id === admin.user.id &&
            <Button { ...this._getEdit() } />
          }
        </div>
        <Component { ...this._getCard() } />
      </div>
    )
  }

  _getCard() {
    const { card, isExpanded, filter, showChart, showTable } = this.props
    const daterange = card.config.daterange_source === 'custom' ? card.config.daterange : this.props.daterange
    return {
      config: card.config,
      daterange,
      key: JSON.stringify({
        ...card.config,
        daterange
      }),
      isExpanded,
      filter,
      showChart,
      showTable
    }
  }

  _getClass() {
    const { card } = this.props
    const classes = ['maha-dashboard-card', `card-${card.id}`]
    return classes.join(' ')
  }

  _getComponent() {
    const { card } = this.props
    const { maha } = this.context
    const found = _.find(maha.dashboardCards, {
      code: card.type.code
    })
    if(!found) return null
    return found.card
  }

  _getEdit() {
    const { card, panel } = this.props
    return {
      icon: 'ellipsis-v',
      className: 'maha-dashboard-card-action',
      tasks: [
        { 
          label: 't(Edit Card)',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: <Edit panel={ panel } card={ card } /> 
        },
        {
          label: 't(Duplicate Card)',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          request: {
            endpoint: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}/clone`,
            method: 'POST',
            onFailure: () => this.context.flash.set('error','t(Unable to duplicate this card)')
          }
        },
        {
          label: 't(Remove Card)',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          request: {
            endpoint: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}`,
            method: 'DELETE'
          }
        }
      ]
    }
  }

  _getExpand() {
    return {
      icon: 'minus-square-o',
      className: 'maha-dashboard-card-action',
      handler: this.props.onToggle
    }
  }

  _handleExpand() {
    const { isExpanded } = this.props
    if(!isExpanded) this.props.onToggle()
  }

}

export default Card
