import FontFamilyField from '../fontfamilyfield'
import Form from '@admin/components/form'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

const weights = [
  { value: '100', text: 'Thin (100)' },
  { value: '200', text: 'Extra Light (200)' },
  { value: '300', text: 'Light (300)' },
  { value: '400', text: 'Regular (400)' },
  { value: '500', text: 'Medium (500)' },
  { value: '600', text: 'SemiBold (600)' },
  { value: '700', text: 'Bold (700)' },
  { value: '800', text: 'ExtraBold (800)' },
  { value: '900', text: 'Black (900)' }
]

class TextField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    theme: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    text: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(this.state.text === null) return null
    return (
      <div className="mjson-designer-stylefield textfield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { text } = this.state
    if(!_.isEqual(text, prevState.text)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { theme } = this.props
    const { text } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Font)', type: 'segment', fields: [
              { name: 'font', type: 'dropdown', options: [
                { value: '@heading', text: this._getThemeValue('t(Heading)', 'heading') },
                { value: '@text', text: this._getThemeValue('t(Text)', 'text') },
                { value: 'custom', text: 't(Custom)' }
              ], defaultValue: '@heading', value: text.font },
              { name: 'fontFamily', type: FontFamilyField, theme, show: text.font === 'custom', value: text.fontFamily }
            ] },
            { label: 't(Font Weight)', name: 'fontWeight', type: 'dropdown', options: this._getWeights(), defaultValue: '400', value: text.fontWeight },
            { label: 't(Font Size)', name: 'fontSize', type: WebRange, units: [
              { name: 'px', min: 1, max: 100, step: 1 }
            ], defaultValue: '15px', value: text.fontSize },
            { label: 't(Line Height)', name: 'lineHeight', type: WebRange, units: [
              { name: '%', min: 100, max: 200, step: 1 }
            ], defaultValue: '160%', value: text.lineHeight },
            { label: 't(Letter Spacing)', name: 'letterSpacing', type: WebRange, units: [
              { name: 'px', min: 0, max: 5, step: 1 }
            ], nullValues: ['0px'], defaultValue: 0, value: text.letterSpacing }
          ]
        }
      ]
    }
  }

  _getThemeValue(label, key) {
    const { fonts } = this.props.theme
    const parts = [label]
    if(fonts[key]) parts.push(`(${fonts[key].fontFamily.family})`)
    return parts.join(' ')    
  }

  _getWeights(){
    const { text } = this.state
    return weights
    // if(!text.fontFamily) return []
    // return weights.filter(weight => {
    //   return _.includes(text.fontFamily.variants, weight.value)
    // })
  }

  _handleChange() {
    const { text } = this.state
    if(text.font === 'custom' && !text.fontFamily.family && !text.fontFamily.asset_id) return
    this.props.onChange({
      fontFamily: text.font === 'custom' ? text.fontFamily : text.font,
      fontWeight: text.fontWeight,
      fontSize: text.fontSize,
      lineHeight: text.lineHeight,
      letterSpacing: text.letterSpacing
    })
  }

  _handleSet(text) {
    this.setState({
      text: {
        font: '@text',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '0px',
        ...text ? {
          font: _.isString(text.fontFamily) ? text.fontFamily : 'custom',
          fontFamily: _.isObject(text.fontFamily) ? text.fontFamily : {},
          fontWeight: text.fontWeight,
          fontSize: text.fontSize,
          lineHeight: text.lineHeight,
          letterSpacing: text.letterSpacing
        } : {}
      }
    })
  }

  _handleUpdate(text) {
    this.setState({ text })
  }

}

export default TextField
