import Dropdown from '@admin/components/form/dropdown'
import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import EditPanel from '../../panels/edit'
import NewCard from '../../cards/new'
import PropTypes from 'prop-types'
import React from 'react'

class Toolbar extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    cards: PropTypes.array,
    daterange: PropTypes.object,
    panel: PropTypes.object,
    onDateRange: PropTypes.func
  }

  render() {
    return (
      <div className="maha-dashboard-panel-grid-toolbar">
        <div className="maha-dashboard-panel-grid-toolbar-section">
          <div className="maha-dashboard-panel-grid-toolbar-field">
            <Dropdown { ...this._getType()} />
          </div>
          <div className="maha-dashboard-panel-grid-toolbar-field">
            <Dropdown { ...this._getStep()} />
          </div>
        </div>
        <div className="maha-dashboard-panel-grid-toolbar-actions">
          <Button { ...this._getAdd() } />
          <Button { ...this._getEdit() } />
          <Button { ...this._getDelete() } />
        </div>        
      </div>
    )
  }

  _getAdd() {
    const { card_types, panel } = this.props
    return {
      icon: 'plus',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      tooltip: 't(Add a Card)',
      modal: <NewCard card_types={ card_types } panel={ panel } />
    }
  }

  _getDelete() {
    const { panel } = this.props
    return {
      icon: 'trash-o',
      tooltip: 't(Delete Panel)',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      confirm: 't(Are you sure you want to delete this panel?)',
      request: {
        method: 'DELETE',
        endpoint: `/api/admin/dashboards/panels/${panel.id}`
      }
    }
  }

  _getEdit() {
    const { panel } = this.props
    return {
      icon: 'pencil',
      tooltip: 't(Edit Panel)',
      className: 'maha-dashboard-panel-grid-toolbar-action',
      modal: <EditPanel panel={ panel } />
    }
  }

  _getStep() {
    const { daterange } = this.props
    return {
      options: [
        { value: 'day', text: 't(Group by day)' },
        { value: 'week', text: 't(Group by week)' },
        { value: 'quarter', text: 't(Group by quarter)' },
        { value: 'month', text: 't(Group by month)' },
        { value: 'year', text: 't(Group by year)' }
      ],
      value: daterange.step,
      onChange: this._handleDateRange.bind(this, 'step')
    }
  }

  _getType() {
    const { daterange } = this.props
    return {
      options: [
        { value: '30_days', text: 't(Last 30 days)' },
        { value: '60_days', text: 't(Last 60 days)' },
        { value: '90_days', text: 't(Last 90 days)' },
        { value: 'this_week', text: 't(This Week)' },
        { value: 'last_week', text: 't(Last Week)' },
        { value: 'this_month', text: 't(This Month)' },
        { value: 'last_month', text: 't(Last Month)' },
        { value: 'this_quarter', text: 't(This Quarter)' },
        { value: 'last_quarter', text: 't(Last Quarter)' },
        { value: 'this_year', text: 't(This Year)' },
        { value: 'last_year', text: 't(Last Year)' },
        { value: 'custom', text: 't(Custom)' }
      ],
      value: daterange.type,
      onChange: this._handleDateRange.bind(this, 'type')
    }
  }

  _handleDateRange(key, value) {
    const { daterange } = this.props
    this.props.onDateRange({
      ...daterange,
      [key]: value
    })
  }

}

export default Toolbar
