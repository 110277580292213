import Container from '@admin/components/container'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class NotificationTypes extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.array,
    endpoint: PropTypes.string,
    items: PropTypes.array,
    notifications: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    ignored: [],
    status: 'pending'
  }

  render() {
    const { items } = this.props
    return (
      <div>
        <p><T text="t(When you receive a notification, we will try our best to deliver every notification to you in the following order:in app, push, and then email. You can customize which notifications your wish to receive via which methods)" /></p>
        <div className="maha-notification-types">
          { items.map((app, index) => (
            <>
              <div className="maha-notification-types-app" key={`app_${index}`}>
                <Icon icon={`${app.icon}`} />
                { app.title }
              </div>
              { app.items.map((item, i) => (
                <div className="maha-notification-types-notification" key={`notification_${i}`}>
                  <div className="maha-notification-types-notification-label">
                    { item.description }<br />
                  </div>
                  <div className="maha-notification-types-notification-methods">
                    <div className="maha-notification-types-notification-method" onClick={ this._handleToggle.bind(this, item.id, 'inapp') }>
                      <i className={ this._getChecked(item.id, 'inapp') } />
                      In App
                    </div>
                    <div className="maha-notification-types-notification-method" onClick={ this._handleToggle.bind(this, item.id, 'push') }>
                      <i className={ this._getChecked(item.id, 'push') } />
                      Push
                    </div>
                    <div className="maha-notification-types-notification-method" onClick={ this._handleToggle.bind(this, item.id, 'email') }>
                      <i className={ this._getChecked(item.id, 'email') } />
                      Email
                    </div>
                  </div>
                </div>
              )) }
            </>
          ))}
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { ignored } = this.state
    if(!_.isEqual(ignored, prevState.ignored)) {
      this._handleChange()
    }
  }

  _handleChange() {
    const { ignored } = this.state
    this.props.onChange(ignored.filter(value => {
      return !(value.inapp_enabled && value.push_enabled && value.email_enabled)
    }))
  }

  _getChecked(notification_type_id, method) {
    const { ignored } = this.state
    const record = _.find(ignored, { notification_type_id })
    const checked = record === undefined || record[`${method}_enabled`] === true
    return checked ? 'fa fa-check-circle' : 'fa fa-circle-o'
  }

  _handleSet(ignored) {
    this.setState({ ignored })
  }

  _handleToggle(notification_type_id, method) {
    const { ignored } = this.state
    const included = _.find(ignored, {
      notification_type_id: notification_type_id
    }) !== undefined
    this.setState({
      ignored: [
        ...ignored.map(item => ({
          ...item,
          ...item.notification_type_id === notification_type_id ? {
            [`${method}_enabled`]: !item[`${method}_enabled`]
          } : {}
        })),
        ...!included ? [{
          notification_type_id,
          inapp_enabled: true,
          push_enabled: true,
          email_enabled: true,
          [`${method}_enabled`]: false
        }] : []
      ]
    })
  }

}

const mapResources = (props, context) => ({
  items: props.endpoint
})

export default Container(mapResources)(NotificationTypes)