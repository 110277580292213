import CallToken from '@apps/phone/admin/tokens/call'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Calls)',
  collection: {
    endpoint: '/api/admin/phone/calls',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Call)', key: 'from_number.formatted', sort: 'from', primary: true, format: (call) => (
        <CallToken call={ call } perspective="program" />
      ) }
    ],
    defaultQuery: { phone_number_id: props.number.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'phone',
      title: 't(No Calls)',
      text: 't(This number has not yet made or received any calls)'
    },
    entity: 'call',
    onClick: (record) => context.router.push(`/phone/calls/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
