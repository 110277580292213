import useActionReducer from '@admin/hooks/use_action_reducer'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Emojis from './emojis'
import React from 'react'

const EmojisContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'emojis',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <Emojis { ...mergedProps } />

}

EmojisContainer.propTypes = {}

export default EmojisContainer