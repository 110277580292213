import LayoutField from '@admin/components/mjson_designer/components/layoutfield'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    config: {
      sections: 1,
      rows: 1,
      columns: 1,
      layout: [12]
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { website } = this.props
    return {
      title: 't(New Page)',
      method: 'POST',
      action: `/api/admin/websites/websites/${website.id}/pages`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'config', type: 'hidden', value: this._getConfig() },
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 'Enter a title', required: true },
            { label: 't(URL)', name: 'path', type: 'textfield', prefix: website.maha_host, placeholder: '/path', required: true },
            { label: 't(Template)', name: 'template_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/templates`, valueKey: 'id', textKey: 'title', required: true },
            { label: 't(Page)', type: 'segment', fields: [
              { label: 't(Sections)', name: 'sections', type: 'optionsfield', options: [1,2,3,4,5,6], defaultValue: 1 },
              { label: 't(Rows per Section)', name: 'rows', type: 'optionsfield', options: [1,2,3,4,5,6], defaultValue: 1 },
              { label: 't(Columns per Row)', name: 'columns', type: 'optionsfield', options: [1,2,3,4,5,6], defaultValue: 1 },
              { label: 't(Column Layout)', name: 'layout', type: LayoutField }
            ] }
          ]
        }
      ]
    }
  }

  _getColumn() {
    return {
      id: getCode(5),
      entity: 'column',
      meta: {
        label: 'Column'
      },
      settings: {},
      styles: {
        padding: '15px'
      },
      content: {
        children: []
      }
    }
  }

  _getRow() {
    const { config } = this.state
    return {
      id: getCode(5),
      entity: 'row',
      meta: {
        label: 'Row'
      },
      settings: {},
      styles: {
        padding: '15px',
        rowWidth: 'auto',
        rowAlign: 'center',
        columnHorizontalAlign: 'center',
        columnVerticalAlign: 'middle'
      },
      content: {
        layout: config.layout,
        children: Array(config.columns).fill(0).map(column => {
          return this._getColumn()
        })
      }
    }
  }

  _getSection() {
    const { config } = this.state
    return {
      id: getCode(5),
      entity: 'section',
      meta: {
        label: 'Section'
      },
      settings: {},
      styles: {
        padding: '25px 0 25px 0'
      },
      content: {
        children: Array(config.rows).fill(0).map(column => {
          return this._getRow()
        })
      }
    }
  }

  _getConfig() {
    const { config } = this.state
    return {
      id: getCode(5),
      meta: {
        label: 'Page',
        title: config.title,
        seo_strategy: 'general',
        social_strategy: 'general'
      },
      entity: 'page',
      styles: {},
      content: {
        children: Array(config.sections).fill(0).map(column => {
          return this._getSection()
        })
      }
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(page) {
    this.props.onDone(page)
    this.context.modal.close()
  }

}

export default New
