import SocialShareToken from '@admin/components/mjson_designer/tokens/social_share'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class ShareField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    shares: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.shares) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { shares } = this.state
    if(!_.isEqual(shares, prevState.shares)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { shares } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'networks', type: 'collectionfield', fields: [
              { label: 't(Service)', name: 'service', type: 'dropdown', options: [
                { value: 'facebook', text: 'Facebook' },
                { value: 'linkedin', text: 'LinkedIn' },
                { value: 'pinterest', text: 'Pinterest' }
              ], required: true, cloneable: false },
              { label: 't(Text)', name: 'text', type: 'textfield', required: true }
            ],
            orderable: true, cloneable: false, token: SocialShareToken, value: shares.networks }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { shares } = this.state
    this.props.onChange(shares.networks)
  }

  _handleSet(networks) {
    this.setState({
      shares: {
        networks: networks || []
      }
    })
  }

  _handleUpdate(shares) {
    this.setState({ shares })
  }

}

export default ShareField
