import useActionReducer from '@admin/hooks/use_action_reducer'
import Container from '@admin/components/container'
import ProfileField from './profilefield'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const ProfileFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'profilefield',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <ProfileField { ...mergedProps } />

}

ProfileFieldContainer.propTypes = {}

const mapResources = (props) => ({
  endpoint: props.endpoint,
  query: {
    $filter: {
      type: {
        $in: props.types
      }
    }
  }
})

export default Container(mapResources)(ProfileFieldContainer)