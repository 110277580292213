import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Folder from './folder'
import React from 'react'

class Move extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    items: PropTypes.array
  }

  stackRef = React.createRef()

  state = {
    folders: [],
    status: 'pending'
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeFolder = this._handleChangeFolder.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleUp = this._handleUp.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="drive-move">
          <div className="drive-move-body">
            <Stack { ...this._getStack() } />
          </div>
          <div className="drive-move-footer">
            <Button { ...this._getButton() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Choose Destination)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ]
    }
  }

  _getButton() {
    return {
      label: 't(Move Here)',
      color: 'blue',
      handler: this._handleDone
    }
  }

  _getStack() {
    const { folders } = this.state
    const root = {
      id: null,
      item_id: null,
      label: 't(My Drive)'
    }
    return {
      display_name: 'move',
      cards: [
        { component: Folder, props: this._getFolder(root) },
        ...folders.map((folder, index) => (
          { component: Folder, props: this._getFolder(folder) }
        ))
      ]
    }
  }

  _getFolder(folder) {
    const { items } = this.props
    return {
      items,
      folder,
      onChangeFolder: this._handleChangeFolder,
      onUp: this._handleUp
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeFolder(folder) {
    const { folders } = this.state
    this.setState({
      folders: [
        ...folders,
        folder
      ]
    })
  }

  _handleDone() {
    const { items } = this.props
    const { folders } = this.state
    const folder = folders[folders.length - 1]
    this.context.network.request({
      endpoint: '/api/admin/drive/items/move',
      method: 'PATCH',
      body: { 
        codes: items.map(item => item.code), 
        folder_id: folder ? folder.item_id : null
      },
      onSuccess: () => this.context.modal.close()
    })
  }

  _handleUp() {
    const { folders } = this.state
    this.setState({
      folders: folders.slice(0, -1)
    })
  }

}

export default Move
