export const onSet = (selected) => ({
  type: 'SET',
  selected
})

export const onToggle = (multiple, item) => ({
  type: 'TOGGLE',
  multiple,
  item
})
