import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class MenuField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    menu: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.menu) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { radius } = this.state
    if(!_.isEqual(radius, prevState.radius)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { website } = this.props
    const { menu } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'strategy', type: 'dropdown', options: [
              { value: 'menu', text: 't(Use website menu)' },
              { value: 'manual', text: 't(Manually add links)' }
            ], defaultValue: 'menu', value: menu.strategy },
            ...menu.strategy === 'menu' ? [
              { name: 'menu', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/menus`, compareKey: 'id', valueKey: null, textKey: 'title', value: menu.menu }
            ] : [
              { name: 'links', type: 'textfield', value: menu.links }
            ]
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { menu } = this.state
    this.props.onChange(menu)
  }

  _handleSet(menu) {
    this.setState({
      menu: menu || {
        strategy: 'menu',
        menu: null,
        links: null
      }
    })
  }

  _handleUpdate(menu) {
    this.setState({ menu })
  }

}

export default MenuField
