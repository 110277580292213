import Image from '@admin/components/image'
import Form from '@admin/components/form'
import CaptionField from './captionfield'
import PlacesField from './placesfield'
import PropTypes from 'prop-types'
import Canvas from './canvas'
import React from 'react'

class SocialDesigner extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    editable: PropTypes.bool,
    reference: PropTypes.func,
    social_campaign: PropTypes.object,
    mode: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onSubmit: PropTypes.func
  }

  static defaultProps = {
    editable: true,
    onChange: () => {}
  }

  state = {
    config: null
  }

  formRef = React.createRef()

  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.config) return null
    const { editable } = this.props
    return (
      <div className="campaigns-social-designer">
        <div className="campaigns-social-designer-preview">
          <Canvas { ...this._getCanvas() } />
        </div>
        { editable &&
          <div className="campaigns-social-designer-form">
            <Form { ...this._getForm() } />
          </div>      
        }
      </div>
    )
  }

  componentDidMount() {
    const { reference, social_campaign } = this.props
    if(social_campaign) this._handleSet(social_campaign.entity)
    if(reference) reference({
      submit: this._handleSubmit
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getCanvas() {
    const { config } = this.state
    return {
      config
    }
  }

  _getDisabled() {
    const entity = this._getNormalized(this.props.social_campaign.entity)
    const config = this._getNormalized(this.state.config)
    return _.isEqual(entity, config)
  }

  _getForm() {
    const { features } = this.context.admin
    const { onSave } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      ...onSave ? {
        buttons: [
          { label: 't(Save)', color: 'blue', disabled: this._getDisabled(), handler: this._handleSubmit }
        ]  
      } : {},
      onChange: this._handleUpdate,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Photos)', name: 'content.photos', type: 'mediafield', multiple: true, editor: onSave ? 'modal' : 'inline', allow: { 
              types: ['photos'], unsplash: true 
            }, min_ratio: 0.8, max_ratio: 1.91, show: config.content.type === 'photo', defaultValue: config.content.photos ? config.content.photos.map(({photo}) => photo) : null, required: true },
            { label: 't(Video)', name: 'content.video', type: 'attachmentfield', show: config.content.type === 'video', formatter: (asset) => ({ asset }), requirements: [
              { type: 'type', value: ['mp4','mov'] },
              { type: 'min_duration', value: 3 },
              { type: 'max_duration', value: 60 }
            ], allow: { types: ['videos'], unsplash: true }, prompt: 'Choose Video', multiple: false, defaultValue: config.content.video ? config.content.video.asset_id : null, required: true },
            { label: 't(Reel)', name: 'content.reel', type: 'attachmentfield', show: config.content.type === 'reel', formatter: (asset) => ({ asset }), requirements: [
              { type: 'type', value: ['mp4','mov'] },
              { type: 'ratio', value: 9/16, text: '9:16' },
              { type: 'min_resolution', value: '540x960' },
              { type: 'max_resolution', value: '1080x1920' },
              { type: 'min_duration', value: 3 },
              { type: 'max_duration', value: 60 }
            ], allow: { types: ['videos'], unsplash: true }, prompt: 'Choose Video', multiple: false, defaultValue: config.content.reel ? config.content.reel.asset_id : null, required: true },
            { label: 't(Story)', type: 'segment', fields: [
              { type: 'radiogroup', name: 'content.story_type', options: [
                { value: 'photo', text: 't(Photo Story)' },
                { value: 'video', text: 't(Video Story)' }
              ], defaultValue: config.content.story_type || 'photo' },
              { label: 't(Photo)', name: 'content.story_photo', type: 'mediafield', show: config.content.type === 'story' && config.content.story_type === 'photo', ratio: 9/16, editor: onSave ? 'modal' : 'inline', formatter: (asset) => {
                return { asset }
              }, allow: { types: ['photos'], unsplash: true }, prompt: 'Choose Photo(s)', defaultValue: config.content.story_photo ? config.content.story_photo : null, required: true },
              { label: 't(Video)', name: 'content.story_video', type: 'attachmentfield', show: config.content.type === 'story' && config.content.story_type === 'video', formatter: (asset) => ({ asset }), requirements: [
                { type: 'type', value: ['mp4','mov'] },
                { type: 'ratio', value: 9/16, text: '9:16' },
                { type: 'min_resolution', value: '540x960' },
                { type: 'max_resolution', value: '1080x1920' },
                { type: 'min_duration', value: 3 },
                { type: 'max_duration', value: 60 }
              ], allow: { types: ['videos'], unsplash: true }, prompt: 'Choose Video', multiple: false, defaultValue: config.content.story_video ? config.content.story_video.asset_id : null, required: true }
            ], show: config.content.type === 'story', required: true },
            { label: 't(Link)', name: 'content.link', type: 'linkfield', show: config.content.type === 'link', showPreview: false, formatter: (link) => {
              return { link }
            }, defaultValue: config.content.link ? config.content.link.link : null, required: true },
            { label: 't(Post Caption)', name: 'content.text', type: CaptionField, maxRows: 15, show: _.includes(['photo','link','video','reel'], config.content.type), placeholder: 'Enter a caption for this post', defaultValue: config.content.text, required: true },
            ...features.social_location === true ? [
              { label: 't(Location)', type: 'segment', fields: [
                { name: 'content.add_location', type: 'checkbox', prompt: 't(Add a location)', defaultValue: config.content.add_location || false },
                { name: 'content.location', type: PlacesField, show: config.content.add_location === true, defaultValue: config.content.location, required: true }
              ], show: !_.includes(['story','video'], config.content.type) }
            ] : [],
            { label: 't(Comments)', type: 'segment', fields: [
              { name: 'content.add_first_comment', type: 'checkbox', prompt: 't(Add first comment)', defaultValue: config.content.add_first_comment || false },
              { name: 'content.first_comment', type: 'textarea', show: config.content.add_first_comment === true, rows: 1, placeholder: 't(Enter comment)', defaultValue: config.content.first_comment, required: true }
            ], show: config.content.type !== 'story' }
          ]
        }
      ]
    }
  }

  _getNormalized(config) {
    return {
      ...config,
      content: {
        ...config.content.profile ? {
          profile: config.content.profile
        } : {},
        ...config.content.type === 'link' ? {
          link: config.content.link && config.content.link.link ? {
            link_id: config.content.link.link.id
          } : null
        } : config.content.type === 'photo' ? {
          photos: config.content.photos && config.content.photos.length > 0 ? config.content.photos.map(photo => ({
            photo: {
              asset_id: photo.photo.asset_id,
              transforms: photo.photo.transforms
            }
          })) : null  
        } : config.content.type === 'reel' ? {
          reel: config.content.reel && config.content.reel.asset ? {
            asset_id: config.content.reel.asset.id
          } : null
        } : config.content.type === 'story' ? {
          story_type: config.content.story_type,
          ...config.content.story_type === 'photo' && config.content.story_photo && config.content.story_photo.asset ? {
            story_photo: {
              asset_id: config.content.story_photo.asset_id,
              transforms: config.content.story_photo.transforms  
            }
          } : config.content.story_type === 'video' && config.content.story_video && config.content.story_video.asset ? {
            story_video: {
              asset_id: config.content.story_video.asset.id
            }
          } : {}
        } : config.content.type === 'video' ? {
          video: config.content.video && config.content.video.asset ? {
            asset_id: config.content.video.asset.id
          } : null
        } : {},
        text: config.content.text,
        type: config.content.type,
        add_location: config.content.add_location || false,
        location: config.content.location || null,
        add_first_comment: config.content.add_first_comment || false,
        first_comment: config.content.first_comment || null
      }
    }
  }

  _handleChange() {
    const { config } = this.state
    const entity = this._getNormalized(config)
    this.props.onChange({ entity })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess() {
    const { onSave, onSubmit } = this.props
    const { config } = this.state
    const entity = this._getNormalized(config)
    if(onSave) this.props.onSave({ entity })
    if(onSubmit) this.props.onSubmit({ entity })
  }

  _handleSet(config) {
    this.setState({ config })
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config,
        content: {
          ...this.state.config.content,
          ...config.content,
          ...config.content.photos ? {
            photos: config.content.photos.map(photo => ({
              photo
            }))
          } : {}
        }
      }
    })
  }

}

export default SocialDesigner
