import NumberField from '@admin/components/form/numberfield'
import MoneyField from '@admin/components/form/moneyfield'
import PropTypes from 'prop-types'
import React from 'react'

class AllowancesField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.array,
    plan: PropTypes.object,
    required: PropTypes.bool,
    service: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    allowances: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 50)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { plan } = this.props
    if(!this.state.allowances) return null
    const resources = this._getResources()
    return (
      <div className="maha-input">
        <div className="subscriptions-allowancesfield">
          <table className="ui compact celled unstackable table">
            <thead>
              <tr>
                <th>Resource</th>
                <th>Included</th>
                { plan.type === 'paid' &&
                  <th>Unit Price</th>
                }
              </tr>
            </thead>
            <tbody>
              { resources.length === 0 &&
                <tr>
                  <td colSpan="3">There are no active resources for this service</td>
                </tr>
              }
              { resources.map((resource, index) => (
                <tr key={`allowance_${index}`}>
                  <td>{ resource.title.value }</td>
                  <td><NumberField { ...this._getIncluded(resource, index) } /></td>
                  { plan.type === 'paid' &&
                    <td><MoneyField { ...this._getPrice(resource, index) } /></td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { allowances } = this.state
    const { value } = this.props
    if(!_.isEqual(allowances, prevState.allowances)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAllowance(resource) {
    const { allowances } = this.state
    const index = _.findIndex(allowances, { resource_id: resource.id })
    return allowances[index] || {
      resource_id: resource.id,
      included: 1,
      price: resource.price
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getIncluded(resource, index) {
    const { tabIndex } = this.props
    const allowance = this._getAllowance(resource)
    return {
      tabIndex,
      value: Number(allowance.included),
      onChange: this._handleUpdate.bind(this, allowance.resource_id, 'included')
    }
  }

  _getPrice(resource, index) {
    const { tabIndex } = this.props
    const allowance = this._getAllowance(resource)
    return {
      tabIndex,
      value: Number(allowance.price),
      onChange: this._handleUpdate.bind(this, allowance.resource_id, 'price')
    }
  }

  _getResources() {
    const { service } = this.props
    return service.resources.filter(resource => {
      return resource.is_active
    })
  }

  _handleChange() {
    const { allowances } = this.state
    this.props.onChange(allowances)
  }

  _handleSet(allowances) {
    const resources = this._getResources()
    this.setState({
      allowances: resources.map(resource => {
        const allowance = allowances ? allowances.find(allowance => {
          return allowance.resource_id === resource.id
        }) : null
        return {
          id: allowance ? allowance.id : null,
          resource_id: allowance ? allowance.resource_id : resource.id,
          included: allowance ? allowance.included : 1,
          price: allowance ? allowance.price : resource.price
        }
      })
    })
  }

  _handleUpdate(resource_id, key, value) {
    const { allowances } = this.state
    this.setState({
      allowances: allowances.map(allowance => ({
        ...allowance,
        ...allowance.resource_id === resource_id ? {
          [key]: value
        } : {}
      }))
    })
  }

  _handleValidate() {
    const { allowances } = this.state
    const { required } = this.props
    const invalid = allowances.find(allowance => {
      return !allowance.included || allowance.price === 0
    }) !== undefined
    if(required === true && invalid) {
      this.props.onValid(allowances, ['You must complete the allowance table for every resource'])
    } else {
      this.props.onValid(allowances)
    }
  }

}

export default AllowancesField
