import SignersField from '@apps/crm/admin/components/signersfield'
import ProfileToken from '@apps/maha/admin/tokens/profile'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    platform: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    deal: PropTypes.object,
    program: PropTypes.object,
    profiles: PropTypes.array,
    onAccount: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact, deal, profiles, program } = this.props
    return {
      title: 't(Send Agreement)',
      method: 'POST',
      action: '/api/admin/crm/agreements',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      saveText: 'Send',
      sections: [
        {
          fields: [
            { name: 'contact_id', type: 'hidden', value: contact.id },
            ...deal ? [
              { name: 'deal_id', type: 'hidden', value: deal.id }
            ] : [],
            { label: 't(Signing Account)', name: 'profile_id', type: 'dropdown', endpoint: '/api/admin/profiles', valueKey: 'id', textKey: 'username', filter: { type: { $eq: 'signatures' } }, required: true, defaultValue: profiles.length > 0 ? profiles[0].id : null, format: ProfileToken, after: (
              <Button { ...this._getAccount() } />
            ) },
            ...program ? [
              { name: 'program_id', type: 'hidden', value: program.id }
            ] : [
              { label: 't(Program)', name: 'program_id', type: 'lookup', endpoint: '/api/admin/programs', valueKey: 'id', textKey: 'title', required: true, format: ProgramToken }
            ],
            { label: 't(Document)', name: 'unsigned_id', type: 'attachmentfield', multiple: false, prompt: 't(Choose File)', required: true },
            { label: 't(Signers)', name: 'signers', type: SignersField, contact, required: true, instructions: `
              Enter the an email address for each individual who should sign this
              agreement in the order you want them to sign
            ` }
          ]
        }
      ]
    }
  }

  _getAccount() {
    const { onAccount } = this.props
    return {
      label: 't(Manage signing accounts)',
      className: 'link',
      handler: onAccount
    }
  }

  _getTokens() {
    return {
      label: 't(You can use the following tokens in your document)',
      className: 'link'
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agreement) {
    this.context.router.push(`/admin/crm/agreements/${agreement.id}`)
    this.context.modal.close()
  }

}

export default New
