import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ program }) => {

  const details = {
    audits: `crm_programs/${program.id}`,
    comments: `crm_programs/${program.id}`,
    sections: [
      {
        title: 't(Program Details)',
        items: [
          { label: 't(Title)', content: program.title },
          { label: 't(Invoice Address)', content: <span dangerouslySetInnerHTML={{ __html: program.address ? program.address.replace(/\n/g,'<br />') : 'NONE' }} /> },
          { label: 't(Bank Account)', content: program.bank ? program.bank.title : 'NONE' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  program: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview program={ props.program } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Program)',
        access: { rights: { $oneOf: ['team:manage_programs']} }, 
        modal: <Edit id={ props.program.id } fields={ props.fields } /> 
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  fields: `/api/admin/crm_programs/${props.program.id}/fields`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
