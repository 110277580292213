import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  ach: {
    title: 't(Bank Refund)',
    text: 't(Refund money back to bank account)'
  },
  card: {
    title: 't(Card Refund)',
    text: 't(Refund money back to credit card)'
  },
  paypal: {
    title: 't(PayPal Refund)',
    text: 't(Refund money back to PayPal account)'
  },
  credit: {
    title: 't(Customer Credit)',
    text: 't(Refund money to a customer credit)'
  }
}

const RefundStrategyToken = ({ value }) => (
  <div className="type-token">
    <strong><T text={ strategies[value].title } /></strong><br />
    <T text={ strategies[value].text } />
  </div>
)

RefundStrategyToken.propTypes = {
  value: PropTypes.string
}

export default RefundStrategyToken
