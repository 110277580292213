import Page from '@admin/components/page'
import Programs from './programs'
import Overview from './overview'
import Manage from '../manage'
import Edit from '../edit'
import React from 'react'

const getTabs = ({ app, bank, programs }) => ({
  items: [
    { label: 't(Overview)', component: <Overview bank={ bank } integration={ app.settings.integration } /> },
    { label: 't(Programs)', component: <Programs bank={ bank } programs={ programs } /> }
  ]
})

const getTasks = ({ app, bank }) => {
  const items = [{
    label: 't(Edit Bank)',
    access: { rights: { $oneOf: ['finance:manage_banks'] } },
    modal: <Edit bank={ bank } integration={ app.settings.integration } />
  }, {
    label: 't(Manage Programs)',
    access: { rights: { $oneOf: ['finance:manage_banks'] } },
    modal: <Manage bank={ bank } />
  }]
  if(!bank.braintree_id && !bank.applied_on) {
    items.push({
      label: 't(Signup with Braintree)',
      link: 'https://apply.braintreegateway.com/signup/us?partner_source=referrer_id=0062E00001GnA8AQAV_0032E00002bbcZ4QAI'
    })
  }
  return { items }
}

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  bank: `/api/admin/finance/banks/${props.params.id}`,
  programs: `/api/admin/finance/banks/${props.params.id}/programs`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Bank Account)',
  access: { rights: { $oneOf: ['finance:view_banks','finance:manage_banks'] } },
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
