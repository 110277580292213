const colorsets = [
  { light: '#FFFFFF', dark: '#192B42', base: '#B2A385', accent1: '#5FA3AB', accent2: '#4A868D' },
  { light: '#FFFFFF', dark: '#202020', base: '#C6D7DB', accent1: '#FAF7F0', accent2: '#3F444F' },
  { light: '#FFFFFF', dark: '#21262D', base: '#69799B', accent1: '#ECEAEA', accent2: '#33415E' },
  { light: '#FFFFFF', dark: '#232427', base: '#264853', accent1: '#DECA8E', accent2: '#93B6AC' },
  { light: '#FFFFFF', dark: '#2A3030', base: '#304C4D', accent1: '#E5E6D5', accent2: '#F2E1D5' },
  { light: '#FFFFFF', dark: '#131313', base: '#E4DAD0', accent1: '#FDFAF4', accent2: '#827363' },
  { light: '#FFFFFF', dark: '#23253F', base: '#945D65', accent1: '#F2E1D5', accent2: '#CAD1E5' },
  { light: '#FFFFFF', dark: '#00192F', base: '#32515E', accent1: '#E1EABB', accent2: '#75ACA7' },
  { light: '#FFFFFF', dark: '#2A3030', base: '#B1B288', accent1: '#E8E2D4', accent2: '#F5C389' },
  { light: '#FFFFFF', dark: '#383B47', base: '#707480', accent1: '#F2E1D5', accent2: '#EAEBEF' }
].map(colorset => ({
  value: colorset
}))

export default colorsets
