import Search from '@admin/components/search'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Program extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getAccess(card_type) {
    const { admin } = this.context
    if(card_type.app.code === 'maha') return true
    return admin.hasRights({ 
      allOf: [
        `${card_type.app.code}:access_app`,
        ...card_type?.access?.rights?.$allOf || []
      ] 
    })
  }

  _getSearch() {
    const { formdata } = this.props
    return {
      autofocus: true,
      options: formdata.card_types.filter(card_type => {
        return this._getAccess(card_type)
      }).map(card_type => ({
        ...card_type,
        group: `t(${card_type.app.title})`
      })),
      format: (card_type) => (
        <div className="token">
          <strong><T text={ card_type.title } /></strong><br />
          <T text={ card_type.description } />
        </div>
      ),
      group: true,
      search: false,
      label: 'card',
      onChange: this._handleChoose,
      valueKey: null,
      textKey: 'label'
    }
  }

  _handleChoose(type) {
    this.props.onNext({ type })
  }

}

export default Program
