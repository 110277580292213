import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Settings from './settings'
import Types from './types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    panel: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { panel } = this.props
    return {
      title: 't(Add Card)',
      action: `/api/admin/dashboards/panels/${panel.id}/cards`,
      method: 'POST',
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: this._getProps(),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { card_types } = this.props
    return {
      card_types
    }
  }

  _getData(card) {
    return {
      type_id: card.type.id,
      title: card.title,
      config: card.config
    }
  }

  _getProps() {
    const { programs } = this.context.admin
    return {
      programs
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Type)', component: Types },
      { label: 't(Settings)', component: Settings }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
