import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class SuppressField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    suppress: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.suppress) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { suppress } = this.state
    if(!_.isEqual(suppress, prevState.suppress)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { suppress } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'after_interaction', text: 't(Hide after interaction)' },
              { value: 'after_once', text: 't(Show once then hide)' },
              { value: 'never', text: 't(Never hide)' }
            ], defualtValue: 'after_interaction', value: suppress.type },
            { name: 'hide_for', show: suppress.type !== 'never', type: 'radiogroup', options: [
              { value: 'days', text: 't(Hide for a specific period of time)' },
              { value: 'forever', text: 't(Hide forever)' }
            ], defualtValue: 'days', value: suppress.hide_for },
            { name: 'days', show: suppress.type !== 'never' && suppress.hide_for === 'days', type: 'numberfield', units: 'days', defaultValue: 5, value: suppress.days }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _handleChange() {
    const { suppress } = this.state
    this.props.onChange(suppress)
  }

  _handleSet(suppress) {
    this.setState({ suppress })
  }

}

export default SuppressField
