import SignatureField from '../../fields/signaturefield'
import AddressField from '../../fields/addressfield'
import HiddenField from '../../fields/hiddenfield'
import NumberField from '../../fields/numberfield'
import MoneyField from '../../fields/moneyfield'
import CheckBoxes from '../../fields/checkboxes'
import RadioGroup from '../../fields/radiogroup'
import PhoneField from '../../fields/phonefield'
import DateField from '../../fields/datefield'
import FileField from '../../fields/filefield'
import TextField from '../../fields/textfield'
import TimeField from '../../fields/timefield'
import TextArea from '../../fields/textfield'
import DropDown from '../../fields/dropdown'
import Checkbox from '../../fields/checkbox'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import Text from '../../fields/text'
import PropTypes from 'prop-types'
import React from 'react'

class Custom extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    fields: PropTypes.array,
    form: PropTypes.object
  }

  render() {
    const fields = this._getFields()
    return (
      <div className="flowchart-designer-blocks">
        <p><T text="t(These fields belong to the form and will not update the contact properties when the form is submitted)" /></p>
        { fields.map((field, index) => (
          <div className="flowchart-designer-block" key={`field_${index}`} { ...this._getField(field) }>
            <div className="flowchart-designer-block-icon action">
              <Icon icon={`${ field.icon }`} />
            </div>
            <div className="flowchart-designer-block-label">
              <T text={ field.label } />
            </div>
          </div>
        )) }
      </div>
    )
  }

  _getFields() {
    const { features } = this.context.admin
    return [
      { label: 't(Address Field)', icon: 'map-marker', type: 'addressfield', component: AddressField },
      { label: 't(Checkbox)', icon: 'check-square', type: 'checkbox', component: Checkbox },
      { label: 't(Checkboxes)', icon: 'check-square-o', type: 'checkboxgroup', component: CheckBoxes },
      { label: 't(Date Field)', icon: 'calendar', type: 'datefield', component: DateField },
      { label: 't(Drop Down)', icon: 'caret-square-o-down', type: 'dropdown', component: DropDown },
      { label: 't(File Upload)', icon: 'cloud-upload', type: 'filefield', component: FileField },
      { label: 't(Hidden Field)', icon: 'eye-slash', type: 'hiddenfield', component: HiddenField },
      { label: 't(Instructions)', icon: 'align-left', type: 'text', component: Text },
      { label: 't(Money Field)', icon: 'dollar', type: 'moneyfield', component: MoneyField },
      { label: 't(Number Field)', icon: 'hashtag', type: 'numberfield', component: NumberField },
      { label: 't(Phone Field)', icon: 'phone', type: 'phonefield', component: PhoneField },
      { label: 't(Radio Group)', icon: 'circle-o', type: 'radiogroup', component: RadioGroup },
      ...features.agreements ? [{ label: 't(Signature Field)', icon: 'pencil', type: 'signaturefield', component: SignatureField }] : [],
      { label: 't(Text Area)', icon: 'font', type: 'textarea', component: TextArea },
      { label: 't(Text Field)', icon: 'font', type: 'textfield', component: TextField },
      { label: 't(Time Field)', icon: 'clock-o', type: 'timefield', component: TimeField }
    ]
  }

  _getField(field) {
    return {
      draggable: true,
      onDragStart: this._handleDragStart.bind(this, field)
    }
  }

  _handleDragStart(field, e) {
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'all'
    e.dataTransfer.setData('field', JSON.stringify({
      type: field.type
    }))
  }

}

export default Custom
