import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import TransformField from '@admin/components/mjson_designer/components/transformfield'
import React from 'react'

export const Transform = (props) => <ResponsiveField { ...props } component={ TransformField } />

export const TransformSection = (config, device, theme, website, type) => ({
  label: 't(Transform)',
  instructions: 't(Specify effects that alter the appearance or behavior of this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.transform', device, type: Transform, value: config.styles.transform }
  ]
})
