import ServiceToken from '@apps/maha/admin/tokens/service'
import Panel from '@admin/components/panel'
import ModeToken from '@admin/tokens/mode'
import Edit from './edit'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Integrations)',
  collection: {
    endpoint: `/api/admin/platform/providers/${props.provider.id}/integrations`,
    table: [
      { label: 't(ID)', key: 'id', sort: 'id', collapsing: true, visible: false },
      { label: 't(Service)', key: 'service.name', sort: 'service', primary: true, format: (integration) => (
        <ServiceToken service={ integration.service } />
      )},
      { label: 't(Mode)', key: 'mode', collapsing: true, format: ModeToken },
      { label: 't(Active)', key: 'is_active', collapsing: true, format: 'check' }
    ],
    recordTasks: (record) => [
      { label: 't(Edit Integration)', modal: <Edit provider={ props.provider } integration={ record } /> },
      { 
        label: 't(Activate Integration)',
        show: !record.is_active
      },
      { 
        label: 't(Dectivate Integration)',
        show: record.is_active
      }
    ],
    defaultSort: { key: 'service', order: 'asc' },
    empty: {
      icon: 'cubes',
      title: 't(No Integrations)',
      text: 't(You have not yet created any integrations for this provider)',
      buttons: [
        {
          label: 't(Create Integration)',
          modal: <New provider={ props.provider } />
        }        
      ]
    },
    entity: 'integration'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Integration)',
    modal: <New provider={ props.provider } />
  }
})

export default Panel(null, mapPropsToPanel)
