import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const statuses = {
  error: { icon: 'times', label: 'Unable to save'},
  published: { icon: 'star', label: 'Published' },
  publishing: { icon: 'circle-o-notch fa-spin', label: 'Publishing' },
  redoing: { icon: 'circle-o-notch fa-spin', label: 'Redoing' },
  saved: { icon: 'check', label: 'Saved' },
  saving: { icon: 'circle-o-notch fa-spin', label: 'Saving' },
  undoing: { icon: 'circle-o-notch fa-spin', label: 'Undoing' }
}

class VersionsFooter extends React.PureComponent {

  static propTypes = {
    status: PropTypes.string
  }

  render() {
    const status = statuses[this.props.status]
    return (
      <div className="maha-versions-footer">
        <Icon icon={ status.icon } /> <T text={ status.label } />
      </div>
    )
  }

}

export default VersionsFooter
