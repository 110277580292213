import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { contact } = this.props
    return {
      title: 't(New Invoice)',
      action: '/api/admin/finance/invoices',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        contact
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(invoice) {
    const { program } = this.context.admin.team
    const { contact } = this.props
    return {
      program_id: program.id,
      contact_id: contact.id,
      line_items: invoice.line_items,
      date: invoice.date,
      due: invoice.due,
      notes: invoice.notes
    }
  }

  _getSteps() {
    return [
      { label: 't(Type)', component: Type },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(invoice) {
    this.context.router.push(`/finance/invoices/${invoice.id}`)
    this.context.modal.close()
  }

}

export default New
