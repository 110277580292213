import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const ResourcesToken = ({ resources }) => (
  <div className="subscriptions-allowances-token">
    <table className="ui celled compact unstackable table">
      <thead>
        <tr>
          <th>Item</th>
          <th className="collapsing">Unit Price</th>
        </tr>
      </thead>
      <tbody>
        { resources.map((resource, index) => (
          <tr key={`resource_${index}`}>
            <td>{ resource.title.value } { !resource.is_active &&
              <span className="alert">INACTIVE</span>
            }</td>
            <td className="right aligned">{ numeral(resource.price).format('0.00') }</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

ResourcesToken.propTypes = {
  resources: PropTypes.array
}

export default ResourcesToken
