import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import reducer from './reducer'
import Search from './search'
import React from 'react'

import { useLocaleContext } from '@admin/components/locale'

const SearchContainer = (props) => {

  const { locale } = useLocaleContext()

  const [state, handlers] = useActionReducer({
    display_name: 'search',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      locale
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Search { ...mergedProps } />

}

SearchContainer.propTypes = {}

export default SearchContainer
