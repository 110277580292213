import { client, usBankAccount } from 'braintree-web'
import Container from '@admin/components/container'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class BraintreeACHField extends React.Component {

  static propTypes = {
    error: PropTypes.string,
    isProcessing: PropTypes.bool,
    payment: PropTypes.object,
    program: PropTypes.object,
    result: PropTypes.object,
    status: PropTypes.string,
    token: PropTypes.string,
    onAuthorize: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdate: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    config: {},
    error: null,
    payment: null,
    result: null,
    status: 'pending' 
  }

  _handleAuthorize = this._handleAuthorize.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { error, payment, status } = this.state
    const isProcessing =_.includes(['authorizing','authorized','submitting'],status)
    return (
      <div className="maha-payment-ach">
        { status === 'authorized' ?
          <>{ payment.reference }</> :
          <>
            <Form { ...this._getForm() } />
            { error &&
              <div className="maha-payment-error">{ error }</div>
            }
            <p className="maha-payment-mandate">
              { this._getMandate() }
            </p>
            { isProcessing ?
              <button className="ui large fluid blue disabled button">
                <Icon icon="circle-o-notch fa-spin fa-fw" /> Validating Bank Account
              </button> :
              <Button { ...this._getButton() } />
            }
          </>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.state
    if(status !== prevState.status && status === 'authorized') {
      this._handleChange()
    }
  }

  _getButton() {
    return {
      label: 't(Validate Bank Account)',
      color: 'blue',
      disabled: this._getDisabled(),
      handler: this._handleAuthorize
    }
  }

  _getDisabled() {
    const { account, routingNumber, accountNumber, businessName, name, address } = this.state.config
    const ownershipType = this._getOwnershipType(account)
    return !(routingNumber && accountNumber && ((ownershipType === 'personal' && name) || (ownershipType === 'business' && businessName)) && address)
  }

  _getForm() {
    const { config } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Account Type)', name: 'account', type: 'dropdown', options: [
              { value: 'personal_checking', text: 't(Personal Checking)' },
              { value: 'personal_savings', text: 't(Personal Savings)' },
              { value: 'business_checking', text: 't(Business Checking)' },
              { value: 'business_savings', text: 't(Business Savings)' }
            ] },
            { type: 'fields', fields: [
              { label: 't(Routing Number)', name: 'routingNumber', type: 'numberfield', show: this._getOwnershipType(config.account) !== null },
              { label: 't(Account Number)', name: 'accountNumber', type: 'numberfield', show: this._getOwnershipType(config.account) !== null }
            ] },
            { label: 't(Business Name)', name: 'businessName', type: 'texfield', show: this._getOwnershipType(config.account) === 'business' },
            { label: 't(Name)', name: 'name', type: 'texfield', show: this._getOwnershipType(config.account) === 'personal' },
            { label: 't(Address)', name: 'address', type: 'addressfield', show: this._getOwnershipType(config.account) !== null }
          ]
        }
      ]
    }
  }

  _getOwnershipType() {
    const { account } = this.state.config
    return account ? account.split('_')[0] : null
  }

  _getMandate() {
    const { program } = this.props
    return `By clicking the button below, I authorize Braintree, a service of PayPal, on behalf of ${program.title} (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.`
  }

  _getInput(name, placeholder, tabIndex) {
    return {
      placeholder,
      tabIndex,
      value: this.props[name],
      onChange: this._handleUpdate.bind(this, name)
    }
  }

  _getName() {
    const { name } = this.state.config
    const parts = name.split(' ')
    return {
      firstName: parts[0],
      lastName: parts.slice(1).join(' ')
    }
  }

  _handleAuthorize() {
    const { account, accountNumber, routingNumber, name, address, businessName } = this.state.config
    const { token } = this.props
    const [ownershipType,accountType] = account.split('_')
    client.create({
      authorization: token
    }).then(clientInstance => {
      return usBankAccount.create({
        client: clientInstance
      })
    }).then(usBankAccountInstance => {
      this.setState({
        error: null,
        status: 'authorizing'
      })
      usBankAccountInstance.tokenize({
        bankDetails: {
          accountNumber: `${accountNumber}`,
          routingNumber: `${routingNumber}`,
          ownershipType,
          accountType,
          ...ownershipType === 'business' ? {
            businessName
          } : this._getName(name),
          billingAddress: {
            streetAddress: address.street_1,
            locality: address.city,
            region: address.state_province,
            postalCode: address.postal_code
          }
        },
        mandateText: this._getMandate()
      }, (err, response) => {
        if(err) {
          this.setState({
            error: response.errors.payment[0],
            status: 'failed'
          })
        }
        this.setState({
          status: 'authorized',
          payment: {
            reference: response.description,
            nonce: response.nonce,
            last_four: response.description.substr(-4)
          }
        })
      })
    }).catch(err => {
      this.setState({
        error: err.errors.payment[0],
        status: 'failed'
      })
    })
  }

  _handleChange() {
    const { config, payment } = this.state
    const { account } = config
    const [ownershipType,accountType] = account.split('_')
    this.props.onChange({
      ...payment,
      account_type: accountType,
      ownership_type: ownershipType
    })
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

const mapResources = (props, context) => ({
  token: '/api/admin/finance/payments/token'
})

export default Container(mapResources)(BraintreeACHField)
