import Page from '@admin/components/page'
import RevenueTypes from './revenue_types'
import ExpenseTypes from './expense_types'
import Scholarships from './scholarships'
import Chargebacks from './chargebacks'
import Expenses from './expenses'
import Deposits from './deposits'
import Accounts from './accounts'
import Projects from './projects'
import Invoices from './invoices'
import Payments from './payments'
import Revenue from './revenue'
import Refunds from './refunds'
import Vendors from './vendors'
import Batches from './batches'
import Credits from './credits'
import Banks from './banks'
import Items from './items'
import Taxes from './taxes'
import Rates from './rates'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Finance)',
  manager: {
    path: '/admin/finance',
    items: [
      {
        label: 't(Projects)',
        path: '/projects',
        panel: <Projects app={ resources.app } />
      },
      {
        label: 't(Expenses)',
        children: [
          {
            label: 't(Accounts)',
            path: '/accounts',
            access: { rights: { $oneOf: ['finance:manage_accounts'] } },
            panel: <Accounts app={ resources.app } />
          },
          {
            label: 't(Batches)',
            path: '/batches',
            access: { rights: { $oneOf: ['finance:process_expenses'] } },
            panel: <Batches app={ resources.app } />
          },
          {
            label: 't(Expense Types)',
            path: '/expense_types',
            access: { rights: { $oneOf: ['finance:manage_configuration'] } },
            panel: <ExpenseTypes app={ resources.app } />
          },
          {
            label: 't(Items)',
            path: '/items',
            access: { rights: { $oneOf: ['finance:manage_expenses'] } },
            panel: <Items app={ resources.app } />
          },
          {
            label: 't(Mileage Rates)',
            path: '/rates',
            access: { rights: { $oneOf: ['finance:manage_configuration'] } },
            panel: <Rates app={ resources.app } />
          },
          {
            label: 't(Report)',
            path: '/report',
            access: { rights: { $oneOf: ['finance:access_expense_reports'] } },
            panel: <Expenses app={ resources.app } />
          },
          {
            label: 't(Taxes)',
            path: '/taxes',
            access: { rights: { $oneOf: ['finance:access_expense_reports'] } },
            panel: <Taxes app={ resources.app } />
          },
          {
            label: 't(Vendors)',
            path: '/vendors',
            access: { rights: { $oneOf: ['finance:manage_vendors'] } },
            panel: <Vendors app={ resources.app } />
          }
        ]
      },
      {
        label: 't(Revenue)',
        children: [
          {
            label: 't(Bank Accounts)',
            path: '/banks',
            access: { rights: { $oneOf: ['finance:manage_banks'] } },
            panel: <Banks app={ resources.app } />
          },
          {
            label: 't(Chargebacks)',
            path: '/chargebacks',
            access: { rights: { $oneOf: ['finance:manage_revenue'] } },
            panel: <Chargebacks app={ resources.app } />
          },
          {
            label: 't(Credits)',
            path: '/credits',
            access: { rights: { $oneOf: ['finance:manage_revenue'] } },
            panel: <Credits app={ resources.app } />
          },
          {
            label: 't(Invoices)',
            path: '/invoices',
            access: { rights: { $oneOf: ['finance:manage_revenue'] } },
            panel: <Invoices app={ resources.app } />
          },
          {
            label: 't(Deposits)',
            path: '/deposits',
            access: { rights: { $oneOf: ['finance:manage_deposits'] } },
            panel: <Deposits app={ resources.app } />
          },
          {
            label: 't(Payments)',
            path: '/payments',
            access: { rights: { $oneOf: ['finance:manage_deposits'] } },
            panel: <Payments app={ resources.app } />
          },
          {
            label: 't(Refunds)',
            path: '/refunds',
            access: { rights: { $oneOf: ['finance:manage_deposits'] } },
            panel: <Refunds app={ resources.app } />
          },
          {
            label: 't(Report)',
            path: '/report',
            access: { rights: { $oneOf: ['finance:access_revenue_reports'] } },
            panel: <Revenue app={ resources.app } />
          },
          {
            label: 't(Revenue Types)',
            path: '/revenue_types',
            access: { rights: { $oneOf: ['finance:manage_configuration'] } },
            panel: <RevenueTypes app={ resources.app } />
          },
          {
            label: 't(Scholarships)',
            path: '/scholarships',
            access: { rights: { $oneOf: ['finance:manage_revenue'] } },
            panel: <Scholarships app={ resources.app } />
          }
        ]
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings'
})

export default Page(mapResourcesToPage, mapPropsToPage)
