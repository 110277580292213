import Audit from '@admin/components/audit'
import Tabs from '@admin/components/tabs'
import PropTypes from 'prop-types'
import Accesses from './accesses'
import Overview from './overview'
import React from 'react'

class Sidebar extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object,
    onChangeFolder: PropTypes.func,
    onCreateFile: PropTypes.func,
    onPreview: PropTypes.func,
    onShowDetails: PropTypes.func
  }

  render() {
    const { item } = this.props
    return (
      <div className="drive-sidebar">
        <div className="drive-sidebar-title">
          { item ? item.label : 'Maha Drive' }
        </div>
        <Tabs { ...this._getTabs() } />
      </div>
    )
  }

  _getTabs() {
    const { item } = this.props
    return { 
      items: [
        { label: 't(Overview)', component: <Overview { ...this.props } /> },
        ...item.accesses ? [
          { label: 't(Access)', component: <Accesses item={ item } /> }
        ] : [],
        ..._.includes(['owner','edit'], item.access_type) ? [
          { label: 'Activity', component: <Audit showall={ true } compact={ true } entity={`drive_${item.type}s/${item.item_id}`} /> }
        ] : []
      ] 
    }
  }

}

export default Sidebar
