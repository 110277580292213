import Form from '@admin/components/form'
import ColorSetToken from './token'
import colorsets from './colorsets'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'

class PaletteField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    palette: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.palette) return null
    return (
      <div className="mjson-designer-stylefield palettefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { palette } = this.state
    if(!_.isEqual(palette, prevState.palette)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { palette } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'type', type: 'radiogroup', options: [
              { value: 'system', text: 'Choose a system palette' },
              { value: 'custom', text: 'Create a custom palette' }
            ], value: palette.type },
            { name: 'colorset', type: 'dropdown', options: colorsets, show: palette.type === 'system', value: palette.colorset, format: ColorSetToken },
            { name: 'preview', type: Preview, show: palette.type === 'custom', palette },
            { label: 't(Light Color)', name: 'light', type: 'colorfield', show: palette.type === 'custom', value: palette.light },
            { label: 't(Light Accent Color)', name: 'accent1', type: 'colorfield', show: palette.type === 'custom', value: palette.accent1 },
            { label: 't(Base Color)', name: 'base', type: 'colorfield', show: palette.type === 'custom', value: palette.base },
            { label: 't(Dark Accent Color)', name: 'accent2', type: 'colorfield', show: palette.type === 'custom', value: palette.accent2 },
            { label: 't(Dark Color)', name: 'dark', type: 'colorfield', show: palette.type === 'custom', value: palette.dark }
          ]
        }
      ]
    }
  }

  _getColorSet(values) {
    const set = values.colorset || values
    if(!set) return null
    const found = colorsets.find(colorset => {
      return set.light === colorset.value.light && set.dark === colorset.value.dark && set.base === colorset.value.base && set.accent1 === colorset.value.accent1 && set.accent2 === colorset.value.accent2
    })
    return found ? found.value : null
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {
      type: 'system',
      colorset: colorsets[0].value,
      ...colorsets[0].value
    }
  }

  _handleChange() {
    const { palette } = this.state
    this.props.onChange({
      type: palette.type,
      light: palette.light,
      dark: palette.dark,
      base: palette.base,
      accent1: palette.accent1,
      accent2: palette.accent2
    })
  }

  _handleSet(newpalette) {
    const colorset = this._getColorSet(newpalette)
    this.setState({
      palette: {
        colorset,
        ...newpalette || {},
        ...colorset || {}
      }
    })
  }

  _handleUpdate(newpalette) { 
    const colorset = this._getColorSet(newpalette) || (newpalette.type === 'system' ? colorsets[0].value : null)
    this.setState({
      palette: {
        colorset,
        type: newpalette.type,
        light: newpalette.light ? newpalette.light : colorset ? colorset.light : null,
        dark: newpalette.dark ? newpalette.dark : colorset ? colorset.dark : null,
        base: newpalette.base ? newpalette.base : colorset ? colorset.base : null,
        accent1: newpalette.accent1  ? newpalette.accent1 : colorset ? colorset.accent1 : null,
        accent2: newpalette.accent2 ? newpalette.accent2 : colorset ? colorset.accent2 : null
      }
    })
  }

}

export default PaletteField
