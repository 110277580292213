import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const schedules = {
  optout: {
    title: 't(Out Out)',
    text: 't(Schedule content and the advisor can unschedule manually)'
  },
  optin: {
    title: 't(Opt In)',
    text: 't(Do not schedule content and the advisor can schedule manually)'
  }
}

const SchedulingToken = ({ value }) => {
  const schedule = schedules[value]
  return (
    <div className="token">
      <strong><T text={ schedule.title } /></strong><br />
      <T text={ schedule.text } />
    </div>
  )
}

SchedulingToken.propTypes = {
  value: PropTypes.string
}

export default SchedulingToken
