import template from '@platforms/frontend/sdk/templates/embed.html.ejs'

/**
 * @typedef {Object} EmbedTemplateAction
 * @property {string} module Name of the module to use
 * @property {string} method Name of the method on module
 * @property {string[]?} args (optional) Arguments to the method call
 */

/**
 * @typedef {Object} EmbedTemplateArgs
 * @property {string} asset_root Root URL where the SDK JS file will be included from
 * @property {string?} preamble (optional) HTML code to insert before the snippet
 * @property {EmbedTemplateAction[]?} actions (optional) Array of actions to call within the snippet
 */

/** @type {function(EmbedTemplateArgs): string} */
export const embedTemplate = template