import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const ServiceToken = ({ service }) => (
  <div className="subscription-service-token">
    <div className="subscription-service-token-logo">
      <Logo team={ service.program } width="24" />
    </div>
    <div className="subscription-service-token-label">
      { service.title }
    </div>
  </div>
)

ServiceToken.propTypes = {
  service: PropTypes.object
}

export default ServiceToken
