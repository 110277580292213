import Dependencies from '@admin/components/dependencies'
import CopyText from '@admin/components/copytext'
import Highlight from 'react-highlight'
import PropTypes from 'prop-types'
import React from 'react'

class Code extends React.PureComponent {

  static propTypes = {
    code: PropTypes.string,
    type: PropTypes.string
  }

  render() {
    const { code, type } = this.props
    return (
      <div className="maha-code">
        <div className="maha-code-header">
          <CopyText { ...this._getCopyText() } />
        </div>
        <div className="maha-code-body">
          <Highlight className={ type }>
            { code }
          </Highlight>
        </div>
      </div>
    )
  }

  _getCopyText() {
    const { code } = this.props
    return {
      text: 't(copy code)',
      content: code
    }
  }

}

const dependencies = {
  styles: [
    { url: '/css/atom-one-dark.min.css' }
  ]
}

Code = Dependencies(dependencies)(Code)

export default Code
