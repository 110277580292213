import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const SessionToken = (session) => (
  <div className="session-token">
    <div className="token-link">
      { session.title }
    </div>
    <div className="token-stats">
      <span>
        { moment(session.starts_at).format('MMM D, YYYY h:mmA') } - { moment(session.ends_at).format('h:mmA') }
      </span>
      { session.is_online ?
        <span>
          Online Session
        </span> :
        <span>
          { session.location.name }, { session.location.address.description }
        </span>
      }
    </div>
  </div>
)

SessionToken.propTypes = {
  session: PropTypes.object
}

export default SessionToken
