import Page from '@admin/components/page'
import Canceled from './canceled'
import Upcoming from './upcoming'
import React from 'react'
import Past from './past'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Events)',
  manager: {
    path: '/admin/events/events',
    items: [
      {
        label: 't(Upcoming)',
        path: '/upcoming',
        panel: <Upcoming />
      },
      {
        label: 't(Past)',
        path: '/past',
        panel: <Past />
      },
      {
        label: 't(Canceled)',
        path: '/canceled',
        panel: <Canceled />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
