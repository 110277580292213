import { useRouterContext } from '@admin/components/router'
import { useAdminContext } from '@admin/components/admin'
import PropTypes from 'prop-types'
import Sessions from './sessions'
import React from 'react'

const SessionsContainer = (props) => {

  const { admin } = useAdminContext()
  const { router } = useRouterContext()

  const mergedProps = {
    context: {
      admin,
      router
    },
    ...props
  }

  return <Sessions { ...mergedProps } />

}

SessionsContainer.propTypes = {}

export default SessionsContainer
