import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_campaign.title,
  manager: {
    path: `/admin/truevail/admin/social_campaigns/${resources.social_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview social_campaign={ resources.social_campaign } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design social_campaign={ resources.social_campaign } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  social_campaign: `/api/admin/truevail/admin/social_campaigns/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
