const triggers = [
  {
    icon: 'calendar',
    code: 'registration_created',
    title: 't(Event Registered)',
    text: 't(Contact registers for event)'
  }
]

export default triggers
