import SocialSchedule from '@admin/components/social_schedule'
import Page from '@admin/components/page'
import React from 'react'

const getSocialSchedule = (props) => ({
  defaultMode: 'month',
  endpoint: `/api/admin/truevail/agency/social_batches/${props.params.social_batch_id}/publishings/${props.params.id}/social_campaigns`,
  editable: false
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Social Campaigns)',
  component: <SocialSchedule { ...getSocialSchedule(props) } />
})

const mapResourcesToPage = (props, context) => ({
  social_batch: `/api/admin/truevail/agency/social_batches/${props.params.social_batch_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
