import NumericField from '@admin/components/form/numericfield'
import PropTypes from 'prop-types'
import React from 'react'

class UsagesField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.array,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    usages: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 50)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { usages } = this.state
    if(!usages) return null
    return (
      <div className="subscriptions-usagesfield">
        <table className="ui compact celled unstackable table">
          <thead>
            <tr>
              <th>Resource</th>
              <th>Used</th>
            </tr>
          </thead>
          <tbody>
            { usages.map((usage, index) => (
              <tr key={`usage_${index}`}>
                <td>{ usage.resource.title.value }</td>
                <td><NumericField { ...this._getUnits(usage, index) } /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { usages } = this.state
    const { value } = this.props
    if(!_.isEqual(usages, prevState.usages)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getUnits(usage, index) {
    const { tabIndex } = this.props
    return {
      of: usage.included,
      tabIndex,
      value: usage.units ? Number(usage.units) : 0,
      onChange: this._handleUpdate.bind(this, index)
    }
  }

  _handleChange() {
    const { usages } = this.state
    this.props.onChange(usages)
  }

  _handleSet(usages) {
    this.setState({ usages })
  }

  _handleUpdate(index, value) {
    const { usages } = this.state
    this.setState({
      usages: usages.map((usage, i) => ({
        ...usage,
        ...i === index ? {
          units: value
        } : {}
      }))
    })
  }

  _handleValidate() {
    const { usages } = this.state
    const { onValid } = this.props
    const incomplete = usages.find(usage => {
      return usage.units === null
    }) !== undefined
    if(incomplete) return onValid(usages, ['Please fill in all usage values'])
    onValid(usages)
  }

}

export default UsagesField
