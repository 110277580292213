import T from '@admin/components/t'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Field from './field'
import React from 'react'
import New from './new'

class Fields extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.object,
    datasources: PropTypes.array,
    fields: PropTypes.array,
    label: PropTypes.string,
    parent_type: PropTypes.string,
    parent_id: PropTypes.number,
    onMove: PropTypes.func
  }

  static defaultProps = {
    label: 'field'
  }

  _handleNew = this._handleNew.bind(this)
  _handleReorder = this._handleReorder.bind(this)
  _handleToggleDisabled = this._handleToggleDisabled.bind(this)

  render() {
    const { fields, label } = this.props
    return (
      <div className="maha-fields">
        <div className="maha-fields-header">
          <T text="Field" />
        </div>
        <div className="maha-fields-body">
          { fields.map((field, index) => (
            <Field { ...this._getField(field, index) } key={`field_${field.id}`} />
          ))}
          { fields.length === 0 &&
            <div className="maha-fields-empty">No { pluralize(label, 2) }</div>
          }
        </div>
      </div>
    )
  }

  _getAddButton() {
    const { label } = this.props
    return {
      label: `Add ${ _.capitalize(label) }`,
      className: 'link',
      handler: this._handleNew
    }
  }

  _getEmpty() {
    const { parent_type, parent_id } = this.props
    return {
      icon: 'check-square-o',
      title: 't(No fields)',
      text: 't(There are no fields for this content type)',
      button: {
        label: 'Add Field',
        modal: () => <New parent_type={ parent_type } parent_id={ parent_id } />
      }
    }
  }

  _getEndpoint() {
    const { parent_id, parent_type } = this.props
    if(parent_id) return `/api/admin/${parent_type}/${parent_id}/fields`
    return `/api/admin/${parent_type}/fields`
  }

  _getFields(is_active) {
    const { fields } = this.props
    return fields.filter(field => {
      return field.is_active === is_active
    })
  }

  _getField(field, index) {
    const { label, onMove } = this.props
    const endpoint = this._getEndpoint()
    return {
      endpoint,
      field,
      index,
      label,
      onMove,
      onReorder: this._handleReorder
    }
  }

  _handleNew() {
    const { datasources } = this.props
    const endpoint = this._getEndpoint()
    this.context.modal.open(<New action={ endpoint } datasources={ datasources } />)
  }

  _handleReorder(from, to) {
    const endpoint = this._getEndpoint()
    this.context.network.request({
      endpoint: `${endpoint}/reorder`,
      method: 'PATCH',
      body: { from, to },
      onSuccess: ({ data }) => {},
      onFailure: () => {}
    })
  }

  _handleToggleDisabled() {
    const { expanded } = this.state
    this.setState({
      expanded: !expanded
    })
  }

}

export default Fields
