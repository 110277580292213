import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import ProgramForm from '@apps/crm/admin/components/programform'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Workflows)',
  collection: {
    endpoint: '/api/admin/automation/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'display_name', sort: 'created_at', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow }/>
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Workflow)', fields: [
            { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'display_name' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Enrolled)', key: 'enrolled_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    empty: {
      icon: 'sitemap',
      title: 't(No Workflow)',
      text: 't(You have not yet created any workflows)',
      buttons: [
        { label: 't(Create New Workflow)', modal: <ProgramForm form={ New } /> }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'workflow',
    onClick: (record) => context.router.push(`/automation/workflows/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Page(null, mapPropsToPage)
