import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import AnimationField from '@admin/components/mjson_designer/components/animationfield'
import React from 'react'

export const Animation = (props) => <ResponsiveField { ...props } component={ AnimationField } />

export const AnimationSection = (config, device, theme, website, type) => ({
  label: 't(Animation)',
  instructions: 't(Specify effects that alter the appearance or behavior of this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.animation', device, type: Animation, value: config.styles.animation }
  ]
})
