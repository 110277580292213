import ProgramForm from '@apps/crm/admin/components/programform'
import ProgramToken from '@apps/crm/admin/tokens/program'
import New from '@apps/finance/admin/views/credits/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Credits)',
  collection: {
    endpoint: '/api/admin/finance/credits',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (credit) => <ProgramToken { ...credit.program } /> },
      { label: 't(Balance)', key: 'balance', primary: true, align: 'right', collapsing: true, wrap: false, format: (credit) => (
        <>{credit.balance} / {credit.amount}</>
      ) }
    ],
    empty: {
      title: 't(No Credits)',
      text: 't(There are no credits for this contact)',
      icon: 'dollar',
      buttons: [{
        label: 't(Create Credit)',
        modal: <ProgramForm form={ New } props={{ contact: props.contact }}/>
      }]
    },
    entity: 'payments',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/credits/${record.id}`),
    task: {
      icon: 'plus',
      modal: <ProgramForm form={ New } props={{ contact: props.contact }}/>
    }
  }
})

export default Panel(null, mapPropsToPanel)
