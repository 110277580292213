import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const FLOAT_REGEX = /^-?[0-9]*\.?[0-9]*$/

const INTEGER_REGEX = /^-?[0-9]*$/

class NumberField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    number_type: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    units: PropTypes.string,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    number: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleChange = _.throttle(this._handleChange.bind(this), 250, { trailing:  true })
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { units } = this.props
    const { number } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
        </div>
        { number !== null && number.length > 0 &&
          <Button { ...this._getClear() } />
        }
        { units &&
          <div className="maha-numberfield-units">
            { units }
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { number } = this.state
    const { value } = this.props
    if(number !== prevState.number) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getFormatted(number) {
    return number > 0 ? Number(number) : null
  }

  _getInput() {
    const { disabled, id, maxLength, tabIndex } = this.props
    const { focused, number } = this.state
    return {
      ref: this.inputRef,
      disabled,
      className: 'ui input',
      type: 'textfield',
      id,
      maxLength,
      placeholder: !focused ? this._getPlaceholder() : null,
      tabIndex,
      value: number,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getPlaceholder() {
    const { label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    return t(label ? `t(Enter) ${label}` : '')
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { number } = this.state
    const formatted = this._getFormatted(number)
    this.props.onChange(formatted)
  }

  _handleClear() {
    this.setState({
      number: ''
    })
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleSet(number) {
    this.setState({
      number: number ? `${number}` : ''
    })
  }

  _handleUpdate(number) {
    const { number_type, maxLength } = this.props
    if(maxLength && number.length > maxLength) return
    const regex = number_type === 'integer' ? INTEGER_REGEX : FLOAT_REGEX
    if(!regex.test(number)) return
    this.setState({ number })
  }

  _handleValidate() {
    const { min, max, required, onValid } = this.props
    const { number } = this.state
    if(required === true && number === '') return onValid(number, ['This field is required'])
    if(!_.isNil(min) && Number(number) < min) return onValid(number, [`This field must be greater than or equal to  ${min}`])
    if(!_.isNil(max) && Number(number) > max) return onValid(number, [`This field must be less than or equal to ${max}`])
    const formatted = this._getFormatted(number)
    onValid(formatted)
  }

}

export default NumberField
