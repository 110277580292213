import Consent from './consent'
import Import from './import'
import Merge from './merge'
import Edit from './edit'
import Note from './note'

const cards = {
  consent: { component: Consent },
  edit: { component: Edit },
  import: { component: Import },
  merge: { component: Merge },
  note: { component: Note }
}

export default cards
