import Dependencies from '@admin/components/dependencies'
import prettify from 'html-prettify'
import PropTypes from 'prop-types'
import React from 'react'

class CodeField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.string,
    lang: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    lang: 'html',
    onChange: () => {},
    onReady: () => {}
  }

  editorRef = React.createRef()
  editor = null

  state = {
    code: ''
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <div className="maha-codefield">
        <div ref={ this.editorRef } />
      </div>
    )
  }

  componentDidMount() {
    this._handleInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { code } = this.state
    if(code !== prevState.code) {
      this._handleChange()
    }
  }

  _handleInit() {
    const { value, lang } = this.props
    window.ace.config.set('basePath', '/js/ace')
    this.editor = window.ace.edit(this.editorRef.current)
    this.editor.setTheme('ace/theme/chrome')
    this.editor.setDisplayIndentGuides(false)
    this.editor.setHighlightActiveLine(false)
    this.editor.session.setUseSoftTabs(false)
    this.editor.session.setTabSize(2)
    this.editor.session.setFoldStyle('manual')
    this.editor.session.setMode(`ace/mode/${lang}`)
    this.editor.container.style.fontSize = '10px'
    this.editor.container.style.lineHeight = '20px'
    this.editor.renderer.setShowGutter(false)
    this.editor.renderer.setShowPrintMargin(false)
    this.editor.renderer.updateFontSize()
    if(value) {
      const formatted = lang === 'html' ? prettify(value) : value
      this.editor.getSession().setValue(formatted)
    }
    this.editor.getSession().on('change', this._handleUpdate)
    this.props.onReady()
  }

  _handleChange() {
    const { code } = this.state
    this.props.onChange(code)
  }

  _handleUpdate() {
    this.setState({
      code: this.editor.getSession().getValue()
    })
  }

}

const dependencies = {
  scripts: [
    { url: '/js/ace.min.js', check: 'ace' }
  ]
}

CodeField = Dependencies(dependencies)(CodeField)

export default CodeField
