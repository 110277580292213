import PhoneForm from '@apps/phone/admin/components/phoneform'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    phone_number_id: PropTypes.number,
    program_id: PropTypes.number,
    onBack: PropTypes.func
  }

  state = {
    purpose: 'marketing'
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { phone_number_id, program_id } = this.props
    return {
      title: 't(New Inbound SMS Campaign)',
      method: 'POST',
      action: '/api/admin/campaigns/sms',
      cancelIcon: 'chevron-left',
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'phone_number_id', type: 'hidden', defaultValue: phone_number_id },
            { name: 'program_id', type: 'hidden', defaultValue: program_id },
            { name: 'direction', type: 'hidden', defaultValue: 'inbound' },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Trigger Term)', name: 'term', type: 'textfield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(key, value) {
    if(key === 'purpose') {
      this.setState({
        purpose: value
      })
    }
  }

  _handleSuccess(sms_campaign) {
    this.context.router.push(`/campaigns/sms/${sms_campaign.id}`)
    this.context.modal.close()
  }

}

const NewWrapper = (props) => <PhoneForm phone_form={ New } props={ props } />

export default NewWrapper
