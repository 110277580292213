import MJSONPreview from '@admin/components/mjson_preview'
import ShortLink from '@admin/components/shortlink'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ signup_page }) => {

  const shortlink = {
    shortlink: signup_page.shortlink,
    text: 't(Share this Signup Page)',
    title: signup_page.title
  }

  const owner = {
    label: signup_page.owner.title,
    className: 'link',
    route: `/truevail/admin/agencies/${signup_page.owner.id}`
  }

  const details = {
    audits: `maha_signup_pages/${signup_page.id}`,
    comments: `maha_signup_pages/${signup_page.id}`,
    before: <ShortLink { ...shortlink } />,
    header: <MJSONPreview entity={ signup_page } table="maha_signup_pages" />,
    sections: [
      {
        title: 't(Signup Page Details)',
        items: [
          { label: 't(Title)', content: signup_page.title },
          { label: 't(Owner)', content: <Button {...owner } /> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  signup_page: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview signup_page={ props.signup_page } />,
  tasks: {
    items: [
      {
        label: 't(Edit Signup Page)',
        modal: <Edit signup_page={ props.signup_page } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
