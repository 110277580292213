import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  upload: {
    title: 't(Upload a Photo)',
    text: 't(Upload a high resolution headshot of yourself)'
  },
  optout: {
    title: 't(Dont Use a Photo)',
    text: 't(I do not want to use a personal headshot)'
  }
}

const Phototoken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
  </div>
)

Phototoken.propTypes = {
  value: PropTypes.string
}

export default Phototoken
