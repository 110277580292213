import TextField from '@admin/components/form/textfield'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Answer extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    account: PropTypes.object,
    security_questions: PropTypes.array,
    security_question_id: PropTypes.number,
    token: PropTypes.string,
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  state = {
    error: false,
    message: null,
    security_question_answer: '',
    status: 'pending'
  }

  _handleBack = this._handleBack.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    const question = this._getQuestion()
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h2><T text="t(Answer your question)" /></h2>
            <p><T text={ question.text } /></p>
          </div>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            <input type="hidden" value="something" />
            <div className="field text-field">
              <TextField { ...this._getAnswer() } />
            </div>
            <div className="field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
          <div className="maha-signin-footer">
            <Button { ...this._getBack() } />
          </div>
        </div>
      </div>
    )
  }

  _getAnswer() {
    return {
      placeholder: 't(Answer)',
      onChange: this._handleUpdate.bind(this, 'security_question_answer')
    }
  }

  _getBack() {
    return {
      label: 't(Choose a different question)',
      className: 'link',
      handler: this._handleBack
    }
  }

  _getContinue() {
    const { status } = this.state
    return {
      label: 't(Continue)',
      loading: status === 'submitting',
      color: 'blue',
      size: 'large',
      handler: this._handleSubmit
    }
  }

  _getQuestion() {
    const { security_questions, security_question_id } = this.props
    return _.find(security_questions, { id: security_question_id })
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleNext() {
    this.props.onNext()
  }

  _handleShake({ errors }) {
    this.setState({ 
      error: true,
      message: errors.security_question_answer[0]
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleSubmit() {
    const { token, security_question_id } = this.props
    const { security_question_answer } = this.state
    this.setState({ status: 'submitting' })
    this.context.network.request({
      endpoint: '/api/admin/activate/security',
      method: 'POST',
      body: { 
        token,
        security_question_id, 
        security_question_answer 
      },
      onSuccess: this._handleNext,
      onFailure: this._handleShake
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Answer
