import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

class OmniSearch extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    records: PropTypes.array
  }

  render() {
    const { records } = this.props
    let type = null
    return records.map((record, j) => {
      const newtype = record.type !== type ? record.type : null
      type = record.type
      return (
        <Fragment key={`record_${j}`}>
          { newtype &&
            <div className="maha-omnisearch-section-header">
              { pluralize(newtype) }
            </div>
          }
          <div className="maha-omnisearch-result" onClick={ this._handleChoose.bind(this, record)}>
            <div className="maha-omnisearch-result-details">
              { record.text }
            </div>
          </div>
        </Fragment>
      )
    })
  }

  _handleChoose(record) {
    const { router } = this.context
    router.push(record.route)
  }

  _getSearch() {
    return {
      prompt: 't(Find anything)',
      start: {
        icon: 'search',
        title: 't(Search for anything)'
      },
      empty: {
        icon: 'remove',
        title: 't(No results matched your query)'
      },
      endpoint: '/api/admin/search',
      layout: ({ records }) => {
        
      }
    }
  }

  _handleChoose(record) {
    const { router } = this.context
    router.push(record.route)
  }

}

export default OmniSearch
