import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const UnsplashToken = ({ photo }) => (
  <div className="unsplash-token">
    <div className="unsplash-token-photo">
      <Img src={ photo.urls.thumb } />
    </div>
    <div className="unsplash-token-details">
      { photo.user.name }<br />
      <span>{ photo.width }x{ photo.height }</span>
    </div>
  </div>
)

UnsplashToken.propTypes = {
  photo: PropTypes.object
}

export default UnsplashToken
