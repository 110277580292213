import Panel from '@admin/components/panel'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'


const totals = [
  { label: 't(Sessions)', name: 'sessions_count', query: '' },
  { label: 't(Active)', name: 'active_count', query: '?$filter[status][$eq]=active' },
  { label: 't(Lost)', name: 'lost_count', query: '?$filter[status][$eq]=lost' },
  { label: 't(Converted)', name: 'converted_count', query: '?$filter[was_converted][$eq]=false' },
  { label: 't(Completed)', name: 'completed_count', query: '?$filter[status][$eq]=completed' }
]

class Performance extends React.Component {

  static propTypes = {
    sms_campaign: PropTypes.object
  }

  render() {
    const { sms_campaign } = this.props
    return (
      <div className="crm-report">
        <div className="crm-report-title">
          Sessions
        </div>
        <div className="crm-report-metrics">
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Sessions
            </div>
            <div className="crm-report-metric-value">
              { sms_campaign.sessions_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Active
            </div>
            <div className="crm-report-metric-value">
              { sms_campaign.active_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Converted
            </div>
            <div className="crm-report-metric-value">
              { sms_campaign.converted_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Conversion Rate
            </div>
            <div className="crm-report-metric-value">
              { numeral(sms_campaign.converted_count / sms_campaign.sessions_count).format('0.0%') }
            </div>
          </div>
        </div>
        <div className="crm-report-table">
          <table className="ui unstackable table">
            <tbody>
              { totals.map((total, index) => (
                <tr key={`total_${index}`}>
                  <td>
                    <T text={ total.label } />
                  </td>
                  <td className="right aligned">
                    { sms_campaign[total.name] }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

Performance.propTypes = {
  sms_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance sms_campaign={ props.sms_campaign } />
})

export default Panel(null, mapPropsToPanel)
