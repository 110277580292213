import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Item extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.array,
    className: PropTypes.string,
    collapsing: PropTypes.bool,
    collapsed: PropTypes.array,
    icon: PropTypes.string,
    id: PropTypes.string,
    index: PropTypes.string,
    label: PropTypes.any,
    padding: PropTypes.number,
    panel: PropTypes.any,
    route: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.string,
    tasks: PropTypes.array,
    onClick: PropTypes.func,
    onCollapse: PropTypes.func,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    collapsing: true,
    padding: 0,
    selectable: true
  }

  _handleClick = this._handleClick.bind(this)
  _handleCollapse = this._handleCollapse.bind(this)
  _handleEnter = this._handleEnter.bind(this)
  _handleLeave = this._handleLeave.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    const { children, icon, label, padding, tasks } = this.props
    const is_expanded = this._getIsExpanded()
    return (
      <div { ...this._getItem(is_expanded) }>
        { padding > 0 &&
          <>
            { Array(padding).fill(0).map((i, index) => (
              <div className="mjson-designer-outline-item-padding" key={`padding_${index}`} />
            ))}
          </>
        }
        { children ?
          <div className="mjson-designer-outline-item-toggle" onClick={ this._handleCollapse }>
            <Icon icon="chevron-right" />
          </div> :
          <div className="mjson-designer-outline-item-padding" />
        }
        { icon &&
          <div className="mjson-designer-outline-item-icon">
            <Icon icon={ icon } />
          </div>
        }
        <div className="mjson-designer-outline-item-details">
          <T text={ label } />
        </div>
        { tasks &&
          <Button { ...this._getTasks() } />
        }
      </div>
    )
  }

  _getClass(is_expanded) {
    const { className, index, selected } = this.props
    const classes = ['mjson-designer-outline-item']
    if(className) classes.push(className)
    if(is_expanded) classes.push('active')
    if(index === selected) classes.push('selected')
    return classes.join(' ')
  }

  _getIsExpanded() {
    const { index, collapsed } = this.props
    return !_.includes(collapsed, index)
  }

  _getItem(is_expanded) {
    return {
      className: this._getClass(is_expanded),
      onClick: this._handleClick,
      onContextMenu: this._handleTasks,
      onMouseEnter: this._handleEnter,
      onMouseLeave: this._handleLeave
    }
  }

  _getExpander(is_expanded) {
    return is_expanded ? 'chevron-down' : 'chevron-right'
  }

  _getTasks() {
    return {
      icon: 'ellipsis-v',
      className: 'mjson-designer-outline-item-action',
      handler: this._handleTasks
    }
  }

  _handleClick(e) {
    e.stopPropagation()
    const { children, id, index, panel, route, selectable, onClick } = this.props
    if(!selectable) return
    if(route) return this.context.router.push(route)
    if(panel || onClick) return this.props.onSelect(index)
    if(children) this.props.onCollapse(id)
  }

  _handleCollapse(e) {
    e.stopPropagation()
    const { children } = this.props
    const { id } = this.props
    if(children) this.props.onCollapse(id)
  }

  _handleEnter(e) {
    const { onEnter } = this.props
    e.stopPropagation()
    if(onEnter) onEnter()
  }

  _handleLeave(e) {
    const { onLeave } = this.props
    e.stopPropagation()
    if(onLeave) onLeave()
  }

  _handleTasks(e) {
    e.stopPropagation()
    e.preventDefault()
    const { tasks } = this.props
    this.context.tasks.open({
      items: tasks
    })
  }

}

export default Item
