import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Complaints)',
  access: {},
  collection: {
    endpoint: '/api/admin/emails',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => (
        <ContactToken { ...email.contact } property="rfc822" /> 
      ) }
    ],
    defaultQuery: { email_campaign_id: props.email_campaign.id },
    defaultFilter: { was_complained: { $eq: true } },
    defaultSort: { key: 'created_at', order: 'desc' },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'contact.first_name' },
      { label: 't(Last Name)', key: 'contact.last_name' },
      { label: 't(Email)', key: 'contact.email' }
    ],
    empty: {
      icon: 'bullhorn',
      title: 't(No Complaints)',
      text: 't(There were no complaints for this email)'
    },
    entity: 'complaint',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
