import PropTypes from 'prop-types'
import Reaction from './reaction'
import React from 'react'

class Reactions extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      network: PropTypes.object,
      reactions: PropTypes.object
    }),
    id: PropTypes.number,
    reactions: PropTypes.array,
    table: PropTypes.string
  }

  _handleReact = this._handleReact.bind(this)
  _handleUnreact = this._handleUnreact.bind(this)

  render() {
    const reactions = this._getSorted()
    const total = this._getTotal()
    if(total === 0) return null
    return (
      <div className="maha-reactions">
        { Object.keys(reactions).map((type) => (
          <Reaction { ...this._getType(type, reactions[type]) } key={`reaction_${type}`} />
        )) }
      </div>
    )
  }

  _getReactions() {
    const { context, id, table } = this.props
    const key = `${table}/${id}`
    return context.reactions.reactions[key] || this.props.reactions
  }

  _getSorted() {
    const reactions = this._getReactions()
    return reactions.reduce((reactions, reaction) => ({
      ...reactions,
      [reaction.type]: [
        ...reactions[reaction.type] || [],
        reaction
      ]
    }), {})
  }

  _getTotal() {
    const reactions = this._getReactions()
    return reactions.length
  }

  _getType(type, reactions) {
    const { id, table } = this.props
    const user_reactions = this._getUserReactions()
    return {
      id,
      reactions,
      type,
      table,
      user_reactions,
      onReact: this._handleReact,
      onUnreact: this._handleUnreact
    }
  }

  _getUserReactions() {
    const { user } = this.props.context.admin
    const reactions = this._getReactions()
    return reactions.filter(reaction => {
      return reaction.id === user.id
    }).map(reaction => reaction.type)
  }

  _handleReact(table, id, reaction) {
    this.props.context.reactions.react({
      table, 
      id, 
      reaction
    })
  }

  _handleUnreact(table, id, reaction) {
    this.props.context.reactions.unreact({
      table, 
      id, 
      reaction
    })
  }

}

export default Reactions
