import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class ValueField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.array,
    recordType: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    recordType: 'A',
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    values: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { values } = this.state
    if(!values) return null
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { values } = this.state
    if(!_.isEqual(values, prevState.values)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    return {
      label: '+ t(add value)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : ['']
  }

  _getInput(index) {
    const { values } = this.state
    const value = values[index]
    return {
      type: 'textfield',
      value,
      placeholder: this._getPlaceholder(),
      onBlur: this._handleBlur.bind(this, index),
      onChange: this._handleUpdate.bind(this, index)
    }
  }

  _getList() {
    const { values } = this.state
    return {
      actions: (item, index) => [
        {
          icon: 'times',
          confirm: 't(Are you sure you want to remove this value?)',
          handler: this._handleRemove.bind(this, index)
        }
      ],
      items: values,
      format: (item, index) => (
        <div className="domains-valuefield-value">
          <div className="domains-valuefield-value-details">
            <Input { ...this._getInput(index) } />
          </div>
        </div>
      )
    }
  }

  _getPlaceholder() {
    const { recordType } = this.props
    if(recordType === 'A') return '192.0.2.235'
    if(recordType === 'AAAA') return '2001:0db8::8a2e:0370:bab5'
    if(recordType === 'CNAME') return 'www.example.com'
    if(recordType === 'MX') return '10 mailserver.example.com'
    if(recordType === 'SRV') return '1 10 5269 xmpp-server.example.com'
    if(recordType === 'TXT') return 'some text entry'
  }

  _handleAdd() {
    const { values } = this.state
    this.setState({
      values: [
        ...values,
        ''
      ]
    })
  }

  _handleBlur(index) {
    const { values } = this.state
    this.setState({
      values: values.map((v, i) => {
        if(i !== index) return v
        return v.trim().replace(/\.$/,'').replace(/^"/,'').replace(/"$/,'')
      })
    })
  }

  _handleChange() {
    const { values } = this.state
    this.props.onChange(values)
  }

  _handleRemove(index) {
    const { values } = this.state
    this.setState({
      values: [
        ...values.filter((value, i) => {
          return i !== index
        }),
        ...values.length === 1 ? [''] : []
      ]
    })
  }

  _handleSet(values) {
    this.setState({ values })
  }

  _handleUpdate(index, value) {
    const { values } = this.state
    this.setState({
      values: values.map((v, i) => {
        return i === index ? value : v
      })
    })
  }

  _handleValidate() {
    const { values } = this.state
    const { required, onValid } = this.props
    const submittable = values.filter(value => {
      return value.length > 0
    })
    if(required && values.length > submittable.length) {
      return onValid(values, ['You must fill in all values'])
    }
    return onValid(values)
  }

}

export default ValueField
