import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  remove: {
    title: 't(Remove Associations)',
    description: 't(Remove this users roles and groups)'
  },
  nothing: {
    title: 't(Do Nothing)',
    description: 't(Leave this user\'s associations intact)'
  }
}

const TeamStrategyToken = ({ value }) => {
  const strategy = strategies[value]
  return (
    <div className="type-token">
      <strong><T text={ strategy.title } /></strong><br />
      <T text={ strategy.description } />
    </div>
  )
}

TeamStrategyToken.propTypes = {
  value: PropTypes.string
}

export default TeamStrategyToken
