import Report from '@admin/components/report'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const data = (data, key) => data.map(row => ({
  x: row.collected_at,
  y: row[key]
}))

class Performance extends React.Component {

  static propTypes = {
    performance: PropTypes.object,
    social_campaign: PropTypes.object
  }

  render() {
    const { performance } = this.props
    return (
      <>
        { performance.map((performance, index) => (
          <Report { ...this._getReport(performance) } key={`performance_${index}`} />          
        )) }
      </>
    )
  }

  _getReport(performance) {
    const { social_campaign } = this.props
    const profile = performance.profile
    return {
      title: profile.name || profile.username,
      link: {
        label: `View on ${profile.service.name.replace(' for Business', '')}`,
        className: 'link',
        link: performance.url
      },
      charts: { 
        label: 't(Post Insights)',
        options: {
          x_min: social_campaign.posted_at,
          x_max: moment(social_campaign.posted_at).add(14, 'days').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          x_ticks: 7
        },
        charts: [
          { 
            label: 't(Impressions)',
            description: 't(Total times the post has been seen)',
            data: data(performance.data, 'impressions'),
            metric: performance.metrics.impressions
          },
          { 
            label: 't(Reach)',
            description: 't(Unique accounts that viewed the posts)',
            data: data(performance.data, 'reach'),
            metric: performance.metrics.reach
          },
          ...profile.service.source === 'facebook' ? [
            { 
              label: 't(Reactions)',
              description: 't(Reactions the post received)',
              data: data(performance.data, 'reactions'),
              metric: performance.metrics.reactions
            }
          ] : [
            { 
              label: 't(Likes)',
              description: 't(Likes the post received)',
              data: data(performance.data, 'reactions'),
              metric: performance.metrics.reactions
            }  
          ],
          { 
            label: 't(Comments)',
            description: 't(Comments the post received)',
            data: data(performance.data, 'comments'),
            metric: performance.metrics.comments
          },
          { 
            label: 't(Engagement)',
            description: 't(Accounts that clicked anywhere in the posts)',
            data: data(performance.data, 'engagement'),
            metric: performance.metrics.engagement
          },
          ...profile.service.source === 'instagram_business' ? [
            { 
              label: 't(Saves)',
              description: 't(Unique accounts that saved the post)',
              data: data(performance.data, 'saves'),
              metric: performance.metrics.saves
            }  
          ] : [],
          { 
            label: 't(Shares)',
            description: 't(Unique accounts that shared the post)',
            data: data(performance.data, 'shares'),
            metric: performance.metrics.shares
          },
          ...social_campaign.type === 'video' ? [
            { 
              label: 't(Video Views)',
              description: 't(Total number of times your video was viewed for 3 seconds or viewed to the end, whichever came first)',
              data: data(performance.data, 'video_views'),
              metric: performance.metrics.video_views
            }  
          ] : []
        ]
      },
      metrics: {
        metrics: [
          { label: 't(Enagegment Rate)', value: performance.metrics.engagement_rate, format: 'percent' },
          { label: 't(Reach)', value: performance.metrics.reach, format: 'integer' },
          ...profile.service.source === 'facebook' ? [
            { label: 't(Reactions)', value: performance.metrics.reactions, format: 'integer' }
          ] : [
            { label: 't(Likes)', value: performance.metrics.reactions, format: 'integer' }
          ],
          { label: 't(Comments)', value: performance.metrics.comments, format: 'integer' }
        ]  
      }
    }
  }

}

const mapResourcesToPanel = (props, context) => ({
  performance: `/api/admin/campaigns/social/${props.social_campaign.id}/performance`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance performance={ props.performance } social_campaign={ props.social_campaign } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
