import { useStarsContext } from '@apps/maha/admin/roots/stars'
import { useNetworkContext } from '@admin/components/network'
import PropTypes from 'prop-types'
import Star from './star'
import React from 'react'

const StarContainer = (props) => {

  const { network } = useNetworkContext()
  const { stars } = useStarsContext()
  
  const mergedProps = {
    context: {
      network,
      stars
    },
    ...props
  }

  return <Star { ...mergedProps } />

}

StarContainer.propTypes = {
  entity: PropTypes.string
}

export default StarContainer