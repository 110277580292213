import AgreementsShow from './agreements/show'
import ContactsList from './contacts/list'
import ContactsShow from './contacts/show'
import ListsList from './lists/list'
import ListsShow from './lists/show'

const routes = [
  { path: '/agreements/:id/:view', component: AgreementsShow },
  { path: '/contacts', component: ContactsList },
  { path: '/contacts/:id/:view', component: ContactsShow },
  { path: '/lists', component: ListsList },
  { path: '/lists/:id/:view', component: ListsShow }
]

export default routes
