import PropTypes from 'prop-types'
import React from 'react'

const CancelStrategyToken = ({ value }) => (
  <div className="token">
    { value === 'charge' &&
      <>
        <strong>Generate Renewal</strong><br />
        Generate a renewal for the final billing cycle
      </>
    }
    { value === 'refund' &&
      <>
        <strong>Refund Renewal</strong><br />
        Refund the contact for the prorated amount of the unused billing cycle
      </>
    }
    { value === 'nothing' &&
      <>
        <strong>Do nothing</strong><br />
        Cancel subscription and do not generate a final renewal
      </>
    }
  </div>
)

CancelStrategyToken.propTypes = {
  value: PropTypes.string
}

export default CancelStrategyToken
