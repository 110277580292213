
import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const EmailActivityToken = (email_activity) => (
  <div className="email-activity-token">
    <div className="email-activity-token-avatar">
      <ContactAvatar { ...email_activity.contact } />
    </div>
    <div className="email-activity-token-label">
      <span><Date datetime={ email_activity.created_at } /></span><br />
      { email_activity.contact.display_name } { email_activity.description }
    </div>
  </div>
)

EmailActivityToken.propTypes = {
  contact: PropTypes.object,
  created_at: PropTypes.string,
  description: PropTypes.string
}

export default EmailActivityToken