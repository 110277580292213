import EnrollmentsShow from './workflows/enrollments/show'
import WorkflowsList from './workflows/list'
import WorkflowsShow from './workflows/show'
import EmailsShow from './emails/show'
import EmailsList from './emails/list'

const routes = [
  { path: '/', component: WorkflowsList },
  { path: '/emails', component: EmailsList },
  { path: '/emails/:id/:view', component: EmailsShow },
  { path: '/workflows', component: WorkflowsList },
  { path: '/workflows/:id/:view', component: WorkflowsShow },
  { path: '/workflows/:workflow_id/enrollments/:id', component: EnrollmentsShow }
]

export default routes
