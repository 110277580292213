import PurposeField from '@apps/campaigns/admin/components/purposefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact_ids: PropTypes.array,
    event: PropTypes.object,
    filter: PropTypes.object,
    form: PropTypes.object,
    list: PropTypes.object,
    program: PropTypes.object,
    program_id: PropTypes.number,
    pipeline: PropTypes.number,
    service: PropTypes.object,
    store: PropTypes.object,
    user: PropTypes.object,
    onBack: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact_ids, event, filter, form, list, pipeline, service, store } = this.props
    const program = this._getProgram()
    const program_id = this.props.program_id || program.id
    return {
      title: 't(New Email Campaign)',
      method: 'POST',
      action: '/api/admin/campaigns/email',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', defaultValue: program_id },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Purpose)', name: 'purpose', type: PurposeField, channel: 'email', required: true, defaultValue: program ? 'marketing' : 'transactional' },
            ...event ? [
              { name: 'event_id', type: 'hidden', defaultValue: event.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'registrations' } }
            ] : [],
            ...form ? [
              { name: 'form_id', type: 'hidden', defaultValue: form.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'responses' } }
            ] : [],
            ...service ? [
              { name: 'service_id', type: 'hidden', defaultValue: service.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'subscriptions' } }
            ] : [],
            ...store ? [
              { name: 'store_id', type: 'hidden', defaultValue: store.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'orders' } }
            ] : [],
            ...pipeline ? [
              { name: 'pipeline_id', type: 'hidden', defaultValue: pipeline.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'deals' } }
            ] : [],
            ...list ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { list_id: list.id } } }
            ] : [],
            ...filter ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { filter_id: filter.id } } }
            ] : [],
            ...contact_ids ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { contact_ids } } }
            ] : [],
            { label: 't(Email Details)', type: 'segment', fields: [
              { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/team/programs/${program_id}/templates`, valueKey: 'id', textKey: 'title', required: true, defaultValue: program.default_template ? program.default_template.id : null },
              { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${program_id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true, defaultValue: program.default_sender ? program.default_sender.id : null },
              { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, maxLength: 150, required: true },
              { label: 't(Preview Text)', name: 'preview_text', type: 'textfield', maxLength: 150 }
            ] }
          ]
        }
      ]
    }
  }

  _getProgram() {
    const { event, form, list, pipeline, program, service, store } = this.props
    if(event) return event.program
    if(form) return form.program
    if(list) return list.program
    if(pipeline) return pipeline.program
    if(program) return program
    if(service) return service.program
    if(store) return store.program
    return null
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(email_campaign) {
    this.context.router.push(`/campaigns/email/${email_campaign.id}`)
    this.context.modal.close()
  }

}

export default New
