import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Subscriptions)',
  access: { rights: { $allOf: ['dashboards:access_app','subscriptions:view_services'] } },
  collection: {
    endpoint: '/api/admin/dashboards/subscriptions/annual_recurring_revenue',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'subscription.contact.display_name', sort: 'last_name', primary: true, format: ({ subscription }) => {
        return <ContactToken { ...subscription.contact } />
      } },
      { label: 't(Organization)', key: 'subscription.position.organization', sort: 'organization' },
      { label: 't(Plan)', key: 'plan.title', sort: 'plan' },
      { label: 't(Monthly Revenue)', key: 'price', collapsing: true, format: 'currency' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Subscriptions)',
      text: 't(You have not created any subscriptions for this service)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/subscriptions/services/${record.subscription.service.id}/subscriptions/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
