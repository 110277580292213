import encode from '@core/services/qrcode/encode'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import QRCode from './qrcode'
import React from 'react'

class Shortlink extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    buttons: PropTypes.array,
    title: PropTypes.string,
    text: PropTypes.string,
    shortlink: PropTypes.object
  }

  render() {
    const { buttons, title } = this.props
    return (
      <div className="detailcard">
        <div className="detailcard-qrcode">
          <Button { ...this._getQRCode() }>
            <Img { ...this._getQRImage() } />
          </Button>
        </div>
        { buttons && buttons.length > 0 &&
          <div className="detailcard-buttons">
            <div className="detailcard-actions">
              { buttons.map((button, index) => (
                <div className="detailcard-action" key={`button_${index}`}>
                  <Button { ...button } className="detailcard-button" />
                  <span><T text={ button.title } /></span>
                </div>
              )) }
            </div>
          </div>
        }
        <div className="detailcard-details">
          <strong>{ title }</strong><br />
          <Button {...this._getExpandedUrl() } /><br />
          <Button {...this._getShortUrl() } />
        </div>
      </div>
    )
  }

  _getExpandedUrl() {
    const { shortlink } = this.props
    return {
      label: shortlink.expanded_url,
      className: 'link',
      link: shortlink.expanded_url
    }
  }

  _getQRCode() {
    const { shortlink } = this.props
    return {
      className: '',
      modal: <QRCode shortlink={ shortlink } />
    }
  }

  _getQRImage() {
    const { team } = this.context.admin
    const { shortlink } = this.props
    const encoded = encode({
      dark: '#000000',
      light: '#FFFFFF',
      string: shortlink.expanded_url
    })
    return {
      src: `${team.public_host}/${encoded}.png`
    }
  }

  _getShortUrl() {
    const { shortlink } = this.props
    return {
      label: shortlink.short_url,
      className: 'link',
      link: shortlink.short_url
    }
  }

}

export default Shortlink
