import MultiForm from '@admin/components/multiform'
import Properties from './properties'
import PropTypes from 'prop-types'
import Contact from './contact'
import React from 'react'

class Merge extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    duplicate: PropTypes.object,
    fields: PropTypes.array,
    programs: PropTypes.array
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { contact } = this.props
    return {
      title: 't(Merge Contact)',
      action: `/api/admin/crm/contacts/${contact.id}/merge`,
      method: 'PATCH',
      defaultValue: this._getDefault(),
      formatData: this._getData,
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { contact, duplicate, fields, programs } = this.props
    return {
      contact1_id: contact.id,
      contact2_id: duplicate ? duplicate.contact.id : null,
      fields,
      programs
    }
  }

  _getData(data) {
    return {
      contact2_id: data.contact2_id,
      values: data.values
    }
  }

  _getSteps() {
    const { duplicate } = this.props
    return [
      ...!duplicate ? [
        { label: 'Choose Contact', component: Contact }
      ] : [],
      { label: 'Map Properties', component: Properties }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Merge
