import Page from '@admin/components/page'
import Tasks from './tasks'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 'Tasks',
  manager: {
    path: '/admin/tasks/tasks',
    items: [
      {
        label: 't(Assigned to Me)',
        path: '/to_me',
        panel: <Tasks scope="to_me" />
      },
      {
        label: 't(Assigned by Me)',
        path: '/by_me',
        panel: <Tasks scope="by_me" />
      },
      {
        label: 't(Overdue)',
        path: '/overdue',
        panel: <Tasks scope="overdue" />
      },
      {
        label: 't(Upcoming)',
        path: '/upcoming',
        panel: <Tasks scope="upcoming" />
      },
      {
        label: 't(Completed)',
        path: '/completed',
        panel: <Tasks scope="completed" />
      },
      {
        label: 't(All)',
        path: '/all',
        panel: <Tasks scope="all" />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
