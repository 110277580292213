import DateField from '@admin/components/form/datefield'
import TimeField from '@admin/components/form/timefield'
import Dropdown from '@admin/components/form/dropdown'
import Button from '@admin/components/button'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'

const dateformats = ['MM-DD-YYYY','MM-DD-YY','YYYY-MM-DD','M/D/YY','M/D/YYYY','MM/DD/YY','MM/DD/YYYY','MMDDYY','MMDDYYYY']
const timeformats = ['HH:mm','H:mm','h:m','h:mmA','h:mm A','h:mA','h:mma','h:mm a','h:ma']
const formats = dateformats.reduce((formats, date) => [
  ...formats,
  ...timeformats.map(time => `${date} ${time}`)
])
const regions = ['Pacific/Honolulu','America/Anchorage','America/Los_Angeles','America/Phoenix','America/Denver','America/Chicago','America/New_York','America/Halifax','America/Buenos_Aires','America/St_Johns','Europe/London','Europe/Paris','Europe/Helsinki','Europe/Moscow','Asia/Dubai','Asia/Kolkata','Asia/Bangkok','Asia/Shanghai','Asia/Tokyo','Australia/Sydney','Pacific/Auckland']

class DateTimeField extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.any,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    date: null,
    time: null,
    tz: null
  }

  _handleClear = this._handleClear.bind(this)

  render() {
    const { date, time } = this.state
    return (
      <div className="maha-datetimefield maha-input">
        <div className="maha-datetimefield-date">
          <DateField { ...this._getDateField() } />
        </div>
        <div className="maha-datetimefield-time">
          <TimeField { ...this._getTimeField() } />
        </div>
        <div className="maha-datetimefield-tz">
          <Dropdown { ...this._getTimezone() } />
        </div>
        { (date || time) &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { date, time, tz } = this.state
    const { value } = this.props
    if(date !== prevState.date || time !== prevState.time || tz !== prevState.tz) {
      this._handleChange()
    }
    if (value !== prevProps.value && value !== this._getCurrentStateValue()) {
      this._handleSet(value)
    }
  }

  _getAbbr(region) {
    const date = this.state.date || moment().format('YYYY-MM-DD')
    const time = this.state.time || moment().format('h:mm A')
    const now = moment.tz(`${date} ${time}`, region)
    if(region === 'America/Buenos_Aires') return 'ART'
    if(region === 'Asia/Bangkok') return 'ICT'
    if(region === 'Asia/Dubai') return 'GST'
    return now.format('z')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getCurrentStateValue() {
    const { date, time, tz } = this.state
    if (!date || !time || !tz) return null
    return moment.tz(`${date} ${time}`, 'YYYY-MM-DD h:mm A', tz).utc().format()
  }

  _getDateField() {
    const { tabIndex } = this.props
    const { date } = this.state
    return {
      entity: 'date',
      tabIndex,
      value: date,
      onChange: this._handleUpdate.bind(this, 'date'),
      clearable: false
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getTimeField() {
    const { tabIndex } = this.props
    const { time } = this.state
    return {
      tabIndex,
      value: time,
      onChange: this._handleUpdate.bind(this, 'time'),
      clearable: false
    }
  }

  _getTimezone() {
    const { tabIndex } = this.props
    const { tz } = this.state
    return {
      tabIndex,
      placeholder: 'TZ',
      value: tz,
      options: regions.map(region => ({
        value: region,
        text: this._getAbbr(region)
      })),
      onChange: this._handleUpdate.bind(this, 'tz')
    }
  }

  _handleChange() {
    const { date, time, tz } = this.state
    if(!date || !time || !tz) return
    const value = moment.tz(`${date} ${time}`, 'YYYY-MM-DD h:mm A', tz).utc().format()
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      date: null,
      time: null
    })
  }

  _handleSet(value) {
    const { timezone } = this.context.admin.account
    const tz = this.state.tz || timezone
    const parsed = value ? moment.tz(value, formats, tz) : moment.tz(tz).add('24', 'hours')
    this.setState({
      date: parsed.format('YYYY-MM-DD'),
      time: parsed.format('h:mm A'),
      tz
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default DateTimeField
