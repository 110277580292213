import TextField from '@admin/components/mjson_designer/components/textstylefield'

export const TextSection = (config, device, theme, type, defaults = {}) => ({
  label: 't(Typography)',
  instructions: 't(Specify the style and properites of text in this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    {
      label: 't(Text)',
      device,
      name: 'styles.text',
      type: TextField,
      theme,
      defaultColor: true,
      defaultValue: defaults.text || {
        preset: 'text'
      },
      value: config.styles.text
    }
  ]
})