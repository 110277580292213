import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Secrets from '../secrets'
import Edit from '../edit'
import React from 'react'

const Overview = ({ provider }) => {

  const details = {
    sections: [
      {
        title: 't(Provider Details)',
        items: [
          { label: 't(Title)', content: provider.title },
          { label: 't(Admin Domain)', content: provider.admin_domain },
          { label: 't(Short Domain)', content: provider.short_domain },
          { label: 't(Support Email)', content: provider.support_email }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  provider: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview provider={ props.provider } />,
  tasks: {
    items: [
      {
        label: 'Edit Provider',
        modal: <Edit provider={ props.provider } />
      },
      {
        label: 'Edit Secrets',
        modal: <Secrets provider={ props.provider } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
