import EmailField from '@admin/components/form/emailfield'
import ChannelField from './channelfield'

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

const validate = ({ contact, network, value, onValid }) => {
  if(!EMAIL_REGEX.test(value)) {
    return onValid(null, 'Enter a valid email address')
  }
  network.request({
    endpoint: '/api/admin/crm/email_addresses/check',
    method: 'PATCH',
    body: {
      address: value,
      ...contact ? {
        contact_id: contact.id
      } : {}
    },
    onSuccess: ({ data }) => {
      if(data === true) {
        return onValid(null, 'A contact with this email already exists')
      }
      return onValid(value)
    },
    onFailure: () => {
      return onValid(null, 'Unable to validate email')
    }
  })
}

const unpack = (item) => item ? item.address : null

const pack = (address) => ({ address })

const compare = (item1, item2) => {
  return item1 === item2
}

const verify = (value) => {
  return EMAIL_REGEX.test(value)
}

export default ChannelField({
  compare,
  emptyValue: '',
  entity: 'email address',
  format: (item) => item.address,
  pack,
  unpack,
  validate,
  verify
}, EmailField)
