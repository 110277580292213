export const getCode = (length, prefix = '') => {
  return new Array(length - 1).fill(0).reduce((code,i) => {
    return code + _.random(1,35).toString(36)
  }, prefix + _.random(10,35).toString(36))
}

export const generateCode = async (req, options = {}) => {
  const key = options.key || 'code'
  const length = options.length || 10
  const { table } = options
  let code = null
  let done = false
  while(!done) {
    code = getCode(length)
    done = table === undefined || isUnique(req, key, table, code)
  }
  return code
}

const isUnique = async (req, key, table, code) => {
  const items = await req.maha(table).where({ [key]: code })
  return items.length === 0
}
