import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Send extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    invoice: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { user } = this.context.admin
    const { invoice } = this.props
    const item = invoice.status === 'paid' ? 'receipt' : 'invoice'
    const subject = `Your ${item}`
    const message = `Here is a copy of your ${item}.`
    return {
      title: `Send ${_.capitalize(item)}`,
      method: 'PATCH',
      action: `/api/admin/finance/invoices/${invoice.id}/send`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(From)', name: 'sender_id', type: 'lookup', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${invoice.program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true },
            { label: 't(To)', name: 'to', type: 'recipientfield', required: true, defaultValue: invoice.customer.email },
            { label: 't(Subject)', name: 'subject', type: 'textfield', required: true, defaultValue: subject },
            { label: 't(Message)', name: 'message', type: 'textarea', required: true, rows: 10, defaultValue: message }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Send
