import VoiceDesigner from '@apps/phone/admin/components/voice_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    voice_campaign: PropTypes.object
  }

  render() {
    return <VoiceDesigner { ...this._getVoiceDesigner() } />
  }

  _getVoiceDesigner() {
    const { voice_campaign } = this.props
    return { voice_campaign }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design voice_campaign={ props.voice_campaign } />
})

export default Panel(null, mapPropsToPanel)
