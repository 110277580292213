import PropTypes from 'prop-types'
import Access from './access'
import React from 'react'

const Accesses = ({ item }) => (
  <div className="drive-accesses">
    { item.accesses.map((access, index) => (
      <Access { ...access } key={`access_${index}`} />
    )) }
  </div>
)

Accesses.propTypes ={
  item: PropTypes.object
}

export default Accesses
