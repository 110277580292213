import Menu from '@admin/components/menu'
import Templates from './templates'
import PropTypes from 'prop-types'
import Items from './items'
import React from 'react'

class Settings extends React.PureComponent {

  static propTypes = {
    device: PropTypes.object,
    root_endpoint: PropTypes.string,
    website: PropTypes.object,
    onBack: PropTypes.func,
    onNavigate: PropTypes.func,
    onSettings: PropTypes.func
  }

  render() {
    return <Menu { ...this._getMenu()} />
  }

  _getMenu() {
    return {
      type: 'pointing',
      items: [
        { label: 'Templates', component: <Templates { ...this._getProps() } /> },
        { label: 'Library', component: <Items { ...this._getProps() } /> }
      ]
    }
  }

  _getPanel() {
    return {
      showHeader: false
    }
  }

  _getProps() {
    const { device, root_endpoint, website, onNavigate, onSettings } = this.props
    return {
      device,
      root_endpoint,
      website,
      onNavigate,
      onSettings
    }
  }

}

export default Settings
