import Actions from '@apps/automation/admin/components/actions'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ actions, enrollment, workflow }) => {

  const contact = {
    label: enrollment.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${enrollment.contact.id}`
  }

  const program = {
    label: workflow.program.title,
    className: 'link',
    route: `/admin/team/programs/${workflow.program.id}`
  }

  const workflow_link = {
    label: workflow.title,
    className: 'link',
    route: `/admin/automation/workflows/${workflow.id}`
  }

  const details = {
    comments: `automation_enrollments/${enrollment.id}`,
    sections: [{
      items: [
        { label: 't(Contact)', content: <Button { ...contact } /> },
        { label: 't(Program)', content: <Button { ...program } /> },
        { label: 't(Workflow)', content: <Button { ...workflow_link } /> },
        { label: 't(Enrolled)', content: enrollment.created_at, format: 'datetime' },
        ...enrollment.unenrolled_at ? [{ label: 'Unenrolled', content: enrollment.unenrolled_at, format: 'datetime' }] : [],
        { component: <Actions actions={ actions } enrollment={ enrollment } trigger={ workflow.trigger } /> }
      ]
    }]
  }

  if(enrollment.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This enrollment was canceled)' }
  } else if(enrollment.status === 'failed') {
    details.alert = { color: 'red', message: 't(This enrollment was unable to complete)' }
  } else if(enrollment.status === 'error') {
    details.alert = { color: 'red', message: 't(This enrollment was unable to complete)' }
  } else if(enrollment.status === 'lost') {
    details.alert = { color: 'red', message: 't(This enrollment was lost)' }
  } else if(enrollment.status === 'active') {
    details.alert = { color: 'yellow', message: 't(This enrollment is active)' }
  } else if(enrollment.status === 'completed') {
    details.alert = { color: 'green', message: 't(This enrollment was completed)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  actions: PropTypes.array,
  enrollment: PropTypes.object,
  workflow: PropTypes.object
}

export default Overview
