import ProgramToken from '@apps/crm/admin/tokens/program'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Phone Numbers)',
  collection: {
    endpoint: `/api/admin/team/usage/${props.usage.id}/phone_numbers`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (usage) => (
        <ProgramToken { ...usage.program } />
      ) },
      { label: 't(Number)', key: 'formatted', primary: true }
    ],
    empty: {
      icon: 'hashtag',
      title: 't(No Phone Numbers)',
      text: 't(You have not yet created any phone numbers)'
    },
    entity: 'phone number',
    defaultSort: { key: 'title', order: 'asc' }
  }
})

export default Panel(null, mapPropsToPanel)
