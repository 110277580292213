export const onAdd = (file) => ({
  type: 'ADD',
  file
})

export const onClear = () => ({
  type: 'CLEAR'
})

export const onRemove = (index) => ({
  type: 'REMOVE',
  index
})

export const onSetSources = (sources) => ({
  type: 'SET_SOURCES',
  sources
})

export const onUpdate = (index, data) => ({
  type: 'UPDATE',
  index,
  data
})
