import ModalPanel from '@admin/components/modal_panel'
import Validating from './validating'
import Importing from './importing'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Overview extends React.Component {

  static propTypes = {
    files: PropTypes.array,
    multiple: PropTypes.bool,
    processed: PropTypes.bool,
    requirements: PropTypes.array,
    retry: PropTypes.bool,
    onAlternate: PropTypes.func,
    onCreate: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    mode: null
  }

  _handleImporting = this._handleImporting.bind(this)

  render() {
    const { mode } = this.state
    if(!mode) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        { mode === 'validating' &&
          <Validating { ...this._getValidating() } />
        }
        { mode === 'importing' &&
          <Importing { ...this._getImporting() } />
        }
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { requirements } = this.props
    this.setState({
      mode: requirements ? 'validating' : 'importing'
    })
  }

  _getPanel() {
    const { files } = this.props
    return {
      title: `Importing ${pluralize('File', files.length)}`
    }
  }

  _getValidating() {
    const { files, requirements, onAlternate } = this.props
    return {
      files,
      requirements,
      onAlternate,
      onDone: this._handleImporting
    }
  }

  _getImporting() {
    return this.props
  }

  _handleImporting() {
    this.setState({
      mode: 'importing'
    })
  }

}

export default Overview
