import ContactFields from '@apps/crm/admin/components/contactfields'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Fields extends React.Component {

  static propTypes = {
    event: PropTypes.object
  }

  render() {
    return <ContactFields { ...this._getContactFields() } />
  }

  _getContactFields() {
    const { event } = this.props
    return {
      name: 'contact_config',
      endpoint: `/api/admin/events/events/${event.id}/fields`,
      fields: event.contact_config.fields,
      program: event.program
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Contact Fields)',
  component: <Fields event={ props.event } />
})

export default Panel(null, mapPropsToPanel)
