import DateRangeField from '@apps/dashboards/admin/components/daterangefield'
import ChartField from '@apps/dashboards/admin/components/chartfield'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    card: PropTypes.object,
    panel: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { card, panel } = this.props
    const { config } = this.state
    const type = _.get(config, 'config.type')
    const elements = _.get(config, 'config.elements')
    const daterange_source = _.get(config, 'config.daterange_source')
    return {
      title: 't(Edit Card)',
      endpoint: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}/edit`,
      action: `/api/admin/dashboards/panels/${panel.id}/cards/${card.id}`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          label: 'Data',
          fields: [
            ...team.has_programs ? [
              { label: 'Scope', type: 'segment', fields: [
                { label: 't(Program)', name: 'config.program_id', type: 'lookup', endpoint: '/api/admin/programs', valueKey: 'id', textKey: 'title', format: ProgramToken, required: true }
              ] }
            ] : [],
            { label: 't(Date Range)', type: 'segment', fields: [
              { name: 'config.daterange_source', type: 'radiogroup', options: [
                { value: 'dashboard', text: 't(Use dashboard daterange)' },
                { value: 'custom', text: 't(Custom)' }
              ], defaultValue: 'dashboard', required: true },
              { name: 'config.daterange', type: DateRangeField, show: daterange_source === 'custom', required: true }
            ] }
          ]
        },
        {
          label: 't(Content)',
          fields: [
            { label: 't(Elements)', name: 'config.elements', type: 'checkboxes', options: [
              { value: 'chart', text: 't(Chart)' },
              { value: 'table', text: 't(Table)' }
            ], defaultValue: ['chart'] },
            { label: 't(Chart Type)', name: 'config.type', type: ChartField, show: _.includes(elements, 'chart'), include: ['area','line','bar'], defaultValue: 'area', required: true },
            { label: 't(Color)', name: 'config.color', type: 'colorfield', show: _.includes(elements, 'chart'), defaultValue: '#2185D0', required: true },
            { label: 't(Line Tension)', name: 'config.tension', type: 'radiogroup', show: _.includes(elements, 'chart') && type !== 'bar', options: [
              { value: 'smooth', text: 't(Smooth)' },
              { value: 'rigid', text: 't(Rigid)' }
            ], defaultValue: 'smooth' },
            { label: 't(Points)', name: 'config.points', type: 'radiogroup', show: _.includes(elements, 'chart') && type !== 'bar', options: [
              { value: 'show', text: 't(Show points)' },
              { value: 'hide', text: 't(Hide points)' }
            ], defaultValue: 'show', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(organizer) {
    this.context.modal.close()
  }

}

export default Edit
