import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const SourceToken = ({ source }) => (
  <div className="source-token">
    <div className="source-token-icon">
      <Img src={`/images/services/${source.source_name}.png`} />
    </div>
    <div className="source-token-text">
      { source.name }
    </div>
  </div>
)

SourceToken.propTypes = {
  source: PropTypes.object
}

export default SourceToken
