import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { service, subscription } = this.props
    return {
      title: 't(Edit Subscription)',
      method: 'PATCH',
      endpoint: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/edit`,
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Start Date)', name: 'started_on', type: 'datefield', required: true },
            { label: 't(Price)', name: 'price', type: 'moneyfield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
