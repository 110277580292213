import MBlocksDesigner from '@admin/components/mblocks_designer/wrapper'
import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  render() {
    const { email_campaign } = this.props
    return (
      <>
        { email_campaign.engine === 'mblocks' ?
          <MBlocksDesigner { ...this._getMBlocksDesigner() } /> :
          <MJSONDesigner { ...this._getMJSONDesigner() } />
        }
      </>
    )
  }

  _getMBlocksDesigner() {
    const { email_campaign } = this.props
    return {
      defaultValue: email_campaign.config,
      editable: _.includes(['active','draft','inactive'], email_campaign.status),
      endpoint: `/api/admin/campaigns/email/${email_campaign.id}`,
      email: email_campaign,
      program: email_campaign.program,
      tokens: this._getTokens()
    }
  }

  _getMJSONDesigner() {
    const { email_campaign } = this.props
    const { admin } = this.context
    return {
      editable: canAccess({ rights: { $allOf: ['campaigns:manage_email_campaigns'] } }, admin) && _.includes(['draft','scheduled'], email_campaign.status),
      endpoint: `/campaigns_email_campaigns/${email_campaign.id}`,
      root_route: `/campaigns/email/${email_campaign.id}/design`,
      themeable: false,
      type: 'email',
      versionable: false
    }
  }

  _getTokens() {
    const { email_campaign } = this.props
    if(email_campaign.store) return this._getOrderTokens(email_campaign.store)
    if(email_campaign.event) return this._getRegistrationTokens(email_campaign.event)
    return []
  }

  _getPaymentTokens(model) {
    return {
      title: 't(Payment Tokens)',
      tokens: [
        { name: 'Method', token: `${model}.payment_method` },
        { name: 'Amount', token: `${model}.payment_amount` },
        { name: 'Card', token: `${model}.payment_card` },
        { name: 'Summary', token: `${model}.payment_summary` }
      ]
    }
  }

  _getRegistrationTokens(event) {
    return [
      {
        title: 't(Registration Tokens)',
        tokens: [
          { name: 'First Name', token: 'registration.first_name' },
          { name: 'Last Name', token: 'registration.last_name' },
          { name: 'Email', token: 'registration.email' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `registration.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('registration')
    ]
  }

  _getOrderTokens(store) {
    return [
      {
        title: 't(Order Tokens)',
        tokens: [
          { name: 'Order ID', token: 'order.id' },
          { name: 'First Name', token: 'order.first_name' },
          { name: 'Last Name', token: 'order.last_name' },
          { name: 'Email', token: 'order.email' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `order.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('order')
    ]
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design email_campaign={ props.email_campaign } />
})

export default Panel(null, mapPropsToPanel)
