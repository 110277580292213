import CallActivities from '@apps/phone/admin/components/call_activities'
import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ actions, call, voice_campaign, enrollment }, { admin }) => {

  const contact = {
    label: call.contact.display_name ,
    className: 'link',
    route: `/admin/crm/contacts/${call.contact.id}`
  }

  const program = {
    label: call.program.title ,
    className: 'link',
    route: `/admin/team/programs/${call.program.id}`
  }

  const phone_number = {
    label: call.program_phone_number.formatted ,
    className: 'link',
    route: `/admin/phone/numbers/${call.program_phone_number.id}`
  }

  const campaign = voice_campaign && voice_campaign.direction === 'outbound' ? {
    label: voice_campaign.title ,
    className: 'link',
    route: `/admin/campaigns/voice/${voice_campaign.id}`
  } : null

  const details = {
    comments: `phone_calls/${call.id}`,
    sections: [
      {
        title: 't(Call Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...contact } /> },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
          { label: 't(Phone Number)', content: <Button { ...phone_number } /> },
          ...campaign ? [
            { label: 't(Voice Campaign)', content: <Button { ...campaign } /> }
          ] : [],
          { label: 't(Time)', content: call.created_at, format: 'datetime' },
          { label: 't(Duration)', content: call.seconds, format: 'duration' },
          { label: 't(Direction)', content: call.direction }
        ]
      }
    ]
  }

  if(call.from_number) details.sections[0].items.push({ label: 't(From)', content: call.from_number.formatted })
  if(call.from_user) details.sections[0].items.push({ label: 't(From)', content: call.from_user.full_name })
  if(call.to_number) details.sections[0].items.push({ label: 't(To)', content: call.to_number.formatted })
  if(call.to_user) details.sections[0].items.push({ label: 't(To)', content: call.to_user.full_name })

  if(enrollment) {
    details.sections[0].items.push({ component: <Actions actions={ actions } enrollment={ enrollment } trigger={ voice_campaign.trigger } call={ call } /> })
  } else {
    details.sections[0].items.push({ component: <CallActivities call={ call } /> })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  actions: PropTypes.array,
  call: PropTypes.object,
  voice_campaign: PropTypes.object,
  enrollment: PropTypes.object
}

const mapResources = (props, context) => ({
  ...props.call.enrollment ? {
    actions: {
      endpoint: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}/enrollments/${props.call.enrollment.id}/actions`,
      refresh: `/teams/${context.admin.team.id}/admin/phone/calls/${props.call.id}`
    },
    voice_campaign: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}`,
    enrollment: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}/enrollments/${props.call.enrollment.id}`
  } : {}
})

export default Container(mapResources)(Overview)
