import Page from '@admin/components/page'
import Overview from './overview'
import Teams from './teams'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.app.title,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: `/admin/platform/apps/${resources.app.code}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview app={ resources.app } />
      },
      {
        label: 'Teams',
        path: '/teams',
        panel: <Teams app={ resources.app } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  app: `/api/admin/platform/apps/${props.params.code}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
