import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ list }, { admin }) => {

  const program = {
    label: list.program.title,
    className: 'link',
    route: `/admin/team/programs/${list.program.id}`
  }

  const details = {
    audits: `crm_lists/${list.id}`,
    comments: `crm_lists/${list.id}`,
    sections: [
      {
        title: 't(List Details)',
        items: [
          { label: 't(Title)', content: list.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
          { label: 't(Type)', content: list.type.toUpperCase() }
        ]
      },
      {
        title: 't(Subscription Emails)',
        instructions: 't(Sending an email to the subscribe and/or unsubscribe address below will toggle the contact\'s subscription to this list and their consent for receiving marketing email.)',
        items: [
          { label: 't(Subscribe)', content: list.subscribe_email, format: 'email' },
          { label: 't(Unsubscribe)', content: list.unsubscribe_email, format: 'email' }
        ]
      }
    ]
  }

  if(list.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This list was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  list: PropTypes.object,
  program: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview list={ props.list } />,
  tasks: props.program.access_type === 'manage' ? {
    items: [
      {
        label: 't(Edit List)',
        show: !props.list.deleted_at,
        access: { rights: { $oneOf: ['crm:manage_lists'] } },
        modal: <Edit list={ props.list } />
      },
      {
        label: 't(Delete List)',
        show: !props.list.deleted_at,
        access: { rights: { $oneOf: ['crm:manage_lists'] } },
        confirm: 't(Are you sure you want to delete this list? You will also remove all of the contacts and delete any associated workflow, emails, and performance data. This will not delete contacts from your CRM.)',
        request: {
          endpoint: `/api/admin/crm/lists/${props.list.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted list)')
            context.router.goBack()
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to delete list)')
        }
      }
    ]
  } : {}
})

const mapResourcesToPanel = (props, context) => ({
  program: `/api/admin/team/programs/${props.list.program.id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
