import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  singlepage: {
    title: 't(Single Page Website)',
    text: 't(A single page website covering all the basics)',
    price: 299,
    discount: 199
  },
  fivepage: {
    title: 't(5-Page Website)',
    text: 't(A 5-page website with a home page, about page, services page, contact page and blog)',
    price: 699,
    discount: 499,
    example: ''
  },
  sevenpage: {
    title: 't(7-Page Website)',
    text: 't(A 7-page website with a home page, about page, consultation page, terms page, booking tools page, why use a travel advisor page, and a blog)',
    price: 699,
    discount: 499
  }
}

const WebsiteTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ types[value].title } /></strong> (<span className="alert">
      <span className="strikethrough">${ types[value].price }</span> ${ types[value].discount } one time setup fee
    </span>)<br />
    <T text={ types[value].text } />
  </div>
)

WebsiteTypeToken.propTypes = {
  value: PropTypes.string
}

export default WebsiteTypeToken
