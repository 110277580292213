import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import Panel from '@admin/components/panel'
import New from '../revenue_types/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Revenue Types)',
  access: { rights: { $oneOf: ['finance:manage_configuration'] } },
  collection: {
    endpoint: '/api/admin/finance/revenue_types',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'fulltitle', primary: true, format: RevenueTypeToken }
    ],
    defaultSort: { key: 'fulltitle', order: 'asc' },
    empty: {
      icon: 'tag',
      title: 't(No Revenue Types)',
      text: 't(You have not yet added any revenue types)',
      buttons: [
        { 
          label: 't(Create Revenue Type)',
          access: { rights: { $oneOf: ['finance:manage_configuration'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'revenue type',
    onClick: (record) => context.router.push(`/finance/revenue_types/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Revenue Type)',
    access: { rights: { $oneOf: ['finance:manage_configuration'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
