import Form from '@admin/components/form'
import AppToken from '@admin/tokens/app'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    maha: PropTypes.object,
    modal: PropTypes.object,
    provider: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { maha, provider } = this.context
    return {
      title: 't(Edit Team)',
      method: 'PATCH',
      endpoint: '/api/admin/team/settings/edit',
      action: '/api/admin/team/settings',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          label: 'General Settings',
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: provider.id, required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', multiple: false }
          ]
        },
        ...Object.values(maha.settings).reduce((sections, app) => [
          ...sections,
          {
            label: <AppToken { ...app } />,
            padded: false,
            collapsing: true,
            collapsed: true,
            fields: app.fields.map(field => ({
              ...field,
              name: `settings.${app.code}.${field.name}`
            }))
          }
        ], [])
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
