export const canAccess = (criteria, config) => {
  if(!criteria) return true
  const keys = Object.keys(criteria)
  if(keys.length === 0) return true
  if(keys.length > 1) return and(keys.map(key => ({ [key]: criteria[key] })), config)
  if(criteria.$and) return and(criteria.$and, config)
  if(criteria.$or) return or(criteria.$or, config)
  const key = keys[0]
  const op = Object.keys(criteria[key])[0]
  const left = _.get(config, key)
  const right = criteria[key][op]
  if(op === '$allOf') return allOf(left, right)
  if(op === '$oneOf') return oneOf(left, right)
  if(op === '$eq') return eq(left, right)
  if(op === '$canManage') return program(left, right, ['manage'])
  if(op === '$canEdit') return program(left, right, ['manage','edit'])
  if(op === '$canView') return program(left, right, ['manage','edit','view'])
  if(left[op]) return canAccess({ [op]: right }, left)
  return true
}

const and = (criteria, config) => {
  return criteria.reduce((valid, subcriteria) => {
    return !valid ? false : canAccess(subcriteria, config)
  }, true)
}

const or = (criteria, config) => {
  return criteria.reduce((valid, subcriteria) => {
    return valid || canAccess(subcriteria, config)
  }, false)
}

const allOf = (left, right) => {
  return right.reduce((valid, value) => {
    return !valid ? false : _.includes(left, value)
  }, true)  
}

const oneOf = (left, right) => {
  return right.reduce((valid, value) => {
    return valid || _.includes(left, value)
  }, false)
}

const eq = (left, right) => {
  return left === right
}

const program = (programs, program_id, allowed) => {
  const program = programs.find(program => {
    return program.id === program_id
  })
  return program ? _.includes(allowed, program.access_type) : false
}
