import PropTypes from 'prop-types'
import React from 'react'

class Touch extends React.Component {

  static propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    onHold: PropTypes.func
  }

  static defaultProps = {
    disabled: false
  }

  state = {
    held: false,
    timer: null
  }

  _handleTouchStart = this._handleTouchStart.bind(this)
  _handleTouchEnd = this._handleTouchEnd.bind(this)

  render() {
    return (
      <div { ...this._getTouch() }>
        { this.props.children }
      </div>
    )
  }

  _getClass() {
    const { timer } = this.state
    const classes = ['maha-touch']
    if(timer !== null) classes.push('held')
    return classes.join(' ')
  }

  _getTouch() {
    return {
      className: this._getClass(),
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd
    }
  }

  _handleHeld(e) {
    const { disabled } = this.props
    this._handleTouchEnd()
    if(disabled) return
    this.props.onHold(e)
  }

  _handleTouchStart(e) {
    const handleHeld = this._handleHeld.bind(this, e)
    this.setState({
      timer: setTimeout(handleHeld, 500)
    })
  }

  _handleTouchEnd() {
    const { timer } = this.state
    clearTimeout(timer)
    this.setState({
      timer: null
    })
  }

}

export default Touch
