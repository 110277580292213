import RefundStrategyToken from '@apps/finance/admin/tokens/refund_strategy'
import AmountField from '@apps/finance/admin/components/amountfield'
import RefundField from '@apps/stores/admin/components/refundfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Cancel extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    order: PropTypes.object,
    store: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { order, store } = this.props
    const options = this._getOptions()
    return {
      title: 't(Cancel Order)',
      action: `/api/admin/stores/stores/${store.id}/orders/${order.id}/cancel`,
      method: 'PATCH',
      saveText: 'Done',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Reason)', name: 'reason', type: 'textfield', placeholder: 't(Enter a reason for refund)' },
            { label: 't(Refund)', type: 'segment', fields: [
              { type: 'radiogroup', name: 'strategy', deselectable: false, options, defaultValue: options[0].value, required: true },
              ...this._getItems()
            ] },
            ...this._getRefund()
          ]
        }
      ]
    }
  }

  _getItems() {
    const { order } = this.props
    const { config } = this.state
    if(config.strategy === 'partial') {
      return [
        { name: 'item_ids', type: RefundField, items: order.items, required: true }
      ]
    }
    return []
  }

  _getOptions() {
    const unfulfilled = this._getStatusItemsCount(['unfulfilled'])
    const options = [
      { value: 'unfulfilled', text: `Refund ${pluralize('unfulfilled item', unfulfilled, true)}` },
      { value: 'partial', text: 't(Refund selected items)' },
      { value: 'none', text: 't(Do not issue refund)' }
    ]
    return options
  }

  _getStrategies() {
    const { order } = this.props
    const { method } = order.payment
    const strategies = []
    if(method === 'ach') {
      strategies.push('ach')
    } else if(method === 'paypal') {
      strategies.push('paypal')
    } else if(_.includes(['card','googlepay','applepay'], method)) {
      strategies.push('card')
    }
    strategies.push('credit')
    return strategies
  }

  _getItemsTotal() {
    const { config } = this.state
    const { order } = this.props
    return Object.values(order.items).filter(item => {
      return _.includes(config.item_ids, item.id)
    }).reduce((total, item) => {
      return total + Number(item.price)
    }, 0.00)
  }

  _getRefund() {
    const { config } = this.state
    if(config.strategy === 'none') return []
    const strategies = this._getStrategies()
    return [
      { label: 't(Refund Type)', name: 'type', type: 'radiogroup', deselectable: false, options: strategies, format: RefundStrategyToken, required: true, defaultValue: strategies[0] },
      { label: 't(Amount)', name: 'amount', type: AmountField, action: 'Refund', required: true, balance: this._getTotal() }
    ]
  }

  _getStatusItems(statuses) {
    const { order } = this.props
    return Object.values(order.items.filter(item => {
      return _.includes(statuses, item.status)
    }).reduce((collected, item) => ({
      ...collected,
      [item.variant.id]: {
        price: item.price,
        quantity: collected[item.variant.id] ? collected[item.variant.id].quantity + 1 : 1
      }
    }), {}))
  }

  _getStatusItemsCount(statuses) {
    return this._getStatusItems(statuses).reduce((total, item) => {
      return total + item.quantity
    }, 0)
  }

  _getStatusItemsTotal(statuses) {
    return this._getStatusItems(statuses).reduce((total, item) => {
      return total + (item.quantity * item.price)
    }, 0)
  }

  _getTotal() {
    const { config } = this.state
    const { strategy } = config
    if(strategy === 'full') return this._getStatusItemsTotal(['fulfilled','unfulfilled'])
    if(strategy === 'unfulfilled') return this._getStatusItemsTotal(['unfulfilled'])
    if(strategy === 'fulfilled') return this._getStatusItemsTotal(['fulfilled'])
    if(strategy === 'partial') return this._getItemsTotal()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(store) {
    this.context.modal.close()
  }

}

export default Cancel
