import getActions from '@admin/utils/get_actions'

export const {
  onAddItems,
  onRemoveItems,
  onSetItems,
  onClearTestItem,
  onSetTestItem
} = getActions([
  { name: 'items', type: 'array' },
  { name: 'test_item', type: 'var' }
])