import PropTypes from 'prop-types'
import React from 'react'

const DomainToken = ({ hostname, is_primary }) => (
  <div className="token">
    { hostname } { is_primary &&
      <>(<span className="alert">primary domain</span>)</>
    }
  </div>
)

DomainToken.propTypes = {
  hostname: PropTypes.string,
  is_primary: PropTypes.bool
}

export default DomainToken
