import SmsEmrollmentShow from './sms/show/enrollments/show'
import EmailLink from './email/show/link'
import SocialList from './social/index'
import SocialShow from './social/show'
import EmailList from './email/list'
import EmailShow from './email/show'
import VoiceList from './voice/list'
import VoiceShow from './voice/show'
import SmsList from './sms/list'
import SmsShow from './sms/show'

const routes = [
  { path: '/email/:id/:view', component: EmailShow },
  { path: '/email/:email_campaign_id/links/:id', component: EmailLink },
  { path: '/email', component: EmailList },
  { path: '/social/:id/:view', component: SocialShow },
  { path: '/social/:view', component: SocialList },
  { path: '/sms/:id/:view', component: SmsShow },
  { path: '/sms/:sms_campaign_id/enrollments/:id/:view', component: SmsEmrollmentShow },
  { path: '/sms/:view', component: SmsList },
  { path: '/voice', component: VoiceList },
  { path: '/voice/:id/:view', component: VoiceShow }
]

export default routes
