export const onAdd = (product) => ({
  type: 'ADD',
  product
})

export const onRemove = (index) => ({
  type: 'REMOVE',
  index
})

export const onReorder = (from, to) => ({
  type: 'REORDER',
  from,
  to
})

export const onSet = (products) => ({
  type: 'SET',
  products
})

export const onUpdate = (index, product) => ({
  type: 'UPDATE',
  index,
  product
})
