import Input from '@admin/components/html/input'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'
import Body from './body'

class TableField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    columns: PropTypes.array,
    defaultValue: PropTypes.array,
    headers: PropTypes.bool,
    placeholder: PropTypes.string,
    reorderable: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    rows: [],
    values: {}
  }

  static defaultProps = {
    columns: [],
    headers: true,
    reorderable: true,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { columns, headers, reorderable } = this.props
    return (
      <div className="maha-tablefield">
        { headers &&
          <div className="maha-tablefield-header">
            <div className="maha-tablefield-row">
              { reorderable &&
                <div className="maha-tablefield-handle">&nbsp;</div>
              }
              { columns.map((column, index) => (
                <div className="maha-tablefield-column" key={`column_${index}`}>
                  <T text={ column.label } />
                </div>
              ))}
              <div className="maha-tablefield-actions">&nbsp;</div>
            </div>
          </div>
        }
        <Body { ...this._getBody() } />
        <div className="maha-tablefield-footer">
          <div className="maha-tablefield-row">
            { reorderable &&
              <div className="maha-tablefield-handle">&nbsp;</div>
            }
            { columns.map((column, index) => (
              <div className="maha-tablefield-column" key={`column_${index}`}>
                <Input { ...this._getInput(column, index) } />
              </div>
            ))}
            <div className="maha-tablefield-actions" onClick={ this._handleAdd }>
              <Icon icon="plus" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { rows, value } = this.state
    if(!_.isEqual(rows, prevState.rows)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevState.value)) {
      this._handleSet(value)
    }
  }

  _getBody() {
    const { columns, reorderable, tabIndex } = this.props
    const { rows } = this.state
    return {
      columns,
      reorderable,
      rows,
      tabIndex,
      onMove: this._handleMove,
      onRemove: this._handleRemove,
      onUpdate: this._handleUpdate
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput(column, index) {
    const { tabIndex } = this.props
    const { rows, values } = this.state
    return {
      type: 'text',
      tabIndex,
      placeholder: this._getPlaceholder(column),
      onKeyDown: this._handleKeyDown,
      onChange: this._handleSetValue.bind(this, column.key),
      value: values[column.key] || ''
    }
  }

  _getIsValid() {
    const { columns } = this.props
    const { values } = this.state
    return columns.reduce((valid, column) => {
      return !valid ? false : !_.isNil(values[column.key]) && !_.isEmpty(values[column.key])
    }, true)
  }

  _getPlaceholder(column) {
    const { placeholder } = this.props
    const { label } = column
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    return t(label ? `t(Enter) ${label}` : '')
  }

  _handleAdd() {
    const { columns } = this.props
    const { values } = this.state
    if (!this._getIsValid()) return
    this.setState({
      rows: [
        ...this.state.rows,
        {
          ...columns.reduce((row, column) => ({
            ...row,
            [column.key]: values[column.key]
          }), {}),
          code: _.random(100000000, 999999999).toString(36)
        }
      ],
      values: {}
    })
  }

  _handleChange() {
    const { rows } = this.state
    const value = rows.length ? rows.map(row => {
      return _.omit(row, ['code'])
    }) : null
    this.props.onChange(value)
  }

  _handleKeyDown(e) {
    if(!(e.keyCode === 13 && e.shiftKey === false)) return
    e.preventDefault()
    this._handleAdd()
  }

  _handleMove(from, to) {
    const { rows } = this.state
    this.setState({
      rows: (from < to) ? [
        ...rows.slice(0, from),
        ...rows.slice(from + 1, to + 1),
        rows[from],
        ...rows.slice(to + 1)
      ] : [
        ...rows.slice(0, to),
        rows[from],
        ...rows.slice(to, from),
        ...rows.slice(from + 1)
      ]
    })
  }

  _handleRemove(index) {
    const { rows } = this.state
    this.setState({
      rows: [
        ...rows.filter((row, i) => {
          return i !== index
        })
      ]
    })
  }

  _handleSet(value) {
    const rows = value || []
    this.setState({
      rows: rows.map(row => ({
        ...row,
        code: _.random(100000000, 999999999).toString(36)
      }))
    })
  }

  _handleSetValue(key, value) {
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      } 
    })
  }

  _handleUpdate(index, key, value) {
    const { rows } = this.state
    this.setState({
      rows: [
        ...rows.map((row, i) => {
          return i === index ? {
            ...row,
            [key]: value
          } : row
        })
      ]
    })
  }

}

export default TableField
