import DomainsShow from './websites/domains/show'
import WebsiteDesign from './websites/design'
import WebsiteList from './websites/list'
import WebsiteShow from './websites/show'

const routes = [
  { path: '/', component: WebsiteList },
  { path: '/:website_id/design/:versionable', component: WebsiteDesign },
  { path: '/:id/:view', component: WebsiteShow },
  { path: '/:website_id/domains/:id/:view', component: DomainsShow }
]

export default routes
