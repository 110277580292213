import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Profile from './profile'
import Email from './email'
import React from 'react'

class New extends React.Component {

  static childContextTypes = {
    form: PropTypes.object
  }

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    flash: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    contact: PropTypes.object,
    deal: PropTypes.object,
    filter: PropTypes.object,
    forward: PropTypes.object,
    order: PropTypes.object,
    profiles: PropTypes.array,
    program: PropTypes.object,
    programs: PropTypes.array,
    reply: PropTypes.object,
    registration: PropTypes.object,
    response: PropTypes.object,
    subscription: PropTypes.object
  }

  stackRef = React.createRef()

  state = {
    profile: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleEmail = this._handleEmail.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSend = this._handleSend.bind(this)

  render() {
    const { profiles } = this.props
    return (
      <>
        { profiles.length > 0 ?
          <Stack { ...this._getStack() } /> :
          <Profile { ...this._getProfile() } />
        }
      </>
    )
  }

  componentDidMount() {
    const { profiles } = this.props
    if(profiles.length > 0) this._handleEmail()
  }

  componentDidUpdate(prevProps) {
    const { profiles } = this.props
    if(profiles.length > prevProps.profiles.length && prevProps.profiles.length === 0) {
      this._handleEmail()
    }
  }

  getChildContext() {
    return {
      form: {
        push: this._handlePush,
        pop: this._handlePop
      }
    }
  }

  _getEmail() {
    const { contact, filter, forward, profiles, programs, reply } = this.props
    return {
      contact,
      reply,
      forward,
      filter,
      profiles,
      programs,
      onCancel: this._handleCancel,
      onSend: this._handleSend
    }
  }

  _getProfile() {
    return {
      onCancel: this._handleCancel
    }
  }

  _getProgram() {
    return {
      onCancel: this._handleCancel,
      onChoose: this._handleProgram
    }
  }

  _getStack() {
    return {
      display_name: 'new_email',
      ref: this.stackRef
    }
  }

  _handleBegin() {
    const { reply, forward } = this.props
    if(reply || forward) return this._handleEmail()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleEmail() {
    this._handlePush(Email, this._getEmail())
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSend(email) {
    const { deal, order, registration, response, subscription } = this.props
    const { bcc, body, cc, import_program_id, priority, profile_id, reply_to_thread, subject, to } = email
    this.context.network.request({
      endpoint: '/api/admin/email/send',
      method: 'POST',
      body: {
        profile_id,
        ...deal ? { deal_id: deal.id } : {},
        ...order ? { order_id: order.id } : {},
        ...registration ? { registration_id: registration.id } : {},
        ...response ? { response_id: response.id } : {},
        ...subscription ? { subscription_id: subscription.id } : {},
        reply_to_thread,
        import_program_id,
        to,
        cc,
        bcc,
        subject,
        ...body,
        priority
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to send email)'),
      onSuccess: ({ data }) => this._handleSuccess(data)
    })
  }

  _handleSuccess(email) {
    this.context.modal.close()
    this.context.router.push(`/admin/email/threads/${email.thread.code}`)
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/profiles',
    refresh: `/accounts/${context.admin.account.id}/admin/profiles/emails`,
    filter: {
      type: {
        $eq: 'emails'
      }
    }
  },
  programs: {
    endpoint: '/api/admin/programs',
    filter: {
      access_type: {
        $in: ['manage','edit']
      }
    }
  }
})

export default Container(mapResources)(New)
