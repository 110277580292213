import Content from '@apps/forms/admin/tokens/content'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ dataset, fields, record, type }) => {

  const dataset_button = {
    label: dataset.title.value,
    className: 'link',
    route: `/admin/datasets/datasets/${dataset.id}`
  }

  const type_button = {
    label: type.title.value,
    className: 'link',
    route: `/admin/datasets/datasets/${dataset.id}/types/${type.id}`
  }

  const details = {
    audits: `datasets_records/${record.id}`,
    comments: `datasets_records/${record.id}`,
    sections: [
      {
        title: 't(Overview)',
        items: [
          { label: 't(Dataset)', content: <Button { ...dataset_button } /> },
          { label: 't(Type)', content: <Button { ...type_button } /> }
        ]
      }, {
        title: 't(Properties)',
        items: fields.map(field => ({
          label: field.name.value, content: record.values[field.name.token]//<Content data={ record.values } field={ field } />
        }))
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  dataset: PropTypes.object,
  fields: PropTypes.array,
  record: PropTypes.object,
  type: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Record)',
  panel: <Overview dataset={ props.dataset } fields={ props.fields } type={ props.type } record={ props.record } />,
  tasks: {
    items: [
      { label: 'Edit Record', modal: <div /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
