import CardToken from '@apps/finance/admin/tokens/card'
import Details from '@admin/components/details'
import PaymentMethod from '../payment_method'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'

const from_now = (date) => {
  const diff = moment(date).diff(moment().subtract(1,'day'), 'days')
  if(diff === -1) return 'yesterday'
  if(diff === 0) return 'today'
  if(diff === 1) return 'tomorrow'
  if(diff > 0) return `in ${pluralize('day', diff, true) }`
  if(diff < 0) return `${pluralize('day', diff, true) } ago`
}

const Billing = ({ subscription }) => {

  const details = {
    sections: [
      {
        title: 't(Subscription Details)',
        items: subscription ? [
          { label: 't(Plan)', content: subscription.current_period.plan.title },
          { label: 't(Payment Method)', padded: false, show: subscription.payment_strategy === 'card', content: <CardToken payment_method={ subscription.payment_method } /> },
          ...subscription.status === 'active' && subscription.canceled_on === null ? [
            { label: 't(Current Billing Cycle)', content: (
              <><Date date={ subscription.current_cycle.start_date } /> - <Date date={ subscription.current_cycle.end_date } /></>
            ) },
            { label: 't(Next Renewal)', content: (
              <><Date date={ subscription.next_renewal_date } /> ({ from_now(subscription.next_renewal_date) })</>
            ) }
          ] : [],
          ...subscription.status === 'pending' ? [
            { label: 't(First Renewal)', content: (
              <><Date date={ subscription.current_period.started_on } /> ({ from_now(subscription.current_period.started_on) })</>
            ) }
          ] : [],
          ...subscription.status === 'trial' && subscription.canceled_on === null ? [
            { label: 't(Trial Ends)', content: (
              <><Date date={ subscription.current_period.ended_on } /> ({ from_now(subscription.current_period.ended_on) })</>
            ) }
          ] : [],
          { label: 't(Price)', content: subscription.current_period.price }
        ] : [
          { label: 't(Plan)', content: 't(You do not currently have an active subscription)' }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Billing.propTypes = {
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Billing)',
  panel: <Billing subscription={ props.subscription } />,
  tasks: {
    items: [
      {
        label: 't(Change Payment Methods)',
        modal: <PaymentMethod />
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  subscription: '/api/admin/team/subscription'
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
