import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

class MJSONPreview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.object,
    table: PropTypes.string
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    return (
      <div className="maha-mjson-preview" onClick={ this._handleClick }>
        <div className="maha-mjson-preview-inner">
          <MJSONScreenshot { ...this._getScreenshot() } />
        </div>
      </div>
    )
  } 

  _getScreenshot() {
    const { engine, entity, screenshoted_at } = this._getEntity()
    return {
      engine: engine || 'mjson',
      entity,
      width: 500,
      height: 360,
      screenshoted_at
    }
  }

  _getEntity () {
    const { entity, table } = this.props
    return {
      ...entity,
      entity: `${table}/${entity.code}`
    }
  }

  _handleClick() {
    const { team } = this.context.admin
    const entity = this._getEntity()
    const { engine } = entity
    const renderer = engine || 'mjson'
    const link = `${team.public_host}/${renderer}/dynamic/${entity.entity}`
    this.context.host.window.open(link)
  }

}

export default MJSONPreview
