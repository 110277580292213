import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  default: {
    title: 't(Use default Aesthetic Profile colors)',
    text: 't(Your color scheme will be based on the default Aesthetic Profile you selected)'
  },
  choose: {
    title: 't(Provide my own color scheme or brand book / style guide)',
    text: 't(Provide up to three colors and/or submit a brand book/style guide to use as a color scheme for your content)'
  },
  design: {
    title: 't(Create a color scheme for me based on my input or logo)',
    text: 't(Let the Truevail design team choose an attractive color scheme for you based on your logo or from scratch)'
  }
}

const ColorToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
    
  </div>
)

ColorToken.propTypes = {
  value: PropTypes.string
}

export default ColorToken
