import AccessField from '@apps/team/admin/components/accessfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class RolesEdit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    role: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { role } = this.props
    return {
      title: 't(Edit Role)',
      method: 'PATCH',
      endpoint: `/api/admin/team/roles/${role.id}`,
      action: `/api/admin/team/roles/${role.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...role.type === 'custom' ? [
              { label: 't(Access)', name: 'rights', type: AccessField }
            ] : [
              { label: 't(Access)', type: 'text', value: 't(Team Administrator have access to every app and feature of Maha)' }
            ]
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default RolesEdit
