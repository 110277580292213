import UnsplashToken from '@admin/tokens/unsplash'
import PropTypes from 'prop-types'
import React from 'react'

class Layout extends React.Component {

  static propTypes = {
    cid: PropTypes.string,
    excludeIds: PropTypes.array,
    files: PropTypes.array,
    format: PropTypes.any,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    selected: PropTypes.array,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onToggle: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="unsplash-photos">
        { records.map((photo, index) => (
          <div className={ this._getClass(photo) } key={`photo_${photo.id}`} onClick={ this._handleChoose.bind(this, photo) }>
            <UnsplashToken photo={ photo } />
          </div>
        )) }
      </div>
    )
  }

  _getClass(photo) {
    const { files } = this.props
    const classes = ['unsplash-photo']
    if(_.find(files, { id: photo.id })) classes.push('selected')
    return classes.join(' ')
  }

  _handleChoose(photo) {
    const { multiple } = this.props
    this.props.onToggle(multiple, photo)
  }

}

export default Layout
