import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ recipients }) => recipients.map((recipient, index) => (
  <div key={`recipient_${index}`}>{recipient.full_name}</div>
))

Token.propTypes = {
  recipients: PropTypes.string
}

export default Token
