import Panel from '@admin/components/panel'
import moment from 'moment-timezone'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Hour)', key: 'processed_at', primary: true, format: (record) => (
        <div className="token">
          { moment(record.processed_at).tz(context.admin.account.timezone).format('YYYY-MM-DD [@] hh:mmA z') }
        </div>
      ) },
      { label: 't(Sent)', key: 'sent_count', collapsing: true, align: 'right' },
      { label: 't(Open Rate)', key: 'open_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Click Rate)', key: 'click_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Bounce Rate)', key: 'bounce_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Complain Rate)', key: 'complain_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Unsubscribe Rate)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: 'percent' }
    ],
    defaultQuery: { 
      ...props.params.domain_id ? {
        domain_id: props.params.domain_id
      } : props.params.domain_segment_id ? {
        domain_segment_id: props.params.domain_segment_id
      } : props.params.segment_id ? {
        segment_id: props.params.segment_id
      } : {},
      interval: 'hour',
      value: props.params.value
    },
    defaultSort: { key: 'processed_at', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'hour',
    onClick: (record) => {
      const parts = ['/admin/truevail/admin/sent']
      if(props.params.domain_id) parts.push(`domains/${props.params.domain_id}`)
      if(props.params.domain_segment_id) parts.push(`domain_segments/${props.params.domain_segment_id}`)
      if(props.params.segment_id) parts.push(`segments/${props.params.segment_id}`)
      parts.push(`hour/${moment(record.processed_at).format('YYYY-MM-DD[T]HH:mm')}`)
      context.router.push(parts.join('/'))
    }  
  }
})

export default Panel(null, mapPropsToPanel)
