import PaletteField from '@admin/components/mjson_designer/components/palettefield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    setup: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, setup } = this.props
    const { configuration } = setup
    return {
      title: 't(New Form)',
      method: 'POST',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/forms`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: 'Newsletter Signup', required: true },
            { label: 't(Palette)', name: 'palette', type: PaletteField, required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { content_types: ['images'] }, multiple: false, defaultValue: configuration.logo_id, required: true },
            { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/templates`, valueKey: 'id', textKey: 'title', required: true, selectFirst: true },
            { label: 't(List)', name: 'list_id', type: 'dropdown', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/lists`, valueKey: 'id', textKey: 'title', required: true, selectFirst: true },
            { label: 't(Sender)', name: 'sender_id', type: 'dropdown', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/senders`, valueKey: 'id', textKey: 'rfc822', required: true, selectFirst: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  setup: `/api/admin/truevail/admin/advisors/${props.advisor.id}/configuration`
})

export default Container(mapResources)(New)