import New from '@admin/components/fields/new'
import Fields from '@admin/components/fields'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Properties)',
  access: { 
    programs: { $canView: props.program.id },
    rights: { $oneOf: ['team:view_properties','team:manage_properties']} 
  },
  component: <Fields label="property" parent_type="crm_programs" parent_id={ props.program.id } access={ { programs: { $canView: props.program.id }, rights: { $oneOf: ['team:manage_properties'] } } } />,
  task: {
    icon: 'plus',
    tooltip: 't(Create Property)',
    access: { 
      programs: { $canEdit: props.program.id }, 
      rights: { $oneOf: ['team:manage_properties'] } 
    },
    modal: <New action={`/api/admin/crm_programs/${props.program.id}/fields`} />
  }
})

export default Panel(null, mapPropsToPanel)
