import Page from '@admin/components/page'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import Performance from './performance'
import Overview from './overview'
import moment from 'moment'
import React from 'react'
import qs from 'qs'

const getReconnect = (profile, context) => {
  return {
    label: 'Reconnect Profile',
    className: 'link',
    link: `${context.provider.admin_host}/admin/oauth/${profile.service.source}/authorize?${qs.stringify({
      program_id: profile.program.id,
      type: profile.service.type,
      timestamp: moment().format('x'),
      token: context.admin.token
    })}`
  
  }
}

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.profile.username || resources.profile.name,
  access: { 
    programs: { $canView: resources.profile.program.id },
    rights: { $oneOf: ['team:view_properties','team:manage_properties'] } 
  },
  manager: {
    path: `/admin/team/profiles/${resources.profile.id}`,
    ...resources.profile.status === 'disconnected' ? {
      alert: {
        style: 'error',
        message: (
          <>
            <T text={ 't(We received the following error)' } />:<br />
            <span className="alert">{ resources.profile.error }</span><br />
            <T text={ 't(This profile needs to be reconnected)' } />.&nbsp;
            <Button { ...getReconnect(resources.profile, context) } />
          </>
        )
      }
    } : {},
    items: [
      {
        label: 't(Overview)',
        panel: <Overview profile={ resources.profile } />
      },
      {
        label: 't(Performance)',
        path: '/performance', 
        panel: <Performance profile={ resources.profile } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  profile: `/api/admin/team/profiles/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
