import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `websites_websites/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const WebsiteToken = ({ website }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(website) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        <strong>{ website.title }</strong><br />
        <span className="link">{ website.primary_host }</span><br />
        { moment(website.published_at).format('MMMM DD YYYY [@] h:mm A') }
      </div>
    </div>
  </div>
)

WebsiteToken.propTypes = {
  website: PropTypes.object
}

export default WebsiteToken
