import flatten from 'flat'

const INITIAL_STATE = {
  busy: [],
  data: {},
  entity: null,
  errors: {},
  ready: [],
  status: 'pending',
  validated: []
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_BUSY':
    return {
      ...state,
      busy: [
        ...action.value ? _.union(state.busy, [action.key]) : _.without(state.busy, action.key)
      ]
    }

  case 'SET_DATA':
    return {
      ...state,
      status: 'ready',
      data: {
        ...state.data,
        ..._.omitBy(action.data, _.isUndefined)
      }
    }

  case 'SET_READY':
    return {
      ...state,
      ready: [
        ..._.union(state.ready, [action.key])
      ]
    }

  case 'SET_STATUS':
    return {
      ...state,
      status: action.status
    }

  case 'SET_VALID':
    return {
      ...state,
      validated: [
        ..._.union(state.validated, [action.key])
      ],
      data: {
        ...state.data,
        [action.key]: action.value
      },
      errors: {
        ...state.errors,
        ...action.errors ? {
          [action.key]: action.errors
        } : {}
      }
    }

  case 'REMOVE_FIELD':
    return {
      ...state,
      errors: {
        ...Object.keys(state.errors).reduce((data, name) => ({
          ...data,
          ...(name !== action.name) ? {
            [name]: state.data[name]
          } : {}
        }), {})
      },
      data: {
        ...Object.keys(state.data).reduce((data, name) => ({
          ...data,
          ...(name !== action.name) ? {
            [name]: state.data[name]
          } : {}
        }), {})
      },
      ready: [
        ...state.ready.filter(name => {
          return name !== action.name
        })
      ],
      validated: [
        ...state.validated.filter(name => {
          return name !== action.name
        })
      ]
    }

  case 'UPDATE_DATA':
    return {
      ...state,
      data: {
        ...state.data,
        [action.key]: action.value
      },
      errors: _.omit(state.errors, action.key),
      status: 'ready',
      validated: _.without(state.validated, action.key)
    }

  case 'VALIDATE':
    return {
      ...state,
      status: 'validating',
      validated: []
    }

  default:
    return state
  }

}
