import SocialBatchToken from '@apps/truevail/admin/tokens/social_batch'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Batches)',
  collection: {
    endpoint: '/api/admin/truevail/admin/social_batches',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (social_batch) => (
        <SocialBatchToken social_batch={ social_batch } />
      ) },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: StatusToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Batches)',
      text: 't(You have not yet created any social batches)',
      buttons: [
        { label: 't(Create Social Batch)', modal: New }
      ]
    },
    entity: 'social batch',
    recordTasks: (record) => [
      {
        label: 't(Clone Social Batch)',
        modal: <Clone social_batch={ record } />
      },
      {
        label: 't(Delete Social Batch)',
        show: record.status !== 'published',
        confirm: 't(Are you sure you want to delete this social batch?)',
        request: {
          endpoint: `/api/admin/truevail/admin/social_batches/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted social batch)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete social batch)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/social_batches/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
