import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Classification extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: this._getClassification()
    }
  }

  _getClassification() {
    const { admin } = this.context
    const programs = admin.programs.filter(program => {
      return program.access_type !== 'view'
    }).sort((a,b) => a.title > b.title ? 1 : -1)
    if(admin.team.has_programs) {
      return programs.map(program => ({
        label: <ProgramToken { ...program } />,
        padded: false,
        collapsing: true,
        collapsed: true,
        fields: this._getProgramFields(program)
      }))
    }
    return [{
      fields: this._getProgramFields(programs[0])
    }]
  }

  _getProgramFields(program) {
    const { email_addresses, mailing_addresses, phone_numbers } = this.props.formdata
    return [
      { label: 't(Consent)', name: `channels.${program.id}`, type: 'checkboxgroup', deselectable: false, options: [
        ...email_addresses.map(email_address => ({ value: `email_${email_address.delta}`, text: `t(EMAIL) (${email_address.address})` })),
        ...phone_numbers.map(phone_number => ({ value: `sms_${phone_number.delta}`, text: `t(SMS) (${phone_number.number})` })),
        ...phone_numbers.map(phone_number => ({ value: `voice_${phone_number.delta}`, text: `t(VOICE) (${phone_number.number})` })),
        ...mailing_addresses.map(mailing_address => ({ value: `mail_${mailing_address.delta}`, text: `t(POSTAL) (${mailing_address.address.description})` }))
      ], instructions: 't(Indicate the channels through which this contact has given you explicit consent to contact them)' },
      { label: 't(Lists)', name: `list_ids.${program.id}`, type: 'lookup', multiple: true, endpoint: '/api/admin/crm/lists', filter: { program_id: { $eq: program.id } }, valueKey: 'id', textKey: 'title' },
      { label: 't(Tags)', name: `tags.${program.id}`, type: 'tagfield', endpoint: '/api/admin/tags', filter: { program_id: { $eq: program.id } } }
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(values) {
    const { formdata } = this.props
    this.props.onSave({
      ...formdata,
      ...values
    })
  }

}

export default Classification
