import Page from '@admin/components/page'
import Outbound from './outbound'
import Inbound from './inbound'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(SMS Campaigns)',
  manager: {
    path: '/admin/campaigns/sms',
    items: [
      {
        label: 't(Outbound)',
        path: '/outbound',
        panel: <Outbound />
      },
      {
        label: 't(Inbound)',
        path: '/inbound',
        panel: <Inbound />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
