import ModalPanel from '@admin/components/modal_panel'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Members from './members'
import React from 'react'

class Lists extends React.PureComponent {

  static propTypes = {
    _import: PropTypes.object,
    profile: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Infinite { ...this._getInfinite() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Choose List)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ]
    }
  }

  _getInfinite() {
    const { profile } = this.props
    const empty = {
      icon: 'user',
      title: 't(No Lists)',
      text: 't(There are no lists available)'
    }
    return {
      endpoint: `/api/admin/profiles/${profile.id}/lists`,
      layout: Results,
      empty: <Message { ...empty } />,
      onUpdateSelected: this._handleUpdateSelected,
      props: {
        onChoose: this._handleChoose
      }
    }
  }

  _handleCancel() {
    this.props.onBack()
  }

  _handleChoose(list_id) {
    const { _import, profile, onDone, onBack } = this.props
    this.props.onPush(Members, {
      _import,
      profile,
      list_id,
      onBack,
      onDone
    })
  }

}

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { records } = this.props
    return (
      <div className="import-lists">
        { records.map((list, index) => (
          <div className="import-list" key={`list_${index}`} onClick={ this._handleChoose.bind(this, list)}>
            <div className="import-list-label">
              { list.name } (
              { list.contact_count } members
              )
            </div>
            <div className="import-list-proceed">
              <Icon icon="chevron-right" />
            </div>
          </div>
        )) }
      </div>
    )
  }

  _handleChoose(list) {
    this.props.onChoose(list.id)
  }

}

export default Lists
