import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Buttons extends React.PureComponent {

  static propTypes = {
    buttons: PropTypes.array
  }

  render() {
    const buttons = this._getButtons()
    return (
      <div className="maha-toolbar-buttons">
        { buttons.map((button, index) => (
          <Button { ...this._getButton(button) } key={`button_${index}`} />
        )) }
      </div>
    )
  }

  _getButton(button) {
    return {
      ...button,
      className: this._getClass(button),
      ...button.tooltip ? {
        tooltip: this._getToolTip(button.tooltip)
      } : {}
    }
  }

  _getButtons() {
    return this.props.buttons.filter(button => {
      return button.show !== false
    })
  }

  _getClass(button) {
    const classes = ['maha-toolbar-button']
    if(button.handlers) classes.push('clickable')
    if(button.className) classes.push(button.className)
    if(button.disabled) classes.push('disabled')
    if(button.null) classes.push('null')
    if(button.filled) classes.push('filled')
    if(button.rotated) classes.push('rotated')
    if(button.active) classes.push('active')
    if(button.icon && button.label  ) classes.push('iconed')
    return classes.join(' ')
  }

  _getToolTip(tooltip) {
    return {
      title: _.isString(tooltip) ? tooltip : tooltip.title,
      position: _.isString(tooltip) ? 'bottom center' : tooltip.position
    }
  }

}

export default Buttons
