import { useProviderContext } from '@admin/components/provider'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import FlowchartDesigner from './flowchart_designer'
import Versions from '@admin/components/versions'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const FlowchartDesignerContainer = (props) => {

  const { admin } = useAdminContext()
  const { provider } = useProviderContext()
  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'flowchart_designer',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      admin,
      provider,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <FlowchartDesigner { ...mergedProps } />

}

const VersionedFlowchartDesigner = (props) => {

  return (
    <Versions endpoint={ props.endpoint }>
      <FlowchartDesignerContainer { ...props } />
    </Versions>
  )

}

VersionedFlowchartDesigner.propTypes = {
  endpoint: PropTypes.string
}

export default VersionedFlowchartDesigner