import { useLocalStorageContext } from '@admin/components/local_storage'
import { useAnalyticsContext } from '@admin/components/analytics'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useLoggerContext } from '@admin/components/logger'
import { useLocaleContext } from '@admin/components/locale'
import { useFlashContext } from '@admin/components/flash'
import { useMahaContext } from '@admin/components/maha'
import Router from '@admin/components/router'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Admin from './admin'
import React from 'react'

export { AdminContext, useAdminContext } from './admin'

const AdminContainer = (props) => {

  const { analytics } = useAnalyticsContext()
  const { flash } = useFlashContext()
  const { local_storage } = useLocalStorageContext()
  const { locale } = useLocaleContext()
  const { logger } = useLoggerContext()
  const { maha } = useMahaContext()
  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'admin',
    actions,
    props,
    reducer
  })

  const routerProps = {
    context: {
      analytics
    },
    apps: state.apps,
    team: state.team,
    teams: state.teams,
    onChooseTeam: handlers.onChooseTeam
  }

  const mergedProps = {
    context: {
      analytics,
      flash,
      local_storage,
      locale,
      logger,
      maha,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return (
    <Router { ...routerProps }>
      <Admin { ...mergedProps } />
    </Router>
  )

}

AdminContainer.propTypes = {}

export default AdminContainer
