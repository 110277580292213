import MJSONPreview from '@admin/components/mjson_preview'
import ShortLink from '@admin/components/shortlink'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ landing_page, form }) => {

  const form_button = {
    label: form.title,
    className: 'link',
    route: `/admin/forms/forms/${form.id}`
  }

  const shortlink = {
    shortlink: landing_page.shortlink,
    text: 't(Share this Landing Page)',
    title: landing_page.title
  }

  const details = {
    audits: `forms_landing_pages/${landing_page.id}`,
    comments: `forms_landing_pages/${landing_page.id}`,
    before: <ShortLink { ...shortlink } />,
    header: <MJSONPreview entity={ landing_page } table="forms_landing_pages" />,
    sections: [
      {
        title: 't(Landing Page Details)',
        items: [
          { label: 't(Title)', content: landing_page.title },
          { label: 't(Form)', content: <Button { ...form_button } />  }
        ]
      }
    ]
  }

  if(landing_page.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This landing page was deleted)' }
  }
  return <Details { ...details } />

}

Overview.propTypes = {
  landing_page: PropTypes.object,
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } landing_page={ props.landing_page } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Landing Page)',
        access: { rights: { $oneOf: ['forms:manage_forms'] } },
        modal: <Edit form={ props.form } landing_page={ props.landing_page } /> 
      },
      {
        label: 't(Delete Landing Page)',
        access: { rights: { $oneOf: ['forms:manage_forms'] } },
        confirm: 't(Are you sure you want to delete this landing page?)',
        request: {
          endpoint: `/api/admin/forms/forms/${props.form.id}/landing_pages/${props.landing_page.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this landing page)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this landing page)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
