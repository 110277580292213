import Workflows from './automation/workflows'
import EmailCampaigns from './email_campaigns'
import VoiceCampaigns from './voice_campaigns'
import Page from '@admin/components/page'
import ContactFields from './contact_fields'
import Registrations from './registrations'
import ScanTickets from '../sessions/scan'
import TicketFields from './ticket_fields'
import SMSCampaigns from './sms_campaigns'
import Demographics from './demographics'
import Emails from './automation/emails'
import TicketTypes from './ticket_types'
import Performance from './performance'
import Attendance from './attendance'
import Analytics from './analytics'
import Sessions from './sessions'
import Overview from './overview'
import Tickets from './tickets'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.event.title,
  access: { 
    programs: { $canView: resources.event.program.id },
    rights: { $oneOf: ['events:view_events','events:manage_events'] } 
  },
  manager: {
    path: `/admin/events/events/${resources.event.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview event={ resources.event } />
      },
      {
        label: 't(Sessions)',
        path: '/sessions',
        panel: <Sessions event={ resources.event } />
      },
      {
        label: 't(Ticket Types)',
        path: '/ticket_types',
        panel: <TicketTypes event={ resources.event } />
      },
      {
        label: 't(Contact Fields)',
        path: '/contact_fields',
        panel: <ContactFields event={ resources.event } />
      },
      {
        label: 't(Ticket Fields)',
        path: '/fields',
        panel: <TicketFields event={ resources.event } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows event={ resources.event } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails event={ resources.event } />
          }
        ]
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns event={ resources.event } />
          },
          {
            label: 't(SMS)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } }
              ] 
            },
            path: '/sms_campaigns',
            panel: <SMSCampaigns event={ resources.event } />
          },
          {
            label: 't(Voice)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } }
              ] 
            },
            path: '/voice_campaigns',
            panel: <VoiceCampaigns event={ resources.event } />
          }
        ]
      },
      {
        label: 't(Registrations)',
        path: '/registrations',
        panel: <Registrations event={ resources.event } />
      },
      {
        label: 't(Tickets)',
        path: '/tickets',
        panel: <Tickets event={ resources.event } />
      },
      {
        label: 't(Attendance)',
        path: '/attendance',
        ...resources.event.sessions.length > 1 ? {
          children: resources.event.sessions.map((session, index) => ({
            label: `Session ${index+1}`,
            panel: <Attendance event={ resources.event } session={ session } />,
            path: `/sessions/${session.id}`
          }))
        } : {
          panel: <Attendance event={ resources.event } session={ resources.event.sessions[0] } />,
          path: `/sessions/${resources.event.sessions[0].id}`
        }
      },
      {
        label: 't(Demographics)',
        path: '/demographics',
        panel: <Demographics event={ resources.event } />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        panel: <Performance event={ resources.event } />
      },
      {
        label: 't(Analytics)',
        path: '/analytics',
        show: context.admin.hasRights({ allOf: ['analytics:access_app'] }),
        panel: <Analytics event={ resources.event } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  event: `/api/admin/events/events/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
