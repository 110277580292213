import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class DestinationField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    destination: null
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { destination } = this.state
    if(destination === null) return null
    return (
      <div className="destinationfield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { destination } = this.state
    if(_.isEqual(destination, prevState.destination)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { website } = this.props
    const { destination } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { type: 'segment', fields: [
              { name: 'strategy', type: 'dropdown', options: [
                { value: 'page', text: 't(Page)' },
                { value: 'record', text: 't(Record)' }
              ], defaultValue: 'page' },
              ...destination.strategy === 'page' ? [
                { name: 'page_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/pages`, textKey: 'title', valueKey: 'id', prompt: 'Choose Page' }
              ] : [
                { name: 'type', type: 'dropdown', endpoint: `/api/admin/websites/websites/${website.id}/types`, textKey: 'type.title.value', valueKey: null, placeholder: 't(Choose Type)' },
                ...destination.type ? [
                  { name: 'record_id', type: 'lookup', endpoint: `/api/admin/datasets/datasets/${destination.type.type.dataset.id}/types/${destination.type.type.id}/records`, textKey: 'title', valueKey: 'id', prompt: 'Choose Record' }
                ] : []
              ]
            ] }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : true
  }

  _handleChange() {
    const { destination } = this.state
    const { page_id, record_id, strategy } = destination
    if(strategy === 'page' && !page_id) return
    if(strategy === 'record' && !record_id) return
    const value = strategy === 'page' ? `pages/${page_id}` : `records/${record_id}`
    this.props.onChange(value)
  }

  _handleSet(destination) {
    this.setState({ destination })
  }

  _handleUpdate(destination) {
    this.setState({ destination })
  }

}

export default DestinationField
