import MultiForm from '@admin/components/multiform'
import Confirmation from './confirmation'
import PropTypes from 'prop-types'
import Details from './details'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program } = this.props
    return {
      title: 't(New Form)',
      action: '/api/admin/forms/forms',
      method: 'POST',
      props: {
        program
      },
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { program_id } = this.props
    return { program_id }
  }

  _getData(formdata) {
    return {
      confirmation: formdata.confirmation,
      contact_config: formdata.contact_config,
      permalink: formdata.permalink,
      program_id: formdata.program_id,
      title: formdata.title
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Confirmation)', component: Confirmation }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(form) {
    this.context.router.push(`/forms/forms/${form.id}`)
    this.context.modal.close()
  }

}

export default New
