import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import AttachmentField from './attachmentfield'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const AttachmentFieldContainer = React.forwardRef((props, ref) => {
  
  const { network } = useNetworkContext()

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    blur: () => componentRef.current._handleBlur(),
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus(),
    set: (value) => componentRef.current._handleSet(value)
  }))

  const [state, handlers] = useActionReducer({
    display_name: 'attachmentfield',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <AttachmentField ref={ componentRef } { ...mergedProps } />

})

export default AttachmentFieldContainer