import Emoji from '@admin/components/emojis/emoji'
import Tooltip from '@admin/components/tooltip'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const reactions = [
  { label: 'Like', type: 'thumbsup', unicode: '1f44d' },
  { label: 'Dislike', type: 'thumbsdown', unicode: '1f44e' },
  { label: 'Happy', type: 'smile', unicode: '1f604' },
  { label: 'Sad', type: 'cry', unicode: '1f625' },
  { label: 'Angry', type: 'rage', unicode: '1f621' },
  { label: 'Love', type: 'heart', unicode: '2764-fe0f' }
]

class Reaction extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    reactions: PropTypes.array,
    table: PropTypes.string,
    id: PropTypes.number,
    user_reactions: PropTypes.array,
    onReact: PropTypes.func,
    onUnreact: PropTypes.func
  }

  state = {
    show: false
  }

  _handleHideReactions = this._handleHideReactions.bind(this)
  _handleToggleReactions = this._handleToggleReactions.bind(this)

  render() {
    const { show } = this.state
    return (
      <div className="maha-reaction-add" title="Add a reaction">
        <div className="maha-reaction-add-button" onClick={ this._handleToggleReactions }>
          <Icon icon="smile-o" />
        </div>
        { show &&
          <div className="maha-reaction-add-items">
            { reactions.map((reaction, index) => (
              <div { ...this._getReaction(reaction.type) } key={`reaction_${reaction.type}`}>
                <Tooltip label={ reaction.label } />
                <Emoji unicode={ reaction.unicode } />
              </div>
            ))}
          </div>
        }
      </div>
    )
  }

  _getReaction(reaction) {
    return {
      className: 'maha-reaction-add-item maha-tooltip',
      onClick: this._handleReact.bind(this, reaction)
    }
  }

  _handleReact(type, e) {
    const { table, id, onReact, onUnreact } = this.props
    const { user } = this.context.admin
    // const action = _.includes(user_reactions, type) ? onUnreact : onReact
    // this.setState({ show: false })
    // action(table, id, {
    //   id: user.id,
    //   full_name: user.full_name,
    //   initials: user.initials,
    //   photo: user.photo,
    //   type
    // })
    this.context.network.request({
      endpoint: `/api/admin/${table}/${id}/react/${type}`,
      method: 'PATCH',
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleHideReactions() {
    this.setState({ show: false })
  }

  _handleToggleReactions() {
    this.setState({ show: !this.state.show })
  }

}

export default Reaction
