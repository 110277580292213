import RegistrationToken from '@apps/events/admin/tokens/registration'
import Page from '@admin/components/page'

const mapPropsToPage = (props, context) => ({
  title: 't(Revenue)',
  collection: {
    endpoint: '/api/admin/dashboards/stores/revenue',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: RegistrationToken },
      { label: 't(Store)', key: 'store.title', sort: 'event', primary: true },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' },
      { label: 't(Ordered)', key: 'created_at', format: 'datetime', collapsing: true }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'user-circle-o',
      title: 't(No Orders)',
      text: 't(No one has ordered from a store)'
    },
    entity: 'order',
    onClick: (record) => context.router.push(`/stores/stores/${record.store.id}/orders/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)