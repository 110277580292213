import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import FiltersField from '@admin/components/mjson_designer/components/filtersfield'
import React from 'react'

export const Filters = (props) => <ResponsiveField { ...props } component={ FiltersField } />

export const FiltersSection = (config, device, theme, website, type) => ({
  label: 't(Filters)',
  instructions: 't(Specify effects that alter the appearance or behavior of this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.filters', device, type: Filters, value: config.styles.filters }
  ]
})
