import * as filters from '@core/utils/filters'
import Loader from '@admin/components/loader'
import PropTypes from 'prop-types'
import React from 'react'

class Canvas extends React.Component {

  static propTypes = {
    asset: PropTypes.object,
    adjustments: PropTypes.object,
    scaled: PropTypes.object
  }

  _handleInit = this._handleInit.bind(this)
  _handleRender = this._handleRender.bind(this)

  canvasRef = React.createRef()
  ctx = null
  image = null

  state = {
    ready: false
  }

  render() {
    const { ready } = this.state
    return (
      <>
        { !ready && <Loader /> }
        <canvas ref={ this.canvasRef } />
      </>
    )
  }

  componentDidMount() {
    const { asset, scaled } = this.props
    this.image = new Image()
    this.image.onload = this._handleInit
    this.image.src = `/imagecache/w=${Math.floor(scaled.w)}&h=${Math.floor(scaled.h)}&dpi=1&q=100${asset.path}`
  }

  componentDidUpdate(prevProps) {
    const { adjustments, scaled } = this.props
    if(!_.isEqual(adjustments, prevProps.adjustments)) {
      this._handleRender()
    }
    if(!_.isEqual(scaled, prevProps.scaled)) {
      this._handleInit()
    }
  }

  _handleInit() {
    const { scaled } = this.props
    this.canvasRef.current.width = scaled.w
    this.canvasRef.current.height = scaled.h
    this.ctx = this.canvasRef.current.getContext('2d')
    this.setState({
      ready: true
    }, this._handleRender)
  }

  _handleRender() {
    if(!this.state.ready) return
    const { adjustments, scaled } = this.props
    const { bri, con, vib, sat, hue, sep } = adjustments
    this.ctx.drawImage(this.image, 0, 0, scaled.w, scaled.h)
    const imagedata = this.ctx.getImageData(0, 0, scaled.w, scaled.h)
    const data = imagedata.data
    if(bri) filters.brightness(data, 4, bri)
    if(con) filters.contrast(data, 4, con)
    if(sat) filters.saturation(data, 4, sat)
    if(vib) filters.vibrance(data, 4, vib)
    if(hue) filters.hue(data, 4, hue)
    if(sep) filters.sepia(data, 4, sep)
    this.ctx.putImageData(imagedata, 0, 0)
  }

}

export default Canvas
