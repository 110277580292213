import PropTypes from 'prop-types'
import React from 'react'

const DomainToken = ({ name, is_default }) => (
  <div className="token">
    { name } { is_default &&
      <>(<span className="alert">default</span>)</>
    }
  </div>
)

DomainToken.propTypes = {
  name: PropTypes.string,
  is_default: PropTypes.bool
}

export default DomainToken
