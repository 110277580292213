import { toQuery } from '@core/utils/transforms'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Image extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    host: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    transforms: PropTypes.object,
    onClick: PropTypes.func,
    onLoad: PropTypes.func
  }

  static defaultProps = {
    host: null,
    onLoad: () => {},
    onClick: () => {}
  }

  state = {
    loaded: false
  }

  _handleClick = this._handleClick.bind(this)
  _handleLoad = this._handleLoad.bind(this)

  render() {
    const { src } = this.props
    if(!src) return <div className="maha-image" />
    return (
      <div className="maha-image" onClick={ this._handleClick }>
        <Img { ...this._getImage() } />
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { loaded } = this.state
    if(loaded !== prevState.loaded) {
      this.props.onLoad()
    }
  }

  _getHost() {
    const { provider } = this.context
    const { host } = this.props
    return host || provider.cdn_host
  }

  _getImage() {
    const { alt, className, height, title, width } = this.props
    const host = this._getHost()
    const normal = `${host}/imagecache${this._getSrc(1)}`
    const retina = `${host}/imagecache${this._getSrc(2)}`
    return {
      alt,
      className,
      draggable: false,
      src: normal,
      srcSet: `${normal} 1x, ${retina} 2x`,
      title,
      width,
      height,
      onLoad: this._handleLoad
    }
  }

  _getSrc(dpi) {
    const { src, height, transforms, width } = this.props
    if(!transforms) return src
    const query = toQuery({
      ...transforms,
      ...width ? { w: width } : {},
      ...height ? { h: height } : {},
      dpi
    })
    return `/${query}${src}`
  }

  _handleClick() {
    this.props.onClick()
  }

  _handleLoad() {
    this.setState({
      loaded: true
    })
  }

}

export default Image
