import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Senders)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/senders`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Sender)', key: 'rfc822', sort: 'name', primary: true },
      { label: 't(Status)', key: 'email_status', primary: true, collapsing: true, format: (sender) => (
        <StatusToken status={ sender.email_status } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      title: 't(No Senders)',
      text: 't(You have not yet created any senders for this advisor)',
      icon: 'th-list',
      buttons: [
        { 
          label: 't(Create Sender)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'sender',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/team/senders/${record.id}`),
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Sender)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
