import { useMemo } from 'react'

export const files = (state, props) => {
  return useMemo(() => {
    return state.files.sort((fileA, fileB) => {
      const aIsImage = (fileA.contentType || fileA.asset.content_type).match(/(jpeg|jpg|gif|png)/) !== null
      const bIsImage = (fileB.contentType || fileB.asset.content_type).match(/(jpeg|jpg|gif|png)/) !== null
      if (aIsImage && !bIsImage) return -1
      if (bIsImage && !aIsImage) return 1
      return 0
    })
  }, [state.files])
}

export const value = (state, props) => {
  return useMemo(() => {
    const assetIds = state.files.filter(file => {
      return !_.isNil(file.asset)
    }).map(file => {
      return file.asset.id
    })
    return (props.multiple ? assetIds : assetIds[0]) || null
  }, [state.files, props.multiple])
}
