import ProgressPie from '@admin/components/progress/pie'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Process extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    batch: PropTypes.object,
    title: PropTypes.string,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func
  }

  state = {
    progress: null
  }

  _handleFailure = this._handleFailure.bind(this)
  _handleProgress = _.throttle(this._handleProgress.bind(this), 150)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <ProgressPie { ...this._getProgress() } />
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleJoin()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  _getPanel() {
    const { title } = this.props
    return {
      title
    }
  }

  _getProgress() {
    const { progress } = this.state
    return {
      title: 't(Processing Request)',
      ...progress || {}
    }
  }

  _handleFailure(err) {
    setTimeout(this.props.onFailure(err), 1000)
  }

  _handleJoin() {
    const { admin, network } = this.context
    const { batch } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/batches/${batch.id}`
    network.subscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.subscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.subscribe({ channel, action: 'failure', handler: this._handleFailure })
  }

  _handleLeave() {
    const { admin, network } = this.context
    const { batch } = this.props
    const { team } = admin
    const channel = `/teams/${team.id}/admin/batches/${batch.id}`
    network.unsubscribe({ channel, action: 'progress', handler: this._handleProgress })
    network.unsubscribe({ channel, action: 'success', handler: this._handleSuccess })
    network.unsubscribe({ channel, action: 'failure', handler: this._handleFailure })
  }

  _handleProgress(progress){
    this.setState({ progress })
  }

  _handleSuccess() {
    setTimeout(this.props.onSuccess, 2000)
  }

}

export default Process
