import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class VersionHistory extends React.Component {

  static contextTypes = {
    network: PropTypes.object,
    versions: PropTypes.object
  }

  static propTypes = {
    version: PropTypes.object,
    versions: PropTypes.array
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    const { versions } = this.props
    return (
      <div className="maha-version-history">
        { versions.map((version, index) => (
          <div { ...this._getVersion(version) } key={`version_${index}`}>
            <div className="maha-version-history-active">
              <Icon icon={ this._getIcon(version.record.id) } />
            </div>
            <div className="maha-version-history-details">
              <div className="maha-version-history-title">
                { moment(version.record.updated_at).format('MMM DD, YYYY [@] h:mm A') }
              </div>
              { version.record.is_published &&
                <span className="maha-version-history-status">
                  <T text="Published Version" />
                </span>
              }
              { version.record.user &&
                <div className="maha-version-history-user">
                  { version.record.user.full_name }
                </div>              
              }
              <Button { ...this._getTasks(version, index) }/>
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getClass(id) {
    const { version } = this.props
    const classes = ['maha-version-history-version']
    if(id === version.record.id) classes.push('active')
    return classes.join(' ')
  }

  _getIcon(id) {
    const { version } = this.props
    return id === version.record.id ? 'check-circle' : 'circle-o'
  }

  _getTasks(version, index) {
    return {
      icon: 'ellipsis-v',
      className: 'maha-version-history-tasks',
      tasks: [
        ...index === 0 && !version.record.is_published ? [
          { label: 't(Publish Version)', handler: this._handlePublish.bind(this, version) }
        ] : [
          { label: 't(Revert to Version)', handler: this._handleRollback.bind(this, version) }
        ]
      ]
    }
  }

  _getVersion(version) {
    return {
      className: this._getClass(version.record.id),
      onClick: this._handleClick.bind(this, version)
    }
  }

  _handleClick(version) {
    this.context.versions.setVersion(version.record.id)
  }

  _handlePublish(version) {
    this.context.versions.publish(version)
  }

  _handleRollback(version) {
    this.context.versions.rollback(version)
  }

}

export default VersionHistory
