import PropTypes from 'prop-types'
import Card from './card'
import React from 'react'

class Settings extends React.PureComponent {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  render() {
    const Component = this._getComponent()
    return <Component { ...this.props } />
  }

  _getComponent() {
    const { maha } = this.context
    const { formdata } = this.props
    const card = _.find(maha.dashboardCards, {
      code: formdata.type.code
    })
    return card.new || Card
  }

}

export default Settings
