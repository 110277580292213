import Form from '@admin/components/form'
import LinkField from '../linkfield'
import IconField from '../iconfield'
import PropTypes from 'prop-types'
import React from 'react'

class ButtonField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    omitFeatures: PropTypes.array,
    theme: PropTypes.object,
    value: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    button: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.button) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { button } = this.state
    if(!_.isEqual(button, prevState.button)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { omitFeatures, theme, website} = this.props
    const { button } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Text)', name: 'text', type: 'textfield', required: true, defaultValue: button.text },
            { label: 't(Icon)', name: 'icon', type: IconField, show: _.includes(omitFeatures, 'icon'), theme, website, value: button.icon  },
            { label: 't(Link To)', name: 'link', type: LinkField, theme, defaultValue: button.link }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { button } = this.state
    this.props.onChange(button.text !== null ? {
      text: button.text,
      ...button.icon ? {
        icon: button.icon
      } : {},
      ...button.link ? {
        link: button.link
      } : {}
    } : null)
  }

  _handleSet(button) {
    this.setState({
      button: {
        icon: null,
        text: null,
        link: null,
        ...button || {}
      }
    })
  }

  _handleUpdate(button) {
    this.setState({ button })
  }

}

export default ButtonField
