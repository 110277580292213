import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forecast)',
  collection: {
    endpoint: '/api/admin/truevail/admin/forecast',
    table: [
      { label: 't(Date)', key: 'date', sort: 'date', primary: true, format: 'date' },
      ...props.domains.map(domain => ({
        label: domain.name, 
        key: 'domains', 
        primary: true, 
        align: 'right', 
        collapsing: true, 
        format: (record) => record.domains[domain.name]
      }))
    ],
    defaultSort: { key: 'date', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Publishings)',
      text: 't(There are no published email campaigns)'
    },
    entity: 'publishing'
  }
})

const mapResourcesToPanel = (props, context) => ({
  domains: {
    endpoint: '/api/admin/domains',
    filter: {
      name: {
        $nin: ['mahaplatform.com','truevailapp.com']
      }
    }
  }
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
