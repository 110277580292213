import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Media extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    website: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Choose Media)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getSearch() {
    const { website, onDone } = this.props
    return {
      endpoint: `/api/admin/websites/websites/${website.id}/images`,
      prompt: 'Find media',
      valueKey: 'id',
      textKey: 'fileName',
      layout: Results,
      props: {
        onDone
      }
    }
  }

  _handleBack() {
    this.props.onBack()
  }

}

export default Media
