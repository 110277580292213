import OrganizersField from '@apps/events/admin/components/organizersfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event } = this.props
    return {
      title: 't(Edit Event)',
      method: 'PATCH',
      endpoint: `/api/admin/events/events/${event.id}/edit`,
      action: `/api/admin/events/events/${event.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/events', placeholder: '/path/to/event', destination: `/events/${event.code}` },
            { label: 't(Description)', name: 'description', type: 'textarea' },
            { label: 't(Image)', name: 'image_id', type: 'attachmentfield' },
            { label: 't(Organizers)', name: 'organizer_ids', type: OrganizersField }
          ]
        },
        {
          label: 't(Options)',
          fields: [
            { label: 'Pay by Check', type: 'segment', fields: [
              { prompt: 'Enable Pay by Check', name: 'payment_config.check_enabled', type: 'checkbox' },
              ...this._getCheck()
            ] },
            { label: 'Pay Later', type: 'segment', fields: [
              { prompt: 'Enable Pay Later', name: 'payment_config.async_enabled', type: 'checkbox' },
              ...this._getAsync()
            ] },
            { label: 'Confirmation Message', name: 'payment_config.confirmation', type: 'textarea', defaultValue: 'You will receive your tickets shortly via email' }
          ]
        }
      ]
    }
  }

  _getAsync() {
    const { config } = this.state
    return [
      { name: 'payment_config.async_text', type: 'textfield', show: _.get(config, 'payment_config.async_enabled') === true,  placeholder: 't(Enter payment text (e.g. Pay at Pickup / Pay at Register))', required: true }
    ]
  }

  _getCheck() {
    const { config } = this.state
    if(_.get(config, 'payment_config.check_enabled') !== true) return []
    return [
      { name: 'payment_config.check_text', type: 'textarea', show: _.get(config, 'payment_config.check_enabled') === true, placeholder: 't(Enter instructions for sending check)', required: true }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
