import Authorized from '@admin/components/authorized'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Signature extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    profile_id: null,
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleProfile = this._handleProfile.bind(this)
  _handleReset = this._handleReset.bind(this)

  render() {
    if(!this.state.config) return null
    return (
      <Authorized { ...this._getAuthorized() }>
        <Form { ...this._getForm() } />
      </Authorized>
    )
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config.signature
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate('signature', config)
    }
  }

  _getForm() {
    const { config, profile_id } = this.state
    return {
      title: 't(Signature)',
      onCancel: this._handleDone,
      onChange: this._handleChange,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleDone }
      ],
      sections: [
        {
          fields: [
            { name: 'profile_id', type: 'hidden', value: profile_id },
            { name: 'sign', type: 'checkbox', prompt: 'Sign Response', defaultValue: config.sign }
          ]
        }
      ]
    }
  }

  _getDefault() {
    return {
      signature: false
    }
  }

  _getAuthorized() {
    return {
      label: 'Adobe Sign',
      image: '/images/services/adobesign.png',
      service: 'adobesign',
      type: 'signatures',
      onProfile: this._handleProfile
    }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone() {
    this.props.onPop()
  }

  _handleProfile(profile) {
    this.setState({
      profile_id: profile.id
    })
  }

  _handleReset() {
    this.setState({
      config: this._getDefault()
    })
  }

}

export default Signature