import Page from '@admin/components/page'
import Overview from './overview'
import Access from './access'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.role.title,
  manager: {
    path: `/admin/team/roles/${resources.role.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview role={ resources.role } />
      },
      {
        label: 't(Users)',
        path: '/users',
        panel: <Users role={ resources.role } />
      },
      {
        label: 't(Access)',
        path: '/access',
        panel: <Access role={ resources.role } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  role: `/api/admin/team/roles/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
