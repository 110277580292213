import ThreadShow from './threads/show'
import EmailShow from './emails/show'
import Email from './emails'

const routes = [
  { path: '', component: Email },
  { path: '/:view', component: Email },
  { path: '/:profile_id/:view', component: Email },
  { path: '/:profile_id/:id/view', component: EmailShow },
  { path: '/threads/:code', component: ThreadShow }
]

export default routes
