import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const Overview = ({ credit }, { admin }) => {

  const program = {
    label: credit.program.title,
    className: 'link',
    route: `/admin/team/programs/${credit.program.id}`
  }

  const customer = {
    className: 'link',
    label: credit.customer.display_name,
    route: `/admin/crm/contacts/${credit.customer.id}`
  }

  const details = {
    audits: `finance_credits/${credit.id}`,
    comments: `finance_credits/${credit.id}`,
    sections: [
      {
        title: 't(Credit Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...customer } /> },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
          { label: 't(Description)', content: credit.description },
          { label: 't(Amount)', content: numeral(credit.amount).format('0.00') },
          { label: 't(Balance)', content: numeral(credit.balance).format('0.00') }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  credit: PropTypes.object,
  payments: PropTypes.array
}

export default Overview
