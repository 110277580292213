import PropTypes from 'prop-types'
import React from 'react'

const flags = {
  de: '1f1e9-1f1ea',
  en: '1f1fa-1f1f8',
  es: '1f1ea-1f1f8',
  fr: '1f1eb-1f1f7',
  ja: '1f1ef-1f1f5',
  ro: '1f1f7-1f1f4',
  zh: '1f1e8-1f1f3'
}

const languageToken = ({ code, text }) => (
  <div className="token">
    <div className={`emoji emoji-${flags[code]}`} /> { text }
  </div>
)

languageToken.propTypes = {
  code: PropTypes.string,
  text: PropTypes.string
}

export default languageToken
