import EmailReader from '@admin/components/email_reader'
import Panel from '@admin/components/panel'
import React from 'react'

const getEmailReader = (props) => ({
  endpoint: '/api/admin/truevail/admin/processings/emails',
  defaultQuery: {
    ...props.params.domain_id ? {
      domain_id: props.params.domain_id
    } : props.params.domain_segment_id ? {
      domain_segment_id: props.params.domain_segment_id
    } : props.params.segment_id ? {
      segment_id: props.params.segment_id
    } : {},
    interval: props.params.interval,
    value: props.params.value
  },
  perspective: 'queue'
})

const mapPropsToPanel = (props, context) => ({
  component: <EmailReader { ...getEmailReader(props) } />
})

export default Panel(null, mapPropsToPanel)
