import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Transition extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    deal: PropTypes.object,
    from_stage: PropTypes.object,
    to_stage: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { deal, from_stage, to_stage } = this.props
    return {
      title: 't(Update Stage)',
      action: `/api/admin/sales/deals/${deal.id}/transition`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'from_stage_id', type: 'hidden', value: from_stage.id },
            { name: 'to_stage_id', type: 'hidden', value: to_stage.id },
            { label: 't(Description)', name: 'description', type: 'textarea', placeholder: this._getDescription() }
          ]
        }
      ]
    }
  }

  _getDescription() {
    const { to_stage } = this.props
    if(to_stage.probability === 0) return 'Whay was this deal lost?'
    if(to_stage.probability === 100) return 'Whay was this deal won?'
    return 'Describe this stage transition'
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Transition
