import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const purposes = {
  no: {
    title: 't(Do Not Auto Assign)',
    text: 't(When new advisors are created, do not automatically assign this user as a support user)'
  },
  yes: {
    title: 't(Auto Assign)',
    text: 't(When new advisors are created, automatically assign this user as a suppport user)'
  }
}

const AutoAssignToken = ({value}) => (
  <div className="token auto-assign-token">
    <strong><T text={ purposes[value].title } /></strong><br />
    <T text={ purposes[value].text } />
  </div>
)

AutoAssignToken.propTypes = {
  value: PropTypes.string
}

export default AutoAssignToken
