import PositionsField from '@apps/crm/admin/components/channelfield/position'
import AddressesField from '@apps/crm/admin/components/channelfield/address'
import EmailsField from '@apps/crm/admin/components/channelfield/email'
import PhonesField from '@apps/crm/admin/components/channelfield/phone'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    platform: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    fields: PropTypes.array
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact } = this.props
    return {
      title: 't(Edit Contact)',
      method: 'PATCH',
      endpoint: `/api/admin/crm/contacts/${contact.id}/edit`,
      action: `/api/admin/crm/contacts/${contact.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(First Name)', name: 'first_name', type: 'textfield' },
              { label: 't(Last Name)', name: 'last_name', type: 'textfield' }
            ] },
            { label: 't(Positions)', name: 'positions', type: PositionsField },
            { label: 't(Email Addresses)', name: 'email_addresses', type: EmailsField, contact },
            { label: 't(Phone Numbers)', name: 'phone_numbers', type: PhonesField, contact },
            { label: 't(Mailing Addresses)', name: 'mailing_addresses', type: AddressesField, contact },
            { label: 't(Social)', name: 'website', type: 'urlfield', service: 'website', placeholder: 't(Enter Website URL)' },
            { name: 'linkedin', type: 'urlfield', service: 'linkedin', placeholder: 't(Enter LinkedIn URL)' },
            { name: 'instagram', type: 'urlfield', service: 'instagram', placeholder: 't(Enter Instagram URL)' },
            { name: 'facebook', type: 'urlfield', service: 'facebook', placeholder: 't(Enter Facebook URL)' },
            { name: 'threads', type: 'urlfield', service: 'threads', placeholder: 't(Enter Threads URL)' },
            { name: 'youtube', type: 'urlfield', service: 'youtube', placeholder: 't(Enter YouTube URL)' },
            { name: 'x', type: 'urlfield', service: 'x', placeholder: 't(Enter X URL)' },
            { label: 't(Photo)', name: 'photo_id', type: 'attachmentfield', multiple: false },
            { label: 't(Birthday)', name: 'birthday', type: 'datefield' },
            { label: 't(Spouse)', name: 'spouse', type: 'textfield' },
            { label: 't(Prefered Language)', name: 'language_id', type: 'dropdown', endpoint: '/api/admin/crm/languages', valueKey: 'id', textKey: 'text', defaultValue: 12 }
          ]
        },
        ...this._getProperties()
      ]
    }
  }

  _getProperties() {
    const { admin } = this.context
    const programs = this.props.fields.filter(program => {
      return program.access_type !== 'view'
    })
    if(programs.length === 0) return []
    if(admin.team.has_programs) {
      return programs.map(program => ({
        label: <ProgramToken { ...program } />,
        padded: false,
        collapsing: true,
        collapsed: true,
        fields: program.fields.map(field => ({
          type: field.type,
          name: `values.${field.code}`,
          ...field.config
        }))
      }))
    }
    return [{
      label: 't(Custom Properties)',
      fields: programs[0].fields.map(field => ({
        type: field.type,
        name: `values.${field.code}`,
        ...field.config
      }))
    }]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  fields: '/api/admin/programs/fields'
})

export default Container(mapResources)(Edit)
