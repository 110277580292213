import ProfileManager from '@admin/components/profile_manager'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {

  static propTypes = {
    selected: PropTypes.array,
    onCompose: PropTypes.func,
    onSync: PropTypes.func
  }

  render() {
    const buttons = this._getButtons()
    return (
      <div className="email-client-header">
        <div className="email-client-header-section">
          { buttons.left.map((button, index) => (
            <Button { ...button } key={`button_${index}`} />
          ))}
        </div>
        <div className="email-client-header-section">
          { buttons.right.map((button, index) => (
            <Button { ...button } key={`button_${index}`} />
          ))}
        </div>
      </div>
    )
  }

  _getButtons() {
    const { onCompose, onSync } = this.props
    return {
      left: [
        { label: 't(New Email)', color: 'blue', fluid: false, size: 'tiny', handler: onCompose }
      ],
      right: [
        { label: 't(Sync)', className: 'link', handler: onSync },
        { label: 't(Accounts)', className: 'link', modal: <ProfileManager types={['emails']} /> }
      ]
    }
  }

}

export default Header
