import SourceToken from '@admin/tokens/source'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    const networks = this._getSources()
    return {
      title: 't(Edit Social Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}/edit`,
      action: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Networks)', name: 'sources', type: 'checkboxgroup', entity: 'network', options: networks, valueKey: 'source_name', textKey: 'name', format: (source) => (
              <SourceToken source={ source } />
            ), defaultValue: networks.map(n => n.source_name), required: true },
            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'all', text: 't(All Perspectives)' },
                { value: 'first-person plural', text: 't(First Person Plural)' },
                { value: 'first-person singular', text: 't(First Person Singular)' }
              ], defaultValue: 'all', required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'all', text: 't(All Languages)' },
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: 'all', required: true }
            ], required: true },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags' }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { social_campaign } = this.props
    return [
      ..._.includes(['photo','video','link','story','reel'], social_campaign.type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ..._.includes(['photo','video','story','reel'], social_campaign.type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      // ..._.includes(['photo','video','link'], formdata.type) ? [
      //   { name: 'LinkedIn', source_name: 'linkedin' }
      // ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
