import LineItems from '@apps/finance/admin/components/line_items'
import ContactToken from '@apps/crm/admin/tokens/contact'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    invoice: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { invoice } = this.props
    return {
      title: 't(Edit Invoice)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/invoices/${invoice.id}/edit`,
      action: `/api/admin/finance/invoices/${invoice.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { type: 'fields', fields: [
              { label: 't(Program)', name: 'program_id', type: 'lookup', endpoint: '/api/admin/programs', filter: { bank_id: { $eq: 'not_null' } }, valueKey: 'id', textKey: 'title', required: true, format: ProgramToken },
              { label: 't(Contact)', name: 'contact_id', type: 'lookup', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, format: ContactToken }
            ] },
            { type: 'fields', fields: [
              { label: 't(Date)', name: 'date', type: 'datefield', placeholder: 'Date', required: true },
              { label: 't(Due)', name: 'due', type: 'datefield', placeholder: 'Date', required: true }
            ] },
            { label: 't(Line Items)', name: 'line_items', type: LineItems, required: true },
            { label: 't(Notes)', name: 'notes', type: 'textfield', placeholder: 'Add notes' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(invoice) {
    this.context.modal.close()
  }

}

export default Edit
