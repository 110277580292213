const INITIAL_STATE = {
  hidden: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_HIDDEN':
    return {
      ...state,
      hidden: action.hidden
    }

  case 'TOGGLE_HIDDEN':
    return {
      ...state,
      hidden: [
        ..._.xor(state.hidden, [action.key])
      ]
    }

  default:
    return state
  }

}

export default reducer
