import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Pricing extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    pricing: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.pricing) return null
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Pricing', type: 'segment', required: true, fields: [
              { name: 'price_type', type: 'radiogroup',options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'sliding_scale', text: 't(Sliding Scale)' },
                { value: 'free', text: 't(Free)'}
              ], required: true, defaultValue: formdata.price_type },
              ...this._getPriceType()
            ] }
          ]
        }
      ]
    }
  }

  componentDidMount() {
    const { formdata } = this.props
    this.setState({
      pricing: formdata
    })
  }

  _getPriceType() {
    const { formdata } = this.props
    const { pricing } = this.state
    if(pricing.price_type === 'fixed') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, defaultValue: formdata.project_id },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: formdata.revenue_type_id }
        ] },
        { label: 't(Fixed Price)', name: 'fixed_price', type: 'moneyfield', required: true, defaultValue: formdata.fixed_price },
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: formdata.tax_rate }
      ]
    }
    if(pricing.price_type === 'sliding_scale') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup',  endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, defaultValue: formdata.project_id },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: formdata.revenue_type_id }
        ] },
        { type: 'fields', fields: [
          { label: 't(Low Price)', name: 'low_price', type: 'moneyfield', required: true, defaultValue: formdata.low_price },
          { label: 't(High Price)', name: 'high_price', type: 'moneyfield', required: true, defaultValue: formdata.high_price }
        ] },
        { label: 't(Overage Strategy)', name: 'overage_strategy', type: 'dropdown', options: [
          { value: 'income', text: 't(Treat any amount over the low price as additional income)' },
          { value: 'donation', text: 't(Treat any amount over the low price as a donation)' }
        ], required: true, defaultValue: formdata.overage_strategy },
        ...this._getOverageStrategy(),
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: formdata.tax_rate }
      ]
    }
    return []
  }

  _getOverageStrategy() {
    const { formdata } = this.props
    const { pricing } = this.state
    if(pricing.overage_strategy === 'donation') {
      return [
        { label: 't(Donation Revenue Type)', name: 'donation_revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', filter: { id: { $in: [30, 37] } }, valueKey: 'id', textKey: 'title', required: true, format: RevenueTypeToken, defaultValue: formdata.donation_revenue_type_id }
      ]
    }
    return []
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(pricing) {
    this.setState({ pricing })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(variant) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...variant
    })
  }

}

export default Pricing
