import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    const { config } = this.state
    return {
      title: 't(Clone Social Campaign)',
      action: `/api/admin/truevail/agency/social_campaigns/${social_campaign.id}/clone`,
      method: 'POST',
      saveText: 't(Clone)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 't(Enter the title)', required: true, defaultValue: `Copy of ${social_campaign.title}` },

            { label: 't(Voice)', type: 'segment', fields: [
              { label: 'Perspective', type: 'dropdown', name: 'perspective', options: [
                { value: 'all', text: 't(All Perspectives)' },
                { value: 'first-person plural', text: 't(First Person Plural)' },
                { value: 'first-person singular', text: 't(First Person Singular)' }
              ], defaultValue: social_campaign.perspective, required: true },
              { label: 'Language', type: 'dropdown', name: 'language', options: [
                { value: 'all', text: 't(All Languages)' },
                { value: 'american english', text: 't(English) (t(American))' },
                { value: 'canadian english', text: 't(English) (t(Canadian))' },
                { value: 'french', text: 't(French)' },
                { value: 'german', text: 't(German)' },
                { value: 'italian', text: 't(Italian)' }
              ], defaultValue: social_campaign.language, required: true }
            ], required: true },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags', defaultValue: social_campaign.tags.map(tag => tag.text) },
            // { label: 't(Voice)', type: 'segment', fields: [
            //   { type: 'radiogroup', name: 'voice', options: [
            //     { value: 'none', text: 't(Dont change the voice)' },
            //     { value: 'translate', text: 't(Translate the text to another voice)' }
            //   ], defaultValue: 'none'},
            //   { label: 'Perspective', type: 'dropdown', show: config.voice === 'translate', name: 'perspective', options: [
            //     { value: 'first-person singular', text: 't(First Person Singular)' },
            //     { value: 'first-person plural', text: 't(First Person Plural)' }
            //   ], defaultValue: 'first-person singular' },
            //   { label: 'Language', type: 'dropdown', show: config.voice === 'translate', name: 'language', options: [
            //     { value: 'american english', text: 't(English) (t(American))' },
            //     { value: 'canadian english', text: 't(English) (t(Canadian))' },
            //     { value: 'french', text: 't(French)' },
            //     { value: 'german', text: 't(German)' },
            //     { value: 'italian', text: 't(Italian)' }
            //   ], defaultValue: 'american english' }
            // ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(social_campaign) {
    this.context.flash.set('success', 't(The social campaign was successfully cloned)')
    this.context.router.push(`/truevail/agency/social_campaigns/${social_campaign.id}`)
    this.context.modal.close()
  }

}

export default Clone
