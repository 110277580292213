import ResponsiveField from '../responsivefield'
import SpacingField from '../spacingfield'
import Form from '@admin/components/form'
import TextField from '../textstylefield'
import BorderField from '../borderfield'
import RadiusField from '../radiusfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import Preview from './preview'
import React from 'react'

const Spacing = (props) => <ResponsiveField { ...props } component={ SpacingField } />
const Border = (props) => <ResponsiveField { ...props } component={ BorderField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />

class ButtonField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    name: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(this.state.config === null) return null
    return (
      <div className="mjson-designer-stylefield">
        <Preview { ...this._getPreview() } />
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { config } = this.state
    const { theme } = this.props
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Text)', name: 'text', type: TextField, collapsing: true, collapsed: true, theme, omitFeatures: ['linkColor','linkFormat'], defaultValue: {
              preset: 'button',
              color: {
                value: 'default'
              }
            }, value: config.text },
            { label: 't(Background Color)', name: 'backgroundColor', type: Color, collapsing: true, collapsed: true, theme, defaultValue: {
              value: 'default'
            }, defaultColor: true, value: config.backgroundColor },
            { label: 't(Border)',  name: 'border', canHover: true, theme, type: Border, collapsing: true, collapsed: true, value: config.border },
            { label: 't(Rounded Corners)',  name: 'radius', canHover: true, type: Radius, collapsing: true, collapsed: true, value: config.radius },
            { label: 't(Padding)',  name: 'padding', canHover: false, type: Spacing, collapsing: true, collapsed: true, defaultValue: '15px 30px 15px 30px', value: config.padding }
          ]
        }
      ]
    }
  }

  _getPreview() {
    const { name, theme } = this.props
    const { config } = this.state
    return {
      theme,
      type: name,
      button: config
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleSet(config) {
    this.setState({
      config: {
        text: {
          preset: 'button',
          color: {
            value: 'default'
          },
          align: 'center'
        },
        backgroundColor: {
          value: 'default'
        },
        radius: {
          type: 'rounded',
          radius: '15px'
        },
        padding: '15px 30px 15px 30px',
        ...config || {}
      }
    })
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default ButtonField
