import { CSSTransition } from 'react-transition-group'
import { getCode } from '@core/utils/codes'
import Notification from './notification'
import PropTypes from 'prop-types'
import React from 'react'

export const NotificationsContext = React.createContext(null)
NotificationsContext.displayName = 'NotificationsContext'

class Notifications extends React.Component {

  static childContextTypes = {
    notifications: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      host: PropTypes.object,
      network: PropTypes.object,
      provider: PropTypes.object,
      router: PropTypes.object
    }),
    children: PropTypes.any,
    notifications: PropTypes.array,
    onClear: PropTypes.func,
    onPush: PropTypes.func,
    onRemove: PropTypes.func
  }

  _handleClear = this._handleClear.bind(this)
  _handleClick = this._handleClick.bind(this)
  _handleMarkVisited = this._handleMarkVisited.bind(this)
  _handlePushDesktop = this._handlePushDesktop.bind(this)
  _handlePushInApp = this._handlePushInApp.bind(this)
  _handlePushNotification = this._handlePushNotification.bind(this)
  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { notifications } = this.props
    return (
      <NotificationsContext.Provider value={ this.getChildContext() }>
        { this.props.children }
        <CSSTransition in={ notifications.length > 0 } classNames="slide-in-down" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-notifications-frame">
            <div className="maha-notifications-panel">
              { notifications.map((notification, index) => (
                <Notification { ...this._getNotification(notification, index) } key={ `notification_${index}` } />
              )) }
            </div>
          </div>
        </CSSTransition>
      </NotificationsContext.Provider>
    )
  }

  componentDidMount() {
    this._handleJoin()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  getChildContext() {
    return {
      notifications: {
        clear: this._handleClear,
        markVisited: this._handleMarkVisited,
        pushNotification: this._handlePushNotification,
        pushInApp: this._handlePushInApp,
        pushDesktop: this._handlePushDesktop,
        remove: this._handleRemove
      }
    }
  }

  _getNotification(notification, index) {
    return {
      notification,
      onClick: this._handleClick.bind(this, index),
      onRemove: this._handleRemove.bind(this, notification.code)
    }
  }

  _handleClear() {
    this.props.onClear()
  }

  _handleClick(index) {
    const { notifications } = this.props
    const notification = notifications[index]
    this.props.onRemove(notification.code)
    if(!notification.route) return
    this.props.context.router.push(notification.route)
  }

  _handleJoin() {
    this.props.context.network.subscribe({ 
      action: 'push_notification', 
      handler: this._handlePushNotification 
    })
  }

  _handleLeave() {
    this.props.context.network.unsubscribe({ 
      action: 'push_notification', 
      handler: this._handlePushNotification 
    })
  }

  _handleMarkVisited(id) {
    this.props.context.network.request({
      endpoint: `/api/admin/notifications/${id}/visited`,
      method: 'PATCH'    
    })
  }

  _handlePlaySound(sound) {
    const audio = new Audio(`/audio/${sound}.mp3`)
    audio.play()
  }

  _handlePushNotification(notification) {
    const { admin, host } = this.props.context
    const { preferences } = admin
    const { push_notifications_enabled, in_app_notifications_enabled, notification_sound_enabled, notification_types } = preferences
    const hasFocus = host.device.getFocus()
    const notification_type = _.find(notification_types, { type: notification.type })
    const inapp_enabled = (!notification_type || notification_type.inapp_enabled) && in_app_notifications_enabled
    const push_enabled = (!notification_type || notification_type.push_enabled) && push_notifications_enabled
    const inapp = hasFocus && inapp_enabled
    const push = !hasFocus && push_enabled
    const play_sound = (inapp || push) && notification_sound_enabled
    const sound = play_sound ? notification.sound || preferences.notification_sound || 'notification' : null
    if(inapp) return this._handlePushInApp(notification, sound)
    if(push) return this._handlePushDesktop(notification, sound)
  }

  _handlePushInApp(notification, sound) {
    const { onPush } = this.props
    const code = getCode(10)
    this._handlePlaySound(sound)
    onPush(code, notification)
  }

  _handlePushDesktop(data) {
    const { provider } = this.props.context
    if(data.sound) this._handlePlaySound(data.sound)
    const notification = new window.Notification(data.title, {
      silent: true,
      icon: `${provider.asset_host}/imagecache/w=100&h=100&fit=cover${provider.logo}`,
      ...data || {}
    })
    if(data.route) {
      notification.onclick = () => {
        notification.close()
        this.props.context.host.device.focus()
        this.props.context.router.push(data.route)
      }
    }
  }

  _handleRemove(code) {
    this.props.onRemove(code)
  }

}

export default Notifications
