import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    cid: PropTypes.string,
    excludeIds: PropTypes.array,
    files: PropTypes.array,
    format: PropTypes.any,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    selected: PropTypes.array,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onDone: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="maha-mediafield-photos">
        { records.map((record, index) => (
          <div className={ this._getClass(record) } key={`photo_${record.id}`} onClick={ this._handleChoose.bind(this, record) }>
            <div className="maha-mediafield-photo-inner">
              <div className="maha-mediafield-photo-preview">
                <Image { ...this._getImage(record.asset) } />
              </div>
              <div className="maha-mediafield-photo-label">
                { record.asset.file_name }<br />
                <span>
                  { record.asset.metadata.width }x{ record.asset.metadata.height }
                </span>
              </div>
            </div>
          </div>
        )) }
      </div>
    )
  }

  _getClass(photo) {
    const { files } = this.props
    const classes = ['maha-mediafield-photo']
    if(_.find(files, { id: photo.id })) classes.push('selected')
    return classes.join(' ')
  }

  _getImage(image) {
    return {
      src: image.path,
      transforms: {
        w: 150,
        h: 150,
        fit: 'cover'
      }
    }
  }

  _handleChoose(photo) {
    this.props.onDone([photo.asset])
  }

}

export default Results
