import PropTypes from 'prop-types'
import React from 'react'

export const MahaContext = React.createContext()
MahaContext.displayName = 'MahaContext'

export const useMahaContext = () => React.useContext(MahaContext)

class Maha extends React.Component {

  static childContextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.array,
    activityCards: PropTypes.object,
    appUserTasks: PropTypes.array,
    appUserFields: PropTypes.array,
    appUserValues: PropTypes.array,
    badges: PropTypes.array,
    calendarTypes: PropTypes.array,
    children: PropTypes.any,
    dashboardCards: PropTypes.array,
    fingerprint: PropTypes.string,
    roots: PropTypes.array,
    routes: PropTypes.object,
    settings: PropTypes.object,
    setFingerprint: PropTypes.func,
    setTimezone: PropTypes.func,
    setToken: PropTypes.func,
    timezone: PropTypes.string,
    token: PropTypes.string,
    triggers: PropTypes.array
  }

  render() {
    return (
      <MahaContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </MahaContext.Provider>
    )
  }

  getChildContext() {
    const { access, activityCards, appUserTasks, appUserFields, appUserValues, badges, calendarTypes, dashboardCards, roots, routes, settings, triggers } = this.props
    const { fingerprint, setFingerprint, setTimezone, setToken, timezone, token } = this.props
    return {
      maha: {
        access,
        activityCards,
        appUserTasks,
        appUserFields,
        appUserValues,
        badges,
        calendarTypes,
        dashboardCards, 
        fingerprint,
        roots,
        routes,
        setFingerprint,
        setTimezone,
        setToken,
        settings,
        timezone,
        token,
        triggers
      }
    }
  }

}

export default Maha
