import Notes from '@apps/crm/admin/views/contacts/show/notes'
import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.task.title,
  manager: {
    path: `/admin/tasks/tasks/${resources.task.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview task={ resources.task } />
      },
      {
        label: 't(Notes)',
        path: '/notes',
        panel: <Notes contact={ resources.task.contact } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  task: `/api/admin/tasks/tasks/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
