import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.sender_domain.name,
  manager: {
    path: `/admin/truevail/admin/sender_domains/${resources.sender_domain.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview sender_domain={ resources.sender_domain } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  sender_domain: `/api/admin/truevail/admin/sender_domains/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
