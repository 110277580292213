import MBlocksDesigner from '@admin/components/mblocks_designer/wrapper'
import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Designer extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <>
        { email.engine === 'mblocks' ?
          <MBlocksDesigner { ...this._getMBlocksDesigner() } /> :
          <MJSONDesigner { ...this._getMJSONDesigner() } />
        }
      </>
    )
  }

  _getMBlocksDesigner() {
    const { admin } = this.context
    const { email } = this.props
    return {
      defaultValue: email.config,
      editable: canAccess({ 
        programs: { $canEdit: email.program.id },
        rights: { $allOf: ['automation:manage_workflows'] } 
      }, admin),
      email,
      endpoint: `/api/admin/automation/emails/${email.id}`,
      program: email.program,
      tokens: this._getTokens()
    }
  }

  _getMJSONDesigner() {
    const { admin } = this.context
    const { email } = this.props
    return {
      editable: canAccess({ 
        programs: { $canEdit: email.program.id },
        rights: { $allOf: ['automation:manage_workflows'] } 
      }, admin),
      endpoint: `/automation_emails/${email.id}`,
      root_route: `/automation/emails/${email.id}/design`,
      themeable: false,
      type: 'email',
      versionable: true
    }
  }

  _getEmailDesigner() {
    const { email } = this.props
    return {
      defaultValue: email.config,
      endpoint: `/api/admin/automation/emails/${email.id}`,
      program: email.program,
      tokens: this._getTokens()
    }
  }

  _getTokens() {
    const { event, form, store } = this.props.email.workflow
    if(event) return this._getRegistrationTokens(event)
    if(form) return this._getResponseTokens(form)
    if(store) return this._getOrderTokens(store)
    return []
  }

  _getPaymentTokens(model) {
    return {
      title: 't(Payment Tokens)',
      tokens: [
        { name: 'Method', token: `${model}.payment_method` },
        { name: 'Amount', token: `${model}.payment_amount` },
        { name: 'Card', token: `${model}.payment_card` },
        { name: 'Summary', token: `${model}.payment_summary` }
      ]
    }
  }

  _getRegistrationTokens(event) {
    return [
      {
        title: 't(Registration Tokens)',
        tokens: [
          { name: 'First Name', token: 'registration.first_name' },
          { name: 'Last Name', token: 'registration.last_name' },
          { name: 'Email', token: 'registration.email' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `registration.${field.name.token}`
          })),
          { name: 'eTickets', token: 'registration.etickets' }
        ]
      },
      this._getPaymentTokens('registration')
    ]
  }

  _getResponseTokens(form) {
    return [
      {
        title: 't(Response Tokens)',
        tokens: [
          ...form.config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `response.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('response')
    ]
  }

  _getOrderTokens(store) {
    return [
      {
        title: 't(Order Tokens)',
        tokens: [
          { name: 'Order ID', token: 'order.id' },
          { name: 'First Name', token: 'order.first_name' },
          { name: 'Last Name', token: 'order.last_name' },
          { name: 'Email', token: 'order.email' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `order.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('order')
    ]
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Designer email={ props.email } />
})

export default Panel(null, mapPropsToPanel)
