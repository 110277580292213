import AlignmentField from '@admin/components/form/alignmentfield'
import FormatField from '@admin/components/form/formatfield'
import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import ColorField from '../colorfield'
import TextField from './textfield'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Alignment = (props) => <ResponsiveField { ...props } component={ AlignmentField } />
const Format = (props) => <ResponsiveField { ...props } component={ FormatField } />

class TextStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    omitFeatures: PropTypes.array,
    theme: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    text: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  // _handleChangeField = this._handleChangeField.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(this.state.text === null) return null
    return (
      <div className="mjson-designer-stylefield textstylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { text } = this.state
    if(!_.isEqual(text, prevState.text)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { omitFeatures, theme } = this.props
    const { text } = this.state
    const presets = this._getTextPresets()
    return {
      inline: true,
      onChange: this._handleUpdate,
      // onChangeField: this._handleChangeField,
      sections: [
        {
          fields: [
            { label: 't(Preset)', type: 'segment', fields: [
              { name: 'preset', type: 'dropdown', options: presets, defaultValue: 'text', value: text.preset },
              { name: 'text', type: TextField, theme, include: text.preset === 'custom', value: text.text }
            ] },
            { label: 't(Properties)', type: 'segment', collapsing: true, collapsed: true, fields: [
              { label: 't(Color)', name: 'color', type: Color, defaultColor: true, theme, value: text.color },
              { label: 't(Format)', name: 'format', type: Format, defaultValue: [], value: text.format },
              { label: 't(Link Color)', name: 'linkColor', type: Color, show: !_.includes(omitFeatures, 'linkColor'), defaultColor: true, theme, value: text.linkColor },
              { label: 't(Link Format)', name: 'linkFormat', type: Format, show: !_.includes(omitFeatures, 'linkFormat'), defaultValue: [], value: text.linkFormat },
              { label: 't(Alignment)', name: 'align', type: Alignment, defaultValue: 'left', value: text.align }  
            ] }
          ]
        }
      ]
    }
  }

  _getTextPresets() {
    const { theme } = this.props
    return [
      ...theme.typography.map(preset => {
        return { value: preset.code, text: preset.name }
      }),
      { value: 'custom', text: 't(Custom)' }
    ]
  }

  _handleChange() {
    const { text } = this.state
    this.props.onChange({
      preset: text.preset,
      ...text.preset === 'custom' ? {
        text: text.text
      } : {},
      color: text.color,
      format: text.format,
      linkColor: text.linkColor,
      linkFormat: text.linkFormat,
      align: text.align
    })
  }

  // _handleChangeField(key, value) {
  //   const { theme } = this.props
  //   if(key !== 'preset') return
  //   const preset = theme.typography.find(preset => {
  //     return preset.code === value
  //   })
  //   if(!preset) return
  //   this.setState({
  //     text: {
  //       ...this.state.text,
  //       color: preset.color,
  //       format: preset.format || [],
  //       linkColor: preset.linkColor,
  //       linkFormat: preset.linkFormat || []
  //     }
  //   })
  // }

  _handleSet(text) {
    this.setState({
      text: {
        preset: 'text',
        text: null,
        color: null,
        format: null,
        linkColor: null,
        linkFormat: null,
        align: null,
        ...text || {}
      }
    })
  }

  _handleUpdate(text) {
    this.setState({ text })
  }

}

export default TextStyleField
