import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'
import New from './new'

class PinsField extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    pins: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCreate = this._handleCreate.bind(this)

  render() {
    const { pins } = this.state
    return (
      <div className="pinsfield">
        <div className="pinsfield-pins">
          { pins.map((pin, index) => (
            <div className="pinsfield-pin" key={`pin_${index}`}>
              <div className="pinsfield-pin-icon">
                <Icon icon="map-marker" />
              </div>
              <div className="pinsfield-pin-label">
                { pin.title }<br />
                <span>{ pin.address.description }</span>
              </div>
              <Button { ...this._getEdit(pin, index) } />
              <Button { ...this._getRemove(index) } />
            </div>
          )) }
          <div className="pinsfield-pin-add">
            <Button { ...this._getAdd() } />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { pins } = this.state
    if(!_.isEqual(pins, prevState.pins)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    return {
      label: '+ t(add pin)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEdit(pin, index) {
    return {
      icon: 'pencil',
      className: 'pinsfield-pin-action',
      handler: this._handleEdit.bind(this, pin, index)
    }
  }

  _getRemove(index) {
    return {
      icon: 'times',
      className: 'pinsfield-pin-action',
      confirm: 't(Are you sure you want to remove this pin?)',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _handleAdd() {
    const config = {
      onDone: this._handleCreate
    }
    this.context.form.push(<New { ...config } />)
  }

  _handleCreate(pin) {
    const { pins } = this.state
    this.setState({
      pins: [
        ...pins,
        pin
      ]
    })
  }

  _handleChange() {
    const { pins } = this.state
    this.props.onChange(pins)
  }

  _handleEdit(pin, index) {
    const config = {
      pin,
      onDone: this._handleUpdate.bind(this, index)
    }
    this.context.form.push(<Edit { ...config } />)
  }

  _handleSet(pins) {
    this.setState({ pins })
  }

  _handleRemove(index) {
    const { pins } = this.state
    this.setState({
      pins: pins.filter((pin, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(index, newpin) {
    const { pins } = this.state
    this.setState({
      pins: pins.map((pin, i) => {
        return i === index ? newpin : pin
      })
    })
  }

}

export default PinsField
