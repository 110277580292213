import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Merge extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    tag: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { tag } = this.props
    return {
      title: 't(Merge Tag)',
      method: 'PATCH',
      action: `/api/admin/team/tags/${tag.id}/merge`,
      saveText: 't(Merge)',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Merge Into)', name: 'merge_id', type: 'dropdown', endpoint: '/api/admin/team/tags', filter: { id: { $neq: tag.id } }, valueKey: 'id', textKey: 'text', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Merge
