import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import RadiusField from '@admin/components/mjson_designer/components/radiusfield'
import BorderField from '@admin/components/mjson_designer/components/borderfield'
import React from 'react'

export const Border = (props) =>  <ResponsiveField { ...props } component={ BorderField } />
export const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />

export const BorderSection = (config, device, theme, type, defaults = {}) => ({
  label: 't(Border)',
  instructions: 't(Specify the box model style and layout properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { label: 't(Border)', name: 'styles.border', device, theme, canHover: false, type: Border, defaultValue: defaults.border, value: config.styles.border },
    { label: 't(Rounded Corners)', name: 'styles.radius', device, canHover: false, type: Radius, defaultValue: defaults.radius, value: config.styles.radius }
  ]
})
