import StatusToken from '@admin/tokens/status'
import PropTypes from 'prop-types'
import React from 'react'

const UserStatusToken = (user) => {
  const status = user?.account?.activated_at ? 'activated' : user?.account?.activation_sent_at ? 'activation sent' : 'not activated'
  return <StatusToken status={ status }/>
}

UserStatusToken.propTypes = {
  account: PropTypes.object
}

export default UserStatusToken
