import * as varaibles from './variables'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    orientation: PropTypes.string,
    scale: PropTypes.number,
    onChange: PropTypes.func
  }

  render() {
    return (
      <div className="preview-header">
        <select { ...this._getDevice() }>
          { varaibles.devices.map((device, index) => (
            <option key={`device_${index}`} value={ device.value }>
              { device.label}
            </option>
          ))}
        </select>
        <select { ...this._getOrientation() }>
          { varaibles.orientations.map((orientation, index) => (
            <option key={`orientation_${index}`} value={ orientation.value }>
              { orientation.label}
            </option>
          ))}
        </select>
        <select { ...this._getScale() }>
          { varaibles.scales.map((scale, index) => (
            <option key={`scale_${index}`} value={ scale.value }>
              { scale.label}
            </option>
          ))}
        </select>
      </div>
    )
  }

  _getDevice() {
    const { device } = this.props
    return {
      className: 'ui selection dropdown',
      onChange: this._handleChange.bind(this, 'device'),
      value: device
    }
  }

  _getOrientation() {
    const { device, orientation } = this.props
    return {
      className: device === 'desktop' ? 'ui disabled selection dropdown' : 'ui selection dropdown',
      disabled: device === 'desktop',
      onChange: this._handleChange.bind(this, 'orientation'),
      value: orientation
    }
  }

  _getScale() {
    const { device, scale } = this.props
    return {
      className: device === 'desktop' ? 'ui disabled selection dropdown' : 'ui selection dropdown',
      disabled: device === 'desktop',
      onChange: this._handleChange.bind(this, 'scale'),
      value: scale
    }
  }

  _handleChange(key, e) {
    const { value } = e.target
    const normalized = key === 'scale' ? Number(value) : value
    this.props.onChange(key, normalized)
  }

}

export default Header
