
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import ProfileToken from '@admin/tokens/profile'
import Message from '@admin/components/message'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Profiles extends React.Component {

  static propTypes = {
    cancel: PropTypes.object,
    profiles: PropTypes.array,
    program_id: PropTypes.number,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onConnect: PropTypes.func,
    onEdit: PropTypes.func,
    onDone: PropTypes.func
  }

  render() {
    const { profiles } = this.props
    const grouped = this._getGrouped()
    return (
      <ModalPanel { ...this._getPanel() }>
        { profiles.length > 0 ?
          <div className="maha-profile-manager-profiles">
            { Object.keys(grouped).map((group, i) => (
              <div key={`group_${i}`}>
                { group !== 'default' &&
                  <div className="maha-profile-manager-header">
                    <T text={ group } />
                  </div>
                }
                { grouped[group].map((profile, index) => (
                  <div { ...this._getProfile(profile)} key={`profile_${index}`}>
                    <div className="maha-profile-manager-profile-label">
                      <ProfileToken profile={ profile } />
                    </div>
                    <div className="maha-profile-manager-profile-proceed">
                      <Icon icon="chevron-right" />
                    </div>
                  </div>
                )) }
              </div>
            )) }
          </div> :
          <Message { ...this._getEmpty() } />
        }
      </ModalPanel>
    )
  }

  _getEmpty() {
    return {
      icon: 'user-circle',
      title: 't(No Accounts)',
      text: 't(You have not connected any third party accounts)'
    }
  }

  _getGrouped() {
    const mapping = {
      contacts: 'contacts',
      emails: 'email',
      files: 'files',
      photos: 'files',
      signatures: 'signatures'
    }
    const { profiles, types } = this.props
    return profiles.reduce((grouped, profile) => ({
      ...grouped,
      ...!types && mapping[profile.type] ? {
        [mapping[profile.type]]: [
          ...grouped[mapping[profile.type]] || [],
          profile
        ]
      } : {
        default: [
          ...grouped.default || [],
          profile
        ]
      }
    }), {})
  }

  _getPanel() {
    const { onBack, onCancel, onConnect, onDone } = this.props
    return {
      color: 'darkblue',
      title: 't(Manage Accounts)',
      leftItems: onBack ? [
        { icon: 'chevron-left', handler: onBack }
      ] : [
        { label: 't(Cancel)', handler: onCancel }
      ],
      rightItems: [
        { label: 't(Done)', handler: onDone }
      ],
      buttons: [
        { label: 'Connect New Account', color: 'blue', handler: onConnect}
      ]
    }
  }

  _getProfile(profile) {
    return {
      className: 'maha-profile-manager-profile',
      onClick: this._handleEdit.bind(this, profile)
    }
  }

  _handleEdit(profile) {
    this.props.onEdit(profile)
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/profiles',
    refresh: `/accounts/${context.admin.account.id}/admin/profiles`,
    ...props.types ? {
      filter: {
        type: { $in: props.types }
      }
    } : {}
  }
})

export default Container(mapResources)(Profiles)
