import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import New from '@apps/automation/admin/views/workflows/new'
import Panel from '@admin/components/panel'
import React from 'react'

const triggers = ['maha:signup_created','maha:signup_configured','maha:team_activated']

const getNew = (props, context) => ({
  program_id: context.admin.programs[0].id,
  triggers
})

const mapPropsToPanel = (props, context) => ({
  title: 't(ty(Workflows))',
  collection: {
    endpoint: '/api/admin/automation/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Workflows)',
      text: 't(There are no workflows for this email campaign)',
      icon: 'sitemap',
      buttons: [
        { 
          label: 't(Create Workflow)',
          access: {
            rights: { $allOf: ['automation:access_app','automation:manage_workflows'] }
          },
          modal: <New { ...getNew(props, context) } /> 
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'display_name' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Enrolled)', key: 'enrolled_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    entity: 'worfklow',
    defaultFilter: { signup_page_id: { $eq: 'null' } },
    defaultQuery: { trigger_ids: [35,38,39] },
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/automation/workflows/${record.id}`)
  },
  task: {
    icon: 'plus',
    access: {
      rights: { $allOf: ['automation:access_app','automation:manage_workflows'] }
    },
    modal: <New { ...getNew(props, context) } />
  }
})

export default Panel(null, mapPropsToPanel)
