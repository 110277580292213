import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class HelpBadge extends React.Component {

  static contextTypes = {
    help: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  _handleClick = this._handleClick.bind(this)

  render() {
    return (
      <div className="maha-badge" onClick={ this._handleClick }>
        <Icon icon="question-circle-o" />
      </div>
    )
  }

  _handleClick() {
    if(document.body.clientWidth <= 768) {
      return this.context.router.push('/help')
    }
    this.context.help.toggle()
  }

}

export default HelpBadge
