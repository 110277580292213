const INITIAL_STATE = {
  account: null,
  advisor: null,
  agency: null,
  active: null,
  apps: [],
  ip: null,
  features: null,
  preferences: null,
  programs: null,
  redirect: null,
  rights: null,
  signin: null,
  teams: null,
  team: null,
  token: null,
  user: null
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CHOOSE_TEAM':
    return {
      ...state,
      active: action.id,
      redirect: action.redirect
    }

  case 'SET_ACCOUNT':
    return {
      ...state,
      account: action.account
    }

  case 'SET_ACTIVE':
    return {
      ...state,
      active: action.active
    }

  case 'SET_REDIRECT':
    return {
      ...state,
      redirect: action.redirect
    }

  case 'SET_SESSION':
    return {
      ...state,
      advisor: action.advisor,
      agency: action.agency,
      apps: action.apps,
      features: action.features,
      ip: action.ip,
      programs: action.programs,
      rights: action.rights,
      team: action.team,
      token: action.token,
      user: action.user
    }

  case 'SET_TEAMS':
    return {
      ...state,
      teams: action.teams
    }

  case 'SIGNIN':
    return {
      ...state,
      account_status: 'authenticated',
      account: action.account
    }

  case 'SIGNOUT':
    return INITIAL_STATE

  default:
    return state
  }

}
