const emoticons = [
  {
    regex: /3:\)/g,
    unicode: '1f608'
  }, {
    regex: /O:\)/g,
    unicode: '1f607'
  }, {
    regex: /:-?\)/g,
    unicode: '1f600'
  }, {
    regex: /:-?D/g,
    unicode: '1f603'
  }, {
    regex: />:\(/g,
    unicode: '1f620'
  }, {
    regex: /:-?\(/g,
    unicode: '1f641'
  }, {
    regex: /:'\(/g,
    unicode: '1f625'
  }, {
    regex: /:-?P/g,
    unicode: '1f61B'
  }, {
    regex: /o.O/g,
    unicode: '1f633'
  }, {
    regex: /;-?\)/g,
    unicode: '1f609'
  }, {
    regex: />:O/g,
    unicode: '1f621'
  }, {
    regex: /:-?O/g,
    unicode: '1f62e'
  }, {
    regex: /-_-/g,
    unicode: '1f611'
  }, {
    regex: /:-?\*/g,
    unicode: '1f618'
  }, {
    regex: /8-?\)/g,
    unicode: '1f913'
  }, {
    regex: /8-?\|/g,
    unicode: '1f60e'
  }, {
    regex: /:-?\/^(\/)/g,
    unicode: '1f615'
  }, {
    regex: /^_^/g,
    unicode: '263a-fe0f'
  }, {
    regex: /<3/g,
    unicode: '2764-fe0f'
  }, {
    regex: /\(n\)/g,
    unicode: '1f44e'
  }, {
    regex: /\(y\)/g,
    unicode: '1f44d'
  }, {
    regex: /\(\^\^\^\)/g,
    unicode: '1f988'
  }, {
    regex: /<\(''\)/g,
    unicode: '1f427'
  }, {
    regex: /:\|]/g,
    unicode: '1f916'
  }, {
    regex: /:poop:/g,
    unicode: '1f4a9'
  }
]

export default emoticons
