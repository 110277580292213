import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Opens)',
  access: {},
  collection: {
    endpoint: `/api/admin/automation/emails/${props.email.id}/opens`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => (
        <ContactToken { ...email.contact } property="rfc822" /> 
      ) },
      { label: 't(Opens)', key: 'opens_count', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope-open-o',
      title: 't(No Opens)',
      text: 't(There were no opens for this email)'
    },
    entity: 'email',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
