import SenderField from '@apps/crm/admin/components/senderfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class MahaSender extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    sender: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { formdata } = this.props
    const { sender } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(From Name)', name: 'name', type: 'textfield', required: true },
            ...formdata.type === 'hosted' ? [
              { label: 't(From Email)', name: 'email', type: SenderField, required: true, suffix: team.public_domain },
              { label: 't(On Behalf Of)',instructions: 't(Email clients like GMail will indicate that this email was sent "on behalf of" this email address)', type: 'segment', fields: [
                { name: 'on_behalf_of', type: 'emailfield' },
                { name: 'verify_on_behalf_of',  type: 'checkbox', prompt: 't(Send a verification email to verify this email address)', disabled: !(sender.on_behalf_of && sender.on_behalf_of.length > 0), defaultValue: true }
              ] },
              { label: 't(Reply To)', name: 'reply_to', instructions: `t(Even though emails from this sender will be sent from a ${team.domain} address, you can specify your personal email address to be used for replies)`, type: 'emailfield' }
            ] : [
              { label: 't(From Email)', type: 'segment', fields: [
                { name: 'email', type: 'emailfield' },
                { name: 'verify_email',  type: 'checkbox', prompt: 't(Send a verification email to verify this email address)', disabled: !(sender.email && sender.email.length > 0), defaultValue: true }
              ] }
            ]
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(sender) {
    this.setState({ sender })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(sender) {
    this.props.onSave(sender)
  }

}

export default MahaSender
