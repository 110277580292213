import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import * as actions from './actions'
import PropTypes from 'prop-types'
import Presence from './presence'
import reducer from './reducer'
import React from 'react'

export { PresenceContext, usePresenceContext } from './presence'

const PresenceContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'presence',
    actions,
    props,
    reducer
  })

  const { network } = useNetworkContext()

  const { admin } = useAdminContext()

  const mergedProps = {
    ...props,
    ...state,
    connected: network.connected,
    token: admin.token,
    ...handlers
  }

  return <Presence { ...mergedProps } />

}

PresenceContainer.propTypes = {}

export default PresenceContainer
