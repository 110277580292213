import Access from '@apps/team/admin/components/access'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Access)',
  panel: <Access rights={ props.role.rights } />
})

export default Panel(null, mapPropsToPanel)
