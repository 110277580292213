import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Profile from './profile'
import React from 'react'

class ProfileWrapper extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Profile, this._getProfile.bind(this))
  }

  _getProfile() {
    return {
      onCancel: this._handleCancel,
      onPop: this._handlePop,
      onPush: this._handlePush
    }
  }

  _getStack() {
    return {
      display_name: 'new_profile',
      ref: this.stackRef
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default ProfileWrapper
