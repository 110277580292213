import PropTypes from 'prop-types'
import Thread from './thread'
import Email from './email'
import React from 'react'
import List from './list'

class EmailReader extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    deal: PropTypes.object,
    defaultQuery: PropTypes.object,
    email: PropTypes.object,
    email_campaign: PropTypes.object,
    endpoint: PropTypes.string,
    order: PropTypes.object,
    perspective: PropTypes.string,
    program: PropTypes.object,
    provider: PropTypes.object,
    registration: PropTypes.object,
    response: PropTypes.object,
    subscription: PropTypes.object,
    usage: PropTypes.object,
    user: PropTypes.object
  }

  static defaultProps = {
    endpoint: '/api/admin/emails'
  }

  state = {
    selected: null
  }

  _handleSelect = this._handleSelect.bind(this)

  render() {
    const { selected } = this.state
    return (
      <div className="email-reader">
        <List { ...this._getList() } />
        <div className="email-reader-show">
          { document.body.clientWidth > 1024 && selected &&
            <>
              { selected.type === 'thread' ?
                <Thread { ...this._getShow() } /> :
                <Email { ...this._getShow() } />
              }
            </>
          }
        </div>
      </div>
    )
  }

  _getList() {
    const { contact, deal, defaultQuery, email, email_campaign, endpoint, order, program, perspective, registration, response, provider, subscription, usage, user } = this.props
    const { selected } = this.state
    return {
      contact,
      deal,
      defaultQuery,
      email,
      email_campaign,
      endpoint,
      order,
      perspective,
      program,
      provider,
      registration,
      response,
      selected,
      subscription,
      usage,
      user,
      onChoose: this._handleSelect
    }
  }

  _getShow() {
    const { selected } = this.state
    const code = selected.type === 'thread' ? selected.thread.code : selected.code
    return {
      key: code,
      code
    }
  }

  _handleSelect(selected) {
    this.setState({ selected })
    if(document.body.clientWidth > 1024) return
    if(selected.type === 'thread') {
      this.context.router.push(`/admin/email/threads/${selected.thread.code}`)
    } else {
      this.context.router.push(`/admin/emails/${selected.code}`)
    }
  }

}

export default EmailReader
