import useContainer from '@admin/hooks/use_container'

import analyticsAccessConfig from '@apps/analytics/config/access.js'
import automationAccessConfig from '@apps/automation/config/access.js'
import calendarAccessConfig from '@apps/calendar/config/access.js'
import campaignsAccessConfig from '@apps/campaigns/config/access.js'
import crmAccessConfig from '@apps/crm/config/access.js'
import dashboardsAccessConfig from '@apps/dashboards/config/access.js'
import datasetsAccessConfig from '@apps/datasets/config/access.js'
import domainsAccessConfig from '@apps/domains/config/access.js'
import driveAccessConfig from '@apps/drive/config/access.js'
import emailAccessConfig from '@apps/email/config/access.js'
import eventsAccessConfig from '@apps/events/config/access.js'
import financeAccessConfig from '@apps/finance/config/access.js'
import formsAccessConfig from '@apps/forms/config/access.js'
import mahaAccessConfig from '@apps/maha/config/access.js'
import phoneAccessConfig from '@apps/phone/config/access.js'
import platformAccessConfig from '@apps/platform/config/access.js'
import salesAccessConfig from '@apps/sales/config/access.js'
import sitesAccessConfig from '@apps/sites/config/access.js'
import storesAccessConfig from '@apps/stores/config/access.js'
import subscriptionsAccessConfig from '@apps/subscriptions/config/access.js'
import tasksAccessConfig from '@apps/tasks/config/access.js'
import teamAccessConfig from '@apps/team/config/access.js'
import truevailAccessConfig from '@apps/truevail/config/access.js'
import websitesAccessConfig from '@apps/websites/config/access.js'
import analyticsRoutes from '@apps/analytics/admin/views/index.js'
import automationRoutes from '@apps/automation/admin/views/index.js'
import calendarRoutes from '@apps/calendar/admin/views/index.js'
import campaignsRoutes from '@apps/campaigns/admin/views/index.js'
import crmRoutes from '@apps/crm/admin/views/index.js'
import dashboardsRoutes from '@apps/dashboards/admin/views/index.js'
import datasetsRoutes from '@apps/datasets/admin/views/index.js'
import domainsRoutes from '@apps/domains/admin/views/index.js'
import driveRoutes from '@apps/drive/admin/views/index.js'
import emailRoutes from '@apps/email/admin/views/index.js'
import eventsRoutes from '@apps/events/admin/views/index.js'
import financeRoutes from '@apps/finance/admin/views/index.js'
import formsRoutes from '@apps/forms/admin/views/index.js'
import mahaRoutes from '@apps/maha/admin/views/index.js'
import phoneRoutes from '@apps/phone/admin/views/index.js'
import platformRoutes from '@apps/platform/admin/views/index.js'
import salesRoutes from '@apps/sales/admin/views/index.js'
import sitesRoutes from '@apps/sites/admin/views/index.js'
import storesRoutes from '@apps/stores/admin/views/index.js'
import subscriptionsRoutes from '@apps/subscriptions/admin/views/index.js'
import tasksRoutes from '@apps/tasks/admin/views/index.js'
import teamRoutes from '@apps/team/admin/views/index.js'
import truevailRoutes from '@apps/truevail/admin/views/index.js'
import websitesRoutes from '@apps/websites/admin/views/index.js'
import calendarBadges from '@apps/calendar/admin/badges/index.js'
import crmBadges from '@apps/crm/admin/badges/index.js'
import emailBadges from '@apps/email/admin/badges/index.js'
import mahaBadges from '@apps/maha/admin/badges/index.js'
import phoneBadges from '@apps/phone/admin/badges/index.js'
import platformBadges from '@apps/platform/admin/badges/index.js'
import tasksBadges from '@apps/tasks/admin/badges/index.js'
import truevailBadges from '@apps/truevail/admin/badges/index.js'
import analyticsTriggers from '@apps/analytics/config/triggers.js'
import automationTriggers from '@apps/automation/config/triggers.js'
import calendarTriggers from '@apps/calendar/config/triggers.js'
import campaignsTriggers from '@apps/campaigns/config/triggers.js'
import crmTriggers from '@apps/crm/config/triggers.js'
import dashboardsTriggers from '@apps/dashboards/config/triggers.js'
import datasetsTriggers from '@apps/datasets/config/triggers.js'
import domainsTriggers from '@apps/domains/config/triggers.js'
import driveTriggers from '@apps/drive/config/triggers.js'
import emailTriggers from '@apps/email/config/triggers.js'
import eventsTriggers from '@apps/events/config/triggers.js'
import financeTriggers from '@apps/finance/config/triggers.js'
import formsTriggers from '@apps/forms/config/triggers.js'
import mahaTriggers from '@apps/maha/config/triggers.js'
import phoneTriggers from '@apps/phone/config/triggers.js'
import platformTriggers from '@apps/platform/config/triggers.js'
import salesTriggers from '@apps/sales/config/triggers.js'
import sitesTriggers from '@apps/sites/config/triggers.js'
import storesTriggers from '@apps/stores/config/triggers.js'
import subscriptionsTriggers from '@apps/subscriptions/config/triggers.js'
import tasksTriggers from '@apps/tasks/config/triggers.js'
import teamTriggers from '@apps/team/config/triggers.js'
import truevailTriggers from '@apps/truevail/config/triggers.js'
import websitesTriggers from '@apps/websites/config/triggers.js'
import mahaRoots from '@apps/maha/admin/roots/index.js'
import phoneRoots from '@apps/phone/admin/roots/index.js'
import truevailRoots from '@apps/truevail/admin/roots/index.js'
import financeUserTasks from '@apps/finance/admin/hooks/user_tasks.js'
import financeUserFields from '@apps/finance/admin/hooks/user_fields.js'
import financeUserValues from '@apps/finance/admin/hooks/user_values.js'
import automationActivityCards from '@apps/automation/admin/activities/index.js'
import campaignsActivityCards from '@apps/campaigns/admin/activities/index.js'
import crmActivityCards from '@apps/crm/admin/activities/index.js'
import eventsActivityCards from '@apps/events/admin/activities/index.js'
import formsActivityCards from '@apps/forms/admin/activities/index.js'
import phoneActivityCards from '@apps/phone/admin/activities/index.js'
import salesActivityCards from '@apps/sales/admin/activities/index.js'
import storesActivityCards from '@apps/stores/admin/activities/index.js'
import AnalyticsconfigcalendarJs from '@apps/analytics/config/calendar.js'
import AutomationconfigcalendarJs from '@apps/automation/config/calendar.js'
import CalendarconfigcalendarJs from '@apps/calendar/config/calendar.js'
import CampaignsconfigcalendarJs from '@apps/campaigns/config/calendar.js'
import CrmconfigcalendarJs from '@apps/crm/config/calendar.js'
import DashboardsconfigcalendarJs from '@apps/dashboards/config/calendar.js'
import DatasetsconfigcalendarJs from '@apps/datasets/config/calendar.js'
import DomainsconfigcalendarJs from '@apps/domains/config/calendar.js'
import DriveconfigcalendarJs from '@apps/drive/config/calendar.js'
import EmailconfigcalendarJs from '@apps/email/config/calendar.js'
import EventsconfigcalendarJs from '@apps/events/config/calendar.js'
import FinanceconfigcalendarJs from '@apps/finance/config/calendar.js'
import FormsconfigcalendarJs from '@apps/forms/config/calendar.js'
import MahaconfigcalendarJs from '@apps/maha/config/calendar.js'
import PhoneconfigcalendarJs from '@apps/phone/config/calendar.js'
import PlatformconfigcalendarJs from '@apps/platform/config/calendar.js'
import SalesconfigcalendarJs from '@apps/sales/config/calendar.js'
import SitesconfigcalendarJs from '@apps/sites/config/calendar.js'
import SubscriptionsconfigcalendarJs from '@apps/subscriptions/config/calendar.js'
import TasksconfigcalendarJs from '@apps/tasks/config/calendar.js'
import TeamconfigcalendarJs from '@apps/team/config/calendar.js'
import TruevailconfigcalendarJs from '@apps/truevail/config/calendar.js'
import WebsitesconfigcalendarJs from '@apps/websites/config/calendar.js'
import CampaignsintegrationscalendaremailCampaignsScheduledviewsindexJs from '@apps/campaigns/integrations/calendar/email_campaigns_scheduled/views/index.js'
import CampaignsintegrationscalendaremailCampaignsSentviewsindexJs from '@apps/campaigns/integrations/calendar/email_campaigns_sent/views/index.js'
import CampaignsintegrationscalendarsocialCampaignsPostedviewsindexJs from '@apps/campaigns/integrations/calendar/social_campaigns_posted/views/index.js'
import CampaignsintegrationscalendarsocialCampaignsScheduledviewsindexJs from '@apps/campaigns/integrations/calendar/social_campaigns_scheduled/views/index.js'
import TasksintegrationscalendarassignedByMeviewsindexJs from '@apps/tasks/integrations/calendar/assigned_by_me/views/index.js'
import TasksintegrationscalendarassignedToMeviewsindexJs from '@apps/tasks/integrations/calendar/assigned_to_me/views/index.js'
import TruevailintegrationscalendaremailCampaignsScheduledviewsindexJs from '@apps/truevail/integrations/calendar/email_campaigns_scheduled/views/index.js'
import TruevailintegrationscalendaremailCampaignsSentviewsindexJs from '@apps/truevail/integrations/calendar/email_campaigns_sent/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignBounceRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_bounce_rate/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignClickRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_click_rate/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignComplainRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_complain_rate/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignDeliveryRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_delivery_rate/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignOpenRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_open_rate/views/index.js'
import CampaignsintegrationsdashboardsemailCampaignUnsubscribeRateviewsindexJs from '@apps/campaigns/integrations/dashboards/email_campaign_unsubscribe_rate/views/index.js'
import CrmintegrationsdashboardsnewContactsviewsindexJs from '@apps/crm/integrations/dashboards/new_contacts/views/index.js'
import CrmintegrationsdashboardsprogramRevenueviewsindexJs from '@apps/crm/integrations/dashboards/program_revenue/views/index.js'
import CrmintegrationsdashboardssubscriptionsviewsindexJs from '@apps/crm/integrations/dashboards/subscriptions/views/index.js'
import EventsintegrationsdashboardsregistrationsviewsindexJs from '@apps/events/integrations/dashboards/registrations/views/index.js'
import EventsintegrationsdashboardsrevenueviewsindexJs from '@apps/events/integrations/dashboards/revenue/views/index.js'
import EventsintegrationsdashboardsticketsviewsindexJs from '@apps/events/integrations/dashboards/tickets/views/index.js'
import FormsintegrationsdashboardsresponsesviewsindexJs from '@apps/forms/integrations/dashboards/responses/views/index.js'
import FormsintegrationsdashboardsrevenueviewsindexJs from '@apps/forms/integrations/dashboards/revenue/views/index.js'
import MahaintegrationsdashboardsgreetingviewsindexJs from '@apps/maha/integrations/dashboards/greeting/views/index.js'
import StoresintegrationsdashboardsordersviewsindexJs from '@apps/stores/integrations/dashboards/orders/views/index.js'
import StoresintegrationsdashboardsrevenueviewsindexJs from '@apps/stores/integrations/dashboards/revenue/views/index.js'
import SubscriptionsintegrationsdashboardsactiveSubscriptionsviewsindexJs from '@apps/subscriptions/integrations/dashboards/active_subscriptions/views/index.js'
import SubscriptionsintegrationsdashboardsannualRecurringRevenueviewsindexJs from '@apps/subscriptions/integrations/dashboards/annual_recurring_revenue/views/index.js'
import SubscriptionsintegrationsdashboardsmonthlyRecurringRevenueviewsindexJs from '@apps/subscriptions/integrations/dashboards/monthly_recurring_revenue/views/index.js'
import SubscriptionsintegrationsdashboardstrialSubscriptionsviewsindexJs from '@apps/subscriptions/integrations/dashboards/trial_subscriptions/views/index.js'
import TruevailintegrationsdashboardsaggregateSocialEngagementviewsindexJs from '@apps/truevail/integrations/dashboards/aggregate_social_engagement/views/index.js'
import TruevailintegrationsdashboardsrenewalPaymentsviewsindexJs from '@apps/truevail/integrations/dashboards/renewal_payments/views/index.js'
import TruevailintegrationsdashboardssignupsviewsindexJs from '@apps/truevail/integrations/dashboards/signups/views/index.js'
import crmSettings from '@apps/crm/admin/hooks/settings.js'
import financeSettings from '@apps/finance/admin/hooks/settings.js'
import teamSettings from '@apps/team/admin/hooks/settings.js'
import LocalStorage from '@admin/components/local_storage'
import Forbidden from '@apps/maha/admin/views/forbidden'
import NotFound from '@apps/maha/admin/views/not_found'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Analytics from '@admin/components/analytics'
import Locale from '@admin/components/locale'
import Provider from '@admin/components/provider'
import Platform from '@admin/components/platform'
import Revision from '@admin/components/revision'
import Network from '@admin/components/network'
import Logger from '@admin/components/logger'
import Device from '@admin/components/device'
import Flash from '@admin/components/flash'
import Admin from '@admin/components/admin'
import Host from '@admin/components/host'
import Maha from '@admin/components/maha'
import { DndProvider } from 'react-dnd'
import { hot } from 'react-hot-loader'
import PropTypes from 'prop-types'
import React from 'react'
import './index.scss'

class App extends React.Component {

  render() {
    return (
      <Platform>
        <DndProvider backend={ this._getBackend() }>
          <Logger>
            <Maha { ...this._getMaha() }>
              <Network>
                <LocalStorage>
                  <Locale>
                    <Provider>
                      <Device>
                        <Revision>
                          <Analytics>
                            <Host>
                              <Flash>
                                <Admin />
                              </Flash>
                            </Host>
                          </Analytics>
                        </Revision>
                      </Device>
                    </Provider>
                  </Locale>
                </LocalStorage>
              </Network>
            </Maha>
          </Logger>
        </DndProvider>
      </Platform>
    )
  }

  _getAccess() {
    return [
      { title: 'Analytics',  code: 'analytics', ...analyticsAccessConfig },
      { title: 'Automation',  code: 'automation', ...automationAccessConfig },
      { title: 'Calendar',  code: 'calendar', ...calendarAccessConfig },
      { title: 'Campaigns',  code: 'campaigns', ...campaignsAccessConfig },
      { title: 'CRM',  code: 'crm', ...crmAccessConfig },
      { title: 'Dashboards',  code: 'dashboards', ...dashboardsAccessConfig },
      { title: 'Datasets',  code: 'datasets', ...datasetsAccessConfig },
      { title: 'Domains',  code: 'domains', ...domainsAccessConfig },
      { title: 'Drive',  code: 'drive', ...driveAccessConfig },
      { title: 'Email',  code: 'email', ...emailAccessConfig },
      { title: 'Events',  code: 'events', ...eventsAccessConfig },
      { title: 'Finance',  code: 'finance', ...financeAccessConfig },
      { title: 'Forms',  code: 'forms', ...formsAccessConfig },
      { title: 'Maha',  code: 'maha', ...mahaAccessConfig },
      { title: 'Phone',  code: 'phone', ...phoneAccessConfig },
      { title: 'Platform',  code: 'platform', ...platformAccessConfig },
      { title: 'Sales',  code: 'sales', ...salesAccessConfig },
      { title: 'Sites',  code: 'sites', ...sitesAccessConfig },
      { title: 'Stores',  code: 'stores', ...storesAccessConfig },
      { title: 'Subscriptions',  code: 'subscriptions', ...subscriptionsAccessConfig },
      { title: 'Tasks',  code: 'tasks', ...tasksAccessConfig },
      { title: 'Team',  code: 'team', ...teamAccessConfig },
      { title: 'Truevail',  code: 'truevail', ...truevailAccessConfig },
      { title: 'Websites',  code: 'websites', ...websitesAccessConfig },
    ]
  }

  _getActivityCards() {
    return {
      ...automationActivityCards,
      ...campaignsActivityCards,
      ...crmActivityCards,
      ...eventsActivityCards,
      ...formsActivityCards,
      ...phoneActivityCards,
      ...salesActivityCards,
      ...storesActivityCards,
    }
  }

  _getAppUserFields() {
    return [
      { app: 'finance', fields: financeUserFields },
    ]
  }

  _getAppUserTasks() {
    return [
      financeUserTasks,
    ]
  }

  _getAppUserValues() {
    return [
      { app: 'finance', sections: financeUserValues },
    ]
  }

  _getBackend() {
    if(typeof window !== undefined ) return HTML5Backend
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    return isTouchDevice ? TouchBackend : HTML5Backend
  }

  _getBadges() {
    return [
      ...calendarBadges.map(badge => ({
        app: 'calendar',
        ...badge
      })),
      ...crmBadges.map(badge => ({
        app: 'crm',
        ...badge
      })),
      ...emailBadges.map(badge => ({
        app: 'email',
        ...badge
      })),
      ...mahaBadges.map(badge => ({
        app: 'maha',
        ...badge
      })),
      ...phoneBadges.map(badge => ({
        app: 'phone',
        ...badge
      })),
      ...platformBadges.map(badge => ({
        app: 'platform',
        ...badge
      })),
      ...tasksBadges.map(badge => ({
        app: 'tasks',
        ...badge
      })),
      ...truevailBadges.map(badge => ({
        app: 'truevail',
        ...badge
      })),
    ]
  }

  _getCalendarTypes() {
    return [
      ...AnalyticsconfigcalendarJs.map(calendar => ({
        app: 'analytics',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...AutomationconfigcalendarJs.map(calendar => ({
        app: 'automation',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...CalendarconfigcalendarJs.map(calendar => ({
        app: 'calendar',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...CampaignsconfigcalendarJs.map(calendar => ({
        app: 'campaigns',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...CrmconfigcalendarJs.map(calendar => ({
        app: 'crm',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...DashboardsconfigcalendarJs.map(calendar => ({
        app: 'dashboards',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...DatasetsconfigcalendarJs.map(calendar => ({
        app: 'datasets',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...DomainsconfigcalendarJs.map(calendar => ({
        app: 'domains',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...DriveconfigcalendarJs.map(calendar => ({
        app: 'drive',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...EmailconfigcalendarJs.map(calendar => ({
        app: 'email',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...EventsconfigcalendarJs.map(calendar => ({
        app: 'events',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...FinanceconfigcalendarJs.map(calendar => ({
        app: 'finance',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...FormsconfigcalendarJs.map(calendar => ({
        app: 'forms',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...MahaconfigcalendarJs.map(calendar => ({
        app: 'maha',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...PhoneconfigcalendarJs.map(calendar => ({
        app: 'phone',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...PlatformconfigcalendarJs.map(calendar => ({
        app: 'platform',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...SalesconfigcalendarJs.map(calendar => ({
        app: 'sales',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...SitesconfigcalendarJs.map(calendar => ({
        app: 'sites',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...SubscriptionsconfigcalendarJs.map(calendar => ({
        app: 'subscriptions',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...TasksconfigcalendarJs.map(calendar => ({
        app: 'tasks',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...TeamconfigcalendarJs.map(calendar => ({
        app: 'team',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...TruevailconfigcalendarJs.map(calendar => ({
        app: 'truevail',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
      ...WebsitesconfigcalendarJs.map(calendar => ({
        app: 'websites',
        code: calendar.code,
        entity: calendar.entity,
        action: calendar.action,
        route: calendar.route
      })),
    ]
  }

  _getDashboardCards() {
    return [
      {
        ...CampaignsintegrationsdashboardsemailCampaignBounceRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignBounceRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignBounceRateviewsindexJs.code
      },
      {
        ...CampaignsintegrationsdashboardsemailCampaignClickRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignClickRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignClickRateviewsindexJs.code
      },
      {
        ...CampaignsintegrationsdashboardsemailCampaignComplainRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignComplainRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignComplainRateviewsindexJs.code
      },
      {
        ...CampaignsintegrationsdashboardsemailCampaignDeliveryRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignDeliveryRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignDeliveryRateviewsindexJs.code
      },
      {
        ...CampaignsintegrationsdashboardsemailCampaignOpenRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignOpenRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignOpenRateviewsindexJs.code
      },
      {
        ...CampaignsintegrationsdashboardsemailCampaignUnsubscribeRateviewsindexJs,
        app: 'campaigns',
        type: CampaignsintegrationsdashboardsemailCampaignUnsubscribeRateviewsindexJs.code,
        code: 'campaigns:'+CampaignsintegrationsdashboardsemailCampaignUnsubscribeRateviewsindexJs.code
      },
      {
        ...CrmintegrationsdashboardsnewContactsviewsindexJs,
        app: 'crm',
        type: CrmintegrationsdashboardsnewContactsviewsindexJs.code,
        code: 'crm:'+CrmintegrationsdashboardsnewContactsviewsindexJs.code
      },
      {
        ...CrmintegrationsdashboardsprogramRevenueviewsindexJs,
        app: 'crm',
        type: CrmintegrationsdashboardsprogramRevenueviewsindexJs.code,
        code: 'crm:'+CrmintegrationsdashboardsprogramRevenueviewsindexJs.code
      },
      {
        ...CrmintegrationsdashboardssubscriptionsviewsindexJs,
        app: 'crm',
        type: CrmintegrationsdashboardssubscriptionsviewsindexJs.code,
        code: 'crm:'+CrmintegrationsdashboardssubscriptionsviewsindexJs.code
      },
      {
        ...EventsintegrationsdashboardsregistrationsviewsindexJs,
        app: 'events',
        type: EventsintegrationsdashboardsregistrationsviewsindexJs.code,
        code: 'events:'+EventsintegrationsdashboardsregistrationsviewsindexJs.code
      },
      {
        ...EventsintegrationsdashboardsrevenueviewsindexJs,
        app: 'events',
        type: EventsintegrationsdashboardsrevenueviewsindexJs.code,
        code: 'events:'+EventsintegrationsdashboardsrevenueviewsindexJs.code
      },
      {
        ...EventsintegrationsdashboardsticketsviewsindexJs,
        app: 'events',
        type: EventsintegrationsdashboardsticketsviewsindexJs.code,
        code: 'events:'+EventsintegrationsdashboardsticketsviewsindexJs.code
      },
      {
        ...FormsintegrationsdashboardsresponsesviewsindexJs,
        app: 'forms',
        type: FormsintegrationsdashboardsresponsesviewsindexJs.code,
        code: 'forms:'+FormsintegrationsdashboardsresponsesviewsindexJs.code
      },
      {
        ...FormsintegrationsdashboardsrevenueviewsindexJs,
        app: 'forms',
        type: FormsintegrationsdashboardsrevenueviewsindexJs.code,
        code: 'forms:'+FormsintegrationsdashboardsrevenueviewsindexJs.code
      },
      {
        ...MahaintegrationsdashboardsgreetingviewsindexJs,
        app: 'maha',
        type: MahaintegrationsdashboardsgreetingviewsindexJs.code,
        code: 'maha:'+MahaintegrationsdashboardsgreetingviewsindexJs.code
      },
      {
        ...StoresintegrationsdashboardsordersviewsindexJs,
        app: 'stores',
        type: StoresintegrationsdashboardsordersviewsindexJs.code,
        code: 'stores:'+StoresintegrationsdashboardsordersviewsindexJs.code
      },
      {
        ...StoresintegrationsdashboardsrevenueviewsindexJs,
        app: 'stores',
        type: StoresintegrationsdashboardsrevenueviewsindexJs.code,
        code: 'stores:'+StoresintegrationsdashboardsrevenueviewsindexJs.code
      },
      {
        ...SubscriptionsintegrationsdashboardsactiveSubscriptionsviewsindexJs,
        app: 'subscriptions',
        type: SubscriptionsintegrationsdashboardsactiveSubscriptionsviewsindexJs.code,
        code: 'subscriptions:'+SubscriptionsintegrationsdashboardsactiveSubscriptionsviewsindexJs.code
      },
      {
        ...SubscriptionsintegrationsdashboardsannualRecurringRevenueviewsindexJs,
        app: 'subscriptions',
        type: SubscriptionsintegrationsdashboardsannualRecurringRevenueviewsindexJs.code,
        code: 'subscriptions:'+SubscriptionsintegrationsdashboardsannualRecurringRevenueviewsindexJs.code
      },
      {
        ...SubscriptionsintegrationsdashboardsmonthlyRecurringRevenueviewsindexJs,
        app: 'subscriptions',
        type: SubscriptionsintegrationsdashboardsmonthlyRecurringRevenueviewsindexJs.code,
        code: 'subscriptions:'+SubscriptionsintegrationsdashboardsmonthlyRecurringRevenueviewsindexJs.code
      },
      {
        ...SubscriptionsintegrationsdashboardstrialSubscriptionsviewsindexJs,
        app: 'subscriptions',
        type: SubscriptionsintegrationsdashboardstrialSubscriptionsviewsindexJs.code,
        code: 'subscriptions:'+SubscriptionsintegrationsdashboardstrialSubscriptionsviewsindexJs.code
      },
      {
        ...TruevailintegrationsdashboardsaggregateSocialEngagementviewsindexJs,
        app: 'truevail',
        type: TruevailintegrationsdashboardsaggregateSocialEngagementviewsindexJs.code,
        code: 'truevail:'+TruevailintegrationsdashboardsaggregateSocialEngagementviewsindexJs.code
      },
      {
        ...TruevailintegrationsdashboardsrenewalPaymentsviewsindexJs,
        app: 'truevail',
        type: TruevailintegrationsdashboardsrenewalPaymentsviewsindexJs.code,
        code: 'truevail:'+TruevailintegrationsdashboardsrenewalPaymentsviewsindexJs.code
      },
      {
        ...TruevailintegrationsdashboardssignupsviewsindexJs,
        app: 'truevail',
        type: TruevailintegrationsdashboardssignupsviewsindexJs.code,
        code: 'truevail:'+TruevailintegrationsdashboardssignupsviewsindexJs.code
      },
    ]
  }

  _getMaha() {
    return {
      access: this._getAccess(),
      activityCards: this._getActivityCards(),
      appUserTasks: this._getAppUserTasks(),
      appUserFields: this._getAppUserFields(),
      appUserValues: this._getAppUserValues(),
      badges: this._getBadges(),
      calendarTypes: this._getCalendarTypes(),
      dashboardCards: this._getDashboardCards(),
      roots: this._getRoots(),
      routes: this._getRoutes(),
      settings: this._getSettings(),
      triggers: this._getTriggers()  
    }
  }

  _getRoots() {
    return [
      ...mahaRoots.map(root => ({
        app: 'maha',
        component: root
      })),
      ...phoneRoots.map(root => ({
        app: 'phone',
        component: root
      })),
      ...truevailRoots.map(root => ({
        app: 'truevail',
        component: root
      })),
    ]
  }

  _getRoutes() {
    return [
      { path: '/:team/analytics', children: analyticsRoutes },
      { path: '/:team/automation', children: automationRoutes },
      { path: '/:team/calendar', children: calendarRoutes },
      { path: '/:team/campaigns', children: campaignsRoutes },
      { path: '/:team/crm', children: crmRoutes },
      { path: '/:team/dashboards', children: dashboardsRoutes },
      { path: '/:team/datasets', children: datasetsRoutes },
      { path: '/:team/domains', children: domainsRoutes },
      { path: '/:team/drive', children: driveRoutes },
      { path: '/:team/email', children: emailRoutes },
      { path: '/:team/events', children: eventsRoutes },
      { path: '/:team/finance', children: financeRoutes },
      { path: '/:team/expenses', children: financeRoutes },
      { path: '/:team/forms', children: formsRoutes },
      { path: '/:team', children: mahaRoutes },
      { path: '/:team/phone', children: phoneRoutes },
      { path: '/:team/platform', children: platformRoutes },
      { path: '/:team/sales', children: salesRoutes },
      { path: '/:team/sites', children: sitesRoutes },
      { path: '/:team/stores', children: storesRoutes },
      { path: '/:team/subscriptions', children: subscriptionsRoutes },
      { path: '/:team/tasks', children: tasksRoutes },
      { path: '/:team/team', children: teamRoutes },
      { path: '/:team/truevail', children: truevailRoutes },
      { path: '/:team/websites', children: websitesRoutes },
      { path: '/:team/calendar/campaigns/email_campaigns_scheduled', component: CampaignsintegrationscalendaremailCampaignsScheduledviewsindexJs.report },
      { path: '/:team/calendar/campaigns/email_campaigns_sent', component: CampaignsintegrationscalendaremailCampaignsSentviewsindexJs.report },
      { path: '/:team/calendar/campaigns/social_campaigns_posted', component: CampaignsintegrationscalendarsocialCampaignsPostedviewsindexJs.report },
      { path: '/:team/calendar/campaigns/social_campaigns_scheduled', component: CampaignsintegrationscalendarsocialCampaignsScheduledviewsindexJs.report },
      { path: '/:team/calendar/tasks/assigned_by_me', component: TasksintegrationscalendarassignedByMeviewsindexJs.report },
      { path: '/:team/calendar/tasks/assigned_to_me', component: TasksintegrationscalendarassignedToMeviewsindexJs.report },
      { path: '/:team/calendar/truevail/email_campaigns_scheduled', component: TruevailintegrationscalendaremailCampaignsScheduledviewsindexJs.report },
      { path: '/:team/calendar/truevail/email_campaigns_sent', component: TruevailintegrationscalendaremailCampaignsSentviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_bounce_rate', component: CampaignsintegrationsdashboardsemailCampaignBounceRateviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_click_rate', component: CampaignsintegrationsdashboardsemailCampaignClickRateviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_complain_rate', component: CampaignsintegrationsdashboardsemailCampaignComplainRateviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_delivery_rate', component: CampaignsintegrationsdashboardsemailCampaignDeliveryRateviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_open_rate', component: CampaignsintegrationsdashboardsemailCampaignOpenRateviewsindexJs.report },
      { path: '/:team/dashboards/campaigns/email_campaign_unsubscribe_rate', component: CampaignsintegrationsdashboardsemailCampaignUnsubscribeRateviewsindexJs.report },
      { path: '/:team/dashboards/crm/new_contacts', component: CrmintegrationsdashboardsnewContactsviewsindexJs.report },
      { path: '/:team/dashboards/crm/program_revenue', component: CrmintegrationsdashboardsprogramRevenueviewsindexJs.report },
      { path: '/:team/dashboards/crm/subscriptions', component: CrmintegrationsdashboardssubscriptionsviewsindexJs.report },
      { path: '/:team/dashboards/events/registrations', component: EventsintegrationsdashboardsregistrationsviewsindexJs.report },
      { path: '/:team/dashboards/events/revenue', component: EventsintegrationsdashboardsrevenueviewsindexJs.report },
      { path: '/:team/dashboards/events/tickets', component: EventsintegrationsdashboardsticketsviewsindexJs.report },
      { path: '/:team/dashboards/forms/responses', component: FormsintegrationsdashboardsresponsesviewsindexJs.report },
      { path: '/:team/dashboards/forms/revenue', component: FormsintegrationsdashboardsrevenueviewsindexJs.report },
      { path: '/:team/dashboards/greeting', component: MahaintegrationsdashboardsgreetingviewsindexJs.report },
      { path: '/:team/dashboards/stores/orders', component: StoresintegrationsdashboardsordersviewsindexJs.report },
      { path: '/:team/dashboards/stores/revenue', component: StoresintegrationsdashboardsrevenueviewsindexJs.report },
      { path: '/:team/dashboards/subscriptions/active_subscriptions', component: SubscriptionsintegrationsdashboardsactiveSubscriptionsviewsindexJs.report },
      { path: '/:team/dashboards/subscriptions/annual_recurring_revenue', component: SubscriptionsintegrationsdashboardsannualRecurringRevenueviewsindexJs.report },
      { path: '/:team/dashboards/subscriptions/monthly_recurring_revenue', component: SubscriptionsintegrationsdashboardsmonthlyRecurringRevenueviewsindexJs.report },
      { path: '/:team/dashboards/subscriptions/trial_subscriptions', component: SubscriptionsintegrationsdashboardstrialSubscriptionsviewsindexJs.report },
      { path: '/:team/dashboards/truevail/aggregate_social_engagement', component: TruevailintegrationsdashboardsaggregateSocialEngagementviewsindexJs.report },
      { path: '/:team/dashboards/truevail/renewal_payments', component: TruevailintegrationsdashboardsrenewalPaymentsviewsindexJs.report },
      { path: '/:team/dashboards/truevail/signups', component: TruevailintegrationsdashboardssignupsviewsindexJs.report },
      { path: '/forbidden', component: Forbidden },
      { path: '/*', component: NotFound }
    ]
  }

  _getSettings() {
    return {
      crm: {
        code: 'crm',
        title: 'CRM',
        icon: 'user',
        fields: crmSettings
      },
      finance: {
        code: 'finance',
        title: 'Finance',
        icon: 'dollar',
        fields: financeSettings
      },
      team: {
        code: 'team',
        title: 'Team',
        icon: 'users',
        fields: teamSettings
      },
    }
  }

  _getTriggers() {
    return [
      ...analyticsTriggers.map(trigger => ({
        app: 'analytics',
        appTitle: 'Analytics',
        icon: 'line-chart',
        ...trigger
      })),
      ...automationTriggers.map(trigger => ({
        app: 'automation',
        appTitle: 'Automation',
        icon: 'gears',
        ...trigger
      })),
      ...calendarTriggers.map(trigger => ({
        app: 'calendar',
        appTitle: 'Calendar',
        icon: 'calendar',
        ...trigger
      })),
      ...campaignsTriggers.map(trigger => ({
        app: 'campaigns',
        appTitle: 'Campaigns',
        icon: 'bullhorn',
        ...trigger
      })),
      ...crmTriggers.map(trigger => ({
        app: 'crm',
        appTitle: 'CRM',
        icon: 'user',
        ...trigger
      })),
      ...dashboardsTriggers.map(trigger => ({
        app: 'dashboards',
        appTitle: 'Dashboards',
        icon: 'table',
        ...trigger
      })),
      ...datasetsTriggers.map(trigger => ({
        app: 'datasets',
        appTitle: 'Datasets',
        icon: 'database',
        ...trigger
      })),
      ...domainsTriggers.map(trigger => ({
        app: 'domains',
        appTitle: 'Domains',
        icon: 'globe',
        ...trigger
      })),
      ...driveTriggers.map(trigger => ({
        app: 'drive',
        appTitle: 'Drive',
        icon: 'hdd-o',
        ...trigger
      })),
      ...emailTriggers.map(trigger => ({
        app: 'email',
        appTitle: 'Email',
        icon: 'envelope',
        ...trigger
      })),
      ...eventsTriggers.map(trigger => ({
        app: 'events',
        appTitle: 'Events',
        icon: 'calendar-o',
        ...trigger
      })),
      ...financeTriggers.map(trigger => ({
        app: 'finance',
        appTitle: 'Finance',
        icon: 'dollar',
        ...trigger
      })),
      ...formsTriggers.map(trigger => ({
        app: 'forms',
        appTitle: 'Forms',
        icon: 'check-square-o',
        ...trigger
      })),
      ...mahaTriggers.map(trigger => ({
        app: 'maha',
        appTitle: 'Maha',
        icon: 'bars',
        ...trigger
      })),
      ...phoneTriggers.map(trigger => ({
        app: 'phone',
        appTitle: 'Phone',
        icon: 'phone',
        ...trigger
      })),
      ...platformTriggers.map(trigger => ({
        app: 'platform',
        appTitle: 'Platform',
        icon: 'cubes',
        ...trigger
      })),
      ...salesTriggers.map(trigger => ({
        app: 'sales',
        appTitle: 'Sales',
        icon: 'handshake-o',
        ...trigger
      })),
      ...sitesTriggers.map(trigger => ({
        app: 'sites',
        appTitle: 'Sites',
        icon: 'globe',
        ...trigger
      })),
      ...storesTriggers.map(trigger => ({
        app: 'stores',
        appTitle: 'Stores',
        icon: 'shopping-bag',
        ...trigger
      })),
      ...subscriptionsTriggers.map(trigger => ({
        app: 'subscriptions',
        appTitle: 'Subscriptions',
        icon: 'refresh',
        ...trigger
      })),
      ...tasksTriggers.map(trigger => ({
        app: 'tasks',
        appTitle: 'Tasks',
        icon: 'check',
        ...trigger
      })),
      ...teamTriggers.map(trigger => ({
        app: 'team',
        appTitle: 'Team',
        icon: 'users',
        ...trigger
      })),
      ...truevailTriggers.map(trigger => ({
        app: 'truevail',
        appTitle: 'Truevail',
        icon: 'plane',
        ...trigger
      })),
      ...websitesTriggers.map(trigger => ({
        app: 'websites',
        appTitle: 'Websites',
        icon: 'mouse-pointer',
        ...trigger
      })),
    ]
  }

}

export default hot(module)(App)
