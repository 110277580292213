import SocialFollowToken from '@admin/components/mjson_designer/tokens/social_follow'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class FollowField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    social: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.social) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { social } = this.state
    if(!_.isEqual(social, prevState.social)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { social } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'networks', type: 'collectionfield', fields: [
              { label: 't(Service)', name: 'service', type: 'dropdown', options: [
                { value: 'email', text: 't(Email)' },
                { value: 'facebook', text: 'Facebook' },
                { value: 'github', text: 'GitHub' },
                { value: 'instagram', text: 'Instagram' },
                { value: 'linkedin', text: 'LinkedIn' },
                { value: 'medium', text: 'Medium' },
                { value: 'phone', text: 't(Phone)' },
                { value: 'messenger', text: 'Messenger' },
                { value: 'pinterest', text: 'Pinterest' },
                { value: 'podcast', text: 't(Podcast)' },
                { value: 'snapchat', text: 'SnapChat' },
                { value: 'tiktok', text: 'TikTok' },
                { value: 'threads', text: 'Threads' },
                { value: 'twitter', text: 'Twitter' },
                { value: 'website', text: 'Website' },
                { value: 'whatsapp', text: 'WhatsApp' },
                { value: 'youtube', text: 'YouTube' },
                { value: 'x', text: 'X' }
              ], required: true, cloneable: false },
              { label: 't(URL)', name: 'url', type: 'urlfield', required: true }
            ],
            orderable: true, cloneable: false, token: SocialFollowToken, value: social.networks }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { social } = this.state
    this.props.onChange(social)
  }

  _handleSet(social) {
    this.setState({
      social: {
        networks: [],
        ...social || {}
      }
    })
  }

  _handleUpdate(social) {
    this.setState({ social })
  }

}

export default FollowField
