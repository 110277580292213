import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Post extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    post_at: PropTypes.string
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    const { social_campaign } = props
    const post_at = props.post_at || social_campaign.post_at || moment().add(1, 'day').format('YYYY-MM-DD [09:00]')
    this.state = {
      config: {
        date: moment(post_at).format('YYYY-MM-DD'),
        strategy: props.post_at || social_campaign.post_at ? 'schedule' : 'now',
        time: moment(post_at).format('HH:mm')
      }
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    const { social_campaign } = this.props
    return {
      title: 't(Post Social Campaign)',
      method: 'PATCH',
      action: `/api/admin/campaigns/social/${social_campaign.id}/schedule`,
      saveText: config.strategy === 'now' ? 't(Post)' : 't(Schedule)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Send At)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'now', text: 't(Post immediately)' },
                { value: 'schedule', text: 't(Schedule Post)' }
              ], defaultValue: config.strategy, required: true },
              { name: 'post_at', type: 'datetimefield', show: config.strategy === 'schedule', required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Post
