import Page from '@admin/components/page'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Datasets)',
  collection: {
    endpoint: '/api/admin/datasets/datasets',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title.value', primary: true }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'cube',
      title: 't(No Datasets)',
      text: 't(You have not yet created any dataset)',
      buttons: [
        { label: 'Create Dataset', modal: New }
      ]
    },
    entity: 'site',
    onClick: (record) => context.router.push(`/datasets/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: 'Create Dataset', modal: New }
    ]
  }
})

export default Page(null, mapPropsToPage)
