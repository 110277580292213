import PropTypes from 'prop-types'
import React from 'react'

class Timezone extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    confirm: PropTypes.object,
    flash: PropTypes.object,
    local_storage: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    revision: PropTypes.string
  }

  _handleChange = this._handleChange.bind(this)
  _handleIgnore = this._handleIgnore.bind(this)
  _handleInit = this._handleInit.bind(this)
  _handlePrompt = this._handlePrompt.bind(this)

  render() {
    return this.props.children
  }

  componentDidMount() {
    setTimeout(this._handleInit, 2000)
  }

  _handleChange() {
    const { flash } = this.context
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.context.network.request({
      endpoint: '/api/admin/account/timezone',
      method: 'PATCH',
      body: { zone },
      onFailure: () => flash.set('error', 't(Unable to update your timezone)'),
      onSuccess: () => {
        flash.set('success', 't(Successfully updated your timezone)')
        this._handleForget()
      }
    })
  }

  _handleIgnore() {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.context.local_storage.set({
      key: 'timezone',
      value: zone
    })        
  }

  _handleInit() {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.context.local_storage.get({
      key: 'timezone',
      onFailure: this._handlePrompt,
      onSuccess: (value) => {
        if(value === zone) return
        this._handlePrompt()
      }
    })
  }

  _handlePrompt() {
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { admin } = this.context
    if(admin.account.timezone === zone) return
    this.context.confirm.open('t(Your browser\'s timezone does not match your account. Would you like to update your account timezone?)', this._handleChange, this._handleIgnore)
  }

  _handleForget() {
    this.context.local_storage.remove({
      key: 'timezone'
    })
  }

}

export default Timezone
