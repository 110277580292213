import { CSSTransition } from 'react-transition-group'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class CopyText extends React.Component {

  static propTypes = {
    text: PropTypes.string,
    content: PropTypes.string
  }

  state = {
    open: false
  }

  textRef = React.createRef()

  _handleCopy = this._handleCopy.bind(this)
  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { open } = this.state
    const { text } = this.props
    return (
      <div className="maha-copytext">
        <div className="maha-copytext-icon">
          <CSSTransition in={ open } classNames="fade-in-full" enter={ false } timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
            <div className="maha-copytext-message">
              <div className="maha-copytext-message-inner">
                Copied to clipboard
              </div>
            </div>
          </CSSTransition>
          <Icon icon="clone" onClick={ this._handleCopy } />
        </div>
        <div className="maha-copytext-text" ref={ this.textRef } onClick={ this._handleCopy }>
          <T text={ text } />
        </div>
      </div>
    )
  }

  _handleCopy() {
    const { content } = this.props
    if(content) {
      const textarea = document.createElement('textarea')
      textarea.value = content
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    } else if(document.body.createTextRange) {
      const range = document.body.createTextRange()
      range.moveToElementText(this.textRef.current)
      range.select()
      document.execCommand('copy')
    } else if(window.getSelection) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(this.textRef.current)
      selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
    }
    this._handleToggle()
  }

  _handleToggle() {
    const { open } = this.state
    this.setState({
      open: !open
    })
    if(open) return
    setTimeout(this._handleToggle, 1500)
  }

}

export default CopyText
