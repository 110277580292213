import Workflows from './automation/workflows'
import Page from '@admin/components/page'
import T from '@admin/components/t'
import Emails from './automation/emails'
import Performance from './performance'
import Activities from './activities'
import Deliveries from './deliveries'
import Complaints from './complaints'
import Overview from './overview'
import Bounces from './bounces'
import Unopens from './unopens'
import Design from './design'
import Links from './links'
import Opens from './opens'
import Spam from './spam'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.email.title,
  manager: {
    path: `/admin/automation/emails/${resources.email.id}`,
    alert: resources.email.is_spam ? {
      style: 'error',
      message: (
        <>
          <strong><T text="t(This email has been flagged for questionable content!)" /></strong> <T text="t(You will not be able to send this email until you have addressed the underlying issues. Please visit the 'SPAM Report' tab for more Overview.)" />
        </>
      )
    } : null,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview email={ resources.email } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design email={ resources.email } />
      },
      {
        label: 't(SPAM Report)',
        show: resources.email.is_spam,
        path: '/spam',
        panel: <Spam email={ resources.email } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows email={ resources.email } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails email={ resources.email } />
          }
        ]
      },
      {
        label: 't(Activities)',
        path: '/activities',
        panel: <Activities email={ resources.email } />
      },
      {
        label: 't(Bounces)',
        path: '/bounces',
        panel: <Bounces email={ resources.email } />
      },
      {
        label: 't(Complaints)',
        path: '/complaints',
        panel: <Complaints email={ resources.email } />
      },
      {
        label: 't(Deliveries)',
        path: '/deliveries',
        panel: <Deliveries email={ resources.email } />
      },
      {
        label: 't(Links)',
        path: '/links',
        panel: <Links email={ resources.email } />
      },
      {
        label: 't(Opens)',
        path: '/opens',
        panel: <Opens email={ resources.email } />
      },
      {
        label: 't(Unopens)',
        path: '/unopens',
        panel: <Unopens email={ resources.email } />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        panel: <Performance email={ resources.email } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  email: `/api/admin/automation/emails/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
