import EmailField from '@admin/components/form/emailfield'
import Button from '@admin/components/button'
import Image from '@admin/components/image'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Email extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      flash: PropTypes.object,
      host: PropTypes.object,
      network: PropTypes.object,
      provider: PropTypes.object,
      router: PropTypes.object
    }),
    onNext: PropTypes.func,
    onRedirect: PropTypes.func
  }

  emailRef = null

  state = {
    error: false,
    email: '',
    message: null,
    status: 'pending'
  }

  _handleShake = this._handleShake.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  
  constructor(props) {
    super(props)
    this.emailRef = React.createRef()
  }

  render() {
    const { provider } = this.props.context
    const { message } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-logo">
            <div className="maha-logo-badge">
              <div className="maha-logo-wrapper">
                <Image { ...this._getLogo() } />
              </div>
            </div>
          </div>
          <h2><T text={ provider.title } /></h2>
          <p><T text="t(Sign in To Your Account)" /></p>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            <div className="field email-field">
              <EmailField { ...this._getEmail() } />
            </div>
            <div className="field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(() => {
      if(!this.emailRef.current) return
      this.emailRef.current.focus()
    }, 250)
  }

  _getContinue() {
    const { status } = this.state
    return {
      label: 't(Continue)',
      loading: status === 'submitting',
      color: 'blue',
      size: 'large',
      handler: this._handleSubmit
    }
  }

  _getEmail() {
    return {
      ref: this.emailRef,
      onEnter: this._handleSubmit,
      onChange: this._handleUpdate.bind(this, 'email')
    }
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _getLogo() {
    const { provider } = this.props.context
    return {
      src: provider.logo,
      title: provider.title,
      host: provider.asset_host,
      transforms: { fit: 'cover', w: 150, h: 150 }
    }
  }

  _handleShake({ message}) {
    this.setState({ 
      error: true,
      message 
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleSubmit() {
    const { email } = this.state
    this.setState({ 
      message: null,
      status: 'submitting' 
    })
    this.props.context.network.request({
      endpoint: '/api/admin/signin/email',
      method: 'POST',
      body: { email },
      onSuccess: ({ data }) => {
        const account = data
        if(account.authentication_strategy !== 'local') return this.props.onRedirect(account)
        if(account.is_locked_out) return this.props.onNext('lockout')
        if(account.is_blocked) return this.props.onNext('blocked')
        this.props.onNext('password', { account })

      },
      onFailure: this._handleShake
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Email
