import Page from '@admin/components/page'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ title }) => {

  const details = {
    sections: [
      {
        items: [
          { label: 't(Title)', content: title }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  title: PropTypes.string
}

const mapResourcesToPage = (props, context) => ({
  item: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}/items/${props.params.id}`
})

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Item)',
  panel: <Overview { ...resources.item } />
})

export default Page(mapResourcesToPage, mapPropsToPage)
