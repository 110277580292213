import AssetInfo from '@admin/components/asset_info'
import Page from '@admin/components/page'
import PropTypes from 'prop-types'
import React from 'react'

const Asset = ({ asset }) => (
  <AssetInfo asset={ asset } />
)

Asset.propTypes = {
  asset: PropTypes.object
}

const mapResourcesToPage = (props, context) => ({
  asset: `/api/admin/assets/${props.params.fingerprint}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Asset Info)',
  component: Asset
})

export default Page(mapResourcesToPage, mapPropsToPage)
