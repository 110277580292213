import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const SocialCampaignVariant = (props) => {

  const { variant } = props

  return (
    <div className="campaign-social-campaign-comment-token">
      <div className="campaign-social-campaign-comment-token-preview">
        <div className="campaign-social-campaign-comment-token-preview-inner">
          <MJSONScreenshot { ...getScreenshot(variant) } />
        </div>
      </div>
      { variant.last_comment ?
        <div className="campaign-social-campaign-comment-token-label">
          <div className="campaign-social-campaign-comment-token-user">
            { variant.last_comment.user_name || 'Unknown' }
          </div>
          <div className="campaign-social-campaign-comment-token-text">
            { variant.last_comment.text }
          </div>
          <div className="campaign-social-campaign-comment-token-date">
            { moment(variant.last_comment.posted_at).format('MMM DD') }
          </div>
        </div> :
        <div className="campaign-social-campaign-comment-token-label">
          <div className="campaign-social-campaign-comment-token-user">
            { variant.profile.username }
          </div>
          <div className="campaign-social-campaign-comment-token-text empty">
            No comments on this post
          </div>
        </div>
      }
    </div>
  )

}

SocialCampaignVariant.propTypes = {
  variant: PropTypes.object
}

export default SocialCampaignVariant
