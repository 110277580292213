import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Buttons extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    buttons: PropTypes.array
  }

  render() {
    const allowed = this._getAllowed()
    if(allowed.length === 0) return null
    return (
      <div className="maha-buttons">
        { allowed.map((button, index) => (
          <div className="maha-buttons-item" key={`button_${index}`}>
            <Button { ...button } key={`button_${index}`} />
          </div>
        )) }
      </div>
    )
  }

  _getAllowed() {
    const { admin } = this.context
    const { buttons } = this.props
    return buttons.filter(button => {
      return button.show !== false && canAccess(button.access, admin)
    })
  }

}

export default Buttons
