import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    product: PropTypes.object,
    store: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { product, store } = this.props
    return {
      title: 't(Edit Product)',
      method: 'PATCH',
      endpoint: `/api/admin/stores/stores/${store.id}/products/${product.id}/edit`,
      action: `/api/admin/stores/stores/${store.id}/products/${product.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Categories)', name: 'category_ids', type: 'checkboxgroup', endpoint: `/api/admin/stores/stores/${store.id}/categories`, valueKey: 'id', textKey: 'title', required: true, placeholder: 't(Choose a category)' },
            { label: 't(Description)', name: 'description', type: 'htmlfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
