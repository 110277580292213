import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const services = {
  email: 't(Email)',
  social: 't(Social Media)',
  legacy_website: 't(Legacy Website)',
  website: 't(Website)'
}

const SubscriptionToken = (props) => {

  const { type, channels, public_channels, url } = props

  return (
    <div className="token">
      <T text={ services[type] } /><br />
      { type !== 'legacy_website' ?
        <>
          { channels.length > 0 ?
            <span>{ channels.map(channel => channel.title).join(', ') }</span> :
            public_channels.length > 0 ?
              <span>{ public_channels.map(channel => channel.title).join(', ') }</span> :
              <span><T text="No assigned channels" /></span> }
        </> :
        <span>{ url }</span>
      }
    </div>
  )

}

SubscriptionToken.propTypes = {
  channels: PropTypes.array,
  public_channels: PropTypes.array,
  type: PropTypes.string,
  url: PropTypes.string
}

export default SubscriptionToken
