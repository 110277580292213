import Resumable from 'resumablejs'
import PropTypes from 'prop-types'
import React from 'react'

export const UploaderContext = React.createContext()
UploaderContext.displayName = 'UploaderContext'

export const useUploaderContext = () => React.useContext(UploaderContext)

const ignored = ['.DS_Store','Thumbs.db']

class Uploader extends React.Component {

  static childContextTypes = {
    uploader: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    device: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    allow: PropTypes.object,
    children: PropTypes.any,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    onAdd: PropTypes.func,
    onUpdate: PropTypes.func
  }

  browseRef = React.createRef()
  dropRef = React.createRef()

  _handleAdd = this._handleAdd.bind(this)
  _handleBrowse = this._handleBrowse.bind(this)
  _handleFailure = this._handleFailure.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleGetFile = this._handleGetFile.bind(this)
  _handleProcessed = this._handleProcessed.bind(this)
  _handleProgress = this._handleProgress.bind(this)
  _handleRetry = this._handleRetry.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)
  _handleUpload = this._handleUpload.bind(this)

  render() {
    return (
      <UploaderContext.Provider value={ this.getChildContext() }>
        <div className="maha-attachments" ref={ this.dropRef }>
          { this.props.children }
          <div ref={ this.browseRef } />
        </div>
      </UploaderContext.Provider>
    )
  }

  componentDidMount() {
    const { admin, device } = this.context
    const { token } = admin
    const { multiple } = this.props
    const file_types = this._getFileTypes()
    this.resumable = new Resumable({
      target: '/api/admin/assets/upload',
      chunkSize: device.getChunkSize(),
      permanentErrors: [204, 400, 404, 409, 415, 500, 501],
      ...file_types ? {
        fileType: file_types
      } : {},
      headers: {
        'Authorization': `Bearer ${token}`
      },
      ...!multiple ? { maxFiles: 1 } : {}
    })
    this.resumable.on('fileAdded', this._handleAdd)
    this.resumable.on('fileError', this._handleFailure)
    this.resumable.on('fileProgress', this._handleProgress)
    this.resumable.on('fileSuccess', this._handleSuccess)
    this.resumable.assignBrowse(this.browseRef.current)
    this.resumable.assignDrop(this.dropRef.current)
  }

  _getFileTypes() {
    const { allow } = this.props
    if(allow.extensions) return allow.extensions
    if(allow.types) {
      return _.castArray(allow.types).reduce((extensions, type) => [
        ...extensions,
        ...type === 'photos' ? ['jpg','jpeg','png','gif'] : [],
        ...type === 'videos' ? ['mp4','mov'] : []
      ], [])
    }
    if(allow.content_types) {
      return _.castArray(allow.content_types).reduce((extensions, content_type) => [
        ...extensions,
        ...content_type === 'images' ? ['jpg','jpeg','png','gif'] : [],
        ...content_type === 'videos' ? ['mp4','mov'] : []
      ], [])
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props
    if(files.length < prevProps.files.length) {
      this._handleRemove(prevProps.files)
    }
  }

  getChildContext() {
    return {
      uploader: {
        browse: this._handleBrowse,
        getFile:this._handleGetFile,
        retry: this._handleRetry,
        upload:this._handleUpload
      }
    }
  }

  _getFileIndex(file) {
    const { files } = this.props
    return _.findIndex(files, {
      id: file.uniqueIdentifier,
      service: 'device'
    })
  }

  _handleAdd(file) {
    if(_.includes(ignored, file.file.name)) {
      return this.resumable.removeFile(file)
    }
    if(!file.file.type.match(/(jpeg|jpg|gif|png)/)) {
      return this.props.onAdd({
        id: file.uniqueIdentifier,
        source_id: 'device',
        name: file.file.name,
        service: 'device',
        content_type: file.file.type,
        status: 'pending',
        progress: 0
      })
    }
    const filereader = new FileReader()
    filereader.readAsArrayBuffer(file.file)
    filereader.onloadend = this._handleLoad.bind(this, file)
  }

  _handleBrowse() {
    setTimeout(() => this.browseRef.current.click(), 250)
  }

  _handleFailure(file) {
    const index = this._getFileIndex(file)
    this.props.onUpdate(index, {
      status: 'failed'
    })
  }

  _handleFetch() {
  }

  _handleGetFile(id) {
    return this.resumable.getFromUniqueIdentifier(id)
  }

  _handleJoin(asset) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.subscribe({ 
      channel: `/teams/${team.id}/admin/assets/${asset.id}`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

  _handleLeave(asset) {
    const { admin } = this.context
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}/admin/assets/${asset.id}`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

  _handleLoad(file, e) {
    const arrayBuffer = e.target.result
    const bytes = new Uint8Array(arrayBuffer)
    const binary = bytes.reduce((binary, byte) => {
      return binary + String.fromCharCode(byte)
    }, '')
    const base64 = window.btoa(binary)
    this.props.onAdd({
      id: file.uniqueIdentifier,
      source_id: 'device',
      name: file.file.name,
      service: 'device',
      content_type: file.file.type,
      thumbnail: `data:${file.file.type};base64,${base64}`,
      status: 'pending',
      progress: 0
    })
  }

  _handleProcessed(asset) {
    if(!asset || asset.status !== 'processed') return
    const file = _.find(file, { asset: { id: asset.id } })
    const index = this._getFileIndex(file)
    this.props.onUpdate(index, {
      status: 'imported'
    })
    this._handleLeave(asset)
  }

  _handleProgress(file) {
    const index = this._getFileIndex(file)
    this.props.onUpdate(index, {
      progress: file.progress(),
      status: 'uploading'
    })
  }

  _handleRemove(oldFiles) {
    const { files } = this.props
    const removed = oldFiles.find(file => {
      return _.find(files, { id: file.id, service: file.service }) === undefined
    })
    if(removed.service !== 'device') return
    const file = this.resumable.getFromUniqueIdentifier(removed.id)
    this.resumable.removeFile(file)
  }

  _handleSuccess(file, message) {
    const asset = JSON.parse(message).data
    this.resumable.removeFile(file)
    const index = this._getFileIndex(file)
    this.props.onUpdate(index, {
      asset,
      status: asset.status === 'assembled' ? 'processing' : 'complete'
    })
    if(asset.status === 'assembled') this._handleJoin(asset)
  }

  _handleRetry(id) {
    const file = this.resumable.getFromUniqueIdentifier(id)
    file.retry()
  }

  _handleUpload() {
    this.resumable.upload()
  }

}

export default Uploader
