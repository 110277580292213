import { useProviderContext } from '@admin/components/provider'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import React, { useEffect, useRef } from 'react'
import * as actions from './actions'
import PropTypes from 'prop-types'
import Revision from './revision'
import reducer from './reducer'

const RevisionContainer = (props) => {

  const { provider } = useProviderContext()

  const { network } = useNetworkContext()

  const listenerRef = useRef()

  const [state, handlers] = useActionReducer({
    display_name: 'revision',
    actions,
    props,
    reducer
  })

  listenerRef.current = (data) => {
    if(data === state.revision) return
    handlers.onSetRevision(data)
  }

  useEffect(() => {
    const listener = (event, data) => listenerRef.current(event, data)
    network.listen('revision', listener)
    return () => {
      network.unlisten('revision', listener)
    }
  }, [])

  const mergedProps = {
    context: {
      provider
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Revision { ...mergedProps } />

}

RevisionContainer.propTypes = {}

export default RevisionContainer
