import VersionShow from './version'
import FileShow from './file'
import FileInfo from './info'
import Drive from './drive'

const routes = [
  { path: '/', component: Drive },
  { path: '/items', component: Drive },
  { path: '/shared', component: Drive },
  { path: '/starred', component: Drive },
  { path: '/trash', component: Drive },
  { path: '/folders/:code', component: Drive },
  { path: '/files/:code', component: FileShow },
  { path: '/files/:code/info', component: FileInfo },
  { path: '/files/:file_id/versions/:id', component: VersionShow }
]

export default routes
