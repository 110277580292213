import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Homepage extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Additional Business Information)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Years of Experience)',
                  instructions: 't(Leave blank if you prefer not to display on your website)).',
                  name: 'website_homepage_years_of_experience',
                  type: 'textfield',
                  defaultValue: config.website_homepage_years_of_experience 
                },
                { 
                  label: 't(Personal or Company Photo(s))',
                  instructions: 't(Headshot or lifestyule photos (optional, but recommended for IC\'s))',
                  name: 'website_homepage_personal_photo_ids',
                  type: 'attachmentfield',
                  allow: { content_types: ['images'] },
                  prompt: 't(Choose Photo(s))',
                  multiple: true,
                  defaultValue: config.website_homepage_personal_photo_ids 
                },
                { 
                  label: 't(Travel Styles)',
                  name: 'website_homepage_travel_styles',
                  type: 'checkboxgroup',
                  options: ['All Inclusive','Adventure','Art and Architecture','Beach and Sun','Bicycle/Motorbike','Caravan/RV','Corporate','Culture and History','Destinations','Destination Weddings','Education and Learning','Escorted Tours','Event Travel','Faith/Religious','Family Vacations','Food and Wine','Gap Year','Hiking/Trekking','Honeymoons','Independent Travel','LGBTQ','Local Experience','Luxury Hotels and Resorts','Ocean Cruising','Other','Private Islands','Private Homes','River Cruising','Safari and Wildlife','Ski','Solo','Spa Wellness','Special Needs','Sports and Outdoors','Train','Unplugged','Villas','Volunteer Tourism'],
                  columns: 4,
                  defaultValue: config.website_homepage_travel_styles 
                },
                { 
                  label: 't(Other Travel Interests + Styles)',
                  name: 'website_homepage_other_interests',
                  type: 'textarea',
                  defaultValue: config.website_homepage_other_interests 
                },
                { 
                  label: 't(Top 10 Travel Destinations)',
                  instructions: 't(List any specific travel destinations such as continents, countries, cities etc. to list on your website)',
                  name: 'website_homepage_top10_destinations',
                  type: 'textarea',
                  placeholder: 't(Example: New Zealand, Maldives, Italy Ireland, North America)',
                  defaultValue: config.website_homepage_top10_destinations 
                },
                { 
                  label: 't(Host Agency Name)',
                  name: 'website_homepage_host_agency_name',
                  type: 'textfield',
                  defaultValue: config.website_homepage_host_agency_name 
                },
                { 
                  label: 't(Host Agency URL)',
                  name: 'website_homepage_host_agency_url',
                  type: 'urlfield',
                  defaultValue: config.website_homepage_host_agency_url 
                },
                { 
                  label: 't(Affiliations / Consortia / Preferred Suppliers List)',
                  name: 'website_homepage_affiliations',
                  type: 'textarea',
                  defaultValue: config.website_homepage_affiliations 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Homepage
