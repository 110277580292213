import PaymentMethods from './payment_methods'
import Subscriptions from './subscriptions'
import Registrations from './registrations'
import Page from '@admin/components/page'
import Scholarships from './scholarships'
import Duplicates from './duplicates'
import Agreements from './agreements'
import Voicemail from './voicemails'
import Responses from './responses'
import Locations from './locations'
import Payments from './payments'
import Invoices from './invoices'
import Timeline from './timeline'
import Overview from './overview'
import Devices from './devices'
import Credits from './credits'
import Refunds from './refunds'
import Consent from './consent'
import Orders from './orders'
import Emails from './emails'
import Deals from './deals'
import Files from './files'
import Smses from './smses'
import Calls from './calls'
import Notes from './notes'
import Lists from './lists'
import Tasks from './tasks'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => {
  const callers = context.admin.programs.filter(program => {
    return program.phone_number !== null
  })
  const callable = callers.length > 0 && resources.contact.phone_numbers.length > 0
  const emailable = resources.contact.email_addresses.length > 0
  return {
    title: resources.contact.display_name,
    manager: {
      path: `/admin/crm/contacts/${resources.contact.id}`,
      items: [
        {
          label: 't(Overview)',
          panel: <Overview contact={ resources.contact } emailable={ emailable } callable={ callable } programs={ context.admin.programs } fields={ resources.fields } />
        },
        {
          label: 't(Timeline)',
          path: '/timeline',
          panel: <Timeline contact={ resources.contact } />
        },
        {
          label: 't(Consent)',
          path: '/consent',
          panel: <Consent contact={ resources.contact } />
        },
        {
          label: 't(Devices)',
          path: '/devices',
          panel: <Devices contact={ resources.contact } />
        },
        {
          label: 't(Lists)',
          path: '/lists',
          panel: <Lists contact={ resources.contact } />
        },
        // {
        //   label: 't(Locations)',
        //   path: '/locations',
        //   panel: <Locations contact={ resources.contact } />
        // },
        {
          label: 't(Activities)',
          children: [
            {
              label: 't(Deals)',
              path: '/deals',
              access: { rights: { $oneOf: ['sales:view_pipeline','sales:manage_pipelines'] } },
              panel: <Deals contact={ resources.contact } />
            },
            {
              label: 't(Orders)',
              path: '/orders',
              access: { rights: { $oneOf: ['stores:view_stores','stores:manage_stores'] } },
              panel: <Orders contact={ resources.contact } />
            },
            {
              label: 't(Registrations)',
              path: '/registrations',
              access: { rights: { $oneOf: ['events:view_events','events:manage_events'] } },
              panel: <Registrations contact={ resources.contact } />
            },
            {
              label: 't(Responses)',
              path: '/responses',
              access: { rights: { $oneOf: ['forms:view_forms','forms:manage_forms'] } },
              panel: <Responses contact={ resources.contact } />
            },
            {
              label: 't(Subscriptions)',
              path: '/subscriptions',
              access: { rights: { $oneOf: ['subscriptions:view_services','subscriptions:manage_services'] } },
              panel: <Subscriptions contact={ resources.contact } />
            }
          ]
        },
        {
          label: 't(Communication)',
          access: { rights: { $oneOf: ['campaigns:access_app'] } },
          children: [
            {
              label: 't(Email)',
              path: '/email',
              access: { rights: { $oneOf: ['campaigns:view_email_campaigns','subscriptions:manage_email_campaigns'] } },
              panel: <Emails contact={ resources.contact } emailable={ emailable } />
            },
            {
              label: 't(SMS)',
              path: '/sms',
              access: {
                $and: [
                  { rights: { $allOf: ['phone:access_app'] } },
                  { rights: { $oneOf: ['campaigns:view_sms_campaigns','subscriptions:manage_sms_campaigns'] } }
                ]
              },
              panel: <Smses contact={ resources.contact } callable={ callable } />
            },
            {
              label: 't(Calls)',
              path: '/calls',
              access: {
                $and: [
                  { rights: { $allOf: ['phone:access_app'] } },
                  { rights: { $oneOf: ['campaigns:view_voice_campaigns','subscriptions:view_voice_campaigns'] } }
                ]
              },
              panel: <Calls contact={ resources.contact } callable={ callable } />
            },
            {
              label: 't(Voicemail)',
              path: '/voicemail',
              access: {
                $and: [
                  { rights: { $allOf: ['phone:access_app'] } }
                ]
              },
              panel: <Voicemail contact={ resources.contact } />
            }
          ]
        },
        {
          label: 't(Finance)',
          access: { rights: { $oneOf: ['finance:access_app'] } },
          children: [
            {
              label: 't(Credit Cards)',
              path: '/payment_methods',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <PaymentMethods contact={ resources.contact } />
            },
            {
              label: 't(Credits)',
              path: '/credits',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <Credits contact={ resources.contact } />
            },
            {
              label: 't(Invoices)',
              path: '/invoices',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <Invoices contact={ resources.contact } />
            },
            {
              label: 't(Payments)',
              path: '/payments',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <Payments contact={ resources.contact } />
            },
            {
              label: 't(Refunds)',
              path: '/refunds',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <Refunds contact={ resources.contact } />
            },
            {
              label: 't(Scholarships)',
              path: '/scholarships',
              access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
              panel: <Scholarships contact={ resources.contact } />
            }
          ]
        },
        {
          label: 't(Agreements)',
          path: '/agreements',
          show: context.admin.hasFeature('agreements'),
          panel: <Agreements contact={ resources.contact } />
        },
        {
          label: 't(Files)',
          path: '/files',
          panel: <Files contact={ resources.contact } />
        },
        {
          label: 't(Notes)',
          path: '/notes',
          panel: <Notes contact={ resources.contact } />
        },
        {
          label: 't(Tasks)',
          access: { rights: { $oneOf: ['tasks:view_tasks','finance:manage_tasks'] } },
          path: '/tasks',
          panel: <Tasks contact={ resources.contact } />
        },
        {
          label: 't(Duplicates)',
          path: '/duplicates',
          panel: <Duplicates contact={ resources.contact } programs={ context.admin.programs } fields={ resources.fields }  />,
          count: resources.duplicates.length
        }
      ]
    }
  }
}

const mapResourcesToPage = (props, context) => ({
  contact: `/api/admin/crm/contacts/${props.params.id}`,
  duplicates: `/api/admin/crm/contacts/${props.params.id}/duplicates`,
  fields: '/api/admin/programs/fields'
})

export default Page(mapResourcesToPage, mapPropsToPage)
