import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import React from 'react'

export const NetworkContext = React.createContext()
NetworkContext.displayName = 'NetworkContext'

export const useNetworkContext = () => React.useContext(NetworkContext)

class Network extends React.Component {

  static childContextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    connected: PropTypes.bool,
    emit: PropTypes.func,
    listen: PropTypes.func,
    request: PropTypes.func,
    subscribe: PropTypes.func,
    unlisten: PropTypes.func,
    unsubscribe: PropTypes.func
  }

  state = {
    alert: null
  }

  _handleClear = this._handleClear.bind(this)
  _handleEmit = this._handleEmit.bind(this)
  _handleListen = this._handleListen.bind(this)
  _handleMessage = this._handleMessage.bind(this)
  _handleOffline = this._handleOffline.bind(this)
  _handleRequest = this._handleRequest.bind(this)
  _handleSubscribe = this._handleSubscribe.bind(this)
  _handleUnlisten = this._handleUnlisten.bind(this)
  _handleUnsubscribe = this._handleUnsubscribe.bind(this)

  render() {
    const { alert } = this.state
    return (
      <NetworkContext.Provider value={ this.getChildContext() }>
        { this.props.children }
        <CSSTransition in={ alert !== null } classNames="slide-in-up" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className={ this._getClass() }>
            { alert?.message }
          </div>
        </CSSTransition>
      </NetworkContext.Provider>
    )
  }

  componentDidUpdate(prevProps) {
    const { connected } = this.props
    if(prevProps.connected !== connected) {
      if(!connected) setTimeout(this._handleOffline, 2500)
      if(connected) this._handleOnline()
    }
  }

  getChildContext() {
    return {
      network: {
        emit: this._handleEmit,
        listen: this._handleListen,
        message: this._handleMessage,
        request: this._handleRequest,
        subscribe: this._handleSubscribe,
        unlisten: this._handleUnlisten,
        unsubscribe: this._handleUnsubscribe
      }
    }
  }

  _getClass() {
    const { alert } = this.state
    const classes = ['maha-network-status']
    if(alert?.status) classes.push(`${alert.status}`)
    return classes.join(' ')
  }

  _handleClear() {
    this.setState({
      alert: null
    })
  }

  _handleEmit(verb, data) {
    this.props.emit(verb, data)
  }

  _handleListen(event, handler) {
    this.props.listen(event, handler)
  }

  _handleOffline() {
    const { connected } = this.props
    if(connected) return
    this.setState({
      alert: {
        status: 'error', 
        message: 'Unable to connect to the server'
      }
    })
  }

  _handleMessage({ channel, action, data }) {
    this.props.emit('message', {
      channel,
      action,
      data
    })
  }

  _handleOnline() {
    const { alert } = this.state
    if(!alert) return
    this.setState({
      alert: {
        status: 'success', 
        message: 'You\'re back online!'
      }
    })
    setTimeout(this._handleClear, 2500)
  }

  _handleRequest(request) {
    this.props.request(request)
  }

  _handleSubscribe(subscritions) {
    _.castArray(subscritions).map(subscrition => {
      this.props.subscribe(subscrition)
    })
  }

  _handleUnlisten(event, handler) {
    this.props.unlisten(event, handler)
  }

  _handleUnsubscribe(subscritions) {
    _.castArray(subscritions).map(subscrition => {
      this.props.unsubscribe(subscrition)
    })
  }

}

export default Network
