import { useLocalStorageContext } from '@admin/components/local_storage'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useMahaContext } from '@admin/components/maha'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Device from './device'
import React from 'react' 

export { DeviceContext, useDeviceContext } from './device'

const DeviceContainer = (props) => {

  const { local_storage } = useLocalStorageContext()
  const { network } = useNetworkContext()
  const { maha } = useMahaContext()

  const [state, handlers] = useActionReducer({
    display_name: 'device',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      local_storage,
      maha,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Device { ...mergedProps } />

}

DeviceContainer.propTypes = {}

export default DeviceContainer
