import CopyText from '@admin/components/copytext'
import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class DNSTable extends React.Component {

  static propTypes = {
    check: PropTypes.object,
    instructions: PropTypes.string,
    is_hosted: PropTypes.bool,
    last_verified_at: PropTypes.string,
    publish: PropTypes.object,
    records: PropTypes.array
  }

  render() {
    const { instructions, is_hosted, last_verified_at, records } = this.props
    return (
      <div className="maha-dns-table">
        { instructions &&
          <p><T text={ instructions } /></p>
        }
        { records ?
          <>
            <table className="ui unstackable compact celled table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Value</th>
                  <th className="collapsing" />
                </tr>
              </thead>
              <tbody>
                { records.map((record, index) => (
                  <tr key={`record_${index}`}>
                    <td>{ record.alias ? 'ALIAS' : record.type }</td>
                    <td><CopyText text={ this._getName(record.name) } /></td>
                    <td><CopyText text={ record.value || record.alias } /></td>
                    <td className="center aligned"><Icon icon={ this._getIcon(record.is_mapped) } /></td>
                  </tr>
                )) }
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="4">
                    <div className="maha-dns-table-footer">
                      <div className="maha-dns-table-footer-message">
                        <T text="t(LAST CHECKED)" />: { last_verified_at ?
                          <Date datetime={ last_verified_at } /> :
                          <>never</>
                        }
                      </div>
                      <div className="maha-dns-table-footer-button">
                        <Button { ...this._getCheck() } />
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            { is_hosted && this._getRemaining() &&
              <div className="maha-dns-table-footer-notice">
                <p><T text="t(It looks like this domain's DNS is currently hosted with Maha and at least one of the records in the table above has not yet been created!)" /></p>
                <Button { ...this._getPublish() } />
              </div>
            }
          </> :
          <Loader label="t(Generating DNS values...)" />
        }
      </div>
    )
  }

  _getCheck() {
    const { check } = this.props
    return {
      label: 't(Verify)',
      color: 'blue',
      basic: true,
      fluid: false,
      size: 'mini',
      ...check
    }
  }

  _getIcon(is_mapped) {
    return is_mapped ? 'check-circle' : 'times'
  }

  _getName(name) {
    return name.length > 0 ? name : '@'
  }

  _getPublish() {
    const { publish } = this.props
    return {
      label: 't(Add missing records)',
      color: 'blue',
      basic: true,
      fluid: false,
      size: 'mini',
      ...publish
    }
  }

  _getRemaining() {
    const { records } = this.props
    return records.reduce((remaining, record) => {
      return remaining ? record.is_hosted : false
    }, true) === false
  }

}

export default DNSTable
