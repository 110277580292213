import SubscriptionProfileToken from '@apps/truevail/admin/tokens/subscription_profile'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

const getList = (subscription_profiles) => ({
  items: subscription_profiles,
  format: (subscription_profile) => (
    <SubscriptionProfileToken { ...subscription_profile } />
  )
})

const SubscriptionProfilesToken = ({ subscription_profiles }) => (
  <List { ...getList(subscription_profiles) }/>
)

SubscriptionProfilesToken.propTypes = {
  subscription_profiles: PropTypes.array
}

export default SubscriptionProfilesToken
