import SPAMReport from '@admin/components/email_report'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Spam = ({ email_campaign }) => {

  const details = {
    sections: [
      {
        title: 't(SPAM Report)',
        items: [
          { padded: true, component: <SPAMReport spamassassin={ email_campaign.spamassassin } /> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Spam.propTypes = {
  email_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Spam Report)',
  panel: <Spam email_campaign={ props.email_campaign } />
})

export default Panel(null, mapPropsToPanel)
