import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Revision extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    reload: PropTypes.bool,
    reloading: PropTypes.bool,
    revision: PropTypes.string,
    children: PropTypes.any,
    onSetReload: PropTypes.func,
    onSetReloading: PropTypes.func
  }

  _handleReload = this._handleReload.bind(this)

  render() {
    const { reload, reloading } = this.props
    const { provider } = this.context
    return (
      <>
        { reload && !reloading &&
          <div className="maha-notice">
            An updated version of { provider.title } is available! <Button { ...this._getReload() } />
          </div>
        }
        { reloading ?
          <Loader { ...this._getReloading() } /> : 
          <>{ this.props.children }</>
        }
      </>
    )
  }

  componentDidUpdate(prevProps) {
    const { reloading, revision } = this.props
    if(revision !== prevProps.revision && prevProps.revision !== null) {
      this.props.onSetReload(true)
    }
    if(reloading !== prevProps.reloading) {
      window.location.reload()
    }
  }

  _getReload() {
    return {
      label: 't(Reload application)',
      className: 'link',
      handler: this._handleReload
    }
  }

  _getReloading() {
    const { provider } = this.context
    return {
      inverted: false,
      label: `t(Reloading) ${provider.title}`
    }
  }

  _handleReload() {
    this.props.onSetReloading(true)
  }

}

export default Revision
