import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Metrics extends React.Component {

  static propTypes = {
    metrics: PropTypes.array
  }

  render() {
    const { metrics } = this.props
    return (
      <div className="crm-report-metrics">
        { metrics.map((metric, index) => (
          <div className="crm-report-metric" key={`metric_${index}`}>
            <div className="crm-report-metric-value">
              { numeral(metric.value).format(this._getFormat(metric.format))}
            </div>
            <div className="crm-report-metric-title">
              <T text={ metric.label } />
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getFormat(format) {
    if(format === 'integer') return '0,0'
    if(format === 'float') return '0,0.00'
    if(format === 'percent') return '0.00%'
    if(format === 'currency') return '$0,0.00'
    return format
  }

}

export default Metrics
