const sections = (fields) => fields.filter(field => {
  return field.is_active
}).reduce((fields, field, index) => [
  ...fields,
  ...index === 0 && field.type !== 'section' ? [{
    type: 'section',
    fields: []
  }] : [],
  field
], []).reduce((sections, field) => ({
  ...sections,
  ...field.type === 'section' ? {
    current: sections.current + 1,
    records: [
      ...sections.records,
      {
        label: field.label,
        instructions: field.instructions
      }
    ]
  } : {
    records: sections.records.map((section, index) => {
      if(index !== sections.current) return section
      return {
        ...section,
        fields: [
          ...section.fields || [],
          _getControl(field)
        ]
      }
    })
  }
}), { records: [], current: -1 }).records

const _getControl = (field) => ({
  ...field.config,
  name: `values.${field.code}`,
  type: field.type,
  ...field.type === 'lookup' ? {
    valueKey: field.config.valueKey,
    textKey: field.config.textKey
  } : {}
})

export default sections
