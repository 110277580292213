import Edit from '@apps/datasets/admin/views/datasets/edit'
import NewType from '@apps/datasets/admin/views/types/new'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

class Overview extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    dataset: PropTypes.object
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { dataset } = this.props
    return {
      audits: `datasets_datasets/${dataset.id}`,
      comments: `datasets_datasets/${dataset.id}`,
      sections: [
        {
          title: 't(List Details)',
          items: [
            { label: 't(Title)', content: dataset.title.value }
          ]
        }
      ]
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Dataset)',
  panel: <Overview dataset={ props.dataset } />,
  ...props.dataset.access_type === 'manage' ? {
    tasks: {
      items: [
        { label: 'Edit Dataset', modal: <Edit dataset={ props.dataset } /> },
        { label: 'Create Type', modal: <NewType dataset={ props.dataset } />  }
      ]
    }
  } : {}
})

const mapResourcesToPanel = (props, context) => ({
  types: `/api/admin/datasets/datasets/${props.dataset.id}/types`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
