import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.subscription.type,
  manager: {
    path: `/admin/truevail/advisor/subscriptions/${resources.subscription.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview subscription={ resources.subscription } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  subscription: `/api/admin/truevail/advisor/subscriptions/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
