import EditInventory from '@apps/stores/admin/views/stores/inventory/edit'
import VariantToken from '@apps/stores/admin/tokens/variant'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Products)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/inventory`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'product.title', sort: 'title', primary: true, format: (variant) => (
        <VariantToken variant={ variant } product={ variant.product } />
      ) },
      { label: 'In stock', key: 'inventory_instock', collapsing: true, align: 'right', tooltip: 'Items in your warehouse' },
      { label: 'Reserved', key: 'inventory_reserved', collapsing: true, align: 'right', tooltip: 'Items reserved in carts' },
      { label: 'Unfulfilled', key: 'inventory_unfulfilled', collapsing: true, align: 'right', tooltip: 'Items ordered but not yet fulfilled' },
      { label: 'On Hand', key: 'inventory_onhand', collapsing: true, align: 'right', tooltip: 'Items available for sale' }
    ],
    defaultSort: { key: 'title', order: 'asc' }
  },
  tasks: {
    items: [
      {
        label: 'Update Inventory',
        modal: <EditInventory store={ props.store } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
