import SubscriptionToken from '@apps/truevail/admin/tokens/subscription'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: '/api/admin/truevail/advisor/subscriptions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Subscription)', key: 'type', primary: true, format: SubscriptionToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'refresh',
      title: 't(No Subscriptions)',
      text: 't(You have not yet been subscribed to any Truevail services)'
    },
    entity: 'advisor',
    onClick: (record) => context.router.push(`/admin/truevail/advisor/subscriptions/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
