import Content from '@apps/forms/admin/tokens/content'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ event, ticket }) => {

  const details = {
    sections: [
      {
        title: 't(Ticket Details)',
        items: [
          { label: 't(Name)', content: ticket.name },
          { label: 't(Code)', content: ticket.code },
          ...event.ticket_config.fields.map(field => ({ 
            label: field.name.value, 
            content: <Content data={ ticket.values } field={ field } /> 
          }))
        ]
      }
    ]
  }

  if(ticket.status === 'voided') {
    details.alert = { color: 'red', message: 't(This ticket was voided)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  event: PropTypes.object,
  ticket: PropTypes.object
}

export default Overview
