import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const ListToken = ({ program, title, contacts_count }) => (
  <div className="sender-token">
    <div className="sender-token-logo">
      <Logo team={ program } width="24" />
    </div>
    <div className="sender-token-label">
      { title }
    </div>
  </div>
)

ListToken.propTypes = {
  program: PropTypes.object,
  title: PropTypes.string,
  contacts_count: PropTypes.number
}

export default ListToken
