import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Chargebacks)',
  access: { rights: { $oneOf: ['finance:view_revenue','finance:manage_revenue'] } },
  collection: {
    endpoint: '/api/admin/finance/chargebacks',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'payment.customer.display_name', sort: 'customer', primary: true, format: ({ payment }) => {
        return <ContactToken { ...payment.customer } />
      } },
      { label: 't(Amount)', key: 'amount', collapsing: true, primary: true, format: 'currency' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/chargebacks/${record.id}`),
    empty: {
      icon: 'dollar',
      title: 't(No Chargebacks)',
      text: 't(You have not yet received any chargebacks)'
    },
    entity: 'chargeback'
  }
})

export default Panel(null, mapPropsToPanel)
