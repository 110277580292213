import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'
import numeral from 'numeral'
import React from 'react'

class LineChart extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    datasets: PropTypes.array,
    options: PropTypes.object,
    type: PropTypes.string
  }

  canvasRef = React.createRef()
  chart = null
  ctx = null

  render() {
    return <canvas ref={ this.canvasRef }/>
  }

  componentDidMount() {
    this.container = this.canvasRef.current.parentNode.parentNode
    this._handleInit()
  }

  componentDidUpdate(prevProps) {
    const { options, datasets } = this.props
    if(!_.isEqual(options, prevProps.options)) {
      this._handlePlot()
    }
    if(!_.isEqual(datasets, prevProps.datasets)) {
      this._handlePlot()
    }
  }

  _handleInit() {
    const { datasets, options, type } = this.props
    const { grid, format, legend, points, tension, xscale, yscale } = options
    const { locale } = this.context
    this.ctx = this.canvasRef.current.getContext('2d')
    this.chart = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: datasets[0].points.map(r => r.x),
        datasets: datasets.map(dataset => ({
          label: locale.t(dataset.label),
          data: dataset.points.map(r => r.y),
          borderColor: dataset.color,
          backgroundColor: type === 'area' ? `${dataset.color}22` : null,
          pointborderColor: dataset.color,
          pointBackgroundColor: '#FFFFFF'
        }))
      },
      options: {
        animation: {
          duration: 0
        },
        elements: {
          line: { 
            borderWidth: 2,
            fill: type === 'area',
            tension: tension === 'smooth' ? 0.4 : 0
          },
          point: {
            borderWidth: 2,
            radius: points === 'show' ? 3 : 0
          }
        },
        plugins: {
          legend: legend || {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.parsed.y
                return `${context.dataset.label}: ${format === 'currency' ? numeral(value).format('$0,0') : value}`
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: xscale || {
            display: grid,
            ...grid ? {
              ticks: {
                display: true
              },
              grid: {
                display: true
              }
            } : {}
          },
          y: yscale || {
            display: grid,
            beginAtZero: true,
            ...grid ? {
              ticks: {
                display: true,
                callback: function(value, index, values) {
                  return format === 'currency' ? numeral(value).format('$0,0') : value
                }
              },
              grid: {
                display: true
              }
            } : {}
          }
        }
      }
    })
  }

  _handlePlot() {
    const { datasets, options, type } = this.props
    const { points, tension } = options
    const { locale } = this.context
    if(!this.chart) this._handleInit()
    this.chart.options.elements.line.fill = type === 'area'
    this.chart.options.elements.line.tension = tension === 'smooth' ? 0.4 : 0
    this.chart.options.elements.point.radius = points === 'show' ? 3 : 0
    this.chart.data.labels = []
    this.chart.data.datasets.pop()
    datasets[0].points.map(r => this.chart.data.labels.push(r.x))
    datasets.map(dataset => {
      this.chart.data.datasets.push({
        label: locale.t(dataset.label),
        data: dataset.points.map(r => r.y),
        borderColor: dataset.color,
        backgroundColor: type === 'area' ? `${dataset.color}22` : null
      })  
    })
    this.chart.update()
  }

}

export default LineChart
