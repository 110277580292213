import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: `/api/admin/subscriptions/services/${props.service.id}/subscriptions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Organization)', key: 'position.organization', sort: 'organization' },
      { label: 't(Plan)', key: 'current_period.plan.title', sort: 'plan' },
      { label: 't(Next Renewal)', key: 'next_renewal_date', collapsing: true, format: 'date' },
      { label: 't(Renewals)', key: 'renewals_count', collapsing: true, align: 'right' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['active','canceled','trial'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Active Subscriptions)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'active' }
          ]
        } },
        { id: 1, title: 't(Trial Subscriptions)', config: {
          criteria: [
            { code: 'def', data: null, field: 'status', operator: '$eq', parent: null, value: 'trial' }
          ]
        } },
        { id: 2, title: 't(Canceled Sending)', config: {
          criteria: [
            { code: 'ghi', data: null, field: 'status', operator: '$eq', parent: null, value: 'canceled' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'created_at', order: 'asc' },
    empty: {
      icon: 'check',
      title: 't(No Subscriptions)',
      text: 't(You have not created any subscriptions for this service)',
      buttons: [
        { label: 'Create Subscription', modal: <New service={ props.service } /> }
      ]
    },
    entity: 'subscription',
    onClick: (record) => context.router.push(`/admin/subscriptions/services/${props.service.id}/subscriptions/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <New service={ props.service } />
  }
})

export default Panel(null, mapPropsToPanel)
