import PropTypes from 'prop-types'
import React from 'react'

const Creator = (dependencies) => (Component) => {

  class Dependencies extends React.Component {

    static contextTypes = {
      provider: PropTypes.object
    }

    state = {
      attempts: 0,
      loaded: false,
      scripts: [],
      styles: []
    }

    _handleCheck = this._handleCheck.bind(this)

    render() {
      if(!this.state.loaded) return null
      return <Component {...this.props} />
    }

    componentDidMount() {
      const scripts = dependencies.scripts ? dependencies.scripts.reduce((scripts, script) => {
        const loaded = this._handleCheckLoadedScript(script)
        if(loaded) return scripts
        this._handleLoadScript(script)
        return [
          ...scripts,
          script
        ]
      }, []) : []
      const styles = dependencies.styles ? dependencies.styles.reduce((styles, style) => {
        const loaded = this._handleCheckLoadedStyle(style)
        if(loaded) return styles
        this._handleLoadStyle(style)
        return [
          ...styles,
          style
        ]
      }, []) : []
      this.setState({
        loaded: scripts.length + styles.length === 0,
        scripts,
        styles
      }, this._handleCheck)
    }

    _getNormalized(path) {
      const { provider } = this.context
      const host = provider ? provider.cdn_host : process.env.ADMIN_HOST
      return path[0] === '/' ? `${host}${path}` : path
    }

    _handleCheck() {
      const { attempts, scripts, styles } = this.state
      const scriptscheck = scripts.reduce((loaded, dependency) => {
        return loaded ? this._handleCheckLoadedScript(dependency) : false
      }, true)
      const stylescheck = styles.reduce((loaded, dependency) => {
        return loaded ? this._handleCheckLoadedStyle(dependency) : false
      }, true)
      const loaded = scriptscheck && stylescheck
      if(loaded) return this.setState({ loaded })
      if(attempts === 4) throw new Error('unable to load dependencies')
      this.setState({
        attempts: attempts + 1
      }, () => {
        setTimeout(this._handleCheck, (attempts + 1) * 250)
      })
    }

    _handleCheckLoadedScript(dependency) {
      const url = this._getNormalized(dependency.url)
      const loaded = document.querySelector(`[src="${url}"]`) !== null
      const available = typeof _.get(window, dependency.check) !== 'undefined'
      return loaded && available
    }

    _handleCheckLoadedStyle(dependency) {
      const url = this._getNormalized(dependency.url)
      return document.querySelector(`[href="${url}"]`) !== null
    }

    _handleLoadScript(dependency) {
      const exists = document.querySelector(`[src="${dependency.url}"]`) !== null
      if(exists) return
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = this._getNormalized(dependency.url)
      document.body.appendChild(script)
    }

    _handleLoadStyle(dependency) {
      const exists = document.querySelector(`[href="${dependency.url}"]`) !== null
      if(exists) return
      const style = document.createElement('link')
      style.rel = 'stylesheet'
      style.type = 'text/css'
      style.href = this._getNormalized(dependency.url)
      document.head.appendChild(style)
    }

  }

  return Dependencies

}

export default Creator
