import TextOverrideStyleField from '../textstylefield'
import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import RadiusField from '../radiusfield'
import BorderField from '../borderfield'
import LinkedField from '../linkedfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Linked = (props) => <ResponsiveField { ...props } component={ LinkedField } />
const Border = (props) => <ResponsiveField { ...props } component={ BorderField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const TextOverride = (props) => <ResponsiveField { ...props } component={ TextOverrideStyleField } />

class NumberStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    number: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.number) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { number } = this.state
    if(!_.isEqual(number, prevState.number)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { number } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Text)', name: 'text', collapsing: true, collapsed: true, type: TextOverride, canHover: false, canResponsive: true, device, theme, defaultValue: { text: { preset: 'block_title' } }, value: number.text },
            { label: 't(Background Color)', name: 'backgroundColor', device, theme, collapsing: true, collapsed: true, type: Color, value: number.backgroundColor },
            { label: 't(Border)', name: 'border', device, theme, collapsing: true, collapsed: true, type: Border, value: number.border },
            { label: 't(Rounded Corners)', name: 'radius', device, theme, collapsing: true, collapsed: true, type: Radius, value: number.radius },
            { label: 't(Padding)', name: 'padding', device, collapsing: true, collapsed: true, type: Linked, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: number.padding }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { number } = this.state
    this.props.onChange(number)
  }

  _handleSet(number) {
    this.setState({
      number: {
        ...number
      }
    })
  }

}

export default NumberStyleField
