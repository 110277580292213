export const INITIAL_STATE = {
  connected: true,
  listeners: [],
  subscriptions: []
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'ADD_LISTENER':
    return {
      ...state,
      listeners: [
        ...state.listeners,
        action.listener
      ]
    }

  case 'ADD_SUBSCRIPTION':
    return {
      ...state,
      subscriptions: [
        ...state.subscriptions,
        action.subscription
      ]
    }

  case 'REMOVE_LISTENER':
    return {
      ...state,
      listeners: state.listeners.filter(listener => {
        return listener.handler !== action.listener.handler    
      })
    }

  case 'REMOVE_SUBSCRIPTION':
    return {
      ...state,
      subscriptions: state.subscriptions.filter(subscription => {
        return subscription.handler !== action.subscription.handler
      })
    }

  case 'SET_CONNECTED':
    return {
      ...state,
      connected: action.connected
    }

  default:
    return state
  }

}
