export const onAddFile = (uniqueIdentifier, fileName, fileSize, contentType, totalChunks) => ({
  type: 'ADD_FILE',
  uniqueIdentifier,
  fileName,
  fileSize,
  contentType,
  totalChunks
})

export const onRemoveFile = (index) => ({
  type: 'REMOVE_FILE',
  index
})

export const onSetFiles = (files) => ({
  type: 'SET_FILES',
  files
})

export const onUploadBegin = () => ({
  type: 'UPLOAD_BEGIN'
})

export const onUploadComplete = () => ({
  type: 'UPLOAD_COMPLETE'
})

export const onUploadFailure = () => ({
  type: 'UPLOAD_FAILURE'
})

export const onUploadProgress = (uniqueIdentifier, progress) => ({
  type: 'UPLOAD_PROGRESS',
  uniqueIdentifier,
  progress
})

export const onUploadSuccess = (uniqueIdentifier, response) => ({
  type: 'UPLOAD_SUCCESS',
  uniqueIdentifier,
  asset: response.data
})