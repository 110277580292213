import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class GenerateField extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object,
    network: PropTypes.object    
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    content: null,
    config: {},
    key: null,
    status: 'prompting',
    options: false
  }

  _handleContent = this._handleContent.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleGenerate = this._handleGenerate.bind(this)
  _handleJoin = this._handleJoin.bind(this)
  _handleOptions = this._handleOptions.bind(this)
  _handleStop = this._handleStop.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { content } = this.state
    return (
      <div className="maha-generate-field">
        <div className="maha-generate-field-form">
          <Form { ...this._getForm() } />
        </div>
        <div className="maha-generate-button">
          <Button { ...this._getGenerate() } />
        </div>
        { content &&
          <div className="maha-generate-output">
            <div className="maha-generate-content">
              <textarea { ...this._getContent() } />
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      key: _.random(100000, 999999).toString(36)
    }, this._handleJoin)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.state
    if(status !== prevState.status && status === 'generated') {
      this._handleChange()
    }
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  _getForm() {
    const { options } = this.state
    return {
      inline: true,
      onCancel: this._handleCancel,
      onChange: this._handleUpdate,
      onChangeField: this._handleChangeField,
      sections: [
        {
          fields: [
            { entity: 't(Prompt)', name: 'prompt', type: 'textarea', required: true, after: (
              <Button { ...this._getOptions() } />
            ) },
            { label: 't(Tone)', name: 'tone', type: 'dropdown', options: [
              { value: 'professional', text: 't(Professional)' },
              { value: 'casual', text: 't(Casual)' },
              { value: 'fun', text: 't(Fun)' },
              { value: 'witty', text: 't(Witty)' },
              { value: 'format', text: 't(Formal)' },
              { value: 'optimistic', text: 't(Optimistic)' }
            ], defaultValue: 'professional', show: options }
          ]
        }
      ]
    }
  }

  _getContent() {
    const { content } = this.state
    return {
      value: content,
      readOnly: true
    }
  }

  _getGenerate() {
    const { config, status } = this.state
    return {
      ...status === 'generating' ? { 
        icon: 'circle-o-notch fa-spin',
        label: 't(Stop Generating)',
        handler: this._handleStop
      } : status === 'generated' ? { 
        label: 't(Regenerate Content)',
        handler: this._handleGenerate
      } : {
        label: 't(Generate Content)',
        handler: this._handleGenerate    
      },
      disabled: !(config.prompt && config.prompt.length > 0),
      basic: true,
      color: 'blue',
      size: 'mini',
      fluid: false
    }
  }

  _getOptions() {
    const { options } = this.state
    return {
      icon: options ? 'caret-down' : 'caret-right',
      label:  options ? 't(hide options)' : 't(show options)',
      className: 'link',
      handler: this._handleOptions
    }
  }

  _handleContent(chunk) {
    const content = this.state.content || ''
    this.setState({
      content: `${content}${chunk}`
    })
  }

  _handleChange() {
    const { content } = this.state
    this.props.onChange(content ? content : null)
  }

  _handleChangeField(key,value) {
    if(key !== 'prompt') return
    this.setState({
      status: 'prompting'
    })
  }

  async _handleGenerate() {
    const { config, key } = this.state
    const { prompt } = config
    this.setState({
      content: '',
      status: 'generating'
    })
    this.context.network.request({
      endpoint: '/api/admin/generate/gpt',
      method: 'POST',
      body: { 
        key,
        prompt 
      },
      onSuccess: () => {
        this.setState({
          status: 'generated'
        })
      }
    })
  }

  _handleJoin() {
    const { admin } = this.context
    const { key } = this.state
    const { team } = admin
    this.context.network.subscribe({ 
      channel: `/teams/${team.id}/admin/generate/gpt/${key}`,
      action: 'content', 
      handler: this._handleContent 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { key } = this.state
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}/admin/generate/gpt/${key}`,
      action: 'content', 
      handler: this._handleContent 
    })
  }

  _handleOptions() {
    const { options } = this.state
    this.setState({
      options: !options
    })
  }

  _handleStop() {
    this.setState({
      status: 'generated'
    })
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default GenerateField
