import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const options = {
  registration: { title: 't(Register Domain)', description: 't(Register a new domain name)' },
  transfer: { title: 't(Transfer Domain)', description: 't(Transfer an existing domain name)' },
  dns: { title: 't(Manage DNS)', description: 't(Manage DNS for an existing domain)' }
}

const DomainTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ options[value].title } /><br /></strong>
    <T text={ options[value].description } />
  </div>
)

DomainTypeToken.propTypes = {
  value: PropTypes.string
}

export default DomainTypeToken
