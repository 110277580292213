import EmailClient from '@apps/email/admin/components/email_client'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 'Email',
  component: <EmailClient profile={ resources.profile } id={ props.params.id } mode="show" />
})

const mapResourcesToPage = (props, context) => ({
  profile: `/api/admin/profiles/${props.params.profile_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
