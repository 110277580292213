import Icon from '@admin/components/icon'
import Resumable from 'resumablejs'
import PropTypes from 'prop-types'
import React from 'react'

class Camera extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    device: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    icon: PropTypes.string,
    team: PropTypes.object,
    onAddAsset: PropTypes.func,
    onUpdateAsset: PropTypes.func
  }

  static defaultProps = {
    icon: 'camera'
  }

  buttonRef = React.createRef()
  input = null
  resumable = null

  _handleAdd = this._handleAdd.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    const { icon } = this.props
    return (
      <div className="maha-camera" ref={ this.buttonRef }>
        <Icon icon={ icon } />
      </div>
    )
  }

  componentDidMount() {
    const { admin, device } = this.context
    const { token } = admin
    this.resumable = new Resumable({
      target: '/api/admin/assets/upload',
      chunkSize: device.getChunkSize(),
      permanentErrors: [204, 400, 404, 409, 415, 500, 501],
      headers: {
        'Authorization': `Bearer ${token}`
      },
      maxFiles: 1,
      fileType: ['jpg','png','gif','jpeg']
    })
    this.resumable.on('fileAdded', this._handleAdd)
    this.resumable.on('fileSuccess', this._handleSuccess)
    this.resumable.assignBrowse(this.buttonRef.current)
    this.input = this.buttonRef.current.lastChild
    this.input.setAttribute('capture', true)
    this.input.setAttribute('accept', 'image/*')
  }

  _handleAdd(file) {
    this.resumable.upload()
    this.props.onAddAsset({ file })
  }

  _handleSuccess(file, message) {
    const response = JSON.parse(message)
    const asset = response.data
    this.resumable.removeFile(file)
    this.props.onUpdateAsset(file.uniqueIdentifier, asset)
  }

}

export default Camera
