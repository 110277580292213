import ReactHtmlParser from 'react-html-parser'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Html extends React.Component {

  static propTypes = {
    html: PropTypes.string
  }

  _handleTransform = this._handleTransform.bind(this)

  render() {
    const { html } = this.props
    return ReactHtmlParser(html, {
      transform: this._handleTransform
    })
  }

  _handleTransform(node, index) {
    if(node.type === 'tag') {
      const style = this._getStyle(node.attribs.style)
      const attribs = _.omit(node.attribs, ['style'])
      if(node.name === 'img') {
        return <Img key={`node_${index}`} { ...attribs } />
      }
      if(node.name === 'a') {
        return (
          <a key={`node_${index}`} style={ style } { ...attribs } target="_blank" rel="noreferrer">
            { node.children.map((child, j) => {
              return this._handleTransform(child, j)
            }) }
          </a>
        )
      }
    } else if(node.data) {
      return <T text={ node.data } />
    }
  }

  _getStyle(style) {
    if(!style) return null
    const declarations = style.split(';')
    return declarations.reduce((style, declaration) => {
      if(declaration.length === 0) return style
      const parts = declaration.split(':')
      return {
        ...style,
        [_.camelCase(parts[0].trim())]: parts[1].trim()
      }
    }, {})
  }

}

export default Html
