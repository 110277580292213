import VariantToken from '@apps/stores/admin/tokens/variant'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Items extends React.Component {

  static propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.type
  }

  render() {
    const { items, title, type } = this.props
    if(items.length === 0) return null
    const total = items.reduce((total, item) => {
      return total + item.quantity
    }, 0.00)
    return (
      <div className={`store-order-panel ${type}`}>
        <div className="store-order-panel-header">
          <T text={ title } /> ({ total })
        </div>
        <div className="store-order-items">
          <div className="maha-table">
            <table>
              <tbody>
                { items.map((item, index) => (
                  <tr key={`item_${index}`}>
                    <td className="unpadded">
                      <VariantToken product={ item.variant.product } variant={ item.variant } />
                    </td>
                    <td className="right">{ numeral(item.price).format('0.00') } x { item.quantity }</td>
                    <td className="right">{ numeral(item.price * item.quantity).format('0.00') }</td>
                  </tr>
                )) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

}

export default Items
