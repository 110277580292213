import Page from '@admin/components/page'
import PhoneNumbers from './phone_numbers'
import Overview from './overview'
import Program from './program'
import Emails from './emails'
import Calls from './calls'
import SMS from './smses'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.usage.period,
  manager: {
    path: `/admin/team/usage/${resources.usage.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview usage={ resources.usage } />
      },
      {
        label: 't(Programs)',
        show: context.admin.team.has_programs,
        path: '/programs',
        panel: <Program usage={ resources.usage } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails usage={ resources.usage } />
      },
      {
        label: 't(SMS)',
        path: '/smses',
        access: { rights: { $oneOf: ['phone:access_app'] } },
        panel: <SMS usage={ resources.usage } />
      },
      {
        label: 't(Calls)',
        path: '/calls',
        access: { rights: { $oneOf: ['phone:access_app'] } },
        panel: <Calls usage={ resources.usage } />
      },
      {
        label: 't(Numbers)',
        path: '/phone_numbers',
        access: { rights: { $oneOf: ['phone:access_app'] } },
        panel: <PhoneNumbers usage={ resources.usage } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  usage: `/api/admin/team/usage/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
