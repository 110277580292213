const messages = {
  accepted: 'This field must be yes, on, true, or 1',
  email: 'This field must be a valid email address',
  exactLength: 'This field must be exactly {{var_1}} characters long',
  exists: 'This field must be defined',
  required: 'This field is required',
  minLength: 'This field must be at least {{var_1}} characters long',
  maxLength: 'This field must not exceed {{var_1}} characters long',
  lessThan: 'This field must be a number less than {{var_1}}',
  lessThanEqualTo: 'This field must be a number less than or equal to {{var_1}}',
  greaterThan: 'This field must be a number greater than {{var_1}}',
  greaterThanEqualTo: 'This field must be a number greater than or equal to {{var_1}}',
  between: 'This field must be a number between {{var_1}} and {{var_2}}',
  range: 'This field must be a number equal or larger than {{var_1}} and equal or smaller than {{var_2}}',
  contains: 'This field must contain {{var_1}}',
  string: 'This field must be type "string"',
  numeric: 'This field must be a numeric value',
  matchesField: 'This field must exactly match the {{var_1}}',
  different: 'This field must be different than the {{var_1}}',
  isPlainObject: 'This field must be a plain object',
  date: 'This field must be a Date',
  equal: 'This field does not match {{var_1}}',
  'boolean': 'This field must be type "boolean"',
  empty: 'This field must be empty',
  array: 'This field must be an array',
  'null': 'This field must be null',
  'NaN': 'This field must be NaN',
  finite: 'This field must be a finite number',
  'function': 'This field must be a function',
  'arguments': 'This field must be a javascript "arguments" object',
  regExp: 'This field must be a javascript RegExp object',
  alpha: 'This field must only contain alphabetical characters',
  alphaDash: 'This field must only contain alpha-numeric characters, underscores, and dashes',
  alphaNumeric: 'This field must only contain alpha-numeric characters',
  alphaUnderscore: 'This field must only contain alpha-numeric characters, underscores, and dashes',
  natural: 'This field must be a positive number',
  naturalNonZero: 'This field must be a number greater than zero',
  integer: 'This field must be a valid integer',
  ipv4: 'This field must be a valid IPv4 string',
  ipv6: 'This field must be a valid IPv6 address',
  base64: 'This field must be a base64 string',
  luhn: 'This field must be a valid credit card number',
  uuid: 'This field must be a valid uuid',
  fallback: 'Validation for {{label}} did not pass'
}

export default messages
