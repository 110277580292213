import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Process from './process'
import Failure from './failure'
import Success from './success'
import React from 'react'

class Batch extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    batch_action: PropTypes.string,
    batch_type: PropTypes.string,
    component: PropTypes.any,
    filter: PropTypes.object,
    title: PropTypes.string,
    onDone: PropTypes.func
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleFailure = this._handleFailure.bind(this)
  _handleConfigure = this._handleConfigure.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleProcess = this._handleProcess.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { component } = this.props
    if(component) return this._handleConfigure(component)
    this._handleProcess()
  }

  _getStack() {
    return {
      display_name: 'batch',
      ref: this.stackRef
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleConfigure(component) {
    this._handlePush(component, {
      onCancel: this._handleCancel, 
      onDone: this._handleProcess
    })
  }

  _handleDone() {
    this.props.onDone()
    this.context.modal.close()
  }

  _handleFailure(error) {
    const { title } = this.props
    this._handlePush(Failure, {
      title,
      error,
      onDone: this._handleDone
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleProcess(config = {}) {
    const { batch_action, batch_type, filter, title } = this.props
    this.context.network.request({
      endpoint: '/api/admin/batch',
      method: 'POST',
      body: {
        config,
        batch_action,
        batch_type,
        filter
      },
      onSuccess: ({ data }) => {
        this._handlePush(Process, {
          batch: data,
          title,
          onFailure: this._handleFailure,
          onSuccess: this._handleSuccess
        })
      }
    })
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSuccess() {
    const { title } = this.props
    this._handlePush(Success, {
      title,
      onDone: this._handleDone
    })
  }

}

export default Batch