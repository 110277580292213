import PropTypes from 'prop-types'
import React from 'react'

class Merge extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object
  }

  render() {
    const { activity } = this.props
    const { display_name, email, phone, address } = activity.data
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Display Name</td>
                <td>{ display_name }</td>
              </tr>
              { email &&
                <tr>
                  <td>Email Address</td>
                  <td>{ email }</td>
                </tr>
              }
              { phone &&
                <tr>
                  <td>Phone Number</td>
                  <td>{ phone }</td>
                </tr>
              }
              { address &&
                <tr>
                  <td>Mailing Address</td>
                  <td>{ address.description }</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

export default Merge
