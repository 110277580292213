import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Features extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    team: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { team } = this.props
    return {
      title: 't(Manage Features)',
      method: 'PATCH',
      endpoint: `/api/admin/platform/teams/${team.id}/edit`,
      action: `/api/admin/platform/teams/${team.id}/features`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'features', type: 'textarea' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Features
