import OmniSearch from '@admin/components/omnisearch'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Search)',
  component: <OmniSearch />
})

export default Page(null, mapPropsToPage)
