import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import Outgoing from './outgoing'
import Incoming from './incoming'
import Inactive from './inactive'
import Active from './active'
import React from 'react'

class Calls extends React.Component {

  static propTypes = {
    calls: PropTypes.array,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleDebug = this._handleDebug.bind(this)

  render() {
    const unfocused = this._getCalls(false)
    const focused = this._getCalls(true)
    return (
      <ModalPanel { ...this._getPanel() }>
        { unfocused.map((call, index) => (
          <Inactive key={`call_${index}`} call={ call } />
        )) }
        { focused.map((call, index) => (
          <div className="maha-handset-receiver" key={`call_${index}`}>
            { call.contact_status === 'in-progress' && (call.program_status === 'in-progress' || call.program_device === 'mobile' || call.held || call.transferring) &&
              <Active { ...this._getCall(call) } />
            }
            { !call.transferring && call.contact_status === 'in-progress' && call.program_status === 'ringing' &&
              <Incoming { ...this._getCall(call) } />
            }
            { !call.transferring && call.call.direction === 'outbound' && call.contact_status !== 'in-progress' &&
              <Outgoing { ...this._getCall(call) } />
            }
          </div>
        )) }
        { process.env.MAHA_ENV === 'development' &&
          <div className="maha-handset-debug" onClick={ this._handleDebug }>
            Debug Calls
          </div>
        }
      </ModalPanel>
    )
  }

  _getActive() {
    return this.props.calls.find(call => {
      return call.active
    })
  }

  _getCalls(focused) {
    return this.props.calls.filter(call => {
      return call.focused === focused
    })
  }

  _getCall(call) {
    const { onPop, onPush } = this.props
    return {
      call,
      onPop,
      onPush
    }
  }

  _getPanel() {
    return {
      title: 't(Call)'
    }
  }

  _handleDebug() {
    this.props.calls.map(call => console.log(call))
  }

}

export default Calls
