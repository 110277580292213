import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useModalContext } from '@admin/components/modal'
import ProductField from './productfield'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const ProductFieldContainer = (props) => {

  const { modal } = useModalContext()
  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'productfield',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      modal,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <ProductField { ...mergedProps } />

}

ProductFieldContainer.propTypes = {}

export default ProductFieldContainer