import { toFilter, toDisplay} from './utils'
import { useMemo } from 'react'

export const criteria = (state) => {
  return useMemo(() => {
    return toFilter(state.items, null)
  }, [state.items])
}

export const display = (state) => {
  return useMemo(() => {
    return toDisplay(state.items, null)
  }, [state.items])
}

export const test = (state) => {
  return useMemo(() => {
    return state.test_item ? [
      ...state.items.filter(item => {
        return item.code !== state.test_item.code
      }),
      state.test_item
    ] : state.items
  }, [state.test_item, state.items])
}