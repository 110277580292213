import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Call extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    platform: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    note: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact, note } = this.props
    return {
      title: 't(Edit Note)',
      method: 'PATCH',
      endpoint: `/api/admin/crm/contacts/${contact.id}/notes/${note.id}/edit`,
      action: `/api/admin/crm/contacts/${contact.id}/notes/${note.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Note)', name: 'text', type: 'textarea', placeholder: 'Leave a note', required: true },
            { label: 't(Attachments)', name: 'asset_ids', type: 'attachmentfield', multiple: true, prompt: 'Attach file(s)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Call
