import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

const Checkin = ({ event, session, ticket }, { network }) => {

  const button = {
    icon: 'check',
    size: 'mini',
    color: ticket.is_checked ? 'green' : null,
    fluid: false,
    request: {
      endpoint: `/api/admin/events/events/${event.id}/sessions/${session.id}/attendings`,
      method: ticket.is_checked ? 'delete' : 'post',
      body: {
        code: ticket.code
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to check in/out)')
    }
  }

  return (
    <div className="event-checkin">
      <Button { ...button } />
    </div>
  )

}

Checkin.propTypes = {
  event: PropTypes.object,
  session: PropTypes.object,
  ticket: PropTypes.object
}

Checkin.contextTypes = {
  network: PropTypes.object
}

export default Checkin
