export const onBegin = () => ({
  type: 'BEGIN'
})

export const onEnd = () => ({
  type: 'END'
})

export const onType = (q)=> ({
  type: 'TYPE',
  q
})

export const onAbort = () => ({
  type: 'ABORT'
})
