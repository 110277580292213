import PropTypes from 'prop-types'
import React from 'react'

const PaymentToken = (registration) => {
  const value = registration.canceled_at ? 'canceled' : registration.is_paid ? 'paid' : 'unpaid'
  return <div className={`payment-token ${value}`}>{ value }</div>
}

PaymentToken.propTypes = {
  value: PropTypes.bool
}

export default PaymentToken
