import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import DisplayField from '@admin/components/mjson_designer/components/displayfield'
import React from 'react'

export const Display = (props) => <ResponsiveField { ...props } component={ DisplayField } />

export const DisplaySection = (config, device, theme, website, type) => (
  {
    label: 't(Display)',
    instructions: 't(Specify how child elements should behave within this element)',
    collapsing: true,
    collapsed: true,
    fields: [
      {
        name: 'styles.display', 
        type: Display,
        defaultValue: { display: 'block' },
        value: config.styles.display
      }
    ]
  })
