import OptOutToken from '@apps/crm/admin/tokens/optout'
import OptInToken from '@apps/crm/admin/tokens/optin'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Consent extends React.Component {

  static propTypes = {
    program: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { program, onBack } = this.props
    const { config } = this.state
    return {
      title: 't(Update Consent)',
      saveText: 'Next',
      ...onBack ? {
        cancelIcon: 'chevron-left',
        onCancel: this._handleBack
      } : {
        onCancel: this._handleCancel
      },
      onChange: this._handleChange,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program.id },
            { label: 't(Consent)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'optin', text: 't(Add Consent)' },
                { value: 'optout', text: 't(Remove Consent)' }
              ], defaultValue: 'optin' },
              { label: 't(Channels)', name: 'channel_types', type: 'checkboxgroup', options: ['email','voice','sms','postal'], valueKey: 'value', textKey: 'text', required: true },
              { label: 't(Reason)', name: 'optin_reason', show: config.strategy === 'optin', type: 'radiogroup', options: ['consent','contract','legal obligation','vital interests','public interest','legitimate interest'], format: OptInToken, defaultValue: 'consent' },
              { label: 't(Reason)', name: 'optout_reason', show: config.strategy === 'optout', type: 'radiogroup', options: ['done','never','inappropriate','spam','other'], format: OptOutToken, defaultValue: 'done' },
              { name: 'restore_consent', type: 'checkbox', show: config.strategy === 'optin', prompt: 't(Restore consent if contact was previously opted out)' }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

export default Consent
