import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Programs from './programs'
import React from 'react'

class ProgramForm extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    form: PropTypes.any,
    props: PropTypes.object,
    requires: PropTypes.array,
    onDone: PropTypes.func
  }

  static defaultProps = {
    requires: []
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleProgram = this._handleProgram.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack()} />
  }

  componentDidMount() {
    const { admin } = this.context
    if(!admin.team.has_programs) return this._handleProgram(admin.programs[0])
    this._handlePush(Programs, this._getPrograms())
  }

  _getPrograms() {
    const { requires } = this.props
    return {
      requires,
      onCancel: this._handleCancel,
      onChoose: this._handleProgram
    }
  }

  _getStack() {
    return {
      display_name: 'programform',
      ref: this.stackRef
    }
  }

  _getForm(program) {
    const { admin } = this.context
    const { props, onDone } = this.props
    return {
      ...props,
      program,
      program_id: program.id,
      ...admin.team.has_programs ? {
        onBack: this._handlePop
      } : {
        onCancel: this._handleCancel
      },
      onDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleProgram(program) {
    const { form } = this.props
    this._handlePush(form, this._getForm(program))
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default ProgramForm
