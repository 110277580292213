import AddressField from './address_field'
import PropTypes from 'prop-types'
import React from 'react'

const AddressFieldContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
  }))

  return <AddressField ref={ componentRef } {...props} />

})

export default AddressFieldContainer
