import Payment from '@apps/finance/admin/views/invoices/payment'
import Order from '@apps/stores/admin/components/order'
import Panel from '@admin/components/panel'
import Fulfill from './fulfill'
import Cancel from './cancel'
import Refund from './refund'
import React from 'react'

const mapPropsToPanel = ({ invoice, order, store }, context) => ({
  title: 't(Overview)',
  component: <Order order={ order } store={ store } invoice={ invoice } />,
  tasks: order.status !== 'canceled' ? {
    items: [
      {
        label: 'Cancel Order',
        show: order.unfulfilled_count === order.items_count,
        modal: <Cancel store={ store } order={ order } />
      }, 
      {
        label: 'Receive Payment',
        show: invoice.status === 'unpaid',
        modal: <Payment invoice_id={ invoice.id } contact_id={ order.contact.id } />
      }, 
      {
        label: 'Fulfill Order',
        show: order.unfulfilled_count > 0,
        modal: <Fulfill store_id={ store.id } order_id={ order.id } />
      }, 
      {
        label: 'Refund Order',
        show: order.items.filter(item => {
          return _.includes(['refunded','restocked'], item.status)
        }).length < order.items_count,
        modal: <Refund store={ store } order={ order } />
      }, 
      {
        label: 'Download Invoice',
        url: `${process.env.ADMIN_HOST}/finance/invoices/${invoice.code}/download`
      }
    ]
  } : null
})

const mapResourcesToPanel = (props, context) => ({
  invoice: `/api/admin/finance/invoices/${props.order.invoice_id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
