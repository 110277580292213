import EmailCampaigns from './email_campaigns'
import Workflows from './automation/workflows'
import VoiceCampaigns from './voice_campaigns'
import Page from '@admin/components/page'
import SMSCampaigns from './sms_campaigns'
import Emails from './automation/emails'
import Agreements from './agreements'
import Properties from './properties'
import Overview from './overview'
import Stages from './stages'
import Deals from './deals'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.pipeline.title,
  manager: {
    path: `/admin/sales/pipelines/${resources.pipeline.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview pipeline={ resources.pipeline } />
      },
      {
        label: 'Stages',
        path: '/stages',
        panel: <Stages pipeline={ resources.pipeline } />
      },
      {
        label: 'Properties',
        path: '/properties',
        panel: <Properties pipeline={ resources.pipeline } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows pipeline={ resources.pipeline } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails pipeline={ resources.pipeline } />
          }
        ]
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns pipeline={ resources.pipeline } />
          },
          {
            label: 't(SMS)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } }
              ] 
            },
            path: '/sms_campaigns',
            panel: <SMSCampaigns pipeline={ resources.pipeline } />
          },
          {
            label: 't(Voice)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } }
              ] 
            },
            path: '/voice_campaigns',
            panel: <VoiceCampaigns pipeline={ resources.pipeline } />
          }
        ]
      },
      {
        label: 't(Agreements)',
        path: '/agreements',
        show: context.admin.hasFeature('agreements'),
        panel: <Agreements pipeline={ resources.pipeline } />
      },
      {
        label: 'Deals',
        path: '/deals',
        panel: <Deals fields={ resources.fields } pipeline={ resources.pipeline } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  pipeline: `/api/admin/sales/pipelines/${props.params.id}`,
  fields: `/api/admin/sales_pipelines/${props.params.id}/fields`
})

export default Page(mapResourcesToPage, mapPropsToPage)
