import { devices, scales } from './variables'
import PropTypes from 'prop-types'
import React from 'react'

class Body extends React.Component {

  static propTypes = {
    children: PropTypes.any,
    device: PropTypes.string,
    orientation: PropTypes.string,
    scale: PropTypes.number,
    onChange: PropTypes.func
  }

  render() {
    const { children } = this.props
    return (
      <div className={ this._getClass() }>
        <div { ...this._getFrame() }>
          { children }
        </div>
      </div>
    )
  }

  _getClass() {
    const { device } = this.props
    return `preview-body ${device}`
  }

  _getFrame() {
    const { scale } = this.props
    const portrait = this.props.orientation === 'portrait'
    const device = _.find(devices, { value: this.props.device })
    return {
      className: 'preview-frame',
      style: device.value !== 'desktop' ? {
        width: (portrait ? device.width : device.height) * scale,
        height: (portrait ? device.height : device.width) * scale
      } : null
    }
  }

}

export default Body
