import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    email: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { email } = this.props
    return {
      title: 't(Edit Email)',
      method: 'PATCH',
      endpoint: `/api/admin/automation/emails/${email.id}/edit`,
      action: `/api/admin/automation/emails/${email.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Email Details)', type: 'segment', fields: [
              { label: 't(Template)', name: 'template_id', type: 'dropdown', endpoint: `/api/admin/team/programs/${email.program.id}/templates`, valueKey: 'id', textKey: 'title', required: true },
              { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: 't(Choose Sender)', endpoint: `/api/admin/team/programs/${email.program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true },
              { label: 't(CC)', name: 'meta.cc', type: 'textfield', placeholder: 't(Enter comma separated list of emails)' },
              { label: 't(BCC)', name: 'meta.bcc', type: 'textfield', placeholder: 't(Enter comma separated list of emails)' },
              { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, required: true },
              { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
