import useContainer from '@admin/hooks/use_container'
import PropTypes from 'prop-types'
import Badge from './badge'
import React from 'react'

const BadgeContainer = (props) => {

  const container = props.channel ? useContainer({
    display_name: 'badge',
    resources: {
      count: `/api${props.channel}`
    }
  }) : {}

  const mergedProps = {
    count: container?.values?.count || 0,
    ...props
  }

  return <Badge { ...mergedProps } />

}

BadgeContainer.propTypes = {
  channel: PropTypes.string
}

export default BadgeContainer