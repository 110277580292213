import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const CompactCampaignToken = ({ sms_campaign, compact }) => (
  <div className="campaign-token">
    { compact !== true &&
      <div className="campaign-token-logo">
        <Logo team={ sms_campaign.program } width="24" />
      </div>
    }
    <div className="campaign-token-label">
      <div className="token-link">
        { sms_campaign.title || sms_campaign.term || sms_campaign.phone_number.formatted }
      </div>
      <div className="token-stats">
        <span className={ sms_campaign.status }>{ sms_campaign.status }</span>
        { sms_campaign.status === 'sent' &&
          <>
            <span>{ sms_campaign.sessions_count } sessions</span>
            <span>{ sms_campaign.active_count } active</span>
            <span>{ sms_campaign.lost_count } lost</span>
            <span>{ sms_campaign.converted_count } converted</span>
            <span>{ sms_campaign.completed_count } completed</span>
          </>
        }
      </div>
    </div>
  </div>
)

CompactCampaignToken.propTypes = {
  compact: PropTypes.bool,
  sms_campaign: PropTypes.object
}

export default CompactCampaignToken
