import { getIntegrationTask } from './integration'

const itemButtons = (settings, type, item, context) => {

  const { admin } = context

  const { team, user } = admin

  if(item.status === 'pending' && user.id === item.user.id) {

    return [
      {
        color: 'red',
        text: 't(Submit for Approval)',
        disabled: item.status === 'incomplete',
        request: {
          endpoint: `/api/admin/finance/${type}s/${item.id}/submit`,
          method: 'PATCH'
        }
      }
    ]

  } else if(item.status === 'submitted' && _.includes(item.approver_ids, user.id) && item.user.id !== user.id) {

    return [
      {
        color: 'red',
        text: 't(Reject)',
        request: {
          endpoint: `/api/admin/finance/${type}s/${item.id}/reject`,
          method: 'PATCH'
        }
      },{
        color: 'green',
        text: 't(Approve)',
        request: {
          endpoint: `/api/admin/finance/${type}s/${item.id}/approve`,
          method: 'PATCH'
        }
      }
    ]

  } else if(item.status === 'approved' && context.admin.hasRights({ allOf: ['finance:manage_configuration'] })) {

    return [
      {
        color: 'red',
        text: 't(Reject)',
        request: {
          endpoint: `/api/admin/finance/${type}s/${item.id}/reject`,
          method: 'PATCH'
        }
      },{
        color: 'pink',
        text: 't(Review)',
        request: {
          endpoint: `/api/admin/finance/${type}s/${item.id}/review`,
          method: 'PATCH'
        }
      }
    ]

  } else if(item.status === 'reviewed' && context.admin.hasRights({ allOf: ['finance:manage_configuration'] })) {

    return getIntegrationTask(settings.integration, team, context, type, item.code, () => {})

  }

  return null

}

export default itemButtons
