import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sending Rates)',
  collection: {
    endpoint: `/api/admin/truevail/admin/domain_segments/${props.domain_segment.id}/sending_rates`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Date)', key: 'date', primary: true, format: 'date' },
      { label: 't(Rate)', key: 'sending_rate', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'date', order: 'desc' },
    empty: {
      icon: 'cog',
      title: 't(No Sending Rates)',
      text: 't(There are no sending_rates for this domain segment)'
    },
    entity: 'sending_rates'
  }
})

export default Panel(null, mapPropsToPanel)
