
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Blocked extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      flash: PropTypes.object,
      host: PropTypes.object,
      network: PropTypes.object,
      provider: PropTypes.object,
      router: PropTypes.object
    })
  }

  render() {
    const { provider } = this.props.context
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h1><Icon icon="ban" /></h1>
            <h2><T text="Your Account is Blocked" /></h2>
            <p>
              Your account has been blocked from signing in. Please contact
              us at <a href={`mailto:${ provider.support_email }`}>{ provider.support_email }</a> to
              rehabilitate your account.
            </p>
          </div>
        </div>
      </div>
    )
  }

}

export default Blocked
