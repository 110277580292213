import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ product, store }) => {

  const storebutton = {
    label: store.title,
    className: 'link',
    route: `/admin/stores/stores/${store.id}`
  }

  const details = {
    audits: `stores_products/${product.id}`,
    comments: `stores_products/${product.id}`,
    sections: [
      {
        title: 't(Product Details)',
        items: [
          { label: 't(Store)', content: <Button { ...storebutton } /> },
          { label: 't(Title)', content: product.title },
          { label: 't(Type)', content: product.type },
          { label: 't(Categories)', content: product.categories.map(category => category.title).join(', ') },
          { label: 't(Description)', content: product.description, format: 'raw' }
        ]
      }
    ]
  }

  if(product.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This product was deleted)' }
  } else if(!product.is_active) {
    details.alert = { color: 'red', message: 't(This product is inactive)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  product: PropTypes.object,
  store: PropTypes.object
}

export default Overview
