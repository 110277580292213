import AlignmentField from './alignment_field'
import PropTypes from 'prop-types'
import React from 'react'

const AlignmentFieldContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
  }))

  return <AlignmentField ref={ componentRef } {...props} />

})

export default AlignmentFieldContainer
