import PresenceToken from '@admin/tokens/presence'
import Image from '@admin/components/image'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Avatar extends React.Component {

  static propTypes = {
    host: PropTypes.string,
    icon: PropTypes.string,
    presence: PropTypes.bool,
    style: PropTypes.string,
    title: PropTypes.any,
    user: PropTypes.object,
    width: PropTypes.any,
    onFail: PropTypes.func,
    onLoad: PropTypes.func
  }

  static defaultProps = {
    host: null,
    presence: true,
    style: 'circular',
    width: 150,
    user: {},
    onFail: () => {},
    onLoad: () => {}
  }

  _handleLoad = this._handleLoad.bind(this)
  _handleFail = this._handleFail.bind(this)

  render() {
    const { icon, presence, style, user } = this.props
    const { initials, photo } = user
    if(!user) return <div className="maha-avatar" />
    return (
      <div className={`maha-avatar ${style}`}>
        <div className="maha-avatar-badge">
          <div className="maha-avatar-wrapper">
            { photo &&  <Image { ...this._getImage() } /> }
            { !photo &&
              <div className="maha-avatar-text-wrapper">
                { initials ?
                  <div className="maha-avatar-text">{ initials }</div> :
                  <div className="maha-avatar-text">
                    <Icon icon={ icon || 'user' } />
                  </div>
                }
              </div>
            }
          </div>
        </div>
        { presence && <PresenceToken user={ user } />}
      </div>
    )
  }

  _getImage() {
    const { host, user } = this.props
    const width = parseInt(this.props.width)
    return {
      host,
      src: user.photo,
      title: this._getTitle(),
      alt: user.initials,
      transforms: {
        fit: 'cover',
        w: width,
        h: width
      },
      onLoad: this._handleLoad
    }
  }

  _getTitle() {
    const { title, user } = this.props
    if(title === false) return null
    return title || user.full_name
  }

  _handleLoad() {
    this.props.onLoad()
  }

  _handleFail() {
    this.props.onFail()
  }

}

export default Avatar
