import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ domain }, { admin }) => {

  const now = moment.tz(domain.timezone.zone).format('YYYY-MM-DD')
  const start_time = moment.tz(`${now} ${domain.start_time}`, domain.timezone.zone).tz(admin.account.timezone)
  const end_time = moment.tz(`${now} ${domain.end_time}`, domain.timezone.zone).tz(admin.account.timezone)

  const details = {
    sections: [
      {
        title: 't(Domain Segment)',
        items: [
          { label: 't(Name)', content: domain.name },
          { label: 't(Sending Range)', content: `${start_time.format('hh:mm A z')} - ${end_time.format('hh:mm A z')}` },
          { label: 't(Start Time)', content: domain.start_time },
          { label: 't(End Time)', content: domain.end_time },
          { label: 't(Timezone)', content: domain.timezone.zone }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  domain: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview domain={ props.domain } />,
  tasks: {
    items: [
      {
        label: 't(Edit Domain)',
        modal: <Edit domain={ props.domain } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
