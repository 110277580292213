import Page from '@admin/components/page'
import Overview from './overview'
import Tickets from './tickets'
import Emails from './emails'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: `Registration #${resources.registration.id}`,
  manager: {
    path: `/admin/events/events/${resources.event.id}/registrations/${resources.registration.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview event={ resources.event } contact_id={ resources.registration.contact.id } registration={ resources.registration } />
      }, {
        label: 't(Tickets)',
        path: '/tickets',
        panel: <Tickets event={ resources.event } registration={ resources.registration } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails event={ resources.event } registration={ resources.registration } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  event: `/api/admin/events/events/${props.params.event_id}`,
  registration: `/api/admin/events/events/${props.params.event_id}/registrations/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
