import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import New from '../deposits/new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Deposits)',
  access: { rights: { $oneOf: ['finance:view_deposits','finance:manage_deposits'] } },
  collection: {
    endpoint: '/api/admin/finance/deposits',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Bank Account)', key: 'bank.title', sort:'bank', primary: true },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(Payments)', key: 'payments_count', collapsing: true, align: 'right' },
      { label: 't(Refunds)', key: 'refunds_count', collapsing: true, align: 'right' },
      { label: 't(Amount)', key: 'amount', collapsing: true, primary: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, align: 'center', format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 'Bank Account', key: 'bank_id', type: 'select', multiple: true, endpoint: '/api/admin/finance/banks', valueKey: 'id', textKey: 'title' },
            { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] },
            { name: 'Status', key: 'status', type: 'select', multiple: true, options: ['pending','exported'], format: StatusToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'date', order: 'desc' },
    onClick: (record) => context.router.push(`/finance/deposits/${record.id}`),
    empty: {
      icon: 'dollar',
      title: 't(No Deposits)',
      text: 't(You do not recveived any deposits)',
      buttons: [
        {
          label: 't(Create Deposit)',
          access: { rights: { $oneOf: ['finance:manage_deposits'] } },
          modal: New
        }
      ]
    },
    entity: 'deposits'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Deposit)',
    access: { rights: { $oneOf: ['finance:manage_deposits'] } },
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
