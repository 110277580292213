import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  serif: 'https://truevail.com/wp-content/uploads/2021/08/Serif.jpg',
  sans_serif: 'https://truevail.com/wp-content/uploads/2021/08/Sansserif.jpg',
  script: 'https://truevail.com/wp-content/uploads/2021/08/Script.jpg',
  retro: 'https://truevail.com/wp-content/uploads/2021/08/retro.jpg',
  techno: 'https://truevail.com/wp-content/uploads/2021/08/techno.jpg',
  stencil: 'https://truevail.com/wp-content/uploads/2021/08/stencil.jpg',
  pixelated: 'https://truevail.com/wp-content/uploads/2021/08/pixelated.jpg',
  typewriter: 'https://truevail.com/wp-content/uploads/2021/08/typewriter.jpg'
}

const FontStyleToken = ({ value }) => (
  <div className="token logo-style-token">
    <img src={ service_types[value] } />
  </div>
)

FontStyleToken.propTypes = {
  value: PropTypes.string
}

export default FontStyleToken
