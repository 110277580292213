import Page from '@admin/components/page'
import Renewals from './renewals'
import Overview from './overview'
import Emails from './emails'
import React from 'react'
import SMS from './sms'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Subscription)',
  manager: {
    path: `/admin/subscriptions/services/${resources.service.id}/subscriptions/${resources.subscription.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview service={ resources.service } contact_id={ resources.subscription.contact.id } subscription={ resources.subscription } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails service={ resources.service } subscription={ resources.subscription } />
      },
      {
        label: 't(SMS)',
        path: '/sms',
        access: {
          $and: [
            { rights: { $allOf: ['phone:access_app'] } },
            { rights: { $oneOf: ['campaigns:view_sms_campaigns','subscriptions:manage_sms_campaigns'] } }
          ]
        },
        panel: <SMS service={ resources.service } subscription={ resources.subscription } />
      },
      {
        label: 't(Renewals)',
        path: '/renewals',
        panel: <Renewals service={ resources.service } subscription={ resources.subscription } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  service: `/api/admin/subscriptions/services/${props.params.service_id}`,
  subscription: `/api/admin/subscriptions/services/${props.params.service_id}/subscriptions/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
