module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<!-- Embed Tag -->\n")
    ;  if (locals.preamble) { 
    ; __append( preamble )
    ; __append("\n")
    ;  } 
    ; __append("<script async src=\"")
    ; __append(escapeFn( asset_root ))
    ; __append("/sdk.js\"></script>\n<script>\n  window.maha=window.maha||(function () {(maha.q=maha.q||[]).push(arguments)});\n")
    ;  for (let action of (locals.actions || [])) { 
    ; __append("  maha(\"")
    ; __append(escapeFn( action.module ))
    ; __append("\", \"")
    ; __append(escapeFn( action.method))
    ; __append("\"")
    ; __append( action.args ? ', ' + action.args.join(', ') : '')
    ; __append(");\n")
    ;  } 
    ; __append("</script>\n<!-- End Embed Tag -->")
  }
  return __output;

}