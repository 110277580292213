import ModalPanel from '@admin/components/modal_panel'
import List from '@admin/components/list'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import tasks from './tasks'
import React from 'react'

class Overview extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    configuration: PropTypes.object,
    intro: PropTypes.object,
    mode: PropTypes.string,
    requirements: PropTypes.array,
    title: PropTypes.string,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onTask: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleTask = this._handleTask.bind(this)
  
  render() {
    const { intro } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-overview">
          { intro &&
            <div className="truevail-configure-overview-header">
              { intro }
            </div>
          }
          <List { ...this._getList() } />
        </div>
      </ModalPanel>
    )
  }

  _getList() {
    const { configuration, onTask } = this.props
    return {
      items: this._getTasks(),
      format: (task) => (
        <div className="truevail-configure-task-token-details">
          <div className="truevail-configure-task-token-description">
            <strong><T text={ task.title } /></strong> ({task.estimate})<br />
            <T text={ task.description } />
          </div>
          <div className={`truevail-configure-task-token-status ${configuration[`${task.code}_status`] || 'incomplete'}`}>
            { configuration[`${task.code}_status`] || 'incomplete' }
          </div>
        </div>
      ),
      onClick: onTask
    }
  }

  _getTasks() {
    const { requirements } = this.props
    return tasks.filter(item => {
      return _.includes(requirements, item.code)
    })
  }

  _getPanel() {
    const { configuration, mode, requirements, title } = this.props
    const { team } = this.context.admin
    const completed =  requirements.reduce((completed, requirement) => {
      return !completed ? false : configuration[`${requirement}_status`] === 'completed'
    }, true)
    return {
      title,
      ...mode === 'edit' ? {
        leftItems: [
          {
            label: 't(Cancel)',
            handler: this._handleCancel
          }
        ],
        rightItems: [
          {
            label: 't(Done)',
            handler: this._handleCancel
          }
        ]
      } : team.status === 'active' ? {
        leftItems: [
          {
            label: 't(Cancel)',
            handler: this._handleCancel
          }
        ]
      } : {},
      ...mode !== 'edit' ? {
        buttons: [
          { 
            label: 't(Complete Setup)', 
            color: 'blue', 
            disabled: !completed, 
            handler: this._handleDone 
          }
        ]
      } : {}
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleTask(task) {
    this.props.onTask(task)
  }

}

export default Overview
