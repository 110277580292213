import SocialConversations from '@admin/components/social_conversations'
import Panel from '@admin/components/panel'
import React from 'react'

const getSocialConversations = () => ({
  endpoint: '/api/admin/campaigns/social/comments'
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_social_campaigns','campaigns:manage_social_campaigns'] } },
  component: <SocialConversations { ...getSocialConversations() } />
})

export default Panel(null, mapPropsToPanel)

