import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Cancel extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event } = this.props
    return {
      title: 't(Cancel Event)',
      method: 'PATCH',
      endpoint: `/api/admin/events/events/${event.id}/edit`,
      action: `/api/admin/events/events/${event.id}/cancel`,
      saveText: 'Proceed',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Reason)', name: 'cancellation_reason', type: 'textarea', placeholder: 'Why are you canceling this event?', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(store) {
    this.context.modal.close()
  }

}

export default Cancel
