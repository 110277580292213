import { useReactionsContext } from '@apps/maha/admin/roots/reactions'
import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import PropTypes from 'prop-types'
import Reactions from './reactions'
import React from 'react'

const ReactionsContainer = (props) => {

  const { admin } = useAdminContext()
  const { network } = useNetworkContext()
  const { reactions } = useReactionsContext()
  
  const mergedProps = {
    context: {
      admin,
      network,
      reactions
    },
    ...props
  }

  return <Reactions { ...mergedProps } />

}

ReactionsContainer.propTypes = {
  entity: PropTypes.string
}

export default ReactionsContainer