import Tokens from '@admin/components/tokens'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Preview from './preview'
import blocks from './blocks'
import Main from './main'
import React from 'react'

class Sidebar extends React.Component {

  static propTypes = {
    active: PropTypes.object,
    changes: PropTypes.number,
    config: PropTypes.object,
    email: PropTypes.object,
    program_id: PropTypes.number,
    settings: PropTypes.bool,
    status: PropTypes.string,
    tokens: PropTypes.array,
    onEdit: PropTypes.func,
    onSave: PropTypes.func,
    onUpdate: PropTypes.func
  }

  stackRef = React.createRef()

  _handleDone = this._handleDone.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePreview = this._handlePreview.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleReplace = this._handleReplace.bind(this)
  _handleTokens = this._handleTokens.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Main, this._getMain.bind(this))
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props
    if(!_.isEqual(active, prevProps.active)) {
      if(active.index !== null) this._handleEdit(prevProps.active.index !== null)
      if(active.index === null) this._handlePop()
    }
  }

  _getBlock() {
    const { active, config } = this.props
    const key = `[${active.section}].blocks[${active.index}]`
    return {
      config: _.get(config, key),
      onDone: this._handleDone,
      onTokens: this._handleTokens,
      onUpdate: this._handleUpdate.bind(this, key)
    }
  }

  _getMain() {
    const { changes, config, program_id, settings, status, onSave } = this.props
    return {
      blocks,
      changes,
      config,
      program_id,
      settings,
      status,
      onPush: this._handlePush,
      onPop: this._handlePop,
      onPreview: this._handlePreview,
      onSave,
      onTokens: this._handleTokens,
      onUpdate: this._handleUpdate
    }
  }

  _getPreview() {
    const { config, email } = this.props
    return {
      config,
      email,
      onBack: this._handlePop
    }
  }

  _getStack() {
    return {
      display_name: 'designer',
      ref: this.stackRef
    }
  }

  _getTokens() {
    const { tokens } = this.props
    return {
      tokens,
      onPop: this._handlePop
    }
  }

  _handleDone() {
    this.props.onEdit(null, null)
  }

  _handleEdit(replace) {
    const { active } = this.props
    const config = this.props.config[active.section].blocks[active.index]
    const { type } = config
    const block = _.find(blocks, { type })
    const push = replace ? this._handleReplace : this._handlePush
    push(block.component, this._getBlock())
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePreview() {
    this._handlePush(Preview, this._getPreview.bind(this))
  }

  _handleReplace(component, props) {
    this._handlePop()
    setTimeout(this._handlePush.bind(this, component, props), 300)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleTokens() {
    this._handlePush(Tokens, this._getTokens())
  }

  _handleUpdate(key, value) {
    const { config } = this.props
    this.props.onUpdate(key, {
      ..._.get(config, key),
      ...value
    })
  }

}

export default Sidebar
