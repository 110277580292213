import WorkflowToken from '@apps/automation/admin/tokens/workflow'
import New from '@apps/automation/admin/views/workflows/new'
import Panel from '@admin/components/panel'
import React from 'react'

const getNew = (props) => ({
  program_id: props.event.program.id,
  trigger: 'events:registration_created',
  params: {
    event_id: props.event.id
  }
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Workflows)',
  collection: {
    endpoint: '/api/admin/automation/workflows',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (workflow) => (
        <WorkflowToken workflow={ workflow } compact={ true } />
      ) }
    ],
    empty: {
      title: 't(No Workflows)',
      text: 't(There are no workflows for this event)',
      icon: 'sitemap',
      buttons: props.event.deleted_at === null ? [
        { 
          label: 't(Create Workflow)',
          access: {
            programs: { $canEdit: props.event.program.id },
            rights: { $allOf: ['automation:access_app','automation:manage_workflows','events:manage_events'] }            
          },
          modal: <New { ...getNew(props) } /> 
        }
      ] : null
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'display_name' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Enrolled)', key: 'enrolled_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    entity: 'worfklow',
    defaultQuery: { event_id: props.event.id },
    defaultSort: { key: 'title', order: 'asc' },
    onClick: (record) => context.router.push(`/automation/workflows/${record.id}`)
  },
  task: props.event.deleted_at === null ? {
    icon: 'plus',
    access: {
      programs: { $canEdit: props.event.program.id },
      rights: { $allOf: ['automation:access_app','automation:manage_workflows','events:manage_events'] }            
    },
    modal: <New { ...getNew(props) } />
  } : null
})

export default Panel(null, mapPropsToPanel)
