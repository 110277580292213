import PropTypes from 'prop-types'
import React from 'react'

export const StarsContext = React.createContext(null)
StarsContext.displayName = 'StarsContext'

export const useStarsContext = () => React.useContext(StarsContext)

class Stars extends React.Component {

  static childContextTypes = {
    stars: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    stars: PropTypes.object,
    onUpdate: PropTypes.func
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <StarsContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </StarsContext.Provider>
    )
  }

  componentDidMount() {
    this._handleJoin()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  getChildContext() { 
    const { stars } = this.props
    return {
      stars: {
        stars,
        update: this._handleUpdate
      }
    }
  }

  _handleJoin() {
    const { admin } = this.context
    const { user } = admin
    this.context.network.subscribe({ 
      channel: `/users/${user.id}`,
      action: 'update_stars',
      handler: this._handleUpdate 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { user } = admin
    this.context.network.unsubscribe({ 
      channel: `/users/${user.id}`,
      action: 'update_stars',
      handler: this._handleUpdate 
    })
  }

  _handleUpdate({ table, id, starred }) {
    this.props.onUpdate(table, id, starred)
  }

}

export default Stars
