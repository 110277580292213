import { DragSource, DropTarget } from 'react-dnd'
import Handle from '@admin/components/handle'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Resource extends React.PureComponent {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    resource: PropTypes.object,
    index: PropTypes.number,
    onEdit: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func,
    onToggle: PropTypes.func
  }

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, resource } = this.props
    return connectDropTarget(connectDragPreview(
      <div className="subscriptions-resourcesfield-resource">
        { connectDragSource(
          <div className="stagesfield-stage-handle">
            <Handle />
          </div>
        ) }
        <div className="subscriptions-resourcesfield-resource-details">
          { resource.title.value } ({ numeral(resource.price).format('0.00') } / unit) { !resource.is_active &&
            <span className="alert">INACTIVE</span>
          }
        </div>
        <Button { ...this._getEdit() } />
        <Button { ...this._getRemove() } />
      </div>
    ))
  }

  _getEdit() {
    return {
      icon: 'pencil',
      className: 'subscriptions-resourcesfield-resource-action',
      handler: this.props.onEdit
    }
  }

  _getRemove() {
    const { resource } = this.props
    return {
      className: 'subscriptions-resourcesfield-resource-action',
      ...resource.id ? {
        ...resource.is_active ? {
          confirm: 't(Once a resource has been created, it cannot be deleted, only deactivated. Are you sure you want to deactivate this resource?)'
        } : {},
        icon: resource.is_active ? 'times' : 'refresh',
        handler: this.props.onToggle
      } : {
        icon: 'times',
        confirm: 't(Are you sure you want to remove this resource?)',
        handler: this.props.onRemove
      }
    }
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const item = monitor.getItem()
    const dragIndex = item.index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Resource = DragSource('ITEM', source, sourceCollector)(Resource)
Resource = DropTarget('ITEM', target, targetCollector)(Resource)

export default Resource
