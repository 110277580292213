import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import React from 'react'

class Failure extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    error: PropTypes.string,
    onDone: PropTypes.func
  }

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Message { ...this._getFailure() } />
      </ModalPanel>
    )
  }

  _getFailure() {
    return {
      title: 't(Request Failed)',
      text: 't(We were unable to complete your request)',
      icon: 'warning',
      color: 'red',
      animation: 'shake',
      button: {
        label: 't(Close)',
        handler: this.props.onDone
      }
    }
  }

  _getPanel() {
    const { title } = this.props
    return {
      title
    }
  }

}

export default Failure
