import MJSONPreview from '@admin/components/mjson_preview'
import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ website }) => {

  const draft = {
    label: website.sandbox_host,
    className: 'link',
    link: website.sandbox_host
  }

  const link = {
    label: website.primary_host,
    className: 'link',
    link: website.primary_host
  }

  const details = {
    audits: `websites_websites/${website.id}`,
    comments: `websites_websites/${website.id}`,
    sections: [
      {
        title: 't(Website Details)',
        items: [
          { label: 't(Title)', content: website.title },
          { label: 't(Last Published)', content: website.published_at, format: 'datetime' }
        ]
      },
      {
        title: 't(Hosting Overview)',
        items: []
      }
    ]
  }

  if(website.status === 'active') {
    details.header = <MJSONPreview entity={ website } table="websites_websites" />
    details.sections[1].items.push({ label: 't(Staging Site)', content: <Button { ...draft } /> })
    details.sections[1].items.push({ label: 't(Live Site)', content: <Button { ...link } /> })
  } else {
    details.sections[1].items.push({ component: <Loader label="t(Configuring hosting...)" /> })

  }

  if(website.deleted_at) {
    details.alert = { color: 'red', message: 't(This website was deleted)' }
  } else if(website.status === 'configuring') {
    details.alert = { color: 'teal', message: 't(This hosting for this website is being configured)' }
  } else if(website.status === 'active') {
    details.alert = { color: 'green', message: 't(This website is actively accepting requests)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  website: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Website)',
  panel: <Overview website={ props.website } />,
  tasks: {
    items: [
      { label: 't(Design Website)', route: `/websites/${props.website.id}/design` },
      { label: 't(Edit Website)', modal: <Edit website={ props.website }/> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
