export const onAdd = (rule) => ({
  type: 'ADD',
  rule
})

export const onRemove = (index) => ({
  type: 'REMOVE',
  index
})

export const onUpdate = (index, rule) => ({
  type: 'REMOVE',
  index,
  rule
})

export const onSet = (rules) => ({
  type: 'SET',
  rules
})
