import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class TokenField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    focused: false,
    token: {
      token: '',
      value: ''
    }
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { token } = this.state
    const { disabled } = this.props
    if(token.value === null) return null
    return (
      <div className={ this._getClass() }>
        <div className="maha-tokenfield-field">
          <div className="maha-input">
            <div className="maha-input-field">
              <Input { ...this._getInput() } />
            </div>
            { token.value && !disabled &&
              <Button { ...this._getClear() } />
            }
          </div>
        </div>
        <div className="maha-tokenfield-token">
          <strong>TOKEN:</strong> { token.token.length > 0 ?
            <span className="maha-tokenfield-token-text">{ token.token }</span> :
            <span>none</span>
          }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { token } = this.state
    const { value } = this.props
    if(!_.isEqual(token, prevState.token)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-tokenfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused, token } = this.state
    return {
      id,
      type: 'text',
      disabled,
      placeholder: !focused ? this._getPlaceholder() : null,
      tabIndex,
      value: token.value,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate,
      onFocus: this._handleFocused
    }
  }

  _getToken(value) {
    return value.replace(/[^A-Za-z0-9\s]+/g, '').replace(/[\s]+/g, '_').toLowerCase()
  }

  _getPlaceholder() {
    const { label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    return t(label ? `t(Enter) ${label}` : '')
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { token } = this.state
    const value = token.value.length > 0 ? token : null
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      token: {
        token: '',
        value: ''
      }
    })
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleSet(value) {
    const token = value || { value: '', token: '' }
    token.token = token.token || this._getToken(token.value)
    this.setState({ token })
  }

  _handleUpdate(value) {
    const token = this._getToken(value)
    this.setState({
      token: {
        token,
        value
      }
    })
  }

}

export default TokenField
