import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import Import from './import'
import Clone from './clone'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: '/api/admin/truevail/agency/social_campaigns',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'title', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ false } show_tags={ true } show_voice={ true }  />
      ) },
      { label: 't(Library)', key: 'status', collapsing: true, format: LibraryToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Perspective)', key: 'perspective', type: 'select', multiple: true, options: ['first-person singular','first-person plural'] },
            { name: 't(Language)', key: 'language', type: 'select', multiple: true, options: ['american english','canadian english','french','german','italian'] },
            { name: 't(Library)', key: 'status', type: 'select', multiple: true, options: ['draft','published'], format: LibraryToken }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Campaigns)',
      text: 't(You have not yet created any social campaigns)',
      buttons: [
        { label: 't(Create Social Campaign)', modal: New }, 
        { label: 't(Import Social Campaigns)', modal: Import }
      ]
    },
    entity: 'social campaign',
    recordTasks: (record) => [
      {
        label: 't(Clone Social Campaign)',
        modal: <Clone social_campaign={ record } />
      },
      {
        label: 't(Publish to Library)',
        show: record.status === 'draft',
        confirm: 't(Are you sure you want to publish this campaign? Once it has been published, you cannot change the content of this social campaign.)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_campaigns/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish social campaign)')
        }
      },
      {
        label: 't(Unpublish from Library)',
        show: record.status === 'published',
        confirm: 't(Are you sure you want to unpublish this campaign?)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_campaigns/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaign)')
        }
      },
      {
        label: 't(Delete Social Campaign)',
        show: record.status !== 'published' || record.advisor_social_campaigns_count === 0,
        confirm: 't(Are you sure you want to delete this social campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_campaigns/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete social campaign)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/agency/social_campaigns/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: 't(Create Social Campaign)', modal: New }, 
      { label: 't(Import Social Campaigns)', modal: Import }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
