import Checkbox from './checkbox'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxFieldContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    check: () => componentRef.current._handleUpdate(true),
    uncheck: () => componentRef.current._handleUpdate(false),
    toggle: () => componentRef.current._handleUpdate()
  }))

  return <Checkbox ref={ componentRef } {...props} />

})

export default CheckboxFieldContainer
