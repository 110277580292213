import ContactToken from '@apps/crm/admin/tokens/contact'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Credit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { contact, program_id } = this.props
    return {
      title: 't(New Credit)',
      method: 'POST',
      action: '/api/admin/finance/credits',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program_id },
            ...contact ? [
              { name: 'contact_id', type: 'hidden', value: contact.id }
            ] : [
              { label: 't(Contact)', name: 'contact_id', type: 'lookup', endpoint: '/api/admin/crm/contacts', valueKey: 'id', textKey: 'display_name', required: true, format: ContactToken }
            ],
            { label: 't(Description)', name: 'description', type: 'textfield', placeholder: 'Describe this credit', required: true },
            { label: 't(Amount)', name: 'amount', type: 'moneyfield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(credit) {
    this.context.router.push(`/finance/credits/${credit.id}`)
    this.context.modal.close()
  }

}

export default Credit
