import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Format from '@admin/components/format'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Assigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    entity: PropTypes.string,
    format: PropTypes.any,
    onAssignAll: PropTypes.func,
    onRemove: PropTypes.func,
    onUnassignAll: PropTypes.func
  }

  render() {
    const { assigned, entity, format } = this.props
    return (
      <div className="maha-support-assignment-assigned">
        <div className="maha-support-assignment-assigned-header">
          <Button { ...this._getUnassignAll() } /> | <Button { ...this._getAssignAll() } />
        </div>
        { assigned.length > 0 ?
          <div className="maha-support-assignment-assigned-body">
            { assigned.map((team, index) => (
              <div className="maha-support-assignment-assigned-team" key={`team_${index}`}>
                <div className="maha-support-assignment-assigned-team-details">
                  <Format { ...team }format={ format } value={ team } />
                </div>
                <Button { ...this._getRemove(index) } />
              </div>
            ))}
          </div> : 
          <Message { ...this._getEmpty() } />
        }
        <div className="maha-support-assignment-assigned-footer">
          { pluralize(entity, assigned.length, true) }
        </div>
      </div>
    )
  }

  _getAssignAll() {
    const { onAssignAll } = this.props
    return {
      label: 't(assign all)',
      className: 'link',
      handler: onAssignAll
    }
  }

  _getEmpty() {
    const { entity } = this.props
    return {
      icon: 'users',
      title: `No ${_.startCase(pluralize(entity))}`,
      text: `You have not yet assigned any ${pluralize(entity)}`
    }
  }

  _getRemove(index) {
    return {
      icon: 'times',
      className: 'maha-support-assignment-assigned-team-action',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _getUnassignAll() {
    const { onUnassignAll } = this.props
    return {
      label: 't(unassign all)',
      className: 'link',
      handler: onUnassignAll
    }
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

}

export default Assigned
