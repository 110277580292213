import ImageField from '../components/imagefield'
import Form from '@admin/components/form'
import * as options from '../variables'
import PropTypes from 'prop-types'
import React from 'react'

class TextForm extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onDone: PropTypes.func,
    onUpdate: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate(config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(Text)',
      ref: this.formRef,
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      onCancel: this._handleDone,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 't(Name)', name: 'name', type: 'tokenfield', required: true, defaultValue: config.name },
                { label: 't(Image)', name: 'image', type: ImageField, defaultValue: config.image },
                { label: 't(Text)', name: 'text', type: 'htmlfield', defaultValue: config.text }
              ]
            }
          ]
        }, {
          label: 't(Style)',
          sections: [
            {
              label: 't(Block)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Background)', name: 'background_color', type: 'colorfield', defaultValue: config.background_color },
                this._getBorder('border', 'Border'),
                { label: 't(Padding)', name: 'padding', type: 'dropdown', options: options.paddings, defaultValue: config.padding }
              ]
            },
            ...options.block_types.map(({ value, text }) => ({
              label: text,
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Font Family)', name: `${value}_font_family`, type: 'fontfamilyfield', defaultValue: config[`${value}_font_family`] },
                { type: 'fields', fields: [
                  { label: 't(Font Size)', name: `${value}_font_size`, type: 'dropdown', options: options.font_sizes, defaultValue: config[`${value}_font_size`] },
                  { label: 't(Color)', name: `${value}_color`, type: 'colorfield', defaultValue: config[`${value}_color`] }
                ] },
                { type: 'fields', fields: [
                  { label: 't(Format)', name: `${value}_format`, type: 'formatfield', defaultValue: config[`${value}_format`] },
                  { label: 't(Alignment)', name: `${value}_text_align`, type: 'alignmentfield', defaultValue: config[`${value}_text_align`] }
                ] },
                { type: 'fields', fields: [
                  { label: 't(Line Height)', name: `${value}_line_height`, type: 'dropdown', options: options.line_heights, defaultValue: config[`${value}_line_height`] },
                  { label: 't(Letter Spacing)', name: `${value}_letter_spacing`, type: 'dropdown', options: options.letter_spacing, defaultValue: config[`${value}_letter_spacing`] }
                ] }
              ]
            })),
            {
              label: 't(Link)',
              collapsing: true,
              collapsed: true,
              fields: [
                { type: 'fields', fields: [
                  { label: 't(Color)', name: 'a_color', type: 'colorfield', defaultValue: config.a_color },
                  { label: 't(Format)', name: 'a_format', type: 'formatfield', defaultValue: config.a_format }
                ] }
              ]
            }
          ]
        }
      ]

    }
  }

  _getDefault() {
    return {
      text: '<p>Messenger bag portland adaptogen food truck pabst, la croix pug vinyl mumblecore chartreuse. Art party schlitz portland, try-hard semiotics tumblr green juice gentrify letterpress tilde gochujang whatever helvetica tote bag. Locavore quinoa man braid cred selvage chambray. Post-ironic everyday carry kale chips umami woke polaroid, meggings organic pork belly air plant.</p>',
      background_color: null,
      border_style: null,
      border_width: null,
      border_color: null,
      padding: 16,
      h1_font_family: null,
      h1_font_size: null,
      h1_color: null,
      h1_format: [],
      h1_text_align: 'left',
      h1_line_height: null,
      h1_letter_spacing: null,
      h2_font_family: null,
      h2_font_size: null,
      h2_color: null,
      h2_format: [],
      h2_text_align: 'left',
      h2_line_height: null,
      h2_letter_spacing: null,
      p_font_family: null,
      p_font_size: null,
      p_color: null,
      p_format: [],
      p_text_align: 'left',
      p_line_height: null,
      p_letter_spacing: null,
      a_color: null,
      a_format: []
    }
  }

  _getBorder(type, label) {
    const { config } = this.state
    if(!config[`${type}_style`]) {
      return { label, name: `${type}_style`, type: 'dropdown', options: options.border_styles, placeholder: 'Style', defaultValue: config[`${type}_style`] }
    }
    return { label, type:'fields', fields: [
      { name: `${type}_style`, type: 'dropdown', options: options.border_styles, placeholder: 'Style', defaultValue: config[`${type}_style`] },
      { name: `${type}_width`, type: 'dropdown', options: options.border_widths, placeholder: 'Width', defaultValue: config[`${type}_width`] },
      { name: `${type}_color`, type: 'colorfield', defaultValue: config[`${type}_color`] }
    ] }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default TextForm
