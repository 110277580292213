import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  optin: 't(Create email newsletters for me)',
  optout: 't(Dont create email newsletters for me)'
}

const EmailStrategyToken = ({ value }) => (
  <div className="token">
    <T text={ service_types[value] } />
  </div>
)

EmailStrategyToken.propTypes = {
  value: PropTypes.string
}

export default EmailStrategyToken
