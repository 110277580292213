import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const actions = {
  add: 't(Opt in to)',
  remove: 't(Opt out of to)'
}

const Token = ({ action, channel_type }) => {
  const description = `${actions[action]} ${channel_type}`
  return <T text={ description } />
}

Token.propTypes = {
  action: PropTypes.string,
  channel_type: PropTypes.string
}

export default Token
