const access = {
  entities: [
    { label: 't(Profiles)', code: 'profiles' },
    { label: 't(Programs)', code: 'programs' },
    { label: 't(Properties)', code: 'properties' },
    { label: 't(Roles)', code: 'roles' },
    { label: 't(Senders)', code: 'senders' },
    { label: 't(Templates)', code: 'templates' },
    { label: 't(Users)', code: 'users' }
  ], 
  rights: [
    { label: 't(Can manage configuration)', code: 'manage_configuration' },
    { label: 't(Can view activities)', code: 'view_activities' }
  ] 
}

export default access
