import Allocations from '@apps/finance/admin/components/allocations'
import VendorToken from '@apps/finance/admin/tokens/vendor'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    reimbursement: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  state = {
    total: 0.00
  }

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { reimbursement } = this.props
    const { total } = this.state
    return {
      title: 't(Edit Reimbursement)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/reimbursements/${reimbursement.id}/edit`,
      action: `/api/admin/finance/reimbursements/${reimbursement.id}`,
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Date of Purchase)', name: 'date', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Vendor)', name: 'vendor_id', type: 'lookup', endpoint: '/api/admin/finance/vendors', valueKey: 'id', textKey: 'name', form: this._getVendorForm(), format: VendorToken },
            { label: 't(Receipt)', name: 'receipt_ids', type: 'attachmentfield', allow: { content_types: ['application/pdf','images'] }, multiple: true, prompt: 'Upload Receipt' },
            { label: 't(Total)', name: 'total', type: 'moneyfield', required: true, placeholder: 't(Enter the full amount minus the tax)' },
            { label: 't(Allocations)', name: 'allocations', type: Allocations, projectEndpoint: `/api/admin/finance/users/${reimbursement.user.id}/projects`, total }
          ]
        }
      ]
    }
  }

  _getVendorForm() {
    return {
      title: 't(New Vendor)',
      method: 'POST',
      action: '/api/admin/finance/vendors',
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield' },
            { label: 't(Address)', name: 'address', type: 'addressfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(name, value) {
    if(name === 'total') {
      this.setState({
        total: Number(value)
      })
    }
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
