import Button from '@admin/components/button'
import Img from '@admin/components/html/img'  
import PropTypes from 'prop-types'
import React from 'react'

const getProfileLink = (profile) => {
  return {
    label: 't(View Profile)',
    className: 'link',
    link: profile.url
  }
}

const getServiceLink = (variant) => {
  return {
    label: 't(View Post)',
    className: 'link',
    link: variant.url
  }
}

const SocialCampaignVariant = (props) => {

  const { social_campaign_variant } = props

  return (
    <div className="campaign-variant-token">
      <div className="campaign-variant-token-icon">
        <Img src={`/images/services/${social_campaign_variant.profile.service.source}.png`} />
      </div>
      <div className="campaign-variant-token-details">
        <div>
          <strong>{ social_campaign_variant.profile.name || social_campaign_variant.profile.username }</strong>
          { social_campaign_variant.status === 'posted' &&
            <> - <span className="success">POSTED</span></>
          }
          { social_campaign_variant.status === 'processing' &&
            <> - <span className="processing">PROCESSING</span></>
          }
          { social_campaign_variant.status === 'pending' &&
            <> - <span className="pending">PENDING</span></>
          }
          { social_campaign_variant.status === 'failed' &&
            <> - <span className="alert">FAILED</span></>
          }
        </div>
        <div>
          <Button { ...getProfileLink(social_campaign_variant.profile) } />
          { social_campaign_variant.status === 'posted' &&
            <> | <Button { ...getServiceLink(social_campaign_variant) } /></>
          }
        </div>
        { social_campaign_variant.status === 'failed' &&
          <div><span className="alert">{ social_campaign_variant.posting_error }</span></div>
        }
      </div>
    </div>
  )

}

SocialCampaignVariant.propTypes = {
  social_campaign_variant: PropTypes.object
}

export default SocialCampaignVariant
