import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Links)',
  access: {},
  collection: {
    endpoint: `/api/admin/campaigns/email/${props.email_campaign.id}/links`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Link)', key: 'url', primary: true },
      { label: 't(Unique Clicks)', key: 'unique_clicks', collapsing: true, align: 'right' },
      { label: 't(Total Clicks)', key: 'total_clicks', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'url', order: 'asc' },
    empty: {
      icon: 'link',
      title: 't(No Links)',
      text: 't(There were no links in this email campaign)'
    },
    entity: 'link',
    onClick: (record) => context.router.push(`/campaigns/email/${props.email_campaign.id}/links/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
