import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static propTypes = {
    entity: PropTypes.string,
    fields: PropTypes.array,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity, fields } = this.props
    return {
      ref: this.formRef,
      title: `New ${_.capitalize(entity)}`,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Add)', color: 'blue', handler: this._handleSave }
      ],
      onCancel: this._handleCancel,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields
        }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSave() {
    this.formRef.current.submit()
  }

  _handleSubmit(item) {
    this.props.onDone(item)
  }

}

export default New
