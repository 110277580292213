import TextField from '@admin/components/form/textfield'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Mobile extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      network: PropTypes.object
    }),
    account: PropTypes.object,
    token: PropTypes.string,
    onSignin: PropTypes.func
  }

  state = {
    code: null,
    error: false,
    sent: false,
    status: 'pending'
  }

  _handleUpdate = this._handleUpdate.bind(this)
  _handleSend = this._handleSend.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleVerify = this._handleVerify.bind(this)

  render() {
    const { account } = this.props
    const { sent } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            { account && <Avatar user={ account } width="150" /> }
            { account && <h2>{ account.full_name }</h2> }
            <p><T text="You'll need an SMS verification code to complete your signin" /></p>
          </div>
          <div className={ this._getFormClass() }>
            { sent &&
              <div className="field">
                <TextField { ...this._getTextField() } />
              </div>
            }
            { sent ?
              <div className="field button-field">
                <Button { ...this._getVerify() } />
              </div> :
              <div className="field button-field">
                <Button { ...this._getSend() } />
              </div>
            }
          </div>
          <div className="maha-signin-footer">
            { sent &&
              <Button { ...this._getResend() }/>
            }
          </div>
        </div>
      </div>
    )
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _getPhoneField() {
    return {
      onChange: this._handleUpdate.bind(this, 'mobile_phone')
    }
  }

  _getResend() {
    return {
      label: 't(Didn\'t receive it? Resend Code)',
      className: 'link',
      handler: this._handleSend
    }
  }

  _getSend() {
    const { status } = this.state
    return {
      label: 't(Send Verification Code)',
      color: 'blue',
      size: 'large',
      loasding: status === 'submitting',
      handler: this._handleSend
    }
  }

  _getSkip() {
    return {
      label: 't(Skip for now)',
      className: 'link',
      handler: this._handleNext
    }
  }

  _getTextField() {
    return {
      placeholder: 't(Enter Verification Code)',
      onChange: this._handleUpdate.bind(this, 'code')
    }
  }

  _getVerify() {
    const { status } = this.state
    return {
      label: 't(Verify Code)',
      color: 'blue',
      size: 'large',
      loasding: status === 'submitting',
      handler: this._handleVerify
    }
  }

  _handleSend() {
    const { token } = this.props.account
    this.setState({ status: 'submitting' })
    this.props.context.network.request({
      endpoint: '/api/admin/signin/twofactor/authorize',
      method: 'POST',
      body: { token },
      onSuccess: () => {
        this.setState({ 
          status: 'pending',
          sent: true 
        })
      },
      onFailure: this._handleShake
    })
  }

  _handleShake() {
    this.setState({ error: true })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleVerify() {
    const { account } = this.props
    const { token } = account
    const { code } = this.state
    this.setState({ sent: true, status: 'submitting' })
    this.props.context.network.request({
      endpoint: '/api/admin/signin/twofactor/verify',
      method: 'POST',
      body: { token, code },
      onSuccess: () => this.props.onSignin(account),
      onFailure: this._handleShake
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Mobile
