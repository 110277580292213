import PropTypes from 'prop-types'
import ACHField from './achfield'
import React from 'react'

const ACHFieldContainer = (props) => {

  return <ACHField { ...props } />

}

ACHFieldContainer.propTypes = {
  assigned: PropTypes.array,
  unassigned: PropTypes.array
}

export default ACHFieldContainer