import ImportToken from '@apps/maha/admin/tokens/import'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class Intro extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    imports: PropTypes.array,
    object_type: PropTypes.string,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNew: PropTypes.func,
    onResume: PropTypes.func
  }
  
  _handleRemoveAll = this._handleRemoveAll.bind(this)

  render() {
    const { imports } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="import-intro">
          { imports.length > 0 ?
            <div className="import-intro-incomplete">
              <div className="import-intro-incomplete-header">
                <h3>Previous Imports</h3>
                <p>We found the following incomplete imports</p>
                <div className="import-intro-deleteall" onClick={ this._handleRemoveAll }>
                  Delete All
                </div>
              </div>
              <List { ...this._getList() } />
            </div> : 
            <Message { ...this._getEmpty() } />
          }
        </div>
      </ModalPanel>
    )
  }

  _getEmpty() {
    const { onNew } = this.props
    return {
      icon: 'copy',
      title: 't(No Imports)',
      text: 't(We couldnt find any previous incomplete imports)',
      buttons: [
        { label: 't(Start New Import)', color: 'blue', handler: onNew }
      ]
    }
  }

  _getList() {
    const { imports, onResume } = this.props
    return {
      actions: (item) => [
        {
          icon: 'times',
          confirm: 't(Are you sure you want to delete this import?)',
          handler: this._handleRemove.bind(this, item)  
        }
      ],
      items: imports,
      format: ImportToken,
      onClick: onResume
    }
  }

  _getPanel() {
    const { imports, onBack, onCancel, onNew } = this.props
    return {
      title: 't(Import Contacts)',
      leftItems: onBack ? [
        { icon: 'chevron-left', handler: onBack }
      ] : [
        { label: 't(Cancel)', handler: onCancel }
      ],
      buttons: imports.length > 0 ? [
        { label: 't(Start New Import)', color: 'blue', handler: onNew }
      ] : []
    }
  }

  _handleRemove(item, e) {
    if(e) e.stopPropagation()
    const { object_type } = this.props
    this.context.network.request({
      endpoint: `/api/admin/${object_type}/imports/${item.id}`,
      method: 'DELETE',
      onFailure: () => {
        this.setState({
          status: 'failure'
        })
      }
    })
  }

  _handleRemoveAll() {
    const { imports } = this.props
    imports.map(item => this._handleRemove(item))
  }

}

const mapResources = (props, context) => ({
  imports: {
    endpoint: `/api/admin/${props.object_type}/imports`,
    filter: {
      status: {
        $neq: 'completed'
      }
    }
  }
})

export default Container(mapResources)(Intro)