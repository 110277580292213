import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  state = {
    data: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Provider)',
      method: 'POST',
      action: '/api/admin/platform/providers',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield',  multiple: false },
            { label: 't(Admin Domain)', name: 'admin_domain', type: 'textfield', required: true },
            { label: 't(Short Domain)', name: 'short_domain', type: 'textfield', required: true },
            { label: 't(Support Email)', name: 'support_email', type: 'emailfield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSuccess(result) {
    this.context.router.push(`/platform/providers/${result.id}`)
    this.context.modal.close()
  }

}

export default New
