import LocationToken from '@apps/crm/admin/tokens/location'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Locations)',
  collection: {
    endpoint: `/api/admin/crm/contacts/${props.contact.id}/locations`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Location)', key: 'city', primary: true, format: LocationToken },
      { label: 't(IP Address)', key: 'ipv4' },
      { label: 't(Last Accessed)', key: 'updated_at', collapsing: true, format: 'datetime' }
    ],
    empty: {
      title: 't(No Locations)',
      text: 't(There are no locations for this contact)',
      icon: 'globe'
    },
    entity: 'location',
    defaultSort: { key: 'created_at', order: 'desc' }
  }
})

export default Panel(null, mapPropsToPanel)
