import { CSSTransition } from 'react-transition-group'
import Stack from '@admin/components/stack'
import Error from '@admin/components/error'
import PropTypes from 'prop-types'
import React from 'react'

export const ModalContext = React.createContext()
ModalContext.displayName = 'ModalContext'

export const useModalContext = () => React.useContext(ModalContext)

class Modal extends React.Component {

  static childContextTypes = {
    modal: PropTypes.object
  }

  static contextTypes = {
    confirm: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  stackRef = React.createRef()

  state = {
    dismissable: true,
    open: false,
    height: null,
    width: null
  }

  _handleClose = this._handleClose.bind(this)
  _handleDismiss = this._handleDismiss.bind(this)
  _handleOpen =  this._handleOpen.bind(this)
  _handlePop =  this._handlePop.bind(this)
  _handlePush =  this._handlePush.bind(this)

  render() {
    const { open } = this.state
    const { children } = this.props
    return (
      <ModalContext.Provider value={ this.getChildContext() }>
        { children }
        <CSSTransition key="maha-modal-overlay" in={ open } classNames="expanded" timeout={ 500 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-modal-overlay" onClick={ this._handleDismiss } />
        </CSSTransition>
        <CSSTransition key="maha-modal-window" in={ open } classNames="expanded" timeout={ 500 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-modal-window" style={ this._getStyle() }>
            <Error { ...this._getError() }>
              <Stack { ...this._getStack() } />
            </Error>
          </div>
        </CSSTransition>
      </ModalContext.Provider>
    )
  }

  getChildContext() {
    return {
      modal: {
        close: this._handleClose,
        open: this._handleOpen,
        pop: this._handlePop,
        push: this._handlePush
      }
    }
  }

  _getError() {
    return {
      isModal: true
    }
  }

  _getStack() {
    return {
      display_name: 'modal',
      ref: this.stackRef
    }
  }

  _getStyle() {
    const { width, height } = this.state
    return {
      maxWidth: width,
      maxHeight: height
    }
  }

  _handleClose() {
    this.setState({ open: false })
  }

  _handleDismiss() {
    const { dismissable } = this.state
    if(!dismissable) return
    this.context.confirm.open('t(Are you sure you want to close this window?)', this._handleClose)
  }

  _handleOpen(component, options = {}) {
    const width = options.width || 996
    const height = options.height || 740
    const dismissable = options.dismissable === false ? false : true
    this.setState({ width, height, dismissable, open: true }, () => {
      this.stackRef.current.push({ component })
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.setState({ open: true })
    this.stackRef.current.push({ component, props })
  }

}

export default Modal
