import Container from '@admin/components/container'
import AssetToken from '@admin/tokens/asset'
import PropTypes from 'prop-types'
import React from 'react'

class Note extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object,
    note: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    const { note } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          { note.text.split('\n').map((line, index) => (
            <span key={`line_${index}`}>{ line }<br /></span>
          )) }
          { note.attachments.map((asset, index) => (
            <div className="crm-card-asset" key={`asset_${index}`}>
              <AssetToken { ...asset } />
            </div>
          ))}
        </div>
      </div>
    )
  }

}

const mapResources = (props, context) => ({
  note: `/api/admin/crm/contacts/${props.activity.contact.id}/notes/${props.activity.data.note_id}`
})

export default Container(mapResources)(Note)
