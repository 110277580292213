import VoiceCampaignToken from '@apps/campaigns/admin/tokens/campaign/voice'
import New from '@apps/campaigns/admin/views/voice/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Voice Campaigns)',
  collection: {
    endpoint: '/api/admin/campaigns/voice',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (voice_campaign) => (
        <VoiceCampaignToken voice_campaign={ voice_campaign } compact={ true } />
      ) }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Calls)', key: 'calls_count' },
      { label: 't(Active)', key: 'active_count' },
      { label: 't(Lost)', key: 'lost_count' },
      { label: 't(Converted)', key: 'converted_count' },
      { label: 't(Completed)', key: 'completed_count' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultQuery: { form_id: props.form.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'phone',
      title: 't(No Voice Campaigns)',
      text: 't(You have not yet created any voice campaigns for this form)',
      buttons: props.form.deleted_at === null ? [
        { 
          label: 't(Create Voice Campaign)', 
          modal: <New form={ props.form } /> 
        }
      ] : null
    },
    entity: 'voice campaign',
    onClick: (record) => context.router.push(`/campaigns/voice/${record.id}`)
  },
  task: props.form.deleted_at === null ? {
    icon: 'plus',
    modal: <New form={ props.form } />
  } : null
})

export default Panel(null, mapPropsToPanel)
