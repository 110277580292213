import useActionReducer from '@admin/hooks/use_action_reducer'
import React, { useEffect, useMemo, useRef } from 'react'
import { useMahaContext } from '@admin/components/maha'
import { createLogger } from '@core/utils/console'
import useRequest from '@admin/hooks/use_request'
import SocketClient from 'socket.io-client'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Network from './network'

export { NetworkContext, useNetworkContext } from './network'

const logAction = createLogger('blue')

const NetworkContainer = (props) => {

  const token = useMahaContext()?.maha?.token

  const connectRef = useRef()
  const disconnectRef = useRef()
  const receiveRef = useRef()

  const client = useMemo(() => SocketClient(), [])

  const [state, handlers] = useActionReducer({
    display_name: 'network',
    actions,
    props,
    reducer,
    selectors
  })

  const request = useRequest({
    display_name: 'network'
  })

  receiveRef.current = (packet) => {

    const [event, message] = packet.data
    logAction('socketio/RECEIVE', { event, message })

    state.listeners.map(listener => {
      if(listener.event !== event) return
      listener.handler(message)
    })

    if(_.isPlainObject(message)) {
      const { action, channel, data } = message
      state.subscriptions.filter(subscription => {
        if(!subscription.handler) return false
        if(subscription.channel === channel && subscription.action === action) return true
        if(!subscription.channel && subscription.action === action) return true
        return false
      }).map(subscription => {
        subscription.handler(data)
      })
    }

  }

  connectRef.current = () => {
    logAction('socketio/CONNECT')
    handlers.onSetConnected(true)
    Object.keys(state.channels).map(channel => {
      logAction('socketio/JOIN', { channel })
      client.emit('join', token, channel)
    })
  }

  disconnectRef.current = () => {
    logAction('socketio/DISCONNECT')
    handlers.onSetConnected(false)
  }

  const emit = (verb  , data) => {
    logAction('socketio/EMIT', { verb, data })
    client.emit(verb, token, data)
  }

  const listen = (event, handler) => {
    handlers.onAddListener({ event, handler })
  }

  const subscribe = (subscription) => {
    if(subscription.channel && !state.channels[subscription.channel]) {
      client.emit('join', token, subscription.channel, () => {
        logAction('socketio/JOIN', { channel: subscription.channel })
      })
    }
    handlers.onAddSubscription({
      channel: subscription.channel,
      action: subscription.action,
      handler: subscription.handler
    })
  }

  const unlisten = (event, handler) => {
    handlers.onRemoveListener({ event, handler })
  }

  const unsubscribe = (subscription) => {
    if(subscription.channel && state.channels[subscription.channel] === 1) {
      client.emit('leave', token, subscription.channel, () => {
        logAction('socketio/LEAVE', { channel: subscription.channel })
      })
    }
    handlers.onRemoveSubscription({
      channel: subscription.channel,
      action: subscription.action,
      handler: subscription.handler
    })
  }

  useEffect(() => {
    const connect = () => connectRef.current()
    const disconnect = () => disconnectRef.current()
    const receive = (packet) => receiveRef.current(packet)
    client.onevent = receive
    client.on('connect', connect)
    client.on('disconnect', disconnect)
    return () => {
      client.onevent = null
      client.off('connect', connect)
      client.off('disconnect', disconnect)
    }
  }, [])

  const mergedProps = {
    ...props,
    ...state,
    emit,
    listen,
    request,
    subscribe,
    unlisten,
    unsubscribe
  }

  return <Network { ...mergedProps } />

}

NetworkContainer.propTypes = {
  children: PropTypes.any
}

export default NetworkContainer
