const INITIAL_STATE = {
  asset: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CLEAR':
    return {
      ...state,
      asset: null
    }

  case 'SET_ASSET':
    return {
      ...state,
      asset: action.asset
    }

  default:
    return state
  }

}

export default reducer
