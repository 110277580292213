import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Multiple from './multiple'
import pluralize from 'pluralize'
import Single from './single'
import React from 'react'

class Importing extends React.Component {

  static contextTypes = {
    uploader: PropTypes.object
  }

  static propTypes = {
    files: PropTypes.array,
    multiple: PropTypes.bool,
    processed: PropTypes.bool,
    retry: PropTypes.bool,
    onBack: PropTypes.func,
    onCreate: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleRetry = this._handleRetry.bind(this)

  render() {
    const { multiple, retry } = this.props
    return (
      <>
        { retry &&
          <div className="maha-attachments-retry">
            <Icon icon="exclamation-triangle" /> One or more of your file imports failed! <Button { ...this._getRetry() } />
          </div>
        }
        { multiple ? <Multiple { ...this._getProps() } /> : <Single { ...this._getProps() }  /> }
      </>
    )
  }

  componentDidMount() {
    const { files, onCreate } = this.props
    const { uploader } = this.context
    files.map((file, index) => {
      if(!file.create) return
      const { endpoint, body } = file.create
      onCreate(endpoint, body, index)
    })
    uploader.upload()
  }

  componentDidUpdate(prevProps) {
    const { processed } = this.props
    if(processed !== prevProps.processed) {
      this._handleDone()
    }
  }

  _getPanel() {
    const { files } = this.props
    return {
      title: `Importing ${pluralize('File', files.length)}`
    }
  }

  _getProps() {
    const { files } = this.props
    return {
      files
    }
  }

  _getRetry() {
    return {
      label: 'Try again?',
      className: 'link',
      handler: this._handleRetry
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleRetry() {
    const { files, onCreate } = this.props
    const { uploader } = this.context
    files.map((file, index) => {
      if(file.status !== 'failed') return
      if(!file.create) return uploader.retry(file.id)
      const { endpoint, body } = file.create
      return onCreate(endpoint, body, index)
    })
  }

}

export default Importing
