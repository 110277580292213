import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Badge extends React.Component {

  static contextTypes = {
    device: PropTypes.object,
    local_storage: PropTypes.object
  }

  static propTypes = {}

  state = {
    ready: false,
    badge: null
  }

  _handleDismiss = this._handleDismiss.bind(this)
  _handleDownload = this._handleDownload.bind(this)

  render() {
    const { badge, ready } = this.state
    if(!ready || badge === 'dismiss') return null
    return (
      <div className="maha-device-badge">
        <Button { ...this._getDismiss() } />
        <T text="t(Stay signed in, receive push notifications, and access your data at any time)" />
        <Button { ...this._getDownload() } />
      </div>
    )
  }

  componentDidMount() {
    this._handleSet('dismiss')
    // this._handleInit()
  }

  _getDismiss() {
    return {
      icon: 'times',
      className: 'maha-device-badge-dismiss',
      handler: this._handleDismiss
    }
  }

  _getLabel() {
    const { device } = this.context
    if(device.os === 'windows') return 't(Download Maha for Windows)'
    if(device.os === 'mac os') return 't(Download Maha for macOS)'
    if(device.os === 'ios') return 't(Download Maha for iOS)'
    if(device.os === 'samsung browser') return 't(Download Maha for Android)'
    if(device.os === 'android') return 't(Download Maha for Android)'
  }

  _getDownload() {
    const { device } = this.context
    return {
      label: this._getLabel(),
      color: 'red',
      handler: this._handleDownload
    }
  }

  _handleInit() {
    const { device } = this.context
    if(device.platform !== 'web') return this._handleSet('dismiss')
    this.context.local_storage.get({
      key: 'platform-badge',
      onSuccess: (badge) => {
        this._handleSet(badge)
      }
    })
  }

  _handleDismiss() {
    this.context.local_storage.set({
      key: 'platform-badge',
      value: 'dismiss',
      onSuccess: (badge) => {
        this._handleSet(badge)
      }
    })
  }

  _handleDownload() {}

  _handleSet(badge) {
    this.setState({
      badge,
      ready: true
    })
  }

}

export default Badge
