import { TransitionGroup, CSSTransition } from 'react-transition-group'
import TwoFactor from './twofactor'
import PropTypes from 'prop-types'
import Security from './security'
import Password from './password'
import Complete from './complete'
import Verify from './verify'
import React from 'react'

class Reset extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    host: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  state = {
    account: null,
    direction: 'forward',
    mode: 'verify',
    previous: null,
    security_question: null,
    token: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleSignin = this._handleSignin.bind(this)

  render() {
    const { direction, mode } = this.state
    if(!mode) return null
    const Component = this._getComponent()
    return (
      <div className={`maha-signin ${direction}`}>
        <TransitionGroup component={ null }>
          <CSSTransition key={ mode } timeout={ 500 } classNames="slide" appear={ false }>
            <Component  { ...this._getProps() } />
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }

  _getComponent() {
    const { mode } = this.state
    if(mode === 'verify') return Verify
    if(mode === 'twofactor') return TwoFactor
    if(mode === 'security') return Security
    if(mode === 'password') return Password
    if(mode === 'complete') return Complete
  }

  _getProps() {
    return {
      ...this.state,
      onBack: this._handleBack,
      onNext: this._handleNext,
      onSignin: this._handleSignin
    }
  }
 
  _handleBack() {
    const { previous } = this.state
    this.setState({
      direction: 'backward',
      mode: previous
    })
  }
    
  _handleNext(mode, values) {
    this.setState({
      ...values || {},
      direction: 'forward',
      mode,
      previous: this.state.mode
    })  
  }

  _handleSignin() {
    const { account } = this.state
    this.context.admin.signin(account)
    this.context.router.push('/')
  } 

}

export default Reset
