import Form from '@admin/components/form'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class DividerField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    divider: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.divider) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { divider } = this.state
    const { value } = this.props
    if(!_.isEqual(divider, prevState.divider)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { theme } = this.props
    const { divider } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Width)', name: 'width', type: WebRange, units: [
              { name: 'px', min: 0, max: 50, step: 1 }
            ], nullValues: ['0px'], defaultValue: '0px', value: divider.width },
            { label: 't(Style)', name: 'style', type:  'dropdown', options: [
              { value: 'solid', text: 't(Solid)' },
              { value: 'dashed', text: 't(Dashed)' },
              { value: 'dotted', text: 't(Dotted)' },
              { value: 'double', text: 't(Double)' },
              { value: 'groove', text: 't(Groove)' },
              { value: 'ridge', text: 't(Ridge)' },
              { value: 'inset', text: 't(Inset)' },
              { value: 'outset', text: 't(Outset)' },
              { value: 'none', text: 't(None)' }
            ], value: divider.style },
            { label: 't(Color)', name: 'color', type: ColorField, theme, defaultColor: true, value: divider.color }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { divider } = this.state
    this.props.onChange({
      width: divider.width,
      color: divider.color,
      style: divider.style
    })
  }

  _handleSet(divider) {
    this.setState({
      divider: {
        width: '1px',
        color: {
          value: '#000000'
        },
        style: 'solid',
        ...divider || {}
      }
    })
  }

}

export default DividerField
