import { CSSTransition } from 'react-transition-group'
import TextField from '@admin/components/form/textfield'
import Buttons from '@admin/components/buttons'
import PropTypes from 'prop-types'
import React from 'react'

export const VerifyContext = React.createContext()
VerifyContext.displayName = 'VerifyContext'

export const useVerifyContext = () => React.useContext(VerifyContext)

class Verify extends React.Component {

  static childContextTypes = {
    verify: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  state = {
    open: false,
    options: null,
    value: ''
  }

  _handleClose = this._handleClose.bind(this)
  _handleConfirm = this._handleConfirm.bind(this)
  _handleOpen = this._handleOpen.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { options, open } = this.state
    return (
      <VerifyContext.Provider value={ this.getChildContext() }>
        {  this.props.children }
        <CSSTransition in={ open } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-verify-overlay" onClick={ this._handleClose } />
        </CSSTransition>
        <CSSTransition in={ open } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <>
            { options &&
              <div className="maha-verify">
                <div className="maha-verify-body">
                  <div className="maha-verify-text">
                    { options.text }
                  </div>
                  <div className="maha-verify-input">
                    <TextField { ...this._getVerification() } />
                  </div>
                </div>
                <div className="maha-verify-footer">
                  <Buttons { ...this._getButtons() } />
                </div>
              </div>
            }
          </>
        </CSSTransition>
      </VerifyContext.Provider>
    )
  }

  getChildContext() {
    return {
      verify: {
        open: this._handleOpen
      }
    }
  }

  _getVerification() {
    const { options, value } = this.state
    return {
      placeholder: `Type '${options.term}'`,
      value,
      onChange: this._handleType
    }
  }

  _getButtons() {
    const { options, value } = this.state
    return {
      buttons: [
        {
          label: 't(Cancel)',
          color: 'yellow',
          handler: this._handleClose
        },
        {
          label: 'Confirm',
          color: 'green',
          disabled: value !== options.term,
          handler: this._handleConfirm
        }
      ]
    }
  }

  _handleClose() {
    this.setState({
      open: false,
      options: null,
      value: ''
    })
  }

  _handleConfirm() {
    const { options, value } = this.state
    if(value !== options.term) return
    options.handler()
    this._handleClose()
  }

  _handleOpen({ text, term, handler }) {
    this.setState({
      open: true,
      options: {
        text, term, handler
      }
    })
  }

  _handleType(value) {
    this.setState({ value })
  }

}

export default Verify
