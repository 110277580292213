import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Presence from '@admin/components/presence'
import PropTypes from 'prop-types'
import Session from './session'
import React from 'react'

class Sessions extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      router: PropTypes.object
    })
  }

  render() {
    const { user } = this.props.context.admin
    return (
      <Presence>
        <TransitionGroup component={ null }>
          <CSSTransition key={ user.id } timeout={ 1000 } classNames="flip" appear={ false }>
            <Session key={`session_${user.id}`} { ...this._getSession() } />
          </CSSTransition>
        </TransitionGroup>
      </Presence>
    )
  }

  _getSession() {
    const { apps, team, user } = this.props.context.admin
    return { apps, team, user }
  }

}

export default Sessions
