import ProgramForm from '@apps/crm/admin/components/programform'
import New from '@apps/campaigns/admin/views/social/new'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class SocialSchedule extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    onEdit: PropTypes.func,
    onUnschedule: PropTypes.func
  }

  render() {
    return (
      <div { ...this._getUnscheduled() }>
        <Search { ...this._getSearch() } />
      </div>
    )
  }

  _getUnscheduled() {
    return {
      className: 'campaigns-social-schedule-unscheduled',
      onDragOver: (e) => e.preventDefault(),
      onDrop: this._handleDrop.bind(this)
    }
  }

  _getSearch() {
    const { team } = this.context.admin
    const { endpoint, onEdit } = this.props
    return {
      endpoint,
      refresh: `/teams/${team.id}/admin/campaigns/social`,
      defaultQuery: {
        status: 'draft'
      },
      empty: {
        icon: 'comments',
        title: 't(Unscheduled Campaigns)',
        text: 't(There are currently no unscheduled social campaigns)',
        buttons: [
          { 
            label: 't(Create Social Campaign)', 
            access: { rights: { $oneOf: ['campaigns:manage_social_campaigns'] } },
            modal: <ProgramForm form={ New } />
          }
        ]
      },
      label: 'social campaign',
      valueKey: null,
      layout: Results,
      props: {
        onEdit
      }
    }
  }

  _handleDrop(e) {
    e.preventDefault()
    const social_campaign = JSON.parse(e.dataTransfer.getData('social_campaign'))
    if(social_campaign.status !== 'scheduled') return
    this.props.onUnschedule(social_campaign)
  }

}

export default SocialSchedule
