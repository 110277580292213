import MultiForm from '@admin/components/multiform'
import Confirmation from './confirmation'
import PropTypes from 'prop-types'
import Sessions from './sessions'
import Tickets from './tickets'
import Contact from './contact'
import Payment from './payment'
import Details from './details'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program } = this.props
    return {
      title: 't(New Event)',
      action: '/api/admin/events/events',
      method: 'POST',
      props: { program },
      defaultValue: this._getDefault(),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { program_id } = this.props
    return {
      program_id,
      payment_config: {
        check_enabled: false,
        async_enabled: false
      }
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Sessions)', component: Sessions },
      { label: 't(Payment)', component: Payment },
      { label: 't(Contact)', component: Contact },
      { label: 't(Tickets)', component: Tickets },
      { label: 't(Confirmation)', component: Confirmation }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(store) {
    this.context.router.push(`/events/events/${store.id}`)
    this.context.modal.close()
  }

}

export default New
