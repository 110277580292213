import Workflows from './automation/workflows'
import Page from '@admin/components/page'
import Emails from './automation/emails'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.tag.text,
  manager: {
    path: `/admin/team/tags/${resources.tag.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview tag={ resources.tag } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows tag={ resources.tag } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails tag={ resources.tag } />
          }
        ]
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  tag: `/api/admin/team/tags/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
