import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sent)',
  collection: {
    endpoint: '/api/admin/truevail/admin/processings',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Domain)', key: 'domain.name', sort: 'domain', primary: true },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment', primary: true },
      { label: 't(Sent)', key: 'sent_count', collapsing: true, align: 'right' },
      { label: 't(Open Rate)', key: 'open_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Click Rate)', key: 'click_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Bounce Rate)', key: 'bounce_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Complain Rate)', key: 'complain_rate', collapsing: true, align: 'right', format: 'percent' },
      { label: 't(Unsubscribe Rate)', key: 'unsubscribe_rate', collapsing: true, align: 'right', format: 'percent' }
    ],
    defaultQuery: { 
      interval: props.params.interval === 'day' ? 'hour' : 'minute',
      group_by: 'domain_segment',
      value: props.params.value
    },
    defaultSort: { key: 'domain', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no sent emails)'
    },
    entity: 'domain',
    onClick: (record) => {
      const parts = ['/admin/truevail/admin/sent']  
      parts.push(`domain_segments/${record.domain_segment.id}`)
      if(props.params.interval === 'day') parts.push(`day/${props.params.value}`)
      if(props.params.interval === 'hour') parts.push(`hour/${props.params.value}`)
      context.router.push(parts.join('/'))
    }  
  }
})

export default Panel(null, mapPropsToPanel)
