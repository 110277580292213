import Panel from '@admin/components/panel'
import Logo from '@admin/components/logo'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Responses)',
  collection: {
    endpoint: '/api/admin/forms/responses',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Form)', key: 'form.title', primary: true, format: (response) => (
        <div className="event-token">
          <div className="event-token-logo">
            <Logo team={ response.form.program } width="24" />
          </div>
          <div className="event-token-label">
            <div className="token-link">
              { response.form.title }
            </div>
          </div>
        </div>
      ) },
      { label: 't(Date)', key: 'created_at', collapsing: true, format: 'date' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' }
    ],
    empty: {
      title: 't(No Responses)',
      text: 't(There are no responses for this contact)',
      icon: 'check-square-o'
    },
    entity: 'response',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/forms/forms/${record.form.id}/responses/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
