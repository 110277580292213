import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import ColorToken from './color'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Color Scheme)',
              instructions: (
                <>
                  Describe the primary colors of your business branding, which will be used in your marketing materials. 
                  View our <Button { ...this._getColors() } /> to help you choose a specific color or specify a generic color description
                </>
              ),
              type: 'segment',
              fields: [
                { 
                  name: 'brand_color_strategy',
                  type: 'radiogroup',
                  options: ['default','choose','design'],
                  format: ColorToken,
                  defaultValue: config.brand_color_strategy || 'default' 
                },
                { 
                  label: 't(Brand Color 1)',
                  name: 'brand_color1',
                  show: config.brand_color_strategy === 'choose',
                  type: 'textfield',
                  placeholder: 't(Enter Brand Color 1 (name, hex, or rgb))',
                  defaultValue: config.brand_color1 
                },
                { 
                  label: 't(Brand Color 2)',
                  name: 'brand_color2',
                  show: config.brand_color_strategy === 'choose',
                  type: 'textfield',
                  placeholder: 't(Enter Brand Color 2 (name, hex, or rgb))',
                  defaultValue: config.brand_color2 
                },
                { 
                  label: 't(Brand Color 3)',
                  name: 'brand_color3',
                  show: config.brand_color_strategy === 'choose',
                  type: 'textfield',
                  placeholder: 't(Enter Brand Color 3 (name, hex, or rgb))',
                  defaultValue: config.brand_color3 
                },
                { 
                  label: 't(Comments) (t(optional))',
                  instructions: 't(Tell us a little bit about your ideal color scheme)',
                  name: 'brand_comments',
                  show: config.brand_color_strategy === 'design',
                  type: 'textarea',
                  placeholder: 't(Examples: I\'d prefer muted feminine colors - beach colors - base the colors off of my current logo - etc)',
                  defaultValue: config.brand_comments 
                },
                { 
                  label: 't(Screenshot) (t(optional))',
                  instructions: 't(Provide a screenshot or example image of the color scheme you\'d prefer)',
                  name: 'brand_screenshot_id',
                  show: config.brand_color_strategy === 'design',
                  type: 'attachmentfield',
                  prompt: 't(Upload Screenshot)',
                  allow: { content_types: ['images'] },
                  multiple: false,
                  defaultValue: config.brand_screenshot_id 
                }
              ] 
            },
            { 
              label: 't(Brand Book / Style Guide)',
              instructions: 't(If available, upload your brand book or style guide to ensure consistency in marketing efforts)',
              name: 'brand_style_guide_id',
              type: 'attachmentfield',
              prompt: 'Upload Document',
              multiple: false,
              defaultValue: config.brand_style_guide_id 
            }
          ]
        }
      ]
    }
  }

  _getColors() {
    return {
      label: 'Color Choice Helper',
      className: 'link',
      link: '/imagecache/images/color-choice-helper.jpg'
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata, onNext, onSave } = this.props
    const action = config.email_strategy === 'optin' ? onNext : onSave
    action({
      ...formdata,
      ...config
    })
  }

}

export default Details