import Form from '@admin/components/form'
import StartTimeToken from './start_time'
import PropTypes from 'prop-types'
import React from 'react'

class QuickStart extends React.Component {

  static propTypes = {
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      title: 't(Prefered Start Time)',
      cancelText: null,
      saveText: null,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true ,
          handler: this._handleBack
        },
        { 
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit
        }
      ],
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Choose Your Preferred Start Time)',
              instructions: 't(To make your experience as seamless as possible, we offer flexible access to your free trial based on the services you selected)',
              name: 'start_time', 
              type: 'radiogroup',
              options: ['asap','wait'],
              format: StartTimeToken,
              defaultValue: 'asap', 
              required: false 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config.start_time)
  }

}

export default QuickStart
