import Input from '@admin/components/html/input'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Linkfield extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.string,
    formatter: PropTypes.func,
    showPreview: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFetch: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    formatter: (asset) => asset.id,
    showPreview: true,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    url: null,
    link: null
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { showPreview } = this.props
    const { link } = this.state
    return (
      <div className="linkfield">
        <div className="linkfield-header">
          <Input { ...this._getInput() } />
        </div>
        { showPreview && link &&
          <div className="linkfield-preview">
            <Img src={ link.image_url } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { link, url } = this.state
    if(url !== prevState.url) {
      this._handleFetch()
    }
    if(!_.isEqual(link, prevState.link)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { url } = this.state
    return {
      type: 'text',
      placeholder: 'Paste a URL',
      value: url || '',
      onChange: this._handleUpdate
    }
  }

  _handleChange() {
    const { link } = this.state
    const { formatter } = this.props
    this.props.onChange(link ? formatter(link) : null)
  }

  _handleFetch() {
    const { url } = this.state
    if(!url) return this.setState({ url: null, link: null })
    this.context.network.request({
      endpoint: '/api/admin/links',
      method: 'POST',
      body: { url },
      onSuccess: ({ data }) => {
        this.setState({
          link: data
        })
      }
    })
  }

  _handleSet(link) {
    this.setState({ 
      link,
      url: link.url
    })
  }

  _handleUpdate(url) {
    this.setState({ url })
  }

}

export default Linkfield
