import EmailField from './email_field'
import PropTypes from 'prop-types'
import React from 'react'

const EmailFieldContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus()
  }))

  return <EmailField ref={ componentRef } {...props} />

})

export default EmailFieldContainer
