import Container from '@admin/components/container'
import Panel from './panel'

const PanelCreator = (mapResourcesToPanel, mapPropsToPanel) => {

  return Container(mapResourcesToPanel, mapPropsToPanel)(Panel)

}

export default PanelCreator
