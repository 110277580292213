import Receipt from '@apps/finance/admin/components/receipt'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

const Overview = ({ payment }) => {

  const customer = {
    className: 'link',
    label: payment.invoice.customer.display_name,
    route: `/admin/crm/contacts/${payment.invoice.customer.id}`
  }

  const invoice = {
    className: 'link',
    label: payment.invoice.code,
    route: `/admin/finance/invoices/${payment.invoice.id}`
  }

  const items = [
    { label: 't(Contact)', content: <Button { ...customer } /> },
    { label: 't(Invoice)', content: <Button { ...invoice } /> },
    { label: 't(Date)', content: moment(payment.date).format('MM/DD/YYYY') },
    { label: 't(Method)', content: payment.method }
  ]

  if(payment.method === 'scholarship') {
    items.push({ label: 'Scholarship', content: payment.scholarship.id })
  } else if(payment.method === 'credit') {

    const credit = {
      className: 'link',
      label: `${payment.credit.id} (${payment.credit.description})`,
      route: `/admin/finance/credits/${payment.credit.id}`
    }

    items.push({ label: 'Credit', content: <Button { ...credit } /> })

  } else if(_.includes(['card','googlepay','applepay','paypal','ach'], payment.method)) {

    if(payment.method === 'ach') {
      items.push({ label: 't(Bank Account)', content: payment.description })
    } else if(payment.method === 'paypal') {
      items.push({ label: 't(Email)', content: payment.description })
    } else {
      items.push({ label: 'Card', content: payment.description })
    }

  }

  if(payment.braintree_id) {

    const braintree = {
      className: 'link',
      label: payment.braintree_id,
      link: payment.braintree_link
    }

    items.push({ label: 't(Braintree ID)', content: <Button { ...braintree } /> })

  }

  if(payment.stripe_id) {

    const stripe = {
      className: 'link',
      label: payment.stripe_id,
      link: payment.stripe_link
    }

    items.push({ label: 't(Stripe ID)', content: <Button { ...stripe } /> })

  }
  
  if(payment.paypal_id) {

    const paypal = {
      className: 'link',
      label: payment.paypal_id,
      link: payment.paypal_link
    }

    items.push({ label: 'PayPal ID', content: <Button { ...paypal } /> })

  }

  if(payment.bank) {
    items.push({ label: 't(Bank Account)', content: payment.bank.title })
    items.push({ label: 'Gross Amount', content: numeral(payment.amount).format('0.00') })
    items.push({ label: 'Fee', content: (
      <span>
        { numeral(payment.fee).format('0.00') } (
        { numeral(Number(payment.rate) + Number(payment.cross_border_rate)).format('0.00%') }
        { payment.transaction_fee && payment.transaction_fee > 0 ? ` + ${payment.transaction_fee}` : '' }) { payment.cross_border_rate > 0 &&
          <span className="error">* an additional {numeral(payment.cross_border_rate).format('0.0%') } cross border fee was applied</span>
        }
        { payment.method === 'ach' && payment.amount > 6.66 &&
          <span className="error">* ACH fees are capped at 5.00</span>
        }
      </span>
    ) })
    items.push({ label: 'Net Amount', content: numeral(payment.disbursed).format('0.00') })
  } else {
    items.push({ label: 't(Amount)', content: numeral(payment.amount).format('0.00') })
  }

  if(payment.refunded) {
    items.push({ label: 'Refunded', content: numeral(payment.refunded).format('0.00') })
  }

  const details = {
    audits: `finance_payments/${payment.id}`,
    comments: `finance_payments/${payment.id}`,
    sections: [
      { 
        title: 't(Payment Details)',
        items
      }
    ]
  }

  if(payment.method === 'check' && payment.photo) {
    const value = { asset_id: payment.photo.id, ...payment.photo }
    details.sections[0].items.unshift({ component: <Receipt preview={ false } value={ value } /> })
    details.header = <Receipt preview={ true } value={ value } />
  }

  if(payment.voided_date !== null) {
    details.alert = { color: 'red', message: 't(This payment was voided)' }
  } else if(payment.status === 'captured') {
    details.alert = { color: 'teal', message: 't(This payment is pending settlement)' }
  } else if(payment.status === 'settled') {
    details.alert = { color: 'blue', message: 't(This payment has been settled)' }
  } else if(payment.status === 'deposited') {
    details.alert = { color: 'violet', message: 't(This payment has been deposited)' }
  } else if(payment.status === 'received') {
    details.alert = { color: 'green', message: 't(This payment was received)' }
  }

  if(payment.status === 'deposited') {

    const deposit = {
      className: 'link',
      label: moment(payment.deposit.date).format('MM/DD/YYYY'),
      route: `/admin/finance/deposits/${payment.deposit.id}`
    }

    details.sections.push({
      title: 't(Deposited)',
      items: [
        { label: 't(Bank Account)', content: payment.deposit.bank.title },
        { label: 't(Date)', content: <Button { ...deposit } /> }
      ]
    })

  }

  if(payment.status === 'voided') {
    details.sections.push({
      title: 't(Voided)',
      items: [
        { label: 't(Date)', content: moment(payment.voided_date).format('MM/DD/YYYY') },
        { label: 't(Reason)', content: payment.voided_reason }
      ]
    })
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  payment: PropTypes.object
}

export default Overview
