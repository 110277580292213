import Page from '@admin/components/page'
import Avatar from '@admin/components/avatar'
import Features from '../features'
import Overview from './overview'
import Teams from './teams'
import React from 'react'
import Team from './team'

const mapResourcesToPage = (props, context) => ({
  account: `/api/admin/platform/accounts/${props.params.id}`,
  teams: `/api/admin/platform/accounts/${props.params.id}/teams`
})

const mapPropsToPage = (props, context, resources) => ({
  title: resources.account.full_name,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  tabs: {
    header: <Avatar user={ resources.account } width="120" presence={ false } />,
    items:  [
      { label: 't(Overview)', component: <Overview account={ resources.account } /> },
      { label: 't(Teams)', component: <Teams teams={ resources.teams } /> }
    ]
  },
  tasks: {
    items: [
      {
        label: 't(Add Team)',
        modal: <Team account={ resources.account } />
      },
      {
        label: 't(Manage Features)',
        modal: <Features account={ resources.account } />
      },
      {
        label: 't(Unblock Account)',
        show: resources.account.is_blocked,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/accounts/${resources.account.id}/unblock`,
          onFailure: (result) => context.flash.set('error', 't(Unable to unblock this account)'),
          onSuccess: (result) => context.flash.set('success', 't(The account has been unblocked)')
        }
      }, {
        label: 'Reset Password',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/accounts/${resources.account.id}/reset`,
          onFailure: (result) => context.flash.set('error', 't(Unable to reset the accounts password)'),
          onSuccess: (result) => context.flash.set('success', 't(Sucessfully sent password reset email)')
        }
      },
      {
        label: 'Sign Out of All Devices',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/accounts/${resources.account.id}/signout`,
          onFailure: (result) => context.flash.set('error', 't(Unable to sign out this account)'),
          onSuccess: (result) => context.flash.set('success', 't(The account has been signed out of all devices)')
        }
      }
    ]
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
