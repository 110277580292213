import ContactAvatarToken from '@apps/crm/admin/tokens/contact_avatar'
import Icon from '@admin/components/icon'
import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class CallToken extends React.Component {

  static propTypes = {
    call: PropTypes.object,
    perspective: PropTypes.string
  }

  render() {
    const { call, perspective } = this.props
    return (
      <div className="call-token">
        <div className="call-token-avatar">
          { perspective === 'program' &&
            <ContactAvatarToken { ...call.contact } />
          }
          { perspective === 'contact' &&
            <Logo team={ call.program } />
          }
          <div className="call-token-direction">
            { call.direction === 'inbound' ?
              <Icon icon="arrow-left" /> :
              <Icon icon="arrow-right" />
            }
          </div>
        </div>
        <div className="call-token-details">
          { call.direction === 'inbound' && perspective === 'contact' &&
            <>
              <div className="call-token-recipient">To: { call.program.title }</div>
              <div className="call-token-number">{ call.to_number.formatted }</div>
            </>
          }
          { call.direction === 'inbound' && perspective === 'program' &&
            <>
              <div className="call-token-recipient">From: { call.contact.display_name }</div>
              <div className="call-token-number">{ call.from_number.formatted }</div>
            </>
          }
          { call.direction === 'outbound' && perspective === 'contact' &&
            <>
              <div className="call-token-recipient">From: { call.program.title }</div>
              <div className="call-token-number">{ call.from_number.formatted }</div>
            </>
          }
          { call.direction === 'outbound' && perspective === 'program' &&
            <>
              <div className="call-token-recipient">To: { call.contact.display_name }</div>
              <div className="call-token-number">{ call.to_number.formatted }</div>
            </>
          }
          { call.status === 'completed' ?
            <div className="call-token-timestamp">
              { this._getTimestamp() }
            </div> :
            <div className={`call-token-status ${call.status}`}>
              { call.status }
            </div>
          }
        </div>
      </div>
    )
  }

  _getTimestamp() {
    const { call } = this.props
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const created_at = moment(call.created_at)
    if(today.format('YYMMDD') === created_at.format('YYMMDD')) return created_at.format('h:mmA')
    if(yesterday.format('YYMMDD') === created_at.format('YYMMDD')) return 'Yesterday'
    if(today.diff(created_at, 'days') < 7) return created_at.format('dddd')
    return created_at.format('MM/DD/YY')
  }

}

export default CallToken
