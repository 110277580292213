import CheckboxesField from '@apps/crm/admin/components/checkboxesfield'
import RadioGroup from '@admin/components/form/radiogroup'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class ListCriteria extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    code: PropTypes.string,
    comparisons: PropTypes.array,
    endpoint: PropTypes.string,
    filter: PropTypes.object,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    name: PropTypes.string,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  static defaultProps = {
    textKey: 'text',
    valueKey: 'value'
  }

  state = {
    operator: null,
    value: null,
    data: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleOperator = this._handleOperator.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-criterion-form">
          <div className="maha-criterion-form-header">
            <RadioGroup { ...this._getRadioGroup() } />
          </div>
          <div className="maha-criterion-form-body">
            <CheckboxesField { ...this._getCheckboxesField() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this._handleSet(defaultValue)
  }

  componentDidUpdate(prevProps, prevState) {
    const { operator, value } = this.state
    if(operator !== prevState.operator) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevState.value)) {
      this._handleChange()
    }
  }

  _getOperators() {
    const { comparisons } = this.props
    return comparisons || [
      { value: '$eq', text: 't(is)' },
      { value: '$neq', text: 't(is not)' },
      { value: '$in', text: 't(is one of)' },
      { value: '$nin', text: 't(is not one of)' }
    ]
  }

  _getOptions() {
    const { options } = this.props
    if(!options) return null
    return options.map(option => {
      return _.isString(option) ? { value: option, text: option } : option
    })
  }

  _getPanel() {
    const { value } = this.state
    const { name } = this.props
    return {
      title: name,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ],
      color: 'grey',
      buttons: [{
        label: 'Add Criteria',
        color: 'blue',
        disabled: value === null,
        handler: this._handleDone
      }]
    }
  }

  _getRadioGroup() {
    const options = this._getOperators()
    const { operator } = this.state
    return {
      defaultValue: operator || options[0].value,
      deselectable: false,
      options,
      onChange: this._handleOperator
    }
  }

  _getMultiple() {
    const { multiple } = this.props
    const { operator } = this.state
    return multiple || _.includes(['$in','$nin','$jin','$njin'],operator)
  }

  _getCheckboxesField() {
    const { endpoint, filter, textKey, valueKey } = this.props
    return {
      defaultValue: this.state.value ? _.castArray(this.state.value) : null,
      endpoint,
      filter,
      multiple: this._getMultiple(),
      options: this._getOptions(),
      valueKey: !endpoint ? valueKey : null,
      textKey,
      onChange: this._handleUpdate
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange() {
    const { data, operator, value } = this.state
    const multiple = this._getMultiple()
    const { code } = this.props
    if(!value) return
    this.props.onChange({
      code,
      operator,
      value: multiple ? value : value[0],
      data: multiple ? data : data[0]
    })
  }

  _handleDone() {
    const { data, operator, value } = this.state
    const multiple = this._getMultiple()
    const { code } = this.props
    this.props.onDone({
      code,
      operator,
      value: multiple ? value : value[0],
      data: multiple ? data : data[0]
    })
  }

  _handleOperator(operator) {
    this.setState({ operator })
  }

  _handleSet(defaultValue) {
    const operator = Object.keys(defaultValue)[0]
    const value = defaultValue[operator]
    this.setState({ value })
  }

  _handleUpdate(selected) {
    const { endpoint, valueKey, textKey } = this.props
    const records = _.castArray(selected)
    const values = endpoint ? records.map(record => {
      return _.get(record, valueKey)
    }) : records
    const data = endpoint ? records.map(record => ({
      value: _.get(record, valueKey),
      text: _.get(record, textKey)
    })) : []
    this.setState({
      data: data,
      value: values
    })
  }

}

export default ListCriteria
