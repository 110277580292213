import { useAdminContext } from '@admin/components/admin'
import momenttz from 'moment-timezone'
import PropTypes from 'prop-types'
import moment from 'moment'

const Date = (props) => {

  const { account } = useAdminContext().admin

  const { datetime, date } = props

  const show = props.show || ['date','time']

  const date_format = props.date_format || account.date_format || 'MM/DD/YYYY'
  const time_format = props.time_format || account.time_format || 'hh:mm A'
  const timezone = props.timezone || account.timezone

  if(date) return moment(date).format(date_format)

  if(!datetime) return null

  const format = []
  if(show.includes('date')) format.push(date_format)
  if(show.includes('time')) format.push(time_format)

  return momenttz.tz(datetime, timezone).format(format.join(' [@] '))

}

Date.propTypes = {
  datetime: PropTypes.any,
  date: PropTypes.any,
  date_format: PropTypes.string,
  show: PropTypes.any,
  timezone: PropTypes.array,
  time_format: PropTypes.string
}

export default Date
