import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Greeting extends React.Component {

  static propTypes = {
    user: PropTypes.object
  }

  state = {
    time: moment().format('h:mm a')
  }

  _updateTime = this._updateTime.bind(this)

  render() {
    const { user } = this.props
    const first_name = user.full_name.split(' ')[0]
    return (
      <div className={`maha-greeting ${ this._getTimeOfDay() }`}>
        <div className="maha-greeting-wrapper">
          <div className="maha-greeting-welcome">
            { this._getSalutation(this.state.time) }, { first_name }!
          </div>
          <div className="maha-greeting-time">
            { this.state.time }
          </div>
          <div className="maha-greeting-date">
            { moment().format('dddd, MMM Do, YYYY') }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.interval = setInterval(this._updateTime, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  _updateTime() {
    this.setState({
      time: moment().format('h:mm a')
    })
  }

  _getSalutation() {
    if( this._getTimeOfDay() === 'afternoon' && moment().format('dd') == 'Fr' ) return 'T.G.I.F.'
    if( this._getTimeOfDay() === 'night' ) return 'It\'s late - go to bed'
    return (this._getTimeOfDay()) ? 'Good ' + _.startCase(this._getTimeOfDay()) : 'Welcome'
  }

  _getTimeOfDay(){
    const currentHour = parseFloat(moment().format('HH'))
    if(currentHour >= 5 && currentHour <= 11) {
      return 'morning'
    } else if(currentHour >= 12 && currentHour <= 16) {
      return 'afternoon'
    } else if(currentHour >= 17 && currentHour <= 21) {
      return 'evening'
    } else {
      return 'night'
    }
  }

}

export default Greeting
