import Infinite from '@admin/components/infinite'
import Page from '@admin/components/page'
import { getCode } from '@core/utils/codes'
import Feed from '@admin/components/feed'
import PropTypes from 'prop-types'
import React from 'react'

export class Notifications extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object,
    network: PropTypes.object,
    notifications: PropTypes.object,
    router: PropTypes.object
  }

  state = {
    cacheKey: null
  }

  _handleRefresh = this._handleRefresh.bind(this)
  _handleClick = this._handleClick.bind(this)

  render() {
    return <Infinite { ...this._getInfinite() } />
  }

  componentDidMount() {
    this._handleJoin()
    this._handleSeen()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  _getInfinite() {
    const { cacheKey } = this.state
    return {
      cacheKey,
      endpoint: '/api/admin/notifications',
      sort: { key: 'created_at', order: 'desc' },
      layout: Feed,
      props: {
        onClick: this._handleClick
      },
      empty: {
        icon: 'bell',
        title: 't(No Notifications)',
        text: 't(You have not yet received any notifications)'
      }
    }
  }

  _handleClick(notification) {
    const { admin, host, notifications, router } = this.context
    const { team } = admin
    const { object_team } = notification
    if(!notification.is_visited) notifications.markVisited(notification.id)
    if(!notification.url) return
    if(object_team?.id !== team.id) return host.window.open(`/${object_team.subdomain}${notification.url}`)
    router.push(notification.url)
  }

  _handleJoin() {
    const { admin } = this.context
    const { user } = admin
    this.context.network.subscribe({ 
      channel: `/user/${user.id}/admin/notifications`,
      action: 'refresh', 
      handler: this._handleRefresh 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { user } = admin
    this.context.network.unsubscribe({ 
      channel: `/user/${user.id}/admin/notifications`,
      action: 'refresh', 
      handler: this._handleRefresh 
    })
  }

  _handleRefresh() {
    this.setState({
      cacheKey: getCode(5)
    })
  }

  _handleSeen() {
    this.context.network.request({
      endpoint: '/api/admin/notifications/seen',
      method: 'PATCH',
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

}

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Notifications)',
  component: Notifications
})

export default Page(null, mapPropsToPage)
