import getDateRange from '@core/utils/get_date_range'
import PropTypes from 'prop-types'
import React from 'react'

const DateRangeToken = ({ daterange }) => {
  const { label, start, end } = getDateRange(daterange)
  return <>{ label } ({ start.format('MM/DD/YY') } - { end.format('MM/DD/YY') })</>
}

DateRangeToken.propTypes = {
  daterange: PropTypes.object
}

export default DateRangeToken
