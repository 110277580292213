import Confirmation from './confirmation'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import Signature from './signature'
import PropTypes from 'prop-types'
import Security from './security'
import Limits from './limits'
import Rules from './rules'
import React from 'react'
import SEO from './seo'

class Settings extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    components: PropTypes.object,
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  render() {
    const sections = this._getSections()
    return (
      <div className="designer-page-sections">
        { sections.map((section, index) => (
          <div key={`section_${index}`} className="designer-page-section" onClick={ this._handleChoose.bind(this, index) }>
            <div className="designer-page-section-label">
              <T text={ section.label } />
            </div>
            <div className="designer-page-section-proceed">
              <Icon icon="chevron-right" />
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getSections() {
    const { features } = this.context.admin
    return [
      { label: 't(Confirmation Page)', component: Confirmation },
      { label: 't(Limits)', component: Limits },
      { label: 't(Rules)', component: Rules },
      { label: 't(SEO)', component: SEO },
      { label: 't(Security)', component: Security },
      ...features.agreements ? [{ label: 't(Signature)', component: Signature }] : []
    ]
  }

  _getSection() {
    const { config, components, onPop, onPush, onUpdate } = this.props
    return {
      config,
      components,
      onPop,
      onPush,
      onUpdate
    }
  }

  _handleChoose(index) {
    const sections = this._getSections()
    const section = sections[index]
    this.props.onPush(section.component, this._getSection.bind(this) )
  }

}

export default Settings
