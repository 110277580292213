import SMSDesigner from '@apps/phone/admin/components/sms_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    sms_campaign: PropTypes.object
  }

  render() {
    return <SMSDesigner { ...this._getVoiceDesigner() } />
  }

  _getVoiceDesigner() {
    const { sms_campaign } = this.props
    return { sms_campaign }
  }

}

const mapResourcesToPanel = (props, context) => ({
  programfields: `/api/admin/team/programs/${props.sms_campaign.program.id}/fields`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design programfields={ props.programfields } sms_campaign={ props.sms_campaign } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
