import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

export const DeviceContext = React.createContext()
DeviceContext.displayName = 'DeviceContext'

export const useDeviceContext = () => React.useContext(DeviceContext)

class Device extends React.Component {

  static childContextTypes = {
    device: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.object,
    children: PropTypes.any,
    device: PropTypes.object,
    fingerprint: PropTypes.string,
    onSetDevice: PropTypes.func,
    onSetFingerprint: PropTypes.func
  }

  _handleGetConnection = this._handleGetConnection.bind(this)
  _handleGetChunkSize = this._handleGetChunkSize.bind(this)
  _handleSaveToken = this._handleSaveToken.bind(this)

  render() {
    const { children, device } = this.props
    return (
      <DeviceContext.Provider value={ this.getChildContext() }>
        { device ? children : null }
      </DeviceContext.Provider>
    )
  }

  componentDidMount() {
    this._handleLoadFingerprint()
  }

  getChildContext() {
    return {
      device: {
        device: this.props.device,
        fingerprint: this.props.fingerprint,
        getConnection: this._handleGetConnection,
        getChunkSize: this._handleGetChunkSize,
        saveToken: this._handleSaveToken
      }
    }
  }

  _handleCreateDevice() {
    const { fingerprint } = this.props
    this.props.context.network.request({
      endpoint: '/api/admin/devices',
      method: 'POST',
      body: { fingerprint },
      onSuccess: ({ data }) => {
        this.props.onSetDevice(data)
      },
      onFailure: () => {}
    })
  }

  _handleCreateFingerprint() {
    this.props.context.local_storage.set({
      key: 'fingerprint',
      value: getCode(6),
      onSuccess: (fingerprint) => {
        this._handleSetFingerprint(fingerprint)
      },
      onFailure: () => {}
    })
  }

  _handleGetConnection() {
    return navigator?.connection?.effectiveType || '3g'
  }

  _handleGetChunkSize() {
    const connection = this._handleGetConnection()
    if(connection === 'slow-2g') return 512 * 1024
    if(connection === '2g') return 1024 * 1024
    if(connection === '4g') return 5 * 1024 * 1024
    return 2 * 1024 * 1024
  }

  _handleLoadFingerprint() {
    this.props.context.local_storage.get({
      key: 'fingerprint',
      onSuccess: (fingerprint) => {
        if(!fingerprint) return this._handleCreateFingerprint()
        this._handleSetFingerprint(fingerprint)
      },
      onFailure: () => {}
    })
  }

  _handleSaveToken(push_token) {
    const { fingerprint } = this.props
    this.props.context.network.request({
      endpoint: `/api/admin/devices/${fingerprint}`,
      method: 'PATCH',
      body: { fingerprint, push_token },
      onSuccess: () => {},
      onFailure: () => {}
    })
  } 

  _handleSetFingerprint(fingerprint) {
    this.props.onSetFingerprint(fingerprint)
    this.props.context.maha.setFingerprint(fingerprint)
    this._handleCreateDevice()
  }

}

export default Device
