import New from '@admin/components/mjson_designer/entities/page/pages/new'
import ElementToken from '@admin/components/mjson_designer/tokens/element'
import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Pages extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.object,
    website: PropTypes.object,
    onBack: PropTypes.func,
    onNavigate: PropTypes.func,
    onSettings: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)
  _handleNew = this._handleNew.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      showHeader: false,
      buttons: [
        { label: 't(Create New Page)', color: 'blue', handler: this._handleNew }
      ]
    }
  }

  _getProps() {
    const { website } = this.props
    return {
      website,
      onDone: this._handleChoose
    }
  }

  _getSearch() {
    const { team } = this.context.admin
    const { website } = this.props
    return {
      endpoint: `/api/admin/websites/websites/${website.id}/pages`,
      refresh: `/teams/${team.id}/admin/websites/${website.id}/pages`,
      format: (page) => <ElementToken icon="file-o" type="page" element={ page } />,
      label: 'Page',
      valueKey: null,
      textKey: 'title',
      onChange: this._handleChoose
    }
  }

  _handleChoose(page) {
    this.props.onSettings(null)
    this.props.onNavigate(`/websites_pages/${page.id}`)
  }

  _handleNew() {
    this.context.modal.open(<New { ...this._getProps() } />)
  }

}

export default Pages
