import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import AppsField from './appsfield'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const AppsFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'appsfield',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <AppsField { ...mergedProps } />

}

AppsFieldContainer.propTypes = {}

export default AppsFieldContainer