import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    post: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { admin } = this.context
    const { website } = this.props
    const { post } = this.state
    return {
      title: 't(New Post)',
      method: 'POST',
      action: `/api/admin/websites/websites/${website.id}/posts`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Date)', name: 'date', type: 'datefield', placeholder: 'Enter a date', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Author)', name: 'author_id', type: 'lookup', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', required: true, value: admin.user.id, format: UserToken },
            { label: 't(Post)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'new', text: 't(Design from scratch)' },
                { value: 'truevail', text: 't(Import from the Truevail library)' }
              ], defaultValue: 'new' },
              { name: 'truevail_post_id', required: true, type: 'lookup', include: post.strategy === 'truevail', placeholder: 'Choose a post', endpoint: '/api/admin/truevail/posts', valueKey: 'id', textKey: 'title' },
              { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 'Enter a title', include: post.strategy === 'new', required: true },
              { label: 't(URL)', name: 'path', type: 'textfield', prefix: website.maha_host, placeholder: '/path', include: post.strategy === 'new', required: true, value: post.path }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(post, prevPost) {
    this.setState({ post })
  }

  _handleSuccess(post) {
    this.props.onDone(post)
    this.context.modal.close()
  }

}

export default New
