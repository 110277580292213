import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.embed.title,
  manager: {
    path: `/admin/forms/forms/${resources.form.id}/embeds/${resources.embed.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview form={ resources.form } embed={ resources.embed } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design form={ resources.form } embed={ resources.embed } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  form: `/api/admin/forms/forms/${props.params.form_id}`,
  embed: `/api/admin/forms/forms/${props.params.form_id}/embeds/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
