import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const requiredField = (label, item, path, extra) => {
  if(!_.isNil(_.get(item, path))) {
    return { label, content: _.get(item, path), ...extra }
  } else  {
    return { label, content: 'REQUIRED', className: 'error' }
  }
}

const Overview = ({ advance }) => {

  const details = {
    audits: `finance_advances/${advance.id}`,
    comments: `finance_advances/${advance.id}`
  }

  if(advance.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This advance was deleted)' }
  } else if(advance.status === 'incomplete') {
    details.alert = { color: 'grey', message: 't(This advance is missing required information)' }
  } else if(advance.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This advance has not yet been submitted)' }
  } else if(advance.status === 'submitted') {
    details.alert = { color: 'blue', message: 't(This advance has been submitted for review)' }
  } else if(advance.status === 'approved') {
    details.alert = { color: 'green', message: 't(This advance has been approved)' }
  } else if(advance.status === 'rejected') {
    details.alert = { color: 'red', message: 't(This advance has been rejected)' }
  } else if(advance.status === 'reviewed') {
    details.alert = { color: 'pink', message: 't(This advance has been reviewed)' }
  } else if(advance.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This advance was exported)' }
  }

  details.items = [
    requiredField('User', advance, 'user.full_name'),
    requiredField('Date Needed', advance, 'date_needed', { content: advance.date_needed, format: 'date' }),
    requiredField('Project', advance, 'project.title', { content: advance, format: CompactProjectToken }),
    requiredField('Expense Type', advance, 'expense_type.title', { content: advance, format: CompactExpenseTypeToken }),
    requiredField('Description', advance, 'description'),
    requiredField('Amount', advance, 'amount')
  ]

  return <Details { ...details } />
  
}

Overview.propTypes = {
  advance: PropTypes.object
}

export default Overview
