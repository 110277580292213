import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'
import React from 'react'

const colors = [
  '#B91F1F',
  '#DB2828',
  '#D55C0C',
  '#F2711C',
  '#D49F03',
  '#FBBD08',
  '#97AA14',
  '#B5CC18',
  '#1B9B3A',
  '#21BA45',
  '#009790',
  '#00B5AD',
  '#1B6FAD',
  '#2185D0',
  '#532CA8',
  '#6435C9',
  '#882BA7',
  '#A333C8',
  '#CA2080',
  '#E03997'
]

class PieChart extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    datasets: PropTypes.array,
    options: PropTypes.object,
    type: PropTypes.string
  }

  canvasRef = React.createRef()
  chart = null
  ctx = null

  render() {
    return <canvas ref={ this.canvasRef }/>
  }

  componentDidMount() {
    this._handleInit()
  }

  componentDidUpdate(prevProps) {
    const { options, datasets } = this.props
    if(!_.isEqual(options, prevProps.options)) {
      this._handlePlot()
    }
    if(!_.isEqual(datasets, prevProps.datasets)) {
      this._handlePlot()
    }
  }

  _handleInit() {
    const { datasets, options } = this.props
    const { cutout, legend } = options
    this.ctx = this.canvasRef.current.getContext('2d')
    this.chart = new Chart(this.ctx, {
      type: 'doughnut',
      data: {
        labels: datasets[0].points.map(r => r.x),
        datasets: datasets.map(dataset => ({
          label: dataset.label,
          data: dataset.points.map(r => r.y)
        }))
      },
      options: {
        cutout,
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            ...legend ? {
              position: 'bottom',
              display: true
            } : {
              display: false
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    })
  }

  _handlePlot() {
    const { datasets, options } = this.props
    const { cutout, legend } = options
    if(!this.chart) this._handleInit()
    this.chart.options.cutout = cutout
    this.chart.options.plugins.legend = legend ? {
      position: 'bottom',
      display: true
    } : {
      display: false
    }
    this.chart.data.labels = []
    this.chart.data.datasets.pop()
    this.chart.data.datasets.pop()
    datasets[0].points.map(r => this.chart.data.labels.push(r.x))
    datasets.map(dataset => {
      this.chart.data.datasets.push({
        label: dataset.label,
        data: dataset.points.map(r => r.y),
        backgroundColor: colors
      })  
    })
    this.chart.update()
  }

}

export default PieChart
