import AccordianStyleField from '@admin/components/mjson_designer/components/accordianstylefield'
import TextOverrideStyleField from '@admin/components/mjson_designer/components/textstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import ItemStyleField from '@admin/components/mjson_designer/components/itemstylefield'
import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

export const TextOverride = (props) => <ResponsiveField { ...props } component={ TextOverrideStyleField } />

class EditAccordianBlock extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, parent, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Items', name: 'content.items', type: 'collectionfield', fields: [
                  { label: 't(Title)', name: 'title', type: 'textfield', required: true },
                  { label: 't(Description)', name: 'description', type: 'textarea' }
                ], token: ({ value }) => (
                  <div className="token">
                    { value.title }
                  </div>
                ), defaultValue: [
                  { title: 'Question 1', description: 'Answer 1' },
                  { title: 'Question 2', description: 'Answer 2' },
                  { title: 'Question 3', description: 'Answer 3' }
                ], value: config.content.items }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 'Show', name: 'content.show', type: 'checkboxgroup', options: [
                  { value: 'expander', text: 't(Expand/Contract All)' }
                ], defaultValue: [], value: config.content.show }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Accordian)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Items)', name: 'styles.items', collapsing: true, collapsed: true, type: ItemStyleField, device, theme, defaultValue: {
                  border: {
                    type: 'bottom',
                    width: '1px',
                    style: 'solid',
                    color: {
                      value: '#000000'
                    }
                  }
                }, value: config.styles.items },
                { label: 't(Item)', name: 'styles.item', collapsing: true, collapsed: true, type: ItemStyleField, device, theme, defaultValue: {
                  border: {
                    type: 'bottom',
                    width: '1px',
                    style: 'solid',
                    color: {
                      value: '#000000'
                    }
                  }
                }, value: config.styles.item },
                { label: 't(Title)', name: 'styles.title', collapsing: true, collapsed: true, type: AccordianStyleField, device, theme, defaultValue: {
                  text: {
                    preset: 'heading',
                    color: {
                      value: 'default'
                    }
                  },
                  padding: '10px 0 10px 0'
                }, value: config.styles.title },
                { label: 't(Description)', name: 'styles.description', collapsing: true, collapsed: true, type: AccordianStyleField, device, theme, defaultValue: {
                  text: {
                    preset: 'text',
                    color: {
                      value: 'default',
                      opacity: '90%'
                    }
                  },
                  padding: '0 0 10px 0'
                }, value: config.styles.description },
                { label: 't(Expand Icon)', name: 'styles.icon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, defaultValue: {
                  size: '32px',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.icon },
                { label: 't(Expand All/Contract All)', device, name: 'styles.expanderText', collapsing: true, collapsed: true, type: AccordianStyleField, theme, defaultValue: {
                  text: {
                    preset: 'text',
                    color: {
                      value: '#2185D0'
                    }
                  }
                }, value: config.styles.expander }
              ]
            },
            ...styles.getIsFlexChild(parent) ? [
              styles.FlexChildSection(config, device, theme, website, type)
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditAccordianBlock
