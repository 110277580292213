import ModuleToken from '@apps/maha/admin/tokens/module'
import New from '@apps/platform/admin/views/modules/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Modules)',
  collection: {
    endpoint: '/api/admin/platform/modules',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (module) => (
        <ModuleToken module={ module } />
      ) }
    ],
    defaultSort: { key: 'title', order: 'desc' },
    empty: {
      icon: 'copy',
      title: 't(No Modules)',
      text: 't(You have not yet added any modules)'
    },
    entity: 'module',
    onClick: (record) => context.router.push(`/platform/modules/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Module)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
