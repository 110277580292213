const isNumeric = (str) => /^\d+$/.test(str);

const shouldBeArray = (obj) => {
  const keys = Object.keys(obj);
  const numericKeys = keys.filter(isNumeric).map(Number);
  return keys.length > 0 && 
         numericKeys.length === keys.length && 
         numericKeys[0] === 0 &&
         numericKeys.every((key, index) => key === index);
};

export const flatten = (obj, prefix = '') => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value) && value.length === 0) {
      // Preserve empty arrays
      acc[newKey] = [];
    } else if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flatten(value, newKey));
    } else {
      acc[newKey] = value;
    }

    return acc;
  }, {});
};

export const unflatten = (obj) => {
  const result = {};

  Object.entries(obj).forEach(([key, value]) => {
    const parts = key.split('.');
    let current = result;

    for (let i = 0; i < parts.length - 1; i++) {
      if (current[parts[i]] === null || current[parts[i]] === undefined) {
        current[parts[i]] = isNumeric(parts[i + 1]) ? {} : {};
      }
      current = current[parts[i]];
    }

    current[parts[parts.length - 1]] = value;
  });

  const convertToArrays = (obj, isTopLevel = false) => {
    if (Array.isArray(obj)) {
      return obj.map(item => convertToArrays(item));
    }
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (!isTopLevel && shouldBeArray(obj)) {
      return Object.entries(obj)
        .sort(([a], [b]) => Number(a) - Number(b))
        .map(([_, v]) => convertToArrays(v));
    }

    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, convertToArrays(v)])
    );
  };

  return convertToArrays(result, true);
};