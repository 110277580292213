import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const purposes = {
  later: {
    title: 't(Save as Draft)',
    text: 't(Save this social campaign as a draft and schedule later)'
  },
  schedule: {
    title: 't(Schedule post)',
    text: 't(Schedule this social campaign to be posted at a later date)'
  },
  now: {
    title: 't(Post immediately)',
    text: 't(Post this social campaign now)'
  }
}

const SchedulingStrategyToken = ({ value }) => (
  <div className="token scheduling-token">
    <strong><T text={ purposes[value].title } /></strong><br />
    <T text={ purposes[value].text } />
  </div>
)

SchedulingStrategyToken.propTypes = {
  value: PropTypes.string
}

export default SchedulingStrategyToken
