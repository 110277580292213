import Logo from '@admin/components/logo'
import React from 'react'

const ProgramToken = (phone_number) => (
  <div className="program-token">
    <div className="program-token-logo">
      <Logo team={ phone_number.program } width="24" />
    </div>
    <div className="program-token-label">
      { phone_number.formatted }
    </div>
  </div>
)

export default ProgramToken
