import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import CompactVendorToken from '@apps/finance/admin/tokens/vendor/compact'
import AllocationsToken from '@apps/finance/admin/tokens/allocations'
import Receipt from '@apps/finance/admin/components/receipt'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const requiredField = (label, item, path, extra) => {
  if(!_.isNil(_.get(item, path))) {
    return { label, content: _.get(item, path), ...extra }
  } else  {
    return { label, content: 'REQUIRED', className: 'error' }
  }
}

const Overview = ({ check }) => {

  const details = {
    audits: `finance_checks/${check.id}`,
    comments: `finance_checks/${check.id}`
  }

  details.items = [
    requiredField('User', check, 'user.full_name'),
    requiredField('Date Needed', check, 'date_needed', { content: check.date_needed, format: 'date' }),
    requiredField('Vendor', check, 'vendor.name', { content: check, format: CompactVendorToken }),
    requiredField('Delivery Method', check, 'delivery_method'),
    { label: 't(Account Number)', content: check.account_number },
    { label: 'Invoice Number', content: check.invoice_number }
  ]

  if(check.allocations.length > 1 ) {
    details.items.push(requiredField('Total', check, 'total', { content: check.total, format: 'currency' }))
    details.items.push(requiredField('Tax', check, 'tax_total', { content: check.tax_total, format: 'currency' }))
    details.items.push({ component: <AllocationsToken allocations={ check.allocations } item={ check } /> })
  } else {
    details.items.push(requiredField('Project', check, 'project.title', { content: check, format: CompactProjectToken }))
    details.items.push(requiredField('Expense Type', check, 'expense_type.title', { content: check, format: CompactExpenseTypeToken }))
    details.items.push(requiredField('Description', check, 'description', { content: check.description }))
    details.items.push(requiredField('Amount', check, 'amount', { content: check.amount, format: 'currency' }))
    details.items.push(requiredField('Tax', check, 'tax', { content: check.tax, format: 'currency' }))
  }
  
  if(check.receipts.length > 0) {
    const previews = check.receipts.filter(receipt => receipt.status === 'processed' && (receipt.has_preview || receipt.is_image))
    const slides = previews.map((receipt, index) => <Receipt key={`receipt_preview_${index}`} preview={ true } value={ receipt } />)
    details.header = <Carousel slides={ slides } />
    details.items.unshift({ content: check.receipts.map((receipt, index) => <Receipt key={`receipt_${index}`} preview={ false } value={ receipt } />), className: 'receipts' })
  } else {
    details.items.unshift(requiredField('Receipt', check, 'receipts_ids'))
  }

  if(check.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This check was deleted)' }
  } else if(check.status === 'incomplete') {
    details.alert = { color: 'grey', message: 't(This check is missing required information)' }
  } else if(check.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This check has not yet been submitted)' }
  } else if(check.status === 'submitted') {
    details.alert = { color: 'blue', message: 't(This check has been submitted for review)' }
  } else if(check.status === 'approved') {
    details.alert = { color: 'green', message: 't(This check has been approved)' }
  } else if(check.status === 'rejected') {
    details.alert = { color: 'red', message: 't(This check has been rejected)' }
  } else if(check.status === 'reviewed') {
    details.alert = { color: 'pink', message: 't(This check has been reviewed)' }
  } else if(check.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This check was exported)' }
  }

  return <Details { ...details } />
}

Overview.propTypes = {
  check: PropTypes.object,
  commentUrl: PropTypes.string
}

export default Overview
