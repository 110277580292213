import AssetThumbnail from '@admin/components/asset_thumbnail'
import React from 'react'

const AgreementToken = (agreement) => (
  <div className="crm-agreement-token">
    <div className="crm-agreement-token-asset">
      <AssetThumbnail { ...agreement.unsigned || agreement.signed } />
    </div>
    <div className="crm-agreement-token-details">
      { agreement.file_name }<br />
    </div>
  </div>
)

export default AgreementToken
