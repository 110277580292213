import T from '@admin/components/t'
import IconToken from '@admin/tokens/icon'
import PropTypes from 'prop-types'
import React from 'react'

const BlockTypeToken = ({ block }) => {
  const icon = {
    icon: {
      social: block.social,
      noun_project_id: block.noun_project_id
    },
    ...block.social ? {
      bg: '2185d0',
      fg: 'fff9e8',
      r: 32,
      w: 32  
    } : {
      fg: '2185d0',
      w: 32 
    }
  }
  return (
    <div className="block-type-token">
      <div className="block-type-token-icon">
        <IconToken { ...icon } />
      </div>
      <div className="block-type-token-label">
        <T text={ block.label } />
      </div>
    </div>
  )
}

BlockTypeToken.propTypes = {
  block: PropTypes.object
}

export default BlockTypeToken
