import CriteriaDesigner from '@admin/components/criteria_designer'
import ProductToken from '@apps/stores/admin/tokens/product'
import ModalPanel from '@admin/components/modal_panel'
import RecipientToken from '../../tokens/recipient'
import PropTypes from 'prop-types'
import React from 'react'

class Orders extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    fields: PropTypes.array,
    instructions: PropTypes.any,
    program: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    store: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    criteria: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <CriteriaDesigner { ...this._getCriteriaDesigner() } />
      </ModalPanel>
    )
  }

  _getCriteriaDesigner() {
    const { channel, defaultValue, endpoint, program_id, purpose, store } = this.props
    return {
      defaultValue: defaultValue ? defaultValue.criteria : null,
      defaultQuery: {
        channel,
        program_id,
        purpose,
        store_id: store.id,
        strategy: 'orders'
      },
      endpoint,
      entity: 'contact',
      fields: [
        {
          label: 'Order',
          fields: [
            { name: 'First Name', key: 'first_name', type: 'text' },
            { name: 'Last Name', key: 'last_name', type: 'text' },
            { name: 'Email', key: 'email', type: 'text' },
            { name: 'Category', key: 'category_id', type: 'select', multiple: true, endpoint: `/api/admin/stores/stores/${store.id}/categories`, valueKey: 'id', textKey: 'title' },
            { name: 'Product', key: 'product_id', type: 'select', multiple: true, endpoint: `/api/admin/stores/stores/${store.id}/products`, valueKey: 'id', textKey: 'title', format: (product) => (
              <ProductToken product={ product } />
            ) },
            { name: 'Order Date', key: 'ordered_at', type: 'daterange', include: ['this','last'] },
            { name: 'Order Status', key: 'status', type: 'select', multiple: true, options: ['unpaid','paid','fulfilled','cancelled'] }
          ],
          virtuals: store.contact_config.fields
        }
      ],
      format: (recipient) => <RecipientToken recipient={ recipient } />,
      onChange: this._handleChange
    }
  }

  _getPanel() {
    const { instructions } = this.props
    return {
      title: 't(Select Criteria)',
      instructions,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange(criteria) {
    this.setState({ criteria })
  }

  _handleDone() {
    const { criteria } = this.state
    this.props.onDone({ criteria })
    this.context.form.pop()
  }

}

export default Orders
