export const onAddListener = (listener) => ({
  type: 'ADD_LISTENER',
  listener
})

export const onAddSubscription = (subscription) => ({
  type: 'ADD_SUBSCRIPTION',
  subscription
})

export const onRemoveListener = (listener) => ({
  type: 'REMOVE_LISTENER',
  listener
})

export const onRemoveSubscription = (subscription) => ({
  type: 'REMOVE_SUBSCRIPTION',
  subscription
})

export const onSetConnected = (connected) => ({
  type: 'SET_CONNECTED',
  connected
})
