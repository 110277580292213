export const INITIAL_STATE = {
  device: null,
  fingerprint: null
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_DEVICE':
    return {
      ...state,
      device: action.device
    }

  case 'SET_FINGERPRINT':
    return {
      ...state,
      fingerprint: action.fingerprint
    }

  default:
    return state
  }

}
