import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ app }) => {

  const details = {
    items: [
      { label: 't(Title)', content: app.title },
      { label: 't(Description)', content: app.description }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  app: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview app={ props.app } />
})

export default Panel(null, mapPropsToPanel)
