import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Call from '@apps/crm/admin/views/contacts/show/calls/new'
import SMS from '@apps/crm/admin/views/contacts/show/smses/new'
import PhoneNumber from '@admin/components/phone_number'
import Email from '@apps/email/admin/views/emails/new'
import Task from '@apps/tasks/admin/views/tasks/new'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class ContactCard extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object
  }

  static defaultProps = {
    link: true
  }

  render() {
    const { admin } = this.context
    const { contact } = this.props
    const position = this._getPosition()
    return (
      <div className="detailcard">
        <div className="detailcard-avatar">
          <ContactAvatar { ...contact } />
        </div>
        <div className="detailcard-buttons">
          <div className="detailcard-actions">
            <div className="detailcard-action">
              <Button { ...this._getVCard() } />
              <span><T text='t(vCard)' /></span>
            </div>
            { admin.hasRights({ allOf: ['tasks:access_app'] }) &&
              <div className="detailcard-action">
                <Button { ...this._getTask() } />
                <span><T text='t(Task)' /></span>
              </div>
            }
            { admin.hasRights({ allOf: ['email:access_app'] }) &&
              <div className="detailcard-action">
                <Button { ...this._getEmail() } />
                <span><T text='t(Email)' /></span>
              </div>
            }
            { admin.hasRights({ allOf: ['phone:access_app'] }) &&
              <>
                <div className="detailcard-action">
                  <Button { ...this._getCall() } />
                  <span><T text='t(Call)' /></span>
                </div>
                <div className="detailcard-action">
                  <Button { ...this._getSMS() } />
                  <span><T text='t(SMS)' /></span>
                </div>
              </>
            }
          </div>
        </div>
        <div className="detailcard-details">
          <div>
            <strong>{ contact.display_name }</strong>
          </div>
          { position &&
            <>{ position }<br /></>
          }
          { contact.email &&
            <><Button { ...this._getEmailAddress(contact.email) } /><br /></>
          }
          { contact.phone &&
            <><Button { ...this._getPhoneNumber(contact.phone) } /><br /></>
          }
          <div className="detailcard-profiles">
            <Button { ...this._getSocial('website') } />
            <Button { ...this._getSocial('linkedin') } />
            <Button { ...this._getSocial('twitter') } />
            <Button { ...this._getSocial('instagram') } />
            <Button { ...this._getSocial('facebook') } />
            <Button { ...this._getSocial('youtube') } />
          </div>
        </div>
      </div>
    )
  }

  _getCall() {
    const { contact } = this.props
    const disabled = contact.phone_numbers.filter(number => {
      return number.is_valid !== false
    }).length === 0
    return {
      icon: 'phone',
      disabled,
      className: 'detailcard-button call',
      modal: {
        component:  <Call contact={ contact } />,
        options: {
          width: 375,
          height: 667
        }
      }
    }
  }

  _getEmail() {
    const { contact } = this.props
    const disabled = contact.email_addresses.length === 0
    return {
      icon: 'envelope',
      disabled,
      className: 'detailcard-button',
      modal: <Email contact={ contact } />
    }
  }

  _getTask() {
    const { contact } = this.props
    return {
      icon: 'check',
      className: 'detailcard-button',
      modal: <Task contact={ contact } />
    }
  }

  _getEmailAddress(email_address) {
    return {
      label: email_address,
      className: 'link',
      url: `mailto:${email_address}`
    }
  }

  _getPhoneNumber(phone_number) {
    return {
      label: <PhoneNumber number={ phone_number} />,
      className: 'link',
      url: `tel:${phone_number.replace('ext=','')}`
    }
  }

  _getPosition() {
    const { contact } = this.props
    const parts = []
    if(contact.organization) parts.push(contact.organization)
    if(contact.job_title) parts.push(contact.job_title)
    return parts.length > 0 ? parts.join(', ') : null
  }

  _getShare() {
    return {
      icon: 'share-alt',
      className: 'detailcard-share',
      handler: this._handleShare
    }
  }

  _getSocial(network) {
    const { provider } = this.context
    const { contact } = this.props
    return {
      image: `${provider.cdn_host}/images/services/${network}.png`,
      className: 'detailcard-profile',
      disabled: contact[network] === null,
      link: contact[network]
    }
  }

  _getSMS() {
    const { contact } = this.props
    const disabled = contact.phone_numbers.filter(number => {
      return number.type === 'mobile' && number.is_valid !== false && number.can_text === true
    }).length === 0
    return {
      icon: 'comments',
      disabled,
      className: 'detailcard-button',
      modal: {
        component:  <SMS contact={ contact } />,
        options: {
          width: 375,
          height: 667
        }
      }
    }
  }

  _getVCard() {
    const { admin } = this.context
    const { contact } = this.props
    return {
      icon: 'id-card',
      className: 'detailcard-button',
      url: admin.signUrl(`/api/admin/crm/contacts/${contact.id}/vcard`)
    }
  }

}

export default ContactCard
