import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true,defaultValue: formdata.title },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/stores', placeholder: '/path/to/store', defaultValue: formdata.permalink }
          ]
        }, {
          label: 't(Options)',
          fields: [
            { label: 't(Cart Lifetime)', name: 'config.cart_lifetime', type: 'numberfield', instructions: `
              Duration in hours before a cart expires and is considered 'abandoned'
            `, defaultValue: formdata.config.cart_lifetime },
            { label: 't(Pay by Check)', type: 'segment', fields: [
              { prompt: 't(Enable Pay by Check)', name: 'config.check_enabled', type: 'checkbox', defaultValue: formdata.config.check_enabled },
              ...this._getCheck()
            ] },
            { label: 't(Pay Later)', type: 'segment', fields: [
              { prompt: 't(Enable Pay Later)', name: 'config.async_enabled', type: 'checkbox', defaultValue: formdata.config.async_enabled },
              ...this._getAsync()
            ] },
            { label: 't(Store Opening)', type: 'segment', fields: [
              { name: 'config.open', type: 'radiogroup', options: [
                { value: 'open', text: 't(Store is open)' },
                { value: 'limit', text: 't(Store opens at a specific time)' }
              ], deselectable: false, defaultValue: 'open' },
              ...this._getOpen()
            ] },
            { label: 't(Store Closing)', type: 'segment', fields: [
              { name: 'config.close', type: 'radiogroup', options: [
                { value: 'open', text: 't(Store never closes)' },
                { value: 'limit', text: 't(Store closes at a specific time)' }
              ], deselectable: false, defaultValue: 'open' },
              ...this._getClosed()
            ] },
            { label: 't(Confirmation Message)', name: 'config.confirmation', type: 'textarea', defaultValue: 'Thank you for your purchase' }
          ]
        }
      ]
    }
  }

  _getAsync() {
    const { formdata } = this.props
    const { config } = this.state
    return [
      { name: 'config.async_text', type: 'textfield', show: _.get(config, 'config.async_enabled') === true, placeholder: 't(Enter payment text (e.g. Pay at Pickup / Pay at Register))', required: true, defaultValue: formdata.config.async_text }
    ]
  }

  _getCheck() {
    const { formdata } = this.props
    const { config } = this.state
    return [
      { name: 'config.check_text', type: 'textarea', show: _.get(config, 'config.check_enabled') === true, placeholder: 't(Enter instructions for sending check)', required: true, defaultValue: formdata.config.check_address }
    ]
  }

  _getClosed() {
    const { config } = this.state
    return [
      { label: 't(Closes At)', name: 'config.closes_at', show: _.get(config, 'config.close') === 'limit', type: 'datetimefield', required: true },
      { label: 't(Once Closed)', name: 'config.close_strategy', show: _.get(config, 'config.close') === 'limit', type: 'radiogroup', options: [
        { value: 'message', text: 't(Show Message)' },
        { value: 'redirect', text: 't(Redirect to URL)' }
      ], deselectable: false, defaultValue: 'message' },
      { label: 't(URL)', name: 'config.close_redirect', show: _.get(config, 'config.close') === 'limit' && _.get(config, 'config.close_strategy') === 'redirect', type: 'textfield', placeholder: 'http://', required: true },
      { label: 't(Message)', name: 'config.close_message', show: _.get(config, 'config.close') === 'limit' && _.get(config, 'config.close_strategy') === 'message', type: 'htmlfield', required: true }
    ]
  }

  _getOpen() {
    const { config } = this.state
    return [
      { label: 't(Closes At)', name: 'config.opens_at', show: _.get(config, 'config.open') === 'limit', type: 'datetimefield', required: true },
      { label: 't(Once Closed)', name: 'config.open_strategy', show: _.get(config, 'config.open') === 'limit', type: 'radiogroup', options: [
        { value: 'message', text: 't(Show Message)' },
        { value: 'redirect', text: 't(Redirect to URL)' }
      ], deselectable: false, defaultValue: 'message' },
      { label: 't(URL)', name: 'config.close_redirect', show: _.get(config, 'config.open') === 'limit' && _.get(config, 'config.open_strategy') === 'redirect', type: 'textfield', placeholder: 'http://', required: true },
      { label: 't(Message)', name: 'config.close_message', show: _.get(config, 'config.open') === 'limit' && _.get(config, 'config.open_strategy') === 'message', type: 'htmlfield', required: true }
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(store) {
    this.props.onNext(store)
  }

}

export default Details
