import Options from '@admin/components/options'
import PropTypes from 'prop-types'
import Select from './select'
import React from 'react'

const SelectContainer =  React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus(),
    selectAll: () => componentRef.current._handleSelectAll(),
    deselectAll: () => componentRef.current._handleDeselectAll()
  }))

  return (
    <Options { ...props }>
      <Select ref={ componentRef } />
    </Options>
  )
})

SelectContainer.propTypes = {
  endpoint: PropTypes.string,
  filter: PropTypes.object,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  query: PropTypes.object,
  sort: PropTypes.string
}

export default SelectContainer