import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  call: `/api/admin/phone/calls/${props.params.id}`
})

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Call)',
  panel: {
    component: <Overview activities={ resources.activities } call={ resources.call } />
  },
  tasks: resources.call.direction === 'inbound' ? {
    items: [
      {
        label: 't(Block Number)',
        request: {
          endpoint: `/api/admin/phone/numbers/${resources.call.program_phone_number.id}/blocked`,
          method: 'POST',
          body: {
            number: resources.call.from_number.number
          },
          onSuccess: () => context.flash.set('success', 't(This number has been blocked)'),
          onFailure: () => context.flash.set('error', 't(Unable to block this number)')
        }
      }
    ]
  } : null
})

export default Page(mapResourcesToPage, mapPropsToPage)
