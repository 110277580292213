import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const TwilioNumberToken = (number) => (
  <div className="numberfield-number" >
    <div className="numberfield-number-icon">
      <Icon icon="mobile" />
    </div>
    <div className="numberfield-number-label">
      <strong>{ number.friendlyName }</strong><br />
      { number.locality &&
        <>{ number.locality },</>
      } { number.region }
    </div>
  </div>
)

TwilioNumberToken.propTypes = {
  number: PropTypes.object
}

export default TwilioNumberToken
