import Page from '@admin/components/page'
import Agreements from './agreements'
import Overview from './overview'
import Emails from './emails'
import Files from './files'
import Tasks from './tasks'
import React from 'react'
import SMS from './sms'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.deal.title,
  manager: {
    path: `/admin/sales/deals/${resources.deal.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview pipeline={ resources.deal.pipeline } contact_id={ resources.deal.contact.id } deal={ resources.deal } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails pipeline={ resources.deal.pipeline } deal={ resources.deal } />
      },
      {
        label: 't(SMS)',
        path: '/sms',
        panel: <SMS pipeline={ resources.deal.pipeline } deal={ resources.deal } />
      },
      {
        label: 't(Agreements)',
        path: '/agreements',
        show: context.admin.hasFeature('agreements'),
        panel: <Agreements deal={ resources.deal } />
      },
      {
        label: 'Files',
        path: '/files',
        panel: <Files deal={ resources.deal } />
      },
      {
        label: 't(Tasks)',
        show: context.admin.hasRights({ allOf: ['tasks:access_app'] }),
        path: '/tasks',
        panel: <Tasks deal={ resources.deal } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  deal: `/api/admin/sales/deals/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
