import PhoneField from '@admin/components/form/phonefield'
import ModalPanel from '@admin/components/modal_panel'
import Button from '@admin/components/button'
import { getCode } from '@core/utils/codes'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Record extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
  }

  state = {
    number: null,
    status: 'pending',
    code: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleCall = this._handleCall.bind(this)
  _handleCreated = this._handleCreated.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const { status } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="crm-recordingfield-panel">
          { status === 'pending' &&
            <div className="crm-recordingfield-field">
              <div className="crm-recordingfield-field-phone">
                <PhoneField { ...this._getPhoneField() } />
              </div>
              <div className="crm-recordingfield-field-button">
                <Button { ...this._getRecordButton() } />
              </div>
            </div>
          }
          { status === 'calling' &&
            <div className="crm-recordingfield-status calling">
              <T text="Calling" />...
            </div>
          }
          { status === 'recording' &&
            <div className="crm-recordingfield-status recording">
              <T text="Recording" />...
            </div>
          }
          { status === 'processing' &&
            <div className="crm-recordingfield-status processing">
              <T text="Processing" />...
            </div>
          }
        </div>
      </ModalPanel>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { code } = this.state
    if(code !== prevState.code && code) {
      this._handleJoin()
    }
  }

  _getPanel() {
    return {
      title: 't(Record Message)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      instructions: 't(We can call you to record a message over the phone. Just enter your number below to initiate the call)'
    }
  }

  _getPhoneField() {
    const { account } = this.context.admin
    return {
      defaultValue: account ? account.mobile_phone : null,
      onChange: this._handleUpdate
    }
  }

  _getRecordButton() {
    const { number } = this.state
    return {
      label: 'Call',
      color: 'black',
      disabled: number === null,
      handler: this._handleCall
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCall() {
    const { number } = this.state
    const code = getCode(10)
    this.setState({
      status: 'calling'
    })
    this.context.network.request({
      endpoint: '/api/admin/phone/recordings',
      method: 'POST',
      body: { code, number },
      onSuccess: ({ data }) => {
        this.setState({
          code: data
        })
      },
      onFailure: () => {}
    })
  }

  _handleCreated({ asset }) {
    this._handleLeave()
    this.props.onAdd({
      id: asset.id,
      name: asset.original_file_name,
      service: 'record',
      content_type: asset.content_type,
      asset,
      thumbnail: asset.signed_url,
      status: 'imported'
    })
  }

  _handleJoin() {
    const { network } = this.context
    const { code } = this.state
    const channel = `/admin/phone/recordings/${code}`
    network.subscribe({ channel, action: 'created', handler: this._handleCreated })
    network.subscribe({ channel, action: 'status', handler: this._handleSetStatus })
  }

  _handleLeave() {
    const { network } = this.context
    const { code } = this.state
    const channel = `/admin/phone/recordings/${code}`
    network.unsubscribe({ channel, action: 'created', handler: this._handleCreated })
    network.unsubscribe({ channel, action: 'status', handler: this._handleSetStatus })
  }

  _handleUpdate(number) {
    this.setState({ 
      number: number.length > 0 ? number : null 
    })
  }

}

export default Record
