import ContactFields from '@apps/crm/admin/components/contactfields'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Fields extends React.Component {

  static propTypes = {
    store: PropTypes.object
  }

  render() {
    return <ContactFields { ...this._getContactFields() } />
  }

  _getContactFields() {
    const { store } = this.props
    return {
      name: 'contact_config',
      endpoint: `/api/admin/stores/stores/${store.id}`,
      fields: store.contact_config.fields,
      program: store.program
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Fields)',
  component: <Fields store={ props.store } />
})

export default Panel(null, mapPropsToPanel)
