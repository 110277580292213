import TextField from '@admin/components/mjson_designer/components/textstylefield/textfield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import ColorField from '@admin/components/mjson_designer/components/colorfield'
import FormatField from '@admin/components/form/formatfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const Text = (props) => <ResponsiveField { ...props } canHover={ false } component={ TextField } />
const Color = (props) => <ResponsiveField { ...props } canHover={ false } component={ ColorField } />
const Format = (props) => <ResponsiveField { ...props } component={ FormatField } />

class New extends React.Component {

  static propTypes = {
    theme: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { theme } = this.props
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      title: 't(New Typography Preset)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Text)', name: 'text', type: Text, theme, required: true },
            { label: 't(Color)', name: 'color', type: Color, theme, defaultColor: true },
            { label: 't(Format)', name: 'format', type: Format },
            { label: 't(Link Color)', name: 'linkColor', type: Color, theme, defaultColor: true },
            { label: 't(Link Format)', name: 'linkFormat', type: Format }
          ]
        }
      ]
    }
  }

  _getCode(name) {
    return name.replace(/[^A-Za-z0-9\s]+/g, '').replace(/[\s]+/g, '_').toLowerCase()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone(preset) {
    this.props.onDone({
      name: preset.name,
      type: 'text',
      code: this._getCode(preset.name),
      text: preset.text,
      color: preset.color,
      format: preset.format
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default New
