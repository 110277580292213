import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    resource: PropTypes.object,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { resource } = this.props
    return {
      title: 't(Edit Resource)',
      onCancel: this._handleCancel,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'id', type: 'hidden', value: resource.id },
            { name: 'is_active', type: 'hidden', value: resource.is_active },
            { name: 'delta', type: 'hidden', value: resource.delta },
            { label: 't(Title)', name: 'title', type: 'tokenfield', required: true, defaultValue: resource.title },
            { label: 't(Pricing)', type: 'segment', fields: [
              { type: 'fields', fields: [
                { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, defaultValue: resource.project_id },
                { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: resource.revenue_type_id }
              ] },
              { label: 'Unit Price', name: 'price', type: 'moneyfield', required: true, defaultValue: resource.price }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleSubmit(resource) {
    this.props.onDone(resource)
    this.context.form.pop()
  }

}

export default Edit
