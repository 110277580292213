import DatasetList from './datasets/list'
import DatasetShow from './datasets/show'
import RecordShow from './records/show'
import TypeShow from './types/show'

const routes = [
  { path: '/', component: DatasetList },
  { path: '/:id/:view', component: DatasetShow },
  { path: '/:dataset_id/types/:id/:view', component: TypeShow },
  { path: '/:dataset_id/types/:type_id/records/:id/:view', component: RecordShow }
]

export default routes
