export const onSetParams = (filter, query, sort) => ({
  type: 'SET_PARAMS',
  filter,
  query,
  sort
})

export const onSetSelected = (selected) => ({
  type: 'SET_SELECTED',
  selected
})

export const onSort = (key) => ({
  type: 'SORT',
  key
})

export const onFilter = (filter) => ({
  type: 'FILTER',
  filter
})

export const onSetRecords = (records) => ({
  type: 'SET_RECORDS',
  records
})

export const onSetFilter = (filter) => ({
  type: 'SET_FILTER',
  filter
})

export const onChooseFilter = (filter) => ({
  type: 'CHOOSE_FILTER',
  filter
})

export const onSetQuery = (q) => ({
  type: 'SET_QUERY',
  q
})

export const onToggleFilter = () => ({
  type: 'TOGGLE_FILTER'
})

export const onAddPanel = (panel) => ({
  type: 'ADD_PANEL',
  panel
})

export const onRemovePanel = (panel)=> ({
  type: 'REMOVE_PANEL'
})

export const onClearPanel = (panel) => ({
  type: 'CLEAR_PANEL'
})
