import { canAccess } from '@core/utils/access'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Button extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    analytics: PropTypes.object,
    confirm: PropTypes.object,
    flash: PropTypes.object,
    host: PropTypes.object,
    locale: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object,
    verify: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.object,
    address: PropTypes.object,
    basic: PropTypes.bool,
    callback: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.string,
    component: PropTypes.any,
    confirm: PropTypes.any,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    event: PropTypes.string,
    fluid: PropTypes.bool,
    location: PropTypes.string,
    handler: PropTypes.func,
    html: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.any,
    link: PropTypes.string,
    loading: PropTypes.bool,
    modal: PropTypes.any,
    padded: PropTypes.bool,
    request: PropTypes.shape({
      method: PropTypes.string,
      endpoint: PropTypes.string,
      onRequest: PropTypes.func,
      onFailure: PropTypes.func,
      onSuccess: PropTypes.func
    }),
    rightIcon: PropTypes.string,
    route: PropTypes.string,
    style: PropTypes.object,
    showIcon: PropTypes.bool,
    size: PropTypes.string,
    tabIndex: PropTypes.number,
    tasks: PropTypes.array,
    text: PropTypes.string,
    tooltip: PropTypes.any,
    url: PropTypes.string,
    verify: PropTypes.any,
    visible: PropTypes.array,
    onDone: PropTypes.func
  }

  static defaultProps = {
    basic: false,
    disabled: false,
    fluid: true,
    padded: false,
    showIcon: true,
    visible: ['desktop','mobile','tablet'],
    onDone: () => {}
  }

  state = {
    error: null,
    status: 'pending'
  }

  _handleClick = _.throttle(this._handleClick.bind(this), 250, { leading: true, trailing: false })
  _handleKeyUp = this._handleKeyUp.bind(this)

  render() {
    const { className, icon, image, link, rightIcon, showIcon } = this.props
    const { status } = this.state
    if(!this._getAllowed()) return null
    return (
      <div { ...this._getButton() }>
        { status === 'processing' ?
          <Icon icon="spin fa-circle-notch-o" /> :
          <>
            { icon && <Icon icon={icon} /> }
            { image && <Img src={image} /> }
            { this._getLabel() }
            { link && className === 'link' && showIcon &&
              <><Icon icon="external-link" />&nbsp;</>
            }
            { rightIcon &&
              <div className="maha-button-right">
                <Icon icon={ rightIcon } />
              </div>
            }
          </>
        }
      </div>
    )
  }

  _getButton() {
    const { disabled, tabIndex } = this.props
    return {
      ...this._getToolTip(),
      className: this._getClass(),
      style: this._getStyle(),
      disabled,
      tabIndex,
      onClick: this._handleClick,
      onKeyUp: this._handleKeyUp
    }
  }

  _getAllowed() {
    const { admin } = this.context
    const { access } = this.props
    return canAccess(access, admin)
  }

  _getClass() {
    const { component, basic, className, color, disabled, fluid, icon, label, loading, padded, rightIcon, visible, size } = this.props
    const { status } = this.state
    const classes = []
    if(!_.includes(visible, 'mobile')) classes.push('hide-mobile')
    if(!_.includes(visible, 'tablet')) classes.push('hide-tablet')
    if(!_.includes(visible, 'desktop')) classes.push('hide-desktop')
    if(className) classes.push(className)
    if(component && !padded) classes.push('compact')
    if(component) return classes.join(' ')
    if(icon && label) classes.push('iconed')
    classes.push('maha-button')
    if(className === undefined) classes.push('ui button')
    if(color) classes.push(color)
    if(loading) classes.push('loading')
    if(fluid) classes.push('fluid')
    if(rightIcon) classes.push('right')
    if(size) classes.push(size)
    if(basic) classes.push('basic')
    if(disabled) classes.push('disabled')
    if(status === 'processing') classes.push('loading')
    return classes.join(' ')
  }

  _getLabel() {
    const { children, component, label, link, text, html } = this.props
    if(!_.isNil(html)) return <span dangerouslySetInnerHTML={{__html: html }} />
    if(!_.isNil(label)) return <T text={ label } />
    if(!_.isNil(text)) return <T text={ text } />
    if(!_.isNil(component)) return component
    if(!_.isNil(link)) return link
    if(!_.isNil(children)) return children
    return null
  }

  _getStyle() {
    const { style } = this.props
    return style
  }

  _getToolTip() {
    const { t } = this.context.locale
    const { tooltip } = this.props
    if(!tooltip) return {}
    return {
      'data-tooltip': _.isString(tooltip) ? t(tooltip) : t(tooltip.title),
      'data-position': _.isString(tooltip) ? 'bottom right' : tooltip.position,
      'data-inverted': ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { flash } = this.context
    const { error, status } = this.state
    if(prevState.status !== status && status === 'failure') {
      flash.set('error', error)
    }
  }

  _handleAddress(address) {
    const description = address.description || `${address.city}, ${address.region}`
    this.context.analytics.track(`opened addrerss '${description}'`)
    this.context.host.window.open(`https://maps.google.com/?q=${address.latitude},${address.longitude}`)
  }

  _handleCallback() {
    const { callback } = this.props
    if(callback) callback()
  }

  _handleClick(e) {
    e.stopPropagation()
    const { address, confirm, disabled, handler, link, modal, request, route, tasks, url, verify, onDone } = this.props
    const { event } = this.props
    if(disabled) return
    if(event) this.context.analytics.track(event)
    const yesHandler = () => {
      if(address) this._handleAddress(address)
      if(link) this._handleLink(link)
      if(url) this._handleUrl(url)
      if(route) this._handleRoute(route, e.metaKey)
      if(request) this._handleRequest(request)
      if(modal) this._handleModal(modal)
      if(handler) this._handleFunction(handler, e)
      if(tasks) this._handleTasks(tasks)
    }
    onDone()
    if(confirm) return this.context.confirm.open(confirm, yesHandler)
    if(verify) return this.context.verify.open({ text: verify.text, term: verify.term, handler: yesHandler})
    yesHandler()
  }

  _handleKeyUp(e) {
    if(e.key !== 'Enter') return
    this._handleClick(e)
  }

  _handleLink(url) {
    this.context.analytics.track(`opened external link '${url}'`)
    this.context.host.window.open(url)
    this._handleCallback()
  }

  _handleUrl(url) {
    this.context.analytics.track(`refreshed page to '${url}'`)
    window.location.href = url
  }

  _handleRoute(route, metaKey) {
    if(metaKey) return this._handleLink(`${process.env.ADMIN_HOST}${route}`)
    this.context.router.push(route)
    this._handleCallback()
  }

  _handleModal(modal) {
    this.context.analytics.track('opened modal')
    if(modal.component) {
      const options = modal.options || {}
      return this.context.modal.open(modal.component, options)
    } else {
      this.context.modal.open(modal)
    }
    this._handleCallback()
  }

  _handleFunction(handler, e) {
    handler(e)
    this._handleCallback()
  }

  _handleRequest(request) {
    const { body, endpoint, method, onRequest, onFailure, onSuccess } = request
    this.setState({
      status: 'processing'
    })
    this.context.network.request({
      method,
      endpoint,
      body,
      onRequest: (request) => {
        if(onRequest) onRequest(request)
      },
      onFailure: (result) => {
        this.setState({
          error: result.error ? result.error.message : '',
          status: 'failure'
        })
        if(onFailure) onFailure(result)
      },
      onSuccess: (result) => {
        this.setState({
          status: 'success'
        })
        if(onSuccess) onSuccess(result)
        this._handleCallback()
      }
    })
  }

  _handleTasks(items) {
    this.context.analytics.track('opened tasks')
    this.context.tasks.open({ items })
  }

}

export default Button
