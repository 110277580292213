import Container from '@admin/components/container'
import Details from '@admin/components/details'
import AppToken from '@admin/tokens/app'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

class Access extends React.Component {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    apps: PropTypes.array,
    rights: PropTypes.array
  }

  render() {
    const access = this._getAccess()
    if(!access) return null
    return (
      <div className="maha-access">
        { access.map((app, appindex) => (
          <Fragment  key={`app_${appindex}`}>
            <div className={ this._getAppClass(app.code) }>
              <AppToken { ...app } />
            </div>
            { this._getCan(`${app.code}:access_app`) &&
              <div className="maha-access-body">
                { app.entities.map((entity, entityindex) => (
                  <div className="maha-access-right" key={`entity_${entityindex}`}>
                    <div className="maha-access-right-label">
                      <T text={ entity.label } />
                    </div>
                    <div className="maha-access-right-action">
                      { this._getEntity(app.code, entity.code) }
                    </div>
                  </div>
                ))}
                { app.rights.map((right, rightindex) => (
                  <div className="maha-access-right" key={`right_${rightindex}`}>
                    <div className="maha-access-right-label">
                      <T text={ right.label } />
                    </div>
                    <div className="maha-access-right-action">
                      { this._getRight(app.code, right.code) }
                    </div>
                  </div>
                ))}
              </div>
            }
          </Fragment>
        )) }
      </div>
    )
  }

  _getAccess() {
    const { maha } = this.context
    if(!this.props.apps) return null
    const apps = this.props.apps.reduce((apps,app) => ({
      ...apps,
      [app.code]: app
    }), {})
    return maha.access.filter(app => {
      return _.includes(Object.keys(apps), app.code) 
    }).map(app => ({
      ...app,
      icon: apps[app.code].icon
    }))
  }

  _getAppClass(app) {
    const classes = ['maha-access-app']
    if(this._getCan(`${app}:access_app`)) classes.push('active')
    return classes.join(' ')
  }

  _getCan(right) {
    const { rights } = this.props
    return _.includes(rights, right)
  }

  _getEntity(app ,entity) {
    const view = this._getCan(`${app}:view_${entity}`)
    const manage = this._getCan(`${app}:manage_${entity}`)
    if(view) return <span className="view">View</span>
    if(manage) return <span className="manage">Manage</span>
    return <span className="none">None</span>    
  }

  _getRight(app, right) {
    const enabled = this._getCan(`${app}:${right}`)
    if(enabled) return <span className="enabled">Enabled</span>
    return <span className="none">Disabled</span>    
  }

}

const mapResources = (props, context) => ({
  apps: '/api/admin/team/apps'
})

export default Container(mapResources)(Access)
