import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Transcription extends React.Component {

  static propTypes = {
    voicemail: PropTypes.object
  }

  render() {
    const { voicemail } = this.props
    const { transcription, transcription_status } = voicemail
    if(!transcription_status) return null
    return (
      <div className="token">
        <strong>Transcript</strong><br />
        { _.includes(['pending','inprogress'], transcription_status) &&
          <>
            <Icon icon="circle-o-notch fa-spin fa-fw" /> transcribing...
          </>
        }
        { transcription_status === 'success' &&
          <>{ transcription }</>
        }
      </div>
    )
  }

}

export default Transcription
