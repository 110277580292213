import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(New Contacts)',
  access: { rights: { $oneOf: ['crm:view_contacts','crm:manage_contacts'] } },
  collection: {
    endpoint: '/api/admin/dashboards/crm/new_contacts',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'display_name', sort: 'last_name', primary: true, format: ContactToken },
      { label: 't(Email)', key: 'email', sort: 'primary_email' },
      { label: 't(Phone)', key: 'phone', sort: 'primary_phone', format: 'phone' },
      { label: 't(Organization)', key: 'organization', visible: false },
      { label: 't(Job Title)', key: 'job_title', visible: false },
      { label: 't(Birthday)', key: 'birthday', visible: false, format: 'date' },
      { label: 't(Spouse)', key: 'spouse', visible: false },
      { label: 't(Created)', key: 'created_at', visible: false, format: 'date' }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user-circle',
      title: 't(No Contacts)',
      text: 't(You have not yet created any contacts)'
    },
    entity: 'contact',
    onClick: (record) => context.router.push(`/crm/contacts/${record.id}`)
  }
})

const mapResourcesToPage = (props, context) => ({
  fields: '/api/admin/programs/fields'
})

export default Page(mapResourcesToPage, mapPropsToPage)
