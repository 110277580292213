import Format from '@admin/components/format'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Text extends React.Component {

  static propTypes = {
    component: PropTypes.object,
    disabled: PropTypes.bool,
    format: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    format: ({ value }) => <T text={ value } />,
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    const { component, format } = this.props
    if(component) return component
    const value = _.toString(this.props.value)
    return (
      <div className={ this._getClass() }>
        <Format format={format} value={value} />
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }

  _getClass() {
    const { disabled } = this.props
    const classes = ['maha-form-text']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

}

export default Text
