import Compose from '@apps/email/admin/views/emails/new'
import Message from '@admin/components/message'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import Header from './header'
import List from './list'
import Show from './show'
import React from 'react'

class EmailClient extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    folder: PropTypes.object,
    id: PropTypes.number,
    mode: PropTypes.string,
    profile: PropTypes.object
  }

  _handleActive = this._handleActive.bind(this)
  _handleCompose = this._handleCompose.bind(this)
  _handleFlag = this._handleFlag.bind(this)
  _handleForward = this._handleForward.bind(this)
  _handleSelect = this._handleSelect.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleMark = this._handleMark.bind(this)
  _handleRefresh = this._handleRefresh.bind(this)
  _handleReply = this._handleReply.bind(this)
  _handleSync = this._handleSync.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      cacheKey: getCode(10),
      selected: [],
      active: null
    }
  }

  render() {
    const { selected } = this.state
    const { mode } = this.props
    return (
      <div className={`email-client ${mode}`}>
        { (document.body.clientWidth > 1024 || mode === 'full') &&
          <Details { ...this._getDetails() } />
        }
        { (document.body.clientWidth > 1024 || mode === 'show') &&
          <div className="email-client-body">
            { mode === 'full' &&
              <Header { ...this._getHeader() } />
            }
            <div className="email-client-reader">
              { selected.length === 0 &&
                <Message { ...this._getEmpty() } />
              }
              { selected.length === 1 &&
                <Show { ...this._getShow() } />
              }
              { selected.length > 1 &&
                <Message { ...this._getMultiple() } />
              }
            </div>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { id, profile } = this.props
    if(id) {
      this.setState({
        selected: [{ profile_id: profile.id, id }]
      })
    }
  }

  _getCompose(params = {}) {
    return {
      forward: params.forward,
      reply: params.reply
    }
  }

  _getDetails() {
    const { active, cacheKey, selected } = this.state
    const { folder, profile } = this.props
    return {
      active,
      cacheKey,
      folder,
      key: profile ? profile.id : 'all',
      selected,
      profile,
      onActive: this._handleActive,
      onDelete: this._handleDelete,
      onFlag: this._handleFlag,
      onMove: this._handleMove,
      onMark: this._handleMark,
      onSelect: this._handleSelect,
      onSync: this._handleSync
    }
  }

  _getEmpty() {
    return {
      icon: 'envelope',
      title: 't(Choose Email)',
      text: 't(Choose an email to view in the reader)'
    }
  }

  _getHeader() {
    const { selected } = this.state
    return {
      selected,
      onCompose: this._handleCompose,
      onSync: this._handleSync
    }
  }

  _getMultiple() {
    const { selected } = this.state
    return {
      icon: 'envelope',
      title: `${selected.length} emails selected`
    }
  }

  _getShow() {
    const { selected } = this.state
    const { id, profile_id } = selected[0]
    return {
      id,
      key: `${id}:${profile_id}`,
      profile_id,
      selected,
      onDelete: this._handleDelete,
      onFlag: this._handleFlag,
      onForward: this._handleForward,
      onMove: this._handleMove,
      onMark: this._handleMark,
      onReply: this._handleReply
    }
  }

  _handleActive(active) {
    this.setState({ active })
  }

  _handleCompose() {
    this.context.modal.open(<Compose { ...this._getCompose() } />)
  }

  _handleFlag(emails, is_flagged) {
    this.context.network.request({
      endpoint: '/api/admin/email/flag',
      method: 'PATCH',
      body: {
        emails,
        is_flagged
      }
    })
  }

  _handleForward(forward) {
    this.context.modal.open(<Compose { ...this._getCompose({ forward }) } />)
  }

  _handleMove(emails, from, to) {
    this.context.network.request({
      endpoint: '/api/admin/email/move',
      method: 'PATCH',
      body: {
        emails,
        from,
        to
      }
    })
  }

  _handleMark(emails, is_read) {
    this.context.network.request({
      endpoint: '/api/admin/email/mark',
      method: 'PATCH',
      body: {
        emails,
        is_read
      }
    })
  }

  _handleRefresh() {
    const cacheKey = getCode(10)
    this.setState({ cacheKey })
  }

  _handleReply(reply) {
    this.context.modal.open(<Compose { ...this._getCompose({ reply }) } />)
  }

  _handleSelect(selected) {
    this.setState({ selected })
  }

  _handleSync() {
    this.context.network.request({
      endpoint: '/api/admin/email/sync',
      method: 'GET'
    })
  }

}

export default EmailClient
