import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Notes extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Any other information you consider important to mention?)', 
              name: 'logo_notes', 
              type: 'textarea', 
              defaultValue: config.logo_notes 
            },
            {
              label: 't(Authorization)',
              type: 'segment',
              fields: [
                { 
                  name: 'logo_authorization',
                  prompt: 't(I authorize Truevail to charge the $799 fee to the card on file upon submission of this information)',
                  type: 'checkbox',
                  defaultValue: config.logo_authorization,
                  required: true
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onSave({
      ...formdata,
      ...config
    })
  }

}

export default Notes
