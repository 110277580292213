import ResponseUploadShow from './forms/responses/upload'
import LandingPageShow from './forms/landing_pages/show'
import ResponsesShow from './forms/responses/show'
import EmbedShow from './forms/embeds/show'
import FormsList from './forms/list'
import FormsShow from './forms/show'

const routes = [
  { path: '/', component: FormsList },
  { path: '/forms/:id/:view', component: FormsShow },
  { path: '/forms/:form_id/embeds/:id/:view', component: EmbedShow },
  { path: '/forms/:form_id/landing_pages/:id/:view', component: LandingPageShow },
  { path: '/forms/:form_id/responses/:id/:view', component: ResponsesShow },
  { path: '/forms/:form_id/responses/:response_id/uploads/:id', component: ResponseUploadShow }
]

export default routes
