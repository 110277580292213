import Buttons from '@admin/components/buttons'
import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class ModalPanel extends React.Component {

  static propTypes = {
    alert: PropTypes.any,
    buttons: PropTypes.array,
    children: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.string,
    footer: PropTypes.any,
    instructions: PropTypes.any,
    leftEnabled: PropTypes.bool,
    leftItems: PropTypes.array,
    rightEnabled: PropTypes.bool,
    rightItems: PropTypes.array,
    showHeader: PropTypes.bool,
    title: PropTypes.any
  }

  static defaultProps = {
    showHeader: true
  }

  render() {
    const { alert, buttons, footer, instructions, leftItems, rightItems, showHeader, title } = this.props
    return (
      <div className={ this._getClass() }>
        { showHeader &&
          <div className="maha-modal-panel-header">
            { leftItems ?
              <div className="maha-modal-panel-header-navigation">
                { leftItems.map((item,index) => (
                  <Button key={`left_${index}`} { ...this._getItem(item) } />
                )) }
              </div> :
              <div className="maha-modal-panel-header-navigation" />
            }
            <div className="maha-modal-panel-header-title" onClick={ this._handleClick }>
              { _.isString(title) ?
                <T text={ title } /> :
                <>{ title }</>
              }
            </div>
            { rightItems ?
              <div className="maha-modal-panel-header-navigation">
                { rightItems.map((item,index) => (
                  <Button key={`right_${index}`} { ...this._getItem(item) } />
                )) }
              </div> :
              <div className="maha-modal-panel-header-navigation" />
            }
          </div>
        }
        { instructions &&
          <div className="maha-modal-panel-instructions">
            { _.isString(instructions) ? 
              <T text={`${ instructions }`} /> :
              <>{ instructions }</> 
            }
          </div>
        }
        <div className="maha-modal-panel-body">
          { this.props.children }
        </div>
        { buttons &&
          <div className="maha-modal-panel-footer">
            <Buttons { ...this._getButtons() } />
          </div>
        }
        { footer &&
          <div className="maha-modal-panel-footer">
            { footer }
          </div>
        }
        { alert &&
          <div className="maha-modal-panel-alert">
            { alert }
          </div>
        }
      </div>
    )
  }

  _getButtons() {
    const { buttons } = this.props
    return {
      buttons
    }
  }

  _getClass() {
    const { className, color, showHeader } = this.props
    const classes = ['maha-modal-panel']
    if(className) classes.push(className)
    if(showHeader && color) classes.push(color)
    return classes.join(' ')
  }

  _getElement(item) {
    if(item.component) return _.isFunction(item.component) ? React.createElement(item.component) : item.component
    if(item.icon) return <div className="maha-modal-panel-header-navigation-button"><i className={ `fa fa-${item.icon}` } /></div>
    if(item.label) return <div className="maha-modal-panel-header-navigation-button"><span>{ item.label }</span></div>
  }

  _getItem(item) {
    return {
      ...item,
      className: 'maha-modal-panel-header-navigation-item'
    }
  }

  _getLeftClass() {
    const { leftEnabled } = this.props
    const classes = ['maha-modal-panel-header-navigation']
    if(!leftEnabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getRightClass() {
    const { rightEnabled } = this.props
    const classes = ['maha-modal-panel-header-navigation']
    if(!rightEnabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getToolTip(tooltip) {
    if(!tooltip) return {}
    return {
      'data-tooltip': _.isString(tooltip) ? tooltip : tooltip.title,
      'data-position': _.isString(tooltip) ? 'bottom right' : tooltip.position,
      'data-inverted': ''
    }
  }

}

export default ModalPanel
