import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const statuses = {
  disconnected: 't(This profile needs to be reconnected)',
  expired_password: 't(This profile requires you reauthenticate due to a recent password change)',
  outdated_permissions: 't(This profile requires you to approve additional permissions)',
  outdated_terms: 't(This profile requires you to agree to a new terms of service)'
}

const ProfileToken = ({ alert, profile }) => (
  <div className="profile-token">
    <div className="profile-token-logo">
      <div className="profile-token-logo-image">
        { profile.photo ?
          <>
            <Img src={`/imagecache/w=50${profile.photo}`} />
            <div className="profile-token-icon">
              <Img src={`/images/services/${profile.service.source_name}.png`} />
            </div>        
          </> :
          <Img src={`/images/services/${profile.service.source_name}.png`} />
        }
      </div>
    </div>
    <div className="profile-token-label">
      <strong>{ profile.username || profile.name}</strong><br />
      { profile.team &&
        <>{ profile.team.title }<br /></>
      }
      { profile.service.name }
      { alert && profile.status !== 'connected' &&
        <>
          <br /><span className="small alert">
            <T text={ statuses[profile.status] } />
          </span>
        </>
      }
    </div>
  </div>
)

ProfileToken.propTypes = {
  alert: PropTypes.bool,
  profile: PropTypes.object
}

ProfileToken.defaultProps = {
  alert: false
}

export default ProfileToken
