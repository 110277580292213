import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `maha_signup_pages/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const SignupPageToken = ({ signup_page }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(signup_page) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { signup_page.title }
      </div>
    </div>
  </div>
)

SignupPageToken.propTypes = {
  signup_page: PropTypes.object
}

export default SignupPageToken