import ProgramToken from '@apps/crm/admin/tokens/program'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import OptOut from './optout'
import OptIn from './optin'
import React from 'react'

class Consent extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    channels: PropTypes.array,
    contact: PropTypes.object
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { admin } = this.context
    const { programs } = admin
    return {
      sections: [
        { title: 't(Consent)' },
        ...admin.team.has_programs ? programs.sort((a,b) => a.title < b.title ? -1 : 1).map((program, index) => ({
          subtitle: <ProgramToken { ...program } />,
          padded: false,
          items: this._getChannels(program)
        })) : [{
          items: this._getChannels(programs[0])
        }]
      ]
    }
  }

  _getChannels(program) {
    const { admin } = this.context
    const found = _.find(this.props.channels, {
      id: program.id
    })
    const channels = found ? found.channels : []
    return channels.filter(channel => {
      if(channel.type === 'sms') return admin.hasRights({ oneOf: ['campaigns:manage_sms_campaigns','phone:access_app'] })
      if(channel.type === 'voice') return admin.hasRights({ oneOf: ['campaigns:manage_voice_campaigns','phone:access_app'] })
      return true
    }).map((channel) => ({
      component: (
        <div className="crm-contact-properties-item" key={`channel_${channel.id}`}>
          <div className="crm-contact-properties-item-icon">
            <Icon icon={ channel.has_consented ? 'check' : 'times' } />
          </div>
          <div className="crm-contact-properties-item-details">
            { channel.type.toUpperCase() } (
            { channel.label })
          </div>
          { program.access_type === 'manage' &&
            <Button { ...this._getTasks(channel, program) } />
          }
        </div>
      )
    }))
  }

  _getConsent(channel, program) {
    const { contact } = this.props
    return {
      contact,
      channel,
      program
    }
  }

  _getPublicLink(channel, program) {
    const code = `${program.code}${channel.code}`
    if(channel.type === 'email') return `/crm/pe${code}`
    if(channel.type === 'sms') return `/crm/ps${code}`
    if(channel.type === 'voice') return `/crm/pv${code}`
    if(channel.type === 'mail') return `/crm/pm${code}`
  }

  _getTasks(channel, program) {
    return {
      icon: 'ellipsis-h',
      className: 'crm-contact-properties-item-extra',
      tasks: [
        {
          label: 't(View Public Consent)',
          show: !channel.has_consented,
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          link: this._getPublicLink(channel, program)
        },
        {
          label: 't(Opt In)',
          show: !channel.has_consented,
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <OptIn { ...this._getConsent(channel, program) } />
        },
        {
          label: 't(Opt Out)',
          show: channel.has_consented,
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <OptOut { ...this._getConsent(channel, program) } />
        }
      ]
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Consent)',
  panel: <Consent contact={ props.contact } channels={ props.channels } />
})

const mapResourcesToPanel = (props, context) => ({
  channels: `/api/admin/crm/contacts/${props.contact.id}/consent`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
