const access = {
  entities: [
    { label: 't(Files)', code: 'files' }
  ], 
  rights: [
    { label: 't(Can transfer ownership of files and folders)', code: 'manage_ownership' }
  ] 
}

export default access
