import Container from '@admin/components/container'
import Composer from '@admin/components/composer'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Variant from './variant'
import Comment from './comment'
import moment from 'moment'
import React from 'react'

class Post extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    comments: PropTypes.array,
    variant: PropTypes.object
  }

  static defaultProps = {}

  commentsRef = React.createRef()
  composerRef = React.createRef()

  state = {
    comments: [],
    selected: null,
    text: ''
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleReply = this._handleReply.bind(this)
  _handleUpdateText = this._handleUpdateText.bind(this)

  render() {
    const { variant } = this.props
    const { comments } = this.state
    return (
      <div className="campaigns-social-conversations-post">
        <Variant variant={ variant } />
        <div className="campaigns-social-conversations-tools">
          <Button { ...this._getRefresh() } />
        </div>
        { comments.length > 0 ?
          <div className="campaigns-social-conversations-post-comments" ref={ this.commentsRef }>
            { comments.map((comment, index) => (
              <Comment { ...this._getComment(comment) } key={`comment_${index}`} />
            )) }
          </div> :
          <div className="campaigns-social-conversations-post-comments-empty">
            <Message { ...this._getEmpty() } />
          </div>
        }
        { (variant.profile.service.source_name !== 'instagram' || variant.profile.scopes.includes('instagram_manage_comments')) &&
          <div className="campaigns-social-conversations-post-composer">
            <Composer { ...this._getComposer() } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const { comments } = this.props
    this._handleSetComments(comments)
  }

  componentDidUpdate(prevProps) {
    const { comments } = this.props
    if(!_.isEqual(comments, prevProps.comments)) {
      this._handleSetComments(comments)
    }
  }


  _getComment(comment) {
    const { variant } = this.props
    return {
      variant,
      comment,
      onHide: this._handleHide.bind(this, comment),
      onDelete: this._handleDelete.bind(this, comment),
      onReply: this._handleReply.bind(this, comment)
    }
  }

  _getComposer() {
    const { text } = this.state
    return {
      text,
      placeholder: 'Add a comment',
      ref: this.composerRef,
      on: this._handleAdd,
      onSubmit: this._handleAdd,
      onUpdateText: this._handleUpdateText
    }
  }

  _getEmpty() {
    return {
      icon: 'comments',
      title: 't(No Comments)',
      text: 't(No one has commented on this post)'
    }
  }

  _getRefresh() {
    const { variant } = this.props
    return {
      icon: 'refresh',
      label: 't(Refresh)',  
      basic: true,
      color: 'blue',
      size: 'mini',
      request: {
        endpoint: `/api/admin/campaigns/social/${variant.social_campaign_id}/variants/${variant.id}/comments/refresh`,
        method: 'PATCH'
      }
    }
  }

  _handleAdd(text) {
    const { comments } = this.state
    const { variant } = this.props
    this.context.network.request({
      endpoint: `/api/admin/campaigns/social/${variant.social_campaign_id}/variants/${variant.id}/comments`,
      method: 'POST',
      body: {
        text
      },
      onRequest: () => {
        this.setState({
          comments: [
            ...comments,
            {
              code: 'abc123',
              user_service_id: variant.profile.profile_id,
              user_name: variant.profile.name,
              text,
              likes_count: 0,
              posted_at: moment()
            }
          ],
          text: ''
        }, this._handleScrollToBottom)
      }
    })
  }

  _handleDelete(comment) {
    const { comments } = this.state
    const { variant } = this.props
    this.context.network.request({
      endpoint: `/api/admin/campaigns/social/${variant.social_campaign_id}/variants/${variant.id}/comments/${comment.id}`,
      method: 'DELETE',
      onRequest: () => {
        this.setState({
          comments: comments.filter(c => {
            return c.id !== comment.id
          })
        })
      },
      onFailure: (result) => {},
      onSuccess: (result) => {}
    })
  }

  _handleFocus() {
    if(!this.composerRef.current) return
    this.composerRef.current.focus()
  }

  _handleHide(comment, is_hidden) {
    const { comments } = this.state
    const { variant } = this.props
    this.context.network.request({
      endpoint: `/api/admin/campaigns/social/${variant.social_campaign_id}/variants/${variant.id}/comments/${comment.id}/hide`,
      method: 'PATCH',
      body: { is_hidden },
      onRequest: () => {
        this.setState({
          comments: comments.map(c => ({
            ...c,
            ...c.id === comment.id ? {
              is_hidden
            } : {}
          }))
        })
      },
      onFailure: (result) => {},
      onSuccess: (result) => {}
    })
  }

  _handleReply(comment) {
    this.setState({ 
      text: `@${comment.user_name} `
    }, this._handleFocus)
  }

  _handleScrollToBottom() {
    if(!this.commentsRef.current) return
    this.commentsRef.current.style.scrollBehavior = 'auto'
    this.commentsRef.current.scrollTop = this.commentsRef.current.scrollHeight
    this.commentsRef.current.style.scrollBehavior = 'scroll'
  }

  _handleSetComments(comments) {
    this.setState({ comments }, () => {
      if(comments.length > 0) this._handleReply(comments[comments.length - 1])
      this._handleScrollToBottom()
    })
  }

  _handleUpdateText(text) {
    this.setState({ text })
  }

}

const mapResources = (props, context) => ({
  comments: `/api/admin/campaigns/social/${props.variant.social_campaign_id}/variants/${props.variant.id}/comments`,
  variant: `/api/admin/campaigns/social/${props.variant.social_campaign_id}/variants/${props.variant.id}`
})

export default Container(mapResources)(Post)
