import Container from '@admin/components/container'
import ScheduledToken from '../token'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

class Month extends React.PureComponent {

  static contextTypes = {
    alert: PropTypes.object
  }

  static propTypes = {
    current: PropTypes.object,
    social_campaigns: PropTypes.array,
    onEdit: PropTypes.func,
    onSchedule: PropTypes.func
  }

  state = {
    hover: null
  }

  _handleDragEnter = this._handleDragEnter.bind(this)
  _handleDragLeave = this._handleDragLeave.bind(this)
  _handleDrop = this._handleDrop.bind(this)

  render() {
    return (
      <div className="campaigns-social-schedule-month">
        { Array(6).fill(0).map((x, i) => (
          <div className="campaigns-social-schedule-month-week" key={`week_${i}`}>
            { Array(7).fill(0).map((y, j) => (
              <div { ...this._getDay(i * 7 + j) } key={`day_${i}_${j}`}>
                { i === 0 && 
                  <div className="campaigns-social-schedule-month-weekday">
                    { days[j] }
                  </div>
                }
                <div className="campaigns-social-schedule-month-number">
                  <span className={ this._getNumberClass(i * 7 + j) }>
                    { this._getNumber(i * 7 + j) }
                  </span>
                </div>
                <div className="campaigns-social-schedule-month-content">
                  { this._getSocialCampaigns(i * 7 + j).map((social_campaign, index) => (
                    <ScheduledToken { ...this._getToken(social_campaign) } key={`token_${i}_${j}_${index}`} />
                  )) }
                </div>
              </div>
            )) }
          </div>
        )) }
      </div>
    )
  }

  _getDate(index) {
    const { current } = this.props
    const offset = index - current.weekday()
    return current.clone().add(offset, 'days')
  }

  _getDay(index) {
    const { hover } = this.state
    const date = this._getDate(index)
    const classes = ['campaigns-social-schedule-month-day']
    if(index === hover) classes.push('hover')
    return {
      'data-time': date.format('YYYY-MM-DD [08:00]'),
      className: classes.join(' '),
      onDragEnter: this._handleDragEnter.bind(this, index),
      onDragLeave: this._handleDragLeave.bind(this, index),
      onDragOver: (e) => e.preventDefault(),
      onDrop: this._handleDrop
    }
  }

  _getNumber(index) {
    const date = this._getDate(index)
    return date.format('D') === '1' ? date.format('MMM D') : date.format('D')
  }

  _getNumberClass(index) {
    const date = this._getDate(index)
    const classes = []
    if(date.isSame(moment(), 'day')) classes.push('today')
    return classes.join(' ')
  }

  _getSocialCampaigns(days) {
    const date = this._getDate(days)
    const { social_campaigns } = this.props
    const start = date.subtract(1, 'seconds')
    const end = start.clone().add(24, 'hours').add(1, 'seconds')
    return social_campaigns.filter(social_campaign => {
      return moment(social_campaign.post_at).isBetween(start, end)
    })
  }

  _getToken(social_campaign) {
    const { onEdit } = this.props
    return {
      social_campaign,
      onEdit
    }
  }

  _handleDragEnter(index, e) {
    e.stopPropagation()
    this.setState({ 
      hover: index
    })
  }

  _handleDragLeave(index, e) {
    e.stopPropagation()
    if(index !== this.state.hover) return
    this.setState({
      hover: null
    })
  }

  _handleDrop(e) {
    const social_campaign = JSON.parse(e.dataTransfer.getData('social_campaign'))
    const post_at = e.target.dataset.time
    const diff = moment(post_at).diff(moment(), 'seconds' )
    if(diff < 0) {
      this.context.alert.open({
        title: 't(You cannot schedule a post in the past)'
      })
      return e.preventDefault()
    }
    this.setState({
      hover: null
    })
    this.props.onSchedule(social_campaign, post_at)
  }

}

const mapResources = (props, context) => ({
  social_campaigns: {
    endpoint: props.endpoint,
    filter: {
      $and: [
        { status: { $neq: 'draft' } },
        { post_at: { $gte: moment(props.range.start).startOf('month').startOf('week').format('YYYY-MM-DD 00:00:00') } },
        { post_at: { $lte: moment(props.range.start).startOf('month').startOf('week').add(41, 'days').format('YYYY-MM-DD 23:59:59') } }
      ]
    }
  }
})

export default Container(mapResources)(Month)
