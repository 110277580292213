import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class FormField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.config) return null
    return (
      <div className="mjson-designer-stylefield unpadded">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { config } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { entity: 't(Form)', name: 'form', type: 'lookup', endpoint: '/api/admin/forms/forms', valueKey: null, compareKey: 'id', textKey: 'title', defaultValue: config.form }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config.form ? {
      form_id: config.form.id,
      form: config.form
    } : null)
  }

  _handleSet(config) {
    this.setState({
      config: {
        form_id: null,
        ...config || {}
      }
    })
  }

  _handleUpdate(config) {
    this.setState({ 
      config: config ? {
        ...config,
        form_id: config.id
      } : {}
    })
  }

}

export default FormField
