import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import PropTypes from 'prop-types'
import React from 'react'

class TransformField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    transform: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.transform) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { transform } = this.state
    if(!_.isEqual(transform, prevState.transform)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getForm() {
    const { transform } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Scale)', name: 'scale', type: LinkedField, units: [
              { name: '%', min: -100, max: 100, step: 1 }
            ], types: ['X Axis','Y Axis'], nullValues: ['0%'], defaultValue: '0%',value: transform.scale },
            { label: 't(Translate)', name: 'translate', type: LinkedField, units: [
              { name: 'px', min: -300, max: 300, step: 1 }
            ], types: ['X Axis','Y Axis'], nullValues: ['0px'], defaultValue: '0px',value: transform.translate },
            { label: 't(Rotate)', name: 'rotate', type: LinkedField, units: [
              { name: 'deg', min: -360, max: 360, step: 1 }
            ], types: ['X Axis','Y Axis','Z Axis'], nullValues: ['0deg'], defaultValue: '0deg', value: transform.rotate },
            { label: 't(Skew)', name: 'skew', type: LinkedField, units: [
              { name: 'deg', min: -180, max: 180, step: 1 }
            ], types: ['X Axis','Y Axis'], nullValues: ['0deg'], defaultValue: '0deg', value: transform.skew }
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { rotate, scale, skew, translate } = this.state.transform
    const value = {
      ...(scale && scale !== '0%' ) ? { scale } : {},
      ...(translate && translate !== '0px' ) ? { translate } : {},
      ...(rotate && rotate !== '0deg' ) ? { rotate } : {},
      ...(skew && skew !== '0deg' ) ? { skew } : {}
    }
    this.props.onChange(Object.keys(value).length > 0 ? value : null)
  }

  _handleSet(transform) {
    this.setState({
      transform: {
        rotate: transform ? transform.rotate : null,
        scale: transform ? transform.scale : null,
        skew: transform ? transform.skew : null,
        translate: transform ? transform.translate : null
      }
    })
  }

  _handleUpdate(transform) {
    this.setState({ transform })
  }

}

export default TransformField
