import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import BoxShadowField from '@admin/components/mjson_designer/components/boxshadowfield'
import React from 'react'

export const BoxShadow = (props) =>  <ResponsiveField { ...props } component={ BoxShadowField } />

export const BoxShadowSection = (config, device, theme, website, type) => ({
  label: 't(Box Shadow)',
  instructions: 't(Specify the box model style and layout properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.boxShadow', device, theme, type: BoxShadow, value: config.styles.boxShadow }
  ]
})
