import Page from '@admin/components/page'
import Subscription from './subscription'
import Activities from './activities'
import Properties from './properties'
import Templates from './templates'
import Sessions from './sessions'
import Profiles from './profiles'
import Programs from './programs'
import Overview from './overview'
import Renewals from './renewals'
import Senders from './senders'
import Themes from './themes'
import Roles from './roles'
import Users from './users'
import Usage from './usage'
import React from 'react'
import Tags from './tags'
import Apps from './apps'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.team.title,
  manager: {
    path: '/admin/team',
    items: [
      {
        label: 't(Overview)',
        panel: <Overview team={ resources.team } />
      },
      {
        label: 't(Activities)',
        path: '/activities',
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Activities />
      },
      {
        label: 't(Apps)',
        path: '/apps',
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Apps />
      },
      {
        label: 't(Billing)',
        children: [
          {
            label: 't(Subscription)',
            path: '/subscription',
            panel: <Subscription />
          },
          {
            label: 't(Payment History)',
            path: '/renewals',
            panel: <Renewals />
          }
        ]
      },
      {
        label: 't(Profiles)',
        path: '/profiles',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_profiles','team:manage_profiles']} },
        panel: <Profiles />
      },
      {
        label: 't(Programs)',
        path: '/programs',
        access: { rights: { $oneOf: ['team:view_programs','team:manage_programs']} },
        show: context.admin.team.has_programs,
        panel: <Programs />
      },
      {
        label: 't(Properties)',
        path: '/properties',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_properties','team:manage_properties']} },
        panel: <Properties />
      },
      {
        label: 't(Roles)',
        path: '/roles',
        access: { rights: { $oneOf: ['team:view_roles','team:manage_roles'] } },
        panel: <Roles />
      },
      {
        label: 't(Senders)',
        path: '/senders',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_senders','team:manage_senders']} },
        panel: <Senders />
      },
      {
        label: 't(Sessions)',
        path: '/sessions',
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Sessions />
      },
      {
        label: 't(Tags)',
        path: '/tags',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Tags />
      },
      {
        label: 't(Templates)',
        path: '/templates',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_templates','team:manage_templates']} },
        panel: <Templates />
      },
      {
        label: 't(Themes)',
        path: '/themes',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_templates','team:manage_templates']} },
        panel: <Themes />
      },
      {
        label: 't(Usage)',
        path: '/usage',
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Usage />
      },
      {
        label: 't(Users)',
        path: '/users',
        access: { rights: { $oneOf: ['team:view_users','team:manage_users'] } },
        panel: <Users />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  team: '/api/admin/team/settings'
})

export default Page(mapResourcesToPage, mapPropsToPage)
