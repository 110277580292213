import Invoice from '@apps/finance/admin/components/invoice'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ invoice, payments }) => {

  const details = {
    audits: `finance_invoices/${invoice.id}`,
    comments: `finance_invoices/${invoice.id}`,
    items: [
      { component: <Invoice invoice={ invoice } payments={ payments } /> }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  invoice: PropTypes.object,
  payments: PropTypes.array
}

export default Overview
