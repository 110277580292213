import StackRouter from './stack_router'
import React from 'react'

import { useRouterContext } from '@admin/components/router'

const StackRouterContainer = (props) => {

  const { router } = useRouterContext()

  const mergedProps = {
    router,
    action: router.action,
    pathname: router.history.slice(-1)[0].pathname,
    ...props
  }

  return <StackRouter { ...mergedProps } />

}

StackRouterContainer.propTypes = {}

export default StackRouterContainer
