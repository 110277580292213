import Details from '@admin/components/details'
import AccessToken from '@admin/tokens/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Manage from '../manage'
import React from 'react'

class DatasetAccess extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    accesses: PropTypes.array,
    dataset: PropTypes.object
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { accesses, dataset } = this.props
    return {
      items: accesses.map((access, index) => ({
        component: (props) => <AccessToken access={ access } />
      })),
      empty: {
        icon: 'user-circle',
        title: 't(No access)',
        text: 't(This datatset has no authorized users)',
        button: {
          label: 't(Manage Access)',
          modal: <Manage dataset={ dataset } />
        }
      },
      buttons: [
        { 
          label: 't(Manage Access)',
          color: 'blue', 
          modal: <Manage dataset={ dataset } />
        }
      ]
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Access)',
  panel: <DatasetAccess accesses={ props.accesses } dataset={ props.dataset } />
})

const mapResourcesToPanel = (props, context) => ({
  accesses: `/api/admin/datasets/datasets/${props.dataset.id}/access`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
