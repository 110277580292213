import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_social_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const AdvisorSocialCampaignToken = ({ social_campaign }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(social_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { social_campaign.title }
      </div>    
      <div className="token-stats">
        { social_campaign.team.title }<br />
        <span className={ social_campaign.status }>{ social_campaign.status }</span>
        { _.includes(['scheduled','posting','failed'], social_campaign.status) &&
          <span><Date datetime={ social_campaign.post_at } timezone={ social_campaign.team.timezone } /></span>
        }
        { social_campaign.status === 'posted' &&
          <>
            <span><Date datetime={ social_campaign.posted_at } timezone={ social_campaign.team.timezone } /></span>
          </>
        }
      </div>
    </div>
  </div>
)

AdvisorSocialCampaignToken.propTypes = {
  social_campaign: PropTypes.object
}

export default AdvisorSocialCampaignToken
