import useActionReducer from '@admin/hooks/use_action_reducer'
import FollowsField from './followsfield'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const FollowsFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'followsfield',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <FollowsField { ...mergedProps } />

}

FollowsFieldContainer.propTypes = {}

export default FollowsFieldContainer