import ProgressBar from '@admin/components/progress/bar'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

class Performance extends React.Component {

  static propTypes = {
    performance: PropTypes.object
  }

  render() {
    return (
      <div className="crm-email-campaign-results">
        <Details { ...this._getDetails() } />
      </div>
    )
  }

  _getDetails() {
    const { performance } = this.props
    const { sent, delivered, bounced, opened, total_opened, desktop_percent } = performance
    const { mobile_percent, webviewed, forwarded, complained, preferenced, addressbooked } = performance
    const { clicked, total_clicked, unsubscribed, last_opened_at } = performance
    const { open_rate, click_rate, bounce_rate } = performance
    const { hard_bounced, soft_bounced } = performance
    const { facebook_shared, facebook_viewed } = performance
    const { linkedin_shared, linkedin_viewed } = performance
    const { pinterest_shared, pinterest_viewed } = performance
    return {
      sections: [
        {
          items: [
            { component: (
              <div className="crm-report">
                <div className="crm-report-table">
                  <div className="crm-email-campaign-results-header">
                    <div className="crm-email-campaign-results-header-item">
                      <div className="crm-email-campaign-results-stat">
                        <div className="crm-email-campaign-results-stat-title">
                          Open Rate
                        </div>
                        <div className="crm-email-campaign-results-stat-percent">
                          { numeral(open_rate).format('0.0%') }
                        </div>
                      </div>
                      <ProgressBar labeled={ false } color="blue" percent={ delivered > 0 ? (opened / delivered) : 0 } />
                    </div>
                    <div className="crm-email-campaign-results-header-item">
                      <div className="crm-email-campaign-results-stat">
                        <div className="crm-email-campaign-results-stat-title">
                          Click Rate
                        </div>
                        <div className="crm-email-campaign-results-stat-percent">
                          { numeral(click_rate).format('0.0%') }
                        </div>
                      </div>
                      <ProgressBar labeled={ false } color="blue" percent={ opened > 0 ? (clicked / opened) : 0 } />
                    </div>
                    <div className="crm-email-campaign-results-header-item">
                      <div className="crm-email-campaign-results-stat">
                        <div className="crm-email-campaign-results-stat-title">
                          Bounce Rate
                        </div>
                        <div className="crm-email-campaign-results-stat-percent">
                          { numeral(bounce_rate).format('0.0%') }
                        </div>
                      </div>
                      <ProgressBar labeled={ false } color="blue" percent={ bounced > 0 ? (bounced / sent) : 0 } />
                    </div>
                  </div>

                </div>
                <div className="crm-report-metrics">
                  <div className="crm-report-metric">
                    <div className="crm-report-metric-title">Opens</div>
                    <div className="crm-report-metric-value">
                      { this._getButton(opened, { was_opened: { $eq: true } }) }
                    </div>
                  </div>
                  <div className="crm-report-metric">
                    <div className="crm-report-metric-title">Clicks</div>
                    <div className="crm-report-metric-value">
                      { this._getButton(clicked, { was_clicked: { $eq: true } }) }
                    </div>
                  </div>
                  <div className="crm-report-metric">
                    <div className="crm-report-metric-title">Bounces</div>
                    <div className="crm-report-metric-value">
                      { this._getBounces(bounced) }
                    </div>
                  </div>
                  <div className="crm-report-metric">
                    <div className="crm-report-metric-title">Unsubscribes</div>
                    <div className="crm-report-metric-value">
                      { this._getButton(unsubscribed, { was_unsubscribed: { $eq: true } }) }
                    </div>
                  </div>
                </div>
                <div className="crm-report-table">
                  <table className="ui unstackable table">
                    <tbody>
                      <tr>
                        <td>Sent</td>
                        <td className="right aligned">
                          { this._getButton(sent) }
                        </td>
                      </tr>
                      <tr>
                        <td>Delivered</td>
                        <td className="right aligned">
                          { this._getButton(delivered, { was_delivered: { $eq: true } }) }
                        </td>
                      </tr>
                      <tr>
                        <td>Not Opened</td>
                        <td className="right aligned">
                          { this._getButton(delivered - opened, [{ was_delivered: { $eq: true } }, { was_opened: { $eq: false } }]) }
                        </td>
                      </tr>
                      <tr>
                        <td>Total Opened</td>
                        <td className="right aligned">
                          { this._getActivity(total_opened, 'open') }
                        </td>
                      </tr>
                      <tr>
                        <td>Total Clicked</td>
                        <td className="right aligned">
                          { this._getActivity(total_clicked, 'click') }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed Online</td>
                        <td className="right aligned">
                          { this._getActivity(webviewed, 'webview') }
                        </td>
                      </tr>
                      <tr>
                        <td>Added to Address Book</td>
                        <td className="right aligned">
                          { this._getActivity(addressbooked, 'forward') }
                        </td>
                      </tr>
                      <tr>
                        <td>Adjusted Preferences</td>
                        <td className="right aligned">
                          { this._getActivity(preferenced, 'forward') }
                        </td>
                      </tr>
                      <tr>
                        <td>Forwarded to a Friend</td>
                        <td className="right aligned">
                          { this._getActivity(forwarded, 'forward') }
                        </td>
                      </tr>
                      <tr>
                        <td>Hard Bounced</td>
                        <td className="right aligned">
                          { this._getBounces(hard_bounced, 'Permanent') }
                        </td>
                      </tr>
                      <tr>
                        <td>Soft Bounced</td>
                        <td className="right aligned">
                          { this._getBounces(soft_bounced, 'Transient') }
                        </td>
                      </tr>
                      <tr>
                        <td>Complained</td>
                        <td className="right aligned">
                          { this._getButton(complained, { was_complained: { $eq: true } }) }
                        </td>
                      </tr>
                      <tr>
                        <td>Shared on Facebook</td>
                        <td className="right aligned">
                          { this._getActivity(facebook_shared, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed on Facebook</td>
                        <td className="right aligned">
                          { this._getActivity(facebook_viewed, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Shared on LinkedIn</td>
                        <td className="right aligned">
                          { this._getActivity(linkedin_shared, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed on LinkedIn</td>
                        <td className="right aligned">
                          { this._getActivity(linkedin_viewed, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Shared on Pinterest</td>
                        <td className="right aligned">
                          { this._getActivity(pinterest_shared, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed on Pinterest</td>
                        <td className="right aligned">
                          { this._getActivity(pinterest_viewed, 'share') }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed on Desktop</td>
                        <td className="right aligned">
                          { this._getDevice(desktop_percent) }
                        </td>
                      </tr>
                      <tr>
                        <td>Viewed on Mobile</td>
                        <td className="right aligned">
                          { this._getDevice(mobile_percent) }
                        </td>
                      </tr>
                      <tr>
                        <td>Last Opened</td>
                        <td className="right aligned">
                          { last_opened_at ? moment(last_opened_at).format('MM/DD/YY hh:mmA') : 'N/A' }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) }
          ]
        }
      ]
    }
  }

  _getDevice(percent) {
    return numeral(percent).format('0.0%')
  }

  _getActivity(value, type) {
    return value
  }

  _getButton(value, $filter) {
    return value
  }

  _getBounces(value, type) {
    return value
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance performance={ props.performance } />
})

const mapResourcesToPanel = (props, context) => ({
  performance: `/api/admin/campaigns/email/${props.email_campaign.id}/performance`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
