import { useMemo } from 'react'

export const defaults = (state, props) => {
  return useMemo(() => {
    return props.columns.filter(column => {
      return column.visible === false
    }).map(column => column.key)
  }, [props.columns])
}

export const display = (state, props) => {
  return useMemo(() => {
    return props.columns.map(column => ({
      ...column,
      visible: column.primary === true || !_.includes( state.hidden, column.key)
    }))
  }, [props.columns, state.hidden])
}

export const visible = (state, props) => {
  return useMemo(() => {
    return state.display.filter(column => {
      return column.visible !== false
    })
  }, [state.display])
}

export const widths = (state, props) => {
  return useMemo(() => {
    const fixed = state.visible.filter(column => {
      return column.width !== undefined
    })
    const used = fixed.reduce((used, column) => {
      return used + (column.width || 0)
    }, 0)
    const available = state.width - used - 40 - 8 - (props.selectable ? 40 : 0)
    const widths = state.visible.map(column => {
      return column.width || available / (state.visible.length - fixed.length)
    })
    return state.visible.map((column, index) => {
      const grow = column.width === undefined ? 1 : 0
      return {
        flex: `${grow} 1 ${widths[index]}px`
      }
    })
  }, [props.selectable, state.visible, state.width])
}
