import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import OverflowField from '@admin/components/mjson_designer/components/overflowfield'
import React from 'react'

const Overflow = (props) => <ResponsiveField { ...props } component={ OverflowField } />

export const OverflowSection = (config, device, theme, website, type) => ({
  label: 't(Overflow)',
  instructions: 't(Specify the content overflow properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.overflow', type: Overflow, device, value: config.styles.overflow }
  ]
})
