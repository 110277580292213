import ShortLink from '@admin/components/shortlink'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ form }, { admin }) => {

  const program = {
    label: form.program.title,
    className: 'link',
    route: `/admin/team/programs/${form.program.id}`
  }

  const shortlink = {
    shortlink: form.shortlink,
    text: 't(Share this form)',
    title: form.title
  }

  const details = {
    audits: `forms_forms/${form.id}`,
    comments: `forms_forms/${form.id}`,
    before: <ShortLink { ...shortlink } />,
    sections: [
      {
        title: 't(Form Details)',
        items: [
          { label: 't(Title)', content: form.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } />  }
        ]
      }
    ]
  }

  if(form.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This form was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview form={ props.form } />,
  tasks: {
    items: [
      { 
        label: 't(Edit Form)',
        access: { 
          programs: { $canEdit: props.form.program.id },
          rights: { $oneOf: ['forms:manage_forms'] } 
        },
        modal: <Edit form={ props.form } /> 
      },
      { 
        label: 't(Clone Form)', 
        access: { 
          programs: { $canEdit: props.form.program.id },
          rights: { $oneOf: ['forms:manage_forms'] } 
        },
        modal: <Clone form={ props.form } /> 
      },
      {
        label: 't(Delete Form)',
        access: { 
          programs: { $canEdit: props.form.program.id },
          rights: { $oneOf: ['forms:manage_forms'] } 
        },
        confirm: 't(Are you sure you want to delete this form? You will loose access to all of the response and revenue information)',
        request: {
          endpoint: `/api/admin/forms/forms/${props.form.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this form)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this form)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
