import Panel from '@admin/components/panel'
import AppToken from '@admin/tokens/app'

const mapPropsToPanel = (props, context) => ({
  title: 't(Apps)',
  rights: ['team:manage_apps'],
  collection: {
    endpoint: '/api/admin/team/apps',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(App)', key: 'code', primary: true, format: AppToken }
    ],
    defaultSort: { key: 'code', order: 'asc' },
    entity: 'app'
  }
})

export default Panel(null, mapPropsToPanel)
