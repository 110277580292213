import CallActivities from '@apps/phone/admin/components/call_activities'
import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import Format from '@admin/components/format'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class VoiceCampaign extends React.PureComponent {

  static propTypes = {
    activities: PropTypes.array,
    actions: PropTypes.array,
    call: PropTypes.object,
    enrollment: PropTypes.object,
    voice_campaign: PropTypes.object
  }

  render() {
    const { actions, activities, call, enrollment, voice_campaign } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <div className="crm-workflow-card">
            <table className="ui celled compact unstackable table">
              <tbody>
                <tr>
                  <td>Voice Campaign</td>
                  <td><Button { ...this._getCampaign() } /></td>
                </tr>
                <tr>
                  <td>Program Phone Number</td>
                  <td>{ voice_campaign.phone_number.formatted }</td>
                </tr>
                <tr>
                  <td>Contact Phone Number</td>
                  <td>{ enrollment.phone_number.formatted }</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td><Format format="duration" value={ call.seconds } /></td>
                </tr>
                <tr>
                  <td>Call</td>
                  <td><Button { ...this._getCall() } /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="crm-timeline-card-section-footer">
          <Actions enrollment={ enrollment } actions={ actions } trigger={ voice_campaign.trigger } />
          <CallActivities activities={ activities } call={ call } />
          <Comments entity={`phone_calls/${enrollment.call_id}`} />
        </div>
      </div>
    )
  }

  _getCall() {
    const { enrollment } = this.props
    return {
      label: 'View Call',
      className: 'link',
      route: `/admin/phone/calls/${enrollment.call_id}`
    }
  }

  _getCampaign() {
    const { voice_campaign } = this.props
    return {
      label: voice_campaign.title,
      className: 'link',
      route: `/admin/campaigns/voice/${voice_campaign.id}`
    }
  }

}

const mapResources = (props, context) => ({
  activities: `/api/admin/phone/calls/${props.activity.data.call_id}/activities`,
  actions: `/api/admin/campaigns/voice/${props.activity.data.voice_campaign_id}/enrollments/${props.activity.data.enrollment_id}/actions`,
  call: `/api/admin/phone/calls/${props.activity.data.call_id}`,
  voice_campaign: `/api/admin/campaigns/voice/${props.activity.data.voice_campaign_id}`,
  enrollment: `/api/admin/campaigns/voice/${props.activity.data.voice_campaign_id}/enrollments/${props.activity.data.enrollment_id}`
})

export default Container(mapResources)(VoiceCampaign)
