import PropTypes from 'prop-types'
import React from 'react'

class Preferences extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object
  }

  render() {
    const { activity } = this.props
    if(!activity.data) return null
    const { type, email_address, mailing_address, phone_number, actions } = activity.data
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              { phone_number &&
                <tr>
                  <td>Phone Number</td>
                  <td>{ phone_number }</td>
                </tr>
              }
              { email_address &&
                <tr>
                  <td>Email Address</td>
                  <td>{ email_address }</td>
                </tr>
              }
              { mailing_address &&
                <tr>
                  <td>Mailing Address</td>
                  <td>{ mailing_address }</td>
                </tr>
              }
              <tr>
                <td>Channel</td>
                <td>{ type.toUpperCase() }</td>
              </tr>
            </tbody>
          </table>
          <ul>
            { actions && actions.map((item, index) => (
              <li key={`item_${index}`}>
                { item.action === 'unconsented' &&
                  <span>opted out of all communications</span>
                }
                { item.action === 'consented' &&
                  <span>opted in to all communications</span>
                }
                { item.action === 'subscribed' &&
                  <span>subscribed to { item.list }</span>
                }
                { item.action === 'unsubscribed' &&
                  <span>unsubscribed from { item.list }</span>
                }
              </li>
            )) }
          </ul>
        </div>
      </div>
    )
  }

}

export default Preferences
