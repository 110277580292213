import BraintreeCardField from '@apps/finance/admin/components/paymentfield/braintree/cardfield'
import PaymentStrategyToken from '@apps/subscriptions/admin/tokens/payment_strategy'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Method extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    service: PropTypes.object,
    subscription: PropTypes.object
  }

  state = {
    formdata: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { service, subscription } = this.props
    const { formdata } = this.state
    return {
      title: 't(Change Payment Method)',
      method: 'PATCH',
      endpoint: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}`,
      action: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/method`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Method', name: 'payment_strategy', type: 'radiogroup', options: ['card','invoice'], defaultValue: 'card', format: PaymentStrategyToken },
            { label: 'Credit Card', name: 'payment', type: BraintreeCardField, show: formdata.payment_strategy === 'card', color: 'blue' },
            { label: 'Terms', name: 'payment_terms', type: 'radiogroup', show: formdata.payment_strategy === 'invoice', options: [
              { value: 10, text: 't(NET10)' },
              { value: 30, text: 't(NET30)' },
              { value: 60, text: 't(NET60)' }
            ], defaultValue: 10 }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(formdata) {
    this.setState({ formdata })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Method
