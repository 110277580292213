import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

const Overview = ({ chargeback }) => {

  const customer = {
    className: 'link',
    label: chargeback.payment.customer.display_name,
    route: `/admin/crm/contacts/${chargeback.payment.customer.id}`
  }

  const payment = {
    className: 'link',
    label: chargeback.payment.reference,
    route: `/admin/finance/payments/${chargeback.payment.id}`
  }

  const items = [
    { label: 't(Date)', content: moment(chargeback.created_at).format('MM/DD/YYYY') },
    { label: 't(Contact)', content: <Button { ...customer } /> },
    { label: 't(Payment)', content: <Button { ...payment } /> },
    { label: 't(Amount)', content: numeral(chargeback.amount).format('$0.00') },
    { label: 't(Fee)', content: numeral(chargeback.fee).format('$0.00') }
  ]

  if(chargeback.braintree_id) {

    const braintree = {
      className: 'link',
      label: chargeback.braintree_id,
      link: chargeback.braintree_link
    }
  
    items.push({ label: 'Braintree ID', content: <Button { ...braintree } /> })
  
  }

  if(chargeback.stripe_id) {
    
    const braintree = {
      className: 'link',
      label: chargeback.stripe_id,
      link: chargeback.payment.stripe_link
    }
  
    items.push({ label: 'Braintree ID', content: <Button { ...braintree } /> })
  
  }

  const details = {
    audits: `finance_chargebacks/${chargeback.id}`,
    comments: `finance_chargebacks/${chargeback.id}`,
    sections: [
      { 
        title: 't(Chargeback Details)',
        items 
      }
    ]
  }

  if(chargeback.deposit) {

    const deposit = {
      className: 'link',
      label: moment(chargeback.deposit.date).format('MM/DD/YYYY'),
      route: `/admin/finance/deposits/${chargeback.deposit.id}`
    }

    details.sections.push({
      title: 't(Withdrawn)',
      items: [
        { label: 't(Bank Account)', content: chargeback.deposit.bank.title },
        { label: 't(Date)', content: <Button { ...deposit } /> }
      ]
    })

  }

  return <Details { ...details } />

}

Overview.propTypes = {
  chargeback: PropTypes.object
}

export default Overview
