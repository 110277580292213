import Image from '@admin/components/image'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const VariantToken = ({ product, variant }) => (
  <div className="product-token">
    <div className="product-token-photo">
      { variant.photos.length > 0 ?
        <Image src={ variant.photos[0].asset.path } transforms={{ fit: 'cover', w: 24, h: 24 }} /> :
        <div className="product-token-icon">
          <Icon icon="shopping-bag" />
        </div>
      }
    </div>
    <div className="product-token-label">
      { product.title } { variant.options.map(option => {
        return `${option.option}: ${option.value}`
      }).join(', ') } { (product.is_active === false || variant.is_active === false) &&
        <span className="active">INACTIVE</span>
      }
    </div>
  </div>
)

VariantToken.propTypes = {
  product: PropTypes.object,
  variant: PropTypes.object
}

export default VariantToken
