import getActions from '@admin/utils/get_actions'

export const {
  onSetReload,
  onSetReloading,
  onSetRevision
} = getActions([
  { name: 'reload', type: 'boolean' },
  { name: 'reloading', type: 'boolean' },
  { name: 'revision', type: 'var' }
])