import Lookup from '@admin/components/form/lookup'
import PropTypes from 'prop-types'
import FontToken from './token'
import React from 'react'

class FontField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    return <Lookup { ...this._getLookup() } />
  }

  _getLookup() {
    return {
      ...this.props,
      endpoint: '/api/admin/fonts',
      valueKey: null,
      prompt: 'Choose a google font',
      compareKey: 'fontFamily.family',
      textKey: 'fontFamily.family',
      formatToken: true,
      format: (value) => <FontToken key={value.type + '+' + value.fontFamily.family} value={value} />
    }
  }

}

export default FontField
