import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Configure from './index'
import React from 'react'

class Requirements extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    requirements: PropTypes.array
  }

  render() {
    return <Configure { ...this._getConfigure() } />
  }

  _getConfigure() {
    const { advisor, team, user } = this.context.admin
    const { requirements } = this.props
    return {
      title: 't(Additional Information)',
      advisor: {
        ...advisor,
        team
      },
      endpoint: '/api/admin/setup',
      mode: 'additional',
      requirements,
      intro: (
        <>
          <div className="truevail-configure-overview-header-image">
            <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
          </div>
          <h1>Hi {user.first_name},</h1>
          <p><T text="t(To proceed with setting up your requested services, we need some additional information from you. Please complete the requirements below.)" /></p>
        </>
      ),
      completed: (props) => (
        <div className="truevail-configure-configuring-text">
          <h2><T text="t(Submission Successful!)" /></h2>
          <p><T text="t(Received & Processing: Your information has been received and currently being processed.)" /></p>
          <h2><T text="t(Next Steps:)" /></h2>
          <p>1. <T text="t(Review & Setup: Our team is reviewing your details and integrating them into your service setup.)" /></p>
          <p>2. <T text="t(Confirmation & Instructions: You'll get a confirmation email with any necessary instructions once setup is complete.)" /></p>
          <h2><T text="t(Need Help?)" /></h2>
          <p><T text={`t(Contact us at <a href="mailto:support@truevail.com">support@truevail.com</a> for assistance or further information.)`} /></p>
          <div className="truevail-configure-configuring-button">
            <Button { ...this._getClose(props) } />
          </div>
        </div>
      )
    }
  }

  _getClose(props) {
    return {
      label: 't(Close Window)',
      color: 'blue',
      basic: true,
      fluid: false,
      handler: props.onClose
    }
  }

}

export default Requirements
