import RenderedText from '@admin/components/rendered_text'
import PropTypes from 'prop-types'
import React from 'react'

class FontSetPreview extends React.PureComponent {

  static propTypes = {
    heading: PropTypes.object,
    text: PropTypes.object,
    onReady: PropTypes.func
  }

  render() {
    const { heading, text } = this.props
    const previewConfig = {
      fontType: 'google',
      mx: 0,
      align: 'left',
      maxWidth: 262
    }
    return (
      <div className="fontset-preview">
        { (heading?.fontFamily && text?.fontFamily) &&
          <>
            <div className="fontset-preview-heading" style={{ fontFamily: heading.fontFamily.family }}>
              <RenderedText {...previewConfig} fontName={heading.fontFamily.family} fontSize={20} lineHeight={20 * 1.30} >
                { heading.fontFamily.family } Heading
              </RenderedText>
            </div>
            <div className="fontset-preview-text" style={{ fontFamily: text.fontFamily.family }}>
              <RenderedText {...previewConfig} fontName={text.fontFamily.family} fontSize={14} lineHeight={14 * 1.60} >
                This is a short example paragraph to showcase the { text.fontFamily.family } font.
              </RenderedText>
            </div>
          </>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady()
  }
}

export default FontSetPreview
