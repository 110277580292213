import Prompt from '@admin/components/prompt'
import Portal from '@admin/components/portal'
import Modal from '@admin/components/modal'
import Flash from '@admin/components/flash'
import PropTypes from 'prop-types'
import React from 'react'

class Session extends React.Component {

  static contextTypes = {
    maha: PropTypes.object
  }

  static propTypes = {
    apps: PropTypes.array,
    children: PropTypes.any,
    pathname: PropTypes.string,
    team: PropTypes.object,
    user: PropTypes.object
  }

  render() {
    const { roots } = this.context.maha
    const { user } = this.props
    return (
      <Prompt>
        <Modal>
          <Flash>
            <div className="maha-session">
              { roots.filter(root => {
                return root.app === 'maha' || this._getAccess(root.app)
              }).reduce((roots, root, index) => (
                <root.component key={`root_${index}`}>
                  { roots }
                </root.component>
              ), <Portal key={`portal_${ user.id }`} />) }
            </div>
          </Flash>
        </Modal>
      </Prompt>
    )
  }

  _getAccess(code) {
    return (_.findIndex(this.props.apps, { code }) >= 0)
  }

}

export default Session
