import Options from '@admin/components/options'
import PropTypes from 'prop-types'
import Dropdown from './dropdown'
import React from 'react'

const DropdownContainer =  React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus()
  }))

  return (
    <Options { ...props }>
      <Dropdown />
    </Options>
  )
})

DropdownContainer.propTypes = {
  endpoint: PropTypes.string,
  filter: PropTypes.object,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  query: PropTypes.object,
  sort: PropTypes.string
}

export default DropdownContainer
