import FormDesigner from '@apps/forms/admin/components/form_designer/wrapper'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Designer = ({ form }, { admin }) => {

  const designer = {
    editable: canAccess({ 
      programs: { $canEdit: form.program.id },
      rights: { $allOf: ['forms:manage_forms'] } 
    }, admin),
    form,
    code: form.code,
    endpoint: `/api/admin/forms/forms/${form.id}`
  }

  return <FormDesigner { ...designer } />

}

Designer.contextTypes = {
  admin: PropTypes.object
}

Designer.propTypes = {
  form: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Designer form={ props.form } />
})

const mapResourcesToPanel = (props, context) => ({
  form: `/api/admin/forms/forms/${props.form_id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
