import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import fontsets from './fontsets'
import Preview from './preview'
import React from 'react'

class FontsField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    fonts: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.fonts) return null
    return (
      <div className="mjson-designer-stylefield fontsfield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { fonts } = this.state
    if(!_.isEqual(fonts, prevState.fonts)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { fonts } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'type', type: 'radiogroup', options: [
              { value: 'system', text: 'Choose a system fontsets' },
              { value: 'custom', text: 'Create a custom fontset' }
            ], value: fonts.type },
            { name: 'preview', type: Preview, heading: fonts.heading, text: fonts.text },
            { label: 't(Fontset)', name: 'fontset', type: 'dropdown', options: fontsets, show: fonts.type === 'system', value: fonts.fontset },
            { label: 't(Heading Font)', name: 'heading', type: 'fontfield', show: fonts.type === 'custom', value: fonts.heading },
            { label: 't(Text Font)', name: 'text', type: 'fontfield', show: fonts.type === 'custom', value: fonts.text }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {
      type: 'system',
      fontset: fontsets[0].value,
      ...fontsets[0].value
    }
  }

  _getFontSet(values) {
    const set = values.fontset || values
    if(!set) return null
    if(!set.heading || !set.text) return null
    const found = fontsets.find(fontset => {  
      return set.heading.fontFamily.family === fontset.value.heading.fontFamily.family && set.text.fontFamily.family === fontset.value.text.fontFamily.family
    })
    return found ? found.value : null
  }

  _getPreview() {
    const { fonts } = this.state
    return fonts.type === 'system' ? fonts.fontset : {
      heading: fonts.heading,
      text: fonts.text
    }
  }

  _handleChange() {
    const { fonts } = this.state
    this.props.onChange({
      type: fonts.type,
      heading: fonts.heading,
      text: fonts.text
    })
  }

  _handleSet(newfonts) {
    const fontset = this._getFontSet(newfonts)
    this.setState({
      fonts: {
        fontset,
        ...newfonts || {},
        ...fontset || {}
      }
    })
  }

  _handleUpdate(newfonts) {
    const fontset = this._getFontSet(newfonts) || (newfonts.type === 'system' ? fontsets[0].value : null)
    this.setState({
      fonts: {
        fontset,
        type: newfonts.type,
        heading: newfonts.heading ? newfonts.heading : fontset ? fontset.heading : null,
        text: newfonts.text ? newfonts.text : fontset ? fontset.text : null
      }
    })
  }

}

export default FontsField
