const triggers = [
  {
    icon: 'check-square-o',
    code: 'response_created',
    title: 't(Form Submitted)',
    text: 't(Contact submits response to form)'
  }
]

export default triggers
