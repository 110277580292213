import PropTypes from 'prop-types'
import React from 'react'

const TagToken = ({ value }) => (
  <div className="crm-tag-token">
    { value }
  </div>
)

TagToken.propTypes = {
  value: PropTypes.string
}

export default TagToken
