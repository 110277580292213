import Comment from '@admin/components/comment'
import Button from '@admin/components/button'
import { toOxford } from '@core/utils/oxford'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Composer from './composer'
import pluralize from 'pluralize'
import moment from 'moment'
import React from 'react'
import _ from 'lodash'

class Comments extends React.Component {

  static propTypes = {
    context: PropTypes.object,
    action: PropTypes.string,
    comments: PropTypes.array,
    draft: PropTypes.object,
    endpoint: PropTypes.string,
    entity: PropTypes.string,
    hidden: PropTypes.number,
    mode: PropTypes.string,
    placeholder: PropTypes.string,
    q: PropTypes.string,
    quoted_comment: PropTypes.object,
    quoted_comment_id: PropTypes.number,
    status: PropTypes.string,
    typing: PropTypes.array,
    onAddComment: PropTypes.func,
    onAddTyping: PropTypes.func,  
    onQuoteComment: PropTypes.func,
    onRemoveComment: PropTypes.func,
    onRemoveTyping: PropTypes.func,
    onSetComments: PropTypes.func,
    onSetMode: PropTypes.func,
    onShowMore: PropTypes.func,
    onUpdateDraft: PropTypes.func
  }

  static defaultProps = {
    placeholder: 't(Write a comment)'
  }

  _handleCreateComment = this._handleCreateComment.bind(this)
  _handleExpand = this._handleExpand.bind(this)
  _handleUpdateDraft = this._handleUpdateDraft.bind(this)

  render() {
    const { comments, hidden, status } = this.props
    const message = this._getMessage(status)
    const typing = this._getTyping()
    return (
      <>
        { message ?
          <div className="maha-comments-message">
            <Icon icon={ message } /><br />
            <T text={ message.label } />
          </div> :
          <div className="maha-comments">
            { hidden > 0 &&
              <div className="maha-comments-more">
                <Button { ...this._getExpand() } />
              </div>
            }
            <div className="maha-comments-body">
              { comments.map((comment, index) => (
                <Comment { ...this._getComment(comment) } key={`comment_${comment.uid}`} />
              )) }
            </div>
            <div className="maha-comments-footer">
              <Composer { ...this._getComposer() } />
              { typing &&
                <div className="maha-comments-typing">
                  { typing }
                </div>
              }
            </div>
          </div>
        }
      </>
    )
  }

  componentDidUpdate(prevProps) {
    const { draft } = this.props
    if(draft.text.length > prevProps.draft.text.length && prevProps.draft.text.length === 0) {
      this._handleAddTyping()
    }
    if(draft.text.length === 0 && prevProps.draft.text.length > 0) {
      this._handleRemoveTyping()
    }
  }

  _getComment(comment) {
    const { entity } = this.props
    return {
      ...comment,
      entity,
      onQuote: this._handleQuoteComment.bind(this, comment),
      onDelete: this._handleDeleteComment.bind(this, comment)
    }
  }

  _getComposer() {
    const { placeholder, quoted_comment } = this.props
    return {
      placeholder,
      quoted: quoted_comment,
      onChange: this._handleUpdateDraft,
      onSubmit: this._handleCreateComment
    }
  }

  _getExpand() {
    const { hidden } = this.props
    return {
      label: `View ${ hidden } more ${ pluralize('comment', hidden) }`,
      color: 'blue',
      basic: true,
      handler: this._handleExpand
    }
  }

  _getMessage(status) {
    if(status === 'loading') return { icon: 'circle-o-notch fa-spin', message: 'Loading comments' }
    if(status === 'failed') return { icon: 'warning', message: 'Unable to load comments' }
    return null
  }

  _getRichtext(comment) {
    return {
      text: comment.text,
      attachments: comment.attachments
    }
  }

  _getTyping() {
    const { admin } = this.props.context  
    const { typing } = this.props
    const typers = typing.filter(user => {
      return user.id !== admin.user.id    
    }).map(user => user.full_name)
    if(typers.length === 0) return null
    const who = toOxford(typers, 'and')
    const verb = typing.length > 1 ? 'are' : 'is'
    return `${who} ${verb} typing`
  }

  _handleAddTyping() {
    const { admin, network } = this.props.context
    const { entity } = this.props
    network.message({
      channel: `/teams/${admin.team.id}/admin/${entity}/comments`,
      action: 'add_typing',
      data: admin.user
    })
  }

  _handleCreateComment({ attachments, link, quoted, text }) {
    const { admin, network } = this.props.context
    const { entity } = this.props
    const comment = {
      uid: _.random(100000000, 999999999).toString(36),
      text
    }
    network.message({
      channel: `/teams/${admin.team.id}/admin/${entity}/comments`,
      action: 'add_comment',
      data: {
        ...comment,
        quoted_comment: quoted,
        attachments: attachments.map(asset => ({ asset })),
        link,
        reactions: [],
        user: admin.user,
        created_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
        updated_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
      }
    })
    this.props.context.network.request({
      endpoint: `/api/admin/${entity}/comments`,
      method: 'POST',
      body: {
        ...comment,
        asset_ids: attachments.map(asset => asset.id),
        link_id: link ? link.id : null,
        quoted_comment_id: quoted ? quoted.id : null
      }
    })
  }

  _handleDeleteComment(comment) {
    const { admin, network } = this.props.context
    const { entity } = this.props
    network.message({
      channel: `/teams/${admin.team.id}/admin/${entity}/comments`,
      action: 'remove_comment',
      data: comment.uid
    })
    this.props.context.network.request({
      endpoint: `/api/admin/${entity}/comments/${comment.id}`,
      method: 'DELETE'
    })
  }

  _handleExpand() {
    this.props.onSetMode('expanded')
  }

  _handleQuoteComment(comment) {
    this.props.onQuoteComment(comment.id)
  }

  _handleRemoveTyping() {
    const { admin, network } = this.props.context
    const { entity } = this.props
    network.message({
      channel: `/teams/${admin.team.id}/admin/${entity}/comments`,
      action: 'remove_typing',
      data: admin.user
    })
  }

  _handleSetComments(comments) {
    this.props.onSetComments(comments)
  }

  _handleUpdateDraft(draft) {
    this.props.onUpdateDraft(draft) 
  }

}

export default Comments
