import Container from '@admin/components/container'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Import extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object,
    import: PropTypes.object,
    item: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    const imp = this.props.import
    const { item } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Source</td>
                <td>{ imp.service }</td>
              </tr>
              { Object.keys(item.values).map((key, index) => (
                <tr key={`value_${index}`}>
                  <td>{ key }</td>
                  <td>{ item.values[key] }</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

const mapResources = (props, context) => ({
  import: `/api/admin/crm_contacts/${props.activity.data.import_id}`,
  item: `/api/admin/crm_contacts/${props.activity.data.import_id}/items/${props.activity.data.import_item_id}`
})

export default Container(mapResources)(Import)
