export const devices = [
  { value: 'desktop', label: 'Desktop', type: 'desktop' },
  { value: 'tablet', label: 'Tablet', type: 'mobile', height: 1024 , width: 768 },
  { value: 'mobile', label: 'Mobile', type: 'mobile', height: 667, width: 375 }
]

export const orientations = [
  { value: 'portrait', label: 'Portrait' },
  { value: 'landscape', label: 'Landscape' }
]

export const scales = [
  { value: 0.5, label: '50%' },
  { value: 0.75, label: '75%' },
  { value: 1, label: '100%' }
]
