import EmailToken from '@apps/automation/admin/tokens/email'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Emails)',
  collection: {
    endpoint: '/api/admin/automation/emails',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'display_name', sort: 'title', primary: true, format: (email) => (
        <EmailToken email={ email } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    ...context.admin.team.has_programs ? {
      criteria: {
        fields: [
          { label: 't(Email)', fields: [
            { name: 't(Program)', key: 'program_id', type: 'select', endpoint: '/api/admin/programs', textKey: 'title', valueKey: 'id' }
          ] }
        ]
      }
    } : {},
    empty: {
      icon: 'envelope-o',
      title: 't(No Emails)',
      text: 't(You have not yet created any emails)'
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'email',
    onClick: (record) => context.router.push(`/automation/emails/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
