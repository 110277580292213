import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    workflow: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { workflow } = this.props
    const filter = { program_id: { $eq: workflow.program.id } }
    return {
      title: 't(Clone Workflow)',
      method: 'POST',
      action: `/api/admin/automation/workflows/${workflow.id}/clone`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true, defaultValue: `Copy of ${workflow.title}` },
            ..._.includes(['automation:email_received','automation:email_opened'], workflow.trigger.full_code) ? [
              { label: 't(Automation Email)', name: 'email_campaign_id', type: 'lookup', endpoint: '/api/admin/automation/emails', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.email_campaign.id }
            ] : [],
            ..._.includes(['campaigns:email_received','campaigns:email_opened'], workflow.trigger.full_code) ? [
              { label: 't(Email Campaign)', name: 'email_campaign_id', type: 'lookup', endpoint: '/api/admin/campaigns/email', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.email.id }
            ] : [],
            ...workflow.trigger.full_code === 'crm:property_updated'? [
              { label: 't(Property)', name: 'field_id', type: 'lookup', endpoint: `/api/admin/crm_programs/${workflow.program.id}/fields`, valueKey: 'id', textKey: 'name.value', filter, required: true, defaultValue: workflow.field.id }
            ] : [],
            ...workflow.trigger.full_code === 'events:registration_created' ? [
              { label: 't(Event)', name: 'event_id', type: 'lookup', endpoint: '/api/admin/events/events', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.event.id }
            ] : [],
            ...workflow.trigger.full_code === 'forms:response_created' ? [
              { label: 't(Form)', name: 'form_id', type: 'lookup', endpoint: '/api/admin/forms/forms', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.form.id }
            ] : [],
            ..._.includes(['crm:subscription_created','crm:subscription_deleted'], workflow.trigger.full_code)? [
              { label: 't(List)', name: 'list_id', type: 'lookup', endpoint: '/api/admin/crm/lists', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.list.id }
            ] : [],
            ..._.includes(['sales:deal_created','sales:deal_updated'], workflow.trigger.full_code) ? [
              { label: 't(Pipeline)', name: 'pipeline_id', type: 'lookup', endpoint: '/api/admin/sales/pipelines', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.pipeline.id }
            ] : [],
            ..._.includes(['stores:order_created','stores:order_shipped'], workflow.trigger.full_code) ? [
              { label: 't(Store)', name: 'store_id', type: 'lookup', endpoint: '/api/admin/stores/stores', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.store.id }
            ] : [],
            ..._.includes(['subscriptions:subscription_created','subscriptions:renewal_success','subscriptions:renewal_failure','subscriptions:payment_method_expiring','subscriptions:payment_method_expired'], workflow.trigger.full_code) ? [
              { label: 't(Service)', name: 'service_id', type: 'lookup', endpoint: '/api/admin/subscriptions/services', valueKey: 'id', textKey: 'title', filter, required: true, defaultValue: workflow.service.id }
            ] : [],
            { label: 't(Enrollment)', name: 'is_unique', type: 'radiogroup', options: [
              { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
              { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
            ], defaultValue: false, required: true  }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(workflow) {
    this.context.router.push(`/automation/workflows/${workflow.id}`)
    this.context.modal.close()
  }

}

export default Clone
