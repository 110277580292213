import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Priority extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className={`maha-email-priority ${email.priority}`}>
        <Icon icon={ this._getIcon() } /> This message is { email.priority } priority
      </div>
    )
  }

  _getIcon() {
    const { email } = this.props
    return email.priority === 'high' ? 'exclamation' : 'arrow-down'
  }

}

export default Priority
