import Buttons from '@admin/components/buttons'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Picker from './picker'
import React from 'react'

class VideoField extends React.Component{

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    prompt: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    prompt: 'Choose Video',
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    video: null
  }

  _handleDone = this._handleDone.bind(this)
  _handlePicker = this._handlePicker.bind(this)
  _handleRemove = this._handleRemove.bind(this)

  render() {
    const { video } = this.state
    if(!video) return <Button { ...this._getNewButton() } />
    return (
      <div className="maha-videofield">
        <div className="maha-videofield-player">
          <iframe { ...this._getIframe()} />
        </div>
        <div className="maha-videofield-footer">
          <Buttons { ...this._getEditButtons() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { video } = this.state
    if(!_.isEqual(video, prevState.video)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEditButtons() {
    return {
      buttons: [
        {
          label: 'Remove',
          color: 'red',
          handler: this._handleRemove
        },{
          label: 'Change',
          color: 'green',
          handler: this._handlePicker
        }
      ]
    }
  }

  _getIframe() {
    const { video } = this.state
    return {
      src: video.embed,
      frameBorder: 0,
      allowFullScreen: true
    }
  }

  _getNewButton() {
    const { disabled, prompt } = this.props
    return {
      className: 'ui button',
      disabled,
      fluid: false,
      label: prompt,
      handler: !disabled ? this._handlePicker : null
    }
  }

  _getPicker() {
    return {
      onDone: this._handleDone
    }
  }

  _handleChange() {
    const { video } = this.state
    this.props.onChange(video)
  }

  _handleDone(video) {
    this.setState({ video })
    this.context.form.pop()
  }

  _handlePicker() {
    this.context.form.push(Picker, this._getPicker.bind(this))
  }

  _handleRemove() {
    this.setState({
      video: null
    })
  }

  _handleSet(video) {
    this.setState({ video })
  }

}

export default VideoField
