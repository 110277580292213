import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Shipping extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    shipping: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.shipping) return null
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { shipping } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Shipping', type: 'segment', fields: [
              { name: 'shipping_strategy', type: 'radiogroup', required: true, options: [
                { value: 'free', text: 't(There is no shipping fee)' },
                { value: 'flat', text: 't(There is a flat shipping fee)' }
              ], defaultValue: shipping.shipping_strategy },
              ...this._getFee()
            ]}
          ]
        }
      ]
    }
  }

  componentDidMount() {
    const { formdata } = this.props
    this.setState({
      shipping: {
        shipping_strategy: formdata.shipping_strategy,
        shipping_fee: formdata.shipping_fee
      }
    })
  }

  _getFee() {
    const { shipping } = this.state
    if(shipping.shipping_strategy === 'free') return []
    return [
      { label: 'Fee', name: 'shipping_fee', type: 'moneyfield', defaultValue: shipping.shipping_fee }
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(shipping) {
    this.setState({ shipping })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(variant) {
    const { formdata } = this.props
    this.props.onSave({
      ...formdata,
      ...variant
    })
  }

}

export default Shipping
