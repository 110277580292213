import LinkedField from '@admin/components/mjson_designer/components/linkedfield'
import React from 'react'

const spacing = {
  units: [
    { name: 'px', min: 0, max: 500, step: 1 }
  ],
  types: ['top','right','bottom','left'],
  nullValues: ['0px'],
  defaultValue: '0px'
}

const SpacingField = (props) => <LinkedField { ...props } { ...spacing } />

export default SpacingField