import VisibilityToken from '@apps/team/admin/tokens/visibility'
import BankToken from '@apps/finance/admin/tokens/bank'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    team: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { admin } = this.context
    const finance = admin.apps.find(app => app.code === 'finance')
    return {
      title: 't(New Program)',
      method: 'POST',
      action: '/api/admin/team/programs',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', multiple: false, allow: { extensions: ['jpg','jpeg','png','gif'] } },
            { label: 't(Managers)', name: 'manager_ids', type: 'lookup', placeholder: 't(Assign Managers)', multiple: true, required: true, endpoint: '/api/admin/users', filter: { is_active: { $eq: true }}, valueKey: 'id', textKey: 'full_name', format: UserToken, defaultValue: [admin.user.id] },
            { label: 't(Visibility)', name: 'visibility', type: 'radiogroup', options: ['public','private'], format: VisibilityToken, defaultValue: 'public' },
            { label: 't(Bank)', name: 'bank_id', type: 'dropdown', endpoint: '/api/admin/finance/banks', filter: { status: { $eq: 'active' } }, valueKey: 'id', textKey: 'title', format: BankToken },
            { label: 't(Invoice Address)', name: 'address', type: 'textarea', rows: 2, defaultValue: finance.settings.address }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(program) {
    this.context.router.push(`/team/programs/${program.id}`)
    this.context.modal.close()
  }

}

export default New
