import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Email extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    campaign: PropTypes.object,
    program: PropTypes.object,
    workflow: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onTokens: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: this.props.config || {}
    })
  }

  _getEndpoint() {
    const { campaign, workflow } = this.props
    if(campaign) return `/api/admin/campaigns/${campaign.type}/${campaign.id}/emails`
    if(workflow) return `/api/admin/automation/workflows/${workflow.id}/emails`
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Send Email)',
      onChange: this._handleChange,
      onCancel: this._handleCancel,
      onSubmit: this._handleDone,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          after: config.email ? (
            <div className="maha-form-section-after">
              <MJSONScreenshot { ...this._getScreenshot() } />
              <Button { ...this._getButton() } />
            </div>
          ) : null,
          fields: [
            { label: 't(Email)', name: 'email', type: 'lookup', required: true, prompt: 'Choose an email', endpoint: this._getEndpoint(), valueKey: null, compareKey: 'id', textKey: 'title', form: this._getEmailForm(), defaultValue: config.email_id }
          ]
        }
      ]
    }
  }

  _getButton() {
    const { email } = this.state.config
    return {
      label: 'Design Email',
      className: 'link',
      route: `/admin/automation/emails/${email.id}/design`
    }
  }

  _getScreenshot() {
    const { email } = this.state.config
    return {
      engine: email.engine === 'mblocks' ? 'mblocks' : 'mjson',
      entity: `automation_emails/${email.code}`,
      width: 100,
      height: 75,
      screenshoted_at: email.screenshoted_at,
      updated_at: email.updated_at
    }
  }

  _getEmailForm() {
    const { user } = this.context.admin
    const { program } = this.props
    return {
      title: 't(New Email)',
      method: 'POST',
      action: '/api/admin/automation/emails',
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', defaultValue: program.id },
            ...this._getHiddenFields(),
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 'Enter a title', required: true },
            { label: 't(Template)', name: 'template_id', type: 'dropdown', placeholder: 'Choose a template', endpoint: `/api/admin/team/programs/${program.id}/templates`, valueKey: 'id', textKey: 'title' },
            { label: 't(From)', name: 'sender_id', type: 'dropdown', placeholder: 'Choose a sender', endpoint: `/api/admin/team/programs/${program.id}/senders`, filter: { email_status: { $eq: 'verified' } }, valueKey: 'id', textKey: 'rfc822', required: true },
            { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true, required: true },
            { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' },
            { label: 't(Body)', name: 'body', type: 'htmlfield', toolbar: ['b','em','u','ol','ul','a'], placeholder: 'Enter a body', required: true }
          ]
        }
      ]
    }
  }

  _getWorkflowFields() {
    const { workflow } = this.props
    const { event, form } = workflow
    if(form) {
      return [
        { name: 'form_id', type: 'hidden', defaultValue: form.id }
      ]
    } else if(event) {
      return [
        { name: 'event_id', type: 'hidden', defaultValue: event.id }
      ]
    }
    return []
  }

  _getHiddenFields() {
    const { campaign, workflow } = this.props
    if(workflow) {
      return [
        { name: 'workflow_id', type: 'hidden', defaultValue: workflow.id },
        ...this._getWorkflowFields()
      ]
    }
    return [
      { name: `${campaign.type}_campaign_id`, type: 'hidden', defaultValue: campaign.id }
    ]
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone({
      email_id: config.email.id,
      email: config.email      
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default Email
