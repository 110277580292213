import PropTypes from 'prop-types'
import React from 'react'

const DuplicateToken = (duplicate) => (
  <div className="contact-token">
    <div className="contact-token-label">
      { duplicate.contact.display_name }<br />
      { duplicate.type === 'name' &&
        <span className="alert">
          shares name { duplicate.contact.display_name }
        </span>
      }
      { duplicate.type === 'phone_number' &&
        <span className="alert">
          shares phone { duplicate.phone_number.formatted }
        </span>
      }
      { duplicate.type === 'mailing_address' && duplicate.mailing_address &&
        <span className="alert">
          shares address { duplicate.mailing_address.address.description }
        </span>
      }
    </div>
  </div>
)

DuplicateToken.propTypes = {
  duplicate: PropTypes.object
}

export default DuplicateToken
