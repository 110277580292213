import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.domain.name,
  manager: {
    path: `/admin/platform/domains/${resources.domain.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview domain={ resources.domain } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  domain: `/api/admin/platform/domains/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
