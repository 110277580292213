import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { account, team } = this.context.admin
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
            { label: 't(Organization)', name: 'organization', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { types: ['photos'] }, multiple: false }, 
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: team.provider.id, required: true },
            { label: 't(Advisor Domains)', name: 'domain_ids', type: 'checkboxgroup', endpoint: '/api/admin/domains', provider_id: team.provider.id, valueKey: 'id', textKey: 'name', multiple: true, required: true },
            { label: 't(Timezone)', name: 'timezone_id', instructions: 't(Content will be scheduled in the advisor\'s timezone)', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true, defaultValue: account.timezone_id }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(advisor) {
    this.props.onNext(advisor)
  }

}

export default Details
