import { useMemo } from 'react'

export const hidden = (state, props) => {
  return useMemo(() => {
    return state.mode === 'collapsed' ? Math.min(state.comments.length - 4, 0) : 0
  }, [state.comments, state.mode])
}

export const comments = (state, props) => {
  return useMemo(() => {
    return state.comments.sort((a,b) => {
      if(a.created_at > b.created_at) return 1
      if(a.created_at < b.created_at) return -1
      return 0
    })
  }, [state.comments])
}

export const quoted_comment = (state) => {
  return useMemo(() => {
    return _.find(state.comments, { id: state.quoted_comment_id })
  }, [state.comments, state.quoted_comment_id])
}
