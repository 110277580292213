import Page from '@admin/components/page'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(403 Forbidden)',
  message: {
    icon: 'exclamation-triangle',
    title: 't(Forbidden)',
    text: 't(You do not have permission to access this content)'
  }
})

export default Page(null, mapPropsToPage)
