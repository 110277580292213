import StatusToken from '@admin/tokens/status'
import PropTypes from 'prop-types'
import React from 'react'

const statuses = {
  draft: 'unpublished',
  translating: 'translating',
  error: 'error',
  published: 'published'
}

const LibraryToken = ({ value }) => (
  <StatusToken value={ statuses[value] } />
)

LibraryToken.propTypes = {
  value: PropTypes.string
}

export default LibraryToken
