import ProgramToken from '@apps/crm/admin/tokens/program'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Invoices)',
  collection: {
    endpoint: '/api/admin/finance/invoices',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (invoice) => <ProgramToken { ...invoice.program } /> },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(Total)', key: 'total', collapsing: true, primary: true, align: 'right', format: 'currency' }
    ],
    empty: {
      title: 't(No Invoices)',
      text: 't(There are no invoices for this contact)',
      icon: 'dollar'
    },
    entity: 'invoices',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/invoices/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
