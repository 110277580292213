import NumericField from '@admin/components/form/numericfield'
import VariantToken from '@apps/stores/admin/tokens/variant'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class RefundField extends React.Component {

  static propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    items: null
  }

  render() {
    if(!this.state.items) return null
    const groups = [
      { label: 'Unfulfilled Items', status: 'unfulfilled' },
      { label: 'Fulfilled Items', status: 'fulfilled' }
    ]
    return (
      <div className="store-refundfield">
        <div className="store-refundfield-body">
          { groups.map((group, index) => {
            const items = this._getItems(group.status)
            if(items.length === 0) return
            return (
              <div className="maha-table" key={`group_${index}`}>
                <table>
                  <tbody>
                    <tr>
                      <th colSpan="4">{group.label}</th>
                    </tr>
                    { items.map((item, index) => (
                      <tr className={ this._getClass(item, group.status) } key={`item_${index}`} onClick={ this._handleToggle.bind(this, item, group.status) }>
                        <td className="collapsing">
                          <Icon icon={ this._getIcon(item.variant.id, group.status) } />
                        </td>
                        <td className="unpadded">
                          <VariantToken product={ item.variant.product } variant={ item.variant }/>
                        </td>
                        <td>
                          <NumericField { ...this._getQuantity(item, group.status) } />
                        </td>
                        <td>
                          { numeral(this._getItemTotal(item, group.status)).format('0.00')}
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>
        <div className="store-refundfield-footer">
          Total: { numeral(this._getTotal()).format('0.00') }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.setState({
      items: {
        fulfilled: this._getDefault('fulfilled'),
        unfulfilled: this._getDefault('unfulfilled')
      }
    }, this.props.onReady)
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.state
    if(!_.isEqual(items, prevState.items)) {
      this._handleChange()
    }
  }

  _getClass(item, status) {
    const items = this.state.items[status]
    const classes = []
    if(!items[item.variant.id].active) classes.push('disabled')
    return classes.join(' ')
  }

  _getDefault(status) {
    return this._getItems(status).reduce((items, item) => ({
      ...items,
      [item.variant.id]: {
        active: false,
        quantity: item.quantity
      }
    }), {})
  }

  _getIcon(variant_id, status) {
    const items = this.state.items[status]
    return items[variant_id].active ? 'check-circle' : 'circle-o'
  }

  _getItems(status) {
    const { items } = this.props
    return Object.values(items.filter(item => {
      return item.status === status
    }).reduce((collected, item) => {
      const existing = collected[item.variant.id]
      return {
        ...collected,
        [item.variant.id]: {
          variant: item.variant,
          price: item.price,
          item_ids: [
            ...existing ? existing.item_ids : [],
            item.id
          ],
          quantity: existing ? existing.quantity + 1 : 1
        }
      }
    }, {})).sort((a,b) => {
      return a.variant.product.title > b.variant.product.title ? 1 : -1
    })
  }

  _getItemIds() {
    const { items } = this.state
    return Object.keys(items).reduce((ids, status) => [
      ...ids,
      ...this._getItems(status).filter((item) => {
        const { active, quantity } = items[status][item.variant.id]
        return active && quantity > 0
      }).reduce((item_ids, item) => [
        ...item_ids,
        ...item.item_ids.slice(0, items[status][item.variant.id].quantity)
      ], [])
    ], [])
  }

  _getQuantity(item, status) {
    const { items } = this.state
    return {
      min: 0,
      max: item.quantity,
      of: item.quantity,
      defaultValue: items[status][item.variant.id].quantity,
      onChange: this._handleQuantity.bind(this, item, status)
    }
  }

  _getItemTotal(item, status) {
    const { items } = this.state
    const refunded = items[status][item.variant.id].quantity
    if(!refunded) return 0
    return refunded * item.price
  }

  _getStatusTotal(status) {
    const { items } = this.state
    return this._getItems(status).filter(item => {
      const { active, quantity } = items[status][item.variant.id]
      return active && quantity > 0
    }).map(item => {
      return this._getItemTotal(item, status)
    }).reduce((total, price) => {
      return total + price
    }, 0.00)
  }

  _getTotal() {
    return this._getStatusTotal('unfulfilled') + this._getStatusTotal('fulfilled')
  }

  _getAllocations() {
    const { items } = this.state
    return Object.keys(items).reduce((allocations, status) => ({
      ...allocations,
      ...Object.keys(items[status]).reduce((sallocations, variant_id) => ({
        ...sallocations,
        [variant_id]: (allocations[variant_id]) + (sallocations[variant_id]) + items[status][variant_id].quantity
      }), {})
    }), {})
  }

  _handleChange() {
    this.props.onChange(this._getItemIds())
  }

  _handleQuantity(item, status, quantity) {
    this._handleUpdate(item.variant.id, status, 'quantity', quantity)
  }

  _handleToggle(item, status) {
    const items = this.state.items[status]
    this._handleUpdate(item.variant.id, status, 'active', !items[item.variant.id].active)
  }

  _handleUpdate(variant_id, status, key, value) {
    this.setState({
      items: {
        ...this.state.items,
        [status]: {
          ...this.state.items[status],
          [variant_id]: {
            ...this.state.items[status][variant_id],
            [key]: value
          }
        }
      }
    })
  }

}

export default RefundField
