import PropTypes from 'prop-types'
import Header from './header'
import Month from './month'
import moment from 'moment'
import React from 'react'

class Calendar extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    buttons: PropTypes.array
  }

  state = {
    items: null,
    offset: 0
  }

  render() {
    if(!this.state.items) return null
    return (
      <div className="maha-calendar">
        <div className="maha-calendar-body">
          <Header { ...this._getHeader() } />
          <Month { ...this._getMonth() } />
        </div>
      </div>
    )
  }
  
  componentDidMount() {
    this._handleFetch()
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { offset } = this.state
    if(offset !== prevState.offset) {
      this._handleFetch()
    }
  }

  _getCurrent() {
    const { offset } = this.state
    return moment().startOf('month').add(offset, 'months')
  }

  _getHeader() {
    const current = this._getCurrent()
    return {
      current,
      onNext: this._handleUpdate.bind(this, 1),
      onPrev: this._handleUpdate.bind(this, -1),
      onToday: this._handleUpdate.bind(this, 0)
    }
  }

  _getMonth() {
    const current = this._getCurrent()
    const { items } = this.state
    return {
      current,
      items
    }
  }

  _handleFetch() {
    const current = this._getCurrent()
    const start_of_month = current.startOf('month')
    const day_of_week = start_of_month.weekday()
    const units = Math.ceil((day_of_week + start_of_month.daysInMonth()) / 7) * 7
    const start_date = start_of_month.clone().subtract(day_of_week, 'days')
    const end_date = start_date.clone().add(units - 1, 'days')
    this.context.network.request({
      endpoint: '/api/admin/calendar',
      method: 'GET',
      query: {
        start_date: start_date.format('YYYY-MM-DD'),
        end_date: end_date.format('YYYY-MM-DD')
      },
      onSuccess: ({ data }) => {
        this.setState({
          items: data
        })
      }
    })
  }

  _handleUpdate(add) {
    const { offset } = this.state
    this.setState({
      offset: add === 0 ? 0 : offset + add
    })
  }

}

export default Calendar
