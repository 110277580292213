import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import UsersNew from '../../users/new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Users)',
  rights: ['team:manage_users'],
  collection: {
    endpoint: '/api/admin/team/users',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: UserToken }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'first_name' },
      { label: 't(Last Name)', key: 'last_name' }
    ],
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 'Role', key: 'role_id', type: 'select', endpoint: '/api/admin/team/roles', valueKey: 'id', textKey: 'title', sort: { key: 'title', order: 'asc' } }
        ] }
      ]
    },
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Active Users)',
      text: 't(You do not have any active users)'
    },
    entity: 'user',
    onClick: (record) => context.router.push(`/team/users/${record.id}`)
  },
  tasks : {
    icon: 'plus',
    access: { rights: { $oneOf: ['team:manage_users'] } },
    items: [
      {
        label: 'Add User',
        modal: UsersNew
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
