import DNSTable from '@admin/components/dnstable'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ sender_domain }, { admin, flash }) => {

  const dnstable = sender_domain ? {
    instructions: `t(In order to maintain a positive sending reputation and ensure that your emails don't end up in a SPAM filter, you must add and maintain the following DNS records for the domain) ${sender_domain.name}:`,
    is_hosted: sender_domain.is_hosted,
    records: sender_domain.records,
    last_verified_at: sender_domain.last_verified_at,
    check: {
      request: {
        endpoint: `/api/admin/truevail/admin/sender_domains/${sender_domain.id}/verify`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to verify dns records)')
      }
    }
  } : null

  const details = {
    sections: [
      {
        title: 't(Sender Details)',
        items: [{ 
          padded: true, 
          component: <DNSTable { ...dnstable } /> 
        }]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object,
  flash: PropTypes.object
}

Overview.propTypes = {
  sender_domain: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview sender_domain={ props.sender_domain } />
})

export default Panel(null, mapPropsToPanel)
