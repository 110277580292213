export const extractTokens = (text) => {
  const chars = text.split('')
  const result = chars.reduce((state, char, index) => {
    let { buffer, depth, tokens } = state
    if(char === '(' && chars[index - 1] === 't') buffer = 't'
    if(buffer.length > 0) {
      buffer += char
      if(char === '(') depth += 1
      if(char === ')') depth -= 1
      if(char === ')' && depth === 0) tokens.push(buffer), buffer = ''
    }
    return { buffer, depth, tokens }
  }, { buffer: '', depth: 0, tokens: [] })
  return result.tokens
}
