export const onClearSelection = () => ({
  type: 'CLEAR_SELECTION'
})

export const onSetResult = (result) => ({
  type: 'SET_RESULT',
  all: result.all,
  next: result.next,
  records: result.records,
  skip: result.skip,
  status: result.status,
  total: result.total
})

export const onSetStatus = (status) => ({
  type: 'SET_STATUS',
  status
})

export const onSelect = (id) => ({
  type: 'SELECT',
  id
})

export const onSelectAll = () => ({
  type: 'SELECT_ALL'
})
  
