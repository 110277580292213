import elementResizeEvent from 'element-resize-event'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Canvas from './canvas'
import React from 'react'

class Panel extends React.Component {

  static propTypes = {
    asset: PropTypes.object,
    adjustments: PropTypes.object,
    customRatio: PropTypes.number,
    frame: PropTypes.object,
    mode: PropTypes.string,
    offset: PropTypes.object,
    ratio: PropTypes.number,
    rotation: PropTypes.object,
    scaled: PropTypes.object,
    spin: PropTypes.number,
    sprite: PropTypes.object,
    viewport: PropTypes.object,
    zoom: PropTypes.number,
    zoomScale: PropTypes.number,
    zoomed: PropTypes.object,
    onMode: PropTypes.func,
    onSet: PropTypes.func
  }

  frameRef = React.createRef()
  panelRef = React.createRef()

  state = {
    dragging: false,
    drag: { x: 0, y: 0 },
    offset: { x: 0, y: 0 },
    start: { x: 0, y: 0 }
  }

  _handleMode = this._handleMode.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleMoveEnd = this._handleMoveEnd.bind(this)
  _handleMoveStart = this._handleMoveStart.bind(this)
  _handleReset = this._handleReset.bind(this)
  _handleResize = this._handleResize.bind(this)
  _handleWheel = this._handleWheel.bind(this)
  _handleZoom = this._handleZoom.bind(this)

  render() {
    const { frame, ratio } = this.props
    const buttons = [
      ...!ratio ? [
        { label: 'Change Ratio', icon: 'crop', mode: 'crop', handler: this._handleMode.bind(this, 'crop') }
      ] : [],
      { label: 'Adjustments', icon: 'sun-o', mode: 'adjustments' ,handler: this._handleMode.bind(this, 'adjustments') },
      { label: 'Rotate Left', icon: 'rotate-left', handler: this._handleRotate.bind(this, -90) },
      { label: 'Rotate Right', icon: 'rotate-right', handler: this._handleRotate.bind(this, 90) },
      { label: 'Flip Horizontal', icon: 'arrows-h', handler: this._handleFlip.bind(this, 'h') },
      { label: 'Flip Vertical', icon: 'arrows-v', handler: this._handleFlip.bind(this, 'v') },
      { label: 'Reset', handler: this._handleReset }
    ]
    return (
      <div className="maha-imageeditor-main" ref={ this.panelRef }>
        <div className="maha-imageeditor-buttons">
          { buttons.map((button, index) => (
            <Button { ...this._getButton(button)} key={`button_${index}`} />
          ))}
        </div>
        <div className="maha-imageeditor-frame" ref={ this.frameRef }>
          { frame &&
            <div className="maha-imageeditor-viewport" style={ this._getViewportStyle() }>
              <div className="maha-imageeditor-rotation" style={ this._getRotationStyle() }>
                <div className="maha-imageeditor-sprite" style={ this._getSpriteStyle() }>
                  <Canvas { ...this._getCanvas() } />
                </div>
              </div>
              <div { ...this._getOverlay()} />
            </div>
          }
        </div>
        <div className="maha-imageeditor-footer">
          <input { ...this._getRange() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    elementResizeEvent(this.panelRef.current, this._handleResize)
    this._handleResize()
  }

  componentWillUnmount() {
    if(this.panelRef?.current) elementResizeEvent.unbind(this.panelRef.current)
  }

  _getBounds() {
    const { viewport, spin, zoomed } = this.props
    const rot = spin % 180
    const right = (zoomed.w - viewport.rw) / 2
    const bottom = (zoomed.h - viewport.rh) / 2
    const left = 0 - right
    const top = 0 - bottom
    if(rot === 90) return { top: left, right: top, bottom: right, left: bottom }
    return { top, right, bottom, left }
  }

  _getButton(button) {
    const { mode } = this.props
    return {
      ...button.icon ? {
        icon: button.icon
      } : {
        label: button.label
      },
      className: `maha-imageeditor-button${ button.mode === mode ? ' active' : ''}`,
      tooltip: {
        title: button.label,
        position: 'top center'
      },
      handler: button.handler
    }
  }

  _getCanvas() {
    const { asset, adjustments, scaled } = this.props
    return {
      asset,
      adjustments,
      scaled
    }
  }

  _getCoordinates(e) {
    return {
      x: e.touches ? e.touches[0].clientX : e.clientX,
      y: e.touches ? e.touches[0].clientY : e.clientY
    }
  }

  _getOverlay() {
    const { dragging } = this.state
    return {
      className: `maha-imageeditor-overlay${dragging ? ' dragging' : ''}`,
      draggable: false,
      onTouchStart: this._handleMoveStart,
      onTouchMove: this._handleMove,
      onTouchEnd: this._handleMoveEnd,
      onMouseDown: this._handleMoveStart,
      onMouseMove: this._handleMove,
      onMouseUp: this._handleMoveEnd,
      onWheel: this._handleWheel
    }
  }

  _getOffset() {
    const { offset, spin } = this.props
    const { drag } = this.state
    const x = offset.x + drag.x
    const y = offset.y + drag.y
    if(spin === 0) return { l: x, t: y }
    if(spin === 90) return { l: y, t: -x }
    if(spin === 180) return { l: -x, t: -y }
    if(spin === 270) return { l: -y, t: x }
  }

  _getRange() {
    const { zoom, zoomScale } = this.props
    return {
      type: 'range',
      min: 0,
      max: zoomScale,
      value: zoom,
      onChange: this._handleZoom
    }
  }

  _getRotationStyle() {
    const { rotation } = this.props
    const csstransforms = []
    if(rotation.x !== 0) csstransforms.push(`rotateX(${rotation.x}deg)`)
    if(rotation.y !== 0) csstransforms.push(`rotateY(${rotation.y}deg)`)
    if(rotation.z !== 0) csstransforms.push(`rotateZ(${rotation.z}deg)`)
    return {
      transform: csstransforms.join(' ')
    }
  }

  _getSpriteStyle() {
    const { scaled, sprite, zoom, zoomScale } = this.props
    const csstransforms = []
    const offset = this._getOffset()
    if(offset.t !== 0 ) csstransforms.push(`translateY(${offset.t}px)`)
    if(offset.l !== 0) csstransforms.push(`translateX(${offset.l}px)`)
    if(zoom > 0) {
      const scale =  1 + (zoom / zoomScale)
      csstransforms.push(`scale(${scale})`)
    }
    return {
      transform: csstransforms.join(' '),
      width: scaled.rw,
      height: scaled.rh,
      top: sprite.t,
      left: sprite.l
    }
  }

  _getViewportStyle() {
    const { viewport } = this.props
    return {
      width: viewport.w,
      height: viewport.h
    }
  }

  _handleFlip(direction) {
    const { rotation } = this.props
    this.props.onSet({
      rotation: {
        ...rotation,
        ...direction === 'h' ? { y: rotation.y + 180 } : {},
        ...direction === 'v' ? { x: rotation.x + 180 } : {}
      }
    })
  }

  _handleMode(newmode) {
    const { mode } = this.props
    if(newmode === null) return this.props.onMode(null)
    if(mode === null) return this.props.onMode(newmode)
    if(newmode === mode) return this.props.onMode(null)
    this.props.onMode(newmode)
  }

  _handleMove(e) {
    const { offset } = this.props
    const { dragging, start } = this.state
    if(!dragging) return
    const drag = this._getCoordinates(e)
    if(drag.x === 0) return
    const bounds = this._getBounds()
    this.setState({
      drag: {
        x: Math.max(Math.min(offset.x + drag.x - start.x, bounds.right), bounds.left) - offset.x,
        y: Math.max(Math.min(offset.y + drag.y - start.y, bounds.bottom), bounds.top) - offset.y
      }
    })
  }

  _handleMoveStart(e) {
    this.setState({
      dragging: true,
      start: this._getCoordinates(e)
    })
  }

  _handleMoveEnd(e) {
    const { offset } = this.props
    const { drag } = this.state
    this.setState({
      dragging: false,
      start: { x: 0, y: 0 },
      drag: { x: 0, y: 0 }
    })
    this.props.onSet({
      offset: {
        x: offset.x + drag.x,
        y: offset.y + drag.y
      }
    })
  }

  _handleReset() {
    this.props.onSet({
      adjustments: {
        bri: 0,
        con: 0,
        vib: 0,
        sat: 0,
        hue: 0,
        sharp: 0,
        blur: 0
      },
      zoom: 0,
      rotation: { x: 0, y: 0, z: 0 },
      offset: { x: 0, y: 0 }
    })
  }

  _handleResize() {
    const { offsetWidth, offsetHeight } = this.frameRef.current
    this.props.onSet({
      frame: {
        w: offsetWidth,
        h: offsetHeight
      }
    })
  }

  _handleRotate(deg) {
    const { rotation } = this.props
    this.props.onSet({
      rotation: {
        ...rotation,
        z: rotation.z + deg
      }
    })
  }

  _handleWheel(e) {
    const { zoom, zoomScale } = this.props
    this.props.onSet({
      zoom: Math.max(Math.min(zoom + (e.deltaY < 0 ? 1 : -1), zoomScale), 0)
    })
  }

  _handleZoom(e) {
    this.props.onSet({
      zoom: Math.floor(e.target.value),
      offset: { x: 0, y: 0 }
    })
  }

}

export default Panel
