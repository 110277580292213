import ExpenseTypeToken from '@apps/finance/admin/tokens/expense_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    projectEndpoint: PropTypes.string,
    advance: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { advance, projectEndpoint } = this.props
    return {
      title: 't(Edit Cash Advance)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/advances/${advance.id}/edit`,
      action: `/api/admin/finance/advances/${advance.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Date Needed', name: 'date_needed', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Description)', name: 'description', type: 'textfield', placeholder: 'Describe the advance', required: true },
            { label: 't(Amount)', name: 'amount', type: 'moneyfield', required: true },
            { label: 'Project', name: 'project_id', type: 'lookup', endpoint: projectEndpoint, valueKey: 'id', textKey: 'display', format: ProjectToken },
            { label: 'Expense Type', name: 'expense_type_id', type: 'lookup', endpoint: '/api/admin/finance/expense_types', valueKey: 'id', textKey: 'display', format: ExpenseTypeToken }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
