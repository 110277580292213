import Button from '@apps/phone/admin/components/button'
import PropTypes from 'prop-types'
import Header from './header'
import React from 'react'

class Incoming extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    twilio: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    call: PropTypes.object
  }

  ringtone = null

  _handleAccept = this._handleAccept.bind(this)
  _handleReject = this._handleReject.bind(this)

  render() {
    const { call } = this.props
    const buttons = this._getButtons()
    return (
      <div className="maha-handset-call">
        <Header call={ call } />
        { call.extra &&
          <div className="maha-handset-call-extra">
            { this._getExtra(call.extra) }
          </div>
        }
        <div className="maha-handset-call-body" />
        <div className="maha-handset-actions" >
          { buttons.map((button, index) => (
            <div className="maha-handset-action" key={`action_${index}`}>
              <Button { ...button } />
            </div>
          ))}
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.ringtone = new Audio('/audio/ring.mp3')
    this.ringtone.play()
  }

  componentWillUnmount() {
    this.ringtone.pause()
    this.ringtone = null
  }

  _getButtons() {
    return [
      { icon: 'phone', type: 'hangup', label: 'Decline', handler: this._handleReject },
      { icon: 'phone', type: 'pickup', label: 'Accept', handler: this._handleAccept }
    ]
  }

  _getExtra(extra) {
    if(extra.transferred_from) {
      return `Call transferring from ${extra.transferred_from}`
    } else if(extra.transferred_back_from) {
      return `No answer, call transferring back from ${extra.transferred_back_from}`
    }
  }

  _getPanel() {
    return {
      title: 't(Incoming Call)'
    }
  }

  _handleAccept() {
    const { call } = this.props
    this.context.twilio.accept(call.call.id)
  }

  _handleReject() {
    const { call } = this.props
    this.context.twilio.reject(call.call.id)
  }

}

export default Incoming
