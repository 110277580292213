import SMSClient from '@apps/phone/admin/components/sms_client'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class SMS extends React.Component {

  static propTypes = {
    contact: PropTypes.object,
    contact_phone_number: PropTypes.object,
    program: PropTypes.object,
    program_phone_number: PropTypes.object,
    onBack: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <SMSClient { ...this._getSMSClient() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Conversation)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getSMSClient() {
    const { contact, contact_phone_number, program, program_phone_number } = this.props
    return {
      contact,
      program,
      contact_phone_number,
      program_phone_number
    }
  }

  _handleBack() {
    this.props.onBack()
  }

}

export default SMS
