import Page from '@admin/components/page'
import Overview from './overview'
import Addons from './addons'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.plan.title,
  manager: {
    path: `/admin/subscriptions/services/${resources.service.id}/plans/${resources.plan.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview service={ resources.service } plan={ resources.plan } />
      },
      {
        label: 'Add Ons',
        path: '/addons',
        panel: <Addons service={ resources.service } plan={ resources.plan } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  service: `/api/admin/subscriptions/services/${props.params.service_id}`,
  plan: `/api/admin/subscriptions/services/${props.params.service_id}/plans/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
