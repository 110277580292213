import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Manager from '@admin/components/manager'
import PropTypes from 'prop-types'
import Panel from './panels/show'
import New from './panels/new'
import React from 'react'

class Dashboard extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    card_types: PropTypes.array,
    panels: PropTypes.array
  }

  render() {
    const { panels } = this.props
    return (
      <div className="maha-dashboard">
        { panels.length > 0 ?
          <Manager { ...this._getManager() } /> : 
          <ModalPanel { ...this._getModalPanel() }>
            <Message { ...this._getEmpty() } />
          </ModalPanel>
        }
      </div>
    )
  }

  _getEmpty() {
    return {
      icon: 'table',
      title: 't(Welcome to your Dashboard)',
      text: 't(This is your dashboard. You can add dashboard panels to organize cards and design a custom, high-level view of whats happening across all of your apps and programs.)',
      buttons: [
        { 
          label: 't(Add Panel)',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: New 
        }
      ]
    }
  }

  _getManager() {
    const { panels } = this.props
    return {
      back: false,
      title: 't(Dashboard)',
      items: panels.map(panel => ({
        label: panel.title,
        panel: <Panel { ...this._getPanel(panel) } />
      }))
    }
  }

  _getModalPanel() {
    return {
      className: 'maha-dashboard',
      title: 't(Dashboard)',
      rightItems: [
        { 
          icon: 'plus',
          tooltip: 't(Add Panel)',
          access: { rights: { $oneOf: ['dashboards:manage_panels'] } },
          modal: New
        }
      ]
    }
  }

  _getPanel(panel) {
    const { card_types } = this.props
    return {
      card_types,
      panel
    }
  }

}

const mapResources = (props, context) => ({
  card_types: '/api/admin/dashboards/cards',
  panels: {
    endpoint: '/api/admin/dashboards/panels',
    refresh: `/users/${context.admin.user.id}/admin/dashboards/panels`
  }
})

export default Container(mapResources)(Dashboard)
