import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Edit from '../edit'
import Send from '../send'
import React from 'react'

const Overview = ({ voice_campaign }, { admin }) => {

  const program = {
    label: voice_campaign.program.title,
    className: 'link',
    route: `/team/programs/${voice_campaign.program.id}`
  }

  const details = {
    audits: `campaigns_voice_campaigns/${voice_campaign.id}`,
    comments: `campaigns_voice_campaigns/${voice_campaign.id}`,
    sections: [{
      title: 't(Voice Campaign Details)',
      items: [
        { label: 't(Title)', content: voice_campaign.title },
        { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> }
      ]
    }]
  }

  if(voice_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This voice campaign was deleted)' }
  } else if(voice_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This voice campaign is in draft mode)' }
  } else if(voice_campaign.status === 'active') {
    details.alert = { color: 'green', message: 't(This voice campaign is active)' }
  } else if(voice_campaign.status === 'inactive') {
    details.alert = { color: 'red', message: 't(This voice campaign is inactive)' }
  } else if(voice_campaign.status === 'retired') {
    details.alert = { color: 'red', message: 't(This voice campaign is retired)' }
  } else if(voice_campaign.status === 'scheduled') {
    details.alert = { color: 'teal', message: 't(This voice campaign is scheduled)' }
  } else if(voice_campaign.status === 'sent') {
    details.alert = { color: 'green', message: 't(This voice campaign was sent)' }
  }

  if(voice_campaign.event) {
    const event = {
      label: voice_campaign.event.title,
      className: 'link',
      route: `/events/events/${voice_campaign.event.id}`
    }
    details.sections[0].items.push({ label: 't(Event)', content: <Button { ...event } /> })
  }

  if(voice_campaign.form) {
    const form = {
      label: voice_campaign.form.title,
      className: 'link',
      route: `/forms/forms/${voice_campaign.form.id}`
    }
    details.sections[0].items.push({ label: 't(Form)', content: <Button { ...form } /> })
  }

  if(voice_campaign.pipeline) {
    const pipeline = {
      label: voice_campaign.pipeline.title,
      className: 'link',
      route: `/sales/pipelines/${voice_campaign.pipeline.id}`
    }
    details.sections[0].items.push({ label: 't(Pipeline)', content: <Button { ...pipeline } /> })
  }

  if(voice_campaign.service) {
    const service = {
      label: voice_campaign.service.title,
      className: 'link',
      route: `/subscriptions/services/${voice_campaign.service.id}`
    }
    details.sections[0].items.push({ label: 't(Service)', content: <Button { ...service } /> })
  }

  if(voice_campaign.store) {
    const store = {
      label: voice_campaign.store.title,
      className: 'link',
      route: `/stores/stores/${voice_campaign.store.id}`
    }
    details.sections[0].items.push({ label: 't(Store)', content: <Button { ...store } /> })
  }

  if(voice_campaign.direction === 'outbound') {
    details.sections[0].items.push({ label: 't(Purpose)', content: voice_campaign.purpose })
  }

  details.sections[0].items.push({ label: 't(Phone Number)', content: voice_campaign.phone_number.formatted })

  if(voice_campaign.status === 'scheduled') {
    details.sections[0].items.push({ label: 't(Send At)', content: voice_campaign.send_at, format: 'datetime' })
  }

  if(voice_campaign.status === 'sent') {
    details.sections[0].items.push({ label: 't(Sent At)', content: voice_campaign.sent_at, format: 'datetime' })
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  voice_campaign: PropTypes.object
}

const mapPropsToPanel = ({ voice_campaign }, { flash }) => ({
  title: 't(Overview)',
  panel: <Overview voice_campaign={ voice_campaign } />,
  tasks: { 
    items: [
      { 
        label: 't(Edit Campaign)', 
        show: voice_campaign.status === 'draft',
        modal: <Edit voice_campaign={ voice_campaign } /> 
      },
      { 
        label: 't(Send/Schedule Campaign)',
        show: voice_campaign.status === 'draft',
        modal: <Send voice_campaign={ voice_campaign } /> 
      },
      {
        label: 't(Unschedule Campaign)',
        confirm: 't(Are you sure you want to unschedule this voice_campaign?)',
        show: voice_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/campaigns/voice/${voice_campaign.id}/unschedule`,
          method: 'PATCH',
          onFailure: () => flash.set('error', 't(Unable to unschedule voice_campaign)')
        }
      },
      {
        label: 't(Delete Campaign)',
        confirm: 't(Are you sure you want to delete this voice_campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/campaigns/voice/${voice_campaign.id}`,
          method: 'DELETE'
        },
        onSuccess: () => {
          context.flash.set('success', 't(Successfully deleted voice campaign)')
          context.router.goBack()
        },
        onFailure: () => context.flash.set('error', 't(Unable to delete voice campaign)')
      }
    ] 
  }
})

export default Panel(null, mapPropsToPanel)
