export const onClear = () => ({
  type: 'CLEAR'
})

export const onSetResponse = ({ response, status, url }) => ({
  type: 'SET_RESPONSE',
  response, 
  status, 
  url
})

export const onSetStatus = (status) => ({
  type: 'SET_STATUS',
  status
})

export const onSetUrl = (url) => ({
  type: 'SET_URL',
  url
})
