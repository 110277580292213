import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import { useEffect, useMemo, useState } from 'react'

const getEndpoint = (resource) => {
  return resource.endpoint || resource
}

const getChannel = (team, resource) => {
  let channel = resource.refresh || resource.endpoint || resource
  channel = channel.replace('/api', '')
  if(team) channel = channel.replace(new RegExp(`^/${team.subdomain}`), '/admin')
  if(team) channel = channel.replace(/^\/admin/, `/teams/${team.id}/admin`) 
  return channel
}

const getStatus = (requests) => {
  return requests.reduce((status, request) => {
    if (status === 'refreshing' || request.status === 'refreshing') return 'refreshing'
    if (status === 'loading' || request.status === 'loading') return 'loading'
    if (status === 'failure' || request.status === 'failure') return 'failure'
    if (status !== 'pending' && request.status === 'pending') return 'pending'
    return request.status || request.status
  }, 'pending')
}

const getFresh = (requests) => {    
  return requests.reduce((fresh, request) => {
    return !fresh ? false : request.fresh
  }, true)
}

const expandRequests = (team, resources, requests) => {
  return Object.keys(resources).map(key => {
    const resource = resources[key]
    const endpoint = getEndpoint(resource)
    const method = resource.method || 'GET'
    const { filter, query, sort } = resource
    const cacheKey = JSON.stringify({ endpoint, method, filter, query, sort })
    const request = requests.find(request => request.key === key)
    const unchanged = request !== undefined && request.cacheKey === cacheKey
    return {
      cacheKey,
      channel: getChannel(team, resource),
      endpoint,
      error: unchanged ? request.error : null,
      filter,
      fresh: unchanged ? request.fresh : false,
      key,
      method,
      query,
      sort,
      status: unchanged ? request.status : 'pending',
      value: unchanged ? request.value : null
    }
  })
}

const expandHandlers = (requests, setRequests) => {
  return requests.map((request, index) => {
    return () => {
      setRequests((requests) => {
        return requests.map((request, i) => ({
          ...request,
          ...index === i ? {
            fresh: false
          } : {}
        }))
      })
    }
  })
}

// fetch all unfresh resources, setting statuses and freshness
// throughout the request lifecycle
const fetchResources = (network, requests, setRequests) => {
  requests.map((request, index) => {
    if(request.fresh) return
    network.request({
      endpoint: request.endpoint,
      method: request.method,
      filter: request.filter,
      query: request.query,
      sort: request.sort,
      onRequest: () => {
        setRequests((resources) => resources.map((resource, i) => ({
          ...resource,
          ...index === i ? {
            status: request.status === 'pending' ? 'loading' : 'refreshing'
          } : {}
        })))
      },
      onSuccess: ({ data }) => {
        setRequests((resources) => resources.map((resource, i) => ({
          ...resource,
          ...index === i ? {
            status: 'success',
            value: data,
            fresh: true
          } : {}
        })))
      },
      onFailure: ({ error }) => {
        setRequests((resources) => resources.map((resource, i) => ({
          ...resource,
          ...index === i ? {
            status: 'failure',
            error
          } : {}
        })))
      } 
    })
  })
}

// subscribe to the refresh event for each resource
const subscribe = (network, resources, handlers) => {
  resources.map((resource, index) => {
    network.subscribe({
      channel: resource.channel,
      action: 'refresh',
      handler: handlers[index]
    })
  })
}

// unsubscribe from the refresh event for each resource
const unsubscribe = (network, resources, handlers) => {
  resources.map((resource, index) => {
    network.unsubscribe({
      channel: resource.channel,
      action: 'refresh',
      handler: handlers[index]
    })
  }, {})
}

const useContainer = (params) => {

  const network = useNetworkContext().network
  const team = useAdminContext()?.admin?.team

  const [resources, setResources] = useState([])
  const [requests, setRequests] = useState([])

  // memoize the fresh and status values
  const fresh = useMemo(() => getFresh(requests), [requests])  
  const status = useMemo(() => getStatus(requests), [requests])

  // if the computed resource prop has changed, then update
  // the resources variable to trigger a refresh
  useEffect(() => {

    if(!params.resources) return

    if(_.isEqual(params.resources, resources)) return

    setResources(params.resources)
    setRequests(expandRequests(team, params.resources, requests))

  }, [params.resources])

  // when the resources have been updated, subscribe to the refresh
  useEffect(() => {

    if(resources.length === 0) return

    const handlers = expandHandlers(requests, setRequests)

    subscribe(network, requests, handlers)

    return () => unsubscribe(network, requests, handlers)

  }, [resources])

  // if any of the resources arent fresh, then fetch them
  useEffect(() => {

    if(resources.length === 0) return

    if(fresh) return

    fetchResources(network, requests, setRequests)

  }, [fresh])

  return {
    status: params.resources ? status : 'success',
    values: requests.reduce((values, request) => ({
      ...values,
      [request.key]: request.value
    }), {})
  }

}

export default useContainer