import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ expense_type, integration }) => {

  const details = {
    audits: `finance_expense_types/${expense_type.id}`,
    comments: `finance_expense_types/${expense_type.id}`,
    sections: [
      {
        title: 't(Expense Type Details)',
        items: [
          { label: 't(Title)', content: expense_type.title },
          { label: 't(Description)', content: expense_type.description }
        ]
      }
    ]
  }

  if(integration) {
    details.sections.push({
      title: <IntegrationToken integration={ integration } />,
      padded: false,
      items: [
        { label: 'Expense Code', content: expense_type.integration.expense_code },
        { label: 'Source Code', content: expense_type.integration.source_code }
      ]
    })
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  expense_type: PropTypes.object,
  integration: PropTypes.object
}

export default Overview
