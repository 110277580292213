import { useLocaleContext } from '@admin/components/locale'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const T = (props) => {

  const { text } = props

  if(!text) return null

  if(!_.isString(text)) return text

  const locale = useLocaleContext()?.locale || {}

  const hasMarkup = useMemo(() => {
    return /<\/[^>]+>/.test(text)
  }, [text])

  const translated = useMemo(() => {
    return locale.t ? locale.t(text) : text
  }, [locale.lang, text])

  return hasMarkup ? <span dangerouslySetInnerHTML={{ __html: translated }} /> : translated

}

T.propTypes = {
  text: PropTypes.any
}

export default T