import LocationToken from '@apps/events/admin/tokens/location'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object
  }

  state = {
    session: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event } = this.props
    const { session } = this.state
    return {
      title: 't(New Session)',
      method: 'POST',
      action: `/api/admin/events/events/${event.id}/sessions`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' },
            { label: 't(Location)', type: 'segment', required: true, fields: [
              { name: 'is_online', type: 'radiogroup', options: [
                { value: false, text: 'This is a in person session' },
                { value: true, text: 'This is a in online session' }
              ], defaultValue: false },
              ...!session.is_online ? [
                { name: 'location', type: 'lookup', required: true, prompt: 'Choose a location', endpoint: '/api/admin/events/locations', valueKey: null, textKey: 'name', form: this._getLocationForm(), format: LocationToken }
              ] : []
            ] },
            { label: 't(Timing)', type: 'segment', required: true, fields: [
              { name: 'date', placeholder: 't(Enter Date)', type: 'datefield', required: true },
              { type: 'fields', fields: [
                { name: 'start_time', placeholder: 't(Enter Start Time)', type: 'timefield', required: true },
                { name: 'end_time', placeholder: 't(Enter End Time)', type: 'timefield', required: true, start: session.start_time, duration: true }
              ] }
            ] }
          ]
        }
      ]
    }
  }

  _getLocationForm() {
    return {
      title: 't(New Location)',
      method: 'POST',
      action: '/api/admin/events/locations',
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield' },
            { label: 't(Address)', name: 'address', type: 'addressfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(session) {
    this.setState({ session })
  }

  _handleSuccess(session) {
    this.context.modal.close()
  }

}

export default New
