import ExpenseTypeToken from '@apps/finance/admin/tokens/expense_type'
import Panel from '@admin/components/panel'
import New from '../expense_types/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Expense Types)',
  access: { rights: { $oneOf: ['finance:manage_configuration'] } },
  collection: {
    endpoint: '/api/admin/finance/expense_types',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'fulltitle', primary: true, format: ExpenseTypeToken }
    ],
    defaultSort: { key: 'fulltitle', order: 'asc' },
    empty: {
      icon: 'tag',
      title: 't(No Expense Types)',
      text: 't(You have not yet added any expense types)',
      buttons: [
        { 
          label: 't(Create Expense Type)', 
          access: { rights: { $oneOf: ['finance:manage_configuration'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'expense type',
    onClick: (record) => context.router.push(`/finance/expense_types/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Expense Type)', 
    access: { rights: { $oneOf: ['finance:manage_configuration'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
