import AccountToken from '@admin/tokens/account'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Accounts)',
  collection: {
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'full_name', primary: true, format: AccountToken }
    ],
    endpoint: '/api/admin/platform/accounts',
    entity: 'account',
    defaultSort: { key: 'last_name', order: 'asc' },
    onClick: (record) => context.router.push(`/platform/accounts/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
