const INITIAL_STATE = {
  adjustments: {},
  customRatio: 1,
  frame: null,
  mode: null,
  offset: { x: 0, y: 0 },
  original: null,
  rotation: { x: 0, y: 0, z: 0 },
  zoom: 0
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'MODE':
    return {
      ...state,
      mode: action.mode
    }

  case 'RATIO':
    return {
      ...state,
      customRatio: action.ratio
    }

  case 'SET':
    return {
      ...state,
      ...action.values
    }

  default:
    return state
  }

}

export default reducer
