import ProgramForm from '@apps/crm/admin/components/programform'
import Merge from '@apps/team/admin/views/tags/merge'
import New from '@apps/team/admin/views/tags/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Tags)',
  access: { rights: { $oneOf: ['team:manage_configuration']} },
  collection: {
    endpoint: '/api/admin/team/tags',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Tag)', key: 'text', primary: true },
      { label: 't(Items)', key: 'taggable_count', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'text', order: 'asc' },
    empty: {
      icon: 'tag',
      title: 't(No Tags)',
      text: 't(You have not yet created any tags)',
      buttons: [
        { 
          label: 't(Create Tag)', 
          access: { rights: { $oneOf: ['team:manage_configuration'] } },
          modal: <ProgramForm form={ New } /> 
        }
      ]
    },
    onClick: (record) => context.router.push(`/admin/team/tags/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Merge Tag)',
        access: { rights: { $oneOf: ['team:manage_configuration'] } },
        modal: <Merge tag={record} />
      },
      {
        label: 't(Delete Tag)',
        access: { rights: { $oneOf: ['team:manage_configuration'] } },
        confirm: 't(Are you sure you want to delete this tag?)',
        request: {
          endpoint: `/api/admin/team/tags/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this tag)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this tag)')
        }
      }
    ],
    entity: 'tag'
  },
  task: {
    access: { rights: { $oneOf: ['team:manage_configuration'] } },
    tooltip: 't(New Tag)',
    icon: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
