import PropTypes from 'prop-types'
import React from 'react'

const RecordValueToken = ({ record, domain }) => (
  <div className="token nobreak">
    { record.name && record.name.length > 0 ? `${record.name}` : '@' }
  </div>
)

RecordValueToken.propTypes = {
  record: PropTypes.object,
  domain: PropTypes.object
}

export default RecordValueToken
