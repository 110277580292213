import RadioGroup from '@admin/components/form/radiogroup'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class ButtonDisplayField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    display: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.display) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { display } = this.state
    if(!_.isEqual(display, prevState.display)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device } = this.props
    const { display } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'display', type: RadioGroup, canHover: false, options: [
              { value: 'block', text: 't(Block)' },
              { value: 'inline', text: 't(Inline)' }
            ], defaultValue: 'inline', device, value: display.display },
            { label: 't(Alignment)', name: 'align', type: 'alignmentfield', include: display.display === 'inline', defaultValue: 'center', value: display.align }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { display } = this.state
    this.props.onChange(display)
  }

  _handleSet(display) {
    this.setState({ display })
  }

}

export default ButtonDisplayField
