import AssetIcon from '@admin/components/asset_icon'
import Timestamp from '@admin/components/timestamp'
import Button from '@admin/components/button'
import Avatar from '@admin/components/avatar'
import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const NoteToken = ({note}, {admin}) => (
  <div className="crm-contact-note">
    <div className="crm-contact-note-avatar">
      <Avatar user={ note.user } width="48" />
    </div>
    <div className="crm-contact-note-details">
      <div className="crm-contact-note-user">{ note.user.full_name }</div>
      <div className="crm-contact-note-timestamp">
        <Timestamp time={ note.created_at } />
      </div>
      { admin.team.has_programs &&
        <div className="crm-contact-note-program">
          <Logo team={ note.program } width="24" /> { note.program.title }
        </div>
      }
      <div>{ note.text }</div>
      <div className="crm-contact-note-attachments">
        { note.attachments.map((attachment, index) => (
          <Button route={ attachment.path } className="crm-contact-note-attachment" key={`attachment_${index}`}>
            <div className="crm-contact-note-attachment-icon">
              <AssetIcon { ...attachment } key={`attachment_${index}`} />
            </div>
            <div className="crm-contact-note-attachment-label">
              { attachment.file_name }
            </div>
          </Button>
        ))}
      </div>
    </div>
  </div>
)

NoteToken.contextTypes = {
  admin: PropTypes.object
}

NoteToken.propTypes = {
  note: PropTypes.object
}

export default NoteToken
