import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class NewColumn extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Column)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { label: 't(Label)', name: 'label', type: 'textfield', placeholder: 'Enter Label', required: true, defaultValue: 'Column' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _getColumn(config) {
    return {
      id: getCode(5),
      entity: 'column',
      meta: {
        label: config.label
      },
      settings: {},
      styles: {
        padding: '15px'
      },
      content: {
        children: []
      }
    }
  }

  _handleSubmit(config) {
    this.props.onDone({
      ...this._getColumn(config)
    })
    this.context.modal.close()
  }

}

export default NewColumn
