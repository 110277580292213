import Page from '@admin/components/page'
import Allocations from './allocations'
import Overview from './overview'
import Refunds from './refunds'
import Refund from './refund'
import React from 'react'
import Void from './void'

const getTabs = ({ allocations, payment, refunds }) => ({
  items: [
    {
      label: 't(Overview)',
      component: <Overview payment={ payment } />
    },
    {
      label: 't(Allocations)',
      component: <Allocations payment={ payment } allocations={ allocations } />
    },
    {
      label: 't(Refunds)',
      show: refunds.length > 0,
      component: <Refunds payment={ payment } refunds={ refunds } />
    }
  ]
})

const getTasks = ({ allocations, payment }) => !_.includes(['cash','check','ebt','credit','scholarship'], payment.method) ? ({
  items: [
    {
      label: 't(Void Payment)',
      show: ((payment.braintree_id === null && payment.stripe_id === null) || payment.status === 'captured'),
      modal: <Void payment={ payment } />
    },
    {
      label: 't(Issue Refund)',
      show: (payment.braintree_id !== null || payment.stripe_id !== null) && !_.includes(['captured','voided'], payment.status) && payment.refundable > 0,
      modal: <Refund allocations={ allocations } payment={ payment } />
    }
  ]
}) : null

const mapResourcesToPage = (props, context) => ({
  payment: `/api/admin/finance/payments/${props.params.id}`,
  allocations: `/api/admin/finance/payments/${props.params.id}/allocations`,
  refunds: `/api/admin/finance/payments/${props.params.id}/refunds`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Payment)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
