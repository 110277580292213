import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    workflow: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { workflow } = this.props
    return {
      title: 't(Edit Workflow)',
      method: 'PATCH',
      endpoint: `/api/admin/automation/workflows/${workflow.id}/edit`,
      action: `/api/admin/automation/workflows/${workflow.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...workflow.trigger.code === 'automation:enrollment_created' ? [
              { label: 't(Purpose)', name: 'purpose', type: 'radiogroup', options: ['marketing','transactional'], required: true, format: PurposeToken, defaultValue: 'marketing' }
            ] : [],
            ...workflow.trigger.code === 'sales:deal_transitioned' ? [
              { label: 't(From Stage)', name: 'from_stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${workflow.pipeline.id}/stages`, valueKey: 'id', textKey: 'title' },
              { label: 't(To Stage)', name: 'to_stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${workflow.pipeline.id}/stages`, valueKey: 'id', textKey: 'title' }
            ] : [],
            { label: 't(Enrollment)', name: 'is_unique', type: 'radiogroup', options: [
              { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
              { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
            ], defaultValue: false, required: true  }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
