import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import PropTypes from 'prop-types'
import Results from './results'
import Call from './call'
import React from 'react'

class Calls extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    phone_number: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  state = {
    q: ''
  }

  _handleCall = this._handleCall.bind(this)
  _handleQuery = this._handleQuery.bind(this)

  render() {
    return (
      <div className="maha-handset-search">
        <div className="maha-handset-search-header">
          <Searchbox { ...this._getSearchBox() } />
        </div>
        <div className="maha-handset-search-body">
          <Infinite { ...this._getInfinite() } />
        </div>
      </div>
    )
  }

  _getCall(call) {
    const { phone_number, onPop, onPush } = this.props
    return {
      call,
      program: phone_number.program,
      onPop,
      onPush
    }
  }

  _getInfinite() {
    const { team } = this.context.admin
    const { phone_number } = this.props
    const { q } = this.state
    return {
      endpoint: '/api/admin/phone/calls',
      refresh: `/teams/${team.id}/admin/phone/numbers/${phone_number.id}/calls`,
      defaultQuery: { phone_number_id: phone_number.id },
      filter: {
        q,
        status: {
          $eq: 'completed'
        }
      },
      empty: {
        icon: 'phone',
        title: 't(No Calls)',
        text: 't(There are no calls for this program)'
      },
      layout: Results,
      props: {
        onChoose: this._handleCall
      }
    }
  }

  _getSearchBox() {
    return {
      onChange: this._handleQuery
    }
  }

  _handleQuery(q) {
    this.setState({ q })
  }

  _handleCall(call) {
    this.props.onPush(Call, this._getCall(call))
  }

}

export default Calls
