import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import ToggleList from './toggle_list'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const ToggleListContainer = (props, ref) => {

  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'toggle_list',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <ToggleList { ...mergedProps } />

}

export default ToggleListContainer
