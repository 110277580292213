import NumberToken from '@apps/phone/admin/tokens/number'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Released Numbers)',
  access: {},
  collection: {
    endpoint: '/api/admin/phone/numbers',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Number)', key: 'formatted', primary: true, format: NumberToken },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title' }
      ] : []
    ],
    empty: {
      icon: 'hashtag',
      title: 't(No Phone Numbers)',
      text: 't(You have not yet released any phone numbers)'
    },
    entity: 'phone number',
    defaultQuery: { released_at: true },
    defaultSort: { key: 'number', order: 'asc' },
    onClick: (record) => context.router.push(`/phone/numbers/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
