const triggers = [
  {
    icon: 'check',
    code: 'subscription_created',
    title: 't(Subscription Created)',
    text: 't(Subscription is created for service)'
  },
  {
    icon: 'dollar',
    code: 'invoice_created',
    title: 't(Invoice Created)',
    text: 't(Invoice created for a subscription)'
  },
  {
    icon: 'refresh',
    code: 'renewal_success',
    title: 't(Subscription Renewed)',
    text: 't(Subscription renewal succeeded for service)'
  },
  {
    icon: 'warning',
    code: 'renewal_failure',
    title: 't(Subscription Not Renewed)',
    text: 't(Subscription renewal failed for service)'
  },
  {
    icon: 'dollar',
    code: 'payment_method_expiring',
    title: 't(Subscription Payment Expiring)',
    text: 't(Payment method is expiring for service)'
  },
  {
    icon: 'dollar',
    code: 'payment_method_expired',
    title: 't(Subscription Payment Expired)',
    text: 't(Payment method is expired for service)'
  },
  {
    icon: 'ban',
    code: 'subscription_canceled',
    title: 't(Subscription Canceled)',
    text: 't(Subscription is canceled)'
  }
]

export default triggers
