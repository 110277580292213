import OmniSearch from '@admin/components/omnisearch'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class SearchPanel extends React.Component {

  static contextTypes = {
    portal: PropTypes.object
  }

  _handleClose = this._handleClose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <OmniSearch { ...this.props }/>
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Search)',
      leftItems: [
        { icon: 'remove', handler: this._handleClose, tooltip: {
          title: 't(Close Search)',
          position: 'bottom left'
        } }
      ]
    }
  }

  _handleClose() {
    this.context.portal.closeSidebar()
  }

}

export default SearchPanel
