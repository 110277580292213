import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import CompactVendorToken from '@apps/finance/admin/tokens/vendor/compact'
import CompactTypeToken from '@apps/finance/admin/tokens/type/compact'
import ExpenseTypeToken from '@apps/finance/admin/tokens/expense_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import VendorToken from '@apps/finance/admin/tokens/vendor'
import BatchToken from '@apps/finance/admin/tokens/batch'
import TypeToken from '@apps/finance/admin/tokens/type'
import { getIntegrationTasks } from '../integration'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'

const mapPropsToPanel = (props, context) => ({
  title: 't(Report)',
  access: { rights: { $oneOf: ['finance:access_expense_reports'] } },
  collection: {
    endpoint: '/api/admin/finance/items/report',
    table: [
      { label: 't(ID)', key: 'item_id', collapsing: true, visible: false },
      { label: 't(Type)', key: 'type', collapsing: true, format: CompactTypeToken },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(User)', key: 'user.full_name', sort: 'last_name', primary: true },
      { label: 'Project', key: 'project.title', sort: 'project', format: CompactProjectToken },
      { label: 't(Description)', key: 'description' },
      { label: 'Expense Type', key: 'expense_type.title', sort: 'expense_type', format: CompactExpenseTypeToken },
      { label: 'Vendor', key: 'vendor.name', sort: 'vendor', format: CompactVendorToken },
      { label: 'Account', key: 'account.name', sort: 'account'},
      { label: 't(Amount)', key: 'amount', collapsing: true, primary: true, format: 'currency' },
      { label: 't(Status)', key: 'status', collapsing: true, align: 'center', format: StatusToken }
    ],
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 'Type', key: 'type', type: 'select', options: [
            { value: 'expense', text: 't(Expense)' },
            { value: 'reimbursement', text: 't(Reimbursement)' },
            { value: 'check', text: 't(Check Request)' },
            { value: 'trip', text: 't(Mileage)' },
            { value: 'advance', text: 't(Cash Advance)' }
          ], format: TypeToken, search: false },
          { name: 'Batch', key: 'batch_id', type: 'select', endpoint: '/api/admin/finance/batches', valueKey: 'id', textKey: 'title', format: BatchToken },
          { name: 'User', key: 'user_id', type: 'select', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', sort: { key: 'last_name', order: 'asc' }, format: UserToken },
          { name: 'Project', key: 'project_id', type: 'select', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'title', format: ProjectToken },
          { name: 'Expense Type', key: 'expense_type_id', type: 'select', multiple: true, endpoint: '/api/admin/finance/expense_types', valueKey: 'id', textKey: 'title', format: ExpenseTypeToken },
          { name: 'Vendor', key: 'vendor_id', type: 'select', endpoint: '/api/admin/finance/vendors', valueKey: 'id', textKey: 'name', format: VendorToken },
          { name: 'Account', key: 'account_id', type: 'select', endpoint: '/api/admin/finance/accounts', valueKey: 'id', textKey: 'name' },
          { name: 'Date Range', key: 'date', type: 'daterange', include: ['this','last'] },
          { name: 'Status', key: 'status', type: 'select', options: ['incomplete','pending','submitted','approved','rejected','reviewed','exported'], format: StatusToken }
        ] }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Type)', key: 'type' },
      { label: 't(Date)', key: 'date' },
      { label: 't(User)', key: 'user.full_name' },
      { label: 'Project Code', key: 'project.integration.project_code' },
      { label: 'Project', key: 'project.title' },
      { label: 'Expense Type Code', key: 'expense_type.integration.expense_code' },
      { label: 'Expense Type', key: 'expense_type.title' },
      { label: 'Vendor', key: 'vendor.name' },
      { label: 'Account', key: 'account.name' },
      { label: 't(Description)', key: 'description' },
      { label: 'Time Leaving', key: 'time_leaving' },
      { label: 'Time Arriving', key: 'time_arriving' },
      { label: 'Odometer Start', key: 'odometer_start' },
      { label: 'Odometer End', key: 'odometer_end' },
      { label: 'Total Miles', key: 'total_miles' },
      { label: 't(Amount)', key: 'amount' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultSort: { key: 'date', order: 'desc' },
    selectable: true,
    selectValue: 'code',
    buttons: (selected, onSuccess) => [
      getIntegrationTasks(props.app.settings.integration, context, selected, onSuccess)
    ],
    onClick: (record) => context.router.push(`/finance/${record.type}s/${record.item_id}`)
  }
})

export default Panel(null, mapPropsToPanel)
