import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const getTabs = ({ actions, enrollment, workflow }) => ({
  items: [
    { label: 't(Overview)', component: <Overview actions={ actions } workflow={ workflow } enrollment={ enrollment } /> }
  ]
})

const getTasks = ({ enrollment, workflow }, { flash }) => ({
  items: [
    {
      label: 't(Retry Enrollment)',
      show: _.includes(['canceled','failed'], enrollment.status),
      request: {
        endpoint: `/api/admin/automation/workflows/${workflow.id}/enrollments/${enrollment.id}/retry`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to retry enrollment)'),
        onSuccess: () => flash.set('success', 't(The enrollment has been queued for execution)')
      }
    }, {
      label: 't(Cancel Enrollment)',
      confirm: 't(Are you sure you want to cancel this enrollment?)',
      show: enrollment.status === 'active',
      request: {
        endpoint: `/api/admin/automation/workflows/${workflow.id}/enrollments/${enrollment.id}/cancel`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to cancel enrollment)'),
        onSuccess: () => flash.set('success', 't(The enrollment has been canceled)')
      }
    }
  ]
})

const mapResourcesToPage = (props, context) => ({
  actions: `/api/admin/automation/workflows/${props.params.workflow_id}/enrollments/${props.params.id}/actions`,
  workflow: `/api/admin/automation/workflows/${props.params.workflow_id}`,
  enrollment: `/api/admin/automation/workflows/${props.params.workflow_id}/enrollments/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Enrollment)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
