import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const SubscriptionProfileToken = ({ profile, hashtags }) => {
  const username = {
    label: profile.username || profile.name,
    className: 'link',
    link: profile.url
  }
  return (
    <div className="profile-token">
      <div className="profile-token-logo">
        { profile.photo ?
          <div className="profile-token-logo-image">
            <Img src={`/imagecache/w=50${profile.photo}`} />
            <div className="profile-token-icon">
              <Img src={`/images/services/${profile.service.source_name}.png`} />
            </div>        
          </div> :
          <Img src={`/images/services/${profile.service.source_name}.png`} />
        }
      </div>
      <div className="profile-token-label">
        <strong><Button { ...username } /></strong><br />
        { hashtags || 'no hashtags' }
      </div>
    </div>
  )
}

SubscriptionProfileToken.propTypes = {
  profile: PropTypes.object,
  hashtags: PropTypes.string
}

export default SubscriptionProfileToken
