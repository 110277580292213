import elementResizeEvent from 'element-resize-event'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.PureComponent {

  static propTypes = {
    device: PropTypes.string,
    devices: PropTypes.array,
    orientation: PropTypes.string,
    children: PropTypes.any
  }

  previewRef = React.createRef()

  state = {
    clientHeight: '100%',
    clientWidth: '100%'
  }

  _handleResize = this._handleResize.bind(this)

  render() {
    return (
      <div className="mjson-designer-preview">
        <div { ...this._getPreview() }>
          <div { ...this._getFrame() }>
            <div className="mjson-designer-frame-inner">
              <div className="mjson-designer-frame-inner2">
                <div { ...this._getOrientation() }>
                  { this.props.children }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    elementResizeEvent(this.previewRef.current, this._handleResize)
    this._handleResize()
    this._handleScrollToHalfway()
  }

  _handleScrollToHalfway() {
    const { clientWidth, scrollWidth } = this.previewRef.current
    this.previewRef.current.scrollLeft = (scrollWidth - clientWidth) / 2
  }

  componentWillUnmount() {
    if(this.previewRef?.current) elementResizeEvent.unbind(this.previewRef.current)
  }

  _getDevice() {
    const { devices } = this.props
    return _.find(devices, { value: this.props.device })
  }

  _getDimensions() {
    const { orientation } = this.props
    const { clientWidth, clientHeight } = this.state
    const device = this._getDevice()
    const transforms = []
    if(orientation === 'landscape') transforms.push('rotate(-90deg)')
    return {
      minWidth: device.value === 'desktop' ? 980 : device.value === 'tablet' ? 768 : null,
      width: device.value !== 'desktop' ? device.width : orientation === 'landscape' ? clientHeight : clientWidth,
      height: device.value !== 'desktop' ? device.height : orientation === 'landscape' ? clientWidth : clientHeight
    }
  }

  _getFrame() {
    const { orientation } = this.props
    const device = this._getDevice()
    const scale = this._getScale()
    const offset = this._getOffset()
    const transforms = ['translate(-50%,-50%)']
    if(orientation === 'landscape') transforms.push('rotate(90deg)')
    if(scale < 1) transforms.push(`scale(${scale})`)
    const { minWidth, width, height } = this._getDimensions()
    return {
      className: 'mjson-designer-frame',
      style: {
        ...device.value !== 'desktop' ? {
          transform: transforms.join(' '),
          top: '50%',
          left: '50%'  
        } : {
          minWidth,
          top: 0,
          left: 0,
          bottom: 0
        },
        width: width + offset.width,
        height: height + offset.height
      }
    }
  }

  _getOffset() {
    const { device } = this.props
    return {
      width: device === 'desktop' ? 0 : 32,
      height: device === 'desktop' ? 0 : 120
    }
  }

  _getOrientation() {
    const { orientation } = this.props
    const transforms = []
    if(orientation === 'landscape') transforms.push('rotate(-90deg)')
    const { minWidth, width, height } = this._getDimensions()
    return {
      className: 'mjson-designer-orientation',
      style: {
        transform: transforms.join(' '),
        top: orientation === 'landscape' ? (height - width) / 2 : 0,
        left: orientation === 'landscape' ? (width - height) / 2 : 0,
        minWidth,
        width: orientation === 'landscape' ? height : width,
        height: orientation === 'landscape' ? width : height
      }
    }
  }

  _getPreview() {
    const device = this._getDevice()
    return {
      className: `mjson-designer-preview-inner ${device.value}`,
      ref: this.previewRef
    }
  }

  _getScale() {
    const { orientation } = this.props
    const device = this._getDevice()
    const offset = this._getOffset()
    const { clientWidth, clientHeight } = this.state
    if(!this.previewRef.current || device.value === 'desktop' || clientWidth === '100%') return 1
    const actual = orientation === 'portrait' ? clientHeight : clientWidth
    const target = orientation === 'portrait' ? device.height + offset.height : device.width + offset.width
    if(target <= actual) return 1
    return (actual - 20) / target
  }

  _handleResize() {
    const { clientHeight, clientWidth } = this.previewRef.current
    this.setState({
      clientWidth,
      clientHeight
    })
  }

}

export default Preview
