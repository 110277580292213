import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    provider: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    signup_page: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { provider } = this.context
    const { signup_page } = this.props
    return {
      title: 't(Edit Signup Page)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/signup_pages/${signup_page.id}/edit`,
      action: `/api/admin/truevail/admin/signup_pages/${signup_page.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Owner)', name: 'owner_id', type: 'lookup', endpoint: '/api/admin/truevail/admin/agencies', valueKey: 'team.id', textKey: 'team.title', required: true },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/signup', required: true, host: provider.admin_host, placeholder: '/path/to/signup' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
