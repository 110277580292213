import ContactToken from '@apps/crm/admin/tokens/contact'
import Search from '@admin/components/search'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Contact extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    onChoose: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleNew = this._handleNew.bind(this)

  render() {
    return (
      <div className="crm-contact-chooser">
        <div className="crm-contact-chooser-search">
          <Search { ...this._getSearch() } />
        </div>
        <div className="crm-contact-chooser-button">
          <Button { ...this._getNew() } />
        </div>
      </div>
    )
  }

  _getNew() {
    return {
      label: 'Create New Contact',
      color: 'blue',
      handler: this._handleNew
    }
  }

  _getSearch() {
    return {
      autofocus: true,
      endpoint: '/api/admin/crm/contacts',
      format: ContactToken,
      label: 'contact',
      onChange: this._handleChoose,
      valueKey: null
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChoose(contact) {
    this.props.onChoose(contact)
  }

  _handleCreate(contact) {
    this.context.form.pop()
    setTimeout(() => {
      this.props.onChoose(contact)
    }, 250)
  }

  _handleNew() {
    const props = {
      onBack: this._handleBack,
      onNext: this._handleCreate
    }
    this.context.form.push(<New { ...props } />)
  }

}

export default Contact
