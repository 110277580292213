import AssetViewer from '@admin/components/asset_viewer'
import Attachments from '@admin/components/attachments'
import Button from '@admin/components/button'
import Microphone from './microphone'
import PropTypes from 'prop-types'
import Record from './record'
import React from 'react'

class Recordingfield extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    asset: PropTypes.object,
    code: PropTypes.string,
    defaultValue: PropTypes.number,
    number: PropTypes.string,
    status: PropTypes.string,
    onCall: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onReady: PropTypes.func,
    onRecord: PropTypes.func,
    onSetAsset: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    const { asset } = this.props
    return (
      <div className="crm-recordingfield">
        { asset ?
          <div className="crm-recordingfield-recording">
            <div className="crm-recordingfield-recording-asset">
              <AssetViewer asset={ asset } />
            </div>
            <Button { ...this._getClear() } />
          </div> :
          <Button { ...this._getChooseButton() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this._handleFetch(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps) {
    const { asset } = this.props
    if(asset !== prevProps.asset && asset) {
      this._handleChange()
    }
  }

  _getAttachments() {
    return {
      allow: {
        types: ['files'],
        content_types: ['audio']
      },
      custom: [
        { icon: 'microphone', service: 'microphone', label: 't(Microphone)', panel: Microphone },
        { icon: 'phone', service: 'phone', label: 't(Phone Call)', panel: Record }
      ],
      multiple: false,
      title: 't(Choose Audio Source)',
      onBack: this._handleBack,
      onDone: this._handleDone
    }
  }

  _getChooseButton() {
    return {
      label: 't(Choose or record an audio file)',
      color: 'black',
      handler: this._handleChoose
    }
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange() {
    const { asset } = this.props
    this.props.onChange(asset.id)
  }

  _handleChoose() {
    this.context.form.push(<Attachments { ...this._getAttachments() } />)
  }

  _handleClear() {
    this.props.onClear()
  }

  _handleDone(assets) {
    const asset = _.castArray(assets)[0]
    this.context.form.pop()
    this.props.onSetAsset(asset)
  }

  _handleFetch(id) {
    this.context.network.request({
      endpoint: `/api/admin/assets/${id}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.props.onSetAsset(data)
      },
      onFailure: () => {}
    })
  }

}

export default Recordingfield
