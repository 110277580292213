import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.sms_campaign.title,
  manager: {
    path: `/admin/campaigns/sms/${resources.sms_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview actions={ resources.actions } sms_campaign={ resources.sms_campaign } enrollment={ resources.enrollment } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  actions: `/api/admin/campaigns/sms/${props.params.sms_campaign_id}/enrollments/${props.params.id}/actions`,
  sms_campaign: `/api/admin/campaigns/sms/${props.params.sms_campaign_id}`,
  enrollment: `/api/admin/campaigns/sms/${props.params.sms_campaign_id}/enrollments/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
