import PaginationStyleField from '@admin/components/mjson_designer/components/paginationstylefield'
import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'

export const SliderSection = (config, device, theme, type, defaults = {}) => ({
  label: 't(Slider)',
  instructions: 't(Specify styles for this slider)',
  include: config.content.display === 'slider',
  collapsing: true,
  collapsed: true,
  fields: [
    { label: 't(Prev/Next Arrows)', name: 'styles.arrowIcon', collapsing: true, collapsed: true, type: IconStyleField, device, theme, canHover: false, defaultValue: defaults.arrowIcon || {
      size: '25px',
      color: { value: '#000000' }
    }, value: config.styles.arrowIcon },
    { label: 't(Pagination Buttons)', name: 'styles.pagination', collapsing: true, collapsed: true, type: PaginationStyleField, device, theme, canHover: false, defaultValue: defaults.pagination || {
      size: '5px',
      radius: {
        type: 'circular'
      },
      color: { value: '#BFBFBF' },
      activeColor: { value: '#000000' }
    }, value: config.styles.pagination }
  ]
})
