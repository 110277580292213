import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  draft: {
    title: 't(Dont wrap, draft)',
    text: 't(Publish any campaigns that do not fit into the selected days as drafts)'
  },
  discard: {
    title: 't(Dont wrap, discard)',
    text: 't(Discard any campaigns that do not fit into the selected days)'
  },
  wrap: {
    title: 't(Wrap)',
    text: 't(If there are more campaigns than selected days, wrap around to the beginning and continue scheduling)'
  }
}

const SchedulingOverflowToken = ({ value }) => (
  <div className="maha-token">
    <div className="maha-token-details">
      <strong><T text={ strategies[value].title } /></strong><br />
      <span><T text={ strategies[value].text } /></span>
    </div>
  </div>
)

SchedulingOverflowToken.propTypes = {
  value: PropTypes.string
}

export default SchedulingOverflowToken
