import VerificationToken from '@apps/crm/admin/tokens/verification'
import DNSTable from '@admin/components/dnstable'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from '../edit'
import React from 'react'

const Overview = ({ sender }, { admin, flash }) => {

  const program = {
    label: sender.program.title,
    className: 'link',
    route: `/admin/team/programs/${sender.program.id}`
  }

  const dnstable = sender.sender_domain ? {
    instructions: `t(In order to maintain a positive sending reputation and ensure that your emails don't end up in a SPAM filter, you must add and maintain the following DNS records for the domain) ${sender.sender_domain.name}:`,
    is_hosted: sender.sender_domain.is_hosted,
    records: sender.sender_domain.records,
    last_verified_at: sender.sender_domain.last_verified_at,
    check: {
      request: {
        endpoint: `/api/admin/team/senders/${sender.id}/verify`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to verify dns records)')
      }
    },
    publish: {
      request: {
        endpoint: `/api/admin/team/senders/${sender.id}/records`,
        method: 'PATCH',
        onFailure: () => flash.set('error', 't(Unable to publish dns records)')
      }
    }
  } : null

  const details = {
    audits: `crm_senders/${sender.id}`,
    comments: `crm_senders/${sender.id}`,
    sections: [
      {
        title: 't(Sender Details)',
        items: [
          { label: 't(From Name)', content: sender.name },
          ...sender.type === 'custom' ? [
            { label: 't(From Email)', content: <VerificationToken sender={ sender }  /> },
            { label: 't(Program)', show: admin.team.has_programs , content: <Button { ...program } /> }
          ] : [
            { label: 't(From Name)', content: sender.name },
            { label: 't(From Email)', content: sender.email },
            { label: 't(Program)', show: admin.team.has_programs , content: <Button { ...program } /> },
            { label: 't(On Behalf Of)', content: sender.on_behalf_of ? <VerificationToken sender={ sender } /> : null },
            { label: 't(Reply To)', content: sender.reply_to }  
          ]
        ]
      },
      ...sender.type === 'custom' ? [
        {
          subtitle: 't(Authentication)',
          items: [{ 
            padded: true, 
            component: <DNSTable { ...dnstable } /> 
          }]
        }
      ] : []
    ]
  }

  if(sender.deleted_at) {
    details.alert = { color: 'red', message: 't(This sender has been deleted)' }
  } else if(sender.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This sender has not yet been verified!)' }
  } else if(sender.status === 'unverified') {
    details.alert = { color: 'red', message: 't(Something has changed with your DNS settings and this domain is no longer verified. Please check that the DNS record below still exist)' }
  } else if(sender.status === 'verified') {
    details.alert = { color: 'green', message: 't(This sender has been verified and can send email)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object,
  flash: PropTypes.object
}

Overview.propTypes = {
  sender: PropTypes.object,
  program: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview sender={ props.sender } />,
  tasks: {
    items: [
      { 
        label: 'Edit Sender', 
        access: { 
          programs: { $canEdit: props.sender.program.id },
          rights: { $oneOf: ['team:manage_senders'] } 
        },
        modal: <Edit sender={ props.sender } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
