import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import React from 'react'

class ContactToken extends React.PureComponent {

  static propTypes = {
    contact: PropTypes.object
  }

  render() {
    const { contact } = this.props
    return (
      <>
        <strong>{ this._getName() }</strong>
        { contact.email &&
          <div>{ contact.email }</div>
        }
        { contact.phone &&
          <div>{ this._getFormatted(contact.phone) }</div>
        }
        { contact.address &&
          <div>{ contact.address.description }</div>
        }
      </>
    )
  }

  _getFormatted(number) {
    const parsed = parsePhoneNumberFromString(number)
    return parsed.formatNational()
  }

  _getName() {
    const { contact } = this.props
    return [contact.first_name, contact.last_name].join(' ')
  }

}

export default ContactToken
