import PropTypes from 'prop-types'
import React from 'react'

const TypographyToken = ({ value }) => (
  <div className="token">
    { value.name }
  </div>
)

TypographyToken.propTypes = {
  value: PropTypes.object
}

export default TypographyToken
