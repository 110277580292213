import PropTypes from 'prop-types'
import React from 'react'

class Country extends React.Component {

  static propTypes = {
    country: PropTypes.object,
    onChoose: PropTypes.func
  }

  _handleClick = this._handleClick.bind(this)

  render() {
    const { country } = this.props
    return (
      <div className="maha-phonefield-country" onClick={ this._handleClick }>
        <div className="maha-phonefield-country-flag">
          <i className={`emoji emoji-${country.emoji}`} />
        </div>
        <div className="maha-phonefield-country-label">
          { country.name } <span className="maha-phonefield-country-code">+{ country.dialCode }</span>
        </div>
      </div>
    )
  }

  _handleClick() {
    const { country } = this.props
    this.props.onChoose(country.code)
  }

}

export default Country
