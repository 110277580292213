import { useProviderContext } from '@admin/components/provider'
import { useNetworkContext } from '@admin/components/network'
import { useRouterContext } from '@admin/components/router'
import { useAdminContext } from '@admin/components/admin'
import { useFlashContext } from '@admin/components/flash'
import { useHostContext } from '@admin/components/host'
import PropTypes from 'prop-types'
import Signin from './signin'
import React from 'react'

const SigninContainer = (props) => {

  const { admin } = useAdminContext()
  const { flash } = useFlashContext()
  const { host } = useHostContext()
  const { network } = useNetworkContext()
  const { provider } = useProviderContext()
  const { router } = useRouterContext()

  const mergedProps = {
    context: {
      admin,
      flash,
      host,
      network,
      provider,
      router
    },
    ...props
  }

  return <Signin { ...mergedProps } />

}

SigninContainer.propTypes = {}

export default SigninContainer
