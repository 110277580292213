export const alignments = [
  { value: 'left', text: 't(Left)' },
  { value: 'center', text: 't(Center)' },
  { value: 'right', text: 't(Right)' }
]

export const positions = [
  { value: 'top', text: 't(Top)' },
  { value: 'right', text: 't(Right)' },
  { value: 'bottom', text: 't(Bottom)' },
  { value: 'left', text: 't(Left)' }
]

export const block_types = [
  { text: 't(Heading 1)', value: 'h1' },
  { text: 't(Heading 2)', value: 'h2' },
  { text: 't(Text)', value: 'p' }
]

export const columns = [
  { value: 1, text: 't(1 column)' },
  { value: 2, text: 't(2 columns)' }
]

export const displays = [
  { value: 'block', text: 't(Full Width)' },
  { value: 'inline', text: 't(Fit to Text)' }
]

export const fonts = [
  { value: 'Arial, Helvetica, sans-serif', text: 't(Arial)' },
  { value: '"Comic Sans MS", cursive, sans-serif', text: 't(Comic Sans)' },
  { value: '"Courier New", Courier, monospace', text: 't(Courier New)' },
  { value: 'Georgia, serif', text: 't(Georgia)' },
  { value: '"Helvetica Neue", Helvetica, Arial, Verdana, sans-serif', text: 't(Helvetica)' },
  { value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif', text: 't(Lucida)' },
  { value: 'Tahoma, Geneva, sans-serif', text: 't(Tahoma)' },
  { value: '"Times New Roman", Times, serif', text: 't(Times New Roman)' },
  { value: '"Trebuchet MS", Helvetica, sans-serif', text: 't(Trebuchet MS)' },
  { value: 'Verdana, Geneva, sans-serif', text: 't(Verdana)' }
]

export const link_strategies = [
  { value: 'web', text: 't(Web Address)' },
  { value: 'email', text: 't(Email Address)' },
  { value: 'asset', text: 't(File)' }
]

export const line_heights = [
  { value: 1, text: 't(Normal)' },
  { value: 1.3, text: 't(Slight)' },
  { value: 1.5, text: 't(1 1/2 spacing)' },
  { value: 2, text: 't(Double space)' }
]

export const letter_spacing = [-5,-4,-3,-2,-1,0,1,2,3,4,5].map(value => ({
  value,
  text: `${value}px`
}))

export const splits = [
  { value: [6,6], text: 't(Half)' },
  { value: [4,8], text: 't(One Third, Two Thirds)' },
  { value: [8,4], text: 't(Two Thirds, One Third)' }
]

export const border_styles = [
  { value: null, text: 't(None)' },
  { value: 'solid', text: 't(Solid)' },
  { value: 'dashed', text: 't(Dashed)' },
  { value: 'dotted', text: 't(Dotted)' },
  { value: 'double', text: 't(Double)' },
  { value: 'groove', text: 't(Groove)' },
  { value: 'ridge', text: 't(Ridge)' },
  { value: 'inset', text: 't(Inset)' },
  { value: 'outset', text: 't(Outset)' }
]

export const border_widths = Array(20).fill(0).map((i,j) => ({
  value: j + 1,
  text: `${j + 1}px`
}))

export const paddings = [
  { value: 0, text: 't(None)' },
  ...Array(20).fill(0).map((i,j) => ({
    value: j + 1,
    text: `${j + 1}px`
  }))
]

export const font_families = [
  { value: 'Arial, Helvetica, sans-serif', text: 't(Arial)' },
  { value: '"Comic Sans MS", cursive, sans-serif', text: 't(Comic Sans)' },
  { value: '"Courier New", Courier, monospace', text: 't(Courier New)' },
  { value: 'Georgia, serif', text: 't(Georgia)' },
  { value: '"Helvetica Neue", Helvetica, Arial, Verdana, sans-serif', text: 't(Helvetica)' },
  { value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif', text: 't(Lucida)' },
  { value: 'Tahoma, Geneva, sans-serif', text: 't(Tahoma)' },
  { value: '"Times New Roman", Times, serif', text: 't(Times New Roman)' },
  { value: '"Trebuchet MS", Helvetica, sans-serif', text: 't(Trebuchet MS)' },
  { value: 'Verdana, Geneva, sans-serif', text: 't(Verdana)' }
]

export const font_sizes = [8,9,10,11,12,14,16,18,24,30,36,48,60,72,96,108].map(value => ({
  value,
  text: `${value}px`
}))

export const image_positions = [
  { value: 'top', text: 't(Top)' },
  { value: 'bottom', text: 't(Bottom)' },
  { value: 'left', text: 't(Left)' },
  { value: 'right', text: 't(Right)' }
]

export const image_widths = [
  { value: 3, text: 't(One-quarter)' },
  { value: 4, text: 't(One-third)' },
  { value: 6, text: 't(One-half)' },
  { value: 8, text: 't(Two-Thirds)' },
  { value: 9, text: 't(Three-quarters)' }
]

export const form_widths = [
  { value: '100%', text: 't(Full)' },
  { value: '770px', text: 't(Fixed)' }
]

export const cover_widths = [
  { value: 1, text: 't(One-half)' },
  { value: 2, text: 't(Two-Thirds)' }
]

export const cover_positions = [
  { value: 0, text: 't(Left)' },
  { value: 2, text: 't(Right)' }
]

export const cover_justifications = [
  { value: 'left', text: 't(Left)' },
  { value: 'center', text: 't(Center)' },
  { value: 'right', text: 't(Right)' }
]

export const strategies = [
  { value: 'message', text: 't(Show Message)' },
  { value: 'redirect', text: 't(Redirect to URL)' }
]
