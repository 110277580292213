import AssetPreview from '@admin/components/asset_preview'
import Page from '@admin/components/page'
import PropTypes from 'prop-types'
import React from 'react'

const Asset = ({ asset }) => (
  <AssetPreview asset={ asset } comments={ true } />
)

Asset.propTypes = {
  asset: PropTypes.object
}

const mapResourcesToPage = (props, context) => ({
  asset: `/api/admin/platform/assets/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Asset Viewer)',
  component: Asset
})

export default Page(mapResourcesToPage, mapPropsToPage)
