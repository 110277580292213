import Button from '@admin/components/button'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

const Overview = ({ refund }) => {

  const contact = {
    className: 'link',
    label: refund.payment.customer.display_name,
    route: `/admin/crm/contacts/${refund.payment.customer.id}`
  }

  const payment = {
    className: 'link',
    label: refund.payment.reference,
    route: `/admin/finance/payments/${refund.payment.id}`
  }

  const items = [
    { label: 't(Date)', content: moment(refund.created_at).format('MM/DD/YYYY') },
    { label: 't(Contact)', content: <Button { ...contact } /> },
    { label: 't(Payment)', content: <Button { ...payment } /> },
    { label: 't(Type)', content: refund.type }
  ]

  if(refund.credit) {

    const credit = {
      className: 'link',
      label: `${refund.credit.id} (${refund.credit.description})`,
      route: `/admin/crm/contacts/${refund.payment.customer.id}/credits/${refund.credit.id}`
    }

    items.push({ label: 't(Credit)', content: <Button { ...credit } /> })

  }

  items.push({ label: 't(Amount)', content: numeral(refund.amount).format('$0.00') })

  if(refund.type === 'card' && refund.braintree_id) {

    const braintree = {
      className: 'link',
      label: refund.braintree_id,
      link: refund.braintree_link
    }

    items.push({ label: 't(Braintree ID)', content: <Button { ...braintree } /> })

  }

  if(refund.type === 'card' && refund.stripe_id) {

    const stripe = {
      className: 'link',
      label: refund.stripe_id,
      link: refund.stripe_link
    }

    items.push({ label: 't(Stripe ID)', content: <Button { ...stripe } /> })

  }

  const details = {
    audits: `finance_refunds/${refund.id}`,
    comments: `finance_refunds/${refund.id}`,
    sections: [
      {
        title: 't(Refund Details)', 
        items 
      }
    ]
  }

  if(refund.status === 'deposited') {

    const deposit = {
      className: 'link',
      label: moment(refund.deposit.date).format('MM/DD/YYYY'),
      route: `/admin/finance/deposits/${refund.deposit.id}`
    }

    details.sections.push({
      title: 't(Withdrawn)',
      items: [
        { label: 't(Bank Account)', content: refund.deposit.bank.title },
        { label: 't(Date)', content: <Button { ...deposit } /> }
      ]
    })

  }

  if(refund.voided_date) {
    details.sections.push({
      title: 't(Voided)',
      items: [
        { label: 't(Date)', content: moment(refund.voided_date).format('MM/DD/YYYY') },
        { label: 't(Reason)', content: refund.voided_reason }
      ]
    })
  }

  if(refund.voided_date !== null) {
    details.alert = { color: 'red', message: 't(This refund was voided)' }
  } else if(refund.status === 'settled') {
    details.alert = { color: 'blue', message: 't(This refund has been settled)' }
  } else if(refund.status === 'deposited') {
    details.alert = { color: 'violet', message: 't(This refund has been deposited)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  refund: PropTypes.object
}

export default Overview
