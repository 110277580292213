import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Identity extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    configuration: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      config: {
        additional: {},
        ...configuration
      }
    })
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Additonal Information)',
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          disabled: true 
        },
        { 
          label: 't(Done)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Notes)',
              instructions: 't(Provide any additional details or preferences that you would like the team to consider when setting up and managing your services)',
              name: 'additional_notes',
              type: 'textarea',
              defaultValue: config.additional_notes,
              placeholder: 't(Enter any additional information you this we should know about you or your business)' 
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onDone(config)
  }

}

export default Identity
