import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Transfer from './transfer'
import Register from './register'
import Contacts from './contacts'
import Details from './details'
import React from 'react'
import Type from './type'
import DNS from './dns'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(Add a Domain)',
      action: '/api/admin/domains/domains',
      method: 'POST',
      formatData: this._getData,
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(domain) {
    return domain
  }

  _getDetails(type) {
    if(type === 'registration') {
      return { label: 't(Name)', component: Register }
    } else if(type === 'transfer') {
      return { label: 't(Name)', component: Transfer }
    } else {
      return { label: 't(Name)', component: DNS }
    }
  }

  _getSteps(formdata) {
    const data = formdata || {}
    return [
      { label: 't(Type)', component: Type },
      this._getDetails(data.type),
      ...data.type !== 'dns' ? [
        { label: 't(Contacts)', component: Contacts },
        { label: 't(Details)', component: Details }
      ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(domain) {
    this.context.router.push(`/domains/${domain.id}`)
    this.context.modal.close()
  }

}

export default New
