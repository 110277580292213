import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import Container from '@admin/components/container'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class PaymentMethod extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    token: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { token } = this.props
    return {
      title: 't(Change Payment Method)',
      method: 'PATCH',
      action: '/api/admin/team/subscription/payment_method',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Credit Card)', name: 'payment', type: StripeCardField, stripeAccount: token.stripe_id }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  token: '/api/admin/team/subscription/token'
})

export default Container(mapResources)(PaymentMethod)
