import PropTypes from 'prop-types'
import React from 'react'

const StatusToken = ({ status }) => {
  return <div className={`ticket-status-token ${status}`}>{ status }</div>
}

StatusToken.propTypes = {
  value: PropTypes.string
}

export default StatusToken
