import ModalPanel from '@admin/components/modal_panel'
import Icon from '@admin/components/icon'
import List from '@admin/components/list'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Methods extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object,
    methods: PropTypes.array,
    onChooseMethod: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    const { methods } = this.props
    if(!methods) return null
    return (
      <ModalPanel { ...this._getPanel() }>
        <List { ...this._getList() }/>
      </ModalPanel>
    )
  }

  _getList() {
    const { methods } = this.props
    return {
      className: '',
      items: methods.map((method, index) => ({
        ...method,
        index
      })),
      format: (method) => (
        <div className="drive-share-methods-item">
          <div className="drive-share-methods-item-icon">
            <Icon icon={ method.icon } />
          </div>
          <div className="drive-share-methods-item-text">
            <T text={ method.label } />
          </div>
        </div>
      ),
      onClick: this._handleChoose
    }
  }

  _getPanel() {
    return {
      title: 't(Share Item)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChoose(item) {
    this.props.onChooseMethod(item.index)
  }

}

export default Methods
