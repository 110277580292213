import StatusToken from '@admin/tokens/status'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ agreement }, { admin }) => {

  const program = {
    label: agreement.program.title,
    className: 'link',
    route: `/admin/team/programs/${agreement.program.id}`
  }

  const contact = {
    label: agreement.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${agreement.contact.id}`
  }

  const details = {
    audits: `crm_agreements/${agreement.id}`,
    comments: `crm_agreements/${agreement.id}`,
    items: [
      { label: 'File Name', content: agreement.unsigned ? agreement.unsigned.file_name : agreement.signed.file_name },
      { label: 't(Program)', content: <Button { ...program } />  },
      { label: 't(Contact)', content: <Button { ...contact } />  }
    ]
  }

  if(agreement.profile && agreement.profile.account_id === admin.account.id) {
    const adobesign = {
      label: agreement.adobesign_agreement_id,
      className: 'link',
      link: agreement.adobesign_agreement_link
    }
    details.items.push({ label: 'Adobe Sign', content: <Button { ...adobesign } />  })
  }

  if(agreement.deal) {
    const deal = {
      label: agreement.deal.title,
      className: 'link',
      route: `/admin/sales/deals/${agreement.deal.id}`
    }
    details.items.push({ label: 'Deal', content: <Button { ...deal } />  })
  }

  if(agreement.signers.length > 0) {
    details.items.push({ label: 'Signers', content: (
      <div>
        { agreement.signers.map((signer, index) => (
          <div key={`signer-${index}`}>
            { signer.email} - <StatusToken value={ signer.status } />
          </div>
        ))}
      </div>
    ) })
  }

  if(agreement.unsigned) {
    details.items.push({ label: 'Unsigned Agreement', content: <AssetToken { ...agreement.unsigned } route={ agreement.unsigned.path } /> })
  }

  if(agreement.signed) {
    details.items.push({ label: 'Signed Agreement', content: <AssetToken { ...agreement.signed } route={ agreement.signed.path } /> })
  }

  if(agreement.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This agreement was canceled)' }
  } else if(agreement.status === 'inprogress') {
    details.alert = { color: 'teal', message: 't(This agreement is in progress)' }
  } else if(agreement.status === 'completed') {
    details.alert = { color: 'green', message: 't(This agreement was completed)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  agreement: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agreement={ props.agreement } />,
  tasks: {
    items: [
      {
        label: 'Cancel Agreement',
        show: props.agreement.status === 'inprogress',
        confirm: 't(Are you sure you want to cancel this agreement?)',
        request: {
          endpoint: `/api/admin/crm/agreements/${props.agreement.id}/cancel`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(The agreement was successfully canceled)'),
          onFailure: () => context.flash.set('error', 't(Unable to cancel the agreement)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
