import PropTypes from 'prop-types'
import React from 'react'

const RecordValueToken = ({ record }) => (
  <div className="token">
    { record.alias ? record.alias : record.values.map((value, index) => (
      <div key={`value_${index}`}>
        { record.type === 'TXT' ? `"${value}"` : value }
      </div>
    )) }
  </div>
)

RecordValueToken.propTypes = {
  record: PropTypes.object
}

export default RecordValueToken
