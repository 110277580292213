import AssetThumbnail from '@admin/components/asset_thumbnail'
import React from 'react'

const FileToken = (file) => (
  <div className="crm-file-token">
    <div className="crm-file-token-asset">
      <AssetThumbnail { ...file.asset } />
    </div>
    <div className="crm-file-token-details">
      { file.asset.file_name }<br />
      { file.note_id &&
        <span>uploaded with contact note</span>
      }
      { file.email_id &&
        <span>attached to contact email</span>
      }
      { file.response_id &&
        <span>uploaded in form response</span>
      }
      { file.deal_id &&
        <span>uploaded in deal</span>
      }
      { file.document_id && ! file.deal_id &&
        <span>manually uploaded</span>
      }
    </div>
  </div>
)

export default FileToken
