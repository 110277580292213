import Page from '@admin/components/page'
import Overview from './overview'
import Aliases from './aliases'
import Domains from './domains'
import Access from './access'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.website.title,
  manager: {
    path: `/admin/websites/${resources.website.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview website={ resources.website } />
      },
      {
        label: 't(Access)',
        path: '/access',
        panel: <Access website={ resources.website } />
      },
      {
        label: 't(Design)',
        show: resources.website.status === 'active',
        route: `/admin/websites/${resources.website.id}/design`
      },
      {
        label: 't(Domains)',
        path: '/domains',
        show: resources.website.status === 'active',
        panel: <Domains website={ resources.website } />
      },
      {
        label: 't(URLs)',
        path: '/urls',
        show: resources.website.status === 'active',
        panel: <Aliases website={ resources.website } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  website: `/api/admin/websites/websites/${props.params.website_id || props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
