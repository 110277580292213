import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ app }) => {

  const details = {
    audits: `analytics_apps/${app.id}`,
    comments: `analytics_apps/${app.id}`,
    items: [
      { label: 'Domain', content: app.domain }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  app: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview app={ props.app } />
})

export default Panel(null, mapPropsToPanel)
