import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const actions = {
  clicked: 't(clicked)',
  delivered: 't(delivered)',
  opened: 't(opened)'
}

class EmailInteractionsToken extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className="email-interactions-token">
        { email.was_bounced ? 
          <ul>
            <li className="bounced">
              <Icon icon="times-circle-o" /> <T text="t(bounced)" />
            </li>
          </ul> :
          <ul>
            { this._getAction(email, 'delivered') }
            { this._getAction(email, 'opened') }
            { this._getAction(email, 'clicked') }
          </ul>        
        }
      </div>
    )
  }

  _getAction(email, action) {
    const icon = email[`was_${action}`] ? 'check-circle-o' : 'times-circle-o'
    return (
      <li>
        <Icon icon={ icon } /> <T text={ actions[action] } />
      </li>
    )
  }

}

export default EmailInteractionsToken
