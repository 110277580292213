export const getRatioGroups = (original_ratio, min_ratio, max_ratio) => [
  { label: 'Custom Ratios', ratios: [
    { label: 'Original', ratio: original_ratio },
    { label: 'Square', ratio: 1 }
  ] },
  { label: 'Landscape Ratios', ratios: [
    { label: '16 x 9', ratio: 16/9 },
    { label: '10 x 8', ratio: 10/8 },
    { label: '7 x 5', ratio: 7/5 },
    { label: '4 x 3', ratio: 4/3 },
    { label: '5 x 3', ratio: 5/3 },
    { label: '3 x 2', ratio: 3/2 }
  ] },
  { label: 'Portrait Ratios', ratios: [
    { label: '9 x 16', ratio: 9/16 },
    { label: '8 x 10', ratio: 8/10 },
    { label: '5 x 7', ratio: 5/7 },
    { label: '3 x 4', ratio: 3/4 },
    { label: '3 x 5', ratio: 3/5 },
    { label: '2 x 3', ratio: 2/3 }
  ] }
].map(group => ({
  label: group.label,
  ratios: group.ratios.filter(ratio => {
    return (!max_ratio || ratio.ratio <= max_ratio) && (!min_ratio || ratio.ratio >= min_ratio)
  })
})).filter(group => {
  return group.ratios.length > 0
})

export const getRatios = (original_ratio, min_ratio, max_ratio) => {
  return getRatioGroups(original_ratio, min_ratio, max_ratio).reduce((ratios, group) => [
    ...ratios,
    ...group.ratios.map(ratio => ratio.ratio)
  ], [])
}