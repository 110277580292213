import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Chart from './timeseries'
import moment from 'moment'
import React from 'react'

class ReportChart extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    data: PropTypes.array,
    description: PropTypes.string,
    label: PropTypes.string,
    metric: PropTypes.number,
    options: PropTypes.object
  }

  canvasRef = React.createRef()
  chart = null

  render() {
    const { description, label, metric } = this.props
    return (
      <div className="crm-report-section-chart">
        <div className="crm-report-section-chart-header">
          <strong><T text={ label } />:</strong> { metric }<br />
          <T text={ description } />
        </div>
        <canvas ref={ this.canvasRef } />
      </div>
    )
  }

  componentDidMount() {
    this._handlePlot()
  }

  _handleInit() {
    this.chart = new Chart(this.canvasRef.current.getContext('2d'), {
      type: 'line',
      options: {
        maintainAspectRatio: true,
        aspectRatio: 4,
        responsive: true,
        animation: {
          duration: 0
        },
        elements: {
          line: {
            tension: 0.4
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: (ctx) => {
              let tooltipEl = document.getElementById('chartjs-tooltip')
              const datetime = moment(ctx.tooltip.dataPoints[0].parsed.x, 'x')
              const date = datetime.format('MMM D, YYYY')
              const time = datetime.format('hh:mm A')
              if(!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                document.body.appendChild(tooltipEl)
              }
              tooltipEl.innerHTML = `
                <div class="crm-report-section-chart-tooltip">
                  <div class="crm-report-section-chart-tooltip-details">
                    <div>
                      <strong>${ ctx.tooltip.dataPoints[0].parsed.y }</strong><br />
                      <span>${ ctx.tooltip.dataPoints[0].dataset.label }</span>
                    </div>
                  </div>
                  <div class="crm-report-section-chart-tooltip-date">
                    <div>
                      ${ time }<br />
                      ${ date }
                    </div>
                  </div>
                </div>
              `
              const tooltipModel = ctx.tooltip
              if(tooltipModel.opacity === 0) {
                return tooltipEl.style.opacity = 0
              }
              tooltipEl.classList.remove('above', 'below', 'no-transform')
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign)
              } else {
                tooltipEl.classList.add('no-transform')
              }
              const position = ctx.chart.canvas.getBoundingClientRect()
              tooltipEl.style.opacity = 1
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px'
              tooltipEl.style.top = position.top +  window.pageYOffset + tooltipModel.caretY - 25 + 'px'
              tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
              tooltipEl.style.pointerEvents = 'none'
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM D'
              }
            },
            ticks: {
              align: 'start'
            }
          },
          y: {
            ticks: {
              stepSize: 1,
              maxTicksLimit: 3
            },
            grid: {
              display: false
            }
          }
        }
      }
    })
  }

  _getMin(data) {
    const min = Math.min(...data.map(d => d.y))
    if(min < 10) return 0
    if(min < 100) return Math.floor(min / 10) * 10
    if(min < 1000) return Math.floor(min / 50) * 50
    return Math.floor(min / 500) * 500
  }

  _getMax(data) {
    const max = Math.max(...data.map(d => d.y))
    if(max < 10) return max + 2
    if(max < 100) return Math.ceil(max / 10) * 10
    if(max < 1000) return Math.ceil(max / 50) * 50
    return Math.ceil(max / 500) * 500
  }

  _handlePlot() {
    const { t } = this.context.locale
    const { data, label, options } = this.props
    if(!this.chart) this._handleInit()
    if(options.x_min) this.chart.options.scales.x.min = options.x_min
    if(options.x_max) this.chart.options.scales.x.max = options.x_max
    if(options.x_ticks) this.chart.options.scales.x.ticks.maxTicksLimit = options.x_ticks
    const min = this._getMin(data)
    const max = this._getMax(data)
    const diff = max - min
    this.chart.options.scales.y.min = min
    this.chart.options.scales.y.max = max
    this.chart.options.scales.y.ticks.maxTicksLimit = diff % 2 === 0 ? 3 : 4
    this.chart.data.datasets = [{
      label: t(label),
      data: data,
      borderColor: '#2185D0',
      backgroundColor: '#2185D022',
      fill: true,
      pointBackgroundColor: '#FFFFFF',
      pointRadius: 1,
      pointHoverBackgroundColor: '#2185D0',
      pointHoverRadius: 3,
      borderWidth: 3
    }]
    this.chart.update()
  }

}

export default ReportChart
