import AutomationWorkflows from './automation/workflows'
import AutomationEmails from './automation/emails'
import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.property.name.value,
  access: { 
    programs: { $canView: resources.property.parent.id },
    rights: { $oneOf: ['team:view_properties','team:manage_properties'] } 
  },
  manager: {
    path: `/admin/team/properties/${resources.property.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview property={ resources.property } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <AutomationWorkflows property={ resources.property } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <AutomationEmails property={ resources.property } />
          }
        ]
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  property: `/api/admin/team/properties/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
