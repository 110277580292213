import AccessTypeToken from '@apps/drive/admin/tokens/access_type'
import Assignment from '@admin/components/assignment'
import AssigneeToken from '@admin/tokens/assignee'
import PropTypes from 'prop-types'
import React from 'react'

class Access extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    item: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Assignment {...this._getAssignment()} />
  }

  _getAssignment() {
    const { item } = this.props
    return {
      title: 't(Manage Access)',
      action: `/api/admin/drive/items/${item.code}/access`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        access: assigned.map(assignee => ({
          grouping_id: assignee.grouping_id,
          user_id: assignee.user_id,
          access_type_id: !assignee.is_revoked ? assignee.access_type_id : null
        }))
      }),
      assignedEndpoint: `/api/admin/drive/items/${item.code}/access`,
      assignedFilter: (assigned) => {
        return assigned.map(assignee => ({
          ...assignee.user,
          grouping_id: assignee.grouping_id,
          user_id: assignee.user.id,
          access_type_id: assignee.access_type_id
        }))
      },
      assigneeFormat: (assignee) => <AssigneeToken { ...assignee } />,
      footer: (
        <>
          <strong>NOTE:</strong> Any changes you make to the access for this folder will be applied to the files and folders beneath it
        </>
      ),
      unassignedEndpoint: {
        endpoint: '/api/admin/assignees',
        query: {
          $filter: {
            is_active: {
              $eq: true
            }
          }
        }
      },
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.full_name.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          if(assignment.grouping_id && assignment.grouping_id === assignee.grouping_id) return true
          if(assignment.user_id && assignment.user_id === assignee.user_id) return true
          return false
        }) === undefined
      },
      revokeable: true,
      types: [
        { value: 3, text: 't(Can View)' },
        { value: 2, text: 't(Can Edit)' },
        { value: 1, text: 't(Owner)' }
      ],
      typeFormat: AccessTypeToken,
      typeKey: 'access_type_id',
      onCancel: this._handleCancel,
      onDone: this._handleSuccess
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Access
