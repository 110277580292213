import Page from '@admin/components/page'
import itemButtons from '../item_buttons'
import itemTasks from '../item_tasks'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  trip: `/api/admin/finance/trips/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Mileage)',
  rights: ['finance:manage_expenses'],
  panel: {
    component: <Overview trip={ resources.trip } />
  },
  tasks: itemTasks('trip', resources.trip, context, Edit),
  buttons: itemButtons(resources.app.settings, 'trip', resources.trip, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
