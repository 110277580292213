import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Unassigned extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    unassigned_endpoint: PropTypes.string,
    onAdd: PropTypes.func
  }

  _handleAdd = this._handleAdd.bind(this)

  render() {
    return (
      <div className="truevail-social-batch-campaigns-unassigned">
        <div className="truevail-social-batch-campaigns-unassigned-body">
          <Search { ...this._getSearch() } />
        </div>
      </div>
    )    
  }

  _getSearch() {
    const { assigned, unassigned_endpoint } = this.props
    return {
      endpoint: unassigned_endpoint,
      entity: 't(Social Campaign)',
      layout: Results,
      props: {
        assigned,
        onChoose: this._handleAdd
      }
    }
  }

  _handleAdd(social_campaign) {
    this.props.onAdd(social_campaign)
  }

}

export default Unassigned
