import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const CompactCampaignToken = ({ voice_campaign }) => (
  <div className="campaign-token">
    <div className="campaign-token-logo">
      <Logo team={ voice_campaign.program } width="24" />
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { voice_campaign.title || voice_campaign.term || voice_campaign.phone_number.formatted }
      </div>
      <div className="token-stats">
        <span className={ voice_campaign.status }>
          { voice_campaign.status }
        </span>
        { voice_campaign.status === 'sent' &&
          <>
            <span>{ voice_campaign.calls_count } calls</span>
            <span>{ voice_campaign.active_count } active</span>
            <span>{ voice_campaign.lost_count } lost</span>
            <span>{ voice_campaign.converted_count } converted</span>
            <span>{ voice_campaign.completed_count } completed</span>
          </>
        }
      </div>
    </div>
  </div>
)

CompactCampaignToken.propTypes = {
  voice_campaign: PropTypes.object
}

export default CompactCampaignToken
