import Page from '@admin/components/page'
import itemButtons from '../item_buttons'
import itemTasks from '../item_tasks'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  advance: `/api/admin/finance/advances/${props.params.id}`
})

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Cash Advance)',
  access: { rights: { $oneOf: ['finance:manage_expenses','finance:view_expenses'] } },
  panel: {
    component: <Overview advance={ resources.advance } />
  },
  tasks: itemTasks('advance', resources.advance, context, Edit),
  buttons: itemButtons(resources.app.settings, 'advance', resources.advance, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
