import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import moment from 'moment'
import React from 'react'

const TicketTypeToken = (ticket_type) => (
  <div className={`tickettype-token${!ticket_type.is_active ? ' inactive' : ''}`}>
    <div className="token-link">
      { ticket_type.name } { !ticket_type.is_active &&
        <span className="error">
          INACTIVE
        </span>
      }
    </div>
    <div className="token-stats">
      { ticket_type.price_type === 'fixed' &&
        <span className="active">
          { numeral(ticket_type.fixed_price).format('$0.00') }
        </span>
      }
      { ticket_type.price_type === 'sliding_scale' &&
        <span className="active">
          { numeral(ticket_type.low_price).format('$0.00') } - { numeral(ticket_type.high_price).format('$0.00') }
        </span>
      }
      { ticket_type.price_type === 'free' &&
        <span className="active">
          FREE
        </span>
      }
      <span>
        { ticket_type.total_tickets ?
          pluralize('ticket', ticket_type.total_tickets, true) :
          'Unlimited tickets'
        } { ticket_type.max_per_order &&
          <>(max {ticket_type.max_per_order} per order)</>
        }
      </span>
      { (ticket_type.sales_open_at && !ticket_type.sales_close_at) &&
        <span>
          Available starting { moment(`${ticket_type.sales_open_at}`).format('MMM D, h:mmA') }
        </span>
      }
      { (!ticket_type.sales_open_at && ticket_type.sales_close_at) &&
        <span>
          Available until { moment(`${ticket_type.sales_close_at}`).format('MMM D, h:mmA') }
        </span>
      }
      { (ticket_type.sales_open_at && ticket_type.sales_close_at) &&
        <span>
          Available from { moment(`${ticket_type.sales_open_at}`).format('MMM D, h:mmA') } - { moment(`${ticket_type.sales_close_at}`).format('MMM D, h:mmA') }
        </span>
      }
      { (!ticket_type.sales_open_at && !ticket_type.sales_close_at) &&
        <span>
          Available indefinitely
        </span>
      }
    </div>
  </div>
)

TicketTypeToken.propTypes = {
  ticket_type: PropTypes.object
}

export default TicketTypeToken
