import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Adjustment extends React.PureComponent {

  static propTypes = {
    adjustments: PropTypes.object,
    onSet: PropTypes.func
  }

  _handleChange = this._handleChange.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { adjustments } = this.props
    return {
      title: 't(Adjustments)',
      inline: true,
      onChange: this._handleChange,
      sections: [
        {
          fields: [
            { label: 't(Brightness)', name: 'bri', type: 'range', min: -100, max: 100, value: adjustments.bri, defaultValue: 0 },
            { label: 't(Contrast)', name: 'con', type: 'range', min: -100, max: 100, value: adjustments.con, defaultValue: 0 },
            { label: 't(Vibrance)', name: 'vib', type: 'range', min: -100, max: 100, value: adjustments.vib, defaultValue: 0 },
            { label: 't(Saturation)', name: 'sat', type: 'range', min: -100, max: 100, value: adjustments.sat, defaultValue: 0 },
            { label: 't(Sepia)', name: 'sep', type: 'range', min: 0, max: 100, value: adjustments.sep, defaultValue: 0 },
            { label: 't(Hue)', name: 'hue', type: 'range', min: 0, max: 100, value: adjustments.hue, defaultValue: 0 }
          ]
        }
      ]
    }
  }

  _handleChange(adjustments) {
    this.props.onSet({ adjustments })
  }

}

export default Adjustment
