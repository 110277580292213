import FilenameToken from '@apps/platform/admin/tokens/filename'
import SourceToken from '@apps/platform/admin/tokens/source'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'

const mapPropsToPanel = (props, context) => ({
  title: 't(Assets)',
  collection: {
    endpoint: '/api/admin/platform/assets',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Filename)', key: 'original_file_name', primary: true, format: FilenameToken },
      { label: 't(Status)', key: 'status', primary: true },
      { label: 't(Created)', key: 'created_at', primary: true, format: 'date' }
    ],
    criteria: {
      fields: [
        { label: 'item', fields: [
          { name: 't(Source)', key: 'source', type: 'select', endpoint: '/api/admin/profiles/sources', valueKey: 'text', textKey: 'text', format: SourceToken },
          { name: 't(Status)', key: 'status', type: 'select', options: ['chunked','assembled','processed'] }
        ] }
      ]
    },
    entity: 'asset',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/platform/assets/${record.id}`),
    recordTasks: (record) => [
      {
        label: 't(Reprocess Asset)',
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/platform/assets/${record.id}/reprocess`,
          onFailure: (result) => context.flash.set('error', 't(Unable to reprocess out this asset)'),
          onSuccess: (result) => context.flash.set('success', 't(The asset has been reprocessed)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
