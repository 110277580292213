import { useProviderContext } from '@admin/components/provider'
import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useRouterContext } from '@admin/components/router'
import { useAdminContext } from '@admin/components/admin'
import { useHostContext } from '@admin/components/host'
import Notifications from './notifications'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const NotificationsContainer = (props) => {

  const { provider } = useProviderContext()
  const { network } = useNetworkContext()
  const { router } = useRouterContext()
  const { admin } = useAdminContext()
  const { host } = useHostContext()

  const [state, handlers] = useActionReducer({
    display_name: 'notifications',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      admin,
      host,
      network,
      provider,
      router
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Notifications { ...mergedProps } />

}

NotificationsContainer.propTypes = {}

export default NotificationsContainer