import DateRangeField from '@apps/dashboards/admin/components/daterangefield'
import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Card extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  form = null

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { programs } = this.props.props
    const { config } = this.state
    const daterange_source = _.get(config, 'config.daterange_source')
    return {
      reference: node => this.form = node,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Finish)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...team.has_programs ? [
              { label: 'Scope', type: 'segment', fields: [
                { label: 't(Program)', name: 'config.program_id', type: 'lookup', endpoint: '/api/admin/programs', valueKey: 'id', textKey: 'title', format: ProgramToken, required: true }
              ] }
            ] : [
              { name: 'config.program_id', type: 'hidden', value: programs[0].id }
            ],
            { label: 't(Date Range)', type: 'segment', fields: [
              { name: 'config.daterange_source', type: 'radiogroup', options: [
                { value: 'dashboard', text: 't(Use dashboard daterange)' },
                { value: 'custom', text: 't(Custom)' }
              ], defaultValue: 'dashboard', required: true },
              { name: 'config.daterange', type: DateRangeField, show: daterange_source === 'custom', required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.form.submit()
  }

  _handleSuccess(card) {
    this.props.onSave(card)
  }

}

export default Card
