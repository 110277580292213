import Button from '@admin/components/button'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import Stage from './stage'
import React from 'react'

class StagesField extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    stages: [
      { title: '', probability: null }
    ]
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleMove = this._handleMove.bind(this)

  render() {
    const { stages } = this.state
    return (
      <div className="stagesfield">
        <div className="stagesfield-stages">
          { stages.map((stage, index) => (
            <Stage { ...this._getStage(stage, index) } key={`stage_${index}`} />
          ))}
          <div className="stagesfield-stage system">
            <div className="stagesfield-stage-handle" />
            <div className="stagesfield-stage-label">
              <div className="stagesfield-stage-text">
                Won
              </div>
            </div>
            <div className="stagesfield-stage-probability">
              <div className="stagesfield-stage-text">
                100%
              </div>
            </div>
            <div className="stagesfield-stage-action" />
          </div>
          <div className="stagesfield-stage system">
            <div className="stagesfield-stage-handle" />
            <div className="stagesfield-stage-label">
              <div className="stagesfield-stage-text">
                Lost
              </div>
            </div>
            <div className="stagesfield-stage-probability">
              <div className="stagesfield-stage-text">
                0%
              </div>
            </div>
            <div className="stagesfield-stage-action" />
          </div>
        </div>
        <div className="stagesfield-stage-add">
          <Button { ...this._getAdd() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { stages } = this.state
    const { value } = this.props
    if(!_.isEqual(stages, prevState.stages)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    const { stages } = this.state
    return {
      label: stages.length > 0 ? '+ Add another stage' : '+ Add a stage',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getStage(stage, index) {
    const { tabIndex } = this.props
    return {
      index,
      stage,
      tabIndex: Number(tabIndex) + ((index + 1) / 10),
      onMove: this._handleMove,
      onRemove: this._handleRemove.bind(this, index),
      onUpdate: this._handleUpdate.bind(this, index)
    }
  }

  _handleAdd() {
    const { stages } = this.state
    const code = getCode(6)
    this.setState({
      stages: [
        ...stages,
        { code, title: '', probability: null, delta: stages.length }
      ]
    })
  }

  _handleChange() {
    const { stages } = this.state
    this.props.onChange(stages)
  }

  _handleMove(from, to) {
    const { stages } = this.state
    this.setState({
      stages: ((from < to) ? [
        ...stages.slice(0, from),
        ...stages.slice(from + 1, to + 1),
        stages[from],
        ...stages.slice(to + 1)
      ] : [
        ...stages.slice(0, to),
        stages[from],
        ...stages.slice(to, from),
        ...stages.slice(from + 1)
      ]).map((stage, delta) => ({
        ...stage,
        delta
      }))
    })
  }

  _handleRemove(index) {
    const { stages } = this.state
    this.setState({
      stages: stages.filter((stage, i) => {
        return i !== index
      })
    })
  }

  _handleSet(stages) {
    this.setState({ stages })
  }

  _handleUpdate(index, key, value) {
    const { stages } = this.state
    this.setState({
      stages: stages.map((stage, i) => {
        if(i !== index) return stage
        return {
          ...stage,
          [key]: value
        }
      })
    })
  }

}

export default StagesField
