import ItemToken from '@apps/stores/admin/tokens/item'
import PropTypes from 'prop-types'
import React from 'react'

class Products extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    items: PropTypes.array
  }

  render() {
    const { items } = this.props
    return (
      <div className="maha-table">
        <table>
          <thead>
            <tr>
              <td>Product</td>
              <td>Quantity</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            { items.map((item, index) => {
              return (
                <tr key={`item_${index}`}>
                  <td className="unpadded">
                    <ItemToken item={ item }/>
                  </td>
                  <td>{ item.quantity }</td>
                  <td>{ item.price }</td>
                </tr>
              )
            }) }
            { items.length === 0 &&
              <tr>
                <td colSpan="3" className="center">
                  This cart doesnt have any items
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }

}

export default Products
