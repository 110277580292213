import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'
import Web from './web'

const WebContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'attachments_web',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <Web { ...mergedProps } />

}

WebContainer.propTypes = {}

export default WebContainer