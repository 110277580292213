import Button from '@admin/components/button'
import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import Token from '@admin/tokens/token'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Select extends React.Component {

  static propTypes = {
    columns: PropTypes.number,
    defaultSelected: PropTypes.bool,
    defaultValue: PropTypes.any,
    deselectable: PropTypes.bool,
    disabled: PropTypes.any,
    entity: PropTypes.string,
    format: PropTypes.any,
    height: PropTypes.number,
    items: PropTypes.array,
    id: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    selected: PropTypes.array,
    selectFirst: PropTypes.bool,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    textKey: PropTypes.string,
    valueKey: PropTypes.string,
    value: PropTypes.any,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    columns: 1,
    deselectable: false,
    entity: 'option',
    format: Token,
    multiple: false,
    options: [],
    selectFirst: true,
    textKey: 'text',
    valueKey: 'value',
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    selected: null
  }

  _handleDeselectAll = this._handleDeselectAll.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleSelectAll = this._handleSelectAll.bind(this) 

  render() {
    const { entity, format, options, tabIndex } = this.props
    const deselectable = this._getDeselectable()
    if(!this.state.selected) return null
    return (
      <div className={ this._getClass() } tabIndex={ tabIndex } onKeyDown={ this._handleKeyDown }>
        { deselectable && options.length > 1 &&
          <div className="maha-select-deselect">
            <Button { ...this._getDeselect() } />
          </div>
        }
        <div className="maha-select-options" style={ this._getStyle() }>
          { options.length === 0 &&
            <div className="maha-select-option-empty">
              There are no { pluralize(entity) }
            </div>
          }
          { options.map((option, index) => (
            <div key={`option_${index}`} { ...this._getOption(index) }>
              <div className="maha-select-option-icon">
                <Icon icon={`${this._getOptionIcon(index)}`} />
              </div>
              <div className="maha-select-option-label">
                <Format { ...option } format={ format } value={ this._getText(option) } />
              </div>
              { option.extra &&
                <div className="maha-select-option-extra">
                  { option.extra }
                </div>
              }
            </div>
          )) }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectFirst, value } = this.props
    const { selected } = this.state
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
    if(!_.isEqual(selected, prevState.selected)) {
      if(prevState.selected === null && !selectFirst) return
      this._handleChange()
    }
  }

  _getClass() {
    const numbers = ['one','two','three','four','five','six']
    const { label, columns, disabled } = this.props
    const deselectable = this._getDeselectable()
    const classes = ['maha-input','maha-select',`${numbers[columns-1]}-column`]
    if(disabled) classes.push('disabled')
    if(deselectable && !label) classes.push('unlabeled')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : []
  }
  
  _getDeselect() {
    const { multiple } = this.props
    const { selected } = this.state
    return {
      label: multiple ? (selected.length > 0 ? 'deselect all' : 'select all') : 'deselect',
      className: (!multiple && selected.length === 0) ? 'link disabled' : 'link',
      handler: (multiple && selected.length === 0) ? this._handleSelectAll : this._handleDeselectAll
    }
  }
  
  _getDeselectable() { 
    const { deselectable, multiple } = this.props
    return deselectable === false ? false : multiple
  }

  _getIndexes(value) {
    if(value === null) return []
    const { options, valueKey } = this.props
    return options.reduce((indexes, option, index) => {
      const isEqual = _.castArray(value).find(val => {
        const optionValue = valueKey ? _.get(option, valueKey) : option
        return _.isEqual(optionValue, val)
      }) !== undefined
      return [
        ...indexes,
        ...isEqual ? [index] : []
      ]
    }, [])
  }

  _getIsSelected(index) {
    const { selected } = this.state
    return _.includes(selected, index)
  }

  _getOption(index) {
    return {
      className: this._getOptionClass(index),
      onClick: this._handleUpdate.bind(this, [index])
    }
  }

  _getOptionClass(index) {
    const classes = ['maha-select-option']
    if(this._getIsSelected(index)) classes.push('selected')
    return classes.join(' ')
  }

  _getOptionIcon(index) {
    const { multiple } = this.props
    const selected = this._getIsSelected(index)
    if(selected) return multiple ? 'check-square' : 'check-circle'
    if(!selected) return multiple ? 'square-o' : 'circle-o'
  }

  _getStyle() {
    const { height } = this.props
    return height ?{
      overflowY: 'scroll',
      maxHeight: height
    } : {}
  }

  _getText(option) {
    const { textKey } = this.props
    return textKey ? _.get(option, textKey) : option
  }

  _getValue(index) {
    if(index === null) return null
    const { options, valueKey } = this.props
    const option = options[index]
    return valueKey ? _.get(option, valueKey) : option
  }

  _handleChange() {
    const { multiple } = this.props
    const { selected } = this.state
    const values = selected.map(index => {
      return this._getValue(index)
    })
    const value = multiple ? values : values[0]
    this.props.onChange(_.isNil(value) ? null : value)
  }

  _handleDeselectAll() {
    this._handleSet([])
  }

  _handleKeyDown(e) {
    const { multiple, options } = this.props
    const { selected } = this.state
    if(multiple || !_.includes([38,40], e.which)) return
    const mod = (x, n) => (x % n + n) % n
    const increment = e.which === 38 ? -1 : 1
    const index = _.findIndex(options, { value: selected[0] })
    const newindex = mod((index + increment), options.length)
    this._handleUpdate([newindex])
  }

  _handleSelectAll() {
    const { options } = this.props
    this._handleUpdate(options.map((option, index) => index))
  }

  _handleSet(value) {
    const selected = this._getIndexes(value)
    this.setState({ selected })
  }

  _handleUpdate(indexes) {
    const { disabled, multiple } = this.props
    const { selected } = this.state
    if(disabled) return
    this.setState({ 
      selected: multiple ? _.xor(selected, indexes) : indexes
    })
  }

}

export default Select
