import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Loader extends React.Component {

  static propTypes = {
    inverted: PropTypes.bool,
    label: PropTypes.string,
    size: PropTypes.string
  }

  static defaultProps = {
    inverted: true,
    size: 'medium'
  }

  render() {
    const { label } = this.props
    return (
      <div className="maha-loader">
        <div className={ this._getDimmerClass() }>
          <div className={ this._getLoaderClass() }>
            { label &&
              <T text={ label } />
            }
          </div>
        </div>
      </div>
    )
  }

  _getDimmerClass() {
    const { inverted } = this.props
    const classes = ['ui','active','dimmer']
    if(inverted) classes.push('inverted')
    return classes.join(' ')
  }

  _getLoaderClass() {
    const { size } = this.props
    const classes = ['ui','text','loader']
    classes.push(size)
    return classes.join(' ')
  }

}

export default Loader
