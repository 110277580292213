import CallActivities from '@apps/phone/admin/components/call_activities'
import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Actions from '@apps/automation/admin/components/actions'
import Button from '@apps/phone/admin/components/button'
import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import SMS from '../sms/sms'
import moment from 'moment'
import React from 'react'

class Call extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    network: PropTypes.object,
    twilio: PropTypes.object,
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    actions: PropTypes.array,
    call: PropTypes.object,
    voice_campaign: PropTypes.object,
    enrollment: PropTypes.object,
    phone_number: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleBlock = this._handleBlock.bind(this)
  _handleCall = this._handleCall.bind(this)
  _handleInfo = this._handleInfo.bind(this)
  _handlePlace = this._handlePlace.bind(this)
  _handleSMS = this._handleSMS.bind(this)

  render() {
    const buttons = this._getButtons()
    const { call } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-handset-detail-container">
          <div className="maha-handset-detail">
            <div className="maha-handset-detail-header">
              <ContactAvatar { ...call.contact } />
              <h2>{ call.contact.display_name }</h2>
              <div className="maha-handset-actions" >
                { buttons.map((button, index) => (
                  <div className="maha-handset-action" key={`action_${index}`}>
                    <Button { ...button } />
                  </div>
                ))}
              </div>
            </div>
            <div className="maha-handset-detail-body">
              <Details { ...this._getDetails() } />
            </div>
          </div>
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    const { call } = this.props
    if(!call.was_viewed) this._handleView()
  }

  _getButtons() {
    return [
      { icon: 'info', label: 'info', handler: this._handleInfo },
      { icon: 'phone', label: 'call', handler: this._handlePlace },
      { icon: 'comments', label: 'sms', handler: this._handleSMS },
      { icon: 'ban', label: 'block', handler: this._handleBlock }
    ]
  }

  _getDetails() {
    const { call, enrollment, actions, voice_campaign } = this.props
    const items = [
      { label: 't(Program)', content: call.program.title },
      { label: 't(Time)', content: this._getTimestamp(call) },
      { label: 't(Duration)', content: call.seconds, format: 'duration' },
      { label: 't(Direction)', content: call.direction }
    ]
    if(call.from_number) items.push({ label: 't(From)', content: call.from_number.formatted })
    if(call.from_user) items.push({ label: 't(From)', content: call.from_user.full_name })
    if(call.to_number) items.push({ label: 't(To)', content: call.to_number.formatted })
    if(call.to_user) items.push({ label: 't(To)', content: call.to_user.full_name })
    if(enrollment) {
      items.push({ component: <Actions actions={ actions } enrollment={ enrollment } trigger={ voice_campaign.trigger } call={ call } /> })
    } else {
      items.push({ component: <CallActivities call={ call } /> })
    }
    return {
      comments: `phone_calls/${call.id}`,
      items
    }
  }

  _getPanel() {
    return {
      title: 't(Call)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getSMS() {
    const { call, onPop } = this.props
    return {
      contact_phone_number: call.contact_phone_number,
      program_phone_number: call.program_phone_number,
      program: call.program,
      onBack: onPop
    }
  }

  _getTimestamp(call) {
    const time = moment(call.created_at).format('h:mm A')
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const created_at = moment(call.created_at)
    if(today.format('YYMMDD') === created_at.format('YYMMDD')) return `Today, ${time}`
    if(yesterday.format('YYMMDD') === created_at.format('YYMMDD')) return `Yesterday, ${time}`
    if(today.diff(created_at, 'days') < 7) return created_at.format('dddd, h:mm A')
    return created_at.format('MM/DD/YY, h:mm A')
  }

  _handleBack() {
    this.props.onPop()
  }

  _handleBlock() {
    const { call } = this.props
    this.context.network.request({
      endpoint: `/api/admin/phone/numbers/${call.program_phone_number.id}/blocked`,
      method: 'POST',
      body: {
        number: call.from_number.number
      },
      onSuccess: () => this.context.flash.set('success', 't(This number has been blocked)'),
      onFailure: () => this.context.flash.set('error', 't(Unable to block this number)')
    })
  }

  _handleCall(device) {
    const { call, phone_number } = this.props
    const { contact, contact_phone_number } = call
    this.context.twilio.call({
      device,
      program: phone_number.program,
      contact,
      number: contact_phone_number.number
    })
  }

  _handleInfo() {
    const { call } = this.props
    this.context.router.push(`/crm/contacts/${call.contact.id}`)
  }

  _handlePlace() {
    const { account } = this.context.admin
    if(!account.mobile_phone) return this._handleCall('maha')
    this.context.tasks.open({
      items: [
        { label: 't(Call with mobile phone)', handler: this._handleCall.bind(this, 'mobile') },
        { label: 't(Call with Maha phone)', handler: this._handleCall.bind(this, 'maha') }
      ]
    })
  }

  _handleSMS() {
    return this.props.onPush(SMS, this._getSMS())
  }

  _handleView() {
    const { call } = this.props
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.id}/view`,
      method: 'PATCH'
    })
  }

}

const mapResources = (props, context) => ({
  call: `/api/admin/phone/calls/${props.call.id}`,
  ...props.call.enrollment ? {
    actions: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}/enrollments/${props.call.enrollment.id}/actions`,
    voice_campaign: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}`,
    enrollment: `/api/admin/campaigns/voice/${props.call.enrollment.voice_campaign_id}/enrollments/${props.call.enrollment.id}`
  } : {}
})

export default Container(mapResources)(Call)
