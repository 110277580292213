import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Networks from './networks'
import Details from './details'
import Design from './design'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Social Campaign)',
      action: '/api/admin/truevail/agency/social_campaigns',
      method: 'POST',
      props: this._getProps(),
      getSteps: this._getSteps,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getProps() {
    const { advisor } = this.props
    return { 
      advisor
    }
  }

  _getSteps() {
    return [
      { label: 't(Details)', component: Details },
      { label: 't(Networks)', component: Networks },
      { label: 't(Design)', component: Design }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(social_campaigns) {
    this.context.router.push(`/truevail/agency/social_campaigns/${social_campaigns.id}`)
    this.context.modal.close()
  }

}

export default New
