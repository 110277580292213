import CancelStrategyToken from '@apps/subscriptions/admin/tokens/cancel_strategy'
import FinalRenewalToken from '@apps/subscriptions/admin/tokens/final_renewal'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Payment extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata, props } = this.props
    const { service, subscription } = props
    const options = this._getOptions()
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'cancel_strategy', type: 'radiogroup', options, defaultValue: 'charge', format: CancelStrategyToken },
            ...(service.model === 'flat' && config.cancel_strategy !== 'nothing') ? [
              { label: 'Final Renewal', type: 'text', component: (
                <FinalRenewalToken service={ service } subscription={ subscription } cancellation={ formdata } />
              ) }
            ] : []
          ]
        }
      ]
    }
  }

  _getOptions() {
    const { timing } = this.props.props.subscription.current_period
    return [
      ...timing === 'end'  ? ['charge'] : ['refund'],
      'nothing'
    ]
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onSave(subscription)
  }

}

export default Payment
