import AssetInfo from '@admin/components/asset_info'
import Page from '@admin/components/page'
import PropTypes from 'prop-types'
import React from 'react'

const Asset = ({ file }) => (
  <AssetInfo asset={ file.asset } />
)

Asset.propTypes = {
  file: PropTypes.object
}

const mapResourcesToPage = (props, context) => ({
  file: `/api/admin/drive/files/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Asset Info)',
  component: Asset
})

export default Page(mapResourcesToPage, mapPropsToPage)
