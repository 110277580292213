import Page from '@admin/components/page'
import Subscriptions from './subscriptions'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/advisor',
    items: [
      {
        label: 't(Overview)',
        panel: <Overview />
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        panel: <Subscriptions />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
