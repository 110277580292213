import ProfileConnect from '@admin/components/profile_connect'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    profiles: PropTypes.array
  }

  state = {
    config: {}
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const profiles = this._getProfiles()
    const { social_campaign } = this.props
    return {
      title: 't(Edit Social Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/campaigns/social/${social_campaign.id}/edit`,
      action: `/api/admin/campaigns/social/${social_campaign.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Profiles)', name: 'profile_ids', type: 'checkboxgroup', entity: 'profile', options: profiles, valueKey: 'id', textKey: 'username', format: (profile) => (
              <ProfileToken profile={ profile } />
            ), deselectable: true, required: true, after: (
              <Button { ...this._getAdd() } />
            ) }
          ]
        }
      ]
    }
  }

  _getAdd() {
    return {
      label: '+ t(add profile)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getProfiles() {
    const { profiles } = this.props
    const sources = this._getSources()
    return profiles.filter(profile => {
      return _.includes(sources, profile.service.source)
    })
  }

  _getSources() {
    const { social_campaign } = this.props
    if(_.includes(['photo','video'], social_campaign.type)) {
      // return ['facebook','instagram_business','linkedin']      
      return ['facebook','instagram_business']      
    } else if(social_campaign.type === 'link') {
      // return ['facebook','linkedin']
      return ['facebook']
    } else if(social_campaign.type === 'reel') {
      return ['facebook','instagram_business']
    } else if(social_campaign.type === 'story') {
      return ['instagram_business']
    }
  }

  _handleAdd() {
    const { social_campaign } = this.props
    const props = {
      program_id: social_campaign.program.id,
      sources: this._getSources(),
      types: ['posts'],
      onBack: this.context.modal.pop,
      onDone: this.context.modal.pop
    }
    this.context.modal.push(ProfileConnect, props)
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: `/api/admin/programs/${props.social_campaign.program.id}/profiles`,
    refresh: `/teams/${context.admin.team.id}/admin/team/programs/${props.social_campaign.program.id}/profiles`
  }
})

export default Container(mapResources)(Edit)
