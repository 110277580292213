import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    programs: PropTypes.array,
    program_id: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    return {
      title: 't(New Sender)',
      action: '/api/admin/team/senders',
      method: 'POST',
      defaultValue: this._getDefault(),
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getDefault() {
    const { program_id, programs } = this.props
    return {
      ...program_id ? { 
        program_id 
      } : programs.length === 1 ? { 
        program_id: programs[0].id 
      } : {}
    }
  }

  _getData(sender) {
    return {
      program_id: sender.program_id,
      type: sender.type,
      name: sender.name,
      email: sender.email,
      verify_email: sender.verify_email,
      reply_to: sender.reply_to,
      on_behalf_of: sender.on_behalf_of,
      verify_on_behalf_of: sender.verify_on_behalf_of
    }
  }

  _getSteps() {
    return [
      { label: 't(Type)', component: Type },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(sender) {
    this.context.router.push(`/team/senders/${sender.id}`)
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  programs: {
    endpoint: '/api/admin/programs',
    filter: {
      access_type: {
        $in: ['manage','edit']
      }
    }
  }
})

export default Container(mapResources)(New)
