import ManagerToken from '../../tokens/manager'
import Page from '@admin/components/page'
import Users from './users'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  fields: `/api/admin/sites_sites/${props.params.site_id}/fields`
})

const mapPropsToPage = (props, context, resources) => ({
  title: 't(Managers)',
  collection: {
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', sort: 'last_name', primary: true, format: ManagerToken }
    ],
    endpoint: `/api/admin/sites/sites/${props.params.site_id}/managers`,
    empty: {
      icon: 'users',
      title: 't(No Managers)',
      text: 't(This site does not have any managers)',
      buttons: [
        { label: 'Create Manager', modal: <Users site_id={ props.params.site_id } /> }
      ]
    },
    entity: 'manager',
    defaultSort: { key: 'created_at', order: 'asc' }
  },
  task: {
    label: 'New Project',
    modal: <Users site_id={ props.params.site_id } />
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
