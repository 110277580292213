import MBlocksDesigner from '@admin/components/mblocks_designer/wrapper'
import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    template: PropTypes.object
  }

  render() {
    const { template } = this.props
    return (
      <>
        { template.engine === 'mblocks' ?
          <MBlocksDesigner { ...this._getMBlocksDesigner() } /> :
          <MJSONDesigner { ...this._getMJSONDesigner() } />
        }
      </>
    )
  }

  _getMBlocksDesigner() {
    const { template } = this.props
    const { admin } = this.context
    return {
      editable: canAccess({ 
        programs: { $canEdit: template.program.id },
        rights: { $allOf: ['team:manage_templates'] } 
      }, admin),
      defaultValue: template.config,
      endpoint: `/api/admin/templates/email/${template.id}`,
      email: template,
      program: template.program
    }
  }

  _getMJSONDesigner() {
    const { template } = this.props
    const { admin } = this.context
    return {
      editable: canAccess({ 
        programs: { $canEdit: template.program.id },
        rights: { $allOf: ['team:manage_templates'] } 
      }, admin),
      endpoint: `/crm_templates/${template.id}`,
      root_route: `/team/templates/${template.id}/design`,
      themeable: true,
      type: 'email',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design template={ props.template } />
})

export default Panel(null, mapPropsToPanel)
