import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Preview extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    publishing: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { publishing } = this.props
    return {
      title: 't(Send Preview)',
      method: 'POST',
      action: `/api/admin/truevail/agency/publishings/${publishing.id}/preview`,
      saveText: 'Send',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Introduction)', name: 'preview_introduction', type: 'htmlfield', toolbar: ['b','em','u','ol','ul','a'], placeholder: 't(Enter an optional email introduction)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Preview
