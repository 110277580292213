import Loader from '@admin/components/loader'
import Stack from '@admin/components/stack'
import Importing from './importing'
import PropTypes from 'prop-types'
import Uploader from './uploader'
import Explorer from './explorer'
import React from 'react'

class Attachments extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    allow: PropTypes.object,
    assets: PropTypes.array,
    counts: PropTypes.object,
    custom: PropTypes.array,
    doneText: PropTypes.any,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    processed: PropTypes.bool,
    prompt: PropTypes.string,
    requirements: PropTypes.array,
    retry: PropTypes.bool,
    sources: PropTypes.array,
    status: PropTypes.string,
    title: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChooseAssets: PropTypes.func,
    onClear: PropTypes.func,
    onCreate: PropTypes.func,
    onFetch: PropTypes.func,
    onDone: PropTypes.func,
    onRemove: PropTypes.func,
    onSetSources: PropTypes.func,
    onUpdate: PropTypes.func
  }

  static defaultProps = {
    allow: {},
    doneText: 't(Next)',
    multiple: false,
    prompt: 't(Attach Files)',
    onChooseAssets: () => {}
  }

  stackRef = React.createRef()

  _handleAdd = this._handleAdd.bind(this)
  _handleAlternate = this._handleAlternate.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleImporting = this._handleImporting.bind(this)

  render() {
    const { status } = this.props
    if(status === 'loading') return <Loader />
    if(status !== 'loaded') return null
    return (
      <Uploader { ... this._getUploader() }>
        <Stack { ...this._getStack() } />
      </Uploader>
    )
  }

  componentDidMount() {
    this._handleJoin()
    this._handleFetch()
  }

  componentDidUpdate(prevProps) {
    const { multiple, files } = this.props
    if(!multiple && files.length === 1 && !_.isEqual(files, prevProps.files) && _.includes(['pending','imported'], files[0].status)) {
      this._handleImporting()
    }
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  _getExplorer() {
    const { allow, counts, custom, files, doneText, multiple, sources, title, view, onBack, onCancel, onToggleView } = this.props
    return {
      allow,
      counts,
      custom,
      doneText,
      files,
      multiple,
      sources,
      title,
      view,
      onAdd: this._handleAdd,
      onBack,
      onCancel,
      onNext: this._handleImporting,
      onRemove: this._handleRemove,
      onToggleView
    }
  }

  _getImporting() {
    const { files, multiple, processed, requirements, retry } = this.props
    return {
      files,
      multiple,
      processed,
      requirements,
      retry,
      onAlternate: this._handleAlternate,
      onDone: this._handleDone,
      onCreate: this._handleCreate
    }
  }

  _getStack() {
    return {
      display_name: 'attachments',
      ref: this.stackRef,
      initial: [
        { component: Explorer, props: this._getExplorer.bind(this) }
      ]
    }
  }

  _getUploader() {
    const { allow, files, multiple, onUpdate } = this.props
    return {
      allow,
      files,
      multiple,
      onAdd: this._handleAdd,
      onUpdate
    }
  }

  _handleAdd(file) {
    const { multiple, files } = this.props
    const index = _.findIndex(files, { id: file.id, service: file.service })
    if(index >= 0) return
    if(!multiple) this.props.onRemove(0)
    this.props.onAdd(file)
  }

  _handleAlternate() {
    this.props.onClear()
    this._handlePop()
  }

  _handleCreate(endpoint, body, index) {
    this.props.onUpdate(index, {
      status: 'importing'
    })
    this.context.network.request({
      endpoint,
      method: 'POST',
      body,
      onSuccess: ({ data }) => {
        this.props.onUpdate(index, {
          asset: data,
          status: 'complete'
        })
      }, 
      onFailure: () => {
        this.props.onUpdate(index, {
          status: 'importing'
        })        
      }
    })
  }

  _handleDone() {
    const { assets, multiple, onDone } = this.props
    this.props.onChooseAssets(assets)
    // if(!multiple) setTimeout(this._handlePop, 500)
    if(onDone) return onDone(assets)
    this.context.modal.pop()
  }

  _handleFetch() {
    const { allow } = this.props
    this.context.network.request({
      endpoint: '/api/admin/profiles',
      method: 'GET',
      query: {
        $filter: {
          type: {
            $in: allow.types || ['files','photos']
          }
        }
      },
      onSuccess: ({ data }) => {
        this.props.onSetSources(data)
      }, 
      onFailure: () => {}
    })
  }

  _handleImporting() {
    const { processed } = this.props
    if(processed) return this._handleDone()
    this._handlePush(Importing, this._getImporting.bind(this))
  }

  _handleJoin() {
    const { admin } = this.context
    const { account } = admin
    this.context.network.subscribe({ 
      channel: `/accounts/${account.id}/admin/profiles`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { account } = admin
    this.context.network.unsubscribe({ 
      channel: `/accounts/${account.id}/admin/profiles`,
      action: 'refresh', 
      handler: this._handleFetch 
    })
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleRemove(index) {
    this.props.onRemove(index)
  }

}

export default Attachments
