import Icon from '@admin/components/icon'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const EngagementToken = ({ engagement }) => (
  <div className={`engagement-token ${engagement.snapshot_id ? ' snapshot' : ''}`}>
    <Icon icon="square" /> <Date date={ engagement.date } />
  </div>
)

EngagementToken.propTypes = {
  engagement: PropTypes.object
}

export default EngagementToken
