import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ title }) => {

  const details = {
    sections: [
      {
        items: [
          { label: 't(Title)', content: title },
          { label: 't(Description)', content: title }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  title: PropTypes.string
}

export default Overview
