import RegistrationToken from '@apps/events/admin/tokens/registration'
import PaymentToken from '@apps/events/admin/tokens/payment'
import Content from '@apps/forms/admin/tokens/content'
import Panel from '@admin/components/panel'
import React from 'react'

const getField = (field) => {
  const type = field.type === 'contactfield' ? field.contactfield.type : field.type
  if(type === 'addressfield') {
    return [
      { label: `${field.name.value} - Full Address`, key: `data.${field.code}.description` },
      { label: `${field.name.value} - Street 1`, key: `data.${field.code}.street_1` },
      { label: `${field.name.value} - Street 2`, key: `data.${field.code}.street_2` },
      { label: `${field.name.value} - City`, key: `data.${field.code}.city` },
      { label: `${field.name.value} - State/Province`, key: `data.${field.code}.state_province` },
      { label: `${field.name.value} - Postal Code`, key: `data.${field.code}.postal_code` },
      { label: `${field.name.value} - County`, key: `data.${field.code}.county` },
      { label: `${field.name.value} - Latitude`, key: `data.${field.code}.latitude` },
      { label: `${field.name.value} - Longitude`, key: `data.${field.code}.longitude` }
    ]
  }
  return [
    { label: field.name.value, key: `data.${field.code}` }
  ]
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Registrations)',
  collection: {
    endpoint: `/api/admin/events/events/${props.event.id}/registrations`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: RegistrationToken },
      ...props.event.contact_config.fields.map(field => ({
        label: field.name.value,
        key: `data.${field.code}`,
        sort: field.code,
        visible: false,
        format: ({ data }) => <Content data={ data } field={ field } />
      })),
      { label: 't(Tickets)', key: 'tickets_count', collapsing: true, align: 'right' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'is_paid', collapsing: true, format: PaymentToken },
      { label: 't(Submitted)', key: 'created_at', visible: false, format: 'datetime' }
    ],
    criteria: {
      fields: [
        {
          label: 'Registration',
          virtuals: props.event.contact_config.fields
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'data.first_name' },
      { label: 't(Last Name)', key: 'data.last_name' },
      { label: 't(Email)', key: 'data.email' },
      ...props.event.contact_config.fields.filter(field => {
        return field.type !== 'text'
      }).reduce((fields, field) => [
        ...fields,
        ...getField(field)
      ], []),
      ...props.event.ticket_types.reduce((ticket_types, ticket_type) => [
        ...ticket_types,
        { label: `${ticket_type.name}`, key: `ticket_type_totals[${ticket_type.id}]` }
      ], []),
      { label: 'Registration Date', key: 'created_at' },
      { label: 't(Payment Method)', key: 'payment.method' },
      { label: 'Payment Reference', key: 'payment.reference' },
      { label: 'Payment Amount', key: 'payment.amount' },
      { label: 'Payment Fee', key: 'payment.fee' },
      { label: 'Payment Disbursed', key: 'payment.disbursed' },
      { label: 'Payment Date', key: 'payment.date' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'user-circle-o',
      title: 't(No Registrations)',
      text: 't(No one has registered for this event)'
    },
    entity: 'registration',
    onClick: (record) => context.router.push(`/events/events/${props.event.id}/registrations/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
