import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class AmountField extends React.PureComponent {

  static propTypes = {
    balance: PropTypes.any,
    required: PropTypes.bool,
    action: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    action: 'Pay',
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    strategy: null,
    other: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <div className="amountfield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state !== prevState) {
      this._handleChange()
    }
  }

  _getForm() {
    const { action, balance } = this.props
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', deselectable: false, options: [
                { value: 'balance', text: `${action} the balance: ${numeral(balance).format('$0.00')}` },
                { value: 'other', text: `${action} another amount` }
              ], defaultValue: 'balance' },
              ...this._getAmountField()
            ] }
          ]
        }
      ]
    }
  }

  _getAmountField() {
    const { strategy } = this.state
    return strategy === 'other' ? [
      { name: 'other', type: 'moneyfield' }
    ] : []
  }

  _getAmount() {
    const { balance } = this.props
    const { strategy, other } = this.state
    return strategy === 'balance' ? balance : other
  }

  _handleChange() {
    const amount = this._getAmount()
    this.props.onChange(amount)
  }

  _handleUpdate(config) {
    this.setState(config)
  }

  _handleValidate() {
    const { balance, required } = this.props
    const amount = this._getAmount()
    if(required && !amount) {
      return this.props.onValid(null, ['This field is required'])
    }
    if(amount && amount > balance) {
      return this.props.onValid(null, ['Amount is greater than the balance'])
    }
    this.props.onValid(amount)
  }

}

export default AmountField
