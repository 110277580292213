import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Header from './header'
import Footer from './footer'
import Cover from './cover'
import React from 'react'
import Page from './page'
import Form from './form'

class Design extends React.Component {

  static propTypes = {
    components: PropTypes.object,
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  render() {
    const sections = this._getSections()
    return (
      <div className="designer-page-sections">
        { sections.map((section, index) => (
          <div key={`section_${index}`} className="designer-page-section" onClick={ this._handleChoose.bind(this, index) }>
            <div className="designer-page-section-label">
              <T text={ section.label } />
            </div>
            <div className="designer-page-section-proceed">
              <Icon icon="chevron-right" />
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getSections() {
    return [
      { label: 't(Page)', component: Page },
      { label: 't(Cover)', component: Cover },
      { label: 't(Header)', component: Header },
      { label: 't(Form)', component: Form },
      { label: 't(Footer)', component: Footer }
    ]
  }

  _getSection() {
    const { config, components, onPop, onPush, onUpdate } = this.props
    return {
      config,
      components,
      onPop,
      onPush,
      onUpdate
    }
  }

  _handleChoose(index) {
    const sections = this._getSections()
    const section = sections[index]
    this.props.onPush(section.component, this._getSection.bind(this))
  }

}

export default Design
