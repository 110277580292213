import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Reschedule extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_campaign } = this.props
    return {
      title: 't(Reschedule Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/campaigns/email/${email_campaign.id}/edit`,
      action: `/api/admin/campaigns/email/${email_campaign.id}/reschedule`,
      saveText: 't(Reschedule)',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Send At)', name: 'send_at', type: 'datetimefield', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Reschedule
