import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import VisibilityField from '@admin/components/mjson_designer/components/visibilityfield'
import React from 'react'

const Visibility = (props) => <ResponsiveField { ...props } component={ VisibilityField } />

export const VisibilitySection = (config, device, theme, typee, defaults = {}) => ({
  label: 't(Visibility)',
  instructions: 't(Specify the visibility properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { label: 't(Visibility)', name: 'styles.visibility', type: Visibility, device, canHover: false, defaultValue: defaults.visibility, value: config.styles.visibility }
  ]
})
