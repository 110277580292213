import Container from '@admin/components/container'
import ScheduledToken from '../token'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Week extends React.PureComponent {

  static contextTypes = {
    alert: PropTypes.object
  }

  static propTypes = {
    current: PropTypes.object,
    social_campaigns: PropTypes.array,
    onEdit: PropTypes.func,
    onSchedule: PropTypes.func
  }

  bodyRef = React.createRef()

  state = {
    hover: null
  }

  _handleDragEnter = this._handleDragEnter.bind(this)
  _handleDragLeave = this._handleDragLeave.bind(this)
  _handleDrop = this._handleDrop.bind(this)

  render() {
    return (
      <div className="campaigns-social-schedule-week">  
        <div className="campaigns-social-schedule-week-header">  
          <div className="campaigns-social-schedule-week-legend">&nbsp;</div>
          { Array(7).fill(0).map((x, i) => (
            <div className={ this._getWeekdayClass(i) } key={`day_${i}`}>
              <span>{ this._getDay(i) }</span>
            </div>  
          )) }
          <div className="campaigns-social-schedule-week-scroller">&nbsp;</div>
        </div>
        <div className="campaigns-social-schedule-week-body" ref={ this.bodyRef }>  
          <div className="campaigns-social-schedule-week-days">  
            <div className="campaigns-social-schedule-week-legend">
              { Array(24).fill(0).map((y, j) => (
                <div className="campaigns-social-schedule-week-hour" key={`hour_${j}`} id={`hour_${j}`}>
                  <div className="campaigns-social-schedule-week-slot">
                    <div className="campaigns-social-schedule-week-label">{ this._getHour(j) }</div>
                  </div>
                  <div className="campaigns-social-schedule-week-slot">&nbsp;</div>
                </div>
              )) }
            </div>
            { Array(7).fill(0).map((x, i) => (
              <div className="campaigns-social-schedule-week-day" key={`day_${i}`}>
                { Array(24).fill(0).map((y, j) => (
                  <div className="campaigns-social-schedule-week-hour" key={`hour_${i}_${j}`}>
                    { this._getIsHour(i,j) &&
                      <div { ...this._getNow() } />
                    }
                    { Array(2).fill(0).map((z, k) => (
                      <div { ...this._getSlot(i, j, k) } key={`slot_${i}_${j}_${k}`}>&nbsp;
                        <div className="campaigns-social-schedule-scheduled-tokens">
                          { this._getSocialCampaigns(i,j,k).map((social_campaign, index) => (
                            <ScheduledToken { ...this._getToken(social_campaign) } key={`token_${i}_${j}_${index}`} />
                          )) }
                        </div>
                      </div>
                    )) }
                  </div>
                )) }
              </div>
            )) }
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleScroll(6)
  }

  _getDay(index) {
    const { current } = this.props
    return current.clone().add(index, 'days').format('D ddd')
  }

  _getHour(index) {
    return moment().startOf('day').add(index, 'hours').format('h A')
  }

  _getIsHour(days, hours) {
    const { current } = this.props
    const now = moment()
    const start = current.clone().add(days, 'days').add(hours, 'hours')
    const end = start.clone().add(1, 'hour')
    return now.isBetween(start, end)    
  }

  _getNow() {
    const now = moment()
    return {
      className: 'campaigns-social-schedule-week-now',
      style: {
        top: (now.minutes() / 60) * 100 + '%'
      }
    }
  }

  _getSlot(days, hours, minutes) {
    const index = days * 24 * 2 + hours * 2 + minutes
    const { hover } = this.state
    const { current } = this.props
    const date = current.clone().add(days, 'days').add(hours, 'hours').add(minutes * 30, 'minutes')
    const classes = ['campaigns-social-schedule-week-slot']
    if(index === hover) classes.push('hover')
    return {
      'data-time': date.format('YYYY-MM-DD HH:mm'),
      className: classes.join(' '),
      onDragEnter: this._handleDragEnter.bind(this, index),
      onDragLeave: this._handleDragLeave.bind(this, index),
      onDragOver: (e) => e.preventDefault(),
      onDrop: this._handleDrop
    }
  }

  _getSocialCampaigns(days, hours, minutes) {
    const { current, social_campaigns } = this.props
    const start = current.clone().add(days, 'days').add(hours, 'hours').add(minutes * 30, 'minutes').subtract(1, 'seconds')
    const end = start.clone().add(30, 'minutes').add(1, 'seconds')
    return social_campaigns.filter(social_campaign => {
      return moment(social_campaign.post_at).isBetween(start, end)
    })
  }

  _getToken(social_campaign) {
    const { onEdit } = this.props
    return {
      social_campaign,
      onEdit
    }
  } 

  _getWeekdayClass(index) {
    const { current } = this.props
    const today = moment().format('YYYY-MM-DD')
    const classes = ['campaigns-social-schedule-week-weekday']
    if(current.clone().add(index, 'days').format('YYYY-MM-DD') === today) classes.push('today')
    return classes.join(' ')
  }

  _handleDragEnter(index, e) {
    e.stopPropagation()
    this.setState({ 
      hover: index
    })
  }

  _handleDragLeave(index, e) {
    e.stopPropagation()
    if(index !== this.state.hover) return
    this.setState({
      hover: null
    })
  }

  _handleDrop(e) {
    const social_campaign = JSON.parse(e.dataTransfer.getData('social_campaign'))
    const post_at = e.target.dataset.time
    const diff = moment(post_at).diff(moment(), 'seconds' )
    if(diff < 0) {
      this.context.alert.open({
        title: 't(You cannot schedule a post in the past)'
      })
      return e.preventDefault()
    }
    this.setState({
      hover: null
    })
    this.props.onSchedule(social_campaign, post_at)
  }

  _handleScroll(index) {
    const elem = document.getElementById(`hour_${index}`)
    this.bodyRef.current.scrollTo({
      top: elem.offsetTop,
      left: 0,
      behavior: 'instant'
    })
  }

}

const mapResources = (props, context) => ({
  social_campaigns: {
    endpoint: props.endpoint,
    filter: {
      $and: [
        { status: { $neq: 'draft' } },
        { post_at: { $gte: props.range.start.format('YYYY-MM-DD 00:00:00') } },
        { post_at: { $lte: props.range.end.format('YYYY-MM-DD 23:59:59') } }
      ]
    }
  }
})

export default Container(mapResources)(Week)
