import Button from '@admin/components/button'
import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import React from 'react'

class Welcome extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    account: PropTypes.object,
    team: PropTypes.object,
    onNext: PropTypes.func
  }

  _handleNext = this._handleNext.bind(this)

  render() {
    const { team, account } = this.props
    const { provider } = this.context
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <div className="maha-avatar rounded">
              <div className="maha-avatar-badge">
                <div className="maha-avatar-wrapper">
                  <Image { ...this._getLogo() } />
                </div>
              </div>
            </div>
            <h2>{ provider.title }</h2>
            <p>
              Hi { account.first_name} and welcome to { provider.title }! Thank you
              for verifying your email address. Let&apos;s take a few moments to set
              up your account for the <strong>{ team.title }</strong> team.
            </p>
            <div className="field button-field">
              <Button { ...this._getContinue() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _getContinue() {
    return {
      label: 't(Continue)',
      color: 'blue',
      size: 'large',
      handler: this._handleNext
    }
  }

  _getLogo() {
    const { provider } = this.context
    return {
      src: provider.logo,
      title: provider.title,
      host: provider.asset_host,
      transforms: { fit: 'cover', w: 150, h: 150 }
    }
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Welcome
