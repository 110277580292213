import TeamToken from '@admin/tokens/team'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Teams = ({ teams }) => {

  const details = {
    items: teams.map((team, index) => ({
      component: <TeamToken { ...team } />
    }))
  }

  return <Details { ...details } />

}

Teams.propTypes = {
  teams: PropTypes.array
}

export default Teams
