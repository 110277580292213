export const onSetMode = (mode) => ({
  type: 'SET_MODE',
  mode
})

export const onChoose = (index) => ({
  type: 'CHOOSE',
  index
})

export const onUpdateUnseen = (unseen) => ({
  type: 'UPDATE_UNSEEN',
  unseen
})
