import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneNumber extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    number: PropTypes.string
  }

  render() {
    const { number } = this.props
    const parsed = parsePhoneNumberFromString(number)
    if(!parsed) return number
    return parsed.country === 'US' ? `+1 ${parsed.formatNational()}` : parsed.formatInternational().replace('int','ext.')
  }

}

export default PhoneNumber
