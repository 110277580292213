import ControlPanel from '@admin/components/control_panel'
import SecurityQuestion from './security_question'
import Subscriptions from './subscriptions'
import Preferences from './preferences'
import PropTypes from 'prop-types'
import Profiles from './profiles'
import Password from './password'
import Devices from './devices'
import Mobile from './mobile'
import Locale from './locale'
import Photo from './photo'
import Edit from './edit'
import React from 'react'

class Account extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  render() {
    return <ControlPanel { ...this._getControlPanel() } />
  }

  _getControlPanel() {
    const { account } = this.context.admin
    return {
      color: 'teal',
      icon: 'id-card',
      sections: [
        { items: [
          { title: 't(Edit Account)', text: 't(Edit account details)', component: Edit },
          { title: 't(Change Photo)', text: 't(Change the photo we use to identify you)', component: Photo },
          { title: 't(Manage Local/Regional Preferences)', text: 't(Manage your language and timezone display preferences)', component: Locale },
          { title: 't(Manage Mobile Phone)', text: 't(Manage your mobile phone number)', component: Mobile }
        ] },
        { title: 't(Security)', items: [
          ...account.authentication_strategy === 'local' ? [
            { title: 't(Change Password)', text: 't(Change the passord you use to signin)', component: Password },
            { title: 't(Change Security Question)', text: 't(Update the info used to reset your password)', component: SecurityQuestion }
          ] : [],
          { title: 't(Manage Devices)', text: 't(Manage the devices you use to access)', component: Devices }
        ] },
        { title: 't(Notifications)', items: [
          { title: 't(Change Preferences)', text: 't(Change how you would like to be notified)', component: Preferences },
          { title: 't(Manage Events)', text: 't(Choose how you would like to be notified for specific events)', component: Subscriptions }
        ] },
        { title: 't(Connected Accounts)', items: [
          { title: 't(Manage Accounts)', text: 't(Manage connections to third party accounts)', component: Profiles }
        ] }
      ],
      title: 't(Manage Account)'
    }
  }

}

export default Account
