import ProgramForm from '@apps/crm/admin/components/programform'
import New from '@apps/finance/admin/views/scholarships/new'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Scholarships)',
  access: { rights: { $oneOf: ['finance:manage_revenue'] } },
  collection: {
    endpoint: '/api/admin/finance/scholarships',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'customer.display_name', sort: 'customer', primary: true, format: ({ customer }) => {
        return <ContactToken { ...customer } />
      } },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Balance)', key: 'balance',  primary: true, align: 'right', format: (scholarship) => (
        <>{scholarship.balance} / {scholarship.amount}</>
      ) }
    ],
    empty: {
      title: 't(No Scholarships)',
      text: 't(There are no scholarships for this contact)',
      icon: 'dollar',
      buttons: [
        {
          label: 't(Create Scholarship)',
          access: { rights: { $oneOf: ['finance:manage_revenue'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    entity: 'payments',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/finance/scholarships/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Scholarship)',
    access: { rights: { $oneOf: ['finance:manage_revenue'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
