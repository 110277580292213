import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import bytes from 'bytes'
import React from 'react'

class Requirements extends React.Component {

  static propTypes = {
    requirements: PropTypes.array
  }

  state = {
    expanded: false
  }

  _handleToggle = this._handleToggle.bind(this)

  render() {
    const { requirements } = this.props
    const { expanded } = this.state
    return (
      <div>
        <Button { ...this._getToggle() } />
        { expanded &&
          <div className="maha-atatchmentfied-requirements">
            <ul>
              { requirements.map((requirement, index) => (
                <li key={`requirement_${index}`}>
                  { this._getRequirement(requirement) }
                </li>
              )) }
            </ul>
          </div>      
        }
      </div>
    )
  }

  _getRequirement(requirement) {
    if(requirement.type === 'type') return `must be one of: ${requirement.value.join(', ')}`
    if(requirement.type === 'ratio') return `aspect ratio of ${requirement.text}`
    if(requirement.type === 'min_ratio') return `minimum aspect ratio of ${requirement.text}`
    if(requirement.type === 'max_ratio') return `maximum aspect ratio of ${requirement.text}`
    if(requirement.type === 'min_resolution') return `minimum resolution of ${requirement.value}`
    if(requirement.type === 'max_resolution') return ` maximum resolution of ${requirement.value}`
    if(requirement.type === 'min_duration') return `minimum duration of ${requirement.value} seconds`
    if(requirement.type === 'max_duration') return `maximum duration of ${requirement.value} seconds`
    if(requirement.type === 'min_size') return `minimum size of ${bytes(requirement.value)}`
    if(requirement.type === 'max_size') return `maximum size of ${bytes(requirement.value)}`
    
  }

  _getToggle() {
    return {
      label: '* t(file requirements)',
      className: 'maha-atatchmentfied-requirements-button',
      handler: this._handleToggle
    }
  }

  _handleToggle() {
    const { expanded } = this.state
    this.setState({
      expanded: !expanded
    })
  }

}

export default Requirements
