import AssetViewer from '@admin/components/asset_viewer'
import Info from '@admin/components/asset_info'
import PropTypes from 'prop-types'
import React from 'react'

class AssetPreview extends React.Component {

  static propTypes = {
    asset: PropTypes.object,
    shareable: PropTypes.bool,
    comments: PropTypes.bool
  }

  static defaultProps = {
    comments: false,
    shareable: true
  }

  render() {
    const { asset, comments, shareable } = this.props
    return (
      <div className="maha-asset-preview">
        <div className="maha-asset-preview-main">
          <div className="maha-asset-preview-body">
            <AssetViewer asset={ asset } />
          </div>
          { document.body.clientWidth > 768 &&
            <div className="maha-asset-preview-sidebar">
              <Info asset={ asset } comments={ comments } shareable={ shareable } />
            </div>
          }
        </div>
      </div>
    )
  }

}

export default AssetPreview
