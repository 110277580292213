import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.link.url,
  access: { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } },
  collection: {
    endpoint: `/api/admin/campaigns/email/${resources.email_campaign.id}/links/${resources.link.id}/clicks`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => (
        <ContactToken { ...email.contact } property="rfc822" /> 
      ) },
      { label: 't(Clicks)', key: 'clicks_count', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'contact.first_name' },
      { label: 't(Last Name)', key: 'contact.last_name' },
      { label: 't(Email)', key: 'contact.email' }
    ],
    empty: {
      icon: 'mouse-pointer',
      title: 't(No Clicks)',
      text: 't(There were no clicks on this link)'
    },
    entity: 'click',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

const mapResourcesToPage = (props, context) => ({
  email_campaign: `/api/admin/campaigns/email/${props.params.email_campaign_id}`,
  link: `/api/admin/campaigns/email/${props.params.email_campaign_id}/links/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
