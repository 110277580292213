import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Refund extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    event: PropTypes.object,
    registration: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { event, registration } = this.props
    return {
      title: 't(Void Registration)',
      action: `/api/admin/events/events/${event.id}/registrations/${registration.id}/void`,
      method: 'PATCH',
      saveText: 'Void',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Reason)', name: 'voided_reason', type: 'textarea', placeholder: 't(Enter a reason for voiding registration)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(event) {
    this.context.modal.close()
  }

}

export default Refund
