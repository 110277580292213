export const INITIAL_STATE = {
  action: null,
  last: [],
  history: []
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'FORWARD':
    return {
      ...state,
      action: 'PUSH',
      last: state.last.slice(0,-1),
      history: [
        ...state.history,
        action.route
      ]
    }
    
  case 'POP':
    return {
      ...state,
      action: 'POP',
      last: [
        ...state.last,
        state.history.slice(-1)[0]
      ],
      history: state.history.slice(0, -1)
    }

  case 'PUSH':
    return {
      ...state,
      action: 'PUSH',
      last: [],
      history: [
        ...state.history,
        action.route
      ]
    }

  case 'REPLACE':
    return {
      ...state,
      action: 'REPLACE',
      history: state.history.map((route, index) => {
        return (index === state.history.length - 1) ? action.route : route
      })
    }

  case 'RESET':
    return {
      ...state,
      action: null,
      last: [],
      history: [action.route]
    }

  default:
    return state
  }

}
