import IntegrationToken from '@apps/finance/admin/tokens/integration'
import React from 'react'

const userValues = (user, context) => {
  const finance = context.admin.apps.find(app => app.code === 'finance')
  return finance && finance.settings.integration === 'accumatica' ? [
    {
      title: <IntegrationToken integration="accumatica" />,
      padded: false,
      items: [
        { label: 'Employee ID', content: user.values.employee_id, empty: 'NONE' },
        { label: 'Vendor ID', content: user.values.vendor_id, empty: 'NONE' }
      ]
    }
  ] : []
}

export default userValues
