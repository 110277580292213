import ResourcesToken from '@apps/subscriptions/admin/tokens/resources'
import FrequencyToken from '@apps/subscriptions/admin/tokens/frequency'
import TimingToken from '@apps/subscriptions/admin/tokens/timing'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import Resources from '../resources'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Edit from '../edit'
import React from 'react'

const Overview = ({ service }, { admin }) => {

  const program = {
    label: service.program.title,
    className: 'link',
    route: `/admin/team/programs/${ service.program.id}`
  }

  const details = {
    audits: `subscriptions_services/${service.id}`,
    comments: `subscriptions_services/${service.id}`,
    sections: [
      {
        title: 't(Service Details)',
        items: [
          { label: 't(Title)', content: service.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
          { label: 't(Billing Model)', content: service.model === 'flat' ? 'Flat Fee' : 'Metered Billing' },
          { label: 't(Renewal Frequency)', content: <FrequencyToken frequency={ service.frequency } /> },
          { label: 't(Renewal Timing)', content: <TimingToken timing={ service.timing } /> },
          { label: 't(Metered Resources)', show: service.model === 'metered', content: service, format: ResourcesToken }
        ]
      }
    ]
  }
  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  service: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview service={ props.service } />,
  tasks: {
    items: [
      { label: 't(Edit Service)', modal: <Edit service={ props.service } /> },
      { label: 't(Manage Resources)', show: props.service.model === 'metered', modal: <Resources service={ props.service } /> },
      {
        label: 't(Generate Renewals)',
        request: {
          endpoint: `/api/admin/subscriptions/services/${ props.service.id }/renew`,
          method: 'PATCH',
          onSuccess: (renewals) => context.flash.set('success', `Successfully generated ${pluralize('renwal', renewals.length, 1) }`),
          onFailure: () => context.flash.set('error', 't(Unable to generate renewals)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
