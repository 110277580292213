import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PhoneField from '@admin/components/form/phonefield'
import ChannelField from './channelfield'

const validate = ({ value, network, onValid }) => {
  const parsed = parsePhoneNumberFromString(value)
  if(!parsed || !parsed.isValid()) {
    return onValid(null, 'Enter a valid phone number')
  }
  return onValid(value)
}

const unpack = (item) => item ? item.number : null

const pack = (number) => ({ number })

const format = (item) => {
  const parsed = parsePhoneNumberFromString(item.number)
  return parsed.country === 'US' ? `+1 ${parsed.formatNational()}` : parsed.formatInternational().replace('int','ext.')
}

const compare = (item1, item2) => {
  return item1 === item2
}

const verify = (value) => {
  const parsed = parsePhoneNumberFromString(value)
  return parsed && parsed.isValid()
}

export default ChannelField({
  compare,
  emptyValue: null,
  entity: 'phone number',
  format,
  pack,
  unpack,
  validate,
  verify
}, PhoneField)
