import Image from '@admin/components/image'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const ItemToken = ({ item }) => (
  <div className="product-token">
    <div className="product-token-photo">
      { item.thumbnail ?
        <Image src={ item.thumbnail.path } transforms={{ fit: 'cover', width: 24, height: 24 }} /> :
        <div className="product-token-icon">
          <Icon icon="shopping-bag" />
        </div>
      }
    </div>
    <div className="product-token-label">
      { item.title }
    </div>
  </div>
)

ItemToken.propTypes = {
  item: PropTypes.object
}

export default ItemToken
