import ModalPanel from '@admin/components/modal_panel'
import SourceToken from '@admin/tokens/source'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const options = [
  { group: 'contacts', type: 'contacts', service: 'constantcontact', source: 'constantcontact' },
  { group: 'contacts', type: 'contacts', service: 'google', source: 'googlecontacts' },
  { group: 'contacts', type: 'contacts', service: 'mailchimp', source: 'mailchimp' },
  { group: 'contacts', type: 'contacts', service: 'microsoft', source: 'outlook' },
  { group: 'email', type: 'emails', service: 'google', source: 'gmail' },
  { group: 'email', type: 'emails', service: 'microsoft', source: 'outlook' },
  { group: 'files', type: 'files', service: 'google', source: 'googledrive' },
  { group: 'files', type: 'photos', service: 'google', source: 'googlephotos' },
  { group: 'files', type: 'files', service: 'microsoft', source: 'onedrive' },
  { group: 'files', type: 'files', service: 'dropbox', source: 'dropbox' },
  { group: 'files', type: 'files', service: 'box', source: 'box' },
  { group: 'posts', type: 'posts', service: 'facebook', source: 'facebook' },
  { group: 'posts', type: 'posts', service: 'instagram', source: 'instagram' },
  // { group: 'posts', type: 'posts', service: 'linkedin', source: 'linkedin' },
  { group: 'signatures', type:'signatures', service: 'adobe', source: 'adobesign' }
]

class New extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    form: PropTypes.object,
    host: PropTypes.object
  }

  static propTypes = {
    types: PropTypes.array,
    onPop: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getOptions() {
    const { features } = this.context.admin
    const { types } = this.props
    return options.filter(option => {
      return (!types || _.includes(types, option.type)) && (option.service !== 'google' || features.google) && (option.service !== 'adobe' || features.adobe)
    })
  }

  _getSearch() {
    const { types } = this.props
    return {
      search: false,
      group: !types || (types && types.length > 1),
      options: this._getOptions(),
      valueKey: null,
      textKey: 'text',
      format: ({ option }) => (
        <SourceToken source={ option.source } />
      ),
      onChange: this._handleChoose
    }
  }

  _getPanel() {
    return {
      title: 't(Choose Service)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChoose(source) {
    const { token } = this.context.admin
    const timestamp = moment().format('x')
    this.context.host.window.open(`/admin/oauth/${source.service}/authorize?type=posts&timestamp=${timestamp}&token=${token}`)
    this.context.form.pop()
  }

}

export default New
