import Handset from '@apps/phone/admin/components/handset'
import Container from '@admin/components/container'
import { Device } from '@twilio/voice-sdk'
import PropTypes from 'prop-types'
import Empty from './empty'
import React from 'react'

export const TwilioContext = React.createContext(null)
TwilioContext.displayName = 'TwilioContext'

class TwilioRoot extends React.Component {

  static childContextTypes = {
    twilio: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.object,
    phone_numbers: PropTypes.array,
    token: PropTypes.string
  }

  device = null

  state = {
    calls: [],
    error: null,
    open: false,
    phone_number_id: null,
    ready: false,
    sms: null
  }

  _handleAccept = this._handleAccept.bind(this)
  _handleCall = this._handleCall.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handleForward = this._handleForward.bind(this)
  _handleHangup = this._handleHangup.bind(this)
  _handleHold = this._handleHold.bind(this)
  _handleIncoming = this._handleIncoming.bind(this)
  _handleMute = this._handleMute.bind(this)
  _handleOpen = this._handleOpen.bind(this)
  _handlePhoneNumber = this._handlePhoneNumber.bind(this)
  _handleRegistered = this._handleRegistered.bind(this)
  _handleReject = this._handleReject.bind(this)
  _handleSMS = this._handleSMS.bind(this)
  _handleStatus = this._handleStatus.bind(this)
  _handleToggle = this._handleToggle.bind(this)
  _handleTransfer = this._handleTransfer.bind(this)
  _handleUnhold = this._handleUnhold.bind(this)

  render() {
    const { open, ready } = this.state
    const { phone_numbers } = this.props
    if(!ready) return null
    return (
      <TwilioContext.Provider value={ this.getChildContext() }>
        <div className="maha-phone">
          <div className="maha-phone-main">
            { this.props.children }
          </div>
          { open &&
            <div className="maha-phone-sidebar">
              { phone_numbers.length > 0 ?
                <Handset { ...this._getHandset() } /> :
                <Empty { ...this._getEmpty() } />
              }
            </div>
          }
        </div>
      </TwilioContext.Provider>
    )
  }

  componentDidMount() {
    const { phone_numbers } = this.props
    if(phone_numbers.length > 0) this._handlePhoneNumber(phone_numbers[0].id)
    this._handleInit()
    this._handleJoin()
  }

  componentWillUnmount() {
    this._handleLeave()
  }

  getChildContext() {
    const { calls } = this.state
    return {
      twilio: {
        calls,
        accept: this._handleAccept,
        call: this._handleCall,
        forward: this._handleForward,
        hangup: this._handleHangup,
        hold: this._handleHold,
        mute: this._handleMute,
        open: this._handleOpen,
        reject: this._handleReject,
        sms: this._handleSMS,
        transfer: this._handleTransfer,
        toggle: this._handleToggle,
        unhold: this._handleUnhold
      }
    }
  }

  _getCall(call_id) {
    const { calls } = this.state
    return calls.find(call => {
      return call.call.id === call_id
    })
  }

  _getEmpty() {
    return {
      onClose: this._handleClose
    }
  }

  _getHandset() {
    const { calls, sms, phone_number_id } = this.state
    const { phone_numbers } = this.props
    return {
      calls,
      key: `phone_numbers_${phone_number_id}`,
      phone_numbers,
      phone_number: this._getPhoneNumber(),
      sms,
      onPhoneNumber: this._handlePhoneNumber,
      onClose: this._handleClose
    }
  }

  _getPhoneNumber() {
    const { phone_number_id } = this.state
    const { phone_numbers } = this.props
    return phone_number_id ? phone_numbers.find(phone_number => {
      return phone_number.id === phone_number_id
    }) : null
  }

  _getStatusComparison(status1, status2) {
    const statuses = ['initiated','ringing','in-progress','completed']
    const index1 = statuses.findIndex(status => status === status1)
    const index2 = statuses.findIndex(status => status === status2)
    return index2 > index1
  }

  _handleAccept(call_id) {
    const call = this._getCall(call_id)
    call.connection.accept()
  }

  _handleAddCall(connection, extra) {
    const { calls } = this.state
    this.setState({
      calls: [
        ...calls.filter(call => {
          return call.call.id !== extra.call.id
        }),
        {
          connection,
          extra: {},
          focused: true,
          held: false,
          muted: false,
          forwarding: false,
          program_sid: null,
          program_status: null,
          contact_sid: null,
          contact_status: null,
          transferring: false,
          ...extra
        }
      ]
    })
  }

  _handleCall(params) {
    const { admin, flash } = this.context
    const { phone_number_id } = this.state
    const { device, contact, phone_number, number } = params
    if(phone_number && phone_number_id !== phone_number.id) {
      this._handlePhoneNumber(phone_number.id)
    }
    this._handleOpen()
    if(number === '911') {
      return flash.set.error('You cannot dial 911 with this phone')
    }
    const config = btoa(JSON.stringify({
      identify: device === 'mobile' ? {
        ...contact ? { name: contact.full_name } : {},
        user_id: admin.user.id,
        number
      } : null,
      from: phone_number.number,
      number
    }))
    this.context.network.request({
      endpoint: '/api/admin/phone/calls',
      method: 'POST',
      body: {
        config,
        from: phone_number.number,
        to: number,
        device
      },
      onSuccess: async (result) => {
        if(device === 'maha') {
          const connection = await this.device.connect({
            params: {
              To: number,
              user_id: admin.user.id,
              call_id: result.data.id,
              config
            }
          })
          setTimeout(() => {
            this._handleAddCall(connection, {
              call: result.data,
              program_sid: connection.parameters.CallSid,
              program_status: 'in-progress',
              program_device: 'maha'
            })
          }, 500)
        }
        if(device === 'mobile') {
          this._handleAddCall(null, {
            call: result.data,
            program_sid: result.data.program_sid,
            program_device: 'mobile',
            contact_sid: null
          })
        }
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to place call)')
    })
  }

  _handleClose() {
    this.setState({
      open: false,
      sms: null
    })
  }

  _handleForward(call_id, device) {
    const call = this._getCall(call_id)
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.call.id}/forward`,
      method: 'PATCH',
      body: {
        contact_sid: call.contact_sid,
        device
      },
      onSuccess: (result) => {
        this._handleUpdateCall(call_id, {
          program_sid: null,
          program_device: device,
          forwarding: true
        })
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to forward call)')
    })
  }

  _handleHangup(call_id) {
    const call = this._getCall(call_id)
    this._handleRemoveCall(call_id)
    if(call.contact_status !== 'in-progress') {
      return call.connection.disconnect()
    }
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.call.id}/hangup`,
      method: 'PATCH',
      body: {
        sid: call.contact_sid
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to hangup call)')
    })
  }

  _handleHold(call_id) {
    const call = this._getCall(call_id)
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.call.id}/hold`,
      method: 'PATCH',
      body: {
        sid: call.contact_sid
      },
      onSuccess: async (result) => {
        this._handleUpdateCall(call.call.id, {
          program_sid: null,
          program_status: null,
          held: true
        })
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to hold call)')
    })
  }

  _handleIncoming(connection) {
    const config = JSON.parse(atob(connection.customParameters.get('config')))
    this._handleOpen()
    if(config.action === 'create') {
      return this._handleAddCall(connection, {
        call: config.call,
        program_sid: connection.parameters.CallSid,
        program_status: null,
        program_device: 'maha',
        contact_sid: config.contact_sid,
        contact_status: 'in-progress'
      })
    }
    if(config.action === 'unhold') {
      connection.accept()
      return this._handleUpdateCall(config.call.id, {
        connection,
        program_sid: connection.parameters.CallSid,
        program_status: 'in-progress',
        held: false
      })
    }
    if(config.action === 'forward') {
      connection.accept()
      return this._handleUpdateCall(config.call.id, {
        connection,
        forwarding: false,
        program_sid: connection.parameters.CallSid,
        program_status: 'in-progress',
        program_device: 'maha'
      })
    }
    if(config.action === 'transfer' && config.extra.transferred_from) {
      return this._handleAddCall(connection, {
        call: config.call,
        program_sid: connection.parameters.CallSid,
        program_status: 'ringing',
        program_device: 'maha',
        contact_sid: config.contact_sid,
        contact_status: 'in-progress',
        extra: config.extra
      })
    }
    if(config.action === 'transfer' && config.extra.transferred_back_from) {
      return this._handleAddCall(connection, {
        call: config.call,
        program_sid: connection.parameters.CallSid,
        program_status: 'ringing',
        program_device: 'maha',
        contact_sid: config.contact_sid,
        contact_status: 'in-progress',
        extra: config.extra
      })
    }
  }

  _handleRefreshToken = this._handleRefreshToken.bind(this)
  _handleRefreshToken() {
    this.context.network.request({
      endpoint: '/api/admin/phone/token',
      method: 'GET',
      onSuccess: (result) => {
        this.device.updateToken(result.data)
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to refresh token)')
    })
  }

  _handleInit() {
    this.context.network.request({
      endpoint: '/api/admin/phone/token',
      method: 'GET',
      onSuccess: (result) => {
        this.device = new Device(result.data, {
          allowIncomingWhileBusy: true
        })
        this.device.audio.incoming(false)
        this.device.audio.outgoing(false)
        this.device.audio.disconnect(false)
        this.device.on('tokenWillExpire', this._handleRefreshToken)
        this.device.on('registered', this._handleRegistered)
        this.device.on('incoming', this._handleIncoming)
        this.device.register()
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to load token)')
    })
  }

  _handleJoin() {
    const { admin } = this.context
    const { team } = admin
    this.context.network.subscribe({ 
      channel: `/teams/${team.id}/calls`,
      action: 'callstatus', 
      handler: this._handleStatus 
    })
  }

  _handleLeave() {
    const { admin } = this.context
    const { team } = admin
    this.context.network.unsubscribe({ 
      channel: `/teams/${team.id}/calls`,
      action: 'callstatus', 
      handler: this._handleStatus 
    })
  }

  _handleMute(call_id) {
    const call = this._getCall(call_id)
    call.connection.mute(!call.muted)
    this._handleUpdateCall(call.call.id, {
      muted: !call.muted
    })
  }

  _handleOpen() {
    this.setState({
      open: true
    })
  }

  _handlePhoneNumber(phone_number_id, callback = () => {}) {
    this.setState({
      phone_number_id
    }, callback)
  }

  _handleReject(call_id) {
    const call = this._getCall(call_id)
    this._handleRemoveCall(call_id)
    call.connection.reject()
  }

  _handleRemoveCall(id) {
    const { calls } = this.state
    this.setState({
      calls: calls.filter((call, i) => {
        return call.call.id !== id
      })
    })
  }

  _handleRegistered() {
    this.setState({
      ready: true
    })
  }

  _handleSMS(sms) {
    this.setState({ sms }, () => {
      this._handleOpen()
    })
  }

  _handleStatus(data) {
    const { call_id, device, sid, status } = data
    const call = this._getCall(call_id)
    if(!call) return
    if(!call.transferring && !call.held && status === 'completed' && sid === call.program_sid) {
      return this._handleRemoveCall(call_id)
    }
    if(call.transferring && status === 'in-progress') {
      return this._handleRemoveCall(call_id)
    }
    if(status === 'completed' && sid === call.contact_sid) {
      return this._handleRemoveCall(call_id)
    }
    this._handleUpdateCall(call_id, {
      ...(call.call.direction === 'inbound' && status === 'in-progress' && device === 'mobile') ? {
        program_sid: sid,
        program_status: status,
        program_device: 'mobile'
      } : {},
      ...(call.forwarding === true && status === 'initiated') ? {
        program_sid: sid
      } : {},
      ...(call.forwarding === true && status === 'in-progress') ? {
        forwarding: false
      } : {},
      ...(sid === call.program_sid && this._getStatusComparison(call.program_status, status)) ? {
        program_status: status
      } : {},
      ...(sid === call.contact_sid && this._getStatusComparison(call.contact_status, status)) ? {
        contact_status: status
      } : {},
      ...(call.call.direction === 'outbound' && device === 'mobile' && sid !== call.program_sid && call.contact_sid === null) ? {
        contact_status: status,
        contact_sid: sid
      } : {},
      ...(call.call.direction === 'outbound' && device === 'maha' && call.contact_sid === null) ? {
        contact_status: status,
        contact_sid: sid
      } : {}
    })
  }

  _handleToggle() {
    this.setState({
      open: !this.state.open,
      sms: null
    })
  }

  _handleTransfer(call_id, user) {
    const call = this._getCall(call_id)
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.call.id}/transfer`,
      method: 'PATCH',
      body: {
        user_id: user.id,
        contact_sid: call.contact_sid
      },
      onSuccess: (result) => {
        this._handleUpdateCall(call.call.id, {
          extra: {
            transferred_to: user.full_name
          },
          transferring: true
        })
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to transfer call)')
    })
  }

  _handleUnhold(call_id) {
    const call = this._getCall(call_id)
    this.context.network.request({
      endpoint: `/api/admin/phone/calls/${call.call.id}/unhold`,
      method: 'PATCH',
      body: {
        sid: call.contact_sid
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to unhold call)')
    })
  }

  _handleUpdateCall(call_id, params) {
    const { calls } = this.state
    this.setState({
      calls: calls.map((call, i) => ({
        ...call,
        ...call.call.id === call_id ? params : {}
      }))
    })
  }

}

const mapResources = (props, context) => ({
  phone_numbers: {
    endpoint: '/api/admin/phone/numbers',
    filter: {
      released_at: { $nl: true }
    }
  }
})

export default Container(mapResources)(TwilioRoot)
