import { useAnalyticsContext } from '@admin/components/analytics'
import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import reducer from './reducer'
import Router from './router'
import React from 'react'

export { RouterContext, useRouterContext } from './router'

const RouterContainer = (props) => {

  const { analytics } = useAnalyticsContext()

  const [state, handlers] = useActionReducer({
    display_name: 'router',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      analytics
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Router { ...mergedProps } />

}

RouterContainer.propTypes = {}

export default RouterContainer
