import PropTypes from 'prop-types'
import Teams from './teams'
import React from 'react'
import Apps from './apps'

class Navigation extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    analytics: PropTypes.object
  }

  static propTypes = {
    mode: PropTypes.string,
    state: PropTypes.string,
    onDone: PropTypes.func,
    onSetMode: PropTypes.func,
    onForward: PropTypes.func,
    onBack: PropTypes.func
  }

  static defaultProps = {
    onDone: () => {}
  }

  _handleSetMode = this._handleSetMode.bind(this)

  render() {
    const { mode } = this.props
    return (
      <div className="maha-navigation">
        { mode === 'apps' ?
          <Apps { ...this._getApps() } /> :
          <Teams { ...this._getTeams() } />
        }
      </div>
    )
  }

  _getApps() {
    const { apps, team, rights, user  } = this.context.admin
    return {
      ...this.props,
      onSetMode: this._handleSetMode,
      items: apps.filter(app => {
        return !(!app.items && !app.route)
      }).reduce((items, app) => [
        ...items,
        ...app.items
      ], []),
      rights,
      team,
      user
    }
  }

  _getTeams() {
    const { team } = this.context.admin
    return {
      ...this.props,
      onSetMode: this._handleSetMode,
      team
    }
  }

  _handleSetMode(mode) {
    this.context.analytics.track(`toggled ${mode} navigation`)
    this.props.onSetMode(mode)
  }

}

export default Navigation
