import { CSSTransition } from 'react-transition-group'
import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

export const TasksContext = React.createContext()
TasksContext.displayName = 'TasksContext'

export const useTasksContext = () => React.useContext(TasksContext)

class Tasks extends React.Component {

  static childContextTypes = {
    tasks: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    access: PropTypes.func,
    allowed: PropTypes.any,
    children: PropTypes.any
  }

  state = {
    component: null,
    title: null,
    items: null,
    open: false,
    onDone: null
  }

  _handleClear = this._handleClear.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handleOpen = this._handleOpen.bind(this)

  render() {
    const { component, open, title } = this.state
    const allowed = this._getAllowed()
    if(!allowed.length === 0) return null
    const show = open && component !== null
    return (
      <TasksContext.Provider value={ this.getChildContext() }>
        { this.props.children }
        <CSSTransition key="maha-tasks-overlay" in={ show } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-tasks-overlay" onClick={ this._handleClose } />
        </CSSTransition>
        <CSSTransition key="maha-tasks-list" in={ show } classNames="expanded" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
          <div className="maha-tasks-list">
            { component }
            { title &&
              <div className="maha-tasks-list-header">
                { title }
              </div>
            }
            { allowed.length > 0 &&
              <div className="maha-tasks-list-body">
                { allowed.map((item, index) => (
                  <div className="maha-tasks-list-item" key={`task_${index}`}>
                    <Button { ...this._getButton(item) }/>
                  </div>
                )) }
              </div>
            }
            <Button { ...this._getCancel() } />
          </div>
        </CSSTransition>
      </TasksContext.Provider>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state
    if(open !== prevState.open && !open) {
      setTimeout(this._handleClear, 500)
    }
  }

  getChildContext() {
    return {
      tasks: {
        open: this._handleOpen,
        close: this._handleClose
      }
    }
  }

  _getAllowed() {
    const { admin } = this.context
    const { items } = this.state
    return items ? items.filter(item => {
      if(item.show !== undefined && !item.show) return false
      if(item.access !== undefined && !canAccess(item.access, admin)) return false
      return true
    }) : []
  }

  _getButton(item) {
    return {
      ...item,
      className: 'maha-task',
      onDone: this._handleClose.bind(this)
    }
  }

  _getCancel() {
    return {
      label: 't(Cancel)',
      className: 'maha-tasks-cancel',
      handler: this._handleClose
    }
  }

  _handleClose() {
    const { onDone } = this.state
    if(onDone) onDone()
    this.setState({
      open: false
    })
  }

  _handleClear() {
    this.setState({
      title: null,
      items: null,
      component: null,
      open: false,
      onDone: null
    })
  }

  _handleOpen(tasks) {
    this.setState({
      title: tasks.title,
      items: tasks.items,
      component: tasks.component,
      open: true,
      onDone: tasks.onDone
    })
  }

}

export default Tasks
