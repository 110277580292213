import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ task }) => (
  <>{ task ? task.title : '' }</>
)

Token.propTypes = {
  task: PropTypes.object
}

export default Token
