import Page from '@admin/components/page'
import Payments from './payments'
import Overview from './overview'
import Payment from './payment'
import Clone from './clone'
import React from 'react'
import Void from './void'
import Edit from './edit'
import Send from './send'

const getTabs = ({ invoice, payments }) => {

  const items = [
    { label: 't(Overview)', component: <Overview invoice={ invoice } payments={ payments } /> }
  ]

  if(payments.length > 0) {
    items.push({ label: 't(Payments)', component: <Payments invoice={ invoice } payments={ payments } /> })
  }

  return { items }

}

const getTasks = (props, context) => {
  if(props.invoice.status === 'voided') return null
  const entity = props.invoice.status === 'paid' ? 't(Receipt)' : 't(Invoice)'
  const items = []
  if(props.invoice.status !== 'paid' && props.invoice.payments.length === 0) {
    items.push({ label: 't(Edit Invoice)', modal: <Edit invoice={ props.invoice } /> })
  }
  items.push({ label: 't(Clone Invoice)', modal: <Clone invoice={ props.invoice } /> })
  if(props.invoice.status !== 'paid' && props.invoice.payments.length === 0) {
    items.push({ label: 't(Void Invoice)', modal: <Void invoice={ props.invoice } />  })
  }
  if(props.invoice.status !== 'paid') {
    items.push({ label: 't(Receive Payment)', modal: <Payment invoice_id={ props.invoice.id } contact_id={ props.invoice.customer.id } /> })
  }
  items.push({ label: `Send ${entity}`, modal: <Send invoice={ props.invoice } />  })
  items.push({ label: `View Public ${entity}`, link: `${context.provider.admin_host}/finance/invoices/${props.invoice.code}` })
  items.push({ label: 't(Download Invoice)', url: `${context.provider.admin_host}/finance/invoices/${props.invoice.code}/download` })
  return { items }
}

const mapResourcesToPage = (props, context) => ({
  invoice: `/api/admin/finance/invoices/${props.params.id}`,
  payments: `/api/admin/finance/invoices/${props.params.id}/payments`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Invoice)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
