import StripeCardField from '@apps/finance/admin/components/paymentfield/stripe/cardfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    medium: PropTypes.string
  }

  state = {
    data: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { programs, rights, team } = this.context.admin
    return {
      title: 't(New Signup)',
      method: 'POST',
      action: '/api/admin/truevail/admin/signups',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Agency)', name: 'agency_id', type: 'lookup', endpoint: '/api/admin/truevail/admin/agencies', valueKey: 'id', textKey: 'team.title', required: true },
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
            { label: 't(Organization)', name: 'organization', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: team.provider.id, required: true },
            { label: 't(Timezone)', name: 'timezone_id', type: 'lookup', endpoint: '/api/admin/timezones', valueKey: 'id', textKey: 'zone', required: true, defaultValue: 169 },
            { label: 't(Referred By)', name: 'referred_by', type: 'textfield' },
            ..._.includes(rights, 'subscriptions:access_app') ? [
              { label: 't(Credit Card)', name: 'payment', type: StripeCardField, stripeAccount: programs[0].stripe_id }              
            ] : []
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New
