import ProfileConnect from '@admin/components/profile_connect'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Profiles from './profiles'
import React from 'react'
import Edit from './edit'

class ProfileManager extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    types: PropTypes.array,
    onBack: PropTypes.func
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleConnect = this._handleConnect.bind(this)
  _handleEdit = this._handleEdit.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handleProfiles()
  }

  _getStack() {
    return {
      display_name: 'profile_manager',
      ref: this.stackRef
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleConnect() {
    const { types } = this.props
    const props = {
      types,
      onBack: this._handlePop,
      onDone: this._handlePop
    }
    this._handlePush(ProfileConnect, props)
  }

  _handleDone() {
    this.context.modal.close()
  }

  _handleEdit(profile) {
    const props = {
      profile,
      onBack: this._handlePop
    }
    this._handlePush(Edit, props)
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleProfiles() {
    const { types, onBack } = this.props
    const props = {
      types,
      onBack,
      onCancel: this._handleCancel,
      onConnect: this._handleConnect,
      onDone: this._handleDone,
      onEdit: this._handleEdit
    }
    this._handlePush(Profiles, props)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default ProfileManager