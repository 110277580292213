import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import WebRange from '../webrange'
import React from 'react'

class DisplayField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    omitFeatures: PropTypes.array,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    config: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.config) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { 
              name: 'display', 
              type: 'radiogroup', 
              options: [
                { value: 'block', text: 't(Block)' },
                { value: 'flex', text: 't(Flex)' },
                { value: 'grid', text: 't(Grid)' }
              ], 
              defaultValue: 'block', 
              value: config.display 
            },
            {
              type: 'fields',
              fields: [
                { 
                  label: 't(Columns)', 
                  name: 'columns', 
                  type: 'numericfield',
                  min: 1,
                  show: config.display === 'grid',
                  defaultValue: 2, 
                  value: config.columns 
                },
                { 
                  label: 't(Rows)', 
                  name: 'rows', 
                  type: 'numericfield',
                  min: 1,
                  show: config.display === 'grid', 
                  defaultValue: 2, 
                  value: config.rows 
                }
              ]
            },
            { 
              label: 't(Direction)', 
              name: 'flexDirection', 
              type: 'dropdown', 
              options: [
                { value: 'row', text: 't(Left to Right)' },
                { value: 'row-reverse', text: 't(Right to Left)' },
                { value: 'column', text: 't(Top to Bottom)' },
                { value: 'column-reverse', text: 't(Bottom to Top)' }
              ], 
              defaultValue: 'row', 
              value: config.flexDirection, 
              show: config.display === 'flex' 
            },
            { 
              label: 't(Wrap)', 
              name: 'flexWrap', 
              type: 'dropdown', 
              options: [
                { value: 'nowrap', text: 't(Dont wrap)' },
                { value: 'wrap', text: 't(Wrap)' },
                { value: 'wrap-reverse', text: 't(Wrap Reverse)' }
              ], 
              defaultValue: 'nowrap', 
              value: config.flexWrap, 
              show: config.display === 'flex' 
            },
            { 
              label: 't(Direction)', 
              name: 'gridAutoFlow', 
              type: 'dropdown', 
              options: [
                { value: 'row', text: 't(Left to Right)' },
                { value: 'column', text: 't(Top to Bottom)' }
              ], 
              defaultValue: 'row', 
              value: config.gridAutoFlow, 
              show: config.display === 'grid' 
            },
            { 
              label: 't(Gap)', 
              name: 'gap', 
              type: WebRange, units: [
                { name: 'px', min: 0, max: 100, step: 1 }
              ], 
              defaultValue: null, 
              value: config.gap , 
              show: ['flex','grid'].includes(config.display)
            },
            { 
              label: 't(Horizontal Alignment)', 
              name: 'blockHorizontalAlign', 
              type: 'dropdown', 
              options: [
                { value: 'default', text: 't(Default)' },
                { value: 'left', text: 't(Left)' },
                { value: 'center', text: 't(Center)' },
                { value: 'right', text: 't(Right)' }
              ], 
              defaultValue: 'default', 
              value: config.blockHorizontalAlign,
              show: config.display === 'block' 
            },
            { 
              label: 't(Horizontal Alignment)', 
              name: 'flexHorizontalAlign', 
              type: 'dropdown', 
              options: [
                { value: 'default', text: 't(Default)' },
                { value: 'left', text: 't(Left)' },
                { value: 'center', text: 't(Center)' },
                { value: 'right', text: 't(Right)' },
                { value: 'stretch', text: 't(Stretch)' },
                { value: 'around', text: 't(Space Around)' },
                { value: 'between', text: 't(Space Between)' },
                { value: 'evenly', text: 't(Space Evenly)' }
              ], 
              defaultValue: 'default', 
              value: config.flexHorizontalAlign,
              show: config.display === 'flex' 
            },
            { 
              label: 't(Vertical Alignment)', 
              name: 'flexVerticalAlign', 
              type: 'dropdown', 
              options: [
                { value: 'default', text: 't(Default)' },
                { value: 'top', text: 't(Top)' },
                { value: 'center', text: 't(Center)' },
                { value: 'bottom', text: 't(Bottom)' },
                { value: 'stretch', text: 't(Stretch)' },
                { value: 'around', text: 't(Space Around)' },
                { value: 'between', text: 't(Space Between)' },
                { value: 'evenly', text: 't(Space Evenly)' }
              ], 
              defaultValue: 'default', 
              value: config.flexVerticalAlign,
              show: config.display === 'flex' 
            },
            { 
              label: 't(Horizontal Alignment)', 
              name: 'gridHorizontalAlign', 
              type: 'dropdown', 
              options: [
                { value: 'default', text: 't(Default)' },
                { value: 'left', text: 't(Left)' },
                { value: 'center', text: 't(Center)' },
                { value: 'right', text: 't(Right)' },
                { value: 'stretch', text: 't(Stretch)' },
                { value: 'baseline', text: 't(Baseline)' }
              ], 
              defaultValue: 'default', 
              value: config.gridHorizontalAlign,
              show: config.display === 'grid' 
            },
            { 
              label: 't(Vertical Alignment)', 
              name: 'gridVerticalAlign', 
              type: 'dropdown', 
              options: [
                { value: 'default', text: 't(Default)' },
                { value: 'top', text: 't(Top)' },
                { value: 'center', text: 't(Center)' },
                { value: 'bottom', text: 't(Bottom)' },
                { value: 'stretch', text: 't(Stretch)' },
                { value: 'baseline', text: 't(Baseline)' }
              ], 
              defaultValue: 'default', 
              value: config.gridVerticalAlign,
              show: config.display === 'grid' 
            }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { config } = this.state
    this.props.onChange(config)
  }

  _handleSet(config) {
    this.setState({ config })
  }

}

export default DisplayField
