import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static propTypes = {
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleChange,
      onSuccess: this._handleDone,
      title: 't(New Recipient)',
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Recipient)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'user', text: 't(Choose a specific user)'},
                { value: 'email_address', text: 't(Enter an email address)' }
              ], defaultValue: 'user', value: config.strategy },
              this._getStrategy()
            ] }
          ]
        }
      ]
    }
  }

  _getStrategy() {
    const { config } = this.state
    if(config.strategy === 'email_address') {
      return { name: 'email_address', type: 'emailfield', required: true, placeholder: 'Enter an email', defaultValue: config.email }
    } else {
      return { name: 'user', type: 'lookup', required: true, prompt: 't(Choose a User)', endpoint: '/api/admin/users', valueKey: null, compareKey: 'id', textKey: 'full_name', format: UserToken, defaultValue: config.user_id }
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleDone(config) {
    this.props.onDone({
      strategy: config.strategy,
      ...config.strategy === 'email_address' ? {
        email_address: config.email_address
      } : {
        user_id: config.user.id,
        user: config.user
      }
    })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default New
