import Page from '@admin/components/page'
import Allocations from './allocations'
import Overview from './overview'
import React from 'react'
import Void from './void'

const getTabs = ({ allocations, refund }) => ({
  items: [
    { label: 't(Overview)', component: <Overview refund={ refund } /> },
    { label: 't(Allocations)', component: <Allocations refund={ refund } allocations={ allocations } /> }
  ]
})

const getTasks = ({ refund }) => {
  if(refund.voided_date) return null
  return {
    items: [
      {
        label: 't(Void Refund)',
        modal: <Void refund={ refund } />
      }
    ]
  }
}

const mapResourcesToPage = (props, context) => ({
  allocations: `/api/admin/finance/refunds/${props.params.id}/allocations`,
  refund: `/api/admin/finance/refunds/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Refund)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
