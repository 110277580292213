import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'
import React from 'react'

class StripeCardField extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    amount: PropTypes.number,
    elements: PropTypes.object,
    errors: PropTypes.object,
    payment: PropTypes.object,
    stripe: PropTypes.object,
    summary: PropTypes.object,
    tabIndex: PropTypes.number,
    token: PropTypes.string,
    onAuthorize: PropTypes.func,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onReady: PropTypes.func,
    onUpdate: PropTypes.func,
    onValid: PropTypes.func
  }

  state = {
    error: null,
    ready: false
  }

  _handleInit = this._handleInit.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { error, ready } = this.state
    if(!ready) return null
    return (
      <div className="finance-stripecardfield">
        <CardElement { ...this._getElement() } />
        { error &&
          <div className="maha-payment-error">{ error }</div>
        }
      </div>
    )
  }

  componentDidMount() {
    setTimeout(this._handleInit, 500) 
  }

  _getElement() {
    return {
      options: {
        disableLink: true
      }
    }
  }

  _handleError(error) {
    this.setState({ 
      error,
      status: 'pending' 
    })
  }

  _handleInit() {
    this.setState({ ready: true })
    this.props.onReady(this._handleValidate)
  }

  _handleValidate() {
    const { elements, stripe, onValid } = this.props
    stripe.createPaymentMethod({
      elements
    }).then(({error, paymentMethod}) => {
      if(error) return onValid(null, [error.message])
      const { card } = paymentMethod
      const { brand, last4, exp_month, exp_year } = card
      onValid({
        reference: `${brand}-${last4}`,
        card_type: brand,
        last_four: last4,
        expiration_month: exp_month,
        expiration_year: exp_year,
        stripe_id: paymentMethod.id
      })
    })
  }

}

class WrappedStripeCardField extends React.Component {

  static propTypes = {
    stripeAccount: PropTypes.string
  }

  stripe = null

  state = {
    ready: false
  }

  render() {
    if(!this.state.ready) return null
    return (
      <Elements stripe={ this.stripe }>
        <ElementsConsumer>
          { ({stripe, elements}) => (
            <StripeCardField { ...this.props } stripe={ stripe } elements={ elements } />
          ) }
        </ElementsConsumer>
      </Elements>
    )
  }

  componentDidMount() {
    const { stripeAccount } = this.props
    this.stripe = loadStripe(process.env.STRIPE_API_KEY, {
      stripeAccount
    })
    this.stripe.then(() => {
      this.setState({ ready: true })
    })
  }

}


export default WrappedStripeCardField
