import Connector from './connector'
import PropTypes from 'prop-types'
import React from 'react'

class Add extends React.Component {

  static propTypes = {
    answer: PropTypes.string,
    blocks: PropTypes.array,
    delta: PropTypes.number,
    editable: PropTypes.bool,
    parent: PropTypes.string,
    onAddStep: PropTypes.func,
    onNewStep: PropTypes.func
  }

  timeout = null

  state = {
    hovering: false
  }

  _handleDragEnter = this._handleDragEnter.bind(this)
  _handleDragLeave = this._handleDragLeave.bind(this)
  _handleDragOver = this._handleDragOver.bind(this)
  _handleDrop = this._handleDrop.bind(this)

  render() {
    const { hovering } = this.state
    return (
      <div className="flowchart-box-add">
        <Connector type="vertical"/>
        { hovering &&
          <div className="flowchart-box-add-label" />
        }
        <div className="flowchart-box-add-target" { ...this._getTarget() } />
      </div>
    )
  }

  _getTarget() {
    if(!this.props.editable) return {}
    return {
      onDragEnter: this._handleDragEnter,
      onDragLeave: this._handleDragLeave,
      onDragOver: this._handleDragOver,
      onDrop: this._handleDrop
    }
  }

  _handleDrop(e) {
    e.stopPropagation()
    e.preventDefault()
    const { answer, blocks, delta, parent, onAddStep, onNewStep } = this.props
    const type = e.dataTransfer.getData('type')
    const action = e.dataTransfer.getData('action')
    const block = _.find(blocks, { type, action })
    if(block.form) onNewStep({ type, action, parent, answer, delta })
    if(!block.form) onAddStep(type, action, parent, answer, delta, {})
    this.setState({
      hovering: false
    })
  }

  _handleDragEnter(e) {
    e.stopPropagation()
    e.preventDefault()
    if(this.state.hovering) return false
    this.setState({
      hovering: true
    })
  }

  _handleDragLeave() {
    this.setState({
      hovering: false
    })
  }

  _handleDragOver(e) {
    e.stopPropagation()
    e.preventDefault()
  }

}

export default Add
