import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Media from './media'
import React from 'react'

class Picker extends React.Component {

  static propTypes = {
    website: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  stackRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Media, this._getMedia.bind(this))
  }

  _getMedia() {
    const { website, onDone } = this.props
    return {
      website,
      onBack: this._handleBack,
      onDone
    }
  }

  _getStack() {
    return {
      display_name: 'mediafield',
      ref: this.stackRef
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Picker
