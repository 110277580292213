import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const options = {
  hosted: { title: 't(Hosted Sender)', description: 't(If you dont own a domain, you can send email using our hosted hosted email domain)' },
  custom: { title: 't(Custom Sender)', description: 't(If you own your own domain and have access to its DNS, you can send email from a self hosted email address)' }
}

const SenderTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ options[value].title } /><br /></strong>
    <T text={ options[value].description } />
  </div>
)

SenderTypeToken.propTypes = {
  value: PropTypes.string
}

export default SenderTypeToken
