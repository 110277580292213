import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Performance extends React.Component {

  static propTypes = {
    voice_campaign: PropTypes.object
  }

  render() {
    const { voice_campaign } = this.props
    return (
      <div className="crm-report">
        <div className="crm-report-title">
          Calls
        </div>
        <div className="crm-report-metrics">
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Calls
            </div>
            <div className="crm-report-metric-value">
              { voice_campaign.calls_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Active
            </div>
            <div className="crm-report-metric-value">
              { voice_campaign.active_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Converted
            </div>
            <div className="crm-report-metric-value">
              { voice_campaign.converted_count }
            </div>
          </div>
          <div className="crm-report-metric">
            <div className="crm-report-metric-title">
              Conversion Rate
            </div>
            <div className="crm-report-metric-value">
              { numeral(voice_campaign.converted_count / voice_campaign.calls_count).format('0.0%') }
            </div>
          </div>
        </div>
        <div className="crm-report-table">
          <table className="ui unstackable table">
            <tbody>
              <tr>
                <td>
                  Lost
                </td>
                <td className="right aligned">
                  { voice_campaign.lost_count }
                </td>
              </tr>
              <tr>
                <td>
                  Hangups
                </td>
                <td className="right aligned">
                  { voice_campaign.hangups_count }
                </td>
              </tr>
              <tr>
                <td>
                  Answering Machines
                </td>
                <td className="right aligned">
                  { voice_campaign.answering_machines_count }
                </td>
              </tr>
              <tr>
                <td>
                  Voicemails
                </td>
                <td className="right aligned">
                  { voice_campaign.voicemails_count }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

Performance.propTypes = {
  voice_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Performance)',
  panel: <Performance voice_campaign={ props.voice_campaign } />
})

export default Panel(null, mapPropsToPanel)
