import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import RadioGroup from '@admin/components/form/radiogroup'
import PropTypes from 'prop-types'
import React from 'react'

const details = {
  email: {
    communication: 'emails',
    address: 'email address'
  },
  sms: {
    communication: 'sms messages',
    address: 'phone number'
  },
  voice: {
    communication: 'calls',
    address: 'phone number'
  },
  mail: {
    communication: 'mailings',
    address: 'mailing address'
  }
}

class PurposeField extends React.PureComponent {

  static propTypes = {
    channel: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    purpose: 'marketing'
  }

  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <div className="purposefield">
        <RadioGroup { ...this._getPurpose() } />
        <div className="purposefield-explanation">
          <strong>NOTE:</strong> { this._getExplanation() }
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { value } = this.props
    if(value) {
      this.setState({
        purpose: value
      })
    }
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { purpose } = this.state
    if(purpose !== prevState.purpose) {
      this.props.onChange(purpose)
    }
  }

  _getExplanation() {
    const { channel } = this.props
    const { purpose } = this.state
    const detail = details[channel]
    if(purpose === 'marketing') {
      return `Marketing ${detail.communication} will only be delivered to matching recipients who have given consent to the program`
    }
    return `Transactional ${detail.communication} will be delivered to the first ${detail.address} for each matching recipients`
  }

  _getPurpose() {
    const { value } = this.props
    return {
      deselectable: false,
      format: PurposeToken,
      options: ['marketing','transactional'],
      value,
      onChange: this._handleUpdate
    }
  }

  _handleUpdate(purpose) {
    this.setState({ purpose })
  }

}

export default PurposeField
