import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Due extends React.Component {

  static propTypes = {
    task: PropTypes.object
  }

  render() {
    return (
      <div className={ this._getClass() }>
        { this._getDue() }
      </div>
    )
  }

  _getClass() {
    const { completed_at, due_at } = this.props.task
    if(!due_at) return 'due-token'
    const diff_hours = moment(due_at).diff(moment(),'hours')
    const overdue = moment().diff(moment(due_at),'seconds') > 0
    const classes = ['due-token']
    if(overdue && !completed_at) classes.push('overdue')
    if(!overdue && !completed_at && diff_hours > 0 && diff_hours <= 36) classes.push('upcoming')
    return classes.join(' ')
  }

  _getDue() {
    const { due_at } = this.props.task
    if(!due_at) return null
    const timestamp = moment(due_at)
    const today = moment()
    const yesterday = moment().subtract(1, 'day')
    const tomorrow = moment().add(1, 'day')
    const diff_days = timestamp.diff(today, 'days')
    if(timestamp.isSame(yesterday, 'day')) return 'Yesterday'
    if(timestamp.isSame(today, 'day')) return 'Today'
    if(timestamp.isSame(tomorrow, 'day')) return 'Tomorrow'
    if(diff_days > 0 && diff_days < 7) return timestamp.format('dddd')
    if(!timestamp.isSame(today, 'year')) return timestamp.format('MMM D, YYYY')
    return timestamp.format('MMM D')
  }

}

export default Due
