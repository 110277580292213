import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Scheduled Email Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } },
  collection: {
    endpoint: '/api/admin/calendar/campaigns/email_campaigns_scheduled',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program' }
      ] : []
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'envelope-o',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any email campaigns)'
    },
    entity: 'email campaign',
    onClick: (record) => context.router.push(`/admin/campaigns/email/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
