import useActionReducer from '@admin/hooks/use_action_reducer'
import { useRouterContext } from '@admin/components/router'
import * as selectors from './selectors'
import Collection from './collection'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const CollectionContainer = (props) => {

  const { router } = useRouterContext()

  const [state, handlers] = useActionReducer({
    display_name: 'collection',
    actions,
    props,
    reducer,
    selectors   
  })

  const mergedProps = {
    route: router.history.slice(-1)[0],
    ...props,
    ...state,
    ...handlers
  }

  return <Collection { ...mergedProps } />

}

CollectionContainer.propTypes = {}

export default CollectionContainer