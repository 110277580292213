import CompactExpenseTypeToken from '@apps/finance/admin/tokens/expense_type/compact'
import CompactProjectToken from '@apps/finance/admin/tokens/project/compact'
import CompactVendorToken from '@apps/finance/admin/tokens/vendor/compact'
import AllocationsToken from '@apps/finance/admin/tokens/allocations'
import Receipt from '@apps/finance/admin/components/receipt'
import Carousel from '@admin/components/carousel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const requiredField = (label, item, path, extra) => {
  if(!_.isNil(_.get(item, path))) {
    return { label, content: _.get(item, path), ...extra }
  } else  {
    return { label, content: 'REQUIRED', className: 'error' }
  }
}

const Overview = ({ expense }) => {

  const details = {
    audits: `finance_expenses/${expense.id}`,
    comments: `finance_expenses/${expense.id}`
  }
  
  details.items = [
    requiredField('User', expense, 'user.full_name'),
    requiredField('Date', expense, 'date', { content: expense.date, format: 'date' }),
    requiredField('Vendor', expense, 'vendor.name', { content: expense, format: CompactVendorToken }),
    requiredField('Account', expense, 'account.name')
  ]

  if(expense.allocations.length > 1 ) {
    details.items.push(requiredField('Total', expense, 'total', { content: expense.total, format: 'currency' }))
    details.items.push(requiredField('Tax', expense, 'tax_total', { content: expense.tax_total, format: 'currency' }))
    details.items.push({ component: <AllocationsToken allocations={ expense.allocations } item={ expense } /> })
  } else {
    details.items.push(requiredField('Project', expense, 'project.title', { content: expense, format: CompactProjectToken }))
    details.items.push(requiredField('Expense Type', expense, 'expense_type.title', { content: expense, format: CompactExpenseTypeToken }))
    details.items.push(requiredField('Description', expense, 'description', { content: expense.description }))
    details.items.push(requiredField('Amount', expense, 'amount', { content: expense.amount, format: 'currency' }))
    details.items.push(requiredField('Tax', expense, 'tax', { content: expense.tax, format: 'currency' }))
  }

  if(expense.receipts.length > 0) {
    const previews = expense.receipts.filter(receipt => receipt.status === 'processed' && (receipt.has_preview || receipt.is_image))
    const slides = previews.map((receipt, index) => <Receipt key={`receipt_preview_${index}`} preview={ true } value={ receipt } />)
    details.header = <Carousel slides={ slides } />
    details.items.unshift({ content: expense.receipts.map((receipt, index) => <Receipt key={`receipt_${index}`} preview={ false } value={ receipt } />), className: 'receipts' })
  } else {
    details.items.unshift(requiredField('Receipt', expense, 'receipts_ids'))
  }
  
  if(expense.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This expense was deleted)' }
  } else if(expense.status === 'incomplete') {
    details.alert = { color: 'grey', message: 't(This expense is missing required information)' }
  } else if(expense.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This expense has not yet been submitted)' }
  } else if(expense.status === 'submitted') {
    details.alert = { color: 'blue', message: 't(This expense has been submitted for review)' }
  } else if(expense.status === 'approved') {
    details.alert = { color: 'green', message: 't(This expense has been approved)' }
  } else if(expense.status === 'rejected') {
    details.alert = { color: 'red', message: 't(This expense has been rejected)' }
  } else if(expense.status === 'reviewed') {
    details.alert = { color: 'pink', message: 't(This expense has been reviewed)' }
  } else if(expense.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This expense was exported)' }
  }

  return <Details { ...details } />
}

Overview.propTypes = {
  expense: PropTypes.object
}

export default Overview
