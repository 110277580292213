import DuplicateToken from '@apps/crm/admin/tokens/duplicate'
import Panel from '@admin/components/panel'
import Merge from '../merge'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Duplicates)',
  collection: {
    endpoint: `/api/admin/crm/contacts/${props.contact.id}/duplicates`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'contact.display_name', primary: true, format: DuplicateToken }
    ],
    empty: {
      title: 't(No Duplicates)',
      text: 't(There are no potential duplicates)',
      icon: 'copy'
    },
    entity: 'order',
    defaultSort: { key: 'last_name', order: 'asc' },
    recordTasks: (record) => [
      { label: 't(Merge Contact)', modal: <Merge contact={ props.contact } duplicate={ record } programs={ props.programs } fields={ props.fields }  /> }
    ],
    onClick: (record) => context.router.push(`/admin/crm/contacts/${record.contact.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
