export const onAddAssigned = (assignee) => ({
  type: 'ADD_ASSIGNED',
  assignee
})

export const onRemoveAssigned = (index) => ({
  type: 'REMOVE_ASSIGNED',
  index
})

export const onRevokeAssigned = (index) => ({
  type: 'REVOKE_ASSIGNED',
  index
})

export const onSetAdding = (adding) => ({
  type: 'SET_ADDING',
  adding
})

export const onSetAssigned = (assigned) => ({
  type: 'SET_ASSIGNED',
  assigned
})

export const onSetQuery = (query) => ({
  type: 'SET_QUERY',
  query
})

export const onSetUnassigned = (assigned) => ({
  type: 'SET_UNASSIGNED',
  assigned
})

export const onUpdateAssigned = (assignee, index) => ({
  type: 'UPDATE_ASSIGNED',
  index,
  assignee
})