import Page from '@admin/components/page'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const getTabs = ({ app, vendor }) => ({
  items: [
    { label: 't(Overview)', component: <Overview vendor={ vendor } integration={ app.settings.integration } /> }
  ]
})

const getTasks = ({ audits, app, vendor }) => ({
  items: [
    {
      label: 'Edit Vendor',
      modal: <Edit vendor={ vendor } integration={ app.settings.integration } />
    }
  ]
})

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  vendor: `/api/admin/finance/vendors/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Vendor)',
  tabs: getTabs(resources, props),
  tasks: getTasks(resources, props, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
