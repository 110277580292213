import TextOverrideStyleField from '@admin/components/mjson_designer/components/textstylefield'
import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import IconStyleField from '@admin/components/mjson_designer/components/iconstylefield'
import ItemStyleField from '@admin/components/mjson_designer/components/itemstylefield'
import ContactField from '@admin/components/mjson_designer/components/contactfield'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import settings from '../settings'
import PropTypes from 'prop-types'
import React from 'react'

export const TextOverride = (props) => <ResponsiveField { ...props } component={ TextOverrideStyleField } />

class EditContactBlock extends React.Component {

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  _getForm() {
    const { device, entity, parent, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Contact', name: 'content.contact', type: ContactField, value: config.content.contact }
              ]
            },
            {
              label: 't(Options)',
              fields: [
                { label: 'Direction', name: 'styles.direction', type: 'dropdown', options: [
                  { value: 'vertical', text: 't(Vertical)' },
                  { value: 'horizontal', text: 't(Horizontal)' }
                ], defaultValue: 'vertical', value: config.styles.direction },
                { label: 't(Titles)', name: 'content.titles', type: 'radiogroup', options: [
                  { value: 'text', text: 't(text)' },
                  { value: 'icon', text: 't(Icon)' }
                ], defaultValue: 'text', value: config.content.titles }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            {
              label: 't(Contact)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Items)', name: 'styles.items', collapsing: true, collapsed: true,type: ItemStyleField, device, theme, defaultValue: {
                }, value: config.styles.items },
                { label: 't(Item)', name: 'styles.item', collapsing: true, collapsed: true,type: ItemStyleField, device, theme, defaultValue: {
                }, value: config.styles.item },
                { label: 't(Icon)', name: 'styles.icon', collapsing: true, collapsed: true,type: IconStyleField, theme, defaultValue: {
                  size: '32px'
                }, include: config.content.titles === 'icon', value: config.styles.icon },
                { label: 't(Title Style)', name: 'styles.titleText', collapsing: true, collapsed: true,type: TextOverride, theme, defaultValue: {
                  preset: 'heading',
                  color: {
                    value: 'default'
                  }
                }, include: config.content.titles === 'text', value: config.styles.titleText },
                { label: 't(Text Style)', name: 'styles.textText', collapsing: true, collapsed: true,type: TextOverride, theme, defaultValue: {
                  preset: 'text',
                  color: {
                    value: 'default'
                  }
                }, value: config.styles.textText }
              ]
            },
            ...styles.getIsFlexChild(parent) ? [
              styles.FlexChildSection(config, device, theme, website, type)
            ] : [],
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SizingSection(config, device, theme, website, type, true, true),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type),
            styles.VisibilitySection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: settings(config, type, entity)
        }
      ]
    }
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default EditContactBlock
