import Color from 'color'

const palette = ['#DB2828','#F2711C','#FBBD08','#B5CC18','#21BA45','#00B5AD','#2185D0','#6435C9','#A333C8','#E03997']

const colors = [
  false,
  ...new Array(9).fill(0).map((i, j) => {
    const color = Color('#000000')
    const ratio = ((255 / 8) * j) / 255
    const lightness = color.lightness()
    return color.lightness(lightness + (100 - lightness) * ratio).hex()
  }),
  ...new Array(7).fill(0).reduce((colors, i, j) => [
    ...colors,
    ...Object.values(palette).map((color, index) => {
      const adjustment = ((((400 / 6) * j) - 200) / 200) * -0.5
      return Color(color).lighten(adjustment).hex().toUpperCase()
    })
  ], [])
].map(hex => ({ value: hex, hex, text: hex }))

export default colors
