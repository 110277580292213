import Memberships from '../memberships/users'
import Page from '@admin/components/page'
import Overview from './overview'
import Members from './members'
import Edit from './edit'
import React from 'react'

const getTabs = ({ app, memberships, project }, props, { admin }) => ({
  items: [
    { label: 't(Overview)', component: <Overview project={ project } integration={ app.settings.integration } /> },
    { label: 't(Members)', component: <Members project={ project } memberships={ memberships } rights={ admin.rights } user={ admin.user } /> }
  ]
})

const getTasks = ({ app, memberships, project }, props, { admin }) => {

  const allowed = _.includes(admin.rights, 'finance:manage_configuration') || memberships.find(membership => {

    return  membership.user.id === admin.user.id && membership.type === 'owner'
  }) !== undefined

  return allowed ? {
    items: [
      {
        label: 't(Edit Project)',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: project.is_active,
        modal: <Edit project={ project } integration={ app.settings.integration } />
      }, {
        label: 't(Disable Project)',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: project.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/finance/projects/${project.id}/disable`,
          onFailure: (result) => context.flash.set('error', 't(Unable to disable this project)')
        }
      }, {
        label: 't(Enable Project)',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: !project.is_active,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/finance/projects/${project.id}/enable`,
          onFailure: (result) => context.flash.set('error', 't(Unable to enable this project)')
        }
      },{
        label: 't(Manage Members)',
        access: { rights: { $oneOf: ['finance:manage_projects'] } },
        show: project.is_active,
        modal: <Memberships project_id={ project.id } />
      }
    ]
  } : null
}

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  project: `/api/admin/finance/projects/${props.params.id}`,
  memberships: `/api/admin/finance/projects/${props.params.id}/memberships`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Project)',
  access: { rights: { $oneOf: ['finance:view_projects','finance:manage_projects'] } },
  tabs: getTabs(resources, props, context),
  tasks: getTasks(resources, props, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
