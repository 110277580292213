import FiltersField from '../filtersfield'
import OverlayField from '../overlayfield'
import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import ColorField from '../colorfield'
import WebRange from '../webrange'
import PropTypes from 'prop-types'
import React from 'react'

class BackgroundField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    background: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.background) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { background } = this.state
    if(!_.isEqual(background, prevState.background)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { theme, website } = this.props
    const { background } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { name: 'type', type: 'dropdown', options: [
              { value: 'none', text: 't(No Background)' },
              { value: 'color', text: 't(Color)' },
              { value: 'gradient', text: 't(Gradient)' },
              { value: 'image', text: 't(Image)' },
              { value: 'video', text: 't(Video)' },
              { value: 'map', text: 't(Map)' }
            ], value: background.type },
            ...background.type !== 'none' ? [
              ...background.type === 'color' ? [
                { label: 't(Color)', name: 'color', type: ColorField, theme, value: background.color }
              ] : [],
              ...background.type === 'gradient' ? [
                { label: 'Color 1', name: 'color1', type: ColorField, theme, value: background.color1 },
                { label: 'Color 2', name: 'color2', type: ColorField, theme, value: background.color2 },
                { label: 'Gradient Style', name: 'style', type: 'dropdown', options: [
                  { value: 'linear', text: 't(Linear)' },
                  { value: 'radial', text: 't(Radial)' }
                ], value: background.style },
                { label: 'Origin', name: 'origin', type: 'dropdown', options: [
                  { value: 'center', text: 't(Center)' },
                  { value: 'top left', text: 't(Top Left)' },
                  { value: 'top', text: 't(Top)' },
                  { value: 'top right', text: 't(Top Right)' },
                  { value: 'right', text: 't(Right)' },
                  { value: 'bottom right', text: 't(Bottom Right)' },
                  { value: 'bottom', text: 't(Bottom)' },
                  { value: 'bottom left', text: 't(Bottom Left)' },
                  { value: 'left', text: 't(Left)' }
                ], include: background.style === 'radial', value: background.origin },
                { label: 't(Direction)', name: 'direction', type: WebRange, units: [
                  { name: 'deg', min: 0, max: 360, step: 1 }
                ], defaultValue: '180deg', include: background.style === 'linear', value: background.direction },
                { label: 't(Start)', name: 'start', type: WebRange, units: [
                  { name: '%', min: 0, max: 100, step: 1 }
                ], defaultValue: '0%', value: background.start },
                { label: 't(End)', name: 'end', type: WebRange, units: [
                  { name: '%', min: 0, max: 100, step: 1 }
                ], defaultValue: '100%', value: background.end }
              ] : [],
              ...background.type === 'image' ? [
                { label: 't(Image)', name: 'image', type: 'mediafield', required: true, value: background.image, website },
                { label: 't(Attachment)', name: 'attachment', type: 'dropdown', options: [
                  { value: 'scroll', text: 't(Scroll)' },
                  { value: 'local', text: 't(Local)' },
                  { value: 'fixed', text: 't(Fixed)' }
                ], value: background.attachment },
                { label: 't(Size)', name: 'size', type: 'dropdown', options: [
                  { value: 'cover', text: 't(Cover)' },
                  { value: 'contain', text: 't(Contain)' },
                  { value: 'auto 100%', text: 't(Full Height)' },
                  { value: '100% auto', text: 't(Full Width)' },
                  { value: 'auto', text: 't(Actual Size)' }
                ], value: background.size },
                { label: 't(Position)', name: 'position', type: LinkedField, units: [
                  { name: '%', min: 0, max: 100, step: 1 }
                ], types: ['horizontal','vertical'], nullValues: ['0%'], defaultValue: '50%', value: background.position },
                { label: 'Repeat', name: 'repeat', type: 'dropdown', options: [
                  { value: 'no-repeat', text: 't(No Repeat)' },
                  { value: 'repeat-x', text: 't(Repeat X)' },
                  { value: 'repeat-y', text: 't(Repeat Y)' },
                  { value: 'space', text: 't(Space)' },
                  { value: 'round', text: 't(Round)' }
                ], include: background.size !== 'cover', value: background.repeat },
                { label: 't(Overlay Color)', name: 'overlayColor', collapsing: true, collapsed: true, type: OverlayField, theme, value: background.overlayColor },
                { label: 't(Filters)', name: 'filters', collapsing: true, collapsed: true, type: FiltersField, value: background.filters }
              ] : [],
              ...background.type === 'video' ? [
                { label: 't(Video)', name: 'video', type: 'videofield', required: true, value: background.video, website },
                { label: 't(Overlay Color)', name: 'overlayColor', collapsing: true, collapsed: true, type: OverlayField, theme, value: background.overlayColor },
                { label: 't(Filters)', name: 'filters', collapsing: true, collapsed: true, type: FiltersField, value: background.filters }
              ] : [],
              ...background.type === 'map' ? [
                { label: 't(Address)', name: 'address', type: 'addressfield', required: true, value: background.address },
                { label: 't(Zoom)', name: 'zoom', type: 'range', min: 1, max: 20, defaultValue: 16, value: background.zoom },
                { label: 't(Theme)', name: 'maptheme', type: 'radiogroup', options: [
                  { value: 'standard', text: 't(Standard)' },
                  { value: 'silver', text: 't(Silver)' },
                  { value: 'retro', text: 't(Retro)' },
                  { value: 'dark', text: 't(Dark)' },
                  { value: 'night', text: 't(Night)' },
                  { value: 'aubergine', text: 't(Aubergine)' }
                ], defaultValue: 'standard', value: background.maptheme },
                { label: 't(Overlay Color)', name: 'overlayColor', collapsing: true, collapsed: true, type: OverlayField, theme, value: background.overlayColor },
                { label: 't(Filters)', name: 'filters', collapsing: true, collapsed: true, type: FiltersField, value: background.filters }
              ] : []
            ] : []
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { background } = this.state
    this.props.onChange(background.type !== 'none' ? {
      type: background.type,
      ...background.type === 'color' ? {
        color: background.color
      } : {},
      ...background.type === 'gradient' ? {
        color1: background.color1,
        color2: background.color2,
        style: background.style,
        origin: background.origin,
        direction: background.direction,
        start: background.start,
        end: background.end
      } : {},
      ...background.type === 'image' ? {
        image: background.image,
        overlayColor: background.overlayColor,
        size: background.size,
        attachment: background.attachment,
        position: background.position,
        repeat: background.repeat,
        filters: background.filters
      } : {},
      ...background.type === 'video' ? {
        video: background.video,
        overlayColor: background.overlayColor,
        filters: background.filters
      } : {},
      ...background.type === 'map' ? {
        address: background.address,
        maptheme: background.maptheme,
        zoom: background.zoom,
        overlayColor: background.overlayColor,
        filters: background.filters
      } : {}
    } : null)
  }

  _handleSet(background) {
    this.setState({
      background: {
        type: 'none',
        color: null,
        color1: null,
        color2: null,
        style: 'linear',
        origin: 'center',
        direction: '180deg',
        start: '0%',
        end: '100%',
        image: null,
        attachment: 'scroll',
        size: 'cover',
        position: '50% 50%',
        overlayColor: null,
        repeat: 'no-repeat',
        filters: null,
        video: null,
        address: null,
        maptheme: 'standard',
        zoom: 16,
        ...background || {}
      }
    })
  }

}

export default BackgroundField
