import Allocations from '@apps/finance/admin/components/allocations'
import VendorToken from '@apps/finance/admin/tokens/vendor'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    check: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  state = {
    tax_total: 0.00,
    total: 0.00
  }

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { tax_total, total } = this.state
    const { check } = this.props
    return {
      title: 't(Edit Check Request)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/checks/${check.id}/edit`,
      action: `/api/admin/finance/checks/${check.id}`,
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Date Needed)', name: 'date_needed', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') },
            { label: 't(Vendor)', name: 'vendor_id', type: 'lookup', endpoint: '/api/admin/finance/vendors', valueKey: 'id', textKey: 'name', form: this._getVendorForm(), format: VendorToken },
            { label: 't(Delivery Method)', name: 'delivery_method', type: 'lookup', options: [
              { value: 'mail', text: 't(Mail)' },
              { value: 'pickup', text: 't(Pickup)' }
            ] },
            { label: 't(Invoice Number)', name: 'invoice_number', type: 'textfield' },
            { label: 't(Invoice)', name: 'receipt_ids', type: 'attachmentfield', allow: { content_types: ['application/pdf','images'] }, multiple: true, prompt: 'Upload Invoice' },
            { label: 't(Total)', name: 'total', type: 'moneyfield', required: true, placeholder: 't(Enter the full amount minus the tax)' },
            { label: 't(Tax)', name: 'tax_total', type: 'moneyfield', required: true, placeholder: 't(Enter the tax paid if any)' },
            { label: 't(Allocations)', name: 'allocations', type: Allocations, projectEndpoint: `/api/admin/finance/users/${check.user.id}/projects`, tax_total, total }
          ]
        }
      ]
    }
  }

  _getVendorForm() {
    return {
      title: 't(New Vendor)',
      method: 'POST',
      action: '/api/admin/finance/vendors',
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield' },
            { label: 't(Address)', name: 'address', type: 'addressfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(name, value) {
    if(name === 'tax_total') {
      this.setState({
        tax_total: Number(value)
      })
    }
    if(name === 'total') {
      this.setState({
        total: Number(value)
      })
    }
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
