import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class SMSCampaign extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.array,
    enrollment: PropTypes.object,
    sms_campaign: PropTypes.object
  }

  render() {
    const { actions, enrollment, sms_campaign } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>SMS Campaign</td>
                <td><Button { ...this._getCampaign() } /></td>
              </tr>
              <tr>
                <td>Program Phone Number</td>
                <td>{ sms_campaign.phone_number.formatted }</td>
              </tr>
              <tr>
                <td>Contact Phone Number</td>
                <td>{ enrollment.phone_number.formatted }</td>
              </tr>
            </tbody>
          </table>
          <Actions enrollment={ enrollment } actions={ actions } trigger={ sms_campaign.trigger } />
        </div>
      </div>
    )
  }

  _getCampaign() {
    const { sms_campaign } = this.props
    return {
      label: sms_campaign.title,
      className: 'link',
      route: `/admin/campaigns/sms/${sms_campaign.id}`
    }
  }

}

const mapResources = (props, context) => ({
  actions: `/api/admin/campaigns/sms/${props.activity.data.sms_campaign_id}/enrollments/${props.activity.data.enrollment_id}/actions`,
  sms_campaign: `/api/admin/campaigns/sms/${props.activity.data.sms_campaign_id}`,
  enrollment: `/api/admin/campaigns/sms/${props.activity.data.sms_campaign_id}/enrollments/${props.activity.data.enrollment_id}`
})

export default Container(mapResources)(SMSCampaign)
