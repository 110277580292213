import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const SocialLinkToken = ({ value }) => (
  <div className="websites-social-links-token">
    <div className="websites-social-links-token-icon">
      <div className="websites-social-links-token-image">
        <Img src={ `/icons/social/w=18&fg=FFFFFF&dpi=2/${value.service}.png` } />
      </div>
    </div>
    <div className="websites-social-links-token-label">
      { value.text }
    </div>
  </div>
)

SocialLinkToken.propTypes = {
  value: PropTypes.object
}

export default SocialLinkToken
