import Sessions from '@admin/components/sessions'
import Activate from '@admin/components/activate'
import Signin from '@admin/components/signin'
import Loader from '@admin/components/loader'
import Reset from '@admin/components/reset'
import PropTypes from 'prop-types'
import NoTeams from './noteams'
import React from 'react'

const whitelist = new RegExp('^/(activate|signin|reset)')

export const AdminContext = React.createContext(null)
AdminContext.displayName = 'AdminContext'

export const useAdminContext = () => React.useContext(AdminContext)

class Admin extends React.Component {

  static childContextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      analytics: PropTypes.object,
      flash: PropTypes.object,
      local_storage: PropTypes.object,
      locale: PropTypes.object,
      logger: PropTypes.object,
      maha: PropTypes.object,
      network: PropTypes.object
    }),
    account: PropTypes.object,
    active: PropTypes.number,
    advisor: PropTypes.object,
    agency: PropTypes.object,
    apps: PropTypes.array,
    children: PropTypes.any,
    features: PropTypes.object,
    ip: PropTypes.string,
    preferences: PropTypes.object,
    programs: PropTypes.array,
    redirect: PropTypes.any,
    rights: PropTypes.array,
    router: PropTypes.object,
    teams: PropTypes.array,
    team: PropTypes.object,
    token: PropTypes.string,
    user: PropTypes.object,
    onChooseTeam: PropTypes.func,
    onSetAccount: PropTypes.func,
    onSetActive: PropTypes.func,
    onSetRedirect: PropTypes.func,
    onSetSession: PropTypes.func,
    onSetTeams: PropTypes.func,
    onSignin: PropTypes.func,
    onSignout: PropTypes.func
  }

  state = {
    ready: false
  }

  _handleChooseTeam = this._handleChooseTeam.bind(this)
  _handleFetchAccount = _.debounce(this._handleFetchAccount.bind(this), 250)
  _handleFetchSession = _.debounce(this._handleFetchSession.bind(this), 250)
  _handleForceSignout = this._handleForceSignout.bind(this)
  _handleHasFeature = this._handleHasFeature.bind(this)
  _handleHasRights = this._handleHasRights.bind(this)
  _handleSignin = this._handleSignin.bind(this)
  _handleSignout = this._handleSignout.bind(this)
  _handleSignUrl = this._handleSignUrl.bind(this)

  render() {
    return (
      <AdminContext.Provider value={ this.getChildContext() }>
        { this._getComponent() }
      </AdminContext.Provider>
    )
  }

  componentDidMount() {
    this._handleIntent()
    this._handleGetActive()
  }

  componentDidUpdate(prevProps) {
    const { account, active } = this.props
    if(!_.isEqual(account, prevProps.account)) {
      if(account) this._handleSetAccount()
      if(!account) this._handleRemoveAccount()
    }
    if(active !== prevProps.active) {
      if(active) this._handleSetActive()
      if(!active) this._handleRemoveActive()
      if(active && prevProps.active) this._handleFetchSession()
    }
  }

  getChildContext() {
    const { account, active, advisor, agency, apps, features, preferences, programs, rights, team, teams, token, user } = this.props
    return {
      admin: {
        account,
        active,
        advisor,
        agency,
        apps,
        features,
        preferences,
        programs,
        rights,
        team,
        teams,
        token,
        user,
        hasFeature: this._handleHasFeature,
        hasRights: this._handleHasRights,
        chooseTeam: this._handleChooseTeam,
        signin: this._handleSignin,
        signout: this._handleSignout,
        signUrl: this._handleSignUrl
      }
    }
  }

  _getComponent() {
    const { account, router, teams, token, user } = this.props
    const { pathname } = router.location
    if(!this.state.ready) return <Loader />
    if(account && teams?.length === 0) return <NoTeams { ...this._getNoTeams() } />
    if(account && teams?.length > 0 && !token) return <Loader />
    if(/^\/activate/.test(pathname)) return <Activate />
    if(/^\/reset/.test(pathname)) return <Reset />
    if(/^\/signin/.test(pathname)) return <Signin />
    return user ? <Sessions /> : <Loader />
  }

  _getNoTeams() {
    const { onSignout } = this.props
    return { onSignout }
  }

  _handleChooseTeam(active = 0, redirect = null) {
    if(active === this.props.active) return this._handleRedirectToSaved()
    this.props.onChooseTeam(active, redirect)
  }

  _handleForceSignout() {
    this._handleSignout('t(Your session has been manually terminated)')
  }

  _handleFetchAccount() {
    const { account } = this.props
    this.props.context.network.request({
      endpoint: '/api/admin/signin/account',
      method: 'GET',
      token: account?.token,
      onSuccess: ({ data }) => {
        this.props.onSetAccount(data)
        setTimeout(() => {
          this._handleFetchTeams()
        }, 100)
      },
      onFailure: () => {
        this._handleRedirectToSignin()
      }
    })
  }

  _handleFetchSession() {
    const { active, account, teams } = this.props
    if(!teams) return
    const team = active ? _.find(teams, { id: active }) : null
    this.props.context.network.request({
      endpoint: '/api/admin/signin/session',
      method: 'POST',
      token: account.token,
      body: { 
        team_id: team?.id || teams[0].id
      },
      onSuccess: ({ data }) => {
        this.props.context.maha.setToken(data.token)
        this.props.onSetSession(data)
        setTimeout(() => {
          const { team, user } = data
          this._handleLoggerLogin()
          this._handleRedirectToSaved()
          this._handleJoin(account, team, user)
        }, 100)
      },
      onFailure: () => {}
    })
  }

  _handleFetchTeams() {
    const { account } = this.props
    this.props.context.network.request({
      endpoint: '/api/admin/signin/teams',
      method: 'GET',
      token: account.token,
      onSuccess: ({ data }) => {
        this.props.onSetTeams(data)
        setTimeout(() => {
          this._handleFetchSession()
        }, 100)
      },
      onFailure: () => {}
    })
  }

  _handleGetActive() {
    this.props.context.local_storage.get({ 
      key: 'active', 
      onSuccess: (active) => {
        if(active) this.props.onSetActive(active)
        setTimeout(() => {
          this._handleGetAccount()
        }, 100)
      },
      onFailure: () => {}
    })
  }

  _handleGetAccount() {
    this.props.context.local_storage.get({ 
      key: 'account', 
      onSuccess: (account) => {
        if(!account?.token) return this._handleRedirectToSignin()
        this.props.onSetAccount(account)
        setTimeout(() => {
          this._handleFetchAccount()
        }, 100)
      },
      onFailure: () => {}
    })
  }

  _handleHasFeature(feature) {
    const { features } = this.props
    return features[feature] === true
  }

  _handleHasRights({ oneOf, allOf, noneOf }) {
    const { rights } = this.props
    if(oneOf) return _.intersection(rights, oneOf).length > 0
    if(allOf) return _.intersection(rights, allOf).length === allOf.length
    if(noneOf) return _.intersection(rights, noneOf).length === 0
    return false
  }

  _handleIntent() {
    const { location } = this.props.router
    const { search, hash } = location
    const pathname = location.pathname.replace(/\/$/, '')
    if(!whitelist.test(pathname)) {
      this.props.router.replace({ pathname: '/', search: null, hash: null })
      if(pathname.length > 1) this.props.onSetRedirect({ pathname, search, hash })  
    }
    this.setState({ ready: true })
  }

  _handleJoin(account, team, user) {
    const { network } = this.props.context
    network.subscribe({ channel: `/accounts/${account.id}`, action: 'session', handler: this._handleFetchSession })
    network.subscribe({ channel: `/teams/${team.id}`, action: 'session', handler: this._handleFetchSession })
    network.subscribe({ channel: `/users/${user.id}`, action: 'session', handler: this._handleFetchSession })
    network.subscribe({ channel: `/accounts/${account.id}`, action: 'signout', handler: this._handleForceSignout })
    network.subscribe({ channel: `/signins/${account.signin.id}`, action: 'signout', handler: this._handleForceSignout })
  }

  _handleLeave(account, team, user) {
    const { network } = this.props.context
    network.unsubscribe({ channel: `/accounts/${account.id}`, action: 'session', handler: this._handleFetchSession })
    network.unsubscribe({ channel: `/teams/${team.id}`, action: 'session', handler: this._handleFetchSession })
    network.unsubscribe({ channel: `/users/${user.id}`, action: 'session', handler: this._handleFetchSession })
    network.unsubscribe({ channel: `/accounts/${account.id}`, action: 'signout', handler: this._handleForceSignout })
    network.unsubscribe({ channel: `/signins/${account.signin.id}`, action: 'signout', handler: this._handleForceSignout })
  }

  _handleLoggerLogin() {
    const { account, ip, user, team } = this.props
    this.props.context.analytics.login(account, ip, team)
    this.props.context.logger.signin(user)
  }

  _handleRedirectToSignin() {
    const { flash } = this.props.context
    const { router } = this.props
    const { pathname } = router.location
    const { redirect } = this.props
    if(pathname.match(/(activate|reset)/)) return
    if(redirect) flash.set('error', 't(You must first signin!)')
    router.replace('/signin')
  }

  _handleRedirectToSaved() {
    const { router, team } = this.props
    const { redirect } = this.props
    const whitelist = new RegExp('^/(activate|signin|reset)')
    this.props.onSetRedirect(null)
    const lastRoute = router.history.slice(-1)[0].pathname
    if(lastRoute === '/' || lastRoute ===  `/${team.subdomain}` ) {
      router.replace(`/${team.subdomain}`)
    } else if(whitelist.test(lastRoute)) {
      router.replace(`/${team.subdomain}`)
    } else if(lastRoute !== `/${team.subdomain}`) {
      router.push(`/${team.subdomain}`)
    }
    if(!redirect) return
    if(redirect.pathname === `/${team.subdomain}`) return
    if(whitelist.test(redirect.pathname)) return
    router.push(redirect)
  }

  _handleRemoveAccount() {
    this.props.context.local_storage.remove({ 
      key: 'account', 
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleRemoveActive() {
    this.props.context.local_storage.remove({ 
      key: 'active', 
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleSetAccount() {
    const { account } = this.props
    this.props.context.local_storage.set({ 
      key: 'account', 
      value: {
        token: account.token
      },
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleSetActive() {
    const { active } = this.props
    this.props.context.local_storage.set({ 
      key: 'active', 
      value: active,
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleSetLocale() {
    const { account } = this.props
    this.props.context.locale.set(account.language)
  }

  _handleSignin(account, active) {
    if(active) this.props.onSetActive(active)
    this.props.onSetAccount(account)
    setTimeout(() => {
      this._handleFetchTeams()
    }, 100)
  }

  _handleSignout(message) {
    const { account, team, user } = this.props
    this.props.context.network.request({
      endpoint: '/api/admin/signout',
      method: 'DELETE',
      onSuccess: () => {
        this._handleLeave(account, team, user)
        setTimeout(() => {
          this.props.onSignout()
          this.props.router.reset('/')
          this.props.context.flash.set('info', message || 't(You have been successfully signed out)')
          this._handleRedirectToSignin()
        }, 100)
      },
      onFailure: () => {}
    })
  }

  _handleSignUrl(url) {
    const { token } = this.props
    const joiner = /\?/.test(url) ? '&' : '?'
    return `${process.env.ADMIN_HOST}${url}${joiner}token=${token}`
  }

}

export default Admin
