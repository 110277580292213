import Panel from '@admin/components/panel'
import New from '../aliases/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Aliases)',
  collection: {
    endpoint: `/api/admin/websites/websites/${props.website.id}/aliases`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Path)', key: 'path', primary: true },
      { label: 't(Destination)', key: 'destination' },
      { label: 't(Primary)', key: 'is_primary', collapsing: true, format: 'check_times' }
    ],
    empty: {
      icon: 'font',
      title: 't(No URL aliases)',
      text: 't(You have not yet created any pages for this site)',
      buttons: [
        { label: 't(Create Alias)', modal: <New website={ props.website } /> }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Delete Alias)',
        confirm: 't(Are you sure you want to delete this alias?)',
        request: {
          endpoint: `/api/admin/websites/websites/${props.website.id}/aliases/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 't(Unable to delete this alias)')
        }
      }
    ],
    entity: 'page',
    defaultSort: { key: 'path', order: 'asc' },
    selectable: true,
    buttons: (selected, onSuccess) => [
      {
        label: 't(Delete all)',
        color: 'red',
        modal: <div>foo</div>
      }
    ]
  },
  tasks: {
    items: [
      {
        label: 't(Regenerate Aliases)',
        confirm: 't(Are you sure you want to regenerate all aliases?)',
        request: {
          endpoint: `/api/admin/websites/websites/${props.website.id}/aliases/regenerate`,
          method: 'POST',
          onSuccess: (result) => context.flash.set('success', 't(Sucessfully to regenerated aliases)'),
          onFailure: (result) => context.flash.set('error', 't(Unable to regenerate aliases)')
        }
      },
      { label: 'Create Alias', modal: <New website={ props.website } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
