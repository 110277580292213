import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Page from '@admin/components/page'
import React from 'react'

const getEntityEndpoint = (params, website) => {
  if(!params.versionable) return `/websites_pages/${website.home_page_id}`
  return `/${params.versionable}`
}

const getDesigner = (props, context, resources) => ({
  editable: canAccess({ rights: { $allOf: ['websites:manage_websites'] } }, context.admin),
  endpoint: getEntityEndpoint(props.params, resources.website),
  root_route: `/websites/${resources.website.id}/design`,
  type: 'page',
  themeable: false,
  website: resources.website,
  versionable: true
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: `${resources.website.title} - Design`,
  component: <MJSONDesigner { ...getDesigner(props, context, resources) } />
})

const mapResourcesToPage = (props, context) => ({
  website: `/api/admin/websites/websites/${props.params.website_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
