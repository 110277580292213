export const INITIAL_STATE = {
  response: null,
  status: 'pending',
  url: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CLEAR':
    return {
      response: null,
      status: 'pending',
      url: null
    }

  case 'SET_RESPONSE':
    return {
      ...state,
      response: action.response,
      status: action.status,
      url: action.url
    }

  case 'SET_STATUS':
    return {
      ...state,
      status: action.status
    }

  case 'SET_URL':
    return {
      ...state,
      url: action.url
    }

  default:
    return state

  }

}

export default reducer
