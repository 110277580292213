const getArraySegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = segment.name

  return {
    [`ADD_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: [
        ...state[key],
        action.item
      ]
    }),
    [`CLEAR_${typeKey}`]: (state) => ({
      ...state,
      [key]: []
    }),
    [`MOVE_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: ((action.from < action.to) ? [
        ...state[key].slice(0, action.from),
        ...state[key].slice(action.from + 1, action.to + 1),
        state[key][action.from],
        ...state[key].slice(action.to + 1)
      ] : [
        ...state[key].slice(0, action.to),
        state[key][action.from],
        ...state[key].slice(action.to, action.from),
        ...state[key].slice(action.from + 1)
      ])
    }),
    [`REMOVE_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: state[key].filter((element, index) => {
        return index !== action.index
      })
    }),
    [`SET_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: action.items
    }),
    [`UPDATE_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: state[key].map((item, index) => {
        return index === action.index ? action.item : item
      })
    })

  }

}

const getBooleanSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = segment.name

  return {
    [`SET_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: action.value
    }),
    [`TOGGLE_${typeKey}`]: (state) => ({
      ...state,
      [key]: !state[key]
    })
  }
  
}

const getStringSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = segment.name

  return {
    [`CLEAR_${typeKey}`]: (state) => ({
      ...state,
      [key]: ''
    }),
    [`SET_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: action.value
    })
  }
  
}

const getVarSegment = (segment) => {

  const typeKey = _.snakeCase(segment.name).toUpperCase()
  const key = segment.name

  return {
    [`CLEAR_${typeKey}`]: (state) => ({
      ...state,
      [key]: null
    }),
    [`SET_${typeKey}`]: (state, action) => ({
      ...state,
      [key]: action.value
    })
  }
  
}

const getSegment = (segment) => {
  if(segment.type === 'array') return getArraySegment(segment)
  if(segment.type === 'boolean') return getBooleanSegment(segment)
  if(segment.type === 'string') return getStringSegment(segment)
  if(segment.type === 'var') return getVarSegment(segment)
  return { [segment.handle]: segment.handler}
}

const getReducer = (segments) => {

  const reducers = segments.reduce((reducer, segment) => ({
    ...reducer,
    ...getSegment(segment)  
  }), {})

  const initial_state = segments.reduce((state, segment) => ({
    ...state,
    ...segment.name ? {
      [segment.name]: segment.default
    } : {}
  }), {})

  return (prevState = initial_state, action) => {
    if(!action) return prevState
    if(!reducers[action.type]) return prevState
    return reducers[action.type](prevState, action)
  }

}

export default getReducer