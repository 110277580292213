import Fields from '@admin/components/fields'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class TypeFields extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    dataset: PropTypes.object,
    type: PropTypes.object
  }

  render() {
    return <Fields { ...this._getFields()}  />
  }

  _getFields() {
    const { dataset, type } = this.props
    return {
      parent_type: 'datasets_types',
      parent_id: type.id,
      datasources: dataset.types.map(type => ({
        label: type.title.value,
        endpoint: `/api/admin/datasets/datasets/${dataset.id}/types/${type.id}/records`,
        valueKey: 'id',
        textKey: 'title',
        dataset_id: dataset.id,
        type_id: type.id
      }))
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Fields)',
  panel: <TypeFields dataset={ props.dataset } type={ props.type } />
})

export default Panel(null, mapPropsToPanel)
