import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    field: {
      code: getCode(10)
    }
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { field } = this.state
    const typefields = this._getTypeFields()
    return {
      title: 't(New Field)',
      method: 'POST',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'code', type: 'hidden', value: field.code },
            { label: 't(Input Type)', name: 'type', type: 'dropdown', options: this._getTypes(), defualtValue: 'textfield', required: true },
            ...typefields ? [
              { label: 't(Name)', name: 'name', type: 'tokenfield', required: true },
              { label: 't(Label)', name: 'label', type: 'textfield' },
              { label: 't(Instructions)', name: 'instructions', type: 'htmlfield' },
              { label: 't(Required)', name: 'required', type: 'checkbox', prompt: 't(This field is required)' },
              ...typefields
            ] : []
          ]
        }
      ]
    }
  }

  _getTypes() {
    return [
      { value: 'addressfield', text: 't(Address Field)' },
      { value: 'checkbox', text: 't(Checkbox)' },
      { value: 'checkboxes', text: 't(Checkbox Group)' },
      { value: 'datefield', text: 't(Date Field)' },
      { value: 'dropdown', text: 't(Dropdown)' },
      { value: 'emailfield', text: 't(Email Field)' },
      { value: 'filefield', text: 't(File Field)' },
      { value: 'moneyfield', text: 't(Money Field)' },
      { value: 'numberfield', text: 't(Number Field)' },
      { value: 'phonefield', text: 't(Phone Field)' },
      { value: 'radiogroup', text: 't(Radio Group)' },
      { value: 'textarea', text: 't(Text Area)' },
      { value: 'textfield', text: 't(Text Field)' },
      { value: 'timefield', text: 't(Time Field)' }
    ]
  }

  _getTypeFields() {
    const { field } = this.state
    const config = field.config || {}
    if(_.includes(['textfield','textarea'], field.type)) {
      return [
        { label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield',  defaultValue: config.placeholder },
        { type: 'fields', fields: [
          { label: 't(Min Length)', name: 'config.min_length', type: 'numberfield', placeholder: 't(Enter maximium character length)', defaultValue: config.min_length },
          { label: 't(Max Length)', name: 'config.max_length', type: 'numberfield', placeholder: 't(Enter minimum character length)', defaultValue: config.max_length }
        ] }
      ]
    }
    if(_.includes(['addressfield','datefield','emailfield','phonefield','timefield'], field.type)) {
      return [
        { label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield', defaultValue: config.placeholder }
      ]
    }
    if(field.type === 'checkbox') {
      return [
        { label: 't(Prompt)', name: 'config.prompt', type: 'htmlfield', defaultValue: config.prompt }
      ]
    }
    if(field.type === 'numberfield') {
      return [
        { label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield', defaultValue: config.placeholder },
        { type: 'fields', fields: [
          { label: 't(Min)', name: 'config.min', type: 'numberfield', placeholder: 't(Enter maximium value)', defaultValue: config.min },
          { label: 't(Max)', name: 'config.max', type: 'numberfield', placeholder: 't(Enter minimum value)', defaultValue: config.max }
        ] }
      ]
    }
    if(_.includes(['datefield','timefield'], field.type)) {
      return [
        { label: 't(Placeholder)', name: 'config.placeholder', type: 'textfield', defaultValue: config.placeholder }
      ]
    }
    if(_.includes(['checkboxes','radiogroup','dropdown'], field.type)) {
      return [
        { label: 't(Options)', name: 'config.options', type: 'tablefield', columns: [
          { label: 't(Value)', key: 'value' },
          { label: 't(Text)', key: 'text' }
        ], defaultValue: config.options }
      ]
    }
    return null
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(field) {
    this.setState({
      field: {
        ...this.state.field,
        ...field
      }
    })
  }

  _handleSuccess(field) {
    this.props.onDone({
      ..._.omit(field, ['strategy'])
    })
    this.context.modal.close()
  }

}

export default New
