import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'

class Stages extends React.Component {

  static propTypes = {
    stages: PropTypes.array
  }

  render() {
    const { stages } = this.props
    return (
      <div className="stages-token">
        <div className="stages-token-title">
          Stages
        </div>
        { stages.map((stage, index) => (
          <div className="stages-token-stage" key={`stage_${index}`}>
            <div className="stages-token-stage-label">
              { stage.title }
            </div>
            <div className="stages-token-stage-probability">
              { stage.probability }%
            </div>
          </div>
        ))}
      </div>
    )
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Stages)',
  panel: <Stages stages={ props.stages } />,
  tasks: {
    items: [
      { label: 'Edit Stages', modal: <Edit pipeline={ props.pipeline } /> }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  stages: `/api/admin/sales/pipelines/${props.pipeline.id}/stages`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
