import PropTypes from 'prop-types'
import React from 'react'

const ChangeStrategyToken = ({ value }) => (
  <div className="token">
    { value === 'prorate' &&
      <>
        <strong>Prorated Charges</strong><br />
        Charge the contact for a prorated split of the old and new subscriptions
      </>
    }
    { value === 'backdate' &&
      <>
        <strong>Backdate Changes</strong><br />
        Backdate changes to the beginning of billing cycle
      </>
    }
  </div>
)

ChangeStrategyToken.propTypes = {
  value: PropTypes.object
}

export default ChangeStrategyToken
