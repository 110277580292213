import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

const Domain = ({ domain, domain_segment, params, segment }) => {

  const domain_link = domain ? {
    label: domain.name,
    className: 'link',
    route: `/truevail/admin/domains/${domain.id}`
  } : null

  const segment_link = segment ? {
    label: segment.text,
    className: 'link',
    route: `/truevail/admin/segments/${segment.id}`
  } : null

  const domain_segment_link = domain_segment ? {
    label: `${domain_segment.domain.name} - ${domain_segment.segment.text}`,
    className: 'link',
    route: `/truevail/admin/domain_segments/${domain_segment.id}`
  } : null

  const details = {
    sections: [
      {
        title: 't(Details)',
        items: [
          ...domain ? [
            { label: 't(Domain)', content: <Button {...domain_link } /> }
          ] : segment ? [
            { label: 't(Segment)', content: <Button {...segment_link } /> }
          ] : domain_segment ? [
            { label: 't(Domain Segment)', content: <Button {...domain_segment_link } /> }
          ] : [],
          ...params.interval === 'day' ? [
            { label: 't(Interval)', content: <Date datetime={ params.value } show={['date']} /> }
          ] : [
            { label: 't(Interval)', content: <Date datetime={ params.value } /> }
          ]
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Domain.propTypes = {
  domain: PropTypes.object,
  domain_segment: PropTypes.object,
  segment: PropTypes.object,
  params: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Domain domain={ props.domain } domain_segment={ props.domain_segment } segment={ props.segment } params={ props.params } />
})

export default Panel(null, mapPropsToPanel)
