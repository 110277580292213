import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  random: {
    title: 't(Randomized)',
    text: 't(Schedule campaigns to selected days in a uniqeuly randomized order for each advisor)'
  },
  sequential: {
    title: 't(Sequential)',
    text: 't(Schedule campaigns to selected days in the same sequential order for all advisors)'
  }
}

const SchedulingStrategyToken = ({ value }) => (
  <div className="maha-token">
    <div className="maha-token-details">
      <strong><T text={ strategies[value].title } /></strong><br />
      <span><T text={ strategies[value].text } /></span>
    </div>
  </div>
)

SchedulingStrategyToken.propTypes = {
  value: PropTypes.string
}

export default SchedulingStrategyToken
