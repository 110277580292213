import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const AllowancesToken = ({ allowances, plan }) => (
  <div className="subscriptions-allowances-token">
    <table className="ui celled compact unstackable table">
      <thead>
        <tr>
          <th>Item</th>
          <th className="collapsing">Included</th>
          { plan.type === 'paid' &&
            <th className="collapsing">Unit Price</th>
          }
        </tr>
      </thead>
      <tbody>
        { allowances.map((allowance, index) => (
          <tr key={`allowance_${index}`}>
            <td>
              { allowance.resource.title.value } { !allowance.resource.is_active &&
                <span className="alert">INACTIVE</span>
              }
            </td>
            <td className="right aligned">{ allowance.included }</td>
            { plan.type === 'paid' &&
              <td className="right aligned">{ numeral(allowance.price).format('0.00') }</td>
            }
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

AllowancesToken.propTypes = {
  allowances: PropTypes.array,
  plan: PropTypes.object
}

export default AllowancesToken
