import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(SMS)',
  collection: {
    endpoint: `/api/admin/team/usage/${props.usage.id}/smses`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(From)', key: 'from_number.formatted', primary: true },
      { label: 't(To)', key: 'to_number.formatted', primary: true },
      { label: 't(Direction)', key: 'direction', collapsing: true },
      { label: 't(Media)', key: 'num_media', collapsing: true, align: 'right' },
      { label: 't(Sent/Received)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Messages)',
      text: 't(No sms messages were sent or received during this billing cycle)'
    },
    entity: 'messages'
  }
})

export default Panel(null, mapPropsToPanel)
