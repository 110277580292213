import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class RatioField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    ratio: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.ratio) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { ratio } = this.state
    if(!_.isEqual(ratio, prevState.ratio)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleUpdate(value)
    }
  }

  _getForm() {
    const { ratio } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'orientation', type: 'dropdown', options: [
              { value: 'landscape', text: 't(Landscape)' },
              { value: 'portrait', text: 't(Portrait)' }
            ], defaultValue: 'landscape', value: ratio.orientation },
            { name: 'landscape', type: 'radiogroup', options: [
              { value: 1, text: 't(Square) (1:1)' },
              { value: 2, text: 't(Half Square) (2:1)' },
              { value: 16/9, text: '16:9' },
              { value: 7/5, text: '7:5' },
              { value: 4/3, text: '4:3' },
              { value: 5/3, text: '5:3' },
              { value: 3/2, text: '3:2' }
            ], include: ratio.orientation === 'landscape', value: ratio.landscape },
            { name: 'portrait', type: 'radiogroup', options: [
              { value: 1, text: 't(Square) (1:1)' },
              { value: 1/2, text: 't(Half Square) (1:2)' },
              { value: 9/16, text: '9:16' },
              { value: 5/7, text: '5:7' },
              { value: 3/4, text: '3:4' },
              { value: 3/5, text: '3:5' },
              { value: 2/3, text: '2:3' }
            ], include: ratio.orientation === 'portrait', value: ratio.portrait }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { ratio } = this.state
    this.props.onChange(ratio.orientation === 'portrait' ? ratio.portrait : ratio.landscape)
  }

  _handleSet(ratio) {
    this.setState({
      ratio: {
        orientation: ratio && ratio < 1 ? 'portrait' : 'landscape',
        portrait: ratio && ratio < 1 ? ratio : 1,
        landscape: ratio && ratio >= 1 ? ratio : 1
      }
    })
  }

  _handleUpdate(ratio) {
    this.setState({ ratio })
  }

}

export default RatioField
