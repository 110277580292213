import Actions from '@apps/automation/admin/components/actions'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ actions, enrollment, sms_campaign }, { admin }) => {

  const contact = {
    label: enrollment.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${enrollment.contact.id}`
  }

  const program = {
    label: sms_campaign.program.title,
    className: 'link',
    route: `/admin/team/programs/${sms_campaign.program.id}`
  }

  const sms_campaignlink = {
    label: sms_campaign.title,
    className: 'link',
    route: `/admin/campaigns/sms/${sms_campaign.id}`
  }

  const details = {
    comments: `automation_enrollments/${enrollment.id}`,
    items: [
      { label: 't(Contact)', content: <Button { ...contact } /> },
      { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } /> },
      { label: 't(SMS Campaign)', content: <Button { ...sms_campaignlink } /> },
      { label: 't(Enrolled)', content: enrollment.created_at, format: 'datetime' },
      { component: <Actions actions={ actions } enrollment={ enrollment } trigger={ sms_campaign.trigger } />}
    ]
  }

  if(sms_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This sms_campaign was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  actions: PropTypes.array,
  sms_campaign: PropTypes.object,
  enrollment: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview actions={ props.actions } sms_campaign={ props.sms_campaign } enrollment={ props.enrollment } />
})

export default Panel(null, mapPropsToPanel)
