import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const stocks = {
  busystrings: { label: 'Busy Strings', filename: 'BusyStrings.mp3' },
  clockworkwaltz: { label: 'Clock Work Waltz', filename: 'ClockworkWaltz.mp3' },
  flightofyounghearts: { label: 'Flight Of Young Hearts', filename: 'Mellotroniac_-_Flight_Of_Young_Hearts_Flute.mp3' },
  chopin: { label: 'Chopin', filename: 'ith_chopin-15-2.mp3' },
  endlessgoodbye: { label: 'Endless Goodbye', filename: 'oldDog_-_endless_goodbye_(instr.).mp3' }
}

class HoldMusicToken extends React.Component {

  static propTypes = {
    number: PropTypes.object
  }

  state = {
    audio: null
  }

  _handlePlay = this._handlePlay.bind(this)

  render() {
    return (
      <div className={ this._getClass() }>
        <div className="phone-hold-token-icon" onClick={ this._handlePlay }>
          <Icon icon={ this._getIcon()} />
        </div>
        <div className="phone-hold-token-label">
          { this._getLabel() }
        </div>
      </div>
    )
  }

  _getClass() {
    const { audio } = this.state
    const classes = ['phone-hold-token']
    if(audio) classes.push('active')
    return classes.join(' ')
  }

  _getIcon() {
    const { audio } = this.state
    return audio ? 'stop' : 'play'
  }

  _getLabel() {
    const { number } = this.props
    if(number.hold_strategy === 'stock') {
      return stocks[number.stock_hold_music].label
    }
    return 'foo'
  }

  _getUrl() {
    const { number } = this.props
    if(number.hold_strategy === 'stock') {
      return `/audio/${stocks[number.stock_hold_music].filename}`
    }
    return 'foo'
  }

  _handlePlay(e) {
    const { audio } = this.state
    e.stopPropagation()
    if(audio) {
      audio.pause()
      this.setState({ audio: null })
    } else {
      const audio = new Audio(this._getUrl())
      audio.play()
      this.setState({ audio })
    }
  }

}

export default HoldMusicToken
