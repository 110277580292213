import ToField from '../../components/tofield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = [
  { value: 'now', text: 't(Send immediately)' },
  { value: 'schedule', text: 't(Schedule Delivery)' }
]

class Send extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    voice_campaign: PropTypes.object
  }

  state = {
    strategy: 'now'
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { strategy } = this.state
    const { voice_campaign } = this.props
    return {
      title: 't(Send Campaign)',
      method: 'PATCH',
      endpoint: `/api/admin/campaigns/voice/${voice_campaign.id}/edit`,
      action: `/api/admin/campaigns/voice/${voice_campaign.id}/send`,
      saveText: strategy === 'now' ? 't(Send)' : 't(Schedule)',
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Recipients)', name: 'to', type: ToField, program_id: voice_campaign.program.id, channel: 'voice', required: true, purpose: voice_campaign.purpose, store: voice_campaign.store, event: voice_campaign.event, form: voice_campaign.form, pipeline: voice_campaign.pipeline, service: voice_campaign.service, defaultValue: voice_campaign.to },
            { label: 't(Send At)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: strategies, required: true, defaultValue: strategy },
              { name: 'send_at', type: 'datetimefield', show: strategy === 'schedule', required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(key, value) {
    if(key === 'strategy') {
      this.setState({
        strategy: value
      })
    }
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Send
