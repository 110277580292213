import ProfileManager from '@admin/components/profile_manager'
import Message from '@admin/components/message'
import Page from '@admin/components/page'
import Email from './email'
import React from 'react'

const getMessage = () => {
  return {
    icon: 'envelope',
    title: 't(Email)',
    text: 't(You can send, receive, and manage your email directly through Maha. This enables you to import emails into the CRM so the conversation becomes a part of your contact\'s history.)',
    buttons: [
      { label: 't(Manage Accounts)', modal: <ProfileManager types={['emails']} /> }
    ]
  }
}

const getFolder = (profiles, profile, folder) => ({
  ...folder.status === 'syncing' ? {
    icon: 'circle-o-notch fa-spin'
  } : folder.status === 'failed' ? {
    icon: 'warning'
  } : {},
  label: folder.name,
  path: profile ? `/${profile.id}/${folder.alias}` : `/${folder.alias}`,
  panel: profiles.length > 0 ? <Email profile={ profile } folder={ folder } /> : <Message { ...getMessage() } />,
  count: folder.unread_count
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 'Email',
  manager: {
    path: '/admin/email',
    items: [
      {
        label: 'All Accounts',
        collapsed: false,
        children: resources.folders.all.map(folder => {
          return getFolder(resources.folders.profiles, null, folder)
        })
      },
      ...resources.folders.profiles.length > 0 ? [
        ...resources.folders.profiles.map(profile => {
          const folders = profile.folders.sort((a,b) => {
            return a.weight > b.weight ? 1 : -1
          }).reduce((folders, folder) => ({
            ...folders,
            [folder.type]: [
              ...folders[folder.type],
              folder
            ]
          }), { system: [], user: []})
          return {
            label: profile.username,
            children: [
              ...folders.system.map(folder => {
                return getFolder(resources.folders.profiles, profile, folder)
              }),
              ...folders.user.length > 0 ? [{
                label: 'Folders',
                children: folders.user.map(folder => {
                  return getFolder(resources.folders.profiles, profile, folder)
                })
              }] : []
            ]
          }
        })
      ] : []
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  folders: {
    endpoint: '/api/admin/email/folders',
    refresh: [
      `/users/${context.admin.user.id}/admin/profiles/emails`,
      `/users/${context.admin.user.id}/admin/email/folders`
    ]
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
