import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Img = (props) => {

  const { onLoading, onError, onLoad, onLoaded, ...additionalProps } = props

  const [visible, setVisible] = useState(true)

  const handleError = () => {
    setVisible(false)
    if(onError) onError()
  }

  const handleLoad = () => {
    setVisible(true)
    if(onLoaded) onLoaded()
    if(onLoad) onLoad()
  }

  useEffect(() => {
    if(onLoading) onLoading()
  }, [])

  const mergedProps = {
    ...additionalProps,
    style: {
      ...props.style || {},
      ...!visible ? {
        display: 'none'
      } : {}
    },
    onError: handleError,
    onLoad: handleLoad
  }

  return <img { ...mergedProps } />

}

Img.propTypes = {
  style: PropTypes.object,
  onLoad: PropTypes.func,
  onLoading: PropTypes.func,
  onLoaded: PropTypes.func,
  onError: PropTypes.func
}

export default Img