const access = {
  entities: [
    { label: 't(Email Campaigns)', code: 'email_campaigns' },
    { label: 't(Social Campaigns)', code: 'social_campaigns' },
    { label: 't(SMS Campaigns)', code: 'sms_campaigns' },
    { label: 't(Voice Campaigns)', code: 'voice_campaigns' }
  ], 
  rights: [] 
}

export default access
