import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Renewal)',
  manager: {
    path: `/admin/subscriptions/services/${resources.service.id}/renewals/${resources.renewal.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview service={ resources.service } subscription={ resources.renewal.subscription } renewal={ resources.renewal } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  service: `/api/admin/subscriptions/services/${props.params.service_id}`,
  renewal: `/api/admin/subscriptions/services/${props.params.service_id}/renewals/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
