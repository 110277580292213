import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

export const ProviderContext = React.createContext(null)
ProviderContext.displayName = 'ProviderContext'

export const useProviderContext = () => React.useContext(ProviderContext)

class Provider extends React.Component {

  static childContextTypes = {
    provider: PropTypes.object
  }

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    provider: PropTypes.object
  }

  render() {
    return (
      <ProviderContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </ProviderContext.Provider>
    )
  }

  getChildContext() {
    const { provider } = this.props
    return { provider }
  }

}

const mapResources = (props, context) => ({
  provider: '/api/admin/provider'
})

export default Container(mapResources)(Provider)