export const onSetChosen = (chosen) => ({
  type: 'SET_CHOSEN',
  chosen
})

export const onSetQuery = (query)=> ({
  type: 'SET_QUERY',
  query
})

export const onToggleRecord = (multiple, record) => ({
  type: 'TOGGLE_RECORD',
  multiple,
  record
})
