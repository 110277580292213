import LookAndFeelStyleToken from './look_and_feel_style'
import FontStyleToken from './font_style'
import LogoStyleToken from './logo_style'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Style extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Logo Style)',
              instructions: 't(Select the types of designs you\'d like for your logo design. You may choose multiple styles.)',
              name: 'logo_design_styles',
              type: 'checkboxgroup',
              columns: 2,
              options: ['just_font','handmade','font_meaning','initials','font_shape','abstract_graphic','silhouette','geometric_symbol','illustrated_symbol','detailed_illustration','seals_crests','mascot'],
              entity: 'styles',
              deselectable: false,
              defaultValue: config.logo_design_styles,
              required: true,
              format: LogoStyleToken 
            },
            { 
              label: 't(Font Style)',
              instructions: 't(Select the types of fonts you\'d like for your logo design. You may choose multiple styles.)',
              name: 'logo_font_styles',
              type: 'checkboxgroup',
              columns: 5,
              options: ['serif','sans_serif','script','retro','techno','stencil','pixelated','typewriter'],
              entity: 'styles',
              deselectable: false,
              defaultValue: config.logo_font_styles,
              required: true,
              format: FontStyleToken 
            },
            { 
              label: 't(Overall Look and Feel)',
              instructions: 't(Select the overall look and feel you\'d like for your logo design. You may choose multiple styles.)',
              name: 'logo_look_and_feel',
              type: 'checkboxgroup',
              columns: 5,
              options: ['sophisticated','artistic','corporate','feminine','minimal','web20','childish','fun','hitech','retro'],
              entity: 'styles',
              deselectable: false,
              defaultValue: config.logo_look_and_feel,
              required: true,
              format: LookAndFeelStyleToken
            }

          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Style
