import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Verficiation)',
  collection: {
    endpoint: '/api/admin/truevail/admin/verifications',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Team)', key: 'title', primary: true },
      { label: 't(Pending)', key: 'pending_count', collapsing: true, align: 'right' },
      { label: 't(Failed)', key: 'failed_count', collapsing: true, align: 'right' },
      { label: 't(Total)', key: 'total_count', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Teams)',
      text: 't(There are no teams with unverified email addresses)'
    },
    entity: 'team',
    onClick: (record) => context.router.push(`/truevail/admin/verifications/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
