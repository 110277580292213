import { getRatioGroups } from './ratios'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class Crop extends React.PureComponent {

  static propTypes = {
    customRatio: PropTypes.number,
    max_ratio: PropTypes.number,
    min_ratio: PropTypes.number,
    original: PropTypes.object,
    onRatio: PropTypes.func
  }

  render() {
    const ratios = this._getRatios()
    return (
      <div className="maha-imageeditor-list">
        { ratios.map((group, index) => (
          <Fragment key={index}>
            <div className="maha-imageeditor-list-header">
              { group.label }
            </div>
            <div className="maha-imageeditor-list-items">
              { group.ratios.map((ratio, index) => (
                <div className="maha-imageeditor-item" key={`row_${index}`}>
                  <div className={ this._getClass(ratio) } onClick={ this._handleClick.bind(this, ratio.ratio) }>
                    { ratio.label }
                  </div>
                </div>
              )) }
            </div>
          </Fragment>
        )) }
      </div>
    )
  }

  _getClass(ratio) {
    const { customRatio } = this.props
    const classes = ['maha-imageeditor-ratio']
    if(ratio.ratio === customRatio) classes.push('active')
    return classes.join(' ')
  }

  _getPanel() {
    return {
      title: 't(Crop / Resize)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ]
    }
  }

  _getRatios() {
    const { max_ratio, min_ratio, original } = this.props
    const original_ratio = original.w / original.h
    return getRatioGroups(original_ratio, min_ratio, max_ratio)
  }

  _handleClick(ratio) {
    this.props.onRatio(ratio)
  }

}

export default Crop
