import Page from '@admin/components/page'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.domain.hostname,
  manager: {
    path: `/admin/websites/${resources.website.id}/domains/${resources.domain.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview website={ resources.website } domain={ resources.domains } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  domain: `/api/admin/websites/websites/${props.params.website_id}/domains/${props.params.id}`,
  website: `/api/admin/websites/websites/${props.params.website_id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
