import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Promise from 'bluebird'
import Item from './item'
import React from 'react'

const ChannelFieldWrapper = ({ emptyValue, compare, entity, format, pack, unpack, validate, verify }, Component) => {

  class ChannelField extends React.PureComponent {

    static contextTypes = {
      form: PropTypes.object,
      network: PropTypes.object
    }

    static propTypes = {
      contact: PropTypes.object,
      defaultValue: PropTypes.array,
      id: PropTypes.string,
      tabIndex: PropTypes.number,
      value: PropTypes.array,
      onChange: PropTypes.func,
      onReady: PropTypes.func,
      onValid: PropTypes.func
    }

    state = {
      focused: false,
      items: null
    }

    _handleAdd = this._handleAdd.bind(this)
    _handleBlured = this._handleBlured.bind(this)
    _handleChange = this._handleChange.bind(this)
    _handleFocused = this._handleFocused.bind(this)
    _handleMove = this._handleMove.bind(this)
    _handleRemove = this._handleRemove.bind(this)
    _handleUpdate = this._handleUpdate.bind(this)
    _handleValidate = this._handleValidate.bind(this)

    render() {
      const { items } = this.state
      if(!items) return null
      return (
        <div className="channelfield">
          <div className={ this._getClass() }>
            <div className="channelfield-items">
              { items.map((item, index) => (
                <Item { ...this._getItem(item, index) } key={ `item_${index}` } />
              ))}
            </div>
          </div>
          <Button { ...this._getAdd() } />
        </div>
      )
    }

    componentDidMount() {
      const defaultValue = this._getDefaultValue()
      this._handleSet(defaultValue)
      this.props.onReady(this._handleValidate)
    }

    componentDidUpdate(prevProps, prevState) {
      const { items } = this.state
      if(!_.isEqual(items, prevState.items)) {
        this._handleChange()
      }
    }

    _getAdd() {
      return {
        label: '+ t(add another)',
        className: 'link',
        handler: this._handleAdd
      }
    }

    _getClass() {
      const { focused } = this.state
      const classes = ['maha-input']
      if(focused) classes.push('focused')
      return classes.join(' ')
    }

    _getDefaultValue() {
      const { defaultValue, value } = this.props
      return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
    }

    _getItem(item, index) {
      const { tabIndex } = this.props
      return {
        Component,
        entity,
        format,
        tabIndex,
        value: item.value,
        index,
        onBlur: this._handleBlured,
        onEnter: this._handleAdd,
        onFocus: this._handleFocused,
        onMove: this._handleMove,
        onRemove: this._handleRemove,
        onUpdate: this._handleUpdate
      }
    }

    _getItems() {
      return this.state.items.filter(item => {
        return !_.isNil(item.value) && item.value !== emptyValue
      })
    }

    _handleAdd() {
      const { items } = this.state
      this.setState({
        items: [
          ...items,
          { delta: items.length, error: null, value: null }
        ]
      })
    }

    _handleBlured() {
      this.setState({
        focused: false
      })
    }

    _handleChange() {
      const items = this._getItems()
      this.props.onChange(items.map(item => ({
        delta: item.delta,
        value: item.value
      })))
    }

    _handleFocused() {
      this.setState({
        focused: true
      })
    }

    _handleMove(from, to) {
      const { items } = this.state
      this.setState({
        items: ((from < to) ? [
          ...items.slice(0, from),
          ...items.slice(from + 1, to + 1),
          items[from],
          ...items.slice(to + 1)
        ] : [
          ...items.slice(0, to),
          items[from],
          ...items.slice(to, from),
          ...items.slice(from + 1)
        ]).map((item, delta) => ({
          ...item,
          delta
        }))
      })
    }

    _handleRemove(index) {
      const { items } = this.state
      this.setState({
        items: items.filter((item, i) => {
          return i !== index
        }).map((item, delta) => ({
          ...item,
          delta
        }))
      }, () => {
        if(items.length > 1) return
        this._handleAdd()
      })
    }

    _handleSet(items) {
      this.setState({
        items: [
          ...items || [],
          ...!items || items.length === 0 ? [{}] : []
        ].map((item,delta) => ({
          delta,
          error: null,
          value: unpack(item)
        }))
      })
    }

    _handleUpdate(index, value) {
      const { items } = this.state
      this.setState({
        items: items.map((item, i) => ({
          ...item,
          value: i === index ? value : item.value
        }))
      })
    }

    _handleValidate() {
      const items = this._getItems()
      if(items.length === 0) return this.props.onValid([])
      Promise.reduce(items, async (errors, item, index) => {
        const duplicate = items.find((i, j) => {
          return j !== index ? compare(i.value, item.value) : false
        }) !== undefined
        if(duplicate) return [...errors, `${item.value} is not a unique ${entity}`]
        if(!verify(item.value)) return [...errors, `${item.value} is not a valid ${entity}`]
        return errors
      }, []).then((errors) => {
        if(errors.length > 0) return this.props.onValid(null, errors)
        this.props.onValid(items.map(item => ({
          delta: item.delta,
          ...pack(item.value)
        })))
      })
    }

  }

  return ChannelField

}

export default ChannelFieldWrapper
