import Page from '@admin/components/page'
import Integrations from './integrations'
import Overview from './overview'
import Notices from './notices'
import Design from './design'
import Teams from './teams'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.provider.title,
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: `/admin/platform/providers/${resources.provider.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview provider={ resources.provider } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design provider={ resources.provider } />
      },
      {
        label: 't(Integrations)',
        path: '/integrations',
        panel: <Integrations provider={ resources.provider } />
      },
      {
        label: 't(Notices)',
        path: '/notices',
        panel: <Notices provider={ resources.provider } />
      },
      {
        label: 't(Teams)',
        path: '/teams',
        panel: <Teams provider={ resources.provider } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  provider: `/api/admin/platform/providers/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
