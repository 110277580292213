import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Chart from './chart'
import React from 'react'

class Charts extends React.Component {

  static propTypes = {
    charts: PropTypes.array,
    label: PropTypes.string,
    options: PropTypes.object
  }

  state = {
    selected: 0
  }

  render() {
    const { charts, label } = this.props
    const { selected } = this.state
    return (
      <div className="crm-report-section">
        <div className="crm-report-section-navigation">
          <div className="crm-report-section-title">
            <strong><T text={ label } /></strong>
          </div>
          <div className="crm-report-section-menu">
            <ul>
              { charts.map((chart, index) => (
                <li key={`chart_${index}`}>
                  <Button { ...this._getButton(chart, index) } />
                </li>
              )) }
            </ul>
          </div>
        </div>
        <Chart { ...this._getChart(charts[selected]) } key={`chart_${selected}`} />
      </div>
    )
  }

  _getButton(chart, index) {
    const { selected } = this.state
    const classes = ['link']
    if(selected === index) classes.push('selected')
    return {
      label: chart.label,
      className: classes.join(' '),
      handler: this._handleSelect.bind(this, index)
    }
  }

  _getChart(chart) {
    const { options } = this.props
    return {
      ...chart,
      options: {
        ...options || {},
        ...chart.options || {}
      }
    }
  }

  _handleSelect(selected) {
    this.setState({ selected })
  }

}

export default Charts
