import CallActivities from '@apps/phone/admin/components/call_activities'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import Format from '@admin/components/format'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Call extends React.PureComponent {

  static propTypes = {
    activities: PropTypes.array,
    call: PropTypes.object
  }

  render() {
    const { activities, call } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Phone Number</td>
                <td>{ call.program_phone_number.formatted }</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td><Format format="duration" value={ call.seconds } /></td>
              </tr>
              <tr>
                <td>Call</td>
                <td><Button { ...this._getCall() } /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="crm-timeline-card-section-footer">
          <CallActivities activities={ activities } call={ call } />
          <Comments entity={`phone_calls/${call.id}`} />
        </div>
      </div>
    )
  }

  _getCall() {
    const { call } = this.props
    return {
      label: 'View Call',
      className: 'link',
      route: `/admin/phone/calls/${call.id}`
    }
  }

  _getDuration(duration) {
    const pad = (value) => _.padStart(value, 2, 0)
    const minutes = Math.floor(duration / 60)
    const seconds = (duration  - (minutes * 60)) % 60
    const parts = [ pad(minutes), pad(seconds) ]
    return parts.join(':')
  }

}

const mapResources = (props, context) => ({
  activities: `/api/admin/phone/calls/${props.activity.data.call_id}/activities`,
  call: `/api/admin/phone/calls/${props.activity.data.call_id}`
})

export default Container(mapResources)(Call)
