import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Channels extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Channels)',
              instructions: 't(Choose which social channels you\'d like to subscribe to)',
              name: 'social_channel_ids',
              type: 'checkboxgroup',
              entity: 'channel',
              endpoint: '/api/admin/truevail/advisor/channels',
              valueKey: 'id',
              textKey: 'title',
              filter: { service: { $eq: 'social' }, type: { $eq: 'public' } },
              deselectable: true, format: (channel) => (
                <ChannelToken channel={ channel } />
              ),
              defaultValue: config.social_channel_ids 
            }
          ] 
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Channels
