import EmailViewer from '@admin/components/email_viewer/email'
import Page from '@admin/components/page'

const mapResourcesToPage = (props, context) => ({
  email: `/api/admin/emails/${props.params.code}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Email)',
  component: EmailViewer,
  tasks: !resources.email.sent_at ? {
    items: [
      {
        label: 't(Resend Email)',
        request: {
          endpoint: `/api/admin/emails/${resources.email.id}/resend`,
          method: 'POST',
          onSuccess: () => context.flash.set('success', 't(Email queued for delivery)'),
          onFailure: () => context.flash.set('error', 't(Unable to resend email)')
        }
      }
    ]
  } : null
})

export default Page(mapResourcesToPage, mapPropsToPage)
