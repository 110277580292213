import Button from '@admin/components/button'
import Token from '@admin/components/token'
import List from '@admin/components/list'
import TimeBlock from './timeblock'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class TimeBlocksField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    timeblocks: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleCreate = this._handleCreate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <>
        <List { ...this._getList() } />
        <Button { ...this._getAdd() } />
      </>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this.setState({
      timeblocks: defaultValue
    })
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { timeblocks } = this.state
    if(!_.isEqual(timeblocks, prevState.timeblocks)) {
      this.props.onChange(timeblocks)
    }
  }

  _getDescription(timeblock) {
    const days = ['Su','M','T','W','Th','F','Sa']
    return [
      days.filter((day, index) => {
        return _.includes(timeblock.days, index)
      }).join('/'),
      ...timeblock.times.map(time => {
        return [
          moment(`2020-01-01 ${time.start_time}`).format('h:mmA'),
          moment(`2020-01-01 ${time.end_time}`).format('h:mmA')
        ].join('-')
      })
    ].join(', ')
  }

  _getAdd() {
    return {
      label: '+ t(add time block)',
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getEdit(timeblock, index) {
    return {
      timeblock,
      mode: 'edit',
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getList() {
    const { timeblocks } = this.state
    return {
      actions: (item, index) => [
        {
          icon: 'pencil',
          handler: this._handleEdit.bind(this, item, index)
        },
        {
          icon: 'times',
          confirm: 't(Are you sure you want to remove this time block?)',
          handler: this._handleRemove.bind(this, index)
        }
      ],
      items: timeblocks,
      empty: <Token text='t(You have not yet added any time blocks)' />,
      format: (timeblock) => (
        <Token>
          { timeblock.name }<br />
          <span>{ this._getDescription(timeblock) }</span>
        </Token>
      )
    }
  }

  _getNew() {
    return {
      mode: 'new',
      onDone: this._handleCreate
    }
  }

  _handleAdd() {
    this.context.form.push(TimeBlock, this._getNew())
  }

  _handleCreate(timeblock) {
    const { timeblocks } = this.state
    this.setState({
      timeblocks: [
        ...timeblocks,
        timeblock
      ]
    })
  }

  _handleEdit(timeblock, index) {
    this.context.form.push(TimeBlock, this._getEdit(timeblock, index))
  }

  _handleRemove(index) {
    const { timeblocks } = this.state
    this.setState({
      timeblocks: timeblocks.filter((timeblock, i) => {
        return i !== index
      })
    })
  }

  _handleUpdate(i, updated) {
    const { timeblocks } = this.state
    this.setState({
      timeblocks: timeblocks.map((timeblock, index) => {
        return index === i ? updated : timeblock
      })
    })
  }

  _handleValidate() {
    const { timeblocks } = this.state
    const { required, onValid } = this.props
    if(required && (!timeblocks || timeblocks.length === 0)) {
      return onValid(null, ['You must add at least 1 time blocks'])
    }
    onValid(timeblocks)
  }

}

export default TimeBlocksField
