import IconToken from '@admin/tokens/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.PureComponent {

  static propTypes = {
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="mjson-designer-media-results">
        { records.map((record, index) => (
          <div className="mjson-designer-media-result icon" key={ index } onClick={ this._handleChoose.bind(this, record)}>
            <div className="mjson-designer-media-icon">
              <IconToken { ...this._getIcon(record) }/>
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getIcon(icon) {
    return {
      icon,
      color: '000000',
      size: 32
    }
  }

  _handleChoose(record) {
    this.props.onChoose(record)
  }

}

export default Results
