import Button from '@admin/components/button'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'
import Edit from './edit'
import New from './new'

class LineItems extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    line_items: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    const line_items = this._getLineItems()
    if(!line_items) return null
    const subtotal = this._getSubtotal(line_items)
    const tax = this._getTax(line_items)
    const total = subtotal + tax
    return (
      <div className="finance-line-items">
        <table className="ui unstackable celled table">
          <thead>
            <tr className="finance-line-items-header">
              <th><T text="t(Item)" /></th>
              <th><T text="t(Qty)" /></th>
              <th><T text="t(Price)" /></th>
              <th><T text="t(Total)" /></th>
              <th colSpan="2" />
            </tr>
          </thead>
          <tbody>
            { line_items.map((line_item, index) => (
              <tr className="finance-line-items-row" key={`line_item_${index}`}>
                <td>{ line_item.description }</td>
                <td>{ line_item.quantity }</td>
                <td>{ numeral(line_item.price).format('0.00') }</td>
                <td>{ numeral(line_item.total).format('0.00') }</td>
                <td><Button { ...this._getEdit(line_item, index) } /></td>
                <td><Button { ...this._getRemove(index) } /></td>
              </tr>
            )) }
            { line_items.length === 0 &&
              <tr className="finance-line-items-empty">
                <td colSpan="6">
                  <T text="t(There are no line items on this invoice)" />
                </td>
              </tr>
            }
            { (tax > 0) &&
              <tr className="finance-line-items-total">
                <td colSpan="3"><T text="t(Subtotal)" /></td>
                <td>{ numeral(subtotal).format('0.00') }</td>
                <td colSpan="2" />
              </tr>
            }
            { tax > 0 &&
              <tr className="finance-line-items-addon">
                <td colSpan="3"><T text="t(Tax)" /></td>
                <td>{ numeral(tax).format('0.00') }</td>
                <td colSpan="2" />
              </tr>
            }
            <tr className="finance-line-items-total">
              <td colSpan="3"><T text="t(Total)" /></td>
              <td>{ numeral(total).format('0.00') }</td>
              <td colSpan="2" />
            </tr>
          </tbody>
        </table>
        <div className="finance-line-items-actions">
          <Button { ...this._getNew() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { line_items } = this.state
    if(!_.isEqual(line_items, prevState.line_items)) {
      this._handleChange()
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getLineItems() {
    const { line_items } = this.state
    return line_items ? line_items.reduce((line_items, line_item) => [
      ...line_items,
      {
        id: line_item.id,
        project_id: line_item.project_id,
        revenue_type_id: line_item.revenue_type_id,
        description: line_item.description,
        quantity: line_item.quantity,
        tax_rate: line_item.tax_rate,
        tax: line_item.tax_rate * line_item.price,
        price: line_item.price,
        total: line_item.quantity * line_item.price
      }
    ], []) : null
  }

  _getNew() {
    return {
      label: 't(add line item)',
      className: 'link',
      handler: this._handleNew
    }
  }

  _getEdit(line_item, index) {
    return {
      icon: 'pencil',
      className: '',
      handler: this._handleEdit.bind(this, line_item, index)
    }
  }

  _getRemove(index) {
    return {
      icon: 'times',
      className: '',
      handler: this._handleRemove.bind(this, index)
    }
  }

  _getSubtotal(line_items) {
    return line_items.reduce((subtotal, line_item) => {
      return subtotal + line_item.total
    }, 0.00)
  }

  _getTax(line_items) {
    return line_items.reduce((tax, line_item) => {
      return tax + (line_item.tax * line_item.quantity)
    }, 0.00)    
  }

  _handleAdd(line_item) {
    const { line_items } = this.state
    this.setState({
      line_items: [
        ...line_items,
        line_item
      ]
    })
  }

  _handleChange() {
    const { line_items } = this.state
    this.props.onChange(line_items)
  }

  _handleNew() {
    const props = {
      onDone: this._handleAdd
    }
    this.context.form.push(New, props)
  }

  _handleEdit(line_item, index) {
    const props = {
      line_item,
      onDone: this._handleUpdate.bind(this, index)
    }
    this.context.form.push(Edit, props)
  }

  _handleRemove(index) {
    const { line_items } = this.state
    this.setState({
      line_items: line_items.filter((line_item, i) => {
        return i !== index
      })
    })
  }

  _handleSet(line_items) {
    this.setState({ 
      line_items: line_items || []
    })
  }

  _handleUpdate(index, new_line_item) {
    const { line_items } = this.state
    this.setState({
      line_items: line_items.map((line_item, i) => ({
        ...i === index ? new_line_item : line_item
      }))
    })
  }

}

export default LineItems
