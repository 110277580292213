import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Demographics)',
  collection: {
    endpoint: '/api/admin/events/demographics',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Event)', key: 'title', primary: true },
      { label: 't(Male)', key: 'male', collapsing: true, align: 'right'  },
      { label: 't(Female)', key: 'female', collapsing: true, align: 'right'  },
      { label: 't(Other)', key: 'other_gender', collapsing: true, align: 'right'  },
      { label: 't(Undeclared Gender)', key: 'undeclared_gender', collapsing: true, align: 'right'  },
      { label: 't(Hispanic)', key: 'hispanic', collapsing: true, align: 'right'  },
      { label: 't(Non Hispanic)', key: 'nonhispanic', collapsing: true, align: 'right'  },
      { label: 't(Undeclared)', key: 'undeclared_ethnicity', collapsing: true, align: 'right'  },
      { label: 't(Caucasian)', key: 'caucasian', collapsing: true, align: 'right'  },
      { label: 't(African)', key: 'african', collapsing: true, align: 'right'  },
      { label: 't(American Indian)', key: 'american_indian', collapsing: true, align: 'right'  },
      { label: 't(Asian)', key: 'asian', collapsing: true, align: 'right'  },
      { label: 't(Multiracial)', key: 'multiracial', collapsing: true, align: 'right'  },
      { label: 't(Pacific Islander)', key: 'pacific_islander', collapsing: true, align: 'right'  },
      { label: 't(Other Race)', key: 'other_race', collapsing: true, align: 'right'  },
      { label: 't(Undeclared)', key: 'undeclared_race', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'id', order: 'desc' },
    empty: {
      icon: 'map-marker',
      title: 't(No Locations)',
      text: 't(You have not yet created any locations)',
      buttons: []
    },
    entity: 'event'
  }
})

export default Page(null, mapPropsToPage)
