import PropTypes from 'prop-types'
import React from 'react'

const TagsToken = ({ tags }) => (
  <>
    { tags.map((tag, index) => (
      <div className="crm-tag-token" key={`tag_${index}`}>
        { tag.text }
      </div>
    )) }
  </>
)

TagsToken.propTypes = {
  tags: PropTypes.array
}

export default TagsToken
