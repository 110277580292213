import Terms from '@admin/components/profile_connect/terms'
import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Overview from './overview'
import React from 'react'

class ProfileReconnect extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    profiles: PropTypes.array
  }

  stackRef = React.createRef()

  _handleClose = this._handleClose.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleTerms = this._handleTerms.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handlePush(Overview, this._getOverview.bind(this))
  }

  _getStack() {
    return {
      display_name: 'profile_reconnect',
      ref: this.stackRef
    }
  }

  _getOverview() {
    const { profiles } = this.props
    return { 
      profiles,
      onReconnect: this._handleTerms,
      onDone: this._handleClose
    }
  }

  _handleClose() {
    this.context.modal.close()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleTerms(profile) {
    const props = {
      program_id: profile.program.id,
      service: profile.service,
      onBack: this._handlePop,
      onDone: this._handlePop
    }
    this._handlePush(Terms, props)
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/team/profiles',
    refresh: `/teams/${context.admin.team.id}/admin/team/profiles`,
    filter: {
      status: {
        $neq: 'connected'
      }
    }
  }
})

export default Container(mapResources)(ProfileReconnect)