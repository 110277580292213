export const INITIAL_STATE = {
  changing: false,
  skinToneIndex: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CHANGE_SKIN_TONE':
    return {
      ...state,
      changing: true
    }

  case 'SET_SKIN_TONE_INDEX':
    return {
      ...state,
      skinToneIndex: action.index,
      changing: false
    }

  default:
    return state

  }

}

export default reducer
