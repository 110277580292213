import PropTypes from 'prop-types'
import Comment from './comment'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    records: PropTypes.array,
    selected: PropTypes.object,
    onChoose: PropTypes.func
  }

  _handleChoose = this._handleChoose

  render() {
    return this.props.records.map((variant, index) => (
      <div className={ this._getClass(variant) } onClick={ this._handleChoose.bind(this, variant)} key={`variant_${index}`}>
        <Comment variant={ variant } />
      </div>
    ))
  }

  componentDidMount() {
    const { records } = this.props
    if(records.length === 0) return
    this._handleChoose(records[0])
  }

  _getClass(variant) {
    const { selected } = this.props
    const classes = ['campaigns-social-conversations-result']
    if(selected && variant.id === selected.id) classes.push('selected')
    return classes.join(' ')
  }

  _handleChoose(post) {
    this.props.onChoose(post)
  }

}

export default Results
