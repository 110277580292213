import VersionHistory from '@admin/components/version_history'
import ModalPanel from '@admin/components/modal_panel'
import Menu from '@admin/components/menu'
import PropTypes from 'prop-types'
import Types from './types'
import React from 'react'

class Sidebar extends React.Component {

  static propTypes = {
    blocks: PropTypes.array,
    version: PropTypes.object,
    versions: PropTypes.array
  }

  render() {
    return (
      <ModalPanel { ...this._getPanel()}>
        <Menu { ...this._getMenu() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      showHeader: false
    }
  }

  _getMenu() {
    return {
      type: 'pointing',
      items: [
        { label: 't(Blocks)', component: <Types { ...this._getTypes() } /> },
        { label: 't(Versions)', component: <VersionHistory { ...this._getVersionHistory() } /> }
      ]
    }
  }

  _getTypes() {
    const { blocks } = this.props
    return { 
      blocks ,
      onCancel: () => {},
      onChoose: () => {}
    }
  }

  _getVersionHistory() {
    const { version, versions } = this.props
    return {
      version,
      versions
    }
  }

}

export default Sidebar
