import RadioGroup from '@admin/components/form/radiogroup'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Question extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    security_questions: PropTypes.array,
    onNext: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h2><T text="t(Choose a Security Question)" /></h2>
            <p><T text="t(In the event that you forget your password, we'll need a way to make sure that you are you. Please choose a security question to help protect your account.)" /></p>
            <RadioGroup { ...this._getRadioGroup() } />
          </div>
        </div>
      </div>
    )
  }

  _getRadioGroup() {
    const { security_questions } = this.props
    return {
      options: security_questions,
      valueKey: 'id',
      selectFirst: false,
      textKey: 'text',
      onChange: this._handleChoose
    }
  }

  _handleChoose(security_question_id) {
    this.props.onNext({ security_question_id })
  }

}

export default Question
