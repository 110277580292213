import moment from 'moment'

const getDateRange = ({ type, start, end, step }) => ({
  ...type === '30_days' ? {
    label: 'Last 30 Days',
    prev_start: moment().subtract(60, 'days'),
    prev_end: moment().subtract(31, 'days'),
    start: moment().subtract(30, 'days'),
    end: moment()
  } : {},
  ...type === '60_days' ? {
    label: 'Last 60 Days',
    prev_start: moment().subtract(120, 'days'),
    prev_end: moment().subtract(61, 'days'),
    start: moment().subtract(60, 'days'),
    end: moment()
  } : {},
  ...type === '90_days' ? {
    label: 'Last 90 Days',
    prev_start: moment().subtract(180, 'days'),
    prev_end: moment().subtract(91, 'days'),
    start: moment().subtract(90, 'days'),
    end: moment()
  } : {},
  ...type === 'last_week' ? {
    label: 'Last Week',
    prev_start: moment().startOf('week').subtract(2, 'weeks'),
    prev_end: moment().startOf('week').subtract(1, 'week').subtract(1, 'day'),
    start: moment().startOf('week').subtract(1, 'week'),
    end: moment().startOf('week').subtract(1, 'day')
  } : {},
  ...type === 'this_week' ? {
    label: 'This Week',
    prev_start: moment().startOf('week').subtract(1, 'week'),
    prev_end: moment().startOf('week').subtract(1, 'day'),
    start: moment().startOf('week'),
    end: moment().startOf('week').add(7, 'days')
  } : {},
  ...type === 'last_month' ? {
    label: 'Last Month',
    prev_start: moment().startOf('month').subtract(2, 'month'),
    prev_end: moment().startOf('month').subtract(1, 'month').subtract(1, 'day'),
    start: moment().startOf('month').subtract(1, 'month'),
    end: moment().startOf('month').subtract(1, 'day')
  } : {},
  ...type === 'this_month' ? {
    label: 'This Month',
    prev_start: moment().startOf('month').subtract(1, 'month'),
    prev_end: moment().startOf('month').subtract(1, 'day'),
    start: moment().startOf('month'),
    end: moment().endOf('month')
  } : {},
  ...type === 'last_quarter' ? {
    label: 'Last Quarter',
    prev_start: moment().startOf('quarter').subtract(2, 'quarters'),
    prev_end: moment().startOf('quarter').subtract(1, 'quarter').subtract(1, 'day'),
    start: moment().startOf('quarter').subtract(1, 'quarter'),
    end: moment().startOf('quarter').subtract(1, 'day')
  } : {},
  ...type === 'this_quarter' ? {
    label: 'This Quarter',
    prev_start: moment().startOf('quarter').subtract(1, 'quarter'),
    prev_end: moment().startOf('quarter').subtract(1, 'day'),
    start: moment().startOf('quarter'),
    end: moment().endOf('quarter')
  } : {},
  ...type === 'last_year' ? {
    label: 'Last Year',
    prev_start: moment().startOf('year').subtract(2, 'years'),
    prev_end: moment().startOf('year').subtract(1, 'year').subtract(1, 'day'),
    start: moment().startOf('year').subtract(1, 'year'),
    end: moment().startOf('year').subtract(1, 'day')
  } : {},
  ...type === 'this_year' ? {
    label: 'This Year',
    prev_start: moment().startOf('year').subtract(1, 'year'),
    prev_end: moment().startOf('year').subtract(1, 'day'),
    start: moment().startOf('year'),
    end: moment().endOf('year')
  } : {},
  ...type === 'custom' ? {
    label: 'Custom',
    prev_start: moment(start).subtract(moment(end).diff(moment(start), 'days'), 'days'),
    prev_end: moment(start).subtract(1, 'day'),
    start: moment(start),
    end: moment(end)
  } : {},
  step,
  interval: step === 'quarter' ? '3 months' : `1 ${step}`
})

export default getDateRange
