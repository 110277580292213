import Numericfield from '@admin/components/form/numericfield'
import PropTypes from 'prop-types'
import React from 'react'

class Range extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    units: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    value: 0
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 250)
  _handleNumeric = this._handleNumeric.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <div className={ this._getClass() }>
        <div className="maha-range-slider">
          <input { ...this._getRange() } />
        </div>
        <div className="maha-range-label">
          <Numericfield { ...this._getNumericField() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const classes = ['maha-input','maha-range']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getNormalizedValue(value) {
    return _.isNil(value) ? this.props.min : value
  }

  _getNumericField() {
    const { disabled, min, max, step, tabIndex, units } = this.props
    const { value } = this.state
    return {
      disabled,
      value: step % 1 !== 0 ? value.toFixed(1) : value,
      min,
      max,
      tabIndex,
      units,
      onChange: this._handleNumeric
    }
  }

  _getRange() {
    const { disabled, min, max, step, tabIndex } = this.props
    const { value } = this.state
    return {
      disabled,
      type: 'range',
      min: 0,
      max: (max - min) / step,
      tabIndex,
      value: (value - min) / step,
      onChange: this._handleUpdate,
      onInput: this._handleUpdate
    }
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value)
  }

  _handleNumeric(value) {
    this.setState({ value })
  }

  _handleSet(value) {
    this.setState({
      value: this._getNormalizedValue(value)
    })

  }

  _handleUpdate(e) {
    const { min, step } = this.props
    const value = parseInt(e.target.value)
    this.setState({
      value: min + (step * value)
    })
  }

}

export default Range
