import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Reactions from './reactions'
import React from 'react'

export { ReactionsContext, useReactionsContext } from './reactions'

const ReactionsContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'reactions',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <Reactions { ...mergedProps } />

}

ReactionsContainer.propTypes = {}

export default ReactionsContainer
