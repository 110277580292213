import DropDown from '@admin/components/form/dropdown'
import ResponsiveField from '../responsivefield'
import TextField from '../textstylefield'
import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import React from 'react'

const Dropdown = (props) => <ResponsiveField { ...props } component={ DropDown } />
const Spacing = (props) => <ResponsiveField { ...props } component={ LinkedField } />

class CaptionStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    caption: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.caption) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { caption } = this.state
    if(!_.isEqual(caption, prevState.caption)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { caption } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Position)', name: 'position', collapsing: true, collapsed: true, type: Dropdown, device, options: [
              { value: 'top_left_overlay', text: 'Top Left Overlay' },
              { value: 'top_right_overlay', text: 'Top Right Overlay' },
              { value: 'bottom_left_overlay', text: 'Bottom Left Overlay' },
              { value: 'bottom_right_overlay', text: 'Bottom Right Overlay' },
              { value: 'top', text: 'Top' },
              { value: 'right', text: 'Right' },
              { value: 'bottom', text: 'Bottom' },
              { value: 'left', text: 'Left' }
            ], value: caption.position },
            { label: 't(Background Color)', name: 'backgroundColor', collapsing: true, collapsed: true, type: ColorField, theme, value: caption.backgroundColor },
            { label: 't(Text)', name: 'text', collapsing: true, collapsed: true, type: TextField, device, theme, defaultValue: {
              preset: 'caption'
            }, value: caption.text },
            { label: 't(Padding)', name: 'padding', device, collapsing: true, collapsed: true, type: Spacing, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: caption.padding },
            { label: 't(Margin)', name: 'margin', device, collapsing: true, collapsed: true, type: Spacing, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: caption.margin }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { caption } = this.state
    this.props.onChange(caption)
  }

  _handleSet(caption) {
    this.setState({
      caption: {
        ...caption
      }
    })
  }

}

export default CaptionStyleField
