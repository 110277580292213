import PropTypes from 'prop-types'
import React from 'react'

class HiddenField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  render() {
    return null
  }

  componentDidMount() {
    const { value } = this.props
    this.props.onReady()
    this.props.onChange(value)
  }

}

export default HiddenField
