import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ usage }, context) => {

  const details = {
    items: [
      { label: 't(Period)', content: usage.period },
      { label: 't(Emails)', content: usage.emails_count, format: 'number' },
      ...context.admin.hasRights({ allOf: ['finance:access_app'] }) ? [
        { label: 't(Phone Minutes)', content: usage.minutes_count, format: 'number' },
        { label: 't(SMS Messages)', content: usage.smses_count, format: 'number' },
        { label: 't(Phone Numbers)', content: usage.phone_numbers_count, format: 'number' }
      ] : []
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  usage: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview usage={ props.usage } />
})

export default Panel(null, mapPropsToPanel)
