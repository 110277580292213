import Page from '@admin/components/page'
import Released from './released'
import Active from './active'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Numbers)',
  manager: {
    path: '/admin/phone/numbers',
    items: [
      {
        label: 't(Active Numbers)',
        panel: <Active />
      },
      {
        label: 't(Released Numbers)',
        path: '/released',
        panel: <Released />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
