import PropTypes from 'prop-types'
import Field from './field'
import React from 'react'

class Fields extends React.Component {

  static propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object,
    field: PropTypes.object,
    fields: PropTypes.array,
    show: PropTypes.bool,
    status: PropTypes.string,
    onBusy: PropTypes.func,
    onReady: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdateData: PropTypes.func,
    onValid: PropTypes.func
  }

  render() {
    const { field } = this.props
    return (
      <div className={ this._getClass() }>
        { field.fields.map((field, index) => (
          <Field key={`field_${index}_${field.name}`} { ...this._getField(field) } />
        )) }
      </div>
    )
  }

  _getClass() {
    const { field, show } = this.props
    const numbers = ['zero','one','two','three','four','five','six','seven','eight','nine','ten']
    const classes = [numbers[field.fields.length],'fields']
    if(show === false) classes.push('hidden')
    return classes.join(' ')
  }

  _getField(field) {
    const { data, errors, fields, status, onBusy, onReady, onSubmit, onUpdateData, onValid } = this.props
    return {
      data,
      errors,
      field,
      fields,
      status,
      onBusy,
      onReady,
      onSubmit,
      onUpdateData,
      onValid
    }
  }

}

export default Fields
