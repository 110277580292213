import VoiceToken from '@apps/truevail/admin/tokens/voice'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Reactivate from '../reactivate'
import PropTypes from 'prop-types'
import Resume from '../resume'
import Cancel from '../cancel'
import Change from '../change'
import Pause from '../pause'
import Edit from '../edit'
import moment from 'moment'
import React from 'react'

const Overview = ({ advisor }) => {

  const agency = {
    className: 'link',
    label: advisor.agency.team.title,
    route: `/admin/truevail/admin/agencies/${advisor.agency.id}`
  }

  const primary_user = {
    className: 'link',
    label: advisor.team.primary_user.display_name,
    route: `/admin/crm/contacts/${advisor.team.primary_user.id}`
  }

  const details = {
    audits: `truevail_advisors/${advisor.id}`,
    comments: `truevail_advisors/${advisor.id}`,
    sections: [
      {
        title: 't(Advisor Details)',
        items: [
          { label: 't(Name)', content: advisor.name },
          { label: 't(Organization)', content: advisor.organization },
          { label: 't(Public Domain)', content: advisor.team.public_domain },
          { label: 't(Agency)', content: <Button { ...agency } /> },
          { label: 't(Voice)', content: <VoiceToken perspective={ advisor.perspective } language={ advisor.language } /> },
          { label: 't(Timezone)', content: advisor.team.timezone.zone },
          { label: 't(Primary User)', content: <Button { ...primary_user } /> }
        ]
      }
    ]
  }

  const unschedule = {
    label: 't(Cancel Transition)',
    className: 'link',
    confirm: 't(Are you sure you want to cancel this transition?)',
    request: {
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/unschedule`,
      method: 'PATCH'
    }
  }

  if(advisor.team.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This advisor is pending configuration)' }
  } if(advisor.team.status === 'active') {
    details.alert = { color: 'green', message: 't(This advisor is active)' }
  } else if(advisor.team.status === 'configured') {
    details.alert = { color: 'blue', message: 't(This advisor has configured their account)' }
  } else if(advisor.team.status === 'pausing') {
    details.alert = { color: 'teal', message: (
      <>This advisor will be paused on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'reactivating') {
    details.alert = { color: 'teal', message: (
      <>This advisor will reactivate on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'resuming') {
    details.alert = { color: 'teal', message: (
      <>This advisor will resume on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'canceling') {
    details.alert = { color: 'teal', message: (
      <>This advisor will cancel on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
    ) }
  } else if(advisor.team.status === 'unconfigured') {
    details.alert = { color: 'grey', message: 't(This advisor did not complete their configuration within 30 days)' }
  } else if(advisor.team.status === 'paused') {
    details.alert = { color: 'yellow', message: 't(This advisor is paused)' }
  } else if(advisor.team.status === 'inactive') {
    details.alert = { color: 'red', message: 't(This advisor is inactive)' }
  } else if(advisor.team.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This advisor has been canceled)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } />,
  tasks: {
    items: [
      {
        label: 't(Edit Advisor)',
        modal: <Edit advisor={ props.advisor } />
      },
      {
        label: 't(Change Agency)',
        modal: <Change advisor={ props.advisor } />
      },
      {
        label: 't(Activate Advisor)',
        show: props.advisor.team.status === 'configured',
        confirm: 't(Are you sure you want to activate this advisor?)',
        request: {
          endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/activate`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully activated this advisor)'),
          onFailure: () => context.flash.set('error', 't(Unable to activate this advisor)')
        }
      },
      {
        label: 't(Reactivate Advisor)',
        show: props.advisor.team.status === 'canceled',
        modal: <Reactivate advisor={ props.advisor } />
      },
      {
        label: 't(Pause Advisor)',
        show: props.advisor.team.status === 'active',
        modal: <Pause advisor={ props.advisor } />
      },
      {
        label: 't(Resume Advisor)',
        show: props.advisor.team.status === 'paused',
        modal: <Resume advisor={ props.advisor } />
      },
      {
        label: 't(Cancel Advisor)',
        show: !_.includes(['canceled','inactive','canceling'], props.advisor.team.status),
        modal: <Cancel advisor={ props.advisor } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
