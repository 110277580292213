import CardField from './cardfield'
import PropTypes from 'prop-types'
import React from 'react'

const CardFieldContainer = (props) => {

  return <CardField { ...props } />

}

CardFieldContainer.propTypes = {
  assigned: PropTypes.array,
  unassigned: PropTypes.array
}

export default CardFieldContainer