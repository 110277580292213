import New from '@apps/platform/admin/views/icons/new'
import IconToken from '@apps/maha/admin/tokens/icon'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Icons)',
  collection: {
    endpoint: '/api/admin/platform/icons',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Icon)', key: 'noun_project_id', primary: true, format: IconToken }
    ],
    defaultSort: { key: 'noun_project_id', order: 'asc' },
    empty: {
      icon: 'picture',
      title: 't(No Icons)',
      text: 't(You have not yet added any icons)'
    },
    entity: 'icon'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Add Icon)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
