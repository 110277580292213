import FileField from '@admin/components/form/filefield'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Avatar extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    token: PropTypes.string,
    onNext: PropTypes.func
  }

  state = {
    photo_id: null
  }
  
  _handleChange = this._handleChange.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    const { photo_id } = this.state
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-content">
            <h2><T text="t(Upload a Photo)" /></h2>
            <p><T text="t(Please select a photo to help others identify you and your content across the platform)" /></p>
            <FileField { ...this._getFilefield() } />
            { photo_id ?
              <Button { ...this._getContinue() }/> :
              <Button { ...this._getSkip() }/>
            }
          </div>
        </div>
      </div>
    )
  }

  _getContinue() {
    return {
      label: 't(Continue)',
      color: 'blue',
      size: 'large',
      handler: this._handleNext
    }
  }

  _getFilefield() {
    const { token } = this.props
    return {
      type: 'filefield',
      button: (
        <div className="maha-signin-avatar">
          <div className="maha-signin-avatar-placeholder">
            <Icon icon="user-circle" />
          </div>
        </div>
      ),
      action: '/api/admin/activate/assets/upload',
      endpoint: '/api/admin/activate/assets',
      token,
      types: ['jpg','png','gif','jpeg'],
      onChange: this._handleChange
    }
  }

  _getSkip() {
    return {
      label: 't(Skip for now)',
      className: 'link',
      handler: this._handleNext
    }
  }

  _handleChange(photo_id) {
    const { token } = this.props
    this.context.network.request({
      endpoint: '/api/admin/activate/avatar',
      method: 'POST',
      body: { token, photo_id },
      onSuccess: this._handleNext

    })
  }

  _handleNext() {
    setTimeout(() => {
      this.props.onNext()
    }, 1000)
  }

}

export default Avatar
