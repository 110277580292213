import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Service from './service'
import Terms from './terms'
import React from 'react'

class ProfileConnect extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    account_id: PropTypes.number,
    program_id: PropTypes.number,
    sources: PropTypes.array,
    team_id: PropTypes.number,
    types: PropTypes.array,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  stackRef = React.createRef()

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleTerms = this._handleTerms.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    this._handleService()
  }

  _getStack() {
    return {
      display_name: 'profile_connect',
      ref: this.stackRef
    }
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleService() {
    const { types, sources, onBack, onCancel } = this.props
    const props = {
      sources,
      types,
      onBack,
      onCancel,
      onChoose: this._handleTerms
    }
    this._handlePush(Service, props)
  }

  _handleTerms(service) {
    const { account_id, team_id, program_id, onDone } = this.props
    const props = {
      account_id,
      team_id,
      program_id,
      service,
      onBack: this._handlePop,
      onDone
    }
    this._handlePush(Terms, props)
  }

}

export default ProfileConnect
