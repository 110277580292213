export const onSet = (values) => ({
  type: 'SET',
  values
})

export const onMode = (mode) => ({
  type: 'MODE',
  mode
})

export const onRatio = (ratio) => ({
  type: 'RATIO',
  ratio
})
