import ModalPanel from '@admin/components/modal_panel'
import Textarea from '@admin/components/form/textarea'
import PropTypes from 'prop-types'
import React from 'react'

class Prompt extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
  }

  state = {
    value: ''
  }

  _handleBack = this._handleBack.bind(this)
  _handleNext = this._handleNext.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-dalle-prompt">
          <Textarea { ...this._getTextarea() } />
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    const panel = {
      title: 't(Write a Prompt)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      buttons: [
        { label: 'Generate Image', color: 'blue', handler: this._handleNext }
      ]
    }
    return panel
  }

  _getTextarea() {
    const { value } = this.state
    return {
      placeholder: 'Describe the image you\'d like to generate...',
      value,
      onChange: this._handleUpdate
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleNext() {
    const { prompt } = this.state
    this.props.onNext(prompt)
  }

  _handleUpdate(prompt) {
    this.setState({ prompt })
  }

}

export default Prompt
