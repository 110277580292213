import Password from './password'
import PropTypes from 'prop-types'
import React from 'react'

const PasswordContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus()
  }))

  return <Password ref={ componentRef } {...props} />

})

export default PasswordContainer