import WebsiteToken from '@apps/websites/admin/tokens/website'
import StatusToken from '@admin/tokens/status'
import Page from '@admin/components/page'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Websites)',
  collection: {
    endpoint: '/api/admin/websites/websites',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Website)', key: 'title', primary: true, padded: false, format: (website) => (
        <WebsiteToken website={ website }/>
      ) },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'mouse-pointer',
      title: 't(No Websites)',
      text: 't(You have not yet created any websites)',
      buttons: [
        { label: 't(Create Website)', modal: New }
      ]
    },
    recordTasks: (website) => [
      {
        label: 't(Design Website)',
        route: `/admin/websites/${website.id}/design`
      }
    ],
    entity: 'site',
    onClick: (record) => context.router.push(`/websites/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: 't(Create Website)', modal: New }
    ]
  }
})

export default Page(null, mapPropsToPage)
