export const onAdd = (index, field) => ({
  type: 'ADD',
  index,
  field
})

export const onClone = (index, code) => ({
  type: 'CLONE',
  index,
  code
})

export const onEdit = (field) => ({
  type: 'EDIT',
  field
})

export const onMove = (from, to) => ({
  type: 'MOVE',
  from,
  to
})

export const onRemove = (field) => ({
  type: 'REMOVE',
  field
})

export const onSetConfig = (config) => ({
  type: 'SET_CONFIG',
  config
})

export const onSetStatus = (status) => ({
  type: 'SET_STATUS',
  status
})

export const onUpdate = (key, value) => ({
  type: 'UPDATE',
  key,
  value
})
