import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class ProgramChooser extends React.Component {

  static propTypes = {
    requires: PropTypes.array,
    onChoose: PropTypes.func
  }

  static defaultProps = {
    requires: []
  }

  state = {
    q: ''
  }

  _handleChoose = this._handleChoose.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    return (
      <div className="crm-program-chooser">
        <div className="crm-program-chooser-search">
          <Searchbox { ...this._getSearchbox() } />
        </div>
        <div className="crm-program-chooser-results">
          <Infinite { ...this._getInfinite() } />
        </div>
      </div>
    )
  }

  _getInfinite() {
    const { requires } = this.props
    const { q } = this.state
    return {
      endpoint: '/api/admin/programs',
      filter: {
        q,
        access_type: {
          $neq: 'view'
        }
      },
      layout: Results,
      props: {
        requires,
        onChoose: this._handleChoose
      }
    }
  }

  _getSearchbox() {
    return {
      prompt: 'Find program...',
      onChange: this._handleType
    }
  }

  _handleChoose(program) {
    this.props.onChoose(program)
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default ProgramChooser
