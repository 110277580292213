import ModalPanel from '@admin/components/modal_panel'
import Adjustments from './adjustments'
import PropTypes from 'prop-types'
import Panel from './panel'
import React from 'react'
import Crop from './crop'

class ImageEditor extends React.Component {

  static propTypes = {
    adjustments: PropTypes.object,
    asset: PropTypes.object,
    crop: PropTypes.object,
    customRatio: PropTypes.number,
    frame: PropTypes.object,
    max_ratio: PropTypes.number,
    mode: PropTypes.string,
    min_ratio: PropTypes.number,
    offset: PropTypes.object,
    original: PropTypes.object,
    ratio: PropTypes.number,
    rotation: PropTypes.object,
    scaled: PropTypes.object,
    spin: PropTypes.number,
    sprite: PropTypes.object,
    transforms: PropTypes.object,
    viewport: PropTypes.object,
    zoom: PropTypes.number,
    zoomScale: PropTypes.number,
    zoomed: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onMode: PropTypes.func,
    onRatio: PropTypes.func,
    onSet: PropTypes.func
  }

  static defaultProps = {
    transforms: {}
  }

  _handleInit = this._handleInit.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    const { original, ratio } = this.props
    if(!original) return null
    return (
      <ModalPanel { ...this._getModalPanel() }>
        <div className={ this._getClass() }>
          { _.isNil(ratio) &&
            <div className="maha-imageeditor-crop">
              <Crop { ...this._getCrop() } />
            </div>
          }
          <div className="maha-imageeditor-adjustments">
            <Adjustments { ...this._getAdjustments() } />
          </div>
          <Panel { ...this._getPanel() } />
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleInit()
  }

  _getAdjustments() {
    const { adjustments, onSet } = this.props
    return {
      adjustments,
      onSet
    }
  }

  _getClass() {
    const { mode } = this.props
    const classes = ['maha-imageeditor']
    if(mode) classes.push(mode)
    return classes.join(' ')
  }

  _getCrop() {
    const { customRatio, max_ratio, min_ratio, original, onRatio } = this.props
    return {
      customRatio,
      max_ratio,
      min_ratio,
      original,
      onRatio
    }
  }

  _getModalPanel() {
    const { onBack, onCancel } = this.props
    return {
      title: 't(Edit Image)',
      leftItems: onBack ? [
        { icon: 'chevron-left', handler: onBack }
      ] : onCancel ? [
        { label: 't(Cancel)', handler: onCancel }
      ] : [],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _getPanel() {
    const { asset, adjustments, customRatio, frame, mode, offset, ratio, rotation, scaled, spin, sprite, viewport, zoom, zoomScale, zoomed, onMode, onSet } = this.props
    return {
      asset,
      adjustments,
      customRatio,
      frame,
      mode,
      offset,
      ratio,
      rotation,
      scaled,
      spin,
      sprite,
      viewport,
      zoom,
      zoomScale,
      zoomed,
      onMode,
      onSet
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleDone() {
    const { adjustments, crop, rotation, offset, zoom } = this.props
    this.props.onDone({
      adjustments,
      ...crop.l + crop.t + crop.w + crop.h !== 0 ? { crop } : {},
      ...rotation.x + rotation.y + rotation.z !== 0 ? { rotation } : {},
      ...offset.x + offset.y !== 0 ? { offset } : {},
      ...zoom !== 0 ? { zoom } : {}
    })
  }

  _handleInit() {
    const { asset, transforms } = this.props
    const { height, width } = asset.metadata
    this.props.onSet({
      original: {
        w: width,
        h: height
      },
      ...transforms.adjustements ? {
        adjustments: transforms.adjustments
      } : {},
      ...transforms.crop ? {
        customRatio: transforms.crop.w / transforms.crop.h
      } : {},
      ...transforms.rotation ? {
        rotation: transforms.rotation
      } : {},
      ...transforms.zoom ? {
        zoom: transforms.zoom
      } : {}
    })
  }

  _handleSet(transforms) {
    this.props.onSet({
      adjustments: transforms.adjustments || { bri: 0, con: 0, vib: 0, sat: 0, sep: 0, hue: 0 },
      crop: transforms.crop || { l: 0, t: 0, w: 0, h: 0 },
      rotation: transforms.rotation || { x: 0, y: 0, z: 0 },
      offset: transforms.zoom || { x: 0, y: 0 },
      zoom: transforms.zoom || 0
    })
  }

}

export default ImageEditor
