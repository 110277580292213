import useActionReducer from '@admin/hooks/use_action_reducer'
import FormDesigner from './form_designer'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const FormDesignerContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'form_designer',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <FormDesigner { ...mergedProps } />

}

export default FormDesignerContainer