import BillingCycleToken from '@apps/subscriptions/admin/tokens/billing_cycle'
import UsagesToken from '@apps/subscriptions/admin/tokens/usages'
import Payment from '@apps/finance/admin/views/invoices/payment'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import Usage from '../usage'
import moment from 'moment'
import React from 'react'

const Overview = ({ service, renewal, subscription }) => {

  const contact = {
    label: renewal.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${renewal.contact.id}`
  }

  const servicelink = {
    label: service.title,
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}`
  }

  const subscriptionlink = {
    label: 't(View Subscription)',
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}`
  }

  const plan = {
    label: renewal.plan.title,
    className: 'link',
    route: `/admin/subscriptions/services/${service.id}/plans/${renewal.plan.id}`
  }

  const details = {
    audits: `subscriptions_renewals/${renewal.id}`,
    comments: `subscriptions_renewals/${renewal.id}`,
    sections: [
      {
        title: 't(Renewal Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...contact } /> },
          { label: 't(Service)', content: <Button { ...servicelink } /> },
          { label: 't(Subscription)', content: <Button { ...subscriptionlink } /> },
          { label: 't(Plan)', content: <Button { ...plan } /> }
        ]
      }
    ]
  }

  if(renewal.invoice_id) {
    const invoice = {
      label: 't(View Invoice)',
      className: 'link',
      route: `/admin/finance/invoices/${renewal.invoice_id}`
    }
    details.sections[0].items.push({ label: 't(Invoice)', content: <Button { ...invoice } /> })
  }

  details.sections[0].items.push({ label: 't(Billing Cycle)', content: <BillingCycleToken { ...renewal } /> })

  if(service.model === 'metered') {
    details.sections[0].items.push({ label: 't(Usage)', content: <UsagesToken usages={ renewal.usages } /> })
  }
  if(renewal.invoice) {
    details.sections[0].items.push({ label: 't(Total)', content: renewal.invoice.total, format: 'currency' })
  }
  if(renewal.error && renewal.status !== 'canceled') {
    const next = moment(renewal.last_attempted_at).add(1, 'day').startOf('day').add(8, 'hours')
    details.sections[0].items.push({ label: 't(Processing Error)', content: <span className="alert">{ renewal.error }</span> })
    details.sections[0].items.push({ label: 't(Processing Attempts)', content: <span className="alert">{ renewal.attempts } (next attempt on <Date datetime={next} />)</span> })
  }

  if(renewal.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This renewal is pending until the usage has been configured)' }
  } else if(renewal.status === 'configured') {
    details.alert = { color: 'blue', message: 't(This renewal is configured and ready to send)' }
  } else if(renewal.status === 'invoiced') {
    details.alert = { color: 'orange', message: 't(This renewal has been invoiced)' }
  } else if(renewal.status === 'sent') {
    details.alert = { color: 'green', message: 't(This renewal has been sent)' }
  } else if(renewal.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This renewal was canceled)' }
  } else if(renewal.status === 'paid') {
    details.alert = { color: 'green', message: 't(This renewal has been paid in full)' }
  } else if(renewal.status === 'failed') {
    details.alert = { color: 'red', message: 't(This renewal was unable to be processed)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  service: PropTypes.object,
  renewal: PropTypes.object,
  subscription: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview service={ props.service } renewal={ props.renewal } subscription={ props.subscription } />,
  tasks: {
    items: [
      { 
        label: 't(Configure Usage)', 
        show: props.renewal.status === 'pending', 
        modal: <Usage service={ props.service } renewal={ props.renewal } /> 
      },
      { 
        label: 't(Generate Invoice)', 
        show: !props.renewal.invoice_id 
      },
      {
        label: 't(Reprocess Renewal)',
        show: props.renewal.status === 'failed',
        request: {
          endpoint: `/api/admin/subscriptions/services/${props.service.id}/renewals/${props.renewal.id}/reprocess`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to reprocess this renewal)')
        }
      },
      { 
        label: 't(Receive Payment)', 
        show: !_.includes(['paid','canceled'], props.renewal.status), 
        modal: <Payment invoice_id={ props.renewal.invoice_id } contact_id={ props.renewal.contact.id } /> 
      },
      {
        label: 't(Cancel Renewal)',
        show: !_.includes(['paid','canceled'], props.renewal.status),
        confirm: 't(Are you sure you want to cancel this renewal?)',
        request: {
          endpoint: `/api/admin/subscriptions/services/${props.service.id}/renewals/${props.renewal.id}/cancel`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to cancel this renewal)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
