import Page from '@admin/components/page'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Locations)',
  collection: {
    endpoint: '/api/admin/events/locations',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true }
    ],
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      icon: 'map-marker',
      title: 't(No Locations)',
      text: 't(You have not yet created any locations)',
      buttons: []
    },
    entity: 'event'
  }
})

export default Page(null, mapPropsToPage)
