import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    user: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { user } = this.props
    const { admin, maha } = this.context
    return {
      title: 't(Edit User)',
      method: 'PATCH',
      endpoint: `/api/admin/team/users/${user.id}/edit`,
      action: `/api/admin/team/users/${user.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Notification Method)', name: 'email_notifications_method', type: 'lookup', options: [
              { value: 'none', text: 't(None)' },
              { value: 'ondemand', text: 't(On Demand)' },
              { value: 'digest', text: 't(Daily Digest)' }
            ] },
            { label: 't(Roles)', name: 'role_ids', type: 'lookup', placeholder: 'Assign roles', multiple: true, endpoint: '/api/admin/team/roles', valueKey: 'id', textKey: 'title' }
          ]
        },
        ...maha.appUserFields.filter(values => {
          return admin.apps.find(app => {
            return app.code !== values.app
          }) !== undefined
        }).reduce((values, app) => [
          ...values,
          ...app.fields(admin.user, this.context)
        ], [])
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
