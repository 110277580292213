import PropTypes from 'prop-types'
import React  from 'react'
import Item from './item'
import _  from 'lodash'

class Finder extends React.PureComponent {

  static propTypes = {
    items: PropTypes.array,
    selected: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    items: [],
    onChange: () => {}
  }

  state = {
    expanded: [],
    selected: null
  }

  _handleExpand = this._handleExpand.bind(this)
  _handleSelect = this._handleSelect.bind(this)

  render() {
    const { items } = this.props
    return (
      <div className="maha-finder">
        { items.map((item, index) => (
          <Item { ...this._getItem(item, index)} key={`item_${index}`} />
        )) }
      </div>
    )
  }

  componentDidMount() {
    const { items, selected } = this.props
    const expanded = this._getExpanded(items)
    this.setState({
      ...selected ? { selected } : {},
      ...expanded ? { expanded } : {}
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    if(selected !== prevState.selected && prevState.selected !== null) {
      this._handleChange()
    }
    if(this.props.selected !== prevProps.selected) {
      if(selected !== this.props.selected) {
        this._handleSelect(this.props.selected)
      }
    }
  }

  _getExpanded(items, prefix) {
    return [
      ...items.reduce((expanded, item, index) => {
        if(!item) return expanded
        return [
          ...expanded,
          ...item.collapsing !== false && item.collapsed === false ? [`${ prefix ? `${prefix}.${index}` : index }`] : [],
          ...item.children ? this._getExpanded(item.children, `${prefix ? `${prefix}.${index}` : index }`) : []
        ]
      }, [])
    ]
  }

  _getItem(item, index) {
    const { expanded, selected } = this.state
    return {
      ...item,
      expanded,
      index: `${index}`,
      selected,
      onExpand: this._handleExpand,
      onSelect: this._handleSelect
    }
  }

  _getResolved(index) {
    const { items } = this.props
    const resolved = index.replace(/\./g,'.children.')
    return _.get(items, resolved)
  }

  _handleChange() {
    const { selected } = this.state
    const item = this._getResolved(selected)
    this.props.onChange(selected, item)
  }

  _handleExpand(index) {
    const { expanded } = this.state
    this.setState({
      expanded: _.xor(expanded, [index])
    })
  }

  _handleSelect(selected) {
    const item = this._getResolved(selected)
    if(item.onClick) return item.onClick()
    if(selected === this.state.selected) return
    const levels = selected.split('.')
    const expanded = levels.reduce((items, item, index) => [
      ...items,
      levels.slice(0,index).join('.')
    ], this.state.expanded)
    this.setState({ selected, expanded })
  }
}

export default Finder
