import Img from '@admin/components/html/img'
import Page from '@admin/components/page'
import Attendance from './attendance'
import Overview from './overview'
import React from 'react'

const getTabs = ({ attendings, event, ticket }) => ({
  header: <Img className="events-ticket-qrcode" src={`/qrcode/${ticket.code}`} />,
  items: [
    { label: 't(Overview)', component: <Overview event={ event } ticket={ ticket } /> },
    { label: 't(Attendance)', component: <Attendance attendings={ attendings } /> }
  ]
})

const getTasks = ({ attendings, event, ticket }) => ({
  items: [
    { label: 'View eTicket', link: ticket.eticket }
  ]
})

const mapResourcesToPage = (props, context) => ({
  event: `/api/admin/events/events/${props.params.event_id}`,
  ticket: `/api/admin/events/events/${props.params.event_id}/tickets/${props.params.id}`,
  attendings: `/api/admin/events/events/${props.params.event_id}/tickets/${props.params.id}/attendings`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Ticket)',
  tabs: getTabs(resources, context),
  tasks: getTasks(resources, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
