export const INITIAL_STATE = {
  files: [],
  sources: [],
  status: 'loading'
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CLEAR':
    return {
      ...state,
      files: []
    }

  case 'ADD':
    return {
      ...state,
      files: [
        ...state.files,
        action.file
      ]
    }

  case 'UPDATE':
    return {
      ...state,
      files: state.files.map((file, index) => ({
        ...file,
        ...index === action.index ? action.data : {}
      }))
    }

  case 'REMOVE':
    return {
      ...state,
      files: state.files.filter((file, index) => {
        return index !== action.index
      })
    }

  case 'SET_SOURCES':
    return {
      ...state,
      sources: action.sources,
      status: 'loaded'
    }

  default:
    return state

  }

}

export default reducer
