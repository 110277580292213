import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'
import qs from 'qs'

class IconToken extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    bg: PropTypes.string,
    color: PropTypes.string,
    fg: PropTypes.string,
    icon: PropTypes.object,
    r: PropTypes.number,
    size: PropTypes.number,
    w: PropTypes.number
  }

  render() {
    return (
      <div className="icon-token">
        <Img src={ this._getSrc() } />
      </div>
    )
  }

  _getSrc() {
    const { noun_project_id, social } = this.props.icon
    if(noun_project_id) return this._getNoun()
    if(social) return this._getSocial()
  }

  _getNoun() {
    const { bg, color, fg, icon, r, size, w } = this.props
    const { provider } = this.context
    const host = provider.cdn_host
    const query = qs.stringify({
      ...color ? { fg: color } : {},
      ...bg ? { bg } : {},
      ...fg ? { fg } : {},
      ...r ? { r } : {},
      ...size ? { w: size } : {},
      ...w ? { w } : {},
      dpi: 2
    })
    return `${host}/icons/noun/${query}/${icon.noun_project_id}.png`
  }

  _getSocial() {
    const { bg, color, fg, icon, r, size, w } = this.props
    const { provider } = this.context
    const host = provider.cdn_host
    const query = qs.stringify({
      ...color ? { fg: color } : {},
      ...bg ? { bg } : {},
      ...fg ? { fg } : {},
      ...r ? { r } : {},
      ...size ? { w: size } : {},
      ...w ? { w } : {},
      dpi: 2
    })
    return `${host}/icons/social/${query}/${icon.social}.png`
  }

}

export default IconToken
