import FormToken from '@apps/forms/admin/tokens/form'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Forms)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/forms`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: FormToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check-square-o',
      title: 't(No Forms)',
      text: 't(You have not yet created any forms for this advisor)',
      buttons: [
        { 
          label: 't(Create New Form)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'form',
    onClick: (record) => context.host.window.open(`/${props.advisor.team.subdomain}/forms/forms/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Form)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
