const clamp = (value) => Math.max(Math.min(value, 255), 0)

const toHsv = (r, g, b) => {
  r /= 255, g /= 255, b /= 255
  var max = Math.max(r, g, b), min = Math.min(r, g, b)
  var h, s, v = max
  var d = max - min
  s = max == 0 ? 0 : d / max
  if (max == min)  h = 0
  else if(max === r) h = (g - b) / d + (g < b ? 6 : 0)
  else if(max === g) h = (b - r) / d + 2
  else if(max === b) h = (r - g) / d + 4
  h /= 6
  return { h, s, v }
}

const toRgb = (h, s, v) => {
  var r, g, b
  var i = Math.floor(h * 6)
  var f = h * 6 - i
  var p = v * (1 - s)
  var q = v * (1 - f * s)
  var t = v * (1 - (1 - f) * s)
  const m = i % 6
  if(m === 0) r = v, g = t, b = p
  if(m === 1) r = q, g = v, b = p
  if(m === 2) r = p, g = v, b = t
  if(m === 3) r = p, g = q, b = v
  if(m === 4) r = t, g = p, b = v
  if(m === 5) r = v, g = p, b = q
  return { r: r * 255, g: g * 255, b: b * 255 }
}

export const brightness = (data, step, amount) => {
  const adjust = Math.floor(255 * (amount / 100))
  const bri = (value) => value + adjust
  for (var i = 0; i < data.length; i+= step) {
    data[i] = clamp(bri(data[i]))
    data[i+1] = clamp(bri(data[i+1]))
    data[i+2] = clamp(bri(data[i+2]))
  }
}

export const contrast = (data, step, amount) => {
  const adjust = Math.pow((amount + 100) / 100, 2)
  var con = (value) => ((((value / 255) - 0.5) * adjust) + 0.5) * 244
  for (var i = 0; i < data.length; i+= step) {
    data[i] = clamp(con(data[i]))
    data[i+1] = clamp(con(data[i+1]))
    data[i+2] = clamp(con(data[i+2]))
  }
}

export const vibrance = (data, step, amount) => {
  const adjust = amount * -1
  for (var i = 0; i < data.length; i+= step) {
    const max = Math.max(data[i], data[i+1], data[i+2])
    const avg = (data[i] + data[i+1] + data[i+2]) / 3
    const amt = ((Math.abs(max - avg) * 2 / 255) * adjust) / 100
    const vib = (value) => value + (value !== max ? (max - value) * amt : 0)
    data[i] = clamp(vib(data[i]))
    data[i+1] = clamp(vib(data[i+1]))
    data[i+2] = clamp(vib(data[i+2]))
  }
}

export const saturation = (data,step, amount) => {
  const adjust = amount * -0.01
  for (var i = 0; i < data.length; i+= step) {
    const max = Math.max(data[i], data[i+1], data[i+2])
    const sat = (value) => value + (value !== max ? (max - value) * adjust : 0)
    data[i] = clamp(sat(data[i]))
    data[i+1] = clamp(sat(data[i+1]))
    data[i+2] = clamp(sat(data[i+2]))
  }
}

export const hue = (data, step, amount) => {
  for (var i = 0; i < data.length; i+= step) {
    const hsv = toHsv(data[i], data[i+1], data[i+2])
    const h = (((hsv.h * 100) + Math.abs(amount)) % 100) / 100
    const rgb = toRgb(h, hsv.s, hsv.v)
    data[i] = rgb.r
    data[i+1] = rgb.g
    data[i+2] = rgb.b
  }
}

export const sepia = (data, step, amount) => {
  const adjust = amount / 100
  for (var i = 0; i < data.length; i+= step) {
    data[i] = Math.min(255, (data[i] * (1 - (0.607 * adjust))) + (data[i+1] * (0.769 * adjust)) + (data[i+2] * (0.189 * adjust)))
    data[i+1] = Math.min(255, (data[i] * (0.349 * adjust)) + (data[i+1] * (1 - (0.314 * adjust))) + (data[i+2] * (0.168 * adjust)))
    data[i+2] = Math.min(255, (data[i] * (0.272 * adjust)) + (data[i+1] * (0.534 * adjust)) + (data[i+2] * (1 - (0.869 * adjust))))
  }
}
