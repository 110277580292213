import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Button from '@admin/components/button'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class PaymentField extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onDone: PropTypes.func,
    onTokens: PropTypes.func,
    onUpdate: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate(config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      title: 't(Payment / Donation)',
      onChange: this._handleChange,
      onSubmit: this._handleDone,
      cancelText: null,
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'tokenfield', required: true, value: config.name },
            { label: 't(Label)', name: 'label', type: 'textfield', value: config.label },
            { label: 't(Instructions)', name: 'instructions', type: 'htmlfield', value: config.instructions },
            { label: 't(Required)', name: 'required', type: 'checkbox', prompt: 't(This field is required)', value: config.required },
            { label: 't(Description)', name: 'description', type: 'textfield', required: true, after: <Button { ...this._getTokens() } />, value: config.description },
            { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken, value: config.project_id },
            { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, value: config.revenue_type_id },
            { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)', value: config.is_tax_deductible || false }
          ]
        }
      ]
    }
  }

  _getDefault() {
    return {
      label: '',
      token: '',
      instructions: '',
      required: false,
      products: []
    }
  }

  _getTokens() {
    const { onTokens } = this.props
    return {
      label: 'you can use these tokens',
      className: 'link',
      handler: onTokens
    }
  }

  _handleChange(config) {
    const { label } = config
    const name = _.get(config, 'name.value')
    this.setState({
      config: {
        ...this.state.config,
        ...config,
        ...config.name && ((!label && name.length === 1) || name.slice(0,-1) === label) ? {
          label: config.name.value
        } : {}
      }
    })
  }

  _handleDone() {
    this.props.onDone()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

}

export default PaymentField
