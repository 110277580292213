import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    maha: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { admin, maha } = this.context
    return {
      title: 't(New User)',
      method: 'POST',
      action: '/api/admin/team/users',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(First Name)', name: 'first_name', type: 'textfield', required: true },
            { label: 't(Last Name)', name: 'last_name', type: 'textfield', required: true },
            { label: 't(Email)', name: 'email', type: 'emailfield', required: true },
            { label: 't(Roles)', name: 'role_ids', type: 'lookup', placeholder: 't(Assign Roles)', multiple: true, endpoint: '/api/admin/team/roles', valueKey: 'id', textKey: 'title' }
          ]
        },
        ...maha.appUserFields.filter(values => {
          return admin.apps.find(app => {
            return app.code !== values.app
          }) !== undefined
        }).reduce((values, app) => [
          ...values,
          ...app.fields(admin.user, this.context)
        ], [])
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.router.push(`/team/users/${result.id}`)
    this.context.modal.close()
  }

}

export default New
