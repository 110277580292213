import PaymentStrategyToken from '@apps/subscriptions/admin/tokens/payment_strategy'
import InitialRenewalToken from '@apps/subscriptions/admin/tokens/initial_renewal'
import PaymentField from '@apps/finance/admin/components/paymentfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Payment extends React.PureComponent {

  static propTypes = {
    cards: PropTypes.array,
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata, props } = this.props
    const { plans, service } = props
    const { config } = this.state
    const plan = _.find(plans, { id: formdata.plan_id })
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 'Method', name: 'payment_strategy', type: 'radiogroup', options: ['card','invoice'], defaultValue: 'card', format: PaymentStrategyToken },
            ...config.payment_strategy === 'card' ? [
              { label: 't(Payment)', name: 'payment', type: PaymentField, color: 'blue', program: service.program, contact: { id: formdata.contact_id }, allowedMethods: ['card','ach'] }
            ] : [
              { label: 'Terms', name: 'payment_terms', type: 'radiogroup', options: [
                { value: 10, text: 't(NET10)' },
                { value: 30, text: 't(NET30)' },
                { value: 60, text: 't(NET60)' }
              ], defaultValue: 10 }
            ],
            ...(service.model === 'flat' && plan.type === 'paid') ? [
              { label: 'Initial Renewal', type: 'text', component: (
                <InitialRenewalToken plan={ plan } service={ service } subscription={ formdata } />
              ) }
            ] : []
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onSave(subscription)
  }

}

export default Payment
