import Card from './card'

const card = {
  code: 'greeting',
  title: 't(Greeting)',
  description: 'A daily greeting',
  card: Card
}

export default card
