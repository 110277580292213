import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'

class Item extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.array,
    className: PropTypes.string,
    collapsing: PropTypes.bool,
    count: PropTypes.number,
    expanded: PropTypes.array,
    icon: PropTypes.string,
    index: PropTypes.string,
    label: PropTypes.any,
    padding: PropTypes.number,
    panel: PropTypes.any,
    route: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.string,
    show: PropTypes.bool,
    tasks: PropTypes.array,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    onSelect: PropTypes.func,
    onExpand: PropTypes.func
  }

  static defaultProps = {
    collapsing: true,
    count: 0,
    padding: 0,
    selectable: true
  }

  _handleClick = this._handleClick.bind(this)
  _handleEnter = this._handleEnter.bind(this)
  _handleExpand = this._handleExpand.bind(this)
  _handleLeave = this._handleLeave.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    const { children, collapsing, count, icon, label, padding, show, tasks } = this.props
    const is_expanded = this._getIsExpanded()
    if(show === false) return null
    return (
      <Fragment>
        <div { ...this._getItem(is_expanded) }>
          { padding > 0 &&
            <Fragment>
              { Array(padding).fill(0).map((i, index) => (
                <div className="maha-finder-item-padding" key={`padding_${index}`} />
              ))}
            </Fragment>
          }
          { (collapsing && children && children.length > 0) ?
            <div className="maha-finder-item-toggle" onClick={ this._handleExpand }>
              <Icon icon="chevron-right" />
            </div> :
            <div className="maha-finder-item-padding" />
          }
          { icon &&
            <div className="maha-finder-item-icon">
              <Icon icon={ icon } />
            </div>
          }
          <div className="maha-finder-item-details">
            <T text={ label } />
          </div>
          { count > 0 &&
            <div className="maha-finder-item-count">
              <span>{ count }</span>
            </div>
          }
          { tasks &&
            <Button { ...this._getTasks() } />
          }
        </div>
        { children && (is_expanded || !collapsing) &&
          <Fragment>
            { children.map((child, index) => (
              <Item { ...this._getChild(child, index) } key={`item_${index}`} />
            )) }
          </Fragment>
        }
      </Fragment>
    )
  }

  _getClass(is_expanded) {
    const { className, index, selected } = this.props
    const classes = ['maha-finder-item']
    if(className) classes.push(className)
    if(is_expanded) classes.push('active')
    if(index === selected) classes.push('selected')
    return classes.join(' ')
  }

  _getChild(child, cindex) {
    const { expanded, index, padding, selected, onExpand, onSelect } = this.props
    return {
      ...child,
      expanded,
      index: `${index}.${cindex}`,
      padding: padding + 1,
      selected,
      onExpand,
      onSelect
    }
  }

  _getIsExpanded() {
    const { index, expanded } = this.props
    return _.includes(expanded, index)
  }

  _getItem(is_expanded) {
    const { onEnter, onLeave } = this.props
    return {
      className: this._getClass(is_expanded),
      onClick: this._handleClick,
      onContextMenu: this._handleTasks,
      ...onEnter ? { onMouseEnter: this._handleEnter } : {},
      ...onLeave ? { onMouseLeave: this._handleLeave } : {}
    }
  }

  _getExpander(is_expanded) {
    return is_expanded ? 'chevron-down' : 'chevron-right'
  }

  _getTasks() {
    return {
      icon: 'ellipsis-v',
      className: 'maha-finder-item-action',
      handler: this._handleTasks
    }
  }

  _handleClick(e) {
    e.stopPropagation()
    const { children, index, panel, route, selectable, onClick } = this.props
    if(!selectable) return
    if(route) return this.context.router.push(route)
    if(panel || onClick) return this.props.onSelect(index)
    if(children) this.props.onExpand(index)
  }

  _handleEnter(e) {
    e.stopPropagation()
    this.props.onEnter()
  }

  _handleExpand(e) {
    e.stopPropagation()
    const { children } = this.props
    const { index} = this.props
    if(children) this.props.onExpand(index)
  }

  _handleLeave(e) {
    e.stopPropagation()
    this.props.onLeave()
  }

  _handleTasks(e) {
    e.stopPropagation()
    e.preventDefault()
    const { tasks } = this.props
    this.context.tasks.open({
      items: tasks
    })
  }

}

export default Item
