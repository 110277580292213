import LayoutField from '@admin/components/mjson_designer/components/layoutfield'
import { getCode } from '@core/utils/codes'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class NewRow extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(New Row)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onChangeField: this._handleChangeField,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { label: 't(Label)', name: 'label', type: 'textfield', placeholder: 'Enter Label', required: true, defaultValue: 'Row' },
            { label: 't(Row)', type: 'segment', fields: [
              { label: 't(Columns per Row)', name: 'columns', type: 'optionsfield', options: [1,2,3,4,6], defaultValue: 1, value: config.columns },
              { label: 't(Column Layout)', name: 'layout', type: LayoutField, value: config.layout }
            ] }
          ]
        }
      ]
    }
  }

  _getColumn() {
    return {
      id: getCode(5),
      entity: 'column',
      meta: {
        label: 'Column'
      },
      settings: {},
      styles: {
        padding: '15px'
      },
      content: {
        children: []
      }
    }
  }

  _getRow(config) {
    const { type } = this.props
    return {
      id: getCode(5),
      entity: 'row',
      meta: {
        label: config.label
      },
      settings: {},
      styles: {
        ...type === 'email' ? {
          background: {
            type: 'color',
            color: {
              value: '#FFFFFF'
            }
          },
          rowWidth: '650px'  
        } : {
          rowWidth: '1200px'
        },
        padding: '15px'
      },
      content: {
        layout: config.layout,
        children: Array(config.columns).fill(0).map(column => {
          return this._getColumn()
        })
      }
    }
  }

  _getUnique(config) {
    return {
      ...config,
      id: getCode(5),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleChangeField(key, value) {
    if(key === 'columns') {
      this.setState({
        config: {
          ...this.state.config,
          layout: Array(value).fill(0).map(column => 12/value) 
        } 
      })
    }
  }

  _handleSubmit(config) {
    this.props.onDone(this._getRow(config))
    this.context.modal.close()
  }

}

export default NewRow
