import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useModalContext } from '@admin/components/modal'
import Container from '@admin/components/container'
import React, { useEffect } from 'react'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Fields from './fields'

const FieldsContainer = (props) => {

  const { modal } = useModalContext()
  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'fields',
    actions,
    props,
    reducer
  })

  useEffect(() => {
    handlers.onSet(props.fields)
  }, [props.fields])

  const mergedProps = {
    context: {
      modal,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Fields { ...mergedProps } />

}

FieldsContainer.propTypes = {
  fields: PropTypes.array,
  parent_id: PropTypes.number,
  parent_type: PropTypes.string
}

const mapResources = (props, context) => ({
  fields: `/api/admin/${props.parent_id ? `${props.parent_type}/${props.parent_id}` : `${props.parent_type}`}/fields`
})

export default Container(mapResources)(FieldsContainer)