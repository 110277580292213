import PropTypes from 'prop-types'
import React from 'react'

class AccessTypeToken extends React.Component {

  static propTypes = {
    access_type_id: PropTypes.number,
    is_revoked: PropTypes.bool
  }

  render() {
    const { access_type_id, is_revoked } = this.props
    if(is_revoked) return (
      <div className="maha-assignment-item-access revoked">
        REVOKED
      </div>
    )
    if(access_type_id === 1) return (
      <div className="maha-assignment-item-access owner">
        IS OWNER
      </div>
    )
    if(access_type_id === 2) return (
      <div className="maha-assignment-item-access edit">
        CAN EDIT
      </div>
    )
    if(access_type_id === 3) return (
      <div className="maha-assignment-item-access view">
        CAN VIEW
      </div>
    )
  }

}

export default AccessTypeToken
