import Clone from '@apps/team/admin/views/themes/clone'
import New from '@apps/team/admin/views/themes/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Themes)',
  access: { 
    programs: { $canView: props.program.id },
    rights: { $oneOf: ['team:view_templates','team:manage_templates']} 
  },
  collection: {
    endpoint: '/api/admin/team/themes',
    defaultQuery: {
      program_id: props.program.id
    },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Theme)', key: 'title', primary: true },
    ],
    empty: {
      title: 't(No Themes)',
      text: 't(There are no themes for this program)',
      icon: 'paint-brush',
      buttons: props.program.deleted_at === null && props.program.access_type !== 'view' ? [
        { 
          label: 't(Create Theme)',
          access: { 
            programs: { $canEdit: props.program.id },
            rights: { $oneOf: ['team:manage_templates'] } 
          },
          modal: <New program_id={ props.program.id } />
        }
      ] : null
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' }
    ],
    entity: 'theme',
    defaultSort: { key: 'created_at', order: 'desc' },
    recordTasks: (record) => [
      { label: 't(Clone Theme)', modal: <Clone theme={ record } /> },
      {
        label: 't(Make Default)',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        show: !record.is_default,
        request: {
          endpoint: `/api/admin/team/themes/${record.id}/activate`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to make themes default)'),
          onSuccess: () => context.flash.set('success', 't(Successfully to made themes default)')
        }
      },
      {
        label: 'Delete Theme',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        confirm: 't(Are you sure you want to delete this theme?)',
        request: {
          endpoint: `/api/admin/team/themes/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this theme)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this theme)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/team/themes/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Theme)',
    access: {
      programs: { $canEdit: props.program.id },
      rights: { $oneOf: ['team:manage_templates'] }
    },
    modal: <New program_id={ props.program.id } />
  }
})

export default Panel(null, mapPropsToPanel)