import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class List extends React.Component {

  static propTypes = {
    contact: PropTypes.object,
    phone_number: PropTypes.object,
    program: PropTypes.object,
    selected: PropTypes.object,
    onChoose: PropTypes.func
  }

  state = {
    q: ''
  }

  _handleType = this._handleType.bind(this)

  render() {
    return (
      <div className="sms-reader-list">
        <Searchbox { ...this._getSearchbox() } />
        <Infinite { ...this._getInfinite() } />
      </div>
    )
  }

  _getInfinite() {
    const { contact, phone_number, program, selected, onChoose } = this.props
    const perspective = this._getPerspective()
    const { q } = this.state
    return {
      endpoint: '/api/admin/phone/conversations',
      query: {
        ...contact ? { contact_id: contact.id } : {},
        ...phone_number ? { phone_number_id: phone_number.id } : {},
        ...program ? { program_id: program.id } : {}
      },
      filter: {
        ...q.length > 0 ? { q } : {}
      },
      layout: Results,
      props: {
        perspective,
        selected,
        onChoose
      }
    }
  }

  _getPerspective() {
    const { contact, phone_number, program } = this.props
    if(phone_number) return 'program'
    if(program) return 'program'
    if(contact) return 'contact'
  }

  _getSearchbox() {
    return {
      prompt: 'Search',
      onChange: this._handleType
    }
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default List
