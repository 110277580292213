import * as options from '../../variables'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Security extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleReset = this._handleReset.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config.confirmation
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate('confirmation', config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(Confirmation Page)',
      onCancel: this._handleDone,
      onChange: this._handleChange,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleDone }
      ],
      sections: [
        {
          fields: [
            { label: 'Once Form is Submitted', name: 'strategy', type: 'radiogroup', options: options.strategies, defaultValue: config.strategy },
            this._getConfirmation()
          ]
        }
      ]
    }
  }

  _getDefault() {
    return {
      strategy: 'message',
      message: '<p>Thank you for your submission</p>',
      redirect: ''
    }
  }

  _getConfirmation() {
    const { config } = this.state
    if(config.strategy === 'message') {
      return { label: 't(Message)', name: 'message', type: 'htmlfield', defaultValue: config.message }
    }
    return { label: 't(URL)', name: 'redirect', type: 'textfield', placeholder: 'http://', defaultValue: config.redirect }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone() {
    this.props.onPop()
  }

  _handleReset() {
    this.setState({
      config: this._getDefault()
    })
  }

}

export default Security
