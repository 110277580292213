import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Library extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    entity: PropTypes.object,
    library: PropTypes.string,
    website: PropTypes.object,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { entity, library } = this.props
    return {
      title: 't(Add to Library)',
      action: `/api/admin${library}/content`,
      method: 'POST',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Library)', name: 'type', type: 'radiogroup', options: [
              { value: 'email_module', text: 't(Add to Module Library)' },
              { value: 'email_article', text: 't(Add to Email Article Library)' }
            ], defaultValue: 'email_module' },
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 'Enter Title', required: true },
            { name: 'config', type: 'hidden', value: entity }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(item) {
    // this.props.onDone(item.config.content.children[0])
    this.context.flash.set('success', 't(Added to Library)')
    this.context.modal.close()
  }

}

export default Library
