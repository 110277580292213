import Assignment from '@admin/components/assignment'
import UserToken from '@admin/tokens/user'
import PropTypes from 'prop-types'
import React from 'react'

class Users extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    project_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <Assignment { ...this._getAssignment() } />
  }

  _getAssignment() {
    const { project_id } = this.props
    return {
      title: 't(Manage Users)',
      action: `/api/admin/finance/projects/${project_id}/memberships`,
      method: 'PATCH',
      bodyFormat: (assigned) => ({
        assignments: assigned.map((assignee) => ({
          user_id: assignee.id,
          type: assignee.type
        })) 
      }),
      assigneeFormat: (user) => <UserToken { ...user } />,
      assignedEndpoint: `/api/admin/finance/projects/${project_id}/memberships`,
      assignedFilter: (assigned) => assigned.map(assignee => ({
        ...assignee.user,
        type: assignee.type
      })),
      empty: {
        icon: 'user',
        title: 't(Add users)',
        text: 't(Please assign users to this project)'
      },
      types: [
        { value: 'member' , text: 't(member)' },
        { value: 'approver', text: 't(approver)' },
        { value: 'owner', text: 't(owner)' }
      ],
      unassignedEndpoint: '/api/admin/users',
      unassignedFilter: (assignee, query, assigned) => {
        if(query.length > 0 && assignee.full_name.toLowerCase().search(query) < 0) return false
        return assigned.find(assignment => {
          return assignment.id === assignee.id
        }) === undefined
      },
      onCancel: this._handleCancel,
      onDone: this._handleDone
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

}

export default Users
