import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import PropTypes from 'prop-types'
import React from 'react'

const ContactToken = (contact) => (
  <div className="contact-token">
    <div className="contact-token-avatar">
      <ContactAvatar { ...contact } />
    </div>
    <div className="contact-token-label">
      { contact.display_name } {
        contact.email &&
        <>({ contact.email })</>
      }
    </div>
  </div>
)

ContactToken.propTypes = {
  contact: PropTypes.object
}

export default ContactToken
