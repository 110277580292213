import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import Date from '@admin/components/date'
import Code from '@admin/components/code'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ dmarc_report }) => {

  const details = {
    sections: [
      {
        title: 't(DMARC Report Details)',
        items: [
          { label: 't(Organization)',  content: dmarc_report.organization.name },
          { label: 't(Date Range)', content: (
            <>
              <Date date={ dmarc_report.start_date } /> - <Date date={ dmarc_report.end_date } />
            </>
          ) }
        ]
      },
      {
        title: 't(Report)',
        items: [
          { padded: true, component: <Code type="json" code={ JSON.stringify(dmarc_report.feedback, null, 2) } /> }
        ]
      }

    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  dmarc_report: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview dmarc_report={ props.dmarc_report } />
})

export default Panel(null, mapPropsToPanel)
