import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  asap: {
    title: 't(Start your free trial as soon as your first service is available)',
    text: 't(Turnaround times: Social Media ~2 business days, Newsletter ~1-2 weeks, Website Design ~ 4 weeks)'
  },
  wait: {
    title: 't(Wait until all selected services are ready)',
    text: 't(Your free trial will begin once your Newsletter or Website Design is ready, depending on your selection)'
  }
}

const StartTimeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ types[value].title } /></strong><br />
    <T text={ types[value].text } />
  </div>
)

StartTimeToken.propTypes = {
  value: PropTypes.string
}

export default StartTimeToken
