import ProgramToken from '@apps/crm/admin/tokens/program'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Usage)',
  collection: {
    endpoint: `/api/admin/team/usage/${props.usage.id}/programs`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Program)', key: 'program', primary: true, format: (usage) => (
        <>
          { usage.program ?
            <ProgramToken { ...usage.program } /> :
            <div className="token">No Program</div>
          }
        </>
      ) },
      { label: 't(Emails)', key: 'emails_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(Minutes)', key: 'minutes_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(SMS)', key: 'smses_count', collapsing: true, align: 'right', format: 'number' },
      { label: 't(Numbers)', key: 'phone_numbers_count', collapsing: true, align: 'right', format: 'number' }
    ],
    defaultSort: { key: 'start', order: 'desc' },
    empty: {
      icon: 'usage',
      title: 't(No Usage)',
      text: 't(You have not yet used any resources)'
    },
    entity: 'usage'
  }
})

export default Panel(null, mapPropsToPanel)
