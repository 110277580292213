import ContactAvatar from './contact_avatar'
import PropTypes from 'prop-types'
import React from 'react'

const EmailAddressToken = (email_address) => (
  <div className="contact-token">
    <div className="contact-token-avatar">
      <ContactAvatar { ...email_address.contact } />
    </div>
    <div className="contact-token-label">
      { email_address.rfc822 }
    </div>
  </div>
)

EmailAddressToken.propTypes = {
  email_address: PropTypes.object
}

export default EmailAddressToken
