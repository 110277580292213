import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const Payment = ({ line_items }) => (
  <table className="ui unstackable compact table">
    <thead>
      <tr>
        <th>Item</th>
        <th className="collapsing">Total</th>
      </tr>
    </thead>
    <tbody>
      { line_items.map((line_item, index) => (
        <tr key={`line_item_${index}`}>
          <td>{ line_item.description } x { line_item.quantity }</td>
          <td className="right aligned">{ numeral(line_item.total).format('0.00') }</td>
        </tr>
      )) }
      <tr>
        <td>Total</td>
        <td className="right aligned">{ numeral(line_items.reduce((total, line_item) => {
          return total + Number(line_item.total)
        }, 0)).format('0.00') }</td>
      </tr>
    </tbody>
  </table>
)

Payment.propTypes = {
  line_items: PropTypes.array
}

export default Payment
