export const normalizeConfig = (value) => {
  if(_.isArray(value)) {
    return value.reduce((normalized, value) => {
      const newvalue = normalizeConfig(value)
      return [
        ...normalized,
        ...!_.isNil(newvalue) ? [value] : []
      ]
    }, [])
  }
  if(_.isPlainObject(value)) {
    return Object.keys(value).reduce((normalized, key) => {
      const newvalue = normalizeConfig(value[key])
      return {
        ...normalized,
        ...!_.isNil(newvalue) ? { [key]: newvalue } : {}
      }
    }, {})
  }
  return value
}
