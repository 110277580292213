import { getCode } from '@core/utils/codes'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Overview from './overview'
import React from 'react'

class CriteriaBuider extends React.Component {

  static childContextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    cid: PropTypes.string,
    code: PropTypes.string,
    criteria: PropTypes.object,
    defaultValue: PropTypes.array,
    display: PropTypes.array,
    fields: PropTypes.array,
    items: PropTypes.array,
    panel: PropTypes.object,
    standalone: PropTypes.bool,
    test: PropTypes.array,
    value: PropTypes.array,
    onAddItems: PropTypes.func,
    onChange: PropTypes.func,
    onClearTestItem: PropTypes.func,
    onRemoveItems: PropTypes.func,
    onSave: PropTypes.func,
    onSetItems: PropTypes.func,
    onSetTestItem: PropTypes.func
  }

  static defaultProps = {
    fields: [],
    standalone: true,
    onChange: () => {}
  }

  stackRef = React.createRef()

  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return (
      <div className="maha-criteria">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this._handlePush(Overview, this._getOverview.bind(this))
  }

  componentDidUpdate(prevProps) {
    const { items, test } = this.props
    if(!_.isEqual(items, prevProps.items)) {
      this.props.onChange(items)
    }
    if(!_.isEqual(test, prevProps.test)) {
      this.props.onChange(test || items)
    }
  }

  getChildContext() {
    return {
      form: {
        pop: this._handlePop,
        push: this._handlePush
      }
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : [{
      code: getCode(10),
      data: null,
      field: null,
      value: null,
      parent: null,
      operator: '$and'
    }]
  }

  _getOverview() {
    const { cid, code, display, fields, panel, standalone, test } = this.props
    const { onAddItems, onChange, onClearTestItem, onRemoveItems, onSetItems, onSetTestItem } = this.props
    return {
      cid,
      code,
      criteria: display,
      fields,
      panel,
      standalone,
      test,
      onAddItems,
      onChange,
      onClearTestItem,
      onPop: this._handlePop,
      onPush: this._handlePush,
      onRemoveItems,
      onSetItems,
      onSetTestItem
    }
  }

  _getStack() {
    return {
      display_name: 'criteria_builder',
      ref: this.stackRef
    }
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSet(items) {
    this.props.onSetItems(items)
  }

}

export default CriteriaBuider
