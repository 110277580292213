import Badge from '@admin/components/host/badge'
import Avatar from '@admin/components/avatar'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Account from './account'
import React from 'react'

class AccountSidebar extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    device: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    onDone: PropTypes.func
  }

  state = {
    x0: null,
    offset: 0
  }

  _handleAccount = this._handleAccount.bind(this)
  _handleSignout = this._handleSignout.bind(this)
  _handleTouchStart = this._handleTouchStart.bind(this)
  _handleTouchMove = this._handleTouchMove.bind(this)
  _handleTouchEnd = this._handleTouchEnd.bind(this)

  render() {
    const { admin, device } = this.context
    const { user } = admin
    const items = [
      { icon: 'id-card', title: 't(Manage Account)', button: this._getAccount() },
      { icon: 'power-off', title: 't(Sign Out)', button: this._getSignout() }
    ]
    return (
      <div className="maha-account" { ...this._getSidebar() }>
        <div className="maha-account-identity">
          <Avatar user={ user } width="150" presence={ false } />
          <h2>{ user.full_name }</h2>
          <p>{ user.email }</p>
        </div>
        <div className="maha-account-tasks">
          { items.map((item, index) => (
            <Button key={`item_${index}`} { ...item.button }>
              <Icon icon={`${item.icon}`} /> <T text={ item.title } />
            </Button>
          )) }
        </div>
        { device.platform === 'web' &&
          <div className="maha-account-footer">
            <Badge />
          </div>
        }
      </div>
    )
  }

  _getAccount() {
    return {
      className: 'maha-account-task',
      event: 'clicked \'Account\' in account navigation',
      handler: this._handleAccount
    }
  }

  _getDownload() {
    const { device } = this.context
    return {
      label: device.device === 'desktop' ? 't(Download Maha for Desktop)' : 't(Download Maha for Mobile)',
      color: 'red',
      handler: () => {}
    }
  }

  _getSidebar() {
    return {
      style: this._getStyle(),
      onTouchStart: this._handleTouchStart,
      onTouchMove: this._handleTouchMove,
      onTouchEnd: this._handleTouchEnd
    }
  }

  _getSignout() {
    return {
      className: 'maha-account-task',
      event: 'clicked \'Sign Out\' in account navigation',
      handler: this._handleSignout
    }
  }

  _getStyle() {
    const { offset } = this.state
    if(!offset) return {}
    if(offset > 0) return { transform: `translateX(${offset}px)` }
  }

  _handleAccount() {
    this._handleModal(Account)
  }

  _handleModal(component) {
    this.props.onDone()
    setTimeout(() => this.context.modal.open(component), 250)
  }

  _handleRoute(route) {
    this.context.router.push(route)
    this.props.onDone()
  }

  _handleSignout() {
    this.context.admin.signout()
  }

  _handleTouchStart(e) {
    this.setState({
      x0: e.touches ? e.touches[0].clientX : e.clientX
    })
  }

  _handleTouchMove(e) {
    const { x0 } = this.state
    const x1 = e.touches ? e.touches[0].clientX : e.clientX
    this.setState({
      offset: x1 - x0
    })
  }

  _handleTouchEnd(e) {
    const { offset } = this.state
    if(offset > 150) return this.props.onDone()
    this.setState({ x0: 0, offset: 0 })
  }

}

export default AccountSidebar
