import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Textarea = React.forwardRef((props, ref) => {

  const { autogrow, rows, onChange, onEnter, onInput, onKeyDown, ...additionalProps } = props

  const inputRef = ref || useRef(null)

  const adjustHeight = () => {  
    if (autogrow === false || !inputRef.current) return
    inputRef.current.style.height = 'auto'
    const { scrollHeight } = inputRef.current
    inputRef.current.style.height = scrollHeight > 20 ? `${scrollHeight}px` : `${rows * 1.6}rem`
  }

  useEffect(() => {
    adjustHeight()
  }, [rows])

  const handleChange = (e) => {
    if(onChange) onChange(e.target.value, e)
    adjustHeight()
  }

  const handleInput = (e) => {
    if (onInput) onInput(e.target.value, e)
    adjustHeight()
  }
  
  const handleKeyDown = (e) => {
    if (onKeyDown) onKeyDown(e)
    if (e.keyCode !== 13 || e.shiftKey) return
    if (!onEnter) return
    e.preventDefault()
    onEnter(e.target.value)
  }

  const mergedProps = {
    ...additionalProps,
    ref: inputRef,
    rows,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onInput: handleInput
  }

  return <textarea { ...mergedProps } />

})

Textarea.propTypes = {
  autogrow: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func
}

export default Textarea