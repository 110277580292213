import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const TypeToken = ({ value }) => (
  <div className="type-token">
    <div className={`type-token-badge ${value}`}>
      { value === 'Cash Advance' && <Icon icon="arrow-right" /> }
      { value === 'Check Request' && <Icon icon="check" /> }
      { value === 'Expense' && <Icon icon="credit-card" /> }
      { value === 'Reimbursement' && <Icon icon="dollar" /> }
      { value === 'Mileage' && <Icon icon="car" /> }
    </div>
    { value }
  </div>
)

TypeToken.propTypes = {
  value: PropTypes.string
}

export default TypeToken
