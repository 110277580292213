import Textfield from '@admin/components/form/textfield'
import Dropdown from '@admin/components/form/dropdown'
import PropTypes from 'prop-types'
import React from 'react'

class DomainField extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    agency_id: PropTypes.number,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    provider_id: PropTypes.number,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onEnter: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    subdomain: null,
    domain: null,
    focused: false
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyPressed = this._handleKeyPressed.bind(this)

  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <div className={ this._getClass() }>
        <div className="maha-domainfield-subdomain">
          <Textfield { ...this._getSubdomain() } />
        </div>
        <div className="maha-domainfield-domain">
          <Dropdown { ...this._getDomain() } />        
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { subdomain, domain } = this.state
    if(subdomain !== prevState.subdomain) {
      this._handleChange()
    }
    if(domain !== prevState.domain) {
      this._handleChange()
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-domainfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getSubdomain() {
    const { subdomain } = this.state
    const { tabIndex } = this.props
    return {
      clearable: false,
      placeholder: 't(Enter Subdomain)',
      ref: this.inputRef,
      tabIndex,
      value: subdomain,
      onBlur: this._handleBlured,
      onChange: this._handleUpdate.bind(this, 'subdomain'),
      onKeyPress: this._handleKeyPressed,
      onFocus: this._handleFocused
    }
  }

  _getDomain() {
    const { agency_id, provider_id, tabIndex } = this.props
    const { domain } = this.state
    return {
      endpoint: '/api/admin/domains',
      ...(provider_id || agency_id) ? {
        filter: {
          $and: [
            ...agency_id ? [
              {
                agency_id: {
                  $eq: agency_id
                }
              }
            ] : [],
            ...provider_id ? [
              {
                provider_id: {
                  $eq: provider_id
                }
              }
            ] : []
          ]
        }  
      } : {},
      entity: 'domain',
      valueKey: 'name',
      textKey: 'name',
      tabIndex,
      deselectable: false,
      format: ({ value }) => <div className="token">{`.${value}`}</div>,
      selectFirst: true,
      onChange: this._handleUpdate.bind(this, 'domain'),
      defaultValue: domain
    }
  }

  _handleBlured() {
    this.setState({
      focused: false
    })
  }

  _handleClear() {
    this.inputRef.current.clear()
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    this.setState({
      focused: true
    })
  }

  _handleChange() {
    const { domain, subdomain } = this.state
    this.props.onChange(`${subdomain}.${domain}`)
  }

  _handleKeyPressed(e) {
    if(e.code === 'Space') return e.preventDefault()
    if(e.key !== 'Enter') return
    this.props.onEnter()
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

  _handleSet(value) {
    this.setState({
      ...value ? {
        subdomain: value.split('.')[0],
        domain: value.split('.').slice(1).join('.')  
      } : {}
    })
  }

  _handleValidate() {
    const { domain, subdomain } = this.state
    const { required } = this.props
    if(required && (!subdomain || subdomain.length === 0)) {
      return this.props.onValid(null, ['t(A sudomain is required)'])
    }
    if(required && (!domain)) {
      return this.props.onValid(null, ['t(You must choose a domain)'])
    }
    this.props.onValid(`${subdomain}.${domain}`)
  }
}

export default DomainField
