import SupportAssignment from '@admin/components/support_assignment'
import Panel from '@admin/components/panel'
import UserToken from '@admin/tokens/user'
import TeamToken from '@admin/tokens/team'
import React from 'react'

const getSupportAssignment = (user) => ({
  title: 't(Assign Support Teams)',
  entity: 'team',
  format: TeamToken,
  valueKey: 'team_ids',
  action: `/api/admin/truevail/admin/support_users/${user.id}/teams`,
  assignedEndpoint: `/api/admin/truevail/admin/support_users/${user.id}/teams`,
  unassignedEndpoint: `/api/admin/truevail/admin/support_users/${user.id}/teams/all`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Support Users)',
  collection: {
    endpoint: '/api/admin/truevail/admin/support_users',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'last_name', primary: true, format: UserToken }
    ],
    empty: {
      icon: 'user',
      title: 't(No Support Users)',
      text: 't(You have not yet assigned any support users to this advisor)'
    },
    defaultSort: { key: 'last_name', order: 'asc' },
    entity: 'user'
  }
})

export default Panel(null, mapPropsToPanel)
