import ExpenseTypeToken from '@apps/finance/admin/tokens/expense_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    index: PropTypes.number,
    allocation: PropTypes.object,
    projectEndpoint: PropTypes.string,
    onSubmit: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { allocation, projectEndpoint } = this.props
    return {
      title: 't(Edit Allocation)',
      saveText: 'Done',
      onSubmit: this._handleSubmit,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'id', type: 'hidden', defaultValue: allocation.id },
            { label: 't(Project)', name: 'project_id', required: true, type: 'lookup', endpoint: projectEndpoint, valueKey: 'id', textKey: 'title', format: ProjectToken, defaultValue: allocation.project_id },
            { label: 't(Expense Type)', name: 'expense_type_id', required: true, type: 'lookup', endpoint: '/api/admin/finance/expense_types', valueKey: 'id', textKey: 'title', format: ExpenseTypeToken, defaultValue: allocation.expense_type_id },
            { label: 't(Description)', name: 'description', required: true, type: 'textfield', defaultValue: allocation.description },
            { label: 't(Amount)', name: 'amount', required: true, type: 'moneyfield', defaultValue: allocation.amount }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.form.pop()
  }

  _handleSubmit(allocation) {
    const { index } = this.props
    this.props.onSubmit(allocation, index)
    return true
  }

  _handleSuccess(result) {
    this.context.form.pop()
  }

}

export default Edit
