import Attachment from './attachment'
import PropTypes from 'prop-types'
import React from 'react'

class Attachments extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className="maha-email-attachments">
        <div className="maha-email-attachments-panel">
          { email.attachments.map((attachment, index) => (
            <Attachment { ...this._getAttachment(attachment) } key={`attachment_${index}`} />
          ))}
        </div>
      </div>
    )
  }

  _getAttachment(attachment) {
    const { email } = this.props
    return {
      attachment,
      email
    }
  }

}

export default Attachments
