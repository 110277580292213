import AccessToken from '@admin/tokens/access'
import Panel from '@admin/components/panel'
import Manage from '../manage'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Access)',
  collection: {
    endpoint: `/api/admin/websites/websites/${props.website.id}/access`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Access)', key: 'full_name', primary: true, format: (access) => (
        <AccessToken access={ access } />
      ) }
    ],
    empty: {
      icon: 'shield',
      title: 't(No Access)',
      text: 't(You have not yet set the access for this website)',
      buttons: [
        { label: 't(Manage Access)', modal:  <Manage website={ props.website } /> }
      ]
    },
    entity: 'access',
    defaultSort: { key: 'path', order: 'asc' }
  },
  tasks: {
    items: [
      { 
        label: 't(Manage Access)', 
        modal:  <Manage website={ props.website } /> 
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
