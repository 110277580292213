import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class EmailBounceToken extends React.Component {

  static propTypes = {
    email: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className="email-bounce-token">
        <strong><T text={ this._getMessage() } /></strong>
        { email.bounce_reason &&
          <>
            { email.bounce_reason && email.bounce_reason.split('\n').map((reason, index) => (
              <div key={`bounce_reason_${index}`}>{ reason }</div>
            )) }          
          </>
        }
      </div>
    )
  }

  _getMessage() {
    const { email } = this.props
    if(email.bounce_reason && email.bounce_type === 'Permanent') return 't(This email was hard bounced for the following reason):'
    if(email.bounce_reason && email.bounce_type === 'Transient') return 't(This email was soft bounced for the following reason):'
    if(!email.bounce_reason && email.bounce_type === 'Permanent') return 't(This email was hard bounced)'
    if(!email.bounce_reason && email.bounce_type === 'Transient') return 't(This email was soft bounced)'
  }

}

export default EmailBounceToken
