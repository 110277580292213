import PropTypes from 'prop-types'
import React from 'react'

export const LocalStorageContext = React.createContext()
LocalStorageContext.displayName = 'LocalStorageContext'

export const useLocalStorageContext = () => React.useContext(LocalStorageContext)

class LocalStorage extends React.Component {

  static childContextTypes = {
    local_storage: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    get: PropTypes.func,
    remove: PropTypes.func,
    set: PropTypes.func
  }
  
  _handleGet = this._handleGet.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSet = this._handleSet.bind(this)

  render() {
    return (
      <LocalStorageContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </LocalStorageContext.Provider>
    )
  }

  getChildContext() {
    return {
      local_storage: {
        get: this._handleGet,
        remove: this._handleRemove,
        set: this._handleSet
      }
    }
  }

  _handleGet({ key, onSuccess, onFailure }) {
    this.props.get({ 
      key, 
      onSuccess, 
      onFailure 
    })
  }

  _handleRemove({ key, onSuccess, onFailure }) {
    this.props.remove({ 
      key, 
      onSuccess, 
      onFailure 
    })
  }

  _handleSet({ key, value, onSuccess, onFailure }) {
    this.props.set({ 
      key,
      value,
      onSuccess, 
      onFailure 
    })
  }

}

export default LocalStorage
