import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import moment from 'moment'
import Month from './month'
import Week from './week'
import List from './list'
import React from 'react'

class Scheduled extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    mode: PropTypes.string,
    offset: PropTypes.number,
    range: PropTypes.object,
    onEdit: PropTypes.func,
    onMode: PropTypes.func,
    onPage: PropTypes.func,
    onSchedule: PropTypes.func
  }

  render() {
    const { mode } = this.props
    return (
      <div className="campaigns-social-schedule-scheduled">
        <div className="campaigns-social-schedule-scheduled-header">
          <div className="campaigns-social-schedule-scheduled-header-details">
            { mode !== 'list' &&
              <>
                <div className="campaigns-social-schedule-scheduled-header-modes">
                  <Button { ...this._getToday() } />
                </div>
                <Button { ...this._getPrev() } />
                <Button { ...this._getNext() } />
                <div className="campaigns-social-schedule-scheduled-header-label">
                  { this._getRange() }
                </div>              
              </>
            }
          </div>
          <div className="campaigns-social-schedule-scheduled-header-modes">
            <div className="campaigns-social-schedule-scheduled-header-modes-buttons">
              <Button { ...this._getMonth() } />
              <Button { ...this._getWeek() } />
              <Button { ...this._getList() } />
            </div>
          </div>
        </div>
        <div className="campaigns-social-schedule-scheduled-body">
          { mode === 'week' &&
            <Week { ...this._getDisplay() } />
          }
          { mode === 'month' &&
            <Month { ...this._getDisplay() } />
          }
          { mode === 'list' &&
            <List { ...this._getDisplay() } />
          }
        </div>
      </div>
    )
  }

  _getCurrent() {
    const { mode, offset } = this.props
    const unit = mode === 'week' ? 'week' : 'month'
    return moment().startOf(unit).add(offset, unit)
  }

  _getDisplay() {
    const { endpoint, range, onEdit, onSchedule } = this.props  
    return {
      current: this._getCurrent(),
      endpoint,
      range,
      onEdit,
      onSchedule
    }
  }

  _getList() {
    const { mode } = this.props
    const classes = ['campaigns-social-schedule-scheduled-header-modes-button']
    if(mode === 'list') classes.push('selected')
    return {
      label: 't(List)',
      className: classes.join(' '),
      handler: this._handleMode.bind(this, 'list')
    }
  }

  _getMonth() {
    const { mode } = this.props
    const classes = ['campaigns-social-schedule-scheduled-header-modes-button']
    if(mode === 'month') classes.push('selected')
    return {
      label: 't(Month)',
      className: classes.join(' '),
      handler: this._handleMode.bind(this, 'month')
    }
  }

  _getNext() {
    return {
      icon: 'chevron-right',
      className: 'campaigns-social-schedule-scheduled-header-action',
      handler: this._handlePage.bind(this, 1)
    }
  }

  _getPrev() {
    return {
      icon: 'chevron-left',
      className: 'campaigns-social-schedule-scheduled-header-action',
      handler: this._handlePage.bind(this, -1)
    }
  }

  _getStart() {
    const { start, end } = this.props.range
    const parts = ['MMM','D']
    if(start.year() !== end.year()) parts.push(', YYYY')
    return start.format(parts.join(' '))
  }

  _getEnd() {
    const { start, end } = this.props.range
    const parts = ['D,','YYYY']
    if(start.month() !== end.month()) parts.unshift('MMM')
    return end.format(parts.join(' '))
  }

  _getRange() {
    const { mode, range } = this.props
    if(mode === 'list') return ''
    if(mode !== 'week') return range.start.format('MMMM YYYY')
    return `${this._getStart()} - ${this._getEnd()}`
  }

  _getToday() {
    return {
      label: 't(Today)',
      color: 'blue',
      basic: true,
      size: 'mini',
      handler: this._handlePage.bind(this, 0)
    }
  }

  _getWeek() {
    const { mode } = this.props
    const classes = ['campaigns-social-schedule-scheduled-header-modes-button']
    if(mode === 'week') classes.push('selected')
    return {
      label: 't(Week)',
      className: classes.join(' '),
      handler: this._handleMode.bind(this, 'week')
    }
  }

  _handleMode(mode) {
    this.props.onMode(mode)
  }

  _handlePage(add) {
    this.props.onPage(add)
  }

}

export default Scheduled