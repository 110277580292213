import ModalPanel from '@admin/components/modal_panel'
import ProfileToken from '@admin/tokens/profile'
import Message from '@admin/components/message'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'
import New from './new'

class Accounts extends React.Component {

  static contextTypes = {
    confirm: PropTypes.object,
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    profiles: PropTypes.array,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)

  render() {
    const { profiles } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        { profiles.length > 0 ?
          <Details { ...this._getDetails() } /> :
          <Message { ...this._getMessage() } />
        }
      </ModalPanel>
    )
  }

  _getDetails() {
    const { profiles } = this.props
    return {
      items: [
        ...profiles.map((profile,index) => (
          { component: (
            <ProfileToken profile={ profile } />
          ), extra: (
            <Button { ...this._getRevoke(profile) } />
          ) }
        )),
        { component: (
          <div className="maha-email-account">
            <div className="maha-email-account-icon">
              <Icon icon="plus-circle" />
            </div>
            <div className="maha-email-account-label">
              Add another account
            </div>
          </div>
        ), handler: this._handleAdd }
      ]
    }
  }

  _getMessage() {
    return {
      backgroundColor: 'blue',
      icon: 'envelope',
      title: 't(Signing Accounts)',
      text: 't(You can send documents for signature in Maha using a connected Adobe Sign account. When you do, Maha will keep a copy the signed document with the associated contact.)',
      buttons: [
        { label: 't(Connect Account)', handler: this._handleAdd }
      ]
    }
  }

  _getRevoke(profile) {
    return {
      icon: 'times',
      className: 'icon',
      handler: this._handleRevoke.bind(this, profile)
    }
  }

  _getNew() {
    const { onPush, onPop } = this.props
    return {
      onPush,
      onPop
    }
  }

  _getPanel() {
    const { profiles } = this.props
    return {
      color: profiles.length > 0 ? 'darkblue' : 'blue',
      title: profiles.length > 0 ? 'Connected Signing Accounts' : null,
      leftItems: profiles.length > 0 ? [
        { icon: 'chevron-left', handler: this._handleBack }
      ] : [
        { label: 't(Cancel)', handler: this._handleCancel }
      ]
    }
  }

  _handleAdd() {
    this.props.onPush(New, this._getNew.bind(this))
  }

  _handleBack() {
    this.props.onPop()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleRevoke(profile) {
    this.context.confirm.open('t(Are you sure you want to disconnect this profile?)', () => {
      this.context.network.request({
        endpoint: `/api/admin/profiles/${profile.id}`,
        method: 'DELETE',
        onFailure: () => this.context.flash.set('error', 't(Unable to revoke profile)')
      })
    })
  }

}

export default Accounts
