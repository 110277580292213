import UsagesField from '@apps/subscriptions/admin/components/usagesfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Usage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    renewal: PropTypes.object,
    service: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { renewal, service } = this.props
    return {
      title: 't(Configure Usage)',
      method: 'PATCH',
      action: `/api/admin/subscriptions/services/${service.id}/renewals/${renewal.id}/usage`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'usages', type: UsagesField, defaultValue: renewal.usages }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Usage
