import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

const StoreToken = (store) => (
  <div className="store-token">
    <div className="store-token-logo">
      <Logo team={ store.program } width="24" />
    </div>
    <div className="store-token-label">
      <div className="token-link">
        { store.title }
      </div>
    </div>
  </div>
)

StoreToken.propTypes = {
  program: PropTypes.object,
  title: PropTypes.string
}

export default StoreToken
