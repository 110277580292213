import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: entity.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `automation_emails/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const Token = ({ email }) => (
  <div className="automation-email-token">
    <div className="automation-email-token-preview" >
      { email.title }<br />
      <MJSONScreenshot { ...getScreenshot(email) } />
    </div>
  </div>
)

Token.propTypes = {
  email: PropTypes.object
}

export default Token