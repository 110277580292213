import EmailToken from '@admin/tokens/email'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/admin/domain_segments/${props.domain_segment.id}/emails`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Subject)', key: 'subject', primary: true, format: (email) => (
        <EmailToken email={ email } perspective="queue" show_interactions={ false } show_timestamp={ false } />
      ) }
    ],
    defaultSort: { key: 'send_index', order: 'desc' },
    empty: {
      icon: 'envelope',
      title: 't(No Emails)',
      text: 't(There are no queued emails for this domain segment)'
    },
    entity: 'email'
  }
})

export default Panel(null, mapPropsToPanel)
