import Page from '@admin/components/page'
import Properties from './properties'
import Templates from './templates'
import Profiles from './profiles'
import Overview from './overview'
import Senders from './senders'
import Access from './access'
import Themes from './themes'
import React from 'react'
import Tags from './tags'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.program.title,
  access: { 
    programs: { $canView: resources.program.id },
    rights: { $oneOf: ['team:view_programs','team:manage_programs'] }
  },
  manager: {
    path: `/admin/team/programs/${resources.program.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview program={ resources.program } />
      },
      {
        label: 't(Access)',
        path: '/access',
        access: { rights: { $oneOf: ['team:manage_programs']} },
        panel: <Access program={ resources.program } />
      },
      {
        label: 't(Profiles)',
        path: '/profiles',
        show: context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_profiles','team:manage_profiles']} },
        panel: <Profiles program={ resources.program } />
      },
      {
        label: 't(Properties)',
        path: '/properties',
        show: context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_properties','team:manage_properties']} },
        panel: <Properties program={ resources.program } />
      },
      {
        label: 't(Senders)',
        path: '/senders',
        show: context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_senders','team:manage_senders']} },
        panel: <Senders program={ resources.program } />
      },
      {
        label: 't(Tags)',
        path: '/tags',
        show: !context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:manage_configuration']} },
        panel: <Tags program={ resources.program } />
      },
      {
        label: 't(Templates)',
        path: '/templates',
        show: context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_templates','team:manage_templates']} },
        panel: <Templates program={ resources.program } />
      },
      {
        label: 't(Themes)',
        path: '/themes',
        show: context.admin.team.has_programs,
        access: { rights: { $oneOf: ['team:view_templates','team:manage_templates']} },
        panel: <Themes program={ resources.program } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  program: `/api/admin/team/programs/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
