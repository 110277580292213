import ProfileManager from '@admin/components/profile_manager'
import PropTypes from 'prop-types'
import React from 'react'

class Profiles extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    profiles: PropTypes.array,
    status: PropTypes.string,
    types: PropTypes.object
  }

  _handleBack = this._handleBack.bind(this)

  render() {
    return <ProfileManager { ...this._getProfileManager() } />
  }

  _getProfileManager() {
    return {
      types: ['signatures','files','photos','contacts','emails'],
      onBack: this._handleBack
    }
  }

  _handleBack() {
    this.context.modal.pop()
  }

}

export default Profiles
