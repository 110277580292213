const INITIAL_STATE = {
  all: null,
  next: null,
  records: [],
  skip: null,
  selectAll: false,
  selectMode: '$in',
  selectedValues: [],
  status: 'pending',
  total: null
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'CLEAR_SELECTION':
    return {
      ...state,
      selectAll: false,
      selectMode: '$in',
      selectedValues: []
    }

  case 'SET_RESULT':
    return {
      ...state,
      all: action.all,
      next: action.next,
      records: action.records,
      skip: action.skip,
      status: action.status,
      total: action.total
    }

  case 'SET_STATUS':
    return {
      ...state,
      status: action.status
    }

  case 'SELECT':
    return {
      ...state,
      selectAll: false,
      selectedValues: _.xor(state.selectedValues, [action.id])
    }

  case 'SELECT_ALL':
    return {
      ...state,
      selectAll: !state.selectAll,
      selectMode: state.selectAll ? '$in' : '$nin',
      selectedValues: []
    }

  default:
    return state
  }

}

export default reducer
