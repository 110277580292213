import Avatar from '@admin/components/avatar'
import PropTypes from 'prop-types'
import React from 'react'

class Access extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    access_type: PropTypes.string,
    grouping: PropTypes.object,
    group: PropTypes.object,
    user: PropTypes.object
  }

  render() {
    const { access_type, grouping, group, user } = this.props
    const { admin } = this.context
    return (
      <div className="drive-access">
        <div className="drive-access-photo">
          { grouping &&
           <div className="assignee-token-icon">
             <Avatar icon='bolt' user={ this._getGrouping() } width="50" title={ false } presence={ false } />
           </div>
          }
          { group &&
           <div className="assignee-token-icon">
             <Avatar user={ this._getGroup() } width="50" title={ false } presence={ false } />
           </div>
          }
          { user &&
           <div className="assignee-token-avatar">
             <Avatar user={ user } width="50" title={ false } presence={ false } />
           </div>
          }
        </div>
        <div className="drive-access-details">
          { this._getSubject() } { user && user.id == admin.user.id &&
            <span>(You)</span>
          }
        </div>
        <div className="drive-access-type">
          <div className="maha-assignment-item-access">
            <span className={ access_type }>{ access_type.toUpperCase() }</span>
          </div>
        </div>
      </div>
    )
  }

  _getGroup() {
    const { group } = this.props
    return {
      initials: group.title.split(' ').map(word => word[0]).join('')
    }
  }

  _getGrouping() {
    const { grouping } = this.props
    return {
      initials: grouping.title.split(' ').map(word => word[0]).join('')
    }
  }

  _getSubject() {
    const { grouping, group, user } = this.props
    if(grouping) return grouping.title
    if(group) return group.title
    if(user) return user.full_name
  }

  _getAccess() {
    const { access_type } = this.props
    if(access_type === 'owner') return 'owner'
    if(access_type === 'edit') return 'can edit'
    if(access_type === 'view') return 'can view'
  }

}

export default Access
