const sanitizeAnchor = (value) => value.toLowerCase().replace(/[^a-z0-9\-_]/g, '')

export default (config, type, entity) => [
  {
    label: 't(Navigation)',
    instructions: 't(Specify how this entity appears in the page outline)',
    collapsing: true,
    collapsed: true,
    fields: [
      { label: 't(Label)', name: 'meta.label', type: 'textfield', defaultValue: config.meta.label, required: true }
    ]
  },
  ...entity.entity === 'section' ? [
    {
      label: 't(Anchor)',
      instructions: 't(Anchors enable you to create buttons or links that will scroll the page to this entity by name)',
      collapsing: true,
      collapsed: true,
      fields: [
        { label: 't(Anchor Name)', name: 'meta.anchor', type: 'textfield', defaultValue: config.meta.anchor, prefix: '#', sanitize: sanitizeAnchor }
      ]
    }
  ] : [],
  ...entity.entity === 'section' && type === 'page' ? [
    {
      label: 't(Accessibility)',
      instructions: 't(Specify the purpose of this entity for screen readers)',
      collapsing: true,
      collapsed: true,
      fields: [
        { label: 'Region', name: 'meta.region', type: 'dropdown', options: [
          { value: null, text: 't(None)' },
          { value: 'header', text: 't(Header)' },
          { value: 'main', text: 't(Main)' },
          { value: 'footer', text: 't(Footer)' },
          { value: 'aside', text: 't(Aside)' },
          { value: 'article', text: 't(Article)' },
          { value: 'section', text: 't(Section)' },
          { value: 'nav', text: 't(Nav)' }
        ], defaultValue: config.meta.region }
      ]
    }
  ] : []
]
