import ResponsiveField from '../responsivefield'
import Form from '@admin/components/form'
import LinkedField from '../linkedfield'
import RadiusField from '../radiusfield'
import ColorField from '../colorfield'
import PropTypes from 'prop-types'
import WebRange from '../webrange'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const Range = (props) => <ResponsiveField { ...props } component={ WebRange } />
const Spacing = (props) => <ResponsiveField { ...props } component={ LinkedField } />

class PaginationStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    icon: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSet = this._handleSet.bind(this)

  render() {
    if(!this.state.icon) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { icon } = this.state
    if(!_.isEqual(icon, prevState.icon)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, theme } = this.props
    const { icon } = this.state
    return {
      inline: true,
      onChange: this._handleSet,
      sections: [
        {
          fields: [
            { label: 't(Size)', name: 'size', device, type: Range, units: [
              { name: 'px', min: 5, max: 20, step: 1 }
            ], value: icon.size },
            { label: 't(Color)', name: 'color', type: Color, device, theme, value: icon.color },
            { label: 't(Active Color)', name: 'activeColor', type: Color, device, theme, value: icon.activeColor },
            { label: 't(Margin)', name: 'margin', device, type: Spacing, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: icon.margin },
            { label: 't(Radius)', name: 'radius', type: Radius, device, theme, value: icon.radius }
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { icon } = this.state
    this.props.onChange(icon)
  }

  _handleSet(icon) {
    this.setState({ icon })
  }

}

export default PaginationStyleField
