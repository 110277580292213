import ProgramForm from '@apps/crm/admin/components/programform'
import TaskToken from '@apps/tasks/admin/tokens/task'
import DueToken from '@apps/tasks/admin/tokens/due'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const scopes = {
  to_me: 't(You have not created or been assigned any tasks)',
  by_me: 't(You have not assigned any tasks to anyone else)',
  overdue: 't(You do not have any overdue tasks)',
  upcoming: 't(You do not have any tasks due in the next 3 days)',
  completed: 't(You have not completed any tasks)',
  all: 't(You do not have any tasks)'
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Tasks)',
  refresh: '/admin/tasks/tasks',
  collection: {
    endpoint: '/api/admin/tasks/tasks',
    defaultQuery: { scope: props.scope },
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (task) => <TaskToken task={ task } /> },
      { label: 't(Contact)', key: 'contact.display_name' },
      { label: 't(Assigned To)', key: 'assignees.full_name', format: (task) => task.assignees.map(assignee => assignee.full_name).join(', ') },
      { label: 't(Due)', key: 'due_at', collapsing: true, format: (task) => <DueToken task={ task } /> }
    ],
    defaultSort: { key: 'due_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Tasks)',
      text: scopes[props.scope],
      buttons: [{
        label: 'Create Task',
        modal: <ProgramForm form={ New } />
      }]
    },
    entity: 'task',
    onClick: (record) => context.router.push(`/tasks/tasks/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
