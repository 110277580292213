import Panel from '@admin/components/panel'
import Edit from '../accounts/edit'
import New from '../accounts/new'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Accounts)',
  access: { rights: { $oneOf: ['finance:view_accounts','finance:manage_accounts'] } },
  collection: {
    endpoint: '/api/admin/finance/accounts',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'name', primary: true }
    ],
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Name)', key: 'name' }
    ],
    recordTasks: (record) => [
      {
        label: 't(Edit Account)',
        access: { rights: { $oneOf: ['finance:manage_accounts'] } },
        modal: <Edit { ...record } integration={ props.app.settings.integration } />
      }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'credit-card',
      title: 't(No Accounts)',
      text: 't(You have not yet created any accounts)',
      buttons: [
        { 
          label: 't(Create Account)', 
          access: { rights: { $oneOf: ['finance:manage_accounts'] } },
          modal: <New integration={ props.app.settings.integration } /> 
        }
      ]
    },
    entity: 'account'
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Account)', 
    access: { rights: { $oneOf: ['finance:manage_accounts'] } },
    modal: <New integration={ props.app.settings.integration } />
  }
})

export default Panel(null, mapPropsToPanel)
