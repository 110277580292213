import CallToken from '@apps/phone/admin/tokens/call'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Results extends React.Component {

  static contextTypes = {
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  render() {
    const { records } = this.props
    return (
      <div className="maha-handset-search-results">
        { records.map((call, index) => (
          <div className="maha-handset-search-result" key={`result_${index}`} onClick={ this._handleChoose.bind(this, call)}>
            <div className="maha-handset-search-result-token">
              <CallToken call={ call } perspective="program" />
            </div>
            <div className="maha-handset-search-result-proceed">
              <Icon icon="chevron-right" />
            </div>
          </div>
        ))}
      </div>
    )
  }

  _getClass(call) {
    const classes = ['maha-handset-call-token']
    if(call.direction === 'inbound' && !call.was_answered) classes.push('missed')
    return classes.join(' ')
  }

  _getTo(call) {
    const { contact, direction, from_user, to_user, from_number, to_number } = call
    if(contact && contact.display_name !== 'Unknown') return contact.display_name
    if(direction === 'inbound' && from_user) return from_user.full_name
    if(direction === 'outbound' && to_user) return to_user.full_name
    if(direction === 'inbound' && from_number) return from_number.formatted
    if(direction === 'outbound' && to_number) return to_number.formatted
    return 'Unknown'
  }

  _getDescription(call) {
    const { voice_campaign } = call
    if(voice_campaign && voice_campaign.direction === 'outbound') return 'outgoing voice campaign'
    return `${ call.direction } call`
  }

  _getTimestamp(call) {
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const created_at = moment(call.created_at)
    if(today.format('YYMMDD') === created_at.format('YYMMDD')) return created_at.format('h:mmA')
    if(yesterday.format('YYMMDD') === created_at.format('YYMMDD')) return 'Yesterday'
    if(today.diff(created_at, 'days') < 7) return created_at.format('dddd')
    return created_at.format('MM/DD/YY')
  }

  _getNumber(call) {
    return call.direction === 'outbound' ? call.from_number.formatted : call.to_number.formatted
  }

  _handleChoose(call) {
    this.props.onChoose(call)
  }

}

export default Results
