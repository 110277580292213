import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Order extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.array,
    activity: PropTypes.object,
    enrollment: PropTypes.object,
    store: PropTypes.object,
    order: PropTypes.object,
    program: PropTypes.object,
    workflow: PropTypes.object
  }

  render() {
    const { actions, enrollment, order, workflow } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Store</td>
                <td><Button { ...this._getStore() } /></td>
              </tr>
              <tr>
                <td>Revenue</td>
                <td>{ numeral(order.revenue).format('$0.00') }</td>
              </tr>
              <tr>
                <td>Order</td>
                <td><Button { ...this._getOrder() } /></td>
              </tr>
              <tr>
                <td>Invoice</td>
                <td><Button { ...this._getInvoice() } /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="crm-timeline-card-section-footer">
          <Actions enrollment={ enrollment } actions={ actions } trigger={ workflow.trigger } />
          <Comments entity={`stores_orders/${order.id}`} />
        </div>
      </div>
    )
  }

  _getInvoice() {
    const { order } = this.props
    return {
      label: 'View Invoice',
      className: 'link',
      route: `/admin/finance/invoices/${order.invoice_id}`
    }
  }

  _getOrder() {
    const { store, order } = this.props
    return {
      label: 'View Order',
      className: 'link',
      route: `/admin/stores/stores/${store.id}/orders/${order.id}`
    }
  }

  _getStore() {
    const { store } = this.props
    return {
      label: store.title,
      className: 'link',
      route: `/admin/stores/stores/${store.id}`
    }
  }

}

const mapResources = (props, context) => ({
  actions: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}/actions`,
  enrollment: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}`,
  store: `/api/admin/stores/stores/${props.activity.data.store_id}`,
  order: `/api/admin/stores/stores/${props.activity.data.store_id}/orders/${props.activity.data.order_id}`,
  workflow: `/api/admin/automation/workflows/${props.activity.data.workflow_id}`
})

export default Container(mapResources)(Order)
