import PropTypes from 'prop-types'
import React from 'react'

const StatusToken = ({ status, value }) => {
  const val = (status || value)
  if(!val) return null
  return (
    <div className={`maha-dmarc-status-token ${val.replace('-','')}`}>
      { _.startCase(val.replace('-','')) }
    </div>
  )
}

StatusToken.propTypes = {
  status: PropTypes.string,
  value: PropTypes.string
}

export default StatusToken
