import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import Variants from './variants'
import React from 'react'

class Inventory extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    data: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'inventory_policy', type: 'radiogroup', required: true, options: [
              { value: 'unlimited', text: 't(Do not manage inventory)' },
              { value: 'deny', text: 't(Stop selling when inventory reaches 0)' },
              { value: 'continue', text: 't(Allow sales to continue into negative inventory levels)' }
            ], defaultValue: 'unlimited' },
            ...this._getInventory()
          ]
        }
      ]
    }
  }

  _getInventory() {
    const { formdata } = this.props
    const { data } = this.state
    if(data.inventory_policy === 'unlimited') return []
    if(formdata.has_variants) {
      return [
        { label: 't(Inventory)', name: 'variants', type: Variants, required: true, product: formdata }
      ]
    }
    return [
      { label: 't(Quantity)', name: 'inventory_quantity', type: 'numberfield', required: true, defaultValue: 0 }
    ]
  }

  _getVariants() {
    const { formdata } = this.props
    const { data } = this.state
    const { inventory_policy, inventory_quantity, variants } = data
    return formdata.variants.map(variant => ({
      ...variant,
      ...inventory_policy !== 'unlimited' ? { inventory_quantity } : {},
      ...formdata.has_variants ? _.find(variants, { code: variant.code }) : {},
      inventory_policy
    }))
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(data) {
    this.props.onNext({
      variants: this._getVariants()
    })
  }

}

export default Inventory
