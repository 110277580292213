import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneButton extends React.Component {

  static propTypes = {
    depressed: PropTypes.bool,
    disabled: PropTypes.bool,
    handler: PropTypes.func,
    icon: PropTypes.string,
    modal: PropTypes.any,
    label: PropTypes.string,
    tooltip: PropTypes.any,
    type: PropTypes.string
  }

  render() {
    const { label } = this.props
    return (
      <div { ...this._getContainer() }>
        <Button { ...this._getButton() } />
        { label &&
          <div className="maha-handset-button-label">
            { label }
          </div>
        }
      </div>
    )
  }

  _getContainer() {
    return {
      className: 'maha-handset-button-container',
      ...this._getToolTip()
    }
  }

  _getButton() {
    const { disabled, handler, icon, modal } = this.props
    return {
      disabled,
      icon,
      handler,
      modal,
      className: this._getClass()
    }
  }

  _getToolTip() {
    const { tooltip } = this.props
    if(!tooltip) return {}
    return {
      'data-tooltip': _.isString(tooltip) ? tooltip : tooltip.title,
      'data-position': _.isString(tooltip) ? 'bottom right' : tooltip.position,
      'data-inverted': ''
    }
  }

  _getClass() {
    const { depressed, disabled, type } = this.props
    const classes = ['maha-handset-button']
    if(type) classes.push(type)
    if(disabled) classes.push('disabled')
    if(depressed) classes.push('depressed')
    return classes.join(' ')
  }

}

export default PhoneButton
