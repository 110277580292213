import Page from '@admin/components/page'
import Overview from './overview'
import Emails from './emails'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: `Order #${resources.order.id}`,
  manager: {
    path: `/admin/stores/stores/${resources.store.id}/orders/${resources.order.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview store={ resources.store } order={ resources.order } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails order={ resources.order } store={ resources.store } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  store: `/api/admin/stores/stores/${props.params.store_id}`,
  order: `/api/admin/stores/stores/${props.params.store_id}/orders/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
