export const onChangeFolder = (folder) => ({
  type: 'CHANGE_FOLDER',
  folder
})

export const onUp = () => ({
  type: 'UP'
})

export const onSetQuery = (q) => ({
  type: 'SET_QUERY',
  q
})

export const onToggleView = () => ({
  type: 'TOGGLE_VIEW'
})
