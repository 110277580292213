import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ tag }, { admin }) => {

  const program = {
    label: tag.program.title,
    className: 'link',
    route: `/admin/team/programs/${tag.program.id}`
  }

  const details = {
    // audits: `crm_tags/${tag.id}`,
    comments: `crm_tags/${tag.id}`,
    sections: [
      {
        title: 't(Tag Details)',
        items: [
          { label: 't(Text)', content: tag.text },
          { label: 't(Program)', show: admin.team.has_programs , content: <Button { ...program } /> },
          { label: 't(Items)', content: tag.taggable_count }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object,
  flash: PropTypes.object
}

Overview.propTypes = {
  tag: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview tag={ props.tag } />,
  // tasks: {
  //   items: [
  //     { 
  //       label: 'Edit Tag', 
  //       access: { 
  //         programs: { $canEdit: props.sender.program.id },
  //         rights: { $oneOf: ['team:manage_senders'] } 
  //       },
  //       modal: <Edit tag={ props.tag } /> 
  //     }
  //   ]
  // }
})

export default Panel(null, mapPropsToPanel)
