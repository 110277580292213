import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    content: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { content } = this.props
    return {
      endpoint: `/maha_contents/${content.id}`,
      library: '/truevail/admin',
      root_route: `/truevail/agency/contents/${content.id}/design`,
      themeable: false,
      type: 'email',
      versionable: false
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design content={ props.content } />
})

export default Panel(null, mapPropsToPanel)
