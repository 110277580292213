import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Lists extends React.Component {

  static propTypes = {
    program: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { program, onBack } = this.props
    const { config } = this.state
    return {
      title: 't(Update Lists)',
      saveText: 'Next',
      ...onBack ? {
        cancelIcon: 'chevron-left',
        onCancel: this._handleBack
      } : {
        onCancel: this._handleCancel
      },
      onChange: this._handleChange,
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program.id },
            { type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'add', text: 't(Add to Lists)' },
                { value: 'remove', text: 't(Remove from Lists)' }
              ], defaultValue: 'add' },
              { label: 't(Lists)', name: 'list_ids', type: 'checkboxgroup',endpoint: '/api/admin/crm/lists', query: { program_id: program.id }, sort : 'title', valueKey: 'id', textKey: 'title', required: true },
              { name: 'restore_subscription', type: 'checkbox', show: config.strategy === 'add', prompt: 't(Resubscribe if contact was previously unsubscribed)' }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

export default Lists
