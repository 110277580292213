import ModalPanel from '@admin/components/modal_panel'
import encode from '@core/services/qrcode/encode'
import Img from '@admin/components/html/img'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'
import qs from 'qs'

class QRCode extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    shortlink: PropTypes.object
  }

  state = {
    config: {
      image_id: null,
      image_margin: 0,
      image_size: 20,
      dark: '#000000',
      light: '#FFFFFF',
      margin: 0,
      utm_campaign: '',
      utm_medium: '',
      utm_source: '',
      utm_term: [],
      utm_content: []
    }
  }

  _handleClose = this._handleClose.bind(this)
  _handleUpdate = _.debounce(this._handleUpdate.bind(this), 250, { trailing: true })

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-shortlink-token-modal">
          <div className="maha-shortlink-token-modal-preview">
            <div className="maha-shortlink-token-modal-qr">
              <Img src={ this._getQRImage() } />
            </div>
          </div>
          <div className="maha-shortlink-token-modal-form">
            <Form { ...this._getForm() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getForm() {
    const { config } = this.state
    return {
      showHeader: false,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Design)', type: 'segment', fields: [
              { label: 't(Background)', name: 'light', type: 'colorfield', defaultValue: config.light },
              { label: 't(Pixels)', name: 'dark', type: 'colorfield', defaultValue: config.dark },
              { label: 't(Margin)', name: 'margin', type: 'range', min: 0, max: 10, defaultValue: 0 }
            ] },
            { label: 't(Image)', type: 'segment', fields: [
              { name: 'image_id', type: 'attachmentfield', allow: { content_types: ['images'] }, prompt: 't(Upload Image)' },
              { label: 't(Margin)', name: 'image_margin', type: 'range', min: 0, max: 4, defaultValue: 0 },
              { label: 't(Size)', name: 'image_size', type: 'range', min: 20, max: 35, step: 5, units: '%', defaultValue: 20 }  
            ] },
            { label: 't(Tracking Variables)', type: 'segment', fields: [
              { label: 't(Campaign)', name: 'utm_campaign', type: 'textfield' },
              { label: 't(Medium)', name: 'utm_medium', type: 'textfield', defaultValue: 'qrcode' },
              { label: 't(Source)', name: 'utm_source', type: 'textfield' },
              { label: 't(Term)', entity: 'terms', name: 'utm_term', type: 'tagfield' },
              { label: 't(Content)', entity: 'terms', name: 'utm_content', type: 'tagfield' }
            ] }
          ]
        }
      ]
    }
  }

  _getQRImage() {
    const { team } = this.context.admin
    const { shortlink } = this.props
    const { config } = this.state
    const { image_id, image_size, image_margin, dark, light, margin } = config
    const { utm_campaign, utm_medium, utm_source, utm_term, utm_content } = config
    const querystring = (utm_campaign || utm_source || utm_medium !== 'qrcode' || utm_term.length > 0 || utm_content.length > 0) ? qs.stringify({
      utm_campaign: utm_campaign?.length > 0 ? utm_campaign : null,
      utm_medium: utm_medium?.length > 0 ? utm_medium : null,
      utm_source: utm_source?.length > 0 ? utm_source : null,
      utm_term: utm_term.length > 0 ? utm_term.join(' ') : null,
      utm_content: utm_content.length > 0 ? utm_content.join(' ') : null
    }, { 
      format: 'RFC1738',
      skipNulls: true,
      addQueryPrefix: true
    }) : ''
    const encoded = encode({
      image_id,
      image_size,
      image_margin,
      dark,
      light,
      margin,
      string: `${shortlink.expanded_url}${querystring}`
    })
    return `${team.public_host}/${encoded}.png`
  }

  _getPanel() {
    return {
      title: 't(QR Code)',
      rightItems: [
        { label: 't(Done)', handler: this._handleClose }
      ]
    }
  }

  _handleClose() {
    this.context.modal.close()
  }

  _handleUpdate(config) {
    this.setState({ config })
  }

}

export default QRCode
