import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import FiltersField from '@admin/components/mjson_designer/components/filtersfield'
import OverlayField from '@admin/components/mjson_designer/components/overlayfield'
import RadiusField from '@admin/components/mjson_designer/components/radiusfield'
import BorderField from '@admin/components/mjson_designer/components/borderfield'
import LinkedField from '@admin/components/mjson_designer/components/linkedfield'
import ColorField from '@admin/components/mjson_designer/components/colorfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

const Color = (props) => <ResponsiveField { ...props } component={ ColorField } />
const Border = (props) => <ResponsiveField { ...props } component={ BorderField } />
const Radius = (props) => <ResponsiveField { ...props } component={ RadiusField } />
const Spacing = (props) => <ResponsiveField { ...props } component={ LinkedField } />

class ImageStyleField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    device: PropTypes.string,
    omit: PropTypes.array,
    theme: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    omit: [],
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    image: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.image) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { image } = this.state
    if(!_.isEqual(image, prevState.image)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getForm() {
    const { device, omit, theme } = this.props
    const { image } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { label: 't(Background Color)', name: 'backgroundColor', type: Color, device, theme, value: image.backgroundColor },
            { label: 't(Padding)', name: 'padding', device, type: Spacing, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top','right','bottom','left'], nullValues: ['0px'], defaultValue: '0px', value: image.padding },
            { label: 't(Border)', name: 'border', device, theme, type: Border, value: image.border },
            { label: 't(Rounded Corners)', name: 'radius', device, type: Radius, units: [
              { name: 'px', min: 0, max: 100, step: 1 }
            ], types: ['top_left','top_right','bottom_right','bottom_left'], nullValues: ['0px'], defaultValue: null, value: image.radius },
            ..._.includes(omit, 'overlayColor') ? [
              { label: 't(Overlay Color)', name: 'overlayColor', type: OverlayField, theme, value: image.overlayColor }
            ] : [],
            ..._.includes(omit, 'filters') ? [
              { label: 't(Filters)', name: 'filters', type: FiltersField, value: image.filters }
            ] : []
          ]
        }
      ]
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _handleChange() {
    const { image } = this.state
    this.props.onChange(image)
  }

  _handleSet(image) {
    this.setState({ image })
  }

  _handleUpdate(image) {
    this.setState({ image })
  }

}

export default ImageStyleField
