import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    vendor: PropTypes.number,
    integration: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { vendor } = this.props
    return {
      title: 't(Edit Vendors)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/vendors/${vendor.id}`,
      action: `/api/admin/finance/vendors/${vendor.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Name)', name: 'name', type: 'textfield' },
            { label: 't(Address)', name: 'address', type: 'addressfield' }
          ]
        },
        ...this._getIntegration()
      ]
    }
  }

  _getIntegration() {
    const { integration } = this.props
    if(!integration) return []
    return [{
      label: <IntegrationToken integration={ integration } />,
      padded: false,
      fields: [
        { label: 't(Vendor ID)', name: 'integration.vendor_id', type: 'textfield'}
      ]
    }]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
