import Page from '@admin/components/page'
import Date from '@admin/components/date'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Renewal Payments)',
  access: { rights: { $allOf: ['dashboards:access_app','truevail:access_app'] } },
  collection: {
    endpoint: '/api/admin/dashboards/truevail/renewal_payments',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'team', primary: true },
      { label: 't(Billing Cycle)', key: 'cycle_start_date', format: (payment) => (
        <><Date date={ payment.cycle_start_date } /> - <Date date={ payment.cycle_end_date } /></>
      ) },
      { label: 't(Email)', key: 'email', collapsing: true, format: 'check' },
      { label: 't(Social)', key: 'social', collapsing: true, format: 'check' },
      { label: 't(Legacy Website)', key: 'legacy_website', collapsing: true, format: 'check' },
      { label: 't(Date)', key: 'date', collapsing: true, format: 'date' },
      { label: 't(Amount)', key: 'amount', primary: true, collapsing: true, format: 'currency' }
    ],
    // criteria: {
    //   fields: [
    //     { label: 't(Advisor)', fields: [
    //       { name: 't(Email Subscription)', key: 'email', type: 'boolean' },
    //       { name: 't(Social Subscription)', key: 'social', type: 'boolean' },
    //       { name: 't(Legacy Website Subscription)', key: 'legacy_website', type: 'boolean' }
    //     ] }
    //   ]
    // },
    export : [
      { label: 't(ID)', key: 'id' },
      { label: 't(Advisor)', key: 'team' },
      { label: 't(Billing Cycle Start)', key: 'cycle_start_date' },
      { label: 't(Billing Cycle End)', key: 'cycle_end_date' },
      { label: 't(Email)', key: 'email' },
      { label: 't(Social)', key: 'social' },
      { label: 't(Legacy Website', key: 'legacy_website' },
      { label: 't(Date)', key: 'date' },
      { label: 't(Amount)', key: 'amount' }
    ],
    defaultSort: { key: 'team', order: 'asc' },
    empty: {
      icon: 'check-square',
      title: 't(No Renewals)',
      text: 't(There are no renewals for this time period)'
    },
    entity: 'renewals',
    onClick: (record) => context.router.push(`/admin/subscriptions/services/3/renewals/${record.id}`)
  }
})

export default Page(null, mapPropsToPage)
