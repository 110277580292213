import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

const formats = ['HH:mm','H:mm','h:m','h:mmA','h:mm A','h:mA','h:mma','h:mm a','h:ma']

class TimeField extends React.Component {

  static contextTypes = {
    locale: PropTypes.object
  }

  static propTypes = {
    clearable: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    entity: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    required: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    clearable: true,
    placeholder: 'Enter Time',
    onChange: () => {},
    onReady: () => {}
  }

  inputRef = React.createRef()

  state = {
    focused: false,
    time: '',
    value: ''
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleFocus = this._handleFocus.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyDown = this._handleKeyDown.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { clearable, disabled } = this.props
    const { time } = this.state
    return (
      <div className={ this._getClass() }>
        <div className="maha-input-field">
          <Input { ...this._getInput() } />
        </div>
        { clearable && time && time.length > 0 && !disabled &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { time } = this.state
    const { value } = this.props
    if(time !== prevState.time) {
      this._handleChange()
    }
    if(value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getAriaLabel() {
    const { entity, label } = this.props
    const { t } = this.context.locale
    if(entity && entity.length > 0) return t(entity)
    if(label && label.length > 0) return t(label)
  }

  _getClass() {
    const { disabled} = this.props
    const { focused } = this.state
    const classes = ['maha-input','maha-textfield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getFormatted() {
    const time = this.state.value.trim()
    const parsed = moment(time, formats)
    if(!parsed.isValid()) return time
    return parsed.format('h:mm A')
  }

  _getInput() {
    const { disabled, id, tabIndex } = this.props
    const { focused, value } = this.state
    return {
      ref: this.inputRef,
      type: 'text',
      'aria-label': this._getAriaLabel(),
      disabled,
      id,
      placeholder: !focused ? this._getPlaceholder() : null,
      tabIndex,
      value,
      onBlur: this._handleBlured,
      onKeyDown: this._handleKeyDown,
      onFocus: this._handleFocused,
      onChange: this._handleUpdate
    }
  }

  _getParsed(time) {
    return moment(time, formats).format('h:mm A')
  }

  _getPlaceholder() {
    const { label, placeholder } = this.props
    const { t } = this.context.locale
    if(placeholder && placeholder.length > 0) return t(placeholder)
    return t(label ? `t(Enter) ${label}` : '')
  }

  _getRaw() {
    const { time } = this.state
    return time && time.length > 0 ? moment(time, formats).format('HH:mm') : null
  }

  _handleBlured() {
    const { onBlur } = this.props
    const time = this._getFormatted()
    this.setState({
      focused: false,
      time,
      value: time
    })
    if(onBlur) onBlur()
  }

  _handleBlur(e) {
    const time = this._getFormatted()
    this.setState({
      focused: false,
      time,
      value: time
    })
  }

  _handleChange() {
    const raw = this._getRaw()
    this.props.onChange(raw)
  }

  _handleClear() {
    this.setState({
      time: '',
      value: ''
    })
  }

  _handleFocus() {
    this.inputRef.current.focus()
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyDown(e) {
    if(e.which === 13) this.inputRef.current.blur()
  }

  _handleSet(value) {
    const time = value ? this._getParsed(value) : ''
    this.setState({
      time,
      value: time
    })
  }

  _handleUpdate(value) {
    this.setState({ value })
  }

  _handleValidate() {
    const { time } = this.state
    const { required } = this.props
    if(required && (!time || time.length === 0)) {
      return this.props.onValid(null, ['This field is required'])
    }
    const parsed = moment(time, formats)
    if(!parsed.isValid()) {
      return this.props.onValid(null, ['Please enter a valid time'])
    }
    const raw = this._getRaw()
    this.props.onValid(raw)
  }

}

export default TimeField
