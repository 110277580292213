import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  just_font: 'https://truevail.com/wp-content/uploads/2021/08/fbl_justfont.jpg',
  handmade: 'https://truevail.com/wp-content/uploads/2021/08/fbl_handmade.jpg',
  font_meaning: 'https://truevail.com/wp-content/uploads/2021/08/fbl_fontmeaning.jpg',
  initials: 'https://truevail.com/wp-content/uploads/2021/08/fbl_initials.jpg',
  font_shape: 'https://truevail.com/wp-content/uploads/2021/08/fbl_fontincludinginashape.jpg',
  abstract_graphic: 'https://truevail.com/wp-content/uploads/2021/08/ibl_abstractgraphic.jpg',
  silhouette: 'https://truevail.com/wp-content/uploads/2021/08/ibl_silhouette.jpg',
  geometric_symbol: 'https://truevail.com/wp-content/uploads/2021/08/ibl_geometricsymbol.jpg',
  illustrated_symbol: 'https://truevail.com/wp-content/uploads/2021/08/ibl_illustratedsymbol.jpg',
  detailed_illustration: 'https://truevail.com/wp-content/uploads/2021/08/ibl_detailedillustration.jpg',
  seals_crests: 'https://truevail.com/wp-content/uploads/2021/08/ibl_sealsandcreast.jpg',
  mascot: 'https://truevail.com/wp-content/uploads/2021/08/ibl_mascot.jpg'
}

const LogoStyleToken = ({ value }) => (
  <div className="token logo-style-token">
    <img src={ service_types[value] } />
  </div>
)

LogoStyleToken.propTypes = {
  value: PropTypes.string
}

export default LogoStyleToken
