import Page from '@admin/components/page'
import Overview from './overview'
// import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.theme.title,
  access: { 
    programs: { $canView: resources.theme.program.id },
    rights: { $oneOf: ['team:view_templates','team:manage_templates'] } 
  },
  manager: {
    path: `/admin/team/themes/${resources.theme.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview program={ resources.theme.program } theme={ resources.theme } />
      },
      // {
      //   label: 't(Design)',
      //   path: '/design',
      //   panel: <Design program={ resources.theme.program } theme={ resources.theme } />
      // }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  theme: `/api/admin/team/themes/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
