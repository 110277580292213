const INITIAL_STATE = {
  criteria: null,
  total: 0
}

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

  case 'SET_CRITERIA':
    return {
      ...state,
      criteria: action.criteria
    }

  case 'SET_TOTAL':
    return {
      ...state,
      total: action.total
    }

  default:
    return state
  }

}

export default reducer
