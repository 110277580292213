import SocialCampaignToken from '@apps/campaigns/admin/tokens/campaign/social'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/advisors/${props.advisor.id}/social_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Publishing)', key: 'advisor.title', sort: 'advisor', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Date Range)', key: 'post_at', type: 'daterange', include: ['day','this','last','next'] },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: ['scheduled','posting','posted'], format: StatusToken }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(Posting Today)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'today' }
          ]
        } },
        { id: 1, title: 't(Posted Yesterday)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'post_at', operator: '$dr', parent: null, value: 'yesterday' }
          ]
        } },
        { id: 2, title: 't(Stuck Posting)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'status', operator: '$eq', parent: null, value: 'posting' }
          ]
        } }
      ]
    },
    defaultSort: { key: 'post_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Campaigns)',
      text: 't(There are no published social campaigns for this advisor)'
    },
    entity: 'social campaign',
    onClick: (record) => context.router.push(`/admin/truevail/agency/social_publishings/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
