import Attachments from '@admin/components/attachments'
import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import AssetToken from '@admin/tokens/asset'
import Requirements from './requirements'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class AttachmentField extends React.Component {

  static contextTypes = {
    form: PropTypes.object,
    network: PropTypes.object,
    locale: PropTypes.object
  }

  static propTypes = {
    allow: PropTypes.object,
    assets: PropTypes.array,
    defaultValue: PropTypes.any,
    endpoint: PropTypes.string,
    formatter: PropTypes.func,
    images: PropTypes.array,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    plains: PropTypes.array,
    prompt: PropTypes.string,
    requirements: PropTypes.array,
    required: PropTypes.bool,
    status: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    width: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onRemoveAsset: PropTypes.func,
    onSetAssets: PropTypes.func,
    onSetStatus: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    action: '/api/admin/assets/upload',
    endpoint: '/api/admin/assets',
    formatter: (asset) => asset.id,
    multiple: false,
    width: 100,
    onChange: () => {},
    onReady: () => {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleClick = this._handleClick.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { assets, images, multiple, plains, requirements, width } = this.props
    return (
      <div className="maha-attachmentfield">
        <div className="maha-attachmentfield-assets">
          { images.map((asset,index) => (
            <div className="maha-attachmentfield-image" key={ `image_${index}` }>
              <div className="maha-attachmentfield-frame" style={{ width, paddingBottom: width }}>
                <Img src={`/imagecache/fit=cover&w=${width}&h=${width}${ asset.path }`} />
              </div>
              <Button { ...this._getRemove(index) } />
            </div>
          )) }
          { plains.map((asset,index) => (
            <div className="maha-attachmentfield-asset" key={ `asset_${index}` }>
              <AssetToken { ...asset } download={ false } key={ `asset_${asset.id}` } />
              <div className="maha-attachmentfield-remove" onClick={ this._handleRemove.bind(this, index) }>
                <Icon icon="times" />
              </div>
            </div>
          )) }
        </div>
        { (multiple || assets.length === 0) &&
          <>
            <Button { ...this._getAdd() } />
            { requirements &&
              <Requirements { ...this._getRequirements() } />
            }          
          </>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!defaultValue) return this._handleReady()
    const ids = _.castArray(defaultValue)
    if(ids.length === 0) return this._handleReady()
    this._handleFetch(ids)
  }

  componentDidUpdate(prevProps) {
    const { assets, status } = this.props
    if(!_.isEqual(assets, prevProps.assets)) {
      this._handleChange()
    }
  }

  _getAdd() {
    const { tabIndex } = this.props
    return {
      label: this._getPrompt(),
      fluid: false,
      tabIndex,
      handler: this._handleClick
    }
  }

  _getPrompt() {
    const { label, multiple, prompt } = this.props
    const { t } = this.context.locale
    if(prompt && prompt.length > 0) return t(prompt)
    return t(label ? `t(Choose) ${label}` : multiple ? 't(Choose Attachments)' : 't(Choose Attachment)')
  }

  _getAttachments() {
    const { allow, multiple, requirements } = this.props
    return {
      allow,
      multiple,
      requirements,
      onBack: this._handleBack,
      onChooseAssets: this._handleSet,
      onDone: this._handleDone
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getInput() {
    const { tabIndex } = this.props
    return {
      tabIndex
    }
  }

  _getRemove(index) {
    const { tabIndex } = this.props
    return {
      label: 't(remove image)',
      className: 'link',
      tabIndex,
      handler: this._handleRemove.bind(this, index)
    }
  }

  _getNormalized() {
    const { formatter, multiple } = this.props
    const assets = this.props.assets.map(formatter)
    return (multiple ? assets : assets[0]) || null
  }

  _getRequirements() {
    const { requirements } = this.props
    return { requirements }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange() {
    const value = this._getNormalized()
    this.props.onChange(value)
  }

  _handleFetch(ids) {
    const { endpoint } = this.props
    this.context.network.request({
      endpoint,
      method: 'GET',
      query: { 
        $filter: { 
          id: { 
            $in: ids 
          } 
        } 
      },
      onSuccess: ({ data }) => {
        this.props.onSetAssets(data)
        this._handleReady()
      },
      onFailure: () => {}
    })
  }

  _handleSet(newassets) {
    const { formatter, multiple } = this.props
    const assets = multiple ? [
      ...this.props.assets,
      ...newassets
    ] : newassets
    this.props.onSetAssets(assets)
    const value = (multiple ? assets.map(formatter) : formatter(assets[0])) || null
    this.props.onChange(value)
  }

  _handleDone() {
    this.context.form.pop()
  }

  _handleClick() {
    this.context.form.push(Attachments, this._getAttachments.bind(this))
  }

  _handleReady() {
    this.props.onReady(this._handleValidate)
  }

  _handleRemove(index) {
    this.props.onRemoveAsset(index)
  }

  _handleValidate() {
    const { assets, required, onValid } = this.props
    if(required && assets.length === 0) return onValid(null, ['This field is required'])
    const value = this._getNormalized()
    onValid(value)
  }

}

export default AttachmentField
