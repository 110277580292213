import HelpArticleToken from '@admin/tokens/help_article'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    records: PropTypes.array,
    onCategory: PropTypes.func
  }

  render() {
    const { records  } = this.props
    const articles = records.sort((a,b) => {
      return a.title > b.title ? 1 : -1
    })
    return (
      <div className="maha-help-search-cards">
        { articles.map((result, i) => (
          <div className="maha-help-search-card" key={ `result_${i}` } onClick={ this._handleClick.bind(this, result) }>
            <div className="maha-help-search-card-inner">
              <div className="maha-help-search-card-icon">
                <Icon svg={ result.icon } />
              </div>
              <div className="maha-help-search-card-label">
                { result.title }
              </div>
            </div>
          </div>
        )) }
      </div>
    )
  }

  _handleClick(category) {
    this.props.onCategory(category)
  }

}

export default Results
