import DmarcStatusToken from '@admin/tokens/dmarc_status'
import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(DMARC Failures)',
  collection: {
    endpoint: '/api/admin/truevail/admin/dmarc_failures',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Header From)', key: 'header_from.name', sort: 'header_from' },
      { label: 't(Source IP)', key: 'source_ip.ipv4', sort: 'source_ip' },
      { label: 't(Organization)', key: 'dmarc_report.organization.name', sort: 'organization' },
      { label: 't(Count)', key: 'count', collapsing: true, align: 'right' },
      { label: 't(Date)', key: 'dmarc_report.start_date', sort: 'start_date', collapsing: true, format: 'date' },
      { label: 't(Disposition)', key: 'disposition', collapsing: true, sort: 'domain', format: DmarcStatusToken },
      { label: 't(SPF)', key: 'spf', collapsing: true, format: DmarcStatusToken },
      { label: 't(DKIM)', key: 'dkim', collapsing: true, format: DmarcStatusToken }
    ],
    defaultSort: { key: 'start_date', order: 'desc' },
    empty: {
      icon: 'times',
      title: 't(No DMARC Failures)',
      text: 't(There are no DMARC failures)'
    },
    entity: 'records',
    onClick: (record) => context.router.push(`/admin/truevail/admin/dmarc_reports/${record.dmarc_report.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
