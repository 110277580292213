const triggers = [
  {
    icon: 'id-card',
    code: 'property_updated',
    title: 't(Property Updated)',
    text: 't(Contact property is updated)'
  },
  {
    icon: 'th-list',
    code: 'subscription_created',
    title: 't(List Subscribed)',
    text: 't(Contact is added to list)'
  },
  {
    icon: 'th-list',
    code: 'subscription_deleted',
    title: 't(List Unsubscribed)',
    text: 't(Contact is removed from list)'
  },
  {
    icon: 'tag',
    code: 'tag_added',
    title: 't(Tag Add)',
    text: 't(Contact is tagged with tag)'
  },
  {
    icon: 'tag',
    code: 'tag_removed',
    title: 't(Tag Removed)',
    text: 't(Contact is untagged with tag)'
  }
]

export default triggers
