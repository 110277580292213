import VoiceToken from '@apps/truevail/admin/tokens/voice'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'
import Edit from '../edit'

const Overview = ({ advisor }) => {

  const details = {
    audits: `truevail_advisors/${advisor.id}`,
    comments: `truevail_advisors/${advisor.id}`,
    sections: [
      {
        title: 't(Advisor Details)',
        items: [
          { label: 't(Name)', content: advisor.name },
          { label: 't(Organization)', content: advisor.organization },
          { label: 't(Voice)', content: <VoiceToken perspective={ advisor.perspective } language={ advisor.language } /> },
          { label: 't(Timezone)', content: advisor.team.timezone.zone },
          { label: 't(Reviewers)', content: advisor.reviewers.length > 0 ? advisor.reviewers.map(((user, index) => (
            <div key={`reviewer_${index}`}>{ user.full_name }</div>
          ))) : null }
        ]
      }
    ]
  }

  if(advisor.team.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This advisor is pending configuration)' }
  } else if(advisor.team.status === 'configured') {
    details.alert = { color: 'blue', message: 't(This advisor has configured their account)' }
  } if(advisor.team.status === 'active') {
    details.alert = { color: 'green', message: 't(This advisor is active)' }
  } else if(advisor.team.status === 'pausing') {
    details.alert = { color: 'teal', message: (
      <>This advisor will be paused on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }</>
    ) }
  } else if(advisor.team.status === 'reactivating') {
    details.alert = { color: 'teal', message: (
      <>This advisor will reactivate on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }</>
    ) }
  } else if(advisor.team.status === 'resuming') {
    details.alert = { color: 'teal', message: (
      <>This advisor will resume on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }</>
    ) }
  } else if(advisor.team.status === 'canceling') {
    details.alert = { color: 'teal', message: (
      <>This advisor will cancel on { moment(advisor.team.transition_at).format('MM/DD/YY [@] h:mm A') }</>
    ) }
  } else if(advisor.team.status === 'unconfigured') {
    details.alert = { color: 'red', message: 't(This advisor did not complete their configuration within 30 days)' }
  } else if(advisor.team.status === 'paused') {
    details.alert = { color: 'yellow', message: 't(This advisor is paused)' }
  } else if(advisor.team.status === 'inactive') {
    details.alert = { color: 'red', message: 't(This advisor is inactive)' }
  } else if(advisor.team.status === 'canceled') {
    details.alert = { color: 'red', message: 't(This advisor has been canceled)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } />,
  tasks: {
    items: [
      {
        label: 't(Edit Advisor)',
        modal: <Edit advisor={ props.advisor } />
      },
    ]
  }
})

export default Panel(null, mapPropsToPanel)
