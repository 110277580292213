import PropTypes from 'prop-types'
import React from 'react'

const RevenueTypeToken = ({ description, integration, title }) => (
  <div className="token revenue-type-token">
    { integration && integration.revenue_code && <strong>{ integration.revenue_code  } - </strong> }
    { title }<br />
    <span>{ description }</span>
  </div>
)

RevenueTypeToken.propTypes = {
  description: PropTypes.string,
  integration: PropTypes.object,
  title: PropTypes.string
}

export default RevenueTypeToken
