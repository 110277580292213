import Page from '@admin/components/page'
import itemButtons from '../item_buttons'
import itemTasks from '../item_tasks'
import Overview from './overview'
import Edit from './edit'
import React from 'react'

const mapResourcesToPage = (props, context) => ({
  app: '/api/admin/apps/finance/settings',
  reimbursement: `/api/admin/finance/reimbursements/${props.params.id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Reimbursement)',
  access: { rights: { $oneOf: ['finance:manage_expenses','finance:view_expenses'] } },
  panel: {
    component: <Overview reimbursement={ resources.reimbursement } />
  },
  tasks: itemTasks('reimbursement', resources.reimbursement, context, Edit),
  buttons: itemButtons(resources.app.settings, 'reimbursement', resources.reimbursement, context)
})

export default Page(mapResourcesToPage, mapPropsToPage)
