import ModalPanel from '@admin/components/modal_panel'
import Details from '@admin/components/details'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Device extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.object,
    is_this_device: PropTypes.bool
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSignout = this._handleSignout.bind(this)

  render() {
    const { device } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-device">
          <div className="maha-device-header">
            <Icon icon={`${device.icon}`} />
          </div>
          <div className="maha-device-body">
            <Details { ...this._getDetails() } />
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getDetails() {
    const { display_name, fingerprint, push_enabled, last_active_at } = this.props.device
    return {
      items: [
        { label: 't(Device)', content: display_name },
        { label: 't(Last Active)', content: moment(last_active_at).fromNow()},
        { label: 't(Push Enabled)', content: push_enabled ? 'YES' : 'NO' },
        { label: 't(Fingerprint)', content: fingerprint }
      ]
    }
  }

  _getPanel() {
    const { is_this_device } = this.props
    return {
      title: 't(Device)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleCancel }
      ],
      buttons: !is_this_device ? [
        { label: 't(Remove this Device)', color: 'red', handler: this._handleRemove },
        { label: 't(Signout)', color: 'red', handler: this._handleSignout }
      ] : null
    }
  }

  _handleCancel() {
    this.context.modal.pop()
  }

  _handleRemove() {
    const { id } = this.props.device
    this.context.network.request({
      endpoint: `/api/admin/sessions/${id}/remove`,
      method: 'POST',
      onSuccess: () => this.context.modal.pop()
    })
  }

  _handleSignout() {
    const { id } = this.props.device
    this.context.network.request({
      endpoint: `/api/admin/sessions/${id}/signout`,
      method: 'POST',
      onSuccess: () => this.context.flash.set('success', 't(The session has been terminated)'),
      onFailure: () => this.context.flash.set('error', 't(Unable to terminate the session)')
    })
  }

}

export default Device
