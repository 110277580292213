import Search from '@admin/components/search'
import Results from './results'
import React from 'react'

class OmniSearch extends React.Component {

  static propTypes = {}

  render() {
    return <Search { ...this._getSearch() }/>
  }

  _getSearch() {
    return {
      prompt: 't(Find anything)',
      start: {
        icon: 'search',
        title: 't(Search for anything)'
      },
      empty: {
        icon: 'remove',
        title: 't(No results matched your query)'
      },
      endpoint: '/api/admin/search',
      layout: Results
    }
  }

}

export default OmniSearch
