import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const types = {
  flat: {
    title: 't(Flat Fee)',
    text: 't(The contact is charged a regular flat fee each billing cycle)'
  },
  metered: {
    title: 't(Metered Billing)',
    text: 't(The contact is charged for their usage of resources in a given billing cycle in addition to an optional base fee)'
  }
}

const BillingTypeToken = ({ value }) => (
  <div className="token">
    <strong><T text={ types[value].title } /></strong><br />
    <T text={ types[value].text } />
  </div>
)

BillingTypeToken.propTypes = {
  value: PropTypes.string
}

export default BillingTypeToken
