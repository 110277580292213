import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  individual_american: {
    title: 't(Individial / American English)',
    text: 't(Write content using I/Me/My and use American English spelling)'
  },
  agency_american: {
    title: 't(Agency / American English)',
    text: 't(Write content using We/Us/Our and use American English spelling)'
  },
  individual_canadian: {
    title: 't(Individial / Canadian English)',
    text: 't(Write content using I/Me/My and use Canadian English spelling)'
  },
  agency_canadian: {
    title: 't(Agency / Canadian English)',
    text: 't(Write content using We/Us/Our and use American English spelling)'
  }
}

const VoiceToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
    
  </div>
)

VoiceToken.propTypes = {
  value: PropTypes.string
}

export default VoiceToken
