import Page from '@admin/components/page'
import Renewals from './renewals'
import Overview from './overview'
import Records from './records'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.domain.name,
  access: { rights: { $oneOf: ['domains:view_domains','domains:manage_domains'] } },
  manager: {
    path: `/admin/domains/${resources.domain.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview domain={ resources.domain } />
      },
      {
        label: 't(DNS)',
        path: '/dns',
        show: resources.domain.dns_status !== 'unused',
        panel: <Records domain={ resources.domain } />
      },
      {
        label: 't(Renewals)',
        path: '/renewals',
        show: resources.domain.registration_status !== 'unused',
        panel: <Renewals domain={ resources.domain } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  domain: `/api/admin/domains/domains/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
