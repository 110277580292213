import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    program: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact, program } = this.props
    return {
      title: 't(Manage Lists)',
      method: 'PATCH',
      action: `/api/admin/crm/contacts/${contact.id}/lists`,
      endpoint: `/api/admin/crm/contacts/${contact.id}/edit`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program.id },
            { label: 't(Lists)', name: 'list_ids', type: 'checkboxgroup', endpoint: '/api/admin/crm/lists', query: { program_id: program.id }, sort: 'title', valueKey: 'id', textKey: 'title' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
