import ShortLink from '@admin/components/shortlink'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import Clone from '../clone'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

const Overview = ({ store }, { admin }) => {

  const program = {
    label: store.program.title,
    className: 'link',
    route: `/admin/team/programs/${store.program.id}`
  }

  const shortlink = {
    shortlink: store.shortlink,
    text: 't(Share this store)',
    title: store.title
  }

  const details = {
    audits: `stores_stores/${store.id}`,
    comments: `stores_stores/${store.id}`,
    before: <ShortLink { ...shortlink } />,
    sections: [
      {
        title: 't(Store Details)',
        items: [
          { label: 't(Title)', content: store.title },
          { label: 't(Program)', show: admin.team.has_programs, content: <Button { ...program } />  }
        ]
      },
      {
        title: 't(Options)',
        items: [
          { label: 't(Cart Lifetime)', content: pluralize('hour', store.config.cart_lifetime, true) },
          { label: 't(Pay by Check)', content: store.config.check_enabled ? 'ENABLED' : 'DISABLED' },
          { label: 't(Pay Later)', content: store.config.async_enabled ? 'ENABLED' : 'DISABLED' }
        ]
      }
    ]
  }

  if(store.config.open === 'limit') {
    details.sections[1].items.push({ label: 't(Store Opens)', content: moment(store.config.opens_at).format('MM/DD/YY [@] h:mm A') })
  }

  if(store.config.close === 'limit') {
    details.sections[1].items.push({ label: 't(Store Closes)', content: moment(store.config.closes_at).format('MM/DD/YY [@] h:mm A') })
  }

  if(store.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This store was deleted)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  store: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview store={ props.store } />,
  tasks: {
    items: [
      { label: 't(Edit Store)', modal: <Edit store={ props.store } /> },
      { label: 't(Clone Store)', modal: <Clone store={ props.store } /> },
      {
        label: 't(Delete Store)',
        confirm: 't(Are you sure you want to delete this store? You will loose access to all of the order and revenue information.)',
        request: {
          endpoint: `/api/admin/stores/stores/${props.store.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this store)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this store)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
