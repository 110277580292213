import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const TriggerToken = ({ value }) => (
  <div className="token">
    <T text={ value.text } />
  </div>
)

TriggerToken.propTypes = {
  value: PropTypes.object
}

export default TriggerToken
