import Note from '@apps/crm/admin/views/contacts/show/notes/new'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Activity from './activity'
import moment from 'moment'
import React from 'react'

class Timeline extends React.PureComponent {

  static propTypes = {
    contact: PropTypes.object,
    activities: PropTypes.array,
    status: PropTypes.string,
    onFetch: PropTypes.func
  }

  render() {
    const { contact } = this.props
    const months = this._getMonths()
    return (
      <div className="crm-timeline">
        { months.map((month, index) => [
          <div className="crm-timeline-month" key={ `date_${index}` }>
            { index > 0 &&
              <div className="crm-timeline-date" key={ `date_${index}` }>
                { moment(month.date).format('MMMM YYYY') }
              </div>
            }
            <div className="crm-timeline-cards">
              { month.activities.map((activity, index) => (
                <Activity contact={ contact } activity={ activity } key={ `event_${index}` } />
              )) }
            </div>
          </div>
        ]) }
      </div>
    )
  }

  _getEmail() {
    const { contact } = this.props
    return {
      label: 't(Email)',
      className: 'ui button',
      modal: <Note contact={ contact } />
    }
  }

  _getMonths() {
    const { activities } = this.props
    return Object.values(activities.reduce((months, activity) => {
      const month = moment(activity.created_at).format('YYYYMM')
      return {
        ...months,
        [month]: {
          date: activity.created_at,
          activities: [
            ...months[month] ? months[month].activities : [],
            activity
          ]
        }
      }
    }, {})).sort((a,b) => {
      return a.date < b.date ? 1 : -1
    })
  }

}

const mapResourcesToPanel = (props, context) => ({
  activities: `/api/admin/crm/contacts/${props.contact.id}/activities`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Timeline)',
  panel: <Timeline contact={ props.contact } activities={ props.activities } />
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
