import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class ChartField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.object,
    exclude: PropTypes.array,
    include: PropTypes.array,
    value: PropTypes.object,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    type: null
  }

  render() {
    const types = this._getTypes()
    return (
      <div className="maha-chartfield">
        { types.map((type, index) => (
          <div { ...this._getType(type) } key={`chart_${index}`}>
            <div className="maha-chartfield-chart-image" >
              <Img src={`/images/charts/${type}.png`} />
            </div>
            <div className="maha-chartfield-chart-label" >
              { type.toUpperCase() }
            </div>
          </div>
        ))}
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { type } = this.state
    const { value } = this.props
    if(!_.isEqual(type, prevState.type)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getClass(type) {
    const classes = ['maha-chartfield-chart']
    if(type === this.state.type) classes.push('selected')
    return classes.join(' ')
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getIcon(type) {
    return type === this.state.type ? 'check-circle' : 'circle-o'
  }

  _getTypes() {
    const types = ['area','line','bar','doughnut','pie']
    const { include, exclude } = this.props
    return types.filter(type => {
      const included = !include || _.includes(include, type)
      const excluded = exclude && !_.includes(exclude, type)
      return included && !excluded
    })
  }

  _getType(type) {
    return {
      className: this._getClass(type),
      onClick: this._handleUpdate.bind(this, type)
    }
  }

  _handleChange() {
    const { type } = this.state
    this.props.onChange(type)
  }

  _handleSet(type) {
    this.setState({ type })
  }

  _handleUpdate(type) {
    this.setState({ type })
  }

}

export default ChartField
