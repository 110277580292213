import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    integration: PropTypes.string
  }

  state = {
    type: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    return {
      title: 't(New Expense Type)',
      method: 'POST',
      action: '/api/admin/finance/expense_types',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' }
          ]
        },
        ...this._getIntegration()
      ]
    }
  }

  _getIntegration() {
    const { integration } = this.props
    if(!integration) return []
    return [{
      label: <IntegrationToken integration={ integration } />,
      padded: false,
      fields: [
        { label: 't(Expense Code)', name: 'integration.expense_code', type: 'textfield', required: true },
        { label: 't(Source Code)', name: 'integration.source_code', type: 'textfield' }
      ]
    }]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(project) {
    const { modal, router } = this.context
    modal.close()
    router.push(`/finance/expense_types/${project.id}`)
  }

}

export default New
