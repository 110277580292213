import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Campaign from './campaign'
import Details from './details'
import React from 'react'

class Import extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    program: PropTypes.object,
    program_id: PropTypes.number
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { program } = this.props
    return {
      title: 't(Import Email Campaign)',
      action: '/api/admin/campaigns/email',
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        program
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(formdata) {
    const { program_id } = this.props
    return {
      program_id,
      truevail_email_campaign_id: formdata.email_campaign.id,
      purpose: formdata.purpose,
      sender_id: formdata.sender_id,
      template_id: formdata.template_id
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Campaign)', component: Campaign },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(campaign) {
    this.context.modal.close()
  }

}

export default Import
