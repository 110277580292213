import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Tags extends React.Component {

  static propTypes = {
    program: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { program, onBack } = this.props
    return {
      title: 't(Update Tags)',
      saveText: 'Next',
      ...onBack ? {
        cancelIcon: 'chevron-left',
        onCancel: this._handleBack
      } : {
        onCancel: this._handleCancel
      },
      onSubmit: this._handleSubmit,
      sections: [
        {
          fields: [
            { name: 'program_id', type: 'hidden', value: program.id },
            { type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'add', text: 't(Add Tags)' },
                { value: 'remove', text: 't(Remove Tags)' }
              ], defaultValue: 'add' },
              { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags', filter: { program_id: { $eq: program.id } }, required: true }
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleSubmit(config) {
    this.props.onDone(config)
  }

}

export default Tags
