import Button from '@admin/components/button'
import Img from '@admin/components/html/img'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Offer extends React.Component {

  static contextTypes = {
    device: PropTypes.object,
    local_storage: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  state = {
    ready: false,
    offer: null
  }

  _handleDismiss = this._handleDismiss.bind(this)
  _handleDownload = this._handleDownload.bind(this)

  render() {
    const { offer, ready } = this.state
    const { device } = this.context
    if(!ready) return null
    return offer !== 'dismiss' ?
      <div className="maha-device-offer">
        <div className="maha-device-offer-panel">
          <h2><T text={ this._getTitle() } /></h2>
          <p><T text={ this._getText() } /></p>
          <Img src={`/images/${device.device}.png`} className={`maha-device-offer-device ${device.device}`}  />
          <div className="maha-device-offer-button">
            <Button { ...this._getDownload() } />
          </div>
          <Button { ...this._getPass() } />
        </div>
      </div> :
      <>{ this.props.children }</>
  }

  componentDidMount() {
    this._handleSet('dismiss')
    // this._handleInit()
  }

  _getDownload() {
    return {
      label: this._getLabel(),
      color: 'red',
      handler: this._handleDownload
    }
  }

  _getLabel() {
    const { device } = this.context
    if(device.os === 'windows') return 't(Download Maha for Windows)'
    if(device.os === 'mac os') return 't(Download Maha for macOS)'
    if(device.os === 'ios') return 't(Download Maha for iOS)'
    if(device.os === 'samsung browser') return 't(Download Maha for Android)'
    if(device.os === 'android') return 't(Download Maha for Android)'
  }

  _getText() {
    return 't(Stay signed in, receive push notifications, and access your data at any time)'
  }

  _getTitle() {
    const { device } = this.context
    return (device.device === 'mobile') ? 't(Maha on Your Phone)' : 't(Maha on Your Desktop)'
  }

  _getPass() {
    return {
      label: 't(No thanks)',
      className: 'link',
      handler: this._handleDismiss
    }
  }

  _handleInit() {
    const { device } = this.context
    if(device.platform !== 'web') return this._handleSet('dismiss')
    this.context.local_storage.get({
      key: 'platform-offer',
      onSuccess: (offer) => {
        this._handleSet(offer)
      }
    })
  }

  _handleDismiss() {
    this.context.local_storage.set({
      key: 'platform-offer',
      value: 'dismiss',
      onSuccess: (offer) => {
        this._handleSet(offer)
      }
    })
  }

  _handleDownload() {}

  _handleSet(offer) {
    this.setState({
      offer,
      ready: true
    })
  }

}

export default Offer
