import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import { useAdminContext } from '@admin/components/admin'
import Container from '@admin/components/container'
import * as selectors from './selectors'
import React, { useEffect } from 'react'
import * as actions from './actions'
import PropTypes from 'prop-types'
import Comments from './comments'
import reducer from './reducer'

const CommentsContainer = (props) => {

  const { admin } = useAdminContext()

  const { network } = useNetworkContext()

  const [state, handlers] = useActionReducer({
    display_name: 'comments',
    actions,
    props,
    initialState: {
      comments: props.comments
    },
    reducer,
    selectors   
  })
  
  useEffect(() => {
    const channel = `/teams/${admin.team.id}/admin/${props.entity}/comments`  
    network.subscribe({ channel, action: 'add_comment', handler: handlers.onAddComment })
    network.subscribe({ channel, action: 'add_typing', handler: handlers.onAddTyping })
    network.subscribe({ channel, action: 'remove_comment', handler: handlers.onRemoveComment })
    network.subscribe({ channel, action: 'remove_typing', handler: handlers.onRemoveTyping })
    return () => {
      network.unsubscribe({ channel, action: 'add_comment', handler: handlers.onAddComment })
      network.unsubscribe({ channel, action: 'add_typing', handler: handlers.onAddTyping })
      network.unsubscribe({ channel, action: 'remove_comment', handler: handlers.onRemoveComment })
      network.unsubscribe({ channel, action: 'remove_typing', handler: handlers.onRemoveTyping })
    }
  }, [])
  
  const mergedProps = {
    context: {
      admin,
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Comments { ...mergedProps } />

}

CommentsContainer.propTypes = {
  comments: PropTypes.array,
  entity: PropTypes.string
}

const mapResources = (props) => ({
  comments: `/api/admin/${props.entity}/comments`
})

export default Container(mapResources)(CommentsContainer, () => null)