import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Preview from './preview'
import Canvas from './canvas'
import React from 'react'

class Main extends React.PureComponent {

  static propTypes = {
    active: PropTypes.string,
    cards: PropTypes.array,
    darkMode: PropTypes.bool,
    device: PropTypes.string,
    devices: PropTypes.array,
    editable: PropTypes.bool,
    hover: PropTypes.string,
    images: PropTypes.bool,
    orientation: PropTypes.string,
    type: PropTypes.string,
    version: PropTypes.object,
    onAction: PropTypes.func,
    onHover: PropTypes.func
  }

  stackRef = React.createRef()

  render() {
    const { editable } = this.props
    return (
      <div className="mjson-designer-main">
        <Preview { ...this._getPreview() }>
          <Canvas { ...this._getCanvas() } />
        </Preview>
        { editable &&
        <div className="mjson-designer-sidebar">
          <Stack { ...this._getStack() } />
        </div>      
        }
      </div>
    )
  }

  _getCanvas() {
    const { active, darkMode, editable, version, hover, images, type, onAction, onHover } = this.props
    return {
      active,
      darkMode,
      editable,
      version,
      hover,
      images,
      type,
      onAction,
      onHover
    }
  }

  _getPreview() {
    const { device, devices, orientation } = this.props
    return {
      device,
      orientation,
      devices
    }
  }

  _getStack() {
    const { cards } = this.props
    return {
      display_name: 'mjson_designer',
      cards,
      ref: this.stackRef
    }
  }

}

export default Main
