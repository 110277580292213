import ThreadViewer from '@admin/components/email_viewer/thread'
import Page from '@admin/components/page'

const mapResourcesToPage = (props, context) => ({
  thread: `/api/admin/email/threads/${props.params.code}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Thread)',
  component: ThreadViewer
})

export default Page(mapResourcesToPage, mapPropsToPage)
