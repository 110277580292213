import Page from '@admin/components/page'
import Performance from './performance'
import Overview from './overview'
import Emails from './emails'
import Design from './design'
import Calls from './calls'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.voice_campaign.title,
  manager: {
    path: `/admin/campaigns/voice/${resources.voice_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview voice_campaign={ resources.voice_campaign } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design voice_campaign={ resources.voice_campaign } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails voice_campaign={ resources.voice_campaign }  />
      },
      {
        label: 't(Calls)',
        path: '/calls',
        panel: <Calls voice_campaign={ resources.voice_campaign }  />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        panel: <Performance voice_campaign={ resources.voice_campaign }  />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  voice_campaign: `/api/admin/campaigns/voice/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
