import { DragSource, DropTarget } from 'react-dnd'
import Format from '@admin/components/format'
import Button from '@admin/components/button'
import Handle from '@admin/components/handle'
import PropTypes from 'prop-types'
import React from 'react'

class Item extends React.Component {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    editable: PropTypes.bool,
    format: PropTypes.any,
    moveable: PropTypes.bool,
    item: PropTypes.object,
    index: PropTypes.number,
    onClone: PropTypes.func,
    onEdit: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func
  }

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, editable, format, item, moveable } = this.props
    const output = (
      <div className={`multifield-item ${item.entity}`}>
        { moveable &&
          connectDragSource(
            <div className="multifield-item-handle">
              <Handle />
            </div>
          )
        }
        <div className="multifield-item-token">
          <Format format={ format } value={ item } />
        </div>
        { editable &&
          <Button { ...this._getEdit() } />
        }
        <Button { ...this._getClone() } />
        <Button { ...this._getRemove() } />
      </div>
    )
    return moveable ? connectDropTarget(connectDragPreview(output)) : output
  }

  _getClone() {
    return {
      icon: 'copy',
      className: 'multifield-item-action',
      handler: this.props.onClone
    }
  }

  _getEdit() {
    return {
      icon: 'pencil',
      className: 'multifield-item-action',
      handler: this.props.onEdit
    }
  }

  _getRemove() {
    return {
      icon: 'times',
      className: 'multifield-item-action',
      confirm: 't(Are you sure you want to delete this item?)',
      handler: this.props.onRemove
    }
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
  drop: (props, monitor, component) => ({
    index: props.index
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

Item = DragSource('ITEM', source, sourceCollector)(Item)
Item = DropTarget('ITEM', target, targetCollector)(Item)

export default Item
