import SPAMReport from '@admin/components/email_report'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Spam = ({ email }) => {

  const details = {
    sections: [
      {
        title: 't(SPAM Check)',
        items: [
          { padded: true, component: <SPAMReport spamassassin={ email.spamassassin } /> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Spam.propTypes = {
  email: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Spam Report)',
  panel: <Spam email={ props.email } />
})

export default Panel(null, mapPropsToPanel)
