import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Inventory extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'inventory_policy', type: 'radiogroup', required: true, options: [
              { value: 'unlimited', text: 't(Do not manage inventory)' },
              { value: 'deny', text: 't(Stop selling when inventory reaches 0)' },
              { value: 'continue', text: 't(Allow sales to continue into negative inventory levels)' }
            ], defaultValue: formdata.inventory_policy }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(inventory) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...inventory
    })
  }

}

export default Inventory
