import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ profile }, { admin }) => {

  const service = {
    label: profile.service.name,
    className: 'link',
    link: profile.url
  }

  const details = {
    audits: `maha_profiles/${profile.id}`,
    comments: `maha_profiles/${profile.id}`,
    sections: [
      {
        title: 't(Profile Details)',
        items: [
          { label: 't(Name)', content: profile.name },
          { label: 't(User Name)', content: profile.username },
          { label: 't(Service)', content: <Button { ...service } /> },
          { label: 't(Scopes)', content: profile.scopes.map((scope, index) => (
            <div key={`scope_${index}`}>{ scope }</div>
          )) }
        ]
      }
    ]
  }

  if(profile.deleted_at) {
    details.alert = { color: 'red', message: 't(This profile has been disconnected)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  profile: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview profile={ props.profile } />,
  tasks: {
    items: [
      { 
        label: 't(Disconnect Profile)',
        access: {
          programs: { $canEdit: props.profile.program.id },
          rights: { $oneOf: ['team:manage_profiles'] } 
        },
        confirm: 't(Are you sure you want to disconnect this profile?)',
        request: {
          endpoint: `/api/admin/team/profiles/${props.profile.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to disconnect this profile)'),
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(This profile has been disconnected)')
          }
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
