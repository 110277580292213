import EmailActivities from '@admin/components/email_activities'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class EmailCampaign extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object,
    email: PropTypes.object,
    email_campaign: PropTypes.object,
    program: PropTypes.object
  }

  render() {
    const { email } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Email Campaign</td>
                <td><Button { ...this._getCampaign() } /></td>
              </tr>
              <tr>
                <td>Email Address</td>
                <td>{ email.to[0].rfc822 }</td>
              </tr>
              <tr>
                <td>Email</td>
                <td><Button { ...this._getDelivery() } /></td>
              </tr>
              { email.enrollment &&
                <tr>
                  <td>Workflow</td>
                  <td><Button { ...this._getEnrollment() } /></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="crm-timeline-card-section-footer">
          <EmailActivities email={ email } />
        </div>
        <Comments entity={`maha_emails/${email.id}`} />
      </div>
    )
  }

  _getCampaign() {
    const { email_campaign } = this.props
    return {
      label: email_campaign.title,
      className: 'link',
      route: `/admin/campaigns/email/${email_campaign.id}`
    }
  }

  _getDelivery() {
    const { email } = this.props
    return {
      label: 'View Email',
      className: 'link',
      route: `/admin/emails/${email.code}`
    }
  }

  _getEmailAddress() {
    const { email } = this.props
    return {
      label: 'View Email',
      className: 'link',
      route: `/admin/emails/${email.code}`
    }
  }

  _getEnrollment() {
    const { email } = this.props
    return {
      label: 'View Enrollment',
      className: 'link',
      route: `/admin/automation/workflows/${email.enrollment.workflow_id}/enrollments/${email.enrollment.id}`
    }
  }

}

const mapResources = (props, context) => ({
  email_campaign: `/api/admin/campaigns/email/${props.activity.data.email_campaign_id}`,
  email: `/api/admin/campaigns/email/${props.activity.data.email_campaign_id}/deliveries/${props.activity.data.email_id}`
})

export default Container(mapResources)(EmailCampaign)
