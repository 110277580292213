import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { config } = this.state
    const { event_id, field_id, form_id, list_id, program_id, service_id, store_id, tag_id, trigger } = formdata
    const pipeline_id = config.pipeline_id || formdata.pipeline_id
    const email_campaign_id = this._getEmailCampaignId()
    const email_id = this._getAutomationEmailId()
    const filter = { program_id: { $eq: program_id } }
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            ...trigger === 'automation:enrollment_created' ? [
              { label: 't(Purpose)', name: 'purpose', type: 'radiogroup', options: ['marketing','transactional'], required: true, format: PurposeToken, defaultValue: 'marketing' }
            ] : [],
            ..._.includes(['automation:email_received','automation:email_opened','automation:email_clicked'], trigger) && !formdata.email_id ? [
              { label: 't(Automation Email)', name: 'email_id', type: 'lookup', endpoint: '/api/admin/automation/emails', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ..._.includes(['automation:email_clicked'], trigger) && email_id ? [
              { label: 't(Link)', name: 'email_link_id', type: 'lookup', endpoint: `/api/admin/automation/emails/${email_id}/links`, valueKey: 'id', textKey: 'url', required: true }
            ] : [],
            ..._.includes(['campaigns:email_received','campaigns:email_opened','campaigns:email_clicked'], trigger) && !formdata.email_campaign_id ? [
              { label: 't(Email Campaign)', name: 'email_campaign_id', type: 'lookup', endpoint: '/api/admin/campaigns/email', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ..._.includes(['campaigns:email_clicked'], trigger) && email_campaign_id ? [
              { label: 't(Link)', name: 'email_link_id', type: 'lookup', endpoint: `/api/admin/campaigns/email/${email_campaign_id}/links`, valueKey: 'id', textKey: 'url', required: true }
            ] : [],
            ...trigger === 'crm:property_updated' && !field_id ? [
              { label: 't(Property)', name: 'field_id', type: 'lookup', endpoint: `/api/admin/crm_programs/${program_id}/fields`, valueKey: 'id', textKey: 'name.value', filter, required: true }
            ] : [],
            ..._.includes(['crm:subscription_created','crm:subscription_deleted'], trigger) && !list_id ? [
              { label: 't(List)', name: 'list_id', type: 'lookup', endpoint: '/api/admin/crm/lists', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ..._.includes(['crm:tag_added','crm:tag_removed'], trigger) && !tag_id ? [
              { label: 't(Tag)', name: 'tag_id', type: 'lookup', endpoint: '/api/admin/tags', valueKey: 'id', textKey: 'text', filter, required: true }
            ] : [],
            ...!trigger === 'events:registration_created' && event_id ? [
              { label: 't(Event)', name: 'event_id', type: 'lookup', endpoint: '/api/admin/events/events', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ...trigger === 'forms:response_created' && !form_id ? [
              { label: 't(Form)', name: 'form_id', type: 'lookup', endpoint: '/api/admin/forms/forms', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ..._.includes(['sales:deal_created','sales:deal_updated','sales:deal_transitioned'], trigger) && !formdata.pipeline_id ? [
              { label: 't(Pipeline)', name: 'pipeline_id', type: 'lookup', endpoint: '/api/admin/sales/pipelines', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ...trigger === 'sales:deal_transitioned' ? [
              { label: 't(From Stage)', name: 'from_stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${pipeline_id}/stages`, valueKey: 'id', textKey: 'title' },
              { label: 't(To Stage)', name: 'to_stage_id', type: 'dropdown', endpoint: `/api/admin/sales/pipelines/${pipeline_id}/stages`, valueKey: 'id', textKey: 'title' }
            ] : [],
            ..._.includes(['stores:order_created','stores:order_shipped'], trigger) && !store_id ? [
              { label: 't(Store)', name: 'store_id', type: 'lookup', endpoint: '/api/admin/stores/stores', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            ..._.includes(['subscriptions:subscription_created','subscriptions:renewal_success','subscriptions:renewal_failure','subscriptions:payment_method_expiring','subscriptions:payment_method_expired'], trigger) && !service_id ? [
              { label: 't(Service)', name: 'service_id', type: 'lookup', endpoint: '/api/admin/subscriptions/services', valueKey: 'id', textKey: 'title', filter, required: true }
            ] : [],
            { label: 't(Enrollment)', name: 'is_unique', type: 'radiogroup', options: [
              { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
              { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
            ], defaultValue: false }
          ]
        }
      ]
    }
  }

  _getEmailCampaignId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_campaign_id || config.email_campaign_id || null
  }

  _getAutomationEmailId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_id || config.email_id || null
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(workflow) {
    this.props.onSave(workflow)
  }

}

export default Details
