import IncomingEmails from './incoming_emails'
import Page from '@admin/components/page'
import Activities from './activities'
import Providers from './providers'
import Accounts from './accounts'
import Modules from './modules'
import Domains from './domains'
import Assets from './assets'
import Banks from './banks'
import Teams from './teams'
import Icons from './icons'
import Apps from './apps'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Platform)',
  access: { rights: { $oneOf: ['platform:access_app'] } },
  manager: {
    path: '/admin/platform',
    items: [
      {
        label: 't(Accounts)',
        path: '/accounts',
        panel: <Accounts />
      },
      {
        label: 't(Activities)',
        path: '/activities',
        panel: <Activities />
      },
      {
        label: 't(Apps)',
        path: '/apps',
        panel: <Apps />
      },
      {
        label: 't(Assets)',
        path: '/assets',
        panel: <Assets />
      },
      {
        label: 't(Banks)',
        path: '/banks',
        panel: <Banks />
      },
      {
        label: 't(Domains)',
        path: '/domains',
        panel: <Domains domain={ resources.domain } />
      },
      {
        label: 't(Icons)',
        path: '/icons',
        panel: <Icons />
      },
      {
        label: 't(Incoming Email)',
        path: '/incoming_emails',
        panel: <IncomingEmails />
      },
      {
        label: 't(Modules)',
        path: '/modules',
        panel: <Modules />
      },
      {
        label: 't(Providers)',
        path: '/providers',
        panel: <Providers />
      },
      {
        label: 't(Teams)',
        path: '/teams',
        panel: <Teams />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  team: '/api/admin/team/settings'
})

export default Page(mapResourcesToPage, mapPropsToPage)
