import SessionToken from '@apps/events/admin/tokens/session'
import Panel from '@admin/components/panel'
import NewSession from '../sessions/new'
import Edit from '../sessions/edit'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Sessions)',
  collection: {
    endpoint: `/api/admin/events/events/${props.event.id}/sessions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: SessionToken }
    ],
    sortable: false,
    defaultSort: { key: 'date', order: 'asc' },
    empty: {
      icon: 'tags',
      title: 't(Sessions)',
      text: 't(You have not yet created any sessions)',
      buttons: []
    },
    recordTasks: props.event.canceled_at === null && props.event.deleted_at === null ? (record) => [
      {
        label: 'Edit Session',
        modal: <Edit event={ props.event } session={ record } />
      }
    ] : null,
    entity: 'session'
  },
  task: props.event.canceled_at === null && props.event.deleted_at === null ? {
    icon: 'plus',
    modal: <NewSession event={ props.event } />
  } : null
})

export default Panel(null, mapPropsToPanel)
