import Form from '@admin/components/form'
import LayoutField from '../layoutfield'
import PropTypes from 'prop-types'
import React from 'react'

class CaptionLayoutField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    slide: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.slide) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { slide } = this.state
    if(!_.isEqual(slide, prevState.slide)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { slide } = this.state
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'align', type: 'dropdown', options: [
              { value: 'top', text: 't(Image Top, Caption Bottom)' },
              { value: 'left', text: 't(Image Left, Caption Right)' },
              { value: 'right', text: 't(Image Right, Caption Left)' }
            ], defaultValue: 'top', value: slide.align },
            ...slide.align !== 'top' ? [
              { name: 'layout', type: LayoutField, layouts: [[12],[6,6],[3,9],[9,3],[4,8],[8,4]], defaultValue: [6,6], value: slide.layout }
            ] : []
          ]
        }
      ]
    }
  }

  _handleChange() {
    const { slide } = this.state
    this.props.onChange(slide)
  }

  _handleSet(slide) {
    this.setState({ slide })
  }

  _handleUpdate(slide) {
    this.setState({ slide })
  }

}

export default CaptionLayoutField
