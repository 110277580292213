import ResponsiveField from '@admin/components/mjson_designer/components/responsivefield'
import BackgroundField from '@admin/components/mjson_designer/components/backgroundfield'
import ColorField from '@admin/components/mjson_designer/components/colorfield'
import React from 'react'

export const BackgroundColor = (props) => <ResponsiveField { ...props } component={ ColorField } />
export const Background = (props) => <ResponsiveField { ...props } component={ BackgroundField } />

export const BackgroundSection = (config, device, theme, type, defaults = {}) => ({
  label: 't(Background)',
  instructions: 't(Specify the box model style and layout properties for this entity)',
  collapsing: true,
  collapsed: true,
  fields: [
    { name: 'styles.background', device, theme, canHover: false, type: Background, defaultValue: defaults.background, value: config.styles.background }
  ]
})
