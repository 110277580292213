import CopyText from '@admin/components/copytext'
import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class NameserversTable extends React.Component {

  static propTypes = {
    check: PropTypes.object,
    instructions: PropTypes.string,
    last_verified_at: PropTypes.string,
    nameservers: PropTypes.array
  }

  render() {
    const { instructions, last_verified_at, nameservers } = this.props
    return (
      <div className="maha-dns-table">
        { instructions &&
          <p><T text={ instructions } /></p>
        }
        { nameservers ?
          <table className="ui unstackable compact celled table">
            <thead>
              <tr>
                <th>Name</th>
                <th className="collapsing" />
              </tr>
            </thead>
            <tbody>
              { nameservers.map((nameserver, index) => (
                <tr key={`nameserver_${index}`}>
                  <td><CopyText text={ nameserver.name } /></td>
                  <td className="center aligned"><Icon icon={ this._getIcon(nameserver.is_mapped) } /></td>
                </tr>
              )) }
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">
                  <div className="maha-dns-table-footer">
                    <div className="maha-dns-table-footer-message">
                      <T text="t(LAST CHECKED)" />: { last_verified_at ?
                        <Date datetime={ last_verified_at } /> :
                        <>never</>
                      }
                    </div>
                    <div className="maha-dns-table-footer-button">
                      <Button { ...this._getCheck() } />
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table> :
          <Loader label="t(Generating nameservers...)" />
        }
      </div>
    )
  }

  _getCheck() {
    const { check } = this.props
    return {
      label: 't(Verify)',
      color: 'blue',
      basic: true,
      fluid: false,
      size: 'mini',
      ...check
    }
  }

  _getIcon(is_mapped) {
    return is_mapped ? 'check-circle' : 'times'
  }

}

export default NameserversTable
