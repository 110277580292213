import { usePresenceContext } from '@admin/components/presence'
import PropTypes from 'prop-types'
import React from 'react'

const PresenceToken = (props) => {

  const { presence } = usePresenceContext()

  const { account_id } = props.user
  const found = _.find(presence.presence, { account_id })
  const status = found ? found.status : null

  return (
    <div className="presence-token">
      { status && 
        <div className={ `presence-token-dot ${status}` } /> 
      }
    </div>
  )

}

PresenceToken.propTypes = {
  user: PropTypes.object
}

export default PresenceToken
