import PhoneBadge from './phone'

const badges = [
  { 
    title: 't(Phone)', 
    weight: 8, 
    component: PhoneBadge,
    access: {
      rights: { $allOf: ['phone:access_app'] }
    },
    tooltip: 'Phone' 
  }
]

export default badges
