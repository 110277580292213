import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Description extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Logo Design)',
              instructions: 't(Help us determine the best look and feel for your logo design)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Your tagline / slogan / motto you want to include on the design)',
                  name: 'logo_tagline_slogan',
                  type: 'textfield',
                  defaultValue: config.logo_tagline_slogan 
                },
                { 
                  label: 't(Exact wording to be used on the logo)',
                  name: 'logo_wording',
                  type: 'textarea',
                  required: true,
                  defaultValue: config.logo_wording 
                },
                { 
                  label: 't(Does your business name / words used on your logo have a specific meaning?)',
                  name: 'logo_meaning',
                  type: 'textarea',
                  required: true,
                  defaultValue: config.logo_meaning 
                },
                { 
                  label: 't(Please provide a short description of your company / brand)',
                  name: 'logo_brand_description',
                  type: 'textarea',
                  required: true,
                  defaultValue: config.logo_brand_description 
                },
                { 
                  label: 't(What objects or images do you want to include in the design?)',
                  name: 'logo_object_images',
                  type: 'textarea',
                  defaultValue: config.logo_object_images 
                },
                { 
                  label: 't(What attributes and/or emotions do you want associated with your brand?)',
                  name: 'logo_attributes_emotions',
                  type: 'textarea',
                  defaultValue: config.logo_attributes_emotions 
                },
                { 
                  label: 't(List any competitors or similar businesses)',
                  name: 'logo_competitors',
                  type: 'textarea',
                  defaultValue: config.logo_competitors 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Description
