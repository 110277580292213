import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import Table from './table'
import React from 'react'

class Allocations extends React.PureComponent {

  static propTypes = {
    allocations: PropTypes.array,
    defaultValue: PropTypes.array,
    expense_types: PropTypes.array,
    projects: PropTypes.array,
    projectEndpoint: PropTypes.string,
    tax_total: PropTypes.number,
    total: PropTypes.number,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onRemove: PropTypes.func,
    onSet: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    allocations: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSet = this._handleSet.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    return (
      <div className="allocations">
        <Table { ...this._getTable() } />
      </div>
    )
  }

  componentDidMount() {
    const { defaultValue } = this.props
    if(defaultValue) this.props.onSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { allocations } = this.state
    if(!_.isEqual(allocations, prevState.allocations)) {
      this._handleChange()
    }
  }

  _getTable() {
    const { expense_types, projects, total } = this.props
    const { allocations } = this.state
    const sum = allocations.reduce((sum, allocation) => {
      return sum + Number(allocation.amount)
    }, 0.00)
    return {
      ...this.props,
      allocations,
      display: allocations.map((allocation) => ({
        id: allocation.id,
        expense_type: _.find(expense_types, { id: allocation.expense_type_id }),
        project: _.find(projects, { id: allocation.project_id }),
        description: allocation.description,
        amount: Number(allocation.amount),
        can_edit: allocation.can_edit,
        can_delete: allocation.can_delete
      })),
      sum,
      unassigned: total > 0 && total > sum ? total - sum : null,
      overassigned: total > 0 && sum > total ? sum - total : null,
      onAdd: this._handleAdd,
      onRemove: this._handleRemove,
      onSet: this._handleSet,
      onUpdate: this._handleUpdate
    }
  }

  _handleChange() {
    const { tax_total, total } = this.props
    const { allocations } = this.state
    this.props.onChange(allocations.map((allocation, index) => {
      const round = index === 0 ? _.ceil : _.floor
      return {
        ...allocation,
        delta: index,
        tax: tax_total ? round(tax_total * (allocation.amount / total), 2) : 0.00
      }
    }))
  }

  _handleAdd(allocation) {
    const { allocations } = this.state
    this.setState({
      allocations: [
        ...allocations,
        allocation
      ]
    })
  }

  _handleRemove(index) {
    const { allocations } = this.state
    this.setState({
      allocations: allocations.filter((allocation, i) => {
        return i !== index
      })
    })
  }

  _handleSet(allocations) {
    this.setState({ allocations })
  }

  _handleUpdate(newallocation, index) {
    const { allocations } = this.state
    this.setState({
      allocations: allocations.map((allocation, i) => ({
        ...i === index ? newallocation : allocation
      }))
    })
  }

}

const mapResources = (props, context) => ({
  projects: props.projectEndpoint || '/api/admin/finance/projects',
  expense_types: '/api/admin/finance/expense_types'
})

export default Container(mapResources)(Allocations)
