import ProgramForm from '@apps/crm/admin/components/programform'
import EmailTemplateToken from '@apps/crm/admin/tokens/template'
import Clone from '@apps/team/admin/views/templates/clone'
import New from '@apps/team/admin/views/templates/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Templates)',
  access: { rights: { $oneOf: ['team:view_templates','team:manage_templates'] } },
  collection: {
    endpoint: '/api/admin/team/templates',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Template)', key: 'title', primary: true, format: (template) => (
        <EmailTemplateToken template={ template } />
      ) },
      ...context.admin.team.has_programs ? [
        { label: 't(Program)', key: 'program.title', sort: 'program', collapsing: true }
      ] : []
    ],
    empty: {
      title: 't(No Email Templates)',
      text: 't(There are no email templates)',
      icon: 'copy',
      buttons: [
        { 
          label: 't(Create Template)',
          access: { rights: { $oneOf: ['team:manage_templates'] } },
          modal: <ProgramForm form={ New } />
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' }
    ],
    entity: 'template',
    defaultSort: { key: 'created_at', order: 'desc' },
    recordTasks: (record) => [
      { label: 't(Clone Template)', modal: <Clone template={ record } /> },
      {
        label: 't(Make Default)',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        show: !record.is_default,
        request: {
          endpoint: `/api/admin/team/templates/${record.id}/activate`,
          method: 'PATCH',
          onFailure: () => context.flash.set('error', 't(Unable to make templates default)'),
          onSuccess: () => context.flash.set('success', 't(Successfully to made templates default)')
        }
      },
      {
        label: 'Delete Template',
        access: { rights: { $oneOf: ['team:manage_templates'] } },
        confirm: 't(Are you sure you want to delete this template?)',
        request: {
          endpoint: `/api/admin/team/templates/${record.id}`,
          method: 'DELETE',
          onFailure: () => context.flash.set('error', 't(Unable to delete this template)'),
          onSuccess: () => context.flash.set('success', 't(Successfully deleted this template)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/team/templates/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Template)',
    access: { rights: { $oneOf: ['team:manage_templates'] } },
    modal: <ProgramForm form={ New } />
  }
})

export default Panel(null, mapPropsToPanel)
