import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Button from '@admin/components/button'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import React from 'react'

class Variant extends React.PureComponent {

  static propTypes = {
    variant: PropTypes.object
  }

  render() {
    const { variant } = this.props
    return (
      <div className="campaigns-social-conversations-post-overview">
        <div className="campaigns-social-conversations-post-overview-photo">
          <MJSONScreenshot { ...this._getScreenshot(variant) } />
        </div>
        <div className="campaigns-social-conversations-post-overview-details">
          <div className="campaigns-social-conversations-post-overview-details-title">
            { variant.social_campaign.title }
          </div>
          <div className="campaigns-social-conversations-post-overview-details-timestamp">
            posted on <Date datetime={ variant.social_campaign.posted_at } />
          </div>
          <Button { ...this._getProfileLink(variant.profile) } />
          { variant.status === 'posted' &&
            <> | <Button { ...this._getServiceLink(variant) } /></>
          }
        </div>
      </div>
    )
  }

  _getScreenshot(entity) {
    return {
      engine: 'mjson',
      entity: `campaigns_social_campaign_variants/${entity.code}`,
      width: 100,
      height: 75,
      screenshoted_at: entity.screenshoted_at
    }
  }

  _getProfileLink(profile) {
    return {
      label: 't(View Profile)',
      className: 'link',
      link: profile.url
    }
  }
  
  _getServiceLink(variant) {
    return {
      label: 't(View Post)',
      className: 'link',
      link: variant.url
    }
  }

}

export default Variant
