import PropTypes from 'prop-types'
import Pasteur from 'pasteur'
import React from 'react'

class Canvas extends React.PureComponent {

  static contextTypes = {
    confirm: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.string,
    darkMode: PropTypes.bool,
    editable: PropTypes.bool,
    version: PropTypes.object,
    hover: PropTypes.string,
    images: PropTypes.bool,
    theme: PropTypes.object,
    type: PropTypes.string,
    onAction: PropTypes.func,
    onHover: PropTypes.func
  }

  iframeRef = React.createRef()

  _handleRender = this._handleRender.bind(this)

  render() {
    return (
      <div className="mjson-designer-canvas">
        <iframe { ...this._getIFrame() } />
      </div>
    )
  }

  componentDidMount() {
    const { onHover } = this.props
    this.pasteur = new Pasteur({
      window,
      target: this.iframeRef.current.contentWindow,
      name: 'mDesigner',
      targetName: 'mRenderer'
    })
    this.pasteur.on('ready', this._handleRender)
    this.pasteur.on('hover', onHover)
    this.pasteur.on('add', this._handleAction.bind(this, 'add'))
    this.pasteur.on('clone', this._handleAction.bind(this, 'clone'))
    this.pasteur.on('edit', this._handleAction.bind(this, 'edit'))
    this.pasteur.on('insert', this._handleAction.bind(this, 'insert'))
    this.pasteur.on('library', this._handleAction.bind(this, 'library'))
    this.pasteur.on('move', this._handleAction.bind(this, 'move'))
    this.pasteur.on('new', this._handleAction.bind(this, 'new'))
    this.pasteur.on('remove', this._handleAction.bind(this, 'remove'))
  }

  componentDidUpdate(prevProps) {
    const { active, darkMode, version, hover, images, theme } = this.props
    if(active !== prevProps.active) {
      this._handleRender()
    }
    if(darkMode !== prevProps.darkMode) {
      this._handleRender()
    }
    if(hover !== prevProps.hover) {
      this._handleRender()
    }
    if(images !== prevProps.images) {
      this._handleRender()
    }
    if(!_.isEqual(version, prevProps.version)) {
      this._handleRender()
    }
    if(!_.isEqual(theme, prevProps.theme)) {
      this._handleRender()
    }
  }

  componentWillUnmount() {
    this.pasteur.close()
  }

  _handleAction(action, ...args) {
    this.props.onAction(action, ...args)
  }

  _getIFrame() {
    const { type } = this.props
    const renderer = type === 'email' ? 'email' : 'page'
    return {
      ref: this.iframeRef,
      src: `/renderers/mjson/${renderer}`
    }
  }

  _handleRender() {
    const { active, darkMode, editable, version, hover, images } = this.props
    const { provider } = this.context
    this.pasteur.send('update', {
      active,
      config: version.current,
      darkMode,
      domain: provider,
      editable,
      hover,
      images
    })
  }

}

export default Canvas
