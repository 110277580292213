import SocialDesigner from '@admin/components/social_designer'
import Dropdown from '@admin/components/form/dropdown'
import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class SocialVariantDesigner extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  state = {
    config: null,
    variant_id: null
  }

  _handleSave = this._handleSave.bind(this)
  _handleVariant = this._handleVariant.bind(this)

  render() {
    const { config } = this.state
    return (
      <div className="campaigns-social-variant-designer">
        <div className="campaigns-social-variant-designer-header">
          <Dropdown { ...this._getVariants() } />
        </div>
        <div className="campaigns-social-variant-designer-body">
          { config &&
            <SocialDesigner { ...this._getSocialDesigner() } />          
          }
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const { variant_id } = this.state
    if(variant_id !== prevState.variant_id) {
      this._handleFetchVersions()
    }
  }

  _getSocialDesigner() {
    const { config, variant_id } = this.state
    const { social_campaign } = this.props
    return {
      editable: social_campaign.status !== 'posted',
      key: variant_id,
      social_campaign: config,
      onSave: this._handleSave
    }
  }

  _getVariants() {
    const { social_campaign } = this.props
    return {
      options: social_campaign.variants,
      valueKey: 'id',
      textKey: 'name',
      format: ProfileToken,
      selectFirst: true,
      onChange: this._handleVariant
    }
  }

  _handleVariant(variant_id) {
    this.setState({ 
      variant_id,
      config: null
    })
  }

  _handleFetchVersions() {
    const { variant_id } = this.state
    this.context.network.request({
      endpoint: `/api/admin/campaigns_social_campaign_variants/${variant_id}/config/versions`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          versions: data
        }, this._handleFetchVersion)
      }
    })
  }

  _handleFetchVersion() {
    const { variant_id, versions } = this.state
    const version = versions[0]
    this.context.network.request({
      endpoint: `/api/admin/campaigns_social_campaign_variants/${variant_id}/config/versions/${version.id}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.setState({
          config: data.value
        })
      }
    })
  }

  _handleSave(config) {
    const { variant_id } = this.state
    this.context.network.request({
      endpoint: `/api/admin/campaigns_social_campaign_variants/${variant_id}/config/versions`,
      method: 'PATCH',
      body: {
        value: config
      },
      onSuccess: ({ data }) => {
        this.setState({ config })
      }
    })
  }
  
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <SocialVariantDesigner social_campaign={ props.social_campaign } />
})

export default Panel(null, mapPropsToPanel)
