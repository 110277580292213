import SocialPreview from '@admin/components/mjson_designer/components/social_preview'
import SegmentsField from '@admin/components/mjson_designer/components/segmentsfield'
import SEOPreview from '@admin/components/mjson_designer/components/seo_preview'
import { normalizeConfig } from '../../../utils'
import Form from '@admin/components/form'
import * as styles from '../styles'
import PropTypes from 'prop-types'
import React from 'react'

class Post extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    device: PropTypes.string,
    entity: PropTypes.object,
    parent: PropTypes.object,
    theme: PropTypes.object,
    type: PropTypes.string,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  constructor(props) {
    super(props)
    const { entity } = props
    this.state = {
      default: entity,
      config: entity
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this._handleChange()
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { device, theme, type, website } = this.props
    const { config } = this.state
    return {
      ref: this.formRef,
      onChange: this._handleUpdate,
      onSuccess: this._handleDone,
      showHeader: false,
      buttons: [
        { label: 't(Cancel)', color: 'blue', basic: true, handler: this._handleCancel },
        { label: 't(Done)', color: 'blue', handler: this._handleSubmit }
      ],
      tabs: [
        {
          label: 't(Content)',
          sections: [
            {
              fields: [
                { label: 'Sections', name: 'content.children', type: SegmentsField, entity: 'page_section', theme, defaultValue: config.content.children }
              ]
            }
          ]
        },
        {
          label: 't(Styles)',
          sections: [
            styles.BackgroundSection(config, device, theme, website, type),
            styles.BoxShadowSection(config, device, theme, website, type),
            styles.BorderSection(config, device, theme, website, type),
            styles.SpacingSection(config, device, theme, website, type),
            styles.TransformSection(config, device, theme, website, type),
            styles.AnimationSection(config, device, theme, website, type),
            styles.FiltersSection(config, device, theme, website, type),
            styles.OverflowSection(config, device, theme, website, type)
          ]
        },
        {
          label: 't(Settings)',
          sections: [
            {
              label: 'Navigation',
              instructions: 't(Specify how this page appears in the page outline)',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Label)', name: 'meta.label', type: 'textfield', defaultValue: config.meta.label }
              ]
            },
            {
              label: 'Metadata',
              collapsing: true,
              collapsed: true,
              fields: [
                { label: 't(Title)', name: 'meta.title', type: 'textfield', maxLength: 44, defaultValue: config.meta.title },
                { label: 't(Description)', name: 'meta.description', type: 'textarea', maxLength: 400, defaultValue: config.meta.description },
                { label: 't(Image)', name: 'meta.image', type: 'mediafield', theme, ratio: 1.91, defaultValue: config.meta.image }
              ]
            }, {
              label: 'Search / SEO',
              collapsing: true,
              collapsed: true,
              fields: [
                { type: 'segment', fields: [
                  { name: 'meta.seo_strategy', type: 'radiogroup', options: [
                    { value: 'general', text: 't(Use general metadata)' },
                    { value: 'custom', text: 't(Use custom metadata)' },
                    { value: 'hide', text: 't(Hide from search results)' }
                  ], defaultValue: config.meta.seo_strategy },
                  ...config.meta.seo_strategy !== 'hide' ? [
                    { type: SEOPreview, config }
                  ] : [],
                  ...this._getSeo()
                ] }
              ]
            }, {
              label: 'Social Media',
              collapsing: true,
              collapsed: true,
              fields: [
                { type: 'segment', fields: [
                  { name: 'meta.social_strategy', type: 'radiogroup', options: [
                    { value: 'general', text: 't(Use general metadata)' },
                    { value: 'custom', text: 't(Use custom metadata)' }
                  ], defaultValue: config.meta.social_strategy },
                  { type: SocialPreview, config },
                  ...this._getSocial()
                ] }
              ]
            }
          ]
        }
      ]
    }
  }

  _getSeo() {
    const { config } = this.state
    if(config.meta.seo_strategy === 'custom') {
      return [
        { label: 't(Title)', name: 'meta.seo_title', type: 'textfield', defaultValue: config.meta.seo_title },
        { label: 't(Description)', name: 'meta.seo_description', type: 'textarea', maxLength: 400, defaultValue: config.meta.seo_description }
      ]
    }
    return []
  }

  _getSocial() {
    const { theme } = this.props
    const { config } = this.state
    if(config.meta.social_strategy === 'custom') {
      return [
        { label: 't(Title)', name: 'meta.social_title', type: 'textfield', defaultValue: config.meta.social_title },
        { label: 't(Description)', name: 'meta.social_description', type: 'textarea', maxLength: 400, defaultValue: config.meta.social_description },
        { label: 't(Image)', name: 'meta.image', type: MediaField, theme, ratio: 1.91, defaultValue: config.meta.social_image }
      ]
    }
    return []
  }

  _getNormalized() {
    const { entity } = this.props
    const { config } = this.state
    return normalizeConfig({
      ...entity,
      meta: config.meta,
      settings: config.settings,
      styles: config.styles,
      content: config.content
    })
  }

  _handleCancel() {
    this.props.onDone(this.state.default)
  }

  _handleChange() {
    const config = this._getNormalized()
    this.props.onChange(config)
  }

  _handleDone() {
    const config = this._getNormalized()
    this.props.onDone(config)
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleUpdate(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

}

export default Post
