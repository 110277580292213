import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Tastes extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { 
          label: 't(Previous)',
          color: 'blue',
          basic: true,
          handler: this._handleBack 
        },
        { 
          label: 't(Next)',
          color: 'blue',
          handler: this._handleSubmit 
        }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            {
              label: 't(Logo Tastes)',
              instructions: 't(Please provide input on company logos you like and dislike.)',
              type: 'segment',
              fields: [
                { 
                  label: 't(Logos you like and why)',
                  name: 'logo_liked_logos',
                  type: 'textarea',
                  required: true,
                  defaultValue: config.logo_liked_logos 
                },
                { 
                  label: 't(Please upload images of the logos you like)',
                  name: 'logo_liked_image_ids',
                  type: 'attachmentfield',
                  allow: { content_types: ['images'] },
                  prompt: 't(Upload Image(s))',
                  multiple: true ,
                  defaultValue: config.logo_liked_image_ids 
                },
                { 
                  label: 't(Logos you dislike and why)',
                  name: 'logo_disliked_logos',
                  type: 'textarea',
                  required: true,
                  defaultValue: config.logo_disliked_logos 
                },
                { 
                  label: 't(Do\'s)',
                  name: 'logo_dos',
                  type: 'textarea',
                  defaultValue: config.logo_dos 
                },
                { 
                  label: 't(Don\'ts)',
                  name: 'logo_donts',
                  type: 'textarea',
                  defaultValue: config.logo_donts 
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    const { formdata } = this.props
    this.props.onNext({
      ...formdata,
      ...config
    })
  }

}

export default Tastes
