import Container from '@admin/components/container'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Edit from './edit'
import React from 'react'
import New from './new'

class ContactFields extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    endpoint: PropTypes.string,
    name: PropTypes.string,
    program: PropTypes.object,
    fields: PropTypes.array,
    programfields: PropTypes.array
  }

  state = {
    fields: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    const { fields } = this.state
    if(!fields) return null
    return (
      <div className="maha-fields">
        <div className="maha-fields-body">
          <div className="maha-field">
            <div className="maha-field-icon">
              <div className="maha-field-badge">
                <Icon icon="font" />
              </div>
            </div>
            <div className="maha-field-label">
              <T text="t(First Name)" /> <span>(<T text="t(textfield)" />)</span>
            </div>
          </div>
          <div className="maha-field">
            <div className="maha-field-icon">
              <div className="maha-field-badge">
                <Icon icon="font" />
              </div>
            </div>
            <div className="maha-field-label">
              <T text="t(Last Name)" /> <span>(<T text="t(textfield)" />)</span>
            </div>
          </div>
          <div className="maha-field">
            <div className="maha-field-icon">
              <div className="maha-field-badge">
                <Icon icon="envelope" />
              </div>
            </div>
            <div className="maha-field-label">
              <T text="t(Email)" /> <span>(<T text="t(emailfield)" />)</span>
            </div>
          </div>
          { fields.map((field, index) => (
            <div className="maha-field" key={`field_${index}`}>
              <div className="maha-field-icon">
                <div className="maha-field-badge">
                  <Icon icon={ this._getIcon(field) } />
                </div>
              </div>
              <div className="maha-field-label">
                { field.name.value } <span>({ this._getType(field) })</span>
              </div>
              <Button { ...this._getEditButton(field, index) } />
            </div>
          ))}
        </div>
        <div className="maha-fields-footer">
          <Button { ...this._getNewButton() } />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { fields } = this.props
    this.setState({ fields } || { fields: [] })
  }

  componentDidUpdate(prevProps, prevState) {
    const { fields } = this.state
    if(!_.isEqual(fields, prevState.fields)) {
      this._handleSave()
    }
  }

  _getAvailable() {
    const { program } = this.props
    const { fields } = this.state
    const contactfields = fields.filter(field => {
      return field.type === 'contactfield'
    }).map(field => {
      return field.contactfield.name
    })
    const available = [
      { label: 't(Contact)', fields: [
        { label: 't(Phone)', name: 'phone', type: 'phonefield' },
        { label: 't(Address)', name: 'address', type: 'addressfield' },
        { label: 't(Organization)', name: 'organization', type: 'textfield' },
        { label: 't(Job Title)', name: 'job_title', type: 'textfield' },
        { label: 't(Birthday)', name: 'birthday', type: 'textfield' },
        { label: 't(Spouse)', name: 'spouse', type: 'textfield' }
      ] },
      {
        label: program.title,
        fields: this.props.fields.map(field => ({
          code: field.code,
          label: field.label,
          name: `values.${field.code}`,
          type: field.type,
          instructions: field.instructions,
          config: field.config
        }))
      },
      { label: 'Consent', fields: [
        { label: 't(Email Consent)', name: 'consent.email', type: 'checkbox', prompt: '<p>Please send me emails</p>' },
        ..._.includes(contactfields, 'phone') ? [
          { label: 't(SMS Consent)', name: 'consent.sms', type: 'checkbox', prompt: '<p>Please send me text messages</p>' },
          { label: 't(Voice Consent)', name: 'consent.voice', type: 'checkbox', prompt: '<p>Please call me</p>' }
        ] : []
      ] }
    ]
    return available.map(group => ({
      ...group,
      fields: group.fields.filter(field => {
        return _.find(fields, {
          contactfield: {
            name: field.name
          }
        }) === undefined
      })
    })).filter(group => {
      return group.fields.length > 0
    })
  }

  _getEdit(field, index) {
    return {
      field,
      fields: this._getAvailable(),
      onBack: this._handleBack,
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getEditButton(field, index) {
    return {
      icon: 'ellipsis-h',
      className: 'maha-field-extra',
      tasks: [
        { label: 't(Edit Field)', modal: <Edit { ...this._getEdit(field, index) } /> },
        {
          label: 't(Delete Field)',
          confirm: 't(Are you sure you want to delete this field?)',
          handler: this._handleDelete.bind(this, index)
        }
      ]
    }
  }

  _getIcon(field) {
    const type = this._getType(field)
    if(type === 'textfield') return 'font'
    if(type === 'textarea') return 'bars'
    if(type === 'checkboxes') return 'check-square-o'
    if(type === 'checkboxgroup') return 'check-square-o'
    if(type === 'checkbox') return 'check-square'
    if(type === 'imagefield') return 'camera'
    if(type === 'emailfield') return 'envelope'
    if(type === 'addressfield') return 'map-marker'
    if(type === 'phonefield') return 'phone'
    if(type === 'datefield') return 'calendar'
    if(type === 'dropdown') return 'caret-down'
    if(type === 'timefield') return 'clock-o'
    if(type === 'moneyfield') return 'dollar'
    if(type === 'radiogroup') return 'circle-o'
    if(type === 'numberfield') return 'hashtag'
    if(type === 'lookup') return 'search'
  }

  _getNew() {
    return {
      fields: this._getAvailable(),
      onDone: this._handleAdd
    }
  }

  _getNewButton() {
    return {
      label: 'Add field',
      className: 'link',
      modal: <New { ...this._getNew() }/>
    }
  }

  _getType(field) {
    return field.contactfield ? field.contactfield.type: field.type
  }

  _handleAdd(field) {
    this.setState({
      fields: [
        ...this.state.fields,
        field
      ]
    })
  }

  _handleEdit(field, index) {
    this.context.modal.push()
  }

  _handleDelete(index) {
    this.setState({
      fields: this.state.fields.filter((field, i) => {
        return i !== index
      })
    })
  }

  _handleSave() {
    const { endpoint, name } = this.props
    const { fields } = this.state
    this.context.network.request({
      endpoint,
      method: 'PATCH',
      body: {
        [name]: { fields }
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to save fields)')
    })
  }

  _handleUpdate(index, newfield) {
    this.setState({
      fields: this.state.fields.map((field, i) => {
        return i === index ? newfield : field
      })
    })
  }

}

const mapResources = (props, context) => ({
  programfields: `/api/admin/crm_programs/${props.program.id}/fields`
})

export default Container(mapResources)(ContactFields)
