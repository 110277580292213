import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Enrollments)',
  collection: {
    endpoint: `/api/admin/campaigns/sms/${props.sms_campaign.id}/enrollments`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (enrollment) => <ContactToken property="phone_name" { ...enrollment.contact } /> },
      { label: 't(Created)', key: 'created_at', format: 'datetime' },
      { label: 't(Converted)', key: 'was_converted', collapsing: true, format: 'check' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }

    ],
    empty: {
      icon: 'comments',
      title: 't(No Sessions)',
      text: 't(There are not yet any sessions for this sms campaign)'
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'enrollment',
    onClick: (record) => context.router.push(`/campaigns/sms/${props.sms_campaign.id}/enrollments/${record.id}`),
    selectable: true,
    selectValue: 'id',
    recordTasks: (record) => [
      {
        label: 't(Delete Session)',
        request: {
          endpoint: `/api/admin/campaigns/sms/${props.sms_campaign.id}/enrollments/${record.id}`,
          method: 'DELETE',
          confirm: 't(Are you sure you want to delete this session?)'
        }
      }
    ],
    buttons: (selected, onSuccess) => [{
      color: 'red',
      text: 't(Delete Selected)',
      confirm: 't(Are you sure you want to delete these sessions?)',
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/campaigns/sms/${props.sms_campaign.id}/enrollments/delete`,
        body: {
          filter: selected.filter
        }
      }
    }]
  }
})

export default Panel(null, mapPropsToPanel)
