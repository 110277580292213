import { unflatten } from '@core/utils/flat'
import { useMemo } from 'react'

const adjusted = (container, field) => ({
  ...field,
  show: !(container.show === false || field.show === false),
  include: !(container.include === false || field.include === false),
  errorIndex: container.errorIndex
})

const reduce = (container) => container.fields.reduce((fields, field) => [
  ...fields,
  ...(_.includes(['fields','segment'], field.type)) ? reduce(adjusted(container, field)) : [adjusted(container, field)]
], [])

const merged = (sections, tabs) => {
  if(sections) return sections.map((section, sectionIndex) => ({
    ...section,
    errorIndex: `0-${sectionIndex}`
  }))
  if(tabs) return tabs.reduce((sections, tab, tabIndex) => [
    ...sections,
    ...tab.sections.map((section, sectionIndex) => ({
      ...section,
      errorIndex: `${tabIndex}-${sectionIndex}`,
    }))
  ], [])
  return []
}

export const fields = (state, props) => {
  return useMemo(() => {
    return merged(props.sections, props.tabs).reduce((fields, section) => [
      ...fields,
      ...reduce(section)
    ], []).map((field, index) => ({
      ...field,
      tabIndex: index + 1
    }))
  }, [props.sections, props.tabs])
}

export const highlights = (state, props) => {
  return useMemo(() => {
    const keys = Object.keys(state.errors)
    return state.fields.reduce((tabs, field) => {
      if(!_.includes(keys, field.name)) return tabs
      return _.uniq([
        ...tabs,
        field.errorIndex
      ])
    }, [])
  }, [state.fields, state.errors])
}

export const submittable = (state, props) => {
  return useMemo(() => {
    return state.fields.filter(field => {
      return field.name && field.include !== false && field.show !== false && !_.includes(['fields','segment','text'], field.type)
    })
  }, [state.fields])
}

export const fieldNames = (state, props) => {
  return useMemo(() => {
    return state.submittable.filter(field => {
      return field.name !== undefined
    }).map(field => field.name)
  }, [state.submittable])
}

export const defaults = (state, props) => {
  return useMemo(() => {
    return state.fields.filter(field => {
      return !_.includes(['fields','segment'], field.type)
    }).reduce((defaults, field) => ({
      ...defaults,
      [field.name]: field.value !== undefined ? field.value : field.defaultValue !== undefined ? field.defaultValue : null
    }), {})
  }, [state.fields])
}

export const filtered = (state, props) => {
  return useMemo(() => {
    return unflatten(state.submittable.reduce((entity, field) => {
      const value = _.get(state.data, field.name)
      return {
        ...entity,
        ...!_.isNil(value) ? {
          [field.name]: value
        } : {}
      }
    }, {}))
  }, [state.submittable, state.data])
}

export const isReady = (state, props) => {
  return useMemo(() => {
    return state.submittable.find(field => {
      return field.name && !_.includes(state.ready, field.name)
    }) === undefined
  }, [state.submittable, state.ready])
}

export const isBusy = (state, props) => {
  return useMemo(() => {
    return state.busy.length > 0
  }, [state.busy])
}

export const isConfiguring = (state, props) => {
  return useMemo(() => {
    return _.includes(['pending','loading'], state.status)
  }, [state.status])
}

export const isValid = (state, props) => {
  return useMemo(() => {
    return state.status === 'validating' && state.submittable.reduce((isValid, field) => {
      if(!isValid) return false
      return _.includes(state.validated, field.name) && !_.includes(Object.keys(state.errors), field.name)
    }, true)    
  }, [state.submittable, state.validated, state.errors, state.status])
}
