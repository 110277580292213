import Button from '@admin/components/button'
import T from '@admin/components/t'
import ChartJs from 'chart.js/auto'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Demographics extends React.Component {

  static propTypes = {
    breakdowns: PropTypes.array,
    label: PropTypes.string,
    total: PropTypes.number
  }

  canvasRef = React.createRef()
  chart = null

  state = {
    selected: 0
  }

  render() {
    const { breakdowns, label } = this.props
    const { selected } = this.state
    return (
      <div className="crm-report-section">
        <div className="crm-report-section-navigation">
          <div className="crm-report-section-title">
            <strong><T text={ label } /></strong>
          </div>
          <div className="crm-report-section-menu">
            <ul>
              { breakdowns.map((breakdown, index) => (
                <li key={`breakdown_${index}`}>
                  <Button { ...this._getBreakdown(breakdown, index) } />
                </li>
              )) }
            </ul>
          </div>
        </div>
        <div className="crm-report-section-chart-header">
          <strong><T text={ breakdowns[selected].title } /></strong><br />
          <T text={ breakdowns[selected].description } />
        </div>
        <div className="crm-report-breakdown">
          <div className="crm-report-breakdown-table">
            <table className="ui unstackable table">
              <tbody>
                { breakdowns[selected].data.map((line, index) => (
                  <tr key={`line_${index}`}>
                    <td>
                      <div className="crm-report-breakdown-color" />
                      <T text={ line.label } />
                    </td>
                    <td className="right aligned">
                      { line.value } <span className="percentage">{ numeral(line.value / breakdowns[selected].total).format('0.0%') }</span>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table>
          </div>
          <div className="crm-report-breakdown-chart">
            <div className="crm-report-breakdown-chart-canvas">
              <canvas ref={ this.canvasRef } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handlePlot()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    if(selected !== prevState.selected) {
      this._handlePlot()
    }
  }

  _getBreakdown(breakdown, index) {
    const { selected } = this.state
    const classes = ['link']
    if(selected === index) classes.push('selected')
    return {
      label: breakdown.label,
      className: classes.join(' '),
      handler: this._handleSelect.bind(this, index)
    }
  }

  _handleInit() {
    this.chart = new ChartJs(this.canvasRef.current.getContext('2d'), {
      type: 'doughnut',
      options: {
        maintainAspectRatio: true,
        aspectRatio: 1,
        responsive: true,
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            display: false
          }  
        }
      }
    })
  }

  _handlePlot() {
    const { breakdowns, label } = this.props
    const { selected } = this.state
    if(!this.chart) this._handleInit()
    this.chart.data.labels = breakdowns[selected].data.map(point => point.label)
    this.chart.data.datasets = [{
      label,
      data: breakdowns[selected].data.map(point => point.value),
      backgroundColor: ['#DB2828','#F2711C','#FBBD08','#21BA45','#2185D0','#A333C8','#6435C9']
    }]
    this.chart.update()
  }

  _handleSelect(selected) {
    this.setState({ selected })
  }

}

export default Demographics
