import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Configure extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Hold Music)', type: 'segment', fields: [
              { name: 'hold_strategy', type: 'radiogroup', options: [
                { value: 'stock', text: 't(Use stock hold music)' },
                { value: 'custom', text: 't(Use custom hold music)' }
              ], defaultValue: 'stock' },
              ...config.hold_strategy === 'stock' ? [
                { name: 'stock_hold_music', type: 'lookup', options: [
                  { value: 'busystrings', text: 'Busy Strings' },
                  { value: 'clockworkwaltz', text: 'Clock Work Waltz' },
                  { value: 'flightofyounghearts', text: 'Flight of Young Hearts' },
                  { value: 'chopin', text: 'Chopin' },
                  { value: 'endlessgoodbye', text: 'Endless Goodbye' }
                ], defaultValue: 'busystrings' }
              ] : [
                { name: 'custom_hold_music_id', type: 'attachmentfield', prompt: 't(Choose Audio File)', allow: { content_types: 'audio' } }
              ]
            ] }
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(config) {
    this.props.onSave(config)
  }

}

export default Configure
