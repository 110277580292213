import TicketTypeToken from '@apps/events/admin/tokens/ticket_type'
import { DragSource, DropTarget } from 'react-dnd'
import Handle from '@admin/components/handle'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class TicketType extends React.PureComponent {

  static propTypes = {
    connectDropTarget: PropTypes.func,
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    index: PropTypes.number,
    isDragging: PropTypes.bool,
    ticket_type: PropTypes.object,
    onActivate: PropTypes.func,
    onEdit: PropTypes.func,
    onMove: PropTypes.func,
    onRemove: PropTypes.func
  }

  static defaultProps = {
    isDragging: false
  }

  render() {
    const { connectDropTarget, connectDragPreview, connectDragSource, ticket_type } = this.props
    return connectDropTarget(connectDragPreview(
      <div className={ this._getClass() }>
        { connectDragSource(
          <div className="tickettypesfield-tickettype-handle">
            <Handle />
          </div>
        ) }
        <div className="tickettypesfield-tickettype-token">
          <TicketTypeToken { ...ticket_type } />
        </div>
        <Button { ...this._getEdit() } />
        { ticket_type.is_active ?
          <Button { ...this._getRemove() } /> :
          <Button { ...this._getActivate() } />
        }
      </div>
    ))
  }

  _getActivate() {
    return {
      icon: 'refresh',
      className: 'tickettypesfield-tickettype-action',
      handler: this.props.onActivate
    }
  }

  _getClass() {
    const { isDragging } = this.props
    const classes = ['tickettypesfield-tickettype']
    if(isDragging) classes.push('dragging')
    return classes.join(' ')
  }

  _getEdit() {
    return {
      icon: 'pencil',
      className: 'tickettypesfield-tickettype-action',
      handler: this.props.onEdit
    }
  }

  _getRemove() {
    return {
      icon: 'times',
      className: 'tickettypesfield-tickettype-action',
      handler: this.props.onRemove
    }
  }

}

const source = {
  beginDrag: (props) => ({
    index: props.index,
    onMove: props.onMove
  })
}

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) return
    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  }
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

const targetCollector = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
})

TicketType = DragSource('ITEM', source, sourceCollector)(TicketType)
TicketType = DropTarget('ITEM', target, targetCollector)(TicketType)

export default TicketType
