import Page from '@admin/components/page'
import Overview from './overview'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.content.title,
  manager: {
    path: `/admin/truevail/admin/content/${resources.content.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview content={ resources.content } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design content={ resources.content }  />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  content: `/api/admin/truevail/admin/content/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
