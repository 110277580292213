import Format from '@admin/components/format'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Item extends React.Component {

  static contextTypes = {
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    component: PropTypes.any,
    color: PropTypes.string,
    content: PropTypes.any,
    empty: PropTypes.object,
    extra: PropTypes.any,
    format: PropTypes.any,
    handler: PropTypes.func,
    icon: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.string,
    padded: PropTypes.bool,
    show: PropTypes.bool,
    route: PropTypes.string,
    tasks: PropTypes.array,
    units: PropTypes.string
  }

  static defaultProps = {
    empty: <span className="disabled">NO VALUE</span>
  }

  _handleClick = this._handleClick.bind(this)
  _handleTasks = this._handleTasks.bind(this)

  render() {
    const { alt, component, content, empty, extra, format, handler, icon, label, link, route, show, tasks, units } = this.props
    if(show === false) return null
    return (
      <div className={ this._getItemClass() } onClick={ this._handleClick }>
        { icon &&
          <div className="maha-details-item-icon">
            <Icon icon={ icon } />
          </div>
        }
        { component &&
          <div className="maha-details-item-component">
            { this._getComponent(component) }
          </div>
        }
        { !component &&
          <div className="maha-details-item-content">
            { label &&
              <div className="maha-details-item-content-label">
                <span><T text={ label } /></span>
              </div>
            }
            <div className="maha-details-item-content-value">
              { !_.isNil(content) && <Format { ...content } format={ format } value={ content } /> }
              { (!_.isNil(content) && units) && ` ${units}` }
              { (_.isNil(content) && alt) &&
                <span>
                  <T text={ alt } />
                </span>
              }
              { (_.isNil(content) && empty) &&
                <span className="maha-details-item-content-empty">
                  <T text={ empty } />
                </span>
              }
            </div>
          </div>
        }
        { extra &&
          <div className="maha-details-item-extra">
            { this._getComponent(extra) }
          </div>
        }
        { tasks &&
          <div className="maha-details-item-proceed" onClick={ this._handleTasks }>
            <Icon icon="ellipsis-v" />
          </div>
        }
        { (handler || link || route) &&
          <div className="maha-details-item-proceed">
            <Icon icon="chevron-right" />
          </div>
        }
      </div>
    )
  }

  _getComponent(component) {
    return _.isFunction(component) || !!component.WrappedComponent ? React.createElement(component) : component
  }

  _getItemClass() {
    const { className, color, content, handler, link, padded, route } = this.props
    const classes = ['maha-details-item']
    if(content && padded === false) classes.push('unpadded')
    if(padded === true) classes.push('padded')
    if(color) classes.push(color)
    if(className) classes.push(className)
    if(link || handler || route) classes.push('maha-details-item-link')
    return classes.join(' ')
  }

  _handleClick() {
    const { link, handler, route } = this.props
    if(link) this.context.router.push(link)
    if(route) this.context.router.push(route)
    if(handler) handler()
  }

  _handleTasks(e) {
    const { tasks } = this.props
    e.stopPropagation()
    this.context.tasks.open({
      items: tasks
    })
  }

}

export default Item
