import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ property }, { admin }) => {

  const program = {
    label: property.parent.title,
    className: 'link',
    route: `/admin/team/programs/${property.parent.id}`
  }

  const details = {
    audits: `maha_fields/${property.id}`,
    comments: `maha_fields/${property.id}`,
    sections: [
      {
        title: 't(Property Details)',
        items: [
          { label: 't(Name)', content: property.name.value },
          { label: 't(Program)', show: admin.team.has_programs , content: <Button { ...program } /> }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object
}

Overview.propTypes = {
  property: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview property={ props.property } />
})

export default Panel(null, mapPropsToPanel)
