export const onAddFolder = (folder) => ({
  type: 'ADD_FOLDER',
  folder
})

export const onAddSelected = (item) => ({
  type: 'ADD_SELECTED',
  item
})

export const onChangeFolder = (folder) => ({
  type: 'CHANGE_FOLDER',
  folder
})

export const onClearSelected = (item) => ({
  type: 'CLEAR_SELECTED'
})

export const onPreview = (item) => ({
  type: 'PREVIEW',
  item
})

export const onReady = () => ({
  type: 'READY'
})

export const onReplaceSelected = (item) => ({
  type: 'REPLACE_SELECTED',
  item
})

export const onSetExplorer = (info, view) => ({
  type: 'SET_EXPLORER',
  info,
  view
})

export const onSetQuery = (q) => ({
  type: 'SET_QUERY',
  q
})

export const onShowDetails = (show) => ({
  type: 'SHOW_DETAILS',
  show
})

export const onUp = () => ({
  type: 'UP'
})
