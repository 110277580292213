import FlowchartDesigner from '@admin/components/flowchart_designer'
import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

class SMSDesigner extends React.PureComponent {

  static propTypes = {
    sms_campaign: PropTypes.object,
    programfields: PropTypes.array
  }

  render() {
    return <FlowchartDesigner { ...this._getFlowchartDesigner() } />
  }

  _getFlowchartDesigner() {
    const { sms_campaign } = this.props
    const { steps, status } = sms_campaign
    return {
      campaign: sms_campaign,
      editable: _.includes(['active','draft','inactive'], sms_campaign.status),
      endpoint: `/campaigns_sms_campaigns/${sms_campaign.id}/config`,
      fields: this._getFields(),
      program: sms_campaign.program,
      properties: this._getProperties(),
      tokens: this._getTokens(),
      blocks: [
        this._getTrigger(),
        { action: 'message' },
        { action: 'question' },
        { action: 'ifthen' },
        { action: 'wait' },
        { action: 'goal' },
        { action: 'set' },
        { action: 'property' },
        { action: 'consent' },
        { action: 'list' },
        { action: 'workflow' },
        { action: 'email' },
        { action: 'sms' },
        { action: 'internal_email' },
        { action: 'internal_sms' },
        { action: 'timeofday' },
        {
          icon: 'phone',
          label: 'End Conversation',
          type: 'ending',
          action: 'ending'
        }
      ],
      defaultValue: steps,
      status
    }
  }

  _getFields() {
    const { programfields, sms_campaign } = this.props
    if(!programfields) return []
    return [
      ...programfields.length > 0 ? [
        { 
          label: sms_campaign.program.title, 
          fields: programfields.map(field => ({
            name: field.name.value,
            key: `contact.values.${field.code}`,
            type: field.type
          }))
        }
      ] : []
    ]
  }

  _getProperties() {
    const { programfields, sms_campaign } = this.props
    return [
      ...programfields.length > 0 ? [
        { 
          label: sms_campaign.program.title, 
          fields: programfields.map(field => ({
            ...field,
            name: `values.${field.code}`
          }))
        }
      ] : []
    ]
  }

  _getTokens() {
    const { programfields, sms_campaign } = this.props
    return [
      ...programfields.length > 0 ? [
        { 
          title: sms_campaign.program.title, 
          tokens: programfields.map(field => ({
            name: field.name.value,
            token: `program.${field.name.token}`
          }))
        }
      ] : [],
      { title: 't(Session)', tokens: [
        { name: 'From Number', token: 'session.from_number' },
        { name: 'To Number', token: 'session.to_number' }
      ] },
      ...sms_campaign.event ? this._getRegistrationTokens(sms_campaign.event) : [],
      ...sms_campaign.store ? this._getOrderTokens(sms_campaign.store) : []
    ]
  }

  _getTrigger() {
    const { sms_campaign } = this.props
    if(sms_campaign.direction === 'inbound') {
      return {
        icon: 'comment',
        label: 'Incoming SMS',
        type: 'trigger',
        action: 'trigger',
        token: () => (
          <div>
            <div>{ sms_campaign.phone_number.formatted}</div>
            <div>&quot;{ sms_campaign.term }&quot;</div>
          </div>
        )
      }
    } else {
      return {
        icon: 'comment',
        label: 'Outbound SMS',
        type: 'trigger',
        action: 'trigger',
        token: () => (
          <div>
            <div>{ sms_campaign.phone_number.formatted}</div>
          </div>
        )
      }
    }
  }

  _getPaymentTokens(model) {
    return {
      title: 't(Payment Tokens)',
      tokens: [
        { name: 'Method', token: `${model}.payment_method` },
        { name: 'Amount', token: `${model}.payment_amount` },
        { name: 'Card', token: `${model}.payment_card` },
        { name: 'Summary', token: `${model}.payment_summary` }
      ]
    }
  }

  _getRegistrationTokens(event) {
    return [
      {
        title: 't(Registration Tokens)',
        tokens: [
          { name: 'First Name', token: 'registration.first_name' },
          { name: 'Last Name', token: 'registration.last_name' },
          { name: 'Email', token: 'registration.email' },
          ...event.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `registration.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('registration')
    ]
  }

  _getOrderTokens(store) {
    return [
      {
        title: 't(Order Tokens)',
        tokens: [
          { name: 'Order ID', token: 'order.id' },
          { name: 'First Name', token: 'order.first_name' },
          { name: 'Last Name', token: 'order.last_name' },
          { name: 'Email', token: 'order.email' },
          ...store.contact_config.fields.filter(field => {
            return !_.includes(['text'], field.type)
          }).map(field => ({
            name: field.name.value,
            token: `order.${field.name.token}`
          }))
        ]
      },
      this._getPaymentTokens('order')
    ]
  }

}

const mapResources = (props, context) => ({
  programfields: `/api/admin/team/programs/${props.sms_campaign.program.id}/fields`
})

export default Container(mapResources)(SMSDesigner)
