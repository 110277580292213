import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const styles = [
  { label: 'Uppercase', icon: 'font', code: 'uppercase' },
  { label: 'Italic', icon: 'italic', code: 'italic' },
  { label: 'Underline', icon: 'underline', code: 'underline' },
  { label: 'Strikethrough', icon: 'strikethrough', code: 'strikethrough' }
]

class FormatField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    selected: []
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })

  render() {
    return (
      <div className="formatfield">
        { styles.map((style, index) => (
          <div key={`style_${index}`} {...this._getStyle(style)}>
            <Icon icon={`${style.icon}`} />
          </div>
        ))}
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state
    const { value } = this.props
    if(!_.isEqual(selected, prevState.selected)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getStyle(style) {
    return {
      className: this._getClass(style),
      'data-tooltip': style.label,
      'data-position': 'top center',
      'data-inverted': '',
      onClick: this._handleUpdate.bind(this, style.code)
    }
  }

  _getClass(style) {
    const { selected } = this.state
    const classes = ['formatfield-option']
    if(_.includes(selected, style.code)) classes.push('selected')
    return classes.join(' ')
  }

  _handleChange() {
    const { selected } = this.state
    this.props.onChange(selected)
  }

  _handleSet(selected) {
    this.setState({
      selected: selected || []
    })
  }

  _handleUpdate(code) {
    const { selected } = this.state
    this.setState({
      selected: _.xor(selected, [code])
    })
  }

}

export default FormatField
