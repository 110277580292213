import PaymentToken from '@apps/events/admin/tokens/payment'
import Export from '@apps/finance/admin/components/export'
import OrderToken from '@apps/stores/admin/tokens/order'
import Panel from '@admin/components/panel'
import React from 'react'

const getField = (field) => {
  const type = field.type === 'contactfield' ? field.contactfield.type : field.type
  if(type === 'addressfield') {
    return [
      { label: `${field.name.value} - Full Address`, key: `data.${field.code}.description`, visible: false },
      { label: `${field.name.value} - Street 1`, key: `data.${field.code}.street_1`, visible: false },
      { label: `${field.name.value} - Street 2`, key: `data.${field.code}.street_2`, visible: false },
      { label: `${field.name.value} - City`, key: `data.${field.code}.city`, visible: false },
      { label: `${field.name.value} - State/Province`, key: `data.${field.code}.state_province`, visible: false },
      { label: `${field.name.value} - Postal Code`, key: `data.${field.code}.postal_code`, visible: false },
      { label: `${field.name.value} - County`, key: `data.${field.code}.county`, visible: false },
      { label: `${field.name.value} - Latitude`, key: `data.${field.code}.latitude`, visible: false },
      { label: `${field.name.value} - Longitude`, key: `data.${field.code}.longitude`, visible: false }
    ]
  }
  return [
    { label: field.name.value, key: `data.${field.code}`, visible: false }
  ]
}

const getColumn = (field) => {
  const type = field.type === 'contactfield' ? field.contactfield.type : field.type
  if(type === 'addressfield') {
    return [
      { label: field.name.value, key: `data.${field.code}.description`, sort: field.code, visible: false }
    ]
  }
  return [
    { label: field.name.value, key: `data.${field.code}`, sort: field.code, visible: false }
  ]
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Orders)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/orders`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: OrderToken },
      { label: 't(Email)', key: 'data.email', sort: 'email', visible: false },
      ...props.store.contact_config.fields.filter(field => {
        return field.type !== 'text'
      }).reduce((fields, field) => [
        ...fields,
        ...getColumn(field)
      ], []),
      { label: 'Ordered', key: 'created_at', format: 'datetime' },
      { label: 'Items', key: 'items_count', collapsing: true, align: 'right' },
      { label: 't(Revenue)', key: 'revenue', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'is_paid', collapsing: true, format: PaymentToken }
    ],
    empty: {
      icon: 'shopping-bag',
      title: 't(No orders)',
      text: 't(There are no orders for this store)'
    },
    criteria: {
      fields: [
        {
          label: 'Order',
          fields: [
            {  name: 'Product', key: 'product_id',type: 'select', endpoint: `/api/admin/stores/stores/${props.store.id}/products`, valueKey: 'id', textKey: 'title' },
            {  name: 'Category', key: 'category_id',type: 'select', endpoint: `/api/admin/stores/stores/${props.store.id}/categories`, valueKey: 'id', textKey: 'title' }
          ],
          virtuals: props.store.contact_config.fields
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'data.first_name' },
      { label: 't(Last Name)', key: 'data.last_name' },
      { label: 't(Email)', key: 'data.email' },
      ...props.store.contact_config.fields.filter(field => {
        return field.type !== 'text'
      }).reduce((fields, field) => [
        ...fields,
        ...getField(field)
      ], []),
      ...props.products.reduce((variants, product) => [
        ...variants,
        ...product.variants.reduce((variants, variant) => [
          ...variants,
          { label: `${product.title}`, key: `variant_totals[${variant.id}]` }
        ], [])
      ], []),
      { label: 'Order Date', key: 'created_at' },
      { label: 't(Payment Method)', key: 'payment.method' },
      { label: 'Payment Reference', key: 'payment.reference' },
      { label: 'Payment Amount', key: 'payment.amount' },
      { label: 'Payment Fee', key: 'payment.fee' },
      { label: 'Payment Disbursed', key: 'payment.disbursed' },
      { label: 'Payment Date', key: 'payment.date' }
    ],
    collectionActions: [
      { icon: 'table', tooltip: 'Download Invoices', handler: ({ filter }) => {
        context.modal.open(<Export endpoint={`/api/admin/stores/stores/${props.store.id}/invoices`} filter={ filter } />)
      }  }
    ],
    entity: 'order',
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/stores/stores/${props.store.id}/orders/${record.id}`)
  }
})

const mapResourcesToPanel = (props, context) => ({
  products: `/api/admin/stores/stores/${props.store_id}/products`,
  store: `/api/admin/stores/stores/${props.store_id}`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
