import NameserversTable from '@admin/components/dnstable/nameservers'
import ContactToken from '@apps/domains/admin/tokens/contact'
import SensitiveText from '@admin/components/sensitive_text'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ domain }) => {

  const registration = {
    label: 't(register or transfer this domain name)',
    className: 'link'
  }

  const dns = {
    label: 't(enable DNS for this domain)',
    className: 'link',
    request: {
      endpoint: `/api/admin/domains/domains/${domain.id}/dns/enable`,
      method: 'PATCH',
      onFailure: () => context.flash.set('error', 't(Unable to enable DNS for this domain)')
    }
  }

  const nameserverstable = {
    instructions: 't(In order to resolve queries, you must set your domain to use the following nameservers)',
    nameservers: domain.nameservers,
    last_verified_at: domain.last_verified_at,
    check: {
      request: {
        endpoint: `/api/admin/domains/domains/${domain.id}/dns/verify`,
        method: 'PATCH',
        onFailure: () => context.flash.set('error', 't(Unable to verify nameservers)')
      }
    }
  }

  const details = {
    audits: `domains_domains/${domain.id}`,
    comments: `domains_domains/${domain.id}`,
    sections: [
      {
        title: 't(Domain Details)',
        items: [
          { label: 't(Name)', content: domain.name }
        ]
      },
      {
        subtitle: 't(Registration)',
        items: domain.registration_status !== 'unused' ? [
          { label: 't(Expires)', content: domain.expires_on, format: 'date' },
          { label: 't(Auto Renew)', content: domain.auto_renew ? 'enabled' : 'disabled', format: 'status' },
          { label: 't(Transfer Lock)', content: domain.is_locked ? 'enabled' : 'disabled', format: 'status' },
          { label: 't(Registrant Contact)', content: <ContactToken contact={ domain.registrant_contact } /> },
          { label: 't(Admin Contact)', content: <ContactToken contact={ domain.admin_contact } /> },
          { label: 't(Tech Contact)', content: <ContactToken contact={ domain.tech_contact } /> },
          ...domain.registration_status === 'active' && domain.auth_code ? [
            { label: 'Auth Code', content: <SensitiveText text={ domain.auth_code } /> }
          ] : []
        ] : [
          { label: 't(Status)', content: (
            <p><span className="alert">DISABLED</span> (<Button { ...registration } />)</p>
          ) }
        ]
      },
      {
        subtitle: 't(DNS)',
        items: domain.dns_status !== 'unused' ? [
          { padded: true, component: <NameserversTable { ...nameserverstable } /> }
        ] : [
          { label: 't(Status)', content: (
            <p><span className="alert">DISABLED</span> (<Button { ...dns } />)</p>
          ) }
        ]
      }
    ]
  }

  if(domain.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This domain was deleted)' }
  } else if(domain.nameserver_status === 'pending') {
    details.alert = { color: 'teal', message: 't(The nameservers have not yet been set for this domain. Before we can resolve DNS queries, you must set the nameservers according to the table below)' }
  } else if(domain.nameserver_status === 'unverified') {
    details.alert = { color: 'red', message: 't(Something has changed with your DNS settings and the nameserver below are no longer mapped appropriately for this domain)' }
  } else if(domain.nameserver_status === 'verified') {
    details.alert = { color: 'green', message: 't(The nameservers are set properly and actively resolving queries for this domain)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  domain: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview domain={ props.domain } />,
  tasks: !props.domain.deleted_at ? {
    items: [
      {
        label: 't(Delete Domain)',
        access: { rights: { $oneOf: ['domains:manage_domains'] } },
        confirm: 't(Are you sure you want to delete this domain? It will also delete all records on file.)',
        request: {
          endpoint: `/api/admin/domains/domains/${props.domain.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.router.goBack()
            context.flash.set('success', 't(Successfully deleted this domain)')
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete this domain)')
        }
      }
    ]
  } : null
})

export default Panel(null, mapPropsToPanel)
