import { useMahaContext } from '@admin/components/maha'
import { createLogger } from '@core/utils/console'
import qs from 'qs'

const logAction = createLogger('green')

const getQuery = (params) => {
  if(!params.query && !params.filter) return null
  const query = params.query || {}
  if(params.filter) query.$filter = params.filter
  if(params.sort) query.$sort = params.sort
  return query
}

const getHeaders = (params, maha) => {
  const token = params.token || maha.token
  return {
    ...token ? {
      'Authorization': `Bearer ${token}`
    } : {},
    ...maha.fingerprint ? {
      'Fingerprint': maha.fingerprint
    } : {},
    ...maha.timezone ? {
      'Timezone': maha.timezone
    } : {},
    'Content-Type': 'application/json',
    ...params.headers || {}
  }
}

const useRequest = () => {

  const { maha } = useMahaContext()

  const onRequest = async (params) => {

    const { body, endpoint, onRequest, onFailure, onSuccess } = params

    const abortController = new AbortController()

    const method = params.method ? params.method.toUpperCase() : 'GET'

    const headers = getHeaders(params, maha)

    const query = getQuery(params)

    const url = query ? `${endpoint}?${qs.stringify(query)}` : endpoint

    const request = {
      endpoint,
      method,
      headers,
      ...query ? { query } : {},
      ...body ? { body } : {}
    }

    logAction('fetch/REQUEST_REQUEST', {
      request
    })

    if(onRequest) onRequest(request)

    try {

      const result = await fetch(url, {
        method,
        headers,
        ...body ? {
          body: JSON.stringify(body)
        } : {},
        signal: abortController.signal
      })

      const response = await result.json()

      if(result.ok) {

        logAction('fetch/REQUEST_SUCCESS', {
          request,
          response
        })

        if(onSuccess) onSuccess(response)

        return {
          error: null,
          status: 'success',
          value: response.data
        }

      } else {

        logAction('fetch/REQUEST_FAILURE', {
          request,
          response
        })

        if(onFailure) onFailure(response)

        return {
          error: response.message,
          status: 'failure',
          value: null
        }
        
      }

    } catch(error) {

      logAction('fetch/REQUEST_ERROR', {
        request,
        error: {
          message: error.message
        }
      })

      if(onFailure) onFailure({
        status: 500,
        message: error.message
      })

      return {
        error: error.message,
        status: 'failure',
        value: null
      }

    }

  }

  return onRequest

}

export default useRequest