import React, { useRef } from 'react'
import PropTypes from 'prop-types'

const Input = React.forwardRef((props, ref) => {

  const { onChange, onEnter, onKeyDown, ...additionalProps } = props

  const inputRef = ref || useRef()

  const handleChange = (e) => {
    if(onChange) onChange(e.target.value, e)
  }

  const handleKeyDown = (e) => {
    if(onKeyDown) onKeyDown(e)
    if(e.keyCode !== 13) return
    if(!onEnter) return
    e.preventDefault()
    onEnter()
  }

  const mergedProps = {
    ...additionalProps,
    ref: inputRef,
    onChange: handleChange,
    onKeyDown: handleKeyDown
  }

  return <input { ...mergedProps } />

})

Input.propTypes = {
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onKeyDown: PropTypes.func
}

export default Input