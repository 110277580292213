import ProgramToken from '@apps/crm/admin/tokens/program'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Upload extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact: PropTypes.object,
    deal: PropTypes.object,
    program: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact, deal, program } = this.props
    return {
      title: 't(Upoad Agreement)',
      method: 'POST',
      action: '/api/admin/crm/agreements',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      saveText: 'Send',
      sections: [
        {
          fields: [
            { name: 'contact_id', type: 'hidden', value: contact.id },
            ...deal ? [
              { name: 'deal_id', type: 'hidden', value: deal.id }
            ] : [],
            ...program ? [
              { name: 'program_id', type: 'hidden', value: program.id }
            ] : [
              { label: 't(Program)', name: 'program_id', type: 'lookup', endpoint: '/api/admin/programs', valueKey: 'id', textKey: 'title', required: true, format: ProgramToken }
            ],
            { label: 't(Signed Agreement)', name: 'signed_id', type: 'attachmentfield', multiple: false, prompt: 't(Choose File)', required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agreement) {
    this.context.router.push(`/admin/crm/agreements/${agreement.id}`)
    this.context.modal.close()
  }

}

export default Upload
