import Personalization from './personalization'
import Additional from './additional'
import Identity from './identity'
import Website from './website'
import Social from './social'
import Email from './email'
import Brand from './brand'
import Logo from './logo'

const tasks = [
  { 
    title: 't(Business Information)', 
    code: 'identity',
    description: 't(Tell us about your business and how you can be found)', 
    modal: Identity,
    estimate: '5 mins'
  },
  { 
    title: 't(Logo)',
    code: 'logo',
    description: 't(Tell us about your logo or ask us to design one for you)',  
    modal: Logo,
    estimate: '1 - 10 mins'
  },
  { 
    title: 't(Brand)',
    code: 'brand',
    description: 't(Tell us about your branding, so we can brand your content)',
    modal: Brand,
    estimate: '2 - 5 mins'
  },
  { 
    title: 't(Personalization)', 
    code: 'personalization',
    description: 't(Tell us your preferences, so we can personalize your content)',
    modal: Personalization,
    estimate: '2 - 5 mins'
  },
  { 
    title: 't(Email Newsletters)', 
    code: 'email',
    description: 't(Select newsletter service preferences. Setup your mailing list and configure your sending frequency)',
    modal: Email,
    estimate: '4 - 8 mins'
  },
  { 
    title: 't(Social Media)', 
    code: 'social',
    description: 't(Select social media service preferences. Connect your social media accounts and configure your posting frequency)',
    modal: Social,
    estimate: '4 - 8 mins'
  },
  { 
    title: 't(Website)', 
    code: 'website',
    description: 't(Tell us about your website or view website design purchasing options)',
    modal: Website,
    estimate: '1 - 30 mins'
  },
  { 
    title: 't(Additional Information)', 
    code: 'additional',
    description: 't(Provide any additional information you\'d like us to know)',
    modal: Additional,
    estimate: '2 mins'
  }
]

export default tasks