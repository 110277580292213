import RegistrationsShow from './events/registrations/show'
import DemographicsList from './demographics/list'
import TicketShow from './events/tickets/show'
import OrganizerList from './organizers/list'
import LocationList from './locations/list'
import EventList from './events/list'
import EventShow from './events/show'

const routes = [
  { path: '/demographics', component: DemographicsList },
  { path: '/events/:id/:view', component: EventShow },
  { path: '/events/:view', component: EventList },
  { path: '/events/:event_id/registrations/:id/:view', component: RegistrationsShow },
  { path: '/events/:event_id/tickets/:id/:view', component: TicketShow },
  { path: '/locations', component: LocationList },
  { path: '/organizers', component: OrganizerList }
]

export default routes
