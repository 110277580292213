import EmailCampaignToken from '@apps/campaigns/admin/tokens/campaign/email'
import New from '@apps/campaigns/admin/views/email/new'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Email Campaigns)',
  collection: {
    endpoint: '/api/admin/campaigns/email',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } compact={ true } />
      ) }
    ],
    empty: {
      icon: 'envelope-o',
      title: 't(No Email Campaigns)',
      text: 't(You have not yet created any email campaigns for this store)',
      buttons: [
        { 
          label: 't(Create Email Campaign)', 
          modal: <New store={ props.store } /> 
        }
      ]
    },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(Title)', key: 'title' },
      { label: 't(Program)', key: 'program.title' },
      { label: 't(Sent)', key: 'sent' },
      { label: 't(Open Rate)', key: 'open_rate' },
      { label: 't(Click Rate)', key: 'click_rate' },
      { label: 't(Bounce Rate)', key: 'bounce_rate' },
      { label: 't(Status)', key: 'status' }
    ],
    defaultQuery: { store_id: props.store.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    entity: 'email campaign',
    onClick: (record) => context.router.push(`/campaigns/email/${record.id}`)
  },
  task: props.store.deleted_at === null ? {
    icon: 'plus',
    modal: <New store={ props.store } />
  } : null
})

export default Panel(null, mapPropsToPanel)