import Button from '@admin/components/button'
import Demographics from './demographics'
import PropTypes from 'prop-types'
import Metrics from './metrics'
import Charts from './charts'
import React from 'react'

class Report extends React.Component {

  static propTypes = {
    charts: PropTypes.object,
    demographics: PropTypes.object,
    link: PropTypes.object,
    metrics: PropTypes.object,
    title: PropTypes.string
  }

  render() {
    const { charts, demographics, link, metrics, title } = this.props
    return (
      <div className="crm-report">
        <div className="crm-report-title">
          { title }
          <div className="crm-report-title-action">
            <Button { ...link } />
          </div>
        </div>
        { metrics &&
          <Metrics { ...metrics } />
        }
        { charts &&
          <Charts { ...charts } />
        }
        { demographics &&
          <Demographics { ...demographics }  />
        }
      </div>
    )
  }

}

export default Report
