import Workflows from './automation/workflows'
import Page from '@admin/components/page'
import Subscriptions from './subscriptions'
import Emails from './automation/emails'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.list.title,
  manager: {
    path: `/admin/crm/lists/${resources.list.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview list={ resources.list } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows list={ resources.list } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails list={ resources.list } />
          }
        ]
      },
      {
        label: 't(Subscribed)',
        path: '/subscribed',
        panel: <Subscriptions list={ resources.list } unsubscribed_at={ false } />
      },
      {
        label: 't(Unsubscribed)',
        path: '/unsubscribed',
        panel: <Subscriptions list={ resources.list } unsubscribed_at={ true } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  list: `/api/admin/crm/lists/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
