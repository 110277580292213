const badges = [
  { 
    title: 't(Email)', 
    weight: 7.2, 
    access: {
      rights: { $allOf: ['email:access_app'] }
    },
    icon: 'envelope-o', 
    route: '/admin/email/inbox', 
    visible: ['desktop','tablet'],
    tooltip: 'Email' 
  }
]

export default badges
