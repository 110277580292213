import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    website: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { website } = this.props
    return {
      title: 't(Edit Website)',
      method: 'PATCH',
      endpoint: `/api/admin/websites/websites/${website.id}/edit`,
      action: `/api/admin/websites/websites/${website.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Home Page)', name: 'home_page_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/pages`, textKey: 'title', valueKey: 'id', prompt: 'Choose Page' },
            { label: 't(Not Found Page)', name: 'notfound_page_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/pages`, textKey: 'title', valueKey: 'id', prompt: 'Choose Page' }

          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
