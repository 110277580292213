import Page from '@admin/components/page'
import Overview from './overview'
import Access from './access'
import Types from './types'
import React from 'react'
// import APIKeys from './api_keys'
// import API from './api'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.dataset.title.value,
  manager: {
    path: `/admin/datasets/${resources.dataset.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview dataset={ resources.dataset } />
      },
      {
        label: 't(Access)',
        path: '/access',
        show: resources.dataset.access_type === 'manage',
        panel: <Access dataset={ resources.dataset } />
      },
      {
        label: 't(Types)',
        path: '/types',
        show: resources.dataset.access_type === 'manage',
        panel: <Types dataset={ resources.dataset } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  dataset: `/api/admin/datasets/datasets/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
