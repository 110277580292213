import AgencyAdvisorSubscription from './agency/advisors/show/subscriptions/show'
import AdminAdvisorSubscription from './admin/advisors/show/subscriptions/show'
import AgencySocialBatchPublishing from './agency/social_batches/publishing'
import AdminSocialPublishing from './admin/social_publishings/show'
import AdvisorSubscription from './advisor/show/subscriptions/show'
import AgencySocialCampaign from './agency/social_campaigns/show'
import AgencyEmailCampaign from './agency/email_campaigns/show'
import AgencyEmailTemplate from './agency/email_templates/show'
import AdminEmailCampaign from './admin/email_campaigns/show'
import AdminEmailTemplate from './admin/email_templates/show'
import AdminDomainSegment from './admin/domain_segments/show'
import AgencySocialBatch from './agency/social_batches/show'
import AdminSenderDomain from './admin/sender_domains/show'
import AdminSocialBatch from './admin/social_batches/show'
import AdminVerification from './admin/verifications/show'
import AdminDmarcReport from './admin/dmarc_reports/show'
import AdminSocial from './admin/social_campaigns/show'
import AdminSignupPage from './admin/signup_pages/show'
import AgencyAdvisor from './agency/advisors/show'
import AgencyContent from './agency/content/show'
import AdminAdvisor from './admin/advisors/show'
import AdminContent from './admin/content/show'
import AdminAgency from './admin/agencies/show'
import AdminNotice from './admin/notices/show'
import AdminDomain from './admin/domains/show'
import AdminSentShow from './admin/sent/show'
import Advisor from './advisor/show'
import Agency from './agency'
import Admin from './admin'

const routes = [
  { path: '/admin', component: Admin },
  { path: '/admin/:view', component: Admin },
  { path: '/admin/agencies/:id/:view', component: AdminAgency },
  { path: '/admin/advisors/:advisor_id/subscriptions/:id/:view', component: AdminAdvisorSubscription },
  { path: '/admin/advisors/:id/:view', component: AdminAdvisor },
  { path: '/admin/content/:id/:view', component: AdminContent },
  { path: '/admin/dmarc_reports/:id/:view', component: AdminDmarcReport },
  { path: '/admin/domains/:id/:view', component: AdminDomain },
  { path: '/admin/domain_segments/:id/:view', component: AdminDomainSegment },
  { path: '/admin/email_campaigns/:id/:view', component: AdminEmailCampaign },
  { path: '/admin/email_templates/:id/:view', component: AdminEmailTemplate },
  { path: '/admin/notices/:id/:view', component: AdminNotice },
  { path: '/admin/sender_domains/:id/:view', component: AdminSenderDomain },
  { path: '/admin/sent/domain_segments/:domain_segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/domains/:domain_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/segments/:segment_id/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/sent/:interval/:value/:view', component: AdminSentShow },
  { path: '/admin/signup_pages/:id/:view', component: AdminSignupPage },
  { path: '/admin/social_batches/:id/:view', component: AdminSocialBatch },
  { path: '/admin/social_campaigns/:id/:view', component: AdminSocial },
  { path: '/admin/social_publishings/:id/:view', component: AdminSocialPublishing },
  { path: '/admin/verifications/:id', component: AdminVerification },
  { path: '/advisor', component: Advisor },
  { path: '/advisor/subscriptions/:id/:view', component: AdvisorSubscription },
  { path: '/advisor/:view', component: Advisor },
  { path: '/agency', component: Agency },
  { path: '/agency/:view', component: Agency },
  { path: '/agency/advisors/:advisor_id/subscriptions/:id/:view', component: AgencyAdvisorSubscription },
  { path: '/agency/advisors/:id/:view', component: AgencyAdvisor },
  { path: '/agency/content/:id/:view', component: AgencyContent },
  { path: '/agency/email_campaigns/:id/:view', component: AgencyEmailCampaign },
  { path: '/agency/email_templates/:id/:view', component: AgencyEmailTemplate },
  { path: '/agency/social_batches/:social_batch_id/publishings/:id/:view', component: AgencySocialBatchPublishing },
  { path: '/agency/social_batches/:id/:view', component: AgencySocialBatch },
  { path: '/agency/social_campaigns/:id/:view', component: AgencySocialCampaign }
]

export default routes
