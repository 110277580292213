import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const service_types = {
  optout: {
    title: 't(Auto Schedule)',
    text: 't(Automatically schedule and send email campaigns for me each month)'
  },
  optin: {
    title: 't(Draft)',
    text: 't(Add email campaigns as a \'draft\' for me to schedule manually)'
  }
}

const EmailStrategyToken = ({ value }) => (
  <div className="token">
    <strong><T text={ service_types[value].title } /></strong><br />
    <T text={ service_types[value].text } />
  </div>
)

EmailStrategyToken.propTypes = {
  value: PropTypes.string
}

export default EmailStrategyToken
