import NumberField from '@admin/components/form/numberfield'
import VariantToken from '@apps/stores/admin/tokens/variant'
import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

class InventoryField extends React.Component {

  static propTypes = {
    store_id: PropTypes.number,
    variants: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  state = {
    inventory: null
  }

  render() {
    const { inventory } = this.state
    const { variants } = this.props
    if(!inventory) return null
    return (
      <div className="stores-inventoryfield">
        <div className="maha-table">
          <table>
            <thead>
              <tr>
                <td>Title</td>
                <td className="collapsing">In Stock</td>
              </tr>
            </thead>
            <tbody>
              { variants.map((variant, index) => [
                <tr key={`variant_${index}`}>
                  <td className="unpadded">
                    <VariantToken variant={ variant } product={ variant.product } />
                  </td>
                  <td className="right aligned">
                    <NumberField { ...this._getQuantity(variant) } />
                  </td>
                </tr>
              ]) }
              { variants.length === 0 &&
                <tr>
                  <td colSpan="2" className="center">
                    This store doesnt yet have any products
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { variants } = this.props
    this.setState({
      inventory: variants.reduce((inventory, variant) => ({
        ...inventory,
        [variant.id]: {
          inventory_instock: variant.inventory_instock
        }
      }), {})
    })
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { inventory } = this.state
    if(!_.isEqual(inventory, prevState.inventory)) {
      this.props.onChange(inventory)
    }
  }

  _getQuantity(variant) {
    const { inventory } = this.state
    return {
      defaultValue: inventory[variant.id].inventory_instock,
      disabled: inventory[variant.id].inventory_policy === 'unlimited',
      placeholder: '',
      onChange: this._handleQuantity.bind(this, variant)
    }
  }

  _handleQuantity(variant, inventory_instock) {
    const { inventory } = this.state
    this.setState({
      inventory: {
        ...inventory,
        [variant.id]: {
          ...inventory[variant.id],
          inventory_instock
        }
      }
    })
  }

}

const mapResourcesToPage = (props, context) => ({
  variants: `/api/admin/stores/stores/${props.store_id}/inventory`
})

export default Container(mapResourcesToPage)(InventoryField)
