import Details from '@admin/components/details'
import AssetToken from '@admin/tokens/asset'
import PropTypes from 'prop-types'
import React from 'react'

class AssetInfo extends React.Component {

  static propTypes = {
    asset: PropTypes.object,
    shareable: PropTypes.bool,
    comments: PropTypes.bool
  }

  static defaultProps = {
    comments: false,
    shareable: true
  }

  render() {
    const { asset, shareable } = this.props
    return (
      <div className="maha-asset-info">
        <div className="maha-asset-info-header">
          <AssetToken { ...asset } shareable={ shareable } />
        </div>
        <div className="maha-asset-info-body">
          <Details { ...this._getDetails() } />
        </div>
      </div>
    )
  }

  _getDetails() {
    const { asset, comments } = this.props
    return {
      ...comments ? {
        comments: `maha_assets/${asset.id}`
      } : {},
      items: [
        { label: 'Uploaded On', content: asset.created_at, format: 'date' },
        { label: 'Virus Scan', content: asset.is_infected ? 'infected' : 'uninfected' },
        { label: 'Source', content: asset.source || 'Unknown' }
      ]
    }
  }

}

export default AssetInfo
