import MJSONScreenshot from '@admin/components/mjson_screenshot'
import Date from '@admin/components/date'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

const format = (rate) => {
  return rate > 0 ? numeral(rate).format('0.00%') : '0%'
}

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `campaigns_email_campaigns/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const AdvisorEmailCampaignToken = ({ publishing }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(publishing.advisor_email_campaign) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { publishing.advisor_email_campaign.title }
      </div>
      <div className="token-stats">
        { publishing.subscription.advisor.team.title }<br />
        <span className={ publishing.advisor_email_campaign.status }>{ publishing.advisor_email_campaign.status }</span>
        { _.includes(['scheduled','sending','sent'], publishing.advisor_email_campaign.status) &&
          <span><Date datetime={ publishing.advisor_email_campaign.send_at } timezone={ publishing.subscription.advisor.team.timezone } /></span>
        }
        { _.includes(['sending','sent'], publishing.advisor_email_campaign.status) &&
          <span>{ publishing.advisor_email_campaign.sent } sent</span>
        }
        { publishing.advisor_email_campaign.status === 'sent' &&
          <>
            <span>{ format(publishing.advisor_email_campaign.open_rate) } open rate</span>
            <span>{ format(publishing.advisor_email_campaign.click_rate) } click rate</span>
            <span>{ format(publishing.advisor_email_campaign.bounce_rate) } bounce rate</span>
          </>
        }
      </div>
    </div>
  </div>
)

AdvisorEmailCampaignToken.propTypes = {
  publishing: PropTypes.object
}

export default AdvisorEmailCampaignToken
