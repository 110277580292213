import Page from '@admin/components/page'
import ItemsImport from './import'
import pluralize from 'pluralize'
import Edit from './edit'
import React from 'react'
import New from './new'

const getField = (field) => {
  const type = field.type === 'contactfield' ? field.contactfield.type : field.type
  if(type === 'addressfield') {
    return [
      { label: `${field.name.value} - Full Address`, key: `values.${field.code}.description` },
      { label: `${field.name.value} - Street 1`, key: `values.${field.code}.street_1` },
      { label: `${field.name.value} - Street 2`, key: `values.${field.code}.street_2` },
      { label: `${field.name.value} - City`, key: `values.${field.code}.city` },
      { label: `${field.name.value} - State/Province`, key: `values.${field.code}.state_province` },
      { label: `${field.name.value} - Postal Code`, key: `values.${field.code}.postal_code` },
      { label: `${field.name.value} - County`, key: `values.${field.code}.county` },
      { label: `${field.name.value} - Latitude`, key: `values.${field.code}.latitude` },
      { label: `${field.name.value} - Longitude`, key: `values.${field.code}.longitude` }
    ]
  }
  return [
    { label: field.name.value, key: `values.${field.code}` }
  ]
}

const mapResourcesToPage = (props, context, page) => ({
  fields: `/api/admin/sites_types/${props.params.type_id}/fields`,
  type: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}`
})

const mapPropsToPage = (props, context, resources) => ({
  title: resources.type.title,
  collection: {
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true },
      { label: 'Published', key: 'is_published', collapsing: true, primary: true, format: 'check_times' },
      { label: 'Updated', key: 'updated_at', collapsing: true, visible: false, format: 'date' }
    ],
    export: [
      ...resources.fields.filter(field => {
        return field.type !== 'text'
      }).reduce((fields, field) => [
        ...fields,
        ...getField(field)
      ], []),
      { label: 't(Created)', key: 'created_at' }
    ],
    endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}/items`,
    empty: {
      icon: 'database',
      title: `No ${_.startCase(pluralize(resources.type.name))}`,
      text: `You have not yet created any ${resources.type.title}`,
      buttons: [
        { label: `Create ${_.startCase(resources.type.name)}`, modal: <New type={ resources.type } fields={ resources.fields } site_id={ props.params.site_id } /> },
        { label: `Import ${_.startCase(pluralize(resources.type.name))}`, modal: <ItemsImport user={ props.user } fields={ resources.fields } page={ page } /> }
      ]
    },
    entity: resources.type.title,
    recordTasks: (record) => [
      {
        label: `Edit ${_.startCase(resources.type.name)}`,
        modal: <Edit { ...record } type={ resources.type } fields={ resources.fields } site_id={ props.params.site_id } />
      },{
        label: `Publish ${_.startCase(resources.type.name)}`,
        show: !record.is_published,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${resources.type.id}/items/${record.id}/publish`,
          body: { is_published: true },
          onFailure: () => {},
          onSuccess: () => {}
        }
      },{
        label: `Unpublish ${_.startCase(resources.type.name)}`,
        show: record.is_published,
        request: {
          method: 'PATCH',
          endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${resources.type.id}/items/${record.id}/publish`,
          body: { is_published: false },
          onFailure: () => {},
          onSuccess: () => {}
        }
      },{
        label: `Delete ${_.startCase(resources.type.name)}`,
        confirm: 't(Are you sure you want to delete this item?)',
        request: {
          endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${resources.type.id}/items/${record.id}`,
          method: 'DELETE',
          onFailure: (result) => context.flash.set('error', 't(Unable to delete this item)'),
          onSuccess: (result) => context.flash.set('success', 't(You successfully deleted this item)')
        }
      }
    ],
    new: () => <New type={ resources.type } fields={ resources.fields } site_id={ props.params.site_id } />,
    defaultSort: { key: 'title', order: 'asc' },
    selectable: true,
    buttons: (selected, onSuccess) => [{
      color: 'red',
      text: 't(Delete Selected)',
      confirm: `Are you sure you want to delete these ${pluralize('item', selected.total, true)}?`,
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}/items/delete`,
        body: {
          filter: selected.filter
        },
        onFailure: (result) => context.flash.set('error', `Unable to delete these ${pluralize('item', selected.total, true)}`),
        onSuccess: (result) => context.flash.set('success', `You successfully deleted ${pluralize('item', selected.total, true)}`)
      }
    },{
      color: 'red',
      text: 't(Publish Selected)',
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}/items/publish`,
        body: {
          filter: selected.filter,
          is_published: true
        },
        onFailure: (result) => context.flash.set('error', `Unable to publish these ${pluralize('item', selected.total, true)}`),
        onSuccess: (result) => context.flash.set('success', `You successfully published ${pluralize('item', selected.total, true)}`)
      }
    },{
      color: 'red',
      text: 't(Unpublish Selected)',
      request: {
        method: 'PATCH',
        endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.type_id}/items/publish`,
        body: {
          filter: selected.filter,
          is_published: false
        },
        onFailure: (result) => context.flash.set('error', `Unable to unpublish these ${pluralize('item', selected.total, true)}`),
        onSuccess: (result) => context.flash.set('success', `You successfully unpublished ${pluralize('item', selected.total, true)}`)
      }
    }]
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: `Create ${_.startCase(resources.type.name)}`, modal: <New type={ resources.type } fields={ resources.fields } site_id={ props.params.site_id } /> },
      { label: `Import ${_.startCase(pluralize(resources.type.name))}`, modal: <ItemsImport user={ props.user } fields={ resources.fields } page={ page } /> }
    ]
  }
})

export default Page(mapResourcesToPage, mapPropsToPage)
