import CriteriaDesigner from '@admin/components/criteria_designer'
import ModalPanel from '@admin/components/modal_panel'
import RecipientToken from '../../tokens/recipient'
import PropTypes from 'prop-types'
import React from 'react'

class Registrations extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.string,
    defaultValue: PropTypes.object,
    endpoint: PropTypes.string,
    event: PropTypes.object,
    fields: PropTypes.array,
    instructions: PropTypes.any,
    program: PropTypes.object,
    program_id: PropTypes.number,
    purpose: PropTypes.string,
    onDone: PropTypes.func
  }

  state = {
    criteria: []
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <CriteriaDesigner { ...this._getCriteriaDesigner() } />
      </ModalPanel>
    )
  }

  _getCriteriaDesigner() {
    const { channel, defaultValue, endpoint, event, program_id, purpose } = this.props
    return {
      defaultValue: defaultValue ? defaultValue.criteria : null,
      defaultQuery: {
        channel,
        event_id: event.id,
        program_id,
        purpose,
        strategy: 'registrations'
      },
      endpoint,
      entity: 'contact',
      fields: [
        {
          label: 'Registration',
          fields: [
            { name: 'First Name', key: 'first_name', type: 'text' },
            { name: 'Last Name', key: 'last_name', type: 'text' },
            { name: 'Email', key: 'email', type: 'text' },
            { name: 'Ticket Type', key: 'ticket_type_id', type: 'select', multiple: true, endpoint: `/api/admin/events/events/${event.id}/ticket_types`, valueKey: 'id', textKey: 'name' },
            { name: 'Registration Date', key: 'ordered_at', type: 'daterange', include: ['this','last'] }
          ],
          virtuals: event.contact_config.fields
        }
      ],
      format: (recipient) => <RecipientToken recipient={ recipient } />,
      onChange: this._handleChange
    }
  }

  _getPanel() {
    const { instructions } = this.props
    return {
      title: 't(Select Criteria)',
      instructions,
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      rightItems: [
        { label: 't(Done)', handler: this._handleDone }
      ]
    }
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleChange(criteria) {
    this.setState({ criteria })
  }

  _handleDone() {
    const { criteria } = this.state
    this.props.onDone({ criteria })
    this.context.form.pop()
  }

}

export default Registrations
