import PurposeField from '@apps/campaigns/admin/components/purposefield'
import PhoneForm from '@apps/phone/admin/components/phoneform'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact_ids: PropTypes.array,
    event: PropTypes.object,
    filter: PropTypes.object,
    form: PropTypes.object,
    list: PropTypes.object,
    phone_number_id: PropTypes.number,
    program: PropTypes.object,
    program_id: PropTypes.number,
    pipeline: PropTypes.number,
    service: PropTypes.object,
    store: PropTypes.object,
    user: PropTypes.object,
    onBack: PropTypes.func
  }

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { contact_ids, event, filter, form, list, phone_number_id, pipeline, program, service, store } = this.props
    const program_id = this._getProgramId()
    return {
      title: 't(New Outbound SMS Campaign)',
      method: 'POST',
      action: '/api/admin/campaigns/sms',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'phone_number_id', type: 'hidden', defaultValue: phone_number_id },
            { name: 'program_id', type: 'hidden', defaultValue: program_id },
            { name: 'direction', type: 'hidden', defaultValue: 'outbound' },
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Purpose)', name: 'purpose', type: PurposeField, channel: 'sms', required: true, defaultValue: program ? 'marketing' : 'transactional' },
            ...event ? [
              { name: 'event_id', type: 'hidden', defaultValue: event.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'registrations' } }
            ] : [],
            ...form ? [
              { name: 'form_id', type: 'hidden', defaultValue: form.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'responses' } }
            ] : [],
            ...service ? [
              { name: 'service_id', type: 'hidden', defaultValue: service.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'subscriptions' } }
            ] : [],
            ...store ? [
              { name: 'store_id', type: 'hidden', defaultValue: store.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'orders' } }
            ] : [],
            ...pipeline ? [
              { name: 'pipeline_id', type: 'hidden', defaultValue: pipeline.id },
              { name: 'to', type: 'hidden', value: { send_strategy: 'deals' } }
            ] : [],
            ...list ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { list_id: list.id } } }
            ] : [],
            ...filter ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { filter_id: filter.id } } }
            ] : [],
            ...contact_ids ? [
              { name: 'to', type: 'hidden', value: { send_strategy: 'list', send_config: { contact_ids } } }
            ] : []
          ]
        }
      ]
    }
  }

  _getProgramId() {
    const { event, form, list, pipeline, program, program_id, service, store } = this.props
    if(program_id) return program_id
    if(event) return event.program.id
    if(form) return form.program.id
    if(list) return list.program.id
    if(pipeline) return pipeline.program.id
    if(program) return program.id
    if(service) return service.program.id
    if(store) return store.program.id
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(sms_campaign) {
    this.context.router.push(`/campaigns/sms/${sms_campaign.id}`)
    this.context.modal.close()
  }

}

const NewWrapper = (props) => <PhoneForm phone_form={ New } props={ props } />

export default NewWrapper
