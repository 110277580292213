import Toolbar from '@admin/components/toolbar'
import PropTypes from 'prop-types'
import React from 'react'

class Header extends React.PureComponent {

  static contextTypes = {
    versions: PropTypes.object
  }

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      provider: PropTypes.object,
      network: PropTypes.object
    }),
    version: PropTypes.object
  }

  _handleRedo = this._handleRedo.bind(this)
  _handlePublish = this._handlePublish.bind(this)
  _handleUndo = this._handleUndo.bind(this)

  render() {
    return (
      <div className="flowchart-designer-header">
        <Toolbar { ...this._getToolbar() } />
      </div>
    )
  }

  _getToolbar() {
    const { version } = this.props
    return {
      rightButtons: [
        {
          icon: 'undo',
          className: 'mjson-designer-header-button',
          tooltip: 't(Undo)',
          disabled: version.undo.length === 0,
          handler: this._handleUndo
        },
        {
          icon: 'repeat',
          className: 'mjson-designer-header-button',
          tooltip: 't(Redo)',
          disabled: version.redo.length === 0,
          handler: this._handleRedo
        },
        {
          label: 't(Publish)',
          className: 'mjson-designer-header-button filled',
          disabled: version.record.is_published,
          handler: !version.record.is_published ? this._handlePublish : null
        }
      ]
    }
  }

  _handleRedo() {
    this.context.versions.redo()
  }

  _handlePublish() {
    this.context.versions.publish()
  }

  _handleUndo() {
    this.context.versions.undo()
  }

}

export default Header
