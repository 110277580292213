import AllowancesField from '@apps/subscriptions/admin/components/allowancesfield'
import AddonsField from '@apps/subscriptions/admin/components/addonsfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Plan extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { plan_id } = this.state.config
    const { plans, service, subscription } = this.props.props
    const plan = plan_id ? _.find(plans, { id: plan_id }) : null
    return {
      ref: this.formRef,
      endpoint: `/api/admin/subscriptions/services/${service.id}/subscriptions/${subscription.id}/edit`,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Plan)', name: 'plan_id', type: 'dropdown', endpoint: `/api/admin/subscriptions/services/${service.id}/plans`, valueKey: 'id', textKey: 'title', required: true },
            ...plan ? [
              { label: 'Base Price', name: 'price', type: 'moneyfield', required: true, defaultValue: plan.price },
              ...plan.addons.length > 0 ? [
                { label: 'Addons', name: 'addons', type: AddonsField, plan, required: true }
              ] : [],
              ...service.model === 'metered' ? [
                { label: 'Resource Allowances', name: 'allowances', type: AllowancesField, service, required: true, defaultValue: plan.allowances.map(allowance => ({
                  resource_id: allowance.resource.id,
                  included: allowance.included,
                  price: allowance.price
                })) }
              ] : [
                { label: 'Price', name: 'price', type: 'moneyfield', required: true, defaultValue: plan.price }
              ]
            ] : []
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(subscription) {
    this.props.onNext(subscription)
  }

}

export default Plan
