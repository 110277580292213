import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Batches)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/social_batches`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Batch)', key: 'social_batch.title', sort: 'title' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, aling: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Batches)',
      text: 't(There are no social batches for this advisor)'
    },
    entity: 'social batch'
  }
})

export default Panel(null, mapPropsToPanel)
