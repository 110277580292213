import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    notice: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { notice } = this.props
    return {
      title: 't(Edit Notice)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/notices/${notice.id}/edit`,
      action: `/api/admin/truevail/admin/notices/${notice.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Subject)', name: 'subject', type: 'textfield', emojis: true,maxLength: 150, required: true },
            { label: 't(CC)', name: 'meta.cc', type: 'textfield', placeholder: 't(Enter comma separated list of emails)' },
            { label: 't(BCC)', name: 'meta.bcc', type: 'textfield', placeholder: 't(Enter comma separated list of emails)' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
