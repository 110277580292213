import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Loader from '@admin/components/loader'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Result extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    prompt: PropTypes.string,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    error: null,
    status: 'pending',
    image: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleChoose = this._handleChoose.bind(this)
  _handleGenerate = this._handleGenerate.bind(this)

  render() {
    const { status, image } = this.state
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-dalle-result">
          { status === 'loading' &&
            <Loader { ...this._getLoading() } />
          }
          { status === 'failure' &&
            <Message { ...this._getFailure() } />
          }
          { status === 'success' &&
            <div className="maha-attachments-dalle-preview">
              <div className="maha-attachments-dalle-preview-image">
                <Button { ...this._getImage(image) } />
              </div>
            </div>
          }
        </div>
      </ModalPanel>
    )
  }

  componentDidMount() {
    this._handleGenerate()
  }

  _getFailure() {
    const { error } = this.state
    return {
      icon: 'exclamation-triangle',
      title: 't(Unable to Generate Image)',
      text: error,
      buttons: [
        { label: 't(Try Again)', handler: this._handleBack }
      ]
    }
  }

  _getImage(image) {
    return {
      image: image.url,
      className: null,
      handler: this._handleChoose.bind(this, image.url)
    }
  }

  _getLoading() {
    return {
      label: 't(Generating Image)'
    }
  }

  _getPanel() {
    const { status } = this.state
    const panel = {
      title: 't(Review Image)',
      leftItems: [
        { icon: 'chevron-left', handler: this._handleBack }
      ],
      ...status === 'success' ? {
        buttons: [
          { label: 'Regenerate Image', color: 'blue', basic: true, handler: this._handleGenerate },
          { label: 'Choose Image', color: 'blue', handler: this._handleChoose }
        ]
      } : {} 
    }
    return panel
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChoose() {
    const { image } = this.state
    this.props.onDone(image.url)
  }

  _handleGenerate() {
    const { prompt } = this.props
    this.setState({
      status: 'loading'
    })
    this.context.network.request({
      endpoint: '/api/admin/generate/dalle',
      method: 'POST',
      body: { prompt },
      onFailure: (error) => {
        this.setState({
          error: error.message,
          status: 'failure'
        })
      },
      onSuccess: ({ data }) => {
        this.setState({
          image: data.image,
          status: 'success'
        })
      }
    })
  }

}

export default Result
