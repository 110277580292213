import Form from '@admin/components/form'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Schedule extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    email_campaign_id: PropTypes.number,
    publishing: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_campaign_id, publishing } = this.props
    const { config } = this.state
    return {
      title: 't(Schedule Email Campaign)',
      method: 'PATCH',
      action: `/api/admin/truevail/agency/email_campaigns/${email_campaign_id}/publishings/${publishing.id}/republish`,
      saveText: 'Publish',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Scheduling)', type: 'segment', fields: [
              { label: 't(Send At)', type: 'fields', show: config.scheduling_strategy !== 'optin', fields: [
                { name: 'date', type: 'datefield', required: true, defaultValue: moment().add(1,'day') },
                { name: 'time', type: 'timefield', required: true, defaultValue: '9:00AM' }
              ], after: (
                <span className="alert"><T text={'Emails will be scheduled in the advisor\'s timezone'} /></span>
              ), required: true }
            ], required: true },
            { label: 't(Preview Email)', type: 'segment', fields: [
              { name: 'preview_strategy', type: 'radiogroup', options: [
                { value: 'send', text: 't(Send preview to agency reviewers)' },
                { value: 'nothing', text: 't(Do nothing)' }
              ], defaultValue: 'send' },
              { name: 'preview_introduction', type: 'htmlfield', toolbar: ['b','em','u','ol','ul','a'], show: config.preview_strategy === 'send', placeholder: 't(Enter an optional email introduction)' }
            ], required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Schedule
