import Clone from '@apps/sales/admin/views/deals/clone'
import Edit from '@apps/sales/admin/views/deals/edit'
import Button from '@admin/components/button'
import UserToken from '@admin/tokens/user'
import Date from '@admin/components/date'
import { DragSource } from 'react-dnd'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Deal extends React.PureComponent {

  static contextTypes = {
    isDragging: PropTypes.bool,
    router: PropTypes.object
  }

  static propTypes = {
    connectDragPreview: PropTypes.func,
    connectDragSource: PropTypes.func,
    deal: PropTypes.object,
    fields: PropTypes.array,
    isDragging: PropTypes.bool,
    pipeline: PropTypes.object
  }

  render() {
    const { connectDragPreview, connectDragSource, deal } = this.props
    return connectDragSource(connectDragPreview(
      <div className={ this._getClass() }>
        <Button { ...this._getTasks() } />
        <div className="sales-dealboard-deal-details">
          <Button { ...this._getDeal() } /><br />
          { deal.description ? <>{ deal.description }<br /></> : null }
          { deal.pricing_type === 'fixed' && deal.amount &&
            <>Fixed Price: { numeral(deal.amount).format('$0,0.00') }<br /></>
          }
          { deal.pricing_type === 'recurring' && deal.annual_recurring_revenue &&
            <>Recurring Revenue: { numeral(deal.annual_recurring_revenue).format('$0,0.00') }<br /></>
          }
          { deal.closed_at &&
            <>Closed: <Date date={ deal.closed_at} /></>
          }
          { deal.closes_on &&
            <>Closes: <Date date={ deal.closes_on} /></>
          }
        </div>
        { deal.owner &&
          <div className="sales-dealboard-deal-owner">
            <UserToken { ...deal.owner } />
          </div>
        }
      </div>
    ))
  }

  _getClass() {
    const { isDragging } = this.props
    const classes = ['sales-dealboard-deal']
    if(isDragging) classes.push('dragging')
    return classes.join(' ')
  }

  _getDeal() {
    const { deal } = this.props
    return {
      label: deal.contact.display_name ,
      className: 'link',
      route: `/admin/sales/deals/${deal.id}`
    }
  }

  _getTasks() {
    const { deal, fields, pipeline } = this.props
    return {
      icon: 'ellipsis-v',
      className: 'sales-dealboard-deal-action',
      tasks: [
        { label: 't(Edit Deal)', modal: <Edit fields={ fields } deal={ deal } /> },
        { label: 't(Clone Deal)', modal: <Clone fields={ fields } deal={ deal } pipeline={ pipeline } /> },
        { 
          label: 't(Delete Deal)',
          confirm: 't(Are you sure you want to delete this deal?)',
          request: {
            endpoint: `/api/admin/sales/deals/${deal.id}`,
            method: 'DELETE',
            onSuccess: () => this.context.flash.set('success', 't(The deal was successfully deleted)'),
            onFailure: () => this.context.flash.set('success', 't(Unable to delete this deal)')
          }
        }
      ]
    }
  }

}

const source = {
  beginDrag: (props) => ({
    deal: props.deal
  })
}

const sourceCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
})

Deal = DragSource('ITEM', source, sourceCollector)(Deal)

export default Deal
