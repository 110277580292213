import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import React from 'react'

const FrequencyToken = ({ frequency }) => {
  if(frequency.mode === 'calendar') {
    return (
      <span>
        { numeral(frequency.quantity).format('Oo') } day of every { frequency.units }
      </span>
    )
  } else {
    return (
      <span>
        Every { pluralize(frequency.units, frequency.quantity, true) }
      </span>
    )
  }
}

FrequencyToken.propTypes = {
  frequency: PropTypes.object
}

export default FrequencyToken
