import IntegrationToken from '@apps/finance/admin/tokens/integration'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    integration: PropTypes.string,
    project: PropTypes.object
  }

  state = {
    type: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChangeField = this._handleChangeField.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { project } = this.props
    return {
      title: 't(Edit Project)',
      method: 'PATCH',
      endpoint: `/api/admin/finance/projects/${project.id}/edit`,
      action: `/api/admin/finance/projects/${project.id}`,
      onCancel: this._handleCancel,
      onChangeField: this._handleChangeField,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Type)', name: 'type', type: 'lookup', required: true, options: [
              { value: 'basic', text: 't(Basic Project)' },
              { value: 'tax', text: 't(Tax Account)' }
            ] },
            ...this._getTypeFields()
          ]
        },
        ...this._getIntegration()
      ]
    }
  }

  _getTypeFields() {
    const { type } = this.state
    if(type !== 'basic') return []
    return [
      { label: 't(Tax Account)', name: 'tax_project_id', type: 'lookup', endpoint: '/api/admin/finance/projects/tax', valueKey: 'id', textKey: 'title', format: ProjectToken }
    ]
  }

  _getIntegration() {
    const { integration } = this.props
    if(!integration) return []
    return [{
      label: <IntegrationToken integration={ integration } />,
      padded: false,
      fields: [
        { label: 't(Program Code)', name: 'integration.program_code', type: 'textfield' },
        { label: 't(Source Code)', name: 'integration.source_code', type: 'textfield' },
        { label: 't(Match)', name: 'integration.match', type: 'textfield' },
        { label: 't(Main Project Code)', name: 'integration.main_project_code', type: 'textfield' },
        { label: 't(County Project Code)', name: 'integration.project_code', type: 'textfield' }
      ]
    }]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChangeField(name, value) {
    if(name === 'type') {
      this.setState({
        type: value
      })
    }
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
