import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

class RatingField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    stars: PropTypes.number,
    tabIndex: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    stars: 5,
    tabIndex: 0,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    value: 0,
    hover: -1
  }

  _handleClear = this._handleClear.bind(this)

  render() {
    const { value, hover } = this.state
    const { stars, tabIndex } = this.props
    const score = hover > 0 ? hover : value
    return (
      <div className={ this._getClass() } tabIndex={ tabIndex }>
        <div className="maha-input-field">
          { [...Array(score)].map((i, index) => (
            <div key={`rating_${index}`} { ...this._getStar(index) }>
              <Icon icon="star" />
            </div>
          ))}
          { [...Array(stars - score)].map((i, index) => (
            <div key={`rating_${index}`} { ...this._getStar(score + index) }>
              <Icon icon="star-o" />
            </div>
          ))}
        </div>
        { value > 0 &&
          <Button { ...this._getClear() } />
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== prevProps.value) {
      this._handleSet(value)
    }
  }

  _getClass() {
    const { disabled} = this.props
    const classes = ['maha-input','maha-rating']
    if(disabled) classes.push('disabled')
    return classes.join(' ')
  }

  _getClear() {
    return {
      icon: 'times',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getStar(index) {
    const { disabled } = this.props
    return {
      className: 'maha-rating-star',
      ...!disabled ? {
        onMouseEnter: this._handleHover.bind(this, index + 1),
        onMouseLeave: this._handleHover.bind(this, -1),
        onClick: this._handleClick.bind(this, index + 1)
      } : {}
    }
  }

  _handleClear() {
    this._handleSet(0)
  }

  _handleChange() {
    const { value } = this.state
    this.props.onChange(value > 0 ? value : null)
  }

  _handleClick(value, e) {
    e.stopPropagation()
    this._handleSet(value)
  }

  _handleHover(hover) {    this.setState({ hover })
  }

  _handleSet(val) {
    const value = val || 0
    this.setState({ value })
  }

}

export default RatingField
