import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    profile: PropTypes.object,
    onBack: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { flash } = this.context
    const { profile } = this.props
    return {
      title: 't(Account Details)',
      method: 'PATCH',
      endpoint: `/api/admin/profiles/${profile.id}`,
      action: `/api/admin/profiles/${profile.id}`,
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onSuccess: this._handleSuccess,
      buttons: [
        {
          label: 't(Disconnect Account)',
          color: 'red',
          confirm: 't(Are you sure you want to disconnect this account?)',
          request: {
            endpoint: `/api/admin/profiles/${profile.id}`,
            method: 'DELETE',
            onSuccess: this._handleSuccess,
            onFailure: () => flash.set('error', 't(Unable to disconnect account)')
          }
        }
      ],
      sections: [
        {
          fields: [
            ...profile.name ? [
              { label: 't(Name)', type: 'text', value: profile.name }
            ] : [],
            { label: 't(Username)', type: 'text', value: profile.username },
            { label: 't(Service)', type: 'text', value: profile.service.name },
            ...profile.type === 'emails' ? [
              { label: 't(Signature)', name: 'preferences.signature', type: 'htmlfield', placeholder: 'Enter a signature', toolbar: ['color','b','em','u','ul','ol','a'] }
            ] : []
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleSuccess() {
    this.props.onBack()
  }

}

export default Edit
