import AgreementToken from '@apps/crm/admin/tokens/agreement'
import Upload from '@apps/crm/admin/views/agreements/upload'
import New from '@apps/crm/admin/views/agreements/new'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Files)',
  collection: {
    endpoint: '/api/admin/crm/agreements',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'unsigned.file_name', sort: 'file_name', primary: true, format: (agreement) => (
        <AgreementToken { ...agreement } />
      ) },
      { label: 't(Created)', key: 'unsigned.created_at', sort: 'created_at', format: 'datetime' },
      { label: 't(Status)', key: 'status', collapsing: true, format: StatusToken }
    ],
    empty: {
      title: 't(No agreements)',
      text: 't(There are no agreements for this contact)',
      icon: 'file-pdf-o',
      buttons: [
        {
          label: 't(Send Agreement)',
          modal: <New contact={ props.contact } />
        },
        {
          label: 't(Upload Agreement)',
          modal: <Upload contact={ props.contact } />
        }
      ]
    },
    entity: 'agreement',
    defaultQuery: { contact_id: props.contact.id },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/admin/crm/agreements/${record.id}`)
  },
  tasks: {
    icon: 'plus',
    items: [
      { label: 't(Send Agreement)', modal: <New contact={ props.contact } /> },
      { label: 't(Upload Agreement)', modal: <Upload contact={ props.contact } /> }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
