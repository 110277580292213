import Greeting from '@admin/components/greeting'
import React from 'react'

class Card extends React.Component {

  render() {
    return (
      <div className="maha-dashboard-card-detail">
        <div className="maha-dashboard-card-detail-header">
        </div>
        <Greeting user={ { full_name: 'John Doe' } } />
      </div>
    )
  }

}

export default Card
