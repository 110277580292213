import useActionReducer from '@admin/hooks/use_action_reducer'
import { useNetworkContext } from '@admin/components/network'
import * as selectors from './selectors'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import Form from './form'
import React from 'react'

const FormContainer = React.forwardRef((props, ref) => {
  
  const { network } = useNetworkContext()

  const formRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    submit: () => formRef.current._handleValidate()
  }))

  const [state, handlers] = useActionReducer({
    display_name: 'form',
    actions,
    props,
    reducer,
    selectors
  })

  const mergedProps = {
    context: {
      network
    },
    ...props,
    ...state,
    ...handlers
  }

  return <Form ref={ formRef } { ...mergedProps } />

})

export default FormContainer