import Report from './report'
import Card from './card'
import Edit from './edit'
import New from './new'

const card = {
  code: 'revenue',
  card: Card,
  edit: Edit,
  new: New,
  report: Report
}

export default card
