import ContactAvatar from '@apps/crm/admin/tokens/contact_avatar'
import Container from '@admin/components/container'
import Message from '@admin/components/message'
import Avatar from '@admin/components/avatar'
import Image from '@admin/components/image'
import Logo from '@admin/components/logo'
import Html from '@admin/components/html'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import Composer from './composer'
import moment from 'moment'
import React from 'react'

class SmsClient extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    channel: PropTypes.object,
    contact_phone_number: PropTypes.object,
    messages: PropTypes.array,
    program: PropTypes.object,
    program_phone_number: PropTypes.object
  }

  bodyRef = React.createRef()
  threadRef = React.createRef()

  state = {
    message: null
  }

  _handleSend = this._handleSend.bind(this)
  _handleScrollToBottom = this._handleScrollToBottom.bind(this)

  render() {
    const sessions = this._getMessages()
    const { channel, contact_phone_number, program, program_phone_number } = this.props
    return (
      <div className="phone-sms-client">
        <div className={ this._getHeaderClass() }>
          <div className="phone-sms-client-recipients">
            <div className="phone-sms-client-recipient">
              { program.title }
            </div>
            <Icon icon="arrow-right" />
            <div className="phone-sms-client-recipient">
              { channel.contact.display_name === 'Unknown' ? contact_phone_number.formatted : channel.contact.display_name }
            </div>
          </div>
          { program_phone_number.released_at &&
            <div className="phone-sms-client-message">
              This phone number has been released and is no longer available
              for sending sms messages
            </div>
          }
          { !program_phone_number.released_at && !channel.has_consented &&
            <div className="phone-sms-client-message">
              This contact has not given consent to send marketing related
              messages on this channel
            </div>
          }
        </div>
        { sessions.length === 0 &&
          <Message { ...this._getEmpty() } />
        }
        <div className="phone-sms-client-body" ref={ this.bodyRef }>
          <div className="phone-sms-client-thread" ref={ this.threadRef }>
            { sessions.map((session,index) => (
              <div className="phone-sms-client-result" key={`date_${index}`}>
                <div className="phone-sms-client-date-label">
                  { session.timestamp.format('MM/DD/YY, h:mmA') }
                </div>
                { session.blocks.map((block, bindex) => (
                  <div className={`phone-sms-client-token ${block.type}`} key={`result_${bindex}`}>
                    { block.user &&
                      <div className="phone-sms-client-token-identity">
                        { block.user.full_name }
                      </div>
                    }
                    <div className="phone-sms-client-token-block">
                      <div className="phone-sms-client-token-avatar">
                        { block.user &&
                          <Avatar user={ block.user } width="24" />
                        }
                        { block.program && !block.user &&
                          <Logo team={ block.program } width="24" />
                        }
                        { block.contact &&
                          <ContactAvatar { ...block.contact } />
                        }
                      </div>
                      <div className="phone-sms-client-token-details">
                        <div className="phone-sms-client-token-messages">
                          { block.messages.map((message, mindex) => (
                            <div className="phone-sms-client-token-message" key={`message_${mindex}`}>
                              <div className="phone-sms-client-token-message-body">
                                { this._getParsed(message.text) }
                                { message.attachments.map((attachment, aindex) => (
                                  <div className="phone-sms-client-token-message-attachment" key={`attachment_${aindex}`}>
                                    <Image src={attachment.asset.path} transforms={{ w: 200 }} />
                                  </div>
                                ))}
                              </div>
                              { message.status === 'undelivered' &&
                                <div className="phone-sms-client-token-message-error">
                                  <Icon icon="exclamation-circle" title="Unable to deliver message" />
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="phone-sms-client-token-padding" />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        { !program_phone_number.released_at &&
          <div className="phone-sms-client-footer">
            <Composer { ...this._getComposer() } />
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this._handleScrollToBottom()
    this._handleRead()
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props
    if(messages.length > prevProps.messages.length) {
      this._handleScrollToBottom()
    }
  }

  _getComposer() {
    return {
      placeholder: 'Enter text message',
      onSubmit: this._handleSend
    }
  }

  _getEmpty() {
    return {
      icon: 'comments',
      title: 't(No messages)',
      text: 't(The contact has not sent or received any messages via this channel)'
    }
  }

  _getHeaderClass() {
    const { program_phone_number } = this.props
    const classes = ['phone-sms-client-header']
    if(program_phone_number.released_at) classes.push('error')
    return classes.join(' ')
  }

  _getMessages() {
    const { messages } = this.props
    return messages.slice().reverse().reduce((sessions, message) => {
      const created_at = moment(message.created_at)
      const sessionIndex = sessions.findIndex(session => {
        return created_at.diff(session.timestamp, 'minutes') < 90
      })
      return [
        ...sessions.map((session, index) => {
          if(index !== sessionIndex) return session
          const last = session.blocks[session.blocks.length - 1]
          const blockIndex = last && _.isEqual(last.contact, message.contact) && _.isEqual(last.program, message.program) && _.isEqual(last.user, message.user) ? session.blocks.length - 1 : -1
          return {
            ...session,
            blocks: [
              ...session.blocks.map((block, bindex) => {
                if(bindex !== blockIndex) return block
                return {
                  ...block,
                  messages: [
                    ...block.messages,
                    { text: message.body, status: message.status, attachments: message.attachments }
                  ]
                }
              }),
              ...blockIndex < 0 ? [{
                type: message.program ? 'program' : 'contact',
                user: message.user,
                program: message.program,
                contact: message.contact,
                messages: [
                  { text: message.body, status: message.status, attachments: message.attachments }
                ]
              }] : []
            ]
          }
        }),
        ...sessionIndex < 0 ? [{
          timestamp: moment(message.created_at),
          blocks: [
            {
              type: message.program ? 'program' : 'contact',
              user: message.user,
              program: message.program,
              contact: message.contact,
              messages: [
                { text: message.body, status: message.status, attachments: message.attachments }
              ]
            }
          ]
        }] : []
      ]
    }, [])
  }

  _getParsed(text) {
    const formatted = text.replace(/(https?:\/\/[^\s]+)/g, (url) => {
      return '<a href="' + url + '">' + url + '</a>'
    })
    return <Html html={ formatted } />
  }

  _handleRead() {
    const { program_phone_number, contact_phone_number } = this.props
    this.context.network.request({
      endpoint: `/api/admin/phone/conversations/${program_phone_number.id}/${contact_phone_number.id}/view`,
      method: 'PATCH'
    })
  }

  _handleScrollToBottom() {
    this.bodyRef.current.scrollTop = this.threadRef.current.offsetHeight - this.bodyRef.current.offsetHeight + 10
  }

  _handleSend(data) {
    const { program_phone_number, contact_phone_number } = this.props
    const { attachments, text } = data
    this.context.network.request({
      endpoint: `/api/admin/phone/conversations/${program_phone_number.id}/${contact_phone_number.id}/smses`,
      method: 'POST',
      body: {
        body: text,
        asset_ids: attachments.map(asset => asset.id)
      }
    })
  }

}

const mapResources = (props, context) => ({
  channel: `/api/admin/phone/conversations/${props.program_phone_number.id}/${props.contact_phone_number.id}`,
  messages: `/api/admin/phone/conversations/${props.program_phone_number.id}/${props.contact_phone_number.id}/smses`
})

export default Container(mapResources)(SmsClient)
