import Button from '@admin/components/button'
import Token from '@admin/tokens/token'
import PropTypes from 'prop-types'
import React from 'react'
import Item from './item'

class MultiField extends React.Component {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    addText: PropTypes.string,
    defaultValue: PropTypes.array,
    moveable: PropTypes.bool,
    newForm: PropTypes.any,
    editForm: PropTypes.any,
    format: PropTypes.any,
    props: PropTypes.any,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    format: Token,
    moveable: false,
    props: {},
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    items: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleBack = this._handleBack.bind(this)
  _handleCreate = this._handleCreate.bind(this)

  render() {
    const { items } = this.state
    return (
      <div className="multifield">
        <div className="multifield-items">
          { items.map((item, index) => (
            <Item { ...this._getItem(item, index) } key={`item_${index}`} />
          ))}
          <div className="multifield-items-add">
            <Button { ...this._getAdd() } />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.state
    const { value } = this.props
    if(!_.isEqual(items, prevState.items)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getAdd() {
    const { addText } = this.props
    return {
      label: addText,
      className: 'link',
      handler: this._handleAdd
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getEdit(item, index) {
    return {
      item,
      ...this._getProps(),
      onBack: this._handleBack,
      onDone: this._handleUpdate.bind(this, index)
    }
  }

  _getItem(item, index) {
    const { format, moveable, editForm } = this.props
    return {
      editable: editForm !== undefined,
      format,
      item,
      index,
      moveable,
      onClone: this._handleClone.bind(this, index),
      onEdit: this._handleEdit.bind(this, item, index),
      onMove: this._handleMove.bind(this, index),
      onRemove: this._handleRemove.bind(this, index)
    }
  }

  _getNew() {
    return {
      ...this._getProps(),
      onBack: this._handleBack,
      onDone: this._handleCreate
    }
  }

  _getProps() {
    const { props } = this.props
    return {
      ..._.isFunction(props) ? props() : {},
      ..._.isPlainObject(props) ? props : {}
    }
  }

  _handleAdd() {
    const New = this.props.newForm
    this.context.form.push(<New { ...this._getNew() } />)
  }

  _handleBack() {
    this.context.form.pop()
  }

  _handleCreate(item) {
    const { items } = this.state
    this.setState({
      items: [
        ...items,
        item
      ]
    })
    this.context.form.pop()
  }

  _handleClone(index) {
    const { items } = this.state
    this.setState({
      items: items.reduce((items, item, i) => [
        ...items,
        item,
        ...i === index ? [item] : []
      ], [])
    })
  }

  _handleEdit(item, index) {
    const Edit = this.props.editForm
    this.context.form.push(<Edit { ...this._getEdit(item, index) } />)

  }

  _handleChange() {
    const { items } = this.state
    this.props.onChange(items)
  }

  _handleMove(from, to) {
    const { items } = this.state
    this.setState({
      items: (from < to) ? [
        ...items.slice(0, from),
        ...items.slice(from + 1, to + 1),
        items[from],
        ...items.slice(to + 1)
      ] : [
        ...items.slice(0, to),
        items[from],
        ...items.slice(to, from),
        ...items.slice(from + 1)
      ]
    })
  }

  _handleRemove(index) {
    const { items } = this.state
    this.setState({
      items: items.filter((item, i) => {
        return i !== index
      })
    })
  }

  _handleSet(items) {
    this.setState({ items })
  }

  _handleUpdate(index, newitem) {
    const { items } = this.state
    this.setState({
      items: items.map((item, i) => {
        return i === index ? newitem : item
      })
    })
    this.context.form.pop()
  }

}

export default MultiField
