import SMSClient from '@apps/phone/admin/components/sms_client'
import Page from '@admin/components/page'
import PropTypes from 'prop-types'
import React from 'react'

class Conversation extends React.Component {

  static propTypes = {
    contact_phone_number: PropTypes.object,
    program_phone_number: PropTypes.object
  }

  render() {
    return <SMSClient { ...this._getSMSClient()} />
  }

  _getSMSClient() {
    const { contact_phone_number, program_phone_number } = this.props
    return {
      contact: contact_phone_number.contact,
      contact_phone_number,
      program: program_phone_number.program,
      program_phone_number
    }
  }

}

const mapResourcesToPage = (props, context) => ({
  contact_phone_number: `/api/admin/crm/phone_numbers/${props.params.contact_phone_number_id}`,
  program_phone_number: `/api/admin/phone/numbers/${props.params.program_phone_number_id}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Conversation)',
  component: Conversation
})

export default Page(mapResourcesToPage, mapPropsToPage)
