import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

const ProfileToken = (profile) => (
  <div className="profile-token">
    <div className="profile-token-icon">
      <Img src={`/images/services/${profile.service}.png`} />
    </div>
    <div className="profile-token-label">{ profile.username }</div>
  </div>
)

ProfileToken.propTypes = {
  profile: PropTypes.object
}

export default ProfileToken
