import PhoneNumberToken from '@apps/phone/admin/tokens/phone_number'
import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import React from 'react'

class Programs extends React.PureComponent {

  static propTypes = {
    program: PropTypes.object,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Choose a Phone Number)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel}
      ]
    }
  }

  _getSearch() {
    const { program } = this.props
    return {
      endpoint: '/api/admin/phone/numbers',
      filter: {
        ...program ? {
          program_id: {
            $eq: program.id
          }
        } : {},
        released_at: {
          $nl: true
        }
      },
      valueKey: null,
      textKey: 'number',
      multiple: false,
      format: PhoneNumberToken,
      onChange: this._handleChoose
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChoose(phone_number) {
    this.props.onChoose(phone_number)
  }

}

export default Programs
