import FrequencyField from '@apps/subscriptions/admin/components/frequencyfield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Cycle extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  state = {
    config: {}
  }

  formRef = React.createRef()

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Renewal Frequency)', name: 'frequency', type: FrequencyField, required: true },
            ...formdata.model === 'flat' ? [
              { label: 't(Renewal Timing)', name: 'timing', type: 'radiogroup', options: [
                { value: 'end', text: 't(Charge at the end of the billing cycle)' },
                { value: 'beginning', text: 't(Charge at the beginning of the billing cycle)' }
              ], defaultValue: 'end', required: true }
            ] : [
              { name: 'timing', type: 'hidden', value: 'end' }
            ]
          ]
        }
      ]
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(service) {
    this.props.onNext(service)
  }

}

export default Cycle
