import SecretsField from '@apps/platform/admin/components/secretsfield'
import ServiceToken from '@apps/maha/admin/tokens/service'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    provider: PropTypes.object
  }

  state = {
    data: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { provider } = this.props
    return {
      title: 't(New Integration)',
      method: 'POST',
      action: `/api/admin/platform/providers/${provider.id}/integrations`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Service)', name: 'service_id', type: 'dropdown', endpoint: '/api/admin/services', valueKey: 'id', textKey: 'name', required: true, format: (service) => (
              <ServiceToken service={ service } />
            ) },
            { label: 't(Mode)', name: 'mode', type: 'dropdown', options: [
              { value: 'development', text: 't(Development)' },
              { value: 'production', text: 't(Production)' }
            ], required: true, defaultValue: 'development' },
            { label: 't(Secrets)', name: 'secrets', type: SecretsField, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(data) {
    this.setState({ data })
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default New
