import ModalPanel from '@admin/components/modal_panel'
import List from '@apps/crm/admin/components/list'
import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

class ProgramPhoneNumbers extends React.Component {

  static propTypes = {
    program_phone_numbers: PropTypes.array,
    onCancel: PropTypes.func,
    onChoose: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <List { ...this._getList() } />
      </ModalPanel>
    )
  }

  _getList() {
    const { program_phone_numbers } = this.props
    return {
      format: (phone_number) => (
        <div className="program-token">
          <div className="program-token-logo">
            <Logo team={ phone_number.program } width="48" />
          </div>
          <div className="program-token-label">
            { phone_number.program.title } <span>{ phone_number.formatted }</span>
          </div>
        </div>
      ),
      items: program_phone_numbers,
      handler: this._handleChange
    }
  }

  _getPanel() {
    return {
      title: 't(Choose a Phone Number)',
      leftItems: [
        { label: 't(Cancel)', handler: this._handleCancel }
      ]
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleChange(phone_number) {
    this.props.onChoose(phone_number)
  }

}

export default ProgramPhoneNumbers
