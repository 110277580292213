import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import moment from 'moment'
import React from 'react'

class Reactivate extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { advisor} = this.props
    const { config } = this.state
    return {
      title: 't(Reactivate Advisor)',
      method: 'PATCH',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/reactivate`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Timing)', type: 'segment', fields: [
              { name: 'strategy', type: 'radiogroup', options: [
                { value: 'now', text: 't(Reactivate Immediately)' },
                { value: 'schedule', text: 't(Schedule Reactivation)' }
              ], required: true, defaultValue: 'now' },
              ...config.strategy === 'schedule' ? [
                { name: 'date', type: 'datefield', required: true, defaultValue: moment().format('YYYY-MM-DD') }
              ] : []
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Reactivate
