import RevenueTypeToken from '@apps/finance/admin/tokens/revenue_type'
import ProjectToken from '@apps/finance/admin/tokens/project'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.PureComponent {

  static propTypes = {
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  state = {
    ticket_type: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      title: 't(New Ticket Type)',
      cancelIcon: 'chevron-left',
      onCancel: this._handleBack,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { name: 'is_active', type: 'hidden', value: true },
            { label: 't(Name)', name: 'name', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' },
            { label: 'Pricing', type: 'segment', required: true, fields: [
              { name: 'price_type', type: 'radiogroup',options: [
                { value: 'fixed', text: 't(Fixed Price)' },
                { value: 'sliding_scale', text: 't(Sliding Scale)' },
                { value: 'free', text: 't(Free)'}
              ], required: true, defaultValue: 'fixed' },
              ...this._getPriceType()
            ] },
            { label: 'Availability', type: 'segment', fields: [
              { type: 'fields', fields: [
                { label: 'Total Tickets', name: 'total_tickets', type: 'numberfield' },
                { label: 'Maximum Per Order', name: 'max_per_order', type: 'numberfield' }
              ] },
              { type: 'fields', fields: [
                { label: 'Sales Open', name: 'sales_open_at', type: 'datetimefield' },
                { label: 'Sales Close', name: 'sales_close_at', type: 'datetimefield' }
              ] }
            ] }
          ]
        }
      ]
    }
  }

  _getPriceType() {
    const { ticket_type } = this.state
    if(ticket_type.price_type === 'fixed') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: 26 }
        ] },
        { label: 't(Fixed Price)', name: 'fixed_price', type: 'moneyfield', required: true },
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: '0.000' },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)', defaultValue: false }
      ]
    }
    if(ticket_type.price_type === 'sliding_scale') {
      return [
        { type: 'fields', fields: [
          { label: 't(Project)', name: 'project_id', type: 'lookup', endpoint: '/api/admin/finance/projects', valueKey: 'id', textKey: 'display', required: true, format: ProjectToken },
          { label: 't(Revenue Type)', name: 'revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: 26 }
        ] },
        { type: 'fields', fields: [
          { label: 't(Low Price)', name: 'low_price', type: 'moneyfield', required: true },
          { label: 't(High Price)', name: 'high_price', type: 'moneyfield', required: true }
        ] },
        { label: 't(Overage Strategy)', name: 'overage_strategy', type: 'dropdown', options: [
          { value: 'income', text: 't(Treat any amount over the low price as additional income)' },
          { value: 'donation', text: 't(Treat any amount over the low price as a donation)' }
        ], required: true, defaultValue: ticket_type.overage_strategy },
        ...this._getOverageStrategy(),
        { label: 't(Tax Rate)', name: 'tax_rate', type: 'ratefield', required: true, defaultValue: '0.000' },
        { label: 't(Tax Deductible?)', name: 'is_tax_deductible', type: 'checkbox', prompt: 't(Is this product tax deductable?)', defaultValue: false }
      ]
    }
    return []
  }

  _getOverageStrategy() {
    const { ticket_type } = this.state
    if(ticket_type.overage_strategy === 'donation') {
      return [
        { label: 'Donation Revenue Type', name: 'donation_revenue_type_id', type: 'lookup', endpoint: '/api/admin/finance/revenue_types', valueKey: 'id', textKey: 'display', required: true, format: RevenueTypeToken, defaultValue: 30 }
      ]
    }
    return []
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(ticket_type) {
    this.setState({ ticket_type })
  }

  _handleSuccess(ticket_type) {
    this.props.onDone(ticket_type)
  }

}

export default New
