import { useMemo } from 'react'

export const images = (state, props) => {
  return useMemo(() => {
    return state.assets.filter(asset => {
      return asset.content_type.match(/(jpeg|jpg|png|gif)/)
    })
  }, [state.assets])
}

export const plains = (state, props) => {
  return useMemo(() => {
    return state.assets.filter(asset => {
      return !asset.content_type.match(/(jpeg|jpg|png|gif)/)
    })
  }, [state.assets])
}
