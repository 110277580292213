import Workflows from './automation/workflows'
import AbandonedCarts from './carts/abandoned'
import EmailCampaigns from './email_campaigns'
import SMSCampaigns from './sms_campaigns'
import Page from '@admin/components/page'
import ActiveCarts from './carts/active'
import Emails from './automation/emails'
import Fulfillment from './fulfillment'
import Performance from './performance'
import Categories from './categories'
import Analytics from './analytics'
import Inventory from './inventory'
import Products from './products'
import Overview from './overview'
import Fields from './fields'
import Orders from './orders'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.store.title,
  manager: {
    path: `/admin/stores/stores/${resources.store.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview store={ resources.store } />
      },
      {
        label: 't(Categories)',
        path: '/categories',
        panel: <Categories store={ resources.store } />
      },
      {
        label: 't(Products)',
        path: '/products',
        panel: <Products store={ resources.store } />
      },
      {
        label: 't(Fields)',
        path: '/fields',
        panel: <Fields store={ resources.store } />
      },
      {
        label: 't(Inventory)',
        path: '/inventory',
        panel: <Inventory store={ resources.store } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows store={ resources.store } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails store={ resources.store } />
          }
        ]
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns store={ resources.store } />
          },
          {
            label: 't(SMS)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } }
              ] 
            },
            path: '/sms_campaigns',
            panel: <SMSCampaigns store={ resources.store } />
          },
          {
            label: 't(Voice)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } }
              ] 
            },
            path: '/voice_campaigns',
            panel: <VoiceCampaigns store={ resources.store } />
          }
        ]
      },
      {
        label: 't(Carts)',
        children: [
          {
            label: 't(Active)',
            path: '/active_carts',
            panel: <ActiveCarts store={ resources.store }  />
          },
          {
            label: 't(Abandoned)',
            path: '/abandoned_carts',
            panel: <AbandonedCarts store={ resources.store }  />
          }
        ]
      },
      {
        label: 't(Orders)',
        path: '/orders',
        panel: <Orders store_id={ resources.store.id } />
      },
      {
        label: 't(Fulfillment)',
        path: '/fulfillment',
        panel: <Fulfillment store={ resources.store } />
      },
      {
        label: 't(Performance)',
        path: '/performance',
        panel: <Performance store={ resources.store } />
      },
      {
        label: 'Analytics',
        path: '/analytics',
        show: context.admin.hasRights({ allOf: ['analytics:access_app'] }),
        panel: <Analytics store={ resources.store } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  store: `/api/admin/stores/stores/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
