import Conversations from './conversations'
import Page from '@admin/components/page'
import Calendar from './calendar'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Social Campaigns)',
  manager: {
    path: '/admin/campaigns/social',
    items: [
      {
        label: 't(Calendar)',
        path: '/calendar',
        panel: <Calendar />
      },
      {
        label: 't(Conversations)',
        path: '/conversations',
        panel: <Conversations />
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
