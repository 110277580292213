import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import React from 'react'

const mapPropsToPage = (props, context) => ({
  title: 't(Subscriptions)',
  collection: {
    endpoint: '/api/admin/dashboards/crm/subscriptions',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => (
        <ContactToken { ...contact } />
      ) },
      { label: 't(List)', key: 'list.title', sort: 'list' },
      { label: 't(Subscribed)', key: 'created_at', collapsing: true, format: 'datetime' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'check',
      title: 't(No Subscription)',
      text: 't(No contacts is subscribed to any lists)'
    },
    entity: 'subscription',
    onClick: (record) => context.router.push(`/crm/contacts/${record.contact.id}`)
  }
})

export default Page(null, mapPropsToPage)
