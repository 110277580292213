import PasswordField from '@admin/components/form/password'
import Button from '@admin/components/button'
import Avatar from '@admin/components/avatar'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

class Password extends React.Component {

  static propTypes = {
    context: PropTypes.shape({
      admin: PropTypes.object,
      flash: PropTypes.object,
      host: PropTypes.object,
      network: PropTypes.object,
      provider: PropTypes.object,
      router: PropTypes.object
    }),
    account: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onRedirect: PropTypes.func,
    onSignin: PropTypes.func
  }

  passwordRef = null

  state = {
    attempts: 0,
    clear: false,
    error: false,
    message: null,
    status: 'pending'
  }

  _handleBack = this._handleBack.bind(this)
  _handleForgot = this._handleForgot.bind(this)
  _handleShake = this._handleShake.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  
  constructor(props) {
    super(props)
    this.passwordRef = React.createRef()
  }

  render() {
    const { message } = this.state
    const { account } = this.props
    return (
      <div className="maha-signin-panel">
        <div className="maha-signin-form">
          <div className="maha-signin-header">
            <Avatar { ...this._getAvatar() } />
            <h2>{ account.full_name }</h2>
            <p>{ account.email }</p>
          </div>
          { message &&
            <div className="maha-signin-error">
              <T text={ message } />
            </div>
          }
          <div className={ this._getFormClass() }>
            <div className="field password-field">
              <PasswordField { ...this._getPassword() } />
            </div>
            <div className="field button-field">
              <Button { ...this._getSignin() } />
            </div>
          </div>
          <div className="maha-signin-footer">
            <Button { ...this._getBack() } /><br />
            <Button { ...this._getForget() } />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this.passwordRef.current.focus()
    }, 250)
  }

  _getAvatar() {
    const { provider } = this.props.context
    const { account } = this.props
    return {
      host: provider.asset_host,
      user: account,
      width: 150,
      presence: false
    }
  }

  _getBack() {
    return {
      label: 't(Not You?)',
      className: 'link',
      handler: this._handleBack
    }
  }

  _getForget() {
    return {
      label: 't(Forgot your password?)',
      className: 'link',
      handler: this._handleForgot
    }
  }

  _getFormClass() {
    const { error } = this.state
    const classes = ['ui','form']
    if(error) classes.push('animating transition shake')
    return classes.join(' ')
  }

  _getPassword() {
    const { password } = this.state
    return {
      ref: this.passwordRef,
      value: password,
      onChange: this._handleUpdate.bind(this, 'password'),
      onEnter: this._handleSubmit
    }
  }

  _getSignin() {
    const { status } = this.state
    return {
      label: 't(Signin)',
      loading: status === 'submitting',
      color: 'blue',
      size: 'large',
      handler: this._handleSubmit
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleForgot() {
    const { account } = this.props
    const { email } = account
    this.props.context.network.request({
      endpoint: '/api/admin/reset/email',
      method: 'POST',
      body: { email },
      onSuccess: () => {
        this.setState({
          message: 't(Please check your email for password reset instructions)'
        })
      },
      onFailure: ({ message }) => {
        this.setState({ message })
      }
    })
  }

  _handleShake({ message }) {
    const { attempts } = this.state
    this.setState({ 
      attempts: attempts + 1,
      error: true,
      message,
      password: ''
    })
    setTimeout(() => {
      this.setState({ 
        error: false,
        status: 'pending'
      })
    }, 500)
  }

  _handleLockout() {
    const { email } = this.props.account
    this.props.context.network.request({
      endpoint: '/api/admin/signin/lockout',
      method: 'POST',
      body: { email },
      onSuccess: () => this.props.onNext('lockout'),
      onFailure: this._handleShake
    })
  }

  _handleSubmit() {
    const { attempts, password } = this.state
    const { account } = this.props
    const { email } = account
    if(password.length === 0) return
    this.props.context.network.request({
      endpoint: '/api/admin/signin/password',
      method: 'POST',
      body: { email, password },
      onSuccess: ({ data }) => {
        const { token } = data
        account.token = token
        if(account.use_twofactor) return this.props.onNext('twofactor', { account })
        this.props.onSignin(account)
      },
      onFailure: ({ message }) => {
        if(attempts === 2) return this._handleLockout()
        this._handleShake({ message })
      }
    })
  }

  _handleUpdate(key, value) {
    this.setState({
      [key]: value
    })
  }

}

export default Password
