import PropTypes from 'prop-types'
import React from 'react'

const layouts = {
  1: { basis: 'calc(100%/12)', text: '1/12' },
  2: { basis: 'calc(100%/6)', text: '1/6' },
  3: { basis: '25%', text: '1/4' },
  4: { basis: 'calc(100%/3)', text: '1/3' },
  6: { basis: '50%', text: '1/2' },
  8: { basis: 'calc(2 * 100% / 3)', text: '2/3' },
  9: { basis: '75%', text: '3/4' },
  10: { basis: 'calc(5 * 100% / 6)', text: '5/6' },
  12: { basis: '100%', text: '1/1' }
}

const LayoutToken = ({ value }) => (
  <div className="layout-token">
    <div className="layout-token-row">
      { value.map((columns, index) => (
        <div className="layout-token-column" style={{ flexBasis: layouts[columns].basis }} key={`column_${index}`}>
          <div className="layout-token-block">{ layouts[columns].text }</div>
        </div>
      ))}
    </div>
  </div>
)

LayoutToken.propTypes = {
  value: PropTypes.array
}

export default LayoutToken
