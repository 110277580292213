import Container from '@admin/components/container'
import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Stack from '@admin/components/stack'
import PhoneNumbers from './phone_numbers'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneForm extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    contact_ids: PropTypes.array,
    event: PropTypes.object,
    filter: PropTypes.object,
    form: PropTypes.object,
    list: PropTypes.object,
    phone_form: PropTypes.any,
    phone_number_id: PropTypes.number,
    phone_numbers: PropTypes.array,
    program: PropTypes.object,
    program_id: PropTypes.number,
    pipeline: PropTypes.number,
    props: PropTypes.object,
    service: PropTypes.object,
    store: PropTypes.object,
    user: PropTypes.object
  }

  static defaultProps = {
    requires: []
  }

  stackRef = React.createRef()

  _handleCancel = this._handleCancel.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePhoneNumber = this._handlePhoneNumber.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    const { phone_numbers } = this.props
    return (
      <>
        { phone_numbers.length > 0 ?
          <Stack { ...this._getStack()} /> :
          <ModalPanel { ...this._getPanel() }>
            <Message { ...this._getMessage() } />
          </ModalPanel>
        }
      </>
    )
  }

  componentDidMount() {
    const { phone_numbers } = this.props
    if(phone_numbers.length == 1) return this._handlePhoneNumber(phone_numbers[0])
    this._handlePush(PhoneNumbers, this._getPhoneNumbers())
  }

  _getMessage() {
    return {
      icon: 'phone',
      title: 't(No Phone Numbers)',
      text: 't(This program does not have any provisioned phone numbers)'
    }
  }

  _getPhoneNumbers() {
    return {
      program: this._getProgram(),
      onCancel: this._handleCancel,
      onChoose: this._handlePhoneNumber
    }
  }

  _getStack() {
    return {
      display_name: 'phoneform',
      ref: this.stackRef
    }
  }

  _getForm(phone_number) {
    const { props } = this.props
    return {
      ...props,
      program: phone_number.program,
      phone_number_id: phone_number.id,
      program_id: phone_number.program.id,
      onBack: this._handlePop
    }
  }

  _getPanel() {
    return {
      title: 't(No Phone Numbers)',
      leftItems: [
        { label: 'Cancel', handler: this._handleCancel }
      ]
    }
  }

  _getProgram() {
    const { event, form, list, pipeline, program, service, store } = this.props.props
    if(event) return event.program
    if(form) return form.program
    if(list) return list.program
    if(pipeline) return pipeline.program
    if(program) return program
    if(service) return service.program
    if(store) return store.program
    return null
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePhoneNumber(phone_number) {
    const { phone_form } = this.props
    this._handlePush(phone_form, this._getForm(phone_number))
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

const mapResources = (props, context) => ({
  phone_numbers: {
    endpoint: '/api/admin/phone/numbers',
    filter: {
      ...props.program ? {
        program_id: {
          $eq: props.program.id
        }
      } : {},
      released_at: {
        $nl: true
      }
    }
  }
})

export default Container(mapResources)(PhoneForm)
