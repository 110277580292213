import { createLogger } from '@core/utils/console'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import React from 'react'

const logAction = createLogger('red')

export const LoggerContext = React.createContext()
LoggerContext.displayName = 'LoggerContext'

export const useLoggerContext = () => React.useContext(LoggerContext)

class Logger extends React.Component {

  static childContextTypes = {
    logger: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any
  }

  _handleError = this._handleError.bind(this)
  _handleSignin = this._handleSignin.bind(this)

  render() {
    const { children } = this.props
    return (
      <LoggerContext.Provider value={ this.getChildContext() }>
        { children }
      </LoggerContext.Provider>
    )
  }

  componentDidMount() {
    if(process.env.MAHA_ENV !== 'production') return
    Sentry.init({
      dsn: 'https://67a13d0dc3b22e4f5d495be6e5d7e1e5@o4506123176181760.ingest.sentry.io/4506123178344448',
      environment: process.env.MAHA_ENV,
      release: process.env.GIT_VERSION,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay()
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }

  getChildContext() {
    return {
      logger: {
        error: this._handleError,
        log: this._handleLog,
        signin: this._handleSignin
      }
    }
  }

  _handleLog(action, data) {
    logAction(action, data)
  }

  _handleSignin(user) {
    Sentry.setUser({ 
      id: user.id,
      username: user.full_name,
      email: user.email
    })
  }

  _handleError(error, info) {
    Sentry.captureException(error)
  }

}

export default Logger
