import useActionReducer from '@admin/hooks/use_action_reducer'
import * as actions from './actions'
import reducer from './reducer'
import Lookup from './lookup'
import React from 'react'

const LookupContainer = React.forwardRef((props, ref) => {

  const componentRef = React.useRef()

  React.useImperativeHandle(ref, () => ({
    clear: () => componentRef.current._handleClear(),
    focus: () => componentRef.current._handleFocus()
  }))

  const [state, handlers] = useActionReducer({
    display_name: 'lookup',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <Lookup ref={ componentRef } { ...mergedProps } />

})

export default LookupContainer
