import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ deposit }) => {

  const details = {
    audits: `finance_deposits/${deposit.id}`,
    comments: `finance_deposits/${deposit.id}`,
    sections: [
      {
        title: 't(Deposit Details)',
        items: [
          { label: 't(Date)', content: deposit.date, format: 'date' },
          { label: 't(Bank Account)', content: deposit.bank.title },
          { label: 't(Total)', content: deposit.total, format: 'currency' },
          { label: 'Fee', content: deposit.fee, format: 'currency' },
          { label: 't(Amount)', content: deposit.amount, format: 'currency' }
        ]
      }
    ]
  }

  if(deposit.status === 'pending') {
    details.alert = { color: 'teal', message: 't(This deposit is pending export)' }
  } else if(deposit.status === 'exported') {
    details.alert = { color: 'violet', message: 't(This deposit was exported)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  deposit: PropTypes.object
}

export default Overview
