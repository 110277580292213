import Page from '@admin/components/page'
import Fields from '@admin/components/fields'
import Overview from './overview'
import React from 'react'
import Edit from './edit'

const mapPropsToPage = (props, context, resources) => ({
  title: resources.type.title,
  tabs: {
    items: [
      { label: 't(Overview)', component: <Overview { ...resources.type } /> },
      { label: 't(Fields)', component: <Fields parent_type="sites_types" parent_id={ props.params.id } datasources={ resources.types.map(type => ({
        label: type.title,
        endpoint: `/api/admin/sites/sites/${props.params.site_id}/types/${type.id}/items`,
        value: 'id',
        text: 't(title)',
        type_id: type.id
      })) } /> }
    ]
  },
  tasks: {
    items: [
      {
        label: 'Edit Type',
        modal: () => <Edit site_id={ props.params.site_id } id={ props.params.id } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  types: `/api/admin/sites/sites/${props.params.site_id}/types`,
  type: `/api/admin/sites/sites/${props.params.site_id}/types/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
