import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class ProgressPie extends React.Component {

  static propTypes = {
    completed: PropTypes.number,
    title: PropTypes.string,
    total: PropTypes.number
  }

  state = {
    started: null
  }

  render() {
    const { completed, title, total } = this.props
    return (
      <div className="maha-progress-pie">
        <div className="pie" style={ this._getStyle() }>
          { completed ?
            <div className="maha-progress-pie-text">
              { numeral(completed / total).format('0%') }<br/>
              <span>{ completed } / { total }</span>
            </div> :
            <Icon icon="hourglass-start" />
          }
        </div>
        <h3><T text={ title } /></h3>
        <p>{ this._getText() }</p>
      </div>
    )
  }

  _getStyle() {
    const { completed, total } = this.props
    const degrees = (completed / total) * 360
    return {
      background: `conic-gradient(#3498db ${degrees}deg, #ecf0f1 ${degrees}deg)`
    }
  }

  componentDidMount() {
    this.setState({
      started: performance.now()
    })
  }

  _getRemaining() {
    const { completed, total } = this.props
    const { started } = this.state
    const elapsed = performance.now() - started
    const remaining = (elapsed / completed) * (total - completed)
    const seconds = Math.floor(remaining / 1000)
    const minutes = Math.floor(seconds / 60)
    return `${minutes}:${(seconds % 60).toString().padStart(2, '0')}`
  }

  _getText() {
    const { completed, total } = this.props
    if(!completed) return <T text="preparing" />
    const percent = completed / total
    if(percent < 0.02) return ''
    return <>{ this._getRemaining() } <T text="remaining" /></>
  }

}

export default ProgressPie
