import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    store: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { store } = this.props
    return {
      title: 't(Edit Store)',
      endpoint: `/api/admin/stores/stores/${store.id}/edit`,
      action: `/api/admin/stores/stores/${store.id}`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(URL)', name: 'permalink', type: 'permalinkfield', prefix: '/stores', placeholder: '/path/to/store', destination: `/stores/stores/${store.code}`  }
          ]
        }, {
          label: 't(Options)',
          fields: [
            { label: 'Cart Lifetime', name: 'config.cart_lifetime', type: 'numberfield', instructions: `
              Duration in hours before a cart expires and is considered 'abandoned'
            ` },
            { label: 'Pay by Check', type: 'segment', fields: [
              { prompt: 'Enable Pay by Check', name: 'config.check_enabled', type: 'checkbox' },
              ...this._getCheck()
            ] },
            { label: 'Pay Later', type: 'segment', fields: [
              { prompt: 'Enable Pay Later', name: 'config.async_enabled', type: 'checkbox' },
              ...this._getAsync()
            ] },
            { label: 'Store Opening', type: 'segment', fields: [
              { name: 'config.open', type: 'radiogroup', options: [
                { value: 'open', text: 't(Store is open)' },
                { value: 'limit', text: 't(Store opens at a specific time)' }
              ], deselectable: false, defaultValue: 'open' },
              ...this._getOpen()
            ] },
            { label: 'Store Closing', type: 'segment', fields: [
              { name: 'config.close', type: 'radiogroup', options: [
                { value: 'open', text: 't(Store never closes)' },
                { value: 'limit', text: 't(Store closes at a specific time)' }
              ], deselectable: false, defaultValue: 'open' },
              ...this._getClosed()
            ] },
            { label: 'Confirmation Message', name: 'config.confirmation', type: 'textarea', defaultValue: 'Thank you for your purchase' }
          ]
        }
      ]
    }
  }

  _getAsync() {
    const { config } = this.state
    return [
      { name: 'config.async_text', type: 'textfield', show: _.get(config, 'config.async_enabled') === true, placeholder: 't(Enter payment text (e.g. Pay at Pickup / Pay at Register))', required: true }
    ]
  }

  _getCheck() {
    const { config } = this.state
    return [
      { name: 'config.check_text', type: 'textarea', show: _.get(config, 'config.check_enabled') === true, placeholder: 't(Enter instructions for sending check)', required: true }
    ]
  }

  _getClosed() {
    const { config } = this.state
    return [
      { label: 'Closes At', name: 'config.closes_at', show: _.get(config, 'config.close') === 'limit', type: 'datetimefield', required: true },
      { label: 'Once Closed', name: 'config.close_strategy', show: _.get(config, 'config.close') === 'limit', type: 'radiogroup', options: [
        { value: 'message', text: 't(Show Message)' },
        { value: 'redirect', text: 't(Redirect to URL)' }
      ], deselectable: false, defaultValue: 'message' },
      { label: 't(URL)', name: 'config.close_redirect', show: _.get(config, 'config.close') === 'limit' && _.get(config, 'config.close_strategy') === 'redirect', type: 'textfield', placeholder: 'http://', required: true },
      { label: 't(Message)', name: 'config.close_message', show: _.get(config, 'config.close') === 'limit' && _.get(config, 'config.close_strategy') === 'message', type: 'htmlfield', required: true }
    ]
  }

  _getOpen() {
    const { config } = this.state
    return [
      { label: 'Closes At', name: 'config.opens_at', show: _.get(config, 'config.open') === 'limit', type: 'datetimefield', required: true },
      { label: 'Once Closed', name: 'config.open_strategy', show: _.get(config, 'config.open') === 'limit', type: 'radiogroup', options: [
        { value: 'message', text: 't(Show Message)' },
        { value: 'redirect', text: 't(Redirect to URL)' }
      ], deselectable: false, defaultValue: 'message' },
      { label: 't(URL)', name: 'config.close_redirect', show: _.get(config, 'config.open') === 'limit' && _.get(config, 'config.open_strategy') === 'redirect', type: 'textfield', placeholder: 'http://', required: true },
      { label: 't(Message)', name: 'config.close_message', show: _.get(config, 'config.open') === 'limit' && _.get(config, 'config.open_strategy') === 'message', type: 'htmlfield', required: true }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(store) {
    this.context.modal.close()
  }

}

export default Edit
