import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import { canAccess } from '@core/utils/access'
import Button from '@admin/components/button'
import Content from '../tokens/content'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Response extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    actions: PropTypes.array,
    activity: PropTypes.object,
    enrollment: PropTypes.object,
    form: PropTypes.object,
    program: PropTypes.object,
    response: PropTypes.object,
    workflow: PropTypes.object
  }

  render() {
    const { actions, enrollment, response, workflow } = this.props
    const { admin } = this.context
    const fields = this._getFields()
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Form</td>
                <td><Button { ...this._getForm() } /></td>
              </tr>
              { fields.map((field, index) => (
                <tr key={`field_${index}`}>
                  <td>{ field.name.value }</td>
                  <td>
                    <Content data={ response.data } field={ field } />
                  </td>
                </tr>
              )) }
              { canAccess({ rights: { $oneOf: ['finance:manage_revenue', 'finance:view_revenue'] } }, admin) &&
                <tr>
                  <td>Revenue</td>
                  <td>{ numeral(response.revenue).format('$0.00') }</td>
                </tr>            
              }
              <tr>
                <td>Response</td>
                <td><Button { ...this._getResponse() } /></td>
              </tr>
              { response.invoice_id &&
                <tr>
                  <td>Invoice</td>
                  <td><Button { ...this._getInvoice() } /></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="crm-timeline-card-section-footer">
          <Actions enrollment={ enrollment } actions={ actions } trigger={ workflow.trigger } />
          <Comments entity={`forms_responses/${response.id}`} />
        </div>
      </div>
    )
  }

  _getFields() {
    const { form } = this.props
    return form.config.fields.filter(field => {
      return field.type !== 'text'
    })
  }

  _getForm() {
    const { form } = this.props
    return {
      label: form.title,
      className: 'link',
      route: `/admin/forms/forms/${form.id}`
    }
  }

  _getInvoice() {
    const { response } = this.props
    return {
      label: 'View Invoice',
      className: 'link',
      route: `/admin/finance/invoices/${response.invoice_id}`
    }
  }

  _getResponse() {
    const { form, response } = this.props
    return {
      label: 'View Response',
      className: 'link',
      route: `/admin/forms/forms/${form.id}/responses/${response.id}`
    }
  }

}

const mapResources = (props, context) => ({
  actions: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}/actions`,
  enrollment: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}`,
  form: `/api/admin/forms/forms/${props.activity.data.form_id}`,
  response: `/api/admin/forms/forms/${props.activity.data.form_id}/responses/${props.activity.data.response_id}`,
  workflow: `/api/admin/automation/workflows/${props.activity.data.workflow_id}`
})

export default Container(mapResources)(Response)
