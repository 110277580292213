import ImageField from '../../components/imagefield'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class SEO extends React.Component {

  static propTypes = {
    config: PropTypes.object,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onUpdate: PropTypes.func
  }

  state = {
    config: null
  }

  _handleChange = this._handleChange.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleReset = this._handleReset.bind(this)

  render() {
    if(!this.state.config) return null
    return <Form { ...this._getForm() } />
  }

  componentDidMount() {
    this.setState({
      config: {
        ...this._getDefault(),
        ...this.props.config.seo
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { config } = this.state
    if(!_.isEqual(config, prevState.config)) {
      this.props.onUpdate('seo', config)
    }
  }

  _getForm() {
    const { config } = this.state
    return {
      title: 't(SEO)',
      onCancel: this._handleDone,
      onChange: this._handleChange,
      cancelIcon: 'chevron-left',
      saveText: null,
      buttons: [
        { label: 't(Done)', color: 'blue', handler: this._handleDone }
      ],
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', defaultValue: config.title },
            { label: 't(Description)', name: 'description', type: 'textarea', maxLength: 55, rows: 1, defaultValue: config.description },
            { label: 't(Image)', name: 'image', type: ImageField, defaultValue: config.image, ratio: 1.9 }
          ]
        }
      ]
    }
  }

  _getDefault() {
    return {
      title: '',
      permalink: '',
      description: '',
      image: null
    }
  }

  _handleChange(config) {
    this.setState({
      config: {
        ...this.state.config,
        ...config
      }
    })
  }

  _handleDone() {
    this.props.onPop()
  }

  _handleReset() {
    this.setState({
      config: this._getDefault()
    })
  }

}

export default SEO
