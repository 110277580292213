import ExpenseStrategyToken from '../../tokens/expense_strategy'
import DriveStrategyToken from '../../tokens/drive_strategy'
import TeamStrategyToken from '../../tokens/team_strategy'
import UserToken from '@admin/tokens/user'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Disable extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    maha: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    user: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config } = this.state
    const { user } = this.props
    return {
      title: 't(Disable User)',
      method: 'PATCH',
      endpoint: `/api/admin/team/users/${user.id}/edit`,
      action: `/api/admin/team/users/${user.id}/disable`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Team)', name: 'team_strategy', type: 'radiogroup', options: ['remove','nothing'], format: TeamStrategyToken, defaultValue: 'remove' },
            { label: 't(Expenses)', name: 'expenses_strategy', type: 'radiogroup', options: ['remove','nothing'], format: ExpenseStrategyToken, defaultValue: 'remove'  },
            { label: 't(Drive)', name: 'drive_strategy', type: 'radiogroup', options: ['nothing','transfer','delete'], format: DriveStrategyToken, defaultValue: 'nothing' },
            { label: 't(Reassign Drive Items To)', name: 'drive_to_user_id', type: 'lookup', show: config.drive_strategy !== 'transfer', prompt: 't(Choose User)', endpoint: '/api/admin/users', valueKey: 'id', textKey: 'full_name', format: UserToken }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Disable
