import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Button from '@admin/components/button'
import Details from '@admin/components/details'
import Braintree from './braintree'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ bank, integration }) => {

  const items = [
    { label: 't(Title)', content: bank.title },
    { label: 't(Bank Name)', content: bank.bank_name },
    { label: 't(Routing Number)', content: bank.routing_number },
    { label: 't(Account Number)', content: bank.account_number }
  ]

  if(bank.braintree_id) {

    const braintree = {
      label: bank.braintree_id,
      className: 'link',
      link: bank.braintree_link
    }

    items.push({ label: 't(Braintree ID)', content: <Button { ...braintree} /> })
    items.push({ label: 't(Credit Card Rate)', content: bank.rate, format: 'percent' })
    items.push({ label: 't(Amex Rate)', content: bank.amex_rate , format: 'percent'})
    items.push({ label: 't(ACH Rate)', content: bank.ach_rate, format: 'percent' })
    items.push({ label: 't(PayPal)', content: bank.has_paypal, format: 'yes_no' })

  } else if(bank.stripe_id) {

    const stripe = {
      className: 'link',
      label: bank.stripe_id,
      link: bank.stripe_link
    }

    items.push({ label: 't(Stripe ID)', content: <Button { ...stripe } /> })

  } else {
    items.unshift({ component: <Braintree bank={ bank }/> })
  }

  const details = {
    sections: [
      { 
        title: 't(Bank Details)',
        items 
      }
    ]
  }

  if(integration) {
    details.sections.push({
      title: <IntegrationToken integration={ integration } />,
      padded: false,
      items: [
        { label: 't(Bank Code)', content: bank.integration.bank_code }
      ]
    })
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  bank: PropTypes.object,
  integration: PropTypes.string
}

export default Overview
