import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    number: PropTypes.object
  }

  state = {
    formdata: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    const { formdata } = this.state
    const { number } = this.props
    return {
      title: 't(Edit Phone Number)',
      method: 'PATCH',
      endpoint: `/api/admin/phone/numbers/${number.id}`,
      action: `/api/admin/phone/numbers/${number.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Hold Music)', type: 'segment', fields: [
              { name: 'hold_strategy', type: 'radiogroup', options: [
                { value: 'stock', text: 't(Use stock hold music)' },
                { value: 'custom', text: 't(Use custom hold music)' }
              ], defaultValue: 'stock' },
              ...formdata.hold_strategy === 'stock' ? [
                { name: 'stock_hold_music', type: 'lookup', options: [
                  { value: 'busystrings', text: 'Busy Strings' },
                  { value: 'clockworkwaltz', text: 'Clock Work Waltz' },
                  { value: 'flightofyounghearts', text: 'Flight of Young Hearts' },
                  { value: 'chopin', text: 'Chopin' },
                  { value: 'endlessgoodbye', text: 'Endless Goodbye' }
                ], defaultValue: 'busystrings' }
              ] : [
                { name: 'custom_hold_music_id', type: 'attachmentfield', prompt: 't(Choose Audio File)', allow: { content_types: 'audio' } }
              ]
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(formdata) {
    this.setState({ formdata })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
