import EnvelopeToken from '@apps/email/admin/tokens/envelope'
import Touch from '@admin/components/touch'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class Results extends React.Component {

  static contextTypes = {
    router: PropTypes.object,
    tasks: PropTypes.object
  }

  static propTypes = {
    active: PropTypes.object,
    folder: PropTypes.object,
    isOverscrolled: PropTypes.bool,
    records: PropTypes.array,
    selected: PropTypes.array,
    onActive: PropTypes.func,
    onFlag: PropTypes.func,
    onMark: PropTypes.func,
    onMove: PropTypes.func,
    onSelect: PropTypes.func,
    onTasks: PropTypes.func
  }

  render() {
    const grouped = this._getGrouped()
    return Object.keys(grouped).map((group, i) => (
      <Fragment key={`group_${i}`}>
        { grouped[group].length > 0 ?
          <>
            <div className="email-client-list-result-header">
              { group.replace('_', ' ') }
            </div>
            { grouped[group].map((record, j) => (
              <Touch { ...this._getTouch(record.email) } key={`email_${i}_${j}`}>
                <div { ...this._getEmail(record.email, record.index) }>
                  <EnvelopeToken { ...this._getEnvelope(record)} />
                </div>
              </Touch>
            ))}
          </> :
          null
        }
      </Fragment>
    ))
  }

  _getClass(email_id) {
    const { active, selected } = this.props
    const classes = ['email-client-list-result']
    if(selected.find(email => email.id === email_id) !== undefined) classes.push('selected')
    if(active && active.id === email_id) classes.push('active')
    return classes.join(' ')
  }

  _getEnvelope(record) {
    const { folder } = this.props
    return {
      email: record.email,
      perspective: folder.alias === 'sent' ? 'to' : 'from',
      showFolder: folder.alias === 'search'
    }
  }

  _getEmail(email, index) {
    return {
      className: this._getClass(email.id),
      onClick: this._handleChoose.bind(this, email, index),
      onContextMenu: this._handleTasks.bind(this, email)
    }
  }

  _getGrouped() {
    const { records } = this.props
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const this_week = moment().startOf('week').startOf('day')
    const last_week = moment().startOf('week').subtract(1, 'week').startOf('day')
    return records.reduce((grouped, email, index) => ({
      ...grouped,
      ...moment(email.sent_at).isAfter(today) ? {
        today: [
          ...grouped.today,
          { email, index }
        ]
      } : {
        ...moment(email.sent_at).isBetween(yesterday, today) ? {
          yesterday: [
            ...grouped.yesterday,
            { email, index }
          ]
        } : {
          ...moment(email.sent_at).isBetween(this_week, yesterday) ? {
            this_week: [
              ...grouped.this_week,
              { email, index }
            ]
          } : {
            ...moment(email.sent_at).isBetween(last_week, this_week) ? {
              last_week: [
                ...grouped.last_week,
                { email, index }
              ]
            } : {
              ...moment(email.sent_at).isBefore(last_week) ? {
                older: [
                  ...grouped.older,
                  { email, index }
                ]
              } : {}
            }
          }
        }
      }
    }), { today: [], yesterday: [], this_week: [], last_week: [], older: [] })
  }

  _getTouch(email) {
    const { isOverscrolled } = this.props
    return {
      disabled: isOverscrolled,
      onHold: this._handleTasks.bind(this, email)
    }
  }

  _handleChoose(email, index, e) {
    const { selected } = this.props
    const { metaKey, shiftKey } = e
    const value = { id: email.id, profile_id: email.profile_id }
    if(metaKey) return this._handleMetaSelect(value)
    if(shiftKey && selected.length > 0) return this._handleShiftSelect(index)
    if(document.body.clientWidth > 1024) return this._handleSelect(value)
    this.context.router.push(`/admin/email/${email.profile_id}/${email.id}/view`)
  }

  _handleFlag(emails, is_flagged) {
    this.props.onFlag(emails, is_flagged)
  }

  _handleMark(emails, is_read) {
    this.props.onMark(emails, is_read)
  }

  _handleMetaSelect(email) {
    const { selected } = this.props
    this.props.onSelect([
      ..._.xor(selected, [email])
    ])
  }

  _handleMove(emails, to) {
    const { folder } = this.props
    this.props.onMove(emails, folder.alias, to)
  }

  _handleSelect(email) {
    const { selected } = this.props
    const included = selected.find(item => item.id === email.id)
    this.props.onSelect(!included ? [email] : [])
  }

  _handleShiftSelect(index) {
    const { records, selected } = this.props
    const lastIndex = records.findIndex(email => {
      return email.id === selected[selected.length - 1].id
    })
    const min = Math.min(index, lastIndex)
    const max = Math.max(index, lastIndex)
    this.props.onSelect([
      ...records.filter((email, i) => {
        return i >= min && i <= max
      }).map(email => ({
        id: email.id,
        profile_id: email.profile_id
      }))
    ])
  }

  _getAreAll(selected, key, value) {
    const { records } = this.props
    const ids = selected.map(r => r.id)
    return records.filter(record => {
      return _.includes(ids, record.id) && record[key] === value
    }).length === selected.length
  }

  _handleTasks(email, e) {
    if(e.cancelable) e.preventDefault()
    const { folder, records, selected } = this.props
    const active = { id: email.id, profile_id: email.profile_id }
    const included = selected.find(item => item.id === active.id)
    if(!included) this.props.onActive(active)
    const emails = included ? selected : [active]
    const allFlagged = this._getAreAll(emails, 'is_flagged', true)
    const allUnflagged = this._getAreAll(emails, 'is_flagged', false)
    const allRead = this._getAreAll(emails, 'is_read', true)
    const allUnread = this._getAreAll(emails, 'is_read', false)
    const first = records.find(record => {
      return record.id === emails[0].id
    })
    this.context.tasks.open({
      title: (
        <div className="email-client-task-header">
          { emails.length > 1 ?
            <>With { emails.length } selected emails</> :
            <>{ first.subject }</>
          }
        </div>
      ),
      items: [
        { label: 'Flag', show: allUnflagged || !allFlagged, handler: this._handleFlag.bind(this, emails, true) },
        { label: 'Unflag', show: allFlagged || !allUnflagged, handler: this._handleFlag.bind(this, emails, false) },
        { label: 'Mark as Read', show: allUnread || !allRead, handler: this._handleMark.bind(this, emails, true) },
        { label: 'Mark as Unread', show: allRead || !allUnread, handler: this._handleMark.bind(this, emails, false) },
        { label: 'Move to Inbox', show: folder.alias === 'trash', handler: this._handleMove.bind(this, emails, 'focused') },
        { label: 'Move to Trash', show: folder.alias !== 'trash', handler: this._handleMove.bind(this, emails, 'trash') }
      ],
      onDone: () => this.props.onActive(null)
    })
  }

}

export default Results
