import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  card: {
    title: 't(Charge)',
    description: 't(Charge the cusomter each cycle)'
  },
  invoice: {
    title: 't(Invoice)',
    description: 't(Send an invoice to the cusomter each cycle and receive payment asynchronously)'
  }
}

const PaymentStrategyToken = ({ value }) => (
  <div className="token">
    <strong><T text={ strategies[value].title } /></strong><br />
    <T text={ strategies[value].description } />
  </div>
)

PaymentStrategyToken.propTypes = {
  value: PropTypes.string
}

export default PaymentStrategyToken
