import Virtualized from '@admin/components/virtualized'
import Searchbox from '@admin/components/searchbox'
import PropTypes from 'prop-types'
import React from 'react'

class Unassigned extends React.Component {

  static propTypes = {
    assigneeFormat: PropTypes.any,
    unassigned: PropTypes.array,
    onChoose: PropTypes.func,
    onSetQuery: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <div className="maha-assignment-unassigned">
        <div className="maha-assignment-unassigned-header">
          <Searchbox { ...this._getSearchbox() } />
        </div>
        <div className="maha-assignment-unassigned-body">
          <Virtualized { ...this._getVirtualized() } />
        </div>
      </div>
    )
  }

  rowRender(index) {
    const { unassigned, assigneeFormat } = this.props
    return (
      <div className="maha-assignment-unassigned-item" onClick={ this._handleChoose.bind(this, unassigned[index]) }>
        { assigneeFormat(unassigned[index]) }
      </div>
    )
  }

  _getSearchbox() {
    return {
      prompt: 't(Find a user or group)',
      onChange: this.props.onSetQuery
    }
  }

  _getVirtualized() {
    const { unassigned } = this.props
    return {
      rowCount: unassigned.length,
      rowHeight: 50,
      rowRender: this.rowRender.bind(this)
    }
  }

  _handleChoose(assignee) {
    this.props.onChoose(assignee)
  }

}

export default Unassigned
