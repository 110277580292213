import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  ignore: {
    title: 't(Ignore)',
    description: 't(Ignore duplicate records)'
  },
  overwrite: {
    title: 't(Merge & Overwrite)',
    description: 't(Merge duplicates, overwriting existing fields with data from import)'
  },
  discard: {
    title: 't(Merge & Discard)',
    description: 't(Merge duplicates, discarding data that conflicts with existing fields)'
  }
}

const ImportStrategyToken = ({ value }) =>{
  const strategy = strategies[value]
  return (
    <div className="token">
      <strong><T text={ strategy.title } /></strong><br />
      <T text={ strategy.description } />
    </div>
  )
}

ImportStrategyToken.propTypes = {
  value: PropTypes.string
}

export default ImportStrategyToken
