import PropTypes from 'prop-types'
import React from 'react'

export const PresenceContext = React.createContext()
PresenceContext.displayName = 'PresenceContext'

export const usePresenceContext = () => React.useContext(PresenceContext)

class Presence extends React.Component {

  static childContextTypes = {
    presence: PropTypes.object
  }

  static contextTypes = {
    admin: PropTypes.object,
    host: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.any,
    connected: PropTypes.bool,
    presence: PropTypes.array,
    onSetPresence: PropTypes.func
  }

  _handleBlurFocus = this._handleBlurFocus.bind(this)
  _handlePresence = this._handlePresence.bind(this)
  _handleReconnect = this._handleReconnect.bind(this)
  _handleSignin = this._handleSignin.bind(this)
  _handleSignout = this._handleSignout.bind(this)
  _handleStatus = this._handleStatus.bind(this)

  render() {
    return (
      <PresenceContext.Provider value={ this.getChildContext() }>
        { this.props.children }
      </PresenceContext.Provider>
    )
  }

  componentDidMount() {
    window.addEventListener('blur', this._handleBlurFocus, false)
    window.addEventListener('focus', this._handleBlurFocus, false)
    this.context.network.listen('presence', this._handlePresence)
    this._handleSignin()
  }

  componentDidUpdate(prevProps) {
    const { connected } = this.props
    if(connected !== prevProps.connected && connected) {
      this._handleReconnect()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this._handleBlurFocus, false)
    window.removeEventListener('focus', this._handleBlurFocus, false)
    this.context.network.unlisten('presence', this._handlePresence)
    this._handleSignout()
  }

  getChildContext() {
    const { presence } = this.props
    return {
      presence: {
        presence
      }
    }
  }

  _getStatus() {
    const { host } = this.context
    return host.device.getFocus() ? 'active' : 'absent'
  }

  _handleBlurFocus() {
    const status = this._getStatus()
    this._handleStatus(status)
  }

  _handleEmit(verb, data) {
    this.context.network.emit(verb, data)
  }

  _handlePresence(presence) {
    this.props.onSetPresence(presence)
  }

  _handleReconnect() {
    const status = this._getStatus()
    this._handleEmit('signin', { status })
  }

  _handleSignin() {
    const { host } = this.context
    const status = host.device.getFocus() ? 'active' : 'absent'
    this._handleEmit('signin', { status })
  }

  _handleSignout() {
    this._handleEmit('signout')
  }

  _handleStatus(status) {
    this._handleEmit('status', { status })
  }

}

export default Presence
