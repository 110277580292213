import { useMemo } from 'react'

const _getType = (attachment) => {
  if(!attachment.asset) return 'files'
  if(attachment.asset.content_type.match(/(jpeg|jpg|gif|png)/)) return 'images'
  if(attachment.asset.content_type.match(/video/)) return 'media'
  if(attachment.asset.content_type.match(/audio/)) return 'media'
  return 'files'
}

export const sorted = (state, props) => {
  return useMemo(() => {
    return props.attachments.reduce((sorted, attachment) => {
      const type = _getType(attachment)
      return {
        ...sorted,
        [type]: [
          ...sorted[type],
          attachment
        ]
      }
    }, { files: [], images: [], media: [] })
  }, [props.attachments])
}
