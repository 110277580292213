import PropTypes from 'prop-types'
import React from 'react'

const Token = ({ name }) => (
  <>{ name }</>)

Token.propTypes = {
  name: PropTypes.string
}

export default Token
