import Workflows from './automation/workflows'
import EmailCampaigns from './email_campaigns'
import VoiceCampaigns from './voice_campaigns'
import PaymentMethods from './payment_methods'
import Subscriptions from './subscriptions'
import SMSCampaigns from './sms_campaigns'
import Page from '@admin/components/page'
import Emails from './automation/emails'
import Renewals from './renewals'
import Overview from './overview'
import Plans from './plans'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.service.title,
  manager: {
    path: `/admin/subscriptions/services/${resources.service.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview service={ resources.service } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows service={ resources.service } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails service={ resources.service } />
          }
        ]
      },
      {
        label: 't(Campaigns)',
        access: { rights: { $oneOf: ['campaigns:access_app'] } },
        children: [
          {
            label: 't(Email)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_email_campaigns','campaigns:manage_email_campaigns'] } }
              ] 
            },
            path: '/email_campaigns',
            panel: <EmailCampaigns service={ resources.service } />
          },
          {
            label: 't(SMS)',
            access: { 
              $and: [
                { rights: { $allOf: ['campaigns:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_sms_campaigns','campaigns:manage_sms_campaigns'] } }
              ] 
            },
            path: '/sms_campaigns',
            panel: <SMSCampaigns service={ resources.service } />
          },
          {
            label: 't(Voice)',
            access: { 
              $and: [
                { rights: { $allOf: ['phone:access_app','phone:access_app'] } },
                { rights: { $oneOf: ['campaigns:view_voice_campaigns','campaigns:manage_voice_campaigns'] } }
              ] 
            },
            path: '/voice_campaigns',
            panel: <VoiceCampaigns service={ resources.service } />
          }
        ]
      },
      {
        label: 't(Credit Cards)',
        path: '/payment_methods',
        panel: <PaymentMethods service={ resources.service } />
      },
      {
        label: 't(Plans)',
        path: '/plans',
        panel: <Plans service={ resources.service } />
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        panel: <Subscriptions service={ resources.service } />
      },
      {
        label: 't(Renewals)',
        path: '/renewals',
        panel: <Renewals service={ resources.service } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  service: `/api/admin/subscriptions/services/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
