import PropTypes from 'prop-types'
import React from 'react'

const ColorSetToken = ({ option }) => {
  const { value }  = option
  return (
    <div className="colorset-token">
      <div className="colorset-swatches">
        <div className="colorset-swatch" style={{ backgroundColor: value.light }} />
        <div className="colorset-swatch" style={{ backgroundColor: value.accent1 }} />
        <div className="colorset-swatch" style={{ backgroundColor: value.base }} />
        <div className="colorset-swatch" style={{ backgroundColor: value.accent2 }} />
        <div className="colorset-swatch" style={{ backgroundColor: value.dark }} />
      </div>
    </div>
  )
}

ColorSetToken.propTypes = {
  option: PropTypes.object
}

export default ColorSetToken
