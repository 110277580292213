import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: entity.engine === 'mblocks' ? 'mblocks' : 'mjson',
  entity: `crm_templates/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const EmailTemplateToken = ({ template }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(template) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { template.title } { template.is_default &&
          <>(<span className="alert">default</span>)</>
        }
      </div>
    </div>
  </div>
)

EmailTemplateToken.propTypes = {
  template: PropTypes.object
}

export default EmailTemplateToken
