import Search from '@admin/components/search'
import ContactToken from './contact_token'
import PropTypes from 'prop-types'
import React from 'react'

class Contact extends React.PureComponent {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return <Search { ...this._getSearch() } />
  }

  _getSearch() {
    const { contact1_id } = this.props.formdata
    return {
      autofocus: true,
      endpoint: '/api/admin/crm/contacts',
      filter: {
        id: { $neq: contact1_id }
      },
      format: ContactToken,
      label: 't(Contact)',
      onChange: this._handleChoose,
      valueKey: null
    }
  }

  _handleChoose(contact) {
    this.context.network.request({
      endpoint: `/api/admin/crm/contacts/${contact.id}`,
      method: 'GET',
      onSuccess: ({ data }) => {
        this.props.onNext({
          contact2_id: data.id
        })
      }
    })
  }

}

export default Contact
