import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Notifications from './notifications'
import PropTypes from 'prop-types'
import Question from './question'
import Password from './password'
import Complete from './complete'
import Welcome from './welcome'
import Answer from './answer'
import Avatar from './avatar'
import Verify from './verify'
import Mobile from './mobile'
import React from 'react'

class Activate extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    flash: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {}

  state = {
    account: null,
    direction: 'forward',
    mode: 'verify',
    notification_methods: null,
    previous: null,
    security_questions: null,
    security_question_id: null,
    token: null,
    team: null,
    user: null
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    const { direction, mode } = this.state
    if(!mode) return null
    const Component = this._getComponent()
    return (
      <div className={`maha-signin ${direction}`}>
        <TransitionGroup component={ null }>
          <CSSTransition key={ mode } timeout={ 500 } classNames="slide" appear={ false }>
            <Component  { ...this._getProps() } />
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }

  _getComponent() {
    const { mode } = this.state
    if(mode === 'verify') return Verify
    if(mode === 'welcome') return Welcome
    if(mode === 'question') return Question
    if(mode === 'answer') return Answer
    if(mode === 'password') return Password
    if(mode === 'mobile') return Mobile
    if(mode === 'avatar') return Avatar
    if(mode === 'notifications') return Notifications
    if(mode === 'complete') return Complete
  }

  _getProps() {
    return {
      ...this.state,
      onBack: this._handleBack,
      onDone: this._handleDone,
      onNext: this._handleNext
    }
  }

  _getNextMode(mode) {
    const { account } = this.state
    const modes = ['verify','welcome','question','answer','password','mobile','avatar','notifications','complete']
    if(mode === 'welcome' && account.activated_at) return this._getNextMode('password')
    if(mode === 'password' && account.mobile_phone) return this._getNextMode('avatar')
    const index = modes.findIndex((m) => m === mode)
    return modes[index+1]
  }
 
  _handleBack() {
    const { previous } = this.state
    this.setState({
      direction: 'backward',
      mode: previous
    })
  }

  _handleDone() {
    const { account, team } = this.state
    this.context.admin.signin(account, team.id)
  }
  
  _handleNext(values) {
    const { mode } = this.state
    this.setState({
      ...values || {},
      direction: 'forward',
      mode: this._getNextMode(mode),
      previous: this.state.mode
    })
  }

}

export default Activate
