import OrderToken from '@apps/stores/admin/tokens/order'
import Panel from '@admin/components/panel'
import Fulfill from '../orders/fulfill'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Fulfillment)',
  collection: {
    endpoint: `/api/admin/stores/stores/${props.store.id}/orders`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: OrderToken },
      { label: 'Unfulfilled', key: 'unfulfilled_count', collapsing: true, align: 'right', format: (order) => (
        <>{ order.unfulfilled_count } / { order.items_count }</>
      ) }
    ],
    empty: {
      icon: 'shopping-bag',
      title: 't(No orders)',
      text: 't(There are no unfilfilled orders for this store)'
    },
    entity: 'order',
    defaultFilter: {
      unfulfilled_count: {
        $gt: 0
      }
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    onClick: (record) => context.router.push(`/stores/stores/${props.store.id}/orders/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
