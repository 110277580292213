import Page from '@admin/components/page'
import Overview from './overview'
import Emails from './emails'
import React from 'react'
import SMS from './sms'

const mapPropsToPage = (props, context, resources, page) => ({
  title: `Response #${resources.response.id}`,
  manager: {
    path: `/admin/forms/forms/${resources.form.id}/responses/${resources.response.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview form={ resources.form } contact_id={ resources.response.contact.id } response={ resources.response } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails form={ resources.form } response={ resources.response } />
      },
      {
        label: 't(SMS)',
        path: '/sms',
        panel: <SMS form={ resources.form } response={ resources.response } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  form: `/api/admin/forms/forms/${props.params.form_id}`,
  response: `/api/admin/forms/forms/${props.params.form_id}/responses/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
