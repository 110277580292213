import ModalPanel from '@admin/components/modal_panel'
import Search from '@admin/components/search'
import PropTypes from 'prop-types'
import Results from './results'
import React from 'react'

class Videos extends React.PureComponent {

  static propTypes = {
    device: PropTypes.object,
    root_endpoint: PropTypes.string,
    website: PropTypes.object,
    onBack: PropTypes.func,
    onNavigate: PropTypes.func,
    onSettings: PropTypes.func
  }

  _handleChoose = this._handleChoose.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Search { ...this._getSearch() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      showHeader: false,
      buttons: [
        { label: 'Import Video', color: 'blue', handler: () => {} }
      ]
    }
  }

  _getSearch() {
    const { root_endpoint } = this.props
    return {
      endpoint: `/api/admin${root_endpoint}/videos`,
      layout: Results,
      label: 'Video',
      valueKey: null,
      textKey: 'title',
      onChange: this._handleChoose,
      props: {
        type: 'video'
      }
    }
  }

  _handleChoose(item) {
    this.props.onSettings(null)
    this.props.onNavigate(`items/${item.id}`)
  }

}

export default Videos
