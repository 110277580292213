import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import React from 'react'

class Deal extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object,
    deal: PropTypes.object
  }

  render() {
    const { deal } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Title</td>
                <td>{ deal.title }</td>
              </tr>
              <tr>
                <td>Pipeline</td>
                <td>{ deal.pipeline.title }</td>
              </tr>
              <tr>
                <td>Stage</td>
                <td>{ deal.stage.title }</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>{ numeral(deal.amount).format('$0,0.00') }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

const mapResources = (props, context) => ({
  deal: `/api/admin/sales/deals/${props.activity.data.deal_id}`
})

export default Container(mapResources)(Deal)
