import PropTypes from 'prop-types'
import Lookup from './lookup'
import Field from './field'
import React from 'react'

class RecipientField extends React.PureComponent {

  static propTypes = {
    defaultValue: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    multiple: true,
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    lookup: false,
    q: '',
    recipients: []
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleClose = this._handleClose.bind(this)
  _handleLookup = this._handleLookup.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleType = this._handleType.bind(this)

  render() {
    const { lookup } = this.state
    return (
      <div className="maha-recipientfield">
        <Field { ...this._getField() } />
        { lookup && <Lookup { ...this._getLookup() } /> }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(defaultValue) this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { q, lookup, recipients } = this.state
    const { value } = this.props
    if(!lookup && q !== prevState.q && q.length > 0) {
      this._handleLookup(true)
    }
    if(!_.isEqual(recipients, prevState.recipients)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { value, defaultValue } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getField() {
    const { q, recipients } = this.state
    const { id, tabIndex } = this.props
    return {
      id,
      isFull: this._getIsFull(),
      q,
      recipients,
      tabIndex,
      onAdd: this._handleAdd,
      onMove: this._handleMove,
      onRemove: this._handleRemove,
      onType: this._handleType
    }
  }

  _getIsFull() {
    const { recipients } = this.state
    const { multiple } = this.props
    return !multiple && recipients.length > 0
  }

  _getLookup() {
    const { q, recipients } = this.state
    const { id, label, multiple, tabIndex } = this.props
    return {
      id,
      isFull: this._getIsFull(),
      label,
      multiple,
      q,
      recipients,
      tabIndex,
      onAdd: this._handleAdd,
      onClose: this._handleClose,
      onMove: this._handleMove,
      onRemove: this._handleRemove,
      onType: this._handleType
    }
  }

  _getNormalized(recipients) {
    return recipients ? _.castArray(recipients).map(recipient => {
      if(!_.isString(recipient)) return recipient
      return {
        name: null,
        address: recipient
      }
    }) : []
  }

  _handleAdd(recipient, at = null) {
    const { recipients } = this.state
    const index = at === null ? recipients.length : at
    this.setState({
      lookup: false,
      q: '',
      recipients: [
        ...recipients.slice(0, index),
        recipient,
        ...recipients.slice(index)
      ]
    })
  }

  _handleChange() {
    const { recipients } = this.state
    const { multiple } = this.props
    const value = (multiple ? recipients : recipients[0]) || null
    this.props.onChange(value)
  }

  _handleClose() {
    this.setState({
      lookup: false,
      q: ''
    })
  }

  _handleLookup(lookup) {
    this.setState({ lookup })
  }

  _handleMove(from, to) {
    const { recipients } = this.state
    this.setState({
      recipients: (from < to) ? [
        ...recipients.slice(0, from),
        ...recipients.slice(from + 1, to + 1),
        recipients[from],
        ...recipients.slice(to + 1)
      ] : [
        ...recipients.slice(0, to),
        recipients[from],
        ...recipients.slice(to, from),
        ...recipients.slice(from + 1)
      ]
    })
  }

  _handleRemove(index) {
    const { recipients } = this.state
    this.setState({
      recipients: [
        ...recipients.filter((recipient, i) => {
          return i !== index
        })
      ]
    })
  }

  _handleSet(value) {
    const recipients = this._getNormalized(value)
    this.setState({ recipients })
  }

  _handleType(q) {
    this.setState({ q })
  }

}

export default RecipientField
