import MJSONDesigner from '@admin/components/mjson_designer'
import { canAccess } from '@core/utils/access'
import Panel from '@admin/components/panel'
import React from 'react'

const getDesigner = (props, context) => ({
  editable: canAccess({ rights: { $allOf: ['forms:manage_forms'] } },context.admin),
  endpoint: `/forms_embeds/${props.embed.id}`,
  preview: `/mjson/dynamic/forms_embeds/${props.embed.code}`,
  root_route: `/forms/forms/${props.form.id}/embeds/${props.embed.id}/design`,
  themeable: true,
  type: 'page',
  versionable: true
})

const mapPropsToPanel = (props, context) => ({
  title: `${props.embed.title} - Design`,
  component: <MJSONDesigner { ...getDesigner(props, context) } />
})

export default Panel(null, mapPropsToPanel)
