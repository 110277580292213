import RoutingNumberField from '@apps/finance/admin/components/routingnumberfield'
import IntegrationToken from '@apps/finance/admin/tokens/integration'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    integration: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form {...this._getForm()} />
  }

  _getForm() {
    return {
      title: 't(New Bank Account)',
      method: 'POST',
      action: '/api/admin/finance/banks',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield',  required: true },
            { label: 't(Routing Number)', name: 'routing', type: RoutingNumberField, required: true },
            { label: 't(Account Number)', name: 'account_number', type: 'textfield', required: true }
          ]
        },
        ...this._getIntegration()
      ]
    }
  }

  _getIntegration() {
    const { integration } = this.props
    if(!integration) return []
    return [{
      label: <IntegrationToken integration={ integration } />,
      padded: false,
      fields: [
        { label: 't(Bank Code)', name: 'integration.bank_code', type: 'textfield' }
      ]
    }]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(invoice) {
    this.context.router.push(`/finance/banks/${invoice.id}`)
    this.context.modal.close()
  }

}

export default New
