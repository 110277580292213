import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Accounts from './accounts'
import React from 'react'
import New from './new'

class NewWrapper extends React.Component {

  static propTypes = {
    children: PropTypes.any,
    contact: PropTypes.object,
    deal: PropTypes.object,
    program: PropTypes.object,
    profiles: PropTypes.array
  }

  stackRef = React.createRef()

  _handleAccount = this._handleAccount.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { profiles } = this.props
    this.stackRef.current.push([
      { component: New, props: this._getProps.bind(this) },
      ...profiles.length === 0 ? [
        { component: Accounts, props: this._getProps.bind(this) }
      ] : []
    ])
  }

  _getProps() {
    const { contact, deal, profiles, program } = this.props
    return {
      contact,
      deal,
      profiles,
      program,
      onAccount: this._handleAccount,
      onPush: this._handlePush,
      onPop: this._handlePop
    }
  }

  _getStack() {
    return {
      display_name: 'agreements',
      ref: this.stackRef
    }
  }

  _handleAccount() {
    this._handlePush(Accounts, this._getProps.bind(this))
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

const mapResources = (props, context) => ({
  profiles: {
    endpoint: '/api/admin/profiles',
    refresh: `/accounts/${context.admin.account.id}/admin/profiles`,
    filter: {
      type: { $eq: 'signatures' }
    }
  }
})

export default Container(mapResources)(NewWrapper)
