import ContactChooser from '@apps/crm/admin/components/contact_chooser'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Details from './details'
import React from 'react'

class Contact extends React.Component {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  stackRef = React.createRef()

  _handleContact = this._handleContact.bind(this)
  _handleDetails = this._handleDetails.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)

  render() {
    return <Stack { ...this._getStack() } />
  }

  _getContactChooser() {
    return {
      onChoose: this._handleContact
    }
  }

  _getDetails(contact) {
    return {
      contact_id: contact.id,
      onBack: this._handlePop,
      onDone: this._handleDetails
    }
  }

  _getStack() {
    return {
      display_name: 'subscription',
      ref: this.stackRef,
      initial: [
        { component: ContactChooser, props: this._getContactChooser() }
      ]
    }
  }

  _handleContact(contact) {
    this._handlePush(Details, this._getDetails(contact))
  }

  _handleDetails(details) {
    this.props.onNext(details)
  }

  _handlePop() {
    this.stackRef.current.pop()
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

}

export default Contact
