import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import Resource from './resource'
import Edit from './edit'
import React from 'react'
import New from './new'

class ResourcesField extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    defaultValue: PropTypes.array,
    required: PropTypes.bool,
    service: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.array,
    onBusy: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    onBusy: () => {},
    onChange: () => {},
    onReady: () => {},
    onValid: () => {}
  }

  state = {
    resources: null
  }

  _handleAdd = this._handleAdd.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleChange = _.debounce(this._handleChange.bind(this), 50)
  _handleMove = this._handleMove.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { resources } = this.state
    if(!resources) return null
    return (
      <div className="maha-input">
        <div className="subscriptions-resourcesfield">
          <div className="subscriptions-resourcesfield-resources">
            { resources.length > 0 &&
              <>
                { resources.map((resource, index) => (
                  <Resource { ...this._getResource(resource, index) } key={`resource_${index}`} />
                ))}
              </>
            }
            <div className="subscriptions-resourcesfield-add">
              <Button { ...this._getNew() } />
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    const { resources } = this.state
    const { value } = this.props
    if(!_.isEqual(resources, prevState.resources)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getNew() {
    return {
      label: '+ t(add resource)',
      className: 'link',
      handler: this._handleNew
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : []
  }

  _getResource(resource, index) {
    return {
      resource,
      index,
      onEdit: this._handleEdit.bind(this, resource, index),
      onMove: this._handleMove,
      onRemove: this._handleRemove.bind(this, index),
      onToggle: this._handleToggle.bind(this, index)
    }
  }

  _getTitle(resource, index) {
    const { tabIndex } = this.props
    return {
      tabIndex,
      value: resource.title,
      onChange: this._handleUpdate.bind(this, index, 'title')
    }
  }

  _getPrice(resource, index) {
    const { tabIndex } = this.props
    return {
      tabIndex,
      value: Number(resource.price),
      onChange: this._handleUpdate.bind(this, index, 'price')
    }
  }

  _handleAdd(resource) {
    const { resources } = this.state
    this.setState({
      resources: [
        ...resources,
        {
          ...resource,
          is_active: true,
          delta: resources.length
        }
      ]
    })
  }

  _handleNew() {
    const props = {
      onDone: this._handleAdd
    }
    this.context.form.push(<New { ...props } />)
  }

  _handleEdit(resource, index) {
    const props = {
      resource,
      onDone: this._handleUpdate.bind(this, index)
    }
    this.context.form.push(<Edit { ...props } />)
  }

  _handleChange() {
    const { resources } = this.state
    this.props.onChange(resources)
  }

  _handleMove(from, to) {
    const { resources } = this.state
    this.setState({
      resources: ((from < to) ? [
        ...resources.slice(0, from),
        ...resources.slice(from + 1, to + 1),
        resources[from],
        ...resources.slice(to + 1)
      ] : [
        ...resources.slice(0, to),
        resources[from],
        ...resources.slice(to, from),
        ...resources.slice(from + 1)
      ]).map((resource, delta) => ({
        ...resource,
        delta
      }))
    })
  }

  _handleRemove(index) {
    const { resources } = this.state
    this.setState({
      resources: resources.filter((resource, i) => {
        return i !== index
      }).map((resource, delta) => ({
        ...resource,
        delta
      }))
    })
  }

  _handleSet(resources) {
    this.setState({
      resources: resources || []
    })
  }

  _handleToggle(index) {
    const { resources } = this.state
    this.setState({
      resources: resources.map((resource, i) => ({
        ...resource,
        is_active: i === index ? !resource.is_active : resource.is_active
      }))
    })
  }

  _handleUpdate(index, newresource) {
    const { resources } = this.state
    this.setState({
      resources: [
        ...resources.map((resource, i) => {
          return i === index ? newresource : resource
        })
      ]
    })
  }

  _handleValidate() {
    const { resources } = this.state
    const { required } = this.props
    if(required === true && resources.length === 0) {
      this.props.onValid(resources, ['You must add at least one resource'])
    } else {
      this.props.onValid(resources)
    }
  }

}

export default ResourcesField
