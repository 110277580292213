import useActionReducer from '@admin/hooks/use_action_reducer'
import CheckboxesField from './checkboxesfield'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const CheckboxesFieldContainer = (props) => {

  const [state, handlers] = useActionReducer({
    display_name: 'checkboxesfield',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    ...props,
    ...state,
    ...handlers
  }

  return <CheckboxesField { ...mergedProps } />
}

CheckboxesFieldContainer.propTypes = {}

export default CheckboxesFieldContainer
