import AssetPreview from '@admin/components/asset_preview'
import Page from '@admin/components/page'
import PropTypes from 'prop-types'
import React from 'react'

class File extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    file: PropTypes.object
  }

  render() {
    const { file } = this.props
    return <AssetPreview asset={ file.asset } comments={ true } shareable={ file.can_share } />
  }

  componentDidMount() {
    const { file } = this.props
    this.context.network.request({
      endpoint: `/api/admin/drive/files/${file.code}/view`,
      method: 'PATCH',
      onFailure: () => {},
      onSuccess: () => {}
    })
  }

}

const mapResourcesToPage = (props, context) => ({
  file: `/api/admin/drive/files/${props.params.code}`
})

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(File Viewer)',
  component: File,
  task: document.body.clientWidth <= 768 ? {
    icon: 'info-circle',
    route: `/admin/drive/files/${props.params.code}/info`
  } : null
})

export default Page(mapResourcesToPage, mapPropsToPage)
