import New from '@admin/components/profile_connect'
import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import React from 'react'

class Profile extends React.Component {

  static propTypes = {
    profiles: PropTypes.array,
    onCancel: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleConnect = this._handleConnect.bind(this)

  render() {
    return <Message { ...this._getMessage()} />
  }

  _getMessage() {
    return {
      backgroundColor: 'blue',
      icon: 'envelope',
      title: 't(Email)',
      text: 't(You can send, receive, and manage your email directly through Maha. This enables you to import emails into the CRM so the conversation becomes a part of your contact\'s history.)',
      buttons: [
        { label: 't(Connect Account)', handler: this._handleConnect }
      ],
      link: { label: 't(No Thanks)', handler: this._handleCancel }
    }
  }

  _getNew() {
    const { onPop, onPush } = this.props
    return {
      types: ['emails'],
      onPush,
      onPop
    }
  }

  _handleCancel() {
    this.props.onCancel()
  }

  _handleConnect() {
    this.props.onPush(New, this._getNew.bind(this))
  }

  _handleDone() {
    this.props.onPop()
  }

}

export default Profile
