import Fields from '@admin/components/fields'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Properties)',
  panel: <Fields label="property" parent_type="sales_pipelines" parent_id={ props.pipeline.id } />
})

export default Panel(null, mapPropsToPanel)
