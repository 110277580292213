import Logo from '@admin/components/logo'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneNumber extends React.Component {

  static propTypes = {
    phone_number: PropTypes.object,
    onClose: PropTypes.func
  }

  render() {
    const { phone_number } = this.props
    return (
      <div className="maha-handset-phone-number">
        <div className="maha-handset-phone-number-logo">
          <Logo team={ phone_number.program }/>
        </div>
        <div className="maha-handset-phone-number-label">
          <strong>{ phone_number.program.title }</strong><br/>
          <span>{ phone_number.formatted }</span>
        </div>
      </div>
    )
  }

}

export default PhoneNumber
