import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const visbilities = {
  public: {
    title: 't(Public)',
    text: 't(Assign view privileges to everyone)'
  },
  private: {
    title: 't(Private)',
    text: 't(Do not assign view privileges to everyone)'
  }
}

const VisibilityToken = ({ value }) => {
  const visbility = visbilities[value]
  return (
    <div className="type-token">
      <strong><T text={ visbility.title } /></strong><br />
      <T text={ visbility.text } />
    </div>
  )
}

VisibilityToken.propTypes = {
  value: PropTypes.string
}

export default VisibilityToken
