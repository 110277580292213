export default [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    code: 'AF',
    dialCode: 93,
    emoji: '1f1e6-1f1eb',
    priority: 0
  },
  {
    name: 'Albania (Shqipëri)',
    code: 'AL',
    dialCode: 355,
    emoji: '1f1e6-1f1f1',
    priority: 0
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    code: 'DZ',
    dialCode: 213,
    emoji: '1f1e9-1f1ff',
    priority: 0
  },
  {
    name: 'American Samoa',
    code: 'AS',
    dialCode: 1,
    emoji: '1f1e6-1f1f8',
    priority: 0
  },
  {
    name: 'Andorra',
    code: 'AD',
    dialCode: 376,
    emoji: '1f1e6-1f1e9',
    priority: 0
  },
  {
    name: 'Angola',
    code: 'AO',
    dialCode: 244,
    emoji: '1f1e6-1f1f4',
    priority: 0
  },
  {
    name: 'Anguilla',
    code: 'AI',
    dialCode: 1,
    emoji: '1f1e6-1f1ee',
    priority: 0
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    dialCode: 1,
    emoji: '1f1e6-1f1ec',
    priority: 0
  },
  {
    name: 'Argentina',
    code: 'AR',
    dialCode: 54,
    emoji: '1f1e6-1f1f7',
    priority: 0
  },
  {
    name: 'Armenia (Հայաստան)',
    code: 'AM',
    dialCode: 374,
    emoji: '1f1e6-1f1f2',
    priority: 0
  },
  {
    name: 'Aruba',
    code: 'AW',
    dialCode: 297,
    emoji: '1f1e6-1f1fc',
    priority: 0
  },
  {
    name: 'Ascension Island',
    code: 'AC',
    dialCode: 247,
    emoji: '1f1e6-1f1e8',
    priority: 0
  },
  {
    name: 'Australia',
    code: 'AU',
    dialCode: 61,
    emoji: '1f1e6-1f1fa',
    priority: 0
  },
  {
    name: 'Austria (Österreich)',
    code: 'AT',
    dialCode: 43,
    emoji: '1f1e6-1f1f9',
    priority: 0
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    code: 'AZ',
    dialCode: 994,
    emoji: '1f1e6-1f1ff',
    priority: 0
  },
  {
    name: 'Bahamas',
    code: 'BS',
    dialCode: 1,
    emoji: '1f1e7-1f1f8',
    priority: 0
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    code: 'BH',
    dialCode: 973,
    emoji: '1f1e7-1f1ed',
    priority: 0
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    code: 'BD',
    dialCode: 880,
    emoji: '1f1e7-1f1e9',
    priority: 0
  },
  {
    name: 'Barbados',
    code: 'BB',
    dialCode: 1,
    emoji: '1f1e7-1f1e7',
    priority: 0
  },
  {
    name: 'Belarus (Беларусь)',
    code: 'BY',
    dialCode: 375,
    emoji: '1f1e7-1f1fe',
    priority: 0
  },
  {
    name: 'Belgium (België)',
    code: 'BE',
    dialCode: 32,
    emoji: '1f1e7-1f1ea',
    priority: 0
  },
  {
    name: 'Belize',
    code: 'BZ',
    dialCode: 501,
    emoji: '1f1e7-1f1ff',
    priority: 0
  },
  {
    name: 'Benin (Bénin)',
    code: 'BJ',
    dialCode: 229,
    emoji: '1f1e7-1f1ef',
    priority: 0
  },
  {
    name: 'Bermuda',
    code: 'BM',
    dialCode: 1,
    emoji: '1f1e7-1f1f2',
    priority: 0
  },
  {
    name: 'Bhutan (འབྲུག)',
    code: 'BT',
    dialCode: 975,
    emoji: '1f1e7-1f1f9',
    priority: 0
  },
  {
    name: 'Bolivia',
    code: 'BO',
    dialCode: 591,
    emoji: '1f1e7-1f1f4',
    priority: 0
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    code: 'BA',
    dialCode: 387,
    emoji: '1f1e7-1f1e6',
    priority: 0
  },
  {
    name: 'Botswana',
    code: 'BW',
    dialCode: 267,
    emoji: '1f1e7-1f1fc',
    priority: 0
  },
  {
    name: 'Brazil (Brasil)',
    code: 'BR',
    dialCode: 55,
    emoji: '1f1e7-1f1f7',
    priority: 0
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    dialCode: 246,
    emoji: '1f1ee-1f1f4',
    priority: 0
  },
  {
    name: 'British Virgin Islands',
    code: 'VG',
    dialCode: 1,
    emoji: '1f1fb-1f1ec',
    priority: 0
  },
  {
    name: 'Brunei',
    code: 'BN',
    dialCode: 673,
    emoji: '1f1e7-1f1f3',
    priority: 0
  },
  {
    name: 'Bulgaria (България)',
    code: 'BG',
    dialCode: 359,
    emoji: '1f1e7-1f1ec',
    priority: 0
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    dialCode: 226,
    emoji: '1f1e7-1f1eb',
    priority: 0
  },
  {
    name: 'Burundi (Uburundi)',
    code: 'BI',
    dialCode: 257,
    emoji: '1f1e7-1f1ee',
    priority: 0
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    code: 'KH',
    dialCode: 855,
    emoji: '1f1f0-1f1ed',
    priority: 0
  },
  {
    name: 'Cameroon (Cameroun)',
    code: 'CM',
    dialCode: 237,
    emoji: '1f1e8-1f1f2',
    priority: 0
  },
  {
    name: 'Canada',
    code: 'CA',
    dialCode: 1,
    emoji: '1f1e8-1f1e6',
    priority: 0
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    code: 'CV',
    dialCode: 238,
    emoji: '1f1e8-1f1fb',
    priority: 0
  },
  {
    name: 'Caribbean Netherlands',
    code: 'BQ',
    dialCode: 599,
    emoji: '1f1e7-1f1f6',
    priority: 0
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    dialCode: 1,
    emoji: '1f1f0-1f1fe',
    priority: 0
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    code: 'CF',
    dialCode: 236,
    emoji: '1f1e8-1f1eb',
    priority: 0
  },
  {
    name: 'Chad (Tchad)',
    code: 'TD',
    dialCode: 235,
    emoji: '1f1f9-1f1e9',
    priority: 0
  },
  {
    name: 'Chile',
    code: 'CL',
    dialCode: 56,
    emoji: '1f1e8-1f1f1',
    priority: 0
  },
  {
    name: 'China (中国)',
    code: 'CN',
    dialCode: 86,
    emoji: '1f1e8-1f1f3',
    priority: 0
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    dialCode: 61,
    emoji: '1f1e8-1f1fd',
    priority: 0
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    dialCode: 61,
    emoji: '1f1e8-1f1e8',
    priority: 0
  },
  {
    name: 'Colombia',
    code: 'CO',
    dialCode: 57,
    emoji: '1f1e8-1f1f4',
    priority: 0
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    code: 'KM',
    dialCode: 269,
    emoji: '1f1f0-1f1f2',
    priority: 0
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    code: 'CD',
    dialCode: 243,
    emoji: '1f1e8-1f1e9',
    priority: 0
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    code: 'CG',
    dialCode: 242,
    emoji: '1f1e8-1f1ec',
    priority: 0
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    dialCode: 682,
    emoji: '1f1e8-1f1f0',
    priority: 0
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    dialCode: 506,
    emoji: '1f1e8-1f1f7',
    priority: 0
  },
  {
    name: 'Côte d’Ivoire',
    code: 'CI',
    dialCode: 225,
    emoji: '1f1e8-1f1ee',
    priority: 0
  },
  {
    name: 'Croatia (Hrvatska)',
    code: 'HR',
    dialCode: 385,
    emoji: '1f1ed-1f1f7',
    priority: 0
  },
  {
    name: 'Cuba',
    code: 'CU',
    dialCode: 53,
    emoji: '1f1e8-1f1fa',
    priority: 0
  },
  {
    name: 'Curaçao',
    code: 'CW',
    dialCode: 599,
    emoji: '1f1e8-1f1fc',
    priority: 0
  },
  {
    name: 'Cyprus (Κύπρος)',
    code: 'CY',
    dialCode: 357,
    emoji: '1f1e8-1f1fe',
    priority: 0
  },
  {
    name: 'Czech Republic (Česká republika)',
    code: 'CZ',
    dialCode: 420,
    emoji: '1f1e8-1f1ff',
    priority: 0
  },
  {
    name: 'Denmark (Danmark)',
    code: 'DK',
    dialCode: 45,
    emoji: '1f1e9-1f1f0',
    priority: 0
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    dialCode: 253,
    emoji: '1f1e9-1f1ef',
    priority: 0
  },
  {
    name: 'Dominica',
    code: 'DM',
    dialCode: 1,
    emoji: '1f1e9-1f1f2',
    priority: 0
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    code: 'DO',
    dialCode: 1,
    emoji: '1f1e9-1f1f4',
    priority: 0
  },
  {
    name: 'Ecuador',
    code: 'EC',
    dialCode: 593,
    emoji: '1f1ea-1f1e8',
    priority: 0
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    code: 'EG',
    dialCode: 20,
    emoji: '1f1ea-1f1ec',
    priority: 0
  },
  {
    name: 'El Salvador',
    code: 'SV',
    dialCode: 503,
    emoji: '1f1f8-1f1fb',
    priority: 0
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    code: 'GQ',
    dialCode: 240,
    emoji: '1f1ec-1f1f6',
    priority: 0
  },
  {
    name: 'Eritrea',
    code: 'ER',
    dialCode: 291,
    emoji: '1f1ea-1f1f7',
    priority: 0
  },
  {
    name: 'Estonia (Eesti)',
    code: 'EE',
    dialCode: 372,
    emoji: '1f1ea-1f1ea',
    priority: 0
  },
  {
    name: 'Eswatini',
    code: 'SZ',
    dialCode: 268,
    emoji: '1f1f8-1f1ff',
    priority: 0
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    dialCode: 251,
    emoji: '1f1ea-1f1f9',
    priority: 0
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'FK',
    dialCode: 500,
    emoji: '1f1eb-1f1f0',
    priority: 0
  },
  {
    name: 'Faroe Islands (Føroyar)',
    code: 'FO',
    dialCode: 298,
    emoji: '1f1eb-1f1f4',
    priority: 0
  },
  {
    name: 'Fiji',
    code: 'FJ',
    dialCode: 679,
    emoji: '1f1eb-1f1ef',
    priority: 0
  },
  {
    name: 'Finland (Suomi)',
    code: 'FI',
    dialCode: 358,
    emoji: '1f1eb-1f1ee',
    priority: 0
  },
  {
    name: 'France',
    code: 'FR',
    dialCode: 33,
    emoji: '1f1eb-1f1f7',
    priority: 0
  },
  {
    name: 'French Guiana (Guyane française)',
    code: 'GF',
    dialCode: 594,
    emoji: '1f1ec-1f1eb',
    priority: 0
  },
  {
    name: 'French Polynesia (Polynésie française)',
    code: 'PF',
    dialCode: 689,
    emoji: '1f1f5-1f1eb',
    priority: 0
  },
  {
    name: 'Gabon',
    code: 'GA',
    dialCode: 241,
    emoji: '1f1ec-1f1e6',
    priority: 0
  },
  {
    name: 'Gambia',
    code: 'GM',
    dialCode: 220,
    emoji: '1f1ec-1f1f2',
    priority: 0
  },
  {
    name: 'Georgia (საქართველო)',
    code: 'GE',
    dialCode: 995,
    emoji: '1f1ec-1f1ea',
    priority: 0
  },
  {
    name: 'Germany (Deutschland)',
    code: 'DE',
    dialCode: 49,
    emoji: '1f1e9-1f1ea',
    priority: 0
  },
  {
    name: 'Ghana (Gaana)',
    code: 'GH',
    dialCode: 233,
    emoji: '1f1ec-1f1ed',
    priority: 0
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    dialCode: 350,
    emoji: '1f1ec-1f1ee',
    priority: 0
  },
  {
    name: 'Greece (Ελλάδα)',
    code: 'GR',
    dialCode: 30,
    emoji: '1f1ec-1f1f7',
    priority: 0
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    code: 'GL',
    dialCode: 299,
    emoji: '1f1ec-1f1f1',
    priority: 0
  },
  {
    name: 'Grenada',
    code: 'GD',
    dialCode: 1,
    emoji: '1f1ec-1f1e9',
    priority: 0
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    dialCode: 590,
    emoji: '1f1ec-1f1f5',
    priority: 0
  },
  {
    name: 'Guam',
    code: 'GU',
    dialCode: 1,
    emoji: '1f1ec-1f1fa',
    priority: 0
  },
  {
    name: 'Guatemala',
    code: 'GT',
    dialCode: 502,
    emoji: '1f1ec-1f1f9',
    priority: 0
  },
  {
    name: 'Guernsey',
    code: 'GG',
    dialCode: 44,
    emoji: '1f1ec-1f1ec',
    priority: 0
  },
  {
    name: 'Guinea (Guinée)',
    code: 'GN',
    dialCode: 224,
    emoji: '1f1ec-1f1f3',
    priority: 0
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    code: 'GW',
    dialCode: 245,
    emoji: '1f1ec-1f1fc',
    priority: 0
  },
  {
    name: 'Guyana',
    code: 'GY',
    dialCode: 592,
    emoji: '1f1ec-1f1fe',
    priority: 0
  },
  {
    name: 'Haiti',
    code: 'HT',
    dialCode: 509,
    emoji: '1f1ed-1f1f9',
    priority: 0
  },
  {
    name: 'Honduras',
    code: 'HN',
    dialCode: 504,
    emoji: '1f1ed-1f1f3',
    priority: 0
  },
  {
    name: 'Hong Kong (香港)',
    code: 'HK',
    dialCode: 852,
    emoji: '1f1ed-1f1f0',
    priority: 0
  },
  {
    name: 'Hungary (Magyarország)',
    code: 'HU',
    dialCode: 36,
    emoji: '1f1ed-1f1fa',
    priority: 0
  },
  {
    name: 'Iceland (Ísland)',
    code: 'IS',
    dialCode: 354,
    emoji: '1f1ee-1f1f8',
    priority: 0
  },
  {
    name: 'India (भारत)',
    code: 'IN',
    dialCode: 91,
    emoji: '1f1ee-1f1f3',
    priority: 0
  },
  {
    name: 'Indonesia',
    code: 'ID',
    dialCode: 62,
    emoji: '1f1ee-1f1e9',
    priority: 0
  },
  {
    name: 'Iran (‫ایران‬‎)',
    code: 'IR',
    dialCode: 98,
    emoji: '1f1ee-1f1f7',
    priority: 0
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    code: 'IQ',
    dialCode: 964,
    emoji: '1f1ee-1f1f6',
    priority: 0
  },
  {
    name: 'Ireland',
    code: 'IE',
    dialCode: 353,
    emoji: '1f1ee-1f1ea',
    priority: 0
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    dialCode: 44,
    emoji: '1f1ee-1f1f2',
    priority: 0
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    code: 'IL',
    dialCode: 972,
    emoji: '1f1ee-1f1f1',
    priority: 0
  },
  {
    name: 'Italy (Italia)',
    code: 'IT',
    dialCode: 39,
    emoji: '1f1ee-1f1f9',
    priority: 0
  },
  {
    name: 'Jamaica',
    code: 'JM',
    dialCode: 1,
    emoji: '1f1ef-1f1f2',
    priority: 0
  },
  {
    name: 'Japan (日本)',
    code: 'JP',
    dialCode: 81,
    emoji: '1f1ef-1f1f5',
    priority: 0
  },
  {
    name: 'Jersey',
    code: 'JE',
    dialCode: 44,
    emoji: '1f1ef-1f1ea',
    priority: 0
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    code: 'JO',
    dialCode: 962,
    emoji: '1f1ef-1f1f4',
    priority: 0
  },
  {
    name: 'Kazakhstan (Казахстан)',
    code: 'KZ',
    dialCode: 7,
    emoji: '1f1f0-1f1ff',
    priority: 0
  },
  {
    name: 'Kenya',
    code: 'KE',
    dialCode: 254,
    emoji: '1f1f0-1f1ea',
    priority: 0
  },
  {
    name: 'Kiribati',
    code: 'KI',
    dialCode: 686,
    emoji: '1f1f0-1f1ee',
    priority: 0
  },
  {
    name: 'Kosovo',
    code: 'XK',
    dialCode: 383,
    emoji: '1f1fd-1f1f0',
    priority: 0
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    code: 'KW',
    dialCode: 965,
    emoji: '1f1f0-1f1fc',
    priority: 0
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    code: 'KG',
    dialCode: 996,
    emoji: '1f1f0-1f1ec',
    priority: 0
  },
  {
    name: 'Laos (ລາວ)',
    code: 'LA',
    dialCode: 856,
    emoji: '1f1f1-1f1e6',
    priority: 0
  },
  {
    name: 'Latvia (Latvija)',
    code: 'LV',
    dialCode: 371,
    emoji: '1f1f1-1f1fb',
    priority: 0
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    code: 'LB',
    dialCode: 961,
    emoji: '1f1f1-1f1e7',
    priority: 0
  },
  {
    name: 'Lesotho',
    code: 'LS',
    dialCode: 266,
    emoji: '1f1f1-1f1f8',
    priority: 0
  },
  {
    name: 'Liberia',
    code: 'LR',
    dialCode: 231,
    emoji: '1f1f1-1f1f7',
    priority: 0
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    code: 'LY',
    dialCode: 218,
    emoji: '1f1f1-1f1fe',
    priority: 0
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    dialCode: 423,
    emoji: '1f1f1-1f1ee',
    priority: 0
  },
  {
    name: 'Lithuania (Lietuva)',
    code: 'LT',
    dialCode: 370,
    emoji: '1f1f1-1f1f9',
    priority: 0
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    dialCode: 352,
    emoji: '1f1f1-1f1fa',
    priority: 0
  },
  {
    name: 'Macau (澳門)',
    code: 'MO',
    dialCode: 853,
    emoji: '1f1f2-1f1f4',
    priority: 0
  },
  {
    name: 'North Macedonia (Македонија)',
    code: 'MK',
    dialCode: 389,
    emoji: '1f1f2-1f1f0',
    priority: 0
  },
  {
    name: 'Madagascar (Madagasikara)',
    code: 'MG',
    dialCode: 261,
    emoji: '1f1f2-1f1ec',
    priority: 0
  },
  {
    name: 'Malawi',
    code: 'MW',
    dialCode: 265,
    emoji: '1f1f2-1f1fc',
    priority: 0
  },
  {
    name: 'Malaysia',
    code: 'MY',
    dialCode: 60,
    emoji: '1f1f2-1f1fe',
    priority: 0
  },
  {
    name: 'Maldives',
    code: 'MV',
    dialCode: 960,
    emoji: '1f1f2-1f1fb',
    priority: 0
  },
  {
    name: 'Mali',
    code: 'ML',
    dialCode: 223,
    emoji: '1f1f2-1f1f1',
    priority: 0
  },
  {
    name: 'Malta',
    code: 'MT',
    dialCode: 356,
    emoji: '1f1f2-1f1f9',
    priority: 0
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    dialCode: 692,
    emoji: '1f1f2-1f1ed',
    priority: 0
  },
  {
    name: 'Martinique',
    code: 'MQ',
    dialCode: 596,
    emoji: '1f1f2-1f1f6',
    priority: 0
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    code: 'MR',
    dialCode: 222,
    emoji: '1f1f2-1f1f7',
    priority: 0
  },
  {
    name: 'Mauritius (Moris)',
    code: 'MU',
    dialCode: 230,
    emoji: '1f1f2-1f1fa',
    priority: 0
  },
  {
    name: 'Mayotte',
    code: 'YT',
    dialCode: 262,
    emoji: '1f1fe-1f1f9',
    priority: 0
  },
  {
    name: 'Mexico (México)',
    code: 'MX',
    dialCode: 52,
    emoji: '1f1f2-1f1fd',
    priority: 0
  },
  {
    name: 'Micronesia',
    code: 'FM',
    dialCode: 691,
    emoji: '1f1eb-1f1f2',
    priority: 0
  },
  {
    name: 'Moldova (Republica Moldova)',
    code: 'MD',
    dialCode: 373,
    emoji: '1f1f2-1f1e9',
    priority: 0
  },
  {
    name: 'Monaco',
    code: 'MC',
    dialCode: 377,
    emoji: '1f1f2-1f1e8',
    priority: 0
  },
  {
    name: 'Mongolia (Монгол)',
    code: 'MN',
    dialCode: 976,
    emoji: '1f1f2-1f1f3',
    priority: 0
  },
  {
    name: 'Montenegro (Crna Gora)',
    code: 'ME',
    dialCode: 382,
    emoji: '1f1f2-1f1ea',
    priority: 0
  },
  {
    name: 'Montserrat',
    code: 'MS',
    dialCode: 1,
    emoji: '1f1f2-1f1f8',
    priority: 0
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    code: 'MA',
    dialCode: 212,
    emoji: '1f1f2-1f1e6',
    priority: 0
  },
  {
    name: 'Mozambique (Moçambique)',
    code: 'MZ',
    dialCode: 258,
    emoji: '1f1f2-1f1ff',
    priority: 0
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    code: 'MM',
    dialCode: 95,
    emoji: '1f1f2-1f1f2',
    priority: 0
  },
  {
    name: 'Namibia (Namibië)',
    code: 'NA',
    dialCode: 264,
    emoji: '1f1f3-1f1e6',
    priority: 0
  },
  {
    name: 'Nauru',
    code: 'NR',
    dialCode: 674,
    emoji: '1f1f3-1f1f7',
    priority: 0
  },
  {
    name: 'Nepal (नेपाल)',
    code: 'NP',
    dialCode: 977,
    emoji: '1f1f3-1f1f5',
    priority: 0
  },
  {
    name: 'Netherlands (Nederland)',
    code: 'NL',
    dialCode: 31,
    emoji: '1f1f3-1f1f1',
    priority: 0
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    code: 'NC',
    dialCode: 687,
    emoji: '1f1f3-1f1e8',
    priority: 0
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    dialCode: 64,
    emoji: '1f1f3-1f1ff',
    priority: 0
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    dialCode: 505,
    emoji: '1f1f3-1f1ee',
    priority: 0
  },
  {
    name: 'Niger (Nijar)',
    code: 'NE',
    dialCode: 227,
    emoji: '1f1f3-1f1ea',
    priority: 0
  },
  {
    name: 'Nigeria',
    code: 'NG',
    dialCode: 234,
    emoji: '1f1f3-1f1ec',
    priority: 0
  },
  {
    name: 'Niue',
    code: 'NU',
    dialCode: 683,
    emoji: '1f1f3-1f1fa',
    priority: 0
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    dialCode: 672,
    emoji: '1f1f3-1f1eb',
    priority: 0
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    code: 'KP',
    dialCode: 850,
    emoji: '1f1f0-1f1f5',
    priority: 0
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    dialCode: 1,
    emoji: '1f1f2-1f1f5',
    priority: 0
  },
  {
    name: 'Norway (Norge)',
    code: 'NO',
    dialCode: 47,
    emoji: '1f1f3-1f1f4',
    priority: 0
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    code: 'OM',
    dialCode: 968,
    emoji: '1f1f4-1f1f2',
    priority: 0
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    code: 'PK',
    dialCode: 92,
    emoji: '1f1f5-1f1f0',
    priority: 0
  },
  {
    name: 'Palau',
    code: 'PW',
    dialCode: 680,
    emoji: '1f1f5-1f1fc',
    priority: 0
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    code: 'PS',
    dialCode: 970,
    emoji: '1f1f5-1f1f8',
    priority: 0
  },
  {
    name: 'Panama (Panamá)',
    code: 'PA',
    dialCode: 507,
    emoji: '1f1f5-1f1e6',
    priority: 0
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    dialCode: 675,
    emoji: '1f1f5-1f1ec',
    priority: 0
  },
  {
    name: 'Paraguay',
    code: 'PY',
    dialCode: 595,
    emoji: '1f1f5-1f1fe',
    priority: 0
  },
  {
    name: 'Peru (Perú)',
    code: 'PE',
    dialCode: 51,
    emoji: '1f1f5-1f1ea',
    priority: 0
  },
  {
    name: 'Philippines',
    code: 'PH',
    dialCode: 63,
    emoji: '1f1f5-1f1ed',
    priority: 0
  },
  {
    name: 'Poland (Polska)',
    code: 'PL',
    dialCode: 48,
    emoji: '1f1f5-1f1f1',
    priority: 0
  },
  {
    name: 'Portugal',
    code: 'PT',
    dialCode: 351,
    emoji: '1f1f5-1f1f9',
    priority: 0
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    dialCode: 1,
    emoji: '1f1f5-1f1f7',
    priority: 0
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    code: 'QA',
    dialCode: 974,
    emoji: '1f1f6-1f1e6',
    priority: 0
  },
  {
    name: 'Réunion (La Réunion)',
    code: 'RE',
    dialCode: 262,
    emoji: '1f1f7-1f1ea',
    priority: 0
  },
  {
    name: 'Romania (România)',
    code: 'RO',
    dialCode: 40,
    emoji: '1f1f7-1f1f4',
    priority: 0
  },
  {
    name: 'Russia (Россия)',
    code: 'RU',
    dialCode: 7,
    emoji: '1f1f7-1f1fa',
    priority: 0
  },
  {
    name: 'Rwanda',
    code: 'RW',
    dialCode: 250,
    emoji: '1f1f7-1f1fc',
    priority: 0
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    dialCode: 590,
    emoji: '1f1e7-1f1f1',
    priority: 0
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    dialCode: 290,
    emoji: '1f1f8-1f1ed',
    priority: 0
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    dialCode: 1,
    emoji: '1f1f0-1f1f3',
    priority: 0
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    dialCode: 1,
    emoji: '1f1f1-1f1e8',
    priority: 0
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    code: 'MF',
    dialCode: 590,
    emoji: '1f1f2-1f1eb',
    priority: 0
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    code: 'PM',
    dialCode: 508,
    emoji: '1f1f5-1f1f2',
    priority: 0
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    dialCode: 1,
    emoji: '1f1fb-1f1e8',
    priority: 0
  },
  {
    name: 'Samoa',
    code: 'WS',
    dialCode: 685,
    emoji: '1f1fc-1f1f8',
    priority: 0
  },
  {
    name: 'San Marino',
    code: 'SM',
    dialCode: 378,
    emoji: '1f1f8-1f1f2',
    priority: 0
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    code: 'ST',
    dialCode: 239,
    emoji: '1f1f8-1f1f9',
    priority: 0
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    code: 'SA',
    dialCode: 966,
    emoji: '1f1f8-1f1e6',
    priority: 0
  },
  {
    name: 'Senegal (Sénégal)',
    code: 'SN',
    dialCode: 221,
    emoji: '1f1f8-1f1f3',
    priority: 0
  },
  {
    name: 'Serbia (Србија)',
    code: 'RS',
    dialCode: 381,
    emoji: '1f1f7-1f1f8',
    priority: 0
  },
  {
    name: 'Seychelles',
    code: 'SC',
    dialCode: 248,
    emoji: '1f1f8-1f1e8',
    priority: 0
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    dialCode: 232,
    emoji: '1f1f8-1f1f1',
    priority: 0
  },
  {
    name: 'Singapore',
    code: 'SG',
    dialCode: 65,
    emoji: '1f1f8-1f1ec',
    priority: 0
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    dialCode: 1,
    emoji: '1f1f8-1f1fd',
    priority: 0
  },
  {
    name: 'Slovakia (Slovensko)',
    code: 'SK',
    dialCode: 421,
    emoji: '1f1f8-1f1f0',
    priority: 0
  },
  {
    name: 'Slovenia (Slovenija)',
    code: 'SI',
    dialCode: 386,
    emoji: '1f1f8-1f1ee',
    priority: 0
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    dialCode: 677,
    emoji: '1f1f8-1f1e7',
    priority: 0
  },
  {
    name: 'Somalia (Soomaaliya)',
    code: 'SO',
    dialCode: 252,
    emoji: '1f1f8-1f1f4',
    priority: 0
  },
  {
    name: 'South Africa',
    code: 'ZA',
    dialCode: 27,
    emoji: '1f1ff-1f1e6',
    priority: 0
  },
  {
    name: 'South Korea (대한민국)',
    code: 'KR',
    dialCode: 82,
    emoji: '1f1f0-1f1f7',
    priority: 0
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    code: 'SS',
    dialCode: 211,
    emoji: '1f1f8-1f1f8',
    priority: 0
  },
  {
    name: 'Spain (España)',
    code: 'ES',
    dialCode: 34,
    emoji: '1f1ea-1f1f8',
    priority: 0
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    code: 'LK',
    dialCode: 94,
    emoji: '1f1f1-1f1f0',
    priority: 0
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    code: 'SD',
    dialCode: 249,
    emoji: '1f1f8-1f1e9',
    priority: 0
  },
  {
    name: 'Suriname',
    code: 'SR',
    dialCode: 597,
    emoji: '1f1f8-1f1f7',
    priority: 0
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    dialCode: 47,
    emoji: '1f1f8-1f1ef',
    priority: 0
  },
  {
    name: 'Sweden (Sverige)',
    code: 'SE',
    dialCode: 46,
    emoji: '1f1f8-1f1ea',
    priority: 0
  },
  {
    name: 'Switzerland (Schweiz)',
    code: 'CH',
    dialCode: 41,
    emoji: '1f1e8-1f1ed',
    priority: 0
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    code: 'SY',
    dialCode: 963,
    emoji: '1f1f8-1f1fe',
    priority: 0
  },
  {
    name: 'Taiwan (台灣)',
    code: 'TW',
    dialCode: 886,
    emoji: '1f1f9-1f1fc',
    priority: 0
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    dialCode: 992,
    emoji: '1f1f9-1f1ef',
    priority: 0
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    dialCode: 255,
    emoji: '1f1f9-1f1ff',
    priority: 0
  },
  {
    name: 'Thailand (ไทย)',
    code: 'TH',
    dialCode: 66,
    emoji: '1f1f9-1f1ed',
    priority: 0
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    dialCode: 670,
    emoji: '1f1f9-1f1f1',
    priority: 0
  },
  {
    name: 'Togo',
    code: 'TG',
    dialCode: 228,
    emoji: '1f1f9-1f1ec',
    priority: 0
  },
  {
    name: 'Tokelau',
    code: 'TK',
    dialCode: 690,
    emoji: '1f1f9-1f1f0',
    priority: 0
  },
  {
    name: 'Tonga',
    code: 'TO',
    dialCode: 676,
    emoji: '1f1f9-1f1f4',
    priority: 0
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    dialCode: 1,
    emoji: '1f1f9-1f1f9',
    priority: 0
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    code: 'TN',
    dialCode: 216,
    emoji: '1f1f9-1f1f3',
    priority: 0
  },
  {
    name: 'Turkey (Türkiye)',
    code: 'TR',
    dialCode: 90,
    emoji: '1f1f9-1f1f7',
    priority: 0
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    dialCode: 993,
    emoji: '1f1f9-1f1f2',
    priority: 0
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    dialCode: 1,
    emoji: '1f1f9-1f1e8',
    priority: 0
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    dialCode: 688,
    emoji: '1f1f9-1f1fb',
    priority: 0
  },
  {
    name: 'U.S. Virgin Islands',
    code: 'VI',
    dialCode: 1,
    emoji: '1f1fb-1f1ee',
    priority: 0
  },
  {
    name: 'Uganda',
    code: 'UG',
    dialCode: 256,
    emoji: '1f1fa-1f1ec',
    priority: 0
  },
  {
    name: 'Ukraine (Україна)',
    code: 'UA',
    dialCode: 380,
    emoji: '1f1fa-1f1e6',
    priority: 0
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    code: 'AE',
    dialCode: 971,
    emoji: '1f1e6-1f1ea',
    priority: 0
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    dialCode: 44,
    emoji: '1f1ec-1f1e7',
    priority: 0
  },
  {
    name: 'United States',
    code: 'US',
    dialCode: 1,
    emoji: '1f1fa-1f1f8',
    priority: 1
  },
  {
    name: 'Uruguay',
    code: 'UY',
    dialCode: 598,
    emoji: '1f1fa-1f1fe',
    priority: 0
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    code: 'UZ',
    dialCode: 998,
    emoji: '1f1fa-1f1ff',
    priority: 0
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    dialCode: 678,
    emoji: '1f1fb-1f1fa',
    priority: 0
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    code: 'VA',
    dialCode: 39,
    emoji: '1f1fb-1f1e6',
    priority: 0
  },
  {
    name: 'Venezuela',
    code: 'VE',
    dialCode: 58,
    emoji: '1f1fb-1f1ea',
    priority: 0
  },
  {
    name: 'Vietnam (Việt Nam)',
    code: 'VN',
    dialCode: 84,
    emoji: '1f1fb-1f1f3',
    priority: 0
  },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    code: 'WF',
    dialCode: 681,
    emoji: '1f1fc-1f1eb',
    priority: 0
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    code: 'EH',
    dialCode: 212,
    emoji: '1f1ea-1f1ed',
    priority: 0
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    code: 'YE',
    dialCode: 967,
    emoji: '1f1fe-1f1ea',
    priority: 0
  },
  {
    name: 'Zambia',
    code: 'ZM',
    dialCode: 260,
    emoji: '1f1ff-1f1f2',
    priority: 0
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    dialCode: 263,
    emoji: '1f1ff-1f1fc',
    priority: 0
  },
  {
    name: 'Åland Islands',
    code: 'AX',
    dialCode: 358,
    emoji: '1f1e6-1f1fd',
    priority: 0
  }
]
