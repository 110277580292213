import ProfileConnect from '@admin/components/profile_connect'
import ModalPanel from '@admin/components/modal_panel'
import Icon from '@admin/components/icon'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Source from './source'
import React from 'react'

class Sources extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    allow: PropTypes.object,
    cancelText: PropTypes.any,
    counts: PropTypes.object,
    doneText: PropTypes.any,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    sources: PropTypes.array,
    title: PropTypes.string,
    view: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onRemove: PropTypes.func,
    onToggleView: PropTypes.func
  }

  _handleConnect = this._handleConnect.bind(this)

  render() {
    const services = this._getServices()
    const { sources } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="maha-attachments-sources">
          { sources.map((source, index) => (
            <Source key={`source_${index}`} { ...this._getSource(source) } />
          ))}
          { services.length > 0 &&
            <div className="maha-attachments-source" onClick={ this._handleConnect }>
              <div className="maha-attachments-source-logo">
                <Icon icon="plus-circle" />
              </div>
              <div className="maha-attachments-source-text">
                <T text="t(Add a source)" />
              </div>
            </div>
          }
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    const { files, title, onBack, onCancel, onNext } = this.props
    return {
      title: title || 't(Choose File Source)',
      leftItems: [
        ...onBack ? [
          { icon: 'chevron-left', handler: onBack }
        ] : [
          { label: 't(Cancel)', handler: onCancel }
        ]
      ],
      rightItems: files.length > 0 ? [
        { label: 't(Next)', handler: onNext }
      ] : []
    }
  }

  _getProps(source) {
    const { allow, doneText, files, multiple, onAdd, onPop, onNext, onRemove } = this.props
    return {
      allow,
      doneText,
      files,
      multiple,
      source,
      ...source.props || {},
      onAdd,
      onBack: onPop,
      onNext,
      onRemove
    }
  }

  _getServices() {
    const { features } = this.context.admin
    const { allow } = this.props
    return [
      { label: 'Google Drive', name: 'googledrive', types: ['files','photos'] },
      { label: 'Google Photos', name: 'googlephotos', types: ['photos'] },
      { label: 'Microsoft OneDrive', name: 'onedrive', types: ['files','photos'] },
      { label: 'Dropbox', name: 'dropbox', types: ['files','photos'] },
      { label: 'Box', name: 'box', types: ['files','photos'] }
    ].filter(service => {
      const google_allowed = !/google/.test(service.name) || features.google === true
      const service_allowed = !allow.services || _.includes(allow.services, service.name)
      const type_allowed = !allow.types || !service.type || _.intersection(allow.types, service.types).length > 0
      return service_allowed && type_allowed && google_allowed
    })
  }

  _getSource(source) {
    const { counts, files, onAdd } = this.props
    return {
      count: counts[source.id],
      files,
      source,
      onAdd,
      onChoose: this._handleChoose.bind(this, source)
    }
  }

  _handleChoose(source) {
    this.props.onPush(source.panel, this._getProps.bind(this, source))
  }

  _handleConnect() {
    const { onPop } = this.props
    const props = {
      types: ['files','photos'],
      onBack: onPop,
      onDone: onPop
    }
    this.props.onPush(ProfileConnect, props)
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Sources
