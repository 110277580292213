const fonts = {
  abel: { family: 'Abel', variants: ['400','400italic','700','700italic'], fallback: 'sans-serif' },
  abril_fatface: { family: 'Abril Fatface', variants: ['400'], fallback: 'cursive' },
  arvo: { family: 'Arvo', variants: ['400'], fallback: 'serif' },
  berkshire_swash: { family: 'Berkshire Swash', variants: ['400'], fallback: 'cursive' },
  comfortaa: { family: 'Comfortaa', variants: ['300','400','500','600','700'], fallback: 'cursive' },
  hind_vadodara: { family: 'Hind Vadodara', variants: ['300','400','500','600','700'], fallback: 'sans-serif' },
  cormorant_garamond: { family: 'Cormorant Garamond', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic'], fallback: 'serif' },
  fira_sans: { family: 'Fira Sans', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  hind: { family: 'Hind', variants: ['300','400','500','600','700'], fallback: 'sans-serif' },
  lato: { family: 'Lato', variants: ['300','300italic','400','400italic','700','700italic'], fallback: 'sans-serif' },
  lora: { family: 'Lora', variants: ['400','400italic','500','500italic','600','600italic','700','700italic'], fallback: 'sans-serif' },
  merriweather: { family: 'Merriweather', variants: ['300','300italic','400','400italic','700','700italic'], fallback: 'serif' },
  montserrat: { family: 'Montserrat', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic'], fallback: 'sans-serif' },
  mukta: { family: 'Mukta', variants: ['300','400','500','600','700','800'], fallback: 'sans-serif' },
  noto_sans: { family: 'Noto Sans', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  open_sans: { family: 'Open Sans', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  oswald: { family: 'Oswald', variants: ['300','400','500','600','700'], fallback: 'sans-serif' },
  playfair_display: { family: 'Playfair Display', variants: ['400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'serif' },
  poppins: { family: 'Poppins', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  prata: { family: 'Prata', variants: ['400'], fallback: 'serif' },
  pt_sans: { family: 'PT Sans', variants: ['400','400italic','700','700italic'], fallback: 'sans-serif' },
  pt_serif: { family: 'PT Serif', variants: ['400','400italic','700','700italic'], fallback: 'serif' },
  raleway: { family: 'Raleway', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  rozha_one: { family: 'Rozha One', variants: ['400'], fallback: 'serif' },
  roboto: { family: 'Roboto', variants: ['300','300italic','400','400italic','500','500italic','700','700italic'], fallback: 'sans-serif' },
  roboto_slab: { family: 'Roboto Slab', variants: ['300','400','500','600','700','800'], fallback: 'serif' },
  rokkitt: { family: 'Rokkitt', variants: ['300','400','500','600','700','800'], fallback: 'serif' },
  rubik: { family: 'Rubik', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' },
  titillium_web: { family: 'Titillium Web', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic'], fallback: 'sans-serif' },
  ubuntu: { family: 'Ubuntu', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic'], fallback: 'sans-serif' },
  work_sans: { family: 'Work Sans', variants: ['300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic'], fallback: 'sans-serif' }
}

const fontsets = [
  { heading: fonts.abel, text: fonts.abel },
  { heading: fonts.arvo, text: fonts.montserrat },
  { heading: fonts.berkshire_swash, text: fonts.open_sans },
  { heading: fonts.comfortaa, text: fonts.montserrat },
  { heading: fonts.cormorant_garamond, text: fonts.fira_sans },
  { heading: fonts.hind, text: fonts.noto_sans },
  { heading: fonts.hind_vadodara, text: fonts.hind_vadodara },
  { heading: fonts.lato, text: fonts.lato },
  { heading: fonts.lora, text: fonts.hind },
  { heading: fonts.merriweather, text: fonts.merriweather },
  { heading: fonts.montserrat, text: fonts.lato },
  { heading: fonts.montserrat, text: fonts.montserrat },
  { heading: fonts.montserrat, text: fonts.open_sans },
  { heading: fonts.montserrat, text: fonts.poppins },
  { heading: fonts.montserrat, text: fonts.raleway },
  { heading: fonts.oswald, text: fonts.merriweather },
  { heading: fonts.oswald, text: fonts.open_sans },
  { heading: fonts.playfair_display, text: fonts.lato },
  { heading: fonts.playfair_display, text: fonts.rubik },
  { heading: fonts.playfair_display, text: fonts.roboto },
  { heading: fonts.playfair_display, text: fonts.mukta },
  { heading: fonts.poppins, text: fonts.merriweather },
  { heading: fonts.poppins, text: fonts.montserrat },
  { heading: fonts.poppins, text: fonts.poppins },
  { heading: fonts.prata, text: fonts.lato },
  { heading: fonts.roboto_slab, text: fonts.roboto_slab },
  { heading: fonts.roboto_slab, text: fonts.montserrat },
  { heading: fonts.rokkitt, text: fonts.pt_serif },
  { heading: fonts.rozha_one, text: fonts.noto_sans },
  { heading: fonts.titillium_web, text: fonts.pt_sans },
  { heading: fonts.ubuntu, text: fonts.lato },
  { heading: fonts.work_sans, text: fonts.open_sans }
].map(fontset => ({
  value: {
    heading: {
      fontFamily: {
        source: 'google',
        ...fontset.heading
      }
    },
    text: {
      fontFamily: {
        source: 'google',
        ...fontset.text
      }
    }
  },
  text: `${fontset.heading.family} / ${fontset.text.family}`
}))

export default fontsets
