import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const grounds = {
  'consent': {
    title: 't(Consent)',
    text: 't(Contact has given consent)'
  },
  'contract': {
    title: 't(Contract)',
    text: 't(Processing is necessary for the performance of a contract with the data subject or to take steps to enter into a contract)'
  },
  'legal obligation': {
    title: 't(Legal Obligation)',
    text: 't(Processing is necessary for compliance with a legal obligation)'
  },
  'vital interests': {
    title: 't(Vital Interests)',
    text: 't(Processing is necessary to protect the vital interests of a data subject or another person)'
  },
  'public interest': {
    title: 't(Public Interest)',
    text: 't(Processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller)'
  },
  'legitimate interest': {
    title: 't(Legitimate Interest)',
    text: 't(Necessary for the purposes of legitimate interests pursued by the controller or a third party, except where such interests are overridden by the interests, rights or freedoms of the data subject.)'
  }
}

const OptInToken = ({ value }) => {
  const ground = grounds[value]
  return (
    <div className="token">
      <T text={ ground.text } />
    </div>
  )
}

OptInToken.propTypes = {
  value: PropTypes.string
}

export default OptInToken
